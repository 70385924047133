import { Box, Typography } from "@material-ui/core";
import Dialog from "../Dialog";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../Button";
import RadioButton from "../RadioButton";
import CloseIcon from "../../images/cross.svg";
import TextField from "../TextField";
import { useEffect, useState } from "react";
import { useUserStore } from "../../stores/user";
import { SendToSomeoneInterface } from "../Bookings/V2/Corporate/CorporateFlow";
import RecipientItem from "../BlysTeam/Recipients/RecipientItem";

interface SendQuotationToInterface {
  open: boolean;
  onClose: () => unknown;
  onConfirm: (data: SendToSomeoneInterface) => unknown;
}

const SendQuotationToModal = ({ open, onClose, onConfirm }: SendQuotationToInterface) => {
  const isMobile = useMobile();

  const [isSendToMe, setIsSendToMe] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [guestFirstName, setGuestFirstName] = useState("");
  const [guestLastName, setGuestLastName] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [isGuest, setIsGuest] = useState(false);
  const { user } = useUserStore();

  useEffect(() => {
    if (!user) {
      setIsGuest(true);
    }
  }, [user]);

  const handleClickSendToMe = () => {
    setIsSendToMe(true);
    setFirstName("");
    setLastName("");
    setEmail("");
    setNote("");
    setGuestFirstName("");
    setGuestLastName("");
    setGuestEmail("");
  };

  const handleConfirm = () => {
    onConfirm({
      firstName,
      lastName,
      email,
      note,
      isSendToMe,
      isGuest,
      guestFirstName,
      guestLastName,
      guestEmail,
    });
  };

  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      <Box
        bgcolor={Colors.White}
        width={isMobile ? "100%" : "448px"}
        borderRadius="8px"
        position={"relative"}
      >
        <Box
          position={"absolute"}
          padding={"12px"}
          style={{ top: 0, right: 0, cursor: "pointer", padding: 12 }}
          onClick={onClose}
        >
          <img src={CloseIcon} />
        </Box>
        <Box paddingX={"48px"} mb={"32px"} pt={"40px"} position={"relative"}>
          {!user ? (
            <Box>
              <Typography
                style={{
                  fontSize: "30px",
                  fontWeight: 600,
                  color: Colors.NightBlue,
                  lineHeight: "33px",
                }}
              >
                Enter your details:
              </Typography>
              <Box paddingTop="24px">
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  gridGap={"24px"}
                  mb="32px"
                >
                  <TextField
                    title="First name"
                    value={guestFirstName}
                    onChange={(text) => setGuestFirstName(text)}
                    style={{ padding: 0 }}
                  />
                  <TextField
                    title="Last name"
                    value={guestLastName}
                    onChange={(text) => setGuestLastName(text)}
                    style={{ padding: 0 }}
                  />
                </Box>

                <TextField
                  title="Email"
                  placeholder="youremail@gmail.com"
                  value={guestEmail}
                  onChange={(text) => setGuestEmail(text)}
                  type="email"
                  style={{ padding: 0, marginBottom: "32px" }}
                />
              </Box>
            </Box>
          ) : (
            <></>
          )}

          <Typography style={{ fontSize: "30px", fontWeight: 600, color: Colors.NightBlue }}>
            Send this quotation to:
          </Typography>
          <Box paddingTop="24px">
            {user ? (
              <RecipientItem
                recipient={user}
                onSelect={handleClickSendToMe}
                selected={isSendToMe}
              />
            ) : (
              <RecipientItem
                recipient="Myself"
                onSelect={() => {
                  setIsGuest(true);
                  setIsSendToMe(true);
                }}
                selected={isSendToMe}
              />
            )}
            <RecipientItem
              recipient="Someone else"
              onSelect={() => setIsSendToMe(false)}
              selected={!isSendToMe}
            />
          </Box>
        </Box>
        {!isSendToMe ? (
          <Box paddingLeft="48px" paddingRight="48px" mb="32px" mt="3px">
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              gridGap={"24px"}
              mb="32px"
            >
              <TextField
                title="First name"
                value={firstName}
                onChange={(text) => setFirstName(text)}
                style={{ padding: 0 }}
              />
              <TextField
                title="Last name"
                value={lastName}
                onChange={(text) => setLastName(text)}
                style={{ padding: 0 }}
              />
            </Box>

            <TextField
              title="Email"
              placeholder="your@email.com"
              value={email}
              onChange={(text) => setEmail(text)}
              type="email"
              style={{ padding: 0, marginBottom: "32px" }}
            />
            <TextField
              title="Note"
              value={note}
              onChange={(text) => setNote(text)}
              type="note"
              multiline
              style={{ padding: 0, marginBottom: "32px" }}
            />
          </Box>
        ) : (
          <></>
        )}
        <Box
          display={"flex"}
          padding={"24px"}
          gridGap={"16px"}
          border={`1px solid ${Colors.LightPeriwinkle}`}
          bgcolor={Colors.White}
          position={"sticky"}
          bottom={0}
        >
          <Button
            type={ButtonType.outlined}
            size={ButtonSize.large}
            title="Cancel"
            onClick={onClose}
          />
          <Button
            size={ButtonSize.large}
            type={ButtonType.indigo}
            title="Confirm"
            onClick={handleConfirm}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default SendQuotationToModal;

const styles = {
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "32px",
  },
};
