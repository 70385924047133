import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import { DEFAULT_RADIUS, useServiceArea } from "../pages/ProDashboard/ServiceArea/context/ServiceAreaState";
import { useAccessToken } from "../hooks/common";
import serviceAreaAction from "../pages/ProDashboard/ServiceArea/context/ServiceAreaAction";
import config from "../config/proDashboard/dashboardConfig.json";

import NotificationRadius from "../pages/ProDashboard/ServiceArea/components/NotificationRadius";
import SuburbAutoComplete from "../pages/ProDashboard/ServiceArea/components/SuburbAutoComplete";
import MapArea from "../pages/ProDashboard/ServiceArea/components/MapArea";
import { useAlertStore } from "../stores/alert";
import { useUserStore } from "../stores/user";
import { Geocode, extractLocationData } from "../helpers/google";
import { getValue } from "../utils/object";

const DEFAULT_MAP_VALUE = {
  latitude: -33.8688197,
  longitude: 151.2092955,
  center: {
    latitude: -25.835029328122733,
    longitude: 134.31833800670688,
  },
  radius: DEFAULT_RADIUS,
  name: "Sydney, Australia",
};

interface MapConfigInterface {
  latitude: number;
  longitude: number;
  center: {
    latitude: number,
    longitude: number,
  },
  radius: number,
  name: string,
}


const ServiceAreaInnerComponent = ({ handleServiceAreaSelected, onRadiusChange }: any) => {

  const [coordinate, setCoordinate] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [radius, setRadius] = useState(0);
  const [mapConfig, setMapConfig] = useState<MapConfigInterface>(DEFAULT_MAP_VALUE);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isEnabled, setEnabled] = useState(false);

  const { state, dispatch } = useServiceArea();

  const { loading, serviceArea = {}, radius: radiusData = [] } = state;
  const { fixed = {} } = serviceArea || {};

  useEffect(() => {
    serviceAreaAction.fetchServiceAreaRadius(dispatch);
  }, [dispatch]);


  useEffect(() => {
    getMapConfigBasedOnCountry();
  }, [mapConfig]);

  useEffect(() => {
    if (fixed && fixed.radius) {
      setEnabled(true);
      setCoordinate({ latitude: fixed.latitude, longitude: fixed.longitude });
      setSelectedAddress(fixed.name);
      setRadius(fixed.radius);
    }
  }, [fixed]);

  useEffect(() => {
    Geocode(coordinate.latitude, coordinate.longitude)
      .then((result) => {
        if (Array.isArray(result) && result[0]) {
          extractLocationData(result[0]).then((res) => {
            handleServiceAreaSelected(res);
          });
        }
      });

  }, [radius, selectedAddress]);

  const handleRadiusChange = (value: number) => {
    setRadius(value);
    onRadiusChange(value);
  };

  const onAutoCompleteChange = ({
    latitude,
    longitude,
    address,
  }: {
    latitude: number;
    longitude: number;
    address: string;
  }) => {
    // set radius if not already set
    if (!radius) {
      setRadius(mapConfig.radius);
    }
    setCoordinate({ latitude, longitude });
    setSelectedAddress(address);

    // if (isEnabled) updateFixedServiceArea({ radius, latitude, longitude, name: address });
  };

  const onMapClicked = ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    Geocode(latitude, longitude)
      .then((result) => {
        if (Array.isArray(result) && result[0]) {
          const matchedAddress = result[0].formatted_address;
          setSelectedAddress(matchedAddress);
          extractLocationData(result[0]).then((res) => {
            handleServiceAreaSelected(res);
          });
        }
      })
      .catch((error) => console.debug("Error while getting address from corodinates", error));
  };

  const getProMapConfig = (config: any, country = "AU") => {
    // defaults to AU if country code is not found
    const countryConfig = config[country] || config["AU"];
    return getValue(countryConfig, "proDashboard.serviceArea.mapConfig");
  };

  const getMapConfigBasedOnCountry = () => {
    const countryCode = "AU";
    const mapConfig = getProMapConfig(config, countryCode);
    setMapConfig(mapConfig);
    setRadius(mapConfig.radius);
  };

  return (
    <Box
      mt="20px"
      display={"flex"}
      flexDirection={"column"}
      maxWidth="632px"
      fontFamily="Museo"
    >
      <SuburbAutoComplete
        value={selectedAddress}
        hasError={isEnabled && (!coordinate.latitude || !coordinate.longitude)}
        onAutoCompleteChange={onAutoCompleteChange}
        title="Service area"
        description={"Get notified of jobs around a fixed location, like your home suburb. This means you'll be notified of bookings nearby your chosen location, even if you're on another job or in a different city."}
      />

      <NotificationRadius
        loading={loading}
        data={radiusData}
        selected={radius || mapConfig.radius}
        onRadiusChange={(value: number) => handleRadiusChange(value)}
      />

      <Box height="336px" mt="22px" mb="22px">
        <MapArea
          id={"fixed-time-service-area-map"}
          latitude={coordinate.latitude || mapConfig.center.latitude}
          longitude={coordinate.longitude || mapConfig.center.longitude}
          radius={radius || 0}
          getAddressFromLatLng={onMapClicked}
        />
      </Box>
    </Box>
  );
};

export default ServiceAreaInnerComponent;