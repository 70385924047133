import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";

interface Props {
  link: string;
  linkText: string;
  preText?: string;
  postText?: string;
  style?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
}

const TextWithLink = ({
  link,
  style,
  linkText,
  linkStyle,
  preText = "",
  postText = "",
}: Props): JSX.Element => (
  <Box style={style}>
    <span>{preText}</span>
    <a href={link} target="_blank" rel="noreferrer" style={{ ...linkStyle, color: Colors.Dusk }}>
      {linkText}
    </a>
    <span>{postText}</span>
  </Box>
);

export default TextWithLink;
