import { Box } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Colors } from "../../constants/colors";
import { useAlertStore } from "../../stores/alert";
import Button, { ButtonSize, ButtonType } from "../Button";
import PaymentModal from "../PaymentFrame/PaymentModal";
import { getValue } from "../../utils/object";
import { retryFailedVoucherPayment } from "../../services/voucher/voucher.service";
import { VOUCHER_DELIVERY_STATUS } from "../../constants/voucher";
import { Paths } from "../../constants/paths";
import { usePaymentStore } from "../../stores/booking";

export default function VoucherPaymentDeclined({
  voucher,
  titleFontSize = "18px",
  buttonStyle = {},
  containerWrapper = {},
  buttonWrapper = {},
  purchasesMutate = () => {},
}: {
  voucher: any;
  titleFontSize?: string;
  buttonStyle?: object;
  containerWrapper?: object;
  buttonWrapper?: object;
  purchasesMutate?: () => unknown;
}) {
  const { paymentMethod } = usePaymentStore();
  const buttonWidth = getValue(buttonStyle, "width", "") || "48%";
  const [enablePaymentModal, setEnablePaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const history = useHistory();
  const [declinedReason, setDeclinedReason] = useState("");

  const getPaymentFailedMessage = () => {
    return (
      declinedReason ||
      getValue(voucher, "reason", "") ||
      getValue(voucher, "voucherPurchaseLogs[0].text") ||
      ""
    );
  };

  const handleRetry = async () => {
    try {
      // check if payment method is selected
      if (!voucher?.paymentMethodId) {
        return setErrorMessage("Please select your payment method!");
      }

      setPaymentLoading(true);

      let paymentMethodId = voucher.paymentMethodId;
      if (paymentMethod?.id) {
        paymentMethodId = paymentMethod.id;
      }

      // make api call
      const purchase = await retryFailedVoucherPayment(voucher.id, {
        paymentMethodId: paymentMethodId,
      });

      if (purchase.deliveryStatus === VOUCHER_DELIVERY_STATUS.DECLINED) {
        setDeclinedReason(purchase.reason);
        setErrorMessage("The payment for the voucher purchase has failed.");
        return;
      }
      if (purchasesMutate) {
        purchasesMutate();
      }
      setSuccessMessage("The payment for the voucher purchase has been successful.");
      history.push(Paths.Purchases);
    } catch (error) {
      setErrorMessage("Sorry, please try again.");
    } finally {
      setPaymentLoading(false);
      setEnablePaymentModal(false);
    }
  };

  const paymentFailedMessage = getPaymentFailedMessage();

  return (
    <>
      <Box style={{ ...containerWrapper }}>
        <Box>
          <Box
            marginBottom={"8px"}
            fontFamily="Museo"
            fontWeight={700}
            fontSize={titleFontSize}
            color={Colors.NightBlue}
          >
            Payment declined
          </Box>
          <Box fontSize="14px" color={Colors.Dusk} fontFamily="Open Sans">
            {paymentFailedMessage}
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            // width: "100%",
            alignItems: "flex-start",
            ...buttonWrapper,
          }}
        >
          <Button
            type={ButtonType.outlined}
            size={ButtonSize.small}
            height={48}
            width={buttonWidth}
            colorTitle={Colors.Dusk}
            title="Manage payment"
            fontStyle={{
              fontFamily: "Museo",
              fontWeight: 700,
              fontSize: 14,
            }}
            style={{ borderRadius: 4 }}
            onClick={() => setEnablePaymentModal(true)}
          />
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            height={48}
            width={buttonWidth}
            colorTitle={Colors.White}
            title="Retry now"
            loading={paymentLoading}
            fontStyle={{
              fontFamily: "Museo",
              fontWeight: 700,
              fontSize: 14,
            }}
            style={{ borderRadius: 4 }}
            onClick={() => handleRetry()}
          />
        </Box>
      </Box>
      <PaymentModal
        booking={voucher}
        open={enablePaymentModal}
        onClose={() => setEnablePaymentModal(false)}
        settleDeclinedPaymentForVoucher={handleRetry}
        isVoucher={true}
      />
    </>
  );
}
