import { User } from "../../models";
import { get } from "../../api/client";
import { ENDPOINTS } from "../../constants/endpoints";
import { UNMIGRATED_USER_ID_LIST } from "../../constants/stripe";

export const fetchCountries = async () => get(ENDPOINTS.COUNTRIES_API);

export const getCountryRestrictions = async () => get(ENDPOINTS.COUNTRY_RESTRICTIONS);

export const checkAndGetUsersStripeCountry = (user: User | null, country: string) => {
  if (user && UNMIGRATED_USER_ID_LIST.includes(user.id)) {
    return "AU";
  }
  return user?.country ? user.country : country;
};
