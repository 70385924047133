import {
  ProDirectoryTherapist,
  TherapistPublicProfile,
  UserService,
  Service
} from "../models";

export function genericBioForTherapistPublicProfile(
  therapistPublicProfile: TherapistPublicProfile
) {
  const {
    firstName,
    gender,
    therapistprofile,
    userservices: services,
  } = therapistPublicProfile?.therapistData;

  const userServicesV2 = therapistPublicProfile.userServices;
  const specialityV2 = getTherapistSkillsV2(userServicesV2);

  const { rating, experience, totalCompletedJobs } = therapistprofile;

  // const specialtyV1 = getTherapistSkills(services);
  const specialty = specialityV2;
  const pronoun = gender === "male" ? "He" : "She";
  const years = experience === "3-5" ? 3 : 5;
  const generic = `${firstName} is a highly-skilled massage therapist with several years experience and a ${rating} Blys rating. You can be confident that you are in great hands (literally) with ${firstName}. ${specialty ? `${pronoun} specialises in ${specialty}.` : ''}`;
  const generic2 = `${firstName} is an experienced massage therapist${specialty ? ` specialising in ${specialty} techniques` : ''}. ${pronoun} has partnered with Blys to provide spa-like massage treatments to clients (like you)!`;
  const job50 = `${firstName} is an experienced massage therapist that has partnered with Blys to deliver high-quality spa-treatments to Australian homes. With a ${rating} rating and over ${totalCompletedJobs} completed jobs for Blys, you can be confident that you are in great hands (literally) with ${firstName}. ${specialty ? `${pronoun} specialises in ${specialty}.`: ''}`;
  const years3 = `${firstName} is a friendly and professional massage therapist with over ${years} years of experience. ${specialty ? `${pronoun} specialises in a range of techniques including ${specialty}.` : ''} ${firstName} has partnered with Blys to provide spa-like massage treatments to clients (like you).`;

  const ratingExist = rating !== null && rating > 0;

  if (totalCompletedJobs >= 50 && ratingExist) {
    return job50;
  } else if (experience === "3-5" || experience === "5+") {
    return years3;
  } else if (ratingExist && totalCompletedJobs > 0) {
    return generic;
  } else {
    return generic2;
  }
}

export function genericBioForProDirectoryTherapist(
  therapist: ProDirectoryTherapist
) {
  const { user, userservices: services } = therapist;
  const { firstName, gender, therapistprofile } = user;

  if (therapistprofile) {
    const { rating, experience, totalCompletedJobs } = therapistprofile;

    const specialty = getTherapistSkills(services);
    const pronoun = gender === "male" ? "He" : "She";
    const years = experience === "3-5" ? 3 : 5;
    const generic = `${firstName} is a highly-skilled massage therapist with several years experience and a ${rating} Blys rating. You can be confident that you are in great hands (literally) with ${firstName}. ${pronoun} specialises in ${specialty}.`;
    const generic2 = `${firstName} is an experienced massage therapist specialising in ${specialty} techniques. ${pronoun} has partnered with Blys to provide spa-like massage treatments to clients (like you)!`;
    const job50 = `${firstName} is an experienced massage therapist that has partnered with Blys to deliver high-quality spa-treatments to Australian homes. With a ${rating} rating and over ${totalCompletedJobs} completed jobs for Blys, you can be confident that you are in great hands (literally) with ${firstName}. ${pronoun} specialises in ${specialty}.`;
    const years3 = `${firstName} is a friendly and professional massage therapist with over ${years} years of experience. ${pronoun} specialises in a range of techniques including ${specialty}. ${firstName} has partnered with Blys to provide spa-like massage treatments to clients (like you).`;

    if (totalCompletedJobs >= 50 && rating !== null) {
      return job50;
    } else if (experience === "3-5" || experience === "5+") {
      return years3;
    } else if (rating !== null) {
      return generic;
    } else {
      return generic2;
    }
  }

  return "";
}

export function getTherapistSkills(services: Array<UserService>) {
  if (!services || services.length === 0) return "";
  if (services.length === 1) {
    const serviceName = services[0].service.alias || services[0].service.name;
    return massageTypeText(serviceName).toString().toLowerCase();
  }
  let serviceString = "";

  services.forEach((service, i) => {
    if (!service.service) {
      return;
    }
    
    const serviceName = service.service.alias || service.service.name;
    let type = massageTypeText(serviceName).toString().toLowerCase();

    if (i === services.length - 1) {
      serviceString += `and ${type}`;
    } else if (i === services.length - 2) {
      serviceString += `${type} `;
    } else {
      serviceString += `${type}, `;
    }
  });

  return serviceString;
}

export function getTherapistSkillsV2(services: Array<Service>) {
  if (!services || services.length === 0) return "";

  if (services.length === 1) {
    if (services[0].alias) {
      return services[0].alias.toLowerCase();
    } else {
      return massageTypeText(services[0].name).toString().toLowerCase();
    }
  }

  let serviceString = "";

  services.forEach((service, i) => {
    if (!service) {
      return;
    }

    let type;
    if (service.alias) type = service.alias.toLowerCase();
    else type = massageTypeText(service.name).toString().toLowerCase();

    if (i === services.length - 1) {
      serviceString += `and ${type}`;
    } else if (i === services.length - 2) {
      serviceString += `${type} `;
    } else {
      serviceString += `${type}, `;
    }
  });

  return serviceString;
}

export function massageTypeText(value: string) {
  switch (value) {
    case "swedish":
      return "Swedish";

    case "deeptissue":
      return "Deep Tissue";

    case "sports":
      return "Sports";

    case "pregnancy":
      return "Pregnancy";

    default:
      return value;
  }
}
