import { Switch } from "@material-ui/core";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Colors } from "../constants/colors";

export default withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 3,
      color: Colors.Grey,
      "&$checked": {
        transform: "translateX(14px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: Colors.TurquoiseBlue,
          borderColor: Colors.TurquoiseBlue,
        },
      },
    },
    thumb: {
      width: 10,
      height: 10,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${Colors.Grey}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
      height: 14,
    },
    checked: {},
  })
)(Switch);
