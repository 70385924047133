import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "../../v2/Styled";
import * as Styled from "../../v2/Styled/enum";
import { Paths } from "../../../constants/paths";
import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import Button, { ButtonType } from "../../Button";
import { useAlertStore } from "../../../stores/alert";
import queryClient from "../../../config/queryClient";
import { parseApiError } from "../../../helpers/error";
import { StripeConnectAccountStatus } from "../../../constants/stripe";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import { STRIPE_KEYS } from "../../../hooks/stripe/connect/stripeConnect.hooks";
import { getStripeAccount } from "../../../services/proDashboard/stripe.service";

interface Props {
  accountStatus?: {
    status: string;
    accountType: string;
  };
  isLoading?: boolean;
}

const StripeConnectCustomV2 = ({ accountStatus, isLoading }: Props): JSX.Element => {
  const [isInitiateLoading, setIsInitiateLoading] = useState<boolean>(false);

  const history = useHistory();

  const isMobile = useMobile();
  const { setErrorMessage } = useAlertStore();

  const status = getValue(accountStatus, "status");

  const handleSetupPayouts = async () => {
    try {
      setIsInitiateLoading(true);
      await getStripeAccount();
      await queryClient.invalidateQueries({ queryKey: [STRIPE_KEYS.ACCOUNT_STATUS] });
      history.push(Paths.ProDashboardBusinessPayoutInformation, { accountStatus });
    } catch (error) {
      const errMessage = parseApiError(error);
      const skipErrorMsgText = "confirmed by our staff";

      setErrorMessage(errMessage);
      if (!errMessage.includes(skipErrorMsgText)) {
        sendErrorLog({
          domain: `WEB ${isMobile ? "MOBILE" : ""}`,
          channel: LOG_CHANNEL.SLACK,
          log: {
            title: "WEB: Stripe connect issue",
            data: error.message,
          },
        });
      }
    } finally {
      setIsInitiateLoading(false);
    }
  };

  const getButtonText = () => {
    let buttonText;
    switch (status) {
    case StripeConnectAccountStatus.CONNECTED:
      buttonText = "Banking details";
      break;
    case StripeConnectAccountStatus.INCOMPLETE:
      buttonText = "Complete setup";
      break;
    default:
      buttonText = "Setup payouts";
      break;
    }
    return buttonText;
  };

  const handlePayoutInfoClicked = async () => {
    const isNotConnected = status === StripeConnectAccountStatus.NOT_CONNECTED;

    if (isNotConnected) {
      await handleSetupPayouts();
    } else {
      history.push(Paths.ProDashboardBusinessPayoutInformation, { accountStatus });
    }
  };

  const handleSeeAllPayoutsClicked = () => {
    history.push(`${Paths.ProDashboardReports}?tab=payouts`);
  };

  return (
    <Box direction={Styled.FlexDirection.Column} gap={Styled.Spacing.S4}>
      <Box
        gap={Styled.Spacing.S3}
        direction={isMobile ? Styled.FlexDirection.Column : Styled.FlexDirection.Row}
      >
        <Button
          width={"auto"}
          title={"See all payouts"}
          type={ButtonType.outlined}
          onClick={handleSeeAllPayoutsClicked}
          style={{
            minWidth: "208px",
          }}
        />

        <Button
          width={"auto"}
          title={getButtonText()}
          loading={isInitiateLoading || isLoading}
          onClick={handlePayoutInfoClicked}
          style={{
            minWidth: "208px",
          }}
        />
      </Box>
    </Box>
  );
};

export default StripeConnectCustomV2;
