import React, { useEffect, useState } from "react";
import { AnswerDetails, BookingQuestion } from "../../../models";
import { Box } from "@material-ui/core";
import { DEFAULT_CURRENCY } from "../../../constants/booking";
import Dropdown, { DropdownOption } from "../../Dropdown";

interface Props {
  question: BookingQuestion;
  onAnswerSelected: (questionId: number, answer: string, type: string) => unknown;
  answer?: string;
  showPrices?: boolean;
  currency: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const SelectQuestion = ({
  question,
  onAnswerSelected,
  answer,
  style,
  showPrices = false,
  currency = DEFAULT_CURRENCY.currencySymbol,
  disabled = false,
}: Props) => {
  const [answerOptions, setAnswerOptions] = useState<AnswerDetails[]>([]);
  const [selectedOption, setSelectedOption] = useState<AnswerDetails>();

  useEffect(() => {
    if (question && question.answerDetails) {
      setAnswerOptions([...question.answerDetails].map((answer: AnswerDetails) => answer));
    }
  }, [question]);

  useEffect(() => {
    setSelectedOption([...answerOptions].find(({ value }) => value === answer));
  }, [answer, answerOptions]);

  const handleSelected = (option: DropdownOption) => {
    onAnswerSelected(question.id, option.value, question.type);
  };

  return (
    <Box style={style}>
      <Dropdown
        isDisabled={disabled}
        title={question.question}
        description={question.description}
        options={answerOptions}
        selectedOption={selectedOption}
        onSelectedOption={handleSelected}
      />
    </Box>
  );
};

export default SelectQuestion;
