import React, { ReactElement } from "react";
import styles from "./styles";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import NewBookingProgressBar from "../NewBookingProgressBar";
import { useSelector } from "react-redux";
import { RootState } from "../../stores/V2";
import { getValue } from "../../utils/object";
// import PriceBreakDownModal from "../PriceBreakDownModal";

interface Props {
  height: number;
  progress: number;
  buttons?: ReactElement[];
}

const ProReviewFooter = ({ height, progress, buttons }: Props) => {
  const { booking: state } = useSelector((state: RootState) => state);
  const [showPriceBreakdownModal, setShowPriceBreakdownModal] = React.useState<boolean>(false);

  const togglePriceBreakdown = () => {
    setShowPriceBreakdownModal(!showPriceBreakdownModal);
  };

  const { selectedService, selectedServiceDuration, bookingPrice, bookingDetails, serviceFee } =
    state;
  const bookingCurrency = getValue(state, "selectedService.selectedCountry.currencySymbol", "$");
  const isMobile = useMobile();

  const TreatmentMinWidth = "200px";
  const totalServiceDuration = getValue(bookingDetails[0], "serviceDuration");

  return (
    <Box>
      <Box style={{ ...styles.wrapper, height: height }}>
        <Box width="100%" height="1px" bgcolor={Colors.LightPeriwinkle} />
        <Box
          style={{
            ...styles.footerWrapper,
            width: isMobile ? "100%" : "800px",
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <Box flexDirection="column" minWidth={TreatmentMinWidth} mt={isMobile ? 2 : undefined}>
            <Box display="flex" flexDirection="row" alignItems={"center"} gridGap={"8px"}>
              {selectedService.isMassage && (
                <Box fontFamily="Museo" fontWeight={700} color={Colors.BlueyGrey}>
                  {selectedServiceDuration.join("min + ")}min
                </Box>
              )}
              {!selectedService.isMassage ? (
                <Box fontFamily={"Museo"} fontWeight={700} fontSize={"18px"} color={Colors.Grey}>
                  {totalServiceDuration}mins
                </Box>
              ) : (
                <></>
              )}
              <Box
                style={{
                  width: "6px",
                  height: "6px",
                  backgroundColor: Colors.Dusk,
                  borderRadius: "50%",
                }}
              />
              <Box
                fontFamily="Museo"
                fontWeight={700}
                fontSize="18px"
                color={Colors.Dusk}
                onClick={togglePriceBreakdown}
                style={{
                  // textDecorationLine: "underline",
                  cursor: "pointer",
                }}
              >
                {bookingCurrency}
                {serviceFee?.toFixed(2)}
              </Box>
            </Box>
            <Box fontFamily="SF UI Text" fontWeight={500} fontSize="14px" color={Colors.BlueyGrey}>
              {state.title.split("|").pop()}
            </Box>
          </Box>

          {!isMobile && (
            <Box
              flex={1}
              marginLeft={isMobile ? undefined : "17px"}
              marginRight={isMobile ? undefined : "39px"}
            >
              <NewBookingProgressBar progress={progress} />
            </Box>
          )}

          <Box style={{ ...styles.buttonsWrapper, width: isMobile ? "90%" : "100%" }}>
            {buttons &&
              buttons.map((button, index) => {
                return (
                  <>
                    {index !== 0 && <Box width="18px"></Box>}
                    {button}
                  </>
                );
              })}
          </Box>
        </Box>
      </Box>
      {/* <PriceBreakDownModal
        open={showPriceBreakdownModal}
        onToggle={togglePriceBreakdown}
        isBookingConfirmed={true}
      /> */}
    </Box>
  );
};

export default ProReviewFooter;
