import { Box } from "@material-ui/core";
import CorporateTreatmentDetailItem from "../../../../components/ReviewAndBook/ReviewAndBookTreatmentDetail/CorporateTreatmentDetailItem";
import TreatmentDetailItems from "../../../../components/ReviewAndBook/ReviewAndBookTreatmentDetail/TreatmentDetailItems";
import TextLink from "../../../../components/TextLink";
import { isCorporateSession, isMassageForMassageType } from "../../../../helpers/massage";
import { Booking } from "../../../../models";
import {
  getFormattedQuestionAnswer,
  getServiceDurationFromDetails,
  useServicesStore,
} from "../../../../stores/booking";
import { checkIfEmpty, getValue } from "../../../../utils/object";

interface Props {
  icon: any;
  booking: Booking;
  onChangeClicked?: () => unknown;
  onTreatmentClicked?: (td: any) => unknown;
  onAddOnClicked: ({ td, addOnId }: { td: any; addOnId: any }) => unknown;
}

const BookingTreatmentDetail = ({
  booking,
  onChangeClicked = () => {},
  icon,
  onTreatmentClicked = () => {},
  onAddOnClicked = () => {},
}: Props) => {
  const { serviceId, bookingdetails, corporateInfo, sessionType, isHairAndMakeup, bookinganswers } =
    booking;

  const { questions } = useServicesStore();

  const genderPreference1 = bookingdetails[0]?.genderPreference;
  const genderPreference2 = bookingdetails[1]?.genderPreference;
  const isCorporateType = isCorporateSession(sessionType);

  const massageType = bookingdetails[0]?.massageType;
  const isMassage = isMassageForMassageType(massageType);

  const [length1, length2] = getServiceDurationFromDetails(sessionType, bookingdetails);

  const getCorporateDetails = (info: any) => {
    if (checkIfEmpty(info)) return {};

    const corporateDetail = {
      numberOfPerson: info.numberOfPerson,
      durationPerPerson: info.durationPerPerson,
      totalEventDuration: {
        massageDuration: info.totalEventDuration,
        hasPaidBreak: info.hasPaidBreak,
      },
      numberOfPros: info.numberOfPros,
      eventType: info.eventType,
      eventName: info.eventName,
      noteForRecipients: info.noteForRecipients,
      paymentSplit: info.paymentSplit,
      genderPreference: genderPreference1,
    };
    return corporateDetail;
  };

  const getCorporateTreatmentId = () => {
    const details = getValue(bookingdetails[0], "treatmentDetails[0]");
    if (!checkIfEmpty(details)) {
      const treatmentId = details.treatmentId || details.treatmentTypeId;
      return treatmentId;
    }
    return null;
  };

  const getParsedTreatmentDetails = () => {
    const parsedDetails: any[] = [];
    bookingdetails.forEach(({ treatmentDetails }: any) => parsedDetails.push(...treatmentDetails));
    return parsedDetails;
  };

  const getFormattedQuestionAnswers = () => {
    if (isCorporateType) return [];

    if (isMassage || isHairAndMakeup) {
      const treatmentDetails = getParsedTreatmentDetails();
      const formattedAnswer = treatmentDetails.map(({ bookingAnswers }) =>
        getFormattedQuestionAnswer(bookingAnswers, questions, serviceId)
      );
      return formattedAnswer;
    }

    // "OTHER" service category
    const formattedAnswers = getFormattedQuestionAnswer(bookinganswers, questions, serviceId);
    return [formattedAnswers];
  };

  const treatmentDetails = getParsedTreatmentDetails();
  const formattedAnswers = getFormattedQuestionAnswers();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems={"flex-start"}
      justifyContent={"space-between"}
    >
      <Box display="flex" flexDirection="row" alignItems={"flex-start"}>
        {icon && (
          <Box
            width="24px"
            height="24px"
            justifyContent="center"
            alignItems="center"
            display="flex"
            style={{
              transform: "translateY(-20%)",
            }}
          >
            <img src={icon} alt="icon" />
          </Box>
        )}
        <Box ml={2} mr={2}>
          {isCorporateType ? (
            <CorporateTreatmentDetailItem
              serviceId={serviceId}
              treatmentId={getCorporateTreatmentId()}
              corporateDetails={getCorporateDetails(corporateInfo)}
            />
          ) : (
            <TreatmentDetailItems
              serviceId={serviceId}
              sessionType={sessionType}
              massageLength={length1}
              massageLength2={length2}
              treatmentDetails={treatmentDetails}
              genderPreference1={genderPreference1}
              genderPreference2={genderPreference2}
              onTreatmentClicked={onTreatmentClicked}
              onAddOnClicked={onAddOnClicked}
              answers={formattedAnswers}
            />
          )}
        </Box>
      </Box>
      {onChangeClicked && (
        <Box mt={"2px"}>
          <TextLink title="Change" onClick={onChangeClicked} />
        </Box>
      )}
    </Box>
  );
};

export default BookingTreatmentDetail;
