import * as httpClient from "../../api/client";
export const addReview = async ({
  jobId,
  rating,
  review,
  images = [],
}: {
  jobId: number;
  rating: number;
  review: string;
  images: File[];
}) => {
  const data = new FormData();
  images.forEach((image: File) => {
    data.append("images", image);
  });
  data.append("rating", JSON.stringify(rating));
  data.append("review", review);
  return httpClient.post(`reviews/${jobId}`, data);
};

export const updateReview = async ({
  jobId,
  rating,
  review,
  images = [],
  removedImages = [],
}: {
  jobId: number;
  rating: number;
  review: string;
  images: File[];
  removedImages: Array<number>;
  imagesToUpload: File[];
}) => {
  const data = new FormData();

  images.forEach((image: File) => {
    data.append("images", image);
  });
  data.append("rating", JSON.stringify(rating));
  data.append("review", review);
  data.append("removedImages", JSON.stringify(removedImages));

  return httpClient.put(`reviews/${jobId}`, data);
};

export const createUserReview = ({
  jobId,
  reviewData,
}: {
  jobId: number | string
  reviewData: any,
}) => {
  const url = `api/v2/therapists/${jobId}/reviewClient`;
  return httpClient.post(url, reviewData);
};