import { Box } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { TreatmentFor } from "../../../constants/booking";
import { getInstructionFromPreferences } from "../../../helpers/user";
import { useQuestions } from "../../../hooks/question.hooks";
import { GenderPreferences, ServiceRatePrice } from "../../../models";
import {
  getGenderPreferences,
  getSelectedAddOnsForTreatment,
  getServiceCategoryProfession,
  getTreatmentAddOns,
  isHairColouringBooking,
  isMultipleAddonsAllowed,
  resetTreatmentAddOn,
  setTreatmentAddOn,
  useBookingStore,
} from "../../../stores/booking";
import { useUserStore } from "../../../stores/user";
import ServiceWrapper from "../../Layout/ServiceWrapper";
import TextField from "../../TextField";
import TreatmentDropdownMenu from "../../Treatment/DropdownMenu";
import NumberOfClients from "../MultiClient/NumberOfClients";
import TreatmentForSelector from "../MultiClient/TreatmentFor";
import Questions from "../Questions";
import { debounce } from "../../../utils/debounce";
import { SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST } from "../../../constants/common";
import AddOnDropdownMenu from "../V2/AddOn/DropdownMenu/AddOnDropdownMenu";
import { checkIfEmpty, getOnlyValuesFromObject } from "../../../utils/object";
import {
  checkIfGenderFallbackOptionsRequired,
  getTranformedGenderOptions,
} from "../../../helpers/gender";
import Dropdown from "../../Dropdown";
import GenderFallback from "../../../pages/NewBooking/GenderFallback";
import { useGetGenderPreference } from "../../../hooks/genderPreference.hook";
import { capitalizeFirstLetter } from "../../../helpers/string";

interface Props {
  treatments: Array<ServiceRatePrice>;
  onTreatmentChanged: (index: number, id: number) => unknown;
  onInfoModalChange: (key: any, value: boolean) => unknown;
}

const HairAndMakeupFlow = ({ treatments, onTreatmentChanged, onInfoModalChange }: Props) => {
  const [providerProfession, setProviderProfession] = useState<string>("provider");

  const {
    treatmentDetails,
    setTreatmentDetails,
    numOfRecipients,
    serviceId,
    updatingForBooking,
    currencySymbol,
    genderPreference,
    setGenderPreference,
    genderFallback,
    setGenderFallback,
  } = useBookingStore();

  const [treatmentNote, setTreatmentNote] = useState([""]);
  const [desiredColour, setDesiredColour] = useState([""]);
  const [currentColour, setCurrentColour] = useState([""]);

  const questions = useQuestions(serviceId as number, treatmentDetails);

  const genders = useGetGenderPreference();
  const genderPreferenceOptions = getTranformedGenderOptions(genders);

  const { showGenderFallBack, genderFallbackMessage } = checkIfGenderFallbackOptionsRequired(
    genderPreference || "",
  );

  const treatmentQuestions = questions.filter((question) => !!question.treatmentId);
  const serviceQuestions = questions.filter((question) => !question.treatmentId);

  const { userPreferences, fetchUserPreferences } = useUserStore();
  const [userServiceInstruction, setUserServiceInstruction] = useState("");

  useEffect(() => {
    if (!userPreferences) {
      fetchUserPreferences();
    }
  }, [userPreferences]);

  useEffect(() => {
    const profession = getServiceCategoryProfession();
    if (profession) {
      setProviderProfession(profession);
    }
  }, [serviceId]);

  useEffect(() => {
    const treatmentNotes = treatmentDetails.map((treatment) => treatment.noteForProvider);
    const desiredColourAnswers = treatmentDetails.map((treatment) => treatment.desiredColour);
    const currentColourAnswers = treatmentDetails.map((treatment) => treatment.currentColour);

    setTreatmentNote(treatmentNotes);
    setDesiredColour(desiredColourAnswers);
    setCurrentColour(currentColourAnswers);
  }, []);

  useEffect(() => {
    if (userPreferences && serviceId && !updatingForBooking) {
      const newTreatmentDetails = [...treatmentDetails];
      const serviceInstructionPreference = getInstructionFromPreferences(serviceId) || "";
      if (serviceInstructionPreference) {
        setUserServiceInstruction(serviceInstructionPreference);
        newTreatmentDetails.forEach((treatment) => {
          if (treatment.treatmentFor === TreatmentFor.myself) {
            treatment.noteForProvider = serviceInstructionPreference;
          }
        });
        setTreatmentDetails(newTreatmentDetails);
      }
    }
  }, [userPreferences, serviceId]);

  const handleTreatmentTextChange = (
    i: number,
    value: string,
    field: string,
    treatDetails: any,
  ) => {
    const newTreatmentDetails = [...treatDetails];
    newTreatmentDetails[i][field] = value;
    setTreatmentDetails(newTreatmentDetails);
  };

  const debouncedChangeHandler = useCallback(debounce(handleTreatmentTextChange, 500), []);

  const onTreatmentForChanged = (index: number, value: string) => {
    const newTreatmentDetails = [...treatmentDetails];
    newTreatmentDetails[index].noteForProvider =
      value === TreatmentFor.myself ? userServiceInstruction : "";
    setTreatmentDetails(newTreatmentDetails);
  };

  const onNoteChanged = (i: number, val: string, treatDetails: any) => {
    const newNote = [...treatmentNote];
    newNote[i] = val;
    setTreatmentNote(newNote);
    debouncedChangeHandler(i, val, "noteForProvider", treatDetails);
  };

  const onDesiredColourChanged = (i: number, val: string, treatDetails: any) => {
    const newDesiredColour = [...desiredColour];
    newDesiredColour[i] = val;
    setDesiredColour(newDesiredColour);
    debouncedChangeHandler(i, val, "desiredColour", treatDetails);
  };

  const onCurrentColourChanged = (i: number, val: string, treatDetails: any) => {
    const newCurrentColour = [...currentColour];
    newCurrentColour[i] = val;
    setCurrentColour(newCurrentColour);
    debouncedChangeHandler(i, val, "currentColour", treatDetails);
  };

  const handleAddOnSelect = ({
    treatment,
    treatmentIndex,
    isMultipleAddonsAllowed,
  }: {
    treatment: ServiceRatePrice;
    treatmentIndex: number;
    isMultipleAddonsAllowed: boolean;
  }) => {
    setTreatmentAddOn({
      treatment,
      treatmentIndex: treatmentIndex,
      isMultipleSelectAllow: isMultipleAddonsAllowed,
    });
  };

  const handleTreatmentChange = (index: number, treatment: ServiceRatePrice) => {
    onTreatmentChanged(index, treatment.id);
  };

  const handleNoneSelect = (treatmentIndex: number) => {
    resetTreatmentAddOn({ treatmentIndex });
  };

  React.useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const onGenderSelect = (option: GenderPreferences) => {
    setGenderPreference(option.value);
    setGenderFallback(false);
  };

  return (
    <ServiceWrapper>
      <Dropdown
        title={`${capitalizeFirstLetter(providerProfession)} gender`}
        options={genderPreferenceOptions}
        selectedOption={genderPreferenceOptions?.find(
          (data: any) => data.value === genderPreference,
        )}
        onSelectedOption={(option: any) => onGenderSelect(option)}
      />

      {showGenderFallBack && (
        <GenderFallback
          onInfoClick={(value) => onInfoModalChange("genderFallback", value)}
          onChange={setGenderFallback}
          genderFallback={genderFallback}
          genderFallbackMessage={genderFallbackMessage}
        />
      )}

      <NumberOfClients />

      {treatmentDetails.map((_, i) => (
        <React.Fragment key={`person-${i}`}>
          <Box width="60px" height="30px" />
          {numOfRecipients > 1 && (
            <TreatmentForSelector index={i} onChanged={onTreatmentForChanged} />
          )}

          <TreatmentDropdownMenu
            label="Treatment type"
            treatments={treatments}
            onSelect={(treatment) => handleTreatmentChange(i, treatment)}
            value={treatmentDetails[i].treatmentId}
          />

          {!checkIfEmpty(getTreatmentAddOns({ treatmentIndex: i })) && (
            <AddOnDropdownMenu
              label="Treatment add-ons"
              treatments={getTreatmentAddOns({ treatmentIndex: i })}
              onSelect={handleAddOnSelect}
              value={treatmentDetails[i].treatmentId}
              treatmentIndex={i}
              selectedAddOn={getSelectedAddOnsForTreatment({ treatmentIndex: i })}
              isMultipleAddonsAllowed={isMultipleAddonsAllowed({ treatmentIndex: i })}
              onNoneSelect={handleNoneSelect}
            />
          )}

          <Questions
            index={i}
            currency={currencySymbol}
            questions={treatmentQuestions}
            treatmentId={treatmentDetails[i].treatmentId}
          />

          {isHairColouringBooking() && (
            <TextField
              title="What colour is your hair currently?"
              onChange={(val) => onCurrentColourChanged(i, val, treatmentDetails)}
              value={currentColour[i] || ""}
              multiline
              maxLength={400}
            />
          )}

          {isHairColouringBooking() && (
            <TextField
              title="What colour would you like to have?"
              onChange={(val) => onDesiredColourChanged(i, val, treatmentDetails)}
              value={desiredColour[i] || ""}
              multiline
              maxLength={400}
            />
          )}

          <TextField
            title={`Note for ${providerProfession}`}
            placeholder={"Add any additional details about your treatment"}
            onChange={(val) => onNoteChanged(i, val, treatmentDetails)}
            value={treatmentNote[i] || ""}
            multiline
            maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
          />
        </React.Fragment>
      ))}

      <Questions questions={serviceQuestions} index={0} currency={currencySymbol} />
    </ServiceWrapper>
  );
};

export default HairAndMakeupFlow;
