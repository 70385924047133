import React, { useState } from "react";
import Dialog from "./Dialog";
import { useMobile } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import { Box, Button } from "@material-ui/core";
import { usePaymentStore } from "../stores/booking";
import { useDateTimeStore } from "../stores/booking";
import { getTotal, useBookingStore } from "../stores/booking";
import SummarySection from "./Bookings/SummarySection";
import TotalDueAccordion from "./Bookings/V2/TotalDueAccordion/TotalDueAccordion";
import PriceBreakdown from "../pages/Bookings/Components/OrderSummary/PriceBreakdown";
import { Spacing } from "./v2/Styled/enum";

interface Props {
  open: boolean;
  onToggle: (boolean: boolean) => unknown;
  actionName?: string;
  isBookingConfirmed?: boolean;
  setShowTotalDueUpToModal?: (boolean: boolean) => unknown;
}

const PriceBreakDownModal = ({
  open,
  onToggle,
  actionName = "Ok",
  isBookingConfirmed = false,
  setShowTotalDueUpToModal,
}: Props): JSX.Element => {
  const { currencySymbol } = useBookingStore();
  const { backupSelectedDay } = useDateTimeStore();
  const { paymentType, bookingPrice } = usePaymentStore();

  const isMobile = useMobile();

  const [hideModal, setHideModal] = useState<boolean>(false);

  const closePriceBreakDownModal = () => {
    onToggle(false);
  };

  return (
    <Dialog open={open} onClose={closePriceBreakDownModal}>
      <Box
        flexDirection={"column"}
        alignItems="center"
        width={isMobile ? "auto" : "406px"}
        maxWidth={isMobile ? "100%" : "438px"}
        paddingX={"16px"}
        style={{ display: hideModal ? "none" : "flex" }}
      >
        {isBookingConfirmed ? (
          <PriceBreakdown forDialog={true} />
        ) : (
          <Box paddingTop={Spacing.S8} paddingBottom={Spacing.S2}>
            <Box width={"100%"}>
              <SummarySection
                centerTitle
                handleRootModal={setHideModal}
                style={{ paddingLeft: "16px", paddingRight: "16px" }}
              />
            </Box>

            <Box marginLeft={Spacing.S3} marginRight={Spacing.S3} marginTop={Spacing.S3}>
              <TotalDueAccordion
                currency={currencySymbol}
                totalPrice={getTotal()}
                bookingStatus="new"
                hasBackup={!!backupSelectedDay}
                paymentType={paymentType}
                bookingPrice={bookingPrice}
                setShowTotalDueUpToModal={setShowTotalDueUpToModal}
                closePriceBreakDownModal={closePriceBreakDownModal}
                width="100%"
              />
            </Box>
          </Box>
        )}
        <Button
          onClick={() => onToggle(false)}
          style={{
            width: "100%",
            margin: "16px",
            padding: "12px",
            fontSize: "16px",
            fontWeight: "600",
            color: Colors.Dusk,
            textTransform: "none",
            border: `1px solid ${Colors.LightPeriwinkle}`,
          }}
        >
          {actionName}
        </Button>
      </Box>
    </Dialog>
  );
};

export default PriceBreakDownModal;
