import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import { TherapistUserReview } from "../../models";
import moment from "moment";
import { firstLetterCapitalized } from "../../helpers/string";
import { getFormatRespectToCountry } from "../../utils/date.util";
import { useUserStore } from "../../stores/user";
import { DEFAULT_DATE_FORMAT } from "../../constants/date";

const Title = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="14px"
      lineHeight="21px"
      fontWeight={600}
      color={Colors.BlueyGrey}
      {...props}
    />
  );
};

const Value = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      mt="4px"
      {...props}
    />
  );
};

interface Props {
  review: TherapistUserReview;
}

export default function ({ review }: Props) {
  const isMobile = useMobile();
  const { user } = useUserStore();

  const trimmedReviewText = review.review
    ?.split(/\n{2,}/g)
    ?.filter(Boolean)
    ?.join("\n\n");

  return (
    <Box
      bgcolor="white"
      borderRadius="8px"
      border="1px solid #DCDFE6"
      padding="24px"
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      mt="8px"
      mb="8px"
    >
      <Box display="flex" flexDirection="row" flex={1} style={{ minWidth: 400 }}>
        <Box display="flex" flexDirection="column" flex={1}>
          <Title>Job ID</Title>
          <Value>{review.jobId}</Value>
        </Box>

        <Box display="flex" flexDirection="column" ml="32px" flex={1}>
          <Title>Date</Title>
          <Value>{moment(review.createdAt).format(DEFAULT_DATE_FORMAT)}</Value>
        </Box>

        <Box display="flex" flexDirection="column" ml="32px" flex={1} minWidth="80px">
          <Title>Rating</Title>
          <Value>{review.rating}</Value>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        mt={isMobile ? "24px" : undefined}
        style={{ minWidth: 130 }}
        ml={!isMobile ? "32px" : undefined}
      >
        <Title>Recipient</Title>
        <Value>{`${review.user.firstName} ${firstLetterCapitalized(review.user.lastName)}`}</Value>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flex={3}
        mt={isMobile ? "24px" : undefined}
        ml={!isMobile ? "32px" : undefined}
      >
        <Title>Review</Title>
        <Value
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-all",
          }}
        >
          {trimmedReviewText}
        </Value>
      </Box>
    </Box>
  );
}
