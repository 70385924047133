import React from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import LandingWrapper from "../../components/LandingWrapper";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { useParams } from "react-router-dom";
import BookingDetailsOrderItem, {
  BookingDetailsOrderItemType,
} from "../../components/BookingDetails/BookingDetailsOrderItem";
import BookingDetailsSummaryItem, {
  BookingDetailsSummaryItemType,
} from "../../components/BookingDetails/BookingDetailsSummaryItem";
import LoginModal from "../../components/LoginModal";
import useSWR from "swr";
import { isEmpty } from "lodash";
import { Booking } from "../../models";
import { useUserStore } from "../../stores/user";
import ContinueWithAfterpayButton from "../../components/ContinueWithAfterpayButton";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import { loadAfterPayScript } from "../../helpers/script";
import { useLocation } from "react-router-dom";
import LogoLink from "../../components/LogoLink";
import AfterpayAccessDenied from "./AfterpayAccessDenied";
import { getAccessToken } from "../../helpers/accessToken";
import { BOOKING_STATUS } from "../../constants/booking";
import { getValue } from "../../utils/object";
import { afterpayRedirect } from "../../services/afterpay/afterpay.service";

interface Params {
  id: string;
  accessTokenParam?: string;
}

export default function AfterPayProcessOrder() {
  const isMobile = useMobile();
  const location = useLocation();
  const { id, accessTokenParam } = useParams<Params>();

  let accessToken =  getAccessToken() || accessTokenParam || null;

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  const {
    data: bookingData,
    mutate: bookingMutate,
    error,
  } = useSWR(
    !isEmpty(accessToken) ? `/bookings/${id}?accessToken=${accessToken}` : null
  );

  const booking = bookingData ? (bookingData as Booking) : null;
  const bookingCurrency = getValue(booking, "service.selectedCountry.currencySymbol", "$");

  const { fetchMe } = useUserStore();

  const [loading, setLoading] = React.useState(false);
  const [isMobileOrder, setIsMobileOrder] = React.useState(false);
  const [shouldAutoPay, setShouldAutoPay] = React.useState(false);

  const { setErrorMessage } = useAlertStore();

  const afterpayProcessing = (id : any, accessToken: any) => {
    afterpayRedirect(id, accessToken).then((response) =>{
      setLoading(false);
      const { token } = response;
      window.AfterPay.initialize({ countryCode: "AU" });
      window.AfterPay.redirect({ token });
    }).catch((err)=> {
      setLoading(false);
      setErrorMessage(parseApiError(err));
    });
  };

  React.useEffect(() => {
    if (error) {
      setErrorMessage(parseApiError(error));
    }
  }, [error]);

  React.useEffect(() => {
    if(!error &&  booking?.status == BOOKING_STATUS.ARRANGED && getValue(location, "state.autoPay", false) ) {
      setShouldAutoPay(true);
      afterpayProcessing(id, accessToken);
    }
  }, [error, booking, location]);

  React.useEffect(() => {
    fetchMe();    
    loadAfterPayScript();
    const query = new URLSearchParams(location.search);
    const isMobileOrder = query.get("isMobileOrder");
    
    if(isMobileOrder){
      const queryAccessToken = query.get("accessToken");
      const countryCode = query.get("countryCode");
      
      setIsMobileOrder(true);
      if (isEmpty(queryAccessToken)) {
        setLoginModalOpen(true);
        return;
      }
      afterpayProcessing(id, queryAccessToken);
    }
    if(!accessToken) {
      setLoginModalOpen(true);
    }
  }, []);

  // NOTE: /afterpay-error route uses this same page as well
  React.useEffect(() => {
    if (location.pathname.includes("afterpay-error")) {
      setErrorMessage(
        "Request to Afterpay could not be processed, please try again"
      );
    }
  }, [location]);
  
  if(shouldAutoPay) {
    return  <CircularProgress
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "45px",
        width: "100%",
      }}
    />;
  }
  return (
    <>
    {
      !error ? (
        !isMobileOrder ? (
      <>
      <LandingWrapper>
        <Box width={isMobile ? undefined : "400px"}>
          <LogoLink />
          <Typography
            variant="h1"
            style={{
              color: Colors.NightBlue,
              fontSize: "30px",
              marginTop: "40px",
              marginBottom: "16px",
            }}
          >
            Complete payment for your booking
          </Typography>

          {!error && !booking ? (
            <CircularProgress
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "45px",
                width: "100%",
              }}
            />
          ) : (
            <>
              {booking?.status === BOOKING_STATUS.ARRANGED && (
                <Typography variant="body1" style={{ fontSize: 18, color: "#41506F" }} >
                  After clicking the button below you will be redirected to
                  Afterpay website to complete your purchase
                </Typography>
              )}

              {booking?.status !== BOOKING_STATUS.ARRANGED && !error && (
                <Typography variant="body1" style={{ color: "red", fontSize: 18 }}>
                  Your booking is not confirmed yet. We will send you another
                  link once your booking is confirmed. Please follow that new
                  link to complete your Afterpay payment.
                </Typography>
              )}

              {booking && (
                <Box
                  width= {isMobile ? "auto" : "350px"}
                  borderRadius="6px"
                  border={`solid 1px ${Colors.PaleLilac}`}
                  bgcolor="white"
                  padding="25px"
                  mt={3}
                >
                  <BookingDetailsOrderItem
                    booking={booking}
                    type={BookingDetailsOrderItemType.massage}
                  />
                  <BookingDetailsOrderItem
                    booking={booking}
                    type={BookingDetailsOrderItemType.recipient}
                  />
                  <BookingDetailsOrderItem
                    booking={booking}
                    type={BookingDetailsOrderItemType.location}
                  />
                  <BookingDetailsOrderItem
                    booking={booking}
                    type={BookingDetailsOrderItemType.time}
                  />

                  <Box
                    fontFamily="Museo"
                    fontSize="20px"
                    fontWeight={700}
                    color={Colors.NightBlue}
                    letterSpacing="0.25px"
                    mt={5}
                  >
                    Summary
                  </Box>
                  <BookingDetailsSummaryItem
                    type={BookingDetailsSummaryItemType.massage}
                    booking={booking}
                  />
                  <BookingDetailsSummaryItem
                    type={BookingDetailsSummaryItemType.peakHourSurcharge}
                    booking={booking}
                  />
                  <BookingDetailsSummaryItem
                    type={BookingDetailsSummaryItemType.discount}
                    booking={booking}
                  />
                  <BookingDetailsSummaryItem
                    type={BookingDetailsSummaryItemType.coupon}
                    booking={booking}
                  />
                  <BookingDetailsSummaryItem
                    type={BookingDetailsSummaryItemType.credit}
                    booking={booking}
                  />
                  <BookingDetailsSummaryItem
                    type={BookingDetailsSummaryItemType.clientPlatformFee}
                    booking={booking}
                  />
                  <Box
                    fontFamily="Museo"
                    fontSize="16px"
                    fontWeight={700}
                    color={Colors.Dusk}
                    display="flex"
                    flexDirection="row"
                    bgcolor={Colors.PaleGrey}
                    height="54px"
                    alignItems="center"
                    borderRadius="6px"
                  >
                    <Box flex={1} ml="19px">
                      Total due
                    </Box>
                    <Box mr="19px">{bookingCurrency}{booking?.price.toFixed(2)}</Box>
                  </Box>
                </Box>
              )}
              {booking?.status === "arranged" && (
                <Box mt={2} mb={2}>
                  <ContinueWithAfterpayButton
                    loading={loading}
                    onClick={() =>
                     afterpayProcessing(id, accessToken)
                  }
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </LandingWrapper>
      <LoginModal
       open={loginModalOpen}
       onClose={() => setLoginModalOpen(false)}
       onSuccess={(accessToken) => {
         localStorage.setItem("token", accessToken);

         setLoginModalOpen(false);

         bookingMutate();
       }}
       subtitle="Please login to view this page"
     />
      </> 
        ) :
        <></>
      ) : (
        <AfterpayAccessDenied />
      )
    }
    </>
  );
}
