import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Each from "../../../Each";
import Divider from "../../../Divider";
import { Text } from "../../../v2/Styled";
import RadioButton from "../../../RadioButton";
import * as Styled from "../../../v2/Styled/enum";
import { Colors } from "../../../../constants/colors";
import FileIcon from "../../../../images/file-icon.svg";
import TextButton from "../../../TextButton/TextButton";
import { getLastElement } from "../../../../utils/array";
import AddGovernmentIDModal from "./AddGovernmentIDModal";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/date";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import CardWrapper from "../../../BookingDetails/BookingDetailCard";
import { convertToMoment, formatDate } from "../../../../utils/date.util";
import { useGovernmentDocuments } from "../../../../hooks/proDashboard/identity/identity.hooks";
import { IGovermentDoumentsResponse } from "../../../../pages/ProDashboard/Business/identity.interface";
import {
  GOVERNMENT_DOCUMENT_TYPE,
  GOVERNMENT_DOCUMENT_TYPE_LABEL,
  TIdentityVerification,
} from "../../../../pages/ProDashboard/Business/Payouts/Forms/model";

const COLUMNS = ["Document", "Document ID", "Document type", "Expiry date"];

interface Props {
  selectable?: boolean;
  allowUpload?: boolean;
  onlyRecentIds?: boolean;
  selectedId?: number | null;
  onUploadSuccess?: () => unknown;
  onSelect?: (document: TIdentityVerification) => unknown;
}

const ListGovernmentIdentity = ({
  onSelect,
  selectedId,
  selectable = false,
  allowUpload = false,
  onlyRecentIds = false,
  onUploadSuccess = () => {},
}: Props): JSX.Element => {
  const [showAddDocModal, setShowAddDocModal] = useState<boolean>(false);
  const [availableDocuments, setAvailableDocuments] = useState<IGovermentDoumentsResponse[]>([]);
  const { data: governmentDocumentData, isLoading: isDocumentLoading } = useGovernmentDocuments();
  const noDocument = checkIfEmpty(availableDocuments);

  useEffect(() => {
    if (isDocumentLoading) return;
    setLatestDocuments(governmentDocumentData);
  }, [governmentDocumentData, isDocumentLoading]);

  /**
   * Filter document based on type and uploaded date
   * @param data
   */
  const setLatestDocuments = (data: IGovermentDoumentsResponse[]) => {
    let availableDocs = data;

    if (onlyRecentIds) {
      availableDocs = Object.values(GOVERNMENT_DOCUMENT_TYPE)
        .map(
          (type) =>
            (data || [])
              .filter((doc: IGovermentDoumentsResponse) => doc.documentType === type)
              .sort((a, b) => convertToMoment(b.createdAt).diff(convertToMoment(a.createdAt)))[0]
        )
        .filter(Boolean);
    }

    setAvailableDocuments(availableDocs);
  };

  const handleOptionSelect = (document: TIdentityVerification) => {
    if (onSelect && typeof onSelect === "function") {
      onSelect(document);
    }
  };

  const handleUploadSuccess = () => {
    onUploadSuccess();
    setShowAddDocModal(false);
  };

  const buildHeader = (headerItem: string, idx: number) => (
    <Box flex={idx === 0 ? 2 : 1}>
      <Text
        color={Colors.Dusk}
        size={Styled.FontSize.F14}
        weight={Styled.FontWeight.Bold}
        font={Styled.FontFamily.OpenSans}
        lineHeight={Styled.LineHeight.L21}
      >
        {headerItem}
      </Text>
    </Box>
  );

  const buildRow = (item: IGovermentDoumentsResponse) => {
    const documentId = getValue(item, "id");
    const filePath = getValue(item, "details.files[0].filePath", "");
    const fileName = getLastElement(filePath.split("/")) || "";
    const documentNumber = getValue(item, "documentNumber", "");
    const documentType = getValue(item, "documentType", "");
    const documentTypeLabel = GOVERNMENT_DOCUMENT_TYPE_LABEL[documentType] || "";
    const expiry = getValue(item, "expiresAt");
    const expiresAtFormatted = formatDate({
      date: expiry,
      format: DEFAULT_DATE_FORMAT,
    });

    return (
      <React.Fragment>
        <Box
          flex={1}
          display={Styled.Display.Flex}
          gridGap={Styled.Spacing.S6}
          alignItems={Styled.AlignItems.center}
        >
          <Box
            display={Styled.Display.Flex}
            alignItems={Styled.AlignItems.center}
            overflow={"hidden"}
            width={"40%"}
          >
            {selectable ? (
              <RadioButton
                selected={documentId === selectedId}
                onSelected={() =>
                  handleOptionSelect({
                    documentId,
                    documentType,
                    documentNumber,
                    expiryDate: expiry,
                    documentPath: filePath,
                  } as TIdentityVerification)
                }
              />
            ) : (
              <></>
            )}
            <img
              src={FileIcon}
              alt={"File icon"}
              style={{ width: Styled.Spacing.S8, height: Styled.Spacing.S8 }}
            />
            <Text size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
              {fileName}
            </Text>
          </Box>
          <Box width={"20%"} overflow={"hidden"}>
            <Text size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
              {documentNumber}
            </Text>
          </Box>
          <Box width={"20%"} overflow={"hidden"}>
            <Text size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
              {documentTypeLabel}
            </Text>
          </Box>
          <Box width={"20%"} overflow={"hidden"}>
            <Text size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
              {expiresAtFormatted}
            </Text>
          </Box>
        </Box>
        <Divider />
      </React.Fragment>
    );
  };

  return (
    <Box
      gridGap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <Box
        gridGap={Styled.Spacing.S2}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
      >
        <Box>
          <Text
            color={Colors.NightBlue}
            size={Styled.FontSize.F18}
            font={Styled.FontFamily.Museo}
            weight={Styled.FontWeight.Bold}
            lineHeight={Styled.LineHeight.L27}
          >
            Your documents
          </Text>
        </Box>
        <Box>
          <Text
            color={Colors.Dusk}
            size={Styled.FontSize.F14}
            font={Styled.FontFamily.OpenSans}
            weight={Styled.FontWeight.Regular}
            lineHeight={Styled.LineHeight.L21}
          >
            PDF, JPG and PNG files accepted. We recommend using a scanning app when photographing
            documents.
          </Text>
        </Box>
      </Box>
      <CardWrapper style={{ width: "100%", margin: 0, padding: 0 }}>
        <Box margin={Styled.Spacing.S4}>
          {noDocument ? (
            <Box
              gridGap={Styled.Spacing.S4}
              display={Styled.Display.Flex}
              flexDirection={Styled.FlexDirection.Column}
            >
              <Text
                color={Colors.Grey}
                size={Styled.FontSize.F14}
                lineHeight={Styled.LineHeight.L21}
              >
                No documents uploaded
              </Text>
              <Divider />
            </Box>
          ) : (
            <Box
              flex={1}
              gridGap={Styled.Spacing.S6}
              display={Styled.Display.Flex}
              flexDirection={Styled.FlexDirection.Column}
            >
              <Box display={Styled.Display.Flex} gridGap={Styled.Spacing.S6}>
                <Each of={COLUMNS} render={buildHeader} />
              </Box>
              {isDocumentLoading ? (
                <Box display={Styled.Display.Flex} alignItems={"center"} justifyContent={"center"}>
                  <CircularProgress style={{ color: Colors.NightBlue }} thickness={4} size={24} />
                </Box>
              ) : (
                <Each of={availableDocuments} render={buildRow} />
              )}
            </Box>
          )}
          {allowUpload ? (
            <Box marginTop={Styled.Spacing.S5} marginBottom={Styled.Spacing.S2}>
              <TextButton
                type={"primary"}
                text={"Add document"}
                onClick={() => setShowAddDocModal(true)}
              />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </CardWrapper>
      <AddGovernmentIDModal
        open={showAddDocModal}
        onSuccess={handleUploadSuccess}
        onClose={() => setShowAddDocModal(false)}
      />
    </Box>
  );
};

export default ListGovernmentIdentity;
