import { CSSProperties, ReactNode } from "react";
import { Colors } from "../../../constants/colors";
import { Text } from "../../v2/Styled";
import { FontFamily, FontSize, FontWeight } from "../../v2/Styled/enum";

type Props = {
  children: ReactNode;
  style?: CSSProperties;
};

const TextPrimary = ({ children, style }: Props) => {
  return (
    <Text
      font={FontFamily.Museo}
      size={FontSize.F16}
      color={Colors.Dusk}
      weight={FontWeight.Medium}
    >
      {children}
    </Text>
  );
};

export default TextPrimary;
