import React from "react";
import { Box } from "@material-ui/core";
import FavHeroImage from "../images/fav-therapist-placeholder-icon.svg";
import BlockedHeroImage from "../images/blocked-therapist-placeholder-icon.svg";
import RecentlyBookedProImage from "../images/empty-state-recent.png";
import { Colors } from "../constants/colors";

export enum TherapistsPlaceholderType {
  favourite,
  blocked,
  recent,
}

interface Props {
  type: TherapistsPlaceholderType;
}

export default function ({ type }: Props) {
  const heroImage = () => {
    if (type === TherapistsPlaceholderType.favourite) {
      return FavHeroImage;
    }

    if (type === TherapistsPlaceholderType.blocked) {
      return BlockedHeroImage;
    }

    if (type === TherapistsPlaceholderType.recent) {
      return RecentlyBookedProImage;
    }

    return undefined;
  };

  const title = () => {
    if (type === TherapistsPlaceholderType.favourite) {
      return "Prioritise who you get";
    }

    if (type === TherapistsPlaceholderType.blocked) {
      return "Avoid bad experiences";
    }

    if (type === TherapistsPlaceholderType.recent) {
      return "Keep track of your providers";
    }

    return undefined;
  };

  const message = () => {
    if (type === TherapistsPlaceholderType.favourite) {
      return "Add & arrange your fav therapists to notify them first on bookings";
    }

    if (type === TherapistsPlaceholderType.blocked) {
      return "Had a bad experience? Block the therapist to never be paired again";
    }

    if (type === TherapistsPlaceholderType.recent) {
      return "View and manage your recently booked providers";
    }

    return undefined;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="300px">
      <img src={heroImage()} width={"61px"} height={"43px"} />
      <Box
        fontFamily="Museo"
        fontSize="20px"
        textAlign="center"
        fontWeight={700}
        color={Colors.Dusk}
        mt="19px"
      >
        {title()}
      </Box>

      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        color={Colors.Dusk}
        letterSpacing="0.39px"
        textAlign="center"
        mt="10px"
      >
        {message()}
      </Box>
    </Box>
  );
}
