import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LandingWrapper from "../components/LandingWrapper";
import { removeHtmlTags, udpateOutLookCalendarUrl } from "../helpers/calendar";

const Calendar = () => {
  const history = useHistory();
  // Get the URL parameter value for the "calendar" parameter
  const urlParams = new URLSearchParams(window.location.search);
  const outLookUrl = urlParams.get("outlook");
  const calendarDataUrl = urlParams.get("iCalData");

  useEffect(() => {
    if (calendarDataUrl) {
      // Create a link element
      const link = document.createElement("a");
      const updatedAppleCalendarLink = removeHtmlTags(calendarDataUrl);

      // Set the href attribute to the calendar data URL
      // @ts-ignore
      link.href = updatedAppleCalendarLink;

      // Set the download attribute to specify the file name
      link.setAttribute("download", "event.ics");

      // Programmatically trigger the download
      link.click();
      history.push("/");
    } else if (outLookUrl) {
      const udpatedOutLookUrl = udpateOutLookCalendarUrl(window.location.href || "");
      const deocedData = decodeURIComponent(udpatedOutLookUrl || "");
      const splitCode = deocedData.split("|");
      window.location.href = splitCode[1];
    } else {
      history.push("/");
    }
  }, []);

  return <></>;
};

export default Calendar;
