import platform from "platform";
import moment from "moment/moment";
import { User } from "../../models";
import { getValue } from "../../utils/object";

const getFeedbackURL = (user: User | null, subject = "") => {
  let urlSubject = "My%20feedback%20about%20Blys%20website";

  if (subject) {
    urlSubject = encodeURIComponent(subject);
  }

  let url =
    "mailto:hello@getblys.com?subject=PLACEHOLDERSUBJECT&body=%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%0D%0A%0D%0APlease%20do%20not%20remove%20the%20information%20below%2C%20we%20need%20them%20for%20troubleshooting%20purposes.%0D%0A%0D%0AApp%20Name%3A%20Blys%20web%20app%0D%0AOS%20Version%3A%20PLACEHOLDEROS%0D%0ABrowser%20Version%3A%20PLACEHOLDERBROWSER%0D%0ADate%2FTime%3A%20PLACEHOLDERDATE%0AUser%3A%20PLACEHOLDERUSERNAME%0AUser%20ID%3A%20PLACEHOLDERUSERID%0AUser%20email%3A%20PLACEHOLDEREMAIL%0AUser%20country%3A%20PLACEHOLDERCOUNTRY";

  const firstName = getValue(user, "firstName") || "";
  const lastName = getValue(user, "lastName") || "";
  const userId = getValue(user, "id") || "";
  const email = getValue(user, "email") || "";
  const countryFullName = getValue(user, "countryFullName") || "";

  url = url.replace("PLACEHOLDEROS", platform.os?.toString() || "");
  url = url.replace("PLACEHOLDERSUBJECT", urlSubject || "");
  url = url.replace("PLACEHOLDERBROWSER", `${platform.name} ${platform.version}`);
  url = url.replace("PLACEHOLDERDATE", moment().format("MMMM D, YYYY h:mm A"));
  url = url.replace("PLACEHOLDERUSERNAME", `${firstName} ${lastName}`);
  url = url.replace("PLACEHOLDERUSERID", `${userId}`);
  url = url.replace("PLACEHOLDEREMAIL", `${email}`);
  url = url.replace("PLACEHOLDERCOUNTRY", `${countryFullName}`);

  return url;
};

export { getFeedbackURL };
