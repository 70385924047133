import React from "react";
import {Colors} from "../../constants/colors";
import {Box} from "@material-ui/core";
import { BASE_UPLOADS_URL } from "../../constants/common";
import { useMobile } from "../../hooks/mobile";
import ImageGallery from "../ImageGallery/ImageGallery";

// @ts-ignore
const TherapistPortfolio = ({ firstName, files  }) => {
    const photos = files.map((file: any) => {
        return {
            src: `${BASE_UPLOADS_URL}/${file.filePath}`
        };
    });

    const portfolioImages = photos ? photos.map(({ src }: {src: String}) => src) : []; 
    return (<>
        <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="16px"
            color={Colors.Dusk}
            mt={4}
            mb={4}
        >
            { firstName || "Therapist"}
            's portfolio
        </Box>
        <ImageGallery images={portfolioImages} />
    </>);
};

export default TherapistPortfolio;
