import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import { Colors } from "../constants/colors";

export enum PopoverItemType {
  default,
  destructive,
}

interface Props {
  type?: PopoverItemType;
  title: string;
  onClick: () => unknown;
}

export default function PopoverItem({
  type = PopoverItemType.default,
  title,
  onClick,
}: Props): JSX.Element {
  return (
    <ButtonBase onClick={onClick}>
      <Box width="100%" height="50px" alignItems="center" display="flex">
        <Box
          marginLeft="17px"
          marginRight="13px"
          fontFamily="Open Sans"
          fontWeight={600}
          fontSize="15px"
          color={type === PopoverItemType.default ? Colors.Dusk : Colors.Tomato}
        >
          {title}
        </Box>
      </Box>
    </ButtonBase>
  );
}
