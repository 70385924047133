import React from "react";
import { Box } from "@material-ui/core";
import { BookingDetails } from "../../models";
import { JobStatus } from "../../constants/booking";
import { Colors } from "../../constants/colors";

interface Props {
  bookingDetail: BookingDetails;
}

export default function ({ bookingDetail }: Props) {
  const { status } = bookingDetail?.job;

  const progressWidth = () => {
    switch (status) {
      case JobStatus.finished:
      case JobStatus.reviewLeft:
        return "100%";

      case JobStatus.onway:
        return "80%";

      case JobStatus.accepted:
        return "40%";

      case JobStatus.pending:
      case JobStatus.rebooking:
      default:
        return "5%";
    }
  };

  return (
    <Box
      height="2px"
      borderRadius="3.5px"
      bgcolor={Colors.PaleLilac}
      width="100%"
      position="relative"
    >
      <Box
        width={progressWidth()}
        height="2px"
        borderRadius="3.5px"
        left={0}
        position="absolute"
        bgcolor={Colors.Dusk}
      />
    </Box>
  );
}
