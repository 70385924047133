import { Typography, Box } from "@material-ui/core";
import Button, { ButtonType } from "../../../Button";
import ContentModal from "../../../Modals/ContentModal/contentModal";

import DeleteIcon from "../../../../images/cross-circle.png";
import styles from "./styles";

interface DeleteSuccessProps {
  visible: boolean;
  onClose: () => unknown;
  onContinue: () => unknown;
}

const DeleteSuccessModal = ({ visible, onClose, onContinue }: DeleteSuccessProps) => {
  return <ContentModal
    visible={visible}
    onClose={onClose}
    divider={false}
    actions={[
      <Button
        onClick={onContinue}
        title="Sweet, thanks"
        type={ButtonType.outlined}
      />
    ]}
  >
    <Box sx={{ ...styles.wrapper }}>
      <img style={{ ...styles.icon }} src={DeleteIcon} alt="Delete account"/>
      <Typography> <Box sx={{ ...styles.title }}>Delete account </Box></Typography>
      <Typography>
        <Box sx={{ ...styles.description }}>
          Your profile and account will be deleted in 48 hours unless you have an outstanding amount or an open complaint ticket.
        </Box>
      </Typography>
    </Box>
  </ContentModal>;
};

export default DeleteSuccessModal;
