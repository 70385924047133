const ADMIN_ACCESS_TOKEN = "ADMIN_ACCESS_TOKEN";

const getAccessToken = () => {
  return localStorage.getItem("token");
};

const setAccessToken = (token: string) => {
  return localStorage.setItem("token", token);
};

const setAdminAccessToken = (token: string | null) => {
  return localStorage.setItem(ADMIN_ACCESS_TOKEN, token || "");
};

const getAdminAccessToken = (): string | null => {
  return localStorage.getItem(ADMIN_ACCESS_TOKEN);
};

const removeAdminAccessToken = () => {
  return localStorage.removeItem(ADMIN_ACCESS_TOKEN);
};

export {
  getAccessToken,
  setAccessToken,
  setAdminAccessToken,
  getAdminAccessToken,
  removeAdminAccessToken,
};
