import { useQuery } from "@tanstack/react-query";
import * as httpClient from "../api/client";
import { transformPagination } from "../helpers/pagination";

export const BILLINGS_INVOICE_KEYS = {
  billingSummary: "billing-summary",
  dueBillingSummary: "due-billing-invoice-summary",
};

export const useGetAllBillingInvoices = ({
  currentPage,
  perPage,
  search,
}: {
  currentPage: number;
  perPage: number;
  search: object;
}) =>
  useQuery(
    ["billing-summary", search, currentPage],
    async () =>
      httpClient.get(
        `api/v2/billings/list-invoice/?page=${currentPage}&limit=${perPage}&search=${JSON.stringify(
          search
        )}`
      ),
    {
      staleTime: 10000,
      select: (res) => transformPagination(res || {}),
    }
  );

export const useGetAllDueBillingInvoiceSummary = (recipientId?: number) =>
  useQuery(
    [`due-billing-invoice-summary-${recipientId}`],
    async () =>
      httpClient.get(
        `api/v2/billings/due-invoice-summary${recipientId ? `?recipientId=${recipientId}` : ""}`
      ),
    {
      staleTime: 10000,
    }
  );
