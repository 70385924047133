import { configureStore } from "@reduxjs/toolkit";

import bookingReducer from "./booking/booking";
import documentReducer from "./documents/addDocuments";
import corporateBookingReducer from "./corporate/booking";

export const store = configureStore({
  reducer: {
    booking: bookingReducer,
    coroprateBooking: corporateBookingReducer,
    documents: documentReducer,
  },
  middleware: (defaultMiddleware) =>
    defaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
