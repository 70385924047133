import useSWR from "swr";
import React from "react";
import { Box } from "@material-ui/core";
import {UploadedFile} from "../../models";
import { Colors } from "../../constants/colors";
import {useUserStore} from "../../stores/user";
import {useAccessToken} from "../../hooks/common";
import {useAlertStore} from "../../stores/alert";
import {parseApiError} from "../../helpers/error";
import { DEFAULT_COUNTRY } from "../../utils/country";
import Button, {ButtonType} from "../../components/Button";
import {ProFileOwnership} from "../../constants/prodashboard";
import UploadedFileRow from "../../components/ProDashboard/UploadedFileRow";
import * as therapistService from "../../services/therapist/therapist.service";

const Portfolio = () => {
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const portfolioUploadRef = React.useRef<HTMLInputElement>(null);

  const accessToken = useAccessToken();
  const { user } = useUserStore();
  const beautyProfession = therapistService.getBeautyProfessionFromCountry(user?.country || DEFAULT_COUNTRY);

  const { data: uploadedFilesData, mutate: uploadedFilesMutate } = useSWR(
    `/therapists/me/files?accessToken=${accessToken}`
  );

  const uploadedFiles = uploadedFilesData
    ? (uploadedFilesData as UploadedFile[]).filter(
      (file) => file.type === ProFileOwnership.portfolio
    )
    : [];

  const onFileInputChange = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event?.target?.files && event.target.files[0];

    if (file) {
      const form = new FormData();
      form.append("image", file);

      if(user) {
        therapistService
          .uploadFile(user.id, accessToken, form)
          .then(() => {
            uploadedFilesMutate();
            setSuccessMessage("This file has been uploaded");
          })
          .catch(error => {
            setErrorMessage(parseApiError(error));
          });
      }
    }
  };

  const onFileRemove = (file: any) => {
    if(user) {
      therapistService
        .removeFile(file.id, user.id, accessToken)
        .then((response) => {
          uploadedFilesMutate();

          setSuccessMessage("This file has been deleted");
        })
        .catch((error) => {
          setErrorMessage(parseApiError(error));
        });
    }
  };

  const disableFileAddition = uploadedFiles.length > 19;

  return (
    <Box padding={"21px 0"}>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="14px"
        lineHeight="21px"
        color={Colors.Dusk}
      >
                My Portfolio
      </Box>
      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        fontWeight={400}
        lineHeight="21px"
        color={Colors.Dusk}
        mt={1}
        mb={2}
      >
        {`If you’re a ${beautyProfession}, hair and makeup artist or hairdresser upload images of your work to showcase your talents.`}
      </Box>
      {uploadedFiles.map((uploadedFile: any) => (
        <UploadedFileRow
          file={uploadedFile}
          onRemove={() => {onFileRemove(uploadedFile);}}
        />
      ))}
      <Box mt="37px">
        <Button
          title="Upload Image"
          type={ButtonType.outlined}
          width="132px"
          disabled={disableFileAddition}
          onClick={() => {
            if (portfolioUploadRef.current) {
              portfolioUploadRef.current.click();
            }
          }}
        />
      </Box>
      <input
        type="file"
        id="portfolio-upload-file"
        ref={portfolioUploadRef}
        style={{ display: "none" }}
        onChange={(event) => {onFileInputChange(event);}}
      />
    </Box>
  );
};

export default Portfolio;
