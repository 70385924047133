import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../v2/Styled/enum";
import { DetailsModal } from "../../Modals/DetailsModal/DetailsModal";
import { InfoSection } from "./PayoutInfoCard/InfoSection";
import transformPayoutInfoDetails from "./PayoutInfoCard/payoutInfo.transformer";
import { ViewDetailsBtn } from "./PayoutInfoCard/ViewDetailsBtn";
import { useMobile } from "../../../hooks/mobile";

interface Props {
  accountBalance: number;
  currencySymbol: string;
  breakDown: any[];
}

const getBalanceText = (balance: number, currencySymbol: string) => {
  let balancePrefix = `${currencySymbol}`;
  if (balance < 0) {
    balancePrefix = `-${currencySymbol}`;
  }
  return `${balancePrefix}${Math.abs(balance).toFixed(2)}`;
};

export const AccountBalance = ({ accountBalance, currencySymbol, breakDown }: Props) => {
  const isNegativeBalance = accountBalance < 0;
  const balanceText = getBalanceText(+accountBalance, currencySymbol);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const isMobile = useMobile();
  const transformedDetails = transformPayoutInfoDetails(breakDown);

  return (
    <Box
      bgcolor={isNegativeBalance ? Colors.Red : Colors.Peppermint}
      style={{
        borderRadius: "8px",
      }}
      padding={Spacing.S6}
    >
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        justifyContent={JustifyContent.spaceBetween}
        fontFamily={FontFamily.Museo}
        color={Colors.Dusk}
        fontWeight={FontWeight.Bold}
      >
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{ gap: Spacing.S1 }}
        >
          <Box>Account balance</Box>
          <Box>
            <ViewDetailsBtn
              onClick={() => {
                setShowInfoModal(true);
              }}
            />
          </Box>
        </Box>

        <Box>{balanceText}</Box>
      </Box>
      <DetailsModal
        visible={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}
        fullScreen={isMobile}
        headerComponent={<InfoSection value={balanceText} title={"Account balance"} />}
        headerStyle={{
          backgroundColor: isNegativeBalance ? Colors.Red : Colors.Peppermint,
        }}
      >
        <Box
          style={{
            gap: Spacing.S4,
            display: Display.Flex,
            flexDirection: FlexDirection.Column,
          }}
        >
          {(transformedDetails || []).map(({ label, description, value }) => {
            return <InfoSection value={value} description={description} title={label} />;
          })}
        </Box>
      </DetailsModal>
    </Box>
  );
};
