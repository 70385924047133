import React from "react";
import { Box } from "@material-ui/core";
import SSNField from "./SSNField";
import { Text } from "../v2/Styled";
import * as Styled from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";

interface Props {
  title?: string;
  subtitle?: string;
  value: string;
  onChange: (val: any) => unknown;
  readonly?: boolean;
}

const SSNInputField = ({ title, subtitle, value, onChange, readonly }: Props): JSX.Element => {
  return (
    <Box
      gridGap={Styled.Spacing.S2}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      {title && (
        <Box>
          <Text
            color={Colors.Dusk}
            size={Styled.FontSize.F14}
            font={Styled.FontFamily.Museo}
            weight={Styled.FontWeight.Bold}
            lineHeight={Styled.LineHeight.L21}
          >
            {title}
          </Text>
        </Box>
      )}
      {subtitle && (
        <Box>
          <Text color={Colors.Dusk} size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
            {subtitle}
          </Text>
        </Box>
      )}
      <SSNField value={value} onChange={onChange} readonly={readonly} />
    </Box>
  );
};

export default SSNInputField;
