import React, { useState } from "react";
// import Dialog from "../Dialog";
import { Button } from "@mui/material";
import { Dialog, Input, makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "../Divider";
import CButton, { ButtonType } from "../Button";
import { useUnsubscribeFromAll } from "../../hooks/communicationPreferences/communication-preference.hook";
import { useAlertStore } from "../../stores/alert";
import { useMobile } from "../../hooks/mobile";
interface UnsubscribeModalProps {
  open: boolean;
  onClose: () => void;
  proDash?: boolean;
}

const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({ open, onClose, proDash = false }) => {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { mutate, isLoading } = useUnsubscribeFromAll({
    onSuccess: (data: any) => setSuccessMessage(data.message),
    onError: () => setErrorMessage("Unable to unsubscribe from notifications. Please try again."),
    isPro: proDash,
  });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSubmit = () => {
    if (!message) {
      setErrorMessage("Reason is required.");
      return;
    }
    mutate({ reason: message });
    onClose();
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Button>
            {" "}
            <CloseIcon className={classes.icon} onClick={onClose} />
          </Button>
          <p className={classes.headerText}>Unsubscribe from all</p>
          <div />
        </div>
        <Divider />
        <div className={classes.body}>
          <div className={classes.bodyText1}>
            Can you please share to us why you would like to unsubscribe communication preferences
            from all? If something slipped through our fingers, we would be grateful to be aware of
            it and fix it.
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <p className={classes.bodyText2}>Why do you want to unsubscribe?</p>
            <Input value={message} onChange={handleChange} />
            <p className={classes.bodyText3}>
              You will stop receiving updates or notifications from us. However, you can always
              manage your communication preferences if you wish to receive specific updates.
            </p>
          </div>
        </div>
        <Divider />
        <div style={{ padding: "24px" }}>
          <CButton
            onClick={handleSubmit}
            disabled={isLoading}
            type={ButtonType.secondary}
            title="Continue"
          ></CButton>
        </div>
      </div>
    </Dialog>
  );
};
const useStyles = () => {
  const isMobile = useMobile();

  return makeStyles({
    container: {
      // padding: "16px",
      // width: "100%",
      maxWidth: "524px",
      boxShadow: "boxShadow: '0px 15px 15px 0px #0000004D'",
      borderRadius: "8px",
    },
    icon: {
      color: "#301E79",
      padding: isMobile ? "5px" : "12px",
      cursor: "pointer",
      "&:hover": {
        opacity: "0.8",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      gap: isMobile ? "5px" : "15px",
      justifyContent: "space-between",
      color: "#100249",
      padding: isMobile ? "8px 40px 0px 8px" : "8px 64px 0px 8px",
    },
    headerText: {
      fontFamily: "Museo",
      fontSize: isMobile ? "16px" : "24px",
      fontWeight: 700,
      lineHeight: "26.4px",
      textAlign: "center",
      // width: "100%",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "32px 24px 32px 24px",
    },
    bodyText1: {
      fontFamily: "Museo",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      textAlign: "left",
      color: "#8B96AD",
    },
    bodyText2: {
      fontFamily: "Museo",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "21px",
      textAlign: "left",
      color: "#41506F",
    },
    bodyText3: {
      fontFamily: "Museo",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "18px",
      textAlign: "left",
      color: "#BEBEC8",
    },
  })();
};

export default UnsubscribeModal;
