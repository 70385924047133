import { Box } from "@material-ui/core";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { Colors } from "../../../../constants/colors";
import Button, { ButtonType } from "../../../../components/Button";
import DirectionMap from "./DirectionMap";

import { useUserStore } from "../../../../stores/user";
import { getValue } from "../../../../utils/object";
import { BOOKING_STATUS } from "../../../../constants/booking";
interface Props {
  openMapModal: boolean;
  onCloseModal: () => void;
  massageDescription: string;
  clientName: string;
  clientImage: string;
  bookingPosition: { lat: number; lng: number };
  booking: any;
}

const MapModal = ({
  booking,
  openMapModal,
  onCloseModal,
  massageDescription,
  clientName,
  clientImage,
  bookingPosition,
}: Props) => {
  const { user } = useUserStore();
  const therapistLongitude = getValue(user, "therapistprofile.longitude");
  const therapistLatitde = getValue(user, "therapistprofile.latitude");
  const isBookingConfirmed = getValue(booking, "status", "") === BOOKING_STATUS.ARRANGED;

  return (
    <ContentModal
      visible={openMapModal}
      divider={false}
      onClose={() => {
        onCloseModal();
      }}
      PaperProps={{ style: { minWidth: "30vw" } }}
      actions={[
        <Button
          title="Ok"
          type={ButtonType.outlined}
          onClick={() => {
            onCloseModal();
          }}
        />,
      ]}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box width="40px" height="40px" borderRadius="50%" overflow="hidden">
          <img src={clientImage} width="100%" height="100%" style={{ objectFit: "cover" }} />
        </Box>
        <Box
          fontFamily="Museo"
          color={Colors.Indigo}
          fontWeight={600}
          fontSize="22px"
          lineHeight="24px"
          marginTop="16px"
        >
          {massageDescription}
        </Box>
        <Box
          fontFamily="Museo"
          color={Colors.Grey}
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          textAlign="center"
          marginTop="16px"
        ></Box>
        <Box bgcolor={Colors.White} width="100%" height="55vh">
          <DirectionMap
            id="direction-map"
            isBookingConfirmed={isBookingConfirmed}
            isMarkerRequired={false}
            latitude={{
              bookingLat: bookingPosition.lat,
              therapistLat: therapistLatitde,
            }}
            longitude={{
              bookingLng: bookingPosition.lng,
              therapistLng: therapistLongitude,
            }}
          />
        </Box>
      </Box>
    </ContentModal>
  );
};
export default MapModal;
