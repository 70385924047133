import { getValue } from "../../utils/object";
import { getUrlsFromText, whiteListedUrlsForChat } from "../../utils/url";
import {
  blockedKeywordsForChat,
  TRICKY_NUMBER_PATTERN_AU,
  TRICKY_NUMBER_PATTERN_US,
} from "./personaIdentifiableConfig";

const wordToDigitMap = {
  zero: "0",
  one: "1",
  two: "2",
  three: "3",
  four: "4",
  five: "5",
  six: "6",
  seven: "7",
  eight: "8",
  nine: "9",
};
// const extractPhoneNumber = (text: string): string => {
//   return text.replace(/\D+/g, "");
// };
const extractPhoneNumber = (text: string) => {
  const transformedText = text;

  // Split text into words and digit sequences while preserving non-digit characters
  let digitSegments = [];
  let nonDigitSegments = [];

  // Find digit and non-digit sequences
  let currentDigits = "";
  let currentNonDigits = "";

  for (let i = 0; i < transformedText.length; i++) {
    if (/\d/.test(transformedText[i])) {
      // If character is a digit, append to digit sequence
      if (currentNonDigits) {
        nonDigitSegments.push(currentNonDigits); // Save non-digit segment
        currentNonDigits = ""; // Reset non-digit sequence
      }
      currentDigits += transformedText[i];
    } else {
      // If character is non-digit, append to non-digit sequence
      if (currentDigits) {
        digitSegments.push(currentDigits); // Save digit segment
        currentDigits = ""; // Reset digit sequence
      }
      currentNonDigits += transformedText[i];
    }
  }

  // Handle any trailing segments
  if (currentDigits) digitSegments.push(currentDigits);
  if (currentNonDigits) nonDigitSegments.push(currentNonDigits);

  // Now process each gap between digit segments
  let finalText = digitSegments[0] || "";

  for (let i = 1; i < digitSegments.length; i++) {
    const gap = nonDigitSegments[i - 1] || ""; // Gap between current and previous digit segment

    if (gap.length < 10) {
      // If the gap is less than 10 characters, replace it with an empty string
      finalText += digitSegments[i];
    } else {
      // Otherwise, keep the gap intact
      finalText += gap + digitSegments[i];
    }
  }
  return finalText;
};

const convertWordsToDigits = (text: string): string => {
  return text
    .split(/\s+/)
    .map((word) => word.toLowerCase())
    .map((word) => getValue(wordToDigitMap, word) || word)
    .join(" ");
};

const hasPhoneNumber = (text: string, textFromJoinedMessages?: boolean) => {
  const textWithoutUrl = removeUrlsFromText(text);
  let transformedText = convertWordsToDigits(textWithoutUrl).replace(/[ .\-()]/g, "");
  if (!textFromJoinedMessages) {
    transformedText = extractPhoneNumber(transformedText);
  }
  return /(\(?\+?[0-9]{1,3}\)?[- ]?)?((\(?[0-9]{3,4}\)?|[0-9]{3,4})[- ]?)?([0-9]{3,4}[- ]?[0-9]{3,4}[- ]?[0-9]{3,4}|\b[A-Z0-9]{9,}\b)/.test(
    transformedText
  );
};

const hasEmailAddress = (text: string) => {
  return /([a-z0-9_\-.+]+)@\w+(\.\w+)*/gi.test(text);
};

const hasBlockedKeywords = (text: string, wordsToCheck: string[]) => {
  const regex = new RegExp(
    wordsToCheck.map((phrase) => phrase.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")).join("|"),
    "gi"
  );
  return regex.test(text);
};

const hasCashKeyword = (text: string) => {
  return hasBlockedKeywords(text, ["cash"]);
};

const hasPrivateBookingPhrases = (text: string) => {
  return hasBlockedKeywords(text, [
    "book privately",
    "booking privately",
    "private book",
    "private booking",
    "privately book",
  ]);
};

const isWhitelisted = (url: string) => {
  return whiteListedUrlsForChat.some((pattern) => pattern.test(url));
};

const removeWords = (text: string, words: string[]) => {
  const pattern = new RegExp(words.join("|"), "gi");
  return text.replace(pattern, "");
};

const removeUrlsFromText = (text: string) => {
  const urls = getUrlsFromText(text);
  const escapedUrls = urls.map((url) => url.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
  return removeWords(text, escapedUrls);
};

const hasBannedUrls = (text: string) => {
  const urls = getUrlsFromText(text);
  return urls.some((url) => !isWhitelisted(url));
};

const hasTrickyPhoneNumberAU = (text: string) => {
  const str = text.toLowerCase().replaceAll(" ", "");

  return TRICKY_NUMBER_PATTERN_AU.some((txt) => str.startsWith(txt));
};

const hasTrickyPhoneNumberUS = (text: string) => {
  const str = text.toLowerCase().replaceAll(" ", "");

  return TRICKY_NUMBER_PATTERN_US.some((txt) => str.startsWith(txt));
};

const hasTrickyPhoneNumber = (text: string) => {
  return hasTrickyPhoneNumberAU(text) || hasTrickyPhoneNumberUS(text);
};

const hasPersonalInformation = (text: string, textFromJoinedMessages?: boolean) => {
  if (hasBannedUrls(text)) {
    return true;
  }

  const textWithoutUrl = removeUrlsFromText(text);

  return (
    hasPhoneNumber(textWithoutUrl, textFromJoinedMessages) ||
    hasEmailAddress(textWithoutUrl) ||
    hasBlockedKeywords(textWithoutUrl, blockedKeywordsForChat) ||
    hasTrickyPhoneNumber(textWithoutUrl)
  );
};

export {
  hasPersonalInformation,
  hasPhoneNumber,
  hasTrickyPhoneNumberAU,
  hasBannedUrls,
  hasCashKeyword,
  hasPrivateBookingPhrases,
};
