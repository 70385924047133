import { COUNTRY } from "../../constants/countries";

const getRoutingNumberLabelForCountry = (country = COUNTRY.AU) => {
  switch (country) {
  case COUNTRY.US:
    return "Routing number";
  case COUNTRY.GB:
    return "Sort code";
  case COUNTRY.AU:
  case COUNTRY.NZ:
  default:
    return "BSB";
  }
};

export { getRoutingNumberLabelForCountry };
