import { Box } from "@material-ui/core";
import {
  getSelectedAddOnsForTreatment,
  usePaymentStore,
  useServicesStore,
} from "../../../stores/booking";
import { useEffect, useState } from "react";
import { ServiceRate } from "../../../models";
import { Colors } from "../../../constants/colors";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { DEFAULT_CURRENCY, DEFAULT_PROFESSION, GenderPreference } from "../../../constants/booking";
import { capitalizeFirstLetter } from "../../../helpers/string";
import TreatmentDetailItem from "./TreatmentDetailItem";
import { getServiceLabel } from "../../../helpers/booking";

interface Props {
  serviceId: any;
  sessionType: any;
  answers: any[];
  treatmentDetails: any[];
  massageLength: number | null;
  massageLength2: number | null;
  genderPreference1: string | null;
  genderPreference2: string | null;
  onTreatmentClicked: (td: any) => unknown;
  onAddOnClicked: ({ td, addOnId }: { td: any; addOnId: any }) => unknown;
  addOn?: any;
}

const TreatmentDetailItems = ({
  answers,
  serviceId,
  sessionType,
  massageLength,
  massageLength2,
  treatmentDetails,
  genderPreference1,
  genderPreference2,
  onTreatmentClicked = () => {},
  onAddOnClicked = () => {},
}: Props) => {
  const { rates } = useServicesStore();
  const { bookingPrice } = usePaymentStore();

  const [label, setLabel] = useState<string>("");
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY.currencySymbol);
  const [selectedService, setSelectedService] = useState<ServiceRate>();

  useEffect(() => {
    const selectedService = rates.find(({ id }) => id === serviceId);
    const treatment = (selectedService?.sessionTypes || []).find(
      ({ value }) => value === sessionType
    );
    const isCouples = treatment?.isCouples || false;

    let serviceLabel = getServiceLabel(selectedService, isCouples);
    const currentCurrency =
      getValue(selectedService, "selectedCountry.currencySymbol") ||
      DEFAULT_CURRENCY.currencySymbol;

    setCurrency(currentCurrency);
    setLabel(serviceLabel);
    setSelectedService(selectedService);
  }, [rates, serviceId, sessionType]);

  const getTreatmentGenderPref = (index: number) => {
    let selectedPref = genderPreference1;
    if (index === 1) selectedPref = genderPreference2;
    let genderPref;
    if (selectedPref) {
      genderPref = GenderPreference.toDescriptiveString(selectedPref);
    }
    return genderPref;
  };

  const checkIfAppendDurationToTreatmentApplied = (question: any[], index: number) => {
    let addedDuration = 0;
    let appendDurationToTreatment = false;

    if (!checkIfEmpty(question)) {
      const questionData = question[index];

      questionData.forEach((answer: any) => {
        if (answer?.attrs?.appendDurationToTreatment) {
          appendDurationToTreatment = true;
          addedDuration += answer?.addedDuration || 0;
        }
      });
    }
    return { isAppendDurationToTreatment: appendDurationToTreatment, addedDuration };
  };

  const getTreatmentDuration = (index: number) => {
    if (!selectedService || checkIfEmpty(selectedService)) return 0;

    const { isMassage } = selectedService;
    if (isMassage) {
      let duration = massageLength;
      if (index === 1) duration = massageLength2;
      return duration || 0;
    }

    const selectedDetail = treatmentDetails[index];
    const { treatmentId } = selectedDetail;
    const { addedDuration } = checkIfAppendDurationToTreatmentApplied(answers, index);

    const treatment = selectedService.prices.find(({ id }) => id === treatmentId);
    const treatmentDuration = (treatment?.duration || 0) as unknown as number;
    return treatmentDuration + addedDuration;
  };

  const multipleTreatmentDetails =
    treatmentDetails && treatmentDetails.length > 1 && !checkIfEmpty(treatmentDetails[1]);

  return (
    <Box>
      <Box mb={"8px"} fontFamily="Museo" fontSize="16px" color={Colors.Dusk} fontWeight={!!selectedService ? "700" : "500"}>
        {label}
      </Box>

      {!!selectedService && (
        <Box
          display={"flex"}
          gridGap={multipleTreatmentDetails ? "16px" : "0px"}
          flexDirection={"column"}
        >
          {treatmentDetails.map((td: any, index: number) => {
            if (!td || checkIfEmpty(td)) return <Box />;

            const treatmentDuration = getTreatmentDuration(index) || getValue(td, "serviceDuration") || 0;
            return (
              <TreatmentDetailItem
                index={index}
                answers={answers}
                currency={currency}
                treatmentDetail={td}
                selectedService={selectedService}
                onTreatmentClicked={onTreatmentClicked}
                onAddOnClicked={onAddOnClicked}
                treatmentDuration={treatmentDuration}
                genderPreference={getTreatmentGenderPref(index)}
                showPersonIndex={multipleTreatmentDetails}
                additionalTreatmentPrice={bookingPrice?.additionalTreatmentPrice ?? 0}
                // addOn={td.addons ?? getSelectedAddOnsForTreatment({ treatmentIndex: index })}
                addOn={td.addons ?? []}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default TreatmentDetailItems;
