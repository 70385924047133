import React from "react";
import MapApiService from "../../../../services/map/MapApiService";
import { MAX_ALLOWED_ZOOM_LEVEL, MAX_ZOOM_LEVEL } from "../../../../services/map/map.service";

import BookingIcon from "../../../../images/booking-marker.svg";
import TherapistAreaMarker from "../../../../images/center-marker.svg";
import BOOKING_ELLIPSE_SELECTED from "../../../../images/booking-ellipse-selected.svg";

interface Props {
  id: string;
  latitude: { bookingLat: number; therapistLat: number };
  longitude: { bookingLng: number; therapistLng: number };
  isMarkerRequired?: boolean;
  isBookingConfirmed?: boolean;
}
const DirectionMap = ({
  id,
  latitude,
  longitude,
  isMarkerRequired = true,
  isBookingConfirmed = false,
}: Props): JSX.Element => {

  async function initMap(): Promise<void> {
    const TherapistPosition = {
      lat: latitude.therapistLat,
      lng: longitude.therapistLng,
    };

    const BookingPosition = {
      lat: latitude.bookingLat,
      lng: longitude.bookingLng,
    };
    const isTherapistLngLat = TherapistPosition.lng && TherapistPosition.lat;

    const map: google.maps.Map = await MapApiService.getMap(
      document.getElementById(id) as HTMLElement,
      isTherapistLngLat ? TherapistPosition : BookingPosition
    );

    map.setOptions({
      disableDefaultUI: true,
      maxZoom: isBookingConfirmed ? MAX_ALLOWED_ZOOM_LEVEL : MAX_ZOOM_LEVEL,
      gestureHandling: "cooperative", // enables ctrl + scroll to zoom
    });

    if (latitude.therapistLat && longitude.therapistLng) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend({ lat: latitude.bookingLat, lng: longitude.bookingLng });
      bounds.extend({ lat: latitude.therapistLat, lng: longitude.therapistLng });
      map.fitBounds(bounds);
    }

    // Marker for therapist
    const therapistMarker: google.maps.Marker = new google.maps.Marker({
      icon: {
        url: TherapistAreaMarker,
        anchor: new google.maps.Point(8, 8),
      },
      position: TherapistPosition,
      map,
      title: "Therapist",
    });

    const icon = isMarkerRequired
      ? {
        url: BookingIcon,
        anchor: new google.maps.Point(8, 8),
      }
      : {
        url: BOOKING_ELLIPSE_SELECTED,
        scaledSize: new google.maps.Size(60, 60),
        anchor: new google.maps.Point(60 / 2, 60 / 2),
      };

    const bookingMarker: google.maps.Marker = new google.maps.Marker({
      icon,
      position: BookingPosition,
      map,
    });

    bookingMarker.addListener("click", () => {
      map?.panTo(BookingPosition);
    });
  }

  React.useEffect(() => {
    initMap();
  }, []);

  return <div id={id} style={{ width: "100%", height: "100%" }}></div>;
};

export default DirectionMap;
