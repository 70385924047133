import { Box } from "@mui/material";
import { useState } from "react";
import LightBox from "../LightBox/LightBox";
interface Props {
  reviewImages: Array<string>;
}
const ReviewImages = ({ reviewImages }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const openLightBox = (index: number) => {
    setSelectedIndex(index);
    setVisible(true);
  };
  return (
    <Box gap="8px" mt="16px" className="scroll-horizontal">
      {reviewImages.map((image: string, index: number) => {
        return (
          <Box
            style={{
              minWidth: "120px",
              height: "120px",
              borderRadius: "8px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <img
              src={image}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              onClick={() => openLightBox(index)}
              alt="Review"
            />
          </Box>
        );
      })}
      <LightBox
        images={reviewImages}
        visible={visible}
        selectedIndex={selectedIndex}
        handleClose={() => {
          setVisible(false);
        }}
      />
    </Box>
  );
};
export default ReviewImages;
