import { Box } from "@material-ui/core";
import React from "react";
import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import Button, { ButtonType } from "../../../components/Button";
import { Colors } from "../../../constants/colors";

interface Props {
  onMyWay: () => unknown;
  onAbandon: () => unknown;
  onBookingUpdate: () => unknown;
  allowBookingUpdate: boolean;
  disabled: boolean;
  isPaidBooking: boolean;
  CanChatBtn: React.ReactElement;
}

const ConfirmedJobActions = ({
  onMyWay,
  onAbandon,
  onBookingUpdate,
  allowBookingUpdate,
  disabled,
  isPaidBooking,
  CanChatBtn,
}: Props) => {
  return (
    <CardWrapper>
      <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <Button title="Cancel" type={ButtonType.danger} onClick={onAbandon} disabled={disabled} />
          <Button
            title="On my way"
            type={ButtonType.secondary}
            onClick={onMyWay}
            disabled={disabled || !isPaidBooking}
          />
        </Box>
        {CanChatBtn}

        {!isPaidBooking && (
          <Box style={{ color: Colors.TextDanger, marginTop: 8, marginBottom: 8, fontSize: 14 }}>
            The booking is pending client payment to settle, it’s not confirmed yet.
          </Box>
        )}
        {allowBookingUpdate ? (
          <Box>
            <Button
              title="Update booking"
              type={ButtonType.outlined}
              onClick={onBookingUpdate}
              disabled={disabled}
            />
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </CardWrapper>
  );
};

export default ConfirmedJobActions;
