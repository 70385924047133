import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import LinkIcon from "../../../../images/link-icon.svg";
import Button, { ButtonSize, ButtonType, IconPosition } from "../../../Button";

interface Props {
  onCopyLink: () => unknown;
  onViewSchedule: () => unknown;
  isDisabled?: boolean;
  title?: string;
  description?: string;
}

const CorporateLink = ({
  onCopyLink,
  onViewSchedule,
  isDisabled = false,
  title = "",
  description = "",
}: Props): JSX.Element => (
  <Box style={styles.wrapper}>
    {!!title && <Box style={styles.title}>{title}</Box>}
    {!!description && <Box style={styles.description}>{description}</Box>}
    <Box style={styles.buttonWrapper}>
      <Button
        size={ButtonSize.small}
        type={ButtonType.outlined}
        title="Copy link"
        icon={LinkIcon}
        iconPosition={IconPosition.left}
        iconStyle={{
          marginRight: "4px",
        }}
        onClick={onCopyLink}
        disabled={isDisabled}
      />
      <Button
        size={ButtonSize.small}
        type={ButtonType.indigo}
        title="View schedule"
        onClick={onViewSchedule}
        disabled={isDisabled}
      />
    </Box>
  </Box>
);

const styles = {
  wrapper: {
    padding: "24px",
    margin: "16px 0px",
    borderRadius: "8px",
    backgroundColor: Colors.White,
    border: `1px solid ${Colors.LightPeriwinkle}`,
  },
  title: {
    fontFamily: "Museo",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "150%",
    color: Colors.NightBlue,
    marginBottom: "8px",
  },
  description: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: Colors.Dusk,
    margin: "8px 0 16px 0",
  },
  buttonWrapper: {
    display: "flex",
    gap: "8px",
  },
};

export default CorporateLink;
