import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props {
  convertedToCurrencyCode: string;
  convertedFromCurrencyCode: string;
  convertedCurrency: string;
}

const CurrencyConversionInfo = ({
  convertedToCurrencyCode,
  convertedFromCurrencyCode,
  convertedCurrency,
}: Props) => {
  const showConversionInfo = convertedToCurrencyCode && convertedToCurrencyCode !== convertedFromCurrencyCode;
  return (
    <>
      {showConversionInfo ? (
        <Box
          style={{
            fontSize: "12px",
            width: "100%",
            textAlign: "right",
            fontFamily: "Open Sans",
            fontWeight: 600,
            color: Colors.BlueyGrey,
            lineHeight: "18px",
          }}
        >
          Converted from {convertedCurrency}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default CurrencyConversionInfo;
