import { Box } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import NewBookingSummaryItem, {
  NewBookingSummaryItemType,
} from "../ReviewAndBook/NewBookingSummaryItem";
import { Display, FlexDirection, Spacing } from "../v2/Styled/enum";

interface Props {
  centerTitle?: boolean;
  handleRootModal?: (boolean: boolean) => unknown;
  style?: React.CSSProperties;
}

const SummarySection = ({ centerTitle = false, handleRootModal, style }: Props): JSX.Element => (
  <Box
    style={style}
    display={Display.Flex}
    flexDirection={FlexDirection.Column}
    gridRowGap={Spacing.S3}
    paddingBottom={Spacing.S2}
  >
    <Box
      fontFamily="Museo"
      fontSize={centerTitle ? "20px" : "16px"}
      fontWeight={700}
      color={centerTitle ? Colors.NightBlue : Colors.Dusk}
      textAlign={centerTitle ? "center" : "left"}
    >
      Summary
    </Box>

    <NewBookingSummaryItem
      type={NewBookingSummaryItemType.service}
      handleRootModal={handleRootModal}
    />

    <NewBookingSummaryItem
      type={NewBookingSummaryItemType.parkingAndTravel}
      handleRootModal={handleRootModal}
    />

    <NewBookingSummaryItem
      type={NewBookingSummaryItemType.peakHourSurcharge}
      handleRootModal={handleRootModal}
    />

    <NewBookingSummaryItem
      type={NewBookingSummaryItemType.publicHolidaySurcharge}
      handleRootModal={handleRootModal}
    />

    <NewBookingSummaryItem
      type={NewBookingSummaryItemType.coupon}
      handleRootModal={handleRootModal}
    />

    <NewBookingSummaryItem
      type={NewBookingSummaryItemType.credit}
      handleRootModal={handleRootModal}
    />

    <NewBookingSummaryItem
      type={NewBookingSummaryItemType.clientPlatformFee}
      handleRootModal={handleRootModal}
    />
  </Box>
);

export default SummarySection;
