import React, { useState } from "react";
import "./styles.css";
interface Props {
  title: string;
  children: JSX.Element;
  followCursor?: boolean;
  style?: React.CSSProperties;
}
const Tooltip = ({ title, followCursor = false, style, children }: Props): JSX.Element => {
  const [position, setPosition] = useState<{ x: number; y: number }>();
  const cursorPadding = 12;
  const handlePositionChange = (e: any) => {
    setPosition({ x: e.clientX + cursorPadding, y: e.clientY + cursorPadding });
  };
  return (
    <div
      className="tooltip-container"
      style={style}
      onMouseEnter={handlePositionChange}
      onMouseMove={followCursor ? handlePositionChange : () => {}}
    >
      {children}
      <div className="tooltip" style={{ top: `${position?.y}px`, left: `${position?.x}px` }}>
        <span className={"text"}>{title}</span>
      </div>
    </div>
  );
};

export default Tooltip;
