import { getValue } from "../../../utils/object";
import ReferralsContent from "./ReferralsContent";
import Wrapper from "../../../components/Wrapper";
import { useMobile } from "../../../hooks/mobile";
import { Box } from "../../../components/v2/Styled";
import { useTherapistStore } from "../../../stores/therapist";
import { FlexDirection, Spacing } from "../../../components/v2/Styled/enum";
import ProDashboardHeader from "../../../components/ProDashboard/ProDashboardHeader";

const Referrals = ({ forClientUser = false }) => {
  const { proDashSections } = useTherapistStore();
  const isMobile = useMobile();
  const getSectionLabel = () => {
    const section = proDashSections.find(({ value }) => value === "referPro");
    return getValue(section, "label");
  };

  const section = getSectionLabel();

  return (
    <Wrapper>
      <ProDashboardHeader section={section} hideTabs={forClientUser} />
      <Box
        maxWidth={"766px"}
        marginY={Spacing.S11}
        direction={FlexDirection.Column}
        paddingX={isMobile ? Spacing.S4 : Spacing.S20}
      >
        <ReferralsContent forClientUser={forClientUser} />
      </Box>
    </Wrapper>
  );
};

export default Referrals;
