import React from "react";
import BlysStripe from "./BlysStripe";
import Dialog from "../../../../Dialog";
import { Box } from "@material-ui/core";
import Button, { ButtonType } from "../../../../Button";
import { Text } from "../../../../v2/Styled";
import * as Styled from "../../../../v2/Styled/enum";
import { Colors } from "../../../../../constants/colors";
import { useMobile } from "../../../../../hooks/mobile";

interface Props {
  open: boolean;
  cancelText?: string;
  confirmText?: string;
  onClose: () => unknown;
  onConfirm: () => unknown;
  title: React.ReactNode | string;
  description: React.ReactNode | string;
}

const CoBrandingActionModal = ({
  open,
  title,
  onClose,
  onConfirm,
  cancelText,
  confirmText,
  description,
}: Props): JSX.Element => {
  const isMobile = useMobile();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={isMobile}
      style={{
        margin: 0,
        padding: `${Styled.Spacing.S8} ${Styled.Spacing.S10}`,
      }}
    >
      <Box
        gridGap={Styled.Spacing.S8}
        display={Styled.Display.Flex}
        width={isMobile ? "100%" : "542px"}
        flexDirection={Styled.FlexDirection.Column}
      >
        <Box alignSelf={Styled.AlignItems.start}>
          <BlysStripe iconStyle={{ height: Styled.Spacing.S9 }} />
        </Box>
        <Box
          gridGap={Styled.Spacing.S4}
          display={Styled.Display.Flex}
          flexDirection={Styled.FlexDirection.Column}
        >
          <Box>
            <Text
              color={Colors.Indigo}
              size={Styled.FontSize.F24}
              weight={Styled.FontWeight.Bold}
              font={Styled.FontFamily.Museo}
              lineHeight={Styled.LineHeight.L27}
            >
              {title}
            </Text>
          </Box>
          <Box>
            <Text color={Colors.Grey} size={Styled.FontSize.F16} lineHeight={Styled.LineHeight.L24}>
              {description}
            </Text>
          </Box>
        </Box>
        <Box gridGap={Styled.Spacing.S4} display={Styled.Display.Flex}>
          <Button title={cancelText || "Cancel"} type={ButtonType.outlined} onClick={onClose} />
          <Button
            onClick={onConfirm}
            type={ButtonType.secondary}
            title={confirmText || "Confirm"}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default CoBrandingActionModal;
