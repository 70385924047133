import { Box, BoxProps } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";

const Value: React.FC<BoxProps> = (props) => {
  return (
    <Box
      fontFamily="Museo"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      mt="4px"
      {...props}
    />
  );
};

export default Value;
