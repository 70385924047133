import { useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { actions as bookingActions } from "../../../stores/V2/booking/booking";

import { checkIfEmpty, getValue } from "../../../utils/object";
import { BOOKING_STATUS, JobStatus } from "../../../constants/booking";
import SectionHeader from "../../../components/Headers/SectionHeader/SectionHeader";
import { Colors } from "../../../constants/colors";
import { capitalizeFirstLetter } from "../../../helpers/string";
import Map from "../../../components/Map";
import { ADDRESS_DISCLAIMER } from "../../../constants/address";
import { useMobile } from "../../../hooks/mobile";
import { openMap } from "../../../services/map/map.service";
import TextButton from "../../../components/TextButton/TextButton";
import AddressModal from "../../../components/Addresses/AddressModal";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
} from "../../../components/v2/Styled/enum";
import { Address } from "../../../models";
import { DEFAULT_COUNTRY, setCountryCode } from "../../../utils/country";
import { useAlertStore } from "../../../stores/alert";

import NotesIcon from "../../../images/notesIcon.svg";
import StairsIcon from "../../../images/stairsIcon.svg";
import PetsIcon from "../../../images/petsIcon.svg";
import ParkingIcon from "../../../images/parkingIcon.svg";
import markerIcon from "../../../images/booking-marker.svg";
import LocationTypeIcon from "../../../images/locationTypeIcon.svg";
import ExactBookingMarker from "../../../images/exact-booking-marker.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/V2";

interface Props {
  status: string;
  userId: string;
  address: any;
  jobStatus?: string;
  hideSensativeData: boolean;
  showLocationDetails: boolean;
  isHairAndMakeup: boolean;
  allowAddressUpdate?: boolean;
  openMapModal: () => void;
}

const AddressSection = ({
  userId,
  openMapModal,
  address,
  status,
  hideSensativeData,
  isHairAndMakeup,
  allowAddressUpdate = true,
  showLocationDetails = true,
  jobStatus,
}: Props) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const { setErrorMessage } = useAlertStore();
 
  const bookingData = useSelector(({ booking }: RootState) => booking);

  const [addressModalOpen, setAddressModalOpen] = useState(false);

  const getFormattedLocationDetails = ({ address }: any) => {
    if (!address || checkIfEmpty(address)) return [];

    let locationType = getValue(address, "locationtype.label") || null;
    if (!locationType) {
      locationType = address?.type ? capitalizeFirstLetter(address.type) : null;
    }

    const fullLocationDetails = [
      {
        type: "locationType",
        value: locationType,
        icon: LocationTypeIcon,
      },
      {
        type: "parking",
        label: "Parking",
        value: address.parkingOption?.title,
        icon: ParkingIcon,
      },
      {
        type: "stairs",
        label: "Stairs",
        value: address.stairOption?.title,
        icon: StairsIcon,
      },
      {
        type: "pets",
        label: "Pets",
        value: address.petOption?.title,
        icon: PetsIcon,
      },
      // {
      //   type: "table",
      //   label: "",
      //   value: address.tableOption?.shortTitle,
      //   icon: TableIcon,
      // },
      {
        type: "notes",
        label: "Location notes",
        value: address.instructions,
        icon: NotesIcon,
      },
    ];
    if (hideSensativeData) return [fullLocationDetails[0]];

    // remove table option form the list if the booking is hair and makeup
    if (isHairAndMakeup) {
      return fullLocationDetails.filter((data) => data.type !== "table");
    }

    return fullLocationDetails;
  };

  const getAddressText = () => {
    const bookingAddress =
      getValue(bookingData, "address") || getValue(bookingData, "address");
    const { suburb, state, address: street, fullAddress, postcode } = bookingAddress || address;

    if (hideSensativeData) return `${suburb} ${state}`;
    return fullAddress || `${street}, ${suburb}, ${state} ${postcode}`;
  };

  const checkAddressAccurate = () => {
    const statusList = [JobStatus.accepted, JobStatus.finished, JobStatus.onway, JobStatus.rebooking];
    return statusList.includes(jobStatus as JobStatus);
  };

  const toggleAddressModal = (visible: boolean) => setAddressModalOpen(visible);

  const handleAddressSaved = (updatedAddress: Address) => {
    const country = getValue(address, "country") || DEFAULT_COUNTRY;
    const selectedCountry = getValue(updatedAddress, "country") || DEFAULT_COUNTRY;

    if (country !== selectedCountry) {
      setErrorMessage("Address cannot be updated to a new country");
      return;
    }

    dispatch(bookingActions.updateBookingLocation({ address: updatedAddress }));

    setCountryCode(selectedCountry);
    toggleAddressModal(false);
  };

  if (!address || checkIfEmpty(address)) return <Box />;

  const showSectionHeaders = [BOOKING_STATUS.ARRANGED, BOOKING_STATUS.COMPLETED].includes(status as BOOKING_STATUS);

  const addressRestrictStatus: string[] = [BOOKING_STATUS.NEW, BOOKING_STATUS.DECLINED];
  const hideFullAddress = addressRestrictStatus.includes(status);

  const locationDetails = getFormattedLocationDetails({ address });
  const addressText = getAddressText();
  const isAddressAccurate = checkAddressAccurate();

  const headerTextStyle = { fontWeight: FontWeight.Medium, color: Colors.BlueyGrey };
  return (
    <Box>
      <Box style={{ display: Display.Flex, flexDirection: FlexDirection.Column, gap: "12px"}}>
        <Box>
          {showSectionHeaders && <>
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              justifyContent={JustifyContent.spaceBetween}
              alignItems={AlignItems.start}
            >
              <SectionHeader
                title="Location"
                textStyle={headerTextStyle}
                wrapperStyle={{ marginBottom: "8px" }}
              />
              {allowAddressUpdate && <TextButton
                text="Change"
                type="primary"
                onClick={() => toggleAddressModal(true)}
                textStyle={{ padding: "0px", fontSize: FontSize.F16 }}
              />}
            </Box>
            <Box
              fontWeight={FontWeight.Medium}
              color={Colors.Dusk}
              fontSize={FontSize.F16}
              fontFamily={FontFamily.Museo}
            >
              {addressText}
            </Box>
          </>}
        </Box>
        <Box width="100%" height="150px" borderRadius="8px" position="relative">
          <Box
            style={styles.address}
            position="absolute"
            maxWidth={isMobile ? "85%" : "90%"}
            onClick={() => openMap({ location: addressText, isDirection: true })}
          >
            <Box>
              <img src={ExactBookingMarker} style={{ width: "14px", height: "14px" }} alt="Location marker"/>
            </Box>
            <Box style={styles.addressText} whiteSpace="nowrap">
              {addressText}
            </Box>
          </Box>
          <Box style={styles.mapSnippetWrapper}>
            <Map
              id={"map-bookingdetais"}
              latitude={address.latitude}
              longitude={address.longitude}
              markerIcon={markerIcon}
              onMapClicked={() => {
                openMapModal();
              }}
              isMarkerAccurate={isAddressAccurate}
              interractable={false}
            />
          </Box>
        </Box>
        {hideFullAddress ? <Box style={styles.helperText}>{ADDRESS_DISCLAIMER}</Box> : <></>}
      </Box>

      {showLocationDetails ? (
        <Box>
          <Box marginTop={"24px"}>
            <SectionHeader title="Location details" />
          </Box>
          <Box mt="10px">
            {locationDetails.map(({ value, label, icon }) => {
              if (!value) return <></>;

              let detail = "";
              if (label) detail += `${label}: `;
              return (
                <Box mb={"12px"} display={"flex"} alignItems={"center"} style={styles.detail}>
                  <img src={icon} alt={`${label}-icon`} style={{ marginRight: "8px" }} />
                  {`${detail}${value}`}
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <></>
      )}

      {/* Address modal */}
      <AddressModal
        open={addressModalOpen}
        onClose={() => toggleAddressModal(false)}
        onSaved={handleAddressSaved}
        userId={userId}
        addedByPro={true}
      />
    </Box>
  );
};

const styles = {
  helperText: {
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 400,
    marginTop: "16px",
  },
  detail: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  address: {
    gap: 4,
    zIndex: "1",
    left: "12px",
    bottom: "8px",
    display: "flex",
    cursor: "pointer",
    padding: "4px 8px",
    borderRadius: "16px",
    alignItems: "center",
    background: Colors.White,
    boxShadow: "0 4px 10px 0 rgba(10,3,41,0.2)",
  },
  addressText: {
    fontSize: "12px",
    lineHeight: "130%",
    overflow: "hidden",
    fontFamily: "SF UI Text",
    color: Colors.ProAppGrey,
    textOverflow: "ellipsis",
    textDecorationLine: "underline",
  },
  mapSnippetWrapper: {
    flex: 1,
    height: "100%",
    borderRadius: "8px",
    overflow: "hidden",
  },
};

export default AddressSection;
