import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import Divider from "../Divider";
import RadioButton from "../RadioButton";
import Switch from "../Switch";
import TwoFAModal from "./2FAModal";
import {
  useEnableMfa,
  useGenerateMfa,
  useGetUserMfa,
  useSendTwoFAVerificationCode,
} from "../../hooks/settings/twoFA.hooks";
import { MFA_APP_NAME, MFA_TYPE } from "../../constants/mfa";
import { getValue } from "../../utils/object";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import RemoveMfaConfirmationModal from "./RemoveMfaConfirmationModal";
import firebaseApp from "../../services/firebaseApp";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
  isMobile: boolean;
  email: string;
  userId: string;
  mobile: string;
}

export interface MfaData {
  qr: string;
  secret: string;
  uri: string;
}

const Security = ({ isMobile, mobile, email, userId }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [mfaType, setMfaType] = useState("");
  const [mfaData, setMfaData] = useState<MfaData>({
    qr: "",
    secret: "",
    uri: "",
  });
  const [showAppMfaModal, setShowAppMfaModal] = useState(false);

  const [shwoRemoveMfaModal, setShowRemoveMfaModal] = useState(false);

  const { data, isLoading: mfaLoading } = useGetUserMfa({ userId });

  const { mutateAsync: generate2FA } = useGenerateMfa();
  const { mutateAsync: enableMfa, isLoading } = useEnableMfa();
  const { mutateAsync: resendVerficationCode } = useSendTwoFAVerificationCode();

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const fetchMfaData = async () => {
    if (mfaType === MFA_TYPE.APP) {
      try {
        const data = await generate2FA({ email: email, appName: MFA_APP_NAME });
        setMfaData(getValue(data, "mfa"));
      } catch (error: any) {
        setErrorMessage(parseApiError(error));
      }
    }
  };

  useEffect(() => {
    if (!mfaLoading && data) {
      setIs2FAEnabled(getValue(data, "isEnabled", false));
      setMfaType(getValue(data, "type"));
    }
  }, [data, mfaLoading]);

  useEffect(() => {
    fetchMfaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaType]);

  const on2FADisableClicked = async () => {
    try {
      const fbToken = await firebaseApp.getToken();
      await resendVerficationCode({ mobileNumber: mobile, fbToken });
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  const on2FAButtonToggled = async (checked: boolean) => {
    try {
      if (!checked && getValue(data, "isEnabled")) {
        setShowRemoveMfaModal(true);

        let mfaType = MFA_TYPE.APP;

        if (getValue(data, "type") === MFA_TYPE.SMS) {
          await on2FADisableClicked();
          mfaType = MFA_TYPE.SMS;
        }

        history.push(location.pathname, { mfaType, mobile, userId });
        return;
      }

      setIs2FAEnabled(checked);
      setShowAppMfaModal(checked);
      if (!checked) {
        setMfaType("");
      } else {
        setMfaType(MFA_TYPE.APP);
      }
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  const onAppBasedSelected = () => {
    setMfaType(MFA_TYPE.APP);
    if (getValue(data, "type") !== MFA_TYPE.APP) {
      setShowAppMfaModal(true);
    }
  };

  const handleAppBasedTwoFASubmit = async (code: string) => {
    try {
      await enableMfa({
        mfaCode: code,
        secret: mfaData.secret,
        uri: mfaData.uri,
        qr: mfaData.qr,
        appName: MFA_APP_NAME,
        type: MFA_TYPE.APP,
        userId,
      });
      setSuccessMessage("2 Factor authentication applied.");
      setMfaType("");
      setShowAppMfaModal(false);
    } catch (error: any) {
      setErrorMessage("Please make sure you have entered the correct authentication code.");
    }
  };

  const onSmsBasedSelected = async () => {
    try {
      setMfaType(MFA_TYPE.SMS);
      if (getValue(data, "type") === MFA_TYPE.SMS) {
        // early return if the sms type is already selected
        return;
      }

      await enableMfa({
        appName: MFA_APP_NAME,
        type: MFA_TYPE.SMS,
        userId,
      });
      setSuccessMessage("2 Factor authentication applied.");
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  const onClose = () => {
    setShowAppMfaModal(false);
    setMfaType(getValue(data, "type", MFA_TYPE.APP));
  };

  const onSuccess = () => {
    setMfaType("");
    setShowRemoveMfaModal(false);
  };

  return (
    <>
      <Box maxWidth="800px" marginRight={isMobile ? "40px" : "80px"}>
        <Box marginLeft={isMobile ? "30px" : "80px"} marginTop="40px" fontFamily="museo">
          <Box fontSize="24px" fontWeight="600" color={Colors.NightBlue}>
            2 Factor Authentication
          </Box>
          <Box
            paddingTop="24px"
            fontSize="16px"
            fontWeight="500"
            color={Colors.Dusk}
            paddingBottom="40px"
          >
            Manage how you'd like to keep your account safe.
          </Box>

          <Divider />

          <Box mt={7} mb={12}>
            <Box display="flex" alignItems="center" gridGap={10}>
              <Switch
                checked={is2FAEnabled}
                onChange={(event) => on2FAButtonToggled(event.target.checked)}
              />
              <Box fontSize="18px" fontWeight="600" color={Colors.NightBlue}>
                Enable 2 Factor Authentication
              </Box>
            </Box>

            <Box
              paddingTop="24px"
              fontSize="14px"
              fontWeight="500"
              color={Colors.Dusk}
              paddingBottom="40px"
              fontFamily="Open Sans"
            >
              Increase your security by enabling two-factor authentication (2FA). A verification
              code will be sent to confirm your identity.
            </Box>

            <Box display="flex" mt={1.5}>
              <RadioButton
                selected={mfaType === MFA_TYPE.SMS}
                onSelected={onSmsBasedSelected}
                disabled={!is2FAEnabled}
              />
              <Box mt={1} flex={1}>
                <Box
                  color={is2FAEnabled ? Colors.Dusk : Colors.Grey}
                  fontSize="18px"
                  fontWeight={600}
                >
                  Text message (SMS)
                </Box>
                <Box
                  fontFamily="Open Sans"
                  fontSize="14px"
                  color={is2FAEnabled ? Colors.Dusk : Colors.Grey}
                  mt={1.5}
                >
                  Use text message (SMS) to receive verification codes.
                </Box>
              </Box>
            </Box>

            <Box display="flex" mt={5}>
              <RadioButton
                selected={mfaType === MFA_TYPE.APP}
                onSelected={onAppBasedSelected}
                disabled={!is2FAEnabled}
              />
              <Box mt={1} flex={1}>
                <Box
                  color={is2FAEnabled ? Colors.Dusk : Colors.Grey}
                  fontSize="18px"
                  fontWeight={600}
                >
                  Authenticator app
                </Box>
                <Box
                  fontFamily="Open Sans"
                  fontSize="14px"
                  color={is2FAEnabled ? Colors.Dusk : Colors.Grey}
                  mt={1.5}
                >
                  Use an app like Google Authenticator to generate verification codes for more
                  protection.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <TwoFAModal
        open={showAppMfaModal}
        onClose={onClose}
        isMobile={isMobile}
        onSubmit={handleAppBasedTwoFASubmit}
        mfaData={mfaData}
        isLoading={isLoading}
      />

      <RemoveMfaConfirmationModal
        open={shwoRemoveMfaModal}
        isMobile={isMobile}
        onClose={() => setShowRemoveMfaModal(false)}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default Security;
