import { Box } from '@material-ui/core';
import caratRight from '../../../../../images/carat-right.png';
import caratLeft from '../../../../../images/carat-left.png';

export default function CalendarHeader({ title, onWeekChange }: { title: string, onWeekChange: (change: number) => void }) {
  return <Box style={headerStyle}>
    <Box style={{ padding: '13px', cursor: 'pointer' }} onClick={() => onWeekChange(-1)}>
      <img src={caratLeft} alt="Left Carat" />
    </Box>
    <Box style={headerTextStyle}>
      {title}
    </Box>
    <Box style={{ cursor: 'pointer', padding: '8px' }} onClick={() => onWeekChange(1)}>
      <img src={caratRight} alt="Right Carat" />
    </Box>
  </Box>;
}

const headerTextStyle = {
  fontFamily: 'Open Sans',
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  color: "#41506F"
}

const headerStyle = {
  margin: '16px 1.7px 16px 0px',
  marginRight: '1.7px',
  height: "48px",
  border: "1px solid #C5CBD6",
  borderRadius: "4px",
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}
