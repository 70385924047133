import React, { useEffect, useState } from "react";
import Wrapper from "../../components/Wrapper";
import SectionPageHeader from "../../components/SectionPageHeader";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { useHistory } from "react-router-dom";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import TextLink from "../../components/TextLink";
import AddUserModal from "../../components/BlysTeam/AddUserModal";
import ActionModal from "../../components/ActionModal";
import { Roles } from "../../constants/blysTeam";
import { useAlertStore } from "../../stores/alert";
import { capitalizeFirstLetter } from "../../helpers/string";
import PaginationControl from "../../components/PaginationControl";
import { useUserStore } from "../../stores/user";
import { useMobile } from "../../hooks/mobile";
import {
  useCreateManager,
  useCreateRecipient,
  useManager,
  useRecipients,
  useRemoveRecipient,
  useUpdateManager,
  useUpdateRecipient,
  useSendInvitaion,
} from "../../hooks/settings/settings.hooks";
import { DropdownOption } from "../../components/Dropdown";
import isValidEmail from "../../helpers/validation";
import { getValue } from "../../utils/object";
import { getInviteUserRoles } from "../../services/settings/role.service";
import Security from "../../components/Settings/Security";
import BookingSettings from "../../components/BookingSettings/BookingSettings";
import { isNdisSupportRecipient } from "../../helpers/user";
import Users from "../Users/Users";
import { LoadingSpinner } from "../../components/loadingSpinner";
import CommunicationPreferences from "../../components/CommunicationPreferences/CommunicationPreferences";

export interface UserDataInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: {} | null;
  mobile?: string;
  userId: number;
  country?: string;
}

export interface RecipientDataInterface {
  firstName: string;
  lastName: string;
  email: string;
  role?: string | number;
  userId?: number | null;
  mobile?: string;
  userOriginalRole?: string;
}

export interface InviteUserRolesOption {
  id: number;
  title: string;
  label: string;
}

const sectionsItems = {
  Users: "Users",
  Security: "Security",
  Bookings: "Bookings",
  CommunicationPreferences: "Communication preferences",
};

const TeamSettings = () => {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [showAddUserModal, setshowAddUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDataInterface | null>(null);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [managerOptions, setManagerOptions] = useState<DropdownOption[] | null>(null);
  const [inviteUserRoles, setInviteUserRoles] = useState<InviteUserRolesOption[]>([]);
  const [redirectedFromJourney, setRedirectedFromJourney] = useState(false);

  const [recipientId, setRecipientId] = useState<number | null>(null);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { user } = useUserStore();
  const isMobile = useMobile();

  const [page, setPage] = React.useState(1);

  const { isLoading: isRecipientLoading, data: recipientData } = useRecipients({
    currentPage: page,
    userId: user?.id,
  });

  const { isLoading: isCreatingManager, mutate: createManager } = useCreateManager({
    onSuccess: () => setSuccessMessage("The user has been added"),
    onError: () => setErrorMessage("Something went wrong"),
  });
  const { isLoading: isUpdatingManager, mutate: updateManager } = useUpdateManager({
    onSuccess: () => setSuccessMessage("The user has been updated"),
    onError: () => setErrorMessage("Something went wrong"),
  });
  const { isLoading: isCreatingRecipient, mutate: createRecipient } = useCreateRecipient({
    onSuccess: () => setSuccessMessage("The user has been added"),
    onError: () => setErrorMessage("Something went wrong"),
  });
  const { isLoading: isUpdatingRecipient, mutate: updateRecipient } = useUpdateRecipient({
    onSuccess: () => setSuccessMessage("The user has been updated"),
    onError: () => setErrorMessage("Something went wrong"),
  });
  const { isLoading: isRemoving, mutate: removeRecipient } = useRemoveRecipient({
    onSuccess: () => setSuccessMessage("The user has been deleted"),
    onError: () => setErrorMessage("Something went wrong"),
  });
  const { isLoading: isManagerLoading, data: managerData } = useManager();

  const { isLoading: isSendingInvitation, mutate: sendInvitation } = useSendInvitaion({
    onSuccess: () => setSuccessMessage("Invitation has been sent"),
    onError: () => setErrorMessage("Something went wrong"),
  });

  const history = useHistory();
  const classes = useStyles();

  // Scroll to top on render
  React.useEffect(() => {
    const location = getValue(history, "location");
    const searchParams = new URLSearchParams(location.search);
    const sectionRedirect = searchParams.get("tab");

    if (sectionRedirect === "communication-preference") {
      setSelectedSectionIndex(3);
      setRedirectedFromJourney(true);
    } else {
      window.scrollTo(0, 0);
    }
  }, [history]);

  const canInviteUser = getValue(user, "role.label") === Roles.HOME_CARE_PROVIDER;

  React.useEffect(() => {
    if (canInviteUser) {
      const fetchRoleOptions = async () => {
        const res = await getInviteUserRoles();
        setInviteUserRoles(res);
      };
      fetchRoleOptions();
    }
  }, [canInviteUser]);

  const authorizedUser = () => {
    if (user) {
      const userRole = user.role?.label;
      const allowInviteUser = getValue(user, "permission.canInviteUser");
      return (
        (userRole && [Roles.ACCOUNT_OWNER, Roles.ACCOUNT_MANAGER].includes(userRole)) ||
        user.adminAccess ||
        allowInviteUser
      );
    }
    return false;
  };

  const handleUserEdit = (user: UserDataInterface) => {
    setSelectedUser(user);
    setshowAddUserModal(true);
  };

  const handleAddNewUser = () => {
    setSelectedUser(null);
    setshowAddUserModal(true);
  };

  const handleSaveUser = async (
    id: number | null,
    data: RecipientDataInterface,
    isManager: boolean,
    userOriginalRole?: string
  ) => {
    if (!data.userId && user) {
      data.userId = user.id;
    }
    const isValid = validateData(data);

    if (isValid !== true) {
      setErrorMessage(isValid.error);
      return;
    }

    const selectedRole = inviteUserRoles.find((item) => item.label === getValue(data, "role"));

    if (canInviteUser) {
      const reqData = {
        firstName: getValue(data, "firstName"),
        lastName: getValue(data, "lastName"),
        email: getValue(data, "email"),
        roleId: getValue(selectedRole, "id"),
      };
      sendInvitation(reqData);
    } else {
      if (!!id) {
        userOriginalRole === Roles.ACCOUNT_MANAGER
          ? updateManager({ id, data })
          : updateRecipient({ id, data });
      } else {
        isManager ? createManager(data) : createRecipient(data);
      }
    }

    setshowAddUserModal(false);
  };

  const validateData = (data: RecipientDataInterface) => {
    if (!data.firstName) {
      return { isValid: false, error: "Please enter first name" };
    }
    if (!data.lastName) {
      return { isValid: false, error: "Please enter last name" };
    }
    if (!data.email) {
      return { isValid: false, error: "Please enter email" };
    }
    if (!isValidEmail(data.email)) {
      return { isValid: false, error: "Please enter valid email" };
    }
    if (!data.role) {
      return { isValid: false, error: "Please enter role" };
    }

    if (data.role === Roles.ACCOUNT_MANAGER && !data.mobile) {
      return { isValid: false, error: "Please enter mobile number" };
    }

    if (!canInviteUser) {
      if (!data.userId) {
        return { isValid: false, error: "Manager Account need to be selected" };
      }
    }
    return true;
  };

  const handleRemoveClicked = (id: number) => {
    setRecipientId(id);
    setShowRemoveUserModal(true);
  };

  const handleRemoveUserConfirm = async () => {
    if (recipientId) {
      removeRecipient(recipientId);
      setRecipientId(null);
      setShowRemoveUserModal(false);
    }
  };

  useEffect(() => {
    if (!isManagerLoading && managerData && managerData.length !== 0) {
      const managerOptions = managerData.map((manager: any) => ({
        title: `${manager.firstName} <${manager.email}>`,
        value: manager.id,
      }));
      setManagerOptions(managerOptions);
    }
  }, [managerData]);

  const sections = [
    sectionsItems.Security,
    sectionsItems.Bookings,
    sectionsItems.CommunicationPreferences,
  ];

  // const isUserAuthorized = authorizedUser();
  // Add Users section for all users
  const isUserAuthorized = true;

  const role = getValue(user, "role.label");

  // if (isUserAuthorized && !isNdisSupportRecipient(role)) {
  //   sections.unshift(sectionsItems.Users);
  // }

  if (isUserAuthorized) {
    sections.unshift(sectionsItems.Users);
  }

  const getSelectedTab = () => {
    const selectedSection = sections[selectedSectionIndex] as keyof typeof sectionsItems;
    return selectedSection;
  };

  const isSelf = (recipient: any) => {
    if (recipient && role === getValue(recipient, "role.label")) {
      return true;
    }
    return false;
  };

  const selectedTab = getSelectedTab();

  return (
    <Wrapper
      header={
        <SectionPageHeader
          title="Settings"
          sections={sections}
          selectedSectionIndex={selectedSectionIndex}
          onSelectedSectionIndex={(index) => {
            history.push(Paths.Settings);
            setSelectedSectionIndex(index);
          }}
        />
      }
    >
      {selectedTab === sectionsItems.Users && (
        <Box maxWidth="1040px" marginRight={isMobile ? "40px" : "80px"}>
          {isNdisSupportRecipient(role) ? (
            <Users />
          ) : (
            <>
              <Box marginLeft={isMobile ? "30px" : "80px"} marginTop="40px" fontFamily="museo">
                <Box fontSize="24px" fontWeight="600" color={Colors.NightBlue}>
                  {" "}
                  User role management
                </Box>
                <Box
                  paddingTop="24px"
                  fontSize="16px"
                  fontWeight="500"
                  color={Colors.Dusk}
                  paddingBottom="40px"
                >
                  Invite your support team to your account so they can help you manage your
                  bookings, payments and correspondence with Blys providers.
                </Box>
                <Box fontSize="18px" fontWeight="600" paddingBottom="32px">
                  Booking access
                </Box>
                <Box width={153}>
                  <Button
                    title={canInviteUser ? "Invite new user" : "Add new user"}
                    size={ButtonSize.large}
                    type={ButtonType.outlined}
                    onClick={handleAddNewUser}
                  />
                </Box>
              </Box>
              {isRecipientLoading ||
              isCreatingRecipient ||
              isCreatingManager ||
              isUpdatingManager ||
              isUpdatingRecipient ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <TableContainer
                      component={Paper}
                      style={{
                        marginLeft: isMobile ? "30px" : "80px",
                        marginTop: "24px",
                        maxWidth: isMobile ? "95%" : "92%",
                      }}
                    >
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.header}>First name</TableCell>
                            <TableCell className={classes.header}>Last name</TableCell>
                            <TableCell className={classes.header}>Email</TableCell>
                            <TableCell className={classes.header}>Role</TableCell>
                            <TableCell className={classes.header}></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getValue(recipientData, "data", []).map((recipient: any) => {
                            return (
                              <TableRow key={`user-lg-${recipient.id}`}>
                                <TableCell className={classes.cell} component="th" scope="row">
                                  {recipient.firstName}
                                </TableCell>
                                <TableCell className={classes.cell} component="th" scope="row">
                                  {recipient.lastName}
                                </TableCell>
                                <TableCell className={classes.cell} component="th" scope="row">
                                  {recipient.email}
                                </TableCell>
                                <TableCell className={classes.cell} component="th" scope="row">
                                  {recipient.role
                                    ? recipient.role.title
                                    : capitalizeFirstLetter(Roles.RECIPIENT)}
                                </TableCell>
                                <TableCell className={classes.cell} component="th" scope="row">
                                  <Box display="flex" flexDirection="row" justifyContent="right">
                                    <TextLink
                                      title="Edit"
                                      fontSize="14px"
                                      fontWeight={500}
                                      onClick={() => handleUserEdit(recipient)}
                                    />
                                    {recipient.role?.label !== Roles.ACCOUNT_OWNER &&
                                    !isSelf(recipient) && (
                                      <TextLink
                                        title="Remove"
                                        fontSize="14px"
                                        marginLeft="24px"
                                        fontWeight={500}
                                        color={Colors.Danger}
                                        onClick={() => handleRemoveClicked(recipient.id)}
                                      />
                                    )}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      marginTop="23px"
                      marginBottom="25px"
                    >
                      <PaginationControl
                        currentPage={recipientData?.page ?? 0}
                        pages={recipientData?.totalPage ?? 0}
                        onPrevious={() => setPage(page - 1)}
                        onNext={() => setPage(page + 1)}
                        startFromOne={true}
                      />
                    </Box>
                  </>
                )}
              {showAddUserModal && !isManagerLoading && (
                <AddUserModal
                  open={showAddUserModal}
                  onClose={() => setshowAddUserModal(false)}
                  selectedUser={selectedUser}
                  handleSaveClicked={(
                    id: number | null,
                    data: RecipientDataInterface,
                    isManager: boolean,
                    userOriginalRole?: string
                  ) => handleSaveUser(id, data, isManager, userOriginalRole)}
                  isLoading={
                    isCreatingRecipient ||
                    isCreatingManager ||
                    isUpdatingManager ||
                    isUpdatingRecipient
                  }
                  managerOptions={managerOptions}
                  inviteUserRolesOption={inviteUserRoles}
                />
              )}
              {showRemoveUserModal && (
                <ActionModal
                  open={showRemoveUserModal}
                  title="Confirm user removal"
                  description="Are you sure you want to delete a team member? "
                  onCancel={() => setShowRemoveUserModal(false)}
                  onConfirm={handleRemoveUserConfirm}
                />
              )}
            </>
          )}
        </Box>
      )}

      {selectedTab === sectionsItems.Security && (
        <Security
          isMobile={isMobile}
          email={getValue(user, "email")}
          userId={getValue(user, "id")}
          mobile={getValue(user, "mobile")}
        />
      )}

      {selectedTab === sectionsItems.Bookings && <BookingSettings />}

      {selectedTab === sectionsItems.CommunicationPreferences && (
        <CommunicationPreferences fromJourney={redirectedFromJourney} />
      )}
    </Wrapper>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: "330px",
    maxWidth: "1040px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  header: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 500,
    color: Colors.Grey,
  },
  cell: {
    fontFamily: "Museo",
    fontSize: "16px",
    color: Colors.Dusk,
  },
});

export default TeamSettings;
