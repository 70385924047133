import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import { Colors } from "../../constants/colors";
import { setAccessToken } from "../../helpers/accessToken";
import { useUserStore } from "../../stores/user";
import { parseQueryString } from "../../helpers/string";

const DomainRedirect = () => {
  
  const history = useHistory();
  const { search, pathname } = useLocation();
  const query = parseQueryString(search);

  const { user, fetchMe } = useUserStore();

  const redirect = () => {
    // remove domain from url
    const currentPath = pathname;
    const pathArr = currentPath.split("/");
    pathArr.splice(0, 2);
    const redirectURL = pathArr.join("/");

    // remove redirect token
    const queryParams = new URLSearchParams(search);
    queryParams.delete("redirectToken");
    
    history.replace({
      pathname: `/${redirectURL}`,
      search: queryParams.toString(),
    });
  };

  const initializeUser = (token: string) => {
    setAccessToken(token);
    fetchMe(token, redirect);
  };

  const handleUserToken = () => {
    // user already loggedIn
    if (!!user) return redirect();

    const existingToken = localStorage.getItem("token");

    // if no redirectToken || token already exists
    if (!query.redirectToken || existingToken) return redirect();

    initializeUser(query.redirectToken as string);
  };

  useEffect(() => {
    handleUserToken();
  }, []);

  return <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
    <CircularProgress style={{ color: Colors.NightBlue }} />
  </Box>;
};

export default DomainRedirect;
