import React, { useEffect } from "react";
import { Box, ButtonBase } from "@material-ui/core";
import NextIcon from "../images/paginate-next.svg";
import { Colors } from "../constants/colors";
import { scrollToTop } from "../helpers/scrollToTop";

interface Props {
  pages: number;
  currentPage: number;
  onPrevious: () => unknown;
  onNext: () => unknown;
  textHidden?: boolean;
  startFromOne?: boolean;
  containerStyle?: object;
}

export default function PaginationControl({
  pages,
  currentPage,
  onPrevious,
  onNext,
  textHidden = false,
  startFromOne = false,
  containerStyle = {},
}: Props): JSX.Element {
  const startPage = startFromOne ? 1 : 0;
  const currentPagination = startFromOne ? currentPage : currentPage + 1;
  const totalPagination = pages === 0 ? 1 : pages;

  const handlePagination = (action: () => unknown) => {
    action();
    scrollToTop();
  };

  if (pages < 2) return <Box />;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{
        ...containerStyle,
      }}
    >
      {!textHidden && (
        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          letterSpacing="0.39px"
          color={Colors.Dusk}
          mr="12px"
        >
          Page {currentPagination} of {totalPagination}
        </Box>
      )}

      <ButtonBase
        onClick={() => handlePagination(onPrevious)}
        style={{
          marginRight: "10px",
          opacity: currentPagination === startPage ? "0.3" : undefined,
        }}
        disabled={currentPagination === startPage}
      >
        <img src={NextIcon} style={{ transform: "scale(-1,1)" }} />
      </ButtonBase>
      <ButtonBase
        onClick={() => handlePagination(onNext)}
        disabled={currentPagination === totalPagination}
        style={{
          opacity: currentPagination === totalPagination ? "0.3" : undefined,
        }}
      >
        <img src={NextIcon} />
      </ButtonBase>
    </Box>
  );
}
