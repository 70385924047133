import { useMutation } from "@tanstack/react-query";
import { User } from "../models";
import { post } from "../api/client";
import { getValue } from "../utils/object";
import { BRAINTREE_COUNTRIES, PAYMENT_PROVIDER } from "../data/enums";

export const useAllowedPayments = (user: User | null) => {
  const code = (getValue(user, "country") || "").toUpperCase();
  const paymentProvider = getValue(user, "paymentProvider");

  const isPaypalAllowed = [PAYMENT_PROVIDER.BRAINTREE, PAYMENT_PROVIDER.BRAINTREE_STRIPE].includes(
    paymentProvider
  );
  const isStripeAllowed = [PAYMENT_PROVIDER.STRIPE, PAYMENT_PROVIDER.BRAINTREE_STRIPE].includes(
    paymentProvider
  );

  return {
    isPaypalAllowed,
    isStripeAllowed,
    isAfterpayAllowed: BRAINTREE_COUNTRIES.includes(code),
    isBraintreeApplePayAllowed: BRAINTREE_COUNTRIES.includes(code),
  };
};

export const useBulkPaymentWithInvoice = () => {
  return useMutation(async (body: { invoices: number[] }) =>
    post("api/v2/payments/bulk-pay-with-invoice", body)
  );
};

export const useBulkPaymentWithCard = () => {
  return useMutation(async (body: { invoices: number[]; paymentMethodId: number }) =>
    post("api/v2/payments/bulk-pay-with-card", body)
  );
};
