import { AxiosError } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import queryClient from "../../config/queryClient";
import { parseApiV2Error } from "../../helpers/error";
import { UserCommunicationInterface } from "../../pages/ProDashboard/Settings";

const KEYS = {
  SETTINGS: "SETTINGS",
};

export const useProSettings = () =>
  useQuery([KEYS.SETTINGS], () => httpClient.get("api/v2/users/settings").then(({ data }) => data));

export const useToggleSettings = ({ onSuccess, onError }: any) =>
  useMutation(
    (data: UserCommunicationInterface) =>
      httpClient.post("api/v2/users/communication-preference", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [KEYS.SETTINGS] });
        if (onSuccess) onSuccess();
      },
      onError: (err: AxiosError) => {
        const errorMessage = parseApiV2Error(err);
        onError(errorMessage);
      },
    }
  );

export const useUpdateClientGenderPreference = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: any) => unknown;
  onError: (error: Error) => unknown;
}) => {
  return useMutation(
    (body: { preferredClientGender: string }) => {
      return httpClient.put("api/v2/therapists/client-gender-preference", body);
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error: Error) => {
        onError(error);
      },
    }
  );
};
