import { Box } from "@material-ui/core";
import { useState } from "react";
import { Colors } from "../../constants/colors";
import { useMobile, useTab } from "../../hooks/mobile";
import { Booking } from "../../models";
import {
  getBookingUpdateDetails,
  keepDeclinedBooking,
} from "../../services/bookings/bookings.service";
import { useAlertStore } from "../../stores/alert";
import { useUserStore } from "../../stores/user";
import Button, { ButtonType } from "../Button";
import CancelBooking from "../CancelBooking";
import Divider from "../Divider";

interface Props {
  booking: Booking;
  bookingMutate: () => unknown;
}

const UpdateDeclinedActions = ({ booking, bookingMutate }: Props) => {
  const { user } = useUserStore();
  const { setErrorMessage } = useAlertStore();
  const isMobile = useMobile();
  const isTab = useTab();

  const [showCancelBooking, setShowCancelBooking] = useState(false);

  const checkIsUpdateDeclined = () => {
    let requestDeclined = false;

    const { bookingUpdates } = booking;
    if (bookingUpdates && bookingUpdates.length > 0 && user) {
      const { id } = user;
      const requestDetails = getBookingUpdateDetails(id, bookingUpdates);
      if (requestDetails && requestDetails.isDeclined && requestDetails.isRequestByClient) {
        requestDeclined = true;
      }
    }
    return requestDeclined;
  };

  const requestDeclined = checkIsUpdateDeclined();

  const handleBookingCancel = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowCancelBooking(true);
  };

  const handleKeep = async (e: MouseEvent) => {
    try {
      e.stopPropagation();
      e.preventDefault();
      const { id } = booking;

      await keepDeclinedBooking(id);

      bookingMutate();
    } catch (err) {
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  if (!requestDeclined) return <></>;

  return (
    <Box paddingTop="16px">
      <Divider mb="16px" />
      <Box
        display={"flex"}
        gridGap="8px"
        alignItems="center"
        flexDirection={isMobile || isTab ? "column" : "row"}
      >
        <Box
          fontWeight={400}
          fontFamily="Museo"
          fontSize={"16px"}
          lineHeight="24px"
          paddingRight={"20px"}
          color={Colors.Dusk}
        >
          Would you like to:
        </Box>
        <Box display={"flex"} flexDirection={isMobile || isTab ? "column" : "row"} gridGap="8px">
          <Button
            title="Cancel booking"
            onClick={handleBookingCancel}
            type={ButtonType.outlined}
            style={{ padding: "0px 20px", marginBottom: "8px" }}
            width="200px"
          />
          <Button
            title="Keep the booking"
            onClick={handleKeep}
            type={ButtonType.secondary}
            style={{ padding: "0px 20px" }}
            width="200px"
          />
        </Box>
      </Box>

      <CancelBooking
        booking={booking}
        open={showCancelBooking}
        onConfirm={() => {
          bookingMutate();
        }}
        onClose={() => setShowCancelBooking(false)}
      />
    </Box>
  );
};

export default UpdateDeclinedActions;
