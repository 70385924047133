import React, { useEffect, useState } from "react";
import ServiceAreaMarker from "../../../../images/serviceAreaMarker.svg";
import MapApiService from "../../../../services/map/MapApiService";
import { getZoomLevel } from "../../../../utils/map";
interface Props {
  id: string;
  latitude: number;
  longitude: number;
  radius: number;
  getAddressFromLatLng: (coordinate: any) => void;
}

export default function MapArea({ id, latitude, longitude, radius, getAddressFromLatLng }: Props): JSX.Element {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [circle, setCircle] = useState<google.maps.Circle | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const position = {
    lat: latitude,
    lng: longitude,
  };

  async function initMap(): Promise<void> {
    const loadedMap = await MapApiService.getMap(document.getElementById(id) as HTMLElement, position);

    loadedMap.setOptions({
      // disableDefaultUI: true,
      draggable: true,
      zoomControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      streetViewControl: false,
      zoom: 4,
    });

    setMap(loadedMap);
  }

  const addMarker = ({ latitude, longitude }: any) => {
    const center = { lat: latitude, lng: longitude };

    if (!marker || !marker.setPosition) {
      const newMarker = new google.maps.Marker({
        position: center,
        map,
        icon: ServiceAreaMarker,
      });
      setMarker(newMarker);
    } else {
      marker.setPosition(center);
    }

    if (!circle || !circle.setCenter) {
      const newCircle = new google.maps.Circle({
        strokeColor: "#5197FFCC",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#5197FF4D",
        fillOpacity: 0.4,
        map,
        center: center,
        radius: radius,
      });

      if (marker) newCircle.bindTo("center", marker, "position");
      setCircle(newCircle);
    } else {
      circle.setCenter(center);
    }
  };

  useEffect(() => {
    if (map) {
      map.setZoom(getZoomLevel(radius));
      if (radius) {
        map.setCenter(new google.maps.LatLng(latitude, longitude));
        addMarker({ latitude, longitude });
      }
    }
  }, [latitude, longitude, radius, map]);

  useEffect(() => {
    if (circle && radius) circle.setRadius(radius);
  }, [circle, radius]);

  const onMapClicked = (coordinate: any) => {
    if (!coordinate) return;
    addMarker({ latitude: coordinate.lat, longitude: coordinate.lng });
    getAddressFromLatLng({ latitude: coordinate.lat, longitude: coordinate.lng });
  };

  React.useEffect(() => {
    if (map) {
      google.maps.event.clearListeners(map, "click");

      if (onMapClicked) {
        map.addListener("click", (event: any) => onMapClicked(event.latLng.toJSON()));
      }
      if (circle) {
        google.maps.event.clearListeners(circle, "click");

        if (onMapClicked) {
          circle.addListener("click", (event: any) => onMapClicked(event.latLng.toJSON()));
        }
      }
    }
  }, [onMapClicked, circle, map]);

  React.useEffect(() => {
    if (!map) initMap();
  }, []);

  return <div id={id} style={{ width: "100%", height: "100%" }}></div>;
}
