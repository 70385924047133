import { useQuestions } from "../../../hooks/question.hooks";
import { GenderPreferences, ServiceRatePrice } from "../../../models";
import {
  getGenderPreferences,
  getSelectedAddOnsForTreatment,
  getTreatmentAddOns,
  isMultipleAddonsAllowed,
  resetTreatmentAddOn,
  setTreatmentAddOn,
  useBookingStore,
} from "../../../stores/booking";
import ServiceWrapper from "../../Layout/ServiceWrapper";
import TreatmentDropdownMenu from "../../Treatment/DropdownMenu";
import Questions from "../Questions";
import AddOnDropdownMenu from "../V2/AddOn/DropdownMenu/AddOnDropdownMenu";
import { checkIfEmpty, getOnlyValuesFromObject } from "../../../utils/object";
import Dropdown from "../../Dropdown";
import {
  checkIfGenderFallbackOptionsRequired,
  getTranformedGenderOptions,
} from "../../../helpers/gender";
import GenderFallback from "../../../pages/NewBooking/GenderFallback";
import { useGetGenderPreference } from "../../../hooks/genderPreference.hook";

interface Props {
  treatments: ServiceRatePrice[];
  onTreatmentChanged: (treatmentId: number) => unknown;
  selectedTreatment?: number | null;
  onInfoModalChange: (key: any, value: boolean) => unknown;
}

const GeneralFlow = ({
  treatments,
  onTreatmentChanged,
  selectedTreatment,
  onInfoModalChange,
}: Props) => {
  const {
    serviceId,
    treatmentDetails,
    currencySymbol,
    genderFallback,
    setGenderFallback,
    genderPreference,
    setGenderPreference,
  } = useBookingStore();
  const questions = useQuestions(serviceId as number, treatmentDetails);

  const genders = useGetGenderPreference();
  const genderPreferenceOptions = getTranformedGenderOptions(genders);

  const { showGenderFallBack, genderFallbackMessage } = checkIfGenderFallbackOptionsRequired(
    genderPreference || "",
  );

  const handleTreatmentSelect = (treatment: ServiceRatePrice) => {
    onTreatmentChanged(treatment.id);
  };

  const handleAddOnSelect = ({
    treatment,
    treatmentIndex,
    isMultipleAddonsAllowed,
  }: {
    treatment: ServiceRatePrice;
    treatmentIndex: number;
    isMultipleAddonsAllowed: boolean;
  }) => {
    setTreatmentAddOn({
      treatment,
      treatmentIndex: treatmentIndex,
      isMultipleSelectAllow: isMultipleAddonsAllowed,
    });
  };

  const handleNoneSelect = (treatmentIndex: number) => {
    resetTreatmentAddOn({ treatmentIndex });
  };

  const onGenderSelect = (option: GenderPreferences) => {
    setGenderPreference(option.value);
    setGenderFallback(false);
  };

  return (
    <ServiceWrapper>
      <TreatmentDropdownMenu
        label="Treatment type"
        treatments={treatments}
        onSelect={handleTreatmentSelect}
        value={selectedTreatment}
      />

      {!checkIfEmpty(getTreatmentAddOns({ treatmentIndex: 0 })) && (
        <AddOnDropdownMenu
          label="Treatment add-ons"
          treatments={getTreatmentAddOns({ treatmentIndex: 0 })}
          onSelect={handleAddOnSelect}
          selectedAddOn={getSelectedAddOnsForTreatment({ treatmentIndex: 0 })}
          value={selectedTreatment}
          treatmentIndex={0}
          isMultipleAddonsAllowed={isMultipleAddonsAllowed({ treatmentIndex: 0 })}
          onNoneSelect={handleNoneSelect}
        />
      )}

      <Questions
        index={0}
        questions={questions}
        currency={currencySymbol}
        treatmentId={selectedTreatment || undefined}
      />

      <Dropdown
        title={"Therapist gender"}
        options={genderPreferenceOptions}
        selectedOption={genderPreferenceOptions?.find(
          (data: any) => data.value === genderPreference,
        )}
        onSelectedOption={(option: any) => onGenderSelect(option)}
      />

      {showGenderFallBack && (
        <GenderFallback
          onInfoClick={(value) => onInfoModalChange("genderFallback", value)}
          onChange={setGenderFallback}
          genderFallback={genderFallback}
          genderFallbackMessage={genderFallbackMessage}
        />
      )}
    </ServiceWrapper>
  );
};

export default GeneralFlow;
