import React, { useState } from "react";
import { Box } from "@material-ui/core";
import FormFooter from "./FormFooter";
import { useAlertStore } from "../../../../../stores/alert";
import queryClient from "../../../../../config/queryClient";
import * as Styled from "../../../../../components/v2/Styled/enum";
import { IDENTITY_KEYS } from "../../../../../hooks/proDashboard/identity/identity.hooks";
import { ListGovernmentIdentity } from "../../../../../components/ProDashboard/Business/Identity";
import {
  FormStepsName,
  IFormData,
  StripeOnboardingFormFiled,
  TIdentityVerification,
} from "./model";

interface Props {
  data: IFormData;
  isUpdating: boolean;
  isForUpdate: boolean;
  fields: StripeOnboardingFormFiled[];
  onSaveAndExit: (e: MouseEvent) => unknown;
  onChange: (n: keyof IFormData, v: any) => unknown;
  onContinue: (e: MouseEvent, to: FormStepsName) => unknown;
  onPrevious: (e: MouseEvent, to: FormStepsName) => unknown;
}

const IdentityVerificationForm = ({
  data,
  fields,
  onChange,
  onContinue,
  onPrevious,
  isForUpdate,
  onSaveAndExit,
}: Props): JSX.Element => {
  const [governmentDocumentData, setGovernmentDocumentData] = useState<TIdentityVerification>({
    documentId: null,
    documentNumber: null,
    documentPath: null,
    documentType: null,
    expiryDate: null,
  });

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const handleDocumentAdd = () => {
    setSuccessMessage("Document added.");
    queryClient.invalidateQueries([IDENTITY_KEYS.GOVERNMENT_ID]);
  };

  const validateIdentityVerification = () => {
    if (!governmentDocumentData.documentId) {
      setErrorMessage("Please select an ID document");
      return false;
    }
    return true;
  };

  const handleSave = (e: MouseEvent, cb = (e: MouseEvent) => {}) => {
    const isValid = validateIdentityVerification();
    if (!isValid) return;

    onChange(FormStepsName.IdentityVerification, governmentDocumentData);
    cb(e);
  };

  const handleSaveAndExit = (e: MouseEvent) => {
    handleSave(e, onSaveAndExit);
  };

  const handlePrevious = (e: MouseEvent) => {
    onPrevious(e, FormStepsName.Address);
  };

  const handleNext = (e: MouseEvent) => {
    handleSave(e, () => onContinue(e, FormStepsName.AccountForPayout));
  };

  return (
    <Box
      gridGap={Styled.Spacing.S8}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <ListGovernmentIdentity
        selectable
        allowUpload
        onlyRecentIds
        onUploadSuccess={handleDocumentAdd}
        selectedId={governmentDocumentData.documentId}
        onSelect={(doc: TIdentityVerification) => setGovernmentDocumentData(doc)}
      />
      <FormFooter
        onContinue={handleNext}
        onPrevious={handlePrevious}
        isForUpdate={isForUpdate}
        onSaveAndExit={handleSaveAndExit}
        style={{ marginTop: Styled.Spacing.S4 }}
      />
    </Box>
  );
};

export default IdentityVerificationForm;
