import React, { useEffect, useRef, useState } from "react";
import { Token } from "@stripe/stripe-js";
import { Box, BoxProps } from "@material-ui/core";
import FormFooter from "./FormFooter";
import BankAccountForm from "./BankAccountForm";
import Each from "../../../../../components/Each";
import { PaymentMethod } from "../../../../../models";
import { getValue } from "../../../../../utils/object";
import { Colors } from "../../../../../constants/colors";
import { useUserStore } from "../../../../../stores/user";
import { Text } from "../../../../../components/v2/Styled";
import { useAlertStore } from "../../../../../stores/alert";
import RadioButton from "../../../../../components/RadioButton";
import * as Styled from "../../../../../components/v2/Styled/enum";
import { StripeCardConnect } from "../../../../../components/Stripe/Card";
import { LOG_CHANNEL, sendErrorLog } from "../../../../../services/log/log.service";
import CardWrapper from "../../../../../components/BookingDetails/BookingDetailCard";
import { StripeCardFormHandle } from "../../../../../components/Stripe/Card/StripeCardElement";
import { getRoutingNumberLabelForCountry } from "../../../../../services/proDashboard/bank.service";
import PayoutAccountCard from "../../../../../components/ProDashboard/Business/Payouts/PayoutAccountCard";
import { CoBrandingActionModal } from "../../../../../components/ProDashboard/Business/Payouts/CoBranding";
import {
  IFormData,
  FormDataModal,
  FormFieldType,
  FormStepsName,
  PayoutAccount,
  TAccountForPayout,
  StripeOnboardingFormFiled,
} from "./model";

interface Props {
  data: IFormData;
  isUpdating: boolean;
  isForUpdate: boolean;
  fields: Array<Record<string, any>>;
  onSaveAndExit: (e: MouseEvent) => unknown;
  onChange: (n: keyof IFormData, v: any) => unknown;
  onContinue: (e: MouseEvent, to: FormStepsName) => unknown;
  onPrevious: (e: MouseEvent, to: FormStepsName) => unknown;
}

const AccountDetailForm = ({
  data,
  fields,
  onChange,
  onContinue,
  onPrevious,
  isForUpdate,
  onSaveAndExit,
}: Props): JSX.Element => {
  const cardFormRef = useRef<StripeCardFormHandle | null>(null);
  const [showWarningForAccountSwitch, setShowWarningForAccountSwitch] = useState<boolean>(false);

  const { user } = useUserStore();
  const { setErrorMessage } = useAlertStore();

  const routingNumberLabel = getRoutingNumberLabelForCountry(user?.country);

  const [accountDetail, setAccountDetail] = useState<TAccountForPayout>(
    FormDataModal[FormStepsName.AccountForPayout]
  );

  useEffect(() => {
    preSelectExistingAccount();
  }, [data]);

  const preSelectExistingAccount = () => {
    if (!data) return;
    let accountInfo = data[FormStepsName.AccountForPayout] || {};
    if (!accountInfo.type) {
      accountInfo = {
        ...accountDetail,
        type: PayoutAccount.BankAccount,
      };
    }

    setAccountDetail(accountInfo);
  };

  const handleChange = (field: string, value: string) => {
    setAccountDetail({
      ...accountDetail,
      [field]: value,
    });
  };

  const onCardAdd = (
    e: MouseEvent,
    token: Token | undefined,
    cb = (e: MouseEvent, d: TAccountForPayout): any => {}
  ) => {
    const cardToken = getValue(token, "id");
    const type = "card";
    const last4 = getValue(token, "card.last4");
    const cardType = getValue(token, "card.brand");
    const expiryMonth = getValue(token, "card.exp_month");
    const expiryYear = getValue(token, "card.exp_year");

    const newState = {
      ...accountDetail,
      token: cardToken,
      details: {
        type,
        last4,
        cardType,
        expiryYear,
        expiryMonth,
      } as PaymentMethod,
    };
    setAccountDetail(newState);
    cb(e, newState);
  };

  const resetAccountForType = (type: PayoutAccount) =>
    setAccountDetail({
      ...accountDetail,
      token: null,
      details: {} as PaymentMethod,
      type: type === PayoutAccount.BankAccount ? PayoutAccount.BankAccount : PayoutAccount.Card,
    });

  const confirmAccountSwitch = () => {
    const existingAccountType = getValue(data[FormStepsName.AccountForPayout], "type");

    resetAccountForType(
      existingAccountType === PayoutAccount.BankAccount
        ? PayoutAccount.Card
        : PayoutAccount.BankAccount
    );
    setShowWarningForAccountSwitch(false);
  };

  const handleSelect = (type: string) => {
    const existingAccountType = getValue(data[FormStepsName.AccountForPayout], "type");
    const isAccountLinked = !!getValue(data[FormStepsName.AccountForPayout], "token");
    if (type === existingAccountType) {
      preSelectExistingAccount();
    } else if (isAccountLinked) {
      setShowWarningForAccountSwitch(true);
    } else {
      resetAccountForType(type as PayoutAccount);
    }
  };

  const handleCardAdd = async (e: MouseEvent, cb: (e: MouseEvent, d: any) => {}) => {
    try {
      if (cardFormRef.current) {
        await cardFormRef.current?.onSubmit(e, cb);
      }
    } catch (err) {
      sendErrorLog({
        domain: "STRIPE_CARD_CONNECT",
        log: {
          title: "Error on handle submit",
          message: getValue(err, "message"),
          data: err,
        },
        channel: LOG_CHANNEL.LOGGER,
      });
    }
  };

  const validatePayoutAccount = (accountDetail: TAccountForPayout) => {
    const { type } = accountDetail;

    if (type === PayoutAccount.BankAccount) {
      if (accountDetail.token) {
        return true;
      }
      if (!accountDetail.accountNumber) {
        setErrorMessage("Account number is required");
        return false;
      }
      if (!accountDetail.confirmAccountNumber) {
        setErrorMessage("Confirm account number is required");
        return false;
      }
      if (accountDetail.accountNumber !== accountDetail.confirmAccountNumber) {
        setErrorMessage("Account number does not match");
        return false;
      }
      if (!accountDetail.routingNumber) {
        setErrorMessage(`${routingNumberLabel} is required`);
        return false;
      }
    }

    if (type === PayoutAccount.Card && !accountDetail.token) {
      setErrorMessage("Error adding card details");
      return false;
    }

    return true;
  };

  const handleSave = async (e: MouseEvent, cb = (e: MouseEvent): any => {}) => {
    const initiateValidate = (e: MouseEvent, accountDetail: TAccountForPayout): any => {
      const isValid = validatePayoutAccount(accountDetail);
      if (!isValid) return;

      onChange(FormStepsName.AccountForPayout, accountDetail);
      cb(e);
    };

    const { type, token } = accountDetail;
    if (type === PayoutAccount.Card && !token) {
      await handleCardAdd(e, initiateValidate);
      return;
    }

    initiateValidate(e, accountDetail);
  };

  const handleExistingAccountChange = () =>
    setAccountDetail({
      ...accountDetail,
      token: null,
    });

  const handlePrevious = (e: MouseEvent) => {
    const submitted = getValue(data[FormStepsName.IdentityVerification], "documentProvided");
    const previous = submitted ? FormStepsName.Address : FormStepsName.IdentityVerification;

    onPrevious(e, previous);
  };

  const handeNext = async (e: MouseEvent) => {
    await handleSave(e, () => onContinue(e, FormStepsName.ReviewAndConfirm));
  };

  const TBox = (props: BoxProps) => (
    <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column} {...props} />
  );

  const buildRow = (field: StripeOnboardingFormFiled) => {
    const type = getValue(field, "type", "");

    const buildRadioField = (field: any) => {
      const name = getValue(field, "name", "");
      const isSelected = accountDetail.type === name;
      const label = getValue(field, "label", "");
      const description = getValue(field, "description", "");

      const Form = ((name: PayoutAccount) => {
        switch (name) {
        case PayoutAccount.Card:
          return <StripeCardConnect ref={cardFormRef} onSuccess={onCardAdd} />;
        case PayoutAccount.BankAccount:
        default:
          return <BankAccountForm accountDetails={accountDetail} onSave={handleChange} />;
        }
      })(name);

      return (
        <TBox gridGap={Styled.Spacing.S8}>
          <TBox gridGap={Styled.Spacing.S4}>
            <TBox gridGap={Styled.Spacing.S1}>
              <Box display={Styled.Display.Flex} alignItems={Styled.AlignItems.center}>
                <RadioButton
                  selected={isSelected}
                  disableRipple={false}
                  onSelected={() => handleSelect(name)}
                />
                <Box>
                  <Text
                    color={Colors.Dusk}
                    size={Styled.FontSize.F18}
                    font={Styled.FontFamily.Museo}
                    weight={Styled.FontWeight.Bold}
                    lineHeight={Styled.LineHeight.L27}
                  >
                    {label}
                  </Text>
                </Box>
              </Box>
              {description ? (
                <Box display={Styled.Display.Flex} alignItems={Styled.AlignItems.center}>
                  <Box width={Styled.Spacing.S9} />
                  <Text size={Styled.FontSize.F14}>{description}</Text>
                </Box>
              ) : (
                <></>
              )}
            </TBox>

            {isSelected ? (
              <CardWrapper
                style={{
                  margin: 0,
                  padding: 0,
                  width: "100%",
                  borderRadius: "8px",
                  borderColor: Colors.LightPeriwinkle,
                }}
              >
                {!!accountDetail.token ? (
                  <Box paddingX={Styled.Spacing.S4} paddingY={Styled.Spacing.S4}>
                    <PayoutAccountCard
                      account={accountDetail}
                      details={accountDetail.details as PaymentMethod}
                      onChange={handleExistingAccountChange}
                    />
                  </Box>
                ) : (
                  <Box paddingX={Styled.Spacing.S8} paddingY={Styled.Spacing.S6}>
                    {Form}
                  </Box>
                )}
              </CardWrapper>
            ) : (
              <></>
            )}
          </TBox>
          <Box bgcolor={Colors.LightPeriwinkle} height={"1px"} />
        </TBox>
      );
    };

    if (type === FormFieldType.Radio) {
      const values = getValue(field, "values", []);
      return <Each of={values} render={buildRadioField} />;
    }
  };

  return (
    <TBox gridGap={Styled.Spacing.S8}>
      <Each of={fields} render={buildRow} />
      <FormFooter
        onContinue={handeNext}
        onPrevious={handlePrevious}
        isForUpdate={isForUpdate}
        style={{ marginTop: Styled.Spacing.S4 }}
      />
      <CoBrandingActionModal
        open={showWarningForAccountSwitch}
        onConfirm={confirmAccountSwitch}
        confirmText={"Yes, proceed"}
        title={"Change payout account?"}
        onClose={() => setShowWarningForAccountSwitch(false)}
        description={
          "To switch your payout method between a bank account and a card and vice-versa, you'll need to remove the current payment details first. Would you like to proceed?"
        }
      />
    </TBox>
  );
};

export default AccountDetailForm;
