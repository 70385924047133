import { getValue } from "../../utils/object";
import { get, post, put } from "../../api/client";
import queryClient from "../../config/queryClient";
import { COUNTRY_STATE_KEYS } from "../../hooks/utility/states.hooks";
import { STRIPE_KEYS } from "../../hooks/stripe/connect/stripeConnect.hooks";

const getAccountStatus = () => get("api/v2/stripe/connect/custom/account-status");
const createAccountLink = () => get("api/v2/stripe/connect/custom/create-account-link");
const createAccountSession = () => post("api/v2/stripe/connect/custom/account-session", {});
const initiateOnboarding = () => put("api/v2/stripe/connect/custom/initiate-onboarding", {});
const getOnboardingFormOptions = () => get("api/v2/stripe/connect/custom/onboarding-form-options");
const getAccountRequirements = () => get("api/v2/stripe/connect/custom/check-account-requirements");
const getPayoutDetails = () => get("api/v2/stripe/connect/custom/payout-details");
const updatePayoutDetails = (body: any) =>
  post("api/v2/stripe/connect/custom/update-account", body);
const uploadAdditionalInfo = (body: FormData) =>
  post("api/v2/stripe/connect/custom/submit-additional-requirement", body);

const getExpressAccountStatus = () => get("api/v2/stripe/express/account-status");
const createExpressAccountLink = () => get("api/v2/stripe/express/create-account-link");

const getStripeAccount = async () => {
  const accountLinkResponse = await createAccountLink();
  return getValue(accountLinkResponse, "data", {});
};

const invalidateOnboardingData = () => {
  queryClient.invalidateQueries({ queryKey: [STRIPE_KEYS.ACCOUNT_ONBOARDING_FORM_OPTION] });
  queryClient.invalidateQueries({ queryKey: [STRIPE_KEYS.ACCOUNT] });
  queryClient.invalidateQueries({ queryKey: [COUNTRY_STATE_KEYS.STATE_OPTIONS] });
};

const invalidateAccountStatus = () => {
  queryClient.invalidateQueries({ queryKey: [STRIPE_KEYS.ACCOUNT_STATUS] });
  queryClient.invalidateQueries({ queryKey: [STRIPE_KEYS.ACCOUNT_REQUIREMENTS] });
};

export {
  getStripeAccount,
  getAccountStatus,
  createAccountLink,
  getPayoutDetails,
  initiateOnboarding,
  updatePayoutDetails,
  uploadAdditionalInfo,
  createAccountSession,
  getAccountRequirements,
  getExpressAccountStatus,
  invalidateAccountStatus,
  createExpressAccountLink,
  getOnboardingFormOptions,
  invalidateOnboardingData,
};
