import React from "react";
import { Box } from "@material-ui/core";
import RecipientItem from "./RecipientItem";
import { CorporateRecipient } from "../../../models";

interface Props {
  recipients: CorporateRecipient[];
  selectedRecipient: number | null | undefined;
  handleSelect: (id: number, role: string) => unknown;
}

const RecipientModalList = ({
  recipients,
  selectedRecipient,
  handleSelect,
}: Props): JSX.Element => (
  <Box>
    {recipients.map((recipient: CorporateRecipient) => {
      const userRole = recipient.role ? recipient.role.label : "recipient";

      const transformedRecipient = {
        id: recipient.id,
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        email: recipient.email,
        role: recipient.role ? recipient.role.label : "recipient", 
      };
      return (
        <RecipientItem
          recipient={transformedRecipient}
          selected={recipient.id === selectedRecipient}
          onSelect={() => handleSelect(recipient.id, userRole)}
        />
      );
    })}
  </Box>
);

export default RecipientModalList;
