import axios, { AxiosResponse } from "axios";

export const getServiceArea = (accessToken: string) => {
  return axios
    .get("api/v2/therapists/service-area", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => res.data as AxiosResponse);
};

export const getRadius = (accessToken: string | null = null) => {
  return axios
    .get("api/v2/therapists/service-area/radius", {
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : null,
      },
    })
    .then((res) => res.data as AxiosResponse);
};

export const updateServiceArea = (data: any, accessToken: any) => {
  return axios
    .put(
      "api/v2/therapists/service-area",
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((res) => res.data as AxiosResponse);
};
