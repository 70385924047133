// REMINDER: Must be served via HTTPS to work!
export const applePaySupported = () => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

  // NOTE: Temporarily disable for Mac OS, due to freezing issues
  if (!isIOS) {
    return false;
  }

  if (window.location.protocol !== "https:") {
    return false;
  }

  if (!window.ApplePaySession) {
    return false;
  }

  let ApplePaySession = window.ApplePaySession;

  if (ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()) {
    // This device supports version 3 of Apple Pay.
    return true;
  }

  return false;
};
