import { Box, ButtonBase, Typography } from "@material-ui/core";
import React from "react";

import { Colors } from "../../../constants/colors";
import DropdownArrow from "../../../images/dropdown-arrow.svg";
import RemoveAllLanguageIcon from "../../../images/cross-indigo.svg";
import RemoveSingleLanguageIcon from "../../../images/cross-black.svg";
import DividerIcon from "../../../images/divider.png";
import { checkIfEmpty } from "../../../utils/object";

interface Props {
  placeholder: string;
  value?: any[];
  onClick: () => unknown;
  onLanguageRemove: (lang: string) => unknown;
  removeAllSelectedLanguage: () => unknown;
}

const LanguageDropdownValueHolder = ({
  placeholder,
  value,
  onClick,
  onLanguageRemove,
  removeAllSelectedLanguage,
}: Props) => {
  return (
    <ButtonBase style={{ width: "100%" }} onClick={onClick}>
      <Box display="flex" flexWrap="wrap" mt={1} mb={1} flex={1} gridGap={12}>
        {checkIfEmpty(value) && (
          <Typography
            style={{
              lineHeight: "32px",
              textAlign: "left",
              marginBottom: "0px",
              color: Colors.LightBlueGrey,
              width: "100%",
            }}
          >
            {placeholder}
          </Typography>
        )}

        {value?.map((data) => (
          <Box
            bgcolor={Colors.CornflowerBlue}
            paddingX={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gridGap={10}
            style={{ cursor: "default" }}
          >
            <Typography
              style={{
                lineHeight: "32px",
                textAlign: "left",
                marginBottom: "0px",
                color: value ? Colors.TurquoiseBlue : Colors.LightBlueGrey,
                width: "100%",
                cursor: "default",
              }}
            >
              {data}
            </Typography>
            <Box
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onLanguageRemove(data);
              }}
            >
              <img src={RemoveSingleLanguageIcon} alt="Close" />
            </Box>
          </Box>
        ))}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" gridGap={12} ml={3}>
        {!checkIfEmpty(value) && (
          <>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                removeAllSelectedLanguage();
              }}
            >
              <img src={RemoveAllLanguageIcon} alt="Close Arrow" width="12px" height={"12px"} />
            </Box>
            <img
              src={DividerIcon}
              alt="Close Arrow"
              width={"2px"}
              height="50px"
              style={{ paddingBottom: "5px" }}
            />
          </>
        )}
        <img src={DropdownArrow} alt="Dropdown Arrow" />
      </Box>
    </ButtonBase>
  );
};

export default React.memo(LanguageDropdownValueHolder);
