import React from "react";
import { Box } from "@material-ui/core";

import Map from "../../../../components/Map";
import BookingStatusPill from "../../../../components/Bookings/V2/BookingStatusPill";
import { BookingUpdateStatus, JobStatus } from "../../../../constants/booking";
import { Colors } from "../../../../constants/colors";
import LinearProgressBar from "../../../../components/LinearProgressBar/LinearProgressBar";

interface Address {
  latitude: number;
  longitude: number;
}

interface Props {
  mapId: string;
  status: JobStatus;
  address: Address | null;
  timeDetail: string;
  bookingStatus?: string;
}

const CardBody = ({mapId, address, status, timeDetail, bookingStatus }: Props) => {
  if (!address) return <Box />;

  const { latitude, longitude } = address;

  const getProgressAmount = (status: string) => {
    switch (status) {
    case JobStatus.finished:
    case JobStatus.reviewLeft:
      return 100;

    case JobStatus.onway:
      return 80;

    case JobStatus.accepted:
    case BookingUpdateStatus.requestByClient:
      return 40;

    case JobStatus.pending:
    case JobStatus.rebooking:
    default:
      return 5;
    }
  };

  const bookingProgress = getProgressAmount(status);

  return (
    <>
      {latitude && longitude && (
        <Box height='100px' mt='22px'>
          <Map id={mapId} latitude={latitude} longitude={longitude} />
        </Box>
      )}

      <Box mt='22px' display={"inline-flex"}>
        <BookingStatusPill
          status={status}
          bookingStatus={bookingStatus}
        />
      </Box>
        

      <Box display='flex' flexDirection='row'>
        <Box fontFamily='Open Sans' fontSize='14px' color={Colors.Dusk} mt='7px'>
          {timeDetail}
        </Box>
      </Box>

      <Box mt='18px'>
        <LinearProgressBar percent={bookingProgress}/>
      </Box>
    </>
  );
};

export default CardBody;
