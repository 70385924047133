import { Box } from "@material-ui/core";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";
import { FontFamily, FontSize, FontWeight, TextAlign } from "../../../../components/v2/Styled/enum";

interface Props {
  visible: boolean;
  title: string;
  description?: string;
  titleStyle?: any;
  contentStyle?: any;
  onClose: () => unknown;
  onConfirm: () => unknown;
}

const ConfirmationModal = ({
  visible,
  title,
  description,
  titleStyle = {},
  contentStyle = {},
  onClose,
  onConfirm
}: Props) => {
  return <>
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      contentStyle={{ padding: "32px" }}
      childrenStyle={{ marginTop: "0px" }}
      actionStyle={{ padding: "0px 32px 0px 32px", marginBottom: "32px" }}
      actions={[
        <Box
          style={{
            flex: 1,
            gap: "8px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button title="Back" type={ButtonType.outlined} onClick={onClose} />
          <Button title="Confirm" type={ButtonType.secondary} onClick={onConfirm} />
        </Box>,
      ]}
    >
      <Box>
        <Box
          fontSize={FontSize.F24}
          color={Colors.NightBlue}
          textAlign={TextAlign.center}
          fontWeight={FontWeight.Bold}
          fontFamily={FontFamily.Museo}
          style={{ ...titleStyle }}
        >
          {title}
        </Box>
        {description && <Box
          mb="5px"
          mt="16px"
          fontWeight={FontWeight.Medium}
          fontSize={FontSize.F16}
          textAlign={TextAlign.center}
          fontFamily={FontFamily.Museo}
          color={Colors.Grey}
          style={{ ...contentStyle }}
        >
          {description}
        </Box>}
      </Box>
    </ContentModal>
  </>;
};

export default ConfirmationModal;
