import React from "react";
import { isNil } from "lodash";
import { Box } from "@material-ui/core";
import { useMobile } from "../../../hooks/mobile";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import ProfilePictureEdit from "../../../images/profile-picture-edit.svg";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";

interface ProfileImageProps {
  getRootProps: () => any;
  getInputProps: () => any;
  selectedImageFile?: File | null;
  profileImage?: string | null;
}

const profileImageStyle = {
  width: "80px",
  height: "80px",
  borderRadius: "40px",
  "object-fit": "cover",
};

const ProfileImage: React.FC<ProfileImageProps> = ({
  getRootProps,
  getInputProps,
  selectedImageFile,
  profileImage,
}) => {
  const isMobile = useMobile();

  return (
    <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
      <div {...getRootProps()} style={{ cursor: "pointer", position: "relative" }}>
        <input {...getInputProps()} />

        {selectedImageFile && (
          <img
            alt="Selected profile pic"
            src={URL.createObjectURL(selectedImageFile)}
            style={profileImageStyle}
          />
        )}

        {!selectedImageFile && profileImage && (
          <img
            alt="Profile pic"
            src={`${BASE_UPLOADS_URL}/${profileImage}`}
            style={profileImageStyle}
          />
        )}

        {!selectedImageFile && isNil(profileImage) && (
          <img
            alt="Profile pic placeholder"
            src={ProfilePicturePlaceholder}
            style={profileImageStyle}
          />
        )}

        <img
          src={ProfilePictureEdit}
          alt="Pic"
          style={{ position: "absolute", top: -20, left: -20 }}
        />
      </div>
    </Box>
  );
};

export default ProfileImage;
