import { Box } from "@material-ui/core";
import { useState } from "react";
import { DEFAULT_CURRENCY } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { useAlertStore } from "../../stores/alert";
import { getValue } from "../../utils/object";
import Button, { ButtonSize, ButtonType } from "../Button";
import Divider from "../Divider";
import PaymentModal from "../PaymentFrame/PaymentModal";
import { AlignItems, Display, FlexDirection, JustifyContent, Spacing } from "../v2/Styled/enum";
import { CompleteAfterPayButton } from "@get-blys/ui.complete-after-pay-button";
import CompleteAfterpayPayment from "./CompleteAfterpayPayment";
import { useHistory } from "react-router-dom";

export default function AfterPayPaymentPending({
  booking,
  isMobile = false,
  showPaymentInfo = true,
  showPaymentHeader = true,
  bookingMutate,
  divider = true,
  showMakePaymentBtn = true,
}: {
  booking: any;
  isMobile?: boolean;
  showPaymentInfo?: boolean;
  showPaymentHeader?: boolean;
  bookingMutate: () => unknown;
  divider?: boolean;
  showMakePaymentBtn?: boolean;
}) {
  const history = useHistory();
  const buttonWidth = isMobile ? "100%" : "148px";
  const { setSuccessMessage } = useAlertStore();
  const [enablePaymentModal, setEnablePaymentModal] = useState(false);
  const onSuccess = () => {
    bookingMutate();
    setSuccessMessage("Payment settled. Your booking is now confirmed.");
  };

  const isPendingAmountDueZero = booking.totalAmountDue === 0;

  if (booking.statusFlag?.isPending || isPendingAmountDueZero) {
    return <></>;
  }

  const currencySymbol = getValue(
    booking,
    "service.selectedCountry.currencySymbol",
    DEFAULT_CURRENCY.currencySymbol
  );

  const completeAfterPay = (bookingId: number) => {
    history.push(`/afterpay-process-order/${bookingId}`, {
      autoPay: true,
    });
  };

  return (
    <>
      {divider && <Divider mb={"16px"} mt={"16px"} />}
      <Box
        display="flex"
        justifyContent={"space-between"}
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
        gridGap={"16px"}
      >
        {showPaymentInfo && (
          <Box>
            {showPaymentHeader && (
              <Box
                marginBottom={"8px"}
                fontFamily="Museo"
                fontWeight={700}
                fontSize="18"
                color={Colors.NightBlue}
              >
                Pending payment
              </Box>
            )}

            <Box
              fontSize="16"
              style={{
                marginTop: "0px",
              }}
              fontWeight={500}
              fontFamily="Museo"
              color={Colors.Dusk}
            >
              The pending amount of the booking to be paid is {currencySymbol}
              {(booking.totalAmountDue || booking.price || booking.bookingPrice?.price)?.toFixed(2)}
              .
            </Box>
          </Box>
        )}
      </Box>
      <Box
        display={Display.Flex}
        justifyContent={JustifyContent.spaceBetween}
        alignItems={AlignItems.center}
        flexDirection={isMobile ? FlexDirection.Column : FlexDirection.Row}
        marginTop={Spacing.S5}
        gridGap={Spacing.S2}
      >
        <Box>
          <CompleteAfterPayButton
            isMobile={isMobile}
            onPress={() => completeAfterPay(booking.id)}
          />
        </Box>
        <Button
          type={ButtonType.secondary}
          size={ButtonSize.small}
          height={48}
          width={"100%"}
          colorTitle={Colors.White}
          title="Make payment"
          fontStyle={{
            fontFamily: "Museo",
            fontWeight: 600,
            fontSize: 14,
          }}
          style={{ borderRadius: 4, padding: isMobile ? "10px" : undefined }}
          onClick={() => setEnablePaymentModal(true)}
        />
      </Box>
      <PaymentModal
        booking={booking}
        open={enablePaymentModal}
        onSuccess={onSuccess}
        onClose={() => setEnablePaymentModal(false)}
      />
    </>
  );
}
