import React from "react";
import {
  Box,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import "./infoModal.css";
import { Text } from "../../v2/Styled";
import { parseBoldText } from "../ContentParser";
import { FontFamily } from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";

interface Props {
  title: string;
  visible: boolean;
  actionName?: string;
  description: string;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  handleClose: () => unknown;
  divider?: boolean;
  data?: string[];
  titleStyle?: object;
  descriptionStyle?: object;
  formatHtml?: boolean;
}

const InfoModal = ({
  title,
  data = [],
  visible,
  description,
  handleClose,
  divider = true,
  maxWidth = "xs",
  actionName = "Ok",
  titleStyle = {},
  descriptionStyle = {},
  formatHtml = false,
}: Props) => {
  return (
    <MUIDialog open={visible} maxWidth={maxWidth} onClose={handleClose}>
      <Box display="flex" alignItems="center" position="relative" mt={"32px"} maxWidth={"438px"} mb={"16px"}>
        <DialogTitle style={{ flex: 1, padding: 0 }}>
          <Typography>
            <Box className="infoModalTitle" style={titleStyle}>
              {title}
            </Box>
          </Typography>
        </DialogTitle>
      </Box>
      <DialogContent dividers={divider} style={{ padding: 0 }}>
        <Box gridGap={"16px"} display={"flex"} flexDirection={"column"} mx={"32px"} px={"24px"}>
          {description ? (
            <div className="infoModalDescription">
              {formatHtml ? (
                parseBoldText(description)
              ) : (
                <Text font={FontFamily.Museo} color={Colors.Grey}>
                  {description}
                </Text>
              )}
            </div>
          ) : (
            <React.Fragment />
          )}
          {data && data.length ? (
            <Box gridGap={"12px"} display={"flex"} flexDirection={"column"} textAlign={"center"}>
              {data.map((item) => (
                <div>
                  {formatHtml ? (
                    parseBoldText(item)
                  ) : (
                    <Text font={FontFamily.Museo} color={Colors.Grey}>
                      {item}
                    </Text>
                  )}
                </div>
              ))}
            </Box>
          ) : (
            <React.Fragment />
          )}
        </Box>
      </DialogContent>
      <DialogActions className="infoModalFooter" style={{ padding: "16px" }}>
        <Button
          onClick={handleClose}
          style={{
            width: "100%",
            color: Colors.NightBlue,
            border: "1px solid #DCDFE6"
          }}
        >
          {actionName}
        </Button>
      </DialogActions>
    </MUIDialog>
  );
};

export default InfoModal;