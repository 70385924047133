export const MFA_APP_NAME = ((env) => {
  switch (env) {
  case "production":
    return "Blys";
  case "staging":
    return "Blys Staging";
  default:
    return "Blys Local";
  }
})(process.env.REACT_APP_ENV);

export const MFA_TYPE = {
  SMS: "sms",
  APP: "app",
};
