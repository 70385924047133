import React from "react";
import { Box, withStyles } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Colors } from "../constants/colors";
import { getFormatRespectToCountry } from "../utils/date.util";

interface Props {
  title?: string;
  selectedDate: Date | null;
  onSelectedDate: (date: Date | null) => unknown;
  width?: string | number;
  disableFuture?: boolean;
  country?: string;
  isDisabled?: boolean;
  color?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

const StyledField = withStyles({
  root: {
    "& .MuiFormLabel-root.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:before": {
      content: "none !important",
    },
    "& .MuiInput-underline:after": {
      content: "none !important",
    },
    marginTop: "10px",
    width: "100%",
    height: "30px",
  },
})(KeyboardDatePicker);

export default function ({
  title = "Date",
  selectedDate,
  onSelectedDate,
  width,
  country = "AU",
  disableFuture = false,
  isDisabled = false,
  color,
  paddingTop = "21px",
  paddingBottom = "21px",
}: Props): JSX.Element {
  return (
    <Box width={width} paddingTop={paddingTop} paddingBottom={paddingBottom}>
      {title && (
        <Box fontFamily="Museo" fontSize="14px" color={Colors.Dusk} fontWeight={700}>
          {title}
        </Box>
      )}
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StyledField
          format={getFormatRespectToCountry(country)}
          margin="normal"
          id="date-picker"
          value={selectedDate}
          onChange={(date, value) => {
            date && onSelectedDate(date.toDate());
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          placeholder={getFormatRespectToCountry(country)}
          disableFuture={disableFuture}
          disabled={isDisabled}
          style={{ color: Colors.BlueyGrey }}
        />
      </MuiPickersUtilsProvider>

      <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
    </Box>
  );
}
