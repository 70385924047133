import React from "react";
import { ButtonBase, Box, CircularProgress } from "@material-ui/core";
import { Colors } from "../constants/colors";
import Logo from "../images/afterpay-logo-alt.png";
import { useMobile } from "../hooks/mobile";

interface Props {
  loading?: boolean;
  onClick: () => unknown;
}

const buttonHeight = 50.0;


export default function ({ loading = false, onClick }: Props) {
  const isMobile = useMobile();

  return (
    <ButtonBase
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "3px",
        width: "100%",
        height: buttonHeight,
        backgroundColor: Colors.NightBlue,
      }}
      onClick={onClick}
    >
      <Box
        fontSize={isMobile ? 14 : 16}
        color="#FFFFFF"
        fontFamily="Museo"
        fontWeight={700}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        {loading ? (
          <CircularProgress
            variant="indeterminate"
            style={{ color: "white" }}
            size={buttonHeight * 0.6}
            thickness={4}
          />
        ) : (
          <Box display="flex" flexDirection="row" alignItems="center">
            Continue with&nbsp;&nbsp;{" "}
            <img src={Logo} height={buttonHeight * 0.6} />
          </Box>
        )}
      </Box>
    </ButtonBase>
  );
}
