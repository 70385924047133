import { useRef } from "react";
import { Box } from "@material-ui/core";

import { deleteApi } from "../../../api/client";
import { Colors } from "../../../constants/colors";
import TextLink from "../../../components/TextLink";
import Dropdown from "../../../components/Dropdown";
import DateField from "../../../components/DateField";
import { useAlertStore } from "../../../stores/alert";
import TextField from "../../../components/TextField";
import { parseApiError } from "../../../helpers/error";
import Button, { ButtonType } from "../../../components/Button";
import GovernmentUploadedFiles from "../../../components/ProDashboard/GovernmentUploadedFiles";
import {
  IGovermentDoumentsResponse,
  IGovernmentData,
  IGovernmentOption,
  IGovernmentOptionField,
  ISelectedDocument,
} from "./identity.interface";
import { useUserStore } from "../../../stores/user";

interface GovernmentDocumentProps {
  fileOwnership: string;
  options: IGovernmentOption[];
  onChange: (data: any) => void;
  governmentData: IGovernmentData;
  uploadedFilesMutate: () => void;
  uploadedFilesData: IGovermentDoumentsResponse[];
}

export default function GovernmentDocuments({
  options,
  onChange,
  fileOwnership,
  governmentData,
  uploadedFilesData,
  uploadedFilesMutate,
}: GovernmentDocumentProps) {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { documentType, selectedDocument, selectedFiles } = governmentData;
  const { user } = useUserStore();

  const currentDropdownOption = options.find((option) => option.value === documentType);

  const handleRemoveDocument = (governmentDocumentId: number, fileId: number) => {
    deleteApi(`api/v2/therapists/identity/government-documents/${governmentDocumentId}/${fileId}`)
      .then((response) => {
        uploadedFilesMutate();
        setSuccessMessage("The file has been deleted successfully");
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  };
  return (
    <Box>
      <Box
        mt="32px"
        fontSize="18px"
        fontWeight={600}
        lineHeight="27px"
        fontFamily="Museo"
        color={Colors.NightBlue}
      >
        Your documents
      </Box>

      <Box
        mt="8px"
        fontSize="14px"
        fontWeight={400}
        lineHeight="21px"
        fontFamily="Open Sans"
        color={Colors.Dusk}
      >
        PDF, JPG and PNG files accepted. We recommend using a scanning app when photographing
        documents.
      </Box>
      {uploadedFilesData?.length > 0 ? (
        <Box>
          <GovernmentUploadedFiles
            uploadedFilesData={uploadedFilesData}
            onRemove={handleRemoveDocument}
            options={options.map((option) => ({ value: option.value, title: option.title }))}
            country={user?.country}
          />
        </Box>
      ) : (
        <Box
          mt="24px"
          fontSize="14px"
          fontWeight={400}
          lineHeight="21px"
          fontFamily="Open Sans"
          color={Colors.BlueyGrey}
        >
          No document uploaded
        </Box>
      )}

      <Box
        mt="40px"
        fontSize="16px"
        fontWeight={700}
        lineHeight="24px"
        fontFamily="Open Sans"
        color={Colors.Dusk}
      >
        Add document
      </Box>

      <Box mt="8px">
        <Dropdown
          title={"Document type"}
          options={options}
          onSelectedOption={(option) => {
            onChange((v: IGovernmentData) => ({ ...v, documentType: option.value }));
          }}
          selectedOption={currentDropdownOption}
        />
      </Box>

      {documentType && (
        <Box>
          {currentDropdownOption?.fields.map((field: IGovernmentOptionField) => (
            <Box key={field.name}>
              {field.type === "text" && (
                <TextField
                  title={field.title}
                  placeholder={field.placeholder}
                  value={selectedDocument[field.type as keyof ISelectedDocument] as string}
                  onChange={(text) =>
                    onChange((v: IGovernmentData) => ({
                      ...v,
                      selectedDocument: {
                        ...v.selectedDocument,
                        [field.type]: text,
                      },
                    }))
                  }
                />
              )}
              {field.type === "date" && (
                <DateField
                  title={field.title}
                  selectedDate={
                    selectedDocument[field.type as keyof ISelectedDocument] as Date | null
                  }
                  onSelectedDate={(date) =>
                    onChange((v: IGovernmentData) => ({
                      ...v,
                      selectedDocument: {
                        ...v.selectedDocument,
                        [field.type]: date,
                      },
                    }))
                  }
                  width="100%"
                  country={user?.country}
                />
              )}
            </Box>
          ))}
          {currentDropdownOption?.note && (
            <Box
              fontSize="14px"
              fontWeight={400}
              lineHeight="21px"
              fontFamily="Open Sans"
              color={Colors.BlueyGrey}
            >
              {currentDropdownOption.note}
            </Box>
          )}

          <Box>
            {selectedFiles.length > 0 &&
              selectedFiles.map((selectedFile: File, i: number) => (
                <Box
                  key={i}
                  mt="9px"
                  mb="9px"
                  display="flex"
                  maxWidth="500px"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Box
                    fontFamily="Open Sans"
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="21px"
                    flex={1}
                    color={Colors.Dusk}
                  >
                    {selectedFile.name}
                  </Box>

                  <TextLink
                    title="Remove"
                    destructive
                    onClick={() => {
                      onChange((v: IGovernmentData) => ({
                        ...v,
                        selectedFiles: v.selectedFiles.filter(
                          (file: File, idx: number) => idx !== i
                        ),
                      }));
                    }}
                  />
                </Box>
              ))}
          </Box>

          <Box mt="37px" mb="32px" display="flex" flexDirection="row">
            <Button
              style={{ marginRight: "8px" }}
              title="Select file"
              type={ButtonType.outlined}
              width="132px"
              onClick={() => {
                if (fileUploadRef.current) {
                  fileUploadRef.current.click();
                }
              }}
            />
          </Box>
        </Box>
      )}

      <Box>
        <form encType="multipart/form-data">
          <input
            type="file"
            accept="image/*, application/pdf"
            ref={fileUploadRef}
            style={{ display: "none" }}
            id={`${fileOwnership}-upload-file`}
            onChange={(event) => {
              event.stopPropagation();
              event.preventDefault();

              const file = event?.target?.files && event.target.files[0];

              if (file) {
                onChange((v: IGovernmentData) => ({
                  ...v,
                  selectedFiles: [...v.selectedFiles, file],
                }));
              }
            }}
          />
        </form>
      </Box>
    </Box>
  );
}
