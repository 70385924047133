import { post } from "../../api/client";

export const LOG_CHANNEL = {
  SLACK: "SLACK",
  LOGGER: "LOGGER",
};

const sendErrorLog = ({
  domain,
  log,
  channel,
}: {
  domain: string;
  log: { title?: string; message?: string; stack?: any; data?: any };
  channel: (typeof LOG_CHANNEL)[keyof typeof LOG_CHANNEL];
}) => {
  const url = "api/v2/notifications/dev";

  return post(url, {
    domain,
    log,
    channel,
  });
};

export { sendErrorLog };
