import axios from "axios";
import moment from "moment-timezone";
import create from "zustand";
import { persist } from "zustand/middleware";
import { put } from "../api/client";
import { PROVIDER_OPTION_TYPE } from "../components/Bookings/PreferredProviders/enum";
import {
  bookingFrequencyType,
  BUFFER_TIME_HOURS,
  DEFAULT_CURRENCY,
  MassageFor,
  Parking,
  PRICE_TYPE,
  RecurringType,
  Relationship,
  Stairs,
} from "../constants/booking";
import { BOOKING_PRICE_MASTER_TOKEN } from "../constants/common";
import { PaymentType } from "../constants/payment";
import { QUESTION_TYPE } from "../constants/questionType";
import { SERVICE_TYPE } from "../constants/serviceType";
import { Storage } from "../constants/storage";
import {
  DEFAULT_MAX_TIME,
  DEFAULT_MIN_TIME,
  MIN_HOUR_ALLOWED_TODAY,
  RECURRING_ENDS,
} from "../constants/time";
import { getAdminAccessToken } from "../helpers/accessToken";
import {
  BOOKING_SESSION_TYPE,
  formatMassageTreatmentDetailsData,
  formatTreatmentAddOnData,
  formatTreatmentDetailsData,
  tranformTreatmentAddOn,
} from "../helpers/booking";
import { isCorporateSession } from "../helpers/massage";
import { getCountryCode } from "../helpers/mobile";
import {
  formatTimezonedDateWithDateAndTime,
  getCurrentMoment,
  minutesToTime,
  setMomentTime,
} from "../helpers/time";
import TreatmentIcon from "../images/review-item-massage.svg";
import {
  Address,
  Booking,
  BookingPrice,
  BookingQuestion,
  BookingQuestionMeta,
  CorporateBookingQuotation,
  PaymentMethod,
  Recipient,
  ServiceRate,
  ServiceRatePrice,
  TherapistPublicProfile,
  TreatmentAddOn,
  TreatmentFor,
} from "../models";
import { getServices, updateCorporateBooking } from "../services/bookings/bookings.service";
import { getTimeRange } from "../services/bookings/timeRange.service";
import {
  convertDatetoTimezoneMoment,
  convertDayMomentToDate,
  convertTimeToMatchTimePicker,
  convertToMoment,
  getCurrentTimeInMinutes,
  getDateObject,
  getDateStringInMinutes,
  getMomentTimeInMinutes,
} from "../utils/date.util";
import { checkIfEmpty, checkIfNil, checkIfUndefined, getValue } from "../utils/object";
import { useAlertStore } from "./alert";
import { transformTreatmentDetailsForUpdate } from "./V2/booking/booking.transformer";

const defaultCountryCode = "+61";
let bookingPriceCancelToken: any;

interface UpdateDateTimeProps {
  selectedDay: Date;
  earliestStartInMinutes: number;
  latestStartInMinutes: number;
  backupSelectedDay: Date | null;
  backupEarliestStartInMinutes: number | null;
  backupLatestStartInMinutes: number | null;
  timeRangeFilterFromInMinutes: number | null;
}

interface RecurringEndType {
  endsOn: Date | null;
  type: string;
  occurrence: number;
}

export interface MonthData {
  dayOfMonth: number | null;
  dayOfWeek: number | null;
  weekOfMonth: number | null;
}

export interface Recurring {
  type: RecurringType | null;
  isCustom: boolean;
  monthly: MonthData | null;
  shouldCreateFutureBookings?: boolean;
  isRepeating?: boolean;
}

type NewBookingState = {
  serviceId: number | null;
  setServiceId: (serviceId: number | null) => unknown;
  treatmentId: number | null;
  setTreatmentId: (treatmentId: number | null) => unknown;
  numOfRecipients: number;
  setNumOfRecipients: (numOfRecipients: number) => unknown;
  resetNumOfRecipients: () => unknown;
  treatmentDetails: any[];
  setTreatmentDetails: (treatmentDetails: any[]) => unknown;
  resetTreatmentDetails: () => unknown;
  sessionType: string | null;
  setSessionType: (type: string) => unknown;
  massageLength: number | null;
  setMassageLength: (length: number) => unknown;
  massageLength2: number | null;
  setMassageLength2: (length: number) => unknown;
  resetTreatmentLength: () => unknown;
  massageType1: string | null;
  setMassageType1: (type: string) => unknown;
  genderPreference: string | null;
  setGenderPreference: (preference: string) => unknown;
  genderPreference1: string | null;
  setGenderPreference1: (preference: string) => unknown;
  genderFallback: boolean | null;
  setGenderFallback: (status: boolean) => unknown;
  couplesFallback: boolean | null;
  setCouplesFallback: (status: boolean) => unknown;
  couplesFallbackGenderPreference: string | null;
  setCouplesFallbackGenderPreference: (preference: string) => unknown;
  massageType2: string | null;
  setMassageType2: (type: string) => unknown;
  genderPreference2: string | null;
  setGenderPreference2: (preference: string) => unknown;
  recipient: Recipient | null;
  setRecipient: (recipient: Recipient | null) => unknown;
  address: Address | null;
  setAddress: (address: Address | null) => unknown;
  treatmentFor: string | null;
  setTreatmentFor: (treatmentFor: string) => unknown;
  noteForProvider: string | null;
  setNoteForProvider: (noteForProvider: string) => unknown;
  isPlatformFeeApplied: boolean;
  updatingForBooking: Booking | null;
  setUpdatingForBooking: (booking: Booking | null) => unknown;
  lastBooking: Booking | null;
  setLastBooking: (booking: Booking | null, resetTherapists?: boolean) => unknown;
  preferredProviderOption: PROVIDER_OPTION_TYPE | null;
  setPreferredProviderOption: (option: PROVIDER_OPTION_TYPE) => unknown;
  preferredTherapists: Array<TherapistPublicProfile>;
  setPreferredTherapists: (therapists: Array<TherapistPublicProfile>) => unknown;
  hasFluLikeSymptoms: string;
  setHasFluLikeSymptoms: (hasFluLikeSymptoms: string) => unknown;
  deliveryMethod: string;
  setDeliveryMethod: (method: string) => unknown;
  exitBookingFlowPath: string | null;
  setExitBookingFlowPath: (path: string | null) => unknown;
  specialInstructions: string | null;
  setSpecialInstructions: (instruction: string) => unknown;
  preferences: string | null;
  setPreference: (preference: string) => unknown;
  contraindications: string | null;
  setContraindications: (contraindications: string | null) => unknown;
  massageTreatmentDetails: massageTreatmentDetails[];
  setMassageTreatmentDetails: (treatmentDetails: massageTreatmentDetails[]) => unknown;
  resetMassageTreatmentDetails: () => unknown;
  currency: string;
  currencySymbol: string;
  setCurrency: (currency: string, currencySymbol: string) => unknown;
  sourceDetails: sourceDetails | null;
  setSourceDetails: (sourceDetails: sourceDetails) => unknown;
  resetSourceDetails: () => unknown;

  //For corporate booking
  numberOfPerson: number | null;
  setNumberOfPerson: (value: number) => unknown;
  totalEventDuration: { massageDuration: number | null; hasPaidBreak: boolean | null } | null;
  setTotalEventDuration: ({ duration, hasPaidBreak }: any) => unknown;
  durationPerPerson: number | null;
  setDurationPerPerson: (value: number) => unknown;
  numberOfPros: number | null;
  setNumberOfPros: (value: number | null) => unknown;
  eventType: string | null;
  setEventType: (value: string) => unknown;
  eventName: string | null;
  setEventName: (value: string) => unknown;
  noteForRecipients: string | null;
  setNoteForRecipients: (value: string) => unknown;
  paymentSplit: string | null;
  setPaymentSplit: (value: string) => unknown;
  updateCorporateFields: (details: CorporateFields) => unknown;

  // For booking frequency
  frequency: number | null;
  setFrequency: (value: number | null) => unknown;

  recurring: Recurring;
  setRecurring: (recurring: Recurring) => unknown;
  setRecurringType: (recurringType: RecurringType) => unknown;
  resetRecurring: () => unknown;

  setIsCustomRecurring: (isCustomRecurring: boolean) => unknown;
  setMonthData: (monthData: MonthData | null) => unknown;
  setRecurringMonthDataForDayOfMonth: ({ dayOfMonth }: { dayOfMonth: number }) => unknown;
  setRecurringMonthDataForWeekOfMonth: ({
    weekOfMonth,
    dayOfWeek,
  }: {
    weekOfMonth: number;
    dayOfWeek: number;
  }) => unknown;
  isSameProvider: boolean;
  setIsSameProvider: (value: boolean) => unknown;
  recuringEndOn: RecurringEndType;
  setRecurringEndOn: ({ endsOn, type }: RecurringEndType) => unknown;

  showUpdateRecurringModal: boolean;
  setShowUpdateRecurringModal: (value: boolean) => unknown;
  updateFutureRecurrings: boolean;
  setUpdateFutureRecurrings: (value: boolean) => unknown;

  resetCorprateFields: () => unknown;

  // For add-on
  addOn: TreatmentAddOn[];
  setAddOn: (addOn: any[]) => unknown;
};

type RecipientDetailsState = {
  gender: string | null;
  setGender: (gender: string) => unknown;
  firstName: string | null;
  setFirstName: (name: string) => unknown;
  lastName: string | null;
  setLastName: (name: string) => unknown;
  countryCode: string | null;
  setCountryCode: (code: string) => unknown;
  mobile: string | null;
  setMobile: (mobile: string) => unknown;
  email: string | null;
  setEmail: (email: string) => unknown;
  specialInstructions: string | null;
  setSpecialInstructions: (note: string) => unknown;
  hasContraindications: boolean | null;
  setHasContraindications: (hasContraindications: boolean) => unknown;
  contraindications: string | null;
  setContraindications: (contraindications: string | null) => unknown;
  massageFor: MassageFor | null;
  setMassageFor: (massageFor: MassageFor) => unknown;
  theirFirstName: string | null;
  setTheirFirstName: (name: string) => unknown;
  theirLastName: string | null;
  setTheirLastName: (name: string) => unknown;
  theirCountryCode: string | null;
  setTheirCountryCode: (code: string) => unknown;
  theirMobile: string | null;
  setTheirMobile: (mobile: string) => unknown;
  theirEmail: string | null;
  setTheirEmail: (email: string) => unknown;
  relationship: Relationship | null;
  setRelationship: (relationship: Relationship) => unknown;
  theirGender: string | null;
  setTheirGender: (gender: string) => unknown;
  theirSpecialInstructions: string | null;
  setTheirSpecialInstructions: (note: string) => unknown;
  isCovidVaccinated: boolean | null;
  setIsCovidVaccinated: (status: boolean) => unknown;
  hasTheirContraindications: boolean | null;
  setHasTheirContraindications: (hasTheirContraindications: boolean) => unknown;
  theirContraindications: string | null;
  setTheirContraindications: (theirContraindications: string | null) => unknown;
};

type LocationDetailsState = {
  newAddress: NewAddress | null;
  setNewAddress: (newAddress: NewAddress) => unknown;
  address: string | null;
  setAddress: (address: string) => unknown;
  fullAddress: string | null | undefined;
  setFullAddress: (fullAddress: string | undefined) => unknown;
  suburb: string | null;
  setSuburb: (suburb: string) => unknown;
  postcode: string | null;
  setPostcode: (postcode: string) => unknown;
  country: string | null;
  setCountry: (country: string) => unknown;
  countryCode: string | null;
  setCountryCode: (countryCode: string) => unknown;
  latitude: number | null;
  longitude: number | null;
  setAddressCordinates: (latitude?: number, longitude?: number) => unknown;
  specialInstructions: string | null;
  setSpecialInstructions: (instructions: string) => unknown;
  type: string | null;
  setType: (type: string) => unknown;
  parking: Parking | null;
  setParking: (parking: Parking) => unknown;
  stairs: Stairs | null;
  setStairs: (stairs: Stairs) => unknown;
  state: string | null;
  setAddressState: (state: string | null) => unknown;
};

export type NewAddress = {
  address: string;
  fullAddress: string;
  suburb: string;
  postcode: string;
  country: string;
  countryCode: string;
  instructions: string;
  type: string;
  stairOptionId?: number;
  parkingOptionId?: number;
  petOptionId?: number;
  tableOptionId?: number;
  latitude?: number;
  longitude?: number;
  state?: string;
};

type DateTimeState = {
  selectedDay: Date | null;
  setSelectedDay: (day: Date) => unknown;
  selectedDayRange: { from: Date; to: Date };
  setSelectedDayRange: (from: Date, to: Date) => unknown;
  earliestStartInMinutes: number | null;
  setEarliestStartInMinutes: (minutes: number) => unknown;
  latestStartInMinutes: number | null;
  setLatestStartInMinutes: (minutes: number) => unknown;
  backupSelectedDay: Date | null;
  setBackupSelectedDay: (day: Date) => unknown;
  backupSelectedDayRange: { from: Date; to: Date };
  setBackupSelectedDayRange: (from: Date, to: Date) => unknown;
  backupEarliestStartInMinutes: number | null;
  setBackupEarliestStartInMinutes: (minutes: number) => unknown;
  backupLatestStartInMinutes: number | null;
  setBackupLatestStartInMinutes: (minutes: number) => unknown;
  toBeReadyByInMinutes: number | null;
  setToBeReadyByInMinutes: (minutes: number | null) => unknown;
  timeRangeFilterFromInMinutes: number | null;
  setTimeRangeFilterFromInMinutes: (minutes: number | null) => unknown;
  updateDateTime: (props: UpdateDateTimeProps) => unknown;
  reset: () => unknown;
};

type PaymentState = {
  couponCode: string | null;
  setCouponCode: (code: string) => unknown;
  bookingPrice: BookingPrice | null;
  setBookingPrice: (price: BookingPrice) => unknown;
  paymentMethod: PaymentMethod | null;
  setPaymentMethod: (method: PaymentMethod | null) => unknown;
  paymentMethodByType: {
    [type in PaymentType]?: PaymentMethod | null;
  };
  setPaymentMethodByType: (type: {}) => unknown;
  paymentType: PaymentType;
  setPaymentType: (type: PaymentType) => unknown;
  firstTimeUserCardRecaptchaSuccess: boolean;
  setFirstTimeUserCardRecaptchaSuccess: (success: boolean) => unknown;
  skipPayment: boolean;
  setSkipPayment: (skipPayment: boolean) => unknown;
};

type ServicesState = {
  rates: ServiceRate[];
  questions: BookingQuestion[];
  answers: Array<any>; // [{ [questionId: number]: string }];
  timeRange: Array<any>;
  treatmentForList: TreatmentFor[];
  minTime: {
    hour: number;
    minute: number;
  };
  maxTime: {
    hour: number;
    minute: number;
  };
};

export const reset = (resetTherapists?: boolean) => {
  const bookingState: any = {
    serviceId: null,
    treatmentId: null,
    sessionType: null,
    massageLength: null,
    massageLength2: null,
    massageType1: null,
    massageType2: null,
    genderPreference1: null,
    genderPreference2: null,
    recipient: null,
    address: null,
    updatingForBooking: null,
    isPlatformFeeApplied: true,
    lastBooking: null,
    preferredProviderOption: null,
    hasFluLikeSymptoms: "No",
    deliveryMethod: "inperson",
    exitBookingFlowPath: null,
    currency: DEFAULT_CURRENCY.currency,
    currencySymbol: DEFAULT_CURRENCY.currencySymbol,
    frequency: bookingFrequencyType.oneOff,
    recurring: {
      type: RecurringType.WEEK,
      isCustom: false,
      monthly: null,
    },
    addOn: [],
  };
  if (resetTherapists !== false) bookingState.preferredTherapists = [];

  useBookingStore.setState(bookingState);

  useRecipientDetailsStore.setState({
    gender: null,
    firstName: null,
    lastName: null,
    countryCode: defaultCountryCode,
    mobile: null,
    email: null,
    specialInstructions: null,
    massageFor: null,
    theirFirstName: null,
    theirLastName: null,
    theirCountryCode: defaultCountryCode,
    theirMobile: null,
    theirEmail: null,
    relationship: null,
    theirGender: null,
    theirSpecialInstructions: null,
  });

  useLocationDetailsStore.setState({
    address: null,
    fullAddress: null,
    suburb: null,
    postcode: null,
    country: null,
    specialInstructions: null,
    type: null,
    parking: null,
    stairs: null,
  });

  useDateTimeStore.setState({
    selectedDay: null,
    earliestStartInMinutes: null,
    latestStartInMinutes: null,
    backupSelectedDay: null,
    backupEarliestStartInMinutes: null,
    backupLatestStartInMinutes: null,
    timeRangeFilterFromInMinutes: null,
  });

  usePaymentStore.setState({
    couponCode: null,
    bookingPrice: null,
    paymentMethod: null,
    paymentType: PaymentType.card,
    firstTimeUserCardRecaptchaSuccess: false,
  });

  useServicesStore.setState({
    // rates: [],
    // questions: [],
    answers: [],
  });
};

export const useServicesStore = create<ServicesState>(
  persist(
    (set) => ({
      rates: [],
      questions: [],
      answers: [],
      treatmentForList: [],
      treatmentRange: [],
      timeRange: [],
      minTime: DEFAULT_MIN_TIME,
      maxTime: DEFAULT_MAX_TIME,
    }),
    {
      name: Storage.SERVICE_STORE,
      getStorage: () => localStorage,
    }
  )
);

export const useBookingStore = create<NewBookingState>(
  persist(
    (set) => ({
      serviceId: null,
      setServiceId: (serviceId: number | null) => {
        set({ serviceId });
      },
      treatmentId: null,
      setTreatmentId: (treatmentId: number | null) => {
        set({ treatmentId });
      },
      numOfRecipients: 1,
      setNumOfRecipients: (numOfRecipients: number) => {
        set({ numOfRecipients });
      },
      resetNumOfRecipients: () => {
        set({ numOfRecipients: 1 });
      },
      treatmentDetails: [
        {
          treatmentFor: "my_self",
          treatmentId: null,
          noteForProvider: null,
          referenceImages: [],
          // constraindications: null,
          // hasContraindications: null,
        },
      ],
      setTreatmentDetails: (treatmentDetails: any[]) => {
        set({ treatmentDetails });
      },
      resetTreatmentDetails: () => {
        set({
          treatmentDetails: [{ treatmentFor: "my_self" }],
        });
      },
      sessionType: null,
      setSessionType: (type: string) => {
        set({ sessionType: type });
      },
      massageLength: null,
      setMassageLength: (length: number) => {
        set({ massageLength: length });
      },
      massageLength2: null,
      setMassageLength2: (length: number) => {
        set({ massageLength2: length });
      },
      resetTreatmentLength: () => {
        set({ massageLength: null, massageLength2: null });
      },
      massageType1: null,
      setMassageType1: (type: string) => {
        set({ massageType1: type });
      },
      genderPreference: "dont_care",
      setGenderPreference: (preference: string) => {
        set({ genderPreference: preference });
      },
      genderPreference1: null,
      setGenderPreference1: (preference: string) => {
        set({ genderPreference1: preference });
      },
      genderFallback: null,
      setGenderFallback: (checked: boolean | null) => {
        set({ genderFallback: checked });
      },
      couplesFallback: null,
      setCouplesFallback: (checked: boolean | null) => {
        set({ couplesFallback: checked });
      },
      couplesFallbackGenderPreference: null,
      setCouplesFallbackGenderPreference: (preference: string) => {
        set({ couplesFallbackGenderPreference: preference });
      },
      massageType2: null,
      setMassageType2: (type: string) => {
        set({ massageType2: type });
      },
      genderPreference2: null,
      setGenderPreference2: (preference: string) => {
        set({ genderPreference2: preference });
      },
      recipient: null,
      setRecipient: (recipient: Recipient | null) => {
        set({ recipient });
      },
      address: null,
      setAddress: (address: Address | null) => {
        set({ address });
      },
      treatmentFor: null,
      setTreatmentFor: (treatmentFor: string) => {
        set({ treatmentFor });
      },
      noteForProvider: null,
      setNoteForProvider: (noteForProvider: string) => {
        set({ noteForProvider });
      },
      isPlatformFeeApplied: true,
      updatingForBooking: null,
      setUpdatingForBooking: async (booking: Booking | null) => {
        if (!booking) {
          set({
            updatingForBooking: null,
          });
          return;
        }

        reset();

        set({
          updatingForBooking: booking,
        });
        getBookingQuestions();
        // await getServicesRates();
        if (!checkIfEmpty(booking)) {
          setDataWithBooking(booking);
        }
      },
      lastBooking: null,
      setLastBooking: async (booking: Booking | null, resetTherapists?: boolean) => {
        reset(resetTherapists);

        set({
          lastBooking: booking,
        });
        // await getServicesRates();
        if (!checkIfEmpty(booking) && booking) {
          setDataWithBooking(booking);
        }

        prefillDateAndTime();
      },
      preferredProviderOption: null,
      setPreferredProviderOption: (option: PROVIDER_OPTION_TYPE | null) => {
        set({
          preferredProviderOption: option,
        });
      },
      preferredTherapists: [],
      setPreferredTherapists: (therapists: Array<TherapistPublicProfile>) => {
        set({
          preferredTherapists: therapists,
        });
        if (therapists.length === 0) {
          set({
            isSameProvider: false,
          });
        }
      },
      hasFluLikeSymptoms: "No",
      setHasFluLikeSymptoms: (hasFluLikeSymptoms) => {
        set({ hasFluLikeSymptoms });
      },
      deliveryMethod: "inperson",
      setDeliveryMethod: (method: string) => {
        set({ deliveryMethod: method });
      },
      exitBookingFlowPath: null,
      setExitBookingFlowPath: (path: string | null) => {
        set({ exitBookingFlowPath: path });
      },
      specialInstructions: null,
      setSpecialInstructions: (instruction: string | null) => {
        set({ specialInstructions: instruction });
      },
      preferences: null,
      setPreference: (preference: string | null) => {
        set({ preferences: preference });
      },
      contraindications: null,
      setContraindications: (contraindications: string | null) => {
        set({ contraindications });
      },
      massageTreatmentDetails: [{}],
      setMassageTreatmentDetails: (massageTreatmentDetails: massageTreatmentDetails[]) => {
        set({ massageTreatmentDetails });
      },
      resetMassageTreatmentDetails: () => {
        set({
          massageTreatmentDetails: [{}],
        });
      },
      currency: "AUD",
      currencySymbol: "A$",
      setCurrency: (currency: string, currencySymbol: string) => {
        set({ currency, currencySymbol });
      },
      sourceDetails: null,
      setSourceDetails: (sourceDetails: sourceDetails | null) => {
        set({ sourceDetails });
      },
      resetSourceDetails: () => {
        set({ sourceDetails: null });
      },

      //For corporate booking
      numberOfPerson: null,
      setNumberOfPerson: (numberOfPerson: number) => {
        set({ numberOfPerson });
      },
      totalEventDuration: null,
      setTotalEventDuration: ({ massageDuration, hasPaidBreak }: any) => {
        set({ totalEventDuration: { massageDuration, hasPaidBreak } });
      },
      durationPerPerson: null,
      setDurationPerPerson: (durationPerPerson: number) => {
        set({ durationPerPerson });
      },
      numberOfPros: null,
      setNumberOfPros: (numberOfPros: number | null) => {
        set({ numberOfPros });
      },
      eventType: null,
      setEventType: (eventType: string) => {
        set({ eventType });
      },
      eventName: null,
      setEventName: (eventName: string) => {
        set({ eventName });
      },
      paymentSplit: null,
      setPaymentSplit: (paymentSplit: string) => {
        set({ paymentSplit });
      },
      updateCorporateFields: (updateDetails: any) => {
        // had to set type to 'any' instead of 'CorporateFields', more investigation later
        set({ ...updateDetails });
      },
      noteForRecipients: null,
      setNoteForRecipients: (noteForRecipients: string) => {
        set({ noteForRecipients });
      },

      //For Booking Recurrency
      frequency: null,
      setFrequency: (frequency: number | null) => {
        set({ frequency });
      },

      isSameProvider: false,
      setIsSameProvider: (isSameProvider: boolean) => {
        set({ isSameProvider });
      },

      recurring: {
        type: "weekly" as RecurringType.WEEK,
        isCustom: true,
        monthly: {
          dayOfMonth: null,
          dayOfWeek: null,
          weekOfMonth: null,
        },
        shouldCreateFutureBookings: false,
        isRepeating: false,
      },
      setRecurring: (recurring: Recurring) => {
        set({ recurring });
      },

      resetRecurring: () => {
        set((state) => ({
          ...state,
          frequency: 0,
          isSameProvider: false,
          recurring: {
            type: RecurringType.WEEK,
            isCustom: false,
            monthly: null,
            shouldCreateFutureBookings: false,
            isRepeating: false,
          },
        }));
      },

      setRecurringType: (recurringType: RecurringType) => {
        set((state) => ({
          recurring: {
            ...state.recurring,
            type: recurringType,
          },
        }));
      },

      setIsCustomRecurring: (isCustomRecurring: boolean) => {
        set((state) => ({
          recurring: {
            ...state.recurring,
            isCustom: isCustomRecurring,
          },
        }));
      },
      setMonthData: (monthData: MonthData | null) => {
        set((state) => ({
          recurring: {
            ...state.recurring,
            monthly: monthData,
          },
        }));
      },
      setRecurringMonthDataForDayOfMonth: ({ dayOfMonth }: { dayOfMonth: number }) => {
        set((state) => ({
          recurring: {
            ...state.recurring,
            monthly: {
              dayOfMonth,
              weekOfMonth: null,
              dayOfWeek: null,
            },
          },
        }));
      },
      setRecurringMonthDataForWeekOfMonth: ({
        weekOfMonth,
        dayOfWeek,
      }: {
        weekOfMonth: number;
        dayOfWeek: number;
      }) => {
        set((state) => ({
          recurring: {
            ...state.recurring,
            monthly: {
              dayOfMonth: null,
              weekOfMonth,
              dayOfWeek,
            },
          },
        }));
      },
      showUpdateRecurringModal: false,
      setShowUpdateRecurringModal: (showUpdateRecurringModal: boolean) => {
        set({ showUpdateRecurringModal });
      },

      updateFutureRecurrings: false,
      setUpdateFutureRecurrings: (updateFutureRecurrings: boolean) => {
        set({ updateFutureRecurrings });
      },

      recuringEndOn: { endsOn: null, type: "never", occurrence: 1 },
      setRecurringEndOn: (recuringEndOn: {
        endsOn: Date | null;
        type: string;
        occurrence: number;
      }) => {
        set({ recuringEndOn });
      },

      resetCorprateFields: () => {
        set({
          numberOfPerson: null,
          totalEventDuration: null,
          durationPerPerson: null,
          numberOfPros: null,
          eventType: null,
          eventName: null,
          paymentSplit: null,
        });
      },
      addOn: [],
      setAddOn: (addOn: any) => {
        set({ addOn });
      },
    }),
    {
      name: Storage.BOOKING_STORE,
      getStorage: () => localStorage,
    }
  )
);

export const isRecurringBooking = () => {
  const { frequency } = useBookingStore.getState();
  if (frequency) return true;
  return false;
};

/*
Generator function to show the update recurring model before submitting the update request

For pending booking - booking is updated on each steps (bookings step form)
each booking step form have a submit button which updates the booking.
If the updating booking is pending and recurring booking - we need to show the recurring model re updating the future bookings.
We have used the NewBookingWrapper component and added the recurring model in this component.
When the update button is clicked it checks the requirement to show the recurring modal.
So, a generator function is used to show the model - and performs the modal operations and
trigger the generator's next to continue the execution of the functionality of submit button (updating booking)

*/
export function* generateHandleNext(fn: () => unknown) {
  const { updatingForBooking, setShowUpdateRecurringModal } = useBookingStore.getState();
  if (!updatingForBooking || !isRecurringBooking()) {
    fn();
  } else {
    setShowUpdateRecurringModal(true);
    yield true;
    fn();
  }
}

export const setMassageTreatments = (
  massageTreatmentDetail: massageTreatmentDetails,
  index: number
) => {
  const { massageTreatmentDetails, setMassageTreatmentDetails } = useBookingStore.getState();
  const details = [...massageTreatmentDetails];
  details[index] = massageTreatmentDetail;
  setMassageTreatmentDetails(details);
};

export const loadTreatmentDetails = (serviceId: number) => {
  const { setTreatmentId, treatmentId, treatmentDetails } = useBookingStore.getState();
  const isMassage = checkServiceIsMassage(serviceId);
  if (isMassage) {
    const { prices: serviceMassageTypes = [] } = getMassageService();
    const treatment = serviceMassageTypes?.find((t) => t.id === treatmentId);
    if (!treatment?.isCorporate) {
      setTreatmentId(null);
      return;
    }
  }

  // set previous treatment ids
  const previousTreatmentIds = (treatmentDetails || [])
    .map((detail) => detail.treatmentId)
    .filter((id) => id);

  if (previousTreatmentIds.length && checkServiceIsHairAndMakeup(serviceId)) {
    previousTreatmentIds.forEach((id, i: number) => {
      _onTreatmentIdChanged(i, id, serviceId);
    });
  } else {
    _onTreatmentIdChanged(0, treatmentId, serviceId);
  }
};

const _onTreatmentIdChanged = (i: number, value: any, serviceId: number) => {
  const { treatmentDetails, setTreatmentDetails } = useBookingStore.getState();
  const newTreatmentDetails = [...treatmentDetails];
  if (newTreatmentDetails && newTreatmentDetails[i]) {
    newTreatmentDetails[i].treatmentId = value;
  }
  setTreatmentDetails(newTreatmentDetails);
  resetAnswersNotService(serviceId as number, value, i);
};

function setDataWithBooking(booking: Booking) {
  const {
    sessionType,
    massageDuration,
    bookingdetails,
    bookinganswers,
    massageFor,
    recipient,
    address,
    earliestTime,
    latestTime,
    backup,
    toBeReadyBy,
    isHairAndMakeup,
    isMassage,
    isPlatformFeeApplied,
    corporateInfo,
    recurring,
    recuringEndOn,
  } = booking;

  const { rates } = useServicesStore.getState();
  const { updatingForBooking } = useBookingStore.getState();

  const allPrices = rates.flatMap((rate) => rate.prices);

  const labelTokens = booking.bookingdetails[0]?.massageType.split(" | ");
  const priceForSessionType =
    labelTokens?.length === 2
      ? allPrices.find((price) => price.label === labelTokens[1])
      : undefined;

  if (priceForSessionType) {
    const { currency, currencySymbol } = priceForSessionType.selectedCountry || {};
    useBookingStore.setState({
      serviceId: parseInt(priceForSessionType.serviceId),
      treatmentId: priceForSessionType.id,
      currency: currency || DEFAULT_CURRENCY.currency,
      currencySymbol: currencySymbol || DEFAULT_CURRENCY.currencySymbol,
    });
  } else {
    // fetch serviceId from last booking
    let selectedServiceId = booking.serviceId;
    if (!selectedServiceId) {
      const massageService = rates.find((rate) => rate.isMassage && rate.category === rate.alias);
      selectedServiceId = massageService?.id || null;
    }
    if (selectedServiceId) {
      const serviceData = rates.find(({ id }) => id === selectedServiceId);
      const defaultTreatmentId = serviceData?.prices[0]?.id || null;

      const { currency, currencySymbol } = serviceData?.selectedCountry || {};
      useBookingStore.setState({
        serviceId: selectedServiceId,
        treatmentId: defaultTreatmentId,
        currency: currency || DEFAULT_CURRENCY.currency,
        currencySymbol: currencySymbol || DEFAULT_CURRENCY.currencySymbol,
      });
    }
  }
  const { prices: serviceMassageTypes = [] } = getMassageService();

  const treatmentDetails =
    bookingdetails[0] && bookingdetails[0].treatmentDetails
      ? bookingdetails[0].treatmentDetails || []
      : [];

  const massageLength1 =
    treatmentDetails[0]?.serviceDuration || bookingdetails[0]?.serviceDuration || massageDuration;
  const massageLength2 =
    bookingdetails[1]?.serviceDuration || treatmentDetails[1]?.serviceDuration || massageLength1;

  const massageType1 = treatmentDetails[0]?.massageType || bookingdetails[0]?.massageType;
  const massageType2 =
    treatmentDetails[1]?.massageType || bookingdetails[1]?.massageType || massageType1;

  const massageTreatmentDetails = [
    {
      duration: massageLength1,
      treatmentTypeId:
        treatmentDetails[0]?.treatmentTypeId ||
        serviceMassageTypes.find((treatment) => treatment.name === massageType1)?.id,
      addons: treatmentDetails[0]?.addons || [],
      isMultipleAddonsAllowed: treatmentDetails[0]?.isMultipleAddonsAllowed,
    },
  ];

  if (isBackToBackMassageType(sessionType)) {
    massageTreatmentDetails.push({
      duration: massageLength2,
      treatmentTypeId:
        treatmentDetails[1]?.treatmentTypeId ||
        serviceMassageTypes.find((treatment) => treatment.name === massageType2)?.id,
      addons: treatmentDetails[1]?.addons || [],
      isMultipleAddonsAllowed: treatmentDetails[1]?.isMultipleAddonsAllowed,
    });
  } else if (!isSingleMassageType(sessionType)) {
    massageTreatmentDetails.push({
      duration: massageLength2,
      treatmentTypeId:
        treatmentDetails[1]?.treatmentTypeId ||
        serviceMassageTypes.find((treatment) => treatment.name === massageType2)?.id,
      addons: bookingdetails[1]?.treatmentDetails[0]?.addons || [],
      isMultipleAddonsAllowed: bookingdetails[1]?.treatmentDetails[0]?.isMultipleAddonsAllowed,
    });
  }

  // get and update booking answers
  if (bookinganswers && bookinganswers.length) {
    for (const answer of bookinganswers) {
      if (Array.isArray(answer)) {
        answer.forEach((ans, i) => {
          if (ans.questionId && (ans.answer || ans.answers)) {
            setAnswerForQuestion({
              questionId: ans.questionId,
              answer: ans.answer,
              answers: ans.answers,
              treatmentIndex: i,
              type: ans.type,
            });
          }
        });
      }
      if (answer.questionId && (answer.answer || answer.answers))
        setAnswerForQuestion({
          questionId: answer.questionId,
          answer: answer.answer,
          answers: answer.answers,
          treatmentIndex: 0,
          type: answer.type,
        });
    }
  }

  if (isMassage) {
    const treatmentDetailsData: any = [];
    bookingdetails.map(({ treatmentDetails }) => {
      if (bookingdetails[0].treatmentDetails) treatmentDetailsData.push(...treatmentDetails);
    });
    setLastBookingQuestions(treatmentDetailsData);
  }

  // @todo: taking from bookingdetails[0].treatmentDetails.bookingAnswers form hair & makeup bookings
  if (isHairAndMakeup && bookingdetails && bookingdetails[0]) {
    if (bookingdetails[0].treatmentDetails && bookingdetails[0].treatmentDetails.length) {
      setLastBookingQuestions(bookingdetails[0].treatmentDetails);
    }
  }

  let addressUsed: Address | null = address;
  // set null only if creating new booking
  // else loads service for AU by default - causing issues to further updates
  if (!updatingForBooking && address.deletedAt) {
    addressUsed = null;
  }

  useBookingStore.setState({
    sessionType: sessionType,
    massageLength: massageLength1,
    massageLength2: massageLength2,
    massageType1: massageType1,
    massageType2: massageType2,
    genderPreference: bookingdetails[0]?.genderPreference || "dont_care",
    genderPreference1: bookingdetails[0]?.genderPreference,
    genderPreference2: bookingdetails[1]?.genderPreference,
    genderFallback: bookingdetails[0]?.genderFallback || false,
    recipient,
    address: addressUsed,
    deliveryMethod: bookingdetails[0]?.deliveryMethod,
    treatmentDetails,
    massageTreatmentDetails,
    isPlatformFeeApplied,
    couplesFallbackGenderPreference: null,
    frequency: recurring?.frequency || 0,
    isSameProvider: recurring?.isSameProvider || false,
    recurring: {
      type: recurring?.type || RecurringType.WEEK,
      isCustom: recurring?.isCustom || false,
      monthly: recurring?.monthly || null,
      isRepeating: recurring?.isRepeating,
      shouldCreateFutureBookings: recurring?.shouldCreateFutureBookings || false,
    },
    updateFutureRecurrings: false,
    showUpdateRecurringModal: false,
    recuringEndOn: recuringEndOn || { endsOn: null, type: RECURRING_ENDS.NEVER, occurrence: 1 },
  });

  let recipientMobileCountryCodeData = getCountryCode(recipient?.mobile);

  useRecipientDetailsStore.setState({
    massageFor: massageFor,
    theirFirstName: recipient?.firstName,
    theirLastName: recipient?.lastName,
    theirCountryCode: recipientMobileCountryCodeData?.countryCode || null,
    theirMobile: recipientMobileCountryCodeData?.withoutCountryCode || null,
    theirEmail: recipient?.email,
    relationship: recipient?.relationship,
    theirGender: recipient?.gender,
    theirSpecialInstructions: recipient?.specialInstructions,
  });

  const {
    address: addressAddress,
    suburb,
    postcode,
    type,
    instructions,
    country,
    parking,
    stairs,
    fullAddress,
    timezone: addressTimezone,
  } = address;

  useLocationDetailsStore.setState({
    address: addressAddress,
    fullAddress,
    suburb,
    postcode,
    country,
    stairs,
    parking,
    type,
    specialInstructions: instructions,
  });

  // set earliest and latest time
  const timezone = addressTimezone || booking.timezone;

  const selectedDayMoment = convertToMoment(earliestTime, timezone);
  let latestStartInMinutes = null;

  if (updatingForBooking) {
    latestStartInMinutes = getDateStringInMinutes(latestTime, timezone);
  }
  const earliestStartInMinutes = getDateStringInMinutes(earliestTime, timezone);
  const bufferedCurrentTimeStampInMinutes = getCurrentTimeInMinutes(timezone);

  let backupTime = {};
  if (backup && backup.earliestTime) {
    const { earliestTime: backupEarliestTime, latestTime: backupLatestTime } = backup;
    const backupSelectedDay = convertToMoment(backupEarliestTime, timezone);
    backupTime = {
      backupSelectedDay: convertDayMomentToDate(backupSelectedDay),
      backupEarliestStartInMinutes: getDateStringInMinutes(backupEarliestTime, timezone),
      backupLatestStartInMinutes: updatingForBooking ? getDateStringInMinutes(backupLatestTime, timezone) : null,
    };
  }

  useDateTimeStore.setState({
    selectedDay: convertDayMomentToDate(selectedDayMoment),
    earliestStartInMinutes,
    latestStartInMinutes,
    timeRangeFilterFromInMinutes: updatingForBooking ? bufferedCurrentTimeStampInMinutes : null,
    ...backupTime,
  });

  if (isCorporateSession(sessionType) && corporateInfo) {
    const corporateDetails: any = {
      numberOfPerson: corporateInfo?.numberOfPerson ?? null,
      durationPerPerson: corporateInfo?.durationPerPerson ?? null,
      totalEventDuration: {
        massageDuration: corporateInfo?.totalEventDuration ?? null,
        hasPaidBreak: corporateInfo?.hasPaidBreak ?? null,
      },
      numberOfPros: corporateInfo?.numberOfPros ?? null,
      eventType: corporateInfo?.eventType ?? null,
      eventName: corporateInfo?.eventName ?? null,
      genderPreference1: bookingdetails[0]?.genderPreference,
    };

    // update treatmentID
    const serviceData = rates.find(({ id }) => id === booking?.serviceId);
    let treatmentTypeId = serviceData?.prices[0]?.id || null;

    const treatmentDetail = getValue(booking, "bookingdetails[0].treatmentDetails[0]") || null;
    if (!checkIfEmpty(treatmentDetail))
      corporateDetails.treatmentId = treatmentDetail.treatmentTypeId || treatmentTypeId;

    useBookingStore.setState(corporateDetails);
  }
}

function setLastBookingQuestions(treatmentDetails: any[]) {
  const { updatingForBooking } = useBookingStore.getState();

  const formattedAnswers: any = [];
  treatmentDetails.forEach((treatmentDetail) => {
    const bookingAnswers = treatmentDetail.bookingAnswers;

    const treatmentAnswer: any = [];
    if (bookingAnswers && bookingAnswers.length) {
      bookingAnswers.forEach((answer: any) => {
        let treatmentId;

        if (updatingForBooking) {
          const selectedAnswer = treatmentDetail?.questions?.find(
            (detail: any) => detail.id === answer.questionId
          );
          if (selectedAnswer) treatmentId = selectedAnswer.treatmentId;
        } else {
          treatmentId = treatmentDetail.treatmentTypeId;
        }

        if (answer.questionId && (answer.answer || answer.answers)) {
          treatmentAnswer.push({
            answer: answer.answer,
            answers: answer.answers || null,
            questionId: answer.questionId,
            treatmentId,
            type: answer.type || QUESTION_TYPE.SELECT,
          });
        }
      });
    }
    formattedAnswers.push(treatmentAnswer);
  });

  useServicesStore.setState({
    answers: formattedAnswers,
  });
}

export const useRecipientDetailsStore = create<RecipientDetailsState>(
  persist(
    (set) => ({
      gender: null,
      setGender: (gender: string) => {
        set({ gender });
      },
      firstName: null,
      setFirstName: (firstName: string) => {
        set({ firstName });
      },
      lastName: null,
      setLastName: (lastName: string) => {
        set({ lastName });
      },
      countryCode: defaultCountryCode,
      setCountryCode: (code: string) => {
        set({ countryCode: code });
      },
      mobile: null,
      setMobile: (mobile: string) => {
        set({ mobile });
      },
      email: null,
      setEmail: (email: string) => {
        set({ email });
      },
      massageFor: null,
      setMassageFor: (massageFor: MassageFor) => {
        set({ massageFor });
      },
      specialInstructions: null,
      setSpecialInstructions: (note: string) => {
        set({ specialInstructions: note });
      },
      theirFirstName: null,
      setTheirFirstName: (name: string) => {
        set({ theirFirstName: name });
      },
      theirLastName: null,
      setTheirLastName: (name: string) => {
        set({ theirLastName: name });
      },
      theirCountryCode: defaultCountryCode,
      setTheirCountryCode: (code: string) => {
        set({ theirCountryCode: code });
      },
      theirMobile: null,
      setTheirMobile: (mobile: string) => {
        set({ theirMobile: mobile });
      },
      theirEmail: null,
      setTheirEmail: (email: string) => {
        set({ theirEmail: email });
      },
      relationship: null,
      setRelationship: (relationship: Relationship) => {
        set({ relationship });
      },
      theirGender: null,
      setTheirGender: (gender: string) => {
        set({ theirGender: gender });
      },
      theirSpecialInstructions: null,
      setTheirSpecialInstructions: (note: string) => {
        set({ theirSpecialInstructions: note });
      },
      isCovidVaccinated: null,
      setIsCovidVaccinated: (checked: boolean) => {
        set({ isCovidVaccinated: checked });
      },
      hasContraindications: null,
      setHasContraindications: (hasContraindications: boolean) => {
        set({ hasContraindications });
      },
      contraindications: null,
      setContraindications: (contraindications: string | null) => {
        set({ contraindications });
      },
      hasTheirContraindications: null,
      setHasTheirContraindications: (hasTheirContraindications: boolean) => {
        set({ hasTheirContraindications });
      },
      theirContraindications: null,
      setTheirContraindications: (theirContraindications: string | null) => {
        set({ theirContraindications });
      },
    }),
    {
      name: Storage.RECIPIENT_STORE,
      getStorage: () => localStorage,
    }
  )
);

export const useLocationDetailsStore = create<LocationDetailsState>(
  persist(
    (set) => ({
      newAddress: null,
      setNewAddress: (newAddress: NewAddress) => {
        set({ newAddress });
      },
      address: null,
      setAddress: (address: string) => {
        set({ address });
      },
      fullAddress: null,
      setFullAddress: (fullAddress: string | undefined) => {
        set({ fullAddress });
      },
      suburb: null,
      setSuburb: (suburb: string) => {
        set({ suburb });
      },
      postcode: null,
      setPostcode: (postcode: string) => {
        set({ postcode });
      },
      country: null,
      setCountry: (country: string) => {
        set({ country });
      },
      countryCode: null,
      setCountryCode: (countryCode: string) => {
        set({ countryCode });
      },
      latitude: null,
      longitude: null,
      setAddressCordinates: (latitude?: number, longitude?: number) => {
        set({ latitude: latitude || null, longitude: longitude || null });
      },
      specialInstructions: null,
      setSpecialInstructions: (instructions: string) => {
        set({ specialInstructions: instructions });
      },
      type: null,
      setType: (type: string) => {
        set({ type });
      },
      parking: null,
      setParking: (parking: Parking) => {
        set({ parking });
      },
      stairs: null,
      setStairs: (stairs: Stairs) => {
        set({ stairs });
      },
      state: null,
      setAddressState: (state: string | null) => {
        set({ state });
      },
    }),
    {
      name: Storage.LOCATION_STORE,
      getStorage: () => localStorage,
    }
  )
);

export const useDateTimeStore = create<DateTimeState>(
  persist(
    (set) => ({
      selectedDay: null,
      setSelectedDay: (day: Date) => {
        set({
          selectedDay: day,
        });
      },
      earliestStartInMinutes: null,
      setEarliestStartInMinutes: (minutes: number) => {
        set({ earliestStartInMinutes: minutes });
      },
      latestStartInMinutes: null,
      setLatestStartInMinutes: (minutes: number) => {
        set({ latestStartInMinutes: minutes });
      },
      backupSelectedDay: null,
      setBackupSelectedDay: (day: Date) => {
        set({
          backupSelectedDay: day,
        });
      },
      backupEarliestStartInMinutes: null,
      setBackupEarliestStartInMinutes: (minutes: number) => {
        set({ backupEarliestStartInMinutes: minutes });
      },
      backupLatestStartInMinutes: null,
      setBackupLatestStartInMinutes: (minutes: number) => {
        set({ backupLatestStartInMinutes: minutes });
      },
      selectedDayRange: { from: new Date(), to: new Date() },
      setSelectedDayRange: (from: Date, to: Date) => {
        set({
          selectedDayRange: { from: from, to: to },
        });
      },
      backupSelectedDayRange: { from: new Date(), to: new Date() },
      setBackupSelectedDayRange: (from: Date, to: Date) => {
        set({
          backupSelectedDayRange: { from: from, to: to },
        });
      },
      toBeReadyByInMinutes: null,
      setToBeReadyByInMinutes: (minutes: number | null) => {
        set({ toBeReadyByInMinutes: minutes });
      },
      timeRangeFilterFromInMinutes: null,
      setTimeRangeFilterFromInMinutes: (minutes: number | null) => {
        set({ timeRangeFilterFromInMinutes: minutes });
      },
      updateDateTime: ({
        selectedDay,
        earliestStartInMinutes,
        latestStartInMinutes,
        backupSelectedDay,
        backupEarliestStartInMinutes,
        backupLatestStartInMinutes,
        timeRangeFilterFromInMinutes,
      }: UpdateDateTimeProps) => {
        set({
          selectedDay,
          earliestStartInMinutes,
          latestStartInMinutes,
          backupSelectedDay,
          backupEarliestStartInMinutes,
          backupLatestStartInMinutes,
          timeRangeFilterFromInMinutes,
        });
      },
      reset: () => {
        set({
          selectedDay: null,
          earliestStartInMinutes: null,
          latestStartInMinutes: null,
          backupSelectedDay: null,
          backupEarliestStartInMinutes: null,
          backupLatestStartInMinutes: null,
          toBeReadyByInMinutes: null,
          timeRangeFilterFromInMinutes: null,
        });
      },
    }),
    {
      name: Storage.DATE_TIME_STORE,
      getStorage: () => localStorage,
    }
  )
);

export const usePaymentStore = create<PaymentState>(
  persist(
    (set) => ({
      couponCode: null,
      setCouponCode: (code: string) => {
        set({ couponCode: code });
      },
      bookingPrice: null,
      setBookingPrice: (price: BookingPrice) => {
        set({ bookingPrice: price });
      },
      paymentMethodByType: {},
      setPaymentMethodByType: (paymentMethodByType: {}) => {
        set({ paymentMethodByType });
      },
      paymentMethod: null,
      setPaymentMethod: (method: PaymentMethod | null) => {
        const { paymentMethodByType, paymentMethod } = usePaymentStore.getState();
        if (paymentMethod) {
          set({
            paymentMethodByType: {
              ...paymentMethodByType,
              [paymentMethod.type]: paymentMethod,
            },
          });
        }
        set({ paymentMethod: method });
      },
      paymentType: PaymentType.card,
      setPaymentType: (type: PaymentType) => {
        set({ paymentType: type });
      },
      firstTimeUserCardRecaptchaSuccess: false,
      setFirstTimeUserCardRecaptchaSuccess: (success: boolean) => {
        set({ firstTimeUserCardRecaptchaSuccess: success });
      },
      skipPayment: false,
      setSkipPayment: (skip: boolean) => {
        set({ skipPayment: skip });
      },
    }),
    {
      name: Storage.PAYMENT_STORE,
      getStorage: () => localStorage,
    }
  )
);

export const resetPaymentStore = () => {
  usePaymentStore.setState({
    couponCode: null,
    skipPayment: false,
    paymentType: PaymentType.card,
    bookingPrice: null,
    paymentMethod: null,
    paymentMethodByType: {},
    firstTimeUserCardRecaptchaSuccess: false,
  });
};

interface GetBookingPriceParams {
  massageDuration: number | null;
  serviceDuration1: number | null;
  serviceDuration2: number | null;
  sessionType: string | null;
  typeOfLocation: string | null;
  couponCode: string | null;
  timezone: string;
  accessToken: string | null;
  earliestTime: string | null;
  latestTime: string | null;
  addressId: number | null;
  treatmentDetails?: massageTreatmentDetails[] | null;
  isPlatformFeeApplied?: boolean;
  bookingId?: number | null;
  numberOfTherapists?: number | null;
  numberOfPerson?: number | null;
  durationPerPerson?: number | null;
  massageType?: string | null;
  paymentType?: string | null;
  country?: string | null;
}

export interface massageTreatmentDetails {
  treatmentTypeId?: number | null;
  duration?: number | null;
  addons?: Array<ServiceRatePrice>;
  isMultipleAddonsAllowed?: boolean;
}

interface sourceDetails {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  clid?: string;
  countryCode?: string;
}

export interface CorporateFields {
  numberOfPerson?: number | undefined | null;
  durationPerPerson?: number | undefined | null;
  totalEventDuration?:
    | {
        massageDuration?: number | undefined | null;
        hasPaidBreak?: boolean | undefined | null;
      }
    | undefined
    | null;
  numberOfPros?: number | undefined | null;
  eventType?: string | undefined | null;
  eventName?: string | undefined | null;
  noteForRecipients?: string | undefined | null;
  paymentSplit?: string | undefined | null;
  genderPreference?: string | undefined | null;
}

export function getTreatmentFor() {
  return axios
    .get("/api/v2/treatments/treatment-for")
    .then((response) => {
      console.debug("getTreatmentFor response.data: ", response.data);

      const list = response.data.data as TreatmentFor[];
      useServicesStore.setState({
        treatmentForList: list,
      });
    })
    .catch((error) => {
      console.debug("error: ", error);
    });
}

export function setServiceRates(rates: ServiceRate[]) {
  useServicesStore.setState({
    rates,
  });
}

export function getServicesRates() {
  return getServices()
    .then((data) => {
      const rates = (data as ServiceRate[]) || [];
      useServicesStore.setState({
        rates,
      });
    })
    .catch((error) => {
      console.debug("error: ", error);
    });
}

export function getServicesRatesForSignup(cb?: () => void) {
  return axios
    .get("/prices/service-rates-for-signup")
    .then((response) => {
      console.debug("response.data: ", response.data);

      const rates = response.data as ServiceRate[];
      useServicesStore.setState({
        rates,
      });
      if (typeof cb === "function") {
        cb();
      }
    })
    .catch((error) => {
      console.debug("error: ", error);
    });
}

export async function getBookingQuestions() {
  return axios
    .get("/api/v3/booking/question?includeServiceQuestions=true")
    .then((response) => {
      console.debug("response.data: ", response.data);

      const questionsMetas = response.data as BookingQuestionMeta[];
      const questions = questionsMetas.flatMap(
        (meta) => meta.bookingquestions
      ) as BookingQuestion[];

      useServicesStore.setState({
        questions,
      });
      return questions;
    })
    .catch((error) => {
      console.debug("error: ", error);
    });
}

export function getTimeRanges() {
  return getTimeRange()
    .then((timeRange) => {
      let updatedState = { timeRange, minTime: DEFAULT_MIN_TIME, maxTime: DEFAULT_MAX_TIME };
      // set minimum and maximum time limit
      if (timeRange && timeRange.length > 0) {
        const minTime = timeRange[0];
        const maxTime = timeRange[timeRange.length - 1];
        const minTimeMoment = moment(minTime.value, "hh:mm a");
        const maxTimeMoment = moment(maxTime.value, "hh:mm a");
        updatedState = {
          ...updatedState,
          minTime: { hour: minTimeMoment.hour(), minute: minTimeMoment.minutes() },
          maxTime: { hour: maxTimeMoment.hour(), minute: maxTimeMoment.minutes() },
        };
      }
      useServicesStore.setState(updatedState);
    })
    .catch((error) => {
      console.debug("error: ", error);
    });
}

export function getBookingQuestionsForService(
  serviceId: number,
  treatmentIds: Array<number>,
  includeServiceQuestion: boolean = true
): BookingQuestion[] {
  const { questions } = useServicesStore.getState();

  const serviceQuestions = questions.filter(
    (question) =>
      (question.serviceId === serviceId && !question.treatmentId && includeServiceQuestion) ||
      (question.serviceId === serviceId &&
        treatmentIds.length &&
        treatmentIds.includes(question.treatmentId))
  );
  return serviceQuestions;
}

export function setAnswerForQuestion({
  questionId,
  answer,
  answers,
  treatmentIndex,
  treatmentId,
  type,
}: {
  questionId: number;
  answer: any;
  answers: any;
  treatmentIndex: number;
  treatmentId?: number | null;
  type: string;
}) {
  const { answers: bookingAnswers } = useServicesStore.getState();

  if (!bookingAnswers[treatmentIndex]) bookingAnswers[treatmentIndex] = [];

  const questionIdToAnswer: any = {};
  for (const question of bookingAnswers[treatmentIndex]) {
    questionIdToAnswer[question.questionId] = question;
  }

  questionIdToAnswer[questionId] = {
    answer,
    questionId,
    treatmentId,
    type,
    answers,
  };
  bookingAnswers[treatmentIndex] = Object.values(questionIdToAnswer);

  useServicesStore.setState({
    answers: bookingAnswers,
  });
}

export function getPriceByQuestion() {
  let { answers = [], questions = [] } = useServicesStore.getState();
  const treatmentPrice: number = getTreatmentPrice();
  if (!treatmentPrice || !Object.values(answers).length || !questions.length) return 0;

  let price = 0;
  const questionIdToDetails = questions.reduce((acc, question) => {
    acc[question.id] = question;
    return acc;
  }, {} as { [key: number]: BookingQuestion });

  answers.forEach((treatmentAnswers) => {
    treatmentAnswers.forEach(({ answer, questionId }: any) => {
      const { answerDetails = [] } = questionIdToDetails[questionId] || {};
      if (!answerDetails) return;

      const matchedAnswer = answerDetails.find(
        (detail) => detail.value === answer && detail.addedPrice
      );
      if (answer && answerDetails && matchedAnswer) {
        if (matchedAnswer.priceType === PRICE_TYPE.VALUE) {
          price += matchedAnswer.addedPrice;
        }

        if (matchedAnswer.priceType === PRICE_TYPE.PERCENT) {
          price = (treatmentPrice * matchedAnswer.addedPrice) / 100;
        }
      }
    });
  });
  return price;
}

export function getAnswerForQuestion(
  questionId: number,
  index: number,
  treatmentId?: number | null
) {
  const { answers } = useServicesStore.getState();
  if (!answers || !answers[index]) return null;

  const answer = answers[index].find((answer: any) => {
    if (treatmentId) {
      return (
        (!answer.treatmentId || answer.treatmentId === treatmentId) &&
        answer.questionId === questionId
      );
    }
    return answer.questionId === questionId;
  })?.answer;

  return answer;
}

export function resetAnswers(serviceId: number, treatmentIds: Array<number>) {
  let { answers } = useServicesStore.getState();

  // previous answers
  let answerToDetail = answers.map((answer) => {
    return answer.reduce((acc: any, ans: any) => {
      acc[ans.questionId] = ans;
      return acc;
    }, {});
  });
  // use previous answers if match found other wise add default answers from questions
  const getQuestionByTreatmentId = (treatmentId: number, index: number) => {
    const includeServiceQuestion = index === 0;
    const questionsForService = getBookingQuestionsForService(
      serviceId,
      [treatmentId],
      includeServiceQuestion
    );
    if (!questionsForService.length) return [];
    return questionsForService.map((question) => {
      const answer = question.answerDetails ? question.answerDetails[0]?.value : "";
      const matchedAnswer =
        answerToDetail[index] && answerToDetail[index][question.id]
          ? answerToDetail[index][question.id]
          : null;
      if (matchedAnswer && matchedAnswer.treatmentId === treatmentId) {
        return {
          treatmentId: question.treatmentId || null,
          type: matchedAnswer.type,
          answer: matchedAnswer.answer,
          answers: matchedAnswer.answers,
          questionId: matchedAnswer.questionId,
        };
      }
      const isImageQuestion = question.type === "image";
      return {
        // don't set default answer when treatmentId is null
        answer: question.treatmentId ? answer : null,
        type: question.type,
        answers: null,
        questionId: question.id,
        treatmentId: isImageQuestion ? question.treatmentId || treatmentId : null,
      };
    });
  };

  let defaultAnswers: any = [];
  treatmentIds.forEach((id, i: number) => {
    defaultAnswers[i] = getQuestionByTreatmentId(id, i);
  });

  useServicesStore.setState({
    answers: defaultAnswers,
  });
}

// called when treatment type is changed
export function resetAnswersNotService(serviceId: number, treatmentId: number, index: number) {
  const includeServiceQuestion = index === 0;
  const questionsForService = getBookingQuestionsForService(
    serviceId,
    treatmentId ? [treatmentId] : [],
    includeServiceQuestion
  );
  let { answers } = useServicesStore.getState();

  if (!questionsForService.length) {
    answers[index] = [];
    useServicesStore.setState({
      answers,
    });
    return;
  }

  if (!answers[index]) answers[index] = [];

  const questionToDetail = questionsForService.reduce((acc: any, question) => {
    const answer = question.answerDetails ? question.answerDetails[0]?.value : "";
    acc[question.id] = {
      answer,
      answers: null,
      type: question.type,
      questionId: question.id,
      treatmentId: question.treatmentId,
    };
    return acc;
  }, {});

  const answerToDetail = (answers[index] || []).reduce((acc: any, answer: any) => {
    acc[answer.questionId] = { ...answer };
    return acc;
  }, {});
  const matchedAnswers = [];
  for (const questionId of Object.keys(questionToDetail)) {
    const answer = answerToDetail[questionId] || questionToDetail[questionId];
    const answerType = questionToDetail[questionId].type || QUESTION_TYPE.SELECT;
    matchedAnswers.push({
      type: answerType,
      // don't set default answers for service questions
      answer: answer.treatmentId || answerToDetail[questionId] ? answer.answer : null,
      answers: answer.answers,
      questionId: answer.questionId,
      treatmentId: answer.treatmentId,
    });
  }

  answers[index] = matchedAnswers;
  useServicesStore.setState({
    answers,
  });
}

export function getAnswersApiValue() {
  let { answers = [] } = useServicesStore.getState();

  const apiValue: any[] = [];

  answers.forEach((answer, i) => {
    if (!apiValue[i]) apiValue[i] = [];
    answer.forEach((ans: any) => {
      if (ans.questionId && (ans.answer || ans.answers)) {
        apiValue[i].push({
          type: ans.type,
          answer: ans.answer,
          answers: ans.answers,
          questionId: ans.questionId,
          treatmentId: ans.treatmentId,
        });
      }
    });
  });

  return apiValue;
}

export function getTreatmentDataForService(serviceId: number | null): ServiceRatePrice[] {
  const isMassage = isMassageType();
  const isCorporateType = isCorporateTreatmentType();
  if ((isMassage || !serviceId) && !isCorporateType) return [];

  const { rates } = useServicesStore.getState();

  const service = rates.find((rate) => rate.id === serviceId);

  let prices: ServiceRatePrice[] = [];
  if (service && service.prices) {
    const { selectedCountry } = service;
    // attach country data (currency)
    prices = service.prices.map((price) => {
      price.selectedCountry = selectedCountry;
      return price;
    });
  }
  return prices || [];
}

export function getTreatmentDetails(): any {
  const { treatmentId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();

  if (!rates) {
    return {};
  }

  const price = rates.flatMap((rate) => rate.prices).find((price) => price.id === treatmentId);

  return price;
}

export function getTreatmentDetailByTreatmentId(id: any): any {
  const { rates } = useServicesStore.getState();

  if (!rates) {
    return {};
  }

  const price = rates.flatMap((rate) => rate.prices).find((price) => price.id === id);

  return price;
}

export function getAddOnDetailById({ treatmentId, id }: { treatmentId: any; id: any }): any {
  const price = getTreatmentDetailByTreatmentId(treatmentId);

  if (!price || !price.addons) {
    return {};
  }
  const addOn =
    price.addons.find((addon: any) => addon?.treatmentAddons?.id === id || addon.id === id) || {};
  return addOn;
}

export function getHairAndBeautyPrice() {
  let price = 0;
  const { treatmentDetails } = useBookingStore.getState();
  treatmentDetails.forEach((td) => {
    let rate = getTreatmentDetailByTreatmentId(td.treatmentId)?.rate || 0;
    price = price + rate;
  });

  price += getPriceByQuestion();
  return price;
}

export function getHairAndBeatuyServiceLabel() {
  const len = useBookingStore.getState().treatmentDetails.length;
  if (len === 0) {
    return "Select Service";
  } else if (len === 1) {
    return getServiceCategory();
  }
  return `(x${len}) ${getServiceCategory()}`;
}

export function getHairAndBeautyTreatmentLabel() {
  const { treatmentDetails, serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();
  const service = rates.find((rate) => rate.id === serviceId);
  const treatmentId = treatmentDetails[0]?.treatmentId;

  return service?.prices?.find((price) => price.id == treatmentId)?.label || "";
}

export function getTreatmentPrice() {
  return getTreatmentDetails()?.rate;
}

export function getTreatmentName() {
  return getTreatmentDetails()?.label;
}

export function getTreatmentId() {
  const isMassage = isMassageType();
  if (!isMassage) {
    return getTreatmentDetails()?.id;
  }

  return undefined;
}

export function getTreatmentDeliveryMethod() {
  return getTreatmentDetails()?.deliveryMethod;
}

const getAnswerParams = () => {
  let { answers = [], questions = [] } = useServicesStore.getState();
  const answersParams: Array<any> = [];
  const questionId = questions[0] && (questions[0].id as number);
  if (!questionId) return answersParams;

  answers.forEach((answer: any, i: number) => {
    if (!answersParams[i]) answersParams[i] = [];

    answer.forEach((ans: any) => {
      if (ans.questionId && ans.answer) {
        answersParams[i].push({ [ans.questionId]: ans.answer });
      }
    });
  });

  return { bookingQuestions: answersParams };
};

export function getBookingPrice() {
  //Check if there are any previous pending requests
  if (typeof bookingPriceCancelToken != typeof undefined) {
    bookingPriceCancelToken.cancel("Operation canceled due to new price api request.");
  }
  //Save the cancel token for the current request
  bookingPriceCancelToken = axios.CancelToken.source();

  const { couponCode, paymentType } = usePaymentStore.getState();
  const {
    massageLength,
    sessionType,
    massageType1,
    address,
    treatmentDetails,
    massageTreatmentDetails,
    isPlatformFeeApplied,
    updatingForBooking,

    //For corporate booking
    numberOfPerson,
    durationPerPerson,
    numberOfPros,
    totalEventDuration,
    treatmentId,
  } = useBookingStore.getState();
  const isMassage = isMassageType();
  const isCorporateType = isCorporateTreatmentType();

  const { selectedDay, earliestStartInMinutes, latestStartInMinutes, selectedDayRange } =
    useDateTimeStore.getState();
  const { setErrorMessage } = useAlertStore.getState();

  const timezone = moment.tz.guess();

  const accessToken = localStorage.getItem("token");
  const couponCodeForBooking = getValue(updatingForBooking, "coupon.code", null);

  let data = {
    sessionType,
    typeOfLocation: address?.type,
    addressId: address?.id,
    couponCode: couponCode ? couponCode : couponCodeForBooking,
    timezone,
    accessToken: accessToken || BOOKING_PRICE_MASTER_TOKEN,
    isPlatformFeeApplied,
    bookingId: getValue(updatingForBooking, "id", null),
    paymentType,
    country: getValue(address, "countryCode", "AU"),
  } as GetBookingPriceParams;

  if (isCorporateType) {
    data = {
      ...data,
      massageType: massageType1,
      sessionType: BOOKING_SESSION_TYPE.CORPORATE,
      treatmentDetails: [{ treatmentTypeId: treatmentId }],
      numberOfTherapists: numberOfPros || null,
      massageDuration: totalEventDuration ? totalEventDuration.massageDuration : null,
      numberOfPerson,
      durationPerPerson,
    };
  } else if (isMassage) {
    data.treatmentDetails = formatMassageTreatmentDetailsData(massageTreatmentDetails);
  } else {
    data.massageDuration = massageLength;
    data.treatmentDetails = formatTreatmentDetailsData(treatmentDetails);
  }

  if (selectedDayRange.from && earliestStartInMinutes) {
    const earliestTime = formatTimezonedDateWithDateAndTime(
      selectedDayRange.from,
      earliestStartInMinutes,
      timezone
    );

    data.earliestTime = earliestTime;
  }

  if (selectedDayRange.to && latestStartInMinutes) {
    const latestTime = formatTimezonedDateWithDateAndTime(
      selectedDayRange.to,
      latestStartInMinutes,
      timezone
    );

    data.latestTime = latestTime;
  }

  if (isHairAndMakeupBooking()) {
    const sessionTypes: string[] = [];
    treatmentDetails.forEach((td) => {
      if (getTreatmentDetailByTreatmentId(td.treatmentId)) {
        sessionTypes.push(getTreatmentDetailByTreatmentId(td.treatmentId).name);
      }
    });
    data.sessionType = sessionTypes.join(",") as string;
  } else if (!isMassage && !isCorporateType) {
    data.sessionType = getServiceName();
  }
  const answerParams = getAnswerParams();
  data = { ...data, ...answerParams };

  axios
    .get("/bookings/price", {
      params: data,
      cancelToken: bookingPriceCancelToken.token,
    })
    .then((response) => {
      const price = response.data as BookingPrice;
      usePaymentStore.setState({
        bookingPrice: price,
      });
    })
    .catch((error) => {
      if (!axios.isCancel(error)) {
        setErrorMessage("Unable to fetch booking price. Please try again");
      }
    });
}

export function getTotal(): number | undefined {
  const { bookingPrice } = usePaymentStore.getState();
  return bookingPrice?.price;
}

export function getAfterpayInstalment() {
  return (getTotal() || 0) / 4;
}

export function getServiceCategory() {
  const { serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();

  return rates.find((rate) => rate.id === serviceId)?.name;
}

export function getProfessionByServiceId(serviceId: number | null) {
  if (!serviceId) return "therapist";
  const { rates } = useServicesStore.getState();
  return rates.find((rate) => rate.id === serviceId)?.profession;
}

export function getServiceCategoryProfession() {
  const { serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();

  return rates.find((rate) => rate.id === serviceId)?.profession;
}

export function getServiceName() {
  const { massageType1, serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();

  const isMassage = isMassageType();
  const isCorporateType = isCorporateTreatmentType();

  if (isCorporateType) {
    return rates.find((rate) => rate.id === serviceId)?.alias;
  } else if (isMassage) {
    return massageType1;
  } else {
    return getTreatmentDetails()?.name;
  }
}

export function getSelectedServiceName() {
  const { serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();

  return rates.find(({ id }) => id === serviceId)?.alias;
}

export function getSelectedService() {
  const { serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();

  return rates.find(({ id }) => id === serviceId);
}

export function getServicePrice() {
  const { bookingPrice } = usePaymentStore.getState();
  return bookingPrice?.subtotal ?? 0;
}

export function getSubtotal() {
  const { bookingPrice } = usePaymentStore.getState();

  return getServicePrice() + (bookingPrice?.hotelSurcharge ?? 0);
}

export const updateBooking = () => {
  const accessToken = localStorage.getItem("token");
  const {
    selectedDay,
    earliestStartInMinutes,
    latestStartInMinutes,
    backupSelectedDay,
    backupEarliestStartInMinutes,
    backupLatestStartInMinutes,
    selectedDayRange,
    backupSelectedDayRange,
  } = useDateTimeStore.getState();

  const {
    serviceId,
    sessionType,
    massageType1,
    massageType2,
    genderPreference1,
    genderPreference2,
    massageLength,
    massageLength2,
    address,
    recipient,
    updatingForBooking,
    deliveryMethod,
    genderFallback,
    genderPreference,
    treatmentDetails,
    specialInstructions: bookingInstructions,
    massageTreatmentDetails,
    contraindications,
    preferences,
    preferredTherapists,

    //For corporate Booking
    treatmentId,
    numberOfPerson,
    durationPerPerson,
    numberOfPros,
    eventType,
    eventName,
    noteForRecipients,
    frequency,
    recurring,
    isSameProvider,
    recuringEndOn,
    updateFutureRecurrings,
  } = useBookingStore.getState();
  const isMassage = isMassageType();
  const isCorporateType = isCorporateSession(sessionType);
  const isCouplesMassage = isCoupleMassageType(sessionType);
  const isBackToBackMassage = isBackToBackMassageType(sessionType);

  // NOTE: Need to use the same timezone as the booking we're updating
  const timezone = address?.timezone || updatingForBooking?.timezone || "";

  const earliestTime = minutesToTime(earliestStartInMinutes || 0);
  const latestTime = minutesToTime(latestStartInMinutes || 0);

  const selectedDayMoment = convertDatetoTimezoneMoment(selectedDay || new Date(), timezone);
  const selectedStartDayMoment = convertDatetoTimezoneMoment(selectedDayRange.from, timezone);
  const selectedEndDayMoment = convertDatetoTimezoneMoment(selectedDayRange.to, timezone);

  const earliestTimeString = setMomentTime(selectedStartDayMoment, earliestTime, timezone);
  const latestTimeString = setMomentTime(selectedEndDayMoment, latestTime, timezone);

  const {
    theirSpecialInstructions: specialInstructions,
    theirContraindications,
    massageFor,
  } = useRecipientDetailsStore.getState();

  const { paymentMethod, paymentType } = usePaymentStore.getState();

  const { rates } = useServicesStore.getState();

  let massageType: any = massageType1;

  // if session type is not massage get from treatmentDetails
  if (!isMassage && getTreatmentDetails()?.label) {
    const service = rates.find((rate) => rate.id === serviceId);
    if (service && service.name) massageType = `${service.name} | ${getTreatmentDetails().label}`;
  }

  // NOTE: Workaround to find the correct sessionType for non-massage services
  const allPrices = rates.flatMap((rate) => rate.prices);
  const labelTokens = massageType?.split(" | ");
  const priceForSessionType =
    labelTokens?.length === 2
      ? allPrices.find((price) => price.label === labelTokens[1])
      : undefined;

  let instructions = "";
  if (recipient) {
    instructions = specialInstructions ? specialInstructions.trim() : "";
  } else {
    instructions = bookingInstructions ? bookingInstructions.trim() : "";
  }

  let bookingInstruction = {};
  if (recipient) {
    bookingInstruction = {
      note: specialInstructions ? specialInstructions.trim() : "",
      contraindications: theirContraindications,
    };
  } else {
    bookingInstruction = {
      note: bookingInstructions ? bookingInstructions.trim() : "",
      preference: preferences,
      contraindications,
    };
  }

  const prefProForPayload = !checkIfEmpty(preferredTherapists)
    ? (preferredTherapists || []).reduce((pros, prefPro) => {
      const proId = getValue(prefPro, "therapistData.id");

      return pros ? `${pros}, ${proId}` : `${proId}`;
    }, "")
    : null;

  if (isCorporateType) {
    const payload: any = {
      addressId: address?.id,
      durationPerPerson,
      earliestTime: earliestTimeString,
      eventName,
      noteForRecipients,
      eventType,
      latestTime: latestTimeString,
      numberOfPerson,
      numberOfPros,
      recipientId: recipient ? recipient.id : null,
      serviceId,
      specialInstructions: instructions,
      instructions: bookingInstruction,
      treatmentId,
      updateFutureRecurrings,
      genderPreference: genderPreference1,
      genderFallback,
      ...(prefProForPayload ? { preferredTherapists: prefProForPayload } : {}),
    };
    if (!updatingForBooking?.id) return Promise.resolve();

    return updateCorporateBooking(updatingForBooking?.id, payload);
  }

  const data: any = {
    sessionType: priceForSessionType ? priceForSessionType.name : sessionType, // NOTE: Using workaround above
    accessToken,
    earliestTime: earliestTimeString,
    latestTime: latestTimeString,
    addressId: address?.id,
    massageFor,
    recipient: recipient
      ? { id: recipient.id, specialInstructions: recipient.specialInstructions }
      : null,
    serviceId,
    booking: {
      massageType: massageType,
      massageType2,
      genderPreference: genderPreference1,
      genderPreference2,
      specialInstructions: instructions,
      deliveryMethod,
      genderFallback,
      instructions: bookingInstruction,
    },
    paymentMethodId: paymentType === PaymentType.card ? paymentMethod?.id : null, // TODO: For Apple Pay
    channel: "web",
    avoidTimeOfArrivalUpdate: true,
    treatmentDetails,
    frequency,
    isSameProvider,
    recurring,
    recuringEndOn,
    updateFutureRecurring: updateFutureRecurrings,
    ...(prefProForPayload ? { preferredTherapists: prefProForPayload } : {}),
  };

  if (backupSelectedDay === null) {
    data.backup = null;
  } else if (backupSelectedDay && backupEarliestStartInMinutes) {
    const backupSelectedStartDayMoment = convertDatetoTimezoneMoment(
      backupSelectedDayRange.from,
      timezone
    );
    const backupSelectedEndDayMoment = convertDatetoTimezoneMoment(
      backupSelectedDayRange.to,
      timezone
    );

    const earliestTime = minutesToTime(backupEarliestStartInMinutes);
    const latestTime = minutesToTime(backupLatestStartInMinutes as number);
    data.backup = {
      earliestTime: setMomentTime(backupSelectedStartDayMoment, earliestTime, timezone),
      latestTime: setMomentTime(backupSelectedEndDayMoment, latestTime, timezone),
    };
  }

  let bookingDetails: any[] = [];

  const bookingAnswers = getAnswersApiValue();
  if (isHairAndMakeupBooking() && data.treatmentDetails) {
    data.treatmentDetails = data.treatmentDetails.map((detail: any, index: number) => {
      if (bookingAnswers && bookingAnswers[index]) {
        detail.bookingAnswers = bookingAnswers[index];
      }
      detail.addons = formatTreatmentAddOnData(detail?.addons || []);
      return detail;
    });
  } else if (!isMassage) {
    data.bookingAnswers = bookingAnswers[0] || [];
  }

  // to support old bookings
  if (isMassage) {
    bookingDetails.push({
      massageType: massageType1,
      deliveryMethod: "inperson",
      genderPreference: genderPreference1,
      genderFallback: genderFallback,
      serviceDuration: massageLength,
      treatmentTypeId: massageTreatmentDetails[0].treatmentTypeId,
      addons: formatTreatmentAddOnData(massageTreatmentDetails[0]?.addons || []),
    });

    if (isCouplesMassage) {
      bookingDetails.push({
        massageType: massageType2,
        deliveryMethod: "inperson",
        genderPreference: genderPreference2,
        serviceDuration: massageLength2,
        treatmentTypeId: massageTreatmentDetails[1].treatmentTypeId,
        addons: formatTreatmentAddOnData(massageTreatmentDetails[1]?.addons || []),
      });
    }

    if (isBackToBackMassage) {
      bookingDetails[0].treatmentDetails = [
        {
          massageType: massageType1,
          serviceDuration: massageLength,
          treatmentTypeId: massageTreatmentDetails[0].treatmentTypeId,
          addons: formatTreatmentAddOnData(massageTreatmentDetails[0]?.addons || []),
        },
        {
          massageType: massageType2,
          serviceDuration: massageLength2,
          treatmentTypeId: massageTreatmentDetails[1].treatmentTypeId,
          addons: formatTreatmentAddOnData(massageTreatmentDetails[1]?.addons || []),
        },
      ];

      // @todo: sending on bookingDetails [] as well for back to back
      bookingDetails.push({
        massageType: massageType2,
        deliveryMethod: "inperson",
        serviceDuration: massageLength2,
        treatmentTypeId: massageTreatmentDetails[1].treatmentTypeId,
        addons: formatTreatmentAddOnData(massageTreatmentDetails[1]?.addons || []),
      });
    }
    data.booking.bookingDetails = bookingDetails;

    data.treatmentDetails = [];
    if (isBackToBackMassage) {
      data.booking.bookingDetails[0].treatmentDetails.forEach(
        ({ treatmentTypeId, addons }: any, index: number) => {
          data.treatmentDetails.push({
            treatmentTypeId,
            bookingAnswers: (bookingAnswers && bookingAnswers[index]) || [],
            addons: formatTreatmentAddOnData(addons || []),
          });
        }
      );
    } else {
      data.booking.bookingDetails.forEach(({ treatmentTypeId, addons }: any, index: number) => {
        data.treatmentDetails.push({
          treatmentTypeId,
          bookingAnswers: (bookingAnswers && bookingAnswers[index]) || [],
          addons: formatTreatmentAddOnData(addons || []),
        });
      });
    }
  } else {
    data.booking.massageDuration = massageLength;
    data.treatmentDetails = transformTreatmentDetailsForUpdate(treatmentDetails);
    data.booking.genderPreference = genderPreference;
    data.booking.genderFallback = genderFallback;
  }

  const isServiceUpdated = updatingForBooking && updatingForBooking.serviceId !== serviceId;
  const path =
    isHairAndMakeupBooking() || isServiceUpdated
      ? `api/v2/bookings/${updatingForBooking?.id}/pending`
      : `bookings/${updatingForBooking?.id}`;

  if (!updatingForBooking?.id) return Promise.resolve();
  return put(path, data);
};

// For rebooking use
export const prefillDateAndTime = (adjustTime: boolean = true) => {
  const { sessionType, address } = useBookingStore.getState();
  const { minTime, maxTime } = useServicesStore.getState();

  const timezone = address?.timezone;

  let nowMoment = getCurrentMoment(timezone);

  // default values, 06:00 am - 11:59 pm
  let dateMoment = getCurrentMoment(timezone);
  const earliestTimeMoment = getCurrentMoment(timezone).set({
    hours: minTime.hour,
    minutes: minTime.minute,
  });
  let earliestStartInMinutes = getMomentTimeInMinutes(earliestTimeMoment);

  // Before 11pm
  if (nowMoment.hour() < MIN_HOUR_ALLOWED_TODAY) {
    let minBufferHour = nowMoment.hours();

    // adding 1 hour buffer on earliest time for couples booking only
    const bufferHours =
      isCoupleMassageType(sessionType) || isCorporateTreatmentType() ? BUFFER_TIME_HOURS : 0;
    const timeWithBuffer = getCurrentMoment(timezone).add({ hours: bufferHours });
    minBufferHour = timeWithBuffer.hour();

    if (minBufferHour >= minTime.hour) {
      const earliestTimeMoment = convertTimeToMatchTimePicker(timeWithBuffer);
      earliestStartInMinutes = getMomentTimeInMinutes(earliestTimeMoment);
    }
  } else {
    // 11pm onwards, set to tomorrow
    dateMoment = getCurrentMoment(timezone).add({ days: 1 });
  }

  // REMINDER: Couples and Corporate only have earliest time
  let updated: any = {
    selectedDay: convertDayMomentToDate(dateMoment),
    selectedDayRange: {
      from: convertDayMomentToDate(dateMoment),
      to: convertDayMomentToDate(dateMoment),
    },
    timeRangeFilterFromInMinutes: earliestStartInMinutes,
  };
  if (adjustTime) {
    updated = {
      ...updated,
      earliestStartInMinutes,
    };
  }
  useDateTimeStore.setState({ ...updated });
};

export const prefillBackupDateAndTime = () => {
  const { selectedDay, earliestStartInMinutes, latestStartInMinutes } = useDateTimeStore.getState();

  useDateTimeStore.setState({
    backupSelectedDay: selectedDay,
    backupEarliestStartInMinutes: earliestStartInMinutes,
    backupLatestStartInMinutes: latestStartInMinutes || null,
    backupSelectedDayRange: { from: getDateObject(selectedDay), to: getDateObject(selectedDay) },
  });
};

export const removeBackupDateAndTime = () => {
  useDateTimeStore.setState({
    backupSelectedDay: null,
    backupEarliestStartInMinutes: null,
    backupLatestStartInMinutes: null,
    backupSelectedDayRange: { from: new Date(), to: new Date() },
  });
};

export const preselectLastPaymentMethod = () => {
  const accessToken = localStorage.getItem("token");
  const adminAccessToken = getAdminAccessToken();

  const { paymentMethod, skipPayment } = usePaymentStore.getState();
  if (paymentMethod || (!!adminAccessToken && skipPayment)) return;

  axios
    .get(`/paymentmethods/last?accessToken=${accessToken}`)
    .then((response) => {
      const paymentMethod = response.data as PaymentMethod;
      const isExpired = getValue(paymentMethod, "isExpired", false);
      if (
        (paymentMethod.type === PaymentType.card || paymentMethod.type === PaymentType.paypal) &&
        !isExpired
      ) {
        usePaymentStore.setState({
          paymentType: paymentMethod.type,
          paymentMethod,
        });
      }
    })
    .catch((error) => {
      console.debug("preselectLastPaymentMethod error: ", error);
    });
};

export const getProductsForSegmentTracking = () => {
  const { sessionType, serviceId } = useBookingStore.getState();
  const {
    massageType2,
    massageLength,
    massageLength2,
    genderPreference1,
    genderPreference2,
    genderPreference,
  } = useBookingStore.getState();

  const isMassage = isMassageType();

  const _price = () => {
    if (isMassage && isCoupleMassageType(sessionType)) {
      return getServicePrice() / 2;
    }

    return getServicePrice();
  };

  const price = _price();

  let products = [
    {
      product_id: getTreatmentId(),
      name: getServiceName(),
      price,
      variant: isMassage ? massageLength : undefined,
      category: getServiceCategory() || SERVICE_TYPE.massage,
      quantity: 1,
      position: 1,
      gender_preference: isMassage ? genderPreference1 : undefined,
    },
  ];

  if (isMassage && isCoupleMassageType(sessionType)) {
    products.push({
      product_id: getTreatmentId(),
      name: massageType2,
      price,
      variant: isMassage ? massageLength2 : undefined,
      category: getServiceCategory() || SERVICE_TYPE.massage,
      quantity: 1,
      position: 2,
      gender_preference: isMassage ? genderPreference2 : undefined,
    });
  }

  if (isMassage && isBackToBackMassageType(sessionType)) {
    products.push({
      product_id: getTreatmentId(),
      name: massageType2,
      price,
      variant: isMassage ? massageLength2 : undefined,
      category: getServiceCategory() || SERVICE_TYPE.massage,
      quantity: 1,
      position: 2,
      gender_preference: isMassage ? genderPreference2 : undefined,
    });
  }

  return products;
};

export const isHairAndMakeupBooking = () => {
  const { serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();
  const service = rates.find(({ id }) => id === serviceId);

  return service ? service.isHairAndMakeup : false;
};

export const checkServiceIsHairAndMakeup = (serviceId: any) => {
  const { rates } = useServicesStore.getState();
  const service = rates.find(({ id }) => id === serviceId);
  return service ? service.isHairAndMakeup : false;
};

export const isHairColouringBooking = () => {
  const { serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();
  const service = rates.find(({ id }) => id === serviceId);

  return service ? service.isHairColouring : false;
};

export const checkServiceIsMassage = (serviceId: any, services?: ServiceRate[]) => {
  let rates = services || [];
  if (!rates || checkIfEmpty(rates)) {
    const { rates: storedRates } = useServicesStore.getState();
    rates = storedRates;
  }
  const service = rates.find(({ id }) => id === serviceId);
  return service ? service.isMassage : false;
};

export const isCorporateTreatmentType = () => {
  const { serviceId, treatmentId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();

  const service = rates.find(({ id }) => id === serviceId);
  const treatment = service?.prices.find(({ id }) => id === treatmentId);

  return treatment ? treatment.isCorporate : false;
};

export const isMassageType = (currentServiceId: null | number = null) => {
  const { serviceId } = useBookingStore.getState();

  let serviceIdToCheck = currentServiceId || serviceId;

  const { rates } = useServicesStore.getState();
  const service = rates.find(({ id }) => id === serviceIdToCheck);

  return service ? service.isMassage : false;
};

export const getMassageService = () => {
  const { serviceId } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();
  const service = rates.find(({ id }) => id === serviceId);
  if (!service || !service.isMassage) return {} as ServiceRate;

  return service;
};

export const getSessionTypes = () => {
  const { rates } = useServicesStore.getState();
  const sessionTypes: any = {};
  const service = rates.find(({ isMassage, sessionTypes }) => isMassage && sessionTypes);
  if (!service?.id || !service?.sessionTypes?.length) return sessionTypes;
  for (const session of service.sessionTypes) {
    sessionTypes[session.value] = session;
  }

  return sessionTypes;
};

export const getGenderPreferences = () => {
  const { rates } = useServicesStore.getState();
  const genderPreferences: any = {};
  const service = rates.find(({ isMassage, genderPreferences }) => isMassage && genderPreferences);
  if (!service?.id || !service?.genderPreferences?.length) return genderPreferences;
  for (const gender of service.genderPreferences) {
    genderPreferences[gender.value] = gender;
  }

  return genderPreferences;
};

export const getServiceDurations = () => {
  const { rates } = useServicesStore.getState();
  const durations: any = {};
  const service = rates.find(({ isMassage, duration }) => isMassage && duration);
  if (!service?.id || !service?.duration?.length) return durations;
  for (const duration of service.duration) {
    durations[duration.value] = duration;
  }

  return durations;
};

export const getMassageTypes = () => {
  const { rates } = useServicesStore.getState();
  const massageTypes: any = {};

  const service = rates.find(
    ({ isMassage, prices, category, alias }) => category === alias && isMassage && prices
  );
  if (!service?.id || !service?.prices?.length) return massageTypes;
  for (const type of service.prices) {
    massageTypes[type.name] = type;
  }
  return massageTypes;
};

export const getCorporateMassageTypes = () => {
  const { rates } = useServicesStore.getState();
  const massageTypes: any = {};
  const service = rates.find(
    ({ isMassage, prices, name }) => name === "corporate" && isMassage && prices
  );
  if (!service?.id || !service?.prices?.length) return massageTypes;
  for (const type of service.prices) {
    massageTypes[type.name] = type;
  }
  return massageTypes;
};

export const isCoupleMassageType = (value: string | null) => {
  if (!value) return false;
  const { rates } = useServicesStore.getState();
  const service = rates.find(({ isMassage, sessionTypes }) => isMassage && sessionTypes);
  if (!service?.id) return false;
  const treatment = (service?.sessionTypes || []).find(
    (sessionType) => sessionType.value === value
  );
  return treatment?.isCouples || false;
};

export const isBackToBackMassageType = (value: string | null) => {
  if (!value) return false;
  const { rates } = useServicesStore.getState();

  const service = rates.find(({ isMassage, sessionTypes }) => isMassage && sessionTypes);
  if (!service?.id) return false;
  const treatment = (service?.sessionTypes || []).find(
    (sessionType) => sessionType.value === value
  );
  return treatment?.isBackToBack || false;
};

export const isSingleMassageType = (value: string | null) => {
  if (!value) return false;
  const { rates } = useServicesStore.getState();

  const service = rates.find(({ isMassage, sessionTypes }) => isMassage && sessionTypes);
  if (!service?.id) return false;
  const treatment = (service?.sessionTypes || []).find(
    (sessionType) => sessionType.value === value
  );
  return treatment?.isSingle || false;
};

export const getAllQuestions = async () => {
  return axios.get("/api/v3/booking/question?includeServiceQuestions=true");
};

export const getCorporateDetails = () => {
  const {
    numberOfPerson,
    totalEventDuration,
    durationPerPerson,
    numberOfPros,
    eventType,
    eventName,
    noteForRecipients,
    paymentSplit,
    genderPreference1,
  } = useBookingStore.getState();
  return {
    numberOfPerson,
    totalEventDuration,
    durationPerPerson,
    numberOfPros,
    eventType,
    eventName,
    noteForRecipients,
    paymentSplit,
    genderPreference: genderPreference1,
  };
};

export const setCorporateBookingWithQuotation = (data: CorporateBookingQuotation | null) => {
  if (!data) return;

  // prepare required state for booking
  const corporateFields = {
    numberOfPerson: data.numberOfPerson,
    durationPerPerson: data.durationPerPerson,
    numberOfPros: data.numberOfPros,
    eventType: data.eventType,
    eventName: data.eventName,
    paymentSplit: data.paymentSplit,
    serviceId: data.serviceId,
    treatmentId: data.treatmentId,
    address: data.address,
    recipient: data.recipient,
    frequency: data.frequency,
    totalEventDuration: data.totalEventDuration,
  };

  const dateTimeFields = {
    // corporate bookings only has start time
    selectedDay: convertDayMomentToDate(convertToMoment(data.earliestTime, null)),
    earliestStartInMinutes: getMomentTimeInMinutes(convertToMoment(data.earliestTime, null)),
    latestStartInMinutes: getMomentTimeInMinutes(convertToMoment(data.earliestTime, null)),
  };

  // set required states for corporate booking
  useBookingStore.setState({ ...corporateFields });
  useDateTimeStore.setState({ ...dateTimeFields });
  useRecipientDetailsStore.setState({ massageFor: data.massageFor });
};

const getCumulativeTreatmentDuration = (treatmentIds: Array<Number> = []) => {
  if (treatmentIds.length === 0) return 0;

  const treatments = treatmentIds.map((treatmentId) => {
    const treatment = getTreatmentDetailByTreatmentId(treatmentId);
    return treatment;
  });

  return treatments.reduce((sum, treatment) => sum + treatment?.duration || 0, 0);
};

export const getDurationForMassageService = () => {
  const { massageLength, massageLength2, sessionType } = useBookingStore.getState();
  const { answers: questionAnswers, questions } = useServicesStore.getState();

  if (!massageLength && !massageLength2) return "0min";

  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCouplesMassage = isCoupleMassageType(sessionType);

  let massageDurationSingle = massageLength || 0;
  let massageDurationCouples = massageLength2 || 0;

  // duration questions
  const answerDurations = [];

  for (const answers of questionAnswers) {
    let totalDurationFromQuestions = 0;
    if (answers && answers.length) {
      const filteredAnswer =
        answers.filter(
          (answer: any) => answer?.treatmentId && answer.type === QUESTION_TYPE.DURATION
        ) || [];
      filteredAnswer.forEach((answer: any) => {
        const selectedQuestion = questions.find((question) => question.id === answer.questionId);
        const selectedAnswer = (selectedQuestion?.answerDetails || []).find(
          (ans) => ans.value === answer.answer
        );

        totalDurationFromQuestions += selectedAnswer?.addedDuration || 0;
      });
    }
    answerDurations.push(totalDurationFromQuestions);
  }

  const [singleQuestionDuration, couplesQuestionDuration] = answerDurations || [];

  massageDurationSingle += singleQuestionDuration || 0;
  massageDurationCouples += couplesQuestionDuration || 0;

  if ((isBackToBackMassage || isCouplesMassage) && massageDurationCouples) {
    return `${massageDurationSingle}mins + ${massageDurationCouples}mins`;
  } else {
    return `${massageDurationSingle}mins`;
  }
};

export const getServiceDurationFromDetails = (sessionType: string, bookingdetails: any) => {
  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCouplesMassage = isCoupleMassageType(sessionType);

  const bookingDetail = getValue(bookingdetails, "[0]", {});
  const bookingDetail2 = getValue(bookingdetails, "[1]", {});

  const length1 =
    getValue(bookingDetail, "treatmentDetails[0].serviceDuration") ||
    getValue(bookingDetail, "serviceDuration", 0);

  const backtobackLength = isBackToBackMassage
    ? getValue(bookingDetail, "treatmentDetails[1].serviceDuration", 0) ||
      getValue(bookingDetail, "treatmentDuration") ||
      getValue(bookingDetail, "serviceDuration", 0)
    : 0;

  const couplesLength = isCouplesMassage
    ? getValue(bookingDetail2, "treatmentDetails[0].serviceDuration") ||
      getValue(bookingDetail2, "treatmentDuration") ||
      getValue(bookingDetail2, "serviceDuration", 0)
    : 0;

  const length2 = backtobackLength || couplesLength;
  return [length1, length2];
};

export const getMassageDurationFromBooking = (sessionType: string, bookingdetails: any) => {
  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCouplesMassage = isCoupleMassageType(sessionType);

  const bookingDetail = getValue(bookingdetails, "[0]", {});
  const bookingDetail2 = getValue(bookingdetails, "[1]", {});

  const length1 =
    getValue(bookingDetail, "treatmentDetails[0].treatmentDuration") ||
    getValue(bookingDetail, "treatmentDetails[0].serviceDuration") ||
    getValue(bookingDetail, "serviceDuration", 0);

  const backtobackLength = isBackToBackMassage
    ? getValue(bookingDetail, "treatmentDetails[1].treatmentDuration") ||
      getValue(bookingDetail, "treatmentDetails[1].serviceDuration", 0) ||
      getValue(bookingDetail, "treatmentDuration") ||
      getValue(bookingDetail, "serviceDuration", 0)
    : 0;

  const couplesLength = isCouplesMassage
    ? getValue(bookingDetail2, "treatmentDetails[0].treatmentDuration") ||
      getValue(bookingDetail2, "treatmentDetails[0].serviceDuration") ||
      getValue(bookingDetail2, "treatmentDuration") ||
      getValue(bookingDetail2, "serviceDuration", 0)
    : 0;

  const length2 = backtobackLength || couplesLength;
  return [length1, length2];
};

export const getDurationForNonMasasgeService = (
  treatmentIds = [] as Array<Number>,
  includeServiceQuestions = false,
  checkQuestionsForDuration = true
) => {
  const { treatmentDetails } = useBookingStore.getState();
  const { answers, questions } = useServicesStore.getState();
  let treatmentIDS = [] as Array<Number>;

  if (treatmentIds.length === 0) {
    treatmentIDS = (treatmentDetails || []).map(
      (treatment) => treatment.treatmentId || treatment.treatmentTypeId
    );
  } else {
    treatmentIDS = treatmentIds;
  }

  const addons = treatmentDetails.flatMap((data) => getValue(data, "addons", []));
  const addonsTotalDurations =
    addons.reduce((sum, addon) => sum + getValue(addon, "duration", 0), 0) || 0;

  const totalTreatmentDuration = getCumulativeTreatmentDuration(treatmentIDS);
  let totalDurationFromQuestions = 0;
  if (checkQuestionsForDuration) {
    const filteredAnswer = answers
      .flat()
      .filter(
        (answer) =>
          treatmentIDS.includes(answer.treatmentId) ||
          (includeServiceQuestions ? checkIfNil(answer.treatmentId) : false)
      )
      .filter((answer) => answer.type === QUESTION_TYPE.DURATION);

    filteredAnswer.forEach((answer) => {
      const selectedQuestion = questions.find((question) => question.id === answer.questionId);
      const selectedAnswer = (selectedQuestion?.answerDetails || []).find(
        (ans) => ans.value === answer.answer
      );

      totalDurationFromQuestions += selectedAnswer?.addedDuration || 0;
    });
  }

  return totalTreatmentDuration + totalDurationFromQuestions + addonsTotalDurations;
};

// converts answer & question ids to readable format
export const getFormattedQuestionAnswer = (
  answers: any = [],
  questions: any = [],
  serviceId: any
) => {
  let selectedQuestions = questions;
  if (serviceId) {
    selectedQuestions = questions.filter(({ serviceId: id }: any) => serviceId === id);
  }
  return answers.map(({ answer, questionId }: any) => {
    const selectedQue: any = selectedQuestions.find(({ id }: any) => id === questionId);
    const selectedAns: any = selectedQue?.answerDetails?.find(({ value }: any) => value === answer);

    const payload: any = {
      question: selectedQue?.question,
      answer: selectedAns?.title,
      addedPrice: selectedAns?.addedPrice,
      addedDuration: selectedAns?.addedDuration,
      treatmentId: selectedQue?.treatmentId,
      serviceId: selectedQue?.serviceId,
      type: selectedQue?.type,
      addOn: selectedQue?.isAddOns,
      attrs: selectedQue?.attrs ?? {},
    };
    return payload;
  });
};

export const getTreatmentDetailsIcon = (numberOfPros = 1) => {
  /*   if (numberOfPros > 2) return MultipleTherapistIcon;
  if (numberOfPros === 2) return DoubleTherapistIcon;
  return SingleTherapistIcon; */
  return TreatmentIcon; //Changed to candle as per requirment.
};

export function setTreatmentAddOn({
  treatment,
  treatmentIndex,
  isMultipleSelectAllow,
}: {
  treatment: any;
  treatmentIndex: number;
  isMultipleSelectAllow: boolean;
}) {
  const isMassage = isMassageType();
  const newAddOn = { ...treatment, treatmentIndex };
  let addOns = [] as any;

  const { massageTreatmentDetails, treatmentDetails } = useBookingStore.getState();

  const treatmentArray = isMassage ? massageTreatmentDetails : treatmentDetails;
  const newTreatmentArray = [...treatmentArray];

  if (!newTreatmentArray[treatmentIndex]) {
    return;
  }

  let existingAddOnIndex = newTreatmentArray[treatmentIndex]?.addons?.findIndex(
    (data: any) => data.id === treatment.id || data.id === treatment?.treatmentAddons?.id
  );

  const treatmentAddOns = newTreatmentArray[treatmentIndex]?.addons || [];

  if (existingAddOnIndex !== -1 && !checkIfUndefined(existingAddOnIndex)) {
    const removeAlreadyExistAddOn =
      treatmentAddOns.filter(
        (data: any) => data.id !== treatment.id && data.id !== treatment?.treatmentAddons?.id
      ) || [];
    newTreatmentArray[treatmentIndex].addons = [...removeAlreadyExistAddOn];
  } else {
    newTreatmentArray[treatmentIndex].addons = isMultipleSelectAllow
      ? [...treatmentAddOns, newAddOn]
      : [newAddOn];
  }

  useBookingStore.setState({
    addOn: addOns,
    massageTreatmentDetails: isMassage ? newTreatmentArray : massageTreatmentDetails,
    treatmentDetails: isMassage ? treatmentDetails : newTreatmentArray,
  });
}

export function resetTreatmentAddOn({
  treatmentIndex,
  treatmentId,
}: {
  treatmentIndex: number;
  treatmentId?: number;
}) {
  const { massageTreatmentDetails, treatmentDetails } = useBookingStore.getState();
  const isMassage = isMassageType();

  const newMassageTreatmentDetails = [...massageTreatmentDetails];
  const newTreatmentDetails = [...treatmentDetails];

  if (isMassage && !newMassageTreatmentDetails[treatmentIndex]) return;
  if (!isMassage && !newTreatmentDetails[treatmentIndex]) return;

  if (isMassage && newMassageTreatmentDetails[treatmentIndex].treatmentTypeId !== treatmentId) {
    newMassageTreatmentDetails[treatmentIndex].addons = [];
  } else if (!isMassage && newTreatmentDetails[treatmentIndex].treatmentId !== treatmentId) {
    newTreatmentDetails[treatmentIndex].addons = [];
  }

  useBookingStore.setState({
    massageTreatmentDetails: newMassageTreatmentDetails,
    treatmentDetails: newTreatmentDetails,
  });
}

export const getTreatmentAddOns = ({ treatmentIndex }: { treatmentIndex: number }) => {
  const isMassage = isMassageType();
  const { serviceId, massageTreatmentDetails, treatmentDetails } = useBookingStore.getState();
  const { rates } = useServicesStore.getState();
  const service = rates.find(({ id }) => id === serviceId);

  if (!service) return [];

  const { prices, selectedCountry } = service;
  if (isMassage && !massageTreatmentDetails[treatmentIndex]) return [];
  if (!isMassage && !treatmentDetails[treatmentIndex]) return [];

  const treatmentId = isMassage
    ? massageTreatmentDetails[treatmentIndex].treatmentTypeId
    : treatmentDetails[treatmentIndex].treatmentId;

  const treatment = prices.find(({ id }) => id === treatmentId);

  const addons = tranformTreatmentAddOn({
    addons: treatment?.addons || [],
    custom: false,
    selectedCountry,
  });
  return addons;
};

export const getSelectedAddOnsForTreatment = ({ treatmentIndex }: { treatmentIndex: number }) => {
  const { massageTreatmentDetails, treatmentDetails } = useBookingStore.getState();
  const isMassage = isMassageType();

  return isMassage
    ? massageTreatmentDetails[treatmentIndex]?.addons || []
    : treatmentDetails[treatmentIndex]?.addons || [];
};

export const isMultipleAddonsAllowed = ({ treatmentIndex }: { treatmentIndex: number }) => {
  const { massageTreatmentDetails, treatmentDetails } = useBookingStore.getState();
  const isMassage = isMassageType();

  if (isMassage && !massageTreatmentDetails[treatmentIndex]) return false;
  if (!isMassage && !treatmentDetails[treatmentIndex]) return false;

  let treatmentId = isMassage
    ? massageTreatmentDetails[treatmentIndex]?.treatmentTypeId
    : treatmentDetails[treatmentIndex]?.treatmentId;

  if (!treatmentId) return false;

  const treatment = getTreatmentDetailByTreatmentId(treatmentId);

  return treatment?.isMultipleAddonsAllowed || false;
};
