import React from 'react';
import { Box } from '@material-ui/core';

import { Colors } from '../../../constants/colors';

interface Props {
  text: string,
  style?: object,
}

const ContentHeader = ({ text = '', style = {} }: Props ) => {

  const defaultStyle = {
    fontWeight: 700,
    fontSize: '24px',
    fontFamily: 'Museo',
    marginBottom: '24px',
    color: Colors.NightBlue,
  };

  return <Box style= {{ ...defaultStyle, ...style }} >
    {text}
  </Box>
};

export default ContentHeader;