import React from "react";

import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import Dialog from "../Dialog";
import Button, { ButtonSize, ButtonType } from "../Button";

interface Props {
  open: boolean;
  onClose: (e: MouseEvent) => unknown;
  onConfirm?: () => void;
}

const OpenBookingConfirmationModal = ({
  open,
  onClose,
  onConfirm,
}: Props) => {
  const isMobile = useMobile();
  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile} style={{ overflowY: "visible" }}>
      <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="start"
        >
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="21px"
            color={Colors.NightBlue}
            textAlign="center"
            width="100%"
          >
            Open booking to all pros
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize="18px"
            color={Colors.BlueyGrey}
            mt="16px"
            width="100%"
            textAlign="center"
          >
            Please confirm if you’d like to open this booking to all available providers nearby for a faster confirmation. Your booking will be confirmed automatically when a provider accepts your request.
          </Box>

          <Box style={styles.actionWrapper} flexDirection="row">
            <Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title="Confirm"
              onClick={onConfirm}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "24px",
  },
};

export default OpenBookingConfirmationModal;
