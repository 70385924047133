import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  Box,
} from "@material-ui/core";
import { Colors } from "../../constants/colors";
import CustomBillingCheckBox from "./CustomBillingCheckBox";
import {
  BillingInvoicePaymentStatus,
  BillingInvoicePaymentStatusText,
} from "../../constants/booking";
import { FontSize, FontWeight } from "../v2/Styled/enum";
import { BillingInvoiceCheckBoxChange } from "../../pages/Payment/Billing";
import { getValue } from "../../utils/object";
import { formatDateWithTimezone } from "../../utils/date.util";
import { useMobile } from "../../hooks/mobile";

interface Props {
  onCheckBoxChange: ({ checked, bookingId }: BillingInvoiceCheckBoxChange) => unknown;
  onViewBookingClick: (val: number) => void;
  billingInvoices: any[];
  selectedBillingInvoices: number[];
  totalDueCount: number;
  isCurrentPageAllDueInvoiceSelected: boolean;
  onAllDueInvoiceSelected: () => void;
  onAllDueInvoiceRemoved: () => void;
  isAllDueInvoiceSelected: boolean;
}

const useStyles = makeStyles({
  table: {
    width: "100%",
  },
  header: {
    fontFamily: "SF UI Text",
    fontSize: "14px",
    fontWeight: 500,
    color: Colors.Grey,
    lineHeight: "18px",
    padding: "16px 8px",
    "&:first-child": {
      paddingLeft: "16px",
    },
    "&:last-child": {
      paddingRight: "16px",
    },
  },
  cell: {
    fontFamily: "Museo",
    fontSize: "16px",
    color: Colors.Dusk,
    fontWeight: 500,
    padding: "16px 8px",
    "&:first-child": {
      paddingLeft: "16px",
    },
    "&:last-child": {
      paddingRight: "16px",
    },
  },
});

const getBookingPaymentStatusBackground = (status: string) => {
  switch (status) {
  case BillingInvoicePaymentStatus.pending:
    return Colors.Champagne;

  case BillingInvoicePaymentStatus.partial:
    return Colors.LightPeriwinkle;

  case BillingInvoicePaymentStatus.overdue:
    return Colors.SecondaryPink;

  case BillingInvoicePaymentStatus.full:
    return Colors.Peppermint;

  default:
    return Colors.PowderBlue;
  }
};

const getBookingPaymentStatusText = (status: string) => {
  switch (status) {
  case BillingInvoicePaymentStatus.pending:
    return BillingInvoicePaymentStatusText.pending;

  case BillingInvoicePaymentStatus.partial:
    return BillingInvoicePaymentStatusText.partial;

  case BillingInvoicePaymentStatus.overdue:
    return BillingInvoicePaymentStatusText.overdue;

  case BillingInvoicePaymentStatus.full:
    return BillingInvoicePaymentStatusText.full;

  default:
    return "";
  }
};
interface RowProps {
  title: string;
  value: string;
  containerStyle?: object;
}

function Row({ title, value, containerStyle = {} }: RowProps) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" mt={0.5} mb={0.5}>
      <Box fontFamily="SF Pro Text" fontSize="14px" fontWeight={500} color={Colors.Grey}>
        {title}
      </Box>
      <Box
        fontFamily="SF Pro Text"
        fontSize="14px"
        color={Colors.Grey}
        style={{ fontWeight: 500, ...containerStyle }}
      >
        {value}
      </Box>
    </Box>
  );
}

const getBookingPaymentStatusStyle = (status: string) => {
  let textStyle = {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "SF UI Text",
    color: Colors.NightBlue,
    backgroundColor: Colors.Red,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 12px",
    borderRadius: "24px",
    letterSpacing: "0.5px",
    width: "fit-content",
    lineHeight: "15.6px",
  };

  textStyle.backgroundColor = getBookingPaymentStatusBackground(status);

  return textStyle;
};

const BillingInvoiceTable: React.FC<Props> = ({
  onViewBookingClick,
  onCheckBoxChange,
  billingInvoices,
  selectedBillingInvoices,
  totalDueCount,
  isCurrentPageAllDueInvoiceSelected,
  isAllDueInvoiceSelected,
  onAllDueInvoiceRemoved,
  onAllDueInvoiceSelected,
}) => {
  const classes = useStyles();
  const isMobile=useMobile();
  return (
    <>
      {isMobile && (
        <>
          {/* Todo after api implemation*/}
          {/* {isCurrentPageAllDueInvoiceSelected && (
             
            <Box
              style={{
                textAlign: "center",
                fontFamily: "Museo",
                fontWeight: 500,
                fontSize: "14px",
                color: Colors.Dusk,
              }}
                  
            >
              {isAllDueInvoiceSelected ? (
                <>
                          All
                  <span style={{ fontWeight: 700 }}>
                            &nbsp;{selectedBillingInvoices.length}
                  </span>{" "}
                          due invoice in Billings are selected.
                  <span
                    style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }}
                    onClick={onAllDueInvoiceRemoved}
                  >
                            &nbsp;Clear selection.
                  </span>
                </>
              ) : (
                <>
                          All
                  <span style={{ fontWeight: 700 }}>
                            &nbsp;{selectedBillingInvoices.length}
                  </span>{" "}
                          due invoices on this page are selected.
                  <span
                    style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }}
                    onClick={onAllDueInvoiceSelected}
                  >
                            &nbsp;Select all of your {totalDueCount} due invoices in Billings.
                  </span>
                </>
              )}
            </Box>
             
          )} */}
          <Box>
            
            {billingInvoices.map((data:any) => {
              const paymentStatus = getValue(data, "paymentStatus");
              const bookingPaymentStatusStyle = getBookingPaymentStatusStyle(paymentStatus);
              const bookingPaymentStatusText = getBookingPaymentStatusText(paymentStatus);
              const dueDate = getValue(data, "dueDate");
              const formatDueDate = dueDate
                ? formatDateWithTimezone(dueDate, data.timezone, "ddd, D MMM YYYY")
                : "";
              return (
                <Box key={`due-invoice-mobile-${data.id}`} mx={2} my={4} py={2} bgcolor="white"     
                  onClick={() => onViewBookingClick(data.id)}>
                  <Row title="Invoice&nbsp;#" value={`#${data.id}`} />
             
                  <Row title="Booking " value={formatDueDate} />
                  <Row title="Due Date" value={formatDueDate} />
                  <Row title="Amount to pay" value={`${getValue(data, "currency")}
                      ${parseFloat(getValue(data, "remainingAmount", 0)).toFixed(2)}`}/>
                  <Row title="Paymnet Status&nbsp;" value={bookingPaymentStatusText} containerStyle={bookingPaymentStatusStyle} />
  
             
                </Box>
              );
            })}
          </Box>
        </>
      )}
      {!isMobile &&
   
      <Paper
        style={{
          overflowX: "auto",
          width: "100%",
        }}
      >
        <TableContainer
          // component={Paper}
          id="demos"
          style={{
            width: "100%",
            minWidth: "700px",
          }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              {/* Todo after api implemation*/}
              {/* {isCurrentPageAllDueInvoiceSelected && (
                <TableRow
                  style={{
                    backgroundColor: Colors.PaleGrey,
                    borderTop: `0.5px solid ${Colors.LightPeriwinkle}`,
                  }}
                >
                  <TableCell colSpan={6}>
                    <Box
                      style={{
                        textAlign: "center",
                        fontFamily: "Museo",
                        fontWeight: 500,
                        fontSize: "14px",
                        color: Colors.Dusk,
                      }}
                    >
                      {isAllDueInvoiceSelected ? (
                        <>
                          All
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;{selectedBillingInvoices.length}
                          </span>{" "}
                          due invoice in Billings are selected.
                          <span
                            style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }}
                            onClick={onAllDueInvoiceRemoved}
                          >
                            &nbsp;Clear selection.
                          </span>
                        </>
                      ) : (
                        <>
                          All
                          <span style={{ fontWeight: 700 }}>
                            &nbsp;{selectedBillingInvoices.length}
                          </span>{" "}
                          due invoices on this page are selected.
                          <span
                            style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }}
                            onClick={onAllDueInvoiceSelected}
                          >
                            &nbsp;Select all of your {totalDueCount} due invoices in Billings.
                          </span>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              )} */}
              <TableRow>
                <TableCell className={classes.header}></TableCell>
                <TableCell className={classes.header}>Booking</TableCell>
                <TableCell className={classes.header}>Due date</TableCell>
                <TableCell className={classes.header}>Amount to pay</TableCell>
                <TableCell className={classes.header}>Payment status</TableCell>
                <TableCell className={classes.header}></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {billingInvoices.map((data) => {
                const paymentStatus = getValue(data, "paymentStatus");
                const bookingPaymentStatusStyle = getBookingPaymentStatusStyle(paymentStatus);
                const bookingPaymentStatusText = getBookingPaymentStatusText(paymentStatus);
                const dueDate = getValue(data, "dueDate");
                const formatDueDate = dueDate
                  ? formatDateWithTimezone(dueDate, data.timezone, "ddd, D MMM YYYY")
                  : "";
                return (
                  <TableRow key={getValue(data, "id")}>
                    <TableCell className={classes.cell} component="th" scope="row">
                      <CustomBillingCheckBox
                        isChecked={selectedBillingInvoices.includes(data.id)}
                        onCheckBoxChange={(checked: boolean) => {
                          onCheckBoxChange({ checked: checked, bookingId: data.id });
                        }}
                        isDisabled={paymentStatus === BillingInvoicePaymentStatus.full}
                      />
                    </TableCell>

                    <TableCell className={classes.cell} component="th" scope="row">
                      #{getValue(data, "id")}
                    </TableCell>
                    <TableCell className={classes.cell} component="th" scope="row">
                      {formatDueDate}
                    </TableCell>
                    <TableCell className={classes.cell} component="th" scope="row">
                      {getValue(data, "currency")}
                      {parseFloat(getValue(data, "remainingAmount", 0)).toFixed(2)}
                    </TableCell>
                    <TableCell className={classes.cell} component="th" scope="row">
                      <Box style={{ ...bookingPaymentStatusStyle }}>{bookingPaymentStatusText}</Box>
                    </TableCell>
                    <TableCell className={classes.cell} component="th" scope="row">
                      <Box
                        fontSize={FontSize.F16}
                        fontWeight={FontWeight.Bold}
                        color={Colors.TurquoiseBlue}
                        onClick={() => onViewBookingClick(data.id)}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        View booking
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      }
    </>
  );
};

export default BillingInvoiceTable;
