import { DEFAULT_BOOKING_DATE_FORMAT } from "../../constants/date";
import { formatMinutesToTimeString } from "../../helpers/time";
import { formatDateWithTimezone, getFormattedDateObject, isSameDate, isSameDay, isSameTime } from "../../utils/date.util";
import { checkIfEmpty } from "../../utils/object";

interface TimeParams {
  earliestTime: string;
  latestTime: string;
  timezone: string;
  prefix?: boolean;
}

const getFormattedRangedBookingTime = (
  { earliestTime, latestTime, timezone, prefix = true }: TimeParams
) => {
  const hasSameTime = isSameTime(earliestTime, latestTime);
  let formattedTime;
  if (hasSameTime) {
    formattedTime = formatDateWithTimezone(earliestTime, timezone, "h:mma");
    if (prefix) formattedTime = `starting at ${formattedTime}`;
  } else {
    formattedTime = formatDateWithTimezone(earliestTime, timezone, "h:mma");
    if (latestTime) {
      formattedTime += ` - ${formatDateWithTimezone(latestTime, timezone, "h:mma")}`;
    }
    if (prefix) formattedTime = `starting anytime between ${formattedTime}`;
  }
  return formattedTime;
};

const getFormattedRangedBookingDate = (
  { earliestTime, latestTime, timezone, prefix = true }: TimeParams
) => {
  let formattedDate;
  const isRanged = !isSameDay(earliestTime, latestTime, timezone);
  if (isRanged) {
    const startDay = formatDateWithTimezone(earliestTime, timezone, DEFAULT_BOOKING_DATE_FORMAT);
    const endDay = formatDateWithTimezone(latestTime, timezone, DEFAULT_BOOKING_DATE_FORMAT);
    formattedDate = `${startDay} - ${endDay}`;
    if (prefix) formattedDate = `Any day from ${formattedDate}`;
  } else {
    formattedDate = formatDateWithTimezone(earliestTime, timezone, DEFAULT_BOOKING_DATE_FORMAT);
  }
  return formattedDate;
};

const getRangedBookingFormattedDateTime = (
  { earliestTime, latestTime, timezone, prefix = true }: TimeParams
) => {
  const formattedTime = getFormattedRangedBookingTime({ earliestTime, latestTime, timezone, prefix });
  const formattedDate = getFormattedRangedBookingDate({ earliestTime, latestTime, timezone, prefix });

  return {
    date: formattedDate,
    time: formattedTime,
    datetime: `${formattedDate}, ${formattedTime}`,
  };
};

const getDateTimeLabelWithMinutes = (
  { fromDate, toDate, earliestMinutes, latestMinutes, timezone }:
  { fromDate: Date, toDate: Date, earliestMinutes?: number | null, latestMinutes?: number | null, timezone: string }
) => {

  let dateLabel = "";
  let timeLabel = "";

  // fomatted date label
  if (!isSameDate(fromDate, toDate)) {
    dateLabel = `
      Any day from ${getFormattedDateObject(fromDate, timezone, DEFAULT_BOOKING_DATE_FORMAT)} - ${getFormattedDateObject(toDate, timezone, DEFAULT_BOOKING_DATE_FORMAT)}`;
  } else {
    dateLabel = `${getFormattedDateObject(fromDate, timezone, DEFAULT_BOOKING_DATE_FORMAT)}`;
  }

  // formatted time label
  if (earliestMinutes) {
    if (latestMinutes && earliestMinutes !== latestMinutes) {
      const earliestTimeString = formatMinutesToTimeString(earliestMinutes);
      const latestTimeString = formatMinutesToTimeString(latestMinutes);
      timeLabel = `starting anytime between ${earliestTimeString} - ${latestTimeString}`;
    } else {
      timeLabel = `starting at ${formatMinutesToTimeString(earliestMinutes)}`;
    }
  }

  const dateTimeExists = !checkIfEmpty(dateLabel) && !checkIfEmpty(timeLabel);
  const dateTimeLabel = dateTimeExists ? `${dateLabel}, ${timeLabel}` : dateLabel;
  return {
    datetime: dateTimeLabel,
    date: dateLabel,
    time: timeLabel,
  };
};

const hasRangedDates = ({ booking, timezone }: { booking: any, timezone: string }) => {
  if (!booking) return false;

  const { earliestTime, latestTime } = booking;
  
  let isRanged = !isSameDay(earliestTime, latestTime, timezone);
  if (isRanged) return true;

  if (booking.backup) {
    const { earliestTime: backupEarliest, latestTime: backupLatest } = booking.backup;
    isRanged = !isSameDay(backupEarliest, backupLatest, timezone);
    return isRanged;
  }
  return false;
};

export {
  getDateTimeLabelWithMinutes,
  getFormattedRangedBookingTime,
  getFormattedRangedBookingDate,
  getRangedBookingFormattedDateTime,
  hasRangedDates,
};
