import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Colors } from "../../../../constants/colors";
import { useAccessToken } from "../../../../hooks/common";
import { useAlertStore } from "../../../../stores/alert";
import serviceAreaAction from "../context/ServiceAreaAction";
import { DEFAULT_RADIUS, useServiceArea } from "../context/ServiceAreaState";
import NotificationRadius from "./NotificationRadius";
import TreatmentSwitch from "../../../../components/ProDashboard/Service/TreatmentSwitch";

export default function RealTimeServiceArea() {
  const [isEnabled, setEnabled] = useState(false);
  const [radius, setRadius] = useState(DEFAULT_RADIUS);
  const accessToken = useAccessToken();

  const { state, dispatch } = useServiceArea();

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { loading, serviceArea = {}, radius: radiusData = [] } = state || {};
  const { realTime = {}, fixed: fixedNotification } = serviceArea || {};

  useEffect(() => {
    setEnabled(realTime?.enabled || false);
  }, [realTime?.enabled]);

  useEffect(() => {
    if (realTime?.radius) setRadius(realTime.radius);
  }, [realTime?.radius]);

  const onRadiusChange = (value: number) => {
    setRadius(value);
    updateRealtimeServiceArea({ radius: value, enabled: isEnabled });
  };

  const onRealTimeNotificationToggle = (checked: boolean) => {
    setEnabled(checked);
    updateRealtimeServiceArea({ enabled: checked, radius });
  };

  const updateRealtimeServiceArea = (realTime: { enabled: boolean; radius: number }) => {
    if (!accessToken) return;
    serviceAreaAction
      .update(
        dispatch,
        {
          realTime,
          fixed: fixedNotification,
        },
        accessToken
      )
      .then(() => {
        setSuccessMessage("Real-time service area notification successfully updated");
      })
      .catch((error) => {
        setErrorMessage("Error while updating real time service area");
        console.debug("Error while updating realtime service area.", error);
      });
  };

  return (
    <Box mb={"48px"}>
      <Box fontSize="18px" mb="24px" fontWeight={600} color={Colors.NightBlue}>
        Real-time service area
      </Box>

      <Box mb={"24px"}>
        <Box mb={"12px"}>
          <TreatmentSwitch
            title={"Receive jobs based on your current location"}
            checked={isEnabled}
            onChange={(checked) => onRealTimeNotificationToggle(checked)}
          />
        </Box>

        <Box fontSize="14px" lineHeight="21px" fontWeight={400} color={Colors.Dusk}>
          Get notified of jobs based on your Blys Pro device's real-time GPS location. This means you'll be notified of
          bookings near your current location.
        </Box>
      </Box>

      <NotificationRadius loading={loading} data={radiusData} selected={radius} onRadiusChange={onRadiusChange} />
    </Box>
  );
}
