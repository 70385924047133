import { Box, ButtonBase } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import FavouritedIcon from "../../images/therapist-profile-fav-button-icon-selected.png";
import FavouriteIcon from "../../images/therapist-profile-fav-button-icon.png";

interface Props {
  onClick: () => unknown;
  favourited: boolean;
  buttonStyle?: React.CSSProperties
}

export default function ({ onClick, favourited, buttonStyle = {} }: Props) {
  return (
    <ButtonBase
      style={{...{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `solid 2px ${
          favourited ? Colors.GoldenYellow : Colors.LightPeriwinkle
        }`,
        borderRadius: "3px",
        width: "120px",
        height: "40px",
        backgroundColor: Colors.White,
      },...buttonStyle}}
      onClick={onClick}
    >
      <Box display="flex" flexDirection="row">
        <Box>
          <img
            src={favourited ? FavouritedIcon : FavouriteIcon}
            style={{ width: "20px", height: "20px" }}
          />
        </Box>
        <Box
          fontSize={14}
          color={Colors.Dusk}
          fontFamily="Museo"
          fontWeight={700}
          alignItems="center"
          justifyContent="center"
          display="flex"
          ml="8px"
        >
          Favourite
        </Box>
      </Box>
    </ButtonBase>
  );
}
