import React from "react";
import { getValue } from "../../../../utils/object";
import { PayoutDetails } from "./PayoutInfo";
import { Box } from "@material-ui/core";

const LinkValueMap: any = {
  "Account Settings": "settings",
  "Refer providers": "/pro/referrals",
};

const compilePayoutDescInfo = ({
  content,
  contentReplacer,
}: {
  content: string;
  contentReplacer: [];
}) => {
  if (!contentReplacer || !Array.isArray(contentReplacer) || contentReplacer.length === 0) {
    return <span>{content}</span>;
  }

  let formattedDesc = content;
  let formatted: any = <Box>{formattedDesc}</Box>;
  (contentReplacer || []).forEach((replacer) => {
    const text = getValue(replacer, "text", "");
    const isLinkType = getValue(replacer, "type");
    const descParts = formattedDesc.split(text);

    formatted = descParts.map((desc: any, index: number) =>
      index < descParts.length - 1 ? (
        <React.Fragment key={index}>
          {desc}
          {isLinkType ? (
            <a
              href={LinkValueMap[text] || ""}
              style={{
                color: "#41506F",
                fontWeight: 600,
              }}
              target="_blank"
              rel="noreferrer"
            >
              {text}
            </a>
          ) : (
            <span>{text}</span>
          )}
        </React.Fragment>
      ) : (
        desc
      )
    );
  });

  return <React.Fragment>{formatted}</React.Fragment>;
};

export const transformPayoutInfoDetails = (details: PayoutDetails[] | null) => {
  const transformedData = (details || []).map((detail: any) => ({
    label: getValue(detail, "label"),
    description: compilePayoutDescInfo({
      content: getValue(detail, "content", ""),
      contentReplacer: getValue(detail, "contentReplacer", []),
    }),
    value: getValue(detail, "valueToDisplay"),
  }));
  return transformedData;
};

export default transformPayoutInfoDetails;
