import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";

import TextField from "../../../TextField";
import Button, { ButtonType } from "../../../Button";
import ContentModal from "../../../Modals/ContentModal/contentModal";
import { useAlertStore } from "../../../../stores/alert";
import InfoText from "../../../Text/InfoText/infoText";

interface DeleteReasonModalProps {
  reason: string,
  visible: boolean,
  onClose: () => unknown,
  onContinue: ({ reason }: { reason: string }) => unknown,
}

const DeleteReasonModal = ({
  reason: initialReason,
  visible,
  onClose,
  onContinue,
}: DeleteReasonModalProps) => {

  const [reason, setReason] = useState("");

  const { setErrorMessage } = useAlertStore();

  useEffect(() => {
    setReason(initialReason);
  }, [initialReason]);

  const onReasonChange = ({ text }: { text: string }) => {
    setReason(text);
  };

  const verifyFields = () => {
    let isValid = true;
    try {
      if (!reason || reason.length <= 0) throw Error("Reason is required");
    } catch (err: any) {
      setErrorMessage(err.message);
      isValid = false;
    }
    return isValid;
  };

  const handleContinue = () => {
    const isValid = verifyFields();
    if (!isValid) return;
    
    onContinue({ reason });
  };

  return <ContentModal 
    title="Delete account"
    description="Can you please share to us what was not working? If something slipped through our fingers, we would be grateful to be aware of it and fix it."
    onClose={onClose}
    visible={visible}
    actions={[
      <Button
        title="Continue"
        onClick={handleContinue}
        type={ButtonType.secondary}
      />
    ]}
  >
    <Box>
      <TextField
        title="Why do you want to delete ?"
        value={reason}
        onChange={(text) => onReasonChange({ text })}
      />
      <InfoText text="You will permanently lose all your reviews, contacts, messages and profile info. After this there is no turning back." />
    </Box>
  </ContentModal>;
};

export default DeleteReasonModal;
