import { Box, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import useSWR from "swr";

import { PRICE_BREAKDOWN } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { formatToPrice } from "../../helpers/number";
import { usePeakHourFixed } from "../../hooks/booking/peakHour.hooks";
import { useAccessToken } from "../../hooks/common";
import { Coupon } from "../../models";
import { useBookingStore, usePaymentStore } from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty } from "../../utils/object";
import CurrencyConversionInfo from "../CurrencyConversionInfo";
import InfoModal from "../Modals/InfoModal/InfoModal";

export enum NewBookingSummaryItemType {
  service,
  peakHourSurcharge,
  coupon,
  credit,
  publicHolidaySurcharge,
  clientPlatformFee,
  parkingAndTravel,
}

interface Props {
  type: NewBookingSummaryItemType;
  handleRootModal?: (boolean: boolean) => unknown;
}

export default function NewBookingSummaryItem({
  type,
  handleRootModal = undefined,
}: Props): JSX.Element {
  const { currencySymbol: currency, currency: bookingCurrencyCode } = useBookingStore();
  const { bookingPrice, couponCode } = usePaymentStore();
  const { currencyCode: userCurrencyCode, fetchCreditBalance } = useUserStore();
  const { currency: userCurrency } = useUserStore();

  useEffect(() => {
    fetchCreditBalance();
  }, []);

  const [infoModal, setInfoModal] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
      data: [],
    },
  });

  const { upToText } = usePeakHourFixed({
    lateNightSurcharge: bookingPrice?.lateNightSurcharge,
    lateNightSurchargeApplied: bookingPrice?.lateNightSurchargeApplied,
  });

  const accessToken = useAccessToken();

  const { data: couponData } = useSWR(
    !isEmpty(couponCode) ? `/coupons/code/${couponCode}?accessToken=${accessToken}` : null
  );
  const coupon = couponData ? (couponData as Coupon) : null;

  const clickableSummaryItems = [
    NewBookingSummaryItemType.clientPlatformFee,
    NewBookingSummaryItemType.peakHourSurcharge,
    NewBookingSummaryItemType.parkingAndTravel,
  ];

  const isClickable = () => {
    return clickableSummaryItems.includes(type);
  };

  const getInfoModalContent = (type: NewBookingSummaryItemType) => {
    let modalContent = {
      title: "",
      description: "",
      data: [],
    };

    switch (type) {
    case NewBookingSummaryItemType.peakHourSurcharge:
      if (!bookingPrice?.peakHourInfo || checkIfEmpty(bookingPrice.peakHourInfo)) break;
      const peakInfo = bookingPrice.peakHourInfo;
      modalContent = {
        title: peakInfo.title,
        description: peakInfo.subtitle,
        data: peakInfo.data,
      };
      break;
    case NewBookingSummaryItemType.clientPlatformFee:
      if (!bookingPrice?.clientPlatformFee || checkIfEmpty(bookingPrice.clientPlatformFee)) break;
      const platformFeeData = bookingPrice.clientPlatformFee;
      modalContent = {
        ...modalContent,
        title: platformFeeData.title,
        description: platformFeeData.remarks,
      };
      break;
    case NewBookingSummaryItemType.parkingAndTravel:
      if (!bookingPrice?.parkingAndTravelInfo || checkIfEmpty(bookingPrice.parkingAndTravelInfo))
        break;
      const parkingInfo = bookingPrice.parkingAndTravelInfo;
      modalContent = {
        title: parkingInfo.title,
        description: parkingInfo.subtitle,
        data: parkingInfo.data,
      };
      break;
    }

    return modalContent;
  };

  const handleInfoIconClicked = (type: NewBookingSummaryItemType) => {
    if (!isClickable()) return;

    const modalContent = getInfoModalContent(type);
    setInfoModal({
      visible: true,
      content: modalContent,
    });

    if (handleRootModal) {
      handleRootModal(true);
    }
  };

  const enabled = () => {
    switch (type) {
    case NewBookingSummaryItemType.service:
      return true;
    case NewBookingSummaryItemType.parkingAndTravel:
      return (bookingPrice?.hotelSurcharge ?? 0) || (bookingPrice?.addressSurcharge ?? 0);

    case NewBookingSummaryItemType.peakHourSurcharge:
      return (
        ((bookingPrice?.lateNightSurcharge ?? 0) || (bookingPrice?.publicHolidaySurcharge ?? 0)) >
          0
      );

    case NewBookingSummaryItemType.coupon:
      return !isEmpty(couponCode) && bookingPrice?.discount;

    case NewBookingSummaryItemType.credit:
      return bookingPrice?.credit ?? 0 > 0;

    case NewBookingSummaryItemType.publicHolidaySurcharge:
      return false;

    case NewBookingSummaryItemType.clientPlatformFee:
      return bookingPrice?.clientPlatformFee?.amount ?? 0 > 0;
    }
  };

  const title = () => {
    switch (type) {
    case NewBookingSummaryItemType.service:
      return PRICE_BREAKDOWN.BASERATE;

    case NewBookingSummaryItemType.parkingAndTravel:
      return PRICE_BREAKDOWN.PARKINGTRAVEL;

    case NewBookingSummaryItemType.peakHourSurcharge:
      return PRICE_BREAKDOWN.PEAK_PERIOD;
    case NewBookingSummaryItemType.coupon:
      return `Coupon (${coupon?.code})`;

    case NewBookingSummaryItemType.credit:
      return PRICE_BREAKDOWN.CREDIT;

    case NewBookingSummaryItemType.publicHolidaySurcharge:
      return PRICE_BREAKDOWN.PUBLIC_HOLIDAY;

    case NewBookingSummaryItemType.clientPlatformFee:
      return `${bookingPrice?.clientPlatformFee?.title}`;
    }
  };

  const value = () => {
    switch (type) {
    case NewBookingSummaryItemType.service:
      return formatToPrice(bookingPrice?.basePrice, currency);

    case NewBookingSummaryItemType.parkingAndTravel:
      const parkingTravelSurcharge =
          (bookingPrice?.addressSurcharge ?? 0) + (bookingPrice?.hotelSurcharge ?? 0);
      return formatToPrice(parkingTravelSurcharge, currency);

    case NewBookingSummaryItemType.peakHourSurcharge:
      return `${upToText}${formatToPrice(
        (bookingPrice?.lateNightSurcharge ?? 0) + (bookingPrice?.publicHolidaySurcharge ?? 0),
        currency
      )}`;

    case NewBookingSummaryItemType.coupon:
      return `-${formatToPrice(bookingPrice?.discount, currency)}`;

    case NewBookingSummaryItemType.credit:
      return `-${formatToPrice(bookingPrice?.credit, currency)}`;

    case NewBookingSummaryItemType.publicHolidaySurcharge:
      return formatToPrice(bookingPrice?.publicHolidaySurcharge, currency);

    case NewBookingSummaryItemType.clientPlatformFee:
      return formatToPrice(bookingPrice?.clientPlatformFee?.amount, currency);
    }
  };

  const handleInfoModalToggle = (open = false) => {
    setInfoModal({
      ...infoModal,
      visible: false,
    });

    if (handleRootModal) {
      handleRootModal(false);
    }
  };

  return enabled() ? (
    <>
      <Box>
        <Box display="flex" flexDirection="row">
          <Box
            fontFamily="Museo"
            fontSize="14px"
            color={Colors.Dusk}
            onClick={() => handleInfoIconClicked(type)}
          >
            <Typography style={isClickable() ? styles.clickable : {}}>{title()}</Typography>
          </Box>
          <Box flex={1}>
            <Box fontFamily="Museo" fontSize="14px" color={Colors.Dusk} textAlign="right">
              {value()}
            </Box>
          </Box>
        </Box>
        {type === NewBookingSummaryItemType.coupon && coupon?.type === "value" ? (
          <CurrencyConversionInfo
            convertedToCurrencyCode={bookingPrice?.originalCouponCurrency || ""}
            convertedFromCurrencyCode={bookingCurrencyCode}
            convertedCurrency={formatToPrice(
              bookingPrice?.discountBeforeConversion,
              bookingPrice?.originalCouponCurrencySymbol
            )}
          />
        ) : (
          <></>
        )}
        {type === NewBookingSummaryItemType.credit ? (
          <CurrencyConversionInfo
            convertedToCurrencyCode={userCurrencyCode}
            convertedFromCurrencyCode={bookingCurrencyCode}
            convertedCurrency={formatToPrice(bookingPrice?.creditBeforeConversion, userCurrency)}
          />
        ) : (
          <></>
        )}
      </Box>
      {isClickable() && (
        <InfoModal
          title={infoModal.content.title}
          description={infoModal.content.description}
          visible={infoModal.visible}
          handleClose={() => handleInfoModalToggle(false)}
          divider={false}
          data={infoModal.content.data}
        />
      )}
    </>
  ) : (
    <></>
  );
}

const styles = {
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};
