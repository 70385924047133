import { Box } from "@material-ui/core";
import React from "react";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import SelectLocationMap from "../../Addresses/SelectLocationMap";
import { SelectedAddress } from "../../AddressAutocomplete";
import Dialog from "../../Dialog";

interface Props {
  setIsMapSelected: (val: boolean) => unknown;
  open: boolean;
  onClose: () => unknown;
  handleAddressSelected: ({ address }: { address: SelectedAddress }) => unknown;
}
export const SetLocationModal = ({
  setIsMapSelected,
  open,
  onClose,
  handleAddressSelected,
}: Props) => {
  const isMobile = useMobile();
  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
        <Box paddingLeft="40px" paddingRight="40px" paddingBottom="40px" paddingTop="32px">
          <Box
            fontFamily="Museo"
            fontSize="22px"
            fontWeight="600"
            color={Colors.Indigo}
            textAlign="center"
            mb={2}
          >
            Set your location
          </Box>
          <SelectLocationMap
            setIsMapSelected={setIsMapSelected}
            onSelectedAddress={(address) => handleAddressSelected({ address })}
          />
        </Box>
      </Box>
    </Dialog>
  );
};
