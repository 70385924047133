import { get } from "../../api/client";
import { useQuery } from "@tanstack/react-query";
import { getValue } from "../../utils/object";
import { transformPagination } from "../../helpers/pagination";

interface Params {
  currentPage: number | string;
  perPage: number | string;
  options?: Record<string, any>;
}
export const PRO_KEYS = {
  RECENT_PROS: "RECENT_PROS",
  RECOMMENDED_PROS: "RECOMMENDED_PROS",
};

const useRecentProviders = ({ currentPage, perPage, options }: Params) => {
  const params = {
    currentPage,
    perPage,
    ...options,
  };
  const {
    isLoading,
    data: response,
    isInitialLoading,
    isFetching,
  } = useQuery(
    [PRO_KEYS.RECENT_PROS, { ...params }],
    () => get("api/v2/therapists/providers/recent", params),
    {
      select: (res) => ({ ...res, data: transformPagination(res.data || {}) }),
    },
  );

  return { data: getValue(response, "data", {}), isLoading, isInitialLoading, isFetching };
};

const useRecommendedProviders = ({ currentPage, perPage, options }: Params) => {
  const params = {
    currentPage,
    perPage,
    excludeRecentProviders: true,
    ...options,
  };

  const {
    isLoading,
    data: response,
    isInitialLoading,
    isFetching,
  } = useQuery(
    [PRO_KEYS.RECOMMENDED_PROS, { ...params }],
    () => get("api/v2/therapists/providers", params),
    {
      select: (res) => ({ ...res, data: transformPagination(res.data || {}) }),
    },
  );

  return { data: getValue(response, "data", {}), isLoading, isInitialLoading, isFetching };
};

const useProForBooking = ({ isRecent }: { isRecent: boolean }) =>
  isRecent ? useRecentProviders : useRecommendedProviders;

export { useRecentProviders, useRecommendedProviders, useProForBooking };
