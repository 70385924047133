import Settings from "./Settings";

export interface UserCommunicationInterface {
  type: string;
  delivery: string;
  frequency: string;
}

export type NotificationOptions = {
  title: string;
  description?: string;
} & UserCommunicationInterface;

export interface NotificationSettings {
  title: string;
  description?: string;
  options: Array<NotificationSettings> | Array<NotificationOptions>;
}

export interface SettingsProps extends NotificationSettings {
  checkIfEnabled: (args: UserCommunicationInterface) => {};
  handleSwitchPressed: (args: UserCommunicationInterface) => void;
  userCountry: string;
}

export default Settings;
