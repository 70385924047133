import { Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { Colors } from "../../../constants/colors";
import { getValue } from "../../../utils/object";
import SectionHeader from "../../../components/Headers/SectionHeader/SectionHeader";
import TextButton from "../../../components/TextButton/TextButton";
import AddProDocumentModal from "../../../components/ProDashboard/Agreement/AddProDocumentModal/AddProDocumentModal";
import { USER_UPLOADED_CONTENT_TYPE } from "../../../data/enums";
import { RootState } from "../../../stores/V2";
import { useAlertStore } from "../../../stores/alert";
import { actions } from "../../../stores/V2/documents/addDocuments";
import {
  useUploadSignedDocument,
  useAgreementsByJob,
  useAgreeLinkDocument,
} from "../../../hooks/userTerms.hook";
import { parseApiError } from "../../../helpers/error";
import { cardWrapperStyles } from "../../../components/BookingDetails/BookingDetailCard";
import DocumentUploadStatusPill from "./documentUploadStatusPill";
import Button, { ButtonType } from "../../../components/Button";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import Divider from "../../../components/Divider";

interface Props {
  job: any;
}

type Agreements = {
  contentType: string;
  documentTypeId: string;
  id: number;
  link: string;
  name: string;
  originalLink: string;
  signatureId: string | number | null;
  type: string;
  filePath: string;
};

const JobDocuments = ({ job }: Props) => {
  const dispatch = useDispatch();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const state = useSelector((state: RootState) => state.documents);

  const { data: jobAgreementResponse } = useAgreementsByJob({ jobId: job.id });
  const jobAgreements = (getValue(jobAgreementResponse, "agreements") || []);

  const [uploadModalVisible, showUploadModal] = useState(false);

  useEffect(() => {
    const pendingAgreements = getPendingAgreements();
    if (!pendingAgreements.length && uploadModalVisible) {
      dimissUploadModal();
    }
  }, [jobAgreements]);

  const resetInput = () => {
    dispatch(actions.reset());
  };

  const onUploadSuccess = (resp: any) => {
    setSuccessMessage("Your terms and policies have been updated");
    resetInput();
    dimissUploadModal();
  };

  const onSignError = (error: any) => {
    const errorMessage = parseApiError(error);
    setErrorMessage(errorMessage || "Something went wrong. Please try again.");
  };

  const { mutate: onAgreeTerms } = useAgreeLinkDocument({
    onSuccess: onUploadSuccess,
    onError: onSignError,
  });

  const { isLoading: isSaving, mutate: uploadSignedAgreement } = useUploadSignedDocument({
    onSuccess: onUploadSuccess,
    onError: onSignError,
  });

  const checkPendingAgreements = () => jobAgreements?.some((item: any) => item.signatureId === null);

  const dimissUploadModal = () => showUploadModal(false);

  const handleDocumentUpload = async () => {
    if (!hasPendingAgreements) {
      showUploadModal(false);
      return;
    }

    const requireDocumentUpload = shouldUploadSignedFile(state.selectedTermPolicies);

    if (requireDocumentUpload) {
      handleUploadedDocument();
    }
    else {
      const selectedDocument = getAgreementById(state.selectedTermPolicies);
      if (!selectedDocument) return;

      const { isTermsAndPolicy } = selectedDocument;
      onAgreeTerms({ agreementId: state.selectedTermPolicies, isTermsAndPolicy });
    }
  };

  const handleUploadedDocument = () => {
    let payload;
    const { bookingId, therapistId } = job;

    const selectedDocument = getAgreementById(state.selectedTermPolicies);
    if (!selectedDocument) return;

    const { isTermsAndPolicy } = selectedDocument;
    if (state.contentType === USER_UPLOADED_CONTENT_TYPE.FILE) {
      payload = new FormData();
      payload.append("image", state.userInput as Blob);
      payload.append("bookingId", job.bookingId);
      payload.append("therapistId", job.therapistId);
    } else {
      // isAdditionalDocument
      payload = { url: state.userInput, bookingId, therapistId,  };
    }

    uploadSignedAgreement({
      isTermsAndPolicy,
      agreementId: state.selectedTermPolicies,
      contentType: state.contentType,
      data: payload,
    });
  };

  const shouldUploadSignedFile = (agreementId: string) => {
    const selected = getAgreementById(agreementId);
    const contentType = getValue(selected, "contentType") || USER_UPLOADED_CONTENT_TYPE.FILE;
    return contentType !== USER_UPLOADED_CONTENT_TYPE.LINK;
  };

  const getAgreementById = (id: number | string) => {
    return jobAgreements.find(({ id: agreementId }: any) => agreementId == id);
  };

  const getUploadModalActionLabel = () => {
    const hasPendingAgreements = checkPendingAgreements();
    if (!hasPendingAgreements) return "Ok";

    return shouldUploadSignedFile(state.selectedTermPolicies) ? "Save changes" : "I Agree";
  };

  const getPendingAgreements = () =>
    (jobAgreements || []).filter(({ signatureId }: any) => !signatureId);

  const hasPendingAgreements = checkPendingAgreements();
  const allowUploadDocument = shouldUploadSignedFile(state.selectedTermPolicies);
  const actionLabel = getUploadModalActionLabel();

  const pendingAgreements = getPendingAgreements();

  const getAgreementLink = (path: string, contentType: string) => {
    if (contentType === USER_UPLOADED_CONTENT_TYPE.LINK) return path;
    return `${BASE_UPLOADS_URL}/${path}`;
  };

  const onDocumentTapped = (url: string) => window.open(url, "_blank");

  if (!jobAgreements || jobAgreements.length ===0) {
    return null;
  }

  return (
    <>
      <Box style={{  flexDirection: "column" }}>
        <Box style={{ ...styles.wrapper, flexDirection: "column" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <SectionHeader title={"Documents"} />
            {hasPendingAgreements && (
              <TextButton
                type={"primary"}
                textStyle={{ padding: 0 }}
                text="Upload signed document"
                onClick={() => showUploadModal(true)}
              />
            )}
          </Box>

          {hasPendingAgreements && (
            <Box style={{ ...styles.detail }}>
              These documents are shared with your client as per their compliance requirements.
            </Box>
          )}

          {jobAgreements.map((item: any) => {
            const status = item.signatureId ? "signed" : "pending";
            const url = getAgreementLink(item.filePath, item.contentType);
            return (
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <TextButton
                  type="primary"
                  onClick={() => onDocumentTapped(url)}
                  text={item.name}
                  textStyle={styles.clickable}
                />
                <DocumentUploadStatusPill status={status} key={item.id} />
              </div>
            );
          })}

          <AddProDocumentModal
            isSaving={isSaving}
            title={"Add document"}
            open={uploadModalVisible}
            onClose={dimissUploadModal}
            onConfirm={handleDocumentUpload}
            uploadTitle="Upload signed document"
            listTitle="Pending documents to be signed"
            documents={pendingAgreements}
            actionName={actionLabel}
            allowUpload={allowUploadDocument}
          />
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    gap: "16px",
  },
  detail: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  clickable: {
    fontWeight: 700,
    padding: 0,
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "Museo",
  },
};

export default JobDocuments;
