import { Box, Typography } from "@material-ui/core";
import axios, { AxiosError } from "axios";
import React from "react";
import Button, { ButtonType } from "../../components/Button";
import TextField from "../../components/TextField";
import TextLink from "../../components/TextLink";
import { Colors } from "../../constants/colors";
import { parseApiError } from "../../helpers/error";
import { useAlertStore } from "../../stores/alert";

interface Props {
  onSentInstructions: (email: string) => unknown;
  onBackToLogin: () => unknown;
  marginTop?: string | number;
  prefillEmail?: string;
}

export default function ForgotPasswordComponent({
  onSentInstructions,
  onBackToLogin,
  marginTop = "48px",
  prefillEmail,
}: Props) {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage } = useAlertStore();

  const onSendInstructions = () => {
    setLoading(true);

    axios
      .get("/users/me/password/reset", {
        params: {
          email,
          source: "web-v2",
        },
      })
      .then((response) => {
        setLoading(false);

        onSentInstructions(email);
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        console.debug("error: ", error);

        setErrorMessage(parseApiError(error));
      });
  };

  React.useEffect(() => {
    prefillEmail && setEmail(prefillEmail);
  }, [prefillEmail]);

  return (
    <Box>
      <Typography variant="h1" style={{ color: Colors.NightBlue, marginTop }}>
        It happens to the best of us
      </Typography>
      <Typography
        variant="h3"
        style={{ color: Colors.Dusk, marginTop: "18px" }}
      >
        Enter your email & we will send you password reset instructions
      </Typography>
      <Box mt="31px">
        <TextField
          title="Email"
          placeholder="youremail@mail.com"
          value={email}
          onChange={(text) => setEmail(text)}
          type="email"
        />
      </Box>

      <Box mt="20px">
        <Button
          loading={loading}
          title="Send instructions"
          type={ButtonType.primary}
          onClick={() => onSendInstructions()}
        />
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            display="flex"
            flexDirection="row"
            fontFamily="Museo"
            fontWeight={500}
            fontSize="16px"
            color={Colors.BlueyGrey}
            mt="29px"
          >
            Got your password?&nbsp;
            <TextLink title="Back to login" onClick={onBackToLogin} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
