import React from "react";
import { Box } from "@material-ui/core";
import Icon from "../../images/bank-icon.svg";
import { Colors } from "../../constants/colors";

interface Props {
  withWrapper?: boolean;
  wrapperStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
}

const BankIcon = ({
  withWrapper = true,
  iconStyle = {},
  wrapperStyle = {},
}: Props): JSX.Element => {
  if (!withWrapper) {
    return <img src={Icon} alt={"Bank icon"} style={iconStyle} />;
  }

  return (
    <Box style={{ ...defaultWrapperStyle, ...wrapperStyle }}>
      <img src={Icon} alt={"Bank icon"} style={iconStyle} />
    </Box>
  );
};

const defaultWrapperStyle: React.CSSProperties = {
  width: "38px",
  height: "24px",
  display: "flex",
  borderRadius: "2px",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: Colors.PaleGrey,
  border: `1px solid ${Colors.LightPeriwinkle}`,
};

export default BankIcon;
