/**
 * REMINDER:
 * This is a temporary modal to confirm alternative time offer.
 * It is copy of of our bit component: '@getblys/blys.ui.confirmalternativetimemodal'
 * It will be in used until we find a solution for webpack compilation error.
 */

import React from "react";
import { Box, Typography, Dialog as MUIDialog } from "@material-ui/core";
import Button from "../../../components/Button";
import { Colors } from "../../../constants/colors";

interface price {
  price: number;
  credit?: number;
  subtotal: number;
  surcharge?: number;
  processingFee?: number;
  coupon?: {
    code?: string;
    amount?: number;
  };
  currency: {
    symbol: string;
  };
  peakHourInfo: {
    title: string;
    description: string;
    data: any;
  };
  processingFeeInfo: {
    title: string;
    description: string;
  };
}

interface ConfirmAlternativeTimeModalProps {
  visible: boolean;
  onBackPress: () => void;
  onConfirm: () => void;
  onInfoPressed: (obj: {
    title: string;
    description: string;
    data?: any;
  }) => void;
  prices: price;
  isMobile?: boolean;
  isLoading?: boolean;
}

const formatPrice = (price: any, limit = 2, currency = "$") => {
  const floatNum = parseFloat(price);

  if (Number.isNaN(floatNum)) return "0.00";

  const limitedResult = floatNum.toFixed(limit);
  return limitedResult;
};

export function ConfirmAlternativeTimeModal({
  prices,
  visible,
  onConfirm,
  onBackPress,
  onInfoPressed,
  isMobile = true,
  isLoading = false,
}: ConfirmAlternativeTimeModalProps) {
  const currencySumbol = prices?.currency?.symbol || "";
  return (
    <MUIDialog open={visible} onClose={onBackPress} fullWidth={false}>
      <Box padding={"32px 16px 24px 16px"} bgcolor="#ffffff" borderRadius="8px">
        <Box width={"448px"} display="flex" flexDirection="column">
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="22px"
            color={Colors.NightBlue}
            mb="24px"
            textAlign="center"
          >
            Pricing detail
          </Box>

          <Box padding="0px 16px" mb="24px" color={Colors.Dusk}>
            <Box fontSize="16px" mb="12px" fontWeight="700" fontFamily="Museo">
              Receipt
            </Box>
            <Box
              mb="12px"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              fontFamily={"Museo"}
              fontSize="14px"
              fontWeight={"500"}
            >
              <Box>Subtotal</Box>
              <Box>
                {currencySumbol}
                {formatPrice(prices?.subtotal)}
              </Box>
            </Box>

            {prices.surcharge ? (
              <Box
                mb="12px"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                fontFamily={"Museo"}
                fontSize="14px"
                fontWeight={"500"}
              >
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => onInfoPressed(prices.peakHourInfo)}
                >
                  <Typography
                    style={{ fontSize: 14, textDecorationLine: "underline" }}
                  >
                    Surcharge
                  </Typography>
                </Box>
                <Box>
                  {currencySumbol}
                  {formatPrice(prices?.surcharge)}
                </Box>
              </Box>
            ) : null}

            {prices.coupon?.amount ? (
              <Box
                mb="12px"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                fontFamily={"Museo"}
                fontSize="14px"
                fontWeight={"500"}
              >
                <Box display="flex" flexDirection="row">
                  <Box>Coupon</Box>
                  <Box pl="8px" color={Colors.TurquoiseBlue}>({prices?.coupon?.code})</Box>
                </Box>
                <Box>
                  {currencySumbol}
                  {formatPrice(prices?.coupon?.amount)}
                </Box>
              </Box>
            ) : null}

            {prices.credit ? (
              <Box
                mb="12px"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                fontFamily={"Museo"}
                fontSize="14px"
                fontWeight={"500"}
              >
                <Box>Credit</Box>
                <Box>
                  {currencySumbol}
                  {formatPrice(prices?.credit)}
                </Box>
              </Box>
            ) : null}
            
            {prices.processingFee ? (
              <Box
                mb="12px"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                fontFamily={"Museo"}
                fontSize="14px"
                fontWeight={"500"}
              >
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => onInfoPressed(prices.processingFeeInfo)}
                >
                  <Typography
                    style={{ fontSize: 14, textDecorationLine: "underline" }}
                  >
                    Processing fee
                  </Typography>
                </Box>
                <Box>
                  {currencySumbol}
                  {formatPrice(prices?.processingFee)}
                </Box>
              </Box>
            ) : null}
          </Box>
          <Box
            padding="16px"
            borderRadius="6px"
            mb="24px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            bgcolor={Colors.PaleGrey}
            color={Colors.Dusk}
            fontWeight={"700"}
            fontFamily={"Museo"}
            fontSize="18px"
          >
            <Box>Total Due</Box>
            <Box>
              {currencySumbol}
              {formatPrice(prices?.price)}
            </Box>
          </Box>
          <Button
            title="Confirm"
            onClick={onConfirm}
            style={{ backgroundColor: Colors.TurquoiseBlue }}
            loading={isLoading}
          />
        </Box>
      </Box>
    </MUIDialog>
  );
}
