import React from "react";
import { Job } from "../../../models";
import { getValue } from "../../../utils/object";
import { getPaymentMethodDetails } from "../../../helpers/payment";

const ClientPaymentMethod = ({ job }: { job: Job }): JSX.Element => {
  const clientPaymentMethod = getValue(job, "clientPaymentMethod", {});
  const paymentType = getValue(clientPaymentMethod, "type");

  const paymentMethodIcon = getPaymentMethodDetails({
    paymentType,
    paymentMethod: clientPaymentMethod,
  });

  return (
    <img
      src={paymentMethodIcon.icon}
      width={paymentMethodIcon.width}
      height={paymentMethodIcon.height}
      alt={paymentType}
    />
  );
};

export default ClientPaymentMethod;
