import { Box, Link } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";

interface Props {
  textStyle?: any;
  headerStyle?: any;
  actionStyle?: any;
  wrapperStyle?: any;
  bookingCancelled?: boolean;
}

function CancellationPolicyText({
  textStyle = {},
  headerStyle = {},
  actionStyle = {},
  wrapperStyle = {},
  bookingCancelled = false,
}: Props) {
  const textMsg = bookingCancelled ? "Fees may apply, please read our" : "Please read our";
  return (
    <Box mt={2} style={wrapperStyle}>
      {bookingCancelled && (
        <Box fontFamily="Museo" fontWeight={600} fontSize="14px" color={Colors.Dusk}>
          Cancellation policy
        </Box>
      )}
      <Box mt={1} fontSize="12px" fontFamily="Open Sans" color={Colors.BlueyGrey} style={textStyle}>
        {textMsg}&nbsp;
        <Link
          href="https://help.getblys.com.au/support/solutions/articles/33000226423-client-cancellation-policy"
          target="_blank"
          underline="none"
          style={{ textDecoration: "underline", color: Colors.BlueyGrey, ...actionStyle }}
        >
          Client Cancellation Policy.
        </Link>
      </Box>
    </Box>
  );
}

export default CancellationPolicyText;
