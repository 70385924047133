import { useQuery } from "@tanstack/react-query";
import queryClient from "../../config/queryClient";
import { InvoiceLink } from "../../models";
import { getInvoiceTypes } from "../../services/bookings/invoice.service";

const KEYS = {
  INVOICE_TYPES: "INVOICE_TYPES",
};

export const useInvoiceLinks = (bookingId?: number) => {
  const { isLoading, data } = useQuery(
    [KEYS.INVOICE_TYPES, bookingId],
    (): Promise<Array<InvoiceLink>> => {
      if (!bookingId) return Promise.resolve([]);

      return getInvoiceTypes(bookingId);
    }
  );

  const clearInvoiceLinks = () => {
    queryClient.invalidateQueries([KEYS.INVOICE_TYPES]);
  };

  return {
    isLoading,
    invoiceLinks: data || [],
    clearInvoiceLinks,
  };
};
