import { TrackingEvents } from "../../constants/tracking";
import { withVersionInNumber } from "../../helpers/tracking";

const trackEvent = (eventName: string, data: any) => {
  return window.analytics.track(eventName, data);
};

const trackNewVoucherStepViewed = () =>
  trackEvent(
    TrackingEvents.NewVoucherStepViewed,
    withVersionInNumber({
      step: 1,
    })
  );

const trackNewVoucherStepCompleted = (
  voucherSchedule: string | Date | null,
  voucherRecipient: string,
  name: string | undefined
) =>
  trackEvent(
    TrackingEvents.NewVoucherStepCompleted,
    withVersionInNumber({
      step: 1,
      voucher_schedule: voucherSchedule,
      voucher_recipient: voucherRecipient,
      name: name,
    })
  );

const trackVoucherPaymentViewed = () =>
  trackEvent(
    TrackingEvents.VoucherPaymentViewed,
    withVersionInNumber({
      step: 2,
    })
  );

const trackVoucherPaymentCompleted = (
  subtotal: string | undefined,
  total: string | undefined,
  name: string | undefined,
  couponId: string,
  price: string,
  quantity: number | undefined
) => trackEvent(
  TrackingEvents.VoucherPaymentCompleted,
  withVersionInNumber({
    step: 2,
    subtotal,
    total,
    currency: "AUD",
    affiliation: "web",
    name,
    couponId,
    price,
    quantity,
  })
);

const trackVoucherNewUserStepViewed = () =>
  trackEvent(
    TrackingEvents.VoucherNewUserStepViewed,
    withVersionInNumber({
      step: 2,
    })
  );

const trackVoucherNewUserStepCompleted = () =>
  trackEvent(
    TrackingEvents.VoucherNewUserStepCompleted,
    withVersionInNumber({
      step: 2,
    })
  );

const trackUsersSignedUp = (userId: number, accountType: string = "user") => {
  trackEvent(
      TrackingEvents.SignedUp,
      withVersionInNumber({
          event: "Signed up",
          userId: userId,
          properties: {
              accountType,
              signUpDateTime: Date.now(),
          },
      })
  );
};

const trackNewBookingStep = (
  serviceId: number | null,
  sessionType: string | null,
  treatmentId: string | undefined,
  products: any,
) => {
  trackEvent("New Booking Step Completed", {
    step: 2,
    session_type:
      serviceId ? sessionType : undefined,
    product_id: treatmentId,
    version: 2,
    products,
  });
};

export {
  trackEvent,
  trackUsersSignedUp,
  trackNewBookingStep,
  trackNewVoucherStepViewed,
  trackNewVoucherStepCompleted,
  trackVoucherPaymentViewed,
  trackVoucherPaymentCompleted,
  trackVoucherNewUserStepViewed,
  trackVoucherNewUserStepCompleted,
};
