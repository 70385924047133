import { Box } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../constants/colors";
import TimerImg from "../../../images/timer.png";
import { BookingSlot } from "../../../stores/V2/corporate/interface";
import Button, { ButtonSize, ButtonType } from "../../Button";
import Dialog from "../../Dialog";
import Dropdown, { DropdownOption } from "../../Dropdown";
import { breakSlotModalStyles } from "./breakSlot.styles";
import { checkIfEmpty } from "../../../utils/object";

interface Props {
  open: boolean;
  onClose: () => unknown;
  isMobile: boolean;
  handleBreakTimeChange: (option: DropdownOption, slot: BookingSlot) => unknown;
  onConfirm: () => unknown;
  isLoading: boolean;
  breakEndTime: string;
  breakStartTime: string;
  timezone: string;
  slot: BookingSlot | null;
  timeOptions: DropdownOption[]
}

const BreakTimeUpdateModal = (props: Props) => {
  const {
    open,
    onClose,
    isMobile,
    breakEndTime,
    breakStartTime,
    handleBreakTimeChange,
    onConfirm,
    isLoading,
    slot,
    timeOptions,
  } = props;

  if (checkIfEmpty(slot) || !slot) return <></>;

  const onTimeSelected = (option: DropdownOption) => {
    handleBreakTimeChange(option, slot);
  };

  const breakTimeOptions = timeOptions;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile} style={{ overflowY: "visible" }}>
      <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
        <Box
          width={isMobile ? "100%" : "380px"}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box fontFamily="Museo" fontWeight={700} fontSize="22px" color={Colors.NightBlue} mb={8}>
            Edit break
          </Box>

          <Box display="flex" position="relative">
            <Box style={{ ...breakSlotModalStyles.startTime, width: isMobile ? "150px" : "192px" }}>
              <Dropdown
                title="Start time"
                onSelectedOption={onTimeSelected}
                options={breakTimeOptions}
                lineHidden={true}
                styles={{
                  width: "65%",
                  titleFontSize: "16px",
                  optionFontSize: "18px",
                  optionFontWeight: "700",
                  titleTextAlign: "center",
                  selectedOptionAlignment: "center",
                }}
                selectedOption={breakTimeOptions.find((time) => time.value === breakStartTime)}
                paddingTop="34px"
              />
            </Box>
            <Box
              style={{ ...breakSlotModalStyles.endTime, width: isMobile ? "150px" : "192px" }}
              flexDirection="column"
            >
              <Box color={Colors.BlueyGrey} fontWeight={700}>
                End time
              </Box>
              <Box mt={1.6} color={Colors.BlueyGrey} fontWeight={700} ml={1.5}>
                {breakEndTime}
              </Box>
            </Box>

            <Box style={{ ...breakSlotModalStyles.timerClockWrapper }} position="absolute">
              <img src={TimerImg} alt="Clock Timer" width="41px" height="38px" />
            </Box>
          </Box>

          <Box width="100%" display="flex" marginTop="31px" flexDirection="row">
            <Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title="Confirm"
              loading={isLoading}
              onClick={onConfirm}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default BreakTimeUpdateModal;
