import { useState, useEffect } from "react";
import { Box, CircularProgress, GridProps } from "@material-ui/core";
import Each from "../Each";
import { FavoriteTherapist, RecentlyBookedPro } from "../../models";
import RecentTherapistsItem from "./RecentTherapistItem";
import { getProfileLink } from "../../services/therapist/therapist.service";
import PaginationControl from "../PaginationControl";
import { useMobile } from "../../hooks/mobile";
import { getValue } from "../../utils/object";
import { Colors } from "../../constants/colors";
import { invalidateQueries } from "../../services/query";
import TherapistsPlaceholder, { TherapistsPlaceholderType } from "../TherapistsPlaceholder";
import { scrollToTop } from "../../helpers/scrollToTop";
import { PRO_KEYS, useRecentProviders } from "../../hooks/booking/preferredProviders.hooks";
import { isAUDomain } from "../../utils/url";
import { getAccessToken } from "../../helpers/accessToken";
import { JustifyContent } from "../v2/Styled/enum";

interface RecentTherapistProps {
  gridProps: GridProps;
  onMoreClicked: (
    event: React.MouseEvent<HTMLButtonElement | MouseEvent>,
    therapist: RecentlyBookedPro
  ) => unknown;
  selectedSectionIndex: number;
  favouriteTherapistList: FavoriteTherapist[];
}

const RecentTherapist = ({
  gridProps,
  favouriteTherapistList,
  onMoreClicked,
}: RecentTherapistProps) => {
  const isMobile = useMobile();
  const [currentPage, setCurrentPage] = useState(1);

  const { isLoading, data } = useRecentProviders({
    currentPage,
    perPage: 12,
    options: {
      allProviders: true,
    },
  });
  const providers = getValue(data, "therapistList", []);

  const onProfileClicked = (therapist: RecentlyBookedPro) => {
    let publicProfileURL = getProfileLink({
      id: getValue(therapist, "id"),
      firstName: getValue(therapist, "firstName", ""),
    });
    const accessToken = getAccessToken();
    publicProfileURL += `?token=${accessToken}`;

    if (isAUDomain()) publicProfileURL += "&country=AU";
    window.open(publicProfileURL, "_blank");
  };

  const invalidatRecentlyBookedProQuery = () => {
    invalidateQueries([PRO_KEYS.RECENT_PROS]);
  };

  useEffect(() => {
    invalidatRecentlyBookedProQuery();
  }, []);

  const onPageChange = (inc = 1) => {
    setCurrentPage((prev: number) => prev + inc);
    scrollToTop();
  };

  if (isLoading) {
    return (
      <Box display={"flex"} flex={1} justifyContent={"center"} mt={"24px"}>
        <CircularProgress variant={"indeterminate"} style={{ color: Colors.Indigo }} />
      </Box>
    );
  }

  if (providers.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={isMobile ? "400px" : "75%"}
      >
        <TherapistsPlaceholder type={TherapistsPlaceholderType.recent} />
      </Box>
    );
  }

  return (
    <Box
      style={gridProps.style}
      gridGap={"24px"}
      display={"flex"}
      flexDirection={"column"}
      maxWidth={"1500px"}
      justifyContent={"center"}
    >
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        style={{ justifyContent: isMobile ? "center" : undefined }}
      >
        <Each
          of={providers}
          render={(pro) => (
            <Box p={"12px"}>
              <RecentTherapistsItem
                therapist={pro}
                onMoreClicked={(event) => {
                  onMoreClicked(event, pro);
                }}
                onClick={() => onProfileClicked(pro)}
                isFavourite={favouriteTherapistList.some(
                  (data: FavoriteTherapist) => data.therapistId === pro.id
                )}
              />
            </Box>
          )}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: isMobile ? "center" : undefined,
        }}
      >
        <Box style={{ width: "275px" }}>
          <PaginationControl
            currentPage={data.currentPage}
            pages={data.totalPages}
            onPrevious={() => onPageChange(-1)}
            onNext={onPageChange}
            startFromOne={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RecentTherapist;
