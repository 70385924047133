import { useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Dialog from "../Dialog";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../Button";
import Divider from "../Divider";
import "./TwoFA.css";
import { MfaData } from "./Security";
import { getValue } from "../../utils/object";

interface Props {
  open: boolean;
  onClose: () => unknown;
  isMobile: boolean;
  onSubmit: (code: string) => unknown;
  mfaData: MfaData | undefined;
  isLoading: boolean;
}

const TwoFAModal = ({ open, onClose, isMobile, onSubmit, mfaData, isLoading }: Props) => {
  const [securityCode, setSecurityCode] = useState("");
  return (
    <div>
      <Dialog open={open} onClose={onClose} fullScreen={isMobile} style={{ overflowX: "visible" }}>
        <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
          <Box
            width={isMobile ? "100%" : "328px"}
            display="flex"
            flexDirection="column"
            alignItems="start"
          >
            <Box
              fontFamily="Museo"
              fontWeight={600}
              fontSize="22px"
              color={Colors.Indigo}
              textAlign="center"
              width="100%"
            >
              Enable Authenticator app
            </Box>

            <Box mt={4}>
              <Box
                fontFamily="Museo"
                fontWeight={600}
                fontSize="16px"
                color={Colors.Dusk}
                textAlign="center"
                width="100%"
              >
                Step 1: Download Authenticator app
              </Box>

              <Box
                fontFamily="Museo"
                fontWeight={400}
                fontSize="15px"
                color={Colors.BlueyGrey}
                textAlign="center"
                width="100%"
                mt={1}
              >
                Download and install authenticator app on your phone, laptop, or tablet.
              </Box>
            </Box>

            <Box mt={4}>
              <Box
                fontFamily="Museo"
                fontWeight={600}
                fontSize="16px"
                color={Colors.Dusk}
                textAlign="center"
                width="100%"
              >
                Step 2: Scan the QR Code
              </Box>

              <Box display="flex" alignItems="center" justifyContent="center" width="100%" my={2}>
                <img src={getValue(mfaData, "qr")} alt="QR" width={100} />
              </Box>

              <Box
                fontFamily="Museo"
                fontWeight={400}
                fontSize="15px"
                color={Colors.BlueyGrey}
                textAlign="center"
                width="100%"
                mt={1}
              >
                Open the authenticator app, tap the + icon and scan the QR image on the top using
                your phone or tablet.
              </Box>

              <Box
                fontFamily="Museo"
                fontWeight={400}
                fontSize="14px"
                color={Colors.BlueyGrey}
                textAlign="center"
                width="100%"
                my={2}
              >
                Or
              </Box>

              <Box fontFamily="Museo" color={Colors.BlueyGrey} textAlign="center" width="100%">
                <Box fontWeight={600}>Manual Entry Key</Box>
                <Box fontWeight={400} mt={1} fontSize="14px">
                  {getValue(mfaData, "secret")}
                </Box>
              </Box>
            </Box>

            <Box mt={4}>
              <Box
                fontFamily="Museo"
                fontWeight={600}
                fontSize="16px"
                color={Colors.Dusk}
                textAlign="center"
                width="100%"
              >
                Step 3: Sign in with your security code
              </Box>

              <Divider mt={3} />

              <Box width="100%" mt={1}>
                <input
                  className="securityCode"
                  type="text"
                  placeholder="
                  Enter security code here to continue (required)
                  "
                  onChange={(e) => setSecurityCode(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        {isLoading ? (
          <Box mb={4} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box display="flex" flexDirection="row" paddingX={2} mb={2}>
            <Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title="Confirm"
              onClick={() => {
                onSubmit(securityCode);
              }}
              disabled={!securityCode || isLoading}
            />
          </Box>
        )}
      </Dialog>
    </div>
  );
};

export default TwoFAModal;
