import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";

interface ReferralItemProps {
  amount: string;
  referred: string;
  joinedAt: string;
  bookingCount: number;
  bookingCompleted: number;
  isMobile: boolean;
};


interface MobileTitleValueProps {
  title: string;
  value: string;
}

function MobileTitleValue({ title, value }: MobileTitleValueProps) {
  return (
    <Box display="flex" flex={1} flexDirection="column" mb="16px">
      <Box
        fontFamily="SF UI Text"
        fontSize="14px"
        fontWeight={500}
        color={Colors.BlueyGrey}
        flex={1}
      >
        {title}
      </Box>
      <Box
        fontFamily="Museo"
        fontSize="16px"
        lineHeight="24px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="8px"
      >
        {value}
      </Box>
    </Box>
  );
}

const ReferralItem = ({
  amount,
  referred,
  joinedAt,
  bookingCount,
  bookingCompleted,
  isMobile = false,
}: ReferralItemProps) => {

  if (isMobile) {
    return (
      <Box pt="24px" pl="16px" pr="16px" pb="24px" display="flex" flexDirection="column">
        <MobileTitleValue title="Provider" value={referred} />
        <MobileTitleValue title="Joined date" value={joinedAt} />
        <MobileTitleValue title="Bookings" value={`${bookingCompleted}/${bookingCount} completed`} />
        <MobileTitleValue title="Referral bonus" value={`${amount}`} />
      </Box>
    );
  }

  return <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    fontFamily="Museo"
    fontSize="16px"
    lineHeight="24px"
    fontWeight={400}
    color={Colors.Dusk}
    height="55px"
    pl="24px"
    pr="24px"
  >
    <Box width="40%">{referred}</Box>
    <Box width="25%">
      {joinedAt}
    </Box>
    <Box width="20%">{`${bookingCompleted}/${bookingCount} completed`}</Box>
    <Box width="15%">{amount}</Box>
  </Box>;
};

export default ReferralItem;