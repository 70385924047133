import Lottie, { LottieRef } from "lottie-react";
import { AnimationEventHandler, CSSProperties, useRef } from "react";

type Props = {
  styles?: CSSProperties;
  speed?: number;
  onAnimationComplete?: AnimationEventHandler;
  autoPlay: boolean;
  loop: boolean;
};

export default function SuccessAnimation(props: Props) {
  const lottieRef: LottieRef = useRef(null);
  const animationData = require("./animationJson/success.json");

  return (
    <Lottie
      animationData={animationData}
      autoPlay={props.autoPlay}
      loop={props.loop}
      style={props.styles ?? {}}
      lottieRef={lottieRef}
      onEnterFrame={() => lottieRef.current?.setSpeed(props.speed ?? 1)}
      onComplete={props.onAnimationComplete}
    />
  );
}
