import { isNil, find } from "lodash";
import mobilePlaceHolders from "../constants/mobile";
import {
  AsYouType,
  getCountryCallingCode as getCallingCode,
  CountryCode as CountryCodeType,
  format,
  parse,
} from "libphonenumber-js";
import countryCodes from "../data/countryCodes.json";

export function getCountryCode(input: string) {
  if (isNil(input)) {
    return undefined;
  }

  let formatted = new AsYouType().input(input);
  let withoutCountryCode = formatted;

  const parts = formatted.split(" ");
  const countryCode = parts.shift();
  withoutCountryCode = parts.join(" ");

  return {
    formatted,
    withoutCountryCode,
    countryCode,
  };
}

export const getCountryCallingCode = (countryCode: CountryCodeType) => {
  try {
    return getCallingCode(countryCode);
  } catch (e) {
    return "61";
  }
};

export const getCountryPhonePlaceholder = (countryCode: CountryCodeType) => {
  const args = {
    placeholder: mobilePlaceHolders[countryCode] || "0000000000",
    countryCode: mobilePlaceHolders[countryCode] ? countryCode : "AU",
  };

  const parsedNumber = parse(args.placeholder, args.countryCode);
  return format(parsedNumber, "NATIONAL");
};

export function getCountryName(code: string) {
  let country = find(countryCodes, { dial_code: code });
  return country?.code || "AU";
}

export function getDialCodeFromCountryCode(countryCodeName: string){
  const country = find(countryCodes, { code: countryCodeName });
  return country?.dial_code || "+61";
}

export type CountryCode = CountryCodeType;
