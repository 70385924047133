import React from "react";
import { Box } from "@material-ui/core";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { QUESTION_TYPE } from "../../../constants/questionType";
import { Colors } from "../../../constants/colors";
import { ServiceRate, ServiceRatePrice } from "../../../models";
import { formatToPrice } from "../../../helpers/number";
import { capitalizeFirstLetter } from "../../../helpers/string";

interface Props {
  index: number;
  answers: any[];
  currency: string;
  treatmentDetail: any;
  genderPreference?: string;
  treatmentDuration: any;
  selectedService: ServiceRate;
  showPersonIndex: boolean;
  additionalTreatmentPrice: number;
  onTreatmentClicked: (id: any) => unknown;
  onAddOnClicked: ({ td, addOnId }: { td: any; addOnId: any }) => unknown;
  addOn?: Array<ServiceRatePrice>;
}

const TreatmentDetailItem = ({
  index,
  answers,
  currency,
  genderPreference,
  selectedService,
  treatmentDetail,
  treatmentDuration,
  additionalTreatmentPrice,
  showPersonIndex = true,
  onTreatmentClicked,
  onAddOnClicked,
  addOn,
}: Props) => {
  const isMassage = selectedService?.isMassage;

  const getTreatmentLabel = (index: number) => {
    if (!selectedService || checkIfEmpty(selectedService)) return "";

    if (!treatmentDetail) return "";

    const { treatmentId, treatmentTypeId } = treatmentDetail;
    const selectedTreatmentId = treatmentId || treatmentTypeId;

    const treatment = selectedService.prices.find(({ id }) => id === selectedTreatmentId);
    const treatmentName = treatment?.label || getValue(treatmentDetail, "treatment.label") || "";

    let treatmentPrice = getValue(treatmentDetail, "price") || 0;
    if (!treatmentPrice) {
      treatmentPrice = treatment?.rate || 0;
      if (selectedService.isMassage && treatment) {
        const selectedDuration = treatmentDuration;
        treatmentPrice =
          treatment?.rates?.find(({ duration }: any) => duration === selectedDuration)?.rate || 0;
      }
    }

    const totalTreatmentPrice = treatmentPrice + additionalTreatmentPrice;
    return `${treatmentName} (${currency}${totalTreatmentPrice.toFixed(2)})`;
  };

  const getFormattedTreatmentDuration = (index: number) => {
    return `${treatmentDuration} ${treatmentDuration > 9 ? "mins" : "min"}`;
  };

  const getAddOnAnswers = () => {
    const formattedAnswers = !checkIfEmpty(answers) ? answers[index] : [];
    const addOnAnswers =
      formattedAnswers?.filter(
        ({ addOn, addedDuration, addedPrice }: any) => addOn && (addedDuration || addedPrice)
      ) || [];
    return addOnAnswers;
  };

  const getAdditionalAnswers = () => {
    const formattedAnswers = !checkIfEmpty(answers) ? answers[index] : [];
    const nonAddonAnswers =
      formattedAnswers?.filter(
        ({ addOn, treatmentId, type }: any) => !addOn && treatmentId && type !== QUESTION_TYPE.IMAGE
      ) || [];
    return nonAddonAnswers;
  };

  if (!treatmentDetail || checkIfEmpty(treatmentDetail)) return <Box />;

  const { treatmentId, treatmentTypeId } = treatmentDetail;
  const id = treatmentId || treatmentTypeId;
  const treatmentLabel = getTreatmentLabel(index);
  const formattedTreatmentDuration = getFormattedTreatmentDuration(index);

  let genderPref;
  if (genderPreference) genderPref = genderPreference;

  const addOnAnswers = getAddOnAnswers();
  const nonAddonAnswers = getAdditionalAnswers();

  const treatmentCanChange = !!onTreatmentClicked;

  const profession = getValue(selectedService, "profession") || "Provider";
  return (
    <Box key={`treatment-${id}`} display="flex" flexDirection="column" flex={1}>
      {showPersonIndex && (
        <Box fontWeight={600} fontFamily="Museo" fontSize="16px" color={Colors.Indigo}>
          Person {index + 1}
        </Box>
      )}

      <Box display="flex" flexDirection={"column"} gridGap={"8px"}>
        <Box>
          <Box
            gridGap={"8px"}
            display={"flex"}
            flexDirection={"row"}
            style={{ overflowWrap: "break-word" }}
          >
            <Box
              fontSize="16px"
              fontFamily="Museo"
              fontWeight={500}
              color={"#41506F"}
              lineHeight={"21px"}
            >
              <Box
                onClick={() => onTreatmentClicked(treatmentDetail)}
                style={treatmentCanChange ? { textDecoration: "underline", cursor: "pointer" } : {}}
              >
                {treatmentLabel}
              </Box>
              <Box style={{ ...styles.infoText }}>{formattedTreatmentDuration}</Box>
            </Box>
          </Box>
          {addOn?.map((addon: any) => {
            if (checkIfEmpty(addon)) return <></>;

            const { name, label, rate, duration, id, price } = addon;
            return (
              <Box mt={0.5} mb={0.5} key={id}>
                <Box
                  fontSize="16px"
                  fontFamily="Museo"
                  fontWeight={500}
                  color={"#41506F"}
                  lineHeight={"21px"}
                  style={
                    treatmentCanChange ? { textDecoration: "underline", cursor: "pointer" } : {}
                  }
                  onClick={() => onAddOnClicked({ td: treatmentDetail, addOnId: id })}
                >
                  Add-on: {`${label ?? name} (${formatToPrice(price || rate, currency)})`}
                </Box>
                {duration ? <Box style={styles.infoText}>{`${duration} min`}</Box> : <></>}
              </Box>
            );
          })}{" "}
          {addOnAnswers.map(({ answer, addedPrice, addedDuration }: any) => {
            return (
              <Box>
                <Box
                  fontSize="16px"
                  fontFamily="Museo"
                  fontWeight={500}
                  color={"#41506F"}
                  lineHeight={"21px"}
                  style={
                    treatmentCanChange ? { textDecoration: "underline", cursor: "pointer" } : {}
                  }
                  onClick={() => onAddOnClicked(treatmentDetail)}
                >
                  + {`${answer} (${formatToPrice(addedPrice, currency)})`}
                </Box>
                {addedDuration && addedDuration > 0 ? (
                  <Box style={styles.infoText}>{`${addedDuration} min`}</Box>
                ) : (
                  <React.Fragment />
                )}
              </Box>
            );
          })}
        </Box>

        {/* additional treatment info */}
        <Box style={styles.infoText}>
          {genderPref && (
            <Box>
              {capitalizeFirstLetter(profession)} gender: {genderPref}
            </Box>
          )}
          {nonAddonAnswers.map(({ question, answer }: any) => (
            <Box>
              {question}: {answer}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  infoText: {
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: Colors.BlueyGrey,
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export default TreatmentDetailItem;
