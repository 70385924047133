import React from "react";
import { getMassageService, useBookingStore } from "../stores/booking";
import InfoModal, { InfoModalItem } from "./InfoModal";

export enum MassageTypeInfoModalType {
  massageType1,
  massageType2,
}

interface Props {
  open: boolean;
  onClose: () => unknown;
  type: MassageTypeInfoModalType;
}

export default function MassageTypeInfoModal({ open, onClose, type }: Props) {
  const { setMassageType1, setMassageType2, massageType1, massageType2 } =
    useBookingStore();

  const { prices: massageTypes = [] } = getMassageService();

  const isSelectedForMassageType = (massageType: string) => {
    if (type === MassageTypeInfoModalType.massageType1) {
      return massageType1 === massageType;
    } else if (type === MassageTypeInfoModalType.massageType2) {
      return massageType2 === massageType;
    }
  };

  const items = massageTypes.map((type) => ({
    icon: type.icon,
    title: type.label,
    description: type.shortDescription,
    selected: isSelectedForMassageType(type.name),
    value: type.name,
    backgroundColor: type.backgroundColor,
  })) as InfoModalItem[];

  return (
    <InfoModal
      title="Massage type"
      open={open}
      onClose={onClose}
      items={items}
      onSelectedItem={(item) => {
        if (type === MassageTypeInfoModalType.massageType1) {
          setMassageType1(item.value);
        } else if (type === MassageTypeInfoModalType.massageType2) {
          setMassageType2(item.value);
        }

        onClose();
      }}
    />
  );
}
