import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { BookingFrequencyUpdateType } from "../../../constants/booking";
import { useMobile } from "../../../hooks/mobile";
import Button, { ButtonType, ButtonSize } from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import { useState } from "react";
import ActionOptions from "../../../components/RecurringBookings/ActionOption";

interface ConfirmCheckoutInterface {
  open: boolean;
  allowCancel?: boolean;
  title?: string;
  updateOneTitle?: string;
  updateAllTitle?: string;
  onClose: () => unknown;
  handleUpdate: (futureRecurring?: boolean) => unknown;
}

const ConfirmBookingUpdateModal = ({
  open,
  onClose,
  handleUpdate,
  allowCancel = true,
  title = "Update recurring booking",
  updateOneTitle = "Only this booking",
  updateAllTitle = "All future repeating bookings"
}: ConfirmCheckoutInterface) => {
  const isMobile = useMobile();
  const [selectedType, setSelectedType] = useState(BookingFrequencyUpdateType.only);

  const handleConfirm = () => {
    onClose();

    handleUpdate(selectedType !== BookingFrequencyUpdateType.only ? true : false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile}>
      <Box padding={"48px 48px 24px 48px"} bgcolor="#ffffff" borderRadius="8px" position="relative">
        <Box
          width={isMobile ? "100%" : "450px"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Box fontFamily="Museo" fontWeight={600} fontSize="22px" color={Colors.NightBlue}>
              {title}
            </Box>
          </Box>
          <Box
            fontFamily="Museo"
            fontSize="16px"
            fontWeight={500}
            color={Colors.Dusk}
            mt="24px"
            textAlign={"center"}
          >
            <ActionOptions
              type={BookingFrequencyUpdateType.only}
              onSelected={() => setSelectedType(BookingFrequencyUpdateType.only)}
              selectedType={selectedType}
              label={updateOneTitle}
            />
            <ActionOptions
              type={BookingFrequencyUpdateType.all}
              onSelected={() => setSelectedType(BookingFrequencyUpdateType.all)}
              selectedType={selectedType}
              label={updateAllTitle}
            />
          </Box>

          <Box style={styles.actionWrapper} flexDirection="row">
            {allowCancel && <Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />}
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title="Confirm"
              onClick={() => handleConfirm()}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmBookingUpdateModal;

const styles = {
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "48px",
  },
};
