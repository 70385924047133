import React from "react";
import { Box, Grid } from "@material-ui/core";
import Wrapper from "../../components/Public/Wrapper";
import ReviewItem from "../../components/Public/Reviews/ReviewItem";
import useSWR from "swr";
import { PublicReviewsResponse, PublicReview } from "../../models";
import PaginationControl from "../../components/PaginationControl";
import { ceil } from "lodash";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";

const pageSize = 10;

export default function Reviews() {
  const isMobile = useMobile();

  const [currentPage, setCurrentPage] = React.useState(0);

  const { data: reviewsData, mutate: reviewsMutate } = useSWR(
    `/reviews/public-reviews/?currentPage=${
      currentPage + 1
    }&perPage=${pageSize}&search={}`
  );

  const reviews = reviewsData ? reviewsData.reviewsList : [];

  const _pages = () => {
    if (reviewsData) {
      const { objectCount, perPage } = reviewsData;
      return ceil(objectCount / perPage);
    }

    return 0;
  };

  const pages = _pages();

  return (
    <Wrapper display="flex" flexDirection="column" alignItems="center">
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="36px"
        color="#301E79"
        mt="40px"
        mb="30px"
        ml="24px"
        mr="24px"
        textAlign="center"
      >
        Live reviews of Blys practitioners
      </Box>
      <Box
        maxWidth={isMobile ? "100%" : "1350px"}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
      >
        {reviews.map((review: PublicReview) => (
          <ReviewItem review={review} />
        ))}
      </Box>

      {pages > 0 && (
        <Box
          display="flex"
          justifyContent="center"
          width="100%"
          mt="30px"
          mb="30px"
        >
          <PaginationControl
            currentPage={currentPage}
            pages={pages}
            onPrevious={() => {
              if (currentPage > 0) {
                setCurrentPage(currentPage - 1);
              }
            }}
            onNext={() => {
              if (currentPage < pages - 1) {
                setCurrentPage(currentPage + 1);
              }
            }}
          />
        </Box>
      )}

      {reviewsData && (
        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          color={Colors.Dusk}
          mb="55px"
          ml="24px"
          mr="24px"
          textAlign="center"
        >
          <span className="schema-divs" itemType="http://schema.org/Brand">
            <span
              itemProp="aggregateRating"
              itemType="http://schema.org/AggregateRating"
            >
              <span itemProp="reviewCount">{reviewsData.objectCount}</span>{" "}
              customers reviews rated on average of{" "}
              <span itemProp="ratingValue">{+reviewsData.avgRating}</span> stars
            </span>
          </span>
        </Box>
      )}
    </Wrapper>
  );
}
