import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { BASE_UPLOADS_URL } from "../../constants/common";
import { useMobile } from "../../hooks/mobile";
import ProfilePicturePlaceholder from "../../images/profile-pic-placeholder.png";

const UserDetails = ({details}: any) => {
  const isMobile = useMobile();

    return (
        <Box>
          <Typography variant="body1"
            style={{
              color: Colors.Dusk,
              marginTop: isMobile ? "32px": "40px",
              fontSize: isMobile ? "16px" : "18px",
              fontWeight: 400}}>
              To complete this payment, please login with this account instead:
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            mt={4.5}
            mb={4.25}
            paddingLeft="12px"
          >
            {details?.profile ? (
              <img
                src={`${BASE_UPLOADS_URL}/${details.profile}`}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "20px",
                  objectFit: "cover",
                }}
              />
            ) : (

              <img
                  src={ProfilePicturePlaceholder}
                  style={{ width: "48px", height: "48px" }}
              />
              ) 
            }
              
            <Box
              ml={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box
                fontFamily="Museo"
                fontWeight={700}
                fontSize="18px"
                color={Colors.NightBlue}
              >
                {details?.firstName} {details?.lastName}
              </Box>
              <Box
                fontFamily="Open Sans"
                fontWeight={400}
                color={Colors.Grey}
                fontSize="14px"
              >
                {details?.email}
              </Box>
            </Box>
          </Box>
        </Box>
    );

};

export default UserDetails;