import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import { times } from "lodash";

import Star from "../images/rating-star.svg";
import StarFilled from "../images/rating-star-filled.svg";

interface Props {
  rating: number;
  width?: string;
  height?: string;
  onClickRating?: (rating: number) => unknown;
}

export default function Ratings({
  rating,
  width = "11.9px",
  height = "11.3px",
  onClickRating,
}: Props): JSX.Element {
  const image = (index: number) => {
    const image = index < Math.ceil(rating) ? StarFilled : Star;

    return <img key={index} src={image} width={width} height={height} />;
  };

  return (
    <Box display="flex" flexDirection="row" gridGap={"4px"}>
      {times(5, (index) => {
        if (onClickRating) {
          return (
            <ButtonBase key={index} onClick={() => onClickRating(index + 1)}>
              {image(index)}
            </ButtonBase>
          );
        }

        return image(index);
      })}
    </Box>
  );
}
