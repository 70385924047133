import { Box } from "@material-ui/core";
import copy from "copy-to-clipboard";
import { isEmpty } from "lodash";
import moment from "moment";
import React from "react";
import useSWR from "swr";
import Button from "../components/Button";
import SectionPageHeader from "../components/SectionPageHeader";
import TextLink from "../components/TextLink";
import Wrapper from "../components/Wrapper";
import { Colors } from "../constants/colors";
import { TrackingEvents } from "../constants/tracking";
import { formatToPrice } from "../helpers/number";
import { withVersion } from "../helpers/tracking";
import { useAccessToken } from "../hooks/common";
import { useMobile } from "../hooks/mobile";
import useShareSheet from "../hooks/shareSheet";
import { InvitedFriend } from "../models";
import { trackEvent } from "../services/segment/track.service";
import { useAlertStore } from "../stores/alert";
import { useUserStore } from "../stores/user";

interface InvitedFriendDataRowProps {
  title: string;
  value: string;
}

function InvitedFriendDataRow({
  title,
  value,
}: InvitedFriendDataRowProps): JSX.Element {
  return (
    <Box
      mt="15px"
      mb="15px"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box
        fontFamily="Museo"
        fontWeight={500}
        fontSize="15px"
        letterSpacing={0}
        color={Colors.Dusk}
      >
        {title}
      </Box>
      <Box
        fontFamily="Museo"
        fontWeight={500}
        fontSize="15px"
        letterSpacing={0}
        color={Colors.TurquoiseBlue}
        textAlign="right"
      >
        {value}
      </Box>
    </Box>
  );
}

interface InvitedFriendRowProps {
  invited: InvitedFriend;
  currency: string;
}

function InvitedFriendRow({ invited, currency }: InvitedFriendRowProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      fontFamily="Museo"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      height="55px"
      pl="24px"
      pr="24px"
    >
      <Box width="33%">{invited.fullName}</Box>
      <Box width="33%">{moment(invited.bookingDate).format("MMMM D, YYYY")}</Box>

      <Box width="33%">{formatToPrice(invited.amount, currency)}</Box>
    </Box>
  );
}

export default function InviteFriends(): JSX.Element {
  const isMobile = useMobile();
  const accessToken = useAccessToken();

  const { setSuccessMessage } = useAlertStore();

  const { user, fetchMe, credit, currency, fetchCreditBalance, fetchReferralDiscount, referralDiscount } = useUserStore();

  // console.debug("location: ", location);
  const { data: invitedFriendsData } = useSWR(
    `/users/me/referrals?accessToken=${accessToken}`
  );
  const invitedFriends = invitedFriendsData
    ? (invitedFriendsData.referrals as InvitedFriend[])
    : [];

  console.debug("invitedFriendsData: ", invitedFriendsData);

  React.useEffect(() => {
    fetchMe();
    fetchCreditBalance();
  }, []);

  React.useEffect(() => {
    fetchReferralDiscount();
  },[user]);

  const shareSheet = useShareSheet();

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeader
            title="Invite friends"
            subtitle={`Give ${currency || "$"}${referralDiscount} to a friend and receive ${
              currency || "$"
            }${referralDiscount} yourself`}
          />
        }
      >
        <Box
          mt="43px"
          ml={isMobile ? "24px" : "80px"}
          mr={isMobile ? "24px" : "80px"}
        >
          {/* <Box
            fontFamily="Museo"
            fontSize="17px"
            color={Colors.NightBlue}
            flexDirection="row"
            display="flex"
            mt={5}
            mb={2}
          >
            Total Credits:{" "}
            <Box fontWeight={700} ml={0.5}>
              ${credit}
            </Box>
          </Box> */}
          {user?.referralCode  && (
            <Box
              width={isMobile ? "100%" : "609px"}
              height="100px"
              bgcolor="#ffffff"
              borderRadius="6px"
              border={`solid 1px ${Colors.VeryLightPink}`}
            >
              <Box margin="25px">
                <Box
                  fontFamily="Museo"
                  fontWeight={700}
                  fontSize="14px"
                  color={Colors.Dusk}
                >
                  Code
                </Box>
                <Box height="8px" />
                <Box display="flex" flexDirection="row">
                  <Box
                    fontFamily="Museo"
                    fontWeight={500}
                    fontSize="24px"
                    color={Colors.NightBlue}
                    flex={1}
                  >
                    {user?.referralCode}
                  </Box>
                  <Box marginTop="6px">
                    <TextLink
                      title="Copy code"
                      onClick={() => {
                        copy(user?.referralCode);
                        setSuccessMessage(
                          "Your code has been copied to your clipboard!"
                        );

                        trackEvent(
                          TrackingEvents.ReferralCodeCopied,
                          withVersion()
                        );
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {shareSheet.isSupported && user?.referralCode && (
            <Box mt={3}>
              <Button
                title="Tell friends"
                onClick={() => {
                  shareSheet.share({
                    title: `Here's ${currency || "$"}${referralDiscount} off your first Blys booking!`,
                    text: `Use my code ${user?.referralCode} to get FREE ${
                      currency || "$"
                    }${referralDiscount} credit for Blys, the #1 app for mobile massage, beauty and more! Sign up or book with my code at https://getblys.com.au`,
                    url: "https://getblys.com.au",
                  });
                }}
              />
            </Box>
          )}

          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="24px"
            color={Colors.NightBlue}
            mt={5}
          >
            Share the code
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize="16px"
            letterSpacing="0.44px"
            color={Colors.Dusk}
            mt={2}
            mb={5}
          >
            It’s simple. Share the code above with a friend and when they make their first booking,
            they get {currency || "$"}{referralDiscount} off and you receive {currency || "$"}{referralDiscount} Blys credit.
          </Box>

          {!isMobile && !isEmpty(invitedFriends) && (
            <Box
              borderRadius="8px"
              border="1px solid #C5CBD6"
              bgcolor="white"
              maxWidth="750px"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                fontFamily="Museo"
                fontSize="16px"
                lineHeight="24px"
                fontWeight={400}
                color={Colors.BlueyGrey}
                height="55px"
                pl="24px"
                pr="24px"
              >
                <Box width="33%">Friend Referred</Box>
                <Box width="33%">Booking Date</Box>
                <Box width="33%">Credit You Earned</Box>
              </Box>

              {invitedFriends.map((invited) => (
                <>
                  <Box width="100%" bgcolor={Colors.LightPeriwinkle} height="1px" />
                  <InvitedFriendRow invited={invited} currency={currency}/>
                </>
              ))}
            </Box>
          )}

          {isMobile && (
            <Box mt={5}>
              {invitedFriends.map((invited) => (
                <Box
                  pl="10px"
                  pr="10px"
                  borderRadius="6px"
                  border={`solid 1px ${Colors.LightPeriwinkle}`}
                  maxWidth="450px"
                  mt="12px"
                  mb="12px"
                >
                  <InvitedFriendDataRow
                    title="Friend Referred"
                    value={invited.fullName}
                  />
                  <Box
                    width="100%"
                    bgcolor={Colors.LightPeriwinkle}
                    height="1px"
                  />
                  <InvitedFriendDataRow
                    title="Booking Date"
                    value={moment(invited.bookingDate).format("MMMM D, YYYY")}
                  />
                  <Box
                    width="100%"
                    bgcolor={Colors.LightPeriwinkle}
                    height="1px"
                  />
                  <InvitedFriendDataRow
                    title="Credits You Earned"
                    value={`$${formatToPrice(invited.amount)}`}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Wrapper>
    </>
  );
}
