import { Box } from "@material-ui/core";
import { Display, FlexDirection, FontFamily, FontSize, FontWeight, JustifyContent, Spacing } from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import { formatDateWithTimezone, getCurrentTimeStamp } from "../../../utils/date.util";
import { DEFAULT_DATE_FORMAT } from "../../../constants/date";
import Ratings from "../../Ratings";

interface Props {
  rating: number;
  text?: string;
  date?: string;
  timezone: string;
};

const ReviewRatingCard = ({ rating, text, date, timezone }: Props) => {

  const getFormattedDate = () => {
    const reviewDate = date || getCurrentTimeStamp();
    const formattedDate = formatDateWithTimezone(reviewDate, timezone, DEFAULT_DATE_FORMAT);
    let metaData = `${formattedDate}`;
    return metaData;
  };

  const parsedDate = getFormattedDate();

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S2} padding={Spacing.S4} style={{ backgroundColor: "#F8F9FA", borderRadius: "8px" }}>
      <Box display={Display.Flex} flexDirection={FlexDirection.Row} justifyContent={JustifyContent.spaceBetween}>
        <Box
          fontFamily={FontFamily.SFPro}
          fontWeight={FontWeight.Medium}
          fontSize={FontSize.F12}
          color={Colors.Grey}
        >
          {parsedDate}
        </Box>
        <Ratings rating={rating} />
      </Box>
      <Box
        fontSize={FontSize.F14}
        color={Colors.DarkSteelGrey}
        fontWeight={FontWeight.Regular}
        fontFamily={FontFamily.OpenSans}
      >
        {text}
      </Box>
    </Box>
  );
};

export default ReviewRatingCard;
