import React from "react";
import { Box } from "@material-ui/core";
import Each from "../../../Each";
import { FileRow } from "./utils/Row";
import { Text } from "../../../v2/Styled";
import * as Styled from "../../../v2/Styled/enum";
import TickIcon from "../../../../images/tick-outlined.svg";
import { Colors } from "../../../../constants/colors";

interface Props {
  details: any;
  hasProvidedIDDocument: boolean;
}

const PayoutIdVerificationCard = ({ details, hasProvidedIDDocument }: Props): JSX.Element => {
  const buildIdentityVerification = (item: { label: string; value: any; type: string }) => (
    <FileRow label={item.label} value={item.value} type={item.type} />
  );

  return (
    <Box
      gridGap={Styled.Spacing.S1}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      {hasProvidedIDDocument ? (
        <Box
          gridGap={Styled.Spacing.S1}
          display={Styled.Display.Flex}
          alignItems={Styled.AlignItems.center}
        >
          <Box
            display={Styled.Display.Flex}
            alignItems={Styled.AlignItems.center}
            justifyContent={Styled.JustifyContent.center}
            style={{ width: Styled.Spacing.S6, height: Styled.Spacing.S6 }}
          >
            <img
              src={TickIcon}
              alt={"Tick icon"}
              style={{ width: Styled.Spacing.S4, height: Styled.Spacing.S4 }}
            />
          </Box>
          <Text color={Colors.Grey} size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
            Provided
          </Text>
        </Box>
      ) : (
        <Each of={details} render={buildIdentityVerification} />
      )}
    </Box>
  );
};

export default PayoutIdVerificationCard;
