export const getZoomLevel = (radius: number, factor = 1) => {
  if (!radius) {
    return 4 * factor;
  } else if (radius >= 100000) {
    return 7.49 * factor;
  } else if (radius >= 50000) {
    return 8 * factor;
  } else if (radius >= 30000) {
    return 9 * factor;
  } else if (radius >= 20000) {
    return 10 * factor;
  }

  return 9 * factor;
};
