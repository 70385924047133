import TextField from "../TextField";
import { Colors } from "../../constants/colors";
import { AdditionalBillingIdentifierInterface } from "./interface";
interface Props {
    customerId?: string,
    orderNumber?: string,
    lineItemCode?: string,
    setCustomerId?: (value: string)=>void,
    setOrderNo?: (value: string)=>void,
    setItemCode?: (value: string)=>void,
    additionalBillConfig?: AdditionalBillingIdentifierInterface
}
export default function BillingIdentiferItem({
  customerId,
  orderNumber,
  lineItemCode,
  setCustomerId,
  setOrderNo,
  setItemCode,
  additionalBillConfig={
    isNDISCustomerIdReq : true,
    isOrderCodeReq : true,
    isItemCodeReq : true,
  },
}:Props):JSX.Element{
  return (
    <>
      {
        additionalBillConfig.isNDISCustomerIdReq? <TextField
          title="NDIS customer ID"
          value={customerId}
          onChange={(value) => !!setCustomerId && setCustomerId(value)}
        />:<></>
      }

      {
        additionalBillConfig.isOrderCodeReq? <TextField
          title="Purchase order number"
          onChange={(value) => !!setOrderNo && setOrderNo(value)}
          value={orderNumber}
        />:<></>
      }
   
      {
        additionalBillConfig.isItemCodeReq? <TextField
          title="Line item code"
          onChange={(value) => !!setItemCode && setItemCode(value)}
          value={lineItemCode}
        />:<></>
      }
    </>
  
  );

}