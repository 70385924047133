import { PaymentType } from "../constants/payment";
import AddNewPaypalModal from "./Payment/AddNewPaypalModal";
import AddNewCardModal from "../components/Payment/AddNewCardModal";
import { BRAINTREE_COUNTRIES, PAYMENT_PROVIDER } from "../data/enums";
import AddNewCardModelStripe from "./Payment/AddNewCardModelStripe";

export default function MobileAppNewPaymentMethod(): JSX.Element {
  const accessToken = new URLSearchParams(window.location.search).get("accessToken");
  const countryCode = new URLSearchParams(window.location.search).get("countryCode");
  const paymentProvider = new URLSearchParams(window.location.search).get("paymentProvider");
  const paymentType = new URLSearchParams(window.location.search).get("paymentType");

  const isStripeAllowed = [PAYMENT_PROVIDER.STRIPE, PAYMENT_PROVIDER.BRAINTREE_STRIPE].includes(
    paymentProvider as string
  );

  localStorage.setItem("token", accessToken as string);

  const postWebviewMessage = () => {
    window.location.replace("/new-payment-successful");
  };

  if (isStripeAllowed && paymentType === PaymentType.paypal) {
    return (
      <AddNewPaypalModal
        open={true}
        onClose={() => {}}
        onAddedNewCard={() => postWebviewMessage()}
        canAddPaypal={BRAINTREE_COUNTRIES.includes(countryCode || "")}
      />
    );
  }

  if (countryCode && isStripeAllowed) {
    return (
      <AddNewCardModelStripe
        open={true}
        onClose={() => {}}
        onAddedNewCard={() => postWebviewMessage()}
        country={countryCode}
      />
    );
  } else {
    return (
      <AddNewCardModal
        open={true}
        onClose={() => {}}
        onAddedNewCard={() => postWebviewMessage()}
        canAddPaypal={BRAINTREE_COUNTRIES.includes(countryCode || "")}
      />
    );
  }
}
