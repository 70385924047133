import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Field from "./Field";
import FormFooter from "./FormFooter";
import Each from "../../../../../components/Each";
import { getValue } from "../../../../../utils/object";
import { useAlertStore } from "../../../../../stores/alert";
import { COUNTRY } from "../../../../../constants/countries";
import * as Styled from "../../../../../components/v2/Styled/enum";
import { DropdownOption } from "../../../../../components/Dropdown";
import { useStateOptions } from "../../../../../hooks/utility/states.hooks";
import {
  TAddress,
  IFormData,
  FormDataModal,
  FormFieldType,
  FormStepsName,
  StripeOnboardingFormFiled,
} from "./model";

interface Props {
  data: IFormData;
  isUpdating: boolean;
  isForUpdate: boolean;
  fields: StripeOnboardingFormFiled[];
  onSaveAndExit: (e: MouseEvent) => unknown;
  onChange: (n: keyof IFormData, v: any) => unknown;
  onContinue: (e: MouseEvent, to: FormStepsName) => unknown;
  onPrevious: (e: MouseEvent, to: FormStepsName) => unknown;
}

const AddressForm = ({
  data,
  fields,
  onChange,
  onContinue,
  onPrevious,
  isForUpdate,
  onSaveAndExit,
}: Props): JSX.Element => {
  const [formData, setFormData] = useState<TAddress>(FormDataModal[FormStepsName.Address]);

  const { setErrorMessage } = useAlertStore();

  const country = data[FormStepsName.Address].country;
  const { data: stateOptions, isLoading: isStatesLoading } = useStateOptions({
    countryCode: country,
  });

  useEffect(() => {
    if (!data) return;
    setFormData(data[FormStepsName.Address]);
  }, [data]);

  const validate = () => {
    let isValid = true;

    const ERROR_MESSAGE = (fields || []).reduce((obj, field) => {
      if (field?.required && !field?.defaultValue) {
        obj[field.name as keyof TAddress] = `Please ${
          field?.type === "select" ? "select" : "enter"
        } ${field.label}.`;
      }
      return obj;
    }, {} as TAddress);

    Object.keys(formData).forEach((key: string) => {
      const validationKey = key as keyof TAddress;
      if (!formData[validationKey] && ERROR_MESSAGE[validationKey] && isValid) {
        // isValid is added to the condition to prevent further validation checks once an error is found
        setErrorMessage(ERROR_MESSAGE[validationKey]);
        isValid = false;
      }
    });

    return isValid;
  };

  const handleSave = (e: MouseEvent, cb = (e: MouseEvent) => {}) => {
    const isValid = validate();
    if (!isValid) return;

    onChange(FormStepsName.Address, formData);
    cb(e);
  };

  const handleChange = (field: keyof TAddress, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSaveAndExit = (e: MouseEvent) => {
    handleSave(e, onSaveAndExit);
  };

  const handlePrevious = (e: MouseEvent) => {
    onPrevious(e, FormStepsName.PersonalDetails);
  };

  const handleNext = (e: MouseEvent) => {
    const submitted = getValue(data[FormStepsName.IdentityVerification], "documentProvided");
    const next = submitted ? FormStepsName.AccountForPayout : FormStepsName.IdentityVerification;

    handleSave(e, () => onContinue(e, next));
  };

  const buildRow = (field: StripeOnboardingFormFiled) => {
    const name = getValue(field, "name") as keyof TAddress;
    let value = formData[name] as any;
    let options = [] as DropdownOption[];

    if (name === "state") {
      options = isStatesLoading ? [] : stateOptions;
    }

    if (field.type === FormFieldType.Select) {
      value = (options || []).find((item) => item.value === value);
    }

    return (
      <Box>
        <Field
          field={field}
          options={options}
          value={value || ""}
          formData={formData}
          onChange={(val) => {
            const value = getValue(val, "value") || val;
            handleChange(name, value);
          }}
        />
      </Box>
    );
  };

  return (
    <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column}>
      <Each of={fields} render={buildRow} />
      <FormFooter
        onContinue={handleNext}
        onPrevious={handlePrevious}
        isForUpdate={isForUpdate}
        onSaveAndExit={handleSaveAndExit}
        style={{ marginTop: Styled.Spacing.S4 }}
      />
    </Box>
  );
};

export default AddressForm;
