import { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';

import Uploader from '../../../File/Uploader';
import { getValue } from '../../../../utils/object';
import { BookingQuestion } from '../../../../models';
import { Colors } from '../../../../constants/colors';
import { useUserStore } from '../../../../stores/user';
import { isImage } from '../../../../utils/file.utils';
import { useAlertStore } from '../../../../stores/alert';
import { parseApiError } from '../../../../helpers/error';
import { useAccessToken } from '../../../../hooks/common';
import { QUESTION_TYPE } from '../../../../constants/questionType';
import UploadedFileRow from '../../../ProDashboard/UploadedFileRow';
import * as therapistService from '../../../../services/therapist/therapist.service';

interface Props {
  question: BookingQuestion;
  removeSuccessHandler: (imageId: any, questionId: number) => unknown;
  uploadSuccessHandler: (data: any, questionId: number, type: QUESTION_TYPE) => unknown;
}

const ImageQuestion = ({ question, uploadSuccessHandler, removeSuccessHandler }: Props) => {
  const accessToken = useAccessToken();
  const { user, fetchMe } = useUserStore();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  // fetch current user data
  useEffect(() => {
    if (!user) {
      fetchMe();
    }
  }, [user, fetchMe]);

  const removeImage = ({ id }: any) => {
    if (user) {
      therapistService
        .removeFile(id, user.id, accessToken)
        .then(() => {
          setSuccessMessage('Image Removed.');
          removeSuccessHandler(id, question.id);
        })
        .catch((error) => setErrorMessage(parseApiError(error)));
    }
  };

  // handles image upload
  const handleUpload = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event?.target?.files[0];
    if (file) {
      if (!isImage(file)) {
        return setErrorMessage('Please select image file');
      }

      const form = new FormData();
      form.append('image', file);

      if (user) {
        therapistService
          .uploadFile(user.id, accessToken, form, `question-${question.id}`)
          .then(({ data }) => {
            setSuccessMessage('Image added.');
            uploadSuccessHandler(data, question.id, QUESTION_TYPE.IMAGE);
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage(parseApiError(error));
          });
      }
    }
  };

  const currentAnswer = question.answer;
  let selectedImages = [];
  if (currentAnswer) {
    selectedImages = currentAnswer.answers || [];
  }

  const maxUploadCount = getValue(question, 'attrs.max');
  const uploadDisabled = maxUploadCount !== undefined && selectedImages.length >= maxUploadCount;

  return (
    <Box fontFamily='Museo' my='20px' color={Colors.Dusk} fontSize='14px' fontWeight={700}>
      <Box>{question.question}</Box>

      <Box>
        <Typography
          style={{
            fontWeight: 400,
            color: Colors.Dusk,
            fontSize: '14px',
            marginTop: '8px',
          }}>
          {question.description || ''}
        </Typography>
      </Box>

      {selectedImages.map((uploadedFile: any) => (
        <UploadedFileRow
          key={`reference-image-${uploadedFile.id}`}
          file={uploadedFile}
          onRemove={() => removeImage(uploadedFile)}
        />
      ))}

      <Uploader
        placeholder='Add Image'
        onUpload={handleUpload}
        disabled={uploadDisabled}
        accept='image/*'
      />
    </Box>
  );
};

export default ImageQuestion;
