import React, { ReactNode, useState } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
} from "../v2/Styled/enum";
import arrowIcon from "./../../images/accordion-arrow.svg";
import { Collapse } from "@mui/material";

interface Props {
  header?: ReactNode;
  children?: ReactNode | ReactNode[];
  containerStyle?: object;
  showDescription?: (showDescription: boolean) => unknown;
}
export const Accordion = ({ header, children, containerStyle, showDescription }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return (
    <Box
      style={{
        backgroundColor: Colors.PaleGrey,
        padding: Spacing.S6,
        ...containerStyle,
      }}
      onClick={(event) => {
        event.stopPropagation();
        setIsCollapsed(!isCollapsed);
        showDescription && showDescription(isCollapsed);
      }}
    >
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        justifyContent={JustifyContent.spaceBetween}
        style={{
          gap: Spacing.S2,
        }}
      >
        <Box
          style={{
            fontFamily: FontFamily.Museo,
            fontWeight: FontWeight.Bold,
            fontSize: FontSize.F16,
            lineHeight: LineHeight.L24,
            color: Colors.Dusk,
            width: "100%",
          }}
        >
          {header}
        </Box>
        <Box>
          <img
            src={arrowIcon}
            alt="arrow"
            style={{
              transform: !isCollapsed ? "rotate(0deg)" : "rotate(-180deg)",
              cursor: "pointer",
              transition: "0.3s",
            }}
          />
        </Box>
      </Box>
      {!isCollapsed ? (
        <Box
          style={{
            backgroundColor: Colors.LightPeriwinkle,
            marginTop: Spacing.S4,
            marginBottom: Spacing.S4,
            height: "1px",
          }}
        />
      ) : (
        <Box />
      )}

      <Collapse in={!isCollapsed} {...{ timeout: 250 }}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
};
