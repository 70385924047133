import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Colors } from "../../../../constants/colors";
import { Geocode } from "../../../../helpers/google";
import { useAccessToken } from "../../../../hooks/common";
import { useAlertStore } from "../../../../stores/alert";
import serviceAreaAction from "../context/ServiceAreaAction";
import config from "../../../../config/proDashboard/dashboardConfig.json";
import { DEFAULT_RADIUS, useServiceArea } from "../context/ServiceAreaState";
import MapArea from "./MapArea";
import NotificationRadius from "./NotificationRadius";
import SuburbAutoComplete from "./SuburbAutoComplete";
import TreatmentSwitch from "../../../../components/ProDashboard/Service/TreatmentSwitch";
import { getValue } from "../../../../utils/object";
import { useUserStore } from "../../../../stores/user";

const DEFAULT_MAP_VALUE = {
  latitude: -33.8688197,
  longitude: 151.2092955,
  center: {
    latitude: -25.835029328122733,
    longitude: 134.31833800670688,
  },
  radius: DEFAULT_RADIUS,
  name: "Sydney, Australia",
};

interface MapConfigInterface {
  latitude: number;
  longitude: number;
  center: {
    latitude: number,
    longitude: number,
  },
  radius: number,
  name: string,
}

export default function FixedServiceArea() {
  const [coordinate, setCoordinate] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [radius, setRadius] = useState(0);
  const [mapConfig, setMapConfig] = useState<MapConfigInterface>(DEFAULT_MAP_VALUE);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isEnabled, setEnabled] = useState(false);
  const accessToken = useAccessToken();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { user } = useUserStore();


  const { state, dispatch } = useServiceArea();

  const { loading, serviceArea = {}, radius: radiusData = [] } = state;
  const { fixed = {}, realTime = { enabled: false, radius: DEFAULT_RADIUS } } = serviceArea || {};

  useEffect(() => {
    if (fixed && fixed.radius) {
      setEnabled(true);
      setCoordinate({ latitude: fixed.latitude, longitude: fixed.longitude });
      setSelectedAddress(fixed.name);
      setRadius(fixed.radius);
    }
  }, [fixed]);

  useEffect(() => {
    getMapConfigBasedOnCountry();
  }, [mapConfig]);

  const onAutoCompleteChange = ({
    latitude,
    longitude,
    address,
  }: {
    latitude: number;
    longitude: number;
    address: string;
  }) => {
    // set radius if not already set
    if (!radius) {
      setRadius(mapConfig.radius);
    }
    setCoordinate({ latitude, longitude });
    setSelectedAddress(address);

    if (isEnabled) updateFixedServiceArea({ radius, latitude, longitude, name: address });
  };

  const onRadiusChange = (value: number) => {
    setRadius(value);
    if (isEnabled) updateFixedServiceArea({ radius: value });
  };

  const onNotificationEnabled = (value: boolean) => {
    setEnabled(value);
    updateFixedServiceArea({ enabled: value });
  };

  const onMapClicked = ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    Geocode(latitude, longitude)
      .then((result) => {
        if (Array.isArray(result) && result[0]) {
          const matchedAddress = result[0].formatted_address;
          setSelectedAddress(matchedAddress);
          if (isEnabled) updateFixedServiceArea({ name: matchedAddress, latitude, longitude });
        }
      })
      .catch((error) => console.debug("Error while getting address from corodinates", error));
  };

  const updateFixedServiceArea = (payload: {
    enabled?: boolean;
    radius?: number;
    latitude?: number;
    longitude?: number;
    name?: string;
  }) => {
    if (!accessToken) return;

    const enabled = payload.enabled !== undefined ? payload.enabled : isEnabled;
    const latitude = payload.latitude || coordinate.latitude;
    const longitude = payload.longitude || coordinate.longitude;
    const name = payload.name || selectedAddress;

    if (!latitude || !longitude || !name) return;

    const data = {
      radius: payload.radius || radius || DEFAULT_MAP_VALUE.radius,
      latitude: latitude || DEFAULT_MAP_VALUE.latitude,
      longitude: longitude || DEFAULT_MAP_VALUE.longitude,
      name: name || DEFAULT_MAP_VALUE.name,
    };
    serviceAreaAction
      .update(
        dispatch,
        {
          realTime,
          fixed: enabled ? data : null,
        },
        accessToken
      )
      .then(() => {
        setSuccessMessage("Fixed service area notification successfully updated");
      })
      .catch((error) => {
        setErrorMessage("Error while updating fixed time service area");
        console.debug("Error while updating fixed time service area.", error);
      });
  };

  const getProMapConfig = (config: any, country = "AU") => {
    // defaults to AU if country code is not found
    const countryConfig = config[country] || config["AU"];
    return getValue(countryConfig, "proDashboard.serviceArea.mapConfig");
  };

  const getMapConfigBasedOnCountry = () => {
    const countryCode = user?.country || "AU";
    const mapConfig = getProMapConfig(config, countryCode);
    setMapConfig(mapConfig);
    setRadius(mapConfig.radius);
  };

  return (
    <Box>
      <Box fontSize="18px" mb="24px" fontWeight={600} color={Colors.NightBlue}>
        Fixed service area
      </Box>

      <Box mb={"24px"}>
        <Box mb={"12px"}>
          <TreatmentSwitch
            title={"Receive jobs based on a fixed location"}
            checked={isEnabled}
            onChange={(checked) => onNotificationEnabled(checked)}
          />
        </Box>

        <Box fontSize="14px" lineHeight="21px" fontWeight={400} color={Colors.Dusk}>
          Get notified of jobs around a fixed location, like your home suburb. This means you'll be notified of bookings
          nearby your chosen location, even if you're on another job or in a different city.
        </Box>
      </Box>

      <NotificationRadius
        loading={loading}
        data={radiusData}
        selected={radius || mapConfig.radius}
        onRadiusChange={(value: number) => onRadiusChange(value)}
      />

      <SuburbAutoComplete value={selectedAddress} hasError={isEnabled && (!coordinate.latitude || !coordinate.longitude)} onAutoCompleteChange={onAutoCompleteChange} />
      <Box height="336px" mt="22px" mb="22px">
  
        <MapArea
          id={"fixed-time-service-area-map"}
          latitude={coordinate.latitude || mapConfig.center.latitude}
          longitude={coordinate.longitude || mapConfig.center.longitude}
          radius={radius || 0}
          getAddressFromLatLng={onMapClicked}
        />
      </Box>
    </Box>
  );
}
