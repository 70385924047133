import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";

import "../../../styles/DayPicker.css";
import { RootState } from "../../../stores/V2";
import { Paths } from "../../../constants/paths";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import LogoLink from "../../../components/LogoLink";
import TextField from "../../../components/TextField";
import { useAlertStore } from "../../../stores/alert";
import BackButton from "../../../components/BackButton";
import CorporateInformation from "./CorporateInformation";
import LandingWrapper from "../../../components/LandingWrapper";
import { SlotConfirmParams } from "../../../stores/V2/corporate/interface";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { useReserveSlot } from "../../../hooks/corporateBooking/corporateBooking.hooks";
import { ReservedSlotSuccessData } from "../../../models";

import isValidEmail from "../../../helpers/validation";

const ConfirmSlot = () => {
  const isMobile = useMobile();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const state = useSelector((state: RootState) => state.coroprateBooking);
  const history = useHistory();
  const params = useParams<SlotConfirmParams>();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { mutate, isLoading: isConfirming } = useReserveSlot({
    onSuccess: (data: ReservedSlotSuccessData) => onSuccess(data),
    onError: () => setErrorMessage("We were unable to confirm this booking slot."),
  });
  const { booking } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!state.booking.id) history.push(`${Paths.CorporateSlots}/${params.uuid}`);
  }, [history, params.uuid, state.booking.id]);

  const isValid = () => {
    if (!firstName) {
      setErrorMessage("First name is required.");
      return false;
    }
    if (!lastName) {
      setErrorMessage("Last name is required.");
      return false;
    }
    if (!email) {
      setErrorMessage("Email is required.");
      return false;
    }
    if (!isValidEmail(email)) {
      setErrorMessage("Enter valid email");
      return false;
    }
    return true;
  };

  const onSuccess = (data: ReservedSlotSuccessData) => {
    setSuccessMessage("Booking slot reserved.");
    onSlotConfirmed({ slotId: data.slotId, calendarLink: data.calendarLink, outLookCalendarLink:data?.outLookCalendarLink, appleCalendarLink:data?.appleCalendarLink });
  };

  const onSlotConfirmed = (data: ReservedSlotSuccessData) => {
    history.push(`${Paths.CorporateSlots}/${params.uuid}/${params.slotId}/reserved`, { ...data });
  };

  const handleSlotConfirm = () => {
    if (!booking.id || !params.slotId) {
      // return redirect back
      history.push(`${Paths.CorporateSlots}/${params.uuid}`);
      return;
    }
    if (isValid()) {
      mutate({
        uuid: params.uuid,
        slotId: parseInt(params.slotId),
        data: {
          firstName,
          lastName,
          email,
        },
      });
    }
  };
  return (
    <LandingWrapper showPromoImages={false}>
      <Box position="relative" width={isMobile ? undefined : "1024px"} marginBottom="80px">
        <LogoLink />

        <Box position="absolute" top="10px" left="-82px" display="flex" justifyContent="center">
          <BackButton
            onClick={() => {
              history.push(`${Paths.CorporateSlots}/${params.uuid}`);
            }}
          />
        </Box>

        <Box
          display="flex"
          flexDirection={`${isMobile ? "column" : "row"}`}
          justifyContent="space-between"
          gridGap={`${isMobile ? "0px" : "50px"}`}
        >
          <CorporateInformation
            slot={state.selectedSlot}
            subTitle="Please make sure to arrive 2 minutes before your turn."
            step={2}
            showBookingInfo
            booking={state.booking}
          />

          <Box mt={`${!isMobile ? "60px" : "0px"}`}>
            <Typography
              variant="h1"
              style={{
                color: Colors.NightBlue,
                fontSize: "36px",
                lineHeight: "39px",
              }}
            >
              Enter your details
            </Typography>
            <Box width={isMobile ? "100%" : "430px"} mt={3.6}>
              <form onSubmit={() => {}}>
                <TextField
                  title="First name"
                  placeholder="First name"
                  onChange={(text) => setFirstName(text)}
                  value={firstName}
                  type="text"
                />

                <TextField
                  title="Last name"
                  placeholder="Last name"
                  onChange={(text) => setLastName(text)}
                  value={lastName}
                  type="text"
                />

                <TextField
                  title="Email"
                  placeholder="Email"
                  onChange={(text) => setEmail(text)}
                  value={email}
                  type="email"
                />
              </form>
            </Box>
            <Box
              marginTop="30px"
              display="flex"
              justifyContent={`${isMobile ? "flex-start" : "flex-end"}`}
            >
              <Button
                height={48}
                width="115px"
                type={ButtonType.primary}
                size={ButtonSize.small}
                title="Confirm"
                loading={isConfirming}
                onClick={handleSlotConfirm}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </LandingWrapper>
  );
};

export default ConfirmSlot;
