import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import Wrapper from "../../../../components/Wrapper";
import { Colors } from "../../../../constants/colors";
import { Text } from "../../../../components/v2/Styled";
import * as Styled from "../../../../components/v2/Styled/enum";
import SectionPageHeader from "../../../../components/SectionPageHeader";

interface Props {
  children?: React.ReactNode;
  newOnboarding?: boolean;
}

const PayoutWrapper = ({ children, newOnboarding }: Props): JSX.Element => {
  const history = useHistory();
  const title = newOnboarding ? "Setup Payout" : "Banking details";
  return (
    <Wrapper>
      <SectionPageHeader
        title={title}
        onBack={() => history.goBack()}
        titleStyle={{
          fontSize: Styled.FontSize.F36,
          fontFamily: Styled.FontFamily.Museo,
          fontWeight: Styled.FontWeight.Bold,
          color: Colors.NightBlue,
        }}
        subtitleElement={
          <Box maxWidth={"845px"}>
            <Text
              font={Styled.FontFamily.Museo}
              weight={Styled.FontWeight.Medium}
              size={Styled.FontSize.F16}
              color={Colors.Dusk}
            >
              Please ensure that these details are accurate and up to date. Blys is not responsible
              for payment issues resulting from incorrect details.
            </Text>
          </Box>
        }
      />

      {children}
    </Wrapper>
  );
};

export default PayoutWrapper;
