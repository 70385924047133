import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";

interface InfoInterface {
  titleStyle: boolean;
  content: string;
}

const ExtraIformation = ({extraInformation}: any)  => {
  return (
    <>
      { extraInformation.map((info: InfoInterface, index: number) => {
        if(!info.titleStyle) {
          return (<Box
            fontFamily="Museo"
            fontSize="16px"
            fontWeight={400}
            color={Colors.NightBlue}
            lineHeight="24px"
            mt="24px"
            key={`extraInfo-${index}`}
          >
            {info.content}
          </Box>);
        } else {
          return (<Box
            fontFamily="Museo"
            fontSize="18px"
            fontWeight={600}
            color={Colors.NightBlue}
            lineHeight="27px"
            mt="32px"
            key={`extraInfo-${index}`}
          >
            {info.content}
          </Box>);
        }
      }
          
      )}

    </>
      
  );
};

export default ExtraIformation;