import { Box, ButtonBase, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../constants/colors";
import PlusIcon from "../../../images/plus-icon.svg";
import SelectedIcon from "../../../images/selected-icon.png";
import { LanguageSpokenInterface } from "../../../pages/ProDashboard/Profile";

interface StyledButtonProps {
  selected?: boolean;
}

const StyledButton = withStyles({
  root: ({ selected }: StyledButtonProps) => ({
    backgroundColor: selected ? "#EBF8FA" : undefined,
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    textAlign: "left",
    "&:hover": {
      backgroundColor: selected ? "#EBF8FA" : "#ebf8fa4d",
    },
  }),
})(ButtonBase);

interface Props {
  language: LanguageSpokenInterface;
  selected: boolean;
  onSelect: (language: any) => unknown;
}

const style = {
  fontFamily: "Open Sans",
};

const LanguageSpokenDropdownItem = ({ selected, onSelect, language }: Props) => {
  const { title } = language;

  return (
    <React.Fragment>
      <StyledButton onClick={() => onSelect(language)} selected={selected}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gridGap={15} alignItems="center">
              <>
                {selected ? (
                  <img src={SelectedIcon} alt="selected" style={{ height: 24, width: 25 }} />
                ) : (
                  <img src={PlusIcon} alt="Plus" />
                )}
              </>

              <Box>
                <Typography style={{ ...style, fontSize: "16px", fontWeight: 700 }}>
                  {title}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledButton>
      <Box
        style={{
          height: "1px",
          backgroundColor: Colors.LightPeriwinkle,
          margin: "0 16px",
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(LanguageSpokenDropdownItem);
