import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { openMapUrl, parkingOptionsText, stairsText } from "../../helpers/address";
import { capitalizeFirstLetter } from "../../helpers/string";
import LocationTypeIcon from "../../images/location-type.png";
import ParkingIcon from "../../images/parking.png";
import PetsIcon from "../../images/pets.png";
import LocationNotes from "../../images/review-note.png";
import StairsIcon from "../../images/stairs.png";
import UpdatedLocationIcon from "../../images/updated-location.png";
import BookingDetailRow from "../BookingSummary/BookingDetailRow/BookingDetailRow";
import Map from "../Map";
import { Display, FlexDirection, Spacing } from "../v2/Styled/enum";
import styles from "./styles";

interface MapProps {
  bookingId: number;
  addressDetails: any;
  isHairAndMakeup: boolean;
}

const UpdatedLocationDetails = ({ addressDetails, isHairAndMakeup }: MapProps) => {
  const {
    latitude,
    longitude,
    locationtype,
    stairOption,
    tableOption,
    petOption,
    parkingOption,
    type,
    stairs,
    parking,
    instructions,
    fullAddress,
  } = addressDetails;

  const mapId = "updated-location-details";

  const locationTypeText = locationtype ? locationtype?.label : capitalizeFirstLetter(type);
  const stairTitle = stairOption ? stairOption?.title : stairsText(stairs);
  const petTitle = petOption ? petOption.title : null;
  const tableTitle = tableOption ? tableOption.shortTitle : null;
  const parkingTitle = parkingOption ? parkingOption?.title : parkingOptionsText(parking);

  const onMapClick = () => {
    openMapUrl(addressDetails);
  };

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridRowGap={Spacing.S2}>
      <Box display="flex" gridGap={8}>
        <img src={UpdatedLocationIcon} alt="icon" style={{ maxWidth: "100%", height: 24 }} />
        <Box fontFamily="Museo" fontWeight={700} fontSize={"15px"} color={Colors.Indigo}>
          {fullAddress}
        </Box>
      </Box>
      <Box height="120px" borderRadius="8px">
        <Map id={mapId} latitude={latitude} longitude={longitude} onMapClicked={onMapClick} />
      </Box>

      <BookingDetailRow
        text={locationTypeText}
        icon={LocationTypeIcon}
        textStyle={styles.textStyle}
      />

      {!!stairTitle && (
        <BookingDetailRow
          text={`Stairs: ${stairTitle}`}
          icon={StairsIcon}
          iconStyle={{ width: 14 }}
          textStyle={styles.textStyle}
        />
      )}

      {!!petTitle && (
        <BookingDetailRow
          text={`Pets: ${petTitle}`}
          icon={PetsIcon}
          iconWrapperStyle={{ height: 24 }}
          textStyle={styles.textStyle}
        />
      )}

      {/* {!isHairAndMakeup && !!tableTitle && (
        <BookingDetailRow
          text={tableTitle}
          icon={TableIcon}
          iconWrapperStyle={{ height: 24 }}
          textStyle={styles.textStyle}
        />
      )} */}

      {!!parkingTitle && (
        <BookingDetailRow
          text={`Parking: ${parkingTitle}`}
          icon={ParkingIcon}
          iconWrapperStyle={{ height: 24 }}
          textStyle={styles.textStyle}
        />
      )}

      {!!instructions && (
        <BookingDetailRow
          text={`Location notes: ${instructions}`}
          icon={LocationNotes}
          textStyle={styles.textStyle}
        />
      )}
    </Box>
  );
};

export default UpdatedLocationDetails;
