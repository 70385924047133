import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { getValue } from "../../../utils/object";
import styles from "./styles";
import TextLink from "../../TextLink";
import { ServiceRate } from "../../../models";
import { capitalizeFirstLetter } from "../../../helpers/string";
import { DEFAULT_CURRENCY, DEFAULT_PROFESSION } from "../../../constants/booking";
import { BOOKING_SESSION_TYPE, getServiceLabel } from "../../../helpers/booking";

interface TreatmentDetail {
  id: number;
  title: string;
  value: string;
  deliveryMethod: string;
  serviceDuration: number;
  treatmentDuration: number;
  genderPreference: string;
}

interface Props {
  treatmentDetails: TreatmentDetail[];
  icon: any;
  iconStyle?: object;
  allowChange?: boolean;
  handleChange?: any;
  serviceId: number | null | undefined;
  rates: ServiceRate[];
  session: string;
}

const TreatmentDetailsRow = ({
  icon,
  iconStyle = {},
  allowChange = true,
  handleChange = () => {},
  treatmentDetails,
  rates,
  serviceId,
  session,
}: Props) => {
  const getServiceName = () => {
    if (!serviceId || treatmentDetails.length < 1) return "";

    const selectedService = rates.find((data: any) => data.id === serviceId);
    if (!selectedService) return "";

    const isCouples = session === BOOKING_SESSION_TYPE.COUPLES;

    let serviceLabel = getServiceLabel(selectedService, isCouples);

    return serviceLabel;
  };

  const getAddOnLabel = (addOn: any) => {
    const selectedService = rates?.find((data) => data.id === serviceId);

    const currency = getValue(
      selectedService,
      "selectedCountry.currencySymbol",
      DEFAULT_CURRENCY.currencySymbol,
    );

    const { name, label, price, rate } = addOn;
    const addOnPrice = price || rate || 0;
    const addOnName = label || name || "Test";

    return `${addOnName} (${currency}${parseInt(addOnPrice)?.toFixed(2)})`;
  };

  return (
    <Box display="flex" flexDirection="row" mt={2} mb={2} lineHeight={"150%"}>
      <Box
        width="24px"
        mt={"5px"}
        height="16px"
        justifyContent="center"
        alignItems="center"
        display="flex"
      >
        <img src={icon} alt="icon" style={{ width: "100%" }} />
      </Box>

      <Box display="flex" flexDirection="column" flex={1}>
        {treatmentDetails.map((detail: any, index) => {
          if (!detail) return <></>;
          const serviceDuration = detail.treatmentDuration || detail.serviceDuration;
          const addons = detail.addons || [];
          const title = detail.title ? detail.title : getValue(detail, "treatment.label");
          const serviceLabel = getServiceName();
          return (
            <Box
              key={`treatment-${index}`}
              display="flex"
              flexDirection="column"
              flex={1}
              style={{ marginBottom: "15px" }}
            >
              <Box
                ml={1.6}
                mr={1.6}
                fontFamily="Museo"
                fontSize="15px"
                color={Colors.Dusk}
                style={{ overflowWrap: "break-word" }}
                width="100%"
              >
                <Box style={styles.personText}>{serviceLabel}</Box>
                <Box style={styles.title}>{title || "-"}</Box>
                {serviceDuration ? <Box style={styles.subText}>{serviceDuration} min</Box> : <></>}

                {addons && (
                  <>
                    {addons.map((data: any) => (
                      <Box key={data.id} mt={0.5}>
                        <Box style={styles.title}>Add-on: {getAddOnLabel(data)}</Box>
                        {data?.duration && (
                          <Box style={styles.subText}>{data.duration || 0} mins</Box>
                        )}
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>

      {allowChange && (
        <Box>
          {" "}
          <TextLink title="Change" onClick={handleChange} />{" "}
        </Box>
      )}
    </Box>
  );
};

export default TreatmentDetailsRow;
