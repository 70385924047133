import React from "react";
import DropDown from "../v2/dropdown/Dropdown";
import { useUpdateUserCommunicationPreferences } from "../../hooks/communicationPreferences/communication-preference.hook";
import { useAlertStore } from "../../stores/alert";
import { getValue } from "../../utils/object";

interface PreferenceDropdownProps {
  medium: string;
  type: string;
  disabled?: boolean;
  options: {
    label: string;
    value: string | number | boolean;
  }[];
  value: string | number | boolean;
}

const PreferenceDropdown: React.FC<PreferenceDropdownProps> = ({
  medium,
  type,
  options,
  disabled,
  value,
}) => {
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { mutate: updateData } = useUpdateUserCommunicationPreferences({
    onSuccess: (data: any) => {
      const message = getValue(data, "message") || "Preference updated successfully";
      setSuccessMessage(message);
    },
    onError: () => {
      setErrorMessage("Unable to update your preference. Please try again.");
    },
  });
  const handleChange = (value: any) => {
    updateData({ type, medium, value });
  };

  return (
    <DropDown
      value={value || false}
      disabled={disabled}
      options={options}
      onChange={handleChange}
    />
  );
};

export default PreferenceDropdown;
