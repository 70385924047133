import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import { Paths } from "../../constants/paths";
import { RecipientForm } from "./components/RecipientForm";
import SectionPageHeader from "../../components/SectionPageHeader";
import { useUserStore } from "../../stores/user";
import { RecipientPageSections } from "./pageSections";
import SectionPageHeaderV2 from "../../components/SectionPageHeaderV2";

export default function AddRecipientPage(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const user = useUserStore();
  const isHomeCareUser = user.user?.isHomeCareProvider;
  const validSections = RecipientPageSections.filter((section) =>
    isHomeCareUser ? true : !section.homecare
  );

  const getSelectedSectionIndex = () => {
    let selectedIndex = 0;
    switch (location.pathname) {
    case Paths.AddRecipient:
      selectedIndex = 0;
      break;

    default:
      selectedIndex = 0;
    }
    return selectedIndex;
  };

  const initialIndex = getSelectedSectionIndex();
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState<number>(initialIndex);

  const getSectionTitle = (index: number) => {
    switch (index) {
    case 0:
      return "Add new recipient";
    default:
      return "Add new recipient";
    }
  };

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeaderV2
            title={getSectionTitle(selectedSectionIndex)}
            sections={validSections.map((sec) => sec.title)}
            onBack={() => history.goBack()}
            selectedSectionIndex={selectedSectionIndex}
            disabledSections={validSections
              .filter((sec) => sec.slug !== "details")
              .map((sec) => sec.title)}
            onSelectedSectionIndex={(index) => {
              // add action for treatment notes later...
            }}
          />
        }
      >
        <RecipientForm recipient={null} recipientId={parseInt("")} />
      </Wrapper>
    </>
  );
}
