// ref: stackoverflow.com/questions/46155
export default function isValidEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}

export const containsSpecialCharacterOrSpace = (str: string) => {
  // Regular expression to match special characters and spaces
  const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+|\s+/g;
  return !regex.test(str);
};

export const isNullString = (str: any) => {
  return str === "null";
};

export const isNumber = (str: any) => {
  const regex = /^(\d+)?$/;
  return regex.test(str);
};
