import { Box } from "@material-ui/core";
import React from "react";
import Button, { ButtonSize, ButtonType } from "../components/Button";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import Dialog from "./Dialog";

interface Props {
  open: boolean;
  onClose: () => unknown;
  title: string;
  message: string;
}

export default function WelcomeModal({
  open,
  onClose,
  title,
  message,
}: Props): JSX.Element {
  const isMobile = useMobile();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        width={isMobile ? "100%" : "440px"}
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          fontFamily="Museo"
          fontWeight={700}
          fontSize="21px"
          color={Colors.NightBlue}
          mt="26px"
        >
          {title}
        </Box>
        <Box
          fontFamily="Open Sans"
          fontSize="16px"
          textAlign="center"
          color={Colors.BlueyGrey}
          ml="35px"
          mr="35px"
          mt="12px"
        >
          {message}
        </Box>
        <Box mt="24px" mb="24px" width="80%">
          <Button
            title="Ok"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onClose}
            width="100%"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
