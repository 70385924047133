import React from "react";
import { Box } from "@material-ui/core";
import { getValue } from "../../../../utils/object";
import * as Styled from "../../../../components/v2/Styled/enum";
import { useAccountStatus } from "../../../../hooks/stripe/connect/stripeConnect.hooks";
import StripeTos from "../../../../components/ProDashboard/Business/Payouts/CoBranding/StripeTOS";
import { PayoutCoBranding } from "../../../../components/ProDashboard/Business/Payouts/CoBranding";
import { StripeConnectAccountStatus, StripeConnectAccountType } from "../../../../constants/stripe";
import { StripeConnectCustomV2, StripeConnectExpress } from "../../../../components/Stripe/Connect";
import BankingInformation from "../../../../components/ProDashboard/Business/Banking/BankingInformation";

interface StripeConnectProps {
  bankingInfoConfig: Record<string, any>;
}

export default function StripeConnect({ bankingInfoConfig }: StripeConnectProps) {
  const { data: accountStatusData, isLoading: isAccountStatusLoading } = useAccountStatus();

  const accountType = getValue(accountStatusData, "accountType");
  const payoutAccountStatus = getValue(accountStatusData, "status");

  const isExpressAccount = accountType === StripeConnectAccountType.EXPRESS;

  const StripeComponent = isExpressAccount ? (
    <StripeConnectExpress isLoading={isAccountStatusLoading} />
  ) : (
    <React.Fragment>
      <PayoutCoBranding accountStatus={accountStatusData} />
      {payoutAccountStatus === StripeConnectAccountStatus.NOT_CONNECTED ? (
        <StripeTos preText={"By setting up your payout account"} />
      ) : (
        <></>
      )}
      <StripeConnectCustomV2 accountStatus={accountStatusData} isLoading={isAccountStatusLoading} />
    </React.Fragment>
  );

  return (
    <Box
      gridGap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <BankingInformation
        bankingInformation={bankingInfoConfig}
        accountStatus={accountStatusData}
        hideBadge={isAccountStatusLoading}
      />
      <Box
        gridGap={Styled.Spacing.S10}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
      >
        {StripeComponent}
      </Box>
    </Box>
  );
}
