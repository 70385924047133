import { Box } from "@mui/material";
import React from "react";
import { AlignItems, Display, FlexDirection, FontSize, FontWeight, JustifyContent, Spacing } from "../v2/Styled/enum";
import { Typography } from "@material-ui/core";
import DropDown, { V2DropdownType } from "../v2/dropdown/Dropdown";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";

interface Props {
    title?: string;
    description?: string;
    options: V2DropdownType[];
    onDropDownValueChange: (selectedValue: any) => unknown;
    value: boolean | string| number;
}
export const DropdownSection = ({title, description, options, onDropDownValueChange, value}: Props)=>{
  const isMobile = useMobile();
  
  return(
    <Box style={{
      display: Display.Flex,
      flexDirection: isMobile?FlexDirection.Column: FlexDirection.Row,
      alignContent: AlignItems.center,
      justifyContent: JustifyContent.center,
      gap: isMobile?Spacing.S6: Spacing.S12
    }}>
      <Box style ={{
        flex: 1,
      }}>
        {title?
          <Typography style ={{
            fontSize: FontSize.F18,
            color: Colors.NightBlue,
            fontWeight: FontWeight.Bold
          }}>
            {title}
          </Typography>
          :
          <Box/>
        }
   
        {description?
          <Typography style ={{
            fontSize: FontSize.F14,
            fontWeight: FontWeight.Regular,
            marginTop: Spacing.S2,
            color: Colors.Dusk,
          }}>
            {description}
          </Typography>
          :
          <Box/>
        }
      </Box>

      <Box style ={{
        display: Display.Flex,
        alignItems: AlignItems.center
      }}>
        <DropDown options={options} onChange={onDropDownValueChange} value={value} containerStyle={{
          padding:"8px 16px"
        }}/>
      </Box>
    </Box>
  );
};