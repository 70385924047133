import { createSlice } from "@reduxjs/toolkit";
import { UPLOAD_CONTENT_TYPE, USER_UPLOADED_CONTENT_TYPE } from "../../../data/enums";

type initialStateType = {
  showAddDocumentModal: boolean;
  selectedTermPolicies: string;
  otherDocumentName: string;
  userInput: File | string | undefined;
  contentType: UPLOAD_CONTENT_TYPE;
  showInputModal: boolean;
  therapistId: number | null;
  sendForSignature: boolean;
};

const initialState: initialStateType = {
  showAddDocumentModal: false,
  selectedTermPolicies: "",
  otherDocumentName: "",
  userInput: "",
  contentType: USER_UPLOADED_CONTENT_TYPE.FILE,
  showInputModal: false,
  therapistId: null,
  sendForSignature: false,
};

const { reducer, actions } = createSlice({
  name: "addDocuments",
  initialState,
  reducers: {
    setShowAddDocumentModal: (state, { payload }) => {
      state.showAddDocumentModal = payload;
      return state;
    },
    setSelectedTermPolicies: (state, { payload }) => {
      state.selectedTermPolicies = payload;
      return state;
    },
    setOtherDocumentName: (state, { payload }) => {
      state.otherDocumentName = payload;
      return state;
    },
    setUserInput: (state, { payload }) => {
      state.userInput = payload;
      return state;
    },
    setContentType: (state, { payload }) => {
      state.contentType = payload;
      return state;
    },
    setShowInputModal: (state, { payload }) => {
      state.showInputModal = payload;
      return state;
    },
    setTherapistId: (state, { payload }) => {
      state.therapistId = payload;
      return state;
    },
    setSendForSignature: (state, { payload }) => {
      state.sendForSignature = payload;
      return state;
    },
    reset: (state) => {
      return {
        ...initialState,
        showAddDocumentModal: state.showAddDocumentModal,
        therapistId: state.therapistId,
      };
    },
  },
});

export { actions };
export default reducer;
