import { get, isEmpty, isEqual, isNil, isObject, isUndefined, keys, values, has } from "lodash";

const getValue = (object: any, path: any, defaultValue: any = undefined) =>
  get(object, path, defaultValue);

const convertParamsToObject = (params: any) => {
  const obj: any = {};
  for (const [key, value] of params) {
    obj[key] = value;
  }
  return obj;
};

const checkIfEmpty = (value: any) => isEmpty(value);

const checkIfObject = (value: any) => isObject(value);

const checkIfNil = (value: any) => isNil(value);

const findItem = (options: any[], { key, value }: any) =>
  options.find(({ [key]: val }) => val === value);

const checkIfUndefined = (val: any) => isUndefined(val);

const countKeys = (val: object = {}) => keys(val).length;

const checkIfEqual = (val1: any, val2: any) => isEqual(val1, val2);

const checkIfKeyExist = (val: object = {}, key: string) => has(val, key);


const getOnlyValuesFromObject = (val: object = {}) => values(val);

export {
  getValue,
  convertParamsToObject,
  checkIfEmpty,
  checkIfObject,
  checkIfNil,
  findItem,
  checkIfUndefined,
  countKeys,
  checkIfEqual,
  getOnlyValuesFromObject,
  checkIfKeyExist
};
