import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";
import Button, { ButtonType, ButtonSize } from "../components/Button";
import IlterPhoto from "../images/ilter-photo.svg";
import { useUserStore } from "../stores/user";
import { useMobile } from "../hooks/mobile";
import Dialog from "./Dialog";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

export default function WelcomeModal({ open, onClose }: Props): JSX.Element {
  const { user } = useUserStore();
  const isMobile = useMobile();

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        width={isMobile ? "100%" : "440px"}
        height="380px"
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <img src={IlterPhoto} width="65px" height="65px" />

        <Box
          fontFamily="Museo"
          fontWeight={700}
          fontSize="21px"
          color={Colors.NightBlue}
          mt="26px"
        >
          Hey {user && user.firstName ? user.firstName : "there"}!
        </Box>
        <Box
          fontFamily="Open Sans"
          fontSize="16px"
          textAlign="center"
          color={Colors.BlueyGrey}
          ml="35px"
          mr="35px"
          mt="12px"
        >
          I’m Ilter, the founder of Blys. I would like to personally thank you for supporting a
          local startup & encourage any feedback. Enjoy!
        </Box>
        <Box mt="24px">
          <Button
            title="Get started"
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onClose}
            width="130px"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
