import React from "react";
import { Box } from "@material-ui/core";
import NotFoundIcon from "../../images/not-found-icon.svg";
import { Colors } from "../../constants/colors";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
} from "../v2/Styled/enum";

interface Props {
  clearAllFilter: () => void;
  isFilterApplied?: boolean;
  isRecipient?: boolean;
}

const BillingInvoiceEmptyPlaceholder: React.FC<Props> = ({
  clearAllFilter,
  isFilterApplied = false,
  isRecipient = false,
}) => {
  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      justifyContent={JustifyContent.center}
      alignItems={AlignItems.center}
      border={1}
      borderRadius={8}
      borderColor={Colors.LightBlueGrey}
      padding={Spacing.S15}
      marginTop={Spacing.S8}
      bgcolor={Colors.White}
    >
      <img src={NotFoundIcon} alt="Notfound" />
      <Box
        fontFamily={FontFamily.Museo}
        fontWeight={FontWeight.Bold}
        fontSize={FontSize.F16}
        lineHeight={LineHeight.L24}
        color={Colors.Dusk}
      >
        No due invoices found
      </Box>
      {!isFilterApplied && isRecipient ? (
        <Box
          fontFamily={FontFamily.SFPro}
          fontSize={FontSize.F12}
          color={Colors.Dusk}
          mt={1}
          mb={0.5}
        >
          All due invoices of this client will appear here.
        </Box>
      ) : (
        <>
          <Box
            fontFamily={FontFamily.SFPro}
            fontSize={FontSize.F12}
            color={Colors.Dusk}
            mt={1}
            mb={0.5}
          >
            Try changing your filter and sort or
          </Box>
          <Box
            fontFamily={FontFamily.SFPro}
            fontSize={FontSize.F12}
            color={Colors.TurquoiseBlue}
            style={{ cursor: "pointer" }}
            onClick={clearAllFilter}
          >
            clear all filter and sort.
          </Box>
        </>
      )}
    </Box>
  );
};

export default BillingInvoiceEmptyPlaceholder;
