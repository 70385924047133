import React, { useEffect, useState } from "react";
import { Box, InputBase, makeStyles } from "@material-ui/core";
import axios from "axios";

import { BookingDetails } from "../models";
import { Colors } from "../constants/colors";
import CloseIcon from "../images/cross.svg";
import Ratings from "../components/Ratings";
import Button, { ButtonSize, ButtonType } from "./Button";
import {
  covidStatusForBookingDetail,
  therapistProfilePictureUrlForBookingDetail,
} from "../helpers/booking";
import { useAlertStore } from "../stores/alert";
import { parseApiError } from "../helpers/error";
import { useAccessToken } from "../hooks/common";
import { JobStatus } from "../constants/booking";
import { useMobile } from "../hooks/mobile";
import Dialog from "./Dialog";
import AvatarImageWithCovidStatus, { ImageSize } from "./AvatarImageWithCovidStatus";
import { ImagePicker } from "./Image/ImagePicker";
import { Image, RemoveableImage } from "./Image/RemovableImage";
import { useTherapistReview, useUpdateTherapistReview } from "../hooks/review/review.hooks";
import { getValue } from "../utils/object";
import { REVIEW_CLIENT_MESSAGE } from "../constants/message";
import { REVIEW_NOTES_LIMIT_FOR_THERAPIST } from "../constants/common";
import { checkFlaggedWord } from "../services/utility/flaggedWord.service";
import { flaggedWordsForProfile } from "../constants/flaggedWords";

interface Props {
  open: boolean;
  detail: BookingDetails | null;
  onClose: () => unknown;
  onCompleted: () => unknown;
  showFlaggedModal: (arg: boolean) => unknown;
}

export default function RateTherapistModal({
  open,
  detail,
  onClose,
  onCompleted,
  showFlaggedModal,
}: Props): JSX.Element {
  const accessToken = useAccessToken();
  const isMobile = useMobile();

  const [rating, setRating] = React.useState(0);
  const [feedback, setFeedback] = React.useState("");

  const [selectedImagesFiles, setSelectedImagesFiles] = React.useState<Image[]>([]);
  const [removedImages, setRemovedImages] = React.useState<number[]>([]);
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const reviewLeft = detail?.job?.status === JobStatus.reviewLeft;

  const useStyles = makeStyles((theme) => ({
    input: {
      "&::placeholder": {
        color: Colors.LightBlueGrey,
        opacity: 1.0,
      },
    },
  }));

  const inputClasses = useStyles();
  const { isLoading: submitting, mutate: reviewTherapist } = useTherapistReview({
    onSuccess: () => {
      setSuccessMessage(REVIEW_CLIENT_MESSAGE);
      onCompleted();
    },
    onError: (error: any) => {
      setErrorMessage(parseApiError(error));
    },
  });

  const { isLoading: isUpdating, mutate: updateReview } = useUpdateTherapistReview({
    onSuccess: () => {
      setSuccessMessage(REVIEW_CLIENT_MESSAGE);
      onCompleted();
    },
    onError: (error: any) => {
      setErrorMessage(parseApiError(error));
    },
  });

  React.useEffect(() => {
    if (detail) {
      setRating(detail.job?.therapistreview?.rating);
      setFeedback((detail.job?.therapistreview?.review || "").trim());
      setSelectedImagesFiles((detail.job?.therapistreview?.reviewFiles || []) as Image[]);
      console.log(detail.job?.therapistreview?.review.length, "review lenght");
    }
  }, [detail]);

  const onImagePicked = (pickedImages: Array<any>) => {
    const images = pickedImages.map((image, index) => ({
      id: `uploaded-image-${index}-${Date.now()}`,
      isLocallyUploaded: true,
      filePath: URL.createObjectURL(image),
      imageFileToUpload: image,
    }));

    setSelectedImagesFiles((preSelectedImages) => [...preSelectedImages, ...images]);
  };

  const onImageRemoved = (removedImage: Image) => {
    const upatedImagesAfterRemoved = selectedImagesFiles.filter(({ id }) => removedImage.id !== id);
    setSelectedImagesFiles(upatedImagesAfterRemoved);
    const isLocallyUploaded = getValue(removedImage, "isLocallyUploaded", false);
    if (!isLocallyUploaded) {
      setRemovedImages((prevImagesToRemove) => [...prevImagesToRemove, removedImage.id as number]);
    }
  };

  const onReviewTherapist = (value: string) => {
    if (value.length > REVIEW_NOTES_LIMIT_FOR_THERAPIST) return;
    setFeedback(value);
  };

  if (!detail) {
    return <Box />;
  }
  const { job } = detail;
  const { id: jobId } = job;
  const therapistName = detail.job?.user?.firstName || "your therapist";

  const url = therapistProfilePictureUrlForBookingDetail(detail);
  const isCovidVaccinated = covidStatusForBookingDetail(detail);

  const handleSubmitFeedback = () => {
    if (!rating) {
      return setErrorMessage(`Please provide your rating for ${therapistName}`);
    }

    if (feedback && checkFlaggedWord(flaggedWordsForProfile, feedback)) {
      showFlaggedModal(true);
      return;
    }

    const images = selectedImagesFiles
      .filter(
        ({ isLocallyUploaded, imageFileToUpload }) => isLocallyUploaded && !!imageFileToUpload
      )
      .map(({ imageFileToUpload }) => imageFileToUpload);
    if (reviewLeft) {
      updateReview({
        jobId,
        rating,
        review: feedback,
        accessToken,
        images,
        removedImages,
      });
    } else {
      reviewTherapist({
        jobId,
        rating,
        review: feedback,
        images,
        accessToken,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={
        isMobile
          ? {
            style: {
              marginLeft: "12px",
              marginRight: "12px",
            },
          }
          : undefined
      }
    >
      <Box
        width={isMobile ? "100%" : "440px"}
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingBottom={"16px"}
        paddingTop={"14px"}
        position={"relative"}
      >
        <Box
          position="absolute"
          style={{
            top: 0,
            right: 0,
            padding: 12,
          }}
        >
          <img src={CloseIcon} alt="close" onClick={onClose} style={{ cursor: "pointer" }} />
        </Box>
        <Box display="flex" flex={1} alignItems="center" justifyContent="center">
          <Box
            width="85%"
            textAlign="center"
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={5}
          >
            <AvatarImageWithCovidStatus
              size={ImageSize.medium}
              isCovidVaccinated={isCovidVaccinated}
              imageSrc={url}
            />
            <Box
              fontFamily="Museo"
              fontWeight={700}
              fontSize="21px"
              letterSpacing="-0.28px"
              color={Colors.NightBlue}
              mt="12px"
            >
              How was {therapistName}?
            </Box>
            <Box
              fontFamily="Open Sans"
              fontSize="16px"
              textAlign="center"
              color={Colors.BlueyGrey}
              mt="12px"
              mb="16px"
            >
              Your honest feedback helps to ensure that the quality and standard of providers remain
              high.
            </Box>
            <Box mt={"16px"}>
              <Ratings
                width="31.5px"
                height="30px"
                rating={rating}
                onClickRating={(rating) => {
                  setRating(rating);
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box width="100%" mt={3}>
          <Box
            minHeight="70px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            pr="25px"
            pl="29px"
          >
            <Box mt={2} mb={3} width="100%">
              <InputBase
                placeholder="Leave a review (optional)"
                onChange={(event) => {
                  onReviewTherapist(event.target.value);
                }}
                fullWidth
                value={feedback}
                multiline
                style={{
                  color: Colors.TurquoiseBlue,
                  paddingLeft: "8px",
                }}
                classes={{
                  input: inputClasses.input,
                }}
              />
              <Box
                bgcolor={Colors.LightPeriwinkle}
                height="1px"
                width="100%"
                style={{ marginTop: "10px" }}
              />

              <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
                <Box fontFamily="Open Sans" fontSize="14px" color={Colors.BlueyGrey} mt={1}>{`${
                  feedback?.length || 0
                } / ${REVIEW_NOTES_LIMIT_FOR_THERAPIST}`}</Box>
              </Box>
            </Box>
            {selectedImagesFiles.length > 0 ? (
              <Box mt={2} mb={3} width={"100%"}>
                <Box style={imageStyles.container} flexWrap={"wrap"}>
                  {selectedImagesFiles.map((image) => (
                    <RemoveableImage image={image} onRemove={onImageRemoved} />
                  ))}
                </Box>
              </Box>
            ) : (
              <></>
            )}

            <Box
              mt={1}
              mb={2}
              width="100%"
              display={"flex"}
              flexDirection={"column"}
              gridGap={"16px"}
            >
              <ImagePicker onImagePicked={onImagePicked} multipleImage />
              <Button
                title="Submit review"
                size={ButtonSize.large}
                type={ButtonType.secondary}
                width="100%"
                loading={reviewLeft ? isUpdating : submitting}
                onClick={handleSubmitFeedback}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
const imageStyles = {
  container: {
    display: "flex",
    width: "100%",
    gap: "12px",
  },
};
