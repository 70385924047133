import React, { useEffect, useState } from "react";

import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import { Box, Text } from "../../../components/v2/Styled";
import { useUserStore } from "../../../stores/user";
import queryClient from "../../../config/queryClient";
import ReferStats from "../../../components/ProDashboard/Referrals/ReferStats";
import ReferralList from "../../../components/ProDashboard/Referrals/ReferralList";
import { REFERRAL_KEYS, useReferralStats } from "../../../hooks/referral/referral.hooks";
import ReferInstructions from "../../../components/ProDashboard/Referrals/ReferInstructions";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../../components/v2/Styled/enum";

const ReferralsContent = ({ forClientUser = false }) => {
  const { isFetching: statLoading, data } = useReferralStats();

  const [referBonus, setReferBonus] = useState("");
  const [totalReferred, setTotalCount] = useState(0);
  const [totalEarned, setEarned] = useState(0);

  const isMobile = useMobile();
  const { user } = useUserStore();

  const referralCode = getValue(user, "referralCode");

  useEffect(() => {
    invalidateCache();
  }, []);

  useEffect(() => {
    if (!statLoading && data) {
      const { earned, referrals: referralCount, bonusText } = data;
      setEarned(earned);
      setReferBonus(bonusText);
      setTotalCount(referralCount);
    }
  }, [statLoading, data]);

  const invalidateCache = () => {
    queryClient.invalidateQueries([REFERRAL_KEYS.STATS]);
    queryClient.invalidateQueries([REFERRAL_KEYS.REFERRALS]);
  };

  return (
    <Box
      direction={FlexDirection.Column}
      gap={Spacing.S6}
    >
      <Box>
        <Text font={FontFamily.Museo} size={FontSize.F24} weight={FontWeight.Bold}>
          Refer a provider to Blys
        </Text>
      </Box>

      {/* instructions */}
      <ReferInstructions referBonus={referBonus} forClientUser={forClientUser} />

      {/* Total stats */}
      <ReferStats earned={totalEarned} referCount={totalReferred} referralCode={referralCode} />

      {/* Referrals */}
      <Box direction={FlexDirection.Column}>
        <Box>
          <Text font={FontFamily.Museo} size={FontSize.F18} weight={FontWeight.SemiBold}>
            Referred pros
          </Text>
        </Box>
        <ReferralList />
      </Box>
    </Box>
  );
};

export default ReferralsContent;
