import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  Client,
  Conversation,
  Message,
  Participant as TwilioParticipant,
} from "@twilio/conversations";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { get } from "../../api/client";
import queryClient from "../../config/queryClient";
import { JobStatus } from "../../constants/booking";
import { Participant } from "../../interfaces/chat.interface";
import { Booking, BookingDetails, Job } from "../../models";
import * as chatService from "../../services/chat/chat.service";
import { isCoupleMassageType } from "../../stores/booking";

const KEYS = {
  CHAT_ACCESS_TOKEN: "CHAT_ACCESS_TOKEN",
  BOOKING_FOR_CHAT: "BOOKING_FOR_CHAT",
  CONVERSATION: "CONVERSATION",
  CONVERSATION_MESSAGES: "CONVERSATION_MESSAGES",
  UNREAD_MESSAGE_COUNT: "UNREAD_MESSAGE_COUNT",
  GET_CONVERSATION_ID: "GET_CONVERSATION_ID",
};

const ERROR_MESSAGE = "We are having difficulty starting the chat. Please try refreshing the page.";
let ChatClient: Client | undefined;

type QueryOptions = {
  onError?: () => void;
  onSuccess?: () => void;
};

export const useChatAccessToken = (options?: QueryOptions) => {
  const { isLoading, isFetching, data } = useQuery(
    [KEYS.CHAT_ACCESS_TOKEN],
    () => chatService.getAccessToken(),
    {
      onError: options?.onError,
      onSuccess: options?.onSuccess,
    }
  );

  const clearChatAccessToken = () => {
    queryClient.invalidateQueries([KEYS.CHAT_ACCESS_TOKEN]);
  };

  return {
    isFetchingToken: isLoading || isFetching,
    token: data,
    clearChatAccessToken,
  };
};

export const useChatClient = () => {
  const [isClientLoading, setIsClientLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const { token, isFetchingToken, clearChatAccessToken } = useChatAccessToken({
    onError: () => {
      setError(ERROR_MESSAGE);
    },
  });

  const refreshToken = () => {
    chatService.getAccessToken().then((token) => {
      ChatClient?.updateToken(token);
    });
  };

  const initializeClient = (chatToken: string) => {
    if (ChatClient) {
      setIsClientLoading(false);
      return;
    }

    const twilioClient = new Client(chatToken);

    twilioClient.on("initialized", () => {
      ChatClient = twilioClient;
      setIsClientLoading(false);
    });

    twilioClient.on("initFailed", () => {
      setError(ERROR_MESSAGE);
      setIsClientLoading(false);
    });

    twilioClient.on("tokenAboutToExpire", refreshToken);
    twilioClient.on("tokenExpired", refreshToken);
  };

  useEffect(() => {
    if (token) {
      setIsClientLoading(true);
      initializeClient(token);
    }

    return () => {
      ChatClient?.removeAllListeners();
    };
  }, [token]);

  const restartClient = () => {
    if (!token) {
      clearChatAccessToken();
    } else {
      ChatClient = undefined;
      initializeClient(token);
    }
  };

  return {
    client: ChatClient,
    isLoading: isFetchingToken || isClientLoading,
    error,
    restartClient,
  };
};

interface BookingInfoOptions {
  onError?: () => void;
}
export const useBookingForChat = (bookingId: string | number, options?: BookingInfoOptions) => {
  const { isLoading, data } = useQuery(
    [KEYS.BOOKING_FOR_CHAT, bookingId],
    () => chatService.getBookingInfo(bookingId),
    {
      onError: options?.onError,
      retry: 1,
    }
  );

  return {
    isFetchingBooking: isLoading,
    booking: data,
  };
};

export const useConversation = (conversationId: string) => {
  const { isLoading, client, error, restartClient } = useChatClient();

  const {
    isLoading: isConversationLoading,
    isFetching,
    data: conversation,
    error: conversationError,
  } = useQuery(
    [KEYS.CONVERSATION, client ? conversationId : null],
    () => {
      if (!client) return null;

      return client.getConversationBySid(conversationId);
    },
    {
      staleTime: Infinity, // We don't need to make it stale as it's not going to change
      enabled: !!conversationId,
    }
  );

  const refetch = () => {
    if (!client && error) {
      restartClient();
    } else {
      queryClient.invalidateQueries([KEYS.CONVERSATION, client ? conversationId : null]);
    }
  };

  useEffect(() => {
    if (conversationError) {
      console.error(conversationError as any);
    }
  }, [conversationError]);

  return {
    isConversationLoading: isLoading || isFetching || isConversationLoading,
    conversation,
    refetch,
    hasError: !!error || !!conversationError,
  };
};

export const useChatMessages = (
  conversation: Conversation,
  userId: number,
  participants: Array<Participant>
) => {
  const history = useHistory();
  const messageBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    conversation.on("messageAdded", handleNewMessage);
    conversation.on("participantLeft", handleParticipantLeft);

    scrollToBottom();

    return () => {
      conversation.removeListener("messageAdded", handleNewMessage);
      conversation.removeListener("participantLeft", handleParticipantLeft);
    };
  }, [conversation]);

  const handleNewMessage = (message: Message) => {
    syncNewMessages(message);
  };

  const handleParticipantLeft = (participant: TwilioParticipant) => {
    const participantUserId = participant.identity?.split("-")?.[0];

    if (participantUserId && participantUserId === `${userId}`) {
      history.goBack();
    }
  };

  const scrollToBottom = () => {
    const currentDiv = messageBoxRef.current;
    if (currentDiv) {
      setTimeout(() => {
        currentDiv.scroll({ top: currentDiv.scrollHeight, behavior: "instant" });
      }, 10);
    }
  };

  const { isLoading, isFetchingPreviousPage, fetchPreviousPage, hasPreviousPage, data } =
    useInfiniteQuery(
      [KEYS.CONVERSATION_MESSAGES, conversation.sid],
      async ({ pageParam }) => {
        const data = await conversation.getMessages(30, pageParam);

        const twilioMessages = data.items;

        const messages = await Promise.all(
          twilioMessages.map((message) =>
            chatService.transformChatMessage(message, userId, participants)
          )
        );

        resetUnreadMessageCount();
        if (typeof pageParam === "undefined") {
          scrollToBottom();
        }

        return {
          items: messages
            .flatMap((message) => (message ? [message] : []))
            .sort((a, b) => a.index - b.index),
          hasPrevPage: data.hasPrevPage,
          hasNextPage: data.hasNextPage,
        };
      },
      {
        getPreviousPageParam: (firstPage) => {
          if (!firstPage) return undefined;

          return firstPage.hasPrevPage ? firstPage.items[0].index - 1 : undefined;
        },
        staleTime: 30 * 60 * 1000, // 30 minutes,
        refetchOnMount: "always",
      }
    );

  const syncNewMessages = async (message: Message) => {
    if (!conversation || !userId) return;

    const messages = await Promise.all(
      [message].map((message) => chatService.transformChatMessage(message, userId, participants))
    );

    queryClient.setQueryData([KEYS.CONVERSATION_MESSAGES, conversation.sid], (data: any) => {
      const pages = data?.pages || [];
      const lastIndex = pages.length - 1 < 0 ? 0 : pages.length - 1;
      const lastPage = pages[lastIndex];

      const newPages = [...pages];

      newPages[lastIndex] = {
        ...(lastPage || {}),
        items: [...(lastPage?.items || []), ...messages],
        hasNextPage: lastPage?.hasNextPage || false,
        hasPrevPage: lastPage?.hasPrevPage || false,
      };

      return {
        pages: newPages,
        pageParams: data?.pageParams,
      };
    });

    chatService.playMessageAddedSound();
    scrollToBottom();

    resetUnreadMessageCount();
  };

  const resetUnreadMessageCount = async () => {
    await conversation.setAllMessagesRead();
    queryClient.invalidateQueries([KEYS.UNREAD_MESSAGE_COUNT, conversation.sid]);
  };

  return {
    isMessagesFetching: isLoading,
    isFetchingPreviousPage,
    messages: data?.pages?.map((page) => page?.items || []).flat() || [],
    fetchPreviousPage,
    hasPreviousPage,
    messageBoxRef,
    syncNewMessages,
  };
};

export const useChatParticipants = (booking?: Booking): Array<Participant> => {
  if (!booking) return [];

  const bookingUsers = [booking.user];

  booking.bookingdetails.forEach((detail) => {
    if (detail.job.user) {
      bookingUsers.push(detail.job.user);
    }
  });

  return bookingUsers.map((user) => {
    const id = user.id;
    const name = user.firstName;
    let image = user.profileImage;

    if (user.therapistprofile) {
      image = user.therapistprofile.profileImage;
    }

    return {
      id,
      name,
      image,
    };
  });
};

export const useUnreadMessages = (conversationId: string) => {
  const { conversation, isConversationLoading } = useConversation(conversationId);

  const { isLoading, data } = useQuery(
    [KEYS.UNREAD_MESSAGE_COUNT, conversation?.sid],
    async () => {
      if (!conversation) return 0;

      const count = await conversation.getUnreadMessagesCount();
      return count;
    },
    {
      refetchOnMount: "always",
      refetchOnWindowFocus: "always",
    }
  );

  return {
    isLoading: isLoading || isConversationLoading,
    unreadCount: data || 0,
  };
};

export const isChatEnableForCoupleBookingByDetail = (bookingDetail: BookingDetails) => {
  return bookingDetail.job.canOpenChat;
};

export const isChatEnableForCoupleBookingByJob = (job: Job, booking: Booking) => {
  const allowedJobStatusToChat = [JobStatus.accepted, JobStatus.rebooking, JobStatus.onway];
  const isCoupleBooking = isCoupleMassageType(booking.sessionType);
  if (!allowedJobStatusToChat.includes(job.status) || !isCoupleBooking) return false;
  return job.canOpenChat;
};

export const isChatEnableForBooking = (booking: Booking, jobStatus: string) => {
  const isJobOnTheWay = jobStatus === JobStatus.onway;
  const isConfirmed = booking.statusFlag?.isConfirmed;
  const isDirectBooking = booking.isBookedByPro || !!booking.preferredTherapists;

  const isConfirmedDirectBooking = isConfirmed && isDirectBooking;
  const isPendingDirectBooking = booking.statusFlag?.isPending && isDirectBooking;

  const shouldRespondToDirectBooking = isDirectBooking && booking.rebookingStatus !== null;

  return {
    shouldRespondToDirectBooking,
    canClientChat:
      isConfirmedDirectBooking || isPendingDirectBooking || booking.canOpenChat || isJobOnTheWay,
  };
};

export const useGetConversationId = (bookingId: string) => {
  const { isLoading, isFetching, data, error, isError } = useQuery<{
    conversationId: string;
  }>(
    [KEYS.GET_CONVERSATION_ID, bookingId],
    () => {
      return get(`chat/twilio/${bookingId}/conversation`, {}, true);
    },
    {
      staleTime: Infinity, // it's not going to change so its infinity
      enabled: !!bookingId,
    }
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return {
    isLoading,
    isFetching,
    data,
    isError,
  };
};
