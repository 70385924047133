import { Box, Typography } from "@material-ui/core";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
} from "../../../v2/Styled/enum";
import { Colors } from "../../../../constants/colors";
import { ReactNode } from "react";
import Each from "../../../Each";

interface Props {
  title: string;
  description?: string | ReactNode | [];
  value: string;
}

const makeDescription = (description: string | ReactNode) => (
  <Box
    style={{
      fontFamily: FontFamily.OpenSans,
      fontWeight: FontWeight.Regular,
      fontSize: FontSize.F14,
      marginTop: "2px",
      color: Colors.AppGrey,
      maxWidth: "269px",
    }}
  >
    {description}
  </Box>
);

export const InfoSection = ({ title, description, value }: Props) => (
  <Box
    display={Display.Flex}
    flexDirection={FlexDirection.Row}
    justifyContent={JustifyContent.spaceBetween}
    fontFamily={FontFamily.Museo}
    fontSize={FontSize.F16}
  >
    <Box>
      <Typography style={{ color: Colors.Dusk, fontWeight: FontWeight.Bold }}>{title}</Typography>
      {description ? (
        Array.isArray(description) ? (
          <Each of={description} render={makeDescription} />
        ) : (
          makeDescription(description)
        )
      ) : (
        <Box />
      )}
    </Box>
    <Box>
      <Typography style={{ color: Colors.Dusk, fontWeight: FontWeight.Bold }}>{value}</Typography>
    </Box>
  </Box>
);
