import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import ContentModal from "../ContentModal/contentModal";
import Button, { ButtonType } from "../../Button";
import { AutoCompleteField } from "../../AutoCompleteField";
import TextButton from "../../TextButton/TextButton";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../v2/Styled/enum";
import { DropdownOption } from "../../Dropdown";
import { debounce } from "../../../utils/debounce";
import recipientIcon from "./../../../images/user-icon.png";
import { useMobile } from "../../../hooks/mobile";
import { useAlertStore } from "../../../stores/alert";
import { useSearchProviderByName } from "../../../hooks/search.hook";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import { ToggleSection } from "../../ToggleSection/ToggleSection";
import { BillingInvoicePaymentStatus } from "../../../constants/booking";
import RadioButton from "../../RadioButton";
import { useRecentProviders } from "../../../hooks/booking/preferredProviders.hooks";
import Divider from "../../Divider";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onFilterBooking: (filterValue: FilterBookingValue) => void;
  shouldClearFilter: boolean;
}
export interface FilterBookingValue {
  paymentStatus: string[];
  providerId: string | number | null | undefined;
  dueDate: string;
  amountToPay: string;
}

const RadioSection = ({
  onSelected,
  isSelected,
  title,
}: {
  onSelected: () => unknown;
  isSelected: boolean;
  title: string;
}) => (
  <Box display={Display.Flex} alignItems={AlignItems.center} gridGap={Spacing.S3}>
    <RadioButton
      selected={isSelected}
      onSelected={onSelected}
      style={{ width: "auto",height:24 }}
      radioBaseStyle={{
        border: isSelected ? `solid 5px ${Colors.TurquoiseBlue}` : `solid 1px ${Colors.Grey}`,
      }}
    />
    <Box
      fontWeight={FontWeight.Medium}
      fontFamily={FontFamily.Museo}
      fontSize={FontSize.F16}
      lineHeight={"24px"}
      color={Colors.Dusk}
    >
      {title}
    </Box>
  </Box>
);

const SORT_BY = {
  ascending: "asc",
  descending: "desc",
};

const BillingInvoiceFilterModal = ({
  open,
  onClose,
  onFilterBooking,
  shouldClearFilter,
}: Props) => {
  const isMobile = useMobile();
  const { setErrorMessage } = useAlertStore();

  const [filterPaymentStatus, setFilterPaymentStatus] = useState<any[]>([]);

  const [sortBy, setSortBy] = useState({
    dueDate: "desc",
    amountToPay: "",
  });

  const [providerName, setProviderName] = useState("");
  const [providerId, setProviderId] = useState<number | undefined | null | string>(null);
  const [providerNameToSearch, setProviderNameToSearch] = useState("");
  const [isProviderSelected, setIsProviderSelected] = useState<boolean>(false);
  const [listOfProvider, setListOfProvider] = useState([]);

  const { data: recentPros } = useRecentProviders({
    currentPage: 1,
    perPage: 20,
    options: { allProviders: true },
  });

  const { data: providerOptions, isLoading } = useSearchProviderByName({
    name: providerNameToSearch,
  });

  const handleClearAll = () => {
    // clear payment related filter
    setFilterPaymentStatus([]);

    // clear provider related filter
    setProviderName("");
    setProviderId(null);
    setSortBy({
      dueDate: "",
      amountToPay: "",
    });
  };

  useEffect(() => {
    if (shouldClearFilter) {
      handleClearAll();
    }
  }, [shouldClearFilter]);

  useEffect(() => {
    if (!checkIfEmpty(recentPros) && !providerNameToSearch) {
      const therapistList = getValue(recentPros, "therapistList", []);
      const transformRecentPros = therapistList.map((data: any) => ({
        value: data.id,
        title: `${data.firstName} ${data.lastName}`,
      }));
      setListOfProvider(transformRecentPros);
    }
  }, [recentPros, providerNameToSearch]);

  useEffect(() => {
    if (providerNameToSearch) {
      setListOfProvider(providerOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerOptions, isLoading]);

  useEffect(() => {
    const debounceSearchProvider = debounce(() => setProviderNameToSearch(providerName), 1000);

    // Call the debounced function
    debounceSearchProvider();

    // Clean up the debounced function on component unmount
    return () => {
      debounceSearchProvider.cancel();
    };
  }, [providerName]);

  const onProviderSelect = (option: DropdownOption) => {
    setProviderName(option.title);
    setProviderId(option.value);
    setIsProviderSelected(true);
  };

  const onPaymentStatusChange = ({ checked, type }: { checked: boolean; type: string }) => {
    if (checked) {
      setFilterPaymentStatus((prev) => [...prev, type]);
      return;
    }

    const removeFilterStatus = [...filterPaymentStatus].filter((data) => data !== type);
    setFilterPaymentStatus(removeFilterStatus);
  };

  const handleFilterApply = () => {
    if (providerName && !isProviderSelected) {
      return setErrorMessage("The provider name you have entered could not be found.");
    }
    onFilterBooking({
      paymentStatus: filterPaymentStatus,
      providerId: providerName ? providerId : "",
      dueDate: sortBy.dueDate,
      amountToPay: sortBy.amountToPay,
    });
    onClose();
  };

  const onSortByChange = (key: string, val: string) => {
    setSortBy((prev) => ({ ...prev, [key]: val }));
  };

  useEffect(() => {
    if (!providerName) {
      setProviderId("");
    }
  }, [providerName]);

  const shouldEnableClearAllButton =
    filterPaymentStatus.length > 0 || providerId || sortBy.amountToPay || sortBy.dueDate;

  return (
    <ContentModal
      visible={open}
      onClose={onClose}
      actions={[
        <Box
          width={"100%"}
          display={Display.Flex}
          justifyContent={JustifyContent.spaceBetween}
          alignItems={AlignItems.center}
          borderTop={1.5}
          borderColor={Colors.LightPeriwinkle}
          paddingTop={Spacing.S4}
          paddingX={Spacing.S7}
        >
          <Box flex={1}>
            <TextButton
              text="Clear all"
              type={shouldEnableClearAllButton ? "danger" : "disabled"}
              onClick={handleClearAll}
            />
          </Box>
          <Box flex={1}>
            <Button title="Apply" type={ButtonType.secondary} onClick={handleFilterApply} />
          </Box>
        </Box>,
      ]}
      title="Filter and sort"
      divider={false}
      maxWidth="xs"
      PaperProps={{
        style: {
          overflowY: "scroll",
          minWidth: !isMobile ? "437px" : undefined,
          height: "710px",
        },
      }}
      titleStyle={{ fontWeight: "700", fontSize: "16px" }}
      fullWidth={isMobile}
      childrenStyle={{ marginTop: 0 }}
      actionStyle={{ padding: "0px 0px 16px 0px" }}
      showCrossIcon={true}
    >
      <Box paddingX={Spacing.S2} mb={Spacing.S6}>
        <Box
          fontWeight={FontWeight.Bold}
          fontFamily={FontFamily.Museo}
          fontSize={FontSize.F22}
          color={Colors.NightBlue}
          mt={Spacing.S4}
        >
          Sort by
        </Box>

        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          mt={Spacing.S3}
          gridGap={Spacing.S3}
        >
          <RadioSection
            isSelected={sortBy.dueDate === SORT_BY.descending}
            onSelected={() => {
              if (!checkIfEmpty(sortBy.amountToPay)) {
                onSortByChange("amountToPay", "");
              }

              if (sortBy.dueDate === SORT_BY.descending) {
                onSortByChange("dueDate", "");
              } else {
                onSortByChange("dueDate", SORT_BY.descending);
              }
            }}
            title="Due date, new to old"
          />
          <RadioSection
            isSelected={sortBy.dueDate === SORT_BY.ascending}
            onSelected={() => {
              if (!checkIfEmpty(sortBy.amountToPay)) {
                onSortByChange("amountToPay", "");
              }
              if (sortBy.dueDate === SORT_BY.ascending) {
                onSortByChange("dueDate", "");
              } else {
                onSortByChange("dueDate", SORT_BY.ascending);
              }
            }}
            title="Due date, old to new"
          />
          <RadioSection
            isSelected={sortBy.amountToPay === SORT_BY.ascending}
            onSelected={() => {
              if (!checkIfEmpty(sortBy.dueDate)) {
                onSortByChange("dueDate", "");
              }

              if (sortBy.amountToPay === SORT_BY.ascending) {
                onSortByChange("amountToPay", "");
              } else {
                onSortByChange("amountToPay", SORT_BY.ascending);
              }
            }}
            title="Amount to pay, low to high"
          />
          <RadioSection
            isSelected={sortBy.amountToPay === SORT_BY.descending}
            onSelected={() => {
              if (!checkIfEmpty(sortBy.dueDate)) {
                onSortByChange("dueDate", "");
              }

              if (sortBy.amountToPay === SORT_BY.descending) {
                onSortByChange("amountToPay", "");
              } else {
                onSortByChange("amountToPay", SORT_BY.descending);
              }
            }}
            title="Amount to pay, high to low"
          />
        </Box>
      </Box>

      <Box paddingX={Spacing.S2} mb={Spacing.S4}>
        <Box
          fontWeight={FontWeight.Bold}
          fontFamily={FontFamily.Museo}
          fontSize={FontSize.F22}
          color={Colors.NightBlue}
          // mt={Spacing.S3}
        >
          Filter by
        </Box>

        <Box
          fontWeight={FontWeight.Bold}
          fontFamily={FontFamily.Museo}
          color={Colors.Dusk}
          mt={"12px"}
          fontSize={FontSize.F18}
          mb={Spacing.S3}
        >
          Payment status
        </Box>

        <Box display={Display.Flex} flexDirection={FlexDirection.Column} mt={Spacing.S3}>
          <Box>
            <ToggleSection
              title="Pending payment"
              subTitle="Show bookings that are invoiced & pending payment"
              onToggle={(isToggled: boolean) => {
                onPaymentStatusChange({
                  checked: isToggled,
                  type: BillingInvoicePaymentStatus.pending,
                });
              }}
              isToggled={filterPaymentStatus.includes(BillingInvoicePaymentStatus.pending)}
              ml={1}
              fontWeight={FontWeight.Medium}
              fontSize={FontSize.F16}
            />
          </Box>
        </Box>

        <Box display={Display.Flex} flexDirection={FlexDirection.Column} mt={Spacing.S4}>
          <Box>
            <ToggleSection
              title="Partially paid"
              subTitle="Show bookings  that are partially paid"
              onToggle={(isToggled: boolean) => {
                onPaymentStatusChange({
                  checked: isToggled,
                  type: BillingInvoicePaymentStatus.partial,
                });
              }}
              isToggled={filterPaymentStatus.includes(BillingInvoicePaymentStatus.partial)}
              ml={1}
              fontWeight={FontWeight.Medium}
              fontSize={FontSize.F16}
            />
          </Box>
        </Box>

        <Box display={Display.Flex} flexDirection={FlexDirection.Column} mt={Spacing.S4}>
          <Box>
            <ToggleSection
              title="Overdue"
              subTitle="Show bookings  that exceeded due date of payment"
              onToggle={(isToggled: boolean) => {
                onPaymentStatusChange({
                  checked: isToggled,
                  type: BillingInvoicePaymentStatus.overdue,
                });
              }}
              isToggled={filterPaymentStatus.includes(BillingInvoicePaymentStatus.overdue)}
              ml={1}
              fontWeight={FontWeight.Medium}
              fontSize={FontSize.F16}
            />
          </Box>
        </Box>

        <Box display={Display.Flex} flexDirection={FlexDirection.Column} mt={Spacing.S4}>
          <Box>
            <ToggleSection
              title="Fully paid"
              subTitle="Show bookings that are fully paid"
              onToggle={(isToggled: boolean) => {
                onPaymentStatusChange({
                  checked: isToggled,
                  type: BillingInvoicePaymentStatus.full,
                });
              }}
              isToggled={filterPaymentStatus.includes(BillingInvoicePaymentStatus.full)}
              ml={1}
              fontWeight={FontWeight.Medium}
              fontSize={FontSize.F16}
            />
          </Box>
        </Box>

        <Box mt={Spacing.S4}>
          <AutoCompleteField
            title="Provider name"
            options={listOfProvider as Array<DropdownOption>}
            placeholder="Enter provider name"
            onOptionClick={(option) => onProviderSelect(option)}
            onValueChange={(value) => {
              setProviderName(value);
              setIsProviderSelected(false);
            }}
            value={providerName}
            dropDownOptionIcon={recipientIcon}
            showCloseIcon={true}
            borderWidth="1px"
            titleFontSize="18px"
          />
        </Box>

      </Box>
    </ContentModal>
  );
};

export default BillingInvoiceFilterModal;
