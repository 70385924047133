import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import BookingsMap from "./BookingsMap";
import { Colors } from "../../../constants/colors";
import { Job, TherapistBooking } from "../../../models";
import { TOGGLE_VALUES } from "../../../constants/prodashboard";
import SegmentedControl from "../../../components/SegmentedControl";
import BookingItem from "../../../components/ProDashboard/BookingItem";

interface BookingsViewProps {
  bookings?: TherapistBooking[];
  handleJobOpen?: ({ jobId }: { jobId: number }) => unknown;
  jobs?: Job[];
  tab: string;
  directBookingJobs?: Job[];
  showPagination: (show: boolean) => void;
}
const NewBookingsView = ({
  bookings,
  tab,
  jobs,
  handleJobOpen,
  showPagination,
  directBookingJobs,
}: BookingsViewProps) => {
  const [selectedSegment, setSelectedSegment] = useState(0);

  useEffect(() => {
    selectedSegment === 0 ? showPagination(true) : showPagination(false);
  }, [selectedSegment]);

  const hasDirectBooking = !!directBookingJobs?.length;
  const hasNewBooking = !!jobs?.length && hasDirectBooking;

  return (
    <Box mt="24px">
      <SegmentedControl
        segments={TOGGLE_VALUES}
        selectedSegmentIndex={selectedSegment}
        onSelectedIndex={(index) => setSelectedSegment(index)}
      />
      <Box>
        {selectedSegment === 0 ? (
          <Box>
            {
              hasDirectBooking && (
                <Box>
                  <Box mt="24px" fontFamily="Museo" fontSize="18px" fontWeight={600} color={Colors.NightBlue}>
                    Direct booking requests
                  </Box>
                  {
                    directBookingJobs &&
                    directBookingJobs.map((job: Job) => <BookingItem key={job.id} job={job} onClick={handleJobOpen} />)
                  }
                </Box>
              )
            }
            <Box>
              { hasNewBooking &&
                (
                  <Box mt="24px" fontFamily="Museo" fontSize="18px" fontWeight={600} color={Colors.NightBlue}>
                    New bookings
                  </Box>
                )
              }
              {
                jobs &&
                jobs.map((job: Job) => <BookingItem key={job.id} job={job} onClick={handleJobOpen} />)
              }
            </Box>
          </Box>
        ) : (
          <Box>
            <BookingsMap bookings={bookings} jobs={jobs} tab={tab} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default NewBookingsView;
