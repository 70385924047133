import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SectionPageHeader from "../../components/SectionPageHeader";
import {
  pathForSectionAtIndex,
  ProDashboardHeaderSavedScrollPositionKey,
  sections,
} from "../../constants/prodashboard";
import { Paths } from "../../constants/paths";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty, getValue } from "../../utils/object";
import { useTherapistStore } from "../../stores/therapist";
import { useReferralStats } from "../../hooks/referral/referral.hooks";

interface Props {
  section: string;
  hideTabs?: boolean;
}

const ProDashboardHeader = ({ section, hideTabs }: Props) => {
  const history = useHistory();
  const { user } = useUserStore();
  const { proDashSections: tabs, setProDashSections } = useTherapistStore();
  const { isLoading: loadingReferralStat, data: referralData } = useReferralStats();
  const therapistProfile = getValue(user, "therapistprofile", null);

  useEffect(() => {
    setProDashSections(sections);
  }, []);

  useEffect(() => {
    if (!checkIfEmpty(referralData) && !loadingReferralStat) {
      const { label } = referralData;
      updateReferSectionLabel(label);
    }
  }, [loadingReferralStat, referralData]);

  useEffect(() => {
    if (!therapistProfile) {
      history.replace(Paths.ProDashboard);
    }
  }, [therapistProfile]);

  const updateReferSectionLabel = (label: string) => {
    const referProTab = tabs.find(({ value }) => value === "referPro");
    if (referProTab) referProTab.label = label;
    setProDashSections(tabs);
  };

  const onTabSelected = (index: number) => {
    let path = pathForSectionAtIndex(index, tabs);
    if (tabs[index].value === "referPro") {
      path = Paths.Referrals;
    }
    if (path) history.push(path);
  };

  const getSectionLabels = () => tabs.map(({ label }) => label);

  const getSelectedIndex = (text: string) => tabs.findIndex(({ label }) => label === text);

  const selectedIndex = getSelectedIndex(section);
  const sectionLabels = getSectionLabels();

  return hideTabs ? (
    <SectionPageHeader title="Pro dashboard" />
  ) : (
    <SectionPageHeader
      sectionsScrollPositionKey={ProDashboardHeaderSavedScrollPositionKey}
      title="Pro dashboard"
      sections={sectionLabels}
      selectedSectionIndex={selectedIndex}
      onSelectedSectionIndex={onTabSelected}
    />
  );
};

export default ProDashboardHeader;
