import { Box, Checkbox } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import Dropdown from "../../components/Dropdown";
import { GenderPreference } from "../../constants/booking";
import { getMassageService, useBookingStore } from "../../stores/booking";

export default function CouplesFallback (): JSX.Element  {

    const {
        sessionType,
        serviceId,
        couplesFallback,
        setCouplesFallback,
        couplesFallbackGenderPreference,
        setCouplesFallbackGenderPreference
      } = useBookingStore();

    const { genderPreferences: serviceGenderPreferences = [] } = getMassageService();


    const genderPreferenceOptions = serviceGenderPreferences.map((preference) => {
        return {
            value: preference.value,
            title: preference.title,
            helperText: preference.helpText || undefined,
        };
    });


    return (
        <>
            <Box
                fontFamily="Museo"
                fontSize="16px"
                color={Colors.Dusk}
                fontWeight={400}
                lineHeight="24px"
                mb="16px"
                flexDirection="row"
            >
                <Box mb="8px">
                    <Checkbox
                        style={{
                            color: Colors.TurquoiseBlue,
                            margin: 0,
                            padding: 0,
                        }}
                        checked={couplesFallback || false}
                        onChange={(event: any) => {
                            setCouplesFallback(event.target.checked);
                        }}
                    /> If we can't find 2 therapists, book 1 therapist back-to-back and get confirmed sooner
                </Box>
                {couplesFallback && (
                    <Box
                        fontFamily="Open Sans"
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight="21px"
                        color={Colors.BlueyGrey}>
                        <Dropdown
                            title="Preferred therapist gender if back-to-back"
                            options={genderPreferenceOptions}
                            selectedOption={genderPreferenceOptions.find(
                                (option) => option.value === couplesFallbackGenderPreference
                            )}
                            onSelectedOption={(option) => {
                                setCouplesFallbackGenderPreference(option.value);
                            }}
                        />
                    </Box>)}
            </Box>
        </>
    );
}