import { useState } from "react";
import RLightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/dist/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/dist/plugins/zoom";

import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";
import "yet-another-react-lightbox/dist/styles.css";

interface Props {
  images: string[];
  visible: boolean;
  handleClose: () => unknown;
  selectedIndex?: number;
}

const LightBox = ({ images, visible, handleClose, selectedIndex }: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const slides = images.map((image) => ({ src: image }));

  let slideIndex = currentIndex;
  if (slideIndex === null) {
    slideIndex = selectedIndex || 0;
  }

  const closeLightBox = () => {
    setCurrentIndex(null);
    handleClose();
  };

  return (
    <RLightbox
      open={visible}
      close={closeLightBox}
      plugins={[Zoom, Thumbnails]}
      carousel={{ finite: true }}
      slides={slides}
      index={slideIndex}
      render={{
        buttonPrev: currentIndex === 0 ? () => null : undefined,
        buttonNext: currentIndex === slides.length - 1 ? () => null : undefined,
      }}
      on={{
        view: ({ index }) => setCurrentIndex(index),
      }}
      thumbnails={{
        width: 20,
        height: 20,
        borderRadius: 50,
        imageFit: "cover",
        padding: 0,
      }}
    />
  );
};

export default LightBox;
