import { Box, ButtonBase, Typography } from "@material-ui/core";
import { isNil } from "lodash";
import React from "react";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import BackIcon from "../images/booking-details-back.svg";
import HeaderDecoImage from "../images/header-deco.svg";
import HeaderSectionControl from "./HeaderSectionControl";

interface Props {
  title?: string;
  subtitle?: string;
  subtitleElement?: JSX.Element;
  rightButton?: JSX.Element;
  sections?: Array<string>;
  selectedSectionIndex?: number;
  onSelectedSectionIndex?: (index: number) => unknown;
  sectionsScrollPositionKey?: string;
  onBack?: () => unknown;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  children?: React.ReactNode;
  disabledSections?: string[];
}
//this is sames as previous but it only aligns with the title of the back arrow
// not with all the content
export default function SectionPageHeaderV2({
  title,
  subtitle,
  subtitleElement,
  rightButton,
  sections,
  selectedSectionIndex,
  onSelectedSectionIndex,
  sectionsScrollPositionKey,
  onBack,
  titleStyle,
  disabledSections,
  subtitleStyle,
  children,
}: Props): JSX.Element {
  const isMobile = useMobile();

  const rightButtonEnabled = !isNil(rightButton);

  const _leftViewWidth = () => {
    if (isMobile) {
      return !isNil(onBack) ? 75 : 25;
    }

    return 80;
  };

  const leftViewWidth = _leftViewWidth();

  return (
    <Box width="100%" bgcolor={Colors.White}>
      <Box
        paddingRight={isMobile ? "25px" : undefined}
        paddingTop={isMobile ? "90px" : undefined}
        height={isMobile ? undefined : "160px"}
        display="flex"
        alignItems="center"
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="row"
          flex={1}
          height="100%"
          mb={isMobile ? "25px" : undefined}
        >
          <Box width={leftViewWidth} display="flex" justifyContent="center" alignItems={"center"}>
            {!isNil(onBack) && (
              <ButtonBase
                style={{
                  width: "40px",
                  height: "40px",
                }}
                onClick={onBack}
              >
                <img src={BackIcon} />
              </ButtonBase>
            )}
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            {children}
            {title || subtitle ? (
              <Box
                display="flex"
                flexDirection="row"
                flex={1}
                alignItems={"center"}
                gridGap={"24px"}
              >
                {title && (
                  <Typography variant={titleStyle ? undefined : "h2"} style={titleStyle}>
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <>
                    <Typography
                      variant={subtitleStyle ? undefined : "h3"}
                      style={{
                        ...subtitleStyle,
                        backgroundColor: Colors.PaleGrey,
                        padding: "4px 16px",
                        color: Colors.Dusk,
                        borderRadius: "20px",
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        textTransform: "capitalize",
                      }}
                    >
                      {subtitle}
                    </Typography>
                  </>
                )}
                {subtitleElement && <> {subtitleElement} </>}
              </Box>
            ) : null}
          </Box>
        </Box>

        {rightButtonEnabled && (
          <Box paddingRight={isMobile ? undefined : "113px"} paddingTop="10px" zIndex={999}>
            {rightButton}
          </Box>
        )}

        <img
          src={HeaderDecoImage}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "110px",
          }}
          alt="blys-banner"
        />
      </Box>

      {!isNil(sections) && !isNil(selectedSectionIndex) && !isNil(onSelectedSectionIndex) && (
        <Box paddingLeft={isMobile ? "25px" : undefined} display={"flex"}>
          <Box width={leftViewWidth} height={1} />
          <HeaderSectionControl
            scrollPositionKey={sectionsScrollPositionKey}
            sections={sections}
            disabledSections={disabledSections}
            selectedIndex={selectedSectionIndex}
            onSelectedIndex={onSelectedSectionIndex}
          />
        </Box>
      )}

      <Box width="100%" height="1px" bgcolor={Colors.PaleLilac} />
    </Box>
  );
}
