import { useQuery } from "@tanstack/react-query";
import { get } from "../api/client";

export const KEYS = {
  LANGUAGE_SPOKEN_OPTION: "LANGUAGE_SPOKEN_OPTION",
};

export const useGetAllLanguageSpokenOptions = () =>
  useQuery([KEYS.LANGUAGE_SPOKEN_OPTION], () =>
    get("api/v2/language-spoken").then(({ data }) => data),
  );
