import { forEach, isArray, isEmpty } from "lodash";
import { History } from "history";
interface FilteredData {
  [key: string]: any;
}

export const getFilterCount = ({
  filteredData,
  considerSingleCount = [],
}: {
  filteredData: FilteredData;
  considerSingleCount?: [string, string][];
}): number => {
  let count = 0;
  const groupMap = new Map<string, string>();

  considerSingleCount.forEach((group) => {
    const groupIdentifier = group.join(",");
    group.forEach((key) => {
      groupMap.set(key, groupIdentifier);
    });
  });

  const countedGroups = new Set<string>();
  const countedKeys = new Set<string>();

  Object.entries(filteredData).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      const groupIdentifier = groupMap.get(key);

      if (groupIdentifier) {
        if (!countedGroups.has(groupIdentifier)) {
          count++;
          countedGroups.add(groupIdentifier);

          considerSingleCount.forEach((group) => {
            if (group.join(",") === groupIdentifier) {
              group.forEach((k) => countedKeys.add(k));
            }
          });
        }
      } else if (!countedKeys.has(key)) {
        if (isArray(value)) {
          count += value.length;
        } else {
          count++;
        }
        countedKeys.add(key);
      }
    }
  });

  return count;
};

export const persistDataInUrl = ({
  history,
  filtersName,
  dataToIncludeInUrl,
}: {
  history: History<any>;
  filtersName: string[];
  dataToIncludeInUrl: object;
}) => {
  const params = new URLSearchParams(history.location.search);
  let hasAnyValueChanged = false;

  const keys = Object.keys(Object.fromEntries(params.entries()));

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    if (!(key in dataToIncludeInUrl) && (filtersName || []).includes(key)) {
      params.delete(key);
      hasAnyValueChanged = true;
    }
  }

  Object.entries(dataToIncludeInUrl).forEach(([key, value]) => {
    const shouldDelete =
      value === undefined || value === null || (typeof value === "object" && isEmpty(value));

    if (shouldDelete) {
      if (params.has(key)) {
        params.delete(key);
        hasAnyValueChanged = true;
      }
    } else if (params.get(key) !== value) {
      params.set(key, value as any);
      hasAnyValueChanged = true;
    }
  });

  if (hasAnyValueChanged) {
    const searchParams = params.toString();
    history.push({ search: searchParams, state: { shouldScrollToTop: false } });
  }
};

export const removeDataFromUrl = ({ filtersToRemove = [] }: { filtersToRemove: [] }) => {
  const params = new URLSearchParams(window.location.search);

  filtersToRemove.forEach((filter) => {
    params.delete(filter);
  });

  const newUrl = `${window.location.pathname}?${params.toString()}`;
  window.history.replaceState({}, "", newUrl);
};
