import create from "zustand";
import axios from "axios";
import { Address } from "../models";

type State = {
  addresses: Address[];
};


export const useAddressStore = create<State>((set) => ({
  addresses: [],
}));

export async function fetchAddresses(page?:number, pageSize?:number) {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) return [];

  const response = await axios.get(
    `/users/me/addresses?accessToken=${accessToken}&page=${page}`
  ).catch(err => {
    console.warn(err);
  });

  if (response && response.data) {
    const addresses = response.data.data as Address[];

    useAddressStore.setState({
      addresses,
    });

    return response.data;
  }

  return [];
}

export function reset() {
  useAddressStore.setState({
    addresses: [],
  });
}
