const getPostalTitle = (countryCode: string) => {
  switch (countryCode) {
  case "US":
    return "Zip code";
  default:
    return "Postcode";
  }
};

const getPostalPlaceholder = (countryCode: string) => {
  switch (countryCode) {
  case "US":
    return "00000";
  case "GB":
    return "00 000";
  default:
    return "0000";
  }
};

export { getPostalTitle, getPostalPlaceholder };