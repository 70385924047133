import React from 'react';
import { Box, ButtonBase } from '@material-ui/core';

import { useMobile } from '../../../hooks/mobile';
import UserAvatarButton from '../../UserAvatarButton';
import BlysLogoImage from '../../../images/blys-logo.svg';
import CloseButton from '../../CloseButton/CloseButton';

interface Props {
  onClose?: any;
  onLogoClick: any;
}

const LogoHeader = ({ onLogoClick, onClose }: Props) => {
  const isMobile = useMobile();

  return (
    <>
      {isMobile ? (
        <Box display='flex' flexDirection='row' justifyContent='space-between' mt={2}>
          <Box mt={1}>
            <UserAvatarButton onClick={() => {}} buttonStyle={{}} />
          </Box>

          <ButtonBase onClick={onLogoClick}>
            <img src={BlysLogoImage} alt='Blys logo' />
          </ButtonBase>

          <Box mt={1}>
            <CloseButton onClick={onClose} />
          </Box>
        </Box>
      ) : (
        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <ButtonBase onClick={onLogoClick}>
            <img src={BlysLogoImage} alt='Blys Logo' />
          </ButtonBase>

          <CloseButton onClick={onClose} />
        </Box>
      )}
    </>
  );
};

export default LogoHeader;
