import { useState, useEffect } from "react";

import Switch from "../Switch";
import Divider from "../Divider";
import { Box, Text } from "../v2/Styled";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { useAlertStore } from "../../stores/alert";
import { Display, FontFamily, FontSize, FontWeight, Spacing } from "../v2/Styled/enum";

import {
  useGetAllowDirectProChangeStatus,
  useUpdateAllowDirectProChangeStatus,
} from "../../hooks/settings/bookings.hooks";
import AutoUpdateSetting from "./AutoUpdateSetting";

const BookingSettings = () => {
  const [allowDirectProChange, setAllowDirectProChange] = useState(false);

  const isMobile = useMobile();
  const { setSuccessMessage } = useAlertStore();

  const { data } = useGetAllowDirectProChangeStatus();
  const { mutateAsync: updateAllowDirectProChangeStatus } = useUpdateAllowDirectProChangeStatus();

  useEffect(() => {
    setAllowDirectProChange(data?.allowDirectProChange);
  }, [data]);

  const handleEventChange = async (checked: boolean) => {
    setAllowDirectProChange(checked);
    await updateAllowDirectProChangeStatus({});
    const message = checked
      ? "Providers can now update your booking directly."
      : "Providers now need your confirmation to update your booking.";
    setSuccessMessage(message);
  };

  return (
    <>
      <Box maxWidth="800px" display={Display.Block}>
        <Box
          marginY={Spacing.S10}
          display={Display.Block}
          marginX={isMobile ? Spacing.S8 : Spacing.S20}
        >
          <Text
            size={FontSize.F24}
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
            color={Colors.NightBlue}
          >
            Booking management
          </Text>

          <Box paddingY={Spacing.S6} display={Display.Block}>
            <Text
              color={Colors.Dusk}
              size={FontSize.F16}
              font={FontFamily.Museo}
              weight={FontWeight.Medium}
            >
              Decide how you'd like to manage your bookings.
            </Text>
          </Box>
          <Divider />
          <AutoUpdateSetting checked={allowDirectProChange} onChange={handleEventChange}/>
        </Box>
      </Box>
    </>
  );
};

export default BookingSettings;
