import React, { useEffect, useState } from "react";
import LocationMarker from "../../images/map-pin.png";
import DraggableMarker from "../../images/map-pin-draggable.png";
import GpsMarker from "../../images/gps-marker.png";
import MapApiService from "../../services/map/MapApiService";

interface Props {
  id: string;
  latitude: number;
  longitude: number;
  onLocationSelected: (lat: number, long: number) => void;
  setIsDragging: (val: boolean) => void;
  isLocationEnabled: boolean;
}

export default function MapAreaView({
  id,
  latitude,
  longitude,
  onLocationSelected,
  setIsDragging,
  isLocationEnabled,
}: Props): JSX.Element {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const position = {
    lat: latitude,
    lng: longitude,
  };

  async function initMap(): Promise<void> {
    const loadedMap = await MapApiService.getMap(
      document.getElementById(id) as HTMLElement,
      position
    );

    loadedMap.setOptions({
      disableDefaultUI: true,
      draggable: true,
      zoomControl: false,
      disableDoubleClickZoom: true,
      fullscreenControl: false,
      streetViewControl: false,
      zoom: 4,
    });

    setMap(loadedMap);
  }

  const addMarker = ({ latitude, longitude }: any) => {
    const center = { lat: latitude, lng: longitude };

    if (isLocationEnabled) {
      new google.maps.Marker({
        position: position,
        map,
        icon: {
          url: GpsMarker,
          scaledSize: new google.maps.Size(16, 16),
        },
        draggable: false,
      });
    }

    if (!marker || !marker.setPosition) {
      const newMarker = new google.maps.Marker({
        position: center,
        map,
        icon: {
          url: LocationMarker,
          scaledSize: new google.maps.Size(36, 36),
        },
        draggable: false,
      });
      setMarker(newMarker);
    } else {
      marker.setPosition(center);
    }
  };

  const getZoomLevel = (isLocationEnabled: boolean) => {
    if (isLocationEnabled) {
      return 18;
    }

    return 4;
  };

  useEffect(() => {
    if (map) {
      map.setZoom(getZoomLevel(isLocationEnabled));
      map.setCenter(new google.maps.LatLng(latitude, longitude));
      addMarker({ latitude, longitude });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latitude, longitude, map]);

  const onMapClicked = (coordinate: any) => {
    if (!coordinate) return;
  };

  useEffect(() => {
    if (map) {
      google.maps.event.clearListeners(map, "click");
      google.maps.event.clearListeners(map, "drag");
      google.maps.event.clearListeners(map, "dragend");

      if (onMapClicked){
        map.addListener("click", (event: any) => onMapClicked(event.latLng.toJSON()));
      }

      if (marker) {
        map.addListener("drag", () => {
          setIsDragging(true);
          marker.setIcon({ url: DraggableMarker, scaledSize: new google.maps.Size(36, 36) });
          marker.setPosition(map.getCenter());
        });

        map.addListener("dragend", () => {
          marker.setIcon({ url: LocationMarker, scaledSize: new google.maps.Size(36, 36) });
          const event = marker.getPosition();
          const latitude = event?.lat();
          const longitude = event?.lng();
          onLocationSelected(latitude || 0, longitude || 0);
          setIsDragging(false);
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMapClicked, map]);


  useEffect(() => {
    if (!map) initMap();
  }, []);

  return <div id={id} style={{ width: "100%", height: "100%" }}></div>;
}
