import React from "react";
import TextField from "../../../../../components/TextField";
import { Box } from "@material-ui/core";

interface TextPanelProps {
  onChange: (value: string) => unknown;
}

const TextPanel: React.FC<TextPanelProps> = ({ onChange }) => {
  return (
    <Box width="100%" height="100%">
      <Box
        style={{
          fontFamily: "Museo",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "21px",
          textAlign: "left",
          color: "#41506F",
        }}
      >
        Your name
      </Box>
      <TextField placeholder="Type your name here" onChange={onChange} />
    </Box>
  );
};

export default TextPanel;
