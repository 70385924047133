import { useMutation } from "@tanstack/react-query";
import { post } from "../../api/client";

export const useSaveIdentifiers = ({ onSuccess, onError }: any) =>
  useMutation((data: any) => post("api/v3/user/config", data), {
    onSuccess: () => {
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
