import { convertDateToUserTimezone } from "../utils/date.util";

export const removeHtmlTags = (str: string | undefined) => {
  if (str) {
    const updateString = decodeURIComponent(str);
    return updateString.replace(/<[^>]*>/g, "");
  }
};

export const udpateOutLookCalendarUrl = (url: string | undefined) => {
  if (url) {
    let urlObj = new URL(url);
    const start = urlObj.searchParams.get("startdt");
    const end = urlObj.searchParams.get("enddt");
    const { startTime, endTime } = convertDateToUserTimezone(start, end);

    urlObj.searchParams.set("startdt", startTime);
    urlObj.searchParams.set("enddt", endTime);
    return urlObj.toString();
  }
};
