import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import AddressForm from "./Forms/AddressForm";
import PersonalDetail from "./Forms/PersonalDetail";
import { getValue } from "../../../../utils/object";
import { Colors } from "../../../../constants/colors";
import PayoutReviewForm from "./Forms/PayoutReviewForm";
import { Text } from "../../../../components/v2/Styled";
import { FormStepsName, IFormData } from "./Forms/model";
import AccountDetailForm from "./Forms/AccountDetailForm";
import * as Styled from "../../../../components/v2/Styled/enum";
import IdentityVerificationForm from "./Forms/IdentityVerificationForm";

interface Props {
  options: any[];
  data: IFormData;
  isUpdating: boolean;
  isForUpdate: boolean;
  selectedStep: number;
  payoutDetails: IFormData;
  invalidate: () => unknown;
  onFormSubmit: () => unknown;
  isInitialOnboardingCompleted: boolean;
  onSaveAndExit: (e: MouseEvent) => unknown;
  onChangeClick: (s: FormStepsName) => unknown;
  onContinue: (e: MouseEvent, to: FormStepsName) => unknown;
  onPrevious: (e: MouseEvent, to: FormStepsName) => unknown;
  onFormValueUpdate: (a: keyof IFormData, b: any) => unknown;
}

const OnboardingForm = ({
  data,
  options,
  onPrevious,
  isUpdating,
  onContinue,
  invalidate,
  isForUpdate,
  selectedStep,
  onFormSubmit,
  payoutDetails,
  onSaveAndExit,
  onChangeClick,
  onFormValueUpdate,
  isInitialOnboardingCompleted,
}: Props): JSX.Element => {
  const form = (options || []).find((option) => option.step === selectedStep);
  const [formStepName, setFormStepName] = useState<FormStepsName>();

  useEffect(() => {
    const name = getValue(form, "name", "");
    setFormStepName(name);
  }, [form, options]);

  const fields = getValue(form, "fields", []);
  const description = getValue(form, "description", "");
  const label = getValue(form, "label", "");

  const FormStep = ((name) => {
    switch (name) {
    case FormStepsName.PersonalDetails:
      return (
        <PersonalDetail
          data={data}
          fields={fields}
          isUpdating={isUpdating}
          onContinue={onContinue}
          isForUpdate={isForUpdate}
          onChange={onFormValueUpdate}
        />
      );
    case FormStepsName.Address:
      return (
        <AddressForm
          data={data}
          fields={fields}
          isUpdating={isUpdating}
          onContinue={onContinue}
          onPrevious={onPrevious}
          isForUpdate={isForUpdate}
          onChange={onFormValueUpdate}
          onSaveAndExit={onSaveAndExit}
        />
      );
    case FormStepsName.IdentityVerification:
      return (
        <IdentityVerificationForm
          data={data}
          fields={fields}
          isUpdating={isUpdating}
          onContinue={onContinue}
          onPrevious={onPrevious}
          isForUpdate={isForUpdate}
          onChange={onFormValueUpdate}
          onSaveAndExit={onSaveAndExit}
        />
      );
    case FormStepsName.AccountForPayout:
      return (
        <AccountDetailForm
          data={data}
          fields={fields}
          isUpdating={isUpdating}
          onContinue={onContinue}
          onPrevious={onPrevious}
          isForUpdate={isForUpdate}
          onChange={onFormValueUpdate}
          onSaveAndExit={onSaveAndExit}
        />
      );
    case FormStepsName.ReviewAndConfirm:
      return (
        <PayoutReviewForm
          data={data}
          isUpdating={isUpdating}
          onChange={onChangeClick}
          isForUpdate={isForUpdate}
          invalidateData={invalidate}
          onFormSubmit={onFormSubmit}
          payoutDetails={payoutDetails}
          isInitialOnboardingCompleted={isInitialOnboardingCompleted}
        />
      );
    }
  })(formStepName);

  return (
    <Box
      gridGap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <Box
        gridGap={Styled.Spacing.S2}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
      >
        <Box>
          <Text
            color={Colors.NightBlue}
            size={Styled.FontSize.F20}
            font={Styled.FontFamily.Museo}
            weight={Styled.FontWeight.Bold}
            lineHeight={Styled.LineHeight.L22}
          >
            {label}
          </Text>
        </Box>

        {description ? (
          <Box>
            <Text
              color={Colors.Dusk}
              size={Styled.FontSize.F16}
              font={Styled.FontFamily.Museo}
              weight={Styled.FontWeight.Medium}
              lineHeight={Styled.LineHeight.L24}
            >
              {description}
            </Text>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      {FormStep}
    </Box>
  );
};

export default OnboardingForm;
