import { useMutation, useQuery } from "@tanstack/react-query";
import { createTreatmentNote, fetchNotesFromOtherPros, fetchTreatmentNotes, updateTreatmentNote, uploadTreatmentNoteFile } from "../../services/users/treatmentNote.service";
import { QUERY_KEYS } from "../../constants/queryKey";

interface MutateParams {
  id?: any,
  onError: () => unknown;
  onSuccess: (response: any) => unknown;
}

const useCreateTreatmentNote = ({ onSuccess, onError }: MutateParams) => {
  return useMutation((body: any) => createTreatmentNote(body),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
      },
      onError: (error) => {
        if (onError) onError();
      },
    });
};

const useEditTreatmentNote = ({ onSuccess, onError }: MutateParams) => {
  return useMutation((body: any) => updateTreatmentNote(body),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
      },
      onError: (error) => {
        if (onError) onError();
      },
    });
};

const useUploadNoteFile = ({ onSuccess, onError }: MutateParams) => {
  return useMutation((body: any) => uploadTreatmentNoteFile(body),
    {
      onSuccess: (response) => {
        if (onSuccess) onSuccess(response);
      },
      onError: (error) => {
        if (onError) onError();
      },
    });
};

const useUserTreatmentNotes = (userId: any, jobId: any, page: number, pageSize: number = 4) => useQuery(
  [QUERY_KEYS.USER_TREATMENT_NOTES, page, jobId, userId],
  () => fetchTreatmentNotes(jobId, page, pageSize).then((res) => res.data));

const useAllTreatmentNotes = (userId: number | string, jobId: number | string, page: number, pageSize: number = 4) => useQuery(
  [QUERY_KEYS.ALL_TREATMENT_NOTES, page, userId, jobId],
  () => fetchNotesFromOtherPros(jobId, page, pageSize).then((res) => res.data));

export {
  useUploadNoteFile,
  useAllTreatmentNotes,
  useUserTreatmentNotes,
  useCreateTreatmentNote,
  useEditTreatmentNote,
};
