import create from "zustand";
import axios from "axios";
import { LocationType } from "../models";

type State = {
  locationTypes: LocationType[];
};

export const useTypesStore = create<State>((set) => ({
  locationTypes: [],
}));

export function fetchLocationTypes() {
  axios
    .get("/locationTypes?global=yes")
    .then((response) => {
      const types = response.data as LocationType[];

      useTypesStore.setState({
        locationTypes: types,
      });

      console.debug("fetched");
    })
    .catch((error) => {});
}

export function labelForLocationType(type: string) {
  const { locationTypes } = useTypesStore.getState();

  return locationTypes.find((locationType) => locationType.slug === type)
    ?.label;
}
