import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";

import TableRowMobile from "./TableRowMobile";
import TableRowDesktop from "./TableRowDesktop";
import { useMobile } from "../../hooks/mobile";

interface PreferencesTableProps {
  data: {
    title: string;
    value: string;
    subtitle?: string;
    medium: CommunicationMediumType;
  }[];
  title: string;
  isMarketing?: boolean;
}

export type CommunicationMediumType = {
  [key: string]: {
    options: {
      label: string;
      value: boolean;
    }[];
    value: boolean;
    isDisabled?: boolean;
  };
};

const PreferencesTable: React.FC<PreferencesTableProps> = ({ data, title, isMarketing = false }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  return (
    <TableContainer
      style={{
        width: "100%",
        // marginTop: "24px",
        padding: isMobile ? "12px" : "24px",
      }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableHead
          style={{
            backgroundColor: "white",
            borderRadius: "8px",
            position: "relative",
            left: -16,
          }}
        >
          <TableRow>
            <TableCell
              style={{ paddingLeft: "0px", borderBottom: "0px" }}
              className={classes.header}
            >
              <div style={{ padding: "16px" }}>{title}</div>
            </TableCell>
            {!isMobile && (
              <>
                {" "}
                <TableCell className={classes.header}>Email</TableCell>
                <TableCell className={classes.header}>App push</TableCell>
                <TableCell className={classes.header}>
                  <span>SMS</span>
                  {!isMarketing && <span className={classes.headerInfoText}> (9 cents/SMS)</span>}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <br />

        <TableBody>
          {data?.map((item) => {
            return isMobile ? (
              <TableRowMobile key={`pref-${item.title}`} data={item} isMarketing={isMarketing} />
            ) : (
              <TableRowDesktop data={item} />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const useStyles = makeStyles({
  table: {
    minWidth: "330px",
    maxWidth: "1040px",
    paddingLeft: "24px",
    width: "100%",
    paddingRight: "24px",
  },

  headerBox: {
    padding: "16px",
    width: "100%",
    background: "white",
  },
  header: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    font: "Museo",
    fontWeight: 700,
    color: "#41506F",
    padding: "0px 10px",
    paddingLeft: "20px",
    borderBottom: "0px",
  },
  headerInfoText : {
    fontWeight: 500,
  },
  box: {
    marginTop: "20px",
    paddingLeft: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
  },
  flex: {
    display: "flex",
    width: "100%",
    backgroundColor: "white",
    justifyContent: "space-between",
  },
  headerChild: {
    display: "flex",
    justifyContent: "space-between",
    width: "65%",
  },
});

export default PreferencesTable;
