export const REGEX = {
  EMAIL: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}/, // simple email validator regex
  URL: /^(https?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
};

export const INPUT_TYPE = {
  TEXT: "text",
  SELECT: "select",
};

export const USER_UPLOADED_CONTENT_TYPE = {
  FILE: "file",
  LINK: "link",
  TEXT: "text",
} as const;

export type UPLOAD_CONTENT_TYPE =
  (typeof USER_UPLOADED_CONTENT_TYPE)[keyof typeof USER_UPLOADED_CONTENT_TYPE];

export const BRAINTREE_COUNTRIES = ["AU", "NZ"];
export const BRAINTREE_APPLE_PAY_DISABLED_COUNTRIES = ["US", "GB"];

export const PAYMENT_PROVIDER = {
  STRIPE: "stripe",
  BRAINTREE: "braintree",
  BRAINTREE_STRIPE: "braintree_stripe",
  AFTERPAY: "afterpay",
  MANUAL: "manual",
};

export const PAYOUT_PROVIDER = {
  ZEPTO: "zepto",
  STRIPE: "stripe",
};

export enum VoucherDetailsItemTypes {
  giftVoucher,
  recipient,
  from,
  notes,
  deliveredTo,
  deliveredDate,
}

export const PAYMENT_FILTER_STATUS = {
  IS_UNPAID: "isUnpaid",
};

export const AGE_CARE_TERMS_DOCUMENTS_TYPE = {
  OTHER_AGE_CARE_REQIREMENT: "otherAgeCareRequirement",
};

export const AGE_CARE_TERMS_DOCUMENTS_TYPE_LABEL = {
  OTHER: "Other",
};

export const PRO_PAYOUT_STATUS = {
  PENDING_CLIENT_PAYMENT: "pendingClientPayment",
  PAYMENT_COLLECTED: "paymentCollected",
  PAYOUT_SCHEDULED: "scheduled",
  PAYOUT_IN_TRANSIT: "inTransit",
  PAYOUT_FAILED: "failed",
  PAYOUT_COMPLETED: "completed",
};

export const EXTRA_PRO_PAYOUT_STATUS = {
  EXTRA_PENDING_CLIENT_PAYMENT: "extraPendingClientPayment",
  EXTRA_PAYMENT_COLLECTED: "extraPaymentCollected",
  EXTRA_PAYOUT_SCHEDULED: "extraPayoutScheduled",
  EXTRA_PAYOUT_IN_TRANSIT: "extraPayoutInTransit",
  EXTRA_PAYOUT_FAILED: "extraPayoutFailed",
  EXTRA_PAYOUT_COMPLETED: "extraPayoutCompleted",
};
