import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import { isEmpty } from "lodash";
import React from "react";
import EmailVerificationModal from "../../components/Account/EmailVerificationModal";
import Button, { ButtonType } from "../../components/Button";
import GoogleReCaptcha from "../../components/GoogleReCaptcha";
import MobileInput from "../../components/MobileModalOtp/MobileInput";
import TextField from "../../components/TextField";
import TextLink from "../../components/TextLink";
import { Colors } from "../../constants/colors";
import { REGEX } from "../../data/enums";
import { parseApiError } from "../../helpers/error";
import { useMobile } from "../../hooks/mobile";
import { useAlertStore } from "../../stores/alert";
import { getCountryCode } from "../../utils/country";
import { resetPaymentStore } from "../../stores/booking";

interface Props {
  onCreatedAccount: () => unknown;
  onLogin: () => unknown;
  marginTop?: number | string;
}

export default function SignUpComponent({ onCreatedAccount, onLogin, marginTop = "48px" }: Props) {
  const isMobile = useMobile();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [referralCode, setReferralCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [recaptchaSuccess, setRecaptchaSuccess] = React.useState(false);

  const { setErrorMessage } = useAlertStore();

  const onSignUp = async () => {
    if (isEmpty(firstName)) {
      setErrorMessage("Please enter your first name");
      return;
    }

    if (isEmpty(lastName)) {
      setErrorMessage("Please enter your last name");
      return;
    }

    if (isEmpty(email)) {
      setErrorMessage("Please enter your email");
      return;
    }

    if (!REGEX.EMAIL.test(email.toLowerCase())) {
      setErrorMessage("Please enter valid email address");
      return;
    }

    if (isEmpty(mobile)) {
      setErrorMessage("Please enter your mobile");
      return;
    }
    if (!recaptchaSuccess) {
      setErrorMessage("Please complete reCAPTCHA");
      return;
    }

    setLoading(true);

    const data = {
      firstName,
      lastName,
      email,
      mobile,
      referral: referralCode,
      country: countryCode || (await getCountryCode()),
    };

    axios
      .post("/users/signup", data)
      .then((response) => {
        setLoading(false);
        // trackUsersSignedUp(response.data.id, "user");

        const { accessToken } = response.data;

        if (accessToken) {
          resetPaymentStore();
          localStorage.setItem("token", accessToken);

          onCreatedAccount();
        }
      })
      .catch((error) => {
        setLoading(false);

        console.debug("error: ", error);

        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <Box>
      <Typography variant="h1" style={{ color: Colors.NightBlue, marginTop }}>
        One of us!
      </Typography>
      <Typography variant="h3" style={{ color: Colors.Dusk, marginTop: "18px" }}>
        Super glad to have you with us!
      </Typography>

      <Box display="flex" flexDirection={isMobile ? "column" : "row"} mt={3}>
        <TextField
          title="First"
          placeholder="Name"
          value={firstName}
          onChange={(text) => setFirstName(text)}
        />
        <Box width="20px" />
        <TextField
          title="Last"
          placeholder="Name"
          value={lastName}
          onChange={(text) => setLastName(text)}
        />
      </Box>

      <EmailVerificationModal onVerified={(email) => setEmail(email)} isVerified={!!email} />

      {/* <MobileTextField
        countryCode={countryCode}
        mobile={mobile}
        onCountryCodeChange={(code) => setCountryCode(code)}
        onMobileChange={(mobile) => setMobile(mobile)}
      /> */}

      <MobileInput
        onVerified={(formattedMobileNumber, numberOnly, countryCode, country) => {
          setMobile(formattedMobileNumber);
          setCountryCode((country || "").toUpperCase());
        }}
        isVerified={!!mobile}
      />

      <TextField
        title="Referral code (optional)"
        placeholder="Enter code here"
        value={referralCode}
        onChange={(text) => setReferralCode(text)}
      />

      <GoogleReCaptcha elementID="client-signup" verifyCallback={() => setRecaptchaSuccess(true)} />

      <Box mt="20px">
        <Button
          loading={loading}
          title="Create account"
          type={ButtonType.secondary}
          onClick={() => onSignUp()}
        />
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box
            display="flex"
            flexDirection="row"
            fontFamily="Museo"
            fontWeight={500}
            fontSize="16px"
            color={Colors.BlueyGrey}
            mt="29px"
          >
            Already have an account?&nbsp;
            <TextLink title="Log in" onClick={onLogin} />
          </Box>
        </Box>
        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="14px"
          color={Colors.Dusk}
          mt="29px"
          textAlign="center"
          lineHeight="21px"
        >
          By creating an account, you agree to our{" "}
          <a
            href="https://getblys.com.au/terms/"
            target="_blank"
            rel="noreferrer"
            style={{ color: Colors.Dusk }}
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            href="https://getblys.com.au/privacy/"
            target="_blank"
            rel="noreferrer"
            style={{ color: Colors.Dusk }}
          >
            Privacy Policy
          </a>
        </Box>
      </Box>
    </Box>
  );
}
