import { Box } from "@material-ui/core";
import React from "react";
import Button, { ButtonType } from "../Button";

interface Props {
  disabled?: boolean;
  onUpload: (event: any) => void;
  placeholder?: string;
  accept?: string;
}

function Uploader({ disabled, onUpload, placeholder, accept }: Props) {
  const uploadInputRef = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <Box my="5px">
        <Button
          title={placeholder || "Upload Image"}
          type={ButtonType.outlined}
          width="132px"
          disabled={disabled}
          onClick={() => {
            if (uploadInputRef.current) {
              uploadInputRef.current.click();
            }
          }}
        />
      </Box>
      <input
        type="file"
        ref={uploadInputRef}
        style={{ display: "none" }}
        onChange={onUpload}
        accept={accept || "*/*"}
      />
    </>
  );
}

export default Uploader;
