import { Box } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { BOOKING_STATUS, JobStatus } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import {
  bookingIsCancelled,
  bookingIsComplete,
  covidStatusForBookingDetail,
  durationAndTimeRangeForBooking,
  getBookingStatusForBooking,
  getMassageDescriptionForBooking,
  therapistProfilePictureUrlForBookingDetail,
} from "../../helpers/booking";
import { Booking } from "../../models";
import { Box as StyledBox } from "./../v2/Styled";
import BookingStatusPill from "./V2/BookingStatusPill";

import { BASE_UPLOADS_URL } from "../../constants/common";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import PlaceholderTherapistIcon from "../../images/placeholder-therapist-icon.svg";
import {
  isAfterPayUnpaidBooking,
  publishBookingToAllPros,
} from "../../services/bookings/bookings.service";
import { useAlertStore } from "../../stores/alert";
import { useUserStore } from "../../stores/user";
import { getValue } from "../../utils/object";
import AvatarImageWithCovidStatus, { ImageSize } from "../AvatarImageWithCovidStatus";
import RebookedByProActions from "./Action/RebookedByProActions";
import BookingNotConfirmedActions from "./BookingNotConfirmedActions";
import CompleteAfterpayPayment from "./CompleteAfterpayPayment";
import CouplesFallbackActions from "./CouplesFallbackActions";
import PaymentDeclined from "./PaymentDeclined";
import PaymentPending from "./PaymentPending";

import { isChatEnableForBooking } from "../../hooks/chat/chat.hooks";
import ProfilePicturePlaceholder from "../../images/profile-pic-placeholder.png";
import CancelBooking from "../CancelBooking";
import ChatButton from "../Chat/ChatButton";
import Divider from "../Divider";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../v2/Styled/enum";
import CancellationRequestByProActions from "./Action/CancellationRequestByProActions";
import BookingItemDate from "./BookingItem/BookingItemDate";
import PreferredGenderNotFoundActions from "./PreferredGenderNotFoundActions";
import RebookingAction from "./RebookActions";
import UpdateDeclinedActions from "./UpdateDeclinedActions";
import UpdateOrCancel from "./UpdateOrCancel";
import UpdateRequestActions from "./UpdateRequestActions";

interface Props {
  booking: Booking;
  onClick: () => unknown;
  handleAlternativeTimes?: () => void;
  bookingMutate?: () => unknown;
  handleChangePaymentMethod?: (bookingId: number) => void;
  handleTherapyClicked?: (therapistID: number) => void;
  fullwidth?: boolean;
  showDivider?: boolean;
}

export default function BookingItem({
  booking,
  onClick,
  handleAlternativeTimes,
  handleChangePaymentMethod = () => {},
  bookingMutate = () => {},
  handleTherapyClicked = () => {},
  fullwidth = false,
  showDivider = true,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const accessToken = useAccessToken();

  const history = useHistory();

  const bookingComplete = bookingIsComplete(booking);
  const bookingCancelled = bookingIsCancelled(booking);
  const bookingStatus = getBookingStatusForBooking(booking);
  const bookingPending = bookingStatus === JobStatus.pending;
  const isBookingPending = booking?.statusFlag?.isPending;

  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { user } = useUserStore();

  const [showCancelBooking, setShowCancelBooking] = React.useState(false);

  const handleRequestCancel = () => {
    setShowCancelBooking(true);
  };

  const publishBooking = () => {
    publishBookingToAllPros(booking.id, accessToken)
      .then((response) => {
        setSuccessMessage("Booking opened to all pros");
        bookingMutate();
      })
      .catch((error) => {
        setErrorMessage("Unable to open booking to all pros");
      });
  };

  const rebookingStatusCases = ["declined", "not-responded", "abandoned"];
  /* Cancel and update button for pending and confirmed bookings */
  const showUpdateOrCancel =
    ![BOOKING_STATUS.DECLINED, BOOKING_STATUS.COMPLETED].includes(
      booking.status as BOOKING_STATUS
    ) &&
    !bookingComplete &&
    !bookingCancelled &&
    !(booking.isBookedByPro && booking.statusFlag?.isPending) &&
    !rebookingStatusCases.includes(booking.rebookingStatus) &&
    !booking.cancellationRequest;

  const assignedTherapistIds = booking.bookingdetails
    .map((detail) => (detail && detail.job ? detail.job.therapistId : null))
    .filter((id) => !isNil(id));
  const assignedTherapists = !isEmpty(assignedTherapistIds);

  const hasRebookedTherapists =
    booking.preferredTherapists &&
    getValue(booking, "preferredTherapistProfile.length", 0) &&
    !assignedTherapists;

  const preferredTherapistProfiles = getValue(booking, "preferredTherapistProfile") || [];

  const bookingUserFullName = `${getValue(booking, "user.firstName")} ${getValue(
    booking,
    "user.lastName"
  )}`;

  const bookingRecipientFullName = getValue(booking, "recipient.firstName")
    ? `${getValue(booking, "recipient.firstName")} ${getValue(booking, "recipient.lastName")}`
    : bookingUserFullName;

  const { canClientChat, shouldRespondToDirectBooking } = isChatEnableForBooking(
    booking,
    bookingStatus
  );

  return (
    <>
      <Box style={{ marginBottom: "14px" }}>
        <Box
          maxWidth={isMobile ? undefined : fullwidth ? undefined : "885px"}
          borderRadius="6px"
          border={`solid 1px ${Colors.VeryLightPink}`}
          bgcolor="#ffffff"
          flexDirection="column"
          display="flex"
          py="16px"
          px="24px"
          style={{ cursor: "pointer" }}
        >
          <Box
            onClick={onClick}
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            flex={1}
          >
            <Box display="flex" flexDirection="row" flex={1}>
              {/* Left */}
              <BookingItemDate booking={booking} />
              <Box width="2px" bgcolor={Colors.PaleLilac} />
              {/* Middle on Desktop, Right for Mobile */}
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                ml={isMobile ? "16px" : "24px"}
                mr={isMobile ? "16px" : undefined}
                flex={1}
              >
                <Box
                  fontFamily="Museo"
                  fontWeight={700}
                  fontSize="18px"
                  color={Colors.NightBlue}
                  textAlign="left"
                >
                  {getMassageDescriptionForBooking(booking)}
                </Box>
                {isMobile ? (
                  <Box
                    fontFamily="Open Sans"
                    fontSize="14px"
                    letterSpacing="0.39px"
                    color={Colors.Dusk}
                    textAlign="left"
                    mt="8px"
                    mb={1}
                    mr={2}
                  >
                    {durationAndTimeRangeForBooking(booking)}
                    {/* &nbsp;- {timeDifferenceForBooking(booking)} */}
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="row" mt="8px">
                    <Box
                      fontFamily="Open Sans"
                      fontSize="14px"
                      letterSpacing="0.39px"
                      color={Colors.Dusk}
                    >
                      {durationAndTimeRangeForBooking(booking)}
                    </Box>
                    {/* <Box
                      fontFamily="Open Sans"
                      fontSize="14px"
                      letterSpacing="0.39px"
                      color={Colors.Dusk}
                      fontWeight={600}
                    >
                      &nbsp;- {timeDifferenceForBooking(booking)}
                    </Box> */}
                  </Box>
                )}

                <StyledBox
                  marginY={Spacing.S2}
                  color={Colors.Dusk}
                  fontFamily={FontFamily.OpenSans}
                  fontSize="14px"
                  flexWrap={"wrap"}
                >
                  Booked by&nbsp;
                  <Box fontWeight={FontWeight.SemiBold}> {bookingUserFullName}</Box> &nbsp;for&nbsp;
                  <Box fontWeight={FontWeight.SemiBold}> {bookingRecipientFullName}</Box>
                </StyledBox>
              </Box>
            </Box>

            {/* Right on Desktop, Bottom for Mobile */}
            <Box
              mr={isMobile ? "0px" : "31px"}
              alignSelf="center"
              display="flex"
              flexDirection={isMobile ? "row-reverse" : "column"}
              justifyContent={isMobile ? "space-between" : undefined}
              width={isMobile ? "100%" : undefined}
              mt={isMobile ? "8px" : undefined}
              alignItems={isMobile ? "center" : undefined}
            >
              <BookingStatusPill
                status={bookingStatus}
                bookingStatus={booking.status === BOOKING_STATUS.DECLINED ? booking.status : ""}
                rebookingStatus={booking.rebookingStatus}
              />

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                mt={isMobile ? "0px" : "11px"}
              >
                {booking.bookingdetails.map((detail, index) => {
                  const url = therapistProfilePictureUrlForBookingDetail(detail);
                  const isCovidVaccinated = covidStatusForBookingDetail(detail);
                  const therapistId = Number(getValue(detail, "job.user.id"));
                  const key = `therapistProfile-${getValue(detail, "job.therapistId")}-${getValue(
                    detail,
                    "job.id"
                  )}`;
                  return url ? (
                    <Box
                      onClick={(e) => {
                        if (!isNaN(therapistId)) {
                          e.stopPropagation();
                          handleTherapyClicked(therapistId);
                        }
                      }}
                    >
                      <AvatarImageWithCovidStatus
                        key={key}
                        size={ImageSize.small}
                        isCovidVaccinated={isCovidVaccinated}
                        imageSrc={url}
                      />
                      {index < booking.bookingdetails.length - 1 && <Box width="12px" />}
                    </Box>
                  ) : (
                    <></>
                  );
                })}

                {hasRebookedTherapists && preferredTherapistProfiles ? (
                  preferredTherapistProfiles.map((therapist: any, index: number) => {
                    const key = `therapistProfile-${getValue(therapist, "id")}-${index}`;
                    const proImage = getValue(therapist, "profileImage");
                    const therapistImage = proImage
                      ? `${BASE_UPLOADS_URL}/${proImage}`
                      : ProfilePicturePlaceholder;

                    return (
                      <img
                        key={key}
                        width="30px"
                        height="30px"
                        alt="profile"
                        style={{ borderRadius: "15px", objectFit: "cover", marginLeft: "5px" }}
                        src={therapistImage}
                      />
                    );
                  })
                ) : (
                  <></>
                )}

                {preferredTherapistProfiles.length === 0 &&
                  bookingPending &&
                  !booking.isBookedByPro && (
                  <img
                    width="30px"
                    height="30px"
                    src={PlaceholderTherapistIcon}
                    alt={"therapist icon"}
                  />
                )}
              </Box>
            </Box>
          </Box>

          <RebookingAction
            booking={booking}
            buttonDirection={isMobile ? "column" : "row"}
            showHeader={true}
            bookingMutate={bookingMutate}
          />

          <BookingNotConfirmedActions
            booking={booking}
            buttonDirection={isMobile ? "column" : "row"}
            showHeader={true}
            bookingMutate={bookingMutate}
          />
          <PreferredGenderNotFoundActions
            booking={booking}
            buttonDirection={isMobile ? "column" : "row"}
            showHeader={true}
            bookingMutate={bookingMutate}
          />
          <CouplesFallbackActions
            booking={booking}
            showHeader={true}
            bookingMutate={bookingMutate}
          />
          <CancellationRequestByProActions
            booking={booking}
            onAccept={handleRequestCancel}
            onDecline={bookingMutate}
          />
          <UpdateDeclinedActions booking={booking} bookingMutate={bookingMutate} />
          <UpdateRequestActions booking={booking} bookingMutate={bookingMutate} />

          {booking.isBookedByPro && booking.statusFlag?.isPending && (
            <RebookedByProActions
              booking={booking}
              onDecline={handleRequestCancel}
              onConfirm={bookingMutate}
            />
          )}

          {!isBookingPending &&
            !(booking.status === BOOKING_STATUS.DECLINED) &&
            !booking.isActuallyPaid &&
            (!isAfterPayUnpaidBooking(booking) || booking.status !== BOOKING_STATUS.ARRANGED) && (
            <Box>
              <PaymentPending
                isMobile={isMobile}
                showPaymentHeader={true}
                booking={booking}
                bookingMutate={bookingMutate}
                showMakePaymentBtn={!isAfterPayUnpaidBooking(booking)}
              />
            </Box>
          )}
          {showDivider && <Divider mt="16px" />}

          <Box
            display={Display.Flex}
            alignItems={!isMobile ? AlignItems.start : undefined}
            flexDirection={isMobile ? FlexDirection.Column : FlexDirection.Row}
            gridGap={10}
          >
            {!bookingCancelled && (
              <>
                {booking.status === BOOKING_STATUS.DECLINED && (
                  <Box style={{ margin: "16px 0px", width: "100%" }}>
                    <Divider mb={"16px"} />
                    <PaymentDeclined isMobile={isMobile} booking={booking} />
                  </Box>
                )}

                {isAfterPayUnpaidBooking(booking) ? (
                  <Box style={{ margin: "16px 0px", width: "100%" }}>
                    <Divider mb={"16px"} />
                    <CompleteAfterpayPayment booking={booking} />
                  </Box>
                ) : (
                  <>
                    {showUpdateOrCancel && (
                      <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        <UpdateOrCancel
                          handleRequestCancel={handleRequestCancel}
                          handleUpdateRequest={onClick}
                          handleAlternativeTimes={handleAlternativeTimes}
                          handlePublishBooking={publishBooking}
                          bookingUpdates={booking.bookingUpdates}
                          bookingStatus={getValue(booking, "status")}
                          sessionType={getValue(booking, "sessionType")}
                          preferredTherapists={getValue(booking, "preferredTherapists")}
                          alternativeOffers={getValue(booking, "totalAlternativeDateOffered", 0)}
                        />
                      </Box>
                    )}
                  </>
                )}
              </>
            )}

            {canClientChat && !shouldRespondToDirectBooking && booking.conversationId && (
              <Box
                display={Display.Flex}
                justifyContent={JustifyContent.center}
                mt={isMobile ? Spacing.S2 : Spacing.S4}
              >
                <Box width={isMobile ? "100%" : "250px"} mt={"4px"}>
                  <ChatButton
                    text="Messages"
                    bookingId={booking.id}
                    conversationId={booking.conversationId}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <CancelBooking
        booking={booking}
        open={showCancelBooking}
        onConfirm={() => {
          bookingMutate();
          history.push("/bookings");
        }}
        onClose={() => setShowCancelBooking(false)}
      />
    </>
  );
}
