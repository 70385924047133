import { useMutation } from "@tanstack/react-query";
import { addReview, createUserReview, updateReview } from "../../services/review/review.service";

interface MutateParams {
  onError: (error: any) => unknown;
  onSuccess: (response: any) => unknown;
}

export const useTherapistReview = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => unknown;
  onError: (error: any) => unknown;
}) => {
  return useMutation((data: any) => addReview(data), {
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export const useUpdateTherapistReview = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => unknown;
  onError: (error: any) => unknown;
}) => {
  return useMutation((data: any) => updateReview(data), {
    onSuccess: () => {
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};

const useCreateClientReview = ({ onSuccess, onError }: MutateParams) => {
  return useMutation((
    { jobId, data }: { jobId: number | string, data: any }
  ) => createUserReview({ jobId, reviewData: data }), {
    onSuccess: (response) => {
      onSuccess(response);
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export {
  useCreateClientReview,
};
