import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Text } from "../../../v2/Styled";
import { BankIcon, Dots } from "../../../Util";
import * as Styled from "../../../v2/Styled/enum";
import { PaymentMethod } from "../../../../models";
import { getValue } from "../../../../utils/object";
import ExpiryInfo from "../../../Payment/ExpiryInfo";
import { Colors } from "../../../../constants/colors";
import TextButton from "../../../TextButton/TextButton";
import { capitalizeFirstLetter } from "../../../../helpers/string";
import { PayoutAccount } from "../../../../pages/ProDashboard/Business/Payouts/Forms/model";

interface Props {
  account?: Record<string, any>;
  details?: PaymentMethod;
  onChange?: () => unknown;
}

const PayoutAccountCard = ({ account, details, onChange }: Props): JSX.Element => {
  const type = getValue(account, "type") || getValue(details, "details.type");

  const isAccountCard = type === PayoutAccount.Card;
  const isAccountBank = type === PayoutAccount.BankAccount;

  const TBox = (props: BoxProps) => (
    <Box
      gridGap={Styled.Spacing.S2}
      display={Styled.Display.Flex}
      alignItems={Styled.AlignItems.start}
      {...props}
    />
  );

  if (isAccountCard && details) {
    return (
      <TBox>
        <TBox flex={1}>
          <Box>
            <img src={PaymentMethod.iconFor(details)} alt={PaymentMethod.toString(details)} />
          </Box>
          <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column}>
            <Text
              size={Styled.FontSize.F14}
              font={Styled.FontFamily.Museo}
              lineHeight={Styled.LineHeight.L21}
              color={Colors.Dusk}
            >
              {capitalizeFirstLetter(PaymentMethod.toString(details) || "")}
            </Text>
            <ExpiryInfo
              isExpired={false}
              expiryYear={details.expiryYear}
              expiryMonth={details.expiryMonth}
            />
          </Box>
        </TBox>
        {onChange ? (
          <Box>
            <TextButton text={"Change"} onClick={onChange} type={"primary"} />
          </Box>
        ) : (
          <></>
        )}
      </TBox>
    );
  }

  if (isAccountBank) {
    const bankName = getValue(details, "name") || "Bank account";
    let last4 = getValue(details, "last4") || "";
    const accountNumber = getValue(account, "accountNumber") || "";

    if (accountNumber) {
      last4 = accountNumber.substring(accountNumber.length - 4);
    }

    return (
      <TBox>
        <TBox flex={1}>
          <Box>
            <BankIcon />
          </Box>
          <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column}>
            <Text
              color={Colors.Dusk}
              size={Styled.FontSize.F16}
              font={Styled.FontFamily.Museo}
              weight={Styled.FontWeight.Medium}
              lineHeight={Styled.LineHeight.L24}
            >
              {bankName}
            </Text>
            <Box display={Styled.Display.Flex} gridGap={"2px"}>
              <Box display={Styled.Display.Flex} alignItems={"center"}>
                <Dots n={5} />
              </Box>
              <Text
                color={Colors.BlueyGrey}
                size={Styled.FontSize.F12}
                font={Styled.FontFamily.OpenSans}
                weight={Styled.FontWeight.SemiBold}
                lineHeight={Styled.LineHeight.L18}
              >
                {last4}
              </Text>
            </Box>
          </Box>
        </TBox>
        {onChange ? (
          <Box>
            <TextButton text={"Change"} onClick={onChange} type={"primary"} />
          </Box>
        ) : (
          <></>
        )}
      </TBox>
    );
  }

  return <></>;
};

export default PayoutAccountCard;
