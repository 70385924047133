import React from "react";
import { styled, Table } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import TermsRow from "./TermsRow";
import { Colors } from "../../../constants/colors";
import { AlreadyUploadedDocs, TermsDocumentType } from "./TermsAndPolicies";

interface TermsPolicesFileRowProps {
  attachBaseURL?: boolean;
  uploadedFilesData: AlreadyUploadedDocs[];
  docsType: TermsDocumentType[];
  removeAlreadyUploadedDocs?: (id: number) => void;
  styles?: React.CSSProperties;
}

const MTableCell = styled(TableCell)({
  borderBottom: 0,
  fontSize: "14px",
  fontWeight: 700,
  fontFamily: "Open Sans",
  lineHeight: "21px",
  color: Colors.Dusk,
  paddingBottom: "8px",
});

export default function TermsAndPoliciesUploadedFiles(props: TermsPolicesFileRowProps) {
  const { uploadedFilesData, docsType, removeAlreadyUploadedDocs, attachBaseURL } = props;
  return (
    <Table style={props.styles}>
      <TableHead>
        <TableRow>
          <MTableCell padding={"none"}>Document</MTableCell>
          <MTableCell padding={"none"}>Document type</MTableCell>
          <MTableCell padding={"none"}></MTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(uploadedFilesData || []).map((document: AlreadyUploadedDocs) => (
          <TermsRow
            attachBaseURL={attachBaseURL}
            document={document}
            documentTypes={docsType}
            handleRemoveDoc={removeAlreadyUploadedDocs}
            style={{ marginBottom: "8px" }}
          />
        ))}
      </TableBody>
    </Table>
  );
}
