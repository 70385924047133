import { Box, CircularProgress } from "@material-ui/core";
import { useState, useEffect } from "react";
import { Colors } from "../../constants/colors";
import { useAlertStore } from "../../stores/alert";
import { useSaveIdentifiers } from "../../hooks/billingIdentifier/billingIdentifier.hooks";
import { useUserConfig } from "../../hooks/userConfig/billingDetails.hooks";
import { useUserStore } from "../../stores/user";
import { getBillingIdentifierConfig } from "../../services/additionalBillingIdentifier/additionalBillingIdentifier.service";
import IndividualDetail from "../BillingDetails/IndividualDetail";

export default function AdditionalBillingIdentifier() {

  const [customerId, setCustomerId] = useState("");
  const [orderNumber, setOrderNo] = useState("");
  const [lineItemCode, setItemCode] = useState("");

  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { user } = useUserStore();
  const { isLoading: isUserConfigLoading, data: userConfigData } = useUserConfig({
    userId: user?.id,
  });

  const {paymentDetailConfig} = getBillingIdentifierConfig(user);

  const {isItemCodeReq, isNDISCustomerIdReq, isOrderCodeReq} = paymentDetailConfig();

  useEffect(() => {
    if (userConfigData && userConfigData.billingDetails) {
      const { billingDetails } = userConfigData;
      setCustomerId(billingDetails.customerId);
      setItemCode(billingDetails.lineItemCode);
      setOrderNo(billingDetails.orderNumber);
    }
  }, [userConfigData]);

  const { isLoading: loading, mutate: saveIdentifiers } = useSaveIdentifiers({
    onSuccess: () => {
      setSuccessMessage("Additional billing identifier updated successfully");
    },
    onError: () => {
      setErrorMessage("An error occurred while saving additional billing identifier");
    },
  });

  const handleSaveDetails = (slug:string, value:string)=>{
    let data: any = {};
    data.billingDetails = {
      [slug]: value
    };
    saveIdentifiers(data);
  };

  return (
    <Box mt={"36px"}>
      <Box
        fontFamily="Museo"
        fontSize="16px"
        lineHeight="24px"
        fontWeight={600}
        color={Colors.Dusk}
        mb={"12px"}
      >
        Additional billing identifiers
      </Box>
      {
        isUserConfigLoading?<>
          <Box>
            <CircularProgress
              variant="indeterminate"
              style={{ color: Colors.Dusk}}
              size="25px"
              thickness={4}
            />
          </Box>
        </>:<>
          <Box>
            {
              isNDISCustomerIdReq? <IndividualDetail
                title="NDIS customer ID"
                slug = {"customerId"}
                value = {customerId}
                onSave = {(slug, value)=>{handleSaveDetails(slug, value);}}
              />:<></>
            }
            {
              isOrderCodeReq? <IndividualDetail
                title="Purchase order number"
                slug = {"orderNumber"}
                value = {orderNumber}
                onSave = {(slug, value)=>{handleSaveDetails(slug, value);}}
              />:<></>
            }
            {
              isItemCodeReq? <IndividualDetail
                title="Line item code"
                slug = {"lineItemCode"}
                value = {lineItemCode}
                onSave = {(slug, value)=>{handleSaveDetails(slug, value);}}
              />:<></>
            }
          </Box>
        </>
      }
    </Box>
  );
}
