import { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import { getAccessToken } from "../../../../helpers/accessToken";
import { useMobile } from "../../../../hooks/mobile";
import { getProSchedules } from "../../../../services/therapist/therapist.service";
import { getEndOfDay, getStartAndEndDates, getDiffInHours, getBookingDurationInHour } from "../../../../utils/date.util";
import BookedCard from "./BookedCard";
import CalendarRow from "./CalendarRow";
import CalendarCell from "./components/CalendarCell";
import CalendarFooter from "./components/CalendarFooter";
import CalendarHeader from "./components/CalendarHeader";
import { flexDisplayStyle, getCalendarViewType, timeRanges, timeSlotWidthInPx } from "./config";
import { DayInterface, ScheduleDataInterface } from "./interface";
import { useUserStore } from "../../../../stores/user";
import CalendarInfoModal from "./CalendarInfoModal";

const Calendar = () => {
  const [selectedDates, setSelectedDates] = useState<DayInterface[]>(getStartAndEndDates("week"));
  const [scheduleData, setScheduleData] = useState<ScheduleDataInterface>({
    loading: true,
    availability: [],
    proSchedules: [],
    proBufferTime: {
      bufferTimeAfter: 0,
      bufferTimeBefore: 0,
    },
  });
  const [showCalendarInfo, setShowCalendarInfo] = useState(false);
  const [bookingInfo, setBookingInfo] = useState({
    bookingHour:0,
    bufferStart:0,
    bufferEnd:0
  });

  const openCalendarInfo = (bookingStart:string, bookingEnd:string, bufferStart:number, bufferEnd:number)=>{
    const timeDiff = getBookingDurationInHour(bookingStart, bookingEnd) || 0;
    setShowCalendarInfo(true);
    setBookingInfo({
      bookingHour:timeDiff,
      bufferStart: bufferStart,
      bufferEnd:bufferEnd
    });
  };

  const closeCalendarInfo = ()=>{
    setShowCalendarInfo(false);
  };

  const isMobile = useMobile();

  const parentDivRef = useRef<HTMLDivElement | null>(null);

  const { user, fetchMe } = useUserStore();

  const innerWidth = window?.innerWidth || 12000;
  // subtract sidebar width
  const adjustedWidth = innerWidth < 1200 ? 55 : 490;
  const baseWidthInPx =
    (window.innerWidth - timeSlotWidthInPx - adjustedWidth) / selectedDates.length;

  const accessToken = getAccessToken();

  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    const type = getCalendarViewType(isMobile);
    setSelectedDates(getStartAndEndDates(type));
  }, [isMobile]);

  useEffect(() => {
    if (!accessToken) return;
    getProAvailability();
    fetchMe();
  }, [accessToken, selectedDates]);

  const getProAvailability = () => {
    const { start, end } = getStartAndEndDate();

    getProSchedules(accessToken, {
      startDate: start.fullDate as string,
      endDate: getEndOfDay(end.fullDate as string),
    })
      .then(({ data }) => {
        setScheduleData({
          loading: false,
          proSchedules: data?.data?.proSchedules || [],
          availability: data?.data?.availability || [],
          proBufferTime: data?.data?.proBufferTime || {},
        });
      })
      .catch((error) => {
        console.error(error);
        setScheduleData({ ...scheduleData, loading: false });
      });
  };

  const getStartAndEndDate = () => {
    return { start: selectedDates[0], end: selectedDates[selectedDates.length - 1] };
  };

  const getTitle = () => {
    const { start, end } = getStartAndEndDate();

    if (isMobile) return `${start.monthName} ${start.date}`;

    return `${start.date} ${start.monthName} ${start.year} - ${end.date} ${end.monthName} ${end.year}`;
  };

  const onWeekChange = (change: number) => {
    const type = getCalendarViewType(isMobile);
    const { end } = getStartAndEndDate();
    setSelectedDates(getStartAndEndDates(type, end.fullDate, change));
  };

  return (
    <div style={{ marginBottom: "150px" }}>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="24px"
        color={Colors.Dusk}
        mt="40px"
        mb="24px"
      >
        Calendar
      </Box>

      <Box color={Colors.Dusk} mb="24px">
        <Box
          fontFamily="Museo"
          fontSize="1rem"
          fontWeight={400}
          color={Colors.Dusk}
          mt="24px"
          mb="24px"
        >
          View your upcoming bookings and availability schedule.
        </Box>
      </Box>

      {/* <CalendarConfig isIgnoreAvailabilities={false}/> */}

      <div ref={parentDivRef}>
        <CalendarHeader onWeekChange={onWeekChange} title={getTitle()} />
        <div style={flexDisplayStyle}>
          <CalendarCell
            style={{
              border: "none",
              width: `${timeSlotWidthInPx}px`,
              minWidth: `${timeSlotWidthInPx}px`,
              margin: "0 2.5px",
            }}
          />
          {selectedDates.map((day: DayInterface) => (
            <CalendarCell
              style={{ width: `${baseWidthInPx}px` }}
            >{`${day.day}, ${day.date} ${day.monthName}`}</CalendarCell>
          ))}
        </div>
        <div style={{ position: "relative", boxSizing: "border-box" }}>
          <BookedCard
            baseWidthInPx={baseWidthInPx}
            proSchedules={scheduleData.proSchedules}
            proBufferTime={scheduleData.proBufferTime}
            openCalendarInfo={openCalendarInfo}
          />
          {timeRanges.map((time, index: number) => (
            <div style={flexDisplayStyle}>
              <CalendarCell
                style={{ width: `${timeSlotWidthInPx}px`, minWidth: `${timeSlotWidthInPx}px` }}
              >
                {time.title}
              </CalendarCell>
              <CalendarRow
                rowIndex={index}
                rowLength={7}
                baseTime={time.value}
                weekDays={selectedDates}
                data={scheduleData}
                baseWidthInPx={baseWidthInPx}
                ignoreAvailabilities={user?.therapistprofile?.ignoreAvailabilities}
              />
            </div>
          ))}
        </div>
        <CalendarFooter />
      </div>
      <CalendarInfoModal show={showCalendarInfo} close={closeCalendarInfo} bookingInfo={bookingInfo} />
     
    </div>
  );
};

export default Calendar;
