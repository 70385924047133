import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { get } from "../../api/client";
import { DropdownOption } from "../../components/Dropdown";

export interface CountryOption extends DropdownOption {
  city: DropdownOption[];
}

export const useCountryOptions = () => {
  const [countries, setCountries] = useState<CountryOption[]>([]);

  const { isLoading, data } = useQuery(["country-city-options"], () => {
    return get("api/v2/utility/country-with-city");
  });

  useEffect(() => {
    setCountries(
      (data || []).map(({ name, code, city }: any) => ({
        title: name,
        value: code,
        city: (city || []).map(({ value, text }: any) => ({
          value,
          title: text,
        })),
      }))
    );
  }, [data]);

  return { countries, isLoading };
};

export const useGenderOptions = () => {
  const [genders, setGenders] = useState<DropdownOption[]>([]);

  const { isLoading, data } = useQuery(["gender-options"], () => {
    return get("api/v2/utility/genders");
  });

  useEffect(() => {
    setGenders(data ? data.genders : []);
  }, [data]);

  return { genders, isLoading };
};

export const useGenderPronounOptions = () => {
  const [pronouns, setGenders] = useState<DropdownOption[]>([]);

  const { isLoading, data } = useQuery(["pronoun-options"], () => {
    return get("api/v2/utility/gender-pronouns");
  });

  useEffect(() => {
    setGenders(data ? data.genderPronouns : []);
  }, [data]);

  return { pronouns, isLoading };
};
