import React from "react";
import braintree from "braintree-web";
import axios from "axios";
import {
  BASE_URL,
  APPLE_PAY_SUCCESS_APP_REDIRECT_URL,
} from "../../constants/common";
import { Box, Link } from "@material-ui/core";
import { isEmpty } from "lodash";
import "../../styles/ApplePay.css";
import { useLocation } from "react-router-dom";

export default function ApplePay() {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const accessToken = query.get("accessToken");
  const amount = query.get("amount");

  const [canMakeApplePayment, setCanMakeApplePayment] = React.useState(false);
  const [applePayInstance, setApplePayInstance] = React.useState<any>(null);
  const [initingApplePay, setInitingApplePay] = React.useState(false);

  React.useEffect(() => {
    if (!isEmpty(accessToken)) {
      initApplePay();
    }
  }, []);

  const initApplePay = () => {
    if (!window.ApplePaySession) {
      return;
    }

    setInitingApplePay(true);

    let path = `/paymentmethods/clientToken`;

    axios
      .get(path, {
        params: {
          accessToken,
        },
      })
      .then((response) => {
        if (response.data && response.data.clientToken) {
          let token = response.data.clientToken;

          braintree.client.create(
            {
              authorization: token,
            },
            (clientErr, clientInstance) => {
              braintree.applePay
                .create({
                  client: clientInstance,
                })
                .then((applePayInstance) => {
                  setApplePayInstance(applePayInstance);

                  console.debug(
                    "merchant id: ",
                    applePayInstance.merchantIdentifier
                  );

                  window.ApplePaySession.canMakePaymentsWithActiveCard(
                    applePayInstance.merchantIdentifier
                  )
                    .then((canMakePaymentsWithActiveCard: boolean) => {
                      console.debug(
                        "canMakePaymentsWithActiveCard: ",
                        canMakePaymentsWithActiveCard
                      );

                      setCanMakeApplePayment(canMakePaymentsWithActiveCard);

                      setInitingApplePay(false);
                    })
                    .catch((error: any) => {
                      setInitingApplePay(false);

                      console.debug(
                        "canMakePaymentsWithActiveCard error",
                        error
                      );
                    });
                });
            }
          );
        }
      });
  };

  function renderApplePayContent() {
    return (
      <div>
        Would you like to authorize a payment of ${amount} to Get Blys Pty Ltd?
        <br />
        <br />
        <br />
        {renderApplePayButton()}
      </div>
    );
  }

  function applePayButtonClicked() {
    let label = "Get Blys Pty Ltd";

    let paymentRequest = applePayInstance.createPaymentRequest({
      total: {
        label: label,
        amount,
      },

      // We recommend collecting billing address information, at minimum
      // billing postal code, and passing that billing postal code with
      // all Apple Pay transactions as a best practice.
      //requiredBillingContactFields: ['postalAddress']
    });
    console.log(paymentRequest.countryCode);
    console.log(paymentRequest.currencyCode);
    console.log(paymentRequest.merchantCapabilities);
    console.log(paymentRequest.supportedNetworks);

    let session = new window.ApplePaySession(3, paymentRequest);

    session.onvalidatemerchant = (event: any) => {
      applePayInstance
        .performValidation({
          validationURL: event.validationURL,
          displayName: label,
        })
        .then((merchantSession: any) => {
          console.debug("merchantSession:", merchantSession);

          session.completeMerchantValidation(merchantSession);
        })
        .catch((validationErr: any) => {
          // You should show an error to the user, e.g. 'Apple Pay failed to load.'
          console.error("Error validating merchant:", validationErr);
          session.abort();
        });
    };

    session.onpaymentauthorized = (event: any) => {
      console.log("Your shipping address is:", event.payment.shippingContact);

      applePayInstance
        .tokenize({
          token: event.payment.token,
        })
        .then((payload: any) => {
          // Send payload.nonce to your server.
          console.log("nonce:", payload.nonce);

          // If requested, address information is accessible in event.payment
          // and may also be sent to your server.
          // console.log('billingPostalCode:', event.payment.billingContact.postalCode);

          // After you have transacted with the payload.nonce,
          // call `completePayment` to dismiss the Apple Pay sheet.
          session.completePayment(window.ApplePaySession.STATUS_SUCCESS);

          const url = `${APPLE_PAY_SUCCESS_APP_REDIRECT_URL}?nonce=${payload.nonce}&path=apple-pay-success`;
          window.location.href = url;
        })
        .catch((tokenizeErr: any) => {
          console.error("Error tokenizing Apple Pay:", tokenizeErr);
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
        });
    };

    session.begin();
  }

  function renderApplePayButton() {
    if (canMakeApplePayment) {
      return (
        <Link
          className={"apple-pay-button-with-text"}
          style={{
            width: 300,
            height: 50,
          }}
          onClick={applePayButtonClicked}
        />
      );
    } else {
      return <div />;
    }
  }

  // REMINDER: Must be served via HTTPS to work!
  const applePaySupported = () => {
    if (!window.ApplePaySession) {
      return false;
    }

    let ApplePaySession = window.ApplePaySession;

    if (
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments()
    ) {
      // This device supports version 3 of Apple Pay.
      return true;
    }
    return false;
  };

  if (initingApplePay) {
    return (
      <Box>
        <div>Please wait...</div>
      </Box>
    );
  }

  if (!applePaySupported()) {
    return (
      <Box>
        <div>Sorry, Apple Pay is not supported on your device!</div>
      </Box>
    );
  } else if (applePaySupported() && canMakeApplePayment) {
    return <Box>{renderApplePayContent()}</Box>;
  } else if (applePaySupported() && canMakeApplePayment === false) {
    return (
      <Box>
        <div>
          You need to have at least one card linked to your Apple Wallet!
          <br />
          <br />
          Please set up Apple Pay and try again.
          <br />
          <br />
          For more info:{" "}
          <a href={"https://support.apple.com/en-au/HT204506"}>
            https://support.apple.com/en-au/HT204506
          </a>
          <br />
        </div>
      </Box>
    );
  } else {
    return (
      <Box>
        <div>Please wait...</div>
      </Box>
    );
  }
}
