import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Box } from "../v2/Styled";
import { Colors } from "../../constants/colors";
import { FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../v2/Styled/enum";
import { ThemeProvider, createTheme } from "@mui/material/styles";

interface GenderToggleInterface {
  gender: string;
  setGender: (value: string) => void;
}


const theme = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: Colors.TurquoiseBlue,
            color: Colors.White, // Text color for the selected option
            "&:hover": {
              backgroundColor: Colors.TurquoiseBlue,
            },
          },
          "&:hover": {
            backgroundColor: "#0DAEC830",
          },
          textTransform: "none",
          fontSize: FontSize.F16,
          fontFamily: FontFamily.Museo,
          color: Colors.Grey,
        },
      },
    },
  },
});


const GenderToggle = ({ gender, setGender }: GenderToggleInterface) => {

  const handleAlignment = (event: any, newAlignment: any) => {
    setGender(newAlignment);
  };

  return (
    <Box direction={FlexDirection.Column} marginY={Spacing.S2} gap={Spacing.S4}>
      <Box fontFamily={FontFamily.Museo} fontSize="14px" color={Colors.Dusk} fontWeight={700}>
        Gender
      </Box>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup
          value={gender}
          exclusive
          onChange={handleAlignment}
          aria-label="gender"
          fullWidth={true}
          color={"secondary"}
          sx={{ borderRadius: "8px" }}
        >
          <ToggleButton value="any" aria-label="any">
            Any
          </ToggleButton>
          <ToggleButton value="male" aria-label="male">
            Male
          </ToggleButton>
          <ToggleButton value="female" aria-label="female">
            Female
          </ToggleButton>
        </ToggleButtonGroup >

      </ThemeProvider >
    </Box>
  );
};

export default GenderToggle;

