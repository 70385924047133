import React from "react";
import { Box } from "@material-ui/core";
import StripeConnect from "./StripeConnect";
import OldBankInfoForm from "./OldBankInfoForm";
import { getValue } from "../../../../utils/object";
import { useUserStore } from "../../../../stores/user";
import { PAYOUT_PROVIDER } from "../../../../data/enums";
import * as Styled from "../../../../components/v2/Styled/enum";

interface Props {
  bankingInfoConfig: Record<string, any>;
}

const Payouts = ({ bankingInfoConfig }: Props): JSX.Element => {
  const { user } = useUserStore();

  const therapistProfile = getValue(user, "therapistprofile");
  const payoutProvider = getValue(user, "payoutProvider");

  const isStripePayoutProvider = payoutProvider === PAYOUT_PROVIDER.STRIPE;

  if (!therapistProfile || !payoutProvider) return <></>;

  return (
    <Box maxWidth={"632px"} marginY={Styled.Spacing.S14}>
      <Box
        gridGap={Styled.Spacing.S6}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
      >
        {isStripePayoutProvider ? (
          <StripeConnect bankingInfoConfig={bankingInfoConfig} />
        ) : (
          <OldBankInfoForm bankingInfoConfig={bankingInfoConfig} />
        )}
      </Box>
    </Box>
  );
};

export default Payouts;
