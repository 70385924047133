const styles = {
  topDeco: {
    top: 0,
    right: 0,
    height: '110px',
    position: 'absolute' as 'absolute',
  },
  bottomDeco: {
    left: 0,
    bottom: 0,
    zIndex: -1,
    height: '110px',
    position: 'fixed' as 'fixed',
  }
};

export default styles;