import { Colors } from "../../../constants/colors";
import { Box, Text } from "../../v2/Styled";
import { FlexDirection, FontFamily, FontWeight, Spacing } from "../../v2/Styled/enum";
import Each from "../../Each";

const ReferInstructions = ({ referBonus = "", forClientUser = false }) => {
  const referSteps = forClientUser
    ? [
      "Share your unique referral code below",
      "When they go to sign up to Blys, ask them to add your unique code to the 'Referral code' section at the bottom of the signup form",
      `${referBonus} Blys store credit will be added to your Blys account balance once they’ve completed their first 3 bookings`,
    ]
    : [
      "Share your unique referral code below",
      "When they go to sign up to Blys, ask them to add your unique code to the 'Referral code' section at the bottom of the signup form",
      "The cash bonus will be added to your account balance once they’ve completed their first 3 bookings",
    ];
  const description = forClientUser
    ? [
      "Not a provider yourself but know someone who is? Refer them to Blys and earn a referral fee.",
      `Every time you refer a provider to Blys, you’ll receive a ${referBonus} Blys store credit after they’ve completed their first 3 bookings.`,
    ]
    : [
      `Every time you refer a fellow provider to Blys, you’ll receive a ${referBonus} cash bonus after they’ve completed their first 3 bookings.`,
    ];

  const buildInstruction = (step: string) => (
    <li style={{ fontFamily: "Museo", color: Colors.Dusk }}>
      <Box marginY={Spacing.S2}>
        <Text font={FontFamily.Museo} weight={FontWeight.Medium}>
          {step}
        </Text>
      </Box>
    </li>
  );

  return (
    <Box direction={FlexDirection.Column} gap={Spacing.S4}>
      <Box direction={FlexDirection.Column} gap={Spacing.S4}>
        {description.map((text, idx) => (
          <Text font={FontFamily.Museo} weight={FontWeight.Medium} key={idx}>
            {text}
          </Text>
        ))}
      </Box>
      <Box direction={FlexDirection.Column}>
        <Box>
          <Text font={FontFamily.Museo} weight={FontWeight.Bold}>
            How to refer a provider
          </Text>
        </Box>
        <Box>
          <ol style={{ margin: 0 }}>
            <Each of={referSteps} render={buildInstruction} />
          </ol>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferInstructions;
