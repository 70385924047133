import { TimeInterface } from "./interface";

export const WEEK_VIEW = "week";
export const DAY_VIEW = "mobile";

export const getCalendarViewType = (isMobile: boolean) => {
  return isMobile ? DAY_VIEW : WEEK_VIEW;
};

const timeSlabInHour = 1;
const dateSlabInDay = 1;

const timeRanges: TimeInterface[] = [
  {
    title: "6am",
    value: "6",
  },
  {
    title: "7am",
    value: "7",
  },
  {
    title: "8am",
    value: "8",
  },
  {
    title: "9am",
    value: "9",
  },
  {
    title: "10am",
    value: "10",
  },
  {
    title: "11am",
    value: "11",
  },
  {
    title: "12pm",
    value: "12",
  },
  {
    title: "1pm",
    value: "13",
  },
  {
    title: "2pm",
    value: "14",
  },
  {
    title: "3pm",
    value: "15",
  },
  {
    title: "4pm",
    value: "16",
  },
  {
    title: "5pm",
    value: "17",
  },
  {
    title: "6pm",
    value: "18",
  },
  {
    title: "7pm",
    value: "19",
  },
  {
    title: "8pm",
    value: "20",
  },
  {
    title: "9pm",
    value: "21",
  },
  {
    title: "10pm",
    value: "22",
  },
  {
    title: "11pm",
    value: "23",
  },
  {
    title: "12am",
    value: "24",
  },
];

const baseWidthInPx = 100;
const baseHeightInPx = 30;
const borderWidthInPx = 1;
const cellMarginInPx = 1.7;
const cellSpaceInPx = borderWidthInPx + cellMarginInPx;
const timeSlotWidthInPx = 54;

const getBaseStyle = () => {
  return {
    width: `${baseWidthInPx}px`,
    height: `${30}px`,
    border: "1px solid #C5CBD6",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `${cellMarginInPx}px`,
  };
};

const flexDisplayStyle = {
  display: "flex",
};

export {
  timeSlabInHour,
  dateSlabInDay,
  timeRanges,
  flexDisplayStyle,
  baseWidthInPx,
  baseHeightInPx,
  borderWidthInPx,
  cellSpaceInPx,
  timeSlotWidthInPx,
  getBaseStyle,
};
