import Box from "@material-ui/core/Box";

import { CircularProgress } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { useCancellationFeeForFutureBookings } from "../../../hooks/booking/bookingCancellation.hooks";
import { useMobile } from "../../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../../Button";
import Dialog from "../../Dialog";
import { Display, JustifyContent } from "../../v2/Styled/enum";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onConfirm: () => unknown;
  bookingId: number;
}

export default function ConfirmFutureBookingCancellation({
  open,
  onClose,
  onConfirm,
  bookingId,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const { isLoading, cancellationWarningMessage } = useCancellationFeeForFutureBookings(bookingId);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile} style={{ overflowY: "visible" }}>
      <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="start"
        >
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="21px"
            color={Colors.NightBlue}
            textAlign="center"
            width="100%"
          >
            Future bookings that are already placed will be cancelled
          </Box>

          <>
            <Box
              fontFamily="Open Sans"
              fontSize="18px"
              color={Colors.BlueyGrey}
              mt="16px"
              width="100%"
              display={Display.Flex}
              justifyContent={JustifyContent.center}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Box textAlign="center">{cancellationWarningMessage}</Box>
              )}
            </Box>

            <Box style={styles.actionWrapper} flexDirection="row">
              <Button
                height={48}
                type={ButtonType.outlined}
                size={ButtonSize.small}
                title="Cancel"
                onClick={onClose}
                style={{ marginRight: "12px" }}
              />
              <Button
                height={48}
                type={ButtonType.secondary}
                size={ButtonSize.small}
                title="Confirm"
                onClick={onConfirm}
              />
            </Box>
          </>
        </Box>
      </Box>
    </Dialog>
  );
}

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "24px",
  },
};
