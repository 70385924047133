import React from "react";
import { Box } from "@material-ui/core";
import { checkIfEmpty } from "../../../utils/object";

function CovidStatus({ booking, style = {} }: any) {
  if (!booking) return null;

  const recipient = booking?.recipient || booking?.user;
  if (!recipient || checkIfEmpty(recipient) || !recipient.isCovidVaccinated) {
    return <></>;
  }

  return (
    <Box style={{ ...styles.normalText, ...style }}>I'm fully vaccinated for COVID-19</Box>
  );
}

export default CovidStatus;

const styles = {
  normalText: {
    fontFamily: "Open Sans",
    fontSize: 16,
  },
};
