import { AFTERPAY_SCRIPT_SRC } from "../constants/common";

export function loadScript(src: string) {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  // script.onload = () => this.scriptLoaded();

  document.body.appendChild(script);
}

export function loadAfterPayScript() {
  loadScript(AFTERPAY_SCRIPT_SRC);
}
