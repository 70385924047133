const MAP_API_KEY = "AIzaSyACuJC8JggcSNUcthDOkyO5ITLDV6JCBsY";

class MapApiService {
  scriptLoading: boolean | null = null;
  scriptLoaded = false;

  loadScripts() {
    // controls multiple map loads for couples/group booking
    if (this.scriptLoading && !this.scriptLoaded) {
      return new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (this.scriptLoaded) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    }

    if (this.scriptLoading === null) {
      this.scriptLoading = true;
    }

    if (this.scriptLoaded) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places&language=en`;
      script.onload = () => {
        this.scriptLoaded = true;
        this.scriptLoading = null;
        resolve();
      };
      script.onerror = () => {
        this.scriptLoaded = false;
        this.scriptLoading = null;
        document.body.removeChild(script);
        console.error("could not load google maps api");
      };
      document.body.appendChild(script);
    });
  }

  async getPlaces() {
    await this.loadScripts();
    return new google.maps.places.PlacesService(document.createElement("div"));
  }

  async getAutoComplete() {
    await this.loadScripts();
    return new google.maps.places.AutocompleteService();
  }

  async getGeocoder() {
    await this.loadScripts();
    return new google.maps.Geocoder();
  }

  async getMap(element: HTMLElement, position: any) {
    await this.loadScripts();
    return new google.maps.Map(element, {
      center: position,
      zoom: 15,
    });
  }

  // async getTravelDistance(
  //   origin:any,
  //   destination: any,
  //   travelMode: google.maps.TravelMode
  // ) {
  //   await this.loadScripts();
  //   const directionService = new google.maps.DirectionsService();

  //   directionService.route(
  //     {
  //       origin,
  //       destination,
  //       travelMode,
  //     },
  //     (response, status) => {
  //       if (status === google.maps.DirectionsStatus.OK) {
  //         return response;
  //       } else {
  //         console.error("Error calculating directions:", status);
  //       }
  //     }
  //   );
  // }
}

export default new MapApiService();
