import React, { useState } from "react";
import { ButtonBase } from "@mui/material";
import { Box, DialogActions, DialogContent, Typography } from "@material-ui/core";
import Dialog from "../../Dialog";
import { styles } from "./styles";
import TextField from "../../TextField";
import Button, { ButtonType } from "../../Button";
import LeftIcon from "../../../images/24_carat-left.png";
import { useAlertStore } from "../../../stores/alert";

interface Props {
  open: boolean;
  title: string;
  onClose: () => unknown;
  onConfirm: (text: string) => unknown;
  validateInput?: (text: string) => boolean;
  validationErrorMessage?: string;
}

const InputModal = ({
  open,
  title,
  onClose,
  onConfirm,
  validateInput,
  validationErrorMessage,
}: Props) => {
  const { setErrorMessage } = useAlertStore();
  const [input, setInput] = useState<string>("");
  const handleFieldChange = (value: string) => {
    setInput(value);
  };

  const handleConfirm = () => {
    if (validateInput) {
      const isValid = validateInput(input);
      if (!isValid) {
        setErrorMessage(validationErrorMessage || "Something went wrong");
        return;
      }
    }
    onConfirm(input);
    setInput("");
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
      <Box style={styles.wrapper}>
        <Box style={styles.titleWrapper}>
          <ButtonBase style={styles.closeWrapper} onClick={onClose}>
            <img src={LeftIcon} alt={"Close"} style={styles.closeBtn} />
          </ButtonBase>
          <Typography style={styles.title}>{title}</Typography>
        </Box>
        <DialogContent style={styles.contentWrapper}>
          <TextField value={input} placeholder={"Enter url"} onChange={handleFieldChange} />
        </DialogContent>
        <DialogActions style={styles.footerWrapper}>
          <Button title={"Add"} width={"120px"} type={ButtonType.indigo} onClick={handleConfirm} />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default InputModal;
