import React from "react";
import Dialog from "../../Dialog";
import { Box } from "@material-ui/core";
import Button, { ButtonType, ButtonSize } from "../../Button";
import { Colors } from "../../../constants/colors";
import { useMobile } from "../../../hooks/mobile";

interface Props {
  open: boolean;
  timeSlotLabel: string | null;
  onClose: () => unknown;
  onConfirm: () => unknown;
  recipientName: string;
}

const ReleaseSlotModal = ({
  open,
  timeSlotLabel,
  onClose,
  onConfirm,
  recipientName = "",
}: Props): JSX.Element => {
  const isMobile = useMobile();
  return (
    <Dialog open={open} fullWidth={isMobile}>
      <Box
        padding={"48px"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gridGap={"16px"}
        maxWidth={isMobile ? "100%" : "352px"}
      >
        <Box
          fontFamily={"Museo"}
          fontSize={"22px"}
          lineHeight={"110%"}
          fontWeight={600}
          color={Colors.NightBlue}
        >
          Confirm timeslot
        </Box>
        <Box
          fontFamily={"Open Sans"}
          fontSize={"16px"}
          fontWeight={400}
          lineHeight={"150%"}
          color={Colors.Grey}
          textAlign={"center"}
        >
          {`Would you like to remove ${recipientName} from ${timeSlotLabel} timeslot?`}
        </Box>

        <Box display={"flex"} gridGap={"16px"} flexGrow={1} width="100%" marginTop={"16px"}>
          <Button
            type={ButtonType.outlined}
            size={ButtonSize.large}
            title="Cancel"
            onClick={onClose}
          />
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.large}
            title="Confirm"
            onClick={onConfirm}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ReleaseSlotModal;
