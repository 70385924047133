import { Box } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import { Service } from "../../models";

interface Props {
  service: Service;
}

export default function SkillPill({ service }: Props): JSX.Element {
  if (!service) {
    return <></>;
  }

  const getServiceName = () => {
    if (service.alias) {
      return service?.alias;
    } else {
      const name = service?.name;

      switch (name) {
        case "swedish":
          return "Swedish / Relaxation";
        case "deeptissue":
          return "Remedial / Deep Tissue";
        case "pregnancy":
          return "Pregnancy";
        case "sports":
          return "Sports";
        case "corporate":
          return "Corporate";
        case "reflexology":
          return "Reflexology";
        case "aromatherapy":
          return "Aromatherapy";
        case "lymphatic-drainage":
          return "Lymphatic Drainage";
        case "hot-stone":
          return "Hot Stone";
        case "scalp-head-massage":
          return "Scalp / Head Massage";
        default:
          return name ? name.charAt(0).toUpperCase() + name.slice(1) : null;
      }
    }
  };

  return (
    <Box
      bgcolor="#faf1eb"
      height="38px"
      paddingLeft="16px"
      paddingRight="16px"
      borderRadius="40px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt="4px"
      mb="4px"
      ml="2px"
      mr="2px"
    >
      <Box
        fontFamily="Museo"
        fontWeight={700}
        fontSize="16px"
        color={Colors.Dusk}
      >
        {getServiceName()}
      </Box>
    </Box>
  );
}
