import { useState, useEffect } from 'react';

const useShareSheet = () => {
  const [isSupported, setSupported] = useState(false);

  useEffect(() => {
    if (!!navigator.share) {
      setSupported(true);
    } else {
      setSupported(false);
    }
  }, [setSupported]);

  return {
    isSupported,
    share: ({ title, url, text }: { title: string, url: string, text: string }, callback?: any) => {
      if (isSupported) {
        navigator.share({
          title,
          url,
          text
        }).then(() => {
          if (callback) {
            callback(null, true);
          }
        }).catch((error) => {
          console.log('Error sharing', error);
          if (callback) {
            callback(error, false);
          }
        });
      }
    }
  };
}

export default useShareSheet;