import { Box } from "@material-ui/core";
import { AxiosResponse } from "axios";
import React from "react";
import { Colors } from "../../../../constants/colors";
import { parseApiError } from "../../../../helpers/error";
import { useAccessToken } from "../../../../hooks/common";
import { useMobile } from "../../../../hooks/mobile";
import { UserService } from "../../../../models";
import { addCustomService } from "../../../../services/therapist/proTreatment.service";
import { useAlertStore } from "../../../../stores/alert";
import Button, { ButtonType } from "../../../Button";
import TextField from "../../../TextField";

interface Props {
  onAdded: (services: UserService[]) => unknown;
}

const AddCustomServiceForm = ({ onAdded }: Props) => {
  const isMobile = useMobile();
  const accessToken = useAccessToken();
  const [newModalityName, setNewModalityName] = React.useState<string>("");

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const handleAddCustomService = () => {
    if (!newModalityName) return;

    addCustomService(newModalityName, accessToken || "")
      .then((response: AxiosResponse) => {
        onAdded(response.data);
        setSuccessMessage("Added new custom skill");
        setNewModalityName("");
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <>
      <Box
        fontFamily="Museo"
        fontSize="14px"
        fontWeight={600}
        color={Colors.Dusk}
        mt="32px"
      >
        Add skill, treatment or service
      </Box>

      <Box display="flex" flexDirection="row" mb="32px">
        <TextField
          placeholder="Enter name"
          value={newModalityName}
          onChange={(text) => setNewModalityName(text)}
          width={isMobile ? "100%" : "364px"}
        />
        <Box mt={2.5} ml="24px">
          <Button
            title="Add"
            type={ButtonType.outlined}
            width="79px"
            onClick={handleAddCustomService}
          />
        </Box>
      </Box>
    </>
  );
};

export default AddCustomServiceForm;
