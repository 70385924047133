import { formatDateWithTimezone } from "../../../utils/date.util";
import DateTimeIcon from "../../../images/review-calendar-time.svg";
import { Colors } from "../../../constants/colors";

const UpdatedTimeOfArrival = ({ timeOfArrival, timezone }: any) => {
  const getFormattedArrivalTime = () => {
    const datetime = formatDateWithTimezone(timeOfArrival, timezone, "ddd, D MMM h:mma");
    return datetime;
  };

  const arrivalTime = getFormattedArrivalTime();

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
      <div>
        <img src={DateTimeIcon} alt="Time of arrival" />
      </div>
      <div style={styles.timeOfArrivalLabel}>{arrivalTime}</div>
    </div>
  );
};

const styles = {
  timeOfArrivalLabel: {
    lineHeight: "21px",
    color: Colors.Indigo,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 600,
  },
};

export default UpdatedTimeOfArrival;
