import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import { BlockedClient } from "../../models";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../constants/date";
import Button, { ButtonSize, ButtonType } from "../Button";
import queryClient from "../../config/queryClient";
import { useUnblockClientWithId } from "../../hooks/client/blockClient.hooks";
import { PayoutKeys } from "../../hooks/payout/payout";
import { useAlertStore } from "../../stores/alert";

const Title = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="14px"
      lineHeight="21px"
      fontWeight={600}
      color={Colors.BlueyGrey}
      {...props}
    />
  );
};

const Value = (props: BoxProps) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      mt="4px"
      {...props}
    />
  );
};

interface Props {
  blockedClient: BlockedClient;
  revalidate:any,
}




export default function ({ blockedClient,revalidate }: Props) {
  const isMobile = useMobile();
  // const { user } = useUserStore();
const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { mutate: unblockClient, isLoading } = useUnblockClientWithId();
  
  const handleClientBlock = (e: Event, clientId: number) => {
    e.stopPropagation();
      unblockClient(
        { clientId },
        {
          onSuccess: () => {
            setSuccessMessage("Client unblocked.");
            revalidate();
            queryClient.invalidateQueries({ queryKey: [PayoutKeys.PAYOUTS] })
          },
          onError: () => {
            setErrorMessage("Something went wrong! Please try again later.");
          },
        }
      );
  };

  return (
    <Box
      bgcolor="white"
      borderRadius="8px"
      border="1px solid #DCDFE6"
      padding="24px"
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      mt="8px"
      mb="8px"
    >
      <Box display="flex" flexDirection="column" maxWidth="150px" flex={1}>
        <Title>Date</Title>
        <Value>
          {moment(blockedClient.createdAt).format(DEFAULT_DATE_FORMAT)}
        </Value>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        mt={isMobile ? "24px" : undefined}
        maxWidth="150px"
        flex={1}
      >
        <Title>Recipient</Title>
        <Value>{blockedClient.firstName}</Value>
      </Box>

      <Box display="flex" flexDirection="column" flex={1} mt={isMobile ? "24px" : undefined}>
        <Title>Reason</Title>
        <Value>{blockedClient.reason}</Value>
      </Box>
      <Box display="flex" flexDirection="column" flex={1} mt={isMobile ? "8px" : undefined} alignItems={'end'}>
      <Button
                  height={40}
                  width={80}
                  type={ButtonType.outlined}
                  size={ButtonSize.small}
                  title={`Unblock`}
                  loading={isLoading}
                  onClick={(e) => {
                    handleClientBlock(e, blockedClient.userId);
                  }}
                />
      </Box>
    </Box>
  );
}
