import { styled } from "@mui/system";
import { Box as MUIBox } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import { Display, FlexDirection, Spacing } from "../enum";

interface Props {
  gap?: Spacing;
  paddingX?: Spacing;
  paddingY?: Spacing;
  marginX?: Spacing;
  marginY?: Spacing;
  display?: Display;
  direction?: FlexDirection;
  backgroundColor?: Colors;
}

const styles = ({
  gap,
  paddingX,
  paddingY,
  marginX,
  marginY,
  display,
  direction,
  backgroundColor,
}: Props) => ({
  gap,
  display,
  backgroundColor,
  flexDirection: direction,
  padding: `${paddingY} ${paddingX}`,
  margin: `${marginY} ${marginX}`,
});

const Box = styled(MUIBox, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "style", // Prevent using custom style from props
})<Props>(styles);

Box.defaultProps = {
  gap: Spacing.S0,
  paddingX: Spacing.S0,
  paddingY: Spacing.S0,
  marginX: Spacing.S0,
  marginY: Spacing.S0,
  display: Display.Flex,
  direction: FlexDirection.Row,
  backgroundColor: Colors.Transparent,
};

export default Box;
