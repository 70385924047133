import { Box, ButtonBase, Link, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../../../constants/colors";

interface StyledButtonProps {
  selected?: boolean;
}

interface Props {
  onSelect: () => unknown;
}

const StyledButton = withStyles({
  root: ({ selected }: StyledButtonProps) => ({
    backgroundColor: selected ? "#EBF8FA" : undefined,
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    textAlign: "left",
    "&:hover": {
      backgroundColor: selected ? "#EBF8FA" : "#ebf8fa4d",
    },
  }),
})(ButtonBase);

const style = {
  fontFamily: "Open Sans",
};

const SelectOther = ({ onSelect }: Props) => {
  return (
    <React.Fragment>
      <StyledButton onClick={onSelect}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gridGap={15} alignItems="center">
              <Box>
                <Typography style={{ ...style, fontSize: "16px", fontWeight: 700 }}>
                  Other
                </Typography>
                <Typography
                  style={{ ...style, fontSize: "13px", fontWeight: 400, color: "#41506F" }}
                >
                  Add treatment add-ons as you and your client wish to.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledButton>
      <Box
        style={{
          height: "1px",
          backgroundColor: Colors.LightPeriwinkle,
          margin: "0 16px",
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(SelectOther);
