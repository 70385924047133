import moment from "moment";
import { last } from "lodash";
import { Box, Link } from "@material-ui/core";

import TextLink from "../TextLink";
import { Colors } from "../../constants/colors";
import FileIcon from "../../images/file-icon.svg";
import { BASE_UPLOADS_URL } from "../../constants/common";
import {
  IGovermentDoumentsResponse,
  IOption,
} from "../../pages/ProDashboard/Business/identity.interface";
import { getFormatRespectToCountry } from "../../utils/date.util";

interface GovernmentFileRowProps {
  options: IOption[];
  uploadedFilesData: IGovermentDoumentsResponse[];
  onRemove: (governmentDocumentId: number, fileId: number) => unknown;
  country?: string;
}

const FileTableHeader = ({ title, ...props }: any) => (
  <Box
    fontSize="14px"
    fontWeight={700}
    lineHeight="21px"
    color={Colors.Dusk}
    fontFamily="Open Sans"
    {...props}
  >
    {title}
  </Box>
);

export default function GovernmentUploadedFiles({
  uploadedFilesData,
  onRemove,
  options,
  country = "AU",
}: GovernmentFileRowProps) {
  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between" mt="24px">
        <table width="100%">
          <tr>
            <td style={{ minWidth: 70 }}>
              <FileTableHeader title="Document" />
            </td>
            <td>
              <FileTableHeader title="Document ID" />
            </td>
            <td>
              <FileTableHeader title="Document type" />
            </td>
            <td>
              <FileTableHeader title="Expiry date" />
            </td>
            <td></td>
          </tr>
          {uploadedFilesData.map((documents: IGovermentDoumentsResponse) =>
            documents?.details?.files.map((file) => (
              <tr key={file.id}>
                <td>
                  <Box display="flex" alignItems="center">
                    <img src={FileIcon} />
                    <Link
                      style={{ color: Colors.Dusk, cursor: "pointer" }}
                      onClick={() => {
                        window.open(`${BASE_UPLOADS_URL}/${file.filePath}`, "_blank");
                      }}
                    >
                      <Box
                        fontFamily="Open Sans"
                        fontWeight={400}
                        fontSize="14px"
                        lineHeight="21px"
                      >
                        {last(file.filePath.split("/"))}
                      </Box>
                    </Link>
                  </Box>
                </td>

                <td>
                  <Box
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="21px"
                    fontFamily="Open Sans"
                    color={Colors.Dusk}
                  >
                    {documents?.documentNumber}
                  </Box>
                </td>
                <td>
                  <Box
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="21px"
                    fontFamily="Open Sans"
                    color={Colors.Dusk}
                  >
                    {
                      options.find((option: IOption) => option.value === documents?.documentType)
                        ?.title
                    }
                  </Box>
                </td>
                <td>
                  <Box
                    fontSize="14px"
                    fontWeight={400}
                    lineHeight="21px"
                    fontFamily="Open Sans"
                    color={Colors.Dusk}
                  >
                    {documents?.expiresAt &&
                      moment(documents.expiresAt).format(getFormatRespectToCountry(country))}
                  </Box>
                </td>
                <td>
                  <TextLink
                    title="Remove"
                    destructive
                    onClick={() => onRemove(documents.id, file.id)}
                  />
                </td>
              </tr>
            ))
          )}
        </table>
      </Box>
    </Box>
  );
}
