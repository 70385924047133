import { CompleteAfterPayButton } from "@get-blys/ui.complete-after-pay-button";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonSize, ButtonType } from "../Button";
import { AlignItems, Display, FlexDirection, JustifyContent, Spacing } from "../v2/Styled/enum";
import { useState } from "react";
import { Booking } from "../../models";
import PaymentModal from "../PaymentFrame/PaymentModal";
import { BOOKING_STATUS } from "../../constants/booking";

export default function CompleteAfterpayPayment({ booking }: { booking: Booking }) {
  const isMobile = useMobile();
  const history = useHistory();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const navigateToAfterpay = () => {
    history.push(`/afterpay-process-order/${booking.id}`, {
      autoPay: true,
    });
  };

  return (
    <Box>
      {booking.status !== BOOKING_STATUS.COMPLETED && (
        <Box
          fontFamily={"Museo"}
          fontWeight={700}
          color="#DC3545"
          paddingBottom={isMobile ? "16px" : "24px"}
          fontSize={isMobile ? "18px" : "20px"}
        >
          Complete booking payment
        </Box>
      )}
      <Box
        display={Display.Flex}
        justifyContent={JustifyContent.spaceBetween}
        alignItems={AlignItems.center}
        gridGap={Spacing.S3}
        flexDirection={isMobile ? FlexDirection.Column : FlexDirection.Row}
      >
        <Box width={"100%"}>
          <CompleteAfterPayButton isMobile={false} onPress={() => navigateToAfterpay()} />
        </Box>
        <Button
          onClick={() => {
            setShowPaymentModal(true);
          }}
          title="Change payment method"
          type={ButtonType.outlined}
          size={ButtonSize.large}
        />
      </Box>
      <PaymentModal
        booking={booking}
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        hidePayWithInvoice={false}
        hidePayWithNDIS={false}
        showInvoiceLink={false}
      />
    </Box>
  );
}
