import React from "react";
import { TableCell, TableRow, makeStyles } from "@material-ui/core";
import { CommunicationMediumType } from "./PreferencesTable";
import { Colors } from "../../constants/colors";
import PreferenceDropdown from "./preferenceDropdown";
import PreferencesTitle from "./title";
import { UserCommunicationMediums, UserCommunicationNameMap } from "../../constants/preferences";
interface TableRowDesktopProps {
  data: {
    title: string;
    value: string;
    subtitle?: string;
    medium: CommunicationMediumType;
  };
}

const TableRowDesktop: React.FC<TableRowDesktopProps> = ({ data: item }) => {
  const classes = useStyles();

  let mediums = UserCommunicationMediums.map((med) => ({
    //@ts-ignore
    name: UserCommunicationNameMap[med],
    ...item.medium[med],
  }));

  return (
    <TableRow key={`user-lg-${item.title}`}>
      <TableCell className={classes.cell} component="th" scope="row">
        <PreferencesTitle subtitle={item.subtitle} title={item.title} />
      </TableCell>
      {mediums.map((medium, index) => (
        <TableCell key={`pref-cell-${index}`} className={classes.cell} component="th" scope="row">
          <PreferenceDropdown
            type={item.value}
            medium={UserCommunicationMediums[index]}
            value={medium.value}
            options={medium.options}
            disabled={medium.isDisabled}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};
const useStyles = makeStyles({
  cell: {
    fontFamily: "Museo",
    fontSize: "16px",
    color: Colors.Dusk,
    // borderBottom: "0px",
    paddingLeft: "0px",
  },
  tableRow: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    fontFamily: "Open Sans",
    fontSize: "16px",
    font: "Museo",
    fontWeight: 700,
    color: "#41506F",
    padding: "0px 10px",
    paddingLeft: "20px",
  },
});

export default TableRowDesktop;
