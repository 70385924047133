export function withVersion(props?: any) {
  return {
    version: "Web App v2",
    ...props,
  };
}

export function withVersionInNumber(props?: any) {
  return {
    version: 2,
    ...props,
  };
}
