import { checkIfEmpty } from "../../../utils/object";
import { DEFAULT_CHANGEOVER } from "../../../data/corporate.options";

export const getDurationPerPersonOption = (config: any = {}, treatment: string = "*") => {
  if (checkIfEmpty(config)) return [];

  const options = config[treatment] || {};
  return options.durationPerPersonOptions || [];
};

export const getChangeOverTimeForTreatment = (config: any = {}, treatment: string = "*") => {
  if (checkIfEmpty(config)) return DEFAULT_CHANGEOVER;

  const options = config[treatment] || {};
  return options.changeOverTime || DEFAULT_CHANGEOVER;
};