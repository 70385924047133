import { Box, BoxProps } from "@material-ui/core";
import React from "react";
import Title from "../Util/Title";
import Value from "../Util/Value";

interface Props extends BoxProps {
  isVaccinated: boolean;
}

const CovidStatus: React.FC<Props> = (props) => {
  if (!props.isVaccinated) return null;
  return (
    <Box display="flex" flexDirection="column" {...props}>
      <Title>Vaccination Status</Title>
      <Value>Vaccinated for COVID-19</Value>
    </Box>
  );
};

export default CovidStatus;
