import { Box, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import AddressAutocomplete from "../../components/AddressAutocomplete";
import Button, { ButtonType } from "../../components/Button";
import TherapistItem from "../../components/Public/ProDirectory/TherapistItem";
import Wrapper from "../../components/Public/Wrapper";
import { Colors } from "../../constants/colors";
import { GeocodeAddress, placeDetails } from "../../helpers/google";
import { useMobile } from "../../hooks/mobile";
import {
  ProDirectoryTherapist,
  ProDirectoryTherapistResponse,
} from "../../models";
import { useHistory } from "react-router-dom";
import { Paths } from "../../constants/paths";

interface Params {
  location?: string;
}

export default function ProDirectory() {
  const isMobile = useMobile();
  const history = useHistory();

  const { location } = useParams<Params>();

  const [address, setAddress] = React.useState("");
  const [lat, setLat] = React.useState<number | undefined>(undefined);
  const [long, setLong] = React.useState<number | undefined>(undefined);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [therapists, setTherapists] = React.useState<ProDirectoryTherapist[]>(
    []
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!isNil(lat) && !isNil(long)) {
      setCurrentPage(1);

      axios
        .get("/therapists/practitioners", {
          params: {
            latitude: lat,
            longitude: long,
            radius: 50000,
            perPage: 20,
            currentPage: 1,
          },
        })
        .then((response) => {
          const therapistsResponse =
            response.data as ProDirectoryTherapistResponse;
          setTherapists(therapistsResponse.therapistList);
        });
    }
  }, [lat, long]);

  // Handle locations in URL
  React.useEffect(() => {
    if (location) {
      GeocodeAddress(location.replace("-", " ")).then((results) => {
        if (!isEmpty(results)) {
          const result = results[0];
          const { formatted_address, place_id } = result;

          setAddress(formatted_address);

          placeDetails(place_id).then((result) => {
            const lat = result.geometry?.location && result.geometry?.location.lat();
            const long = result.geometry?.location && result.geometry?.location.lng();

            if (!isNil(lat) && !isNil(long)) {
              setLat(lat);
              setLong(long);
            }
          });
        }
      });
    }
  }, [location]);

  return (
    <Wrapper display="flex" flexDirection="column" alignItems="center">
      <Box
        maxWidth={isMobile ? "88vw" : "856px"}
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Box
          fontFamily="Museo"
          fontWeight={600}
          fontSize="36px"
          color="#301E79"
          mt="40px"
          mb="30px"
          ml="24px"
          mr="24px"
          textAlign="center"
        >
          {isEmpty(therapists)
            ? "Book the best practitioners that come to you"
            : `The best massage therapists in ${address}`}
        </Box>

        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          color="#301E79"
          textAlign="center"
        >
          {isEmpty(therapists)
            ? "Blys brings the best massage therapist to your door. Fully qualified, experienced massage therapist, vetted by our team to deliver a great treatment."
            : "All massage therapists are fully qualified, experienced, have police checks and insurance. Professional mobile massage therapists bring everything to ensure you have the best treatments in the comfort of your home, hotel or office."}
        </Box>

        <Box width={isMobile ? "100%" : "636px"} mb="24px" mt="12px">
          <AddressAutocomplete
            value={address}
            onChange={(text) => setAddress(text)}
            onSelectedAddress={(address) => {
              const selectedAddress = `${address.suburb} ${address.state} ${address.country}`;

              setAddress(selectedAddress);

              const addressPathToken = selectedAddress
                .replace(/[^A-Z0-9]+/gi, "-")
                .toLowerCase();

              history.push(`${Paths.PublicTherapists}/${addressPathToken}`);
            }}
            title="Search practitioners"
            placeholder="Enter a suburb or address"
          />
        </Box>
      </Box>

      <Box
        maxWidth={isMobile ? "100%" : "1350px"}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
      >
        {therapists.map((therapist: ProDirectoryTherapist) => (
          <TherapistItem therapist={therapist} />
        ))}
      </Box>

      <Box mt="56px" mb="56px">
        {loading && (
          <CircularProgress
            style={{ color: Colors.NightBlue, margin: "42px" }}
          />
        )}

        {!loading && !isEmpty(therapists) && (
          <Button
            type={ButtonType.secondary}
            height={48}
            width="131px"
            title="Load more"
            onClick={() => {
              if (loading) {
                return;
              }

              const nextPage = currentPage + 1;

              setLoading(true);
              setCurrentPage(nextPage);

              axios
                .get("/therapists/practitioners", {
                  params: {
                    latitude: lat,
                    longitude: long,
                    radius: 50000,
                    perPage: 20,
                    currentPage: nextPage,
                  },
                })
                .then((response) => {
                  setLoading(false);
                  const therapistsResponse =
                    response.data as ProDirectoryTherapistResponse;
                  setTherapists(
                    therapists.concat(therapistsResponse.therapistList)
                  );
                })
                .catch((error) => {
                  setLoading(false);
                });
            }}
          />
        )}
      </Box>
    </Wrapper>
  );
}
