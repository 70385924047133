import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Colors } from "../constants/colors";

const Divider = (props: BoxProps) => {
  return (
    <Box
      height="1px"
      width="100%"
      bgcolor={Colors.PaleLilac}
      {...props}
    />
  );
};

export default Divider;
