import { Box, CircularProgress, styled } from "@material-ui/core";

import { styles } from "./styles";
import { Colors } from "../../../constants/colors";
import { useAlertStore } from "../../../stores/alert";
import TermsAndPoliciesAddbutton from "./TermsAndPoliciesAddbutton";
import TermsAndPoliciesUploadedFiles from "./TermsPoliciesUploadFiles";

import {
  useDocumentTypeOptions,
  useExistingUserDocuments,
  useRemoveDocument,
} from "../../../hooks/userTerms.hook";

interface Props {
  accessToken: string | null;
  termsPolicies: {
    showTermsPolicy: boolean;
    allowUploadDocument: boolean;
  };
}

export interface TermsDocumentType {
  title: string;
  value: string;
}

export interface AlreadyUploadedDocs {
  createdAt: string;
  filePath: string;
  id: number;
  type: string;
  name?: string;
  contentType: string;
  updatedAt: string;
  userId: number;
}

const BoxColumn = styled(Box)({
  flexDirection: "column",
});

const TermsAndPolicies = (props: Props) => {
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const onComplete = {
    remove: (success: boolean) => {
      if (success) {
        setSuccessMessage("Your terms and policies have been removed.");
      } else {
        setErrorMessage("Error occur while removing document.");
      }
    },
  };

  const { isLoading: isDocumentOptionsLoading, data: documentOptions } = useDocumentTypeOptions();
  const { isLoading: isExistingDocsLoading, data: existingDocs } = useExistingUserDocuments();
  const { isLoading: isRemoving, mutate: removeTermsDocument } = useRemoveDocument(
    onComplete.remove
  );

  const handleRemoveDocs = (documentId: number) => {
    if (!isRemoving) {
      removeTermsDocument(documentId);
    }
  };

  if (isDocumentOptionsLoading || isExistingDocsLoading) {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <CircularProgress
          variant="indeterminate"
          style={{ color: Colors.Dusk }}
          size="25px"
          thickness={4}
        />
      </Box>
    );
  }

  return (
    <BoxColumn style={styles.wrapper}>
      <BoxColumn style={styles.block}>
        <BoxColumn style={styles.blockWrapper}>
          <Box style={styles.title}>Your documents</Box>
          <Box style={{ ...styles.description, ...styles.dusk }}>
            PDF, JPG and PNG files accepted.
          </Box>
        </BoxColumn>

        {(existingDocs || []).length > 0 ? (
          <TermsAndPoliciesUploadedFiles
            uploadedFilesData={existingDocs}
            docsType={documentOptions}
            removeAlreadyUploadedDocs={handleRemoveDocs}
            styles={{ marginTop: "16px" }}
          />
        ) : (
          <Box style={{ ...styles.description, ...styles.grey }}>No documents uploaded</Box>
        )}
      </BoxColumn>
      <TermsAndPoliciesAddbutton isTermsDocument />
    </BoxColumn>
  );
};

export default TermsAndPolicies;
