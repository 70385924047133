import moment from "moment";
import { DropdownOption } from "../../../components/Dropdown";
import {
  BookingRecurringMonthOptionType,
  RecurringType,
  recurringTypeOptions,
  weekDayPositions,
} from "../../../constants/booking";
import { getDayOfMonth, getWeekOfMonth } from "../../../utils/date.util";

interface RecurringOptionParams {
  isRepeating?: boolean;
  frequency?: number | null;
}

interface FrequencyOptionParams {
  type?: RecurringType | null;
}

interface MonthlyOptionParams {
  selectedDate?: Date | null;
}

interface OccurrenceOptionParams {
  maxOccurrence?: number;
}

export const useRecurringTypeOptions = ({
  isRepeating,
  frequency,
}: RecurringOptionParams): Array<DropdownOption> => {
  let options = recurringTypeOptions;

  if (isRepeating) {
    options = recurringTypeOptions.map(({ value, title }) => ({
      value,
      title: `${title}${(frequency || 0) > 1 ? "s" : ""}`,
    }));
  }

  return options;
};

export const useFrequencyOptions = ({ type }: FrequencyOptionParams): Array<DropdownOption> => {
  let maxFrequency = 12;
  if (type === RecurringType.DAY) {
    maxFrequency = 6;
  } else if (type === RecurringType.MONTH) {
    maxFrequency = 4;
  }

  return Array.from({ length: maxFrequency }, (_, i) => ({
    value: i + 1,
    title: `${i + 1}`,
  }));
};

export const useMonthlyOptions = ({ selectedDate }: MonthlyOptionParams): Array<DropdownOption> => {
  if (!selectedDate) return [];

  const weekOfMonth = getWeekOfMonth(selectedDate);

  const monthTitle = `On the ${
    weekDayPositions[weekOfMonth - 1] || weekDayPositions[weekDayPositions.length]
  } ${moment(selectedDate).format("dddd")}`;

  const dayOfMonth = getDayOfMonth(selectedDate);
  const dayTitle = `On day ${dayOfMonth}`;

  return [
    {
      value: BookingRecurringMonthOptionType.DAY_OF_MONTH,
      title: dayTitle,
    },
    {
      value: BookingRecurringMonthOptionType.WEEK_DAY_OF_MONTH,
      title: monthTitle,
    },
  ];
};

export const useOccurrenceOptions = ({
  maxOccurrence = 20,
}: OccurrenceOptionParams): Array<DropdownOption> => {
  return Array.from({ length: maxOccurrence }, (_, i) => ({
    value: i + 1,
    title: `${i + 1}`,
  }));
};
