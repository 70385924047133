import { Snackbar as MUISnackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React from "react";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export enum SnackbarType {
  error = "error",
  warning = "warning",
  info = "info",
  success = "success",
}

interface Props {
  type: SnackbarType;
  open: boolean;
  message?: string | null | undefined | React.ReactNode;
  onClose: () => unknown;
}

export default function Snackbar({ type, open, message, onClose }: Props): JSX.Element {
  return (
    <MUISnackbar
      style={{ maxWidth: "700px" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </MUISnackbar>
  );
}
