interface Coordinates {
  latitude: number;
  longitude: number;
}

export const MAX_ZOOM_LEVEL = 16;
export const MAX_ALLOWED_ZOOM_LEVEL = 20;
export const MIN_INITIAL_ZOOM_LEVEL = 13;

export const openMap = ({
  location,
  isDirection = false,
}: {
  location: string | Coordinates;
  isDirection: boolean;
}) => {
  let url = "https://www.google.com/maps/";
  let search = "";

  if (typeof location === "object" && "latitude" in location && "longitude" in location) {
    const { latitude, longitude } = location as Coordinates;
    search = `${latitude},${longitude}`;
  } else {
    search = `${encodeURIComponent(location as string)}`;
  }

  url += isDirection ? `dir/?api=1&destination=${search}&travelmode=driving` : `?q=${search}`;

  window.open(url, "_blank");
};

export const getMapConfig = (config: any, country = "AU") => {
  const localStorageCountry = localStorage.getItem("countryCode");
  const countryConfig =
    config[country] || (localStorageCountry && config[localStorageCountry]) || config["AU"];
  return countryConfig.proDashboard.serviceArea;
};

export const drawArea = ({ areaInfo, map, google, options }: any) => {
  if (google && map) {
    new google.maps.Circle({
      map,
      center: areaInfo.coords,
      radius: areaInfo.radius,
      ...options,
    });
  }
};

export const drawMarker = ({ coords, map, google, icon, options }: any) => {
  if (google && map) {
    new google.maps.Marker({
      position: coords,
      map,
      icon,
    });
  }
};
