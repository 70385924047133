import axios from "axios";
import { BASE_URL } from "../constants/common";
import { ENDPOINTS } from "../constants/endpoints";
import { getAccessToken, getAdminAccessToken } from "../helpers/accessToken";
import { parseApiError } from "../helpers/error";
import { getCountryCode } from "../utils/country";

interface ErorrMsg {
  customErrMsg?: Boolean;
}

axios.interceptors.request.use(
  async (config) => {
    const adminToken = getAdminAccessToken();
    if (adminToken) {
      config.headers["X-ADMIN-TOKEN"] = adminToken;
    }

    // do not overwrite country if already exists
    const skipEndpoints = [ENDPOINTS.COUNTRY_CODE_API, ENDPOINTS.GET_SERVICES];
    if (
      !skipEndpoints.some((endpoint) => config?.url?.includes(endpoint)) &&
      !config?.params?.country
    ) {
      config.params = { ...config.params, country: await getCountryCode() };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

interface HTTPGetOptions {
  signal?: AbortSignal;
}

const get = async (
  url: string,
  params: object = {},
  includeToken = true,
  options?: HTTPGetOptions
) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "",
  };

  const requestParams: { accessToken: string | null } = {
    ...params,
    accessToken: "",
  };

  if (includeToken) {
    const token = getAccessToken();
    headers.Authorization = `Bearer ${token}`;

    requestParams.accessToken = token;
  }

  const fullUrl = `${BASE_URL}/${url}`;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const promise = axios
    .get(fullUrl, {
      headers,
      params: requestParams,
      cancelToken: source.token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorMessage = parseApiError(error);
      throw Error(errorMessage);
    });

  options?.signal?.addEventListener("abort", () => {
    source.cancel();
  });

  return promise;
};

const post = async (
  url: string,
  body: {},
  includeToken = true,
  headers: {
    Authorization?: string;
    "X-SESSION"?: string | null;
    "Content-Type"?: string | null;
  } = {}
) => {
  let fullUrl = `${BASE_URL}/${url}`;
  let params = {};

  if (includeToken) {
    const token = getAccessToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
      params = { ...params, accessToken: token };
    }
  }

  return axios
    .post(fullUrl, body, {
      headers,
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      if (error?.response?.data?.error?.message || error?.response?.data?.errorMessage) {
        throw error;
      } else {
        throw error.message;
      }
    });
};

const deleteApi = async (url: string, includeToken = true) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "",
  };

  const params: { accessToken: string | null } = { accessToken: "" };

  if (includeToken) {
    const token = getAccessToken();
    headers.Authorization = `Bearer ${token}`;
    params.accessToken = token;
  }

  const fullUrl = `${BASE_URL}/${url}`;

  return axios
    .delete(fullUrl, {
      headers,
      params,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.message;
    });
};

const put = async (url: string, body: {}, params?: {}) => {
  const token = getAccessToken();

  const fullUrl = `${BASE_URL}/${url}?accessToken=${token}`;
  return axios
    .put(fullUrl, body, {
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw Error(parseApiError(error));
    });
};

export { deleteApi, get, post, put };
