const TRICKY_NUMBER_PATTERN_AU = ["zerofour", "04", "zero4", "0four"];
const TRICKY_NUMBER_PATTERN_US = ["nineseven", "9seven", "nine7", "97"];
const noOfPreviousMessagesForPhoneNumber = 8;
// constant to pick previous message within 30 minutes
const previousMessageDiffInMinutes = 30;

const blockedKeywordsForChat = [
  "cash",
  "snapchat",
  "insta",
  "your number",
  "your phone number",
  "book privately",
  "booking privately",
  "private book",
  "private booking",
  "privately book",
  "whatsapp",
  "viber",
  "linkedin",
  "skype",
  "my website",
];

export {
  TRICKY_NUMBER_PATTERN_AU,
  TRICKY_NUMBER_PATTERN_US,
  noOfPreviousMessagesForPhoneNumber,
  previousMessageDiffInMinutes,
  blockedKeywordsForChat,
};
