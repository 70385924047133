import React from "react";
import { Box } from "@material-ui/core";
import LogoLink from "../LogoLink";
import UserIconLink from "./UserIconLink";
import HeaderLink, { headerLinkTypes } from "./HeaderLink";
import Button, { ButtonType, ButtonSize } from "../../components/Button";
import { useMobile } from "../../hooks/mobile";

export default function Header() {
  const isMobile = useMobile();

  return (
    <Box
      height="82px"
      pl={isMobile ? "28px" : "52px"}
      pr={isMobile ? "28px" : "52px"}
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <LogoLink />

      <Box display="flex" flexDirection="row" alignItems="center">
        {!isMobile && headerLinkTypes.map((type) => <HeaderLink type={type} />)}
        {!isMobile && (
          <Box ml="20px">
            <Button
              title="Book a Session"
              type={ButtonType.secondary}
              height={42}
              width="153px"
              onClick={() =>
                window.location.assign(
                  "https://app.getblys.com.au/new-booking/"
                )
              }
            />
          </Box>
        )}

        <Box ml="49px">
          <UserIconLink />
        </Box>
      </Box>
    </Box>
  );
}
