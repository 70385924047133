import { AxiosError } from "axios";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { User } from "../../../models";
import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import { STRIPE_PUBLISHABLE_KEY_FOR_CONNECT } from "../../../constants/common";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import { stripeTransformer } from "../../../services/proDashboard/transformer";
import { checkAndGetUsersStripeCountry } from "../../../services/countries/countries.services";
import {
  getPayoutDetails,
  getAccountStatus,
  createAccountLink,
  updatePayoutDetails,
  createAccountSession,
  getOnboardingFormOptions,
  getAccountRequirements,
  uploadAdditionalInfo,
} from "../../../services/proDashboard/stripe.service";

export const STRIPE_KEYS = {
  ACCOUNT: "ACCOUNT",
  ACCOUNT_LINK: "ACCOUNT_LINK",
  ACCOUNT_STATUS: "ACCOUNT_STATUS",
  UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
  ACCOUNT_SESSION: "ACCOUNT_SESSION",
  ACCOUNT_REQUIREMENTS: "ACCOUNT_REQUIREMENTS",
  ACCOUNT_ONBOARDING_FORM_OPTION: "ACCOUNT_ONBOARDING_FORM_OPTION",
} as const;

interface StripeConnectProps {
  account?: {
    accountId?: string;
  };
  user: User | null;
}

const useStripeAccountLink = (userId: number | undefined) =>
  useQuery([STRIPE_KEYS.ACCOUNT_LINK, { userId }], () =>
    createAccountLink().then(({ data }) => data)
  );

const useAccountStatus = () =>
  useQuery([STRIPE_KEYS.ACCOUNT_STATUS], getAccountStatus, { refetchOnWindowFocus: true });

const getAccountSessionSecret = async () => {
  try {
    const session = await createAccountSession();
    return getValue(session, "client_secret", "");
  } catch (err) {
    sendErrorLog({
      domain: "WEB",
      channel: LOG_CHANNEL.SLACK,
      log: {
        title: "WEB: Stripe connect custom account session issue",
        data: { message: err.message },
      },
    });
    return "";
  }
};

const useStripeConnect = ({
  account,
  user,
}: StripeConnectProps): StripeConnectInstance | undefined => {
  const userCountry = getValue(user, "country");
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance>();
  const stripeCountry = checkAndGetUsersStripeCountry(user, userCountry);
  const publishableKey = STRIPE_PUBLISHABLE_KEY_FOR_CONNECT(stripeCountry);

  useEffect(() => {
    if (account?.accountId) {
      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey,
          fetchClientSecret: getAccountSessionSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: Colors.NightBlue,
              colorText: Colors.Dusk,
              colorDanger: Colors.Danger,
              buttonPrimaryColorBackground: Colors.NightBlue,
            },
          },
        })
      );
    }
  }, [account?.accountId]);

  return stripeConnectInstance;
};

const useStripeOnboardingOptions = () =>
  useQuery([STRIPE_KEYS.ACCOUNT_ONBOARDING_FORM_OPTION], getOnboardingFormOptions);

const useStripePayoutDetails = (transform = true) =>
  useQuery([STRIPE_KEYS.ACCOUNT], getPayoutDetails, {
    select: (data) =>
      transform ? stripeTransformer.transformStripePayoutDetailsResponse(data) : data,
  });

const useUpdateStripeAccount = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: (data: { success: boolean; message: string }) => unknown;
  onError?: (e: AxiosError) => unknown;
}) =>
  useMutation(updatePayoutDetails, {
    onSuccess: (data: { success: boolean; message: string }) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (e: AxiosError) => {
      if (onError) {
        onError(e);
      }
    },
  });

const useStripeAccountRequirements = () =>
  useQuery([STRIPE_KEYS.ACCOUNT_REQUIREMENTS], () => getAccountRequirements(), {
    refetchOnWindowFocus: true,
  });

const useStripeSubmitAdditionalRequirements = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => unknown;
  onError?: (e: AxiosError) => unknown;
}) =>
  useMutation(uploadAdditionalInfo, {
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (e: AxiosError) => {
      if (onError) {
        onError(e);
      }
    },
  });

export {
  useStripeConnect,
  useAccountStatus,
  useStripeAccountLink,
  useStripePayoutDetails,
  useUpdateStripeAccount,
  useStripeOnboardingOptions,
  useStripeAccountRequirements,
  useStripeSubmitAdditionalRequirements,
};
