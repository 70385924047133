import React, { useCallback } from "react";
import { QUESTION_TYPE } from "../../../constants/questionType";
import { BookingQuestion } from "../../../models";
import {
  getAnswerForQuestion,
  setAnswerForQuestion,
  useServicesStore,
} from "../../../stores/booking";
import ImageQuestion from "./ImageQuestion";
import SelectQuestion from "./SelectQuestion";
import { DEFAULT_CURRENCY } from "../../../constants/booking";

interface Props {
  questions: BookingQuestion[];
  treatmentId?: number;
  index: number;
  currency?: string;
  onAnswerSelected?: () => unknown;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const Questions = ({
  questions,
  treatmentId,
  index,
  style,
  currency = DEFAULT_CURRENCY.currencySymbol,
  disabled = false,
}: Props) => {
  const { answers } = useServicesStore();

  const answerForQuestion = (questionId: number) => {
    const answer = getAnswerForQuestion(questionId, index, treatmentId);
    return answer;
  };

  const handleAnswerSelected = useCallback(
    (questionId: number, answer: string, type: string) => {
      setAnswerForQuestion({
        questionId,
        answer,
        answers: null,
        treatmentIndex: index,
        treatmentId,
        type,
      });
    },
    [index, treatmentId]
  );

  const onImageUploadSuccess = (data: any, questionId: number, type = QUESTION_TYPE.IMAGE) => {
    const {
      id,
      filePath,
      type: imageType,
    } = data.filter(({ type }: any) => (type ? type === `question-${questionId}` : true)).at(-1);

    const imageData = { id, filePath, type: imageType };

    const targetAnswer = answers[index]?.find(
      (answer: any) => answer.questionId === questionId && answer.type === QUESTION_TYPE.IMAGE
    );
    let images: any[] = [];
    if (targetAnswer) {
      images = targetAnswer.answers ? [...targetAnswer.answers] : [];
    }

    images.push(imageData);

    setAnswerForQuestion({
      questionId,
      answer: null,
      answers: images,
      treatmentIndex: index,
      treatmentId,
      type,
    });
  };

  const onImageDeleteSuccess = (imageId: number, questionId: number) => {
    const tempAnswers = [...answers];
    const targetAnswer = tempAnswers[index].find(
      (answer: any) => answer.questionId === questionId && answer.type === QUESTION_TYPE.IMAGE
    );
    targetAnswer.answers = targetAnswer.answers.filter(({ id }: { id: number }) => id !== imageId);
    useServicesStore.setState({
      answers: tempAnswers,
    });
  };

  return (
    <React.Fragment>
      {questions.map((question) => {
        if (question.treatmentId && question.treatmentId !== treatmentId) {
          return null;
        }
        switch (question.type) {
        case QUESTION_TYPE.IMAGE:
          return (
            <ImageQuestion
              style={style}
              key={`question-${question.id}`}
              question={question}
              treatmentIndex={index}
              uploadSuccessHandler={onImageUploadSuccess}
              removeSuccessHandler={onImageDeleteSuccess}
              disabled={disabled}
            />
          );
        case QUESTION_TYPE.SELECT:
        case QUESTION_TYPE.DURATION:
        default:
          return (
            <SelectQuestion
              style={style}
              key={`question-${question.id}`}
              question={question}
              showPrices={question.isAddOns}
              answer={answerForQuestion(question.id)}
              onAnswerSelected={handleAnswerSelected}
              currency={currency}
              disabled={disabled}
            />
          );
        }
      })}
    </React.Fragment>
  );
};

export default Questions;
