import useSWR from "swr";
import { useRef } from "react";
import { Box } from "@material-ui/core";

import { UploadedFile } from "../../../models";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import { deleteApi, post } from "../../../api/client";
import { useAlertStore } from "../../../stores/alert";
import { parseApiError } from "../../../helpers/error";
import { useAccessToken } from "../../../hooks/common";
import Button, { ButtonType } from "../../../components/Button";
import UploadedFileRow from "../../../components/ProDashboard/UploadedFileRow";

interface UploadIdentityDocumentsProps {
  fileOwnership: string;
}

export default function UploadDocuments({ fileOwnership }: UploadIdentityDocumentsProps) {
  const fileUploadRef = useRef<HTMLInputElement>(null);

  const { user } = useUserStore();
  const accessToken = useAccessToken();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { data: uploadedFilesData, mutate: uploadedFilesMutate } = useSWR(
    `therapists/me/files?accessToken=${accessToken}`
  );

  const uploadedFiles = uploadedFilesData
    ? (uploadedFilesData as UploadedFile[]).filter((file) => file.type === fileOwnership)
    : [];

  return (
    <Box>
      <Box
        mb='32px'
        mt='32px'
        fontSize='18px'
        fontWeight={600}
        lineHeight='27px'
        fontFamily='Museo'
        color={Colors.Dusk}>
        Upload documentation
      </Box>
      <Box
        mt="8px"
        fontSize="14px"
        fontWeight={400}
        lineHeight="21px"
        fontFamily="Open Sans"
        color={Colors.Dusk}
      >
        PDF, JPG and PNG files accepted. We recommend using a scanning app when photographing
        documents.
      </Box>

      {uploadedFiles.map((uploadedFile) => (
        <UploadedFileRow
          file={uploadedFile}
          onRemove={() => {
            user &&
              deleteApi(`therapists/${uploadedFile.id}/file?therapistId=${user.id}`)
                .then((response) => {
                  uploadedFilesMutate();
                  setSuccessMessage("This file has been deleted");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
          }}
        />
      ))}

      <Box mt='37px' mb='32px'>
        <Button
          title='Upload file'
          type={ButtonType.outlined}
          width='132px'
          onClick={() => {
            if (fileUploadRef.current) {
              fileUploadRef.current.click();
            }
          }}
        />
      </Box>

      <Box>
        <input
          type='file'
          ref={fileUploadRef}
          style={{ display: "none" }}
          id={`${fileOwnership}-upload-file`}
          onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();

            const file = event?.target?.files && event.target.files[0];

            if (file) {
              const form = new FormData();
              form.append("image", file);

              user &&
                post(`therapists/${user.id}/file?fileOwnership=${fileOwnership}`, form, true, {
                  "Content-Type": "multipart/form-data",
                })
                  .then((response) => {
                    uploadedFilesMutate();
                    setSuccessMessage("This file has been uploaded");
                  })
                  .catch((error) => {
                    setErrorMessage(parseApiError(error));
                  });
            }
          }}
        />
      </Box>
    </Box>
  );
}
