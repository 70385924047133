import { Box, Typography } from "@material-ui/core";
import React from "react";
import Button from "../components/Button";
import SectionPageHeader from "../components/SectionPageHeader";
import Wrapper from "../components/Wrapper";
import { Links } from "../constants/links";
import { TrackingEvents } from "../constants/tracking";
import { withVersion } from "../helpers/tracking";
import { useMobile } from "../hooks/mobile";
import { trackEvent } from "../services/segment/track.service";

export default function (): JSX.Element {
  const isMobile = useMobile();

  return (
    <>
      <Wrapper header={<SectionPageHeader title="Coming soon" />}>
        <Box
          ml={isMobile ? "24px" : "80px"}
          mr={isMobile ? "24px" : "80px"}
          mt={isMobile ? "24px" : "43px"}
          mb="24px"
        >
          <Typography style={{ marginTop: "15px" }}>
            Blys Plus, our upcoming monthly membership program.
          </Typography>
          <Typography style={{ marginTop: "15px" }}>
            Members-only discounts and benefits on every booking. It's your
            monthly dose of wellness.
          </Typography>
          <Typography style={{ marginTop: "15px", marginBottom: "30px" }}>
            Join the waitlist now for early access.
          </Typography>

          <Button
            width={isMobile ? "100%" : "300px"}
            title="Join Waitlist Now"
            onClick={() => {
              trackEvent(
                TrackingEvents.JoinWaitlistClicked,
                withVersion()
              );

              window.open(Links.JoinTheWaitingList, "_blank");
            }}
          />
        </Box>
      </Wrapper>
    </>
  );
}
