import { useMutation } from "@tanstack/react-query";
import { post } from "../../api/client";

const makeOffer = (body: { jobId: any; timeOfArrival: any }) => {
  const URL = "api/v2/bookings/alternative-offer";
  return post(URL, body);
};

const updateAlternateOffer = (body: { jobId: any; timeOfArrival: any }, offerId: number) => {
  const URL = `api/v2/bookings/alternative-offer/${offerId}`;
  return post(URL, body);
};

const useGetStandardRate = () => {
  return useMutation({
    mutationFn: ({ jobId, body }: { body: any; jobId: string }) =>
      post(`api/v2/jobs/${jobId}/servicefee`, body),
  });
};

export { makeOffer, updateAlternateOffer, useGetStandardRate };
