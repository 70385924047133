import React from "react";
import TextWithLink from "../Text/TextWithLink/TextWithLink";
import NoItemsPlaceholder from "../ProDashboard/NoItemsPlaceholder";

interface AccountSuspendedMessageProps {
  message: string;
}

const SERVICE_AND_GUIDELINES_LINK = "https://getblys.com.au/terms/software-licence-agreement/";

const ErrorMessageView: React.FC<AccountSuspendedMessageProps> = ({ message }) => {
  let errorMessage = message;  
  const serviceAndCommunityGuidelinesString = "Service & Community Guidelines.";
  
  // Check if service and guidelines string exists
  const serviceAndGuideLinesStringExist = errorMessage.includes(serviceAndCommunityGuidelinesString);

  if (serviceAndGuideLinesStringExist) {
    errorMessage = message.replace(serviceAndCommunityGuidelinesString, "  ").trim() + "\u00A0"; // Added space
  }

  return (
    <>
      <NoItemsPlaceholder>{errorMessage}</NoItemsPlaceholder>
      {
        serviceAndGuideLinesStringExist && ( // Use && instead of ternary for better readability
          <TextWithLink
            linkText={serviceAndCommunityGuidelinesString}
            link={SERVICE_AND_GUIDELINES_LINK}
            linkStyle={{
              fontFamily: "Museo",
            }}
          />
        )
      }  
    </>
  );
};

export default ErrorMessageView;
