import React from "react";
import { Box } from "@material-ui/core";
import { useMobile } from "../../../../../hooks/mobile";
import { Colors } from "../../../../../constants/colors";
import * as Styled from "../../../../../components/v2/Styled/enum";
import Button, { ButtonType } from "../../../../../components/Button";

interface Props {
  isForUpdate: boolean;
  continueText?: string;
  style?: React.CSSProperties;
  onContinue: (e: MouseEvent) => unknown;
  onPrevious?: (e: MouseEvent) => unknown;
  onSaveAndExit?: (e: MouseEvent) => unknown;
}

const FormFooter = ({
  style = {},
  onContinue,
  onPrevious,
  isForUpdate,
  continueText,
  onSaveAndExit,
}: Props): JSX.Element => {
  const isMobile = useMobile();
  const saveText = "Continue";
  const backText = isForUpdate ? "Back" : "Previous";

  return (
    <Box
      flex={1}
      style={style}
      gridGap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      alignItems={Styled.AlignItems.center}
      justifyContent={Styled.JustifyContent.spaceBetween}
      flexDirection={isMobile ? Styled.FlexDirection.Column : Styled.FlexDirection.Row}
    >
      {onPrevious ? (
        <Box
          display={Styled.Display.Flex}
          width={isMobile ? "100%" : "auto"}
          style={isMobile ? { flex: 1 } : {}}
        >
          <Button
            disableRipple
            title={backText}
            onClick={onPrevious}
            type={ButtonType.outlinedWhite}
            fontStyle={{
              color: Colors.Indigo,
              textDecoration: "underline",
              fontSize: Styled.FontSize.F18,
              fontWeight: Styled.FontWeight.Bold,
              fontFamily: Styled.FontFamily.Museo,
            }}
            style={{
              justifyContent: "flex-start",
            }}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box
        flex={1}
        gridGap={Styled.Spacing.S6}
        display={Styled.Display.Flex}
        width={isMobile ? "100%" : "auto"}
        justifyContent={Styled.JustifyContent.end}
        flexDirection={isMobile ? Styled.FlexDirection.Column : Styled.FlexDirection.Row}
      >
        {onSaveAndExit && !isForUpdate ? (
          <Box>
            <Button
              title={"Save & exit"}
              onClick={onSaveAndExit}
              type={ButtonType.outlined}
              width={isMobile ? "100%" : "auto"}
              style={{ padding: `${Styled.Spacing.S3} ${Styled.Spacing.S8}` }}
            />
          </Box>
        ) : (
          <></>
        )}
        <Box>
          <Button
            onClick={onContinue}
            title={continueText || saveText}
            style={{ padding: `${Styled.Spacing.S3} ${Styled.Spacing.S8}` }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FormFooter;
