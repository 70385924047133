import { Box } from "@material-ui/core";
import TextLink from "../../components/TextLink";
import { GenderPreference, SessionType } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { CORPORATE_BOOKING_KEYS } from "../../data/corporate.options";
import {
  bookingFrequencyCaption,
  bookingIsConfirmed,
  checkIfJobAccepted,
  frequencyTitleForBooking,
  getDateForBooking,
  getTimeRangeForBooking,
} from "../../helpers/booking";
import { isCorporateSession, isMassageForMassageType } from "../../helpers/massage";
import { capitalizeFirstLetter } from "../../helpers/string";
import { formatMinutesToDisplayTime } from "../../helpers/time";
import BookingFrequencyIcon from "../../images/booking-recurrency.png";
import RecipientIcon from "../../images/one-person-outline.svg";
import TimeIcon from "../../images/review-calendar-time.svg";
import LocationIcon from "../../images/review-item-location.svg";
import { Booking } from "../../models";
import { transformCorporateForDescription } from "../../stores/V2/booking/booking.transformer";
import {
  getMassageDurationFromBooking,
  getTreatmentDetailByTreatmentId,
  getTreatmentDetailsIcon,
  isBackToBackMassageType,
  isCoupleMassageType,
} from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty, getValue } from "../../utils/object";
import { useHistory } from "react-router-dom";

export enum BookingDetailsOrderItemType {
  massage,
  recipient,
  location,
  time,
  frequency,
}

interface Props {
  booking: Booking;
  type: BookingDetailsOrderItemType;
  onChangeClicked?: () => unknown;
}

export default function BookingDetailsOrderItem({
  booking,
  type,
  onChangeClicked,
}: Props): JSX.Element {
  const { user } = useUserStore();
  const history = useHistory();

  const {
    massageDuration,
    sessionType,
    massageFor,
    recipient,
    address,
    isHairAndMakeup,
    bookingdetails,
    corporateInfo,
    recurring,
  } = booking;
  const massageType = bookingdetails[0]?.massageType;
  const treatmentDetails = bookingdetails[0]?.treatmentDetails || [];

  const genderPreference1 = bookingdetails[0]?.genderPreference;
  const genderPreference2 = bookingdetails[1]?.genderPreference;

  const isMassage = isMassageForMassageType(massageType);
  const isBackToBackMassage = isBackToBackMassageType(sessionType);
  const isCouplesMassage = isCoupleMassageType(sessionType);
  const isCorporateType = isCorporateSession(sessionType);

  // get service duration from treatment details for b2b
  // and for other massage type use it from booking details
  // default to massageDuration
  const [length1, length2] = getMassageDurationFromBooking(sessionType, bookingdetails);
  let serviceDuration: string = `${massageDuration}min`;
  if (isMassage) {
    if (isBackToBackMassage || isCouplesMassage) {
      serviceDuration = `${length1}min + ${length2}min`;
    } else {
      serviceDuration = `${length1}min`;
    }
  }

  const isBookingConfirmed = bookingIsConfirmed(booking);
  const isAnyJobAccepted = checkIfJobAccepted(booking);

  const getTreatmentRowIcon = () => {
    let therapistCount = 1;
    if (isCorporateType) {
      therapistCount = corporateInfo.numberOfPros || 1;
    } else if (isMassage) {
      therapistCount = isCouplesMassage ? 2 : 1;
    }
    return getTreatmentDetailsIcon(therapistCount);
  };

  const imageSrc = () => {
    switch (type) {
    case BookingDetailsOrderItemType.massage:
      return getTreatmentRowIcon();
    case BookingDetailsOrderItemType.recipient:
      return RecipientIcon;
    case BookingDetailsOrderItemType.location:
      return LocationIcon;
    case BookingDetailsOrderItemType.time:
      return TimeIcon;
    case BookingDetailsOrderItemType.frequency:
      return BookingFrequencyIcon;
    }
  };

  const title = (index = -1) => {
    switch (type) {
    case BookingDetailsOrderItemType.massage:
      if (isHairAndMakeup) {
        const td = treatmentDetails[index];
        const formattedTD = getTreatmentDetailByTreatmentId(td ? td.treatmentTypeId : null);
        return formattedTD ? formattedTD.label : getValue(td, "treatment.label", "-");
      }
      if (isMassage) {
        return `${serviceDuration} ${SessionType.toString(sessionType)}`;
      }

      // defaults to service type if booking details wasn't created
      return capitalizeFirstLetter(booking.bookingdetails[0]?.massageType || booking.serviceType);

    case BookingDetailsOrderItemType.recipient:
      return recipient && recipient.aliasUserId !== user?.id ? (
        <div>
            Booked by {user?.firstName} for{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => history.push(`/recipients/${recipient.id}/details`)}
          >
            {recipient.firstName}
          </span>{" "}
        </div>
      ) : (
        "For myself"
      );

      // if (massageFor === MassageFor.myself) {
      //   return `For ${user?.firstName} ${user?.lastName}`;
      // }

      // return `For ${recipient?.firstName} ${recipient?.lastName}`;
    case BookingDetailsOrderItemType.location:
      return (
        address?.fullAddress || `${address?.address} ${address?.suburb} ${address?.postcode}`
      );

    case BookingDetailsOrderItemType.time:
      return `${getDateForBooking(booking)} ${getTimeRangeForBooking(booking)}`;

    case BookingDetailsOrderItemType.frequency:
      if (!recurring) return "";
      const { frequency, ...restRecurringData } = recurring;
      const title = frequencyTitleForBooking({
        frequency,
        recurring: restRecurringData,
      });
      return title;
    }
  };

  const getCorporateCaption = () => {
    let corporateDescription: any = [];
    if (corporateInfo && !checkIfEmpty(corporateInfo)) {
      corporateDescription = transformCorporateForDescription({
        numberOfPerson: corporateInfo.numberOfPerson,
        durationPerPerson: corporateInfo.durationPerPerson,
        totalEventDuration: {
          massageDuration: corporateInfo.totalEventDuration,
          hasPaidBreak: corporateInfo.hasPaidBreak,
        },
        numberOfPros: corporateInfo.numberOfPros,
        eventType: corporateInfo.eventType,
        eventName: corporateInfo.eventName,
        noteForRecipients: corporateInfo.noteForRecipients,
        paymentSplit: corporateInfo.paymentSplit,
        genderPreference: genderPreference1,
      });
    } else {
      const treatmentDuration =
        booking.massageDuration ||
        getValue(bookingdetails[0], "serviceDuration") ||
        getValue(treatmentDetails[0], "treatmentDuration", null);
      corporateDescription = [
        {
          key: "numberOfPros",
          label: "Number of providers:",
          value: booking?.bookingdetails?.length || 1,
        },
        {
          key: "totalEventDuration",
          label: "Total event duration:",
          value: formatMinutesToDisplayTime(treatmentDuration),
        },
      ];
    }
    return corporateDescription.map((item: any) => {
      if (!item.value && item.key === CORPORATE_BOOKING_KEYS.eventName) return <></>;
      return (
        <Box key={item.key} lineHeight={"21px"}>{`${item.label} ${
          item.value ? item.value : "-"
        }`}</Box>
      );
    });
  };

  const caption = (index = -1) => {
    if (type === BookingDetailsOrderItemType.massage && isHairAndMakeup) {
      let treatmentDuration = getValue(treatmentDetails[index], "treatmentDuration", null);
      const treatmentdetails = getTreatmentDetailByTreatmentId(
        treatmentDetails[index].treatmentTypeId
      );
      const duration = treatmentDuration ? treatmentDuration : treatmentdetails?.duration;

      return duration
        ? `Up to ${duration} mins`
        : `Up to ${getValue(treatmentDetails[index], "treatment.duration", "-")} mins`;
    }

    if (type === BookingDetailsOrderItemType.massage && isCorporateType) {
      return getCorporateCaption();
    }

    if (type === BookingDetailsOrderItemType.massage && genderPreference1 && isMassage) {
      let result = GenderPreference.toDescriptiveString(genderPreference1);

      if (genderPreference2 && isCouplesMassage) {
        result += ` & ${GenderPreference.toDescriptiveString(genderPreference2)}`;
      }

      return result;
    }

    if (type === BookingDetailsOrderItemType.massage && !isHairAndMakeup && !isMassage) {
      const treatmentDuration =
        getValue(bookingdetails[0], "serviceDuration") ||
        getValue(treatmentDetails[0], "treatmentDuration", null);
      return treatmentDuration
        ? `Up to ${treatmentDuration} mins`
        : `Up to ${getValue(treatmentDetails[0], "treatment.duration", "-")} mins`;
    }

    if (type === BookingDetailsOrderItemType.location && address?.instructions) {
      return address.instructions;
    }

    if (type === BookingDetailsOrderItemType.frequency) {
      return bookingFrequencyCaption({
        bookingDate: booking.earliestTime,
        frequency: getValue(recurring, "frequency"),
        monthData: getValue(recurring, "monthly", null),
        timezone: getValue(address, "timezone", getValue(booking, "timezone")),
        type: recurring?.type,
      });
    }

    return undefined;
  };

  return (
    <Box display="flex" flexDirection="row" alignItems={"flex-start"}>
      <Box width="24px" height="24px" justifyContent="center" alignItems="center" display="flex">
        <img
          src={imageSrc()}
          alt="icon"
          style={{
            width: `${BookingDetailsOrderItemType.frequency === type && "24px"}`,
            height: `${BookingDetailsOrderItemType.frequency === type && "24px"}`,
          }}
        />
      </Box>
      {isHairAndMakeup && type === BookingDetailsOrderItemType.massage && (
        <Box display="flex" flexDirection="column" flex={1} mt={"2px"}>
          {treatmentDetails.map((td, i) => (
            <Box
              key={`treatment-${td.treatmentTypeId}`}
              display="flex"
              flexDirection="column"
              flex={1}
              style={{ marginBottom: "5px" }}
            >
              <Box
                ml={2}
                mr={2}
                fontFamily="Museo"
                fontSize="16px"
                color={Colors.Dusk}
                style={{ overflowWrap: "break-word" }}
                width="100%"
              >
                {title(i)}
              </Box>
              {caption(i) && (
                <Box
                  fontFamily="SF UI Text"
                  fontSize="12px"
                  color={Colors.BlueyGrey}
                  mt={0.2}
                  ml={2}
                  mr={2}
                >
                  {caption(i)}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}

      {(type !== BookingDetailsOrderItemType.massage || !isHairAndMakeup) && (
        <Box display="flex" flexDirection="column" flex={1} mt={"2px"}>
          <Box
            ml={2}
            mr={2}
            fontFamily="Museo"
            fontSize="16px"
            color={Colors.Dusk}
            style={{ overflowWrap: "break-word" }}
          >
            {title()}
          </Box>
          {!!caption() && (
            <Box
              fontFamily="SF UI Text"
              fontSize="12px"
              color={Colors.BlueyGrey}
              mt={0.2}
              ml={2}
              mr={2}
            >
              {caption()}
            </Box>
          )}
        </Box>
      )}

      {onChangeClicked &&
        ((!isCorporateType && !isBookingConfirmed) || (isCorporateType && !isAnyJobAccepted)) && (
        <Box ml={4} mt={"2px"}>
          <TextLink title="Change" onClick={onChangeClicked} />
        </Box>
      )}
    </Box>
  );
}
