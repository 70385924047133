// v1: static options for corporate booking

export const DURATION_PER_PERSON = [
  { title: "5 mins", value: 5 },
  { title: "10 mins", value: 10 },
  { title: "15 mins", value: 15 },
  { title: "20 mins", value: 20 },
  { title: "30 mins", value: 30 },
  { title: "60 mins", value: 60 },
];

export const NUMBER_OF_PROS = [
  { title: "1", value: 1 },
  { title: "2", value: 2 },
  { title: "3", value: 3 },
  { title: "4", value: 4 },
  { title: "5", value: 5 },
  { title: "6", value: 6 },
  { title: "7", value: 7 },
  { title: "8", value: 8 },
];

export const EVENT_TYPE = [
  { title: "Workplace / team event (for staff)", value: "workplace-team-event" },
  { title: "Conference, corporate event or trade show", value: "conference-corporate-trade" },
  { title: "Private event (e.g. Hens, girls weekend)", value: "private-event" },
  { title: "Other", value: "other" },
];

export const PAYMENT_SPLIT = [
  { title: "100% paid by the company", value: "100-paid-by-company"},
  { title: "50% paid by the company, 50% paid by the staff", value: "50-paid-by-company-50-paid-by-staff"},
  { title: "100% paid by each individual staff", value: "100-paid-by-each-individual-staff"},
];

export const CORPORATE_BOOKING_KEYS = {
  eventName: "eventName",
  noteForRecipients: "noteForRecipients",
};

export const CORPORATE_LINK_META = {
  title: "Select your treatment time | Blys",
  description: "Lock in your time-slot and provider for your Blys event massage, beauty or wellness treatment.",
};

export const DEFAULT_CHANGEOVER = 5;
