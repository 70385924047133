import { Box, CircularProgress } from "@material-ui/core";

type Props = {
  paddingTop?: string
}

export function LoadingSpinner(props:Props) {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      paddingTop={props.paddingTop ?? "2rem"}
      height={"inherit"}
      width={"inherit"}
    >
      <CircularProgress />
    </Box>
  );
}
