import { Box } from "@material-ui/core";
import React from "react";
import Billing from "../../Payment/Billing";
import { Recipient } from "../../../models";
import { useMobile } from "../../../hooks/mobile";

interface RecipientBillingSectionProps {
  recipient: Recipient;
}

const RecipientBillingSection: React.FC<RecipientBillingSectionProps> = ({ recipient }) => {
  const isMobile = useMobile();
  return (
    <Box
      marginTop="43px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <Billing recipientId={recipient.id} isRecipient={true} />
    </Box>
  );
};

export default RecipientBillingSection;
