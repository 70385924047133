import {
  Box,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import React from "react";
import CrossIcon from "../../images/cross.svg";
import "./genderFallbackInfoModal.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
}

const CloseButton = styled(Button)({
  fontSize: "16px",
  textTransform: "none",
  color: "#41506F",
});

function GenderFallbackInfoModal(props: Props) {
  const handleClose = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    props.onClose();
  };

  return (
    <MUIDialog open={props.isOpen} maxWidth="sm" onClose={handleClose}>
      <Box display="flex" alignItems="center" height="64px">
        <IconButton onClick={handleClose}>
          <img src={CrossIcon} alt="Close" />
        </IconButton>
        <DialogTitle style={{ flex: 1 }}>
          <Typography className="treatmentTitle">{props.title}</Typography>
        </DialogTitle>
      </Box>
      <DialogContent dividers>
        <Typography className="treatmentDescription">
          {props.description}
        </Typography>
      </DialogContent>
      <DialogActions className="treatmentFooter">
        <CloseButton variant="outlined" onClick={handleClose}>
          Close
        </CloseButton>
      </DialogActions>
    </MUIDialog>
  );
}

export default GenderFallbackInfoModal;
