import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import TextField from "../../../TextField";
import Button, { ButtonType } from "../../../Button";
import ContentModal from "../../../Modals/ContentModal/contentModal";
import { requestAccountDeletionMfa } from "../../../../services/users/users.service";
import { useAlertStore } from "../../../../stores/alert";
import { parseApiError, parseApiV2Error } from "../../../../helpers/error";
import { MFA_TYPE } from "../../../../constants/mfa";
import { Colors } from "../../../../constants/colors";
import { useSendTwoFAVerificationCode } from "../../../../hooks/settings/twoFA.hooks";
import { getFutureTimeStamp } from "../../../../utils/date.util";
import { VERIFICATION_CODE_RESEND_TIMEOUT } from "../../../../constants/time";
import CountdownTimer from "../../../MobileModalOtp/CountdownTimer";
import { useLocation } from "react-router-dom";
import { getValue } from "../../../../utils/object";
import firebaseApp from "../../../../services/firebaseApp";

interface DeleteConfirmModalProps {
  reason: string;
  email: string;
  mobile: string;
  mfaType: string;
  serviceId: string;
  visible: boolean;
  onClose: () => unknown;
  onSuccess: () => unknown;
}

const DeleteConfirmMfaModal = ({
  reason,
  visible,
  onClose,
  onSuccess,
}: DeleteConfirmModalProps) => {
  const location = useLocation();

  const locationState = location.state;

  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [waitTime, setWaitTime] = useState("");
  const [isActionDisabled, setIsActionDisabled] = useState(true);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { mutateAsync: resendVerficationCode } = useSendTwoFAVerificationCode();

  useEffect(() => {
    clearFormFields();
  }, [reason]);

  const clearFormFields = () => {
    setCode("");
  };

  const verifyFields = () => {
    let isValid = true;
    try {
      if (!code) throw Error("Please enter a verfication code");
    } catch (err: any) {
      setErrorMessage(err.message);
      isValid = false;
    }
    return isValid;
  };

  const handleRequestDeletion = async () => {
    try {
      const isValid = verifyFields();
      if (!isValid) return;

      setLoading(true);
      const payload = {
        email: getValue(locationState, "email"),
        reason: getValue(locationState, "reason"),
        serviceId: getValue(locationState, "serviceId"),
        code,
      };

      const response = await requestAccountDeletionMfa(payload);
      if (response && response.success) {
        setLoading(false);
        onClose();
        onSuccess();
      }
    } catch (err: any) {
      setLoading(false);
      setErrorMessage(parseApiV2Error(err));
    }
  };

  const onResend = async () => {
    try {
      const fbToken = await firebaseApp.getToken();

      const response = await resendVerficationCode({
        mobileNumber: getValue(locationState, "mobile"),
        fbToken,
      });

      if (response) {
        setWaitTime(getFutureTimeStamp({ seconds: VERIFICATION_CODE_RESEND_TIMEOUT }).format());
        setIsActionDisabled(true);
        setSuccessMessage("Verification code sent successfully.");
      }
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  const mfaType = getValue(locationState, "mfaType");

  const mfaTypeText = mfaType === MFA_TYPE.SMS ? "phone number" : "Authentication app";

  return (
    <>
      <ContentModal
        title="Delete account"
        description={`Please enter the authentication code sent to your ${mfaTypeText}.`}
        onClose={onClose}
        visible={visible}
        actions={[
          <Box display="flex" flexDirection="column" width={"100%"}>
            <Box>
              <Button
                loading={isLoading}
                title="Submit"
                onClick={handleRequestDeletion}
                type={ButtonType.secondary}
              />
            </Box>
            {mfaType === MFA_TYPE.SMS && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  display="flex"
                  flexDirection="row"
                  fontFamily="Museo"
                  fontWeight={500}
                  fontSize="16px"
                  color={Colors.BlueyGrey}
                  mt="29px"
                >
                  Didn’t get a code?&nbsp;
                  <Box
                    color={isActionDisabled ? Colors.LightBlueGrey : Colors.TurquoiseBlue}
                    style={{ cursor: isActionDisabled ? "default" : "pointer" }}
                    onClick={() => (!isActionDisabled ? onResend() : null)}
                    pr={0.5}
                  >
                    Resend code
                  </Box>
                  {isActionDisabled ? (
                    <CountdownTimer time={waitTime} setIsActionDisabled={setIsActionDisabled} />
                  ) : null}
                </Box>
              </Box>
            )}
          </Box>,
        ]}
      >
        <Box>
          <TextField
            value={code}
            type="text"
            title="Verification code"
            placeholder="Enter your code"
            onChange={(code) => setCode(code)}
          />
        </Box>
      </ContentModal>
    </>
  );
};

export default DeleteConfirmMfaModal;
