import React from "react";
import { BoxProps, Box } from "@material-ui/core";
import { useMobile } from "../hooks/mobile";

interface Props extends BoxProps {
  height?: number | string;
}

export default function ({ children, height }: Props) {
  return (
    <>
      {/* Footer phantom view for making views above respect margins */}
      <Box width="100%" height={height} />

      {/* Footer itself */}
      <Box
        display="flex"
        height={height}
        width="100vw"
        position="fixed"
        bottom={0}
        left={0}
        bgcolor="white"
        justifyContent={"center"}
      >
        {children}
      </Box>
    </>
  );
}
