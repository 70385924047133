import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import SignUpComponent from "./SignUpComponent";
import Dialog from "../../components/Dialog";
import CloseIcon from "../../images/modal-close.svg";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onCreatedAccount: () => unknown;
  onLogin: () => unknown;
}

export default function ({ open, onClose, onCreatedAccount, onLogin }: Props) {
  const isMobile = useMobile();

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullScreen={isMobile}
      onClose={onClose}
    >
      <Box
        bgcolor="white"
        width={isMobile ? "100%" : "500px"}
        borderRadius="11px"
      >
        <Box display="flex" justifyContent="flex-end" mt={1} mr={1}>
          <IconButton onClick={onClose}>
            <img src={CloseIcon} />
          </IconButton>
        </Box>
        <Box ml="40px" mr="40px" mb="32px">
          <SignUpComponent
            marginTop="12px"
            onLogin={onLogin}
            onCreatedAccount={onCreatedAccount}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
