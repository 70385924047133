import { Box, Grid } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../../constants/colors";
import { parseApiError } from "../../../../helpers/error";
import { useAccessToken } from "../../../../hooks/common";
import { UserService } from "../../../../models";
import { toggleService } from "../../../../services/therapist/proTreatment.service";
import { useAlertStore } from "../../../../stores/alert";
import { useTherapistStore } from "../../../../stores/therapist";
import AddCustomServiceForm from "./AddCustomService";
import CustomServiceItem from "./CustomServiceItem";

const CustomServices = () => {
  const accessToken = useAccessToken();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { customServices, setCustomServices } = useTherapistStore();

  const filterCustomServices = (userServices: UserService[]) =>
    userServices.filter((item) => item.service?.custom);

  const handleServiceRemoved = (userServiceId: number) => {
    toggleService(accessToken || "", { serviceId: userServiceId, value: false })
      .then((response) => {
        setCustomServices([...filterCustomServices(response.data)]);
        setSuccessMessage("Skill has been removed.");
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      });
  };

  const handleSkillAdded = (addedServices: UserService[]) => {
    setCustomServices([...filterCustomServices(addedServices)]);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={8} lg={6} xl={4}>
        <Box
          fontFamily="Museo"
          fontWeight={600}
          color={Colors.Dusk}
          my="32px"
          fontSize={24}
        >
          Add your additional skills
        </Box>
        <Box fontFamily="Museo" fontSize={16} color={Colors.Dusk}>
          Showcase your abilities by adding additional skills and services to
          your public profile. Clients won't be able to book these services
          specifically, however, they may be added to the Blys platform in the
          future.
        </Box>

        <AddCustomServiceForm onAdded={handleSkillAdded} />

        <Box mb={10}>
          {customServices.map((service) => (
            <CustomServiceItem
              key={`custom-service-${service.id}`}
              userService={service}
              onRemove={handleServiceRemoved}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomServices;
