import React from "react";
import { Text } from "../v2/Styled";
import { Colors } from "../../constants/colors";
import { FontFamily, FontWeight } from "../v2/Styled/enum";

/**
 * Takes plain text and returns array of text component with bold style based on <b></b> tag on plain text
 * @param text
 *
 */
const parseBoldText = (text: string): Array<JSX.Element> => {
  const parsedTags = [] as any;
  if (!text) return parsedTags;

  const subStr = text.split(/<\/b>/);
  for (let str of subStr) {
    if (!str) continue;
    const [normalText, boldText] = str.split("<b>");
    parsedTags.push(
      <Text font={FontFamily.Museo} color={Colors.Grey}>
        {normalText}
      </Text>,
    );
    if (boldText)
      parsedTags.push(
        <Text weight={FontWeight.Bold} font={FontFamily.Museo} color={Colors.Grey}>
          {boldText}
        </Text>,
      );
  }
  return parsedTags;
};

export { parseBoldText };
