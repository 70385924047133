import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import { isEmpty } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonType } from "../components/Button";
import LandingWrapper from "../components/LandingWrapper";
import LogoLink from "../components/LogoLink";
import TextField from "../components/TextField";
import { Colors } from "../constants/colors";
import { Paths } from "../constants/paths";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import logo from "../images/blys-logo.svg";
import { useAlertStore } from "../stores/alert";

export default function ResetPassword(): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const email = query.get("email");
  const token = query.get("token");

  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const onSubmit = () => {
    if (isEmpty(email) || isEmpty(token)) {
      setErrorMessage("Fatal error: invalid link");
      return;
    }

    if (isEmpty(newPassword)) {
      setErrorMessage("Please enter a new password");
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorMessage("Please make sure your passwords are the same");
      return;
    }

    setLoading(true);

    axios
      .post("/users/me/password/reset", {
        email,
        token,
        password: newPassword,
      })
      .then((response) => {
        setLoading(false);

        setSuccessMessage("Your password was successfully updated");

        history.push(Paths.Login);
      })
      .catch((error) => {
        setLoading(false);

        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <LandingWrapper>
      <Box width={isMobile ? undefined : "400px"}>
        <LogoLink />
        <Typography
          variant="h1"
          style={{ color: Colors.NightBlue, marginTop: "48px" }}
        >
          Reset password
        </Typography>
        <Typography
          variant="h3"
          style={{ color: Colors.Dusk, marginTop: "18px" }}
        >
          Setup a new password for your account
        </Typography>
        <Box mt="31px">
          <TextField
            title="Email"
            placeholder="youremail@mail.com"
            value={email}
            type="email"
            disabled
            useInputTextColor
          />
          <TextField
            title="New Password"
            placeholder="New Password"
            onChange={(text) => setNewPassword(text)}
            value={newPassword}
            type="password"
          />
          <TextField
            title="Confirm New Password"
            placeholder="Confirm New Password"
            onChange={(text) => setConfirmPassword(text)}
            value={confirmPassword}
            type="password"
          />
        </Box>

        <Box mt="20px">
          <Button
            loading={loading}
            title="Reset Password"
            type={ButtonType.primary}
            onClick={() => onSubmit()}
          />
        </Box>
      </Box>
    </LandingWrapper>
  );
}
