import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider, getToken, AppCheck } from "firebase/app-check";
import { environment, USE_FIREBASE_APP_CHECK } from '../constants/common';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAf13-sZ7Kp77BaJz8pVVt1VaB6w3IOzsw",
  // authDomain: "blys-verify-app.firebaseapp.com",
  projectId: "blys-verify-app",
  storageBucket: "blys-verify-app.appspot.com",
  // messagingSenderId: "34740155676",
  appId: "1:34740155676:web:273fd8ae0a3283088fb6d9",
  // measurementId: "G-GVKTSRERQ7"
};

const RECAPTCHA_SITE_KEY = '6LdlEfUiAAAAAJTICiLOP10iO5VyYG3Ef1WBeX9A';

const FIREBASE_DEBUG_TOKEN = '44E9B038-E95A-4306-9EE9-2BEAB8AEAE08';


class FirebaseApp {
  private static _instance: FirebaseApp;
  private _appCheck: AppCheck | undefined;

  public static getInstance(): FirebaseApp {
    if (!FirebaseApp._instance) {
      FirebaseApp._instance = new FirebaseApp();
    }

    return FirebaseApp._instance;
  }

  // disable for dev env
  public init() {

    if (!USE_FIREBASE_APP_CHECK) return;

    // NOTE: this is not for production
    if (environment.isDevelopment) (globalThis as any).FIREBASE_APPCHECK_DEBUG_TOKEN = FIREBASE_DEBUG_TOKEN;

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // initialize app check
    this._appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY),
      isTokenAutoRefreshEnabled: true
    });

  }

  public async getToken() {
    if (!USE_FIREBASE_APP_CHECK) return null;

    if (!FirebaseApp._instance || !this._appCheck) {
      console.error('Firesbase App is not initialized yet');
      return null;
    }
    try {
      const { token } = await getToken(this._appCheck);
      return token;
    } catch (error) {
      console.error('Firebase Token Error:', error);
      return null;
    }
  }

}

// returning shared instance
export default FirebaseApp.getInstance();