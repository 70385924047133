import firebaseApp from "../services/firebaseApp";

export const getFireBaseToken = async () => {
  let token = await firebaseApp.getToken();
  if (token) return token;

  const maxRetry = 3;

  for (let counter = 1; counter <= maxRetry; counter++) {
    token = await firebaseApp.getToken();
    if (token) return token;
  }

  return null;
};
