import React from "react";
import { FontFamily, FontSize, FontWeight } from "../v2/Styled/enum";
import { Text } from "../v2/Styled";

import { Colors } from "../../constants/colors";

interface TitleProps {
  title: string;
  subtitle?: string;
}

const PreferencesTitle: React.FC<TitleProps> = ({ title, subtitle }) => {
  return (
    <div style={{ maxWidth: "280px" }}>
      <Text
        size={FontSize.F16}
        font={FontFamily.Museo}
        weight={FontWeight.Bold}
        color={Colors.NightBlue}
      >
        {title}
      </Text>
      <p
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "21px",
          textAlign: "left",
        }}
      >
        {subtitle}
      </p>
    </div>
  );
};

export default PreferencesTitle;
