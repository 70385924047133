import React from "react";
import { upperFirst } from "lodash";
import { Box } from "@material-ui/core";

import AvatarName from "./AvatarName";
import { UserReview } from "../../models";
import Ratings from "../../components/Ratings";
import { Colors } from "../../constants/colors";
import VerifiedTick from "../../images/verified_tick.svg";
import { BASE_UPLOADS_URL } from "../../constants/common";
import { firstLetterCapitalized } from "../../helpers/string";
import { isMassageForMassageType } from "../../helpers/massage";
import ReviewImages from "./ReviewImages";

interface Props {
  review: UserReview;
  isMobile: boolean;
}

const RecentReviewItem = ({ review, isMobile }: Props): JSX.Element => {
  const {
    massageType,
    suburb,
    profileImage,
    firstName,
    lastName,
    review: reviewText,
    rating,
    reviewFiles = [],
  } = review;
  const trimmedReviewText = reviewText
    ?.split(/\n{2,}/g)
    ?.filter(Boolean)
    ?.join("\n\n");
  const profilePictureUrl = profileImage ? `${BASE_UPLOADS_URL}/${profileImage}` : null;
  const reviewImages = reviewFiles.map((file: any) => `${BASE_UPLOADS_URL}/${file.filePath}`);
  let serviceType;
  if (massageType) {
    if (isMassageForMassageType(massageType)) {
      serviceType = `${upperFirst(massageType)} massage`;
    } else {
      serviceType = upperFirst(massageType);
    }
  }

  return (
    <Box width={isMobile ? "100%" : "340px"} mb="35px">
      <Box
        mb="18.5px"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Ratings width="18px" height="18px" rating={rating} />
        <Box display="flex" flexDirection="row" alignItems="center">
          <img src={VerifiedTick} width={"24px"} height={"24px"} alt="verifiedTick" />
          <Box color="#0DAEC8" fontFamily="Open Sans" fontSize="14px" fontWeight="600">
            Verified
          </Box>
        </Box>
      </Box>
      <Box
        mb="16px"
        fontFamily="Open Sans"
        fontWeight="400"
        fontSize="16px"
        lineHeight="24px"
        color={Colors.Dusk}
        style={{
          whiteSpace: "pre-wrap",
          wordBreak: "break-all",
        }}
      >
        {trimmedReviewText}
      </Box>
      <Box display="flex" flexDirection="row">
        <Box color={"red"}>
          {profilePictureUrl ? (
            <img
              alt="Reviewer"
              src={profilePictureUrl}
              width="56px"
              height="56px"
              style={{ borderRadius: "50px", objectFit: "cover" }}
            />
          ) : (
            <AvatarName firstName={firstName} lastName={lastName} />
          )}
        </Box>
        <Box ml="16px">
          <Box
            mb="8px"
            fontFamily="Museo"
            fontWeight={400}
            fontSize="18px"
            color={Colors.NightBlue}
          >
            {firstName} {firstLetterCapitalized(lastName)}, {suburb}
          </Box>
          <Box fontFamily="Open Sans" fontSize="14px" fontWeight="400" color={Colors.Dusk}>
            {serviceType || <></>}
          </Box>
        </Box>
      </Box>
      <ReviewImages reviewImages={reviewImages} />
    </Box>
  );
};

export default RecentReviewItem;
