import React, { useState } from "react";
import { resetAnswers, useBookingStore } from "../../../stores/booking";
import Dropdown, { DropdownOption } from "../../Dropdown";

const NumberOfClients = () => {
  const [recipientOptions] = useState<Array<DropdownOption>>(
    [1, 2, 3, 4, 5, 6].map((item) => {
      return {
        value: item,
        title: String(item),
      };
    })
  );

  const {
    numOfRecipients,
    treatmentDetails,
    setTreatmentDetails,
    setNumOfRecipients,
    serviceId,
  } = useBookingStore();

  const onNumOfClientsChanged = (val: number) => {
    const newTreatmentDetails = [...treatmentDetails];
    const [treatmentDetail = {}] = newTreatmentDetails;
    if (newTreatmentDetails.length < val) {
      let diff = val - newTreatmentDetails.length;
      for (let i = 0; i < diff; i++) {
        newTreatmentDetails.push({
          treatmentFor: treatmentDetail.treatmentFor || null,
          treatmentId: treatmentDetail.treatmentId || null,
          noteForProvider: treatmentDetail.noteForProvider || null,
        });
      }
    } else if (newTreatmentDetails.length > val) {
      let diff = newTreatmentDetails.length - val;
      for (let i = 0; i < diff; i++) {
        newTreatmentDetails.pop();
      }
    }
    setTreatmentDetails(newTreatmentDetails);
    setNumOfRecipients(val);

    const treatmentIds = newTreatmentDetails.map(
      (treatment) => treatment.treatmentId
    );

    // reset answers when number of client has been changed
    resetAnswers(serviceId as number, treatmentIds);
  };

  return (
    <Dropdown
      title="How many people?"
      options={recipientOptions}
      selectedOption={recipientOptions.find(
        (option) => option.value === numOfRecipients
      )}
      onSelectedOption={(option) => onNumOfClientsChanged(option.value)}
    />
  );
};

export default NumberOfClients;
