import { actions as bookingActions } from "../../../stores/V2/booking/booking";
import { getAllBookingQuestions, transformBookingData } from "./booking.transformer";
import { getBookingById, getServices } from "../../../services/bookings/bookings.service";
import { getBookingByJobId } from "../../../services/jobs/job.service";
import { getValue } from "../../../utils/object";
import { getAddOnsForPro } from "../../../services/proDashboard/addon.service";

const bookingInitializer = (bookingId: string, dispatch: any) =>
  Promise.all([getBookingById(bookingId), getServices(), getAllBookingQuestions()])
    .then(([booking, allServices, questions]) => {
      const payload = transformBookingData(booking, allServices, questions);
      dispatch(bookingActions.initialize({ ...payload, allServices }));
    })
    .catch((err) => {
      console.log(err);
    });

const bookingInitializerUsingJobId = (jobId: string, dispatch: any, onError: (message: String) => void) => {
  Promise.all([
    getBookingByJobId(jobId),
    getServices(),
    getAllBookingQuestions(),
    getAddOnsForPro(),
  ])
    .then(([job, allServices, questions, addOns]) => {
      const payload = transformBookingData(
        getValue(job, "job.bookingdetail.booking"),
        allServices,
        questions,
        addOns
      );
      dispatch(bookingActions.initialize({ ...payload, allServices }));
    })
    .catch((err) => {
      onError(err.message);
    });
};

export { bookingInitializer, bookingInitializerUsingJobId };
