import React from "react";
import { Box, Checkbox } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { AlignItems, Display, FontFamily, FontSize, FontWeight, Spacing } from "../v2/Styled/enum";
import { useUserRole } from "../../hooks/user.hooks";
import { Text } from "../v2/Styled";
import Switch from "../Switch";

interface Props {
  isPointOfContact: boolean;
  onCheckBoxChange: (isChecked: boolean) => unknown;
  firstName: string;
}
export const PointOfContactInfo = ({ isPointOfContact, onCheckBoxChange, firstName }: Props) => {
  const { isHomeCareClient } = useUserRole();
  if (!isHomeCareClient) return <></>;

  const name = firstName || "this recipient";
  return (
    <Box paddingY={Spacing.S3} display={Display.Block}>
      <Box
        display={Display.Flex}
        gridGap={Spacing.S3}
        alignItems={AlignItems.center}
        paddingY={Spacing.S3}
      >
        <Switch
          checked={isPointOfContact}
          onChange={(event) => {
            const isChecked = event.target.checked;
            onCheckBoxChange(isChecked);
          }}
        />
        <Box>
          <Text
            font={FontFamily.Museo}
            size={FontSize.F14}
            weight={FontWeight.Bold}
            color={Colors.Dusk}
          >
            Set as primary contact
          </Text>
        </Box>
      </Box>
      <Box alignItems="center">
        <Box paddingY={Spacing.S2}>
          <Text
            font={FontFamily.OpenSans}
            size={FontSize.F14}
            weight={FontWeight.Regular}
            color={Colors.Dusk}
          >
            {`We will notify ${name} via email and/or SMS that they’ve been appointed as the primary point of contact (POC) for ${name}’s bookings. POC’s receive all booking-related updates and correspondence. POC’s can also manage the recipient’s bookings.`}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
