import { Box } from "@material-ui/core";
import { ReactNode } from "react";
import { Colors } from "../../constants/colors";

interface Props {
  children: ReactNode | ReactNode[];
};

const JumboTron = ({ children }: Props) => {
  return <Box style={styles.wrapper}>
    {children}
  </Box>;
};

const styles = {
  wrapper: {
    backgroundColor: Colors.PaleGrey,
    color: Colors.Dusk,
    borderRadius: "6px",
    lineHeight: "27px",
    padding: "16px",
  },
};

export default JumboTron;
