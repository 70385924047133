import { isNil } from "lodash";
import { useUserStore } from "../stores/user";
import { Roles } from "../constants/blysTeam";

export function isLoggedIn() {
  return !isNil(localStorage.getItem("token"));
}

export const getInstructionFromPreferences = (serviceId: number | null) => {
  const { userPreferences } = useUserStore.getState();
  if (!userPreferences) return;
  const targetServicePref = userPreferences.find((pref: any) => pref.serviceId === serviceId);
  if (targetServicePref) {
    const preference = JSON.parse(targetServicePref.preference);
    return preference["specialInstructions"];
  } else {
    return "";
  }
};

export function getUserPreferenceForService(serviceId: number) {
  if (!serviceId) return "";

  const { userPreferences } = useUserStore.getState();
  const targetPreference = userPreferences.find(
    (userPref: any) => userPref.serviceId === serviceId,
  );
  if (!targetPreference) return "";

  return targetPreference;
}

const HOME_CARE_SELF_MANAGED_RECIPIENTS = [
  Roles.AGED_CARE_SUPPORT_RECIPIENT,
  Roles.DISABILITY_SUPPORT_RECIPIENT,
];

export const isNdisSupportRecipient = (roleName: Roles) =>
  HOME_CARE_SELF_MANAGED_RECIPIENTS.includes(roleName);
