import * as httpClient from "../../api/client";

const ALTERNATIVE_BASE_URL = "api/v2/bookings/alternative-offer";

const getAlternativeOffersForBooking = async (bookingId: number) => {
  const URL = `${ALTERNATIVE_BASE_URL}/${bookingId}`;
  return httpClient.get(URL);
};

const declineAlternativeOffer = async (offerId: number) => {
  const URL = `${ALTERNATIVE_BASE_URL}/decline/${offerId}`;
  return await httpClient.post(URL, {});
};

const acceptAlternativeOffer = async (offerId: number) => {
  const URL = `${ALTERNATIVE_BASE_URL}/accept/${offerId}`;
  return await httpClient.post(URL, {});
};

const getOfferedPriceBreakdown = async (offerId: number) => {
  const url = `${ALTERNATIVE_BASE_URL}/price/${offerId}`;
  return await httpClient.get(url);
};

export {
  getAlternativeOffersForBooking,
  declineAlternativeOffer,
  acceptAlternativeOffer,
  getOfferedPriceBreakdown,
};
