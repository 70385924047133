import { Colors } from "../../../constants/colors";

const styles = {
  wrapper: {
    display: 'flex',
  },
  row: {
    flex: 1,
  },
  label: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    fontFamily: 'SF UI Text',
    color: Colors.BlueyGrey,
  },
  title: {
    fontWeight: 700,
    fontSize: '42px',
    fontFamily: 'Museo',
    color: Colors.NightBlue,
    letterSpacing: '-0.56px',
  }
};

export default styles;