import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";

import CloseIcon from "../images/modal-close.svg";
import { Colors } from "../constants/colors";

interface Props {
  title: string;
  subtitle?: string;
  onClose: () => unknown;
}

export default function ModalHeader({ title, subtitle, onClose }: Props): JSX.Element {
  return (
    <Box paddingLeft="20px" paddingRight="20px" paddingBottom="12px" position={"relative"}>
      <Box position="absolute" top="10px" right="10px">
        <IconButton onClick={onClose}>
          <img src={CloseIcon} alt="closeIcon" />
        </IconButton>
      </Box>
      <Box paddingTop="46px">
        <Typography variant="h1" style={{ marginLeft: "20px" }}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="h3" style={{ marginTop: "16px", marginLeft: "20px" }}>
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
