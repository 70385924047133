import { ButtonBase } from "@mui/material";
import { useSelector } from "react-redux";
import { Box, DialogActions, DialogContent } from "@material-ui/core";

import { styles } from "./styles";
import { RootState } from "../../../../stores/V2";
import Dialog from "../../../Dialog";
import CrossIcon from "../../../../images/cross.svg";
import AddTherapistDocument from "../AddTherapistDocument/AddTherapistDocument";
import Button, { ButtonType } from "../../../Button";
import { Colors } from "../../../../constants/colors";

interface Props {
  open: boolean;
  title: string;
  onClose: () => unknown;
  onConfirm: () => unknown;
  isSaving: boolean;
  listTitle?: string;
  uploadTitle?: string;
  actionName?: string;
  documents: any[];
  allowUpload?: boolean;
}

const AddProDocumentModal = ({
  open,
  title,
  onClose,
  isSaving,
  onConfirm,
  documents = [],
  actionName = "Save changes",
  listTitle = "Your documents",
  uploadTitle = "Add document",
  allowUpload = true,
}: Props) => {
  const state = useSelector((state: RootState) => state.documents);
  
  const hasPendingOptions = documents && documents.length > 0;

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth={"sm"}
      onClose={onClose}
      style={{ opacity: state.showInputModal ? 0 : 1, overflow: "visible" }}
    >
      <Box style={styles.wrapper}>
        <Box style={styles.titleWrapper}>
          <ButtonBase style={styles.closeWrapper} onClick={onClose}>
            <img src={CrossIcon} alt={"Close"} style={styles.closeBtn} />
          </ButtonBase>
        </Box>
        <Box>
          <Box style={styles.title}>{title}</Box>
        </Box>
        <Box marginTop={"32px"} />
        <DialogContent style={{ ...styles.contentWrapper, ...{ overflow: "visible" } }}>
          {hasPendingOptions ? <AddTherapistDocument
            listTitle={listTitle}
            documents={documents}
            uploadTitle={uploadTitle}
            allowUpload={allowUpload}
          /> : <Box fontFamily="Museo" fontSize="18px" fontWeight={700} lineHeight="27px" color={Colors.NightBlue}>
            You have no pending documents to be signed.
          </Box>}
        </DialogContent>
        <DialogActions style={styles.footerWrapper}>
          <Button
            title={actionName}
            width={"150px"}
            type={ButtonType.indigo}
            onClick={onConfirm}
            loading={isSaving}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddProDocumentModal;
