import { useEffect, useState } from "react";

import { BOOKING_STATUS, CLIENT_BOOKING_CANCEL_REASONS } from "../constants/booking";
import { TrackingEvents } from "../constants/tracking";
import { withVersion } from "../helpers/tracking";
import { useUpcomingBookings } from "../hooks/booking/booking.hooks";
import { useAccessToken } from "../hooks/common";
import { Booking } from "../models";
import {
  confirmBookingCancel,
  getBookingCancellationReason,
  requestBookingCancel,
  verifyBookingCancellationReason,
} from "../services/bookings/bookings.service";
import { trackEvent } from "../services/segment/track.service";
import { useAlertStore } from "../stores/alert";
import { getValue } from "../utils/object";
import CancelBookingModal from "./CancelBookingModal";
import { DropdownOption } from "./Dropdown";
import InfoModal from "./Modals/InfoModal/InfoModal";
import CancelRecurringBookingModal from "./RecurringBookings/CancelRecurringBookingModal";

export interface BookingCancelReasonOption {
  title: string;
  value: string;
}
interface Props {
  open: boolean;
  booking: Booking;
  onClose: () => unknown;
  onConfirm: () => unknown;
}

export default function CancelBooking({ booking, open, onClose, onConfirm }: Props) {
  const [cancelling, setCancelling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [cancelRecurringBooking, setCancelRecurringBooking] = useState(false);
  const [cancellationFee, setCancellationFee] = useState<number | null>(null);
  const [platformFeeData, setPlatformFeeData] = useState({ title: "", value: 0 });
  const [showCancelRecurringBooking, setShowCancelRecurringBooking] = useState(false);
  const [showReasonNotValidModal, setShowReasonNotValidModal] = useState(false);
  const [isCancelReasonValid, setIsCancelReasonValid] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [additionalCancellationReason, setAdditionalCancellationReason] = useState("");
  const [cancellationReasonOptions, setCancellationReasonOptions] = useState<
    BookingCancelReasonOption[]
  >([]);
  const [canceReasonNotValidMsg, setCancelReasonNotValidMsg] = useState({
    title: "",
    description: "",
  });
  const [cancellationWarningMessage, setCancellationWarningMessage] = useState<string>();

  const accessToken = useAccessToken();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { invalidateUpcomingBookings } = useUpcomingBookings({});

  // const bookingCurrency = getValue(booking, "selectedService.selectedCountry.currencySymbol", "$");
  const bookingCurrency = getValue(booking, "service.selectedCountry.currencySymbol", "A$");

  useEffect(() => {
    if (open) {
      if (booking.recurring?.frequency) {
        setShowCancelRecurringBooking(true);
      } else {
        handleCancel(false);
      }
    }
  }, [open, booking]);

  const handleConfirmCancel = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const { id } = booking;
    setCancelling(true);

    const cancelReason =
      cancellationReason === CLIENT_BOOKING_CANCEL_REASONS.OTHER
        ? `${cancellationReason}-${additionalCancellationReason}`
        : cancellationReason;

    confirmBookingCancel(id, accessToken, cancelRecurringBooking, cancelReason)
      .then((response) => {
        invalidateUpcomingBookings();
        setSuccessMessage("Your booking has been cancelled.");
        setCancelling(false);
        setShowCancelBooking(false);
        trackEvent(TrackingEvents.CancelBookingConfirmed, withVersion());
        setSuccessMessage("Your booking has been cancelled.");
        onConfirm();
      })
      .catch((error) => {
        setErrorMessage("Unable to cancel booking");
      })
      .finally(() => {
        setCancelling(false);
        onClose();
      });
  };

  const getBookingCancellationFee = async ({
    id,
    accessToken,
    reason,
    withFutureBookings,
  }: {
    id: number;
    accessToken: string | null;
    reason: string | null;
    withFutureBookings?: boolean;
  }) => {
    try {
      const cancellationFee = await requestBookingCancel(id, {
        accessToken,
        reason,
        withFutureBookings,
      });
      if (cancellationFee.data) {
        const {
          clientPlatformFee,
          platformFeeLabel,
          totalCancellationFee,
          cancellationWarningMessage,
        } = cancellationFee.data;
        setCancellationWarningMessage(cancellationWarningMessage);
        if (totalCancellationFee >= 0) {
          setCancellationFee(totalCancellationFee);
        }
        setPlatformFeeData({ title: platformFeeLabel, value: clientPlatformFee });
      }
      return cancellationFee;
    } catch (error) {
      setErrorMessage("Unable to get cancellation fee");
    }
  };

  const handleCancel = async (cancelFutureRecurring: boolean) => {
    try {
      setCancelRecurringBooking(cancelFutureRecurring);
      setShowCancelRecurringBooking(false);
      const { id } = booking;

      const cancelReasonOptions = getBookingCancellationReason(id);
      const cancellationFee = getBookingCancellationFee({
        id,
        accessToken,
        reason: "",
        withFutureBookings: cancelFutureRecurring,
      });
      const [cancellationFeeResponse, cancelReasonOptionResponse] = await Promise.all([
        cancellationFee,
        cancelReasonOptions,
      ]);

      if (cancelReasonOptionResponse) {
        const options = cancelReasonOptionResponse.data?.data;
        setCancellationReasonOptions(options);
      }

      setShowCancelBooking(true);

      trackEvent(TrackingEvents.CancelBookingClicked, withVersion());
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancellationReasonSelected = async (option: DropdownOption) => {
    setCancellationReason(option.value);

    setIsLoading(true);
    try {
      const verifyCancelReason = verifyBookingCancellationReason(booking.id, {
        accessToken,
        reason: option.value,
      });

      const cancellationFee = getBookingCancellationFee({
        id: booking.id,
        accessToken,
        reason: option.value,
        withFutureBookings: cancelRecurringBooking,
      });

      const [cancellationFeeResponse, verifyCancelReasonResponse] = await Promise.all([
        cancellationFee,
        verifyCancelReason,
      ]);

      if (verifyCancelReasonResponse.data) {
        const { isValidReason, message } = verifyCancelReasonResponse.data;
        setIsCancelReasonValid(isValidReason);

        if (!isValidReason && message) {
          setShowReasonNotValidModal(true);
          setCancelReasonNotValidMsg({
            title: message?.title || "",
            description: message?.body || "",
          });
        }
      }
    } catch (error) {
      setErrorMessage("Failed to verify cancellation reason.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdditionalCancellationReasonChange = (reason: string) => {
    setAdditionalCancellationReason(reason);
  };

  const handleInfoModalClose = () => {
    setShowReasonNotValidModal(false);
  };

  const handleCancelBookingModalClose = () => {
    onClose();
    setShowCancelBooking(false);
    setCancellationReason("");
    setAdditionalCancellationReason("");
  };

  const shouldDisableConfirmButton = () => {
    if (!cancellationReason || !isCancelReasonValid || isLoading) return true;
    if (
      cancellationReason === CLIENT_BOOKING_CANCEL_REASONS.OTHER &&
      !additionalCancellationReason
    ) {
      return true;
    }
    return false;
  };

  if (!open) return <></>;

  return (
    <>
      <CancelBookingModal
        open={showCancelBooking}
        onClose={handleCancelBookingModalClose}
        onConfirm={handleConfirmCancel}
        cancelling={cancelling}
        bookingConfirmed={booking.status === BOOKING_STATUS.ARRANGED}
        cancellationFee={cancellationFee}
        clientPlatformFee={platformFeeData.value}
        clientPlatformLabel={platformFeeData.title}
        bookingCurrency={bookingCurrency}
        handleCancellationReasonSelected={handleCancellationReasonSelected}
        cancellationReason={cancellationReason}
        handleAdditionalCancellationReasonChange={handleAdditionalCancellationReasonChange}
        additionalCancellationReason={additionalCancellationReason}
        cancellationReasonOptions={cancellationReasonOptions}
        shouldDisableConfirmButton={shouldDisableConfirmButton}
        cancellationWarningMessage={cancellationWarningMessage}
      />

      <CancelRecurringBookingModal
        open={showCancelRecurringBooking}
        onConfirm={handleCancel}
        onClose={() => {
          onClose();
          setShowCancelRecurringBooking(false);
        }}
      />

      <InfoModal
        visible={showReasonNotValidModal}
        title={canceReasonNotValidMsg.title}
        description={canceReasonNotValidMsg.description}
        handleClose={handleInfoModalClose}
        divider={false}
      />
    </>
  );
}
