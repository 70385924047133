import React from "react";
import axios from "axios";
import { Box } from "@material-ui/core";
import { Booking } from "../../models";
import { useHistory } from "react-router-dom";
import { useAccessToken } from "../../hooks/common";
import { useAlertStore } from "../../stores/alert";
import Divider from "../Divider";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../Button";
import { get, isNil } from "lodash";
import { AlignItems, Display, FlexDirection, JustifyContent, Spacing } from "../v2/Styled/enum";
import { useMobile } from "../../hooks/mobile";
import { getValue } from "../../utils/object";

interface Props {
  booking: Booking;
  buttonDirection?: "column" | "row";
  showHeader?: boolean;
  bookingMutate: () => unknown;
}

export default function CouplesFallbackActions({
  booking,
  showHeader = false,
  bookingMutate,
}: Props): JSX.Element {
  const history = useHistory();
  const genderPreference1 = booking.bookingdetails[0]?.genderPreference;
  const [saving, setSaving] = React.useState(false);
  const [cancel, setCancel] = React.useState(false);
  const accessToken = useAccessToken();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const submitFallbackPrompt = ({
    type,
    allowFallback,
    options,
  }: {
    type: string;
    allowFallback: boolean;
    options: any;
  }) => {
    couplesFallbackResponse({ type, allowFallback, options });
  };

  const couplesFallbackResponse = ({
    type,
    allowFallback,
    options,
  }: {
    type: string;
    allowFallback: boolean;
    options: any;
  }) => {
    return axios
      .put(`/api/v2/bookings/fallback/${booking.id}/client-status`, {
        accessToken,
        type,
        allowFallback,
        options,
      })
      .then((response) => {
        bookingMutate();
        history.push("/bookings");
        setSuccessMessage("Your booking has been changed to back-to-back.");
      })
      .catch((error) => {
        setErrorMessage("Unable to update booking");
      });
  };

  const genderFallbackTimeReached = get(
    booking,
    "bookingdetails[0].genderFallbackTimeReached",
    false
  );
  const bookingStatus = get(booking, "status");

  // if (genderFallbackTimeReached === false || isNil(genderFallbackTimeReached) || bookingStatus !== 'new') return <></>;
  const isMobile = useMobile();
  const fallbackPrompt = booking.fallbackPrompt;

  const choices = getValue(fallbackPrompt, "notification.choices", []);

  const hasMultipleChoices = choices.length > 1;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {fallbackPrompt && (
        <>
          <Divider mt="16px" />

          <Box
            display={Display.Flex}
            flexDirection={
              isMobile || hasMultipleChoices ? FlexDirection.Column : FlexDirection.Row
            }
            justifyContent={JustifyContent.center}
            style={{
              gap: "32px",
            }}
          >
            <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
              <Box
                fontFamily="Museo"
                fontWeight={700}
                color={Colors.Dusk}
                fontSize="18px"
                textAlign="left"
                mt="16px"
              >
                {fallbackPrompt.notification.title}
              </Box>
              <Box
                fontFamily="Open Sans"
                fontSize="14px"
                color={Colors.Dusk}
                textAlign="left"
                mt="8px"
              >
                {fallbackPrompt.notification.description}
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection={
                hasMultipleChoices && !isMobile ? FlexDirection.Row : FlexDirection.Column
              }
              mt={isMobile || hasMultipleChoices ? "0px" : "16px"}
              alignItems={isMobile ? AlignItems.center : AlignItems.end}
              justifyContent={JustifyContent.spaceBetween}
              flex={1}
              style={{
                gap: hasMultipleChoices && !isMobile ? Spacing.S8 : undefined,
              }}
            >
              {fallbackPrompt.notification.choices.map((choice: any) => (
                <Button
                  loading={saving}
                  title={choice.label}
                  type={ButtonType.outlined}
                  size={ButtonSize.large}
                  onClick={() =>
                    submitFallbackPrompt({
                      type: fallbackPrompt.type,
                      ...choice.values,
                    })
                  }
                  fontStyle={{ fontFamily: "Museo", fontWeight: 600, fontSize: 14 }}
                  style={{
                    marginBottom: 8,
                    width: isMobile || hasMultipleChoices ? "100%" : "auto",
                    paddingLeft: Spacing.S4,
                    paddingRight: Spacing.S4,
                  }}
                />
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
