import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";

interface Props {
  percent: number;
}

const LinearProgressBar = ({ percent }: Props) => {
  return (
    <Box
      height="2px"
      borderRadius="3.5px"
      bgcolor={Colors.PaleLilac}
      width="100%"
      position="relative"
    >
      <Box
        width={`${percent}%`}
        height="2px"
        borderRadius="3.5px"
        left={0}
        position="absolute"
        bgcolor={Colors.Dusk}
      />
    </Box>
  );
}

export default LinearProgressBar;
