import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Colors } from "../../constants/colors";

interface PlaceholderProps extends BoxProps {}

export default function (props: PlaceholderProps) {
  return (
    <Box
      fontFamily="Museo"
      fontWeight={400}
      fontSize="16px"
      lineHeight="24px"
      textAlign="center"
      color={Colors.Dusk}
      {...props}
    />
  );
}
