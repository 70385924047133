import { Box } from "@mui/material";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { formatDateWithTimezone, isSameDay } from "../../../utils/date.util";
import { bookingIsComplete } from "../../../helpers/booking";
import { Booking } from "../../../models";

interface Props {
  booking: Booking;
}

const BookingItemDate = ({ booking }: Props) => {
  const isMobile = useMobile();
  const bookingComplete = bookingIsComplete(booking);

  const { earliestTime, latestTime, timezone, timeOfArrival } = booking;
  const showDateRange = !timeOfArrival && !isSameDay(earliestTime, latestTime, timezone);

  const getFormattedDay = ({
    timeOfArrival,
    earliestTime,
    latestTime,
    timezone,
  }: {
    timeOfArrival: string;
    earliestTime: string;
    latestTime: string;
    timezone: string;
  }) => {
    if (timeOfArrival) {
      return formatDateWithTimezone(timeOfArrival, timezone, "ddd");
    }
    if (showDateRange) return "Any day";

    return formatDateWithTimezone(earliestTime, timezone, "ddd");
  };

  const getFormattedDate = ({
    timeOfArrival,
    earliestTime,
    latestTime,
    timezone,
  }: {
    timeOfArrival: string;
    earliestTime: string;
    latestTime: string;
    timezone: string;
  }) => {
    if (timeOfArrival) {
      return formatDateWithTimezone(timeOfArrival, timezone, "D");
    }
    if (showDateRange) {
      const earliestDate = formatDateWithTimezone(earliestTime, timezone, "D");
      const latestDate = formatDateWithTimezone(latestTime, timezone, "D");
      return `${earliestDate}-${latestDate}`;
    }

    return formatDateWithTimezone(earliestTime, timezone, "D");
  };

  const getFormattedMonth = ({
    timeOfArrival,
    earliestTime,
    latestTime,
    timezone,
  }: {
    timeOfArrival: string;
    earliestTime: string;
    latestTime: string;
    timezone: string;
  }) => {
    if (timeOfArrival) {
      return formatDateWithTimezone(timeOfArrival, timezone, "MMM");
    }

    return formatDateWithTimezone(earliestTime, timezone, "MMM");
  };

  const getFormattedYear = ({
    timeOfArrival,
    earliestTime,
    latestTime,
    timezone,
  }: {
    timeOfArrival: string;
    earliestTime: string;
    latestTime: string;
    timezone: string;
  }) => {
    if (timeOfArrival) {
      return formatDateWithTimezone(timeOfArrival, timezone, "YYYY");
    }

    return formatDateWithTimezone(earliestTime, timezone, "YYYY");
  };

  const day = getFormattedDay(booking);
  const date = getFormattedDate(booking);

  const isSameMonth = isSameDay(earliestTime, latestTime, timezone, "month");

  const isSameYear = isSameDay(earliestTime, latestTime, timezone, "year");

  return (
    <Box flexDirection="column" alignSelf="center" mr={isMobile ? "16px" : "24px"} width={"74px"}>
      <Box fontFamily="Open Sans" fontSize="14px" color={Colors.BlueyGrey} textAlign="center">
        {day}
      </Box>
      <Box
        fontFamily="Museo"
        fontSize="20px"
        fontWeight={600}
        color={bookingComplete ? Colors.BlueyGrey : Colors.NightBlue}
        textAlign="center"
      >
        {date}
      </Box>
      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        color={bookingComplete ? Colors.BlueyGrey : Colors.TurquoiseBlue}
        textAlign="center"
      >
        {showDateRange ? (
          <Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              gap={isSameMonth ? "0px" : "9px"}
            >
              <Box textAlign={"right"}>{formatDateWithTimezone(earliestTime, timezone, "MMM")}</Box>

              {!isSameMonth ? (
                <Box textAlign={"left"}>{formatDateWithTimezone(latestTime, timezone, "MMM")}</Box>
              ) : (
                <></>
              )}
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"center"}
              gap={isSameYear ? "0px" : "9px"}
            >
              <Box textAlign={"right"}>
                {formatDateWithTimezone(earliestTime, timezone, "YYYY")}
              </Box>
              {!isSameYear ? (
                <Box textAlign={"left"}>{formatDateWithTimezone(latestTime, timezone, "YYYY")}</Box>
              ) : (
                <></>
              )}
            </Box>
          </Box>
        ) : (
          <Box textAlign={"center"}>
            <Box>{getFormattedMonth(booking)}</Box>
            <Box>{getFormattedYear(booking)}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BookingItemDate;
