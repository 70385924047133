import {useState } from "react";
import {
  Box,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog as MUIDialog,
} from "@material-ui/core";

import {
  Display,
  FlexDirection,
  Position,
  Spacing
} from "../../../../../components/v2/Styled/enum";
import StaticSectionControl from "../../../../../components/SectionControl/StaticSectionControl/SectionControl";

import OtherProviderNotes from "./ListSections/OtherProviderNotes";
import ProviderNotes from "./ListSections/ProviderNotes";
import Button, { ButtonType } from "../../../../../components/Button";
import Divider from "../../../../../components/Divider";

interface Props {
  visible: boolean;
  jobId: number | string;
  clientId: number | string;
  timezone: string;
  onClose: () => unknown;
  onAddNote: () => unknown;
  refreshJob: () => unknown;
  onEditNote: (note: any) => unknown;
  onDeleteNote: (note: any) => unknown;
}

const TreatmentNoteListModal = ({
  jobId,
  clientId,
  timezone,
  visible,
  onClose,
  refreshJob,
  onAddNote,
  onEditNote,
  onDeleteNote
}: Props) => {
  const [sectionIndex, setSectionIndex] = useState(0);

  const onSectionChange = (index: number) => {
    setSectionIndex(index);
  };

  const closeModal = () => {
    setSectionIndex(0);
    onClose();
  };

  return <MUIDialog
    fullWidth
    open={visible}
    onClose={closeModal}
    maxWidth={"md"}
  >
    <DialogTitle style={{ flex: 1, padding: "0px 0px" }}>
      <StaticSectionControl
        tabStyle={{ paddingTop: "33px" }}
        sections={["Your notes", "Notes from other providers"]}
        onSelectedIndex={onSectionChange} selectedIndex={sectionIndex}
        wrapperStyle={{ backgroundColor: "white" }}
      />
    </DialogTitle>
    <DialogContent
      style={{
        maxHeight: "calc(100vh - 200px)",
        overflowY: "auto",
        padding: "0px 0px",
      }}
    >
      <Box style={{ position: Position.Relative }}  display={Display.Flex} flexDirection={FlexDirection.Column}>
        
        <Box paddingX={Spacing.S10} paddingY={Spacing.S10}>
          {sectionIndex === 0 && <ProviderNotes
            jobId={jobId}
            clientId={clientId}
            timezone={timezone}
            refreshJob={refreshJob}
            onAddNote={onAddNote}
            onEditNote={onEditNote}
            onDeleteNote={onDeleteNote}
            showOtherNotes={() => onSectionChange(1)}
          />}
          {sectionIndex === 1 && <OtherProviderNotes clientId={clientId} jobId={jobId} timezone={timezone} />}
        </Box>
      </Box> 
    </DialogContent>
    <Box flexDirection={"column"}>
      {<Divider width="100%" height="2px" />}
      <DialogActions
        className="infoModalFooter"
        style={{ padding: "16px 24px 16px 24px" }}
      >
        <Button title="Close" type={ButtonType.outlined} onClick={closeModal} style={{ width: "auto" }}/>
      </DialogActions>
    </Box>
  </MUIDialog>;
};

export default TreatmentNoteListModal;
