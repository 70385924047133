import { PaymentMethod } from "../../../../../models";
import { IGovernmentData, IGovernmentOption } from "../../identity.interface";

export type TPersonalDetails = {
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  mobile?: string | null;
  dob?: Date | string | null;
  idNumber?: string | null;
  idNumberProvided?: boolean | null;
};

export type TAddress = {
  line1: string | null;
  line2?: string | null;
  state: string | null;
  city: string | null;
  postalCode: string | null;
  country: string | null;
};

export type TIdentityVerification = {
  document?: {} | null;
  documentStatus?: string | null;
  documentId: number | null;
  documentNumber: number | null;
  documentType: string | null;
  expiryDate: string | Date | null;
  documentPath: string | null;
  documentProvided?: boolean;
};

export type TAccountForPayout = {
  type: string | null;
  accountNumber: string | null;
  confirmAccountNumber: string | null;
  routingNumber: string | null;
  token: string | null;
  details?: PaymentMethod;
};

export interface IFormData {
  personalDetails: TPersonalDetails;
  address: TAddress;
  identityVerification: TIdentityVerification;
  accountForPayout: TAccountForPayout;
}

export enum PayoutAccount {
  BankAccount = "bank_account",
  Card = "card",
}

export enum AdditionalInformation {
  BankAccountOwnerVerification = "bankAccountOwnerVerification",
  IdentityVerification = "identityVerification",
  AddressVerification = "addressVerification",
  PersonalDetails = "personalDetails",
  ExternalAccount = "externalAccount",
  Other = "Other",
}

export enum FormStepsName {
  PersonalDetails = "personalDetails",
  Address = "address",
  IdentityVerification = "identityVerification",
  AccountForPayout = "accountForPayout",
  ReviewAndConfirm = "reviewAndConfirm",
}

export const FormStepIndex = {
  [FormStepsName.PersonalDetails] : 1,
  [FormStepsName.Address] : 2,
  [FormStepsName.IdentityVerification] : 3,
  [FormStepsName.AccountForPayout] : 4,
  [FormStepsName.ReviewAndConfirm] : 5,
};

export enum FormFieldType {
  Text = "text",
  Email = "email",
  Select = "select",
  Radio = "radio",
  Date = "date",
  Sensitive = "sensitive",
  TextSubSection = "textSubSection",
}

export type StripeOnboardingFormFiled = {
  name: string;
  type: FormFieldType;
  key: string;
  label: string;
  description: string;
  required: boolean;
  disabled: boolean;
  fields: Array<Record<string, any>>;
  value: Array<Record<string, any>>;
  defaultValue?: Array<Record<string, any>>;
};

export type FormValueType = {
  label: string;
  value: string;
  description: string;
};

export type TFormData = TPersonalDetails | TAddress | TIdentityVerification | TAccountForPayout;
export type TName =
  | keyof TPersonalDetails
  | keyof TAddress
  | keyof TIdentityVerification
  | keyof TAccountForPayout;

export const FormDataModal = {
  personalDetails: {
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    dob: null,
    idNumber: null,
  } as TPersonalDetails,
  identityVerification: {
    documentId: null,
    documentNumber: null,
    expiryDate: null,
    documentPath: null,
  } as TIdentityVerification,
  accountForPayout: {
    type: null,
    accountNumber: null,
    confirmAccountNumber: null,
    routingNumber: null,
    token: null,
  } as TAccountForPayout,
  address: {
    line1: null,
    line2: null,
    state: null,
    city: null,
    postalCode: null,
    country: null,
  } as TAddress,
};

export const initialGovernmentData = {
  documentType: "",
  selectedDocument: {
    text: "",
    date: null,
  },
  selectedFiles: [],
} as IGovernmentData;

export const GOVERNMENT_DOCUMENT_TYPE = {
  DRIVERS_LICENSE: "driversLicense",
  PASSPORT: "passport",
};

export const GOVERNMENT_DOCUMENT_TYPE_LABEL = {
  [GOVERNMENT_DOCUMENT_TYPE.DRIVERS_LICENSE]: "Driver License",
  [GOVERNMENT_DOCUMENT_TYPE.PASSPORT]: "Passport",
};

export const identityOptions = [
  {
    title: GOVERNMENT_DOCUMENT_TYPE_LABEL[GOVERNMENT_DOCUMENT_TYPE.DRIVERS_LICENSE],
    value: GOVERNMENT_DOCUMENT_TYPE.DRIVERS_LICENSE,
    fields: [
      {
        name: "licenseNumber",
        title: "License number",
        type: "text",
        placeholder: "",
        validationMessage: "Please enter your license number",
      },
      {
        name: "expiryDate",
        title: "Expiry date",
        type: "date",
        placeholder: "",
        validationMessage: "Please select expiry date",
      },
    ],
    note: "Please include the front and back of the driver licence",
  },
  {
    title: GOVERNMENT_DOCUMENT_TYPE_LABEL[GOVERNMENT_DOCUMENT_TYPE.PASSPORT],
    value: GOVERNMENT_DOCUMENT_TYPE.PASSPORT,
    fields: [
      {
        name: "documentNumber",
        title: "Document number",
        type: "text",
        placeholder: "",
        validationMessage: "Please enter your document number",
      },
      {
        name: "expiryDate",
        title: "Expiry date",
        type: "date",
        placeholder: "",
        validationMessage: "Please select expiry date",
      },
    ],
    note: "",
  },
] as IGovernmentOption[];
