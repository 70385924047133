import React from "react";
import { Box, Link, styled } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { styles } from "./styles";
import TextLink from "../../TextLink";
import Tooltip from "../../Tooltip/Tooltip";
import { formatUrl } from "../../../utils/url";
import { Colors } from "../../../constants/colors";
import FileIcon from "../../../images/file-icon.svg";
import LinkIcon from "../../../images/link-icon-v2.svg";
import { getLastElement } from "../../../utils/array";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import { AGE_CARE_TERMS_DOCUMENTS_TYPE, USER_UPLOADED_CONTENT_TYPE } from "../../../data/enums";
import { AlreadyUploadedDocs, TermsDocumentType } from "./TermsAndPolicies";

interface Props {
  attachBaseURL?: boolean;
  document: AlreadyUploadedDocs;
  documentTypes: TermsDocumentType[];
  handleRemoveDoc?: (id: number) => void;
  style?: React.CSSProperties;
}

const MTableCell = styled(TableCell)({
  borderBottom: 0,
  padding: 0,
  paddingBottom: "8px",
});

const TermsRow = ({
  document,
  documentTypes,
  handleRemoveDoc,
  style,
  attachBaseURL = true,
}: Props): JSX.Element => {
  const { id, filePath = "", type, contentType, name } = document;
  const isDocumentTypeLink = contentType === USER_UPLOADED_CONTENT_TYPE.LINK;
  const fileName = isDocumentTypeLink ? filePath : getLastElement(filePath.split("/"));
  const documentType = (documentTypes || []).find(({ value }) => value === type)?.title;

  const docType =
    type === AGE_CARE_TERMS_DOCUMENTS_TYPE.OTHER_AGE_CARE_REQIREMENT
      ? name || "Other"
      : documentType;
  const displayIcon = isDocumentTypeLink ? LinkIcon : FileIcon;

  const getFileURL = () => {
    if (isDocumentTypeLink) return formatUrl(filePath);

    return attachBaseURL ? `${BASE_UPLOADS_URL}/${filePath}` : filePath;
  };

  const fileURL = getFileURL();

  return (
    <TableRow key={id} style={style}>
      <MTableCell>
        <Box style={styles.existingFileRow}>
          <img src={displayIcon} alt={contentType} style={{ width: "32px", height: "32px" }} />
          <Tooltip title={fileName} style={styles.tooltip}>
            <Link
              style={{ ...styles.fileLink, ...styles.dusk }}
              onClick={() => {
                window.open(fileURL, "_blank");
              }}
              noWrap
            >
              <Box style={{ ...styles.description, ...styles.fullWidth }}>{fileName}</Box>
            </Link>
          </Tooltip>
        </Box>
      </MTableCell>

      <MTableCell padding={"none"}>
        <Box style={{ ...styles.description, ...styles.dusk }}>{docType}</Box>
      </MTableCell>

      {!!handleRemoveDoc && (
        <MTableCell padding={"none"}>
          <TextLink
            title="Remove"
            color={Colors.Danger}
            fontSize={"14px"}
            style={{ lineHeight: "21px", cursor: "pointer" }}
            onClick={() => handleRemoveDoc(id)}
          />
        </MTableCell>
      )}
    </TableRow>
  );
};

export default TermsRow;
