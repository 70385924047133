import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import AddressItem from "../../components/Addresses/AddressItem";
import AddressModal from "../../components/Addresses/AddressModal";
import Button, { ButtonType } from "../../components/Button";
import NewBookingWrapper from "../../components/NewBookingWrapper";
import TextLink from "../../components/TextLink";
import { Paths } from "../../constants/paths";
import { parseApiError } from "../../helpers/error";
import { isLoggedIn } from "../../helpers/user";
import { Address, Booking } from "../../models";
import { fetchAddresses, useAddressStore } from "../../stores/address";
import { useAlertStore } from "../../stores/alert";
import {
  generateHandleNext,
  getProductsForSegmentTracking,
  getServicesRates,
  getSubtotal,
  getTotal,
  getTreatmentId,
  isMassageType,
  resetAnswers,
  setServiceRates,
  updateBooking,
  useBookingStore,
  useLocationDetailsStore,
  usePaymentStore,
  useServicesStore,
} from "../../stores/booking";
import { fetchLocationTypes } from "../../stores/types";
import { setCountryCode } from "../../utils/country";
import { STEP_PROGRESS } from "../../constants/booking";
import { getValue, checkIfNil } from "../../utils/object";
import { removeUserAddress } from "../../services/users/users.service";
import { trackEvent } from "../../services/segment/track.service";
import { useServiceRates } from "../../hooks/services/rates.hooks";
import { PaginatedResponse3 } from "../../models";
import PaginationControl from "../../components/PaginationControl";

export default function Bookings(): JSX.Element {
  const history = useHistory();
  const { state } = useLocation();

  const {
    setAddress: setSelectedAddress,
    updatingForBooking,
    address: selectedAddress,
    resetTreatmentDetails,
    sessionType,
    recipient,
    setAddress,
    setServiceId,
    address,
  } = useBookingStore();

  const { setCountryCode: setNewAddressCountryCode } = useLocationDetailsStore();

  const { addresses } = useAddressStore();

  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [selectedAddressForEdit, setSelectedAddressForEdit] = useState<Address | null>(null);

  const { bookingPrice } = usePaymentStore();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const [saving, setSaving] = useState(false);

  const [previousAddress, setPreviousAddress] = useState<Address | null>(null);
  const { services } = useServiceRates(selectedAddress?.countryCode);

  const [page, setPage] = React.useState(1);
  const [paginateResponse, setpaginateResponse] = useState<PaginatedResponse3>();

  useEffect(() => {
    if (updatingForBooking) {
      setInitialAddress({ address: updatingForBooking.address });
      return;
    }

    fetchLocationTypes();
    trackInitEvents();

    // Pre-select last booking's address
    if (isLoggedIn() && checkIfNil(address)) {
      axios
        .get("/bookings/last?channel=web", {
          params: {
            accessToken: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const booking = response.data as Booking;
          if (booking && booking.address) {
            setInitialAddress({ address: booking.address });
            setAddress(booking.address);
          }
        })
        .catch((error) => {
          const err = parseApiError(error);
          const errMessage = typeof err == "string" ? err : error.reason;
          setErrorMessage(errMessage);
        });
    } else {
      if (address) {
        setInitialAddress({ address });
      }
    }
  }, []);

  useEffect(() => {
    const getAddresses = async () => {
      const res = await fetchAddresses(page);
      setpaginateResponse({
        page: res?.page,
        total: res?.totalPage,
        perPage: res?.perPage,
      });
    };
    getAddresses();
  }, [page]);

  const analyticsValues = () => {
    const isMassage = isMassageType();
    return {
      version: 2,
      currency: "AUD",
      total: getTotal(),
      affiliation: "web",
      subtotal: getSubtotal(),
      product_id: getTreatmentId(),
      session_type: isMassage ? sessionType : undefined,
      recipient_role: recipient?.relationship,
      recipient_gender: recipient?.gender,
      peak_hour: bookingPrice?.lateNightSurcharge,
      location_type: selectedAddress?.type,
      location_parking: selectedAddress?.parking,
      location_stairs: selectedAddress?.stairs,
      products: getProductsForSegmentTracking(),
    };
  };

  const trackInitEvents = () => {
    const data = analyticsValues();
    trackEvent("Checkout Started", data);
    trackEvent("Location Viewed", {
      step: 1,
      version: 2,
    });
  };

  // set initial addresses
  const setInitialAddress = async ({ address }: { address: Address }) => {
    setPreviousAddress(address);
  };

  const hasCountryCodeChanged = () => selectedAddress?.countryCode !== previousAddress?.countryCode;
  const hasTimezoneChanged = () => selectedAddress?.timezone !== previousAddress?.timezone;

  const handleAddressSelected = ({ address }: { address: Address }) => {
    setSelectedAddress(address);

    const countryCode = getValue(address, "selectedCountry.code", "AU");
    setCountryCode(countryCode);
    setNewAddressCountryCode(countryCode);
  };

  const handleAddressEdit = ({ address }: { address: Address }) => {
    setSelectedAddressForEdit(address);
    setAddressModalOpen(true);
  };

  const handleAddressRemove = ({ addressId }: { addressId: number }) => {
    if (selectedAddress?.id === addressId) {
      setSelectedAddress(null);
    }
    removeUserAddress({ addressId })
      .then(() => {
        fetchAddresses();
        setSuccessMessage("Address successfully deleted.");
      })
      .catch((err) => setErrorMessage(err));
  };

  // handles if country switches when address change
  const handleAddressCountry = () => {
    const countryChanged = hasCountryCodeChanged();
    if (!countryChanged) return;

    // when address changed the booking country

    // reset current treatment details
    resetTreatmentDetails();
    setServiceId(null);

    // reFetch services
    setServiceRates(services);
    // getServicesRates();

    //reset booking questions and answers
    useServicesStore.setState({
      questions: [],
      answers: [],
    });

    // redirect to new booking page
    const locationState: any = {};
    const isFirstBooking = getValue(state, "firstBooking", false);
    if (!isFirstBooking) {
      locationState.next = Paths.ReviewAndBook;
    }
    history.push(Paths.ServiceDetails, locationState);
    return true;
  };

  const handleContinue = () => {
    if (checkIfNil(selectedAddress) || !selectedAddress) {
      setErrorMessage("Please select an address");
      return;
    }

    trackEvent("Location Completed", {
      step: 1,
      location_type: selectedAddress.type,
      location_parking: selectedAddress.parking,
      location_stairs: selectedAddress.stairs,
      version: 2,
    });

    // new booking
    if (!updatingForBooking) {
      const countryChanged = handleAddressCountry();
      if (countryChanged) return;

      let nextPath = getValue(state, "next", Paths.ServiceDetails);
      history.push(nextPath, { preFetchPaymentMethod: false });
      return;
    }

    const addressCountryChanged = hasCountryCodeChanged();
    if (addressCountryChanged) {
      setErrorMessage("Address cannot be updated to a new country");
      return;
    }

    const addressTimezoneChanged = hasTimezoneChanged();
    if (addressTimezoneChanged) {
      setErrorMessage("Address cannot be updated to a new timezone");
      return;
    }

    // if country not changed, update booking
    setSaving(true);
    updateBooking()
      .then(() => {
        setSaving(false);
        history.replace(`/bookings/${updatingForBooking.id}`);
      })
      .catch((error) => {
        setSaving(false);
        setErrorMessage(parseApiError(error));
      });
  };

  const handleGoBack = () => {
    const accessToken = localStorage.getItem("token");
    !checkIfNil(accessToken) ? history.goBack() : history.push("/");
  };

  const handleAddLocation = () => {
    setSelectedAddressForEdit(null);
    setSelectedAddress(null);
    setAddressModalOpen(true);
    setPage(1);
  };

  const handleSaveAddress = ({ address }: { address: Address }) => {
    const countryCode = address.countryCode || "AU";
    setSelectedAddress(address);
    setCountryCode(countryCode);
    setNewAddressCountryCode(countryCode);

    setAddressModalOpen(false);
    fetchAddresses();

    window.scrollTo(0, 0);
  };

  const handleNextObject = generateHandleNext(handleContinue);

  return (
    <>
      <NewBookingWrapper
        overline="Step 1 of 7"
        title="Booking location"
        // subtitle="Select from your previously selected or add a new one"
        footerLeftButton={
          <Button
            type={ButtonType.outlined}
            title={updatingForBooking ? "Back" : "Previous"}
            onClick={handleGoBack}
            style={{ padding: "12px 24px" }}
          />
        }
        nextGeneratorObject={handleNextObject}
        footerRightButton={
          <Button
            loading={saving}
            type={updatingForBooking ? ButtonType.secondary : ButtonType.indigo}
            title={updatingForBooking ? "Save & apply" : "Continue"}
            onClick={() => handleNextObject.next()}
            style={{ padding: "12px 24px" }}
          />
        }
        progress={STEP_PROGRESS[1]}
      >
        <Box mt={4}>
          {addresses &&
            addresses.map((address: Address) => (
              <AddressItem
                address={address}
                selected={selectedAddress?.id === address.id}
                onSelected={() => handleAddressSelected({ address })}
                onEditClicked={() => handleAddressEdit({ address })}
                onDeleteClicked={() => handleAddressRemove({ addressId: address.id })}
              />
            ))}
        </Box>

        <Box
          mt={4}
          mb={4}
          display="flex"
          flexWrap={true}
          alignItems="center"
          justifyContent="space-between"
        >
          <TextLink title="Add new location" onClick={handleAddLocation} />

          <PaginationControl
            currentPage={paginateResponse?.page!}
            pages={paginateResponse?.total!}
            onPrevious={() => setPage(page - 1)}
            onNext={() => setPage(page + 1)}
            startFromOne={true}
          />
        </Box>
      </NewBookingWrapper>
      <AddressModal
        open={addressModalOpen}
        existingAddress={selectedAddressForEdit}
        onClose={() => setAddressModalOpen(false)}
        onSaved={(address) => handleSaveAddress({ address })}
      />
    </>
  );
}
