import Box from "@material-ui/core/Box";
import { CSSProperties } from "react";
import { Colors } from "../../../constants/colors";
import { FontSize } from "../../v2/Styled/enum";

type Props = {
  text: string;
  style?: CSSProperties;
};

const TextMuted = ({ text, style }: Props) => {
  return (
    <Box
      style={{
        width: "100%",
        letterSpacing: "-0.5px",
        fontFamily: "SF UI Text",
        fontSize: FontSize.F14,
        color: Colors.BlueyGrey,
        ...(style || {}),
      }}
    >
      {text}
    </Box>
  );
};

export default TextMuted;
