import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import HeroImage from "../../../images/no-bookings-placeholder.svg";
export const PastBookingPlaceholder = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <img src={HeroImage} alt="placeholder" />
      <Box
        fontFamily="Museo"
        fontSize="20px"
        textAlign="center"
        fontWeight={700}
        color={Colors.Dusk}
        mt="19px"
      >
        No bookings found
      </Box>

      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        color={Colors.Dusk}
        letterSpacing="0.39px"
        textAlign="center"
        mt="10px"
      >
        Please try again with different filters.
      </Box>
    </Box>
  );
};
