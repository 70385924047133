import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Job, TherapistBooking } from "../../../models";
import SegmentedControl from "../../../components/SegmentedControl";
import BookingItem from "../../../components/ProDashboard/BookingItem";
import { BOOKINGS_SECTION_VALUES, TOGGLE_VALUES } from "../../../constants/prodashboard";
import BookingsMap from "./BookingsMap";
import { environment } from "../../../constants/common";

interface BookingsViewProps {
  bookings?: TherapistBooking[];
  handleJobOpen?: ({ jobId }: { jobId: number }) => unknown;
  jobs?: Job[];
  openAbandonBookingModal?: (booking: any) => unknown;
  tab: string;
  showPagination: (show: boolean) => void;
}
const BookingsView = ({
  bookings,
  openAbandonBookingModal,
  tab,
  jobs,
  handleJobOpen,
  showPagination,
}: BookingsViewProps) => {
  const [selectedSegment, setSelectedSegment] = useState(0);

  useEffect(() => {
    selectedSegment === 0 ? showPagination(true) : showPagination(false);
  }, [selectedSegment]);

  return (
    <Box mt="24px">
      <SegmentedControl
        segments={TOGGLE_VALUES}
        selectedSegmentIndex={selectedSegment}
        onSelectedIndex={(index) => setSelectedSegment(index)}
      />
      <Box mt="24px">
        {selectedSegment === 0 ? (
          <Box>
            {tab === BOOKINGS_SECTION_VALUES.NEW &&
              jobs &&
              jobs.map((job: Job) => <BookingItem job={job} onClick={handleJobOpen} />)}

            {tab === BOOKINGS_SECTION_VALUES.UPCOMING &&
              jobs &&
              jobs.map((booking: Job) => (
                <BookingItem
                  job={booking}
                  showAbandonBookingBtn={true}
                  openAbandonBookingModal={openAbandonBookingModal}
                  onClick={handleJobOpen}
                  fromProDash
                />
              ))}
          </Box>
        ) : (
          <Box>
            <BookingsMap bookings={bookings} jobs={jobs} tab={tab} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default BookingsView;
