import React, { createContext } from "react";

type State = {
  loading: boolean;
  error: boolean;
  serviceArea: any;
  radius: any[];
  message: string;
};

type Action = {
  type: string;
  payload: string;
};

type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const initialState = {
  loading: true,
  error: false,
  serviceArea: {},
  radius: [],
  message: "",
};

export const ServiceAreaContext = createContext<Context | undefined>(undefined);
