import { useEffect, useState } from "react";
import { useCountryOptions } from "../hooks/utility/utilty.hooks";
import Dropdown, { DropdownOption } from "./Dropdown";

interface Prop {
  onChange: (value: string | number) => unknown;
  value?: string | number;
  label?: string;
  defaultValue?: string | null;
}

export default function SelectCountry({
  onChange,
  value,
  label = "Country",
  defaultValue,
}: Prop) {
  const { countries } = useCountryOptions();
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );

  useEffect(() => {
    if(!value && defaultValue === null) {
      setSelectedOption(null);
      return;
    }
    const country = countries.find((country) => country.value === value);
    setSelectedOption(country || countries[0]);
  }, [countries, value]);

  const handleSelected = (option: DropdownOption) => {
    onChange(option.value);
  };

  return (
    <Dropdown
      title={label}
      options={countries}
      selectedOption={selectedOption}
      onSelectedOption={handleSelected}
    />
  );
}
