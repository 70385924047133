import { useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import { getValue } from "../../utils/object";

const key = {
  GET_HEALTH_FUNDS: "GET_HEALTH_FUNDS",
};

export const useGetHealthFundsOptions = () =>
  useQuery(
    [key.GET_HEALTH_FUNDS],
    async () =>
      await httpClient.get("healthFunds").then((res) => {
        return res.map((data: []) => ({
          title: getValue(data, "name"),
          value: getValue(data, "id"),
        }));
      })
  );
