import { JobStatus } from "../constants/booking";
import { getValue } from "../utils/object";
import { compileDescriptionForPayoutTimeline } from "../components/ProDashboard/PastBookings/PayoutTimeline.transformer";
import { isArray } from "../utils/array";

export const getAmountAndTitleForPayout = ({ job }: { job: any }) => {
  let title = "Earning";
  let amount = getValue(job, "amount");
  const currency = getValue(job, "currencySymbol", "A$");
  const isDebit = getValue(job, "isDebit", false);
  const jobStatus = getValue(job, "status");
  if (isDebit) {
    title = "Cancellation fee";
  } else {
    if (jobStatus === JobStatus.cancelled) {
      title = "Payout";
    }
  }

  return { payouTitle: title, amountText: `${currency}${amount}` };
};

export const getJobStatus = ({ job }: { job: any }) => {
  const status = getValue(job, "status");
  if (getValue(job, "isDebit", false)) {
    return JobStatus.cancelled;
  }
  return status;
};

export const getBookingDateTitle = ({ status, isDebit }: { status: string; isDebit: boolean }) => {
  if (isDebit) {
    return "Abandoned date";
  }
  if (status === JobStatus.cancelled) {
    return "Cancelled date";
  }

  return "Completed date";
};
