import { Box } from "@material-ui/core";
import { CSSProperties, ReactNode } from "react";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

const CardWrapper = ({ children, style }: Props) => {
  const isMobile = useMobile();

  return (
    <Box
      style={{
        ...cardWrapperStyles.card,
        width: isMobile ? undefined : "400px",
        flexDirection: "column",
        ...(style || {}),
      }}
    >
      {children}
    </Box>
  );
};

export const cardWrapperStyles = {
  card: {
    gridGap: "24px",
    display: "flex",
    marginTop: "16px",
    borderRadius: "6px",
    border: `solid 1px ${Colors.PaleLilac}`,
    backgroundColor: "white",
    padding: "32px 32px",
  },
};

export default CardWrapper;
