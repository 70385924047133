import { Box, Typography } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import LandingWrapper from "../../components/LandingWrapper";
import LogoLink from "../../components/LogoLink";
import MetaTags from "../../components/Voucher/MetaTags";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import logo from "../../images/blys-logo.svg";
import { Purchase } from "../../models";
import { useUserStore } from "../../stores/user";
import { getFormatRespectToCountry } from "../../utils/date.util";

interface RowProps {
  title: string;
}

function Row({ title }: RowProps) {
  return (
    <Box
      ml={1.6}
      mr={1.6}
      fontFamily="Museo"
      fontSize="16px"
      color={Colors.Dusk}
      style={{ overflowWrap: "break-word" }}
      width="100%"
      height="30px"
    >
      {title}
    </Box>
  );
}

interface SummaryRowProps {
  title: string;
  value: string;
}

function SummaryRow({ title, value }: SummaryRowProps) {
  return (
    <Box mt={0.5} mb={0.5} ml={1.6} mr={1.6}>
      <Box display="flex" flexDirection="row">
        <Box flex={1} fontFamily="Museo" fontSize="14px" color={Colors.Dusk}>
          {title}
        </Box>
        <Box
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
          textAlign="right"
        >
          {value}
        </Box>
      </Box>
    </Box>
  );
}

export default function PurchaseVoucherSuccess() {
  const isMobile = useMobile();
  const history = useHistory();

  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const purchaseId = query.get("purchaseId");

  const accessToken = useAccessToken();
  const { user } = useUserStore();

  const { data: purchaseData } = useSWR(`/giftVouchers/${purchaseId}?accessToken=${accessToken}`);

  const purchase = purchaseData ? (purchaseData as Purchase) : null;

  const _couponUsedAtCheckoutValue = () => {
    if (purchase?.couponUsedAtCheckout) {
      if (purchase?.couponUsedAtCheckout?.type === "percent") {
        return `${purchase?.couponUsedAtCheckout?.value?.toFixed(2)}%`;
      } else {
        return `$${purchase?.couponUsedAtCheckout?.value?.toFixed(2)}`;
      }
    }

    return undefined;
  };

  const couponUsedAtCheckoutValue = _couponUsedAtCheckoutValue();

  return (
    <>
      <LandingWrapper showPromoImages={false}>
        <MetaTags />
        <Box width={isMobile ? undefined : "400px"}>
          <LogoLink />
          <Typography
            variant="h1"
            style={{
              color: Colors.NightBlue,
              marginTop: "48px",
              marginBottom: "32px",
            }}
          >
            Blys Voucher
          </Typography>

          {purchase && purchase.coupon && (
            <Box
              display="flex"
              flexDirection="column"
              width={"100%"}
              borderRadius="6px"
              border={`solid 1px ${Colors.PaleLilac}`}
              bgcolor="white"
              justifyContent="center"
              mb={4}
              pt={3}
              pb={2}
              pl={1}
              pr={1}
            >
              <Row title={`Gift voucher for ${purchase.coupon.couponText}`} />
              <Row title={`From: ${purchase.fromName}`} />
              <Row title={`To: ${purchase.toName}`} />

              {!isEmpty(purchase.note) && (
                <Row title={`Note: ${purchase.note}`} />
              )}

              <Row
                title={`Delivered to: ${
                  purchase.sendTo === "recipient"
                    ? purchase.recipientEmail
                    : `${purchase.user.email}`
                }`}
              />
              <Row
                title={`Delivery date: ${
                  purchase.sendOnDate && purchase.sendASAP === "specificDate"
                    ? moment(purchase.sendOnDate).format(getFormatRespectToCountry(user?.country))
                    : "ASAP"
                }`}
              />

              <Box mt={3} mb={1}>
                <SummaryRow
                  title="Subtotal"
                  value={`$${purchase?.priceWithoutDiscount.toFixed(2)}`}
                />

                {!isNil(purchase.couponUsedAtCheckout) && (
                  <SummaryRow
                    title={`Coupon (${purchase.couponUsedAtCheckout.code})`}
                    value={`-${couponUsedAtCheckoutValue}`}
                  />
                )}
              </Box>

              <Box
                fontFamily="Museo"
                fontSize="16px"
                fontWeight={700}
                color={Colors.Dusk}
                display="flex"
                flexDirection="row"
                bgcolor={Colors.PaleGrey}
                height="54px"
                alignItems="center"
                borderRadius="6px"
                ml={1}
                mr={1}
              >
                <Box flex={1} ml="19px">
                  Total
                </Box>
                <Box mr="19px">${purchase.price?.toFixed(2)}</Box>
              </Box>
            </Box>
          )}

          <Box height="24px" />
          <Button
            width="100%"
            type={ButtonType.secondary}
            size={ButtonSize.large}
            title="Continue"
            onClick={() => {
              history.push(Paths.Purchases);
            }}
          />
        </Box>
      </LandingWrapper>
    </>
  );
}
