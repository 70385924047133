import { Box } from "@material-ui/core";
import { ceil, isEmpty, isNil } from "lodash";
import React from "react";
import useSWR from "swr";
import ContentSectionControl from "../../components/ContentSectionControl";
import PaginationControl from "../../components/PaginationControl";
import BlockedClientItem from "../../components/ProDashboard/BlockedClientItem";
import Placeholder from "../../components/ProDashboard/NoItemsPlaceholder";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import ReviewItem from "../../components/ProDashboard/ReviewItem";
import Wrapper from "../../components/Wrapper";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import {
  BlockedClient,
  PaginatedResponse,
  TherapistUserReview,
} from "../../models";

const sections = ["Received", "Given", "Blocked"];

const perPage = 10;

export default function () {
  const accessToken = useAccessToken();
  const isMobile = useMobile();

  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);


  const [currentPage, setCurrentPage] = React.useState(0);

  const _apiUrl = () => {
    if (selectedSectionIndex === 0) {
      return `/reviews/me?accessToken=${accessToken}&currentPage=${
        currentPage + 1
      }&perPage=${perPage}`;
    }

    if (selectedSectionIndex === 1) {
      return `/reviews/byMe?accessToken=${accessToken}&currentPage=${
        currentPage + 1
      }&perPage=${perPage}`;
    }

    if (selectedSectionIndex === 2) {
      return `/therapists/blockedClients?accessToken=${accessToken}&currentPage=${
        currentPage + 1
      }&perPage=${perPage}`;
    }

    return null;
  };

  const apiUrl = _apiUrl();

  const { data ,revalidate} = useSWR(apiUrl);

  const _items = () => {
    if (selectedSectionIndex === 0 || selectedSectionIndex === 1) {
      return data && (data.reviewsList as TherapistUserReview[]);
    }

    if (selectedSectionIndex === 2) {
      return data && (data.blockedClients as BlockedClient[]);
    }

    return [];
  };

  let items = _items();

  const _pages = () => {
    const paginatedResponse = data && (data as PaginatedResponse);

    if (paginatedResponse) {
      const { objectCount, perPage } = paginatedResponse;
      return ceil(objectCount / perPage);
    }

    return 0;
  };

  const pages = _pages();

  let noItems = !isNil(data) && isEmpty(items);

  React.useEffect(() => {
    setCurrentPage(0);
  }, [selectedSectionIndex]);


  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Reviews" />

        <Box
          mt="40px"
          ml={isMobile ? "16px" : "80px"}
          mr={isMobile ? "16px" : "80px"}
        >
          <ContentSectionControl
            sections={sections}
            selectedIndex={selectedSectionIndex}
            onSelectedIndex={(index) => setSelectedSectionIndex(index)}
          />

          {noItems && (
            <Box
              width="100%"
              height="70vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Placeholder>{selectedSectionIndex === 2?'No blocked clients':'No reviews to display'}</Placeholder>
            </Box>
          )}
          {!noItems && (
            <Box mt="16px" mb="16px">
              {(selectedSectionIndex === 0 || selectedSectionIndex === 1) &&
                items &&
                items.map((item: TherapistUserReview) => (
                  <ReviewItem review={item} />
                ))}

              {selectedSectionIndex === 2 &&
                items &&
                items.map((item: BlockedClient) => (
                  <BlockedClientItem blockedClient={item} key={item.id} revalidate={revalidate} />
                ))}
            </Box>
          )}

          {pages > 0 && (
            <Box
              display="flex"
              justifyContent="flex-start"
              width="100%"
              mb="55px"
            >
              <PaginationControl
                currentPage={currentPage}
                pages={pages}
                onPrevious={() => {
                  if (currentPage > 0) {
                    setCurrentPage(currentPage - 1);
                  }
                }}
                onNext={() => {
                  if (currentPage < pages - 1) {
                    setCurrentPage(currentPage + 1);
                  }
                }}
              />
            </Box>
          )}
        </Box>
      </Wrapper>
    </>
  );
}
