import { Colors } from "../../../constants/colors";

const styles = {
  personText: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "150%",
    color: Colors.Indigo,
  },
  title: {
    fontWeight: 500,
    color: Colors.Dusk,
    lineHeight: "150%",
    fontSize: "14px",
    fontStyle: "normal",
  },
  subText: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: "150%",
    fontFamily: "Open Sans",
    color: Colors.BlueyGrey,
  },
};

export default styles;
