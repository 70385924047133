import { checkIfEqual } from "../../utils/object";
import { IFormData } from "../../pages/ProDashboard/Business/Payouts/Forms/model";
import { getPayloadForAccountUpdate } from "./transformer/stripeTransformer.service";

const checkIfDetailsUpdated = ({
  data,
  payoutDetails,
}: {
  data: IFormData;
  payoutDetails: IFormData;
}) => {
  const dataPayload = getPayloadForAccountUpdate(data);
  const payoutDetailsPayload = getPayloadForAccountUpdate(payoutDetails);

  return !checkIfEqual(dataPayload, payoutDetailsPayload);
};
export { checkIfDetailsUpdated };
