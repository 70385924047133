import React, { useEffect } from "react";
import { useCountDown } from "@get-blys/hooks.use-count-down";

interface Props {
  time: string;
  setIsActionDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  style?:object;
}

const CountdownTimer = ({ time, setIsActionDisabled, style={} }: Props) => {
  
  const countDown = useCountDown({ createdAt: time, format: "ss" });

  useEffect(() => {
    if (!countDown) {
      setIsActionDisabled(false);
    }
  }, [countDown]);

  return countDown ? (
    <span className="disabled" style={{...style}}  >{` in ${countDown}s `}</span>
  ) : null;
};

export default CountdownTimer;
