import { BOOKING_FALLBACK_STATUS, BOOKING_STATUS, JobStatus } from "../constants/booking";
import { Booking, Job } from "../models";
import { getValue } from "../utils/object";

export const checkIfBackToBackUpdateRequired = (booking: Booking, job: Job) => {
  const fallback = booking.bookingFallbacks ? booking.bookingFallbacks[0] : null;

  return (
    fallback &&
    getValue(fallback, "proStatus") === BOOKING_FALLBACK_STATUS.PENDING &&
    job.status === JobStatus.accepted &&
    booking.status === BOOKING_STATUS.NEW
  );
};

export const checkIfFallbackUpdateRequestHasProviderPendingStatus = (booking: Booking) => {
  const fallback = booking.bookingFallbacks ? booking.bookingFallbacks[0] : null;

  return (
    fallback &&
    getValue(fallback, "proStatus") === BOOKING_FALLBACK_STATUS.PENDING &&
    booking.status === BOOKING_STATUS.NEW
  );
};
