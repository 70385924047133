import { Box } from "@material-ui/core";
import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import Button, { ButtonType } from "../../../components/Button";

interface Props {
  onAccept: () => unknown;
  onDecline: () => unknown;
  onAlternateOffer: () => unknown;
  allowAlternateOffer: boolean;
  disabled: boolean;
  CanChatBtn: React.ReactElement;
}

const JobActions = ({
  onAccept,
  onDecline,
  onAlternateOffer,
  allowAlternateOffer,
  disabled,
  CanChatBtn,
}: Props) => {
  return (
    <CardWrapper>
      <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <Button
            title="Decline"
            type={ButtonType.outlined}
            onClick={onDecline}
            disabled={disabled}
          />
          <Button
            title="Accept"
            type={ButtonType.secondary}
            onClick={onAccept}
            disabled={disabled}
          />
        </Box>
        {allowAlternateOffer ? (
          <Box>
            <Button
              title="Make an offer "
              type={ButtonType.outlined}
              onClick={onAlternateOffer}
              disabled={disabled}
            />
          </Box>
        ) : (
          <></>
        )}
        {CanChatBtn}
      </Box>
    </CardWrapper>
  );
};

export default JobActions;
