import { Box } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Colors } from "../../../constants/colors";
import { ServiceRate, ServiceRatePrice } from "../../../models";
import TreatmentDescription from "../TreatmentDescription/TreatmentDescription";
import "./dropdown.css";
import DropdownItem from "./DropdownItem";
import DropdownValueHolder from "./DropdownValueHolder";
import { getSelectedService } from "../../../stores/booking";
import { useMobile } from "../../../hooks/mobile";

type Direction = "up" | "down";

interface Props {
  label: string;
  disabled?: boolean;
  placeholder?: string;
  dropDirection?: Direction;
  value?: string | number | null;
  treatments: Array<ServiceRatePrice>;
  onSelect: (treatment: ServiceRatePrice) => unknown;
  selectedDuration?:number
}

const TreatmentDropdownMenu = ({
  label,
  value,
  disabled,
  onSelect,
  treatments,
  placeholder,
  dropDirection,
  selectedDuration
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [descriptionVisible, _setDescriptionVisible] = useState<boolean>(false);
  const [dropBelow] = useState<boolean>(dropDirection ? dropDirection === "down" : true);

  const [selectedService, setSelectedService] = useState<ServiceRate>();

  const [selectedTreatment, setSelectedTreatment] = useState<ServiceRatePrice>();
  const [selectedForDetail, setSelectedForDetail] = useState<ServiceRatePrice>();

  const isMobile = useMobile();
  // Since we cannot access current state values inside event listeners
  // we use ref to go around this issue.
  const descriptionVisibleRef = useRef(descriptionVisible);
  const setDescriptionVisible = (value: boolean) => {
    descriptionVisibleRef.current = value;
    _setDescriptionVisible(value);
  };

  useEffect(() => {
    if (value && treatments) {
      const selected = [...treatments].find(({ name, id }) => value === name || value === id);
      setSelectedTreatment(selected);
    }
  }, [treatments, value]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    const selectedService = getSelectedService();
    setSelectedService(selectedService);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      !descriptionVisibleRef.current
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (selectedTreatment) {
      onSelect(selectedTreatment);
    }
  }, [selectedTreatment]);

  const handleSelect = useCallback((treatment: ServiceRatePrice) => {
    setDropdownVisible(false);
    setSelectedTreatment(treatment);
  }, []);

  const onLearnMore = useCallback((treatment: ServiceRatePrice) => {
    setSelectedForDetail(treatment);
    setDescriptionVisible(true);
  }, []);

  const handlePopupClose = useCallback(() => {
    setDescriptionVisible(false);
  }, []);

  return (
    <>
      <div ref={containerRef} style={{ width: "100%" }}>
        <Box
          position={"relative"}
          width="100%"
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
        >
          <Box paddingY="21px">
            <Box fontWeight={700}>{label}</Box>
            <DropdownValueHolder
              disabled={disabled}
              placeholder={placeholder || "Select"}
              value={selectedTreatment?.label}
              onClick={() => setDropdownVisible(!dropdownVisible)}
            />
            <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
          </Box>

          <Box
            className="treatment-dropdown"
            style={{
              display: dropdownVisible ? "block" : "none",
              marginTop: dropBelow ? "-8px" : "-560px",
              maxHeight: isMobile ? "350px" : undefined,
            }}
          >
            {treatments.map((treatment) => (
              <DropdownItem
                key={treatment.name}
                selected={treatment.name === selectedTreatment?.name}
                treatment={{...treatment, selectedCountry: treatment.selectedCountry ?? selectedService?.selectedCountry}}
                onSelect={handleSelect}
                onLearnMoreClick={onLearnMore}
                hasFixedDuration={selectedService?.hasFixedDuration}
                selectedDuration={selectedDuration}
              />
            ))}
          </Box>
        </Box>
      </div>

      <TreatmentDescription
        isOpen={descriptionVisible}
        title={selectedForDetail?.label || ""}
        description={selectedForDetail?.fullDescription || ""}
        onClose={handlePopupClose}
      />
    </>
  );
};

export default TreatmentDropdownMenu;
