import { checkServiceIsMassage } from "../stores/booking";

const towelsValue = (preferences: { [preference: string]: string }) => {
  return preferences["use_towels"];
};

const conversationValue = (preferences: { [preference: string]: string }) => {
  return preferences["preference_during_treatment"];
};

const levelofPressureValue = (preferences: { [preference: string]: string }) => {
  return preferences["pressure_level"];
};

const focusAreaValue = (preferences: { [preference: string]: string }) => {
  return preferences["therapist_focus_on"];
};

const specialInstructionsValue = (preferences: { [preference: string]: string }) => {
  return preferences["specialInstructions"];
};

const contraindicationsValue = (preferences: { [preference: string]: string }) => {
  return preferences["contraindications"];
};

const generateInstructionFromPreference = (preferences: any, serviceId: number | null) => {
  let specialInstruction = "";

  if (!preferences) return specialInstruction;

  if (preferences) {
    specialInstruction = specialInstructionsValue(preferences);
  }
  return specialInstruction;
};

const generatePreferenceFromUserPreference = (preferences: any, serviceId: number | null) => {
  const isMassageType = checkServiceIsMassage(serviceId);

  let userPref = "";  

  if (preferences) {
    if (isMassageType) {
      if (towelsValue(preferences)) {
        userPref += `Towels / Sheets: ${towelsValue(preferences)}. \n`;
      }
      if (conversationValue(preferences)) {
        userPref += `Conversation during treatment: ${conversationValue(preferences)}. \n`;
      }
      if (levelofPressureValue(preferences)) {
        userPref += `Level of pressure: ${levelofPressureValue(preferences)}. \n`;
      }
      if (levelofPressureValue(preferences)) {
        if (focusAreaValue(preferences)) {
          userPref += `Focus area: ${focusAreaValue(preferences)}. \n`;
        }
      }
    }
  }
  return userPref;
};

const generateContraindicationFromUserPreference = (preferences: any, serviceId: number | null) => {
  let contraindications = "";
  if (preferences) {
    if (contraindicationsValue(preferences)) {
      contraindications = contraindicationsValue(preferences);
    }
  }
  return contraindications;
};

const getNotesFromTreatmentDetails = (treatmentDetails: any[]) => {
  let specialInstruction = "";
  if (treatmentDetails) {
    const notes = treatmentDetails
      .map((detail) =>  detail.noteForProvider )
      .filter((note) => note !== undefined);
    if (notes.length > 0) {
      specialInstruction = notes.join(" | ");
    }
  }
  return specialInstruction;
};

export {
  generateInstructionFromPreference,
  getNotesFromTreatmentDetails,
  generatePreferenceFromUserPreference,
  generateContraindicationFromUserPreference,
};
