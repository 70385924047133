import { Box, BoxProps } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";

const Title: React.FC<BoxProps> = (props) => {
  return (
    <Box
      fontFamily="Open Sans"
      fontSize="14px"
      lineHeight="21px"
      fontWeight={600}
      color={Colors.BlueyGrey}
      {...props}
    />
  );
};

export default Title;
