import React from "react";
import InfoModal, { InfoModalItem } from "./InfoModal";
import { getMassageService, useBookingStore } from "../stores/booking";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

export default function SessionTypeInfoModal({ open, onClose }: Props) {
  const { sessionType, setSessionType } = useBookingStore();

  const { sessionTypes: serviceSessionTypes = [] } = getMassageService();

  const items = serviceSessionTypes.map((session) => (
    {
      icon: session.icon,
      title: session.title,
      description: session.description,
      selected: sessionType === session.value,
      value: session.value,
      backgroundColor: session.backgroundColor,
    }
  )) as InfoModalItem[];

  return (
    <InfoModal
      title="Session type"
      open={open}
      onClose={onClose}
      items={items}
      onSelectedItem={(item) => {
        setSessionType(item.value);
        onClose();
      }}
    />
  );
}
