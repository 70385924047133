import { useMutation, useQuery } from "@tanstack/react-query";
import queryClient from "../config/queryClient";
import { get, post } from "../api/client";

const KEYS = {
  SIGNATURE: "SIGNATURE",
};

export const useSignature = ({ userId }: { userId: number }) =>
  useQuery([KEYS.SIGNATURE, userId], () => get("api/v2/therapists/signature"));

export const useCreateSignature = ({
  onSuccess,
  onError,
}: {
  onSuccess: Function;
  onError: Function;
}) =>
  useMutation((data: any) => post("api/v2/therapists/signature", data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.SIGNATURE] });
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

export const useConfirmAgreement = ({
  onSuccess,
  onError,
}: {
  onSuccess: Function;
  onError: Function;
}) =>
  useMutation(
    (data: { userId: number; signatureId: number; bookingId: number }) =>
      post("api/v2/agreements", data),
    {
      onSuccess: () => {
        if (onSuccess) onSuccess();
      },
      onError: (err) => {
        if (onError) onError(err);
      },
    }
  );
