import React from "react";
import { Box, Grid, GridProps } from "@material-ui/core";
import { BlockedTherapist, FavoriteTherapist, Recipient } from "../../../models";
import { useMobile } from "../../../hooks/mobile";
import { getProfileLink } from "../../../services/therapist/therapist.service";
import { getAccessToken } from "../../../helpers/accessToken";
import { isAUDomain } from "../../../utils/url";
import BlockedTherapistItem from "../../../components/Therapists/BlockedTherapistItem";
import { useGetBlockedPros } from "../../../hooks/recipient/recipient.hooks";
import TherapistsPlaceholder, { TherapistsPlaceholderType } from "../../../components/TherapistsPlaceholder";
import { checkIfEmpty } from "../../../utils/object";

interface RecipientFavProsProps {
  recipient: Recipient;
}

const RecipientBlockedPro = ({ recipient }: RecipientFavProsProps) => {
  const isMobile = useMobile();

  const { data: blockedTherapistsData, isLoading } = useGetBlockedPros(recipient.id, {});

  const gridProps = {
    container: true,
    spacing: 3,
    style: {
      marginTop: isMobile ? "16px" : "40px",
      marginBottom: "16px",
      width: "auto",
      marginRight: "0px",
    },
    direction: isMobile ? "column" : undefined,
    alignItems: isMobile ? "center" : undefined,
  } as GridProps;

  const onProfileClicked = (therapist: FavoriteTherapist | BlockedTherapist) => {
    let publicProfileURL = getProfileLink({
      id: therapist.therapistId,
      firstName: therapist.therapist?.firstName || "",
    });
    const userToken = getAccessToken();
    publicProfileURL += `?token=${userToken}`;

    if (isAUDomain()) publicProfileURL += "&country=AU";

    window.open(publicProfileURL, "_blank");
  };

  if (checkIfEmpty(blockedTherapistsData)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={isMobile ? "400px" : "75%"}
        marginTop={"80px"}
      >
        <TherapistsPlaceholder type={TherapistsPlaceholderType.blocked} />
      </Box>
    );
  }

  return (
    <Box
      marginTop="43px"
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <Grid {...gridProps}>
        {!isLoading && blockedTherapistsData &&
          blockedTherapistsData.map((therapist: BlockedTherapist) => (
            <Grid item>
              <BlockedTherapistItem
                therapist={therapist}
                onClick={() => onProfileClicked(therapist)}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default RecipientBlockedPro;