import React from "react";
import { Booking } from "../../../models";
import { getValue } from "../../../utils/object";
import { Box, Text } from "../../../components/v2/Styled";
import RecurringIcon from "../../../images/booking-recurrency.png";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";

interface Props {
  booking: Booking;
}

const RecurringInfo = ({ booking }: Props): JSX.Element => {
  const recurringInfo = getValue(booking, "recurring");
  const recurringFrequency = getValue(recurringInfo, "recurringData.frequency", 0);
  const recurringText = getValue(recurringInfo, "recurringData.pro.nextBookingDateText", "");
  const recurringLabel = getValue(recurringInfo, "recurringData.pro.recurringLabel", "");

  if (recurringFrequency === 0) {
    return <React.Fragment />;
  }

  return (
    <Box gap={Spacing.S4}>
      <Box width={"24px"} height={"24px"}>
        <img src={RecurringIcon} style={{ width: 24, height: 24 }} />
      </Box>
      <Box direction={FlexDirection.Column}>
        <Box>
          <Text
            font={FontFamily.Museo}
            size={FontSize.F16}
            lineHeight={LineHeight.L24}
            color={Colors.Dusk}
            weight={FontWeight.Medium}
          >
            {recurringLabel}
          </Text>
        </Box>
        <Box>
          <Text
            font={FontFamily.OpenSans}
            weight={FontWeight.Regular}
            size={FontSize.F14}
            color={Colors.Grey}
          >
            {recurringText}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default RecurringInfo;
