import { styled } from "@mui/system";
import { Link as MuiLink, LinkProps } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import { FontFamily, FontSize, FontWeight } from "../enum";

type StyledLinkProps = {
  onClick?: () => unknown;
  font?: FontFamily;
  weight?: FontWeight;
  size?: FontSize;
  fontColor?: Colors;
  underlined?: boolean;
} & LinkProps;

const Link = styled(MuiLink, {
  shouldForwardProp: (propName: PropertyKey) => propName !== "style", // Prevent using custom style from props
})<StyledLinkProps>(({ font, weight, size, fontColor, underlined }) => ({
  color: fontColor,
  fontFamily: font,
  fontWeight: weight,
  fontSize: size,
  cursor: "pointer",
  textDecoration: underlined ? "underline" : "none",
}));

Link.defaultProps = {
  weight: FontWeight.Regular,
  size: FontSize.F16,
  font: FontFamily.OpenSans,
  fontColor: Colors.Dusk,
  underlined: false,
};

export default Link;
