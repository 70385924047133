import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";

const WithSuspense = (Component: React.ComponentType) => (props: any) => {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default WithSuspense;
