import { Box } from "@material-ui/core";
import { UserTreatment } from "../../../models";
import TreatmentSwitch from "./TreatmentSwitch";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import React, { useEffect, useState } from "react";
import { useAlertStore } from "../../../stores/alert";
import { parseApiV2Error } from "../../../helpers/error";
import { LockAttributePayload } from "../../../hooks/userProfileLock.hooks";
import { toggleTreatmentService } from "../../../services/therapist/proTreatment.service";
import TreatmentDescription from "../../Treatment/TreatmentDescription/TreatmentDescription";

interface Props {
  loading?: boolean;
  services: Array<any>;
  userTreatments: Array<UserTreatment>;
  onChange: (treatmentId: number, value: boolean) => unknown;
  updateUserTreatments: (items: UserTreatment[], added: boolean) => unknown;
  handleToggleLock: (body: LockAttributePayload) => any;
}

const DEFAULT_HEIGHT = 1200;

const SystemServices = (props: Props) => {
  const {
    services,
    userTreatments,
    onChange,
    updateUserTreatments,
    handleToggleLock,
    loading,
  } = props;
  const isMobile = useMobile();
  const [wrapperHeight, setWrapperHeight] = useState<number>(DEFAULT_HEIGHT);
  const [openDescModal, setOpenDescModal] = useState<boolean>(false);
  const [selectedTreatment, setSelectedTreatment] = useState<any>(null);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const handleToggleService = (serviceId: number, toggleStatus: boolean) => {
    toggleTreatmentService(serviceId, toggleStatus)
      .then((data) => {
        updateUserTreatments(data, toggleStatus);
        setSuccessMessage(`Treatments successfully ${toggleStatus ? "added" : "removed"}`);
      })
      .catch((error) => setErrorMessage(parseApiV2Error(error)));
  };

  useEffect(() => {
    // We need to calculate height of the wrapper dynamically because whenever new
    // services or treatments are added we need to fit them in two columns of masonry grid
    // where we need to provide fixed height
    const totalHeight = services.reduce((total, { treatments }) => {
      return total + 78 + treatments.length * 40;
    }, 0);
    const newHeight = totalHeight / 2;
    setWrapperHeight(newHeight > DEFAULT_HEIGHT ? newHeight : DEFAULT_HEIGHT);
  }, [services]);

  const handleInfoClick = (treatment: any) => {
    setSelectedTreatment(treatment);
    setOpenDescModal(true);
  };

  if (!services) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxHeight={isMobile ? null : `${wrapperHeight}px`}
      flexWrap="wrap"
      mb={3}
    >
      {services
        .filter((service) => service.treatments?.length > 0)
        .map((service) => (
          <Box
            key={`system-service-${service.id}`}
            flex="1 0 auto"
            width={isMobile ? "100%" : "387px"}
            mr={isMobile ? "0px" : "20px"}
            my="16px"
          >
            <Box
              fontFamily="Museo"
              fontSize="24px"
              fontWeight={600}
              color={Colors.NightBlue}
              mb="16px"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <TreatmentSwitch
                key={`service-treatment-${service.id}`}
                checked={service.treatments?.every((treatment: any) =>
                  userTreatments.some(({ treatmentId }) => treatmentId === treatment.id)
                )}
                onChange={(checked) => {
                  handleToggleService(service.id, checked);
                }}
                isLocked={service.treatments?.some((treatment: any) => treatment?.isLocked)}
                isCategory={true}
                handleToggleLock={handleToggleLock}
                loading={loading}
              />
              {service.label}
            </Box>

            {service.treatments?.map((treatment: any) => {
              return (
                <TreatmentSwitch
                  key={`service-treatment-${treatment.id}`}
                  title={treatment.label}
                  checked={userTreatments.some(({ treatmentId }) => treatmentId === treatment.id)}
                  isLocked={treatment?.isLocked}
                  onChange={(checked) => onChange(treatment.id, checked)}
                  onInfoClick={() => handleInfoClick(treatment)}
                  treatmentId={treatment.id}
                  handleToggleLock={handleToggleLock}
                  loading={loading}
                />
              );
            })}
          </Box>
        ))}

      <TreatmentDescription
        isOpen={openDescModal}
        title={selectedTreatment?.label}
        description={selectedTreatment?.proFullDescription}
        onClose={() => setOpenDescModal(false)}
      />
    </Box>
  );
};

export default React.memo(SystemServices);
