import { Box, IconButton } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import Dialog from "../../components/Dialog";
import { Colors } from "../../constants/colors";
import { SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST } from "../../constants/common";
import { NOTE_FOR_THERAPIST_PLACHOLDER } from "../../constants/placeholder";
import {
  AllUserPreferences,
  labelForUserPreference,
  optionsForUserPreference,
} from "../../constants/preferences";
import { parseApiError } from "../../helpers/error";
import {
  generateContraindicationFromUserPreference,
  generateInstructionFromPreference,
  generatePreferenceFromUserPreference,
} from "../../helpers/specialInstruction";
import { useAccessToken } from "../../hooks/common";
import { useMobile } from "../../hooks/mobile";
import CloseIcon from "../../images/modal-close.svg";
import { useAlertStore } from "../../stores/alert";
import { getServiceCategoryProfession, isMassageType, useBookingStore } from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import Dropdown from "../Dropdown";
import TextField from "../TextField";
import Contraindications from "../Contraindications";
import { User } from "../../models";
import { useUserPreference } from "../../hooks/userPreference/userPreference.hooks";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onSaved: (specialInstruction: any) => unknown;
  immediateUpdate?: boolean;
}

export default function PreferencesModal({
  open,
  onClose,
  onSaved,
  immediateUpdate = true,
}: Props) {
  const accessToken = useAccessToken();
  const isMobile = useMobile();

  const [instructions, setInstructions] = React.useState("");

  const [contraindications, setContraindications] = useState<string | null>(null);

  const [hasContraindications, setHasContraindications] = useState<boolean | null>(null);

  const { serviceId } = useBookingStore();

  const { userPreferences, user, fetchUserPreferences } = useUserStore();

  const [profession, setProfession] = useState("therapist");

  const isMassage = isMassageType();

  const [preferences, setPreferences] = React.useState<{
    [preference: string]: string;
  }>({});

  const { isLoading, updatePreference } = useUserPreference();
  const gender = user?.gender;

  React.useEffect(() => {
    if (userPreferences) {
      const _preferences: { [preference: string]: string } = {};

      const servicePreferences = userPreferences.find(
        (userPref: any) => userPref.serviceId === serviceId
      );

      if (servicePreferences) {
        const parsedPreferences = JSON.parse(servicePreferences.preference);
        Object.keys(parsedPreferences).forEach((preference) => {
          _preferences[preference] = parsedPreferences[preference];
        });

        setInstructions(parsedPreferences["specialInstructions"] || "");
        setContraindications(parsedPreferences["contraindications"] || "");
        setHasContraindications(Boolean(parsedPreferences["hasContraindications"]));
      }
      setPreferences(_preferences);
    }
  }, [userPreferences, serviceId]);

  useEffect(() => {
    if (!serviceId) return;
    const serviceProfession = getServiceCategoryProfession();
    if (serviceProfession) {
      setProfession(serviceProfession);
    }
  }, [serviceId]);

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const handleSave = () => {
    if (!preferences) {
      setErrorMessage("Sorry, could not load your preferences");
      return;
    }

    const userPrefs = {
      ...preferences,
      specialInstructions: instructions,
      hasContraindications: hasContraindications,
      contraindications,
    };

    if (!immediateUpdate) {
      onSaved(userPrefs);
      return;
    }

    updatePreference(
      {
        preferences: userPrefs,
        serviceId,
      },
      {
        onSuccess: () => {
          fetchUserPreferences();
          onSaved(userPrefs); // only setPreferencesModalOpen(false) is executed as the booking will be null.
          setSuccessMessage("Your preferences have been updated");
        },
        onError: () => setErrorMessage("Something went wrong"),
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullScreen={isMobile}>
      <Box
        width={isMobile ? "100%" : "600px"}
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          position="relative"
          height="52px"
        >
          <IconButton
            onClick={onClose}
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <img src={CloseIcon} />
          </IconButton>
        </Box>

        <Box ml="48px" mr="48px" mb="32px">
          <Box
            fontFamily="Museo"
            style={{
              fontWeight: 600,
              fontSize: "30px",
              lineHeight: "33px",
              color: Colors.NightBlue,
            }}
          >
            Preferences
          </Box>
          <Box
            fontFamily="Museo"
            style={{
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "24px",
              color: Colors.Dusk,
            }}
            mt="16px"
            mb="32px"
          >
            Update your preferences
          </Box>

          {isMassage &&
            AllUserPreferences.map((userPreference) => {
              const options = optionsForUserPreference(userPreference).map((option) => {
                return {
                  value: option,
                  title: option,
                };
              });

              return (
                <Dropdown
                  title={labelForUserPreference(userPreference)}
                  options={options}
                  onSelectedOption={(option) => {
                    const updatedPreferences = { ...preferences };
                    updatedPreferences[userPreference] = option.value;
                    setPreferences(updatedPreferences);
                  }}
                  selectedOption={options.find(
                    (option) => option.value === preferences[userPreference]
                  )}
                />
              );
            })}

          <Contraindications
            contraindications={contraindications}
            hasContraindications={hasContraindications}
            setContraindications={setContraindications}
            setHasContraindications={setHasContraindications}
            gender={gender}
          />

          <TextField
            title={`Note for your ${profession || "therapist"}`}
            subtitle="Your note is visible to providers."
            placeholder={NOTE_FOR_THERAPIST_PLACHOLDER}
            value={instructions}
            onChange={(text) => setInstructions(text)}
            multiline
            maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
            style={{ paddingBottom: "0px" }}
          />
        </Box>

        <Box height="1px" width="100%" bgcolor={Colors.LightPeriwinkle} />

        <Box display="flex" flexDirection="row" p="24px">
          <Button
            title="Cancel"
            type={ButtonType.outlined}
            size={ButtonSize.large}
            onClick={onClose}
          />

          <Box width="16px" />

          <Button
            loading={isLoading}
            title="Save"
            type={ButtonType.primary}
            size={ButtonSize.large}
            onClick={() => handleSave()}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
