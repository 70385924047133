import { Box } from '@material-ui/core';
import React from 'react';
import { useMobile } from '../../../hooks/mobile';
import styles from './styles';

interface Props {
  title: string;
  label?: string;
}

const HeaderBlock = ({ title, label }: Props) => {
  const isMobile = useMobile();

  return (
    <Box style={styles.wrapper}>
      <Box style={styles.row}>
        {label && (
          <Box style={{ ...styles.label, marginTop: isMobile ? undefined : '28px' }}>
            {label}
          </Box>
        )}
        {title && <Box style={{ ...styles.title }}>{title}</Box>}
      </Box>
    </Box>
  );
};

export default HeaderBlock;
