import { useQuery } from "@tanstack/react-query";
import { checkAddressHasRecurringBooking } from "../../services/users/users.service";

const ADDRESS_QUERY_KEYS = {
  hasRecurring: "HAS-RECURRING",
};

const useIsAddressRecurring = (addressId: number | null) =>
  useQuery([ADDRESS_QUERY_KEYS.hasRecurring, addressId], () => {
    if (!addressId) return null;
    return checkAddressHasRecurringBooking(addressId).then(({ hasRecurring }) => hasRecurring || false);
  });

export {
  useIsAddressRecurring,
  ADDRESS_QUERY_KEYS,
};
