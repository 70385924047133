import React from "react";
import StripeCoBranding from "./StripeCoBranding";
import InReviewCoBranding from "./InReviewCoBranding";
import { getValue } from "../../../../../utils/object";
import { useMobile } from "../../../../../hooks/mobile";
import UpdateInfoCoBranding from "./UpdateInfoCoBranding";
import CardWrapper from "../../../../BookingDetails/BookingDetailCard";
import {
  StripeConnectAccountStatus,
  StripeConnectAccountDetailStatus,
} from "../../../../../constants/stripe";

interface Props {
  accountStatus?: {
    status: string;
    accountStatus: any;
  };
}

const PayoutCoBranding = ({ accountStatus }: Props): JSX.Element => {
  const isMobile = useMobile();
  const status = getValue(accountStatus, "accountStatus.status");
  const stripeStatus = getValue(accountStatus, "status");

  if (stripeStatus === StripeConnectAccountStatus.NOT_CONNECTED) {
    return (
      <CardWrapper
        style={{
          margin: 0,
          padding: "16px 24px",
          borderRadius: "8px",
          width: isMobile ? "auto" : "100%",
        }}
      >
        <StripeCoBranding />
      </CardWrapper>
    );
  }

  switch (status) {
  case StripeConnectAccountDetailStatus.Verified:
    return (
      <CardWrapper
        style={{
          margin: 0,
          width: isMobile ? "auto" : "100%",
          padding: "16px 24px",
          borderRadius: "8px",
        }}
      >
        <StripeCoBranding />
      </CardWrapper>
    );
  case StripeConnectAccountDetailStatus.ActionRequired:
  case StripeConnectAccountDetailStatus.ActionRequiredOverdue:
    return <UpdateInfoCoBranding accountStatus={accountStatus} />;
  case StripeConnectAccountDetailStatus.InReview:
    return <InReviewCoBranding />;
  default:
    return <></>;
  }
};

export default PayoutCoBranding;
