import React from "react";
import { ButtonBase } from "@material-ui/core";

import CloseIcon from "../../images/close.svg";
import { Colors } from "../../constants/colors";
import CloseIconIndigo from "../../images/cross-indigo.svg";

interface Props {
  onClick: () => unknown;
  icon?: any;
  color?: Colors;
  size?: string;
  wrapperStyle?: any;
}

const CloseButton = ({ onClick, color, size = "md", icon, wrapperStyle = {} }: Props) => {
  const getCrossIcon = () => {
    if (icon) return icon;

    switch (color) {
    case Colors.Indigo:
      return CloseIconIndigo;
    default:
      return CloseIcon;
    }
  };

  const getSize = () => {
    switch (size) {
    case "lg":
      return "48px";
    case "md":
      return "40px";
    case "sm":
      return "24px";
    default:
      return "40px";
    }
  };

  const getIconSize = () => {
    switch (size) {
    case "lg":
      return "24px";
    case "md":
      return "44px";
    case "sm":
      return "14px";
    default:
      return "44px"; // default size
    }
  };

  return (
    <ButtonBase
      style={{
        width: getSize(),
        height: getSize(),
        marginBottom: "20px",
        ...wrapperStyle,
      }}
      onClick={onClick}
    >
      <img
        alt="close"
        src={getCrossIcon()}
        style={{ width: getIconSize(), height: getIconSize() }}
      />
    </ButtonBase>
  );
};

export default CloseButton;
