import { LOCKED_ATTRIBUTES } from "../constants/profile";
import { LockedAttributeOption } from "../models";

const checkLockAttribute = ({
  lockedAttributes = [],
  fieldName,
}: {
  lockedAttributes: LockedAttributeOption[] | undefined;
  fieldName: (typeof LOCKED_ATTRIBUTES)[keyof typeof LOCKED_ATTRIBUTES];
}) => {
  return (lockedAttributes || []).map(({ fieldName }) => fieldName).includes(fieldName);
};

export { checkLockAttribute };
