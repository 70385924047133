// api / v2 / therapists / providers;
import { useQuery } from "@tanstack/react-query";
import * as httpClient from "../api/client";
import { Coordinators, User } from "../models";
import { DropdownOption } from "../components/Dropdown";
import { getValue } from "../utils/object";

const KEY = {
  PROVIDER_OPTIONS: "PROVIDER_OPTIONS",
  CHILD_ACCOUNT_OPTIONS: "CHILD_ACCOUNT_OPTIONS",
};

const useSearchProviderByName = ({
  name,
  myProviders = true,
}: {
  name: string;
  myProviders: undefined | boolean;
}) =>
  useQuery([KEY.PROVIDER_OPTIONS, name], async (): Promise<any> => {
    return httpClient
      .get("api/v2/therapists/providers/search", { search: { name }, myProviders: myProviders })
      .then((providers: Array<User>) => {
        const providerOptions: Array<DropdownOption> = providers.map((provider) => ({
          value: provider.id,
          title: `${provider.firstName} ${provider.lastName}`,
        }));

        return providerOptions;
      });
  });

const useChildAccounts = ({ name }: { name: string }) =>
  useQuery([KEY.CHILD_ACCOUNT_OPTIONS, name], async (): Promise<any> => {
    return httpClient
      .get("api/v2/users/account/child", { search: { name } })
      .then((response: any) => {
        const coordinators: Array<Coordinators> = getValue(response, "data.accounts", []);
        const coordinatorOptions: Array<DropdownOption> = coordinators.map((coordinator) => ({
          value: coordinator.value,
          title: coordinator.label,
        }));

        return coordinatorOptions;
      });
  });

export { useSearchProviderByName, useChildAccounts };
