import React, { ReactNode } from "react";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "./v2/Styled/enum";
import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props {
  children: ReactNode;
  headerText?: string;
  wrapperStyle?: object;
  mainHeaderText?: string;
}
export const FilterBlockWrapper = ({ children, headerText, wrapperStyle = {}, mainHeaderText }: Props) => {
  return (
    <Box>
      {
        mainHeaderText? <Box
          fontWeight={FontWeight.Bold}
          fontSize={FontSize.F24}
          lineHeight={"24px"}
          fontFamily={FontFamily.Museo}
          color={Colors.NightBlue}
          style={{ marginBottom: Spacing.S4}}
        >
          {mainHeaderText}
        </Box>:<Box/>
      }
      {
        headerText? 
          <Box
            fontWeight={FontWeight.Bold}
            fontSize={FontSize.F16}
            lineHeight={"24px"}
            fontFamily={FontFamily.Museo}
            color={Colors.Dusk}
            style={{ ...wrapperStyle }}
          >
            {headerText}
          </Box>:<Box/>
      }
  
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        mt={Spacing.S2}
        style={{
          gap: Spacing.S6,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
