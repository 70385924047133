import { BookingFilterStatus } from "../../constants/sortOptions";

const useCachedBookingSort = () => {

  const cachedSortBy = localStorage.getItem("bookingSortBy");

  const setSortBy = (sortBy: string) => {
    if (sortBy) localStorage.setItem("bookingSortBy", sortBy);
  };

  const removeSortBy = () => localStorage.removeItem("bookingSortBy");

  return { cachedSortBy, setSortBy, removeSortBy };
};

const useBookingFilterCount = () => {

  const getFilterCount = (search: any, defaultSortBy: string) => {
    const keys = Object.keys(search);
    let count = 0;
    const nonEmptyFilters = keys.map((item) => {
      if (item === "status") {
        const allStatus = search[item];
        const noOfStatusFilter = Object.values(BookingFilterStatus).length - allStatus.length;
        count += noOfStatusFilter;
      } else if (item === "sortBy") {
        if (search[item] !== defaultSortBy) count += 1;
      } else {
        count += 1;
      }
    });

    return count;
  };

  return { getFilterCount };
};

const useCachedRecipientSort = () => {

  const cachedSortBy = localStorage.getItem("recipientSortBy");

  const cacheSortBy = (sortBy: string) => {
    if (sortBy) localStorage.setItem("recipientSortBy", sortBy);
  };

  const removeSortBy = () => localStorage.removeItem("recipientSortBy");

  return { cachedSortBy, cacheSortBy, removeSortBy };
};


export {
  useCachedBookingSort,
  useBookingFilterCount,
  useCachedRecipientSort,
};