import React from "react";
import "./Calendar.css";
import { ProBufferTime, ProSchedule } from "./interface";
import {
  getCurrentWeekDay,
  getDiffInHours,
  getTimeFromDate,
  getTimeTitle,
  getMinutesFormAddedTime,
  getMinutesFormSubtractedTime,
  calcDiffBySubtracting,
  calcDiffByAddding,
  addBufferTimeAfterBooking,
  addBufferTimeBeforeBooking,
  calcTimeDiffBetweenBookingEnd,
  calcTimeDiffBetweenBookingStart,
} from "../../../../utils/date.util";
import { Box } from "@material-ui/core";
import {
  flexDisplayStyle,
  cellSpaceInPx,
  baseHeightInPx,
  timeRanges,
  timeSlabInHour,
  timeSlotWidthInPx,
  getCalendarViewType,
  getBaseStyle,
  DAY_VIEW,
} from "./config";
import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
interface WrapperInfo {
  top: number;
  left: number;
}

interface HeaderFooterInfo {
  required: boolean;
  height: number;
}

interface CardInfo {
  height: number;
  title: string;
}

interface WrapperWithCardInfo {
  wrapperInfo: WrapperInfo;
  headerInfo: HeaderFooterInfo;
  cardInfo: CardInfo;
  footerInfo: HeaderFooterInfo;
}

interface GetWrapperInfoInterface {
  timeofArrivalInHour: number;
  currentWeekDay: number;
  baseWidthInPx: number;
}
const THRESHOLD_TIME_IN_MINUTES = 5;

const calcTimeDiffBeforeBookingAfterBooking = (
  timeOfArrival: string,
  bookingEndDate: string,
  bufferBefore: number,
  bufferAfter: number
) => {
  const bookingStart = addBufferTimeBeforeBooking(timeOfArrival, bufferBefore);
  const bookingEnd = addBufferTimeAfterBooking(bookingEndDate, bufferAfter);

  return { bookingStart, bookingEnd };
};

const checkIfHeaderFooterRequired = (minutes: number) =>
  minutes > THRESHOLD_TIME_IN_MINUTES && minutes < 60;

const isBufferBeforeAllow = (date: string, bufferTime: number) => {
  const { hour1 } = calcDiffBySubtracting(date, bufferTime);
  if (hour1 < 6) {
    return false;
  } else  {
    return true;
  } 
};

const hourDifference = (arrivalTime: string, bookingEnd: string, bufferAfter: number) => {
  const { day1, day2, hourDiff } = calcDiffByAddding(arrivalTime, bookingEnd, bufferAfter);
  return { day1, day2, hourDiff };
};

const isBufferAfterAllow = (arrivalTime: string, bookingEnd: string, bufferAfter: number) => {
  const { day1, day2 } = hourDifference(arrivalTime, bookingEnd, bufferAfter);
  if (day1 !== day2) {
    return false;
  } else {
    return true;
  }
};

const calcAllowedBufferTime = (date: string, bufferTime: number) => {
  const { hour1, hour2 } = calcDiffBySubtracting(date, bufferTime);
  if (hour1 < 6 && hour2 === 6){
    return 0;
  } else if (hour2 === 7) {
    return bufferTime > 60 ? 60 : bufferTime;
  } else {
    return bufferTime;
  }
};

const getCardFooterInfo = (
  bookingEndDateTime: string,
  bufferTime: number,
  isBufferAfter: boolean,
  diffBookingEnd:number
) => {
  let updatedBufferTime = bufferTime;
  let isFooterAllow = isBufferAfter;
  if (!isBufferAfter && bufferTime !== 0) {
    updatedBufferTime = bufferTime > 60 ? 60 : bufferTime;
    if (bufferTime < 60) {
      isFooterAllow = true;
    }
  }
  const updatedTime = getMinutesFormAddedTime(bookingEndDateTime || "", updatedBufferTime);
  let required = checkIfHeaderFooterRequired(60 - updatedTime);
  if (required && bufferTime !== 0){
    required = isFooterAllow;
  }
  const height = (((60 - updatedTime) / 60) * baseHeightInPx) - cellSpaceInPx;
  return {
    required,
    height,
  };
};

const getCardHeaderInfo = (timeOfArrivalDateTime:string, bufferAllowed:number, bufferTime:number, isBufferBefore: boolean)=>{
  let required = false;
  let height = 0;
  if (isBufferBefore) {
    const leftTime = getMinutesFormSubtractedTime(timeOfArrivalDateTime || "", bufferAllowed);
    required = checkIfHeaderFooterRequired(leftTime);
    height = ((leftTime / 60) * baseHeightInPx) - (bufferTime === 0 ? cellSpaceInPx : 0);
  }

  return {required, height};
};

const getCardInfo = (
  timeOfArrivalDateTime: string,
  bookingEndTime: string,
  footerPresent: boolean,
  bufferAfter:number
) => {
  const bookingDurationInHour = getDiffInHours(bookingEndTime, timeOfArrivalDateTime);

  if (!bookingDurationInHour) {
    return null;
  }

  // @ts-ignore
  let height = (bookingDurationInHour / timeSlabInHour) * baseHeightInPx +   bookingDurationInHour * 2 * cellSpaceInPx - cellSpaceInPx;
 
  if (!footerPresent && bufferAfter === 0){
    height -= cellSpaceInPx;
  }

  const title = `${getTimeTitle(timeOfArrivalDateTime)} - ${getTimeTitle(bookingEndTime)}`;

  return {
    height,
    title,
  };
};

const calcHeightForAll = (
  timeOfArrivalDateTime: string,
  bookingEndDateTime: string,
  bufferBefore: number,
  bufferAfter: number,
  currentWeekDay: number,
  baseWidthInPx: number
) => {
  const { bookingStart, bookingEnd } = calcTimeDiffBeforeBookingAfterBooking(
    timeOfArrivalDateTime,
    bookingEndDateTime,
    bufferBefore,
    bufferAfter
  );
  // const getHour = getHourFromSubtractedTime(timeOfArrivalDateTime, bufferBefore);
  const timeIndex = timeRanges.findIndex((timeRange) => parseInt(timeRange.value) === bookingStart);

  let totalDiff = 0;
  if (bookingStart === bookingEnd) {
    totalDiff += 1;
  } else {
    totalDiff += bookingEnd - bookingStart + 1;
  }

  const totalHeight = totalDiff * 32 + totalDiff * cellSpaceInPx;

  // week position + total cell space + time card + time card cell space
  const wrapperLeftPosition =
    currentWeekDay * baseWidthInPx +
    currentWeekDay * 2 * cellSpaceInPx +
    (baseWidthInPx + 2 * cellSpaceInPx) -
    (baseWidthInPx - timeSlotWidthInPx);

  // time position + total cell space + height due to minutes
  // @ts-ignore
  const wrapperTopPosition = (timeIndex * baseHeightInPx) + (timeIndex * 2 * cellSpaceInPx);
  
  const isBufferBefore = isBufferBeforeAllow(timeOfArrivalDateTime, bufferBefore);
  const bufferAllowed = calcAllowedBufferTime(timeOfArrivalDateTime, bufferBefore);
  const isBufferAfter = isBufferAfterAllow(timeOfArrivalDateTime, bookingEndDateTime, bufferAfter);
  const {hourDiff} = hourDifference(timeOfArrivalDateTime, bookingEndDateTime, bufferAfter);

  let bufferBeforeHeight = 0;
  let bufferAfterHeight = 0;
  
  const diffBookingStart = calcTimeDiffBetweenBookingStart(timeOfArrivalDateTime, bufferBefore);
  const diffBookingEnd = calcTimeDiffBetweenBookingEnd(timeOfArrivalDateTime,bookingEndDateTime, bufferAfter);

  if (!isBufferAfter && bufferAfter) {
    let updatedBuffer = bufferAfter > 60 ? hourDiff * 60 : bufferAfter;
    if(updatedBuffer > 120){
      updatedBuffer = 120;
    }
    bufferAfterHeight = ((updatedBuffer / 60) * baseHeightInPx) - cellSpaceInPx + diffBookingEnd * cellSpaceInPx;
  } else if (bufferAfter) {
    bufferAfterHeight = (((bufferAfter / 60)) * baseHeightInPx) - cellSpaceInPx + diffBookingEnd * cellSpaceInPx;
  }

  const footerInfo = getCardFooterInfo(bookingEndDateTime, bufferAfter, isBufferAfter, diffBookingEnd);
  const headerInfo = getCardHeaderInfo(timeOfArrivalDateTime, bufferAllowed, bufferBefore, isBufferBefore);
  const cardInfo = getCardInfo(timeOfArrivalDateTime, bookingEndDateTime, footerInfo?.required, bufferAfter);

  if (isBufferBefore || bufferAllowed) {
    bufferBeforeHeight = ((bufferAllowed / 60) * baseHeightInPx) - (headerInfo.required ? cellSpaceInPx : 0) + ((diffBookingStart) * cellSpaceInPx);
  }

  return {
    left: wrapperLeftPosition,
    top: wrapperTopPosition,
    totalHeight,
    cardInfo,
    footerInfo,
    headerInfo,
    bufferBeforeInfo:{
      height:bufferBeforeHeight,
      required: (isBufferBefore || bufferAllowed !== 0) && bufferBefore !== 0
    },
    bufferAfterInfo:{
      height:bufferAfterHeight,
      required: bufferAfter !== 0
    },
  };
};


const getWrapperAndCardsPosition = (
  proSchedule: ProSchedule,
  dayIndex: null | number = null,
  baseWidthInPx: number,
  bufferTime: ProBufferTime
) => {
  const bufferTimeBefore = bufferTime?.bufferTimeBefore || 0;
  const bufferTimeAfter = bufferTime?.bufferTimeAfter || 0; 
  const timeOfArrivalDateTime = proSchedule.timeOfArrival;
  const bookingEndDateTime = proSchedule.bookingEnd;

  const timeOfArrivalTime = getTimeFromDate(timeOfArrivalDateTime);
  const [timeOfArrivalInHour, timeOfArrivalInMinute] = timeOfArrivalTime
    ? timeOfArrivalTime.split("--")
    : [null, null];

  const bookingEndTime = getTimeFromDate(bookingEndDateTime);
  const [bookingEndTimeInHour, bookingEndTimeInMinute] = bookingEndTime
    ? bookingEndTime.split("--")
    : [null, null];

  const currentWeekDay = dayIndex !== null ? dayIndex : getCurrentWeekDay(timeOfArrivalDateTime);

  if (
    !timeOfArrivalInMinute ||
    !bookingEndTimeInMinute ||
    !timeOfArrivalInHour ||
    currentWeekDay === null
  ) {
    return null;
  }


  const wrapperInfo = calcHeightForAll(
    timeOfArrivalDateTime,
    bookingEndDateTime,
    bufferTimeBefore,
    bufferTimeAfter,
    currentWeekDay,
    baseWidthInPx
  );

  if (
    !wrapperInfo ||
    !wrapperInfo?.headerInfo ||
    !wrapperInfo?.cardInfo ||
    !wrapperInfo?.footerInfo
  ) {
    return null;
  }

  return {
    wrapperInfo,
    timeOfArrivalDateTime,
    bookingEndDateTime,
    bufferTimeBefore,
    bufferTimeAfter
  };
};

const BookedCard = ({
  baseWidthInPx,
  proSchedules,
  proBufferTime,
  openCalendarInfo
}: {
  baseWidthInPx: number;
  proSchedules: ProSchedule[];
  proBufferTime: ProBufferTime;
  openCalendarInfo: (bookingStart:string, bookingEnd:string, bufferStart:number, bufferEnd:number)=>unknown;
}) => {
  const isMobile = useMobile();
  const calendarType = getCalendarViewType(isMobile);
  const baseStyle = getBaseStyle();

  const bookedCardPositions = proSchedules
    .map((proSchedule: ProSchedule) =>
      getWrapperAndCardsPosition(
        proSchedule,
        calendarType === DAY_VIEW ? 0 : null,
        baseWidthInPx,
        proBufferTime
      )
    )
    .filter((schedule) => schedule);

  return (
    <>
      {bookedCardPositions.map(
        (cardDetails) =>
          cardDetails && (
            <div
              style={{
                position: "absolute",
                top: `${cardDetails.wrapperInfo.top}px`,
                left: `${cardDetails.wrapperInfo.left}px`,
                maxHeight: `${cardDetails.wrapperInfo.totalHeight}px`,
                background: "white",
                cursor:"pointer"
              }}
              onClick={()=>openCalendarInfo(cardDetails.timeOfArrivalDateTime, cardDetails.bookingEndDateTime, cardDetails.bufferTimeBefore, cardDetails.bufferTimeAfter)}
            >
              {cardDetails.wrapperInfo.headerInfo.required && (
                <Box
                  style={{
                    ...flexDisplayStyle,
                    ...baseStyle,
                    width: `${baseWidthInPx}px`,
                    height: `${cardDetails.wrapperInfo.headerInfo.height}px`,
                  }}
                ></Box>
              )}

              {cardDetails.wrapperInfo.bufferBeforeInfo.required && (
                <Box
                  style={{
                    ...flexDisplayStyle,
                    ...baseStyle,
                    width: `${baseWidthInPx}px`,
                    height: `${cardDetails.wrapperInfo.bufferBeforeInfo.height}px`,
                    backgroundColor: Colors.LightBlueGrey,
                    position:"relative",
                    zIndex:90
                  }}
                ></Box>
              )}

              <Box
                style={{
                  ...flexDisplayStyle,
                  ...baseStyle,
                  width: `${baseWidthInPx}px`,
                  height: `${cardDetails.wrapperInfo?.cardInfo?.height}px`,
                  backgroundColor: "#0DAEC8",
                  position: "relative",
                  zIndex: 1000,
                }}
              >
                <Box
                  style={{
                    color: "white",
                    fontFamily: "Open Sans",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {cardDetails.wrapperInfo?.cardInfo?.title}
                </Box>
              </Box>

              {cardDetails.wrapperInfo?.bufferAfterInfo?.required && (
                <Box
                  style={{
                    ...flexDisplayStyle,
                    ...baseStyle,
                    width: `${baseWidthInPx}px`,
                    height: `${cardDetails.wrapperInfo.bufferAfterInfo.height}px`,
                    backgroundColor: Colors.LightBlueGrey,
                    position: "relative",
                    zIndex: 100,
                  }}
                ></Box>
              )} 

              {cardDetails.wrapperInfo.footerInfo.required && (
                <Box
                  style={{
                    ...flexDisplayStyle,
                    ...baseStyle,
                    width: `${baseWidthInPx}px`,
                    height: `${cardDetails.wrapperInfo.footerInfo.height}px`,
                  }}
                ></Box>
              )}
            </div>
          )
      )}
    </>
  );
};

export default BookedCard;
