import { get } from "../api/client";
import { ENDPOINTS } from "../constants/endpoints";

export const DEFAULT_COUNTRY = "AU";

export const getCountryCode = async (force = false) => {
  try {
    const countryCode = localStorage.getItem("countryCode");
    if (!countryCode || force) {
      // fetch country code from API
      return await fetchCountryCode();
    }
    return countryCode;
  } catch (error) {
    return DEFAULT_COUNTRY;
  }
};

export const fetchCountryCode = async (store = true) => {
  try {
    const data = await get(ENDPOINTS.COUNTRY_CODE_API);
    if (store) {
      await setCountryCode(data.country);
    }
    return data.country;
  } catch (error) {
    console.log(error);
    return DEFAULT_COUNTRY;
  }
};

export const setCountryCode = async (countryCode: string) => {
  try {
    await localStorage.setItem("countryCode", countryCode);
  } catch (error) {
    console.log(error);
  }
};
