import { GenderPreference } from "../constants/booking";
import { GenderPreferences } from "../models";

export const getTranformedGenderOptions = (options: any[] = []) =>
  options.map((preference: GenderPreferences) => {
    return {
      value: preference.value,
      title: preference.title,
      helperText: preference.helpText || undefined,
    };
  });

export const checkIfGenderFallbackOptionsRequired = (genderPreference: string) => {
  const validGenderSelection =
    genderPreference === GenderPreference.femaleOnly ||
    genderPreference === GenderPreference.maleOnly;

  let showGenderFallBack = false;
  let genderFallbackMessage = "";

  if (validGenderSelection) {
    let bookGender = "";
    let unavailableGender = "";
    if (genderPreference === GenderPreference.femaleOnly) {
      bookGender = "male";
      unavailableGender = "female";
    } else if (genderPreference === GenderPreference.maleOnly) {
      bookGender = "female";
      unavailableGender = "male";
    }

    showGenderFallBack = true;
    genderFallbackMessage = `Book ${bookGender} if ${unavailableGender} is unavailable`;
  }

  return { showGenderFallBack, genderFallbackMessage };
};
