import React from "react";
import { checkStringEndsWith } from "../../utils/string";

const transformAlertMessage = (str: string | null | React.ReactNode) => {
  if (!str || typeof str !== "string") return str;

  const needsTransform =
    !checkStringEndsWith(str.trim(), ".") && !checkStringEndsWith(str.trim(), "!");

  if (!needsTransform) return str.trim();

  return `${str.trim()}.`;
};

export { transformAlertMessage };
