import axios from "axios";

export const getBalance = async (accessToken: string) => {
  try {
    const { data } = await axios.get('api/v2/therapists/me/balance', {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return { data };
  } catch ({response: {data: {error}}}) {
    return { error };
  }
};