import { Message } from "@twilio/conversations";

import * as httpClient from "../../api/client";
import { ChatMessage, Participant } from "../../interfaces/chat.interface";
import { Booking } from "../../models";
import { getValue } from "../../utils/object";
import {
  noOfPreviousMessagesForPhoneNumber,
  previousMessageDiffInMinutes,
} from "../utility/personaIdentifiableConfig";
import { getLastElement } from "../../utils/array";
import { getDifferenceInMinutesFromCurrentTime, getTimeDiffBetween } from "../../utils/date.util";
import {
  hasPersonalInformation,
  hasPhoneNumber,
} from "../utility/personalIdentifiableInfo.service";

export const getAccessToken = (): Promise<string> => {
  return httpClient.get("chat/twilio/client").then((data) => data.jwt);
};

export const getBookingInfo = (bookingId: string | number): Promise<Booking> => {
  return httpClient.get(`chat/booking/${bookingId}`);
};

export const transformChatMessage = async (
  { sid, type, attachedMedia, attributes, body, dateCreated, index, author }: Message,
  userId: string | number,
  participants: Array<Participant>
): Promise<ChatMessage | null> => {
  const isNotification = getValue(attributes, "notification", false);
  const participantId = getValue(attributes, "userId");

  const isNotificationForCurrentUser = `${participantId}` === `${userId}`;
  if (isNotification && !isNotificationForCurrentUser) return null;

  const participant = participants.find((p) => participantId === p.id);

  const mediaUrls = await Promise.all(
    (attachedMedia || []).map(async (media) => {
      const url = await media.getContentTemporaryUrl();
      if (!url) return null;

      return {
        name: media.filename,
        type: media.contentType,
        src: url,
      };
    })
  );

  return {
    sid,
    type,
    attachedMedia: mediaUrls.flatMap((media) => (media ? [media] : [])),
    attributes,
    body,
    dateCreated,
    index,
    author,
    received: participantId !== userId,
    participant,
    isNotification,
  };
};

export const playMessageAddedSound = () => {
  try {
    const audio = new Audio("/message.mp3");
    audio.play();
  } catch (err) {}
};

// check previous messages+new message for personal info
export const checkPreviousMessages = (text: string, messages: ChatMessage[]) => {
  let myMessages = messages.filter((message) => !message.received);
  if (!myMessages.length) return;
  let slicedMessages =
    myMessages.length >= noOfPreviousMessagesForPhoneNumber
      ? myMessages.splice(myMessages.length - noOfPreviousMessagesForPhoneNumber, myMessages.length)
      : myMessages;

  let lastMessage = getLastElement(slicedMessages);
  //return if last message is older
  if (
    getDifferenceInMinutesFromCurrentTime(lastMessage.dateCreated.toString()) >
    previousMessageDiffInMinutes
  ) {
    return false;
  }

  let recentMessages = slicedMessages.filter((message) => {
    let diff = getTimeDiffBetween(
      lastMessage.dateCreated.toString(),
      message.dateCreated?.toString() as unknown as string,
      "minutes"
    );
    return diff < previousMessageDiffInMinutes;
  });

  let messageTexts = recentMessages.map((message) => message.body);
  if (hasPersonalInformation(messageTexts.join(""), true)) {
    return false;
  }

  messageTexts.push(text);
  return hasPhoneNumber(messageTexts.join(" "), true);
};
