import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import queryClient from "../../config/queryClient";

export const useUserConfig = ({ userId }: any) =>
  useQuery([userId], () => httpClient.get("api/v3/user/config").then((res) => res.data));

export const useCreateOrUpdateUserConfig = ({ userId, onSuccess, onError }: any) =>
  useMutation((data: any) => httpClient.post("api/v3/user/config", data), {
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: [userId] });
      if (onSuccess) onSuccess(response);
    },
    onError: (error) => {
      onError();
    },
  });