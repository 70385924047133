import React from "react";

import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { getFormattedDiffForDeadline } from "../../../utils/date.util";

const UnpaidBookingInfo = ({ deadline }: any) => {

  const duration = getFormattedDiffForDeadline(deadline);

  return (
    <Box>
      <Box style={{ color: Colors.TextDanger, fontSize: 14 }}>
        The client’s payment has declined. We have notified the client to
        settle payment within <span style={{ fontWeight: "700" }}>{duration}</span> {" "}
        or the booking will be cancelled automatically. Only proceed with booking if payment
        is settled.
      </Box>
      <Box style={{ color: Colors.TextDanger, fontSize: 14, paddingTop: 16 }}>
        You may cancel / abandon a pending booking without penalty.
      </Box>
    </Box>
  );
};

export default UnpaidBookingInfo;
