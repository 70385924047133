export enum TrackingEvents {
  ProfileClicked = "Profile Clicked",
  ProfileSaved = "Profile Saved",
  BookNowClicked = "Book Now Clicked",
  RebookClicked = "Rebook Clicked",
  JoinWaitlistClicked = "Join Waitlist Clicked",
  AddAddressClicked = "Add Address Clicked",
  AddressSaved = "Address Saved",
  AddCardClicked = "Add Card Clicked",
  CardAdded = "Card Added",
  ReferralCodeCopied = "Referral Code Copied",
  CancelBookingClicked = "Cancel Booking Clicked",
  CancelBookingConfirmed = "Cancel Booking Confirmed",
  TherapistInteracted = "Therapist Interacted",
  DownloadInvoiceClicked = "Download Invoice Clicked",
  TherapistProfileViewed = "Therapist Profile Viewed",
  NewVoucherStepViewed = "New Voucher Step Viewed",
  NewVoucherStepCompleted = "New Voucher Step Completed",
  VoucherNewUserStepViewed = "Voucher New User Step Viewed",
  VoucherNewUserStepCompleted = "Voucher New User Step Completed",
  VoucherPaymentViewed = "Voucher Payment Viewed",
  VoucherPaymentCompleted = "Voucher Payment Completed",
  SignedUp = "Signed Up",
}

const UTM_SOURCES = {
  google: "google",
};

export {
  UTM_SOURCES,
};
