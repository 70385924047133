import { useContext, useReducer } from "react";
import { initialState, ServiceAreaContext } from "./ServiceAreaContext";
import ServiceAreaReducer from "./ServiceAreaReducer";

// export const useServiceArea = () => {
//   const serviceAreaContext = useContext(ServiceAreaContext);
//   return [serviceAreaContext.state, serviceAreaContext.dispatch];
// };

export const DEFAULT_RADIUS = 100000;

export const useServiceArea = () => {
  const context = useContext(ServiceAreaContext);
  if (context === undefined) {
    throw new Error("useServiceArea must be used within a ServiceAreaContext");
  }
  return context;
};

export const ServiceAreaProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(ServiceAreaReducer, initialState);

  return (
    <ServiceAreaContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </ServiceAreaContext.Provider>
  );
};
