import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import { Box } from "../../../components/v2/Styled";
import { FontFamily } from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";

const BackToBackUpdate = () => {
  return (
    <CardWrapper>
      <Box fontFamily={FontFamily.Museo} color={Colors.Dusk}>
        The client has changed this booking from couples to back to back. Would you like to accept
        the back-to-back booking?
      </Box>
    </CardWrapper>
  );
};

export default BackToBackUpdate;
