import { Box, ButtonBase } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import { genericBioForProDirectoryTherapist } from "../../../helpers/therapist";
import { useMobile } from "../../../hooks/mobile";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";
import { ProDirectoryTherapist } from "../../../models";
import { getProfileLink } from "../../../services/therapist/therapist.service";
import Button, { ButtonType } from "../../Button";
import Ratings from "../../Ratings";

interface Props {
  therapist: ProDirectoryTherapist;
}

export default function TherapistItem({ therapist }: Props) {
  const isMobile = useMobile();

  const profilePicUrl = therapist.user.therapistprofile
    ? `${BASE_UPLOADS_URL}/${therapist.user.therapistprofile?.profileImage}`
    : ProfilePicturePlaceholder;

  const bio =
    therapist.user.therapistprofile?.bio ||
    genericBioForProDirectoryTherapist(therapist);

  const onViewProfile = () => {
    const url = getProfileLink(therapist.user);
    window.open(url, "_blank");
  };

  return (
    <Box
      width={isMobile ? "88vw" : "636px"}
      p="24px"
      boxSizing="border-box"
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      border="1px solid #DCDFE6"
      borderRadius="8px"
      m="12px"
    >
      <Box display="flex" flexDirection="column">
        <ButtonBase
          style={{ justifyContent: "flex-start" }}
          onClick={onViewProfile}
        >
          <img
            src={profilePicUrl}
            width="96px"
            height="96px"
            style={{
              borderRadius: "48px",
              objectFit: "cover",
            }}
          />
        </ButtonBase>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "80vw" : "468px"}
        ml={isMobile ? undefined : "24px"}
        mt={isMobile ? "12px" : undefined}
      >
        <Box
          fontFamily="Museo"
          fontSize="24px"
          fontWeight={600}
          color={Colors.NightBlue}
          mb="4px"
        >
          {therapist.user.firstName}
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center">
          <Ratings
            rating={therapist.user.therapistprofile?.rating || 0}
            width="15px"
            height="15px"
          />

          <Box
            fontFamily="Open Sans"
            fontWeight={600}
            fontSize="16px"
            lineHeight="24px"
            color={Colors.Dusk}
            ml="4px"
          >
            {therapist.user.therapistprofile?.rating || 0}
          </Box>
        </Box>

        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="14px"
          lineHeight="21px"
          color={Colors.TurquoiseBlue}
        >
          ({therapist.totalReviews} Reviews)
        </Box>

        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          mt="12.5px"
          mb="16px"
          color={Colors.Dusk}
        >
          {bio}
        </Box>
        <Button
          type={ButtonType.secondary}
          width="141px"
          height={48}
          title="View profile"
          onClick={onViewProfile}
        />
      </Box>
    </Box>
  );
}
