import { useEffect, useState } from "react";
import { useAccessToken } from "../common";
import { MassageFor } from "../../constants/booking";
import { fetchAddresses, useAddressStore } from "../../stores/address";
import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post } from "../../api/client";
import queryClient from "../../config/queryClient";
import { QUERY_KEYS } from "../../constants/queryKey";

export const useTotalEventDuration = ({
  numberOfPros,
  durationPerPerson,
  numberOfPerson,
  massageType,
}: any) =>
  useQuery(
    ["event-duration", { numberOfPros, durationPerPerson, numberOfPerson, massageType }],
    () => {
      if (numberOfPros && durationPerPerson && numberOfPerson) {
        return get("api/v2/bookings/corporate/duration", {
          numberOfPros,
          durationPerPerson,
          numberOfPerson,
          massageType,
        });
      }
    }
  );

export const useBookingQuotation = (referenceId: string | null) => {
  const { addresses } = useAddressStore();
  const accessToken = useAccessToken();
  const [recipients, setRecipients] = useState([]);
  const [quotation, setQuotation] = useState(null);

  const { isLoading, data } = useQuery(["quotation", referenceId, accessToken], () => {
    return get(`api/v2/quotation/${referenceId}`).then(({ data }) => data);
  });

  useQuery([accessToken], () => get("users/me/recipients").then((data) => setRecipients(data)));

  useEffect(() => {
    if (addresses.length === 0) {
      fetchAddresses();
    }
  }, []);

  useEffect(() => {
    if (data && data.bookingDetails) {
      // append address to details
      const address = addresses.find(({ id }) => id === data.bookingDetails.addressId);
      data.bookingDetails.address = address;

      if (data.bookingDetails.recipientId) {
        // append recipient to details
        data.bookingDetails.recipient = recipients.find(
          ({ id }) => id === data.bookingDetails.recipientId
        );
        if(data.bookingDetails.recipient) {
          data.bookingDetails.recipient.specialInstructions = data.bookingDetails.specialInstructions;
        }
        // append massageFor to details
        data.bookingDetails.massageFor = MassageFor.someoneElse;
      } else {
        // append massageFor to details
        data.bookingDetails.massageFor = MassageFor.myself;
      }

      setQuotation(data.bookingDetails);
    }
  }, [data]);

  return { isLoading, data: quotation };
};

export const useGetQuoteAsEmail = ({ onSuccess, onError }: any) =>
  useMutation(({ data }: { data: any }) => post("api/v2/quotation/send", data), {
    onSuccess: (response) => {
      const data = response.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CORPORATE_BOOKING] });
      if (onSuccess) onSuccess();
    },
    onError: () => {
      onError();
    },
  });
