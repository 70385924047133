import { Box } from "@material-ui/core";

import { useMobile } from "../../../../hooks/mobile";
import {
  getAfterPayBookingStatus,
  getBookingTimeRange,
  getManualBookingStatus,
} from "../../../../helpers/booking";
import { formatDateWithTimezone } from "../../../../utils/date.util";
import BookingDetailCard from "../BookingDetailCard/BookingDetailCard";
import { getBookingUpdateDetails } from "../../../../services/bookings/bookings.service";
import { useUserStore } from "../../../../stores/user";
import TherapistProfileDrawer from "../../../../components/TherapistProfileDrawer";
import { useEffect, useState } from "react";
import { BOOKING_STATUS } from "../../../../constants/booking";
import { getValue } from "../../../../utils/object";
import { DEFAULT_BOOKING_DATE_FORMAT } from "../../../../constants/date";

interface Props {
  booking: any;
  bookingMutate: any;
}

const BookingDescription = ({ booking, bookingMutate }: Props) => {
  const isMobile = useMobile();
  const { user } = useUserStore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [therapistProfileOpen, setTherapistProfileOpen] = useState(false);
  const [therapistProfileId, setTherapistProfileId] = useState<number | null>(null);

  const getDateTimeString = (
    earliestTime: string,
    latestTime: string,
    sessionType: string,
    timezone: string,
    timeOfArrival: string,
  ) => {
    return `${formatDateWithTimezone(timeOfArrival, timezone, DEFAULT_BOOKING_DATE_FORMAT)} ${getBookingTimeRange(
      earliestTime,
      latestTime,
      sessionType,
      timeOfArrival,
      timezone,
    )}`;
  };

  const getBookingStatus = ({ detailId }: any) => {
    const { bookingUpdates } = booking;
    let status;
    if (bookingUpdates && bookingUpdates.length > 0 && user) {
      const { id: userId } = user;
      const detailUpdates = bookingUpdates.filter(({ jobId }: any) => jobId === detailId);
      let updateDetails = getBookingUpdateDetails(userId, detailUpdates);
      if (updateDetails) {
        status = updateDetails.jobStatus;
      }
    }
    return status;
  };

  const openTherapistDrawer = (therapistId: any) => {
    setTherapistProfileId(therapistId);
    setTherapistProfileOpen(true);
  };

  const {
    address,
    timezone,
    timeOfArrival,
    bookingDetails,
    selectedLatestTime,
    selectedSessionType,
    selectedEarliestTime,
    status: bookingStatus,
  } = booking;

  const isHomeCareProvider = user?.isHomeCareProvider || false;

  return (
    <Box display="flex" flexDirection="column">

      {isMobile && <Box height="16px" />}
      {bookingDetails.map((detail: any, index: number) => {
        if (!detail) return <></>;
        const status = getBookingStatus({ detailId: detail.jobId }) || detail.status;

        const { therapist } = detail;
        const datetime = getDateTimeString(
          selectedEarliestTime,
          selectedLatestTime,
          selectedSessionType?.value,
          timezone,
          timeOfArrival,
        );

        return (
          <BookingDetailCard
            key={`booking-${detail.title}-${index}`}
            index={index}
            status={getManualBookingStatus(booking) || getAfterPayBookingStatus(booking) || status}
            address={address}
            therapist={therapist}
            timeDetail={datetime}
            detail={detail}
            onProfileTap={openTherapistDrawer}
            booking={booking}
            bookingMutate={bookingMutate}
            bookingStatus={bookingStatus === BOOKING_STATUS.DECLINED ? bookingStatus : null}
            agreements={isHomeCareProvider ? getValue(detail, "agreements.documents", []) : []}
            providersDocuments={getValue(detail, "providersDocuments", [])}
          />
        );
      })}
      <TherapistProfileDrawer
        open={therapistProfileOpen}
        therapistId={therapistProfileId}
        onClose={() => setTherapistProfileOpen(false)}
      />
    </Box>
  );
};

export default BookingDescription;
