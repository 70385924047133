import Button, { ButtonType } from "../Button";
import { useHistory } from "react-router-dom";
import { Paths } from "../../constants/paths";
import { useMobile } from "../../hooks/mobile";


const LogoutAndLoginButton = ({details}: any) => {
  const history = useHistory();
  const isMobile = useMobile();

  const handleLogoutClicked = () => {
    history.push({
      pathname: Paths.Login,
      state: {
        email : details?.email
      } 
    });
  };
  const title = details?.email ? `Logout and continue as ${details?.email}` : "Login";

  return (
    <Button
      width={isMobile ? "100%" : "416px"}
      type={ButtonType.secondary}
      title = {title}
      onClick={handleLogoutClicked}
      disabled={false}
    />
  );
    
};

export default LogoutAndLoginButton;