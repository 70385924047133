import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteApi, get, post } from "../api/client";
import queryClient from "../config/queryClient";
import { stringifyParams } from "../helpers/string";

export const KEYS = {
  DOCUMENT_TYPE: "DOCUMENT_TYPE",
  EXISTING_DOCS: "EXISTING_DOCS",
  AGREE_LINK_DOCUMENT: "AGREE_LINK_DOC",
  UPLOAD_SIGNED_DOCUMENT: "UPLOAD_SIGNED_DOC",
  PENDING_AGREEMENT: "PENDING_AGREEMENT",
  REMOVE_AGREEMENT: "REMOVE_AGREEMENT",
  JOB_AGREEMENTS: "JOB_AGREEMENTS"
};

const useDocumentTypeOptions = () =>
  useQuery([KEYS.DOCUMENT_TYPE], () =>
    get("api/v2/users/terms/documents/types").then(({ data }) => data)
  );

const useExistingUserDocuments = () =>
  useQuery([KEYS.EXISTING_DOCS], () =>
    get("api/v2/users/terms/documents").then(({ data }) => data)
  );

interface hookProps {
  onSuccess: (data?: any) => unknown;
  onError: (err: any) => unknown;
}

const useCreateDocument = ({ onSuccess, onError }: hookProps) =>
  useMutation(
    ({
      docType,
      otherDocType,
      contentType,
      data,
    }: {
      docType: string;
      otherDocType: string;
      contentType: string;
      data: any;
    }) =>
      post(
        `api/v2/users/file?type=${docType}&otherType=${otherDocType}&contentType=${contentType}`,
        data
      ),
    {
      onSettled: (data, error: any) => {
        if (error && !!onError) {
          console.log({ handleError: true });
          onError(error);
          return;
        } else if (!error) {
          queryClient.invalidateQueries({ queryKey: [KEYS.EXISTING_DOCS] });
          onSuccess(data);
        }
      },
    }
  );

const useRemoveDocument = (onComplete: (success: boolean) => void) =>
  useMutation((documentId: number) => deleteApi(`api/v2/users/file/${documentId}`), {
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: [KEYS.EXISTING_DOCS] });
      }
      onComplete(!!data);
    },
  });

const useAgreeLinkDocument = ({ onSuccess, onError }: any) =>
  useMutation(({ agreementId, isTermsAndPolicy }: any) => {
    const params = stringifyParams({  isTermsAndPolicy });
    const url = `api/v2/agreements/${agreementId}/agree?${params}`;
    return post(url, {});
  }, {
    onSettled: (data, error: any) => {
      if (error && !!onError) {
        onError(error);
        return;
      } else if (!error) {
        invalidateAgreementsList();
        onSuccess(data);
      }
    },
  });


interface UploadSignedDocParams {
  data: any;
  agreementId: string;
  contentType: string;
  isTermsAndPolicy?: boolean;
};

const useUploadSignedDocument = ({ onError, onSuccess }: hookProps) =>
  useMutation(
    ({ agreementId, contentType, isTermsAndPolicy, data }: UploadSignedDocParams) => {
      const params = stringifyParams({ agreementId, contentType, isTermsAndPolicy });
      const url = `api/v2/agreements/upload?${params}`;
      return post(url, data);
    },
    {
      onSettled: (data, error: any) => {
        if (error && !!onError) {
          onError(error);
          return;
        } else if (!error) {
          invalidateAgreementsList();
          onSuccess(data);
        }
      },
    }
  );

const usePendingAgreements = ({ proId, enabled }: { proId: number; enabled: boolean }) =>
  useQuery(
    [KEYS.PENDING_AGREEMENT, proId],
    () => get(`api/v2/agreements/${proId}`).then(({ data }) => data),
    { enabled }
  );

const useRemoveAgreement = ({
  therapistId,
  onSuccess,
  onError,
}: {
  therapistId: number;
  onSuccess: (data?: any) => unknown;
  onError: (error: any) => unknown;
}) =>
  useMutation((documentId: number) => deleteApi(`api/v2/agreements/${documentId}`), {
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: [KEYS.PENDING_AGREEMENT, therapistId] });
        if (onSuccess) onSuccess(data);
        return;
      } else if (!!onError) {
        onError(error);
      }
    },
  });

const invalidateAgreementsList = () => {
  queryClient.invalidateQueries([KEYS.JOB_AGREEMENTS]);
};

const useAgreementsByJob = ({ jobId }: { jobId: number }) =>
  useQuery([KEYS.JOB_AGREEMENTS, { jobId }], () =>
    get(`api/v2/agreements/job/${jobId}`).then(({ data }) => data)
  );

export {
  useDocumentTypeOptions,
  useExistingUserDocuments,
  useCreateDocument,
  useRemoveDocument,
  useAgreeLinkDocument,
  useUploadSignedDocument,
  usePendingAgreements,
  useRemoveAgreement,
  invalidateAgreementsList,
  useAgreementsByJob,
};
