import { Box } from "@material-ui/core";
import React from "react";
import { useMobile } from "../../hooks/mobile";

const ServiceWrapper: React.FC = ({ children }) => {
  const isMobile = useMobile();
  return <Box width={isMobile ? "100%" : "497px"}>{children}</Box>;
};

export default ServiceWrapper;
