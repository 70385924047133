import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { get } from "../../api/client";
import { AddressOptions } from "../../models";

interface AddressOptionsInterface {
  isLoading: boolean;
  addressOptions: AddressOptions;
}

const useAddressOptions = (country: string = "AU") => {
  const defaultOptions: AddressOptions = {
    petOptions: [],
    stairOptions: [],
    parkingOptions: [],
    tableOptions: [],
  };

  const [options, setAddressOptions] = useState(defaultOptions);
  const { isLoading, data: response } = useQuery([`address-options-${country}`], () => {
    return get("api/v2/address-options", { country });
  });

  useEffect(() => {
    if (!response || !response.data) return;

    const options = response.data as AddressOptions;
    setAddressOptions(options);
  }, [response, country]);

  return { isLoading, addressOptions: options } as AddressOptionsInterface;
};

const getCountryDetails = (country: string) =>
  get("api/v2/address-options/country-details", { country });

export { useAddressOptions, getCountryDetails };
