import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FilterModal } from "../../../components/Modals/FilterModal";
import { SortContainer } from "../../../components/FiltersComponent/SortContainer";
import { Selection } from "../../../components/v2/Selection/Selection";
import { FilterBlockWrapper } from "../../../components/FilterBlockWrapper";
import Dropdown, { DropdownOption } from "../../../components/Dropdown";
import { useGetHealthFundsOptions } from "../../../hooks/PrivateHealthFund/PrivateHealthFund";
import { useGetAllLanguageSpokenOptions } from "../../../hooks/languageSpoken.hooks";
import { getValue } from "../../../utils/object";
import { DateSelectorField } from "../../../components/DateSelectorField/DateSelectorField";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { ProviderFilters } from "./BrowseResults";
import { SORT_PROS_BY } from "../../../constants/sortOptions";
import { useServicesStore } from "../../../stores/booking";
import { getTimeOptions } from "../../../helpers/time";
import { formatDate } from "../../../utils/date.util";
import moment from "moment";
import ProAutoCompleteField from "../../../components/BookingFilter/ProAutoCompleteField";
import { useGetTimeRange } from "../../../hooks/timerange/timerange.hooks";

enum ProviderFilterName {
  SortBy = "sortBy",
  Name = "name",
  Language = "language",
  Healthfund = "healthFund",
  Gender = "gender",
  Date = "date",
  StartTime = "startTime",
  EndTime = "endTime",
}

export const providerBrowseFilters = Object.values(ProviderFilterName);

interface Props {
  open: boolean;
  onClose: () => void;
  onFilterApplied: () => unknown;
  appliedFilter: ProviderFilters;
  handleFilterChanged: (appliedFilters: ProviderFilters) => unknown;
  clearFilters: () => unknown;
}

const PROS_GENDER_OPTION = [
  { value: null, title: "Any" },
  { value: "male", title: "Male" },
  { value: "female", title: "Female" },
] as DropdownOption[];

export const BrowseTherapistFilter = ({
  open,
  onClose,
  onFilterApplied,
  appliedFilter,
  handleFilterChanged,
  clearFilters,
}: Props) => {
  const { data: healthFunds } = useGetHealthFundsOptions();
  const { data: languageOptions } = useGetAllLanguageSpokenOptions();

  const {isLoading, data: timeRange} = useGetTimeRange();

  const timeOptions = getTimeOptions(timeRange ||[]) as DropdownOption[];

  const sortingOptions = Object.values(SORT_PROS_BY).map((value) => ({
    title: value,
    value: value === SORT_PROS_BY.RECOMMENDED ? null : value, // considering recomended as default and avoid the filter count.
  }));

  const handleLanguageSelected = (selectedLanguage: any) => {
    const selectedLanguageValue = getValue(selectedLanguage, "value");
    handleFilterChanged({
      ...appliedFilter,
      language: selectedLanguageValue,
    });
  };

  const handleHealthFundSelected = (selectedHealthFund: any) => {
    const selectedHealthFundValue = getValue(selectedHealthFund, "value");
    handleFilterChanged({
      ...appliedFilter,
      healthFund: selectedHealthFundValue,
    });
  };

  const handleFilterApplied = () => {
    onFilterApplied();
    onClose();
  };

  const [isTimeSelected, setIsTimeSelected] = useState(false);

  const shouldShowClearAll = !!Object.values(appliedFilter).filter((value) => !!value).length;

  const getSelectedOption = (appliedTime: any, timeOptions: DropdownOption[]) => {
    if(!isTimeSelected) return;

    const date = moment(appliedTime);
    const totalMinutes = date.hours() * 60 + date.minutes();
    return timeOptions?.find((data) => data.value === totalMinutes);
  };

  const handleTimeDropdownChange = (selectedTime: any, isStartTime: boolean) => {
    if(!isTimeSelected){
      setIsTimeSelected(true);
    }
    
    const selectedTimeInMinutes = getValue(selectedTime, "value");
    const newTime = moment(appliedFilter.startTime || appliedFilter.endTime || moment())
      .startOf("day")
      .add(selectedTimeInMinutes, "minutes")
      .toString();
    handleFilterChanged({
      ...appliedFilter,
      [isStartTime ? "startTime" : "endTime"]: newTime,
    });
  };

  return (
    <FilterModal
      open={open}
      onClose={onClose}
      handleFilterApply={handleFilterApplied}
      shouldEnableClearAll={shouldShowClearAll}
      handleClearAll={clearFilters}
      filtersName={providerBrowseFilters}
      dataToIncludeInUrl = {
        {
          ...(appliedFilter || {}),
          page : 1 // As after applying filter page number should begin from 1
        }
      }
    >
      <SortContainer
        sortOptions={sortingOptions}
        onOptionSelected={(value) => {
          handleFilterChanged({ ...appliedFilter, sortBy: value });
        }}
        selectedOption={appliedFilter.sortBy}
      />

      <FilterBlockWrapper mainHeaderText="Filter by">
        <ProAutoCompleteField
          onItemClicked={(item) => {
            handleFilterChanged({ ...appliedFilter, name: item.title });
          }}
          itemValue={appliedFilter.name ?? undefined}
          onChange={(data) => {
            handleFilterChanged({ ...appliedFilter, name: data as string });
          }}
        />

        <Selection
          selectionOptions={PROS_GENDER_OPTION}
          selectedOption={appliedFilter.gender}
          onSortOptionSelected={(selectedValue: any) => {
            handleFilterChanged({ ...appliedFilter, gender: selectedValue });
          }}
          title="Gender"
        />

        <Dropdown
          paddingTop={Spacing.S0}
          paddingBottom={Spacing.S0}
          title="Language spoken"
          options={languageOptions}
          selectedOption={languageOptions?.find(
            (data: any) => data.value === appliedFilter.language
          )}
          onSelectedOption={(selectedLanguage) => {
            handleLanguageSelected(selectedLanguage);
          }}
          styles={{
            titleFontSize: FontSize.F16,
          }}
        />

        <DateSelectorField
          title="Availability"
          value={
            appliedFilter.startTime || appliedFilter.endTime
              ? formatDate({
                date: appliedFilter.startTime || (appliedFilter.endTime as string),
                format: "D MMM YYYY",
              })
              : ""
          }
          placeHolder="Select date"
          onDateSelected={(selectedDate: Date) => {
            const startOfDay = moment(selectedDate).startOf("day").toString();
            const endOfDay = moment(selectedDate).endOf("day").toString();
            handleFilterChanged({ ...appliedFilter, startTime: startOfDay, endTime: endOfDay });
          }}
          titleStyle={{
            fontSize: FontSize.F16,
            fontWeight: FontWeight.Bolder,
            marginBottom: Spacing.S2,
          }}
          minValidDate = {new Date()}
        />

        <Box
          style={{
            display: Display.Flex,
            flexDirection: FlexDirection.Row,
            gap: Spacing.S6,
          }}
        >
          <Dropdown
            paddingTop={Spacing.S0}
            paddingBottom={Spacing.S0}
            title="Earliest start time"
            options={timeOptions}
            selectedOption={getSelectedOption(appliedFilter.startTime, timeOptions)}
            onSelectedOption={(selectedStartTime) =>
              handleTimeDropdownChange(selectedStartTime, true)
            }
            titleStyles={{
              fontFamily: FontFamily.OpenSans,
              fontWeight: FontWeight.SemiBold,
            }}
          />
          <Dropdown
            paddingTop={Spacing.S0}
            paddingBottom={Spacing.S0}
            title="Latest start time"
            options={timeOptions}
            selectedOption={getSelectedOption(appliedFilter.endTime, timeOptions)}
            onSelectedOption={(selectedEndTime) => handleTimeDropdownChange(selectedEndTime, false)}
            titleStyles={{
              fontFamily: FontFamily.OpenSans,
              fontWeight: FontWeight.SemiBold,
            }}
          />
        </Box>

        <Dropdown
          paddingTop={Spacing.S0}
          paddingBottom={Spacing.S0}
          title="Private health fund"
          options={healthFunds}
          selectedOption={healthFunds?.find(
            (data: any) => data.value === +(appliedFilter.healthFund || "")
          )}
          onSelectedOption={(selectedHealthFund) => {
            handleHealthFundSelected(selectedHealthFund);
          }}
          styles={{
            titleFontSize: FontSize.F16,
          }}
          placeholder="Select health fund"
        />
      </FilterBlockWrapper>
    </FilterModal>
  );
};
