import { CircularProgress, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import Recaptcha, { RecaptchaProps } from "react-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../constants/common";
import CustomReCaptchaProvider from "./CustomReCaptchaProvider";

interface GoogleReCaptchaProps extends RecaptchaProps {
  useCustomProvider?: boolean;
}

const ReCaptchaLoader = (): JSX.Element => {
  return (
    <Typography>
      <CircularProgress
        variant="indeterminate"
        style={{ color: "blue", marginRight: "8px" }}
        size="25px"
        thickness={4}
      />
      Loading ReCaptcha ....
    </Typography>
  );
};

const ReCaptchaError = (): JSX.Element => {
  return (
    <>
      <Typography style={{ color: "red" }}>
        Error loading ReCaptcha.
        <a style={{ marginLeft: "4px" }} href={window?.location?.pathname}>
          Retry
        </a>
      </Typography>
    </>
  );
};

// show error message if captcha is not loaded within 10 seconds
const CAPTCHA_TIMEOUT = 10000;

const GoogleReCaptcha = ({ useCustomProvider, ...props }: GoogleReCaptchaProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const isReCaptchaReady = (): boolean => {
    return (
      typeof window !== "undefined" &&
      typeof window.grecaptcha !== "undefined" &&
      typeof window.grecaptcha.render === "function"
    );
  };

  useEffect(() => {
    if (isReCaptchaReady()) {
      setLoading(false);
    }

    const timer = setTimeout(() => {
      if (loading && isReCaptchaReady()) {
        onLoad();
      } else if (loading) {
        setError(true);
      }
    }, CAPTCHA_TIMEOUT);

    // clean up
    return () => clearTimeout(timer);
  }, []);

  const onLoad = () => {
    setLoading(false);
    if (props.onloadCallback) {
      props.onloadCallback();
    }
  };

  return (
    <>
      {loading && !error && <ReCaptchaLoader />}

      {loading && error && <ReCaptchaError />}

      {useCustomProvider && (
        <CustomReCaptchaProvider
          {...props}
          onloadCallback={() => onLoad()}
          sitekey={RECAPTCHA_SITE_KEY}
        />
      )}

      {!useCustomProvider && (
        <Recaptcha
          {...props}
          render="explicit"
          onloadCallback={onLoad}
          sitekey={RECAPTCHA_SITE_KEY}
        />
      )}
    </>
  );
};

export default GoogleReCaptcha;
