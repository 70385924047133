import { Box } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import { BOOKING_SESSION_TYPE } from "../../helpers/booking";
import {
  formatDateToDayMonthString,
  formatDateToMonthDayString,
  formatMinutesToTimeString,
} from "../../helpers/time";
import TimeIcon from "../../images/review-calendar-time.svg";
import { getDateTimeLabelWithMinutes } from "../../services/bookings/bookingTime.service";
import { isCoupleMassageType, useBookingStore, useDateTimeStore } from "../../stores/booking";
import { isSameDate } from "../../utils/date.util";
import { getValue } from "../../utils/object";
import TextLink from "../TextLink";

interface Props {
  onChangeClicked: () => unknown;
  isNewAction: Boolean;
  showLabel?: boolean;
}

export default function ReviewAndBookTimeDetail({
  onChangeClicked,
  isNewAction = true,
  showLabel = true,
}: Props) {
  const {
    selectedDay,
    earliestStartInMinutes,
    latestStartInMinutes,
    backupSelectedDay,
    backupEarliestStartInMinutes,
    backupLatestStartInMinutes,
    selectedDayRange,
    backupSelectedDayRange,
  } = useDateTimeStore();

  const { address } = useBookingStore();

  const [actionText, setActionText] = React.useState("");
  const { sessionType } = useBookingStore();

  const isCouplesMassage = isCoupleMassageType(sessionType);
  const isCorporate = sessionType === BOOKING_SESSION_TYPE.CORPORATE;

  React.useEffect(() => {
    setActionText(isNewAction ? "Add" : "Change");
  }, [isNewAction]);

  const getCouplesTimeFormat = (earliestStartInMinutes: number) => {
    return `${formatDateToDayMonthString(selectedDayRange.from)} at ${formatMinutesToTimeString(
      earliestStartInMinutes
    )}`;
  };

  const getSinglesTimeFormat = (earliestStartInMinutes: number, latestStartInMinutes: number) => {
    return `${formatDateToDayMonthString(
      selectedDayRange.from
    )} between ${formatMinutesToTimeString(earliestStartInMinutes)} - ${formatMinutesToTimeString(
      latestStartInMinutes
    )}`;
  };

  const getMulitpleDateRangeTimeFormat = (
    earliestStartInMinutes: number,
    latestStartInMinutes: number,
    selectedDateRange: { from: Date; to: Date }
  ) => {
    let timeText = `Any day from ${formatDateToMonthDayString(
      selectedDateRange.from
    )} - ${formatDateToMonthDayString(selectedDateRange.to)},`;
    let minuteText = `starting anytime between ${formatMinutesToTimeString(
      earliestStartInMinutes
    )} – ${formatMinutesToTimeString(latestStartInMinutes)}`;

    return { timeText, minuteText };
  };

  const getDateAndTime = () => {
    if (!latestStartInMinutes) {
      return (
        <Box
          ml={2}
          mr={2}
          fontFamily="Museo"
          fontSize="16px"
          color={Colors.Dusk}
          style={{ overflowWrap: "break-word" }}
        >
          Select date & time
        </Box>
      );
    }

    let time = "";
    let minutes = "";
    if (earliestStartInMinutes) {
      const addressTimezone = getValue(address, "timezone");

      const { from: fromDate, to: toDate } = selectedDayRange;
      const formattedDateTime = getDateTimeLabelWithMinutes({
        fromDate: new Date(fromDate),
        toDate: new Date(toDate),
        earliestMinutes: earliestStartInMinutes,
        latestMinutes: latestStartInMinutes || null,
        timezone: addressTimezone,
      });

      if (!isSameDate(new Date(fromDate), new Date(toDate))) {
        time = `${formattedDateTime.date},`;
        minutes = formattedDateTime.time;
      } else {
        time = formattedDateTime.datetime;
      }
    }

    return (
      <Box ml={2} mr={2} fontFamily="Museo">
        {showLabel && (
          <Box
            style={{
              fontWeight: 400,
              fontSize: "16px",
              color: "#8B96AD",
              paddingBottom: "2px",
            }}
          >
            Preferred date and time
          </Box>
        )}
        <Box fontSize="16px" color={Colors.Dusk} style={{ overflowWrap: "break-word" }}>
          {time} {minutes ? minutes : ""}
        </Box>
      </Box>
    );
  };

  const getBackupDateAndTime = () => {
    if (!backupSelectedDay || !backupEarliestStartInMinutes) return null;

    let time = "";
    let minutes = "";
    if (backupEarliestStartInMinutes && backupLatestStartInMinutes) {
      const addressTimezone = getValue(address, "timezone");

      const { from: fromDate, to: toDate } = backupSelectedDayRange;
      const formattedDateTime = getDateTimeLabelWithMinutes({
        fromDate: new Date(fromDate),
        toDate: new Date(toDate),
        earliestMinutes: backupEarliestStartInMinutes,
        latestMinutes: backupLatestStartInMinutes,
        timezone: addressTimezone,
      });

      if (!isSameDate(new Date(fromDate), new Date(toDate))) {
        time = `${formattedDateTime.date},`;
        minutes = formattedDateTime.time;
      } else {
        time = formattedDateTime.datetime;
      }
    }

    return (
      <>
        <Box height={12} />
        <Box ml={2} mr={2} fontFamily="Museo">
          {showLabel && (
            <Box
              style={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#8B96AD",
                paddingBottom: "2px",
              }}
            >
              Backup date and time
            </Box>
          )}
          <Box fontSize="16px" color={Colors.Dusk} style={{ overflowWrap: "break-word" }}>
            {time} {minutes ? minutes : ""}
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="flex-start">
      <Box
        width="24px"
        height="24px"
        justifyContent="center"
        alignItems="flex-start"
        display="flex"
      >
        <img src={TimeIcon} alt="Time" />
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        {getDateAndTime()}
        {getBackupDateAndTime()}
      </Box>

      <TextLink title={actionText} onClick={onChangeClicked} />
    </Box>
  );
}
