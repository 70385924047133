import { Box, Checkbox } from "@material-ui/core";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import "react-day-picker/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FutureBookingOptions from "../../components/Bookings/Frequency/FutureBookingOptions";
import RecurringEndsOn from "../../components/Bookings/RecurringEndsOn";
import { CustomRecurrence } from "../../components/Bookings/V2/CustomRecurrence/CustomRecurrence";
import Button, { ButtonType } from "../../components/Button";
import TextPrimary from "../../components/Text/TextPrimary";
import { Display, Spacing } from "../../components/v2/Styled/enum";
import { RecurringType, STEP_PROGRESS } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { MIN_HOUR_ALLOWED_TODAY, RECURRING_ENDS } from "../../constants/time";
import { getCurrentMoment, getDate } from "../../helpers/time";
import { useCountryCode } from "../../hooks/countryCode.hooks";
import { useMobile } from "../../hooks/mobile";
import { trackEvent } from "../../services/segment/track.service";
import { RootState } from "../../stores/V2";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import { MonthData } from "../../stores/booking";
import "../../styles/DayPicker.css";
import {
  convertDayMomentToDate,
  getCurrentDate,
  getCurrentDayInTimezone,
  getNextBookingDateForRecurrence,
} from "../../utils/date.util";
import { getValue } from "../../utils/object";
import ReviewWrapper from "./Wrapper/ReviewWrapper";

interface Params {
  id?: string;
}

function ReviewBookingFrequency() {
  const { id } = useParams<Params>();

  const history = useHistory();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const countryCode = useCountryCode();

  const state = useSelector(({ booking }: RootState) => booking);

  const dateTime = state.selectedDateMoment?.toDate();

  const [endType, setEndType] = useState(state.recuringEndOn?.type || RECURRING_ENDS.NEVER);
  const defaultRecurringEndDate = getNextBookingDateForRecurrence({
    date: dateTime,
    monthData: getValue(state, "recurring.monthly", null),
    frequency: getValue(state, "recurring.frequency", 0),
  });

  const [recurringEndData, setRecurringEndData] = useState({
    occurrence: state.recuringEndOn?.occurrence || 1,
    endDate: state.recuringEndOn?.endsOn || getCurrentDate(defaultRecurringEndDate),
  });

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (!isInitialRender.current) {
      setRecurringEndData({
        ...recurringEndData,
        endDate: getCurrentDate(defaultRecurringEndDate),
      });
    } else {
      isInitialRender.current = false;
    }
  }, [state.recurring?.frequency, state.recurring?.monthly]);
  // @ts-ignore
  const addressTimezone = state?.address?.timezone;

  const getCurrentHours = (timezone?: string) => getCurrentMoment(timezone).hours();

  const getMinValidDate = () => {
    const currentMoment = getCurrentMoment(addressTimezone);
    return getCurrentHours(addressTimezone) >= MIN_HOUR_ALLOWED_TODAY
      ? getDate(convertDayMomentToDate(currentMoment), 1)
      : convertDayMomentToDate(currentMoment);
  };

  const oneYearFromNow = getCurrentDayInTimezone(addressTimezone);
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  useEffect(() => {
    trackEvent("Review treatment type", {
      step: 4,
      version: 2,
    });
  }, []);

  const handleBack = () => history.replace(`/bookings/review/${id}`, { initialize: false });

  const findConfirmedProIds = () => {
    const { bookingDetails } = state.existingBooking;
    const confirmedPros = bookingDetails.map((detail) => getValue(detail, "therapist.id"));
    return confirmedPros.join(",");
  };

  const onCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { recurring } = state;
    const isChecked = event.target.checked;
    if (isChecked !== recurring?.isSameProvider) {
      let preferredTherapists = "";
      if (isChecked) {
        preferredTherapists = findConfirmedProIds();
      }
      dispatch(
        bookingActions.updateIsSameProvider({
          isSameProvider: isChecked,
        })
      );
      dispatch(
        bookingActions.updatePreferredPro({ preferredTherapists })
      );
    }
  };

  const handleContinue = () => {
    history.replace(`/bookings/review/${id}`, { initialize: false });
  };

  const saveRecurringEndTypeData = (data: any) => {
    if (
      state.existingBooking?.recuringEndOn?.endsOn !== data?.endsOn ||
      state.existingBooking?.recuringEndOn?.occurrence !== data?.occurrence
    ) {
      dispatch(bookingActions.updateBookingRecurringEndsOn({ data }));
    }
  };
  const setMonthData = (monthData: MonthData | null) => {
    dispatch(
      bookingActions.updateMonthData({
        monthly: monthData,
      })
    );
  };

  const setRecurringMonthDataForDayOfMonth = ({ dayOfMonth }: { dayOfMonth: number }) => {
    dispatch(
      bookingActions.updateRecurringMonthDataForDayOfMonth({
        dayOfMonth,
      })
    );
  };

  const setRecurringMonthDataForWeekOfMonth = ({
    weekOfMonth,
    dayOfWeek,
  }: {
    weekOfMonth: number;
    dayOfWeek: number;
  }) => {
    dispatch(
      bookingActions.updateRecurringMonthDataForWeekOfMonth({
        weekOfMonth,
        dayOfWeek,
      })
    );
  };

  const setRecurringType = (recurringType: RecurringType) => {
    dispatch(
      bookingActions.updateRecurringType({
        recurringType,
      })
    );
  };

  const setFrequency = (frequency: number | null) => {
    dispatch(bookingActions.updateBookingFrequency({ frequency }));
  };

  const setIsRepeating = (isRepeating: boolean) => {
    dispatch(
      bookingActions.updateBookingFrequency({
        isRepeating,
        frequency: !isRepeating ? 0 : state.recurring?.frequency || 0,
      })
    );
  };

  const updateShouldCreateFutureBookings = (shouldCreateFutureBookings: boolean) => {
    dispatch(
      bookingActions.updateShouldCreateFutureBookings({
        shouldCreateFutureBookings,
      })
    );
  };

  return (
    <ReviewWrapper
      buttons={[
        <Button title={"Back"} onClick={handleBack} type={ButtonType.outlined} />,
        <Button title={"Continue"} type={ButtonType.secondary} onClick={handleContinue} />,
      ]}
      progress={STEP_PROGRESS[5]}
      title="Booking frequency"
      label="Step 5 of 7"
      onClose={handleBack}
    >
      <Box style={{ marginTop: Spacing.S2 }}>
        <TextPrimary>Select how often you would like to have this service.</TextPrimary>
      </Box>

      <Box mt={Spacing.S8}>
        <CustomRecurrence
          selectedDate={dateTime}
          onFrequencyChange={setFrequency}
          frequency={getValue(state, "recurring.frequency")}
          recurring={state.recurring}
          onRecurringTypeChange={setRecurringType}
          onRecurringMonthDataChange={setMonthData}
          onDayOfMonthChange={setRecurringMonthDataForDayOfMonth}
          onWeekOfMonthChange={setRecurringMonthDataForWeekOfMonth}
          timezone={addressTimezone}
          updateRepeating={setIsRepeating}
        />
      </Box>

      {state?.recurring?.isRepeating && (
        <Box
          mt={Spacing.S8}
          fontFamily="Museo"
          fontSize="16px"
          color={Colors.Dusk}
          fontWeight={400}
          lineHeight="24px"
          display={Display.Flex}
          gridColumnGap={Spacing.S4}
        >
          <Checkbox
            style={{
              color: Colors.TurquoiseBlue,
              margin: 0,
              padding: 0,
              borderRadius: 30,
            }}
            className="booking-frequency-checkbox"
            checked={state?.recurring?.isSameProvider!}
            onChange={(event: any) => {
              onCheckBoxChange(event);
            }}
          />{" "}
          Book the same provider for my future repeat bookings.
        </Box>
      )}

      {/* This is for confirmed booking.*/}
      {state.recurring?.isRepeating && (
        <Box mt={Spacing.S8}>
          {/* ends on option */}
          <RecurringEndsOn
            endType={endType}
            setEndType={setEndType}
            recurringEndData={recurringEndData}
            setRecurringEndData={setRecurringEndData}
            saveRecurringEndTypeData={saveRecurringEndTypeData}
            isMobile={isMobile}
            getMinValidDate={getMinValidDate}
            oneYearFromNow={oneYearFromNow}
            countryCode={countryCode}
          />
        </Box>
      )}

      {state.recurring?.isRepeating ? (
        <Box mt={Spacing.S8}>
          <FutureBookingOptions
            shouldCreateFutureBookings={state.recurring?.shouldCreateFutureBookings}
            onChange={updateShouldCreateFutureBookings}
          />
        </Box>
      ) : null}
    </ReviewWrapper>
  );
}

export default ReviewBookingFrequency;
