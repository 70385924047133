import React from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import Button, { ButtonSize, ButtonType } from "../Button";
interface Props {
  onImagePicked: (acceptedFiles: File[]) => unknown;
  multipleImage?: boolean;
  buttonSize?: ButtonSize;
  buttonType?: ButtonType;
  buttonStyle?: {};
  buttonTitle?: string;
}
export const ImagePicker = ({
  onImagePicked,
  multipleImage = false,
  buttonSize = ButtonSize.large,
  buttonType = ButtonType.outlined,
  buttonStyle = {},
  buttonTitle = "Add image",
}: Props) => {
  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    onImagePicked(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: multipleImage,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button title={buttonTitle} size={buttonSize} type={buttonType} style={buttonStyle} />
    </div>
  );
};
