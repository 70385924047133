import React from "react";
import { Box } from "@material-ui/core";
import { Booking } from "../../models";
import { useBookingStore } from "../../stores/booking";
import { useHistory } from "react-router-dom";
import { Paths } from "../../constants/paths";
import Divider from "../Divider";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../Button";
import { BOOKING_STATUS } from "../../constants/booking";
import CancelBooking from "../CancelBooking";

interface Props {
  booking: Booking;
  buttonDirection?: "column" | "row";
  showHeader?: boolean;
  bookingMutate: () => unknown;
}

export default function BookingNotConfirmedActions({
  booking,
  buttonDirection = "column",
  showHeader = false,
  bookingMutate,
}: Props): JSX.Element {
  const history = useHistory();
  const { setUpdatingForBooking } = useBookingStore();

  const [showCancelBooking, setShowCancelBooking] = React.useState(false);

  const navigateToDateTime = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    setUpdatingForBooking(booking);

    history.push(Paths.DateAndTime);
  };

  const requestCancel = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setShowCancelBooking(true);
  };

  const buttonSpaceStyle = () => {
    if (buttonDirection === "column") {
      return {
        marginBottom: 8
      };
    }
    return {
      marginRight: 8
    };
  };

  if (!booking.latestTimeNotifiedForPending || booking.status !== BOOKING_STATUS.NEW) return <></>;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
    >
      <Divider mt="16px" />
      {showHeader && (
        <>
          <Box
            fontFamily="Museo"
            fontWeight={700}
            color={Colors.Dusk}
            fontSize="18px"
            textAlign="left"
            mt="16px"
          >
            Booking not confirmed 😩
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize="14px"
            color={Colors.Dusk}
            textAlign="left"
            mt="8px"
          >
            Please tap to Reschedule to another date, or Cancel for now.
          </Box>
        </>
      )}

      <Box
        display="flex"
        flexDirection={buttonDirection}
        flex={1}
        mt="16px"
      >
        <Button
          title="Reschedule to another time"
          type={ButtonType.outlined}
          size={ButtonSize.large}
          onClick={navigateToDateTime}
          style={buttonSpaceStyle()}
        />
        <Button
          title="Cancel booking"
          type={ButtonType.outlined}
          size={ButtonSize.large}
          onClick={requestCancel}
        />
      </Box>

      <CancelBooking
        booking={booking}
        open={showCancelBooking}
        onConfirm={() => {
          bookingMutate();
          history.push("/bookings");
        }}
        onClose={() => setShowCancelBooking(false)}
      />
    </Box>
  );
}