import { Helmet } from "react-helmet-async";

export default function MetaTags() {
  return (
    <Helmet>
      <title>Blys Gift Vouchers - Give the gift of wellness | Blys</title>
      <meta
        name="description"
        content="Perfect last minute gift! Give your loved ones a blissful treatment in the comfort of their home. Buy a Blys gift voucher today!"
      />
    </Helmet>
  );
}
