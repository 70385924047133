import React from "react";
import { Box, ButtonBase, Link, Typography, withStyles } from "@material-ui/core";
import PlusIcon from "../../../images/plus-icon.svg";
import SelectedIcon from "../../../images/selected-icon.png";
import { DropdownOption } from "../../Dropdown";
import { FontSize, FontWeight } from "../Styled/enum";
import { Colors } from "../../../constants/colors";

interface StyledButtonProps {
  selected?: boolean;
}

const StyledButton = withStyles({
  root: ({ selected }: StyledButtonProps) => ({
    backgroundColor: selected ? "#EBF8FA" : undefined,
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    textAlign: "left",
    "&:hover": {
      backgroundColor: selected ? "#EBF8FA" : "#ebf8fa4d",
    },
  }),
})(ButtonBase);

interface Props {
  selected: boolean;
  option: DropdownOption;
  onSelect: (value: DropdownOption) => unknown;
}

export const DropdownItems = ({ selected, option, onSelect }: Props) => {
  return (
    <>
      <StyledButton onClick={() => onSelect(option)}>
        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gridGap={15} alignItems="center">
              <>
                {selected ? (
                  <img src={SelectedIcon} alt="selected" style={{ height: 18, width: 18 }} />
                ) : (
                  <img src={PlusIcon} alt="Plus" style={{ height: 17, width: 17 }} />
                )}

                <Box>
                  <Typography
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: FontSize.F16,
                      fontWeight: selected ? FontWeight.Bold : FontWeight.SemiBold,
                    }}
                  >
                    {option.title}
                  </Typography>
                </Box>
              </>
            </Box>
          </Box>
        </Box>
      </StyledButton>
      <Box
        style={{
          height: "1px",
          backgroundColor: Colors.LightPeriwinkle,
          margin: "0 16px",
        }}
      />
    </>
  );
};
