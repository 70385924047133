import { useEffect, useState } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";

import { deleteApi, get, put, post } from "../../api/client";
import { QUERY_KEYS } from "../../constants/queryKey";
import queryClient from "../../config/queryClient";
import { getValue } from "../../utils/object";

export const useReserveSlot = ({ onSuccess, onError }: any) =>
  useMutation(
    ({ slotId, uuid, data }: { uuid: string; slotId: number; data: any }) =>
      put(`api/v2/bookings/corporate/${uuid}/slots/${slotId}`, data),
    {
      onSuccess: (response) => {
        const {
          recipientId,
          userId,
          slotId,
          alreadyBooked,
          calendarLink,
          outLookCalendarLink,
          appleCalendarLink,
        } = response.data;
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CORPORATE_BOOKING] });
        if (onSuccess) {
          const decodeApple = decodeURIComponent(appleCalendarLink);
          onSuccess({
            recipientId,
            userId,
            slotId,
            alreadyBooked,
            calendarLink,
            outLookCalendarLink,
            appleCalendarLink: decodeApple,
          });
        }
      },
      onError: () => {
        onError();
      },
    }
  );

export const useReleaseSlot = ({ onSuccess, onError }: any) =>
  useMutation(
    ({ slotId, uuid }: { uuid: string; slotId: string | number }) => {
      const path = `api/v2/bookings/corporate/${uuid}/slots/${slotId}`;
      return deleteApi(path);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CORPORATE_BOOKING] });
        if (onSuccess) onSuccess();
      },
      onError: () => {
        onError();
      },
    }
  );

const useCorproateBooking = (uuid: string, path: string = "") => {
  const [booking, setBooking] = useState<any>({});
  const { isLoading, data, refetch } = useQuery([QUERY_KEYS.CORPORATE_BOOKING, uuid, path], () => {
    return get(`api/v2/bookings/corporate/${uuid}/${path}`);
  });

  useEffect(() => {
    if (!data) return;
    setBooking(data);
  }, [data]);

  return { booking, isLoading, refetch };
};

interface UpdateBreakInterface {
  breakStartTime: string;
  breakEndTime: string;
  uuid: string;
  slotId: number | null;
};

export const useUpdateBreakTimeSlot = ({ onSuccess, onError }: any) =>
  useMutation(
    ({
      breakStartTime,
      breakEndTime,
      uuid,
      slotId,
    }: UpdateBreakInterface) => {
      const path = `api/v2/bookings/corporate/${uuid}/update-break-time`;
      return post(path, { uuid, breakEndTime, breakStartTime, slotId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CORPORATE_BOOKING] });
        if (onSuccess) onSuccess();
      },
      onError: () => {
        if (onError) onError();
      },
    }
  );

const useCorporateConfig = () => {
  const [config, setConfig] = useState<any>({ });

  const { isFetching , data } = useQuery([QUERY_KEYS.CORPORATE_CONFIG], () => {
    const path = "api/v2/bookings/corporate/config";
    return get(path);
  });

  useEffect(() => {
    if (isFetching) return;
    
    const configData = getValue(data, "durationConfig") || {};
    setConfig(configData);
  }, [isFetching, data]);

  return { data: config, isFetching };
};

export { useCorproateBooking, useCorporateConfig };
