const styles = {
  modalTitle: {
    fontWeight: 600,
    fontSize: "22px",
    color: "#100249",
    textAlign: "center",
  },
  infoDescriptionWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  infoModalDescription: {
    color: "#8B96AD",
    lineHeight: "150%",
    fontFamily: "Museo",
  },
  errorMessage: {
    color: "tomato",
  },
  modalAction: {
    padding: "0px 50px",
  },
  modalActionInput: {
    width: "20px",
    height: "20px",
    marginRight: "15px",
  },
  modalFooter: {
    height: "64px",
  },
  closeIcon: {
    position: "absolute",
  },
};

export default styles;
