import { getTotalMinutesOfDay } from "../../../helpers/time";
import { isFutureMoment, isSelectedDateBeforeEndDate } from "../../../utils/date.util";

type BookingType = {
  earliestTime: string;
  latestTime: string;
  backup?: {
    earliestTime: string;
    latestTime: string;
  };
  [key: string]: any;
};

const calculateMinMaxMinutes = ({ earliestTime, latestTime, backup }: BookingType) => {
  let earliestMinutes = getTotalMinutesOfDay(new Date(earliestTime));
  let latestMinutes = getTotalMinutesOfDay(new Date(latestTime));
  if (!backup) {
    return {
      max: latestMinutes,
      min: earliestMinutes,
    };
  }

  const backupEarliestMinutes = getTotalMinutesOfDay(new Date(backup.earliestTime));
  const backupLatestMinutes = getTotalMinutesOfDay(new Date(backup.latestTime));

  return {
    min: earliestMinutes < backupEarliestMinutes ? earliestMinutes : backupEarliestMinutes,
    max: latestMinutes > backupLatestMinutes ? latestMinutes : backupLatestMinutes,
  };
};

const isOldBooking = (booking: BookingType) => {
  let dateToCheck = booking.latestTime;
  if (booking.backup) {
    dateToCheck = isSelectedDateBeforeEndDate(
      new Date(booking.latestTime),
      new Date(booking.backup.latestTime)
    )
      ? booking.backup.latestTime
      : booking.latestTime;
  }

  return !isFutureMoment(dateToCheck, booking.timezone);
};

export { isOldBooking, calculateMinMaxMinutes };
