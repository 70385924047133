const styles = {
  wrapper: {
    textAlign: "center",
  },
  icon: {
    maxHeight: "64px",
    maxWidth: "64px",
  },
  title: {
    fontWeight: 600,
    color: "#100249",
    fontSize: "22px",
    lineHeight: "24px",
    fontFamily: "Museo",
    padding: "16px 0px",
  },
  description: {
    color: "#8B96AD",
    fontSize: "16px",
  },
};

export default styles;