import { Box } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Colors } from "../../constants/colors";
import { formatToPrice } from "../../helpers/number";
import { useMobile } from "../../hooks/mobile";
import { Purchase } from "../../models";
import { useUserStore } from "../../stores/user";
import { getValue } from "../../utils/object";
import Dialog from "../Dialog";
import ModalHeader from "../ModalHeader";
import { DEFAULT_DATE_FORMAT } from "../../constants/date";

interface PlatformFeeData {
  title: string;
  remarks: string;
}

interface Props {
  platformFeeData: PlatformFeeData;
  purchase: Purchase | null;
  open: boolean;
  onClose: () => unknown;
}

interface RowProps {
  title: string;
  value: string;
}

function Row({ title, value }: RowProps) {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" mt={0.5} mb={0.5}>
      <Box fontFamily="Museo" fontSize="18px" fontWeight={700} color={Colors.NightBlue}>
        {title}
      </Box>
      <Box fontFamily="Museo" fontSize="18px" fontWeight={500} color={Colors.NightBlue}>
        {value}
      </Box>
    </Box>
  );
}

export default function PurchaseDetailsModal({ platformFeeData, purchase, open, onClose }: Props) {
  const isMobile = useMobile();
  const { user } = useUserStore();

  const subTotal = purchase?.priceWithoutDiscount || 0;

  const currency = getValue(purchase, "coupon.selectedCountry.currencySymbol", "A$");

  let totalPrice = purchase?.price || 0;
  totalPrice += purchase?.clientPlatformFee || 0;

  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      <Box bgcolor="white" width={isMobile ? "100%" : "640px"} borderRadius="11px">
        <ModalHeader title={`Order #${purchase?.id}`} onClose={onClose} />
        <Box paddingLeft="40px" paddingRight="40px" paddingBottom="40px">
          {purchase && (
            <>
              <Row title="Order number" value={`${purchase.id}`} />
              <Row title="Gift" value={`${purchase.coupon?.couponText}`} />
              <Row title="Voucher code" value={`${purchase.coupon?.code}`} />
              <Row
                title="Delivery date"
                value={`${moment(purchase.sendOnDate).format(DEFAULT_DATE_FORMAT)}`}
              />
              <Row title="From" value={`${purchase.fromName}`} />
              <Row title="To" value={`${purchase.toName}`} />
              <Row title="Delivered to" value={purchase.recipientEmail || user?.email || ""} />
              <Row title="Note" value={`${purchase.note}`} />
              <Row title="Subtotal" value={`${formatToPrice(subTotal, currency)}`} />
              {purchase.clientPlatformFee ? (
                <Row
                  title={platformFeeData.title}
                  value={`${formatToPrice(purchase.clientPlatformFee || 0, currency)}`}
                />
              ) : (
                <></>
              )}
              {purchase.couponUsedAtCheckout && (
                <Row
                  title={`Coupon (${purchase.couponUsedAtCheckout.code})`}
                  value={
                    purchase.couponUsedAtCheckout.type === "percent"
                      ? `-${purchase.couponUsedAtCheckout.value}%`
                      : purchase.couponUsedAtCheckout.type === "value"
                        ? `-${formatToPrice(purchase.couponUsedAtCheckout.value, currency)}`
                        : ""
                  }
                />
              )}
              {purchase.creditUsed && purchase.creditUsed > 0 && (
                <Row title="Credit" value={`-${formatToPrice(purchase.creditUsed, currency)}`} />
              )}
              <Row title="Total" value={`${formatToPrice(totalPrice, currency)}`} />
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
