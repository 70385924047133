import React from "react";
import { Box, Checkbox } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import Dropdown from "../../../../components/Dropdown";
import * as scheduleService from "./schedule.service";
import { DefaultWeekScheduleInterface, TimeInterface, WeekDayScheduleInterface } from "./interface";

interface Props {
  schedule: DefaultWeekScheduleInterface;
  timeRanges: TimeInterface[];
  handleCheckBoxClick: (weekDay: number, isActive: boolean) => void;
  handleTimeChange: (params: WeekDayScheduleInterface) => void;
  index: number;
}

const DaySchedule = ({
  schedule,
  handleCheckBoxClick,
  handleTimeChange,
  timeRanges,
  index,
}: Props) => {
  const timeRangesForStartTime = scheduleService.getFilteredTimeRanges(
    schedule.endTime,
    [...timeRanges],
    true
  );
  const timeRangesForEndTime = scheduleService.getFilteredTimeRanges(
    schedule.startTime,
    [...timeRanges],
    false
  );
  return (
    <Box style={styles.dayWrapper}>
      <Box style={styles.day}>
        <Checkbox
          style={{
            color: schedule.isActive ? Colors.TurquoiseBlue : Colors.BlueyGrey,
            margin: "0px 8px 0px 0px",
            width: 16,
            height: 16,
            border: 2,
            borderRadius: 4,
            padding: 0,
          }}
          checked={schedule.isActive}
          onClick={() => handleCheckBoxClick(schedule.weekDay, schedule.isActive)}
        />
        {schedule.title}
      </Box>
      <Box style={styles.timeSelect}>
        <Dropdown
          options={timeRangesForStartTime}
          onSelectedOption={(option) => {
            handleTimeChange({
              weekDay: schedule.weekDay,
              startTime: option.value,
              endTime: null,
              isActive: schedule.isActive,
            });
          }}
          isDisabled={!schedule.isActive}
          selectedOption={timeRanges.find(
            (time: { value: number }) => time.value === parseInt(schedule.startTime as string)
          )}
          isDropdownDirectionUp={index > 2}
        />
      </Box>
      <Box style={styles.timeSelect}>
        <Dropdown
          options={timeRangesForEndTime}
          onSelectedOption={(option) => {
            handleTimeChange({
              weekDay: schedule.weekDay,
              endTime: option.value,
              startTime: null,
              isActive: schedule.isActive,
            });
          }}
          isDisabled={!schedule.isActive}
          selectedOption={timeRanges.find(
            (time: { value: number }) => time.value === parseInt(schedule.endTime as string)
          )}
          isDropdownDirectionUp={index > 2}
        />
      </Box>
    </Box>
  );
};

const styles = {
  dayWrapper: {
    display: "flex",
  },
  day: {
    display: "flex",
    fontFamily: "Museo",
    fontSize: "16px",
    alignItems: "center",
    width: "280px",
    color: "#41506F",
  },
  timeSelect: {
    fontFamily: "Museo",
    fontSize: "14px",
    width: "182px",
    padding: "0px 15px",
  },
};

export default DaySchedule;
