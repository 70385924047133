import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import { AlternativeOffer as AlternativeOfferCard } from "@get-blys/ui.alternative-offer";
import { Paths } from "../../../constants/paths";
import { getValue } from "../../../utils/object";
import Wrapper from "../../../components/Wrapper";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { useAlertStore } from "../../../stores/alert";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import SectionPageHeader from "../../../components/SectionPageHeader";
import InfoModal from "../../../components/Modals/InfoModal/InfoModal";
import { ConfirmAlternativeTimeModal } from "./ConfirmAlternativeTimeModal";
import { transformPriceForAlternativeOffer } from "../../../stores/V2/booking/booking.transformer";
import {
  acceptAlternativeOffer,
  declineAlternativeOffer,
  getAlternativeOffersForBooking,
  getOfferedPriceBreakdown,
} from "../../../services/bookings/alternativeOffer.service";
import { FontSize } from "../../../components/v2/Styled/enum";
import { formatDateWithTimezone, formatTime } from "../../../utils/date.util";
import { DEFAULT_TIMEZONE } from "../../../constants/time";
import { useCachedBookingSort } from "../../../hooks/utility/filter.hooks";
import UseBookingQueryFilter from "../../../hooks/booking/bookingFilter.hook";
import { useUpcomingBookings } from "../../../hooks/booking/booking.hooks";

const priceInitialState = {
  price: 0,
  credit: 0,
  subtotal: 0,
  surcharge: 0,
  processingFee: 0,
  coupon: {
    code: "xxxxxx",
    amount: 0,
  },
  currency: {
    symbol: "$",
  },
  peakHourInfo: {
    title: "",
    description: "",
    data: [],
  },
  processingFeeInfo: {
    title: "",
    description: "",
  },
};

const AlternativeOffer = () => {
  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { state } = location;
  const bookingId = getValue(state, "bookingId");
  const timezone = getValue(state, "timezone");

  const [isLoading, setIsLoading] = useState(false);
  const [alternativeOffers, setAlternativeOffers] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(0);
  const [prices, setPrices] = useState(priceInitialState);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState({
    title: "",
    description: "",
    data: [],
  });

  const { cachedSortBy } = useCachedBookingSort();
  const bookingSortBy = cachedSortBy || "asc";
  const refetchUpcomingQuery = { sortBy: bookingSortBy };
  const { safeBookingQueries } = UseBookingQueryFilter({ forUpcoming: true });

  const { invalidateUpcomingBookings } = useUpcomingBookings({
    query: { ...refetchUpcomingQuery, ...safeBookingQueries },
  });

  useEffect(() => {
    getAllAlternativeOffers(bookingId);
  }, [bookingId]);

  const getAllAlternativeOffers = async (bookingId: number) => {
    try {
      setIsLoading(true);
      const response = await getAlternativeOffersForBooking(bookingId);
      if (response && response.status === "OK") {
        setAlternativeOffers(response.data);
      }
    } catch (err) {
      setErrorMessage("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOfferDecline = async (offerId: number) => {
    try {
      setIsLoading(true);
      const response = await declineAlternativeOffer(offerId);
      if (response && response.success) {
        const message = response.message || "Offer has been declined.";
        setSuccessMessage(message);
        await getAllAlternativeOffers(bookingId);
      }
    } catch (err) {
      setErrorMessage("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const viewOfferPriceDetail = async (offer: any) => {
    try {
      setIsLoading(true);
      setSelectedOfferId(offer.id);
      const response = await getOfferedPriceBreakdown(offer.id);
      if (response && response.success) {
        const transformPrices = transformPriceForAlternativeOffer(response.data);
        setPrices(transformPrices);
        setShowConfirmModal(true);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAlternativeTimeConfirm = async (offerId: number) => {
    try {
      setIsLoading(true);
      const response = await acceptAlternativeOffer(offerId);
      if (response && response.success) {
        const message = response.message || "Offer has been accepted.";
        setSuccessMessage(message);
        invalidateUpcomingBookings();
        setTimeout(() => {
          history.goBack();
        }, 600);
      }
    } catch (err) {
      setErrorMessage("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInfoModal = ({ title, description, data = [] }: any) => {
    setInfoModalData({
      title,
      description,
      data,
    });

    setShowInfoModal(true);
  };

  const back = getValue(state, "back");
  const isRedirected = getValue(state, "isRedirected", false);

  const handleBack = () => {
    if (isRedirected) {
      history.goBack();
    } else if (back) {
      history.push(back);
    } else {
      history.push(Paths.Bookings);
    }
  };

  const getDescriptionForOffer = (offer: any, proName: string) => {
    const { time, preferredPayout, price, timezone, currency } = offer;
    const formattedDate = formatDateWithTimezone(time, timezone || DEFAULT_TIMEZONE);
    const formattedTime = formatTime(time, "hh:mm a ");
    const boldStyles = { color: Colors.Dusk };

    return (
      <Box fontFamily="Open Sans" fontWeight={600} color={Colors.BlueyGrey} fontSize={FontSize.F14}>
        {proName} can do it on <span style={boldStyles}>{formattedDate}</span> at
        <span style={boldStyles}> {formattedTime}</span>
        {preferredPayout && price && (
          <span>
            for
            <span style={boldStyles}>
              {" "}
              {currency}
              {price}
            </span>
          </span>
        )}
        .
      </Box>
    );
  };

  return (
    <Wrapper>
      <SectionPageHeader
        title={"Offers from providers"}
        titleStyle={
          isMobile
            ? {
              fontFamily: "Museo",
              fontWeight: 700,
              fontSize: "20px",
              color: Colors.NightBlue,
              textAlign: "left",
            }
            : undefined
        }
        subtitle={
          "While we're still searching to find someone for your booking, these providers have you their availabilities and/or preferred rates. You may choose one of the offers below to confirm your booking immediately."
        }
        subtitleStyle={
          isMobile
            ? {
              fontFamily: "Open Sans",
              fontSize: "14px",
              letterSpacing: "0.39px",
              color: Colors.Dusk,
              textAlign: "left",
            }
            : {
              fontSize: "16px",
              maxWidth: "878px",
              color: Colors.Dusk,
            }
        }
        onBack={handleBack}
      />
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        ml={isMobile ? "24px" : "80px"}
        mr={isMobile ? "24px" : "80px"}
        mt={"42px"}
        alignItems={isMobile ? "center" : undefined}
        maxWidth={isMobile ? undefined : "936px"}
      >
        <Box display="flex" flexDirection="column" width={"100%"}>
          {alternativeOffers.map((offer: any, idx: number) => {
            const proInfo = {
              name: getValue(offer, "user.firstName"),
              image: getValue(offer, "user.therapistprofile.profileImage"),
            };
            return (
              <Box mb={"16px"} key={offer.id}>
                <AlternativeOfferCard
                  timezone={timezone}
                  isMobile={false}
                  position={idx + 1}
                  description={getDescriptionForOffer(offer, proInfo.name)}
                  proInfo={{
                    name: proInfo.name,
                    image: `${BASE_UPLOADS_URL}/${proInfo.image}`,
                  }}
                  onDecline={() => {
                    handleOfferDecline(offer.id);
                  }}
                  onAccept={() => {
                    viewOfferPriceDetail(offer);
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </Box>
      <ConfirmAlternativeTimeModal
        visible={showConfirmModal}
        onBackPress={() => setShowConfirmModal(false)}
        onConfirm={() => handleAlternativeTimeConfirm(selectedOfferId)}
        onInfoPressed={(data) => {
          handleInfoModal(data);
          setShowConfirmModal(false);
        }}
        prices={prices}
        isLoading={isLoading}
      />
      <InfoModal
        visible={showInfoModal}
        title={infoModalData?.title}
        description={infoModalData?.description}
        data={infoModalData.data}
        handleClose={() => {
          setShowInfoModal(false);
          setShowConfirmModal(true);
        }}
        divider={false}
      />
    </Wrapper>
  );
};

export default AlternativeOffer;
