export enum Paths {
  Login = "/login",
  MfaLogin = "/mfa-login",
  SignUp = "/signup",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  Bookings = "/bookings",
  Therapists = "/my-therapists",
  BlockedTherapists = "/my-therapists/blocked",
  RecentTherapists = "/my-therapists/recent",
  BrowseTherapists = "/my-therapists/browse",
  Addresses = "/addresses",
  Payment = "/payment",
  Profile = "/profile",
  Preferences = "/preferences",
  Business = "/business",
  TermsAndPolicies = "/terms-policies",
  Membership = "/membership",
  Users = "/user",
  Settings = "/settings",
  Team = "/settings?section=team",
  PastBookings = "/bookings?section=past&page=1",
  NewBookingLanding = "/new-booking",
  NewBooking = "/new-booking/new",
  ServiceDetails = "/new-booking/service",
  RecipientDetails = "/new-booking/recipient",
  LocationDetails = "/new-booking/location",
  SavedLocations = "/new-booking/saved-locations",
  SavedRecipients = "/new-booking/saved-recipients",
  DateAndTime = "/new-booking/datetime",
  BookingFrequency = "/new-booking/booking-frequency",
  PreferredProviders = "/new-booking/preferred-providers",
  ReviewAndBook = "/new-booking/review",
  ReviewAndBookUser = "/new-booking/review/user",
  Recipients = "/recipients",
  AddRecipient = "/recipients/add",
  RecipientDetailsPage = "/recipients/:id/:section",
  Purchases = "/purchases",
  PurchaseVoucher = "/gift",
  PurchaseVoucherSignUp = "/gift-new-user",
  PurchaseVoucherPayment = "/gift-payment",
  AfterpayVoucherSuccess = "/afterpay-voucher-success",
  AfterpayVoucherError = "/afterpay-voucher-error",
  PurchaseVoucherSuccess = "/gift-success",
  AfterpayProcessOrder = "/afterpay-process-order/:id",
  AfterpaySuccess = "/afterpay-success/:id",
  AfterpayError = "/afterpay-error/:id",
  ProDashboard = "/pro/dashboard",
  ProDashboardProfile = "/pro/profile",
  ProDashboardServices = "/pro/services",
  ProDashboardBusiness = "/pro/business",
  ProDashboardBusinessPayoutInformation = "/pro/business/payout-information",
  ProDashboardReviews = "/pro/reviews",
  ProDashboardBookings = "/pro/bookings",
  ProDashboardJobDetails = "/pro/bookings/:id",
  ProDashboardReports = "/pro/reports",
  ProDashboardServiceAreas = "/pro/service-area",
  ProDashboardAvailability = "/pro/availability",
  ProDashboardSettings = "/pro/settings",
  PublicReviews = "/reviews",
  PublicTherapists = "/therapists",
  ApplePay = "/apple-pay",
  CorporateSlots = "/bookings/corporate",
  ProConfirmedBooking = "/pro/confirmed-booking/:id",
  ReviewConfirmedBookingLocation = "/bookings/location",
  ProReviewConfirmedBookingLocation = "/pro/confirmed-booking-review-location",
  Referrals = "/pro/referrals",
  AgreeLinkDocument = "/document/:id/agree",
  Redirect = "/redirect",
  DomainRedirect = "/domain",
  ShortUrlPro = "/p",
  ShortUrlClient = "/c",
}
