import { Box } from "@material-ui/core";
import TextLink from "../../../../components/TextLink";
import { JobAgreement } from "../../../../models";
import { formatUrl } from "../../../../utils/url";
import { checkIfEmpty } from "../../../../utils/object";
import {
  AGE_CARE_TERMS_DOCUMENTS_TYPE_LABEL,
  USER_UPLOADED_CONTENT_TYPE,
} from "../../../../data/enums";

interface Props {
  agreements?: JobAgreement[];
}

const JobAgreements = ({ agreements }: Props) => {
  if (!agreements || !agreements.length) return <></>;

  const onAgreementClick = ({ link, contentType }: any) => {
    const isDocumentTypeLink = contentType === USER_UPLOADED_CONTENT_TYPE.LINK;
    const fileURL = isDocumentTypeLink ? formatUrl(link) : link;

    window.open(fileURL, "_blank", "noreferrer");
  };

  const getDocumentTypeName = (agreement: JobAgreement) => {
    if (checkIfEmpty(agreement)) return "";

    const { name, type } = agreement;
    const title = type === AGE_CARE_TERMS_DOCUMENTS_TYPE_LABEL.OTHER ? name || type : type;
    return title;
  };

  return (
    <Box>
      {agreements?.map((agreement) => {
        const { link, contentType }: any = agreement;
        const title = getDocumentTypeName(agreement);
        return (
          <Box marginBottom="2px">
            <TextLink
              title={title}
              onClick={() => onAgreementClick({ link, contentType })}
              fontSize="14px"
              fontWeight={700}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default JobAgreements;
