import Box from "@material-ui/core/Box";
import { BOOKING_STATUS, JobStatus } from "../../../constants/booking";
import { Colors } from "../../../constants/colors";
import BookingStatusPill from "../../Bookings/BookingStatusPill";

interface Props {
  jobId: number | string;
  jobStatus: JobStatus;
  bookingStatus: string;
}

const JobHeaderSubtitle = ({ jobId, jobStatus, bookingStatus }: Props) => {
  return (
    <Box display={"flex"} alignItems={"center"} gridGap={10} mt={1}>
      <Box fontSize={16} fontFamily={"Museo"} color={Colors.Dusk}>
        Booking #{jobId}
      </Box>
      {bookingStatus === BOOKING_STATUS.DECLINED ? (
        <BookingStatusPill status={jobStatus} bookingStatus={bookingStatus} />
      ) : (
        <BookingStatusPill status={jobStatus} />
      )}
    </Box>
  );
};

export default JobHeaderSubtitle;
