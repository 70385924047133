import axios from "axios";
import * as httpClient from "../../api/client";
import { isArray } from "../../utils/array";
import { getValue } from "../../utils/object";
import { checkIsString } from "../../utils/string";

const requestBlysFeeInvoice = (bookingId: number, accessToken: string | null) =>
  axios.get(`/bookings/requestInvoice/${bookingId}`, { params: { accessToken } });

const requestServiceFeeInvoice = (bookingId: number, accessToken: string | null) =>
  axios.get(`/api/v2/invoice/serviceFee/${bookingId}`, { params: { accessToken } });

const requestSummaryInvoice = (bookingId: number, accessToken: string | null) =>
  httpClient.get(`api/v2/invoice/request-summary-and-details-invoice/${bookingId}`);

const getClientTaxInvoice = (bookingId: number, therapistId?: number) =>
  httpClient.get(`api/v2/invoice/${bookingId}/${therapistId ? therapistId : ""}`);

const getInvoiceTypes = (bookingId: number) => {
  return httpClient
    .get(`api/v2/bookings/${bookingId}/invoice-types`)
    .then((response) => response.data);
};

const getInvoiceLinks = async (endpoint: string): Promise<Array<string>> => {
  const response = await httpClient.get(endpoint);
  const invoiceLinks = getValue(response, "invoiceLinks");
  if (isArray(invoiceLinks)) return invoiceLinks;

  const invoiceLink = getValue(response, "invoiceLink");
  if (checkIsString(invoiceLink)) return [invoiceLink];

  return [getValue(response, "invoiceLink.link")];
};

export {
  getClientTaxInvoice,
  getInvoiceLinks,
  getInvoiceTypes,
  requestBlysFeeInvoice,
  requestServiceFeeInvoice,
  requestSummaryInvoice,
};
