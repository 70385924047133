import { Box } from '@material-ui/core';

const legends = [
  {
    title: 'Available',
    color: '#FFFFFF'
  },
  {
    title: 'Unavailable',
    color: '#ECEDEF'
  },
  {
    title: 'Booked',
    color: '#0DAEC8'
  }
];

export default function CalendarFooter() {
  return (
    <Box display={'flex'} fontFamily={'Open Sans'} fontWeight={400} fontSize={'14px'} color={'#41506F'} paddingTop={'16px'}>
      {
        legends.map((legend, i) => (
          <Box key={i} display={'flex'} marginRight={'24px'}>
            <Box style={{
              backgroundColor: `${legend.color}`,
              border: '1px solid #8B96AD',
              borderRadius: '4px',
              width: '16px',
              height: '16px',
              marginRight: '5px',
            }} />
            <Box>{legend.title}</Box>
          </Box>
        ))
      }
    </Box>
  );
}