import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Colors } from "../../../constants/colors";
import { QUESTION_TYPE } from "../../../constants/questionType";
import { parseApiError } from "../../../helpers/error";
import { useAccessToken } from "../../../hooks/common";
import { BookingQuestion } from "../../../models";
import * as therapistService from "../../../services/therapist/therapist.service";
import { useAlertStore } from "../../../stores/alert";
import { useServicesStore } from "../../../stores/booking";
import { useUserStore } from "../../../stores/user";
import { isImage } from "../../../utils/file.utils";
import { getValue } from "../../../utils/object";
import Uploader from "../../File/Uploader";
import UploadedFileRow from "../../ProDashboard/UploadedFileRow";

interface Props {
  question: BookingQuestion;
  treatmentIndex: number;
  uploadSuccessHandler: (
    data: any,
    questionId: number,
    type: QUESTION_TYPE
  ) => unknown;
  removeSuccessHandler: (imageId: any, questionId: number) => unknown;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const ImageQuestion = ({
  question,
  uploadSuccessHandler,
  removeSuccessHandler,
  treatmentIndex,
  style,
  disabled = false,
}: Props) => {
  const accessToken = useAccessToken();
  const { user, fetchMe } = useUserStore();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { answers } = useServicesStore();

  // fetch current user data
  useEffect(() => {
    if (!user) {
      fetchMe();
    }
  }, [user, fetchMe]);

  const removeImage = ({ id }: any) => {
    if (disabled) return;

    if (user) {
      therapistService
        .removeFile(id, user.id, accessToken)
        .then(() => {
          setSuccessMessage("Image Removed.");
          removeSuccessHandler(id, question.id);
        })
        .catch((error) => setErrorMessage(parseApiError(error)));
    } else {
      setSuccessMessage("Image Removed.");
      removeSuccessHandler(id, question.id);
    }
  };

  // handles image upload
  const handleUpload = (event: any) => {
    if (disabled) return;

    event.stopPropagation();
    event.preventDefault();
    const file = event?.target?.files[0];
    if (file) {
      if (!isImage(file)) {
        return setErrorMessage("Please select image file");
      }

      const form = new FormData();
      form.append("image", file);

      if (user) {
        therapistService
          .uploadFile(user.id, accessToken, form, `question-${question.id}`)
          .then(({ data }) => {
            setSuccessMessage("Image added.");
            uploadSuccessHandler(data, question.id, QUESTION_TYPE.IMAGE);
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage(parseApiError(error));
          });
      } else {
        therapistService
          .uploadPublicImage(form)
          .then(({ data }) => {
            setSuccessMessage("Image added.");
            uploadSuccessHandler(data, question.id, QUESTION_TYPE.IMAGE);
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage(parseApiError(error));
          });
      }
    }
  };

  const currentAnswer = answers[treatmentIndex]?.find(
    (answer: any) => answer.questionId === question.id
  );
  
  let selectedImages = [];
  if (currentAnswer) {
    selectedImages = currentAnswer.answers || [];
  }

  const maxUploadCount = getValue(question, "attrs.max");
  const uploadDisabled =
    maxUploadCount !== undefined && selectedImages.length >= maxUploadCount;

  return (
    <Box
      fontFamily="Museo"
      my="20px"
      color={Colors.Dusk}
      fontSize="14px"
      fontWeight={700}
      style={style}
    >
      <Box>{question.question}</Box>

      <Box>
        <Typography
          style={{
            fontWeight: 400,
            color: Colors.Dusk,
            fontSize: "14px",
            marginTop: "8px",
          }}
        >
          {question.description || ""}
        </Typography>
      </Box>

      {selectedImages.map((uploadedFile: any) => (
        <UploadedFileRow
          key={`reference-image-${uploadedFile.id}`}
          file={uploadedFile}
          onRemove={() => removeImage(uploadedFile)}
        />
      ))}

      <Uploader
        placeholder="Add Image"
        onUpload={handleUpload}
        disabled={uploadDisabled}
        accept="image/*"
      />
    </Box>
  );
};

export default ImageQuestion;
