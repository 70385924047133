import { Box, ButtonBase, Link } from "@material-ui/core";
import moment from "moment";
import { Colors } from "../../../constants/colors";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import { getMassageDescriptionForBookingDetail } from "../../../helpers/booking";
import { firstLetterCapitalized } from "../../../helpers/string";
import { useMobile } from "../../../hooks/mobile";
import ProfilePicturePlaceholder from "../../../images/profile-pic-placeholder.png";
import VerifiedTickIcon from "../../../images/public-verified-tick.svg";
import { PublicReview } from "../../../models";
import { getProfileLink } from "../../../services/therapist/therapist.service";
import Ratings from "../../Ratings";

interface Props {
  review: PublicReview;
}

export default function ReviewItem({ review }: Props) {
  const isMobile = useMobile();

  const url = `${BASE_UPLOADS_URL}/${review.therapist.therapistprofile?.profileImage}`;

  const jobFinishedAt = review.job.finishedAt;
  const jobFinishedAtFormatted = moment(jobFinishedAt).format("MMMM Do YYYY");

  const handleLinkToMassageType = (massageType: string) => {
    const permalink = "https://getblys.com.au/our-massages";
    let type = "";

    switch (massageType) {
      case "swedish":
        type = "swedish";
        break;

      case "deeptissue":
        type = "remedial";
        break;

      case "sports":
        type = "sports";
        break;

      case "pregnancy":
        type = "pregnancy";
        break;

      case "corporatetable":
      case "corporatechair":
        type = "group-bookings";
        break;

      default:
        type = "swedish";
        break;
    }

    window.open(`${permalink}/${type}`);
  };
  return (
    <Box
      width={isMobile ? "88vw" : "636px"}
      p="24px"
      boxSizing="border-box"
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      border="1px solid #DCDFE6"
      borderRadius="8px"
      m="12px"
    >
      <Box display="flex" flexDirection="column">
        <ButtonBase
          style={{
            justifyContent: "flex-start",
          }}
          onClick={() => {
            const url = getProfileLink(review.therapist);
            window.open(url, "_blank");
          }}
        >
          <Box display="flex" flexDirection="column">
            <img
              src={url || ProfilePicturePlaceholder}
              width="96px"
              height="96px"
              style={{
                borderRadius: "48px",
                objectFit: "cover",
              }}
            />

            <Box
              fontFamily="Museo"
              fontSize="24px"
              fontWeight={600}
              color={Colors.NightBlue}
              mt="16px"
              width="179px"
              textAlign="left"
            >
              {review.therapist.firstName}
            </Box>
          </Box>
        </ButtonBase>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        width={isMobile ? "80vw" : "368px"}
        ml={isMobile ? undefined : "41px"}
      >
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={isMobile ? undefined : "space-between"}
          alignItems={isMobile ? undefined : "center"}
          mt={isMobile ? "17.5px" : undefined}
          mb={isMobile ? "8.5px" : undefined}
        >
          <Ratings rating={review.rating} width="15px" height="15px" />
          <Box height="8px" />
          <Box display="flex" flexDirection="row" alignItems="center">
            <img src={VerifiedTickIcon} />
            <Box
              fontFamily="Open Sans"
              fontWeight={600}
              fontSize="14px"
              color={Colors.TurquoiseBlue}
            >
              Verified
            </Box>
          </Box>
        </Box>

        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          mt="12.5px"
          color={Colors.Dusk}
        >
          {review.review}
        </Box>

        <Box
          fontFamily="Museo"
          fontSize="18px"
          lineHeight="27px"
          fontWeight={400}
          mt="16px"
          color={Colors.NightBlue}
        >
          {review.user.firstName} {firstLetterCapitalized(review.user.lastName)}
          , {review.job.bookingdetail.booking.address.suburb},{" "}
          {review.job.bookingdetail.booking.address.state}
        </Box>

        <Box
          fontFamily="Open Sans"
          fontWeight={400}
          fontSize="14px"
          lineHeight="21px"
          color={Colors.BlueyGrey}
        >
          <Link
            style={{ color: Colors.BlueyGrey, cursor: "pointer" }}
            onClick={() => {
              handleLinkToMassageType(review.job.bookingdetail.massageType);
            }}
          >
            {getMassageDescriptionForBookingDetail(review.job.bookingdetail)}
          </Link>
          , {jobFinishedAtFormatted}
        </Box>
      </Box>
    </Box>
  );
}
