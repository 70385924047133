export const Links = {
  GiftVouchers: "https://getblys.com.au/massage-gift-voucher/",
  HelpAndSupport: "https://help.getblys.com.au/support/solutions/33000134882",
  JoinTheWaitingList: "https://blys.typeform.com/to/Jjq7hj",
  OrderSnackPack: "https://blys.typeform.com/to/bt0Rkd",
  CorporateWellness: "https://getblys.com.au/business/corporate-wellness/",
};

export const SITE_URLS = {
  au: "app.getblys.com.au",
  default: "app.getblys.com",
};

export const HOME_PAGE: Record<string, string> = {
  AU: "https://getblys.com.au",
  US: "https://getblys.com/us",
  GB: "https://getblys.com/uk",
  DEFAULT: "https://getblys.com.au",
};
