import React from "react";
import { Box } from "@material-ui/core";
import { Timeline } from "../../Timeline/Timeline";
import { tranformPayoutTimeline } from "./PayoutTimeline.transformer";
interface Props {
  proPayouts: [];
}

export const PayoutTimeline = ({ proPayouts }: Props) => {
  const timelineItems = tranformPayoutTimeline({
    proPayouts,
  });
  return (
    <Box>
      <Timeline items={timelineItems} />{" "}
    </Box>
  );
};
