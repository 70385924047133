export enum StripeConnectAccountStatus {
  CONNECTED = "connected",
  NOT_CONNECTED = "not_connected",
  INCOMPLETE = "incomplete",
}

export enum StripeConnectAccountDetailStatus {
  InReview = "inReview",
  Verified = "verified",
  ActionRequired = "actionRequired",
  ActionRequiredOverdue = "actionRequiredOverdue",
}

export enum StripeConnectAccountType {
  STANDARD = "standard",
  EXPRESS = "express",
  CUSTOM = "custom",
  NONE = "none", // Indicates that the account was created with controller attributes that don’t map to a type of standard, express, or custom.
}

export const UNMIGRATED_USER_ID_LIST = [
  172682, 173160, 174404, 173328, 172617, 170877, 169544, 166502, 173379, 170643, 173373, 165402,
  167159,
];
