import { Box, Typography } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import { Display, FlexDirection, Spacing } from "../../../v2/Styled/enum";
import TextButton from "../../../TextButton/TextButton";
import { DetailsModal } from "../../../Modals/DetailsModal/DetailsModal";
import { useState } from "react";
import { InfoSection } from "./InfoSection";
import transformPayoutInfoDetails from "./payoutInfo.transformer";
import { ViewDetailsBtn } from "./ViewDetailsBtn";
import { useMobile } from "../../../../hooks/mobile";

export interface PayoutDetails {
  label: string;
  content: string;
  // value: string;
  valueToDisplay: string;
  contentReplacer: any[];
}

interface Props {
  title: string;
  description: string | [];
  value: string;
  details?: PayoutDetails[] | null;
}

export const PayoutInfo = ({ title, description, value, details = null }: Props) => {
  const isMobile = useMobile();
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const transformedDetails = transformPayoutInfoDetails(details);
  return (
    <Box
      style={{
        backgroundColor: Colors.PaleGrey,
      }}
      padding={Spacing.S6}
    >
      <InfoSection title={title} description={description} value={value} />
      {details && (
        <Box mt={Spacing.S2}>
          <ViewDetailsBtn
            onClick={() => {
              setShowInfoModal(true);
            }}
          />
        </Box>
      )}
      <DetailsModal
        fullScreen={isMobile}
        visible={showInfoModal}
        onClose={() => {
          setShowInfoModal(false);
        }}
        headerStyle={{
          backgroundColor: Colors.PaleGrey,
        }}
        headerComponent={<InfoSection title={title} description={description} value={value} />}
      >
        <Box
          style={{
            gap: Spacing.S4,
            display: Display.Flex,
            flexDirection: FlexDirection.Column,
          }}
        >
          {(transformedDetails || []).map(({ label, description, value },index) => {
            return <InfoSection key={index} value={value} description={description} title={label} />;
          })}
        </Box>
      </DetailsModal>
    </Box>
  );
};
