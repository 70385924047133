import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getValue } from "../../../../utils/object";
import { Colors } from "../../../../constants/colors";
import TextField from "../../../../components/TextField";
import { environment } from "../../../../constants/common";
import AddressLocationPin from "../../../../images/address-location-pin.svg";
import { getCountryRestrictions } from "../../../../services/countries/countries.services";
import { getSearchResults, GooglePlaceSearchResult, placeDetails } from "../../../../helpers/google";
import { dropdownStylesCombined, StyledButtonBase } from "../../../../components/Dropdown";
import { MIN_LENGTH_TO_AUTO_SEARCH } from "../../../../constants/address";

interface Props {
  value: string | null;
  hasError: boolean;
  onAutoCompleteChange: (param: {
    latitude: number;
    longitude: number;
    address: string;
  }) => unknown;
  title?: string;
  placeholder?: string;
  description?: string;
}

export default function SuburbAutoComplete({
  value,
  onAutoCompleteChange,
  title = "Location",
  placeholder = "Enter suburb name",
  description = "",
  hasError = false,
}: Props) {
  const [address, setAddress] = useState("");
  const [searchResults, setSearchResults] = React.useState<Array<GooglePlaceSearchResult>>([]);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [countryRestrictions, setCountryRestrictions] = React.useState([]);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  const fetchCountryRestrictions = async () => {
    const data = await getCountryRestrictions();
    if (data) {
      const countryList = getValue(data, "countries", []);
      const restrictedCountries = environment.isProduction
        ? countryList
        : countryList.concat(["np"]);
      setCountryRestrictions(restrictedCountries);
    }
  };

  useEffect(() => {
    fetchCountryRestrictions();
  }, []);

  useEffect(() => {
    if (value) setAddress(value);
  }, [value]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onAddressChange = (value: string) => {
    setAddress(value);
    if (value.length >= MIN_LENGTH_TO_AUTO_SEARCH) {
      getSearchResults(value, countryRestrictions)
        .then((results) => {
          setSearchResults(results);
        })
        .catch((err) => {
          console.debug("Fetch places search error", err);
        });
    }
  };

  const onAddressSelect = (address: string, placeId: string) => {
    setShowDropdown(false);
    setAddress(address);

    placeDetails(placeId).then((placeData) => {
      if (placeData.geometry?.location) {
        onAutoCompleteChange({
          latitude: placeData.geometry?.location?.lat(),
          longitude: placeData.geometry?.location?.lng(),
          address,
        });
      }
    });
  };

  return (
    <>
      <div ref={containerRef}>
        <Box position="relative">
          <TextField
            title={title}
            subtitle={description}
            placeholder={placeholder}
            onChange={onAddressChange}
            value={address}
            onFocus={() => {
              setShowDropdown(true);
            }}
            onBlur={() => {}}
          />
          {!showDropdown && hasError && (
            <Box fontSize={"12px"} color="red">
              Enter your location to receive notification
            </Box>
          )}
          {showDropdown && !!searchResults.length && (
            <Box position="absolute" style={dropdownStylesCombined}>
              {searchResults.map((result, index) => (
                <React.Fragment key={index}>
                  <Box display="flex" flexDirection="row">
                    <StyledButtonBase
                      onClick={() => {
                        onAddressSelect(result.formatted_address, result.place_id);
                      }}
                    >
                      <img alt="pin" src={AddressLocationPin} style={{ marginLeft: "21px", marginRight: "21px" }} />
                      <Box
                        fontFamily="Open Sans"
                        fontSize="16px"
                        color={Colors.Dusk}
                        textAlign="left"
                        flex={1}
                        fontWeight={600}
                        mr="16px"
                      >
                        {result.formatted_address}
                      </Box>
                    </StyledButtonBase>
                  </Box>

                  {index !== searchResults.length && <Box width="100%" height="1px" bgcolor={Colors.LightPeriwinkle} />}
                </React.Fragment>
              ))}
            </Box>
          )}
        </Box>
      </div>
    </>
  );
}
