import React, { useEffect, useState } from "react";
import { useMobile } from '../../hooks/mobile';
import { RecaptchaProps } from 'react-recaptcha';

export default function CustomReCaptchaProvider({ elementID, verifyCallback, onloadCallback, sitekey }: RecaptchaProps): JSX.Element {
  let reCaptchaRef: any = React.useRef();
  const isMobile = useMobile();
  const [loading, setLoading] = useState(true);

  const isReCaptchaReady = (): boolean => {
    return typeof window !== 'undefined'
      && typeof window.grecaptcha !== 'undefined'
      && typeof window.grecaptcha.render === 'function';
  }

  const waitForReCaptcha = new Promise(resolve => {
    let interval = setInterval(() => {
      if (isReCaptchaReady()) {
        clearInterval(interval);
        resolve(window.grecaptcha);
      }
    }, 1000);
  });



  const renderReCaptcha = () => {
    waitForReCaptcha.then((reCaptcha: any) => {
      setLoading(false);
      if (onloadCallback) onloadCallback();

      const el = reCaptchaRef || document.getElementById('google-captcha-section');
      if (el) {
        reCaptcha.render(el, {
          sitekey,
          elementID,
          callback: verifyCallback,
          render: "explicit"
        });
      }
    });
  }

  useEffect(() => {
    if (loading) renderReCaptcha();
  }, [isMobile]);

  return (
    <div ref={(el) => { reCaptchaRef = el }} id="google-captcha-section" />
  )
}