import { getDefaultSiteURL } from "../constants/common";
import { checkIfEmpty } from "../utils/object";
import { parseQueryString, stringifyParams } from "./string";

const SITE_URL = getDefaultSiteURL();

const checkIsAUdomain = () => {
  const domainParts = window.location.host.split(".");
  const domain = domainParts.slice(domainParts.length - 2).join(".");
  return domain === "com.au";
};

const getDomainReplacedCurrentURL = (
  domain: string = SITE_URL,
  includeToken: boolean = true
) : string => {
  const { pathname, search } = window.location;
  let url = `${domain}/domain${pathname}`;

  const params = parseQueryString(search);

  const accessToken = localStorage.getItem("token");
  if (includeToken && !!accessToken && !params.accessToken) {
    params.redirectToken = accessToken;
  };

  if (!checkIfEmpty(params)) {
    const queryString = stringifyParams(params);
    url += `?${queryString}`;
  }

  return url;
};

export {
  checkIsAUdomain,
  getDomainReplacedCurrentURL,
};
