import axios from "axios";
import qs from "query-string";
import { getAccessToken } from "../../helpers/accessToken";
import { getCurrentTimezone } from "../../utils/date.util";

// handles services related to after pay voucher

const AfterPayVoucherService = {
  // purchase voucher
  purchase: async (body: any) => {
    let accessToken = getAccessToken();

    return axios.post("/api/v2/voucher/afterpay", body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  // on successful purchase
  successPurchase: async (queryString: string) => {
    let { voucherId, orderToken } = qs.parse(queryString);
    let accessToken = getAccessToken();

    return axios.post(
      `/api/v2/voucher/${voucherId}/afterpay/success`,
      { token: orderToken, timezone: getCurrentTimezone() },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },

  // on purchase failed
  failedPurchase: async (queryString: string) => {
    let { voucherId } = qs.parse(queryString);
    let accessToken = getAccessToken();

    return axios.put(
      `/api/v2/voucher/${voucherId}/afterpay/failure`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  },
};

export default AfterPayVoucherService;
