import { Typography } from "@material-ui/core";
import { useUserStore } from "../../stores/user";
import { HOME_PAGE } from "../../constants/links";
import { Colors } from "../../constants/colors";

export function useFlaggedWordContentForProfile() {
  const { user } = useUserStore();

  const userCountry = user?.country || "";
  const websiteHomePage = HOME_PAGE[userCountry.toUpperCase()] || HOME_PAGE.DEFAULT;

  return {
    title: "You cannot use the terms \"CBD\" or \"Cannabis\"",
    content: (
      <Typography>
        As per our{" "}
        <a
          href={`${websiteHomePage}/terms/software-licence-agreement/`}
          target="_blank"
          rel="noreferrer"
          style={{ color: Colors.Indigo }}
        >
          Terms
        </a>{" "}
        and{" "}
        <a
          href={`${websiteHomePage}/privacy/`}
          target="_blank"
          rel="noreferrer"
          style={{ color: Colors.Indigo }}
        >
          Privacy Policy
        </a>
        , usage of the terms “CBD” and “cannabis” is strictly prohibited in profiles, bios, reviews,
        and portfolios on the Blys Platform.
        <br />
        This policy is in place to maintain a safe and compliant environment for all users.
      </Typography>
    ),
  };
}
