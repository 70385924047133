import React, { ReactNode } from "react";
import ContentModal from "../ContentModal/contentModal";
import TextButton from "../../TextButton/TextButton";
import Button, { ButtonType } from "../../Button";
import { Box } from "@material-ui/core";
import { useMobile } from "../../../hooks/mobile";
import { Spacing } from "../../v2/Styled/enum";
import { useHistory } from "react-router-dom";
import { persistDataInUrl } from "../../../helpers/filter";

interface Props {
  children: ReactNode;
  open: boolean;
  onClose: () => unknown;
  handleFilterApply: () => unknown;
  shouldEnableClearAll: boolean;
  handleClearAll: () => unknown;
  title?: string;
  dataToIncludeInUrl?: {};
  filtersName?: string[];
}

export const FilterModal = ({
  children,
  open,
  onClose,
  handleFilterApply,
  shouldEnableClearAll,
  handleClearAll,
  title = "Filter and sort",
  dataToIncludeInUrl = {},
  filtersName = [],
}: Props) => {
  const isMobile = useMobile();

  const history = useHistory();

  const handleDataPersist = () => {
    persistDataInUrl({
      history,
      filtersName,
      dataToIncludeInUrl,
    });
  };

  return (
    <ContentModal
      showCrossIcon={true}
      visible={open}
      onClose={onClose}
      fullWidth={isMobile}
      title={title}
      actions={[
        <TextButton
          text="Clear all"
          type={shouldEnableClearAll ? "danger" : "disabled"}
          onClick={() => {
            handleClearAll();
          }}
        />,
        <Button
          title="Apply"
          type={ButtonType.secondary}
          onClick={() => {
            handleDataPersist();
            handleFilterApply();
          }}
          width={"161px"}
        />,
      ]}
      PaperProps={{
        style: {
          minWidth: !isMobile ? "438px" : "100%",
          minHeight: !isMobile ? undefined : "100%",
        },
      }}
      actionStyle={{
        paddingLeft: Spacing.S6,
        paddingRight: Spacing.S6,
        justifyContent: "space-between",
      }}
      titleStyle={{ fontSize: "16px" }}
    >
      <Box>{children}</Box>
    </ContentModal>
  );
};
