import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";

interface Props extends BoxProps {}
export default function ModalFooter({ children }: BoxProps): JSX.Element {
  const isMobile = useMobile();

  return (
    <Box>
      <Box width="100%" height="1px" bgcolor={Colors.PaleLilac} />

      {isMobile && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          height="88px"
        >
          {children}
        </Box>
      )}

      {!isMobile && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          paddingRight="46.5px"
          height="88px"
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
