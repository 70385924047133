import { ProBookingRequestOptions } from "@get-blys/ui.pro-booking-request-options";
import { Box, CircularProgress } from "@material-ui/core";
import { useConfirmRebook } from "../../../hooks/booking/rebookByPro.hooks";
import { Booking } from "../../../models";
import { useAlertStore } from "../../../stores/alert";
import { getValue } from "../../../utils/object";
import Divider from "../../Divider";

type Props = {
  booking: Booking;
  onDecline: () => unknown;
  onConfirm: () => unknown;
};

const RebookedByProActions = ({ booking, onDecline, onConfirm }: Props) => {
  const { mutate, isLoading: isConfirming } = useConfirmRebook();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const proName = getValue(booking, "bookingdetails[0].job.user.firstName");

  const handleConfirm = () => {
    mutate(booking.id, {
      onSuccess: () => {
        setSuccessMessage("Booking confirmed.");
        onConfirm();
      },
      onError: () => {
        setErrorMessage("We were unable to confirm this booking.");
      },
    });
  };

  return (
    <Box mt={2}>
      <Divider mb="16px"/>
      {isConfirming ? (
        <CircularProgress />
      ) : (
        <ProBookingRequestOptions
          proName={proName}
          onConfirm={handleConfirm}
          onDecline={onDecline}
          isMobile={false}
        />
      )}
    </Box>
  );
};

export default RebookedByProActions;
