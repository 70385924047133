import { useQuery } from "@tanstack/react-query";
import { getPaginatedReferrals, getReferralStats } from "../../services/proDashboard/referral.service";

export const REFERRAL_KEYS = {
  STATS: "REFERRAL_STAT",
  REFERRALS: "REFERRALS"
};

export const useReferralStats = () =>
  useQuery([REFERRAL_KEYS.STATS], async () => await getReferralStats().then(({ data }) => data));

export const useReferrals = ({ page }: any) =>
  useQuery([REFERRAL_KEYS.REFERRALS, { page }],
    async () => await getPaginatedReferrals(page).then(({ data }) => data)
  );
