import { Colors } from "../../../../constants/colors";

const styles = {
  contentWrapper: {
    borderRadius: '6px',
    border: `solid 1px ${Colors.PaleLilac}`,
    backgroundColor: Colors.White,
    // padding: '26px'
  }
};

export default styles;
