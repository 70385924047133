import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";

const backgroundColor = (online: boolean) => {
  return online ? Colors.VeryLightGreen : Colors.Cancelled;
};

const text = (online: boolean) => {
  return online ? "ONLINE" : "OFFLINE";
};

interface Props {
  online: boolean;
}

export default function ({ online }: Props): JSX.Element {
  return (
    <Box
      width="110px"
      height="25px"
      borderRadius="12.5px"
      bgcolor={backgroundColor(online)}
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <Box
        fontFamily="Open Sans"
        fontSize="11px"
        fontWeight={700}
        color={Colors.NightBlue}
        letterSpacing="0.5px"
      >
        {text(online)}
      </Box>
    </Box>
  );
}
