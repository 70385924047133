import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";

const KEYS = {
  VOUCHER_CARDS: "VOUCHER_CARDS",
};

export const useVoucherCards = () => useQuery(
  [KEYS.VOUCHER_CARDS], () =>
    get("api/v2/voucher/cards").then(({ data }) => data),
);
