export enum UserPreference {
  useTowels = "use_towels",
  preferenceDuringTreatment = "preference_during_treatment",
  pressureLevel = "pressure_level",
  therapistFocusOn = "therapist_focus_on"
}

export const AllUserPreferences = [
  UserPreference.useTowels,
  UserPreference.preferenceDuringTreatment,
  UserPreference.pressureLevel,
  UserPreference.therapistFocusOn
];

export const labelForUserPreference = (preference: UserPreference) => {
  switch (preference) {
  case UserPreference.useTowels:
    return "Towels / Sheets";

  case UserPreference.preferenceDuringTreatment:
    return "Conversation preference during treatment";

  case UserPreference.pressureLevel:
    return "Level of pressure for your massage";

  case UserPreference.therapistFocusOn:
    return "Area for your therapist to focus on";
  }
};

export const optionsForUserPreference = (preference: UserPreference) => {
  switch (preference) {
  case UserPreference.useTowels:
    return [
      "No preference",
      "Please bring towels or sheets with you",
      "I prefer to use my own towels or sheets"
    ];

  case UserPreference.preferenceDuringTreatment:
    return ["No preference", "Prefer quiet"];

  case UserPreference.pressureLevel:
    return ["No preference", "Light", "Medium", "Firm"];

  case UserPreference.therapistFocusOn:
    return [
      "No preference",
      "Head",
      "Neck",
      "Shoulders",
      "Back",
      "Lower back",
      "Legs",
      "Feet",
      "Other"
    ];
  }
};

export const UserCommunicationNameMap = {
  email: "Email",
  push: "App Push",
  sms: "SMS"
};
export const UserCommunicationMediums = ["email", "push", "sms"];
