import { useEffect, useState } from "react";
import { useGenderPronounOptions } from "../hooks/utility/utilty.hooks";
import Dropdown, { DropdownOption } from "./Dropdown";

interface Prop {
  onChange: (value: string | number) => unknown;
  value?: string | number;
  title?: string;
}

export default function Pronoun({
  onChange,
  value,
  title = "Preferred gender pronoun",
}: Prop) {
  const { pronouns } = useGenderPronounOptions();
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );

  useEffect(() => {
    const pronoun = pronouns.find((pronoun) => pronoun.value === value);
    setSelectedOption(pronoun || null);
  }, [pronouns, value]);

  const handleSelected = (option: DropdownOption) => {
    onChange(option.value);
  };

  return (
    <>
      <Dropdown
        title={title}
        options={pronouns}
        selectedOption={selectedOption}
        onSelectedOption={handleSelected}
      />
    </>
  );
}
