import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import Button, { ButtonType } from "../Button";
import { Colors } from "../../constants/colors";
import { getTimeOptions, minutesToTime } from "../../helpers/time";
import Dropdown, { DropdownOption } from "../Dropdown";
import { getTimeRanges, useServicesStore } from "../../stores/booking";
import ContentModal from "../Modals/ContentModal/contentModal";
import {
  convertToMoment,
  isCurrentDayMoment,
  getCurrentTimeInMinutes,
  getDateStringInMinutes,
  isFutureMoment,
  convertDatetoTimezoneMoment,
} from "../../utils/date.util";
import { checkIfEmpty } from "../../utils/object";

interface Props {
  open: boolean;
  onOpen: () => unknown;
  onClose: () => unknown;
  onChange: () => unknown;
  onAccept: (date: any) => unknown;
  minTime: string;
  maxTime: string;
  timezone: string;
}

const CustomTimePicker = ({
  open,
  onOpen,
  onClose,
  onAccept,
  onChange,
  minTime,
  maxTime,
  timezone = "Australia/Sydney",
}: Props) => {
  const [selectedTime, setSelectedTime] = useState();

  const { timeRange } = useServicesStore();
  const timeOptions = getTimeOptions(timeRange) as DropdownOption[];

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    if (checkIfEmpty(timeRange)) {
      getTimeRanges();
    }
  };

  const filteredTimeRange = () => {
    let minFeasibleInMinutes = getCurrentTimeInMinutes(timezone);
    if (isFutureMoment(minTime, timezone)) {
      minFeasibleInMinutes = getDateStringInMinutes(minTime, timezone);
    }

    const maxFeasibleInMinutes = getDateStringInMinutes(maxTime, timezone);
    return timeOptions.filter(
      ({ value }) => value >= minFeasibleInMinutes && value <= maxFeasibleInMinutes
    );
  };

  const handleSelectedTime = (selected: any) => {
    setSelectedTime(selected);
  };

  const convertTimeInMinutesToProperTime = (day: any, time: any) => {
    const earliestTime = minutesToTime(time || 0);

    const earlyTime = convertDatetoTimezoneMoment(day, timezone)
      .set("hour", earliestTime.hour)
      .set("minute", earliestTime.mins)
      .set("millisecond", 0)
      .set("second", 0);
    return earlyTime;
  };

  const handleAccept = () => {
    const day = new Date(minTime);
    const selected = convertTimeInMinutesToProperTime(day, selectedTime);
    onAccept(selected);
  };

  return (
    <ContentModal
      actions={[
        <Box style={{ ...styles.wrapper, flexDirection: "row" }}>
          <Button title="Cancel" onClick={onClose} type={ButtonType.outlined} />
          <Button title="Accept" onClick={handleAccept} type={ButtonType.secondary} />
        </Box>,
      ]}
      PaperProps={{ style: { overflowY: "visible" } }}
      visible={open}
      onClose={onClose}
      contentStyle={{
        overflowY: "visible",
      }}
    >
      <Box style={styles.header}>Select time of arrival</Box>
      <Dropdown
        options={filteredTimeRange()}
        onSelectedOption={(option) => handleSelectedTime(option.value)}
        selectedOption={timeOptions.find((option) => option.value === selectedTime)}
      />
    </ContentModal>
  );
};

const styles = {
  wrapper: {
    gap: "8px",
    display: "flex",
  },
  header: {
    fontWeight: 700,
    fontSize: "22px",
    fontFamily: "Museo",
    color: Colors.NightBlue,
  },
};

export default CustomTimePicker;
