import moment from "moment";
import { Box } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";

import { get, put } from "../../../api/client";
import { Colors } from "../../../constants/colors";
import DateField from "../../../components/DateField";
import { useAlertStore } from "../../../stores/alert";
import { parseApiError } from "../../../helpers/error";
import GovernmentDocuments from "./GovernmentDocuments";
import governmentOptionsConfig from "../../../config/proDashboard/identityConfig.json";
import Button, { ButtonType } from "../../../components/Button";
import UploadIdentityDocuments from "./UploadIdentityDocuments";
import { IGovernmentData, IGovernmentOption } from "./identity.interface";
import PoliceCheckTickIcon from "../../../images/police-check-confirmed-tick.svg";
import { fetchMyTherapistProfile, useTherapistStore } from "../../../stores/therapist";
import ExtraIformation from "./ExtraInformation";

const policeCheckUrl = "https://blys.checked.com.au/";

const defaultIdentityOption = [
  {
    "title": "Driver License",
    "value": "driversLicense",
    "fields": [
      {
        "name": "licenseNumber",
        "title": "License number",
        "type": "text",
        "placeholder": "",
        "validationMessage": "Please enter your license number"
      },
      {
        "name": "expiryDate",
        "title": "Expiry date",
        "type": "date",
        "placeholder": "",
        "validationMessage": "Please select expiry date"
      }
    ],
    "note": "Please include the front and back of the driver licence"
  },
  {
    "title": "Passport",
    "value": "passport",
    "fields": [
      {
        "name": "documentNumber",
        "title": "Document number",
        "type": "text",
        "placeholder": "",
        "validationMessage": "Please enter your document number"
      },
      {
        "name": "expiryDate",
        "title": "Expiry date",
        "type": "date",
        "placeholder": "",
        "validationMessage": "Please select expiry date"
      }
    ],
    "note": ""
  }
]; 

export default function Identity({ identityConfig }: any) {
  const [saving, setSaving] = useState(false);
  const [validUntil, setValidUntil] = useState<Date | null>(null);
  const initialGovernmentData: IGovernmentData = {
    documentType: "",
    selectedDocument: {
      text: "",
      date: null,
    },
    selectedFiles: [],
  };
  const [governmentData, setGovernmentData] = useState<IGovernmentData>(initialGovernmentData);
  const [governmentOptions, setGovernmentOptions] = useState<IGovernmentOption[]>(defaultIdentityOption);
  const { therapistUser } = useTherapistStore();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { showPoliceCheck, governmentId, policeCheck } = identityConfig;
  const { extraInformationForPoliceCheck }  = policeCheck || {};
  const policeChecked = therapistUser?.therapistprofile?.policeCheck === true;

  const { data: uploadedFilesData, refetch: uploadedFilesMutate } = useQuery(
    ["government-documents"],
    () => {
      return get("api/v2/therapists/identity/government-documents");
    }
  );

  useEffect(() => {
    fetchMyTherapistProfile();
  }, []);

  useEffect(() =>  {
    getIdentityOptionFromConfig(governmentOptionsConfig, therapistUser?.country);
  },[therapistUser?.country]);

  useEffect(() => {
    if (therapistUser) {
      setValidUntil(
        therapistUser?.therapistprofile?.expiryDate
          ? moment(therapistUser.therapistprofile?.expiryDate, "YYYY-MM-DD").toDate()
          : null
      );
    }
  }, [therapistUser]);

  const governmentFileOwnership: string = "governmentId";

  const getIdentityOptionFromConfig = (config: any, countryCode = "AU") => {
    const governmentOptions = config[countryCode] || config["*"];
    setGovernmentOptions(governmentOptions);
  };

  const validateGovernmentData = () => {
    const { documentType, selectedDocument, selectedFiles } = governmentData;
    const currentSelectedOption = governmentOptions?.find((option) => option.value === documentType);
    if (!currentSelectedOption) return true;
    const { fields } = currentSelectedOption;
    const { text, date } = selectedDocument;
    const hasText = text && text.length > 0;
    const hasDate = date && date !== null;
    let isVerified = fields.every((field) => {
      if (field.name === "expiryDate") {
        if (!hasDate) {
          setErrorMessage(field.validationMessage);
        }
        return hasDate;
      }
      if (field.name === "licenseNumber" || field.name === "documentNumber") {
        if (!hasText) {
          setErrorMessage(field.validationMessage);
        }
        return hasText;
      }
      return true;
    });
    if (!isVerified) return isVerified;
    // check this error after all fields are verified
    if (selectedFiles.length === 0) {
      setErrorMessage("Please upload your document");
      isVerified = false;
    }
    return isVerified;
  };

  const handleSave = () => {
    if (!validateGovernmentData()) {
      return;
    }
    let data = {
      expiryDate: therapistUser?.therapistprofile?.expiryDate || "",
    };
    if (showPoliceCheck) {
      const issueDate = moment(validUntil).format("YYYY-MM-DD");
      if (issueDate === "Invalid date") {
        setErrorMessage("Please enter the valid issue date for police check");
        return;
      }
      data.expiryDate = issueDate;
    }

    const form = new FormData();
    for (let i = 0; i < governmentData.selectedFiles.length; i++) {
      form.append("images", governmentData.selectedFiles[i]);
    }
    form.append("expiryDate", data.expiryDate);
    form.append("documentType", governmentData.documentType);
    form.append("documentNumber", governmentData.selectedDocument.text);
    form.append("expiresAt", governmentData.selectedDocument.date?.toString() || "");
    form.append("fileOwnership", governmentFileOwnership);
    setSaving(true);

    put("api/v2/therapists/identity", form)
      .then((response) => {
        setSaving(false);
        uploadedFilesMutate();
        setSuccessMessage("Your documents has been added successfully");
        setGovernmentData(initialGovernmentData);
      })
      .catch((error) => {
        setSaving(false);

        setErrorMessage(parseApiError(error));
      });
  };

  return (
    <Box maxWidth="632px" pb={20}>
      {(showPoliceCheck && policeCheck) && (
        <Box>
          {!policeChecked && (
            <>
              <Box
                fontFamily="Museo"
                fontSize="24px"
                fontWeight={600}
                color={Colors.NightBlue}
                mt="56px"
              >
                {policeCheck.title}
              </Box>

              <Box
                fontFamily="Museo"
                fontSize="16px"
                fontWeight={400}
                color={Colors.NightBlue}
                lineHeight="24px"
                mt="24px"
              >
                {policeCheck.description}
              </Box>

              {
                extraInformationForPoliceCheck &&
                <ExtraIformation extraInformation = {extraInformationForPoliceCheck.noPoliceCheck}/>
              }
              {
                policeCheck.title === "Police Check" &&
                <Box mt="32px">
                  <Button
                    width="189px"
                    type={ButtonType.primary}
                    title="Get a police check"
                    onClick={() => {
                      window.open(policeCheckUrl, "_blank");
                    }}
                  />
                </Box>
              }

            </>
          )}

          {(policeChecked) && (
            <>
              <Box display="flex" flexDirection="row" alignItems="center" mt="56px">
                <Box fontFamily="Museo" fontSize="24px" fontWeight={600} color={Colors.NightBlue}>
                  {policeCheck.title}
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center" ml="24px">
                  <img src={PoliceCheckTickIcon} alt="police check tick icon" />
                  <Box
                    fontFamily="Museo"
                    fontWeight={400}
                    fontSize="16px"
                    color={Colors.TurquoiseBlue}
                  >
                    {policeCheck.title} confirmed
                  </Box>
                </Box>
              </Box>

              <Box
                fontFamily="Museo"
                fontSize="16px"
                fontWeight={400}
                color={Colors.NightBlue}
                lineHeight="24px"
                mt="24px"
              >
                {policeCheck.description}
                <br />
              </Box>
              {
                extraInformationForPoliceCheck &&
                <ExtraIformation extraInformation={extraInformationForPoliceCheck.hasPoliceCheck}/>
              }
              {
                policeCheck.title === "Police Check" &&
                <Box mt="32px">
                  <Button
                    width="222px"
                    type={ButtonType.primary}
                    title="Start new police check"
                    onClick={() => {
                      window.open(policeCheckUrl, "_blank");
                    }}
                  />
                </Box>
              }
            </>
          )}
          <UploadIdentityDocuments fileOwnership="policecheck" />
          <Box>
            <DateField
              title="Issue Date"
              selectedDate={validUntil}
              onSelectedDate={(date) => setValidUntil(date)}
              width="100%"
            />
          </Box>
        </Box>
      )}

      {governmentId && (
        <Box>
          <Box
            mt="64px"
            mb="24px"
            fontSize="24px"
            fontWeight={600}
            fontFamily="Museo"
            color={Colors.NightBlue}
          >
            {governmentId.title}
          </Box>
          <Box
            fontFamily="Museo"
            fontWeight={400}
            fontSize="16px"
            lineHeight="24px"
            color={Colors.Dusk}
          >
            {governmentId.description}
          </Box>
          <GovernmentDocuments
            options={governmentOptions}
            onChange={setGovernmentData}
            governmentData={governmentData}
            uploadedFilesData={uploadedFilesData}
            fileOwnership={governmentFileOwnership}
            uploadedFilesMutate={uploadedFilesMutate}
          />
        </Box>
      )}

      <Button
        style={{ marginTop: "48px" }}
        title="Save"
        loading={saving}
        type={ButtonType.primary}
        width="132px"
        onClick={handleSave}
      />
    </Box>
  );
}
