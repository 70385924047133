import React, { useRef, useState } from "react";
import { Box } from "@material-ui/core";
import Divider from "../../../Divider";
import Dropdown from "../../../Dropdown";
import TextLink from "../../../TextLink";
import TextField from "../../../TextField";
import DateField from "../../../DateField";
import { put } from "../../../../api/client";
import * as Styled from "../../../v2/Styled/enum";
import Button, { ButtonType } from "../../../Button";
import { Colors } from "../../../../constants/colors";
import { useUserStore } from "../../../../stores/user";
import { checkIfEmpty } from "../../../../utils/object";
import { useAlertStore } from "../../../../stores/alert";
import { parseApiError } from "../../../../helpers/error";
import {
  identityOptions,
  initialGovernmentData,
} from "../../../../pages/ProDashboard/Business/Payouts/Forms/model";
import {
  IGovernmentData,
  IGovernmentOptionField,
  ISelectedDocument,
} from "../../../../pages/ProDashboard/Business/identity.interface";

interface Props {
  onDocumentAddSuccess?: () => unknown;
}

const AddGovernmentID = ({ onDocumentAddSuccess }: Props): JSX.Element => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const fileUploadRef = useRef<HTMLInputElement>(null);

  const { user } = useUserStore();
  const { setErrorMessage } = useAlertStore();

  const [governmentData, setGovernmentData] = useState<IGovernmentData>(initialGovernmentData);

  const currentDropdownOption = identityOptions.find(
    (option) => option.value === governmentData.documentType
  );

  const handleChange = (field: keyof IGovernmentData, value: any) => {
    setGovernmentData({
      ...governmentData,
      [field]: value,
    });
  };

  const validateGovernmentData = () => {
    const { documentType, selectedDocument, selectedFiles } = governmentData;
    const currentSelectedOption = identityOptions?.find((option) => option.value === documentType);
    if (!currentSelectedOption) return true;

    const { fields } = currentSelectedOption;
    const { text, date } = selectedDocument;
    let isVerified = true;

    fields.forEach((field) => {
      const validationConditions: Record<string, any> = {
        licenseNumber: !text || checkIfEmpty(text),
        documentNumber: !text || checkIfEmpty(text),
        expiryDate: !date,
      };

      if (validationConditions[field.name]) {
        setErrorMessage(field.validationMessage);
        isVerified = false;
      }
    });

    if (isVerified && selectedFiles.length === 0) {
      setErrorMessage("Please upload your document");
      isVerified = false;
    }

    return isVerified;
  };

  const handleUpload = () => {
    if (!validateGovernmentData()) {
      return;
    }

    setIsUploading(true);
    const form = new FormData();
    for (let i = 0; i < governmentData.selectedFiles.length; i++) {
      form.append("images", governmentData.selectedFiles[i]);
    }
    form.append("expiryDate", String(governmentData.selectedDocument.date));
    form.append("documentType", governmentData.documentType);
    form.append("documentNumber", governmentData.selectedDocument.text);
    form.append("expiresAt", governmentData.selectedDocument.date?.toString() || "");
    form.append("fileOwnership", "governmentId");

    put("api/v2/therapists/identity", form)
      .then((response) => {
        setGovernmentData(initialGovernmentData);
        if (onDocumentAddSuccess && typeof onDocumentAddSuccess === "function") {
          onDocumentAddSuccess();
        }
      })
      .catch((error) => {
        setErrorMessage(parseApiError(error));
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <Box>
      <Box padding={`${Styled.Spacing.S2} ${Styled.Spacing.S6}`}>
        <Dropdown
          title={"Document type"}
          options={identityOptions}
          onSelectedOption={(option) => {
            setGovernmentData((v: IGovernmentData) => ({ ...v, documentType: option.value }));
          }}
          selectedOption={currentDropdownOption}
          paddingTop={0}
          paddingBottom={Styled.Spacing.S2}
        />

        {governmentData.documentType ? (
          <Box>
            {currentDropdownOption?.fields.map((field: IGovernmentOptionField) => (
              <Box key={field.name}>
                {field.type === "text" && (
                  <TextField
                    title={field.title}
                    placeholder={field.placeholder}
                    value={
                      governmentData.selectedDocument[
                        field.type as keyof ISelectedDocument
                      ] as string
                    }
                    onChange={(text) =>
                      handleChange("selectedDocument", {
                        ...governmentData.selectedDocument,
                        [field.type]: text,
                      })
                    }
                  />
                )}
                {field.type === "date" && (
                  <DateField
                    title={field.title}
                    selectedDate={
                      governmentData.selectedDocument[
                        field.type as keyof ISelectedDocument
                      ] as Date | null
                    }
                    onSelectedDate={(date) =>
                      handleChange("selectedDocument", {
                        ...governmentData.selectedDocument,
                        [field.type]: date,
                      })
                    }
                    width="100%"
                    country={user?.country}
                    paddingTop={"8px"}
                    paddingBottom={"0px"}
                  />
                )}
              </Box>
            ))}

            {/*Commenting this block for now as we dont have feature for tracking Front/Back document*/}
            {/*{currentDropdownOption?.note && (*/}
            {/*  <Box*/}
            {/*    fontSize="14px"*/}
            {/*    fontWeight={400}*/}
            {/*    lineHeight="21px"*/}
            {/*    fontFamily="Open Sans"*/}
            {/*    color={Colors.BlueyGrey}*/}
            {/*  >*/}
            {/*    {currentDropdownOption.note}*/}
            {/*  </Box>*/}
            {/*)}*/}

            {governmentData.selectedFiles.length > 0 ? (
              <Box mt={"32px"}>
                {governmentData.selectedFiles.map((selectedFile: File, i: number) => (
                  <Box
                    key={i}
                    mt="9px"
                    mb="9px"
                    display="flex"
                    maxWidth="500px"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box
                      fontFamily="Open Sans"
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="21px"
                      flex={1}
                      color={Colors.Dusk}
                    >
                      {selectedFile.name}
                    </Box>

                    <TextLink
                      title="Remove"
                      destructive
                      onClick={() => {
                        const files = governmentData.selectedFiles.filter(
                          (file: File, idx: number) => idx !== i
                        );
                        handleChange("selectedFiles", files);
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <></>
            )}

            <Box mt="32px" mb="32px" display="flex" flexDirection="row">
              <Button
                style={{ marginRight: "8px" }}
                title="Select file"
                type={ButtonType.outlined}
                width="132px"
                onClick={() => {
                  if (fileUploadRef.current) {
                    fileUploadRef.current.click();
                  }
                }}
              />
            </Box>
          </Box>
        ) : (
          <></>
        )}

        <Box>
          <form encType="multipart/form-data">
            <input
              type="file"
              accept="image/*, application/pdf"
              ref={fileUploadRef}
              style={{ display: "none" }}
              id={"governmentId-upload-file"}
              onChange={(event) => {
                event.stopPropagation();
                event.preventDefault();

                const file = event?.target?.files && event.target.files[0];
                if (!file) return;

                handleChange("selectedFiles", [file]);
              }}
            />
          </form>
        </Box>
      </Box>
      <Box>
        <Divider />
        <Box padding={Styled.Spacing.S6}>
          <Button
            title="Add"
            width="100%"
            type={ButtonType.indigo}
            loading={isUploading}
            onClick={handleUpload}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AddGovernmentID;
