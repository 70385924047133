import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../constants/colors";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
} from "../../v2/Styled/enum";
import { PayoutInfoCard } from "./PayoutInfoCard/PayoutInfoCard";
import { FilterButton } from "../../FilterButton/FilterButton";
import {
  useAccountBalance,
  useEarningsBreakdown,
  useEarningsReport,
  usePayoutFilters,
  useProPayouts,
} from "../../../hooks/payout/payout";
import { PredefineFilterEnum, PredefinedFilter } from "./PredefinedFilter";
import { getValue } from "../../../utils/object";
import { useHistory } from "react-router-dom";
import { LoadingSpinner } from "../../loadingSpinner";
import {
  FilterName,
  ProDashPastBookingFilter,
  SearchFilter,
} from "../../Modals/FilterModal/ProDashPastBookingFilter";
import { getFilterCount } from "../../../helpers/filter";
import { AccountBalance } from "./AccountBalance";
import PaginationControl from "../../PaginationControl";
import { Jobs } from "./Jobs";
import EarningsGraph from "../../../pages/ProDashboard/Reports/EarningsGraph";
import { EarningsChartData } from "../../../models";
import { Chart } from "react-chartjs-2";
import { useMobile } from "../../../hooks/mobile";

Chart.defaults.font.size = 14;
Chart.defaults.font.family = "Open Sans";
interface Params {
  [key: string]: any;
}

export const PastBookings = () => {
  const history = useHistory();
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<object>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedPreDefinedFilter, setSelectedPreDefinedFilter] = useState(PredefineFilterEnum.All);
  const chartSegments = ["Weekly", "Monthly", "Yearly"];
  const [selectedSegmentIndex, setSelectedSegmentIndex] = React.useState(0);
  const { isLoading,data } = useEarningsReport();
  const isMobile = useMobile();

  const { isLoading: isFilterLoading, data: filters } = usePayoutFilters();
  const { isLoading: isPayoutsLoading, data: proPayouts } = useProPayouts({
    params: {
      currentPage,
      ...selectedFilters,
      completed: getValue(selectedFilters, "completed") ?? true,
      cancelled: getValue(selectedFilters, "cancelled") ?? true,
      preDefinedFilter: selectedPreDefinedFilter,
    },
  });
  const { isLoading: isBalanceLoading, data: accountBalanceData } = useAccountBalance();
  const { isLoading: isEarningBreakDownLoading, data: earningBreakdownData } =useEarningsBreakdown();
 
  const initializeData = () => {
    const params = new URLSearchParams(window.location.search);
    const searchParams: Params = {};

    const allowedKeys: Set<FilterName> = new Set([
      FilterName.Completed,
      FilterName.Cancelled,
      FilterName.PayoutStatus,
      FilterName.Overdue,
      FilterName.StartingDate,
      FilterName.EndingDate,
    ]);

    params.forEach((value, key) => {
      if (allowedKeys.has(key as FilterName)) {
        searchParams[key] = key === FilterName.PayoutStatus ? value.split(",") : value;
      }
    });

    const page = params.get("page");
    setCurrentPage(page ? +page : 1);

    const preDefinedFilter = params.get("preDefinedFilter");
    setSelectedPreDefinedFilter(
      (preDefinedFilter as PredefineFilterEnum) || PredefineFilterEnum.All
    );
    setSelectedFilters({
      completed: params.get("completed"),
      cancelled: params.get("cancelled"),
      startingDate: params.get("startingDate"),
      endingDate: params.get("endingDate"),
      overdue: params.get("overdue"),
      payoutStatus: params.get("payoutStatus")?.split(","),
    });
  };

  useEffect(() => {
    if (!isPayoutsLoading && proPayouts) {
      const totalPage = getValue(proPayouts, "pageCount");
      setTotalPage(totalPage);
      console.log(proPayouts, "proPayouts");
    }
  }, [isPayoutsLoading]);

  useEffect(() => {
    initializeData();
  }, []);

  if (isBalanceLoading || isFilterLoading) return <LoadingSpinner />;

  const preDefinedFilter = [
    { label: PredefineFilterEnum.All, value: PredefineFilterEnum.All },
    ...getValue(filters, "preDefinedFilter", []),
  ];

  const paymentStatusFilter = getValue(filters, "paymentStatus", []);
  const bookingStatusFilter = getValue(filters, "bookingStatus", []);

  const filterCount = () => {
    const count = getFilterCount({
      filteredData: selectedFilters,
      considerSingleCount: [["startingDate", "endingDate"]],
    });
    return count;
  };

  const balance = getValue(accountBalanceData, "balance", 0);
  const currencySymbol = getValue(accountBalanceData, "currencySymbol", "");
  const breakDown = getValue(accountBalanceData, "breakDown", []);

  const handlePaginate = (page: number) => {
    const params = new URLSearchParams(history.location.search);
    params.set("page", `${page}`);
    const searchParams = params.toString();
    history.push({ search: searchParams });
  };

  const earnings = data ? (data as any[]) : null;
  const _chartData = earnings ? (earnings[earnings.length - 3] as EarningsChartData[]) : null;
  const _expectedChartData = earnings ? (earnings[earnings.length - 1] as EarningsChartData[]) : null;

  const chartData = () => {
    const keyOptions = ["week", "month", "year"];
    const key = keyOptions[selectedSegmentIndex] || "week";
    let chartFormattedData = null;
    let mergedLabels: string[] = [];
    let thisChartData;
    let expectedChartData;

    if (_chartData) {
      thisChartData = _chartData.find((data) => data.key === key); // TODO: Switch between weekly and monthly
    }
    if (_expectedChartData) {
      expectedChartData = _expectedChartData?.find((data) => data.key === key);
    }

    if (thisChartData) {
      mergedLabels = thisChartData.data.label;
      if(expectedChartData){
        mergedLabels = [...new Set([...thisChartData.data.label, ...expectedChartData.data.label])];
      }
      const data = [];
      for (let i = 0; i <= mergedLabels.length - 1; i++) {
        const index = thisChartData.data.label.indexOf(mergedLabels[i]);
        data.push({ x: mergedLabels[i], y: thisChartData.data.values[index] || 0 });
      }

      chartFormattedData = {
        labels: mergedLabels,
        datasets: [
          {
            label: "Completed bookings",
            data: data,
            backgroundColor: Colors.GoldenYellow
          }
        ],
      };
    }

    if (expectedChartData) {
      const dataEx = [];
      for (let i = 0; i <= mergedLabels.length - 1; i++) {
        const index = expectedChartData.data.label.indexOf(mergedLabels[i]);
        dataEx.push({ x: mergedLabels[i], y: expectedChartData.data.values[index] || 0 });
      }
      if (chartFormattedData) {
        chartFormattedData.datasets.push({
          label: "Upcoming bookings",
          data: dataEx,
          backgroundColor: Colors.LightGoldenYellow
        });
      } else {
        chartFormattedData = {
          labels: expectedChartData.data.label,
          datasets: [
            {
              label: "Upcoming bookings",
              data: dataEx,
              backgroundColor: Colors.LightGoldenYellow
            }
          ],
        };
      }
    }
    return chartFormattedData;
  };


  return (
    <Box mb={Spacing.S10}>
      <Box mt={Spacing.S10} mb={Spacing.S10}>
        <Box display={"flex"} flexDirection={ isMobile? "column":"row"} gridGap={24}>
          <Box flex={1}>
            <AccountBalance
              accountBalance={+balance}
              currencySymbol={currencySymbol}
              breakDown={breakDown}
            />
            <Box mt={Spacing.S6} >
              <PayoutInfoCard infoData={earningBreakdownData} />
            </Box>
          </Box>
          <Box flex={1}>
            <EarningsGraph chartSegments={chartSegments} selectedSegmentIndex= {selectedSegmentIndex} setSelectedSegmentIndex={setSelectedSegmentIndex} isLoading={isLoading} chartData={chartData} viewReportsButton={true}></EarningsGraph>

          </Box>
        </Box>

        <Box mt={Spacing.S10} mb={Spacing.S4}>
          <Box
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
            justifyContent={JustifyContent.spaceBetween}
          >
            <Typography
              style={{
                fontFamily: FontFamily.Museo,
                fontWeight: FontWeight.Bold,
                lineHeight: LineHeight.L27,
                fontSize: FontSize.F24,
                color: Colors.Dusk,
              }}
            >
              Your payouts
            </Typography>
            <Box>
              <FilterButton
                title="Filter"
                onClick={() => {
                  setOpenFilterModal(true);
                }}
                filterCount={filterCount()}
              />
            </Box>
          </Box>
        </Box>

        {/* Pre defined filter */}
        <PredefinedFilter
          filters={preDefinedFilter}
          onPredefineFilterApplied={(selectedFilter: PredefineFilterEnum) => {
            setCurrentPage(1);
            setSelectedPreDefinedFilter(selectedFilter);
          }}
        />
        <Box
          style={{
            minHeight: "300px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Jobs proPayouts={proPayouts} isPayoutsLoading={isPayoutsLoading} />
        </Box>
      </Box>
      <ProDashPastBookingFilter
        open={openFilterModal}
        onClose={() => {
          setOpenFilterModal(false);
        }}
        onFilterApplied={(selectedFilter: SearchFilter) => {
          setSelectedFilters(selectedFilter);
          setCurrentPage(1);
        }}
        paymentStatusFilter={paymentStatusFilter}
        bookingStatusFilter={bookingStatusFilter}
        appliedFilter={selectedFilters}
      />
      <Box>
        <PaginationControl
          containerStyle={{
            justifyContent: "right",
          }}
          onPrevious={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
              handlePaginate(currentPage - 1);
            }
          }}
          onNext={() => {
            if (currentPage < totalPage) {
              setCurrentPage(currentPage + 1);
              handlePaginate(currentPage + 1);
            }
          }}
          currentPage={currentPage - 1}
          pages={totalPage}
        />
      </Box>
    </Box>
  );
};
