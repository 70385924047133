import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Dialog as MUIDialog,
  DialogTitle as MUIDialogTitle,
  DialogContent as MUIDialogContent,
  DialogProps,
  IconButton,
} from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import CloseIcon from "../../images/modal-close.svg";
import { Display, FlexDirection, FontSize, FontWeight, Position, Spacing } from "./Styled/enum";

interface Props extends DialogProps {
  title?: string;
  width?: string;
  children: ReactNode;
}

const useStyles = makeStyles({
  dialogTitle: {
    top: 0,
    zIndex: 1,
    position: "sticky",
  },
  dialogTitleWrapper: {
    display: Display.Flex,
    position: Position.Relative,
    flexDirection: FlexDirection.Row,
    paddingTop: Spacing.S2,
  },
  dialogTitleIcon: {
    position: Position.Absolute,
    right: "-20px",
    top: Spacing.S0,
  },
});

const Dialog = ({
  open,
  title,
  children,
  width = "750px",
  onClose = () => {},
  ...props
}: Props) => {
  const isMobile = useMobile();
  const classes = useStyles();

  return (
    <MUIDialog open={open} onClose={onClose} {...props}>
      <MUIDialogTitle className={classes.dialogTitle}>
        <Box className={classes.dialogTitleWrapper}>
          <Box fontSize={FontSize.F20} fontWeight={FontWeight.Bold} color={Colors.NightBlue}>
            {title}
          </Box>
          <Box className={classes.dialogTitleIcon}>
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="Close" />
            </IconButton>
          </Box>
        </Box>
      </MUIDialogTitle>
      <MUIDialogContent style={{ width: isMobile ? undefined : width, marginBottom: Spacing.S6 }}>
        {children}
      </MUIDialogContent>
    </MUIDialog>
  );
};

export default Dialog;
