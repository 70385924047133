import React from "react";
import { Avatar } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { firstLetterCapitalized } from "../../helpers/string";

interface Props {
  firstName: string,
  lastName: string,
  height?: string,
  width?: string,
  fontSize?: string
  style?: React.CSSProperties,
}

const RANDOM_COLORS = [
    Colors.Champagne,
    Colors.Peppermint,
    Colors.Purple,
    Colors.Red,
    Colors.PowderBlue,
];

const stringToColor = (text: String) => {
    const index = text.charCodeAt(0) % 5;
    return index > 0 && index < 5 ? RANDOM_COLORS[index] : RANDOM_COLORS[0];
};

export default function ({ firstName="A", lastName="A", height="56px", width="56px", fontSize="16px", style={} }: Props) {
    const firstInitialFirstAndLastName = `${firstLetterCapitalized(firstName)}${firstLetterCapitalized(lastName)}`;
    const color = stringToColor(firstInitialFirstAndLastName);
    return (
        <Avatar style={{ height, width, fontSize, fontWeight: 600, fontFamily: "Open Sans", color: Colors.Dusk, backgroundColor: color, ...style }}>{firstInitialFirstAndLastName}</Avatar>
    );
}
