import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Colors } from "../../constants/colors";
import { BASE_UPLOADS_URL } from "../../constants/common";
import ProfilePicturePlaceholder from "../../images/profile-pic-placeholder.png";
import { TherapistPublicProfile } from "../../models";

import TherapistIcon from "../../images/review-therapist.svg";
import { getProfessionByServiceId } from "../../stores/booking";
import { getValue } from "../../utils/object";
import TextLink from "../TextLink";
import ProModal from "../TherapistProfileDrawer";

interface Props {
  profile?: Array<TherapistPublicProfile> | null;
  onChange?: () => unknown | null;
  serviceId?: number | null;
}

const ReviewAndBookPrefTherapist = ({ profile, onChange, serviceId }: Props): JSX.Element => {
  const [opacity, setOpacity] = useState(1);
  const [iconHover, setIconHover] = useState(false);
  const [showProInfo, setShowProInfo] = useState(false);
  const [profession, setProfession] = useState("therapist");
  const [therapistId, setTherapistId] = useState<number | null>(null);

  useEffect(() => {
    if (!serviceId) return;

    const serviceProfession = getProfessionByServiceId(serviceId);
    if (serviceProfession) {
      setProfession(serviceProfession);
    }
  }, [serviceId]);

  useEffect(() => {
    !!profile && !!iconHover ? setOpacity(0.65) : setOpacity(1);
  }, [iconHover]);

  const handleShowProInfo = (therapistId: number) => {
    if (!profile) {
      return;
    }
    setTherapistId(therapistId);
    setShowProInfo(true);
  };

  const styles = {
    proName: {
      textDecoration: "underline",
      // marginLeft: "5px",
    },
    proImageWrapper: {
      display: "flex",
      overflow: "hidden",
      justifyContent: "center",
      alighItems: "center",
      opacity: opacity,
      cursor: !!profile ? "pointer" : "",
    },
    description: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      fontFamily: "Museo",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      color: Colors.Dusk,
      cursor: !!profile ? "pointer" : "",
    },
  };

  const addSpaceBtwnImage = { marginRight: profile && profile.length > 1 ? "4px" : undefined };

  const allowChange = !!onChange;

  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      {profile ? (
        profile.map((therapist) => {
          const proImage =
            getValue(therapist, "profileImage") ||
            getValue(therapist, "therapistData.therapistprofile.profileImage");
          const therapistImage = proImage
            ? `${BASE_UPLOADS_URL}/${proImage}`
            : ProfilePicturePlaceholder;
          const therapistId =
            getValue(therapist, "userId") || getValue(therapist, "therapistData.id");

          return (
            <Box
              style={{ ...styles.proImageWrapper, ...addSpaceBtwnImage }}
              onMouseOver={() => setIconHover(true)}
              onMouseOut={() => setIconHover(false)}
              onClick={() => handleShowProInfo(therapistId)}
            >
              <img
                width="24"
                height="24"
                alt="Therapist"
                src={therapistImage}
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            </Box>
          );
        })
      ) : (
        <Box style={styles.proImageWrapper}>
          <img
            src={TherapistIcon}
            alt="Therapist"
            width="24"
            height="24"
            style={{ borderRadius: "50%", objectFit: "cover" }}
          />
        </Box>
      )}

      <Box display="flex" flex={1}>
        <Box flex={1} ml={2} mr={2} style={styles.description}>
          {!!profile ? (
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              style={{
                gap: "0px 5px",
              }}
            >
              With
              {profile.map((therapist) => {
                const proFirstName =
                  getValue(therapist, "firstName") ||
                  getValue(therapist, "therapistData.firstName", null);
                const therapistId =
                  getValue(therapist, "userId") || getValue(therapist, "therapistData.id");

                return (
                  <Box
                    key={therapistId}
                    style={styles.proName}
                    onClick={() => handleShowProInfo(therapistId)}
                  >
                    {proFirstName}
                  </Box>
                );
              })}
            </Box>
          ) : (
            `With the first available ${profession}`
          )}
        </Box>
        {allowChange && <TextLink title="Change" onClick={onChange} />}
      </Box>
      <ProModal
        open={showProInfo}
        therapistId={therapistId}
        onClose={() => setShowProInfo(false)}
        type="popup"
      />
    </Box>
  );
};

export { ReviewAndBookPrefTherapist };
