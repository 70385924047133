import React, { useState } from 'react';
import { Box } from "@material-ui/core";
import LightBox from "../LightBox/LightBox";
import { useMobile } from '../../hooks/mobile';
import "./ImageGallery.css";

interface Props {
  images: string[],
}

const ImageGallery = ({ images }: Props) => {

  const isMobile = useMobile();
  const imageSize = isMobile ? "100px" : "250px";
  
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visible, setVisible] = useState(false);

  const toggleLightBox = (value: boolean) => {
    setVisible(value);
  };

  const openLightBox = (index: number) => {
    setSelectedIndex(index);
    setVisible(true);
  };

  if (!images || images.length <= 0) return <Box> No images found.</Box>;

  return (
    <Box overflow={"hidden"} width="100%">
      <Box className="scroll-horizontal">
        {images.map((image: string, index: number) => (
          <Box style={styles.imageWrapper}>
            <img
              src={image}
              style={{...styles.image, height: imageSize}}
              className='clickable'
              alt={`portfolio ${index}`}
              onClick={() => openLightBox(index)}
            />
          </Box>
        ))}
      </Box>
      <LightBox
        images={images}
        visible={visible}
        selectedIndex={selectedIndex}
        handleClose={() => toggleLightBox(false)}
      />
    </Box>
  );
};

const styles = {
  imageWrapper: {
    margin: "0px 8px",
  },
  image: {
    borderRadius: '8px',
  }
};

export default ImageGallery;