import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import Dialog from "../Dialog";
import Dropdown from "../Dropdown";
import TextField from "../TextField";
import RadioButton from "../RadioButton";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { checkIfEmpty } from "../../utils/object";
import { useAlertStore } from "../../stores/alert";
import dangerIcon from "../../images/error_icon.png";
import Button, { ButtonSize, ButtonType } from "../Button";
import { IComplaint } from "../../interfaces/complaint.interfaces";
import { useComplaintOptions, useComplaintOptionsForPro, useCreateComplaint } from "../../hooks/complaint/complaint.hooks";

interface Props {
  open: boolean;
  complaintFor: number;
  onClose: () => unknown;
  reportPro?: boolean;
  refreshJobDetails?: any;
}

interface Option {
  title?: string;
  value?: string;
}

interface Options {
  types: Option[],
  reasons: Option[]
}

type IValidateComplaint =  Omit<IComplaint, "isInDanger" | "information">;

export default function ComplaintModal({
  open,
  onClose,
  complaintFor,
  reportPro = true,
  refreshJobDetails,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const [type, setType] = useState<Option>({});
  const [reason, setReason] = useState<Option>({});
  const [information, setInformation] = useState("");
  const [isInDanger, setIsInDanger] = useState(false);
  const [typeDropdownOpened, setTypeDropdownOpened] = useState(false);
  const { data: clientOptions = {}, isLoading: isClientOptionLoading } = useComplaintOptions() as any;
  const { data: proOptions = {}, isLoading: isProOptionLoading } = useComplaintOptionsForPro() as any;

  const [options, setOptions] = useState(clientOptions);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { mutate, isLoading: isConfirming } = useCreateComplaint();

  const reportInfo = `Your feedback is confidential and is only shared with Blys HQ, ${reportPro ? "providers" : "clients"} are not notified when they are reported.`;
  const MAX_INFORMATION_LENGTH = 400;

  const validateData = ({ type, reason, complaintFor } : IValidateComplaint) => {
    return !(!type || !reason || !complaintFor);

  };

  useEffect(() => {
    if (reportPro) {
      setOptions(clientOptions);
    } else {
      if (!checkIfEmpty(proOptions)) {
        setOptions(proOptions);
      }
    }
  }, [reportPro, clientOptions, proOptions]);


  const handleSubmit = () => {
    const data = {
      type: type.value || "",
      reason: reason.value || "",
      information,
      isInDanger,
      complaintFor,
    };

    const isValid = validateData(data);
    if(!isValid) return;

    mutate(data, {
      onSuccess: () => {
        setSuccessMessage(`${reportPro ? "Therapist" : "Client"} reported.`);
        if (refreshJobDetails) refreshJobDetails();
        onClose();
      },
      onError: () => {
        setErrorMessage("Something went wrong!");
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile}>
      {
        (isClientOptionLoading || isProOptionLoading) ?
          <></> : (
            <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
              <Box
                width={isMobile ? "100%" : "352px"}
                display="flex"
                flexDirection="column"
                alignItems="start"
              >
                <Box
                  fontFamily="Museo"
                  fontWeight={600}
                  fontSize="24px"
                  color={Colors.NightBlue}
                  width="100%"
                >
                  What went wrong?
                </Box>
                <Box
                  mt="16px"
                  width="100%"
                >
                  <Box fontFamily="Museo"
                    fontSize="16px"
                    color={Colors.Dusk}
                  >
                    {reportInfo}
                  </Box>
                  <Box fontFamily="Museo" mt="0px">
                    <Dropdown
                      paddingTop={"32px"}
                      paddingBottom={"0px"}
                      title="I am reporting because"
                      options={options.types as any}
                      onSelectedOption={(option: any) => setType(option)}
                      selectedOption={options.types?.find(
                        (data: any) => data.value === type.value
                      )}
                      onOpen={() => {
                        setTypeDropdownOpened(true);
                      }}
                      onClose={() => {
                        setTypeDropdownOpened(false);
                      }}
                    />
                    <Box>
                      {!reason.title && typeDropdownOpened ? (
                      // invisible box
                        <Box style={{ height: 200 }} />
                      ) : (
                        <Box />
                      )
                      }
                    </Box>
                    {
                      type.title && (
                        <Box>
                          <Dropdown
                            paddingTop={"32px"}
                            paddingBottom={"0px"}
                            title="Reason"
                            options={options.reasons as any}
                            onSelectedOption={(option: any) => setReason(option)}
                            selectedOption={options.reasons?.find(
                              (data: any) => data.value === reason.value
                            )}
                          />

                          {
                            reason.title && (
                              <Box>
                                <Box>
                                  <TextField
                                    style={{
                                      paddingBottom: "0px",
                                    }}
                                    paddingTop={"32px"}
                                    title="Additional information"
                                    placeholder=""
                                    value={information}
                                    onChange={setInformation}
                                    multiline
                                  />
                                  {
                                    !!information?.length && (
                                      <Box display={"flex"} flexDirection={"row"} style={styles.totalCharactersContainer}>
                                        <Box fontFamily="Open Sans"
                                          fontWeight={400}
                                          fontSize="14px"
                                          lineHeight="21px"
                                          color={Colors.NightBlue}>
                                          {information.length}/{MAX_INFORMATION_LENGTH}
                                        </Box>
                                      </Box>
                                    )
                                  }
                                </Box>

                                <Box>
                                  <Box marginTop={"32px"} fontFamily="Museo" fontSize="14px" color={Colors.Dusk} fontWeight={700}>
                                    Are you in immediate danger or distress?
                                  </Box>
                                  <Box mt={"10px"} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} mr={"120px"}>
                                      <RadioButton selected={isInDanger} onSelected={() => setIsInDanger(true)} />
                                      <Box fontFamily="Museo" fontSize="14px" color={Colors.Dusk} fontWeight={600}>Yes</Box>
                                    </Box>
                                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                      <RadioButton selected={!isInDanger} onSelected={() => setIsInDanger(false)} />
                                      <Box fontFamily="Museo" fontSize="14px" color={Colors.Dusk} fontWeight={600}>No</Box>
                                    </Box>
                                  </Box>
                                </Box>

                                {
                                  isInDanger && (
                                    <Box marginTop={"24px"} display={"flex"} flexDirection={"row"} alignItems={"center"} gridColumnGap={"8px"}>
                                      <img height={"16px"} src={dangerIcon} alt={"Alert icon"} />
                                      <Box fontFamily="Open Sans" fontSize="14px" color={Colors.Dusk} fontWeight={400}>
                                        Please contact local police immediately.
                                      </Box>
                                    </Box>
                                  )
                                }
                              </Box>
                            )
                          }
                        </Box>
                      )
                    }
                  </Box>
                </Box>

                <Box style={styles.actionWrapper} flexDirection="row" gridColumnGap={"16px"}>
                  <Button
                    height={48}
                    type={
                      ButtonType.outlined
                    }
                    size={ButtonSize.small}
                    title="Cancel"
                    onClick={onClose}
                  />
                  <Button
                    height={48}
                    type={
                      ButtonType.secondary
                    }
                    size={ButtonSize.small}
                    title="Submit"
                    onClick={handleSubmit}
                    disabled={checkIfEmpty(reason) || checkIfEmpty(type)}
                    loading={isConfirming}
                  />
                </Box>
              </Box>
            </Box>
          )
      }
    </Dialog>
  );
}

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "32px",
  },
  totalCharactersContainer: {
    justifyContent: "flex-end",
    marginTop: 12,
  },
};
