import { Box, Typography } from "@material-ui/core";
import Button, { ButtonType } from "../../../../components/Button";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { getValue } from "../../../../utils/object";
import { Colors } from "../../../../constants/colors";

interface Props {
  info: any;
  visible: boolean;
  renderHTML?: boolean;
  onClose: () => unknown;
}

const PayoutInfoModal = ({ visible, onClose, info, renderHTML = false }: Props) => {
  const title = getValue(info, "title");
  const body = getValue(info, "description");
  const items = getValue(info, "items");

  return (
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      actions={[
        <Button
          title="Close"
          type={ButtonType.outlined}
          style={{ width: "100%" }}
          onClick={onClose}
        />,
      ]}
    >
      <Box padding="8px 24px">
        <Box
          fontFamily="Museo"
          fontWeight={700}
          fontSize="22px"
          color={Colors.NightBlue}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {title}
        </Box>

        <Box
          fontFamily="Open sans"
          fontSize="16px"
          color={Colors.BlueyGrey}
          mt="16px"
          mb="5px"
          lineHeight={"150%"}
          textAlign={"center"}
          width={"328px"}
        >
          {renderHTML ? <div dangerouslySetInnerHTML={{ __html: body as string }} /> : <Typography>{body}</Typography>} 
        </Box>
        {items && (
          <Box mt="16px" mb="5px" textAlign="center" lineHeight="150%">
            {items.map((item: any) => (
              <Box mt="16px">
                <Box fontFamily="Museo" color={Colors.NightBlue}>
                  {item.name}
                </Box>
                <Box color={Colors.BlueyGrey}>
                  {item.data &&
                    item.data.map((data: any) => (
                      <div style={{ paddingTop: 6, paddingBottom: 6 }}>{data}</div>
                    ))}
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </ContentModal>
  );
};
export default PayoutInfoModal;
