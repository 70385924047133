import { Box, ButtonBase } from "@material-ui/core";
import { isNil } from "lodash";
import moment from "moment";
import platform from "platform";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import SideMenuButton, { SideMenuButtonType } from "../components/SideMenuButton";
import TextLink from "../components/TextLink";
import { Colors } from "../constants/colors";
import { BASE_UPLOADS_URL } from "../constants/common";
import { Links } from "../constants/links";
import { Paths } from "../constants/paths";
import { ProDashboardHeaderSavedScrollPositionKey } from "../constants/prodashboard";
import { TrackingEvents } from "../constants/tracking";
import { withVersion } from "../helpers/tracking";
import { isLoggedIn } from "../helpers/user";
import ProfilePicturePlaceholder from "../images/profile-pic-placeholder.png";
import ProfilePictureEdit from "../images/profile-picture-edit.svg";
import { resetUserData } from "../services/auth/auth.service";
import { trackEvent } from "../services/segment/track.service";
import { useUserStore } from "../stores/user";
import { getValue } from "../utils/object";
import BookMassageButton, { BookMassageButtonSource } from "./BookMassageButton";
import LogoLink from "./LogoLink";
import SideMenuLink from "./SideMenuLink";

interface Props {
  onClose?: () => unknown;
  onLogin?: () => unknown;
  withFixedSidebar?: boolean;
}

export default function SideMenu({ onClose, onLogin, withFixedSidebar }: Props): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const { user, fetchMe, credit, currency, fetchCreditBalance } = useUserStore();

  React.useEffect(() => {
    fetchMe();
    fetchCreditBalance();
  }, []);

  const navigateTo = (path: string) => {
    if (location.pathname === path) {
      onClose && onClose();
      return;
    }

    history.push(path);
  };

  const userName =
    getValue(user, "legalFirstName") || getValue(user, "firstName") || getValue(user, "email");

  const getTotalBalance = (totalCredit: number, balance: number, currency = "A$") => {
    const total = totalCredit + balance;
    if (total < 0) {
      return `-${currency}${Math.abs(total).toFixed(2)}`;
    }
    return `${currency}${total.toFixed(2)}`;
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={withFixedSidebar ? { height: "100dvh", overflow: "scroll", flexShrink: 0 } : {}}
    >
      <Box
        maxWidth="240px"
        paddingLeft={2.5}
        paddingRight={2.5}
        paddingTop={5.25}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <LogoLink />

          {user && (
            <Box display="flex" flexDirection="row" mt={4.5} mb={4.25} paddingLeft="12px">
              <ButtonBase
                onClick={() => {
                  navigateTo("/profile");

                  trackEvent(TrackingEvents.ProfileClicked, withVersion());
                }}
              >
                {user.profileImage && (
                  <img
                    src={`${BASE_UPLOADS_URL}/${user.profileImage}`}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "20px",
                      objectFit: "cover",
                    }}
                  />
                )}
                {!user.profileImage && (
                  <img src={ProfilePicturePlaceholder} style={{ width: "40px", height: "40px" }} />
                )}

                <img
                  src={ProfilePictureEdit}
                  style={{
                    position: "absolute",
                    top: -12,
                    left: -12,
                    width: "33px",
                    height: "33px",
                  }}
                />

                <Box ml={2} display="flex" flexDirection="column" alignItems="flex-start">
                  <Box
                    fontFamily="Museo"
                    fontWeight={700}
                    fontSize="18px"
                    color={Colors.NightBlue}
                    textAlign="left"
                    letterSpacing="0.22px"
                    lineHeight={"27px"}
                  >
                    {userName}
                  </Box>
                  <Box
                    fontFamily="SF UI Text"
                    fontWeight={400}
                    color={Colors.Dusk}
                    fontSize="13px"
                    textAlign={"left"}
                    lineHeight={"21px"}
                  >
                    {getTotalBalance(credit, user.accountBalance, currency)} Account balance
                  </Box>
                </Box>
              </ButtonBase>
            </Box>
          )}

          {isNil(user) && <Box height="128px" />}

          <Box mb={1}>
            <BookMassageButton
              source={BookMassageButtonSource.Navigation}
              width="100%"
              onNavigationToBookingFlow={() => onClose && onClose()}
            />
          </Box>

          <SideMenuButton
            type={SideMenuButtonType.bookings}
            selected={location.pathname === Paths.Bookings}
            onClick={() => navigateTo(Paths.Bookings)}
          />

          <SideMenuButton
            type={SideMenuButtonType.purchases}
            selected={location.pathname === Paths.Purchases}
            onClick={() => navigateTo(Paths.Purchases)}
          />

          <SideMenuButton
            type={SideMenuButtonType.providers}
            selected={
              location.pathname === Paths.Therapists ||
              location.pathname === Paths.BlockedTherapists ||
              location.pathname === Paths.BrowseTherapists ||
              location.pathname === Paths.RecentTherapists
            }
            onClick={() => navigateTo(Paths.Therapists)}
          />

          {/* <SideMenuButton
            type={SideMenuButtonType.membership}
            selected={location.pathname === "/membership"}
            onClick={() => navigateTo("/membership")}
          /> */}

          <SideMenuButton
            type={SideMenuButtonType.addresses}
            selected={location.pathname === Paths.Addresses}
            onClick={() => navigateTo(Paths.Addresses)}
          />

          <SideMenuButton
            type={SideMenuButtonType.recipients}
            selected={location.pathname.startsWith("/recipients")}
            onClick={() => navigateTo(Paths.Recipients)}
          />

          <SideMenuButton
            type={SideMenuButtonType.payment}
            selected={location.pathname === "/payment"}
            onClick={() => navigateTo("/payment")}
          />

          <SideMenuButton
            type={SideMenuButtonType.inviteFriends}
            selected={location.pathname === "/invite-friends"}
            onClick={() => navigateTo("/invite-friends")}
          />

          <SideMenuButton
            type={SideMenuButtonType.prodashboard}
            selected={location.pathname.startsWith("/pro/")}
            onClick={() => {
              localStorage.removeItem(ProDashboardHeaderSavedScrollPositionKey);
              const next = user?.therapistprofile ? Paths.ProDashboardProfile : Paths.ProDashboard;

              navigateTo(next);
            }}
          />

          <SideMenuButton
            type={SideMenuButtonType.settings}
            selected={location.pathname === Paths.Settings}
            onClick={() => navigateTo(Paths.Settings)}
          />

          <Box width="100%" height="1px" bgcolor={Colors.PaleLilac} mt={2.5} mb={2.5} />
          <SideMenuLink
            selected={false}
            title="Gift vouchers"
            onClick={() => window.open(Links.GiftVouchers, "_blank")}
          />

          <SideMenuLink
            selected={false}
            title="Corporate wellness"
            onClick={() => window.open(Links.CorporateWellness, "_blank")}
          />

          <SideMenuLink
            selected={false}
            title="Help & support"
            onClick={() => window.open(Links.HelpAndSupport, "_blank")}
          />

          <SideMenuLink
            selected={false}
            title="Leave feedback"
            onClick={() => {
              // const url = "mailto:hello@getblys.com.au";
              let url =
                "mailto:hello@getblys.com?subject=My%20feedback%20about%20Blys%20website&body=%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A-----%0D%0A%0D%0APlease%20do%20not%20remove%20the%20information%20below%2C%20we%20need%20them%20for%20troubleshooting%20purposes.%0D%0A%0D%0AApp%20Name%3A%20Blys%20web%20app%0D%0AOS%20Version%3A%20PLACEHOLDEROS%0D%0ABrowser%20Version%3A%20PLACEHOLDERBROWSER%0D%0ADate%2FTime%3A%20PLACEHOLDERDATE%0AUser%3A%20PLACEHOLDERUSERNAME%0AUser%20ID%3A%20PLACEHOLDERUSERID%0AUser%20email%3A%20PLACEHOLDEREMAIL%0AUser%20country%3A%20PLACEHOLDERCOUNTRY";
              url = url.replace("PLACEHOLDEROS", platform.os?.toString() || "");
              url = url.replace("PLACEHOLDERBROWSER", `${platform.name} ${platform.version}`);
              url = url.replace("PLACEHOLDERDATE", moment().format("MMMM D, YYYY h:mm A"));
              url = url.replace(
                "PLACEHOLDERUSERNAME",
                `${user?.firstName || ""} ${user?.lastName || ""}`
              );
              url = url.replace("PLACEHOLDERUSERID", `${user?.id}`);
              url = url.replace("PLACEHOLDEREMAIL", `${user?.email}`);
              url = url.replace("PLACEHOLDERCOUNTRY", `${user?.countryFullName}`);
              window.open(url, "_blank");
            }}
          />
        </Box>

        <Box>
          <Box width="100%" height="1px" bgcolor={Colors.PaleLilac} mt={2.5} mb={2.5} />
          <Box display="flex" flexDirection="row" alignItems="center" mb={5}>
            <Box fontFamily="Open Sans" fontSize="12px" color={Colors.BlueyGrey} flex={1}>
              © {new Date().getFullYear()} Blys Pty Ltd
            </Box>

            {isLoggedIn() && (
              <TextLink
                title="Log out"
                onClick={() => {
                  trackEvent("Logged Out", {
                    version: 2,
                  });
                  resetUserData();

                  history.push(Paths.Bookings);
                }}
              />
            )}

            {!isLoggedIn() && (
              <TextLink
                title="Log in"
                onClick={() => {
                  if (onLogin) {
                    onLogin();
                  } else {
                    history.push(Paths.Login);
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box width="1px" bgcolor={Colors.PaleLilac} height="100%" minHeight="100vh" />
    </Box>
  );
}
