import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FontFamily, FontSize, FontWeight, Spacing } from "../../../../components/v2/Styled/enum";
import { Typography } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import Divider from "../../../../components/Divider";
import { DropdownSection } from "../../../../components/DropdownSection";
import { getValue } from "../../../../utils/object";
import { useUserStore } from "../../../../stores/user";
import { useUpdateClientGenderPreference } from "../../../../hooks/settings/proSettings.hooks";
import { useAlertStore } from "../../../../stores/alert";
import { parseApiError } from "../../../../helpers/error";
import { User } from "../../../../models";
import { useGetClientGenderPreferenceOptions } from "../../../../hooks/genderPreference.hook";

export const BookingManagement = ()=>{
  const {user, setUser} = useUserStore();

  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const preferredClientGender = getValue(user, "therapistprofile.preferredClientGender");

  const {isGenderPrefLoading, data:clientGenderPreferenceOption} =  useGetClientGenderPreferenceOptions();
 
  const {mutate: updateClientGenderPreference} = useUpdateClientGenderPreference({
    onSuccess:(data: any)=>{
      const updatedClientGender = getValue(data, "preferredClientGender");
      setSuccessMessage("Preferred client gender updated.");

      setUser({...user, therapistprofile:{
        ...getValue(user, "therapistprofile",{}),
        preferredClientGender: updatedClientGender
      }} as User);
    },
    
    onError:(error: any)=>{
      setErrorMessage(parseApiError(error));
    }
  });

  const handleClientGenderPrefUpdate = (selectedValue: string)=>[
    updateClientGenderPreference({
      preferredClientGender: selectedValue
    })
  ];
  
  if(isGenderPrefLoading) return <></>;
  
  return (
    <Box marginY={Spacing.S10}>

      <Typography style = {
        {
          fontWeight: FontWeight.Bold,
          fontFamily: FontFamily.Museo,
          fontSize: FontSize.F24,
          color: Colors.NightBlue
        }
      }> Booking management</Typography>

      <Typography style = {
        {
          fontWeight: FontWeight.Medium,
          fontFamily: FontFamily.Museo,
          fontSize: FontSize.F16,
          color: Colors.Dusk,
          marginTop: Spacing.S6
        }
      }> Decide how you'd like to manage your bookings.</Typography>

      <Divider mt={Spacing.S10} mb={Spacing.S10}/>

      <Box>
        <DropdownSection
          title={"Preferred client gender"}
          description={"Select the gender of clients you'd like to receive bookings from. Please note that this doesn't apply to direct booking requests. Clients can still book you directly."}
          options ={clientGenderPreferenceOption}
          onDropDownValueChange={(selectedValue: any)=>{
            handleClientGenderPrefUpdate(selectedValue);
          }}
          value ={preferredClientGender}
        />
      </Box>
    </Box>
  );
};