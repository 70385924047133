export function formatToPrice(
  number: Number = 0,
  currency: String = "A$",
  fractionDigits: Number = 2
) {
  return `${currency}${number
    .toFixed(fractionDigits as number)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export const defaultFloatingValue: string = "0.00";

declare global {
  interface Number {
    toPriceString: (currency?: String, number?: number) => string;
  }
}

Number.prototype.toPriceString = function (currency?: String, fractionDigits: number = 2): string {
  return formatToPrice(this, currency, fractionDigits);
};
