import React from "react";
import {
  Box,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import "./treatmentDescription.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  btnText?: string;
}

function TreatmentDescription(props: Props) {
  return (
    <MUIDialog open={props.isOpen} maxWidth="xs" onClose={props.onClose}>
      <Box className="modalWrapper">
        <DialogTitle className="modalTitle">
          <Box className="titleText">
            {props.title}
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: "0px 48px" }}>
          <div
            className="treatmentDescriptionV2"
            dangerouslySetInnerHTML={{ __html: props.description as string }}
            style={{ padding: 0 }}
          />
        </DialogContent>
        <DialogActions className="modalFooter">
          <Button
            variant="outlined"
            onClick={props.onClose}
            className="fotterBtn"
          >
            {props.btnText || "Ok"}
          </Button>
        </DialogActions>
      </Box>
    </MUIDialog>
  );
}

export default React.memo(TreatmentDescription);
