import React, { useState } from "react";
import TextField from "../../../../../components/TextField";
import { Box } from "@material-ui/core";

interface Props {
  accountDetails: any;
  onSave: (f: string, v: any) => unknown;
}

const BankAccountForm = ({ accountDetails, onSave }: Props): JSX.Element => {
  const [details, setDetails] = useState(accountDetails);

  const handleChange = (field: string, value: any) => {
    setDetails({
      ...details,
      [field]: value,
    });
  };

  const handleSave = (field: string) => {
    onSave(field, details[field]);
  };

  return (
    <Box>
      <TextField
        title={"Account number"}
        placeholder={"000000000"}
        value={details.accountNumber}
        onChange={(val) => handleChange("accountNumber", val)}
        onBlur={() => handleSave("accountNumber")}
      />
      <TextField
        title={"Confirm account number"}
        placeholder={"000000000"}
        value={details.confirmAccountNumber}
        onChange={(val) => handleChange("confirmAccountNumber", val)}
        onBlur={() => handleSave("confirmAccountNumber")}
      />
      <TextField
        title={"Routing number"}
        placeholder={"000000"}
        value={details.routingNumber}
        onChange={(val) => handleChange("routingNumber", val)}
        onBlur={() => handleSave("routingNumber")}
      />
    </Box>
  );
};

export default BankAccountForm;
