import { Box } from "@material-ui/core";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { DayPickerNavbar } from "../../pages/NewBooking/DateAndTime";
import { Spacing } from "../v2/Styled/enum";

interface DatePickerProps {
  showDatePicker: boolean;
  setShowDatePicker?: (val: boolean) => unknown;
  handleRecurringEndDateSelect: (val: Date) => unknown;
  isMobile?: boolean;
  selectedDate: Date | string;
  getMinValidDate: () => Date;
  oneYearFromNow: Date;
}

const RecurringDatePicker = (props: DatePickerProps) => {
  const {
    showDatePicker,
    handleRecurringEndDateSelect,
    selectedDate,
    getMinValidDate,
    oneYearFromNow,
  } = props;

  if (!showDatePicker) {
    return null;
  }

  return (
    <Box
      bgcolor="#ffffff"
      borderRadius="8px"
      boxShadow="0px 2px 8px 0px #00000040"
      marginTop={Spacing.S3}
      paddingTop={Spacing.S4}
      alignSelf="flex-start"
      position="absolute"
      top={"30px"}
      zIndex={10}
      right={0}
    >
      <DayPicker
        firstDayOfWeek={1}
        containerProps={{
          style: { display: "block" },
        }}
        onDayClick={(day, modifiers) => {
          if (modifiers.disabled) return;
          handleRecurringEndDateSelect(day);
        }}
        selectedDays={selectedDate ? [new Date(selectedDate)] : []}
        month={new Date(selectedDate)}
        disabledDays={{
          before: getMinValidDate(),
          after: oneYearFromNow,
        }}
        captionElement={() => {
          return <Box />;
        }}
        navbarElement={(props) => {
          return <DayPickerNavbar {...props} />;
        }}
        modifiers={{ from: new Date(selectedDate) }}
      />
    </Box>
  );
};

export default RecurringDatePicker;
