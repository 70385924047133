import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";

type UploadStatus = "pending"|"signed"

interface Props {
  status: UploadStatus;
  label?:string
}

export default function DocumentUploadStatusPill({
  status,
  label
}: Props): JSX.Element {
  const backgroundColor = (status: UploadStatus) => {
    switch (status) {
    case "signed":
      return Colors.VeryLightGreen;
    default:
      return Colors.Pale;
    }
  };
  const getLabel = ()=>{
    if(status==="signed"){
      return "Signed";
    }

    return "Pending your signature";
  }; 
  return (
    <Box display={"inline-block"} width={"fit-content"}>
      <Box
        paddingY={"4px"}
        paddingX="12px"
        borderRadius="12.5px"
        bgcolor={backgroundColor(status)}
      >
        <Box
          fontFamily="Open Sans"
          fontSize="12px"
          fontWeight={700}
          color={Colors.NightBlue}
          letterSpacing="0.5px"
        >
          {label || getLabel() }
        </Box>
      </Box>
    </Box>
  );
}
