import React from "react";
import { useMediaQuery } from "react-responsive";

export function useMobile() {
  return useMediaQuery({ query: "(max-width: 640px)" });
}

export function useTab() {
  return useMediaQuery({ minWidth: 641, maxWidth: 820 });
}

export function useDesktop() {
  return useMediaQuery({ query: "(min-width: 821px)" });
}