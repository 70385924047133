import React from "react";
import { Box } from "@material-ui/core";

import JobAgreements from "../../pages/Bookings/Components/BookingDetailCard/JobAgreements";
import ProvidersDocument, {
  ProDocs,
} from "../../pages/Bookings/Components/BookingDetailCard/ProvidersDocument";
import { JobAgreement } from "../../models";
import TermsAndPoliciesAddbutton from "../Users/TermsAndPolicies/TermsAndPoliciesAddbutton";
import { useUserRole } from "../../hooks/user.hooks";

type Props = {
  showAddAdditionalDocuments: boolean;
  showAgreements: boolean;
  hasProsDocuments: boolean;
  agreements: JobAgreement[];
  proDocuments: ProDocs[];
  bookingId?: number | null;
  therapistId?: number | null;
  alignItems?: string;
  allowUpload?: boolean;
  onSuccess?: () => unknown;
};

const TermsAndPoliciesDocuments = ({
  showAgreements,
  hasProsDocuments,
  agreements,
  proDocuments,
  showAddAdditionalDocuments,
  bookingId,
  therapistId,
  alignItems = "center",
  allowUpload = true,
  onSuccess = () => {},
}: Props) => {
  const { isHomeCareClient } = useUserRole();
  if (!showAddAdditionalDocuments || !isHomeCareClient) return <></>;

  return (
    <Box marginTop={"16px"} display={"flex"} flexDirection={"column"} alignItems={alignItems}>
      {showAgreements ? <JobAgreements agreements={agreements} /> : <React.Fragment />}
      {hasProsDocuments ? <ProvidersDocument documents={proDocuments} /> : <React.Fragment />}
      {allowUpload && (
        <Box marginTop={"5px"}>
          <TermsAndPoliciesAddbutton
            bookingId={bookingId}
            therapistId={therapistId}
            isAdditionalDocument
            onSuccess={onSuccess}
          />
        </Box>
      )}
    </Box>
  );
};

export default TermsAndPoliciesDocuments;
