import React, { useState } from "react";

import { Box } from "@material-ui/core";

import Dialog from "../Dialog";
import TextField from "../TextField";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { useAlertStore } from "../../stores/alert";
import Button, { ButtonSize, ButtonType } from "../Button";
import { useBlockClient } from "../../hooks/client/blockClient.hooks";


interface Props {
  open: boolean;
  jobId: number;
  onClose: () => unknown;
  onBlockSuccess?: any;
}

export default function BlockClientModal({
  jobId,
  open,
  onClose,
  onBlockSuccess,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const [reason, setReason] = useState("");

  const { setErrorMessage } = useAlertStore();

  const { mutate, isLoading } = useBlockClient();


  const blockInfo = "Please provide reason for blocking this client. (Your note will remain confidential with Blys)";

  const handleSubmit = () => {
    const isValid = !!reason.trim();

    if (!isValid) return;

    mutate({
      jobId,
      blockReason: reason.trim()
    }, {
      onSuccess: () => {
        if (onBlockSuccess) onBlockSuccess();
        onClose();
      },
      onError: () => {
        setErrorMessage("Something went wrong!");
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile}>
      <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="start"
        >
          <Box
            fontFamily="Museo"
            fontWeight={600}
            fontSize="24px"
            color={Colors.NightBlue}
            width="100%"
          >
            What went wrong?
          </Box>
          <Box
            mt="16px"
            width="100%"
          >
            <Box fontFamily="Museo"
              fontSize="16px"
              color={Colors.Dusk}
            >
              {blockInfo}
            </Box>
            <Box>
              <Box>
                <TextField
                  style={{
                    paddingBottom: "0px",
                  }}
                  paddingTop={"32px"}
                  title="Reason"
                  value={reason}
                  onChange={setReason}
                  multiline
                />
                {
                  !!reason?.length && (
                    <Box fontFamily="Open Sans"
                      fontWeight={400}
                      fontSize="14px"
                      lineHeight="21px"
                      color={Colors.NightBlue}>
                    </Box>
                  )
                }
              </Box>
            </Box>
          </Box>
        </Box>

        <Box style={styles.actionWrapper} flexDirection="row" gridColumnGap={"16px"}>
          <Button
            height={48}
            type={
              ButtonType.outlined
            }
            size={ButtonSize.small}
            title="Cancel"
            onClick={onClose}
          />
          <Button
            height={48}
            type={
              ButtonType.secondary
            }
            size={ButtonSize.small}
            title="Submit"
            onClick={handleSubmit}
            disabled={!reason.trim()}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Dialog >
  );
}

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "32px",
  },
  totalCharactersContainer: {
    justifyContent: "flex-end",
    marginTop: 12,
  },
};
