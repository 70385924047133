import { Box, Checkbox, IconButton } from "@material-ui/core";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { getFullUploadsUrl } from "../../../../utils/url";
import { SIGNATURE_TYPE } from "./addSignatureModal";
import { useState } from "react";
import { useAgreementsByJob } from "../../../../hooks/userTerms.hook";
import { getValue } from "../../../../utils/object";
import { USER_UPLOADED_CONTENT_TYPE } from "../../../../data/enums";
import { BASE_UPLOADS_URL } from "../../../../constants/common";
import TextButton from "../../../../components/TextButton/TextButton";
import { useMobile } from "../../../../hooks/mobile";

interface Props {
  onClose: () => unknown;
  openAddSignatureModal: () => unknown;
  signature?: { id: number; filePath: string; text: string; type: string };
  visible: boolean;
  onConfirm: (agreed: boolean) => unknown;
  isLoading: boolean;
  job: { id: number };
}

const InitialModalContent = ({
  onClose,
  openAddSignatureModal,
  onConfirm,
  visible,
  signature,
  isLoading,
  job,
}: Props) => {
  const signatureImageUrl = signature?.filePath ? getFullUploadsUrl(signature?.filePath) : null;
  const [agreed, setAgreed] = useState(false);
  const isMobile = useMobile();
  const handleConfirm = () => {
    onConfirm(agreed);
  };

  const getAgreementLink = (path: string, contentType: string) => {
    if (contentType === USER_UPLOADED_CONTENT_TYPE.LINK) return path;
    return `${BASE_UPLOADS_URL}/${path}`;
  };

  const { data: jobAgreementResponse } = useAgreementsByJob({ jobId: job.id });
  const jobAgreements = getValue(jobAgreementResponse, "agreements") || [];
  const onDocumentTapped = (url: string) => window.open(url, "_blank");

  return (
    <ContentModal
      visible={visible}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      PaperProps={{ style: { maxWidth: "549px" } }}
      actions={[
        <Box
          style={{
            flex: 1,
            gap: "8px",
            display: "flex",
            flexDirection: "row",
            padding: "0px 16px 16px",
            justifyContent: "flex-end",
          }}
        >
          <Box minWidth={"170px"} />{" "}
          <Button title="Cancel" type={ButtonType.outlined} onClick={onClose} />
          <Button
            loading={isLoading}
            title="Add"
            type={ButtonType.primary}
            onClick={handleConfirm}
          />
        </Box>,
      ]}
    >
      <Box
        display={"flex"}
        padding={isMobile ? "" : "0px 10px"}
        flexDirection={"column"}
        style={{ gap: "16px" }}
      >
        <Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton style={{ height: "12px", width: "12px" }} onClick={onClose}>
              {" "}
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </Box>
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize={isMobile ? "24px" : "30px"}
            color={Colors.NightBlue}
          >
            Client terms and policies
          </Box>
        </Box>

        <Box fontFamily="Open Sans" fontSize="16px" color={Colors.BlueyGrey}>
          This client requires all providers to accept the client's specific terms and policies.
        </Box>

        <Box display={"flex"} flexDirection={"column"} style={{ gap: "32px" }}>
          <Box>
            {jobAgreements.map((item: any) => {
              const url = getAgreementLink(item.filePath, item.contentType);
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "2px",
                  }}
                >
                  <TextButton
                    type="primary"
                    onClick={() => onDocumentTapped(url)}
                    text={item.name}
                    textStyle={{
                      fontWeight: 700,
                      padding: 0,
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontFamily: "Museo",
                    }}
                  />
                </div>
              );
            })}
          </Box>

          <Box
            fontFamily="Museo"
            fontSize="16px"
            color={"#41506F"}
            fontWeight={500}
            display={"flex"}
            alignItems={"start"}
            maxWidth={"466px"}
          >
            <Checkbox
              checked={agreed}
              disableRipple
              onChange={(e) => setAgreed(e.target.checked)}
              style={{
                background: "white",
                marginTop: 0,
                paddingTop: 0,
                color: agreed ? Colors.TurquoiseBlue : "",
              }}
            />
            I agree with the client terms and conditions for all bookings from this client
          </Box>
        </Box>
        <Box>
          <Box
            style={{
              height: isMobile ? "200px" : "265px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "1px solid #EFEFEF",
            }}
          >
            {signatureImageUrl && (
              <img
                style={{ width: "100%", height: "100%" }}
                alt="signature"
                src={signatureImageUrl}
              />
            )}
            {signature?.type === SIGNATURE_TYPE.TEXT && (
              <Box
                style={{
                  fontSize: 25,
                  lineHeight: 27.5,
                  fontWeight: "700",
                  fontFamily: "open-sans",
                }}
              >
                {signature.text}
              </Box>
            )}
          </Box>
          <Button
            onClick={openAddSignatureModal}
            type={ButtonType.secondary}
            title={signature ? "Edit" : "Add signature"}
          />
        </Box>
      </Box>
    </ContentModal>
  );
};

export default InitialModalContent;
