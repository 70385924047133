import { Box, ButtonBase, Checkbox, Popover } from "@material-ui/core";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../../../components/v2/Styled/enum";
import { Colors } from "../../../../constants/colors";
import Ratings from "../../../../components/Ratings";
import TextField from "../../../../components/TextField";
import TreatmentNoteForm from "../../../../components/UserTreatmentNote/TreatmentNoteForm/TreatmentNoteForm";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Button, { ButtonType } from "../../../../components/Button";
import { useCreateClientReview } from "../../../../hooks/review/review.hooks";
import { checkIfEmpty, getValue } from "../../../../utils/object";
import { useAlertStore } from "../../../../stores/alert";
import RoundedImage from "../../../../components/Image/RoundedImage";
import { getFullUploadsUrl } from "../../../../utils/url";
import PopoverItem, { PopoverItemType } from "../../../../components/PopoverItem";
import MoreIcon from "../../../../images/therapists-more.svg";
import { useJobDetail } from "../../../../hooks/job/job.hooks";
import { useBlockClient, useUnblockClient } from "../../../../hooks/client/blockClient.hooks";

interface Props {
  review?: any;
  visible: boolean;
  jobId: string | number;
  includeNote?: boolean;
  allowActions?: boolean;
  onClose: () => unknown;
  onSuccess: () => unknown;
}

const UserRatingModal = ({
  review,
  visible,
  jobId,
  includeNote = false,
  allowActions = true,
  onClose,
  onSuccess,
}: Props) => {
  const { job, refetch: refreshJobDetails } = useJobDetail({ jobId });

  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [reviewFiles, setReviewFiles] = useState<any>([]);
  const [imagesToRemove, setImagesToRemove] = useState<number[]>([]);
  const [uploadToPortfolio, setUploadToPortfolio] = useState(false);

  const [noteText, setNoteText] = useState("");
  const [noteFiles, setNoteFiles] = useState<any>([]);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState<HTMLElement | null>(null);

  const reviewImageRef = useRef<HTMLInputElement>(null);

  const { mutate: unblockClient } = useUnblockClient();
  const { mutate: blockClient } = useBlockClient();

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  useEffect(() => {
    if (!checkIfEmpty(review) && visible) {
      const { rating, review: reviewText, reviewFiles: ratingFiles } = review;
      setRating(rating);
      setReviewText(reviewText);
      setReviewFiles(ratingFiles);
    }
  }, [review, visible]);

  const createReviewCB = {
    onSuccess: (response: any) => {
      if (!!onSuccess) onSuccess();
    },
    onError: (error: any) => {
      setErrorMessage("Unable to update client rating. Please try again.");
    },
  };

  const { mutate: addClientRating, isLoading: isAddingReview } =
    useCreateClientReview(createReviewCB);

  const onNoteFileUploaded = (uploadedFiles = []) => {
    const files = [...noteFiles, ...uploadedFiles];
    setNoteFiles(files);
  };

  const onRemoveNote = (index: number) => {
    const uploadedFiles = [...noteFiles];
    uploadedFiles.splice(index, 1);
    setNoteFiles(uploadedFiles);
  };

  const onRatingUser = (index: number) => {
    setRating(index);
  };

  const resetData = () => {
    setRating(0);
    setReviewText("");
    setNoteText("");
    setNoteFiles([]);
  };

  const onModalClose = () => {
    resetData();
    onClose();
  };

  const addClientReview = () => {
    const data: any = new FormData();
    data.append("rating", rating.toString());
    data.append("review", reviewText);
    data.append("isUploadToPortfolio", JSON.stringify(uploadToPortfolio));

    if (reviewFiles && reviewFiles.length) {
      reviewFiles.forEach((image: any) => {
        if (typeof image !== "string") data.append("images", image.file);
      });
    }

    if (!checkIfEmpty(imagesToRemove)) {
      data.append("imagesToRemove", JSON.stringify(imagesToRemove));
    }

    if (includeNote) {
      const treatmentNote = { files: noteFiles, text: noteText };
      data.append("treatmentNote", JSON.stringify(treatmentNote));
    }

    addClientRating({ jobId, data });
  };

  const onUploadFileClick = () => {
    if (reviewImageRef.current) {
      reviewImageRef.current.click();
    }
  };

  const onReviewImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    const uploadedFile = {
      file,
      tempURL: URL.createObjectURL(file),
    };

    setReviewFiles([...reviewFiles, uploadedFile]);
  };

  const onRemoveReviewFile = (index: number) => {
    if (reviewFiles[index]) {
      const fileId = reviewFiles[index].id;
      if (fileId) setImagesToRemove([...imagesToRemove, fileId]);
    }
    const uploadedFiles = [...reviewFiles];
    uploadedFiles.splice(index, 1);
    setReviewFiles(uploadedFiles);
  };

  const handleOptionsClicked = (event: any) => {
    setOptionsAnchorEl(event.currentTarget as HTMLElement);
  };

  const onReportIncident = () => {
    setOptionsAnchorEl(null);
    window.open("mailto:hello@getblys.com");
  };

  const onBlockClient = () => {
    blockClient(
      { jobId, blockReason: "blocked" },
      {
        onSuccess: (response: any) => {
          refreshJobDetails();
          setSuccessMessage("Client blocked");
          setOptionsAnchorEl(null);
        },
        onError: (error: any) => {
          setErrorMessage("Unable to block client. Please try again.");
        },
      }
    );
  };

  const onUnblockClient = () => {
    unblockClient(
      { jobId },
      {
        onSuccess: (response: any) => {
          refreshJobDetails();
          setOptionsAnchorEl(null);
          setSuccessMessage("Client unblocked.");
        },
        onError: (error: any) => {
          setErrorMessage("Unable to unblock client. Please try again.");
        },
      }
    );
  };

  const hasReview = !checkIfEmpty(review);

  const actions = [
    <Button
      onClick={onModalClose}
      type={ButtonType.outlined}
      title={hasReview ? "Cancel" : "Skip"}
    />,
    <Button
      loading={isAddingReview}
      onClick={addClientReview}
      type={ButtonType.secondary}
      title={hasReview ? "Save changes" : "Add"}
    />,
  ];

  const isUserBlocked = !!getValue(job, "userBlock");

  return (
    <ContentModal
      fullWidth
      maxWidth={"sm"}
      divider={false}
      visible={visible}
      onClose={onModalClose}
      actionStyle={{ padding: "0px 48px 48px 48px" }}
      contentStyle={{ padding: "48px 48px 0px 48px" }}
      childrenStyle={{ marginTop: 0 }}
      actions={actions}
    >
      <input
        type="file"
        id="note-file-upload"
        ref={reviewImageRef}
        style={{ display: "none" }}
        onChange={onReviewImageChange}
      />
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S8}>
        <Box
          marginBottom={!includeNote ? "32px" : 0}
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          gridGap={Spacing.S8}
        >
          <Box>
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              justifyContent={JustifyContent.spaceBetween}
            >
              <Box
                fontSize={FontSize.F22}
                color={Colors.NightBlue}
                marginBottom={Spacing.S3}
                fontWeight={FontWeight.Bold}
                fontFamily={FontFamily.Museo}
              >
                {hasReview ? "Update review" : "Leave a review"}
              </Box>
              <ButtonBase onClick={handleOptionsClicked} disableTouchRipple={true}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <img src={MoreIcon} alt="More options" />
                </Box>
              </ButtonBase>

              {/* Dropdown actions */}
              <Popover
                id={"review-client-popover"}
                open={optionsAnchorEl !== null}
                anchorEl={optionsAnchorEl}
                onClose={() => setOptionsAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box display="flex" flexDirection="column" width="220px">
                  {isUserBlocked ? (
                    <PopoverItem
                      type={PopoverItemType.default}
                      title={"Unblock client"}
                      onClick={onUnblockClient}
                    />
                  ) : (
                    <PopoverItem
                      type={PopoverItemType.default}
                      title="Block client"
                      onClick={onBlockClient}
                    />
                  )}
                  <PopoverItem
                    type={PopoverItemType.default}
                    title="Report an incident"
                    onClick={onReportIncident}
                  />
                </Box>
              </Popover>
            </Box>
            <Box
              color={Colors.Grey}
              fontSize={FontSize.F14}
              marginBottom={Spacing.S4}
              fontWeight={FontWeight.Medium}
              fontFamily={FontFamily.OpenSans}
            >
              Your review will not be shared with the client, but may be visible to other providers.
              Please keep your feedback relevant and avoid sensitive information.
            </Box>
            <Ratings rating={rating} width="19.8px" height="18.9px" onClickRating={onRatingUser} />
          </Box>

          <TextField
            multiline
            paddingTop="0px"
            paddingBottom="0px"
            value={reviewText}
            placeholder="Leave a review (optional)"
            onChange={(text: string) => setReviewText(text)}
          />

          {(reviewFiles || []).length ? (
            <Box>
              <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3}>
                {reviewFiles.map((file: any, index: number) => {
                  const { filePath } = file;
                  const fileURL =
                    filePath && typeof filePath === "string"
                      ? getFullUploadsUrl(filePath)
                      : file.tempURL;
                  return (
                    <RoundedImage
                      fileUrl={fileURL}
                      allowRemove
                      onRemove={() => onRemoveReviewFile(index)}
                    />
                  );
                })}
              </Box>
              <Box
                fontFamily="Museo"
                fontSize="16px"
                color={Colors.Dusk}
                fontWeight={400}
                lineHeight="24px"
                display={"flex"}
                alignItems={"center"}
                gridGap={"8px"}
                marginTop={"16px"}
              >
                <Checkbox
                  style={{
                    color: Colors.TurquoiseBlue,
                    margin: 0,
                    padding: 0,
                  }}
                  checked={uploadToPortfolio}
                  onChange={(event: any) => {
                    setUploadToPortfolio(event.target.checked);
                  }}
                />{" "}
                Upload images to my portfolio
              </Box>
            </Box>
          ) : (
            <></>
          )}

          <Button
            width="132px"
            loading={false}
            title="Add image"
            type={ButtonType.outlined}
            onClick={onUploadFileClick}
          />
        </Box>

        {includeNote && (
          <TreatmentNoteForm
            note={noteText}
            files={noteFiles}
            title="Add your note"
            onRemoveNote={onRemoveNote}
            onfileUploaded={onNoteFileUploaded}
            onNoteChange={(text) => setNoteText(text)}
            wrapperStyle={{ gridGap: Spacing.S3 }}
            contentWrapper={{ marginTop: Spacing.S4, marginBottom: Spacing.S8 }}
            subTitleStyle={{
              fontSize: FontSize.F14,
              FontFamily: FontFamily.OpenSans,
              FontWeight: FontWeight.Regular,
            }}
            titleStyle={{
              fontSize: FontSize.F22,
              color: Colors.NightBlue,
              fontWeight: FontWeight.Bold,
              fontFamily: FontFamily.Museo,
            }}
          />
        )}
      </Box>
    </ContentModal>
  );
};

export default UserRatingModal;
