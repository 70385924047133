import { useEffect, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getValue } from "../../utils/object";
import { useUserStore } from "../../stores/user";
import { STRIPE_PUBLISHABLE_KEY } from "../../constants/common";

const useStripePromise = () => {
  const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
  const { user } = useUserStore();

  useEffect(() => {
    if (!user) return;

    const country = getValue(user, "country");
    const stripeSecret = STRIPE_PUBLISHABLE_KEY(country);

    loadStripe(stripeSecret).then((stripe) => setStripePromise(stripe));
  }, [user]);

  return stripePromise;
};

export { useStripePromise };
