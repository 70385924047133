import React from "react";
import { ButtonBase, withStyles } from "@material-ui/core";
import LockIcon from "../images/lock-icon.svg";
import UnlockIcon from "../images/unlock-icon.svg";
import { Colors } from "../constants/colors";

interface Props {
  isLocked: boolean;
  handlePress: () => any;
  size?: number;
  padding?: number;
}

const Button = withStyles({
  root: {
    borderRadius: "50%",
    "&:hover": {
      background: Colors.LightGrey,
    },
  },
})(ButtonBase);

const LockButton = ({ isLocked, handlePress, size = 16, padding = 8 }: Props) => {
  const btnSize = `${size + padding * 2}px`;
  return (
    <Button onClick={handlePress} style={{ width: btnSize, height: btnSize }}>
      <img
        src={isLocked ? LockIcon : UnlockIcon}
        alt={"icon"}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    </Button>
  );
};

export default LockButton;
