import React from "react";
import { Box } from "@material-ui/core";
import { Text } from "../../../../v2/Styled";
import * as Styled from "../../../../v2/Styled/enum";
import { getValue } from "../../../../../utils/object";
import { useMobile } from "../../../../../hooks/mobile";
import { Colors } from "../../../../../constants/colors";
import FileIcon from "../../../../../images/file-icon.svg";
import { getLastElement } from "../../../../../utils/array";
import CardWrapper from "../../../../BookingDetails/BookingDetailCard";

const Row = (item: { label: string; value: string }) => {
  return (
    <Box>
      <Text size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21} color={Colors.Grey}>
        {item.label}:{" "}
      </Text>
      <Text size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21} color={Colors.Dusk}>
        {item.value}
      </Text>
    </Box>
  );
};

export const FileRow = (document: { label: string; value: any; type: string }) => {
  const isMobile = useMobile();
  if (!document.value) return <></>;
  if (document.type === "file") {
    const filePath = getValue(document, "value") || "";
    const fileName = getLastElement(filePath.split("/"));
    return (
      <CardWrapper
        style={{
          marginTop: Styled.Spacing.S3,
          padding: Styled.Spacing.S2,
          backgroundColor: Colors.PaleGrey,
          border: `1px solid ${Colors.LightPeriwinkle}`,
          width: isMobile ? "100%" : "234px",
        }}
      >
        <Box display={Styled.Display.Flex} gridGap={"2px"} alignItems={Styled.AlignItems.center}>
          <img
            src={FileIcon}
            alt={"File icon"}
            style={{ width: Styled.Spacing.S8, height: Styled.Spacing.S8 }}
          />
          <Text size={Styled.FontSize.F14} lineHeight={Styled.LineHeight.L21}>
            {fileName}
          </Text>
        </Box>
      </CardWrapper>
    );
  }

  return <Row label={document.label} value={document.value} />;
};

export default Row;
