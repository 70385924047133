import React, { useEffect, useState } from "react";
import { AutoCompleteField } from "../AutoCompleteField";
import { debounce } from "lodash";
import recipientIcon from "./../../images/user-icon.png";
import { useSearchProviderByName } from "../../hooks/search.hook";

interface DropdownOption {
  value: any;
  title: string;
  helperText?: string;
  type?: string;
  locationTypeId?: number;
  id?: number;
}

interface IProps {
  itemValue?: any;
  onItemClicked: (item: DropdownOption) => void;
  onChange: (data: any) => void;
}

const ProAutoCompleteField: React.FC<IProps> = ({ itemValue, onItemClicked, onChange }) => {
  const [providerName, setProviderName] = useState("");
  const [listOfProvider, setListOfProvider] = useState([]);

  const { data: providerOptions } = useSearchProviderByName({
    name: providerName,
    myProviders: false,
  });

  const handleProviderChange = (value: any) => {
    if (value.length >= 2) {
      setProviderName(value);
    }
  };

  useEffect(() => {
    setListOfProvider(providerOptions);
  }, [providerOptions]);

  const debounceProviderChangeHandler = debounce(handleProviderChange, 500);

  return (
    <AutoCompleteField
      title="Provider name"
      options={listOfProvider as Array<DropdownOption>}
      placeholder="Enter provider name"
      onOptionClick={(option) => onItemClicked(option)}
      onValueChange={(text) => {
        setProviderName(text);
        debounceProviderChangeHandler(text);
        onChange(text);
      }}
      value={itemValue}
      dropDownOptionIcon={recipientIcon}
    />
  );
};

export default ProAutoCompleteField;
