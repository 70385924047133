import { getAdminAccessToken } from "../helpers/accessToken";

const useAuth = () => {
  const adminToken = getAdminAccessToken();

  return {
    adminToken,
    isAdminLoggedInAsClient: !!adminToken,
  };
};

export default useAuth;
