import React from "react";
import { Box, Link } from "@material-ui/core";
import HeroImage from "../../images/no-bookings-placeholder.svg";
import { Colors } from "../../constants/colors";
import BookMassageButton, { BookMassageButtonType } from "../BookMassageButton";

export enum NoBookingsPlaceholderType {
  upcoming,
  past,
}

interface Props {
  type: NoBookingsPlaceholderType;
  onNewBookingClicked?: () => unknown;
  onPastBookingsClicked?: () => unknown;
}

export default function NoBookingsPlaceholder({
  type,
  onNewBookingClicked,
  onPastBookingsClicked,
}: Props): JSX.Element {
  if (type === NoBookingsPlaceholderType.upcoming) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" width="300px">
        <img src={HeroImage} />
        <Box
          fontFamily="Museo"
          fontSize="20px"
          textAlign="center"
          fontWeight={700}
          color={Colors.Dusk}
          mt="19px"
        >
          No upcoming bookings
        </Box>

        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          color={Colors.Dusk}
          letterSpacing="0.39px"
          textAlign="center"
          mt="10px"
        >
          Make a{" "}
          <BookMassageButton
            type={BookMassageButtonType.textLink}
            onBookPress={onNewBookingClicked}
          />
          , or rebook one of your favourites from your{" "}
          <Link
            style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }}
            onClick={onPastBookingsClicked}
          >
            past bookings.
          </Link>
        </Box>
      </Box>
    );
  }

  if (type === NoBookingsPlaceholderType.past) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" width="300px">
        <img src={HeroImage} />
        <Box
          fontFamily="Museo"
          fontSize="20px"
          textAlign="center"
          fontWeight={700}
          color={Colors.Dusk}
          mt="19px"
        >
          No past bookings
        </Box>

        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          color={Colors.Dusk}
          letterSpacing="0.39px"
          textAlign="center"
          mt="10px"
        >
          What are you waiting for? Book your first treatment today!
        </Box>
      </Box>
    );
  }

  return <Box />;
}
