import { Box } from "@material-ui/core";

type Props = {
  onClick?: () => unknown;
};

const DuplicateEmailError = (props: Props) => {
  const handleAccountChange = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Box>
      Email already exists. Try another or{" "}
      <Box
        fontWeight={700}
        display={"inline"}
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={handleAccountChange}
      >
        login with this email.
      </Box>
    </Box>
  );
};

export default DuplicateEmailError;
