import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { scrollToTop } from "../helpers/scrollToTop";
import { getValue } from "../utils/object";

export default function GlobalScrollToTop() {
  /* 
    This component is used to scroll to top of the page 
    and add the state to location as isRedirected = true
    whenever the push action is triggered in all the apps
  */

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const currentState = getValue(location, "state", {});
      const shouldScrollToTop = getValue(currentState, "shouldScrollToTop", true);
      if (action === "PUSH") {
        location.state = { ...currentState, isRedirected: true };
        if (!shouldScrollToTop) return;
        scrollToTop();
      } else if (action === "REPLACE") {
        scrollToTop();
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}
