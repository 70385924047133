import { Box, IconButton } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import Dialog from "../../components/Dialog";
import CloseIcon from "../../images/modal-close.svg";
import { useAlertStore } from "../../stores/alert";
import { useMfaLogin, useSendTwoFAVerificationCode } from "../../hooks/settings/twoFA.hooks";
import { getValue } from "../../utils/object";
import { User } from "../../models";
import { trackEvent } from "../../services/segment/track.service";
import MfaLoginComponent from "./MfaLoginComponent";
import { useState } from "react";
import { getFutureTimeStamp } from "../../utils/date.util";
import { VERIFICATION_CODE_RESEND_TIMEOUT } from "../../constants/time";
import { parseApiError } from "../../helpers/error";
import { useLocation } from "react-router-dom";
import firebaseApp from "../../services/firebaseApp";

interface Props {
  open: boolean;
  onClose: () => unknown;
  closeLoginModal: Function | undefined;
  onLoggedIn: (user: User) => unknown;
}

const MfaLoginModal = ({ open, onClose, onLoggedIn, closeLoginModal }: Props) => {
  const isMobile = useMobile();
  const location = useLocation();

  const email = getValue(location, "state.email");
  const mfaType = getValue(location, "state.mfaType");
  const mobileNumber = getValue(location, "state.mobile");
  const serviceId = getValue(location, "state.serviceId");

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const [waitTime, setWaitTime] = useState("");
  const [isActionDisabled, setIsActionDisabled] = useState(true);

  const { mutateAsync: handleMfaLogin, isLoading: mfaLoginLoading } = useMfaLogin();
  const { mutateAsync: resendVerficationCode } = useSendTwoFAVerificationCode();

  const onCodeSubmit = async (code: string) => {
    try {
      const response = await handleMfaLogin({ code: code, email, serviceId });

      const user = getValue(response, "user") as User;

      const { accessToken } = user;

      if (accessToken) {
        if (closeLoginModal) {
          closeLoginModal();
        }
        trackEvent("Logged In", {
          version: 2,
        });

        localStorage.setItem("token", accessToken);
        onLoggedIn(user);
      }
    } catch (error) {
      setErrorMessage("Please make sure you have entered the correct verification code");
    }
  };

  const onResend = async () => {
    try {
      const fbToken = await firebaseApp.getToken();
      const response = await resendVerficationCode({ mobileNumber, fbToken });
      if (response) {
        setWaitTime(getFutureTimeStamp({ seconds: VERIFICATION_CODE_RESEND_TIMEOUT }).format());
        setIsActionDisabled(true);
        setSuccessMessage("Verification code sent successfully.");
      }
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  return (
    <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
      <Box bgcolor="white" width={isMobile ? "100%" : "500px"} borderRadius="11px">
        <Box display="flex" justifyContent="flex-end" mt={1} mr={1}>
          <IconButton onClick={onClose}>
            <img src={CloseIcon} alt="Close" />
          </IconButton>
        </Box>
        <Box ml="40px" mr="40px" mb="32px">
          <MfaLoginComponent
            onCodeSubmit={onCodeSubmit}
            mfaLoginLoading={mfaLoginLoading}
            onResend={onResend}
            mfaType={mfaType}
            isActionDisabled={isActionDisabled}
            waitTime={waitTime}
            setIsActionDisabled={setIsActionDisabled}
            marginTop={"20px"}
            titleFontSize={"30px"}
            subTextFontSize="16px"
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default MfaLoginModal;
