import React, { useEffect, useState } from "react";
import DayPicker from "react-day-picker";
import "../../styles/DayPicker.css";
import "react-day-picker/lib/style.css";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { Position, Spacing } from "../v2/Styled/enum";
import { DayPickerNavbar } from "../../pages/NewBooking/DateAndTime";
import { useMobile } from "../../hooks/mobile";
import { getDateObject, isSelectedDateBeforeEndDate } from "../../utils/date.util";

interface Props {
  open: boolean;
  onDaySelected: (day: Date) => unknown;
  minValidDate?: Date | null;
  selectedDate: Date | null | undefined;
  selectedFromDate?: Date | string | null;
  hasDateRange?: boolean;
}
export const Calendar = ({
  open,
  onDaySelected,
  minValidDate,
  selectedDate,
  hasDateRange = false,
  selectedFromDate,
}: Props) => {
  const isMobile = useMobile();
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(selectedDate || undefined);
  const [hoveredDate, setHoveredDate] = useState<null | Date>(null);

  const handleDayHovered = (day: Date) => {
    setHoveredDate(day);
  };

  const handleDaySelected = (day: Date) => {
    setSelectedDay(day);
    onDaySelected(day);
  };

  const getPreferredModifier = () => {
    let fromDate = selectedFromDate ? getDateObject(selectedFromDate) : selectedDate;
    let toDate = selectedDay ? getDateObject(selectedDay) : undefined;

    if (!fromDate) return;

    if (hoveredDate) {
      const isBeforeSelected = isSelectedDateBeforeEndDate(hoveredDate, fromDate);
      if (isBeforeSelected) {
        toDate = hoveredDate || toDate;
      } else {
        toDate = hoveredDate;
      }
    }

    return {
      from: fromDate,
      to: toDate,
    };
  };
  if (!open) return null;

  const preferredModifiers = getPreferredModifier();
  return (
    <Box
      bgcolor={Colors.White}
      style={{
        boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)",
        borderRadius: "8px",
        padding: isMobile ? Spacing.S0 : Spacing.S4,
        paddingTop: Spacing.S4,
        top: "85px",
        left: "0px",
        right: "0px",
        zIndex: 999,
        width: "auto",
        display: "inline-block",
        whiteSpace: "nowrap",
      }}
      position={Position.Absolute}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <DayPicker
        firstDayOfWeek={1}
        onDayMouseLeave={() => setHoveredDate(null)}
        onDayMouseEnter={handleDayHovered}
        onDayClick={(day, modifiers) => {
          if (modifiers.disabled) return;
          handleDaySelected(day);
        }}
        selectedDays={[
          hoveredDate || preferredModifiers?.from,
          ...(hasDateRange ? [{ from: preferredModifiers?.from, to: preferredModifiers?.to }] : []),
        ]}
        captionElement={() => {
          return <Box />;
        }}
        {...(minValidDate && { disabledDays: { before: minValidDate } })}
        navbarElement={(props) => {
          return <DayPickerNavbar {...props} />;
        }}
        modifiers={{
          from: preferredModifiers?.from,
          to: preferredModifiers?.to,
        }}
      />
    </Box>
  );
};
