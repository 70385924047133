import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";

import TextField from "../../../TextField";
import Button, { ButtonType } from "../../../Button";
import ContentModal from "../../../Modals/ContentModal/contentModal";
import { requestAccountDeletion } from "../../../../services/users/users.service";
import { useAlertStore } from "../../../../stores/alert";
import { parseApiV2Error } from "../../../../helpers/error";
import isValidEmail from "../../../../helpers/validation";
import InfoText from "../../../Text/InfoText/infoText";
import DeleteConfirmMfaModal from "../DeleteConfirmMfaModal/DeleteCofirmMfaModal";
import { getValue } from "../../../../utils/object";
import { useHistory } from "react-router-dom";
import { Paths } from "../../../../constants/paths";

interface DeleteConfirmModalProps {
  reason: string;
  visible: boolean;
  onClose: () => unknown;
  onSuccess: () => unknown;
}

const DeleteConfirmModal = ({ reason, visible, onClose, onSuccess }: DeleteConfirmModalProps) => {
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [showMfaModal, setShowMfaModal] = useState(false);
  const [mfaType, setMfaType] = useState("");
  const [serviceId, setServiceId] = useState("");

  const { setErrorMessage } = useAlertStore();

  useEffect(() => {
    clearFormFields();
  }, [reason]);

  const clearFormFields = () => {
    setEmail("");
    setPassword("");
  };

  const verifyFields = () => {
    let isValid = true;
    try {
      if (!email || email.length <= 0) throw Error("Email is required");
      if (!isValidEmail(email)) throw Error("Please enter a valid email");
      if (!password || password.length <= 0) throw Error("Password is required");
    } catch (err: any) {
      setErrorMessage(err.message);
      isValid = false;
    }
    return isValid;
  };

  const handleRequestDeletion = async () => {
    try {
      const isValid = verifyFields();
      if (!isValid) return;

      setLoading(true);
      const payload = {
        email,
        reason,
        password,
      };

      const response = await requestAccountDeletion(payload);
      const needMfa = getValue(response, "data.needMfa");
      const mfaType = getValue(response, "data.mfaType");
      const mobile = getValue(response, "data.mobile");
      const serviceId = getValue(response, "data.serviceId");

      if (response && response.success) {
        setLoading(false);
        if (needMfa) {
          history.push("/profile", { email, reason, mfaType, serviceId, mobile });
          setShowMfaModal(true);
          onClose();
          return;
        }
        onSuccess();
      }
    } catch (err: any) {
      setLoading(false);
      setErrorMessage(parseApiV2Error(err));
    }
  };

  const onMfaModalClose = () => {
    setShowMfaModal(false);
  };

  return (
    <>
      <ContentModal
        title="Delete account"
        description="Verify your account details to confirm deletion."
        onClose={onClose}
        visible={visible}
        actions={[
          <Button
            loading={isLoading}
            title="Confirm delete"
            onClick={handleRequestDeletion}
            type={ButtonType.secondary}
          />,
        ]}
      >
        <Box>
          <TextField
            value={email}
            title="Email address"
            placeholder="youremail@mail.com"
            onChange={(text) => setEmail(text)}
          />
          <TextField
            type="password"
            title="Password"
            value={password}
            isPassword={true}
            placeholder="Enter password"
            onChange={(text) => setPassword(text)}
          />
          <InfoText text="You will permanently lose all your reviews, contacts, messages and profile info. After this there is no turning back." />
        </Box>
      </ContentModal>

      <DeleteConfirmMfaModal
        visible={showMfaModal}
        onClose={onMfaModalClose}
        reason={reason}
        email={email}
        onSuccess={onSuccess}
        mfaType={mfaType}
        mobile={mobile}
        serviceId={serviceId}
      />
    </>
  );
};

export default DeleteConfirmModal;
