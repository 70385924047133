import { createSlice } from "@reduxjs/toolkit";

import { BookingSlot, CorporateBookingState, CorporateInfo, Job } from "./interface";

const defaultCorporateInfo: CorporateInfo = {
  eventName: "",
  eventType: "",
  noteForRecipients: "",
  numberOfPros: 0,
  numberOfPerson: 0,
  durationPerPerson: 0,
};

const defaultBooking: CorporateBookingState = {
  id: null,
  title: null,
  earliestTime: null,
  timeOfArrival: null,
  jobs: [],
  address: null,
  corporateInfo: { ...defaultCorporateInfo },
  user: null,
  bookingLink: null,
  uuid: null,
  timezone: "",
};

const defaultSelectedSlot: BookingSlot = {
  id: null,
  isReserved: false,
  bookingId: null,
  jobId: null,
  proId: null,
  userId: null,
  recipientId: null,
  type: null,
  start: null,
  end: null,
  recipient: {
    id: null,
    lastName: null,
    firstName: null,
  },
  user: null,
  uuid: null,
};

const initialState = {
  booking: { ...defaultBooking },
  selectedSlot: { ...defaultSelectedSlot },
  selectedTherapist: "",
  selectedDate: null,
};

const { reducer, actions } = createSlice({
  name: "corporateBooking",
  initialState,
  reducers: {
    initialize: (state, { payload = {} }) => {
      // transforming booking attributes
      state.booking = payload;
    },
    setSelectedSlot: (state, { payload }) => {
      if (state.selectedSlot.id !== payload.id) {
        state.selectedSlot = payload;
        state.selectedTherapist = getTherapistName({
          jobs: state.booking.jobs,
          jobId: payload.jobId,
        });
      } else {
        state.selectedSlot = defaultSelectedSlot;
        state.selectedTherapist = "";
      }
    },
    resetSelectedSlot: (state) => {
      state.selectedSlot = defaultSelectedSlot;
    },
  },
});

const getTherapistName = ({ jobs, jobId }: { jobs: Job[]; jobId: number }) => {
  const job = jobs.find((job: Job) => job.id === jobId);
  if (!job) return "";
  return job.user.firstName || "";
};

export { actions };
export default reducer;
