import { checkIfEmpty, getValue } from "../../../utils/object";

import { Box } from "@material-ui/core";
import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import Divider from "../../../components/Divider";
import SectionHeader from "../../../components/Headers/SectionHeader/SectionHeader";
import JumboTron from "../../../components/Jumbotron/Jumbotron";
import UpdatedLocationDetails from "../../../components/UpdateRequest/UpdatedLocationDetails";
import { Spacing } from "../../../components/v2/Styled/enum";
import { Colors } from "../../../constants/colors";
import TreatmentDetails from "../TreatmentDetails/TreatmentDetails";
import UpdatedRecipient from "./UpdatedRecipient";
import UpdatedTimeOfArrival from "./UpdatedTimeOfArrival";

interface UpdateRequestProps {
  updatedData: any;
  booking: any;
  job: any;
}

const UpdateRequest = ({ updatedData = {}, booking = {}, job = {} }: UpdateRequestProps) => {
  const getUpdatedServiceFee = () => {
    const { updatedServiceFee } = booking;
    if (!updatedServiceFee) return 0;

    return `${updatedServiceFee}`;
  };

  if (checkIfEmpty(updatedData) || checkIfEmpty(booking) || checkIfEmpty(job)) return <></>;

  const { timeOfArrival, addressDetails, recipientDetails } = updatedData;
  const { timezone } = booking;
  const serviceFee = getUpdatedServiceFee();

  const updatedTreatmentDetails = getValue(job, "bookingdetail.updatedTreatmentDetails") || [];
  const currency = getValue(booking, "service.selectedCountry.currencySymbol");
  const isHairAndMakeup = getValue(booking, "isHairAndMakeup");

  return (
    <div>
      <SectionHeader title="Changes requested by client" wrapperStyle={{ margin: "16px 0px" }} />
      <CardWrapper
        style={{
          gap: Spacing.S4,
        }}
      >
        {!checkIfEmpty(addressDetails) ? (
          <Box>
            <UpdatedLocationDetails
              bookingId={booking.id}
              addressDetails={addressDetails}
              isHairAndMakeup={isHairAndMakeup}
            />
          </Box>
        ) : null}
        {!checkIfEmpty(updatedTreatmentDetails) ? (
          <>
            <TreatmentDetails booking={booking} treatmentDetails={updatedTreatmentDetails} />
          </>
        ) : null}
        {!!timeOfArrival && (
          <UpdatedTimeOfArrival timeOfArrival={timeOfArrival} timezone={timezone} />
        )}

        {!checkIfEmpty(recipientDetails) && (
          <UpdatedRecipient recipientDetails={recipientDetails} />
        )}
        {serviceFee ? (
          <>
            <Divider />
            <JumboTron>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "16px",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ ...styles.treatmentLabel, color: Colors.Dusk }}>Updated payout</div>
                <div style={{ ...styles.payoutStyle, textAlign: "center" }}>
                  {currency}
                  {parseFloat(serviceFee).toFixed(2)}
                </div>
              </Box>
            </JumboTron>
          </>
        ) : null}
      </CardWrapper>
    </div>
  );
};

const styles = {
  treatmentLabel: {
    lineHeight: "21px",
    color: Colors.Indigo,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 600,
  },
  payoutStyle: {
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "17px",
    fontWeight: 600,
  },
};

export default UpdateRequest;
