import { formatDate } from "../../../utils/date.util";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { DATE_ISO_8601_FORMAT } from "../../../constants/date";
import { FormStepsName, IFormData } from "../../../pages/ProDashboard/Business/Payouts/Forms/model";
import { PayoutOnboardingSteps } from "../../../pages/ProDashboard/Business/Payouts/SetupPayoutTimeline";

const transformStripePayoutDetailsResponse = (stripeAccount: any) => {
  return {
    [FormStepsName.PersonalDetails]: {
      firstName: getValue(stripeAccount, "firstName") || "",
      lastName: getValue(stripeAccount, "lastName") || "",
      email: getValue(stripeAccount, "email") || "",
      mobile: getValue(stripeAccount, "phone") || "",
      dob: getValue(stripeAccount, "dob") || "",
      idNumberProvided: getValue(stripeAccount, "idNumberProvided") || "",
      idNumber: getValue(stripeAccount, "idNumber") || "",
    },
    [FormStepsName.Address]: {
      city: getValue(stripeAccount, "address.city", ""),
      line1: getValue(stripeAccount, "address.line1", ""),
      line2: getValue(stripeAccount, "address.line2", ""),
      state: getValue(stripeAccount, "address.state", ""),
      country: getValue(stripeAccount, "address.country", ""),
      postalCode: getValue(stripeAccount, "address.postal_code", ""),
    },
    [FormStepsName.IdentityVerification]: {
      documentId: getValue(stripeAccount, "identityDocument.document.id"),
      documentNumber: getValue(stripeAccount, "identityDocument.document.documentNumber"),
      documentType: getValue(stripeAccount, "identityDocument.document.documentType"),
      expiryDate: getValue(stripeAccount, "identityDocument.document.expiresAt"),
      documentPath: getValue(stripeAccount, "identityDocument.document.documentPath"),
      documentProvided: getValue(stripeAccount, "identityDocument.status", "") === "verified",
    },
    [FormStepsName.AccountForPayout]: {
      type: getValue(stripeAccount, "externalAccount.type"),
      token: getValue(stripeAccount, "externalAccount.id"),
      accountNumber: getValue(stripeAccount, "externalAccount.accountNumber", null),
      routingNumber: getValue(stripeAccount, "externalAccount.routingNumber", null),
      details: {
        last4: getValue(stripeAccount, "externalAccount.last4"),
        expiryMonth: getValue(stripeAccount, "externalAccount.expiryMonth"),
        expiryYear: getValue(stripeAccount, "externalAccount.expiryYear"),
        type: getValue(stripeAccount, "externalAccount.type"),
        cardType: getValue(stripeAccount, "externalAccount.brand"),
      },
    },
  } as IFormData;
};

const getAllOnboardingFormSteps = (options: []): Record<string, Array<any>> => {
  let data = {
    allSteps: [],
    steps: [],
  };
  if (checkIfEmpty(options)) return data;

  const allSteps = [
    ...(options || []),
    {
      step: (options || []).length + 1,
      name: FormStepsName.ReviewAndConfirm,
      title: "Confirm",
      label: "Review & confirm",
      description:
        "Please take a moment to carefully review your information before submitting it.",
    },
  ];

  const steps = allSteps
    .map(
      (item: any) =>
        ({
          step: getValue(item, "step", ""),
          name: getValue(item, "name", ""),
          title: getValue(item, "title", ""),
          label: getValue(item, "label", ""),
        } as PayoutOnboardingSteps)
    )
    .sort((a: PayoutOnboardingSteps, b: PayoutOnboardingSteps) => a.step - b.step);

  return { allSteps, steps };
};

const getPayloadForAccountUpdate = (data: IFormData, step?: number) => {
  const dateOfBirth = data[FormStepsName.PersonalDetails].dob || "";

  const payloadData = {
    [FormStepsName.PersonalDetails]: {
      dob: formatDate({ date: dateOfBirth, format: DATE_ISO_8601_FORMAT }) || "",
      idNumber: data[FormStepsName.PersonalDetails].idNumber || "",
    },
    [FormStepsName.Address]: {
      line1: data[FormStepsName.Address].line1 || "",
      line2: data[FormStepsName.Address].line2 || null,
      city: data[FormStepsName.Address].city || "",
      state: data[FormStepsName.Address].state || "",
      postalCode: data[FormStepsName.Address].postalCode || "",
      country: data[FormStepsName.Address].country || "",
    },
    [FormStepsName.IdentityVerification]: {
      documentId: data[FormStepsName.IdentityVerification].documentId || null,
    },
    [FormStepsName.AccountForPayout]: {
      type: data[FormStepsName.AccountForPayout].type || "",
      accountNumber: data[FormStepsName.AccountForPayout].accountNumber || "",
      routingNumber: data[FormStepsName.AccountForPayout].routingNumber || "",
      token: data[FormStepsName.AccountForPayout].token || "",
    },
  };

  return { data: payloadData, step: step || Object.keys(data).length };
};

export {
  getAllOnboardingFormSteps,
  getPayloadForAccountUpdate,
  transformStripePayoutDetailsResponse,
};
