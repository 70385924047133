import React from "react";
import {
  Box,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { description } from "platform";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: {
    boldContent?: string;
    normalContent?: string;
  };
  btnText?: string;
}

const RecipientBookingInfoCard = (props: Props) => {
  return (
    <MUIDialog open={props.isOpen} maxWidth="xs" onClose={props.onClose}>
      <Box
        style={{
          paddingTop: "16px",
        }}
      >
        <DialogTitle>
          <Box
            className="titleText"
            style={{
              color: Colors.Indigo,
            }}
          >
            {props.title}
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: "0px 48px" }}>
          <Box
            style={{
              color: Colors.Grey,
              textAlign: "center",
            }}
          >
            {props.description.boldContent && (
              <span style={{ fontFamily: "Museo", fontWeight: 700 }}>
                {props.description.boldContent}
              </span>
            )}
            {props.description.normalContent && (
              <span
                style={{
                  fontFamily: "Museo",
                  fontWeight: 300,
                }}
              >
                {props.description.normalContent}
              </span>
            )}
          </Box>
        </DialogContent>
        <DialogActions className="modalFooter">
          <Button
            variant="outlined"
            onClick={props.onClose}
            fullWidth
            style={{
              fontFamily: "Museo",
              color: Colors.Dusk,
              textTransform: "capitalize",
              fontWeight: 700,
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "16px",
            }}
          >
            {props.btnText || "Ok"}
          </Button>
        </DialogActions>
      </Box>
    </MUIDialog>
  );
};

export default React.memo(RecipientBookingInfoCard);
