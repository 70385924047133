import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../constants/colors";
import { useBookingStore, useServicesStore } from "../../../stores/booking";
import Dropdown, { DropdownOption } from "../../Dropdown";

interface Props {
  index: number;
  onChanged: Function;
}

const TreatmentForSelector = ({ index, onChanged }: Props) => {
  const { treatmentForList } = useServicesStore();
  const { treatmentDetails, setTreatmentDetails } = useBookingStore();
  const [treatmentForOptions, setTreatmentForOptions] = useState<
    Array<DropdownOption>
  >([]);

  useEffect(() => {
    if (treatmentForList) {
      setTreatmentForOptions(
        [...treatmentForList].map((f) => {
          return {
            value: f.value,
            title: String(f.label),
          };
        })
      );
    }
  }, [treatmentForList]);

  const onTreatmentForChanged = (i: number, value: string) => {
    const newTreatmentDetails = [...treatmentDetails];
    newTreatmentDetails[i].treatmentFor = value;
    setTreatmentDetails(newTreatmentDetails);
    onChanged(i, value);
  };

  return (
    <>
      <Box
        fontFamily="Museo"
        fontSize="20px"
        color={Colors.Dusk}
        fontWeight={700}
        flex={1}
        textAlign="left"
      >
        {`Person ${index + 1}`}
      </Box>
      <Dropdown
        title="Who is this for?"
        options={treatmentForOptions}
        selectedOption={treatmentForOptions.find(
          (option) => option.value === treatmentDetails[index].treatmentFor
        )}
        onSelectedOption={(option) =>
          onTreatmentForChanged(index, option.value)
        }
      />
    </>
  );
};

export default TreatmentForSelector;
