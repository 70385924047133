import React from "react";
import create from "zustand";
import { transformAlertMessage } from "../services/alert/alert.transformer";

type State = {
  errorMessage: string | null | React.ReactNode;
  setErrorMessage: (message: string | null | React.ReactNode) => unknown;
  successMessage: string | null | React.ReactNode;
  setSuccessMessage: (message: string | null) => unknown;
};

export const useAlertStore = create<State>((set) => ({
  errorMessage: null,
  setErrorMessage: (message: string | null | React.ReactNode) => {
    const errorMessage = transformAlertMessage(message);
    set({ errorMessage });
  },
  successMessage: null,
  setSuccessMessage: (message: string | null | React.ReactNode) => {
    const successMessage = transformAlertMessage(message);
    set({ successMessage });
  },
}));
