import { Box } from "@material-ui/core";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";

interface Props {
  visible: boolean;
  onClose: () => unknown;
}

const PastBookingWarningModal = ({ visible, onClose }: Props) => {
  return (
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      actions={[
        <Box
          style={{
            flex: 1,
            gap: "8px",
            display: "flex",
            flexDirection: "row",
            padding: "0px 16px 16px",
          }}
        >
          <Button title="Ok" type={ButtonType.outlined} onClick={onClose} />
        </Box>,
      ]}
    >
      <Box style={{ padding: "12px 8px 0px 8px" }}>
        <Box
          fontFamily="Museo"
          fontWeight={700}
          fontSize="22px"
          color={Colors.NightBlue}
          textAlign={"center"}
        >
          Old booking
        </Box>
        <Box
          fontFamily="Open Sans"
          fontSize="16px"
          color={Colors.BlueyGrey}
          mt="16px"
          mb="5px"
          textAlign={"center"}
        >
          This booking cannot be accepted as the arrival time has already passed.
        </Box>
      </Box>
    </ContentModal>
  );
};

export default PastBookingWarningModal;
