import axios from "axios";
import { getAccessToken } from '../../helpers/accessToken';

export const getTimeRange = () => {
  const accessToken = getAccessToken();
  return axios
    .get("api/v2/config/time-range", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => res.data)
    .catch(err => {
      console.error('Time Range fetch failed', err);
      return [];
    });
};