import { Box } from "@material-ui/core";
import CardWrapper from "../../../components/BookingDetails/BookingDetailCard";
import Button, { ButtonType } from "../../../components/Button";

interface Props {
  onUpdateRequestAccept: () => unknown;
  onUpdateRequestDeclined: () => unknown;
  disabled: boolean;
  acceptBtnText?: string;
  CanChatBtn: React.ReactElement | null;
}

const PendingUpdateReqeuestActions = ({
  onUpdateRequestAccept,
  onUpdateRequestDeclined,
  disabled,
  acceptBtnText,
  CanChatBtn,
}: Props) => {
  return (
    <CardWrapper>
      <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Box mt="10px">
          <Box>
            <Button
              title={acceptBtnText || "Accept update"}
              type={ButtonType.secondary}
              onClick={onUpdateRequestAccept}
              disabled={disabled}
            />
          </Box>

          <Box mt="24px">
            <Button
              title="Decline"
              type={ButtonType.danger}
              onClick={onUpdateRequestDeclined}
              disabled={disabled}
            />
          </Box>
        </Box>
      </Box>
      {CanChatBtn && <Box mt="24px">{CanChatBtn}</Box>}
    </CardWrapper>
  );
};

export default PendingUpdateReqeuestActions;
