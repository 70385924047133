import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { parseApiV2Error } from "../../../helpers/error";
import { useDeclineCancellationRequest } from "../../../hooks/booking/bookingAction.hooks";
import { useMobile, useTab } from "../../../hooks/mobile";
import { Booking } from "../../../models";
import { useAlertStore } from "../../../stores/alert";
import Button, { ButtonType } from "../../Button";
import Divider from "../../Divider";

type Props = {
  booking: Booking;
  onAccept: () => unknown;
  onDecline: () => void;
};

const CancellationRequestByProActions = ({ booking, onAccept, onDecline }: Props) => {
  const isMobile = useMobile();
  const isTab = useTab();

  const { isLoading: isDeclining, mutate: decline } = useDeclineCancellationRequest();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const declineRequest = () => {
    decline(booking.id, {
      onSuccess: () => {
        setSuccessMessage("Cancellation request declined");
        onDecline();
      },
      onError: (error: any) => {
        setErrorMessage(parseApiV2Error(error));
      },
    });
  };

  if (!booking.cancellationRequest) {
    return null;
  }

  return (
    <Box paddingTop="16px">
      <Divider mb={"16px"} />
      <Box display="flex" gridGap="8px" flexDirection="column">
        <Box
          fontWeight={400}
          fontFamily="Museo"
          fontSize={"16px"}
          lineHeight="24px"
          paddingRight={"20px"}
          color={Colors.Dusk}
        >
          {booking.cancellationRequest.text}
        </Box>

        <Box display="flex" flexDirection={isMobile || isTab ? "column" : "row"} gridGap="8px">
          <Button
            title="No, do not cancel"
            onClick={declineRequest}
            disabled={isDeclining}
            type={ButtonType.outlined}
            style={{ padding: "0px 20px", marginBottom: "8px" }}
            width="200px"
          />
          <Button
            title="Yes, cancel"
            onClick={onAccept}
            type={ButtonType.secondary}
            style={{ padding: "0px 20px" }}
            width="200px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CancellationRequestByProActions;
