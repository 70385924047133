import { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { useMobile } from "../../../hooks/mobile";
import { useServiceArea } from "./context/ServiceAreaState";
import { useAccessToken } from "../../../hooks/common";
import Wrapper from "../../../components/Wrapper";
import FixedServiceArea from "./components/FixedServiceArea";
import RealTimeServiceArea from "./components/RealTimeServiceArea";
import serviceAreaAction from "./context/ServiceAreaAction";
import ProDashboardHeader from "../../../components/ProDashboard/ProDashboardHeader";
import NotificationDisabled from "./components/NotificationDisabled";

export default function ProDashboardServiceArea() {
  const isMobile = useMobile();
  const { dispatch } = useServiceArea();
  const accessToken = useAccessToken();

  // useEffect(() => {
  //   if (window) window.scrollTo(0, 0);
  // });

  useEffect(() => {
    if (accessToken) serviceAreaAction.fetchServiceArea(dispatch, accessToken);
  }, [accessToken, dispatch]);

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Service area" />

        <Box
          mt="40px"
          paddingLeft={isMobile ? "16px" : "88px"}
          paddingRight={isMobile ? "16px" : undefined}
          maxWidth="632px"
          fontFamily="Museo"
        >
          <Box mb={"48px"}>
            <Box fontSize="24px" mb="24px" fontWeight={600} color={Colors.NightBlue}>
              Service area
            </Box>

            <Box fontSize="16px" lineHeight="24px" fontWeight={400} color={Colors.Dusk}>
              You can choose to receive job notifications based on your current location or a fixed location. Both
              methods can be active simultaneously, which means you could get notified of jobs around your home while
              completing a job in another area or travelling in a different city.{" "}
            </Box>
          </Box>

          <NotificationDisabled />

          <RealTimeServiceArea />
          <FixedServiceArea />
        </Box>
      </Wrapper>
    </>
  );
}
