import React from "react";
import { Box, DialogActions, Dialog as MUIDialog, styled } from "@material-ui/core";
import { FontFamily, FontSize, FontWeight, JustifyContent, Spacing } from "../../v2/Styled/enum";
import Button, { ButtonType } from "../../Button";
import { Colors } from "../../../constants/colors";
interface Props {
  headerComponent: any;
  children: any;
  visible: boolean;
  maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
  onClose: () => unknown;
  fullWidth?: boolean;
  headerStyle?: object;
  actionText?: string;
  fullScreen?: boolean;
}

const CustomChildContainer = styled(Box)({
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
});
export const DetailsModal = ({
  headerComponent,
  visible,
  onClose,
  maxWidth = "xs",
  fullWidth = false,
  headerStyle = {},
  children,
  actionText = "Ok",
  fullScreen = false,
}: Props) => {
  return (
    <MUIDialog
      open={visible}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={onClose}
      fullScreen={fullScreen}
    >
      <Box
        style={{
          backgroundColor: Colors.Peppermint,
          fontFamily: FontFamily.Museo,
          fontWeight: FontWeight.Bold,
          fontSize: FontSize.F18,
          color: Colors.NightBlue,
          borderBottom: `1.5px solid ${Colors.LightPeriwinkle}`,
          ...headerStyle,
        }}
        padding={Spacing.S6}
      >
        {headerComponent}
      </Box>

      <CustomChildContainer
        padding={Spacing.S6}
        style={{
          maxWidth: "624px",
          overflow: "scroll",
          flex: 1,
        }}
      >
        <Box>{children}</Box>
      </CustomChildContainer>
      <DialogActions
        style={{
          borderTop: `1.5px solid ${Colors.LightPeriwinkle}`,
          padding: Spacing.S6,
        }}
      >
        <Box width={"100%"} justifyContent={JustifyContent.spaceBetween}>
          <Box flex={1}>
            <Button title={actionText} type={ButtonType.outlined} onClick={onClose} />
          </Box>
        </Box>
      </DialogActions>
    </MUIDialog>
  );
};
