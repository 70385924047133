import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Colors } from "../../../constants/colors";
import { Box, Text } from "../../../components/v2/Styled";
import { useUpcomingBookings } from "../../../hooks/booking/booking.hooks";
import { FilterButton } from "../../../components/FilterButton/FilterButton";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { LoadingSpinner } from "../../../components/loadingSpinner";
import Each from "../../../components/Each";
import BookingItem from "../../../components/Bookings/BookingItem";
import { Booking } from "../../../models";
import InfoModal from "../../../components/Modals/InfoModal/InfoModal";
import NoBookingsPlaceholder, {
  NoBookingsPlaceholderType,
} from "../../../components/Bookings/NoBookingsPlaceholder";
import { useBookingStore } from "../../../stores/booking";
import { Paths } from "../../../constants/paths";
import { NoFilteredBookings } from "../../../components/Bookings/NoFilteredBookings";
import { checkIfEmpty } from "../../../utils/object";
import {
  BookingFilterModal,
  FilterBookingValue,
} from "../../../components/Modals/FilterModal/BookingFilterModal";
import { useBookingFilterCount, useCachedBookingSort } from "../../../hooks/utility/filter.hooks";
import useBookingFilter from "../../../hooks/booking/bookingFilter.hook";

interface Props {
  onBookingClick: (booking: Booking, status: string) => unknown;
  isMobile: boolean;
}

const Upcomming = ({ onBookingClick, isMobile }: Props): JSX.Element => {
  const [showAlternativeOfferModal, setShowAlternativeOfferModal] = React.useState(false);
  const [showFilterAndSortModal, setShowFilterAndSortModal] = React.useState(false);
  const { cachedSortBy } = useCachedBookingSort();

  const bookingSortBy = cachedSortBy || "asc";
  const [searchQuery, setSearchQuery] = useState<FilterBookingValue>({ sortBy: bookingSortBy });
  const [numOfFilter, setNumOfFilter] = useState(bookingSortBy !== "asc" ? 1 : 0);

  const { getFilterCount } = useBookingFilterCount();

  const history = useHistory();
  const location = useLocation();
  const { setExitBookingFlowPath } = useBookingStore();

  const { safeBookingQueries } = useBookingFilter({ forUpcoming: true });

  const { isLoading, upcomingBookings, invalidateUpcomingBookings } = useUpcomingBookings({
    query: { ...searchQuery, ...safeBookingQueries },
  });

  const onAlternativeTimePressed = (booking: Booking) => {
    const { id: bookingId, totalAlternativeDateOffered: offers, timezone } = booking;

    const url = `bookings/${bookingId}/alternative-offer`;

    offers === 0
      ? setShowAlternativeOfferModal(true)
      : history.push(url, {
        bookingId,
        timezone,
      });
  };

  useEffect(() => {
    setNumOfFilter(getFilterCount(safeBookingQueries, "asc"));
  }, [safeBookingQueries, getFilterCount]);

  const buildRow = (booking: Booking) => {
    return (
      <BookingItem
        booking={booking}
        onClick={() => onBookingClick(booking, booking.status)}
        handleAlternativeTimes={() => onAlternativeTimePressed(booking)}
        bookingMutate={invalidateUpcomingBookings}
      />
    );
  };

  return (
    <Box
      gap={Spacing.S6}
      marginX={isMobile ? Spacing.S6 : Spacing.S20}
      marginY={Spacing.S11}
      maxWidth={isMobile ? undefined : "936px"}
      direction={FlexDirection.Column}
    >
      {/* Label */}
      <Box flex={1} alignItems={"center"} justifyContent={isMobile ? "flex-end" : "space-between"}>
        {isMobile ? (
          <></>
        ) : (
          <Text
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
            size={FontSize.F24}
            color={Colors.NightBlue}
          >
            Upcoming
          </Text>
        )}
        <FilterButton
          title="Filter and sort"
          onClick={() => setShowFilterAndSortModal(true)}
          filterCount={numOfFilter}
        />
      </Box>

      {isLoading ? <LoadingSpinner /> : <></>}

      {checkIfEmpty(upcomingBookings) && !isLoading ? (
        numOfFilter ? (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <NoFilteredBookings sortOrEditFilter={setShowFilterAndSortModal} />
          </Box>
        ) : (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <NoBookingsPlaceholder
              type={NoBookingsPlaceholderType.upcoming}
              onNewBookingClicked={() => {
                setExitBookingFlowPath(location.pathname);
                history.push(Paths.NewBooking);
              }}
              onPastBookingsClicked={() => {
                history.push(Paths.PastBookings);
              }}
            />
          </Box>
        )
      ) : (
        <Box direction={FlexDirection.Column}>
          <Each of={upcomingBookings} render={buildRow} />
        </Box>
      )}

      <InfoModal
        visible={showAlternativeOfferModal}
        title={"Offers from providers"}
        description={
          "While you're waiting to be connected with a provider, if a provider offers their availability and/or preferred rate, you'll see them here. You'll have the option to accept one of the offers and get your booking confirmed instantly."
        }
        handleClose={() => setShowAlternativeOfferModal(false)}
        divider={false}
      />

      <BookingFilterModal
        open={showFilterAndSortModal}
        onClose={() => setShowFilterAndSortModal(false)}
        forUpcoming
      />
    </Box>
  );
};

export default Upcomming;
