import { ServiceRate, ServiceRatePrice } from "../models";
import { capitalizeFirstLetter } from "./string";

const formatServicesData = (rates: ServiceRate[]) => {
  return rates.map((rate) => {
    return {
      value: rate.id,
      title: capitalizeFirstLetter(rate.alias || rate.name),
      profession: rate.profession || "therapist",
    };
  });
};

const formatTreatmentsData = (treatments: ServiceRatePrice[]) => {
  return treatments.map((treatment: ServiceRatePrice) => {
    return {
      value: treatment.id,
      title: treatment.label,
    };
  });
};

export { formatServicesData, formatTreatmentsData };
