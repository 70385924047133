export const saveDivToPdf = (id: string) => {
  const toPrint = document.getElementById(id);
  const win = window.open(); //open new screen

  if (!win || !toPrint) return;

  win.document.write(toPrint.innerHTML);

  win.document.close();
  win.print();
};
