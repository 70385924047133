import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import Dialog from "../../components/Dialog";
import CloseIcon from "../../images/modal-close.svg";

interface Props {
  open: boolean;
  onClose: () => unknown;
  onSentInstructions: (email: string) => unknown;
  onBackToLogin: () => unknown;
  prefillEmail?: string;
}

export default function ForgotPasswordModal({
  open,
  onClose,
  onSentInstructions,
  onBackToLogin,
  prefillEmail,
}: Props) {
  const isMobile = useMobile();

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullScreen={isMobile}
      onClose={onClose}
    >
      <Box
        bgcolor="white"
        width={isMobile ? "100%" : "500px"}
        borderRadius="11px"
      >
        <Box display="flex" justifyContent="flex-end" mt={1} mr={1}>
          <IconButton onClick={onClose}>
            <img src={CloseIcon} />
          </IconButton>
        </Box>
        <Box ml="40px" mr="40px" mb="32px">
          <ForgotPasswordComponent
            onSentInstructions={onSentInstructions}
            onBackToLogin={onBackToLogin}
            marginTop="12px"
            prefillEmail={prefillEmail}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
