import React from "react";
import { Box } from "@material-ui/core";
import Dropdown from "../Dropdown";
import { findItem } from "../../utils/object";
import { Colors } from "../../constants/colors";
import { useBookingStore } from "../../stores/booking";
import { PAYMENT_SPLIT } from "../../data/corporate.options";

const PaymentSplit = () => {
  const { paymentSplit, setPaymentSplit } = useBookingStore();

  return (
    <Box borderRadius={"8px"} border={`1px solid ${Colors.LightPeriwinkle}`} mb={"24px"}>
      <Box
        fontWeight={600}
        padding={"16px"}
        fontSize={"16px"}
        color={Colors.Dusk}
        lineHeight={"24px"}
        fontFamily={"Museo"}
        style={{
          backgroundColor: Colors.PaleGrey,
          borderRadius: "8px 8px 0 0",
        }}
      >
        Payment split
      </Box>
      <Box padding={"16px"}>
        <Box
          fontSize={"14px"}
          fontWeight={400}
          lineHeight={"21px"}
          fontFamily={"Open Sans"}
          color={Colors.Invoice}
        >
          Any unpaid amount (e.g. for unbooked slots) will be charged to the company 72 hours before
          the booking start time. Credit card is required to save your spot. You won’t be charged
          until booking is confirmed.
        </Box>
        <Box>
          <Dropdown
            options={PAYMENT_SPLIT}
            selectedOption={findItem(PAYMENT_SPLIT,{ key: "value", value: paymentSplit})}
            onSelectedOption={({ value }) => setPaymentSplit(value)}
            paddingTop={"8px"}
            paddingBottom={"0px"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSplit;
