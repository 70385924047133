import Switch from "../Switch";
import { Box, Text } from "../v2/Styled";
import { Colors } from "../../constants/colors";
import { Display, FontFamily, FontSize, FontWeight, Spacing } from "../v2/Styled/enum";

interface Props {
  checked: boolean;
  text?: string;
  onChange: (checked: boolean) => unknown;
  textSize?: FontSize;
  textColor?: Colors;
}

const AutoUpdateSetting = ({
  checked = false,
  onChange = () => {},
  text = "Allow providers to change booking details without my confirmation",
  textSize = FontSize.F18,
  textColor = Colors.NightBlue,
}: Props) => {
  return (
    <Box paddingY={Spacing.S4} display={Display.Block}>
      <Box alignItems="center" paddingY={Spacing.S3}>
        <Switch checked={checked} onChange={(event) => onChange(event.target.checked)} />
        <Box paddingX={Spacing.S2} />
        <Text font={FontFamily.Museo} size={textSize} weight={FontWeight.Bold} color={textColor}>
          {text}
        </Text>
      </Box>
      <Box paddingY={Spacing.S4}>
        <Text
          font={FontFamily.OpenSans}
          size={FontSize.F14}
          weight={FontWeight.Regular}
          color={Colors.Dusk}
        >
          Save time and make your booking updates easier by letting providers make instant changes
          to your booking details without needing your confirmation.
        </Text>
      </Box>
    </Box>
  );
};

export default AutoUpdateSetting;
