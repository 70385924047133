import { Box } from "@material-ui/core";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BOOKING_STATUS, MassageFor, getProvidersTitle } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { Paths } from "../../constants/paths";
import { checkIfJobAccepted } from "../../helpers/booking";
import { parseApiError } from "../../helpers/error";
import { isCorporateSession } from "../../helpers/massage";
import {
  generateContraindicationFromUserPreference,
  generateInstructionFromPreference,
  generatePreferenceFromUserPreference,
  getNotesFromTreatmentDetails,
} from "../../helpers/specialInstruction";
import { useRecipientUpdate } from "../../hooks/recipient/recipient.hooks";
import { useUserPreference } from "../../hooks/userPreference/userPreference.hooks";
import NoteIcon from "../../images/review-note-icon.svg";
import { Booking, Recipient } from "../../models";
import { useAlertStore } from "../../stores/alert";
import {
  checkServiceIsHairAndMakeup,
  getProfessionByServiceId,
  getServiceCategory,
  isHairAndMakeupBooking,
  updateBooking,
  useBookingStore,
  useRecipientDetailsStore,
} from "../../stores/booking";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty, getValue } from "../../utils/object";
import { toJSON } from "../../utils/string";
import RecipientModal from "../Recipients/RecipientModal";
import TextLink from "../TextLink";
import PreferencesModal from "./PreferencesModal";

interface Props {
  booking?: Booking;
  onRecipientUpdated?: (recipient: Recipient) => unknown;
  onChangeClicked?: () => unknown;
  refreshBooking?: () => unknown;
}

function BookingNoteForTherapistItem({
  booking,
  onRecipientUpdated,
  onChangeClicked,
  refreshBooking,
}: Props) {
  const { fetchMe } = useUserStore();

  const {
    numOfRecipients,
    recipient: newBookingRecipient,
    setRecipient,
    serviceId,
    treatmentDetails,
    setTreatmentDetails,
    setSpecialInstructions,
    preferences: userPreference,
    setPreference,
    contraindications,
    setContraindications,
    numberOfPros,
  } = useBookingStore();

  const history = useHistory();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const [profession, setProfession] = useState("therapist");
  const [showRecipientModal, setShowRecipientModal] = React.useState(false);
  const [preferencesModalOpen, setPreferencesModalOpen] = React.useState(false);
  const [instruction, setInstruction] = React.useState("");
  const [preferences, setPreferences] = React.useState<{
    [preference: string]: string;
  }>({});

  const { userPreferences, fetchUserPreferences } = useUserStore();

  const { isLoading: isUpdatingPrefernce, updatePreference } = useUserPreference();
  const { isLoading: isUpdatingRecipient, updateRecipient } = useRecipientUpdate();

  const { setTheirSpecialInstructions, setTheirContraindications } = useRecipientDetailsStore();
  const { massageFor: newBookingMassageFor } = useRecipientDetailsStore();

  const isCorporateType = isCorporateSession(getValue(booking, "sessionType", ""));
  const isAnyJobAccepted = checkIfJobAccepted(booking);

  const isBookingHairAndMakeup = () => {
    const targetServiceId = booking ? booking.serviceId : serviceId;
    return checkServiceIsHairAndMakeup(targetServiceId) || false;
  };

  const _recipient = () => {
    return booking ? booking.recipient : newBookingRecipient;
  };

  const _serviceId = () => {
    return booking ? booking.serviceId : serviceId;
  };

  const recipient = _recipient();
  const _massageFor = () => {
    return booking ? booking.massageFor : newBookingMassageFor;
  };

  const massageFor = _massageFor();

  const formatUserPreferences = (userPreferences: any) => {
    if (userPreferences) {
      const bookingServiceName = getServiceCategory();
      const _preferences: { [preference: string]: string } = {};
      const servicePreferences = userPreferences.find(
        (userPref: any) => getValue(userPref, "service.name") === bookingServiceName
      );
      if (servicePreferences) {
        const parsedPreferences = JSON.parse(servicePreferences.preference);
        Object.keys(parsedPreferences).forEach((preference) => {
          _preferences[preference] = parsedPreferences[preference];
        });
      }
      setPreferences(_preferences);
    }
  };

  useEffect(() => {
    fetchMe();
    if (!userPreferences) {
      fetchUserPreferences();
    } else {
      formatUserPreferences(userPreferences);
    }
  }, [booking, serviceId]);

  useEffect(() => {
    formatUserPreferences(userPreferences);
  }, [userPreferences]);

  useEffect(() => {
    const bookingServiceId = _serviceId();
    if (!bookingServiceId) return;

    const serviceProfession = getProfessionByServiceId(bookingServiceId);
    if (serviceProfession) {
      setProfession(serviceProfession);
    }
  }, [booking, serviceId]);

  const getNotesForTherapist = () => {
    let specialInstruction = "";
    let preference = "";
    let contraindications = "";

    const isHairAndMakeup = isBookingHairAndMakeup();

    if (booking) {
      const instructions = toJSON(getValue(booking, "instructions"));
      specialInstruction = getValue(instructions, "note", "");
      if (isHairAndMakeupBooking()) {
        const treatmentDetails = getValue(booking, "bookingdetails[0].treatmentDetails", []);
        const numOfClient = getValue(booking, "numberOfClients", 1);
        if (numOfClient > 1) {
          specialInstruction = getNotesFromTreatmentDetails(treatmentDetails);
        }
      }
      contraindications = getValue(instructions, "contraindications", "");
      preference = getValue(instructions, "preference", "");
    } else if (isHairAndMakeup) {
      if (recipient) {
        contraindications = recipient?.contraindications || "";
        if (numOfRecipients === 1) {
          specialInstruction = recipient?.specialInstructions
            ? `${recipient.specialInstructions}`
            : "";
        } else {
          specialInstruction = getNotesFromTreatmentDetails(treatmentDetails);
        }
      } else {
        specialInstruction = getNotesFromTreatmentDetails(treatmentDetails);
        contraindications = generateContraindicationFromUserPreference(preferences, serviceId);
      }
    } else if (recipient) {
      specialInstruction = recipient?.specialInstructions ? `${recipient.specialInstructions}` : "";
      contraindications = recipient?.contraindications || "";
    } else {
      specialInstruction = generateInstructionFromPreference(preferences, serviceId);
      preference = generatePreferenceFromUserPreference(preferences, serviceId);
      contraindications = generateContraindicationFromUserPreference(preferences, serviceId);
    }

    return { specialInstruction, preference, contraindications };
  };

  React.useEffect(() => {
    const { specialInstruction, preference, contraindications } = getNotesForTherapist();
    setSpecialInstructions(specialInstruction);
    setPreference(preference);
    setContraindications(contraindications);
    setInstruction(specialInstruction);
  }, [preferences, recipient]);

  const _noValue = () => {
    if (massageFor === MassageFor.myself) {
      return isEmpty(instruction);
    } else if (recipient) {
      return isEmpty(recipient.specialInstructions);
    }
    return true;
  };

  const noValue = _noValue();
  const NotesForTherapist = () => {
    return (
      <>
        <Box whiteSpace="pre-wrap">{instruction}</Box>

        {userPreference ? (
          <Box>
            <Box style={styles.notesTitle}>Preferences</Box>
            {userPreference}
          </Box>
        ) : (
          <></>
        )}
        {contraindications ? (
          <Box>
            <Box style={styles.notesTitle}>Contraindications</Box>
            {contraindications}
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  const updateBookingState = async (
    specialInstructions: string,
    contraindications: string,
    _preferences: string
  ) => {
    setSpecialInstructions(specialInstructions);
    setContraindications(contraindications);
    setPreference(_preferences);
  };

  const onPreferenceSaved = async (userPreferences: any) => {
    setPreferencesModalOpen(false);
    const isHairAndMakeup = isHairAndMakeupBooking();
    fetchMe();
    const { specialInstructions, contraindications, ...preferences } = userPreferences;
    if (booking) {
      const _preferences = generatePreferenceFromUserPreference(preferences, serviceId);
      if (isHairAndMakeup) {
        updateInstructionsForTreatment(specialInstructions);
      }
      updateBookingState(specialInstructions, contraindications, _preferences).then(async () => {
        await updateBookingDataAndUserPreference(userPreferences);
        refreshBooking && refreshBooking();
      });
    } else if (numOfRecipients === 1 && isHairAndMakeup) {
      updateInstructionsForTreatment(specialInstructions);
    }
  };

  const updateInstructionsForTreatment = (specialInstructions: string) => {
    const newDetails = [...treatmentDetails];
    newDetails[0].noteForProvider = specialInstructions;
    setTreatmentDetails(newDetails);
    return;
  };

  const updateRecipeintBookingState = async (instructions: string, contraindications: string) => {
    setTheirSpecialInstructions(instructions);
    setTheirContraindications(contraindications);
  };

  const onRecipientSaved = async (recipient: Recipient) => {
    if (!recipient) return;

    if (booking && recipient) {
      const newInstructions = recipient.specialInstructions;
      const newContrandications = recipient.contraindications;

      updateRecipeintBookingState(newInstructions, newContrandications).then(async () => {
        await updateBookingDataAndRecipient(recipient);
        onRecipientUpdated && onRecipientUpdated(recipient);
      });
    }
    setShowRecipientModal(false);
  };

  const updateBookingDataAndUserPreference = async (userPreferences: any) => {
    if (booking) {
      await updateBooking()
        .then((response: any) => {
          updatePreference(
            {
              preferences: userPreferences,
              serviceId,
            },
            {
              onSuccess: () => {
                fetchUserPreferences();
                setSuccessMessage("Your preferences have been updated");
              },
              onError: () => {
                setErrorMessage("Something went wrong");
              },
            }
          );
          console.debug("Booking Updated after note for therapist change", response);
        })
        .catch((error) => {
          fetchUserPreferences();
          if (error && error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage(parseApiError(error));
          }
        });
    }
  };

  const updateBookingDataAndRecipient = async (recipientData: Recipient) => {
    setRecipient(recipientData);
    if (booking) {
      await updateBooking()
        .then((response: any) => {
          const { id, ...updatedRecipientData } = recipientData;
          updateRecipient(
            {
              id,
              updatedRecipientData,
            },
            {
              onSuccess: (res) => {
                onRecipientUpdated && onRecipientUpdated(res);
                setSuccessMessage("Recipient updated");
              },
              onError: () => setErrorMessage("Something went wros"),
            }
          );
          console.debug("Booking Updated after note for therapist change", response);
        })
        .catch((error) => {
          if (error && error.message) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage(parseApiError(error));
          }
        });
    }
  };

  const checkAllowNoteUpdate = () => {
    if (!checkIfEmpty(onChangeClicked)) return false;

    const allowNoteUpdate =
      !booking ||
      (getValue(booking, "status") === BOOKING_STATUS.NEW && !isCorporateType) ||
      (getValue(booking, "status") === BOOKING_STATUS.NEW && isCorporateType && !isAnyJobAccepted);
    return allowNoteUpdate;
  };

  const handleChange = () => {
    if (!!onChangeClicked) {
      onChangeClicked();
    }

    if (numOfRecipients > 1 && !booking?.isMassage) {
      history.push(Paths.ServiceDetails, {
        next: Paths.ReviewAndBook,
        editing: true,
      });
      return;
    }

    if (massageFor === MassageFor.myself) {
      setPreferencesModalOpen(true);
    } else if (massageFor === MassageFor.someoneElse) {
      setShowRecipientModal(true);
    }
  };

  const allowUpdate = checkAllowNoteUpdate();
  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" flexDirection="row" flex={1} alignItems="center">
            <Box
              width="24px"
              height="24px"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <img src={NoteIcon} alt="icon" />
            </Box>
            <Box
              fontFamily="Museo"
              fontSize="16px"
              color={Colors.Dusk}
              fontWeight={700}
              flex={1}
              textAlign="left"
              ml={2}
              mr={2}
            >
              Note for {getProvidersTitle(numberOfPros, profession)}
            </Box>
            {allowUpdate && (
              <TextLink title={noValue ? "Add" : "Change"} onClick={() => handleChange()} />
            )}
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize="14px"
            color={Colors.BlueyGrey}
            lineHeight="21px"
            mt={0.2}
            ml={5}
            whiteSpace="pre-wrap"
          >
            <NotesForTherapist />
          </Box>
        </Box>
      </Box>
      <RecipientModal
        existingRecipient={recipient}
        open={showRecipientModal}
        onClose={() => setShowRecipientModal(false)}
        onSaved={(recipient) => onRecipientSaved(recipient)}
        immediateUpdate={isNil(booking)}
      />

      <PreferencesModal
        open={preferencesModalOpen}
        onClose={() => setPreferencesModalOpen(false)}
        onSaved={(userPreferences) => onPreferenceSaved(userPreferences)}
        immediateUpdate={isNil(booking)}
      />
    </>
  );
}
const styles = {
  notesTitle: {
    color: Colors.Dusk,
    marginTop: "8px",
    fontWeight: 600,
  },
};
export default BookingNoteForTherapistItem;
