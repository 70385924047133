import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  activeValue: string | number;
  value: string | number;
}

function TabPanel({ children, value, activeValue, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== activeValue}
      id={`full-width-tabpanel-${activeValue}`}
      aria-labelledby={`full-width-tab-${activeValue}`}
      {...other}
    >
      {value === activeValue && children}
    </div>
  );
}
export default TabPanel;
