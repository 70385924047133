import { Box } from "@material-ui/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TextLink from "../../../components/TextLink";
import TreatmentDescription from "../../../components/Treatment/TreatmentDescription/TreatmentDescription";
import {
  BOOKING_STATUS,
  DEFAULT_CURRENCY,
  JobStatus,
  UpdateRequestStatus,
  UpdateRequestedBy,
} from "../../../constants/booking";
import { Colors } from "../../../constants/colors";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import { DEFAULT_BOOKING_DATETIME_FORMAT } from "../../../constants/date";
import { QUESTION_TYPE } from "../../../constants/questionType";
import { CORPORATE_BOOKING_KEYS } from "../../../data/corporate.options";
import { BOOKING_SESSION_TYPE } from "../../../helpers/booking";
import { isCorporateSession } from "../../../helpers/massage";
import DateTimeIcon from "../../../images/review-calendar-time.svg";
import TreatmentIcon from "../../../images/review-item-massage.svg";
import { ServiceRatePrice } from "../../../models";
import { getRangedBookingFormattedDateTime } from "../../../services/bookings/bookingTime.service";
import { RootState } from "../../../stores/V2";
import { transformCorporateForDescription } from "../../../stores/V2/booking/booking.transformer";
import { useAlertStore } from "../../../stores/alert";
import {
  getAddOnDetailById,
  getTreatmentDetailByTreatmentId,
  useServicesStore,
} from "../../../stores/booking";
import { formatDateWithTimezone } from "../../../utils/date.util";
import { checkIfEmpty, getValue } from "../../../utils/object";
import { FontFamily, FontSize, FontWeight } from "../../../components/v2/Styled/enum";
import TextButton from "../../../components/TextButton/TextButton";

const TreatmentDetailSection = ({
  job,
  booking,
  sessionType,
  corporateInfo,
  treatmentDetails,
}: any) => {
  const history = useHistory();
  const { rates } = useServicesStore();

  const state = useSelector(({ booking }: RootState) => booking);
  const { proAddOns } = state;

  const [showTreatmentInfoModel, setShowTreatmentInfoModel] = useState(false);
  const [showAddOnInfoModel, setShowAddOnInfoModel] = useState(false);
  const [treatmentForDetails, setTreatmentForDetails] = useState<ServiceRatePrice>();

  const bookingUpdates = getValue(booking, "bookingUpdates");
  const updateRequest = bookingUpdates?.find(
    (update: any) => update.status === UpdateRequestStatus.pending
  );
  const isPendingUpdate = updateRequest?.requestedBy === UpdateRequestedBy.pro;
  const { setErrorMessage } = useAlertStore();

  const bookingStatus = getValue(booking, "status");
  const jobStatus = getValue(job, "status");

  const hasBackupDate = !!booking?.backup;

  const isSessionCorporate = isCorporateSession(sessionType);
  const showCorporateInfo = isSessionCorporate && !checkIfEmpty(corporateInfo);

  const getCorporateDetails = () => {
    if (checkIfEmpty(corporateInfo)) return [];

    return transformCorporateForDescription({
      numberOfPerson: corporateInfo.numberOfPerson,
      durationPerPerson: corporateInfo.durationPerPerson,
      totalEventDuration: {
        massageDuration: corporateInfo.totalEventDuration,
        hasPaidBreak: corporateInfo.hasPaidBreak,
      },
      numberOfPros: corporateInfo.numberOfPros,
      eventType: corporateInfo.eventType,
      eventName: corporateInfo.eventName,
      noteForRecipients: corporateInfo.noteForRecipients,
      paymentSplit: corporateInfo.paymentSplit,
    });
  };

  const getFormattedDateTime = ({ booking }: any) => {
    if (!booking) return;

    const { earliestTime, latestTime, timezone } = booking;
    const { datetime } = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });
    return datetime;
  };

  const getFormattedBackupDate = ({ booking }: any) => {
    if (!booking || !booking?.backup) return "";
    const { timezone } = booking;
    const { earliestTime, latestTime } = booking?.backup;

    const { datetime } = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });
    return datetime;
  };

  const getFormattedDateTimeForConfirmedBooking = ({ booking }: any) => {
    if (!booking) return;

    const { timezone, timeOfArrival } = booking;
    const datetime = formatDateWithTimezone(
      timeOfArrival,
      timezone,
      DEFAULT_BOOKING_DATETIME_FORMAT
    );
    return datetime;
  };

  const getGroupedAnswers = ({ answers }: any) => {
    const imageQuestions: any = [];
    const otherQuestions: any = [];
    if (!answers || checkIfEmpty(answers)) {
      return { image: imageQuestions, other: otherQuestions };
    }

    answers.forEach((answer: any) => {
      if (answer.type === QUESTION_TYPE.IMAGE) imageQuestions.push(answer);
      else otherQuestions.push(answer);
    });
    return { image: imageQuestions, other: otherQuestions };
  };

  const openImage = (imageUrl: string) => window.open(imageUrl);
  const details = showCorporateInfo ? getCorporateDetails() : [];

  const showPayoutInfo = !getValue(job, "approxServiceFee.isFixed") ?? false;

  const handleTreatmentChange = () => {
    return history.push(`/pro/boooking-add-on/${job.id}`);
    // return isPendingUpdate
    //   ? setErrorMessage(PENDING_UPDATE_REQUEST_MESSAGE_PRO)
    //   : history.push(`/pro/boooking-add-on/${job.id}`);
  };

  const getCurrencyForBooking = () => {
    if (checkIfEmpty(booking)) return;
    const selectedService = rates.find(({ id }) => id === booking.serviceId);

    const currency =
      getValue(selectedService, "selectedCountry.currencySymbol") ||
      DEFAULT_CURRENCY.currencySymbol;

    return currency;
  };

  const getTreatmentLabel = ({
    treatmentLabel,
    serviceFee = 0,
  }: {
    treatmentLabel: any;
    serviceFee: number;
  }) => {
    if (!treatmentLabel) return "";

    const currency = getCurrencyForBooking();

    let label = treatmentLabel;
    if (serviceFee) label += ` (${currency}${serviceFee.toFixed(2)})`;
    return label;
  };

  const getAddOnLabel = (addOn: any) => {
    const { name, label, serviceFee, price, rate } = addOn;
    const addOnPrice = serviceFee || price || rate || 0;
    const addOnName = label || name || "";

    const currency = getCurrencyForBooking();

    return `${addOnName} (${currency}${parseInt(addOnPrice).toFixed(2)})`;
  };

  const getFormattedAnswers = (questions: any) => {
    const formattedData = (questions || []).map((question: any) => {
      const answerValue = getValue(question, "answer.answer") || null;
      const answerOptions = question?.answerDetails;

      const selectedAns = answerOptions?.find(
        ({ value }: { value: string }) => value === answerValue
      );
      const payload: any = {
        question: question?.question,
        answer: selectedAns?.title,
        addedPrice: selectedAns?.addedPrice,
        addedDuration: selectedAns?.addedDuration,
        treatmentId: question?.treatmentId,
        serviceId: question?.serviceId,
        type: question?.type,
        addOn: question?.isAddOns,
        attrs: question?.attrs,
      };
      return payload;
    });

    return formattedData;
  };

  const handleTreatmentClick = (td: any) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    const treatment = getValue(td, "treatment") || getTreatmentDetailByTreatmentId(treatmentId);
    setTreatmentForDetails(treatment);
    setShowTreatmentInfoModel(true);
  };

  const hasProFullDescription = ({ td, addOnId }: { td: any; addOnId: any }) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    let addOn = getAddOnDetailById({ treatmentId, id: addOnId });
    if (checkIfEmpty(addOn)) {
      addOn = proAddOns.find((data: any) => data.id === addOnId) || {};
    }

    if (!getValue(addOn, "proFullDescription")) return false;
    return true;
  };

  const handleAddOnClick = ({ td, addOnId }: { td: any; addOnId: any }) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    let addOn = getAddOnDetailById({ treatmentId, id: addOnId });
    if (checkIfEmpty(addOn)) {
      addOn = proAddOns.find((data: any) => data.id === addOnId) || {};
    }

    if (getValue(addOn, "proFullDescription")) {
      setTreatmentForDetails(addOn);
      setShowAddOnInfoModel(true);
    }
  };

  const isTreatmentChangeAllowed = () => {
    if (
      sessionType === BOOKING_SESSION_TYPE.COUPLES ||
      sessionType === BOOKING_SESSION_TYPE.CORPORATE
    ) {
      return false;
    }

    if (bookingStatus === BOOKING_STATUS.NEW && jobStatus !== JobStatus.rebooking) {
      return false;
    }

    if (jobStatus === JobStatus.cancelled) return false;

    if (jobStatus === JobStatus.finished) return true;

    return true;
  };

  const allowTreatmentChange = isTreatmentChangeAllowed();

  if (!treatmentDetails || checkIfEmpty(treatmentDetails)) return <></>;

  return (
    <Box style={{ ...styles.flex, flexDirection: "column" }}>
      <Box style={{ ...styles.flex, flexDirection: "row" }}>
        <Box style={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
          {treatmentDetails.map((detail: any, index: number) => {
            if (isSessionCorporate && index > 0) return; // Only show Person 1 for Corporate bookings; handling for pro dashboard
            const {
              treatmentDuration,
              bookingAnswers: answers,
              questions,
              serviceDuration,
              serviceFee,
              title,
            } = detail;

            const { image: imageAnswers, other: otherAnswers } = getGroupedAnswers({
              answers: answers,
            });

            const addOnForThisTreatment = detail?.addons || [];

            const bookingAnswers = getFormattedAnswers(questions);
            const hasAnswers = bookingAnswers?.length > 0;

            return (
              <Box>
                <Box display="flex" alignItems="flex-start" gridGap={16}>
                  <img src={TreatmentIcon} alt="Treatment icon" />
                  <Box flex={1}>
                    <Box display="flex" justifyContent="space-between">
                      <Box style={styles.person}>Person {index + 1}</Box>
                      {allowTreatmentChange && (
                        <Box>
                          <TextButton
                            text="Change"
                            type="primary"
                            onClick={handleTreatmentChange}
                            textStyle={{ marginTop: "2px", fontSize: FontSize.F16, fontWeight: FontWeight.Bold }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box
                      mr={8}
                      style={{
                        ...styles.treatmentLabel,
                        textDecoration: "underline",
                        cursor: "pointer",
                        overflowWrap: "break-word",
                      }}
                      onClick={() => handleTreatmentClick(detail)}
                    >
                      {getTreatmentLabel({ treatmentLabel: title, serviceFee })}
                    </Box>

                    {!showCorporateInfo && (treatmentDuration || serviceDuration) && (
                      <Box style={styles.detail}>{serviceDuration || treatmentDuration} mins</Box>
                    )}

                    {addOnForThisTreatment?.map((data: any) => {
                      const hasDescription = hasProFullDescription({
                        td: detail,
                        addOnId: data?.id,
                      });
                      return (
                        <Box key={data?.id} mt={1} mb={1}>
                          <Box>
                            <Box
                              style={{
                                ...styles.treatmentLabel,
                                textDecoration: hasDescription ? "underline" : "none",
                                cursor: hasDescription ? "pointer" : "default",
                              }}
                              onClick={() => handleAddOnClick({ td: detail, addOnId: data?.id })}
                            >
                              Add-on: {getAddOnLabel(data)}
                            </Box>
                            <Box style={styles.detail}>{data?.duration} min</Box>
                          </Box>
                        </Box>
                      );
                    })}

                    {hasAnswers ? (
                      <Box mt={1} mb={1}>
                        {bookingAnswers.map(({ answer, question }: any) => (
                          <Box style={styles.detail}>
                            {question}: {answer}
                          </Box>
                        ))}

                        {imageAnswers.map(({ answer, question }: any) => (
                          <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                            <Box style={styles.detail}>{question}</Box>
                            <Box style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                              {(answer || []).map(({ filePath, id }: any) => (
                                <Box
                                  style={{
                                    ...styles.imageWrapper,
                                    ...styles.clickable,
                                    backgroundImage: `url("${BASE_UPLOADS_URL}/${filePath}")`,
                                  }}
                                  onClick={() => openImage(`${BASE_UPLOADS_URL}/${filePath}`)}
                                ></Box>
                              ))}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <></>
                    )}

                    {showCorporateInfo && details.length > 0 ? (
                      <Box>
                        {details.map((item) => {
                          if (!item.value && item.key === CORPORATE_BOOKING_KEYS.eventName)
                            return <></>;
                          if (!item.value && item.key === CORPORATE_BOOKING_KEYS.noteForRecipients)
                            return <></>;
                          return (
                            <Box key={item.key} lineHeight={"21px"} style={styles.detail}>{`${
                              item.label
                            } ${item.value ? item.value : "-"}`}</Box>
                          );
                        })}
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
          {/* <Box style={styles.treatmentLabel}>{getJobSessionTypeText(sessionType)}</Box> */}
        </Box>
      </Box>
      {showPayoutInfo ? (
        <Box>
          <span style={styles.infoText}>
            Final payout may vary depending on your chosen date and time.
          </span>
        </Box>
      ) : null}

      <TreatmentDescription
        isOpen={showTreatmentInfoModel}
        onClose={() => setShowTreatmentInfoModel(false)}
        title={treatmentForDetails?.label || treatmentForDetails?.name || ""}
        description={treatmentForDetails?.fullDescription || ""}
      />

      <TreatmentDescription
        isOpen={showAddOnInfoModel}
        onClose={() => setShowAddOnInfoModel(false)}
        title={treatmentForDetails?.label || treatmentForDetails?.name || ""}
        description={getValue(treatmentForDetails, "proFullDescription", "")}
      />
    </Box>
  );
};

const styles = {
  flex: {
    display: "flex",
    gap: "16px",
  },
  person: {
    lineHeight: "24px",
    color: Colors.Indigo,
    fontFamily: FontFamily.Museo,
    fontSize: FontSize.F16,
    fontWeight: FontWeight.Bold,
  },
  treatmentLabel: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
  detail: {
    lineHeight: "21px",
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  detailText: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
  imageWrapper: {
    height: "75px",
    width: "75px",
    borderRadius: 4,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  clickable: {
    cursor: "pointer",
  },
  label: {
    fontFamily: "Museo",
    fontWeight: 400,
    fontSize: "16px",
    color: Colors.Grey,
  },
  infoText: {
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
};

export default TreatmentDetailSection;
