import React from "react";
import { Dialog as MUIDialog, DialogProps } from "@material-ui/core";
import { useMobile } from "../hooks/mobile";

export default function (props: DialogProps) {
  const isMobile = useMobile();

  return (
    <MUIDialog
      PaperProps={{
        style: {
          borderRadius: isMobile ? undefined : "8px",
          ...props.style,
        },
      }}
      {...props}
    />
  );
}
