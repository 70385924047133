import { deleteApi, get, post, put } from "../../api/client";

const createTreatmentNote = (data: any) => {
  const url = "api/v2/users/treatmentnotes";
  return post(url, data);
};

const updateTreatmentNote = (data: any) => {
  const { id: noteId, ...body } = data;
  const url = `api/v2/users/treatmentnotes/${noteId}`;
  return put(url, body);
};

const uploadTreatmentNoteFile = (data: any) => {
  const url = "api/v2/users/treatmentnotes/file";
  return post(url, data);
};

const fetchTreatmentNotes = (jobId: any, page: number, pageSize: number = 10) => {
  const url = `api/v2/users/treatmentnotes/job/${jobId}`;
  return get(url, { page, pageSize });
};

const fetchNotesFromOtherPros = (jobId: any, page: number, pageSize: number = 10) => {
  const url = `api/v2/users/treatmentnotes/job/others/${jobId}`;
  return get(url, { page, pageSize });
};

const removeTreatmentNote = (id: number) => {
  const url = `api/v2/users/treatmentnotes/${id}`;
  return deleteApi(url);
};

export {
  fetchTreatmentNotes,
  createTreatmentNote,
  updateTreatmentNote,
  removeTreatmentNote,
  uploadTreatmentNoteFile,
  fetchNotesFromOtherPros,
};
