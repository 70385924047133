import React, { useEffect, useState } from "react";
import Switch from "../../../../components/Switch";
import { Box, Typography } from "@material-ui/core";
import { getProfile, updateProfile } from "./apiCall";
import { Colors } from "../../../../constants/colors";

interface CalendarConfigInterface {
  isIgnoreAvailabilities: boolean;
}

const CalendarConfig = ({isIgnoreAvailabilities}: CalendarConfigInterface) => {
  const [allowCalendarBooking, setAllowCalendarBooking] = useState<boolean>(false);
  const [ignoreAvailabilities, setIgnoreAvailabilities] = useState<boolean>(false);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    const profile = await getProfile();
    setAllowCalendarBooking(profile?.therapistprofile?.allowCalendarBooking);
    setIgnoreAvailabilities(profile?.therapistprofile?.ignoreAvailabilities);
  };

  const handleAllowCalendarBookingChange = async () => {
    const data = {
      allowCalendarBooking: !allowCalendarBooking,
    };

    const response = await updateProfile(data);

    if (response) {
      setAllowCalendarBooking(data.allowCalendarBooking);
    }
  };

  const handleIgnoreAvailabilityChange = async () => {
    const data = {
      ignoreAvailabilities: !ignoreAvailabilities,
    };

    const response = await updateProfile(data);

    if (response) {
      setIgnoreAvailabilities(data.ignoreAvailabilities);
    }
  };

  return (
    <Box mb="40px">
      <Box color={Colors.Dusk}>
        { !isIgnoreAvailabilities && 
        <Box display="flex" alignItems="center" mb="8px">
          <Switch
            checked={allowCalendarBooking}
            onChange={handleAllowCalendarBookingChange}
          />
           <Box
              fontFamily="Museo"
              fontSize="1rem"
              fontWeight={400}
              color={Colors.Dusk}
              marginLeft="8px"
            >
            Allow clients to request bookings via calendar
          </Box>
        </Box>
        }
        {
          isIgnoreAvailabilities &&
          <Box display="flex" alignItems="center" mb="8px">
            <Switch
              checked={ignoreAvailabilities}
              onChange={handleIgnoreAvailabilityChange}
            />
            <Box
              fontFamily="Museo"
              fontSize="1rem"
              fontWeight={400}
              color={Colors.Dusk}
              marginLeft="8px"
            >
              Ignore availabilities and send me all booking requests
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default CalendarConfig;
