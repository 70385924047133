import { getCountryDetails } from "../hooks/address/addressOptions.hooks";
import MapApiService from "../services/map/MapApiService";
import { getCountryCode } from "../utils/country";

export interface GooglePlaceSearchResult {
  formatted_address: string;
  place_id: string;
}

// Auto complete countries Australia & New Zealand
// Adding Nepal & Indonesia for staging environment

export function getSearchResults(
  query: string,
  countryRestriction = [],
): Promise<Array<GooglePlaceSearchResult>> {
  return new Promise((resolve, reject) => {
    MapApiService.getAutoComplete()
      .then((autoCompleteService) => {
        if (!query) return resolve([]);

        const sessionToken = new google.maps.places.AutocompleteSessionToken();

        autoCompleteService
          .getPlacePredictions(
            {
              input: query,
              sessionToken,
              componentRestrictions: {
                country: countryRestriction,
              },
              // locationBias: "ipbias",
            },
            (results, status) => {
              if (status === "OK" && results) {
                resolve(
                  results.map((result) => {
                    return {
                      formatted_address: result.description,
                      place_id: result.place_id,
                    };
                  }),
                );
              }
            },
          )
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  });
}

export async function placeDetails(placeId: string): Promise<google.maps.places.PlaceResult> {
  return new Promise((resolve, reject) => {
    MapApiService.getPlaces()
      .then((placesService) => {
        placesService.getDetails(
          {
            placeId: placeId,
            fields: ["name", "address_component", "type", "geometry", "formatted_address"],
          },
          (result, status) => {
            if (status === "OK") {
              resolve(result as google.maps.places.PlaceResult);
            }
          },
        );
      })
      .catch((error) => console.log(error));
  });
}

export async function normalisedPlaceId(placeId: string): Promise<string | undefined> {
  return await new Promise((resolve, reject) => {
    MapApiService.getPlaces().then((placesService) => {
      placesService.getDetails(
        {
          placeId: placeId,
          fields: ["place_id"],
        },
        (result, status) => {
          if (status === "OK") {
            resolve((result as google.maps.places.PlaceResult).place_id);
          } else {
            reject("Invalid Google Place ID");
          }
        },
      );
    });
  });
}

export async function Geocode(
  lat: number,
  long: number,
): Promise<Array<google.maps.GeocoderResult>> {
  return await new Promise(async (resolve, reject) => {
    const geocoder = await MapApiService.getGeocoder();

    geocoder
      .geocode(
        {
          location: new google.maps.LatLng(lat, long),
        },
        (results, status) => {
          console.debug("results: ", results);
          console.debug("status: ", status);
          if (status === "OK") {
            resolve(results as Array<google.maps.GeocoderResult>);
          } else {
            reject("Invalid request");
          }
        },
      )
      .catch((error) => console.log(error));
  });
}

export async function GeocodeAddress(address: string): Promise<Array<google.maps.GeocoderResult>> {
  return await new Promise(async (resolve, reject) => {
    const geocoder = await MapApiService.getGeocoder();

    geocoder.geocode(
      {
        address,
      },
      (results, status) => {
        console.debug("results: ", results);
        console.debug("status: ", status);
        if (status === "OK") {
          resolve(results as Array<google.maps.GeocoderResult>);
        } else {
          reject("Invalid request");
        }
      },
    );
  });
}

export function CityForGeocoderResult(result: google.maps.GeocoderResult) {
  return result.address_components.find((component) => {
    return component.types.includes("locality");
  })?.long_name;
}

export const extractLocationData = async (result: GooglePlaceSearchResult) => {
  try {
    const placeId = await normalisedPlaceId(result.place_id);
    if (placeId) {
      const details = await placeDetails(placeId);

      const streetNumber =
        details.address_components?.find((component) => component.types.includes("street_number"))
          ?.long_name || "";
      const street =
        details.address_components?.find((component) => component.types.includes("route"))
          ?.long_name || "";
      const suburb =
        details.address_components?.find((component) => component.types.includes("locality"))
          ?.long_name ||
        details.address_components?.find((component) => component.types.includes("postal_town"))
          ?.long_name || // For Europe addresses
        details.address_components?.find((component) => component.types.includes("sublocality"))
          ?.long_name ||
        "";

      const state =
        details.address_components?.find((component) =>
          component.types.includes("administrative_area_level_1"),
        )?.long_name || "";

      const postcode =
        details.address_components?.find((component) => component.types.includes("postal_code"))
          ?.long_name || "";

      const countryDetails = details.address_components?.find((component) =>
        component.types.includes("country"),
      );
      const country = countryDetails?.long_name || "";

      const countryCode = countryDetails?.short_name || "";
      let address = `${streetNumber} ${street}`;

      if (details.types?.includes("street_address") === false) {
        if (details.formatted_address) {
          address = `${details.formatted_address.split(",")[0]}`;
        } else {
          address = `${details.name}, ${address}`;
        }
      }

      const route = details.address_components?.find((component) =>
        component.types.includes("route"),
      );
      const long_name = route?.long_name || "";
      const short_name = route?.short_name || "";

      let fullAddress = details.formatted_address;

      if (
        !details.formatted_address?.includes(long_name) &&
        !details.formatted_address?.includes(short_name)
      ) {
        fullAddress = `${long_name}, ${fullAddress}`;
      } else if (
        details.name &&
        !details.formatted_address?.includes(details.name) &&
        ![long_name, short_name].includes(details.name)
      ) {
        fullAddress = `${details.name}, ${fullAddress}`;
      }

      const latitude = details.geometry?.location && details.geometry?.location.lat();
      const longitude = details.geometry?.location && details.geometry?.location.lng();

      return {
        fullAddress,
        country,
        countryCode,
        postcode,
        state,
        suburb,
        latitude,
        longitude,
        address,
        lat: latitude,
        long: longitude,
        streetNumber,
        street
      };
    }
  } catch (error) {}
};

export const fetchCountryData = async () => {
  const countryCode = await getCountryCode();
  const countryDetails = await getCountryDetails(countryCode);
  return {
    lat: countryDetails?.data?.latitude,
    lng: countryDetails?.data?.longitude,
    locationEnabled: false,
  };
};

export const getCurrentLocation = async () => {
  if ("geolocation" in navigator) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position),
          (error) => reject(error),
        );
      });

      // @ts-ignore
      const { latitude, longitude } = position.coords;
      return { lat: latitude, lng: longitude, locationEnabled: true };
    } catch (error) {
      return await fetchCountryData();
    }
  } else {
    return await fetchCountryData();
  }
};
