const DATA_LAYER = {
  ORDER_COMPLETED: "orderCompleted",
  VOUCHER_PURCHASE_COMPLETED: "voucherPurchaseCompleted"
};

const VOUCHER_PURCHASE_REVENUE_WEIGHTAGE = 0.17;

export {
  DATA_LAYER,
  VOUCHER_PURCHASE_REVENUE_WEIGHTAGE,
};
