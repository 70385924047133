import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import Wrapper from "../../../components/Wrapper";
import { useAgreeLinkDocument } from "../../../hooks/userTerms.hook";
import { parseApiV2Error } from "../../../helpers/error";
import { useAlertStore } from "../../../stores/alert";

interface RouteParams {
  id: string;
}

const AgreeLinkDocument = () => {
  
  const history = useHistory();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const params = useParams<RouteParams>();
  const { id: agreementId } = params;

  const onSuccess = (resp: any) => {
    const { success, jobId } = resp;
    if (success) {
      history.replace(`/pro/bookings/${jobId}`);
      setSuccessMessage("Document successfully signed");
    }
  };

  const onError = (error: any) => {
    const errorMessage = parseApiV2Error(error);
    setErrorMessage(errorMessage);
    history.replace("/pro/bookings");
  };

  const { isLoading, mutate } = useAgreeLinkDocument({ onSuccess, onError });

  useEffect(() => {
    mutate({ agreementId });
  }, []);

  return <Wrapper>
    {isLoading ? <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" flex={1}>
      <CircularProgress />
    </Box> : <Box></Box>}
  </Wrapper>;
};

export default AgreeLinkDocument;
