import { Paths } from "./paths";

export const sections = [
  { label: "Profile", value: "profile" },
  { label: "Services", value: "services" },
  { label: "Business", value: "business" },
  { label: "Reviews", value: "reviews" },
  { label: "Bookings", value: "bookings" },
  { label: "Reports", value: "reports" },
  { label: "Service area", value: "serviceArea" },
  { label: "Availability", value: "availability" },
  { label: "Settings", value: "settings" },
  { label: "Earn ", value: "referPro"}
];

export const pathForSection = (section: string) => {
  switch (section) {
  case "Profile":
    return Paths.ProDashboardProfile;
  case "Services":
    return Paths.ProDashboardServices;
  case "Business":
    return Paths.ProDashboardBusiness;
  case "Reviews":
    return Paths.ProDashboardReviews;
  case "Bookings":
    return Paths.ProDashboardBookings;
  case "Reports":
    return Paths.ProDashboardReports;
  case "Service area":
    return Paths.ProDashboardServiceAreas;
  case "Availability":
    return Paths.ProDashboardAvailability;
  case "Settings":
    return Paths.ProDashboardSettings;
  }
};

export const pathForSectionAtIndex = (index: number, sections: any[]) => {
  return pathForSection(sections[index].label);
};

export const ProDashboardHeaderSavedScrollPositionKey = "pro-dashboard-header-scroll-left";

const ProFileOwnership = {
  portfolio: "portfolio",
  ReferenceImage: "reference_image",
  HNMClientImage: "hnm_client_image",
};

export { ProFileOwnership };

export const COMMS_DELIVERY = {
  EMAIL: "EMAIL",
  TEXT: "TEXT",
  PUSH: "PUSH",
};

export const COMMS_TYPE = {
  NEW_BOOKING: "NEW_BOOKING",
  CONFIRMED_BOOKING: "CONFIRMED_BOOKING",
};

export const COMMS_FREQUENCY = {
  INSTANTLY: "INSTANTLY",
  DAILY_DIGEST: "DAILY_DIGEST",
};

export const ABANDON_REASON = {
  ACCEPTED_BY_MISTAKE: "ACCEPTED_BY_MISTAKE",
  CHANGED_MY_MIND: "CHANGED_MY_MIND",
  CLIENT_NO_SHOW: "CLIENT_NO_SHOW",
  CLIENT_PAYMENT_STILL_PENDING: "CLIENT_PAYMENT_STILL_PENDING",
  PARTNER_PRO_NOT_CONFIRMED: "PARTNER_PRO_NOT_CONFIRMED",
  CLIENT_DECIDED_TO_CANCEL: "CLIENT_DECIDED_TO_CANCEL",
  CLIENT_HAS_CONTRAINDICATION: "CLIENT_HAS_CONTRAINDICATION",
  OTHER: "OTHER",
};

export const abandonReasonOptions = [
  { title: "Accepted by mistake", value: ABANDON_REASON.ACCEPTED_BY_MISTAKE },
  { title: "Changed my mind", value: ABANDON_REASON.CHANGED_MY_MIND },
  { title: "Client no show", value: ABANDON_REASON.CLIENT_NO_SHOW },
  {
    title: "Client payment still pending",
    value: ABANDON_REASON.CLIENT_PAYMENT_STILL_PENDING,
  },
  {
    title: "Partner pro not confirmed",
    value: ABANDON_REASON.PARTNER_PRO_NOT_CONFIRMED,
  },
  {
    title: "Client decided to cancel",
    value: ABANDON_REASON.CLIENT_DECIDED_TO_CANCEL,
  },
  {
    title: "Client has contraindication",
    value: ABANDON_REASON.CLIENT_HAS_CONTRAINDICATION,
  },
  { title: "Other", value: ABANDON_REASON.OTHER },
];

interface MyObject {
  [key: string]: string;
}

export const ABANDON_REASON_DESCRIPTION: MyObject = {
  CLIENT_NO_SHOW:
    "Clients are given 20min grace period for no-shows. Please wait until 12:35pm for your client to show up.",
  CLIENT_PAYMENT_STILL_PENDING: "Payment is already settled by the client.",
  PARTNER_PRO_NOT_CONFIRMED: "The booking is already confirmed, please choose another reason.",
};

export const GRACE_TIME_FOR_CLIENT = { VALUE: 20, UNIT: "minutes" };

export const BOOKINGS_SECTION_VALUES = {
  NEW: "new",
  UPCOMING: "upcoming",
  PAST: "past",
  ABANDONED: "abandoned",
};

export const TOGGLE_VALUES = ["List", "Map"];

export const PRO_TIER_LINK = "https://help.getblys.com.au/support/solutions/articles/33000249310-how-do-tiered-accounts-work";