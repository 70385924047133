import { post } from "../../../api/client";

const requestRecurringDataUpdate = (
  { bookingId, data = {} }:
  { bookingId: number | string, data: any }
) =>
  post(`api/v2/bookings/recurring/${bookingId}/request-update`, data);

export {
  requestRecurringDataUpdate,
};