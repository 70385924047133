import React from "react";
import Header from "../../components/Public/Header";
import { Box, BoxProps } from "@material-ui/core";

interface Props extends BoxProps {}

export default function Wrapper({ children, ...props }: Props) {
  return (
    <Box>
      <Header />
      <Box {...props}>{children}</Box>
    </Box>
  );
}
