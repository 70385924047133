import React from "react";
import { EXTRA_PRO_PAYOUT_STATUS, PRO_PAYOUT_STATUS } from "../../../data/enums";
import { getValue } from "../../../utils/object";
import { TIMELINE_ICON_TYPE } from "../../Timeline/Timeline";
import { Box, Typography } from "@material-ui/core";
import TextLink from "../../TextLink";
import { Colors } from "../../../constants/colors";
import { FontFamily, FontWeight } from "../../v2/Styled/enum";

interface ContentItem {
  text: string;
  link?: string;
  newLine?: boolean;
}

interface Props {
  contents: ContentItem[];
}
const statusIconMap = {
  [PRO_PAYOUT_STATUS.PAYOUT_FAILED]: TIMELINE_ICON_TYPE.ERROR,
  [EXTRA_PRO_PAYOUT_STATUS.EXTRA_PAYOUT_FAILED]: TIMELINE_ICON_TYPE.ERROR,
  [PRO_PAYOUT_STATUS.PAYOUT_COMPLETED]: TIMELINE_ICON_TYPE.SUCCESS,
  [EXTRA_PRO_PAYOUT_STATUS.EXTRA_PAYOUT_COMPLETED]: TIMELINE_ICON_TYPE.SUCCESS,
};

export const compileDescriptionForPayoutTimeline = ({ contents = [] }: Props) => {
  let output: JSX.Element[] = [];
  let currentText: JSX.Element[] = [];

  const buildRow = (text: string, link = ""): JSX.Element => {
    return link ? (
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{
          color: "#41506F",
        }}
      >
        {text}
      </a>
    ) : (
      <span>{text}</span>
    );
  };

  for (let i = 0; i < contents.length; i++) {
    const currentContent = contents[i];
    const text = currentContent.text || "";
    const link = currentContent.link || "";

    const isNextContentNewLine = getValue(contents[i + 1], "newLine", false);

    currentText.push(buildRow(text, link));

    if (isNextContentNewLine || i === contents.length - 1) {
      output.push(
        <div key={`row-${i}`} style={{ width: "100%" }}>
          {currentText}
          {isNextContentNewLine && <br />}
        </div>
      );
      currentText = [];
    } else {
      currentText.push(<span key={`space-${i}`}> </span>); // Add space between elements
    }
  }

  return <>{output}</>;
};

export const tranformPayoutTimeline = ({ proPayouts }: any) => {
  const transformedData = (proPayouts || []).map((proPayout: any) => {
    const status = getValue(proPayout, "status");

    let iconType = statusIconMap[status];
    return {
      label: getValue(proPayout, "label"),
      isInProcess:
        getValue(proPayout, "isActive", false) && getValue(proPayout, "isInProcess", false),
      isFutureStatus: getValue(proPayout, "isFutureStatus", false),
      isCompleted:
        !getValue(proPayout, "isFutureStatus", false) && !getValue(proPayout, "isActive", false),
      description: compileDescriptionForPayoutTimeline({
        contents: getValue(proPayout, "content", []),
      }),
      status: getValue(proPayout, "status"),
      iconType,
    };
  });
  return transformedData;
};
