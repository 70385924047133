import { useUserStore } from "../stores/user";
import { getValue } from "../utils/object";

export const useUserRole = () => {
  const { user } = useUserStore();

  const isAdmin = !!getValue(user, "adminAccess");
  const isB2BUser = !!getValue(user, "role.isB2BUser");
  const isHomeCareClient = !!(
    getValue(user, "role.isHomeCareProvider") ||
    getValue(user, "role.isNdisSupportCoordinator") ||
    getValue(user, "role.isNdisSupportRecipient") ||
    getValue(user, "role.isGuardianOrCarer")
  );
 
  const isSupportRecipient = !!(
    getValue(user,"role.isAgedCareSupportRecipient") ||
    getValue(user,"role.isDisabilitySupportRecipient")
  );

  return { isAdmin, isHomeCareClient, isB2BUser,isSupportRecipient };
};
