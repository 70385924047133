import { Box, ButtonBase, Typography } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../../../constants/colors";
import DropdownArrow from "../../../../../images/dropdown-arrow.svg";

interface Props {
  placeholder: string;
  value?: string;
  disabled?: boolean;
  onClick: () => unknown;
}

const DropdownValueHolder = ({ placeholder, value, onClick, disabled }: Props) => {
  
  const handleClick = () => {
    if (disabled || !onClick) return;

    if (!!onClick) onClick();
  };
  
  const getColor = () => {
    if (disabled) return Colors.LightBlueGrey;
    return value ? Colors.TurquoiseBlue : Colors.LightBlueGrey;
  };

  const color = getColor();

  return (
    <ButtonBase style={{ width: "100%" }} onClick={handleClick}>
      <Box display="flex" flexDirection="row" mt={1} mb={1} flex={1}>
        <Typography
          style={{
            color,
            // height: "32px",
            lineHeight: "32px",
            textAlign: "left",
            marginBottom: "0px",
            width: "100%",
            // overflow: "hidden",
          }}
        >
          {value || placeholder}
        </Typography>
        <img src={DropdownArrow} alt="Dropdown Arrow" />
      </Box>
    </ButtonBase>
  );
};

export default React.memo(DropdownValueHolder);
