import { Box } from "@material-ui/core";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../v2/Styled/enum";
import { ToggleSection } from "../ToggleSection/ToggleSection";
import { Colors } from "../../constants/colors";
interface Props {
  onUnpaidToggled: (isUnpaidToggled: boolean) => unknown;
  unPaidToggle: boolean;
}
export const PaymentStatusFilter = ({ unPaidToggle, onUnpaidToggled }: Props) => {
  return (
    <Box mb={Spacing.S2}>
      <Box
        fontWeight={FontWeight.Bold}
        fontSize={FontSize.F18}
        lineHeight={"27px"}
        fontFamily={FontFamily.Museo}
        color={Colors.Dusk}
      >
        Payment status
      </Box>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} mt={Spacing.S4}>
        <Box>
          <ToggleSection
            title="Unpaid only"
            subTitle="Show bookings that are pending payment"
            onToggle={(isToggled: boolean) => {
              onUnpaidToggled(isToggled);
            }}
            isToggled={unPaidToggle}
          />
        </Box>
      </Box>
    </Box>
  );
};
