import { getValue } from "../../../utils/object";
import { convertToMoment } from "../../../utils/date.util";
import { DEFAULT_DATE_FORMAT } from "../../../constants/date";
import {
  TAddress,
  TPersonalDetails,
  TAccountForPayout,
  TIdentityVerification,
  GOVERNMENT_DOCUMENT_TYPE_LABEL,
} from "../../../pages/ProDashboard/Business/Payouts/Forms/model";

const transformPersonalDetails = (data: TPersonalDetails) => [
  {
    label: "Name",
    value: `${data.firstName} ${data.lastName}`,
  },
  {
    label: "Email",
    value: data.email,
  },
  {
    label: "Mobile number",
    value: data.mobile,
  },
  {
    label: "Date of birth",
    value: (() => {
      let date = getValue(data, "dob");
      if (date instanceof Date) {
        date = date.toString();
      }
      return date ? convertToMoment(date).format(DEFAULT_DATE_FORMAT) : "-";
    })(),
  },
];

const transformAddress = (address: TAddress) => {
  const street = getValue(address, "line1") || "-";
  const city = getValue(address, "city") || "-";
  const state = getValue(address, "state") || "";
  const postalCode = getValue(address, "postalCode") || "-";
  const country = getValue(address, "country") || "-";

  return `${street}, ${city}, ${state} ${postalCode}, ${country}`;
};

const transformIdentityVerification = (document: TIdentityVerification) => [
  {
    label: "Document Id",
    value: document.documentNumber,
    type: "text",
  },
  {
    label: "Document type",
    value: GOVERNMENT_DOCUMENT_TYPE_LABEL[document.documentType as string],
    type: "text",
  },
  {
    label: "Expiry date",
    value: (() => {
      let date = getValue(document, "expiryDate");
      if (date instanceof Date) {
        date = date.toString();
      }
      return date ? convertToMoment(date).format(DEFAULT_DATE_FORMAT) : "";
    })(),
    type: "text",
  },
  {
    label: "",
    value: document.documentPath,
    type: "file",
  },
];

const transformPayoutAccount = (account: TAccountForPayout) => account;

export {
  transformPersonalDetails,
  transformAddress,
  transformIdentityVerification,
  transformPayoutAccount,
};
