import { Box, Link } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import HeroImage from "../../images/no-bookings-placeholder.svg";

interface Props {
  sortOrEditFilter: (val: boolean) => unknown;
}

export const NoFilteredBookings = ({ sortOrEditFilter }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src={HeroImage} alt="not found" />
      <Box
        fontFamily="Museo"
        fontSize="20px"
        textAlign="center"
        fontWeight={700}
        color={Colors.Dusk}
        mt="19px"
      >
        No bookings found
      </Box>

      <Box
        fontFamily="Open Sans"
        fontSize="14px"
        color={Colors.Dusk}
        letterSpacing="0.39px"
        textAlign="center"
        mt="10px"
      >
        Try changing your filter and sort or   <Link
          style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }}
          onClick={() => sortOrEditFilter(true)}
        >
          clear all filter and sort.
        </Link>
      </Box>
    </Box>
  );
};
