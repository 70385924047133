import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { isNil } from "lodash";

import { getValue } from "../../../../utils/object";
import { Colors } from "../../../../constants/colors";
import InfoModal from "../../../../components/Modals/InfoModal/InfoModal";
import { BOOKING_STATUS, PRICE_BREAKDOWN } from "../../../../constants/booking";
import { formatToPrice } from "../../../../helpers/number";
import { useUserStore } from "../../../../stores/user";
import CurrencyConversionInfo from "../../../../components/CurrencyConversionInfo";

export enum BookingSummaryItemType {
  massage,
  peakHourSurcharge,
  discount,
  coupon,
  credit,
  cancellationFee,
  publicHolidaySurcharge,
  clientPlatformFee,
  parkingAndTravel,
}

interface Props {
  type: BookingSummaryItemType;
  booking: any;
}

export default function BookingDetailsSummaryItem({ type, booking }: Props): JSX.Element {
  const { bookingPrice, platformFeeData, peakHourInfo, parkingAndTravelInfo } = booking;
  const [infoModal, setInfoModal] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
      data: [],
    },
  });

  const bookingIsCancelled = (booking: any) => {
    return booking.status === BOOKING_STATUS.CANCELLED;
  };
  const bookingCurrency = getValue(booking, "selectedService.selectedCountry.currencySymbol", "A$");
  const bookingCurrencyCode = getValue(booking, "selectedService.selectedCountry.currency", "AUD");

  const originalCouponAmount = getValue(booking, "coupon.originalAmount");
  const originalCouponCurrency = getValue(booking, "coupon.originalCurrency");
  const originalCouponSymbol = getValue(booking, "coupon.originalCouponCurrencySymbol");
  const couponType = getValue(booking, "coupon.type");

  const cancelled = bookingIsCancelled(booking);

  const { currencyCode: userCurrencyCode } = useUserStore();

  const clickableSummaryItems = [
    BookingSummaryItemType.clientPlatformFee,
    BookingSummaryItemType.peakHourSurcharge,
    BookingSummaryItemType.parkingAndTravel,
  ];

  const enabled = () => {
    switch (type) {
    case BookingSummaryItemType.massage:
      return true;
    case BookingSummaryItemType.parkingAndTravel:
      const parkingAndTravelSurcharge =
        (bookingPrice?.addressSurcharge ?? 0) + (bookingPrice?.hotelSurcharge ?? 0);
      return parkingAndTravelSurcharge > 0;
    case BookingSummaryItemType.peakHourSurcharge:
      return (
        ((bookingPrice?.lateNightSurcharge ?? 0) || (bookingPrice?.publicHolidaySurcharge ?? 0)) >
          0
      );
    case BookingSummaryItemType.discount:
      return false;
    case BookingSummaryItemType.coupon:
      return !isNil(booking.coupon);
    case BookingSummaryItemType.credit:
      return bookingPrice.credit ?? 0 > 0;
    case BookingSummaryItemType.cancellationFee:
      return cancelled;
    case BookingSummaryItemType.publicHolidaySurcharge:
      return false;
    case BookingSummaryItemType.clientPlatformFee:
      return bookingPrice.clientPlatformFee ?? 0 > 0;
    }
  };

  const title = () => {
    switch (type) {
    case BookingSummaryItemType.massage:
      return PRICE_BREAKDOWN.BASERATE;
    case BookingSummaryItemType.parkingAndTravel:
      return PRICE_BREAKDOWN.PARKINGTRAVEL;
    case BookingSummaryItemType.peakHourSurcharge:
      return PRICE_BREAKDOWN.PEAK_PERIOD;
    case BookingSummaryItemType.discount:
      return "Discount";
    case BookingSummaryItemType.coupon:
      return `Coupon (${booking.coupon.code})`;
    case BookingSummaryItemType.credit:
      return PRICE_BREAKDOWN.CREDIT;
    case BookingSummaryItemType.cancellationFee:
      return "Cancellation fee";
    case BookingSummaryItemType.publicHolidaySurcharge:
      return PRICE_BREAKDOWN.PUBLIC_HOLIDAY;
    case BookingSummaryItemType.clientPlatformFee:
      return getValue(booking, "platformFeeData.title", "Platform Fee");
    }
  };

  const value = () => {
    let price = 0;
    switch (type) {
    case BookingSummaryItemType.massage:
      price = cancelled ? price : bookingPrice.originalPrice - bookingPrice.addressSurcharge || 0;
      return formatToPrice(price, bookingCurrency);
    case BookingSummaryItemType.parkingAndTravel:
      const parkingAndTravelSurcharge =
        (bookingPrice?.addressSurcharge ?? 0) + (bookingPrice?.hotelSurcharge ?? 0);
      price = cancelled ? price : parkingAndTravelSurcharge;
      return formatToPrice(price, bookingCurrency);
    case BookingSummaryItemType.peakHourSurcharge:
      price = cancelled
        ? price
        : bookingPrice.lateNightSurcharge + bookingPrice.publicHolidaySurcharge;
      return formatToPrice(price, bookingCurrency);
    case BookingSummaryItemType.discount:
      return formatToPrice(0, bookingCurrency); // TODO
    case BookingSummaryItemType.coupon:
      if (booking.coupon.type === "percent") {
        const couponPrice =
            (bookingPrice.priceWithoutDiscount - bookingPrice.clientPlatformFee) *
            (booking.coupon.value / 100);
        return `-${formatToPrice(couponPrice, bookingCurrency)}`;
      }

      if (booking.coupon.type === "referral") {
        return `-${formatToPrice(booking.coupon.value, bookingCurrency)}`;
      }

      if (booking.coupon.type === "value") {
        return `-${formatToPrice(booking.coupon.value, bookingCurrency)}`;
      }

      return null;

    case BookingSummaryItemType.credit:
      return formatToPrice(bookingPrice.credit, bookingCurrency);
    case BookingSummaryItemType.cancellationFee:
      return formatToPrice(booking.cancellationFee, bookingCurrency);

    case BookingSummaryItemType.publicHolidaySurcharge:
      return `${formatToPrice(bookingPrice.publicHolidaySurcharge, bookingCurrency)}`;
    case BookingSummaryItemType.clientPlatformFee:
      return `${formatToPrice(bookingPrice.clientPlatformFee, bookingCurrency)}`;
    }
  };

  const isClickable = () => clickableSummaryItems.includes(type);

  const handleInfoIconClicked = (type: BookingSummaryItemType) => {
    const modalContent = {
      title: "",
      description: "",
      data: [],
    };
    switch (type) {
    case BookingSummaryItemType.clientPlatformFee:
      modalContent.title = getValue(platformFeeData, "title", "");
      modalContent.description = getValue(platformFeeData, "remarks", "");
      break;
    case BookingSummaryItemType.peakHourSurcharge:
      modalContent.title = getValue(peakHourInfo, "title", "");
      modalContent.description = getValue(peakHourInfo, "subtitle", "");
      modalContent.data = getValue(peakHourInfo, "data", []);
      break;
    case BookingSummaryItemType.parkingAndTravel:
      modalContent.title = getValue(parkingAndTravelInfo, "title", "");
      modalContent.description = getValue(parkingAndTravelInfo, "subtitle", "");
      modalContent.data = getValue(parkingAndTravelInfo, "data", []);
      break;
    }
    setInfoModal({
      visible: true,
      content: modalContent,
    });
  };

  const handleInfoDismiss = () => {
    setInfoModal({ ...infoModal, visible: false });
  };

  return enabled() ? (
    <Box mt={2} mb={2}>
      <Box display="flex" flexDirection="row">
        <Box
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
          onClick={() => handleInfoIconClicked(type)}
        >
          <Typography style={isClickable() ? styles.clickable : {}}>{title()}</Typography>
        </Box>
        <Box flex={1}>
          <Box
            fontFamily="Museo"
            fontSize="14px"
            color={Colors.Dusk}
            textAlign="right"
            // flex={1}
          >
            {value()}
          </Box>
        </Box>
      </Box>
      {type === BookingSummaryItemType.coupon && couponType === "value" ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={originalCouponCurrency}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(originalCouponAmount, originalCouponSymbol)}
        />
      ) : (
        <></>
      )}
      {type === BookingSummaryItemType.credit ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={userCurrencyCode}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(bookingPrice.creditBeforeConversion)}
        />
      ) : (
        <></>
      )}
      {isClickable() && (
        <InfoModal
          visible={infoModal.visible}
          title={infoModal.content.title}
          description={infoModal.content.description}
          data={infoModal.content.data}
          divider={false}
          handleClose={handleInfoDismiss}
        />
      )}
    </Box>
  ) : (
    <></>
  );
}

const styles = {
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};
