import React, { useEffect } from "react";
import { Box } from "@material-ui/core";

import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { Address, Recipient } from "../../../models";
import { fetchLocationTypes } from "../../../stores/types";
import AddressItem from "../../../components/Addresses/AddressItem";
import { useGetRecipientAddress } from "../../../hooks/recipient/recipient.hooks";
import { getValue } from "../../../utils/object";

interface RecipientAddressSectionProps {
  recipient: Recipient;
}
const RecipientAddressSection = ({ recipient }: RecipientAddressSectionProps) => {
  const isMobile = useMobile();

  const { data, isLoading } = useGetRecipientAddress(recipient.id, {});

  const addresses = getValue(data, "data") || [];

  useEffect(() => {
    fetchLocationTypes();
  }, []);

  return (
    <Box
      marginTop="43px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <Box
        fontFamily="Museo"
        fontWeight={700}
        fontSize="24px"
        color={Colors.NightBlue}
        mb="24px"
      >
        Manage {recipient.firstName}'s saved addresses
      </Box>

      <Box
        style={{
          backgroundColor: Colors.White,
          borderRadius: "6px",
          border: `solid 1px ${Colors.VeryLightPink}`,
        }}
        maxWidth={isMobile ? "100%" : "935px"}
        mt={isMobile ? 2 : undefined}
      >
        <Box ml="24px" mr="24px">
          {!isLoading &&
            addresses.map((address: Address) => (
              <AddressItem
                address={address}
                key={address?.id}
              />
            ))
          }
        </Box>
      </Box>


    </Box>
  );
};

export default RecipientAddressSection;