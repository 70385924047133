import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import LangugeDropdownItem from "./LangugeDropdownItem";
import { useEffect, useRef, useState } from "react";
import LanguageDropwnValueHolder from "./LanguageDropwnValueHolder";
import { LanguageSpokenInterface } from "../../../pages/ProDashboard/Profile";

interface Props {
  languageSpoken: LanguageSpokenInterface[];
  handleSpokenLanguageChange: (data: LanguageSpokenInterface) => unknown;
  handleLanguageRemove: (lang: string) => unknown;
  removeAllSelectedLanguage: () => unknown;
  slectedLanguage: any[];
}
const LanguageSpoken = ({
  languageSpoken,
  slectedLanguage,
  handleSpokenLanguageChange,
  handleLanguageRemove,
  removeAllSelectedLanguage,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  // Since we cannot access current state values inside event listeners
  // we use ref to go around this issue.

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  return (
    <>
      <div ref={containerRef} style={{ width: "100%" }}>
        <Box
          position={"relative"}
          width="100%"
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
        >
          <Box paddingY="21px">
            <Box fontWeight={700}>Languages spoken</Box>
            <LanguageDropwnValueHolder
              placeholder={"Select"}
              value={slectedLanguage}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              onLanguageRemove={handleLanguageRemove}
              removeAllSelectedLanguage={removeAllSelectedLanguage}
            />
            <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
          </Box>

          <Box
            className="treatment-dropdown"
            style={{
              display: dropdownVisible ? "block" : "none",
              // marginTop: dropBelow ? "-8px" : "-560px",
            }}
          >
            {languageSpoken?.map((language: any) => (
              <LangugeDropdownItem
                key={language.title}
                selected={
                  slectedLanguage?.find(
                    (lang) => lang.toLowerCase() === language.value.toLowerCase(),
                  )
                    ? true
                    : false
                }
                language={language}
                onSelect={handleSpokenLanguageChange}
              />
            ))}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default LanguageSpoken;
