import { Box } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../../constants/colors";
import { UserService } from "../../../../models";
import TextLink from "../../../TextLink";

interface Props {
  userService: UserService;
  onRemove: (userServiceId: number) => unknown;
}

const CustomServiceItem = ({ userService, onRemove }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mt="8px"
    >
      <Box fontFamily="Museo" fontSize="16px" color={Colors.Dusk} mr="24px">
        {userService.service.alias}
      </Box>
      <TextLink
        title="Remove"
        destructive
        onClick={() => onRemove(userService.service?.id)}
      />
    </Box>
  );
};

export default CustomServiceItem;
