const ServiceAreaReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_SERVICE_AREA":
      return {
        ...state,
        serviceArea: action.payload,
      };
    case "SET_RADIUS":
      return {
        ...state,
        radius: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: true,
        message: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        error: false,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default ServiceAreaReducer;
