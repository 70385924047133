import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, DialogContent, DialogTitle as MUIDialogTitle, IconButton } from "@material-ui/core";
import Dialog from "../../../Dialog";
import AddGovernmentID from "./AddGovernmentId";
import * as Styled from "../../../v2/Styled/enum";
import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
import CloseIcon from "../../../../images/modal-close.svg";

const useStyles = makeStyles({
  dialogTitle: {
    top: 0,
    zIndex: 1,
    position: "sticky",
    backgroundColor: Colors.White,
    borderRadius: Styled.Spacing.S1,
  },
  dialogTitleWrapper: {
    display: Styled.Display.Flex,
    position: Styled.Position.Relative,
    flexDirection: Styled.FlexDirection.Row,
    paddingTop: Styled.Spacing.S2,
  },
  dialogTitleIcon: {
    position: Styled.Position.Absolute,
    right: "-20px",
    top: Styled.Spacing.S0,
  },
});

interface Props {
  open: boolean;
  onClose: () => unknown;
  onSuccess: () => unknown;
}

const AddGovernmentIDModal = ({ open, onClose, onSuccess }: Props): JSX.Element => {
  const isMobile = useMobile();
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={isMobile}
      PaperProps={{ style: { overflowY: "visible" } }}
    >
      <MUIDialogTitle className={classes.dialogTitle}>
        <Box className={classes.dialogTitleWrapper}>
          <Box
            color={Colors.NightBlue}
            fontSize={Styled.FontSize.F20}
            fontWeight={Styled.FontWeight.Bold}
          >
            Add document
          </Box>
          <Box className={classes.dialogTitleIcon}>
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="Close" />
            </IconButton>
          </Box>
        </Box>
      </MUIDialogTitle>
      <DialogContent style={{ overflowY: "visible", padding: 0 }}>
        <Box width={isMobile ? "100%" : "400px"}>
          <AddGovernmentID onDocumentAddSuccess={onSuccess} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddGovernmentIDModal;
