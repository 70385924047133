import { useMutation, useQuery } from "@tanstack/react-query";
import { addAddOns, getAddOnsForPro } from "../../services/proDashboard/addon.service";
import queryClient from "../../config/queryClient";

const KEYS = {
  PRO_ADD_ONS: "PRO_ADD_ONS",
};

export const useSaveAddOn = ({ onSuccess, onError }: any) =>
  useMutation((data: any) => addAddOns(data), {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries({ queryKey: [KEYS.PRO_ADD_ONS] });
    },
    onError: () => {
      onError();
    },
  });

export const useGetProAddOns = () =>
  useQuery([KEYS.PRO_ADD_ONS], () => getAddOnsForPro(), {
    select: (response) => {
      return response.addons || [];
    },
  });
