import { useMutation, useQuery } from "@tanstack/react-query";

import { get, post } from "../../api/client";
import { QUERY_KEYS } from "../../constants/queryKey";
import { IComplaint } from "../../interfaces/complaint.interfaces";

interface option {
  label?: string;
  value?: string;
}

const getComplaintOptionForPro = async () => {
  const response = await get("api/v2/complaints/options?for=pro");
  const types = response.types.map((option: option) => {
    return {
      title: option.label,
      value: option.value
    };
  });

  const reasons = response.reasons.map((option: option) => {
    return {
      title: option.label,
      value: option.value
    };
  });

  return { reasons, types };
};

export const useComplaintOptions = () =>
  useQuery([QUERY_KEYS.COMPLAINT_OPTIONS], () => get("api/v2/complaints/options?for=client"));

export const useComplaintOptionsForPro = () =>
  useQuery([QUERY_KEYS.COMPLAINT_OPTIONS_PRO], getComplaintOptionForPro);

export const useCreateComplaint = () =>
  useMutation(
    (data: IComplaint) => {
      return post("api/v2/complaints", data);
    }
  );