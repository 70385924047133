const styles = {
  textStyle: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Museo",
    display: "inline",
    padding: "8px 0px",
    "&:hover": {
      cursor: "pointer",
    }
  }
};

export default styles;