import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import { RecipientDataInterface } from "../../pages/BlysTeam/Settings";
import queryClient from "../../config/queryClient";

const KEYS = {
  RECIPIENT: "RECIPIENT",
  MANAGER: "MANAGER",
  HOME_CARE_PROVIDER: "HOME_CARE_PROVIDER",
  INVITATION_ROLE: "INVITATION_ROLE",
  CONTACT_PERSON: "CONTACT_PERSON",
};

interface UpdateRecipientInterface {
  id: number;
  data: RecipientDataInterface;
}

export const useGetAllContactPerson = () =>
  useQuery(
    [KEYS.CONTACT_PERSON],
    () => httpClient.get("api/v2/recipient/list").then((res) => res.data),
    {
      refetchOnWindowFocus: true,
      staleTime: 1000,
    }
  );

export const useGetAllInvitationRoles = () =>
  useQuery(
    [KEYS.INVITATION_ROLE],
    () => httpClient.get("api/v2/invite/roles?invitedBy=recipient"),
    {
      refetchOnWindowFocus: true,
    }
  );

export const useUpdateRecipientContactPerson = ({ onSuccess, onError }: any) =>
  useMutation(
    ({ id, data }: UpdateRecipientInterface) =>
      httpClient.put(`api/v2/recipient/${id}/contact-person`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [KEYS.CONTACT_PERSON] });
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        onError();
      },
    }
  );

export const useRemoveRecipientContactPerson = ({ onSuccess, onError }: any) =>
  useMutation((id: number) => httpClient.deleteApi(`api/v2/recipient/${id}/contact-person`), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEYS.CONTACT_PERSON] });
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
