import React from "react";
import { Box } from "@material-ui/core";
import FilterIcon from "./../../images/filter-icon.svg";
import FilterIconActive from "./../../images/filter-icon-active.svg";

import { Colors } from "../../constants/colors";
import { Spacing } from "../v2/Styled/enum";

interface Props {
  title?: string;
  onClick: () => unknown;
  filterCount: number;
  font?: string;
}
export const FilterButton = ({ title, onClick, filterCount, font }: Props) => {
  const isActive = filterCount > 0;
  return (
    <Box
      style={{
        border: `1px solid ${isActive ? "#41506F" : "#DCDFE6"} `,
        backgroundColor: Colors.White,
        gap: Spacing.S3,
        cursor: "pointer",
      }}
      borderRadius={"24px"}
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      position="relative"
      padding={"8px 18px 8px 16px"}
      onClick={onClick}
    >
      <Box>
        <img src={isActive ? FilterIconActive : FilterIcon} alt="filter-icon" />
      </Box>
      <Box
        fontWeight={500}
        fontSize={"14px"}
        color={isActive ? "#41506F" : Colors.Grey}
        fontFamily="Museo"
        lineHeight="21px"
      >
        {title ? title : "Filter"}
      </Box>
      {filterCount ? (
        <Box
          position="absolute"
          top="-10px"
          right={"-8px"}
          width={"14px"}
          height={"14px"}
          style={{ borderRadius: "50%", backgroundColor: `${Colors.Danger}` }}
          color={Colors.White}
          textAlign={"center"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={Spacing.S1}
        >
          {filterCount}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};
