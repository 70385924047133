import React, { useState } from "react";
import Dialog from "../Dialog";
import { Box, IconButton } from "@material-ui/core";
import MfaLoginComponent from "../../pages/Login/MfaLoginComponent";
import CloseIcon from "../../images/modal-close.svg";
import { useLocation } from "react-router-dom";
import { getValue } from "../../utils/object";
import { useAlertStore } from "../../stores/alert";
import { useRemoveMfa, useSendTwoFAVerificationCode } from "../../hooks/settings/twoFA.hooks";
import firebaseApp from "../../services/firebaseApp";
import { getFutureTimeStamp } from "../../utils/date.util";
import { VERIFICATION_CODE_RESEND_TIMEOUT } from "../../constants/time";
import { parseApiError } from "../../helpers/error";

interface Props {
  open: boolean;
  isMobile: boolean;
  onClose: () => unknown;
  onSuccess: () => unknown;
}

const RemoveMfaConfirmationModal: React.FC<Props> = ({ open, onClose, isMobile, onSuccess }) => {
  const location = useLocation();

  const userId = getValue(location, "state.userId");
  const mfaType = getValue(location, "state.mfaType");
  const mobileNumber = getValue(location, "state.mobile");

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const [waitTime, setWaitTime] = useState("");
  const [isActionDisabled, setIsActionDisabled] = useState(true);

  const { mutateAsync: handleMfaRemove, isLoading: mfaRemoveLoading } = useRemoveMfa();
  const { mutateAsync: resendVerficationCode } = useSendTwoFAVerificationCode();

  const onCodeSubmit = async (code: string) => {
    try {
      await handleMfaRemove({ code: code, userId });
      onSuccess();
      setSuccessMessage("2 Factor authentication removed.");
    } catch (error) {
      setErrorMessage("Please make sure you have entered the correct verification code");
    }
  };

  const onResend = async () => {
    try {
      const fbToken = await firebaseApp.getToken();
      const response = await resendVerficationCode({ mobileNumber, fbToken });
      if (response) {
        setWaitTime(getFutureTimeStamp({ seconds: VERIFICATION_CODE_RESEND_TIMEOUT }).format());
        setIsActionDisabled(true);
        setSuccessMessage("Verification code sent successfully.");
      }
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };
  return (
    <div>
      <Dialog open={open} maxWidth={false} fullScreen={isMobile} onClose={onClose}>
        <Box bgcolor="white" width={isMobile ? "100%" : "500px"} borderRadius="11px">
          <Box display="flex" justifyContent="flex-end" mt={1} mr={1}>
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="Close" />
            </IconButton>
          </Box>
          <Box ml="40px" mr="40px" mb="32px">
            <MfaLoginComponent
              onCodeSubmit={onCodeSubmit}
              mfaLoginLoading={mfaRemoveLoading}
              onResend={onResend}
              mfaType={mfaType}
              isActionDisabled={isActionDisabled}
              waitTime={waitTime}
              setIsActionDisabled={setIsActionDisabled}
              marginTop={"20px"}
              titleFontSize={"30px"}
              subTextFontSize="16px"
            />
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default RemoveMfaConfirmationModal;
