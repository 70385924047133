import { useQuery } from "@tanstack/react-query";

import { get } from "../../api/client";
import { QUERY_KEYS } from "../../constants/queryKey";

export const useGetRebookings = ({ currentPage, perPage }: any) =>
  useQuery([QUERY_KEYS.REBOOKINGS], () => get("api/v2/therapists/bookings/rebookings", {
    currentPage,
    perPage,
  }));
