import React, { useState } from "react";
import { Box } from "../v2/Styled";
import { Display, FlexDirection, Position, Spacing } from "../v2/Styled/enum";
import { DateSelectorField } from "../DateSelectorField/DateSelectorField";
import { formatDate, getDateObject } from "../../utils/date.util";

interface BookingDateFilterInterface {
  startingDate: Date | string | null;
  endingDate: Date | string | null;
  handleDateSelected: (field: string, value: Date | string | null) => unknown;
}

const BookingDateFilter = ({
  startingDate,
  endingDate,
  handleDateSelected,
}: BookingDateFilterInterface) => {

  const [forceSelectToCalendar, setForceSelectToCalendar] = useState(false);
  const [actualFromDate, setActualFromDate] = useState<Date | null>(null);

  return (
    <Box
      display={Display.Flex}
      flexDirection={FlexDirection.Row}
      gap={Spacing.S4}
      style={{
        width: "100%",
      }}
      position={Position.Relative}
    >
      <DateSelectorField
        title="From"
        value={
          startingDate
            ? formatDate({ date: startingDate, format: "D MMM YYYY" })
            : ""
        }
        placeHolder="Select date"
        onDateSelected={(selectedDate: Date) => {
          setForceSelectToCalendar(true);
          handleDateSelected("startDate", selectedDate);
          setActualFromDate(selectedDate);
        }}
        wrapperStyle={{
          position: Position.Static,
        }}
      />
      <DateSelectorField
        title="To"
        fromDate={actualFromDate}
        forceSelect={forceSelectToCalendar}
        value={
          endingDate
            ? formatDate({ date: endingDate, format: "D MMM YYYY" })
            : ""
        }
        placeHolder="Select date"
        onDateSelected={(selectedDate: Date) => {
          setForceSelectToCalendar(false);
          handleDateSelected("endDate", selectedDate);
        }}
        wrapperStyle={{
          position: Position.Static,
        }}
        minValidDate={
          startingDate ? getDateObject(startingDate) : null
        }
      />
    </Box>
  );

};

export default BookingDateFilter;