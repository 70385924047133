import { useMutation, useQuery } from "@tanstack/react-query";
import { get, deleteApi } from "../api/client";
import { transformPagination } from "../helpers/pagination";

export const RECIPIENT_KEYS = {
  recipients: "recipients",
};

export const useGetAllRecipients = ({ currentPage }: { currentPage: number }) => {
  const params = {
    page: currentPage,
    paginate: true,
  };

  return useQuery(
    [RECIPIENT_KEYS.recipients, { ...params }],
    () => get("users/me/recipients", params),
    {
      select: (res) => transformPagination(res || {}),
    },
  );
};

export const useDeleteRecipient = () => {
  const { isLoading, mutateAsync } = useMutation(async (id: number) => {
    return deleteApi(`users/me/recipients/${id}`);
  });

  return { isLoading, deleteRecipient: mutateAsync };
};
