import { Box, LinearProgress } from "@material-ui/core";
import { useState } from "react";

import TextLink from "../../../../components/TextLink";
import { parseApiError } from "../../../../helpers/error";
import { useInvoiceLinks } from "../../../../hooks/booking/invoice.hooks";
import { Booking, InvoiceLink as IInvoiceLink } from "../../../../models";
import { getInvoiceLinks } from "../../../../services/bookings/invoice.service";
import { useAlertStore } from "../../../../stores/alert";

interface InvoiceLinksProps {
  booking: Booking;
  style?: object;
}

interface InvoiceLinkProps extends IInvoiceLink {}

const InvoiceLink = ({ title, link }: InvoiceLinkProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { setErrorMessage } = useAlertStore();

  const downloadInvoice = () => {
    setLoading(true);
    getInvoiceLinks(link)
      .then((invoices) => {
        (invoices || []).forEach((invoiceLink: string): void => {
          if (invoiceLink) {
            window.open(invoiceLink, "_blank");
          }
        });
      })
      .catch((error) => setErrorMessage(parseApiError(error)))
      .finally(() => setLoading(false));
  };

  return loading ? (
    <LinearProgress style={{ width: "30%" }} />
  ) : (
    <TextLink
      style={{ cursor: "pointer" }}
      title={title}
      fontSize="14px"
      onClick={downloadInvoice}
    />
  );
};

const InvoiceLinks = ({ booking, style }: InvoiceLinksProps) => {
  const { invoiceLinks } = useInvoiceLinks(booking.id);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      width="100%"
      style={{ ...style }}
    >
      {invoiceLinks.map((link, index) => (
        <>
          {index > 0 && <span style={{ color: "#DCDFE6", padding: "0 15px 0 15px" }}> | </span>}
          <InvoiceLink key={link.title} {...link} />
        </>
      ))}
    </Box>
  );
};

export default InvoiceLinks;
