import React, { useState } from "react";
import Dialog from "../../../../components/Dialog";
import { Box } from "@material-ui/core";
import { useMobile } from "../../../../hooks/mobile";
import Button from "../../../../components/Button";
import { ButtonType, ButtonSize } from "../../../../components/Button";

const CalendarInfoModal = ({
  show,
  close,
  bookingInfo,
}: {
  show: boolean;
  close: () => unknown;
  bookingInfo: { bookingHour: number; bufferStart: number; bufferEnd: number };
}) => {
  const isMobile = useMobile();

  const isHour = (hour: number) => (hour < 2 ? "hr" : "hrs");
  const isMinute = (minute: number) => (minute < 2 ? "min" : "mins");
  const bookingHour = bookingInfo?.bookingHour || 0;
  const bufferStart = bookingInfo?.bufferStart || 0;
  const bufferEnd = bookingInfo?.bufferEnd || 0;

  return (
    <Dialog open={show} maxWidth={false} onClose={() => close()}>
      <Box bgcolor="white" width={isMobile ? "100%" : "448px"} borderRadius="8px">
        <Box paddingX={"40px"} mb={"32px"} pt={"40px"} position={"relative"}>
          <Box fontSize="22px" fontFamily="Museo" fontWeight={600} textAlign="center">
            Booking time info
          </Box>
          <Box
            fontFamily="Open Sans"
            textAlign="center"
            fontSize="16px"
            lineHeight="16px"
            color="#8B96AD"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            gridGap={14}
            mt={2}
          >
            <Box>
              Buffer before booking: {bufferStart}
              {isMinute(bufferStart)}
            </Box>
            <Box>
              Booking time: {parseFloat(bookingHour.toFixed(2))}
              {isHour(bookingHour)}
            </Box>
            <Box>
              Buffer after booking: {bufferEnd}
              {isMinute(bufferEnd)}
            </Box>
          </Box>

          <Box mt={3}>
            <Button
              type={ButtonType.outlined}
              size={ButtonSize.large}
              title="Ok"
              onClick={() => close()}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CalendarInfoModal;
