import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box } from "@material-ui/core";
import Legal from "./Business/Legal";
import Payouts from "./Business/Payouts/Payouts";
import Identity from "./Business/Identity";
import Wrapper from "../../components/Wrapper";
import { useMobile } from "../../hooks/mobile";
import { useUserStore } from "../../stores/user";
import Information from "./Business/Information";
import Qualifications from "./Business/Qualifications";
import config from "../../config/proDashboard/dashboardConfig.json";
import ContentSectionControl from "../../components/ContentSectionControl";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import { getLegalConfig } from "../../services/proDashboard/dashboardConfig.service";
import { toCamelCase } from "../../utils/string";

const sections = ["Information", "Identity", "Qualifications", "Payouts", "Legal"];
const sectionName = ["information", "identity", "qualifications", "payouts", "legal"];

const SECTIONS = sections.reduce(
  (sections, section, idx) => ({
    ...sections,
    [toCamelCase(section)]: {
      index: idx,
      name: toCamelCase(section),
      label: section,
    },
  }),
  {} as { [key: string]: any }
);

export default function Business() {
  const isMobile = useMobile();
  const { search } = useLocation();
  const history = useHistory();

  const DEFAULT_SECTION = SECTIONS["information"];

  const [selectedTab, setSelectedTab] = useState(DEFAULT_SECTION);
  const params = new URLSearchParams(search);
  const tab = params.get("tab");

  useEffect(() => {
    const selectedSection = SECTIONS[tab as string] || DEFAULT_SECTION;
    setSelectedTab(selectedSection);
  }, [tab]);

  const { user } = useUserStore();

  const getProConfig = (config: any, country = "AU") => {
    // defaults to AU if country code is not found
    const countryConfig = config[country] || config["AU"];
    return countryConfig.proDashboard;
  };

  const { business } = getProConfig(config, user?.country);

  const legalConfig = getLegalConfig(user?.country);

  const sectionComponents = {
    information: <Information informationConfig={business.information} />,
    identity: <Identity identityConfig={business.identity} />,
    qualifications: <Qualifications />,
    payouts: <Payouts bankingInfoConfig={business.information.bankingInformation} />,
    legal: <Legal legalConfig={legalConfig} />,
  } as Record<string, React.ReactNode>;

  const onSelectedIndex = (idx: number) => {
    const tab = Object.keys(SECTIONS).find((key) => {
      return SECTIONS[key].index === idx;
    });
    params.set("tab", tab as string);
    history.push({
      search: params.toString(),
    });
  };

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Business" />

        <Box mt="40px" ml={isMobile ? "16px" : "80px"} mr={isMobile ? "16px" : "80px"}>
          <ContentSectionControl
            sections={sections}
            selectedIndex={selectedTab.index}
            onSelectedIndex={onSelectedIndex}
          />

          {sectionComponents[selectedTab.name]}
        </Box>
      </Wrapper>
    </>
  );
}
