import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { verifyInvitationCode, acceptInvitation } from "../services/users/users.service";
import { Paths } from "../constants/paths";
import { useAlertStore } from "../stores/alert";
import { parseApiError } from "../helpers/error";
import { AxiosError } from "axios";

const useVerifyInvitation = (code: string) => {
  const history = useHistory();
  const { setErrorMessage } = useAlertStore();

  const { isLoading } = useQuery(
    ["invite-verification", code],
    async () => {
      try {
        const res = await verifyInvitationCode(code);
        if (res) {
          const user = await acceptInvitation(code);
          const { accessToken } = user;
          if (accessToken) {
            localStorage.setItem("token", accessToken);
            if (user) {
              history.push(Paths.Bookings);
            }
          }
        }
        return res;
      } catch (error) {
        const errMsg = (error && error.message) || parseApiError(error as AxiosError);
        setErrorMessage(errMsg);
        history.push("/");
        return error;
      }
    },
    {
      retry: false,
      retryOnMount: false,
    }
  );

  return { isLoading };
};

export default useVerifyInvitation;
