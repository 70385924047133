import { Box, ButtonBase } from "@material-ui/core";
import { Colors } from "../../constants/colors";
import { PaymentMethod } from "../../models";
import ExpiryInfo from "../Payment/ExpiryInfo";
import RadioButton from "../RadioButton";

interface Props {
  paymentMethod: PaymentMethod;
  selected?: boolean;
  onSelected?: () => unknown;
}

export default function PaymentFrameItem({
  paymentMethod,
  selected = false,
  onSelected,
}: Props): JSX.Element {
  return (
    <ButtonBase onClick={onSelected}>
      <Box width="100%" mt={0.5} mb={0.5}>
        <Box
          display="flex"
          flexDirection="row"
          paddingLeft={1}
          paddingRight={4}
          paddingTop={1}
          paddingBottom={1}
          alignItems="center"
        >
          <RadioButton selected={selected} onSelected={onSelected} />

          <Box width="8px" />
          <img
            src={PaymentMethod.iconFor(paymentMethod)}
            width="37.5px"
            height="24px"
            alt={PaymentMethod.toString(paymentMethod)}
          />

          <Box
            ml={1}
            display="flex"
            flex={1}
            flexDirection="column"
            alignItems="flex-start"
          >
            <Box
              fontFamily="Museo"
              fontSize="16px"
              fontWeight={500}
              color={Colors.Dusk}
            >
              {PaymentMethod.toString(paymentMethod)}
            </Box>
            <ExpiryInfo
              isExpired={paymentMethod.isExpired}
              expiryYear={paymentMethod.expiryYear}
              expiryMonth={paymentMethod.expiryMonth}
            />
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
}
