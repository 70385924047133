import { Box, IconButton, Tab, Tabs } from "@material-ui/core";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import TabPanel from "../../../../components/TabPanel";
import SignaturePanel from "./components/signaturePanel";
import { useCreateSignature } from "../../../../hooks/signature.hook";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { base64ToFile } from "../../../../utils/file.utils";
import TextPanel from "./components/textPanel";
import UploadImagePanel from "./components/uploadImagePanel";
import { useAlertStore } from "../../../../stores/alert";
import { useMobile } from "../../../../hooks/mobile";

interface AddSignatureModalContentProps {
  onClose: any;
  sigCanvas: React.RefObject<any>;
  goBack: () => unknown;
  visible: boolean;
}

export const SIGNATURE_TYPE = {
  DRAWING: "signature-drawing",
  TEXT: "signature-text",
  IMAGE: "signature-image",
};

const AddSignatureModalContent: React.FC<AddSignatureModalContentProps> = ({
  onClose,
  sigCanvas,
  visible,
  goBack,
}) => {
  const [file, setFile] = useState<File | undefined>();
  const [signatureText, setSignatureText] = useState<string | null>(null);
  const saveSignature = () => {
    if (activeTab === SIGNATURE_TYPE.TEXT && signatureText) {
      const data = {
        type: activeTab,
        text: signatureText,
      };
      createSignature(data);
    } else {
      const formData = new FormData();

      let sigFile: File | undefined;

      if (activeTab === SIGNATURE_TYPE.DRAWING) {
        let base64Image = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        sigFile = base64ToFile(base64Image, "signature.png");
      } else {
        sigFile = file;
      }

      if (!sigFile) {
        return;
      }

      formData.append("type", activeTab);
      formData.append("image", sigFile);

      createSignature(formData);
    }
  };

  const tabPanels = [
    {
      label: "Draw",
      value: SIGNATURE_TYPE.DRAWING,
      Component: <SignaturePanel sigCanvasRef={sigCanvas} />,
    },
    {
      label: "Type",
      value: SIGNATURE_TYPE.TEXT,
      Component: <TextPanel onChange={(value: string) => setSignatureText(value)} />,
    },
    {
      label: "Upload",
      value: SIGNATURE_TYPE.IMAGE,
      Component: <UploadImagePanel file={file} setFile={setFile} />,
    },
  ] as const;

  const tabValues = tabPanels.map((item) => item.value);
  type TabValues = (typeof tabValues)[number];

  const [activeTab, setActiveTab] = useState<TabValues>(tabValues[0]);
  const { setErrorMessage } = useAlertStore();

  const { mutate: createSignature, isLoading } = useCreateSignature({
    onSuccess: () => {
      goBack();
    },
    onError: (err: any) => {
      setErrorMessage(err?.response?.data?.error?.message || "Failed to add signature.");
    },
  });

  const isMobile = useMobile();

  const Actions = () => (
    <Box
      style={{
        flex: 1,
        gap: "8px",
        display: "flex",
        flexDirection: "row",
        padding: "0px 16px 16px",
        justifyContent: "flex-end",
      }}
    >
      <Box minWidth={"170px"} />{" "}
      <Button title="Cancel" type={ButtonType.outlined} onClick={goBack} />
      <Button
        title="Add"
        loading={isLoading}
        type={ButtonType.primary}
        onClick={() => {
          saveSignature();
        }}
      />
    </Box>
  );

  return (
    <ContentModal
      visible={visible}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      PaperProps={{ style: { maxWidth: "549px" } }}
      actions={[<Actions></Actions>]}
    >
      <Box
        display={"flex"}
        padding={isMobile ? "" : "0px 10px"}
        flexDirection={"column"}
        style={{ gap: "16px" }}
      >
        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <IconButton style={{ height: "12px", width: "12px" }} onClick={onClose}>
            {" "}
            <CloseIcon style={{ color: "black" }} />
          </IconButton>
        </Box>
        <Box
          fontFamily="Museo"
          fontWeight={700}
          fontSize={isMobile ? "24px" : "30px"}
          color={Colors.NightBlue}
        >
          Add your signature
        </Box>

        <Tabs
          TabIndicatorProps={{ style: { background: Colors.TurquoiseBlue } }}
          value={activeTab}
          onChange={(e, newValue) => {
            setActiveTab(newValue);
          }}
        >
          {tabPanels.map((item, index) => (
            <Tab
              style={{
                font: "Museo",
                textTransform: "capitalize",
                color: item.value === activeTab ? "#100249" : "#8B96AD",
              }}
              key={`signature-tab-${index}`}
              label={item.label}
              value={item.value}
            />
          ))}
        </Tabs>

        {tabPanels.map((panel) => (
          <TabPanel value={panel.value} activeValue={activeTab}>
            {panel.Component}
          </TabPanel>
        ))}
      </Box>
    </ContentModal>
  );
};

export default AddSignatureModalContent;
