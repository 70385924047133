import React, { useState } from 'react';
import { Box, BoxProps, LinearProgress } from '@material-ui/core';

import TextLink from '../TextLink';
import { useUserStore } from '../../stores/user';
import { getClientTaxInvoice } from '../../services/bookings/invoice.service';

interface Props extends BoxProps {
  jobId?: number;
  bookingId?: number;
}

const DownloadInvoice: React.FC<Props> = ({ bookingId, jobId, ...props }) => {
  const [loadingPDF, setLoadingPDF] = useState(false);
  const { user } = useUserStore();

  // do all the necessary action required to download the client invoice.

  const handleInvoiceDownload = (bookingId: number, therapistId: number) => {
    setLoadingPDF(true);
    getClientTaxInvoice(bookingId, therapistId)
      .then((response) => {
        if (response.invoiceLinks) {
          response.invoiceLinks.forEach((invoiceLink: string): void => {
            if (invoiceLink) {
              window.open(invoiceLink, '_blank');
            }
          });
        }
      })
      .catch((err) => {
        console.debug(`Failed to download Service fee invoice`, err);
      })
      .finally(() => {
        setLoadingPDF(false);
      });
  };

  if (!jobId || !bookingId || !user) {
    return <> </>;
  }

  return (
    <Box display='flex' flexDirection='column' {...props}>
      <TextLink
        title='Download client invoice'
        fontSize='14px'
        onClick={() => handleInvoiceDownload(bookingId, user.id)}
      />
      {loadingPDF && <LinearProgress style={{ width: '100%', height: 2, marginTop: 4 }} />}
    </Box>
  );
};

export default DownloadInvoice;
