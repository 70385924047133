import React from "react";
import { Box } from "@material-ui/core";
import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";

import Button from "../components/Button";
import TextField from "../components/TextField";
import ModalHeader from "../components/ModalHeader";
import { useAlertStore } from "../stores/alert";
import { parseApiError } from "../helpers/error";
import { useMobile } from "../hooks/mobile";
import Dialog from "./Dialog";
import firebaseApp from "../services/firebaseApp";
import { post } from "../api/client";
import { setCountryCode } from "../utils/country";
import { getFireBaseToken } from "../helpers/firebase";
import { resetPaymentStore } from "../stores/booking";

interface Props {
  open: boolean;
  email?: string | null;
  onClose: () => unknown;
  onSuccess: (accessToken: string) => unknown;
  title?: string;
  subtitle?: string;
}

export default function LoginModal({
  open,
  email: providedEmail,
  onClose,
  onSuccess,
  title = "Welcome back.",
  subtitle = "You already have an account with us, enter your details below to login",
}: Props): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const { setErrorMessage } = useAlertStore();

  const [loading, setLoading] = React.useState(false);

  const onLogin = async () => {
    setLoading(true);

    const token = await getFireBaseToken();

    if (!token) {
      setLoading(false);
      return setErrorMessage(
        "We were not able to verify your request. Please refresh the page and try again."
      );
    }

    post(
      "api/v2/users/login",
      {
        email,
        password,
        channel: "web",
      },
      false,
      {
        "X-SESSION": token,
      }
    )
      .then((response) => {
        setLoading(false);
        resetPaymentStore(); // hotfix

        const { accessToken, country } = response.data;
        setCountryCode(country);
        if (accessToken) {
          localStorage.setItem("token", accessToken);

          onSuccess(accessToken);
        }
      })
      .catch((error: AxiosError) => {
        setLoading(false);

        setErrorMessage(parseApiError(error));
      });
  };

  React.useEffect(() => {
    providedEmail && setEmail(providedEmail);
  }, [providedEmail]);

  return (
    <Dialog open={open} fullScreen={isMobile} onClose={onClose}>
      <Box
        width={isMobile ? "100%" : "440px"}
        height="500px"
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <ModalHeader title={title} subtitle={subtitle} onClose={onClose} />
        <Box display="flex" flexDirection="column" width={isMobile ? "80%" : "360px"}>
          <TextField
            title="Email"
            placeholder="Enter email address"
            onChange={(text) => setEmail(text)}
            value={email}
            type="email"
          />

          <TextField
            title="Password"
            placeholder="Enter password"
            isPassword={true}
            onChange={(text) => setPassword(text)}
            value={password}
            type="password"
            actionTitle="Forgot"
            onActionClicked={() => history.push("/forgot-password")}
          />

          <Button title="Login" onClick={onLogin} loading={loading} />
        </Box>
      </Box>
    </Dialog>
  );
}
