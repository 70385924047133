import { Box, Typography } from "@material-ui/core";
import React from "react";
import Button from "../../components/Button";
import TextField from "../../components/TextField";
import { Colors } from "../../constants/colors";
import { MFA_TYPE } from "../../constants/mfa";
import CountdownTimer from "../../components/MobileModalOtp/CountdownTimer";

interface Props {
  onCodeSubmit: (code: string) => unknown;
  mfaLoginLoading: boolean;
  isActionDisabled: boolean;
  setIsActionDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  waitTime: string;
  onResend: () => unknown;
  titleFontSize?: string;
  subTextFontSize?: string;
  marginTop?: string | number;
  mfaType?: string | null;
}

const MfaLoginComponent = ({
  onCodeSubmit,
  mfaLoginLoading,
  titleFontSize,
  subTextFontSize,
  isActionDisabled,
  setIsActionDisabled,
  waitTime,
  onResend,
  marginTop = "60px",
  mfaType = MFA_TYPE.APP,
}: Props) => {
  const [code, setCode] = React.useState("");
  const mfaTypeText = mfaType === MFA_TYPE.SMS ? "phone number" : "Authentication app";

  const onSubmitClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onCodeSubmit(code);
  };

  return (
    <Box>
      <Typography
        variant="h1"
        style={{ color: Colors.NightBlue, marginTop, fontSize: titleFontSize }}
      >
        Verify your identity
      </Typography>
      <Typography
        variant="h3"
        style={{ color: Colors.Dusk, marginTop: "24px", fontSize: subTextFontSize }}
      >
        Please enter the authentication code sent to your {mfaTypeText}.
      </Typography>

      <Box height="40px" />

      <form onSubmit={onSubmitClicked}>
        <TextField
          title="Verification code"
          placeholder="Enter your code"
          onChange={(code) => setCode(code)}
          value={code}
          type="text"
        />

        <Box mt="20px">
          <Button
            title="Submit"
            loading={mfaLoginLoading}
            submit={true}
            disabled={!code}
            // type={code ? ButtonType.indigo : ButtonType.lightSteel}
          />
          {mfaType === MFA_TYPE.SMS && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box
                display="flex"
                flexDirection="row"
                fontFamily="Museo"
                fontWeight={500}
                fontSize="16px"
                color={Colors.BlueyGrey}
                mt="29px"
              >
                Didn’t get a code?&nbsp;
                <Box
                  color={isActionDisabled ? Colors.LightBlueGrey : Colors.TurquoiseBlue}
                  style={{ cursor: isActionDisabled ? "default" : "pointer" }}
                  onClick={() => (!isActionDisabled ? onResend() : null)}
                  pr={0.5}
                >
                  Resend code
                </Box>
                {isActionDisabled ? (
                  <CountdownTimer time={waitTime} setIsActionDisabled={setIsActionDisabled} />
                ) : null}
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default MfaLoginComponent;
