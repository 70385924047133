import React from "react";
import { Colors } from "../../../../constants/colors";

export const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  titleWrapper: {
    padding: "8px",
  },
  closeWrapper: {
    padding: "12px",
    cursor: "pointer",
  },
  closeBtn: {
    width: "24px",
    height: "24px",
  },
  title: {
    flex: 1,
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "start",
    color: Colors.NightBlue,
    paddingLeft: "40px",
    fontFamily: "Museo",
  },
  contentWrapper: {
    padding: "32px",
    paddingTop: "0px",
    borderBottom: `1px solid ${Colors.LightPeriwinkle}`,
  },
  footerWrapper: {
    padding: "24px",
  },
};
