import React from "react";
import { Box, ButtonBase, CircularProgress } from "@material-ui/core";
import { Colors } from "../constants/colors";

export enum ButtonType {
  primary,
  secondary,
  outlined,
  outlinedWhite,
  white,
  background,
  indigo,
  lightSteel,
  danger,
}

export enum ButtonSize {
  large,
  small,
}
export enum IconPosition {
  left,
  right,
}

interface Props {
  title: string;
  subTitle?: string;
  colorTitle?: string;
  type?: ButtonType;
  size?: ButtonSize;
  width?: string | number;
  onClick?: (event: MouseEvent) => unknown;
  loading?: boolean;
  submit?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  fontStyle?: React.CSSProperties;
  height?: number;
  subTitleStyle?: React.CSSProperties;
  icon?: any;
  iconPosition?: IconPosition;
  iconStyle?: React.CSSProperties;
  disableRipple?: boolean;
}

export default function Button({
  title,
  subTitle = "",
  type = ButtonType.primary,
  size = ButtonSize.large,
  colorTitle,
  width = "100%",
  style = {},
  onClick,
  loading = false,
  submit = false,
  disabled = false,
  fontStyle = { fontFamily: "Museo", fontWeight: 700 },
  subTitleStyle = { fontSize: 12, fontWeight: 500, maxWidth: "205px" },
  height,
  icon,
  iconStyle = {},
  iconPosition = IconPosition.left,
  disableRipple = false,
}: Props): JSX.Element {
  const backgroundColor = () => {
    switch (type) {
    case ButtonType.primary:
      return Colors.NightBlue;
    case ButtonType.secondary:
      return Colors.TurquoiseBlue;
    case ButtonType.outlined:
    case ButtonType.white:
      return Colors.White;
    case ButtonType.background:
      return Colors.Snow;
    case ButtonType.outlinedWhite:
      return "transparent";
    case ButtonType.indigo:
      return Colors.Indigo;
    case ButtonType.lightSteel:
      return Colors.LightBlueGrey;
    case ButtonType.danger:
      return Colors.LightRed;
    }
  };

  const titleSize = () => {
    switch (size) {
    case ButtonSize.large:
      return 16.0;
    case ButtonSize.small:
      return 14.0;
    }
  };

  const titleColor = () => {
    if (colorTitle) return colorTitle;
    switch (type) {
    case ButtonType.primary:
    case ButtonType.secondary:
    case ButtonType.indigo:
    case ButtonType.outlinedWhite:
      return Colors.White;
    case ButtonType.outlined:
    case ButtonType.white:
      return Colors.Dusk;
    }
  };

  const buttonHeight = () => {
    if (height) {
      return height;
    }

    switch (size) {
    case ButtonSize.large:
      return 48.0;
    case ButtonSize.small:
      return 40.0;
    }
  };

  const border = () => {
    switch (type) {
    case ButtonType.outlined:
      return `solid 1px ${Colors.LightPeriwinkle}`;
    case ButtonType.outlinedWhite:
      return "solid 1px #ffffff";
    default:
      return undefined;
    }
  };

  return (
    <ButtonBase
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: border(),
        borderRadius: "4px",
        width,
        height: buttonHeight(),
        backgroundColor: backgroundColor(),
        opacity: disabled ? 0.3 : undefined,
        flexDirection: iconPosition === IconPosition.left ? "row" : "row-reverse",
        ...style,
      }}
      onClick={(event: any) => {
        if (loading === false && !disabled) {
          onClick && onClick(event);
        }
      }}
      type={submit ? "submit" : undefined}
      disabled={disabled}
      disableTouchRipple={disableRipple}
    >
      {loading ? (
        <CircularProgress
          variant="indeterminate"
          style={{ color: titleColor() }}
          size={buttonHeight() * 0.6}
          thickness={4}
        />
      ) : (
        <>
          {icon ? (
            <Box display={"flex"} marginRight={"8px"} alignItems={"center"}>
              <img src={icon} style={{ ...iconStyle }} alt="Icon" />
            </Box>
          ) : (
            <></>
          )}
          <Box
            fontSize={titleSize()}
            color={titleColor()}
            alignItems="center"
            justifyContent="center"
            display="flex"
            style={fontStyle}
          >
            <div>
              <div>{title}</div>
              {!!subTitle && <div style={subTitleStyle}>{subTitle}</div>}
            </div>
          </Box>
        </>
      )}
    </ButtonBase>
  );
}
