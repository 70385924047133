export const VERIFICATION_CODE_RESEND_TIMEOUT = 30; //seconds

export const MIN_HOUR_ALLOWED_TODAY = 23;

export const DEBOUNCE_WAIT_TIMEOUT = 30; //seconds

export const DEFAULT_MIN_TIME = {
  hour: 6,
  minute: 0,
};

export const DEFAULT_MAX_TIME = {
  hour: 23,
  minute: 59,
};

export const RECURRING_ENDS = {
  NEVER: "Never",
  ON: "On",
  AFTER: "After",
};

export const DEFAULT_TIMEZONE = "Australia/Sydney";
