import { Box } from "@material-ui/core";
import axios from "axios";
import braintree from "braintree-web";
import React from "react";
import { Colors } from "../../constants/colors";
import { useAccessToken } from "../../hooks/common";
import {} from "../../stores/booking";
import { useBraintreeStore } from "../../stores/braintree";
import Button, { ButtonType } from "../Button";

interface Props {
  onAddCard: () => unknown;
  onPaypalAdded: () => unknown;
  canAddPaypal?: boolean;
}

export default function AddPaymentMethod({ onAddCard, onPaypalAdded, canAddPaypal }: Props) {
  const accessToken = useAccessToken();

  const { paypalInstance, setPaypalInstance, clientToken, fetchClientToken } = useBraintreeStore();

  const [paypalLoading, setPaypalLoading] = React.useState(false);

  const onPaypal = () => {
    paypalInstance?.tokenize({ flow: "vault" }, (tokenizeErr, payload) => {
      if (tokenizeErr) {
        console.debug("error: ", tokenizeErr);
        return;
      }

      console.debug("payload: ", payload);

      if (payload) {
        const { nonce, details } = payload;
        const { email } = details;

        axios
          .post("/paymentmethods", {
            accessToken,
            nonce,
            email,
          })
          .then((response) => {
            console.debug("added: ", response);

            onPaypalAdded();
          })
          .catch((error) => {
            console.debug("add payment method error: ", error);
          });
      }
    });
  };

  React.useEffect(() => {
    if (!clientToken) {
      fetchClientToken();
    }
  }, []);

  React.useEffect(() => {
    if (clientToken) {
      setPaypalLoading(true);

      braintree.client.create(
        {
          authorization: clientToken,
        },
        (error, clientInstance) => {
          // create paypal instance
          setPaypalInstance(clientInstance);

          braintree.paypal.create({ client: clientInstance }, (paypalErr, paypalInstance) => {
            setPaypalLoading(false);

            if (!paypalErr && paypalInstance) {
              setPaypalInstance(paypalInstance);
            }
          });
        }
      );
    }
  }, [clientToken]);

  return (
    <Box>
      <Box>
        <Box
          fontFamily="Museo"
          fontSize="16px"
          lineHeight="24px"
          fontWeight={600}
          color={Colors.Dusk}
        >
          Add a payment method
        </Box>
        <Box height="16px" />
        <Button type={ButtonType.outlined} title="Add credit or debit card" onClick={onAddCard} />
        <Box height="16px" />
        {canAddPaypal ? (
          <Button
            type={ButtonType.outlined}
            loading={paypalLoading}
            title="Add PayPal account"
            onClick={onPaypal}
          />
        ) : null}
      </Box>
    </Box>
  );
}
