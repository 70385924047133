import { Box } from "@material-ui/core";
import React from "react";
import TextButton from "../../../TextButton/TextButton";
import { FontFamily, FontSize, FontWeight } from "../../../v2/Styled/enum";

interface Props {
  text?: string;
  onClick: () => unknown;
}
export const ViewDetailsBtn = ({ text = "View more details", onClick }: Props) => {
  return (
    <Box>
      <TextButton
        text="View more details"
        onClick={onClick}
        type={"primary"}
        textStyle={{
          fontFamily: FontFamily.OpenSans,
          fontSize: FontSize.F14,
          textDecorationLine: "underline",
          fontWeight: FontWeight.Medium,
        }}
      />
    </Box>
  );
};
