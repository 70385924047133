import { Box } from "@material-ui/core";
import { useServiceArea } from "../context/ServiceAreaState";

export default function NotificationDisabled() {
  const { state } = useServiceArea();
  const { loading, serviceArea = {} } = state;
  const { fixed, realTime = {} } = serviceArea;

  if (loading || fixed || realTime.enabled) return null;

  return (
    <Box
      mb={"22px"}
      border={"1px solid #DC3545"}
      borderRadius={"8px"}
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={"#DC3545"}
    >
      <Box padding={"8px"}>
        You are not receiving any job notifications, please turn on
        {" "}<span style={{ fontWeight: 700 }}>Real-time service area</span> or{" "}
        <span style={{ fontWeight: 700 }}>Fixed service area</span> to receive job notifications.
      </Box>
    </Box>
  );
}
