import { Box } from "@material-ui/core";
import Snackbar, { SnackbarType } from "../../components/Snackbar";
import { useAlertStore } from "../../stores/alert";
import HeaderDecoImage from "../../images/header-deco.svg";
import FooterDecoImage from "../../images/footer-deco.svg";
import { useMobile } from "../../hooks/mobile";

export default function PurchaseVoucherLandingWrapper({
  children,
}: { children: JSX.Element[] }): JSX.Element {
  const isMobile = useMobile();

  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } =
    useAlertStore();

  return (
    <>
      <Box display="flex" position={'relative'} flexDirection={isMobile ? "column" : "row"} justifyContent ='center'>
        <img
          src={HeaderDecoImage}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "110px",
          }}
          alt="Deco"
        />
        {!isMobile && (
          <img
            src={FooterDecoImage}
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              height: "110px",
            }}
            alt={"Footer Deco"}
          />
        )}

        <Box
          position="relative"
          minHeight="100vh"
          height="100%"
          // width="100%"
          width={ !isMobile ? '1039px' : '100%'}
          padding={isMobile ? '0' : '0 10%'}
        >
          {isMobile && (
            <Box mt="64px" ml="25px" mr="25px">
              {children}
            </Box>
          )}

          {!isMobile && <Box mt="64px">
            {children}
          </Box>}
        </Box>

      </Box>
      <Snackbar
        type={SnackbarType.error}
        message={errorMessage}
        open={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />

      <Snackbar
        type={SnackbarType.success}
        open={successMessage !== null}
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </>
  );
}
