import { useMutation, useQuery } from "@tanstack/react-query";

import * as httpClient from "../../api/client";
import queryClient from "../../config/queryClient";

const KEYS = {
  ALLOW_DIRECT_PRO_CHANGE: "ALLOW_DIRECT_PRO_CHANGE",
};

export const useGetAllowDirectProChangeStatus = () =>
  useQuery([KEYS.ALLOW_DIRECT_PRO_CHANGE], () =>
    httpClient.get("api/v2/settings/bookings/allow-direct-pro-change").then((res) => res),
  );

export const useUpdateAllowDirectProChangeStatus = () =>
  useMutation(
    (data?: any) => httpClient.post("api/v2/settings/bookings/allow-direct-pro-change", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [KEYS.ALLOW_DIRECT_PRO_CHANGE] });
      },
    },
  );
