import moment from "moment";
import { Box, IconButton } from "@material-ui/core";

import Dialog from "./Dialog";
import Divider from "./Divider";
import { useMobile } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import CloseIcon from "../images/modal-close.svg";
import DateIcon from "../images/review-date-icon.png";
import AddressIcon from "../images/saved-address-location-pin.svg";
import Button, { ButtonSize, ButtonType } from "../components/Button";
import BookingDetailRow from "./BookingSummary/BookingDetailRow/BookingDetailRow";
import TreatmentDetailsRow from "./BookingSummary/TreatmentDetailsRow/TreatmentDetailsRow";
import ProIcon from "../images/pro-icon.png";
import { ServiceRate } from "../models";
import { checkIfEmpty, countKeys, getValue } from "../utils/object";
import UpdatedLocationDetails from "./UpdateRequest/UpdatedLocationDetails";

interface Props {
  open: boolean;
  timezone: string;
  updateRequest: any;
  decliningLoader: boolean;
  confirmingLoader: boolean;
  onConfirm: (e: MouseEvent) => unknown;
  onDecline: (e: MouseEvent) => unknown;
  onClose: any;
  serviceId: number | null | undefined;
  rates: ServiceRate[];
  session: string;
  bookingId: number;
  isHairAndMakeup: boolean;
}

export default function ProUpdateBookingRequest({
  open,
  onClose,
  timezone,
  onConfirm,
  onDecline,
  updateRequest,
  decliningLoader,
  confirmingLoader,
  serviceId,
  rates,
  session,
  bookingId,
  isHairAndMakeup,
}: Props): JSX.Element {
  const isMobile = useMobile();
  const timeOfArrival = updateRequest?.requestDetails?.timeOfArrival;
  const treatmentDetails = updateRequest?.requestDetails?.treatmentDetails || [];
  const addressDetails = getValue(updateRequest, "requestDetails.addressDetails", {});

  const updatedDataLength = countKeys(getValue(updateRequest, "requestDetails", {}));
  const requestServiceId = getValue(updateRequest, "requestDetails.serviceId") || serviceId;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile}>
      <Box
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        padding={"42px 48px"}
        bgcolor="#ffffff"
        borderRadius="8px"
      >
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box fontFamily="Museo" fontWeight={700} fontSize="21px" color={Colors.NightBlue}>
            Update booking request{" "}
            <Box position="absolute" right="20px" top="10px" style={{ cursor: "pointer" }}>
              <IconButton onClick={onClose}>
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" ml={isMobile ? undefined : "29px"}></Box>
          <Box style={styles.updateWrapper}>
            {!checkIfEmpty(addressDetails) && (
              <>
                <UpdatedLocationDetails
                  addressDetails={addressDetails}
                  bookingId={bookingId}
                  isHairAndMakeup={isHairAndMakeup}
                />
                {updatedDataLength > 1 && <Divider my={4} />}
              </>
            )}

            {!!treatmentDetails.length && (
              <>
                <TreatmentDetailsRow
                  treatmentDetails={treatmentDetails}
                  allowChange={false}
                  icon={ProIcon}
                  serviceId={requestServiceId}
                  rates={rates}
                  session={session}
                />
                {updatedDataLength > 2 && <Divider my={4} />}
              </>
            )}

            {!!timeOfArrival && (
              <BookingDetailRow
                icon={DateIcon}
                text={moment(timeOfArrival).tz(timezone).format("D MMM h:mma")}
              />
            )}
          </Box>
          <Divider mt="16px" />
          <>
            <Box style={styles.actionWrapper} flexDirection="column">
              <Button
                height={48}
                type={ButtonType.outlined}
                size={ButtonSize.small}
                title={confirmingLoader ? "..." : "Confirm"}
                onClick={onConfirm}
                style={{ marginRight: "12px", marginBottom: 16 }}
              />
              <Button
                height={48}
                type={ButtonType.outlined}
                size={ButtonSize.small}
                title={decliningLoader ? "..." : "Decline"}
                onClick={onDecline}
              />
            </Box>
          </>
        </Box>
      </Box>
    </Dialog>
  );
}

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "24px",
    marginBottom: "24px",
  },
  updateWrapper: {
    width: "100%",
    marginTop: "15px",
  },
};
