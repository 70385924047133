export enum PaymentType {
  card = "card",
  paypal = "paypal",
  afterpay = "afterpay",
  applepay = "applepay",
  payWithInvoice = "payWithInvoice",
  payWithNDISFunding = "ndis",
  default = "DEFAULT",
}

export const PAYMENT_TYPE_LIST = [
  "card",
  "paypal",
  "afterpay",
  "applepay",
  "payWithInvoice",
  "ndis",
];

export const PAYMENT_TERMS = [
  { title: "1 hour", value: 1 }, // in hours
  { title: "1 day", value: 24 },
  { title: "7 days", value: 168 },
  { title: "14 days", value: 336 },
  { title: "30 days", value: 720 },
];

export const INVOICE_ISSUE = [
  { title: "Booking confirmed", value: "Booking confirmed" },
  { title: "Booking completed", value: "Booking completed" },
];

export const INVOICE_FORMAT = [
  {
    title: "Send service provider invoice only",
    value: "Send service provider invoice only",
  },
  {
    title: "Send service provider & Blys invoices in a single PDF",
    value: "Send service provider & Blys invoices in a single PDF",
  },
  {
    title: "Send service provider & Blys invoices as separate PDFs",
    value: "Send service provider & Blys invoices as separate PDFs",
  },
  {
    title: "Send all amount owing in a single tax invoice",
    value: "Send all amount owing in a single tax invoice",
  },
  {
    title: "Send bulk invoice for all recipients (Excel)",
    value: "Send bulk invoice for all recipients (Excel)",
  },
  {
    title: "Send bulk invoice for each recipient",
    value: "Send bulk invoice for each recipient",
  },
  {
    title: "Send bulk invoice for each recipient (NDIS)",
    value: "Send bulk invoice for each recipient (NDIS)",
  },
  {
    title: "Send bulk invoice for all recipients",
    value: "Send bulk invoice for all recipients",
  },
  {
    title: "Send bulk invoice for each provider",
    value: "Send bulk invoice for each provider",
  },
];

export const INVOICE_DELIVERY_FORMAT = [
  {
    title: "Immediately after service",
    value: "Immediately after service",
  },
  {
    title: "Weekly",
    value: "Weekly",
  },
  {
    title: "Monthly",
    value: "Monthly",
  },
];

export const PAYMENT_RECEIPT_PREFERENCE_OPTIONS = [
  { title: "Yes", value: "yes" },
  { title: "No", value: "no" },
];

export const SHOULD_ITEMIZE_SURCHARGES = [
  { title: "Yes", value: "yes" },
  { title: "No", value: "no" },
];

export const INVOICE_FORMAT_ENUM = {
  SERVICE_PROVIDER_AND_BLYS_INVOICE_SINGLE_PDF:
    "Send service provider & Blys invoices in a single PDF",
  SERVICE_PROVIDER_AND_BLYS_INVOICE_SEPARATE_PDF:
    "Send service provider & Blys invoices as separate PDFs",
  ALL_AMOUNT_OWING_IN_SINLE_TAX_INVOICE: "Send all amount owing in a single tax invoice",
  BULK_INVOICE_FOR_EACH_RECIPIENT: "Send bulk invoice for each recipient",
  BULK_INVOICE_FOR_EACH_RECIPIENT_NDIS: "Send bulk invoice for each recipient (NDIS)",
  BULK_INVOICE_FOR_ALL_RECIPIENTS: "Send bulk invoice for all recipients",
  BULK_INVOICE_FOR_EACH_PROVIDER: "Send bulk invoice for each provider",
  BULK_INVOICE_FOR_ALL_RECIPIENTS_EXCEL: "Send bulk invoice for all recipients (Excel)",
};

export const PayoutStatus = {
  pending: "Pending",
  inTransit: "In transit",
  paid: "Paid",
};
