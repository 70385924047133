import React from "react";
import { Colors } from "../../../constants/colors";

export const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    gap: "40px",
    display: "flex",
  },
  blockWrapper: {
    gap: "8px",
    display: "flex",
  },
  block: {
    gap: "16px",
    display: "flex",
  },
  title: {
    fontFamily: "Museo",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "27px",
    color: Colors.NightBlue,
  },
  subTitle: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
  description: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  },
  selectedFileWrapper: {
    gap: "8px",
    display: "flex",
    alignItems: "center",
  },
  existingFileRow: {
    display: "flex",
    alignItems: "center",
    gap: 2,
    maxWidth: "300px",
    overflow: "hidden",
  },
  fileLink: {
    width: "100%",
    cursor: "pointer",
    overflow: "hidden",
  },
  tooltip: {
    maxWidth: "300px",
    overflow: "hidden",
  },
  flex: { flex: 1 },
  displayFlex: { display: "flex" },
  dusk: {
    color: Colors.Dusk,
  },
  grey: {
    color: Colors.Grey,
  },
  fullWidth: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};
