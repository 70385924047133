import { Helmet } from "react-helmet-async";

const PageMeta = ({
  title = "",
  description = "",
}) => {
  return <Helmet>
    <title>{title}</title>
    <meta name="title" content={title} />
    <meta name="description" content={description} />
    <meta property="og:title" content={title}  />
    <meta property="og:description" content={description} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
  </Helmet>;
};

export default PageMeta;
