import { Box, ButtonBase } from "@material-ui/core";
import { isNil } from "lodash";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import DayPicker, { NavbarElementProps } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import { BOOKING_TIME, BUFFER_TIME_MINS } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { DEFAULT_TIMEZONE, MIN_HOUR_ALLOWED_TODAY } from "../../constants/time";
import { getCurrentMoment, getDate, getTimeOptions, minutesToTime } from "../../helpers/time";
import { useMobile } from "../../hooks/mobile";
import PlusIcon from "../../images/plus-icon.svg";
import TextField from "../../components/TextField";
import dateConfig from "../../config/dateAndTime.json";
import CalendarNext from "../../images/calendar-next.png";
import CalendarPrevious from "../../images/calendar-previous.png";
import { useTotalEventDuration } from "../../hooks/booking/corporate.hooks";
import { getNearestNextTimeOption } from "../../services/bookings/prefillDateTime.service";
import { trackEvent } from "../../services/segment/track.service";
import { fetchAddresses } from "../../stores/address";
import { useAlertStore } from "../../stores/alert";
import dangerIcon from "../../images/error_icon.png";

import {
  isCorporateTreatmentType,
  isCoupleMassageType,
  prefillBackupDateAndTime,
  prefillDateAndTime,
  useBookingStore,
  useDateTimeStore,
  useServicesStore,
  isMassageType,
  removeBackupDateAndTime,
} from "../../stores/booking";
import "../../styles/DayPicker.css";
import {
  isCurrentDay,
  getCurrentTimeInMinutes as getCurrentTimeInMins,
  convertDatetoTimezoneMoment,
  convertDayMomentToDate,
  resetTimeFromDate,
  isCurrentDayMoment,
  isDateObjectToday,
  getCurrentDayInTimezone,
  getCorporateEndTime,
  getDateObject,
  isSelectedDateBeforeEndDate,
  isSameDate,
  isBeforeCurrentDate,
} from "../../utils/date.util";
import Divider from "../../components/Divider";
import { getDateTimeLabelWithMinutes } from "../../services/bookings/bookingTime.service";
import { checkIfEmpty } from "../../utils/object";
import { checkIsString } from "../../utils/string";

export function DayPickerNavbar({
  month,
  nextMonth,
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
  localeUtils,
}: NavbarElementProps) {
  const buttonStyle = {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
  };

  return (
    <div className={className}>
      <Box display="flex" justifyContent="space-between" pl="22px" pr="22px">
        <Box fontFamily="Museo" fontSize="20px" fontWeight={700} color={Colors.Dusk}>
          {moment(month).format("MMMM YYYY")}
        </Box>

        <Box display="flex" flexDirection="row">
          <ButtonBase style={buttonStyle} onClick={() => onPreviousClick()}>
            <img src={CalendarPrevious} />
          </ButtonBase>
          <ButtonBase style={buttonStyle} onClick={() => onNextClick()}>
            <img src={CalendarNext} />
          </ButtonBase>
        </Box>
      </Box>
    </div>
  );
}

interface DateTimeProps {
  showInfoIcon?: boolean;
  titleStyle?: object;
}

export default function DateAndTime({ showInfoIcon, titleStyle = {} }: DateTimeProps): JSX.Element {
  const isMobile = useMobile();
  const { timeRange } = useServicesStore();

  const timeOptions = getTimeOptions(timeRange) as DropdownOption[];

  const {
    selectedDay,
    setSelectedDay,
    earliestStartInMinutes,
    setEarliestStartInMinutes,
    latestStartInMinutes,
    setLatestStartInMinutes,
    backupSelectedDay,
    setBackupSelectedDay,
    backupEarliestStartInMinutes,
    setBackupEarliestStartInMinutes,
    backupLatestStartInMinutes,
    setBackupLatestStartInMinutes,
    selectedDayRange,
    setSelectedDayRange,
    backupSelectedDayRange,
    setBackupSelectedDayRange,
  } = useDateTimeStore();

  const { setErrorMessage } = useAlertStore();

  const {
    updatingForBooking,
    lastBooking,
    sessionType,
    address,

    // For corporate booking
    numberOfPros,
    durationPerPerson,
    numberOfPerson,
  } = useBookingStore();

  const addressTimezone = address?.timezone;

  const isCoupleMassage = isCoupleMassageType(sessionType);
  const isCorporateType = isCorporateTreatmentType();
  const isMassage = isMassageType();

  const labels = isCorporateType ? dateConfig["corporate"] : dateConfig["*"];

  const [saving, setSaving] = useState(false);
  const [showBackUpDate, setShowBackUpDate] = useState(false);
  const [hoveredDate, setHoveredDate] = useState<null | Date>(null);
  const [backupHoveredDate, setBackupHovered] = useState<null | Date>(null);

  const disableRangeDate = isCorporateType || (isCoupleMassage && isMassage);

  const oneYearFromNow = getCurrentDayInTimezone(addressTimezone);
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

  const { data: corporateDuration } = useTotalEventDuration({
    numberOfPros,
    durationPerPerson,
    numberOfPerson,
  });

  const getSelectedDateTime = (selectedDay: Date, timeInMinutes: number, timezone?: string) => {
    const { hour, mins } = minutesToTime(timeInMinutes);
    const timeMoment = convertDatetoTimezoneMoment(selectedDay, timezone);
    return timeMoment.set({
      hours: hour,
      minutes: mins,
      seconds: 0,
      milliseconds: 0,
    });
  };


  useEffect(() => {
    if (isNil(updatingForBooking) && isNil(lastBooking) && !selectedDay) {
      const shouldAdjustTime = false;
      prefillDateAndTime(shouldAdjustTime);
    }
    if (!lastBooking && !latestStartInMinutes) {
      setLatestStartInMinutes(NaN);
    } else {
      setLatestStartInMinutes(latestStartInMinutes || NaN);
    }

    fetchAddresses();

    if (selectedDay) {
      setSelectedDay(new Date(selectedDay));
    }

    if (backupSelectedDay) {
      setBackupSelectedDay(new Date(backupSelectedDay));
    }

    if (isNil(updatingForBooking) && isBeforeCurrentDate(selectedDayRange.from, addressTimezone)) {
      const currentDate = getCurrentDayInTimezone(addressTimezone);
      setSelectedDayRange(currentDate, currentDate);
    }

    if (
      isNil(updatingForBooking) &&
      isBeforeCurrentDate(backupSelectedDayRange.from, addressTimezone)
    ) {
      const currentDate = getCurrentDayInTimezone(addressTimezone);
      setBackupSelectedDayRange(currentDate, currentDate);
    }

    trackEvent("Date Time Viewed", {
      step: 4,
      version: 2,
    });
  }, []);

  useEffect(() => {
    if (!showBackUpDate && backupSelectedDay) {
      setShowBackUpDate(true);
    }
  }, [showBackUpDate, backupSelectedDay]);

  useEffect(() => {
    if (!earliestStartInMinutes) return;

    const latestStart = getLatestStartInMinutes({
      timezone: addressTimezone,
      daySelected: selectedDay,
      latestStart: latestStartInMinutes,
      earliestStart: earliestStartInMinutes,
    });

    if (latestStartInMinutes && latestStart !== latestStartInMinutes) {
      setLatestStartInMinutes(latestStart);
    }
  }, [earliestStartInMinutes]);

  useEffect(() => {
    if (!backupSelectedDay) return;
    const latestStart = getLatestStartInMinutes({
      timezone: addressTimezone,
      daySelected: backupSelectedDay,
      latestStart: backupLatestStartInMinutes,
      earliestStart: backupEarliestStartInMinutes,
    });
    if (latestStart !== latestStartInMinutes) {
      setBackupLatestStartInMinutes(latestStart);
    }
  }, [backupEarliestStartInMinutes]);

  const onAddBackUpDateTimePress = () => {
    setShowBackUpDate(true);
    prefillBackupDateAndTime();
  };

  const onRemoveBackupDateTimePress = () => {
    setShowBackUpDate(false);
    removeBackupDateAndTime();
  };

  const getUpdatedEarliestTimeBasedOnDay = ({
    timezone,
    dayMoment,
    selectedTime,
  }: {
    timezone?: string;
    dayMoment: Moment;
    selectedTime: any;
  }) => {
    if (!isCurrentDayMoment(dayMoment, timezone)) return selectedTime;

    const bufferMins = isCoupleMassage || isCorporateType ? BUFFER_TIME_MINS : 0;
    const currentTimeInMins = getCurrentTimeInMins(timezone, bufferMins);
    if (selectedTime > currentTimeInMins) return selectedTime;
    const newEarliestTime =
      timeOptions.find(({ value }) => value > currentTimeInMins)?.value || selectedTime;
    return newEarliestTime;
  };

  const getLatestStartInMinutes = ({
    timezone,
    daySelected,
    earliestStart,
    latestStart,
  }: {
    timezone?: string;
    daySelected: any;
    earliestStart: any;
    latestStart: any;
  }) => {
    let updatedLatest = latestStart;

    const isToday = isDateObjectToday(daySelected, timezone);
    if (
      (!isToday && latestStart < earliestStart) ||
      isCoupleMassage ||
      isCorporateType ||
      updatingForBooking?.isBookedByPro
    ) {
      updatedLatest = earliestStart;
    } else if (isToday) {
      const minFeasibleLatestMinutes = getCurrentTimeInMins(timezone, BUFFER_TIME_MINS);
      if (latestStart < minFeasibleLatestMinutes) {
        updatedLatest = getNearestNextTimeOption({
          timeOptions,
          timeInMinutes: minFeasibleLatestMinutes,
        });
      } else if (latestStart < earliestStart) {
        updatedLatest = earliestStart;
      }
    }
    return updatedLatest;
  };

  const getEarliestTimeOptions = ({
    startDay,
    endDay,
    timezone,
  }: {
    startDay: any;
    endDay: any;
    timezone?: string;
  }) => {
    const selectedDayMoment = convertDatetoTimezoneMoment(new Date(startDay), timezone);
    const isSameDay = isCurrentDay(selectedDayMoment, timezone);
    if (!isSameDay) return timeOptions;

    let minFeasibleEarliest = getCurrentTimeInMins(timezone);
    if (isCoupleMassage || isCorporateType) {
      minFeasibleEarliest = getCurrentTimeInMins(timezone, BUFFER_TIME_MINS);
    }

    // allow past time if RANGE selected
    const isRange = !isSameDate(new Date(startDay), new Date(endDay), timezone);
    if (isRange && isSameDay) return timeOptions;

    return timeOptions.filter(({ value }) => value >= minFeasibleEarliest);
  };

  const filteredLatestRange = ({ filterFor, selectedEarliest, timezone }: any) => {
    let selectedDayForFilter =
      filterFor === BOOKING_TIME.BACKUP ? backupSelectedDayRange.from : selectedDayRange.from;

    if (!selectedDayForFilter) selectedDayForFilter = getCurrentDayInTimezone(timezone);
    const isSameDay = isDateObjectToday(selectedDayForFilter, timezone);

    if (!isSameDay && selectedEarliest != null) {
      return timeOptions.filter(({ value }) => value >= selectedEarliest);
    }

    // for non-couples massaga adding 1 hour buffer in time-picker for latestStart
    if (!isCoupleMassage || !updatingForBooking?.isBookedByPro || !isCorporateType) {
      const minFeasibleLatest = getCurrentTimeInMins(timezone, BUFFER_TIME_MINS);
      return timeOptions.filter(
        ({ value }) => value >= minFeasibleLatest && value >= selectedEarliest
      );
    }
    return timeOptions;
  };

  const getCurrentHours = (timezone?: string) => getCurrentMoment(timezone).hours();

  const getMinValidDate = () => {
    const currentMoment = getCurrentMoment(addressTimezone);
    return getCurrentHours(addressTimezone) >= MIN_HOUR_ALLOWED_TODAY
      ? getDate(convertDayMomentToDate(currentMoment), 1)
      : convertDayMomentToDate(currentMoment);
  };

  const getAdjustedDateRange = (date: Date, selectedRange: { from: Date; to: Date }) => {
    // date range selecting disabled for corporate and couples booking
    if (isCorporateType || (isCoupleMassage && isMassage)) {
      return { from: date, to: date };
    }

    const isEarliestLatestSame = isSameDate(selectedRange.from, selectedRange.to, addressTimezone);
    if (!isEarliestLatestSame) {
      return { from: date, to: date };
    }

    const isBefore = isSelectedDateBeforeEndDate(date, selectedRange.to);
    if (isBefore) {
      selectedRange.from = date;
    } else {
      selectedRange.to = date;
    }
    return selectedRange;
  };

  // REMINDER: date is in local timezone
  const handleDaySelected = (date: Date) => {
    const updatedDate = resetTimeFromDate(date);

    const updatedDayRange = getAdjustedDateRange(updatedDate, selectedDayRange);
    setSelectedDayRange(updatedDayRange.from, updatedDayRange.to);
    setSelectedDay(updatedDate);

    const selectedDayMoment = convertDatetoTimezoneMoment(
      updatedDate || new Date(),
      addressTimezone
    );

    // do not adjust time, if a time is not already selected
    if (!earliestStartInMinutes) return;

    // do not adjust time, if is ranged date
    const { from: fromDate, to: toDate } = updatedDayRange;
    const isRange = !isSameDate(fromDate, toDate);
    if (isRange) return;

    const newEarliestTime = getUpdatedEarliestTimeBasedOnDay({
      timezone: addressTimezone,
      dayMoment: selectedDayMoment,
      selectedTime: earliestStartInMinutes,
    });

    if (newEarliestTime === earliestStartInMinutes) return;
    setEarliestStartInMinutes(newEarliestTime);
  };

  const handleBackupDaySelected = (date: Date) => {
    const updatedDate = resetTimeFromDate(date);
    const updatedDateRange = getAdjustedDateRange(updatedDate, backupSelectedDayRange);

    setBackupSelectedDayRange(updatedDateRange.from, updatedDateRange.to);
    setBackupSelectedDay(updatedDate);

    // do not adjust time, if a time is not already selected (first time users)
    if (!backupEarliestStartInMinutes) return;

    // do not adjust time, if is ranged date
    const { from: fromDate, to: toDate } = updatedDateRange;
    const isRange = !isSameDate(fromDate, toDate);
    if (isRange) return;

    const selectedDayMoment = convertDatetoTimezoneMoment(
      updatedDate || new Date(),
      addressTimezone
    );
    const newBackupEarliestTime = getUpdatedEarliestTimeBasedOnDay({
      timezone: addressTimezone,
      dayMoment: selectedDayMoment,
      selectedTime: backupEarliestStartInMinutes,
    });
    if (newBackupEarliestTime === backupEarliestStartInMinutes) return;
    setBackupEarliestStartInMinutes(newBackupEarliestTime);
  };

  const getPreferredModifier = () => {
    let fromDate = selectedDayRange.from;
    let toDate = selectedDayRange.to;

    if (hoveredDate && isSameDate(fromDate, toDate, addressTimezone)) {
      const isBeforeSelected = isSelectedDateBeforeEndDate(hoveredDate, fromDate);
      fromDate = isBeforeSelected ? hoveredDate : fromDate;
      toDate = isBeforeSelected ? toDate : hoveredDate;
    }

    const selected = {
      from: getDateObject(fromDate, addressTimezone),
      to: getDateObject(toDate, addressTimezone),
    };
    return selected;
  };

  const getBackupModifier = () => {
    let fromDate = backupSelectedDayRange.from;
    let toDate = backupSelectedDayRange.to;

    if (backupHoveredDate && isSameDate(fromDate, toDate, addressTimezone)) {
      const isBeforeSelected = isSelectedDateBeforeEndDate(backupHoveredDate, fromDate);
      fromDate = isBeforeSelected ? backupHoveredDate : fromDate;
      toDate = isBeforeSelected ? toDate : backupHoveredDate;
    }
    return {
      from: getDateObject(fromDate, addressTimezone),
      to: getDateObject(toDate, addressTimezone),
    };
  };

  const showAddBackupOption =
    !showBackUpDate && !updatingForBooking?.isBookedByPro && !isCorporateType;

  const getPreferredDateTimeLabel = () => {
    if (checkIfEmpty(selectedDayRange) && !earliestStartInMinutes && !latestStartInMinutes) {
      return "Select any date and time";
    }

    const { from, to } = selectedDayRange;
    const preferredLabel = getDateTimeLabelWithMinutes({
      fromDate: from,
      toDate: to,
      earliestMinutes: earliestStartInMinutes,
      latestMinutes: latestStartInMinutes,
      timezone: addressTimezone || DEFAULT_TIMEZONE,
    });
    return preferredLabel.datetime;
  };

  const getBackupDateTimeLabel = () => {
    if (
      checkIfEmpty(backupSelectedDayRange) &&
      !backupEarliestStartInMinutes &&
      !backupLatestStartInMinutes
    ) {
      return "Select any date and time";
    }

    const { from, to } = backupSelectedDayRange;
    const backupLabel = getDateTimeLabelWithMinutes({
      fromDate: from,
      toDate: to,
      earliestMinutes: backupEarliestStartInMinutes,
      latestMinutes: backupLatestStartInMinutes,
      timezone: addressTimezone || DEFAULT_TIMEZONE,
    });
    return backupLabel.datetime;
  };

  const handleDayHovered = (day: Date) => {
    if (checkIfEmpty(selectedDayRange) || disableRangeDate) return;

    const { from, to } = selectedDayRange;
    if (isSameDate(from, to, addressTimezone)) {
      setHoveredDate(day);
    }
  };

  const getInitialMonth = () => {    
    if(selectedDay){
      return checkIsString(selectedDay)? new Date(selectedDay): selectedDay;
    }
    
    // undefined takes the current month
    return undefined;
 
  };

  const handleBackupHovered = (day: Date) => {
    if (checkIfEmpty(backupSelectedDayRange) || disableRangeDate) return;

    const { from, to } = backupSelectedDayRange;
    if (isSameDate(from, to, addressTimezone)) {
      setBackupHovered(day);
    }
  };

  const preferredModifiers = getPreferredModifier();
  const backupModifiers = getBackupModifier();

  return (
    <Box>
      <Box
        mb={"16px"}
        mt={"16px"}
        fontFamily={"Museo"}
        fontSize={"16px"}
        fontWeight={"500"}
        color={Colors.Dusk}
        letterSpacing={"0.5px"}
        lineHeight={"24px"}
      >
        Select a specific date & time or a range to give your provider more options. The exact
        appointment date & start time will be confirmed by your provider and you'll receive a
        notification.
      </Box>
      <Box style={{ marginTop: "16px", marginBottom: "16px" }}>
        <Box
          style={{ fontFamily: "Museo", fontWeight: "600", fontSize: "20px", color: Colors.Dusk }}
        >
          {getPreferredDateTimeLabel()}
        </Box>
      </Box>
      <Divider mt={"16px"} mb={"16px"} />

      <Box display="flex" flexDirection={isMobile ? "column" : "row"} marginTop="16px">
        <Box
          width="100%"
          mt="16px"
          display={isMobile ? "flex" : undefined}
          flexDirection={isMobile ? "column" : undefined}
          alignItems={isMobile ? "center" : undefined}
        >
          <DayPicker
            month={getInitialMonth()}
            firstDayOfWeek={1}
            onDayClick={(day, modifiers) => {
              if (modifiers.disabled) return;
              handleDaySelected(day);
            }}
            onDayMouseLeave={() => setHoveredDate(null)}
            onDayMouseEnter={handleDayHovered}
            selectedDays={[
              preferredModifiers.from,
              { from: preferredModifiers.from, to: preferredModifiers.to },
            ]}
            disabledDays={{
              before: getMinValidDate(),
              after: oneYearFromNow,
            }}
            captionElement={() => {
              return <Box />;
            }}
            navbarElement={(props) => {
              return <DayPickerNavbar {...props} />;
            }}
            modifiers={preferredModifiers}
          />
        </Box>
        <Box width="100%">
          <Dropdown
            title={labels.earliestLabel}
            options={getEarliestTimeOptions({
              startDay: selectedDayRange.from,
              endDay: selectedDayRange.to,
              timezone: addressTimezone,
            })}
            onSelectedOption={(option) => {
              setEarliestStartInMinutes(option.value);

              if (isCoupleMassage || updatingForBooking?.isBookedByPro || isCorporateType) {
                setLatestStartInMinutes(option.value);
              }
            }}
            selectedOption={timeOptions.find((option) => option.value === earliestStartInMinutes)}
          />
          {isCorporateType ? (
            <TextField
              title={labels.latestLabel}
              value={getCorporateEndTime({
                startTimeInMinutes: earliestStartInMinutes,
                durationInMinutes: corporateDuration?.massageDuration,
              })}
              disabled
            />
          ) : (
            !isCoupleMassage &&
            !updatingForBooking?.isBookedByPro && (
              <>
                <Dropdown
                  title={labels.latestLabel}
                  options={filteredLatestRange({
                    timezone: addressTimezone,
                    filterFor: BOOKING_TIME.PREFERRED,
                    selectedEarliest: earliestStartInMinutes,
                  })}
                  onSelectedOption={(option) => setLatestStartInMinutes(option.value)}
                  selectedOption={timeOptions.find(
                    (option) => option.value === latestStartInMinutes
                  )}
                  paddingBottom="16px"
                />
                {showInfoIcon && !latestStartInMinutes && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    gridColumnGap={"8px"}
                  >
                    <img height={"16px"} src={dangerIcon} alt={"Alert icon"} />
                    <Box
                      fontFamily="Open Sans"
                      fontSize="14px"
                      color={Colors.TextDanger}
                      fontWeight={400}
                    >
                      Please select latest start time.
                    </Box>
                  </Box>
                )}
              </>
            )
          )}
        </Box>
      </Box>

      {showAddBackupOption && (
        <Box
          style={{
            cursor: "pointer",
            fontFamily: "Museo",
            fontStyle: "normal",
            fontWeight: 600,
            color: "#0DAEC8",
            fontSize: "18px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
          onClick={() => onAddBackUpDateTimePress()}
        >
          <img src={PlusIcon} style={{ marginRight: "6px" }} alt="plus" />
          {showBackUpDate ? "Remove Backup date and time" : "Add Backup date and time"}
        </Box>
      )}

      {/* backup date and time */}
      {showBackUpDate && (
        <Box style={{ paddingTop: "30px" }}>
          <Box
            width="100%"
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
            gridGap={isMobile ? "10px" : 0}
            justifyContent={isMobile ? "none" : "space-between"}
          >
            <Box
              fontFamily="Museo"
              fontWeight={700}
              fontSize="36px"
              letterSpacing="-0.56px"
              color={Colors.NightBlue}
              style={titleStyle}
            >
              Backup Date and time
            </Box>
            <Box
              fontFamily="Museo"
              fontWeight={700}
              fontSize="18px"
              letterSpacing="-0.56px"
              color="#DC3545"
              onClick={() => onRemoveBackupDateTimePress()}
              style={{ cursor: "pointer" }}
            >
              Remove
            </Box>
          </Box>
          <Box style={{ marginTop: "32px", marginBottom: "16px" }}>
            <Box
              style={{
                fontFamily: "Museo",
                fontWeight: "600",
                fontSize: "20px",
                color: Colors.Dusk,
              }}
            >
              {getBackupDateTimeLabel()}
            </Box>
          </Box>
          <Divider mt={"16px"} mb={"16px"} />
          <Box display="flex" flexDirection={isMobile ? "column" : "row"} marginTop="30px">
            <Box
              mt="16px"
              width="100%"
              display={isMobile ? "flex" : undefined}
              flexDirection={isMobile ? "column" : undefined}
              alignItems={isMobile ? "center" : undefined}
            >
              <DayPicker
                firstDayOfWeek={1}
                onDayClick={(day, modifiers) => {
                  if (modifiers.disabled) return;
                  handleBackupDaySelected(day);
                }}
                onDayMouseLeave={() => setBackupHovered(null)}
                onDayMouseEnter={handleBackupHovered}
                selectedDays={[
                  backupModifiers.from,
                  { from: backupModifiers.from, to: backupModifiers.to },
                ]}
                disabledDays={{
                  before: getMinValidDate(),
                  after: oneYearFromNow,
                }}
                captionElement={() => {
                  return <Box />;
                }}
                navbarElement={(props) => {
                  return <DayPickerNavbar {...props} />;
                }}
                modifiers={backupModifiers}
              />
            </Box>
            <Box width="100%">
              <Dropdown
                title="Earliest start time"
                options={getEarliestTimeOptions({
                  startDay: backupSelectedDayRange.from,
                  endDay: backupSelectedDayRange.to,
                  timezone: addressTimezone,
                })}
                onSelectedOption={(option) => {
                  setBackupEarliestStartInMinutes(option.value);

                  if (isCoupleMassage) {
                    setBackupLatestStartInMinutes(option.value);
                  }
                }}
                selectedOption={timeOptions.find(
                  (option) => option.value === backupEarliestStartInMinutes
                )}
              />
              {!isCoupleMassage && (
                <Dropdown
                  title="Latest start time"
                  options={filteredLatestRange({
                    filterFor: BOOKING_TIME.BACKUP,
                    selectedEarliest: backupEarliestStartInMinutes,
                    timezone: addressTimezone,
                  })}
                  onSelectedOption={(option) => setBackupLatestStartInMinutes(option.value)}
                  selectedOption={timeOptions.find(
                    (option) => option.value === backupLatestStartInMinutes
                  )}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
