import { Typography } from "@material-ui/core";
import React from "react";
import CountdownTimer from "./CountdownTimer";

interface Props {
  onResend: () => unknown;
  onChange: () => unknown;
  retryIn: string;
  isActionDisabled: boolean;
  setIsActionDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  changeText?: string;
}

const MobileInputAction = ({
  onResend,
  onChange,
  retryIn,
  isActionDisabled,
  setIsActionDisabled,
  changeText = "Change number",
}: Props) => {
  return (
    <Typography
      style={{
        fontSize: "14px",
        width: "100%",
        color: "#41506F",
        fontWeight: 400,
        textAlign: "center",
      }}
    >
      {"Didn’t receive the code? "}
      <span
        className={isActionDisabled ? "clickable disabled" : "clickable"}
        onClick={() => (!isActionDisabled ? onResend() : null)}
      >
        Resend
      </span>
      {" or "}
      <span
        className={isActionDisabled ? "clickable disabled" : "clickable"}
        onClick={() => (!isActionDisabled ? onChange() : null)}
      >
        {changeText}
      </span>
      {isActionDisabled ? (
        <CountdownTimer time={retryIn} setIsActionDisabled={setIsActionDisabled} />
      ) : null}
    </Typography>
  );
};

export default MobileInputAction;
