import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import PublicUserIcon from "../../images/public-user-icon.svg";

export default function UserIconLink() {
  return (
    <ButtonBase
      onClick={() =>
        window.location.assign("https://app.getblys.com.au/bookings")
      }
    >
      <img src={PublicUserIcon} />
    </ButtonBase>
  );
}
