import React, { useEffect, useState } from "react";
import { Box, Text } from "../../../components/v2/Styled";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { Booking } from "../../../models";
import { Colors } from "../../../constants/colors";
import { FilterButton } from "../../../components/FilterButton/FilterButton";
import { checkIfEmpty } from "../../../utils/object";
import { NoFilteredBookings } from "../../../components/Bookings/NoFilteredBookings";
import NoBookingsPlaceholder, {
  NoBookingsPlaceholderType,
} from "../../../components/Bookings/NoBookingsPlaceholder";
import { usePastBookings } from "../../../hooks/booking/booking.hooks";
import { useBookingFilterCount, useCachedBookingSort } from "../../../hooks/utility/filter.hooks";
import Each from "../../../components/Each";
import BookingItem from "../../../components/Bookings/BookingItem";
import { LoadingSpinner } from "../../../components/loadingSpinner";
import PaginationControl from "../../../components/PaginationControl";
import { BookingFilterModal, FilterBookingValue } from "../../../components/Modals/FilterModal/BookingFilterModal";
import UseBookingQueryFilter from "../../../hooks/booking/bookingFilter.hook";

interface Props {
  onBookingClick: (booking: Booking, status: string) => unknown;
  isMobile: boolean;
}

const PerPage = 10;

const Past = ({ onBookingClick, isMobile }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showFilterAndSortModal, setShowFilterAndSortModal] = React.useState(false);

  const { cachedSortBy } = useCachedBookingSort();
  const { getFilterCount } = useBookingFilterCount();

  const bookingSortBy = cachedSortBy || "desc";
  const [numOfFilter, setNumOfFilter] = useState((bookingSortBy !== "desc") ? 1 : 0);
  const [searchQuery, setSearchQuery] = useState({ sortBy: bookingSortBy });
  const {safeBookingQueries}  = UseBookingQueryFilter({});
  const {
    isLoading,
    invalidatePastBookings,
    bookings: pastBookings,
    pageCount,
  } = usePastBookings({
    currentPage,
    perPage: PerPage,
    search: {...searchQuery,...safeBookingQueries}
  });

  useEffect(()=>{
    setNumOfFilter(getFilterCount(safeBookingQueries,"desc")); 
  },[safeBookingQueries,getFilterCount]);

  const buildRow = (booking: Booking) => {
    return (
      <BookingItem
        booking={booking}
        onClick={() => onBookingClick(booking, booking.status)}
        bookingMutate={invalidatePastBookings}
      />
    );
  };

  return (
    <Box
      gap={Spacing.S6}
      marginX={isMobile ? Spacing.S6 : Spacing.S20}
      marginY={Spacing.S11}
      maxWidth={isMobile ? undefined : "936px"}
      direction={FlexDirection.Column}
    >
      {/* Label */}
      <Box flex={1} alignItems={"center"} justifyContent={isMobile ? "flex-end" : "space-between"}>
        {isMobile ? (
          <></>
        ) : (
          <Text
            font={FontFamily.Museo}
            weight={FontWeight.Bold}
            size={FontSize.F24}
            color={Colors.NightBlue}
          >
            Past bookings
          </Text>
        )}
        <FilterButton title="Filter and sort" onClick={() => setShowFilterAndSortModal(true)} filterCount={numOfFilter} />
      </Box>

      {isLoading ? <LoadingSpinner /> : <></>}

      {checkIfEmpty(pastBookings) && !isLoading ? (
        numOfFilter ? (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <NoFilteredBookings sortOrEditFilter={setShowFilterAndSortModal} />
          </Box>
        ) : (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            height={isMobile ? "400px" : "75%"}
            marginY={isMobile ? Spacing.S0 : Spacing.S20}
          >
            <NoBookingsPlaceholder type={NoBookingsPlaceholderType.past} />
          </Box>
        )
      ) : (
        <Box direction={FlexDirection.Column}>
          <Each of={pastBookings} render={buildRow} />
        </Box>
      )}

      {pageCount > 0 ? (
        <Box justifyContent={"flex-end"}>
          <PaginationControl
            currentPage={currentPage}
            pages={pageCount}
            onNext={() => setCurrentPage(currentPage + 1)}
            onPrevious={() => setCurrentPage(currentPage - 1)}
            startFromOne
          />
        </Box>
      ) : (
        <></>
      )}

      <BookingFilterModal
        open={showFilterAndSortModal}
        onClose={() => setShowFilterAndSortModal(false)}
        forUpcoming={false}
      />
    </Box>
  );
};

export default Past;
