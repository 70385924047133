import React, { useState } from "react";
import queryString from "query-string";

import { Box } from "@material-ui/core";
import { Recipient } from "../../../models";
import { useMobile } from "../../../hooks/mobile";
import HeaderSectionControl from "../../../components/HeaderSectionControl";
import { Paths } from "../../../constants/paths";
import { useHistory, useLocation } from "react-router-dom";
import ContentSectionControl from "../../../components/ContentSectionControl";
import BrowseTherapists from "../../Therapists/Browse/BrowseTherapists";
import RecipientFavPro from "../components/RecipientFavPro";
import RecipientBlockedPro from "../components/RecipientBlockedPro";
import RecipientRecentPros from "../components/RecipientRecentPro";

interface RecipientProvidersSectionProps {
  recipient: Recipient;
}

const sections = ["Favourite", "Blocked", "Recent", "Browse"];


const getSegmentIndex = (value: string) => {
  const index = sections.findIndex((item) => value === item);

  return index < 1 ? 0 : index;
};

const RecipientProvidersSection = ({ recipient }: RecipientProvidersSectionProps) => {

  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();

  const query = queryString.parse(location.search) as Record<
    string,
    string | number | undefined | string[]
  >;

  const currentSegment = query.section || "Favourite";

  const [selectedSectionIndex, setSelectedSectionIndex] = useState(getSegmentIndex(currentSegment as string));

  return (
    <Box
      marginTop="43px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <ContentSectionControl
        sections={sections}
        selectedIndex={selectedSectionIndex}
        onSelectedIndex={(index) => {
          history.push(`?section=${sections[index]}`);
          setSelectedSectionIndex(index);
        }}
      />
      {selectedSectionIndex === 0 && <RecipientFavPro recipient={recipient} />}
      {selectedSectionIndex === 1 && <RecipientBlockedPro recipient={recipient} />}
      {selectedSectionIndex === 2 && <RecipientRecentPros recipient={recipient} />}
      {selectedSectionIndex === 3 && <BrowseTherapists />}

    </Box>
  );
};

export default RecipientProvidersSection;
