import { Box, ButtonBase, Link, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../../../constants/colors";
import { ServiceRatePrice } from "../../../../../models";
import PlusIcon from "../../../../../images/plus-icon.svg";
import SelectedIcon from "../../../../../images/selected-icon.png";
import { getValue } from "../../../../../utils/object";

interface StyledButtonProps {
  selected?: boolean;
}

const StyledButton = withStyles({
  root: ({ selected }: StyledButtonProps) => ({
    backgroundColor: selected ? "#EBF8FA" : undefined,
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    textAlign: "left",
    "&:hover": {
      backgroundColor: selected ? "#EBF8FA" : "#ebf8fa4d",
    },
  }),
})(ButtonBase);

interface Props {
  treatment: ServiceRatePrice;
  selected: boolean;
  onSelect: (treatment: ServiceRatePrice) => unknown;
  onLearnMoreClick: (treatment: ServiceRatePrice) => unknown;
  hasFixedDuration?: boolean;
}

const style = {
  fontFamily: "Open Sans",
};

const DropdownItem = ({
  selected,
  onLearnMoreClick,
  onSelect,
  hasFixedDuration = false,
  treatment,
}: Props) => {
  const {
    name,
    label,
    rate,
    price,
    duration,
    shortDescription: description,
    selectedCountry,
  } = treatment;

  const handleLearnMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onLearnMoreClick(treatment);
  };

  const addOnPrice = rate || price;

  return (
    <React.Fragment>
      <StyledButton onClick={() => onSelect(treatment)} selected={selected}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gridGap={15} alignItems="center">
              <>
                {selected ? (
                  <img src={SelectedIcon} alt="selected" style={{ height: 24, width: 25 }} />
                ) : (
                  <img src={PlusIcon} alt="Plus" />
                )}
              </>

              <Box>
                <Typography style={{ ...style, fontSize: "16px", fontWeight: 700 }}>
                  {label || name}
                </Typography>
              </Box>
            </Box>
            {!!addOnPrice && (
              <Typography style={{ ...style, fontSize: "16px", fontWeight: 700 }}>
                {`${getValue(selectedCountry, "currencySymbol", "A$")}${addOnPrice.toFixed(2)}`}
              </Typography>
            )}
          </Box>

          {!!duration && (
            <Box ml="35px">
              <Typography style={{ ...style, fontSize: "14px", color: Colors.BlueyGrey }}>
                {`${!hasFixedDuration ? "Up to " : ""} ${duration}min`}
              </Typography>
            </Box>
          )}

          <Box ml="35px">
            <Typography
              style={{
                ...style,
                fontSize: "14px",
                color: Colors.Dusk,
                paddingRight: "5px",
                margin: "8px 0",
              }}
            >
              {description}
            </Typography>
          </Box>

          <Box ml="35px">
            <Link
              style={{
                fontSize: "14px",
                color: Colors.TurquoiseBlue,
                width: "fit-content",
                fontWeight: 600,
              }}
              onClick={handleLearnMoreClick}
            >
              Learn more
            </Link>
          </Box>
        </Box>
      </StyledButton>
      <Box
        style={{
          height: "1px",
          backgroundColor: Colors.LightPeriwinkle,
          margin: "0 16px",
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(DropdownItem);
