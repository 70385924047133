import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { checkIfEmpty, getValue } from "../../utils/object";
import Dropdown from "../../components/Dropdown";
import TreatmentDropdownMenu from "../../components/Treatment/DropdownMenu";
import { Colors } from "../../constants/colors";
import { capitalizeFirstLetter } from "../../helpers/string";
import { useMobile } from "../../hooks/mobile";
import { BookingQuestion, ServiceRatePrice } from "../../models";
import { useAlertStore } from "../../stores/alert";
import {
  getMassageService,
  getServiceCategoryProfession,
  getSelectedAddOnsForTreatment,
  getTreatmentAddOns,
  isBackToBackMassageType,
  isCoupleMassageType,
  isSingleMassageType,
  resetAnswers,
  setMassageTreatments,
  setTreatmentAddOn,
  useBookingStore,
  useDateTimeStore,
  isMultipleAddonsAllowed,
  resetTreatmentAddOn,
} from "../../stores/booking";
import GenderFallback from "./GenderFallback";
import CouplesFallback from "./CouplesFallback";
import AlertModal from "../../components/AlertModal";
import Questions from "../../components/Bookings/Questions";
import { useQuestions } from "../../hooks/question.hooks";
import { DEFAULT_CURRENCY } from "../../constants/booking";
import { BOOKING_SESSION_TYPE } from "../../helpers/booking";
import AddOnDropdownMenu from "../../components/Bookings/V2/AddOn/DropdownMenu/AddOnDropdownMenu";

interface Props {
  onInfoModalChange: (key: any, value: boolean) => unknown;
  genderFallback: {
    message: string;
    visible: boolean;
    showGenderFallback: (value: boolean) => unknown;
  };
  onTreatmentChanged: (index: number, id: number) => unknown;
}

interface MassageQuestionsProps {
  index: number;
  currency?: string;
  treatmentId?: number;
  questions: BookingQuestion[];
  disabled?: boolean;
}

export default function MassageDetail({
  onInfoModalChange,
  genderFallback,
  onTreatmentChanged,
}: Props): JSX.Element {
  const isMobile = useMobile();

  const {
    sessionType,
    setSessionType,
    massageLength,
    setMassageLength,
    massageLength2,
    setMassageLength2,
    massageType1,
    setMassageType1,
    genderPreference1,
    setGenderPreference1,
    massageType2,
    setMassageType2,
    genderPreference2,
    setGenderPreference2,
    genderFallback: genderFallbackCheckBox,
    setGenderFallback: setGenderFallbackCheckBox,
    updatingForBooking,
    massageTreatmentDetails,
    serviceId,
    currencySymbol,
  } = useBookingStore();

  const [sessionTypeErrorModalOpen, setSessionTypeErrorModalOpen] = useState(false);
  const { setErrorMessage } = useAlertStore();

  useEffect(() => {
    if (isSingleMassageType(sessionType)) {
      const index = 1;
      setMassageTreatments({}, index);
    }
  }, [sessionType]);

  const {
    genderPreferences: serviceGenderPreferences = [],
    duration: serviceDurations = [],
    prices: serviceMassageTypes = [],
    sessionTypes: serviceSessionTypes = [],
  } = getMassageService();

  const { setLatestStartInMinutes, earliestStartInMinutes } = useDateTimeStore();

  const isOldB2BMassage =
    updatingForBooking &&
    isBackToBackMassageType(updatingForBooking.sessionType) &&
    updatingForBooking.massageDuration;
  const isBackToBackMassage = !isOldB2BMassage && isBackToBackMassageType(sessionType);

  const isCouplesMassage = isCoupleMassageType(sessionType);

  const sessionTypeOptions = serviceSessionTypes.map((data) => {
    return {
      value: data.value,
      title: data.title,
    };
  });

  const massageLengthOptions = serviceDurations.map((data) => {
    return {
      value: data.value,
      title: data.title,
    };
  });

  const genderPreferenceOptions = serviceGenderPreferences.map((preference) => {
    return {
      value: preference.value,
      title: preference.title,
      helperText: preference.helpText || undefined,
    };
  });

  const handleFirstMassageTypeSelect = (treatment: ServiceRatePrice) => {
    setMassageType1(treatment.name);
    const index = 0;
    resetTreatmentAddOn({ treatmentIndex: index, treatmentId: treatment.id });
    setMassageTreatments(
      {
        ...massageTreatmentDetails[index],
        treatmentTypeId: treatment.id,
        isMultipleAddonsAllowed: treatment?.isMultipleAddonsAllowed,
      },
      index,
    );
    onTreatmentChanged(0, treatment.id);
  };

  const handleSecondMassageTypeSelect = (treatment: ServiceRatePrice) => {
    setMassageType2(treatment.name);
    const index = 1;
    resetTreatmentAddOn({ treatmentIndex: index, treatmentId: treatment.id });
    setMassageTreatments(
      {
        ...massageTreatmentDetails[index],
        treatmentTypeId: treatment.id,
        isMultipleAddonsAllowed: treatment?.isMultipleAddonsAllowed,
      },
      index,
    );
    onTreatmentChanged(1, treatment.id);
  };

  const handleAddOnSelect = ({
    treatment,
    treatmentIndex,
    isMultipleAddonsAllowed,
  }: {
    treatment: ServiceRatePrice;
    treatmentIndex: number;
    isMultipleAddonsAllowed: boolean;
  }) => {
    setTreatmentAddOn({
      treatment,
      treatmentIndex,
      isMultipleSelectAllow: isMultipleAddonsAllowed,
    });
  };

  const validateSessionTobeSingle = (selectedSessionType: String) => {
    if (!updatingForBooking) {
      return true;
    }

    // for updating booking get acual booking session type
    const bookingSessionType = getValue(updatingForBooking, "sessionType");

    if (
      bookingSessionType === BOOKING_SESSION_TYPE.COUPLES &&
      (selectedSessionType === BOOKING_SESSION_TYPE.SINGLES ||
        selectedSessionType === BOOKING_SESSION_TYPE.BACK_TO_BACK)
    ) {
      return false;
    }
    if (
      (bookingSessionType === BOOKING_SESSION_TYPE.SINGLES ||
        bookingSessionType === BOOKING_SESSION_TYPE.BACK_TO_BACK) &&
      selectedSessionType === BOOKING_SESSION_TYPE.COUPLES
    ) {
      return false;
    }
    return true;
  };

  const questions = useQuestions(serviceId as number, massageTreatmentDetails);
  const treatmentQuestions = questions.filter((question) => !!question.treatmentId);
  const serviceQuestions = questions.filter((question) => !question.treatmentId);

  const handleNoneSelect = (treatmentIndex: number) => {
    resetTreatmentAddOn({ treatmentIndex });
  };

  const handleSessionTypeChange = (option: any) => {
    if (!validateSessionTobeSingle(option.value)) {
      setSessionTypeErrorModalOpen(true);
      return;
    }

    if (option.value !== sessionType && updatingForBooking?.isBookedByPro) {
      return setErrorMessage("Cannot change session type for this booking.");
    }

    setSessionType(option.value);

    const treatment = serviceMassageTypes.find(
      (treatment: ServiceRatePrice) => treatment.name === massageType1,
    );

    setMassageTreatments(
      {
        duration: massageLength,
        treatmentTypeId: treatment?.id,
      },
      0,
    );

    resetAnswers(serviceId as number, treatment?.id ? [treatment?.id] : []);

    if (isCoupleMassageType(option.value)) {
      earliestStartInMinutes && setLatestStartInMinutes(earliestStartInMinutes);
    }
    const index = 1;
    if (isBackToBackMassageType(option.value) || isCoupleMassageType(option.value)) {
      massageLength && setMassageLength2(massageLength);
      massageType1 && setMassageType2(massageType1);
      const treatment = serviceMassageTypes.find(
        (treatment) => treatment.name === massageType1,
      );
      setMassageTreatments(
        { duration: massageLength, treatmentTypeId: treatment?.id },
        index,
      );
    } else {
      setMassageTreatments({}, index);
    }
  };

  const isPartiallyConfirmedCouples = () => {
    if (sessionType !== BOOKING_SESSION_TYPE.COUPLES ) return false;
    if (!updatingForBooking || checkIfEmpty(updatingForBooking)) return false;

    const therapists = (updatingForBooking.bookingdetails || [{}]).map(({ job }) => getValue(job, "therapistId") || null);

    const isPending = therapists.every((id) => id === null);
    if (isPending) return false;

    return therapists.some((id) => id === null);
  };

  const getPartiallyConfirmedIndex = () => {
    const partiallyConfirmedCouples = isPartiallyConfirmedCouples();
    if (!partiallyConfirmedCouples || !updatingForBooking) return -1;

    const therapists = (updatingForBooking.bookingdetails || [{}]).map(({ job }) => getValue(job, "therapistId") || null);
    return therapists.findIndex((id) => !!id);
  };

  const confirmedTreatmentIndex = getPartiallyConfirmedIndex();
  const partiallyConfirmedCouples = isPartiallyConfirmedCouples();

  const allowDetailChange = (index: number) => {
    if (!partiallyConfirmedCouples) return true;
    if (confirmedTreatmentIndex !== index) return true;

    return false;
  };

  return (
    <>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Box style={{ width: "100%" }}>
          <Dropdown
            title="Session type"
            options={sessionTypeOptions}
            isDisabled={updatingForBooking?.isBookedByPro}
            selectedOption={sessionTypeOptions.find((option) => option.value === sessionType)}
            onSelectedOption={(option) => handleSessionTypeChange(option)}
            onInfoClicked={() => {
              if (updatingForBooking?.isBookedByPro) return;

              onInfoModalChange("sessionType", true);
            }}
          />
          {isCouplesMassage && !partiallyConfirmedCouples && <CouplesFallback />}
        </Box>

        <Box width="60px" />
        {(isBackToBackMassage || isCouplesMassage) && <Box width="100%" />}

        {!(isBackToBackMassage || isCouplesMassage) && (
          <Dropdown
            title={"Duration"}
            options={massageLengthOptions}
            selectedOption={massageLengthOptions.find((option) => option.value === massageLength)}
            onSelectedOption={(option) => {
              setMassageLength(option.value);
              const index = 0;
              setMassageTreatments(
                { ...massageTreatmentDetails[index], duration: option.value },
                index,
              );
            }}
          />
        )}
      </Box>

      <Box
        display="flex"
        flexWrap={"wrap"}
        style={{ gap: "0px 30px" }}
        flexDirection={isMobile ? "column" : "row"}
      >
        {!(isBackToBackMassage || isCouplesMassage) && (
          <>
            <Box style={{ width: isMobile ? "100%" : "48%" }}>
              <TreatmentDropdownMenu
                label="Treatment type"
                treatments={serviceMassageTypes}
                onSelect={handleFirstMassageTypeSelect}
                value={massageType1}
                selectedDuration={massageLength ?? undefined}
              />
            </Box>

            {!checkIfEmpty(getTreatmentAddOns({ treatmentIndex: 0 })) && (
              <Box style={{ width: isMobile ? "100%" : "48%" }}>
                <AddOnDropdownMenu
                  label="Treatment add-ons"
                  treatments={getTreatmentAddOns({ treatmentIndex: 0 })}
                  selectedAddOn={getSelectedAddOnsForTreatment({ treatmentIndex: 0 })}
                  onSelect={handleAddOnSelect}
                  value={massageType1}
                  treatmentIndex={0}
                  isMultipleAddonsAllowed={isMultipleAddonsAllowed({ treatmentIndex: 0 })}
                  onNoneSelect={handleNoneSelect}
                />
              </Box>
            )}

            <MassageQuestions
              index={0}
              currency={currencySymbol}
              questions={treatmentQuestions}
              treatmentId={massageTreatmentDetails[0].treatmentTypeId as number}
            />
          </>
        )}

        {!isCouplesMassage && (
          <Box style={{ width: isMobile ? "100%" : "48%" }}>
            <Dropdown
              title={`${capitalizeFirstLetter(
                getServiceCategoryProfession() || "Practitioner",
              )} gender`}
              options={genderPreferenceOptions}
              selectedOption={genderPreferenceOptions.find(
                (option) => option.value === genderPreference1,
              )}
              onSelectedOption={(option) => {
                setGenderPreference1(option.value);
                genderFallback.showGenderFallback(false);
                setGenderFallbackCheckBox(false);
              }}
            />
            {genderFallback.visible && (
              <GenderFallback
                onChange={setGenderFallbackCheckBox}
                genderFallback={genderFallbackCheckBox}
                genderFallbackMessage={genderFallback.message}
                onInfoClick={(value) => onInfoModalChange("genderFallback", value)}
              />
            )}
          </Box>
        )}
        {(isBackToBackMassage || isCouplesMassage) && <Box width="100%" />}
      </Box>

      {(isBackToBackMassage || isCouplesMassage) && (
        <>
          <Box>
            <Box
              fontFamily="Museo"
              fontWeight={700}
              fontSize="24px"
              color={Colors.NightBlue}
              mt="24px"
              mb="8px"
            >
              Person 1
            </Box>
            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <TreatmentDropdownMenu
                disabled={!allowDetailChange(0)}
                label="Treatment type"
                treatments={serviceMassageTypes}
                onSelect={handleFirstMassageTypeSelect}
                value={massageType1}
                dropDirection={isBackToBackMassage && !isMobile ? "up" : "down"}
                selectedDuration={massageLength ?? undefined}
              />
              <Box width="60px" />

              <Dropdown
                isDisabled={!allowDetailChange(0)}
                title={"Duration"}
                options={massageLengthOptions}
                selectedOption={massageLengthOptions.find(
                  (option) => option.value === massageLength,
                )}
                onSelectedOption={(option) => {
                  setMassageLength(option.value);
                  const index = 0;
                  setMassageTreatments(
                    { ...massageTreatmentDetails[index], duration: option.value },
                    index,
                  );
                }}
              />
            </Box>

            <Box
              display="flex"
              flexWrap={"wrap"}
              style={{ gap: "0px 30px" }}
              flexDirection={isMobile ? "column" : "row"}
            >
              {!checkIfEmpty(getTreatmentAddOns({ treatmentIndex: 0 })) && (
                <Box style={{ width: isMobile ? "100%" : "48%" }}>
                  <AddOnDropdownMenu
                    label="Treatment add-ons"
                    treatments={getTreatmentAddOns({ treatmentIndex: 0 })}
                    selectedAddOn={getSelectedAddOnsForTreatment({ treatmentIndex: 0 })}
                    onSelect={handleAddOnSelect}
                    value={massageType1}
                    treatmentIndex={0}
                    dropDirection={isBackToBackMassage && !isMobile ? "up" : "down"}
                    isMultipleAddonsAllowed={isMultipleAddonsAllowed({ treatmentIndex: 0 })}
                    onNoneSelect={handleNoneSelect}
                    disabled={!allowDetailChange(0)}
                  />
                </Box>
              )}

              <MassageQuestions
                index={0}
                currency={currencySymbol}
                questions={treatmentQuestions}
                disabled={!allowDetailChange(0)}
                treatmentId={massageTreatmentDetails[0].treatmentTypeId as number}
              />
              {isCouplesMassage && (
                <>
                  <Box style={{ width: isMobile ? "100%" : "48%" }}>
                    <Dropdown
                      isDisabled={!allowDetailChange(0)}
                      title={`${capitalizeFirstLetter(
                        getServiceCategoryProfession() || "Practitioner",
                      )} gender`}
                      options={genderPreferenceOptions}
                      selectedOption={genderPreferenceOptions.find(
                        (option) => option.value === genderPreference1,
                      )}
                      onSelectedOption={(option) => {
                        setGenderPreference1(option.value);
                        genderFallback.showGenderFallback(false);
                        setGenderFallbackCheckBox(false);
                      }}
                    />
                    {genderFallback.visible && (
                      <GenderFallback
                        onInfoClick={(value) => onInfoModalChange("genderFallback", value)}
                        onChange={setGenderFallbackCheckBox}
                        genderFallback={genderFallbackCheckBox}
                        genderFallbackMessage={genderFallback.message}
                      />
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box>
            <Box
              fontFamily="Museo"
              fontWeight={700}
              fontSize="24px"
              color={Colors.NightBlue}
              mt="24px"
              mb="8px"
            >
              Person 2
            </Box>
            <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
              <TreatmentDropdownMenu
                label="Treatment type"
                treatments={serviceMassageTypes}
                onSelect={handleSecondMassageTypeSelect}
                value={massageType2}
                dropDirection={isMobile ? "down" : "up"}
                selectedDuration={massageLength2 ?? undefined}
              />

              <Box width="60px" />

              <Dropdown
                title={"Duration"}
                options={massageLengthOptions}
                isDisabled={!allowDetailChange(1)}
                selectedOption={massageLengthOptions.find(
                  (option) => option.value === massageLength2,
                )}
                onSelectedOption={(option) => {
                  setMassageLength2(option.value);
                  const index = 1;
                  setMassageTreatments(
                    { ...massageTreatmentDetails[index], duration: option.value },
                    index,
                  );
                }}
              />
            </Box>
            <Box
              display="flex"
              flexWrap={"wrap"}
              style={{ gap: "0px 30px" }}
              flexDirection={isMobile ? "column" : "row"}
            >
              {!checkIfEmpty(getTreatmentAddOns({ treatmentIndex: 1 })) && (
                <Box style={{ width: isMobile ? "100%" : "48%" }}>
                  <AddOnDropdownMenu
                    label="Treatment add-ons"
                    treatments={getTreatmentAddOns({ treatmentIndex: 1 })}
                    selectedAddOn={getSelectedAddOnsForTreatment({ treatmentIndex: 1 })}
                    onSelect={handleAddOnSelect}
                    value={massageType1}
                    treatmentIndex={1}
                    dropDirection={isBackToBackMassage && !isMobile ? "up" : "down"}
                    isMultipleAddonsAllowed={isMultipleAddonsAllowed({ treatmentIndex: 1 })}
                    onNoneSelect={handleNoneSelect}
                    disabled={!allowDetailChange(1)}
                  />
                </Box>
              )}

              <MassageQuestions
                index={1}
                currency={currencySymbol}
                questions={treatmentQuestions}
                disabled={!allowDetailChange(1)}
                treatmentId={getValue(massageTreatmentDetails[1], "treatmentTypeId") as number} // when switching from hair and makeup to massage index [1] is undefined
              />

              {isCouplesMassage && (
                <>
                  <Box style={{ width: isMobile ? "100%" : "48%" }}>
                    <Dropdown
                      isDisabled={!allowDetailChange(1)}
                      title={`${capitalizeFirstLetter(
                        getServiceCategoryProfession() || "Practitioner",
                      )} gender`}
                      options={genderPreferenceOptions}
                      selectedOption={genderPreferenceOptions.find(
                        (option) => option.value === genderPreference2,
                      )}
                      onSelectedOption={(option) => setGenderPreference2(option.value)}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </>
      )}

      <MassageQuestions questions={serviceQuestions} index={0} currency={currencySymbol} />

      <AlertModal
        open={sessionTypeErrorModalOpen}
        title="Session Type"
        message="To change the session type, please cancel this booking and create a new one with your preferred session type."
        onClose={() => setSessionTypeErrorModalOpen(false)}
      />
    </>
  );
}

const MassageQuestions = ({
  index,
  questions,
  treatmentId,
  currency = DEFAULT_CURRENCY.currencySymbol,
  disabled = false,
}: MassageQuestionsProps) => {
  const isMobile = useMobile();

  if (!questions.length) return <></>;
  return (
    <>
      <Questions
        style={{ width: isMobile ? "100%" : "48%" }}
        questions={questions}
        index={index}
        treatmentId={treatmentId}
        currency={currency}
        disabled={disabled}
      />
    </>
  );
};
