import "./Calendar.css";
import CalendarCell from "./components/CalendarCell";
import { flexDisplayStyle } from "./config";
import { DayInterface, ScheduleDataInterface } from "./interface";

interface Props {
  rowIndex: number;
  rowLength: number;
  data: ScheduleDataInterface;
  baseTime: string;
  weekDays: DayInterface[];
  baseWidthInPx: number;
  ignoreAvailabilities?: boolean;
}

// baseIndex: rowNumber
const CalendarRow = ({ rowIndex, data, baseTime, weekDays, baseWidthInPx, ignoreAvailabilities }: Props) => {
  const checkIfDataIsValid = (baseTime: string, weekDay: number, data: ScheduleDataInterface) => {
    let isAvailable = false;
    const availabilityList = data.availability;
    const weekDayAvailability = availabilityList.find((av) => parseInt(av.weekDay) === weekDay);

    if (weekDayAvailability && weekDayAvailability.isActive) {
      const dayStartTime = parseInt(weekDayAvailability.startTime);
      const dayEndTime = parseInt(weekDayAvailability.endTime);
      const dayTime = parseInt(baseTime);

      isAvailable = dayTime >= dayStartTime && dayTime < dayEndTime;
    }

    return isAvailable;
  };

  return (
    <div style={flexDisplayStyle}>
      {weekDays.map((weekDay: DayInterface) => {
        const isAvailable = checkIfDataIsValid(baseTime, weekDay.weekDay, data);
        return (
          <CalendarCell
            style={{ width: `${baseWidthInPx}px` }}
            className={(isAvailable || ignoreAvailabilities) ? "ProAvailable" : "ProUnAvailable"}
          >
            {isAvailable}
          </CalendarCell>
        );
      })}
    </div>
  );
};

export default CalendarRow;
