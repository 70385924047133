import { Box } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Colors } from "../../../constants/colors";
import DropdownValueHolder from "../../Treatment/DropdownMenu/DropdownValueHolder";
import { FontFamily, FontSize } from "../Styled/enum";
import { DropdownOption } from "../../Dropdown";
import { DropdownItems } from "./DropdownItems";

interface Props {
  onSelect: (value: DropdownOption) => unknown;
  disabled?: boolean;
  placeholder?: string;
  selectedValues: string[];
  options: DropdownOption[];
}
export const MultSelectDropDown = ({
  onSelect,
  disabled = false,
  placeholder = "Select",
  selectedValues,
  options,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedValuesString = selectedValues.join(" | ") || "none";

  const handleSelect = (option: DropdownOption) => {
    onSelect(option);
  };
  return (
    <>
      <div ref={containerRef} style={{ width: "100%" }}>
        <Box
          position={"relative"}
          width="100%"
          fontFamily={FontFamily.Museo}
          fontSize={FontSize.F14}
          color={Colors.Dusk}
        >
          <Box paddingY="1px">
            <DropdownValueHolder
              disabled={disabled}
              placeholder={placeholder || "Select"}
              value={selectedValuesString}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              typographyStlyle={{
                height: undefined,
              }}
            />
            <Box bgcolor={Colors.LightPeriwinkle} height="2px" width="100%" />
          </Box>

          <Box
            style={{
              display: dropdownVisible ? "block" : "none",
              position: "absolute",
              backgroundColor: Colors.White,
              boxShadow: "0 4px 10px 0 rgba(10,3,41,0.2)",
              marginTop: "10px",
              width: "100%",
              borderRadius: "11px",
            }}
          >
            {(options || []).map((option: DropdownOption) => {
              return (
                <DropdownItems
                  selected={
                    selectedValues?.find((selectedValue: string) => selectedValue === option.value)
                      ? true
                      : false
                  }
                  option={option}
                  onSelect={() => {
                    handleSelect(option);
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </div>
    </>
  );
};
