import React from "react";
import { Box, Link } from "@material-ui/core";
import { PROVIDER_OPTIONS_VALUE } from "./enum";
import { Colors } from "../../../constants/colors";
import NoRecentPro from "../../../images/empty-state-recent.png";
import NoRecommendedPro from "../../../images/no-providers-placeholder.svg";

interface Props {
  onFallbackClick?: () => unknown;
  selectedProviderOption: (typeof PROVIDER_OPTIONS_VALUE)[keyof typeof PROVIDER_OPTIONS_VALUE];
}

const NoProviders = ({ onFallbackClick, selectedProviderOption }: Props) => {
  const info =
    selectedProviderOption === PROVIDER_OPTIONS_VALUE.RECENT_PROVIDERS
      ? { message: "No previous providers for the given booking", icon: NoRecentPro }
      : { message: "No top rated providers in your area", icon: NoRecommendedPro };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"300px"}
      alignItems={"center"}
      gridGap={"8px"}
    >
      <Box width={"40px"}>
        <img src={info.icon} alt={"No Providers"} width={"40px"}/>
      </Box>
      <Box
        fontFamily={"Museo"}
        fontWeight={700}
        lineHeight={"21px"}
        fontSize={"14px"}
        color={Colors.Dusk}
        textAlign={"center"}
      >
        {info.message}
      </Box>
      <Box
        fontFamily="Open Sans"
        fontSize={"12px"}
        fontWeight={400}
        lineHeight={"15px"}
        color={Colors.Dusk}
        textAlign={"center"}
        maxWidth={"220px"}
      >
        Select{" "}
        <Link style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }} onClick={onFallbackClick}>
          first available
        </Link>{" "}
        for the fastest way to get your booking confirmed.
      </Box>
    </Box>
  );
};

export default NoProviders;
