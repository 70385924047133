import React from "react";
import { Box, Typography } from "@material-ui/core";
import { PulsatingCircle } from "@get-blys/ui.pulsating-circle";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
} from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";
import TimelineSuccessIcon from "./../../images/timeline-success-icon.png";
import TimelineErrorIcon from "./../../images/timeline-error-icon.png";

interface Items {
  label: string;
  description: string;
  isCompleted: boolean;
  isInProcess: boolean;
  isFutureStatus: boolean;
  iconType: string;
}
interface Props {
  items: Items[];
}

export const TIMELINE_ICON_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
};
const getIcon = (type: string) => {
  switch (type) {
  case TIMELINE_ICON_TYPE.SUCCESS:
    return TimelineSuccessIcon;
  case TIMELINE_ICON_TYPE.ERROR:
    return TimelineErrorIcon;
  default:
    return TimelineSuccessIcon;
  }
};
export const Timeline = ({ items }: Props) => {
  const statusIcon = ({
    isInProcess,
    isFutureStatus,
    iconType = TIMELINE_ICON_TYPE.SUCCESS,
  }: {
    isInProcess: boolean;
    isFutureStatus: boolean;
    iconType: string;
  }) => {
    if (isInProcess) {
      return (
        <Box width={Spacing.S3} height={Spacing.S3}>
          <PulsatingCircle style={{ top: "10px" }} />
        </Box>
      );
    } else if (isFutureStatus) {
      return (
        <Box
          borderRadius={Spacing.S3}
          width={"18px"}
          height={"18px"}
          display={Display.Flex}
          justifyContent={JustifyContent.center}
          alignItems={AlignItems.center}
          style={{ backgroundColor: Colors.White }}
        >
          <Box
            borderRadius={Spacing.S3}
            width={"11px"}
            height={"11px"}
            style={{ backgroundColor: Colors.LightBlueGrey }}
          />
        </Box>
      );
    } else {
      return (
        <Box width={Spacing.S4} height={Spacing.S4}>
          <img
            src={getIcon(iconType)}
            alt="status-icon"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      );
    }
  };

  return (
    <Box paddingTop={Spacing.S1}>
      {items.map(
        ({ label, description, isCompleted, isInProcess, isFutureStatus, iconType }, index) => {
          return (
            <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
              <Box
                display={Display.Flex}
                flexDirection={FlexDirection.Row}
                style={{
                  gap: Spacing.S4,
                }}
              >
                <Box
                  display={Display.Flex}
                  flexDirection={FlexDirection.Column}
                  alignItems={AlignItems.center}
                  style={{
                    marginLeft: Spacing.S2,
                    width: "8px",
                    transform: "translateY(5%)",
                  }}
                >
                  <Box>
                    {statusIcon({
                      isInProcess,
                      isFutureStatus,
                      iconType,
                    })}
                  </Box>
                  {index !== items.length - 1 ? (
                    <Box
                      flex={1}
                      width={"2px"}
                      style={{
                        height: "100%",
                        backgroundColor: isCompleted ? Colors.TurquoiseBlue : Colors.LightBlueGrey,
                      }}
                    />
                  ) : (
                    <Box />
                  )}
                </Box>
                <Box
                  style={{
                    paddingBottom: Spacing.S4,
                    flex: 1,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.Museo,
                      fontWeight: FontWeight.Bold,
                      fontSize: FontSize.F16,
                      lineHeight: LineHeight.L24,
                      color: Colors.NightBlue,
                    }}
                  >
                    {label}
                  </Typography>
                  <Box
                    style={{
                      fontFamily: FontFamily.OpenSans,
                      fontWeight: FontWeight.Regular,
                      fontSize: FontSize.F14,
                      lineHeight: LineHeight.L21,
                      color: Colors.AppGrey,
                    }}
                  >
                    {description}
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        }
      )}
    </Box>
  );
};
