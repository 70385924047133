import { post } from "../../api/client";
import { environment } from "../../constants/common";
import { COUNTRY } from "../../constants/countries";
import { DATA_LAYER, VOUCHER_PURCHASE_REVENUE_WEIGHTAGE } from "../../constants/event";
import { GTAG_ID } from "../../constants/gtag";
import { SITE_URLS } from "../../constants/links";
import { fetchCountryCode } from "../../utils/country";
import { limitDecimals } from "../../utils/number";

// global declaration for window
declare global {
	interface Window {
			dataLayer:any;
	}
}

export const getGtmId = async () => {
  const host = window.location.hostname;

  if (host === SITE_URLS.au) {
    return GTAG_ID.AU;
  }

  const countryCode = await fetchCountryCode(false);

  if(countryCode === COUNTRY.GB) {
    return GTAG_ID.GB;
  }

  if(countryCode === COUNTRY.US) {
    return GTAG_ID.US;
  }

  return GTAG_ID.AU;
};

const trackOrderCompleted = (data: any, user: any) => {
  window.dataLayer = window.dataLayer || [];

  const transformedData: any = {
    event: DATA_LAYER.ORDER_COMPLETED,
    visitorId: data.userId,
    ecommerce: {
      email: user.email,
      phoneNumber: user.phone_number,
      firstName: user.firstName,
      lastName: user.lastName,
      street: user.street,
      city: user.city,
      region: user.region,
      country: user.country,
      postalCode: user.postalCode,
      currencyCode: data.currency,
      purchase: {
        actionField: {
          id: data.braintreeTransactionId,
          affiliation: "Website",
          revenue: data.priceWithoutDiscount,
          weightedRevenue: parseFloat((+data.priceWithoutDiscount * 0.06).toFixed(2)),
          tax: 0,
          coupon: data.coupon ? data.coupon.code : null,
        },
        products: [
          {
            name: "Massage",
            id: data.id,
            price: data.priceWithoutDiscount,
            category: data.sessionType,
            variant: data.massageDuration,
            quantity: 1,
          },
        ],
      },
    },
  };

  window.dataLayer.push(transformedData);

  transformedData.createdAt = data.createdAt;
  transformedData.bookingEarliestDate = data.earliestTime;
  transformedData.bookingLatestDate = data.latestTime;

  //track gmt analyticsValue in production only
  if(environment.isProduction) {
    logGTM(transformedData, "gtm");
  }
};

const trackVoucherOrderCompleted = (data: any) => {
  window.dataLayer = window.dataLayer || [];

  const transformedData = {
    event: DATA_LAYER.VOUCHER_PURCHASE_COMPLETED,
    giftVoucherID: data.id,
    giftValue: data.price,
    giftValueRevenue: limitDecimals((data.price + data.clientPlatformFee) * VOUCHER_PURCHASE_REVENUE_WEIGHTAGE),
  };

  window.dataLayer.push(transformedData);
};

const logGTM = (data: any, type="gtm") => {
  const BASE_URL = "api/v2/log";
  return post(BASE_URL, {data, type});
};

export default {
  getGtmId,
  trackOrderCompleted,
  trackVoucherOrderCompleted
};
