import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import "./stripeSubmit.css";
import Dialog from "../../../Dialog";
import { Text } from "../../../v2/Styled";
import * as Styled from "../../../v2/Styled/enum";
import Button, { ButtonType } from "../../../Button";
import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
import SuccessAnimation from "../../../LottieAnimation/SuccessAnimation";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

const StripeSubmitSuccessModal = ({ open, onClose }: Props): JSX.Element => {
  const actionComponentRef = useRef(null);
  const [isAnimationComplete, setIsAnimationComplete] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setIsAnimationComplete(false);
    }, 500);
  };

  const isMobile = useMobile();

  const onAnimationComplete = () => setIsAnimationComplete(true);

  return (
    <Dialog open={open} fullWidth={isMobile} maxWidth={"xs"} onClose={onClose}>
      <div className={"wrapper"}>
        <div className={"check-wrapper"}></div>
        <Box
          width={isMobile ? "100%" : "444px"}
          display={Styled.Display.Flex}
          alignItems={Styled.AlignItems.center}
          justifyContent={Styled.JustifyContent.center}
          className={`animation-wrapper ${isAnimationComplete ? "collapsed" : "expanded"}`}
        >
          <SuccessAnimation
            loop={false}
            autoPlay={true}
            styles={{ width: "300px", height: "300px" }}
            onAnimationComplete={onAnimationComplete}
          />
        </Box>
        <div
          id={"action-wrapper-id"}
          ref={actionComponentRef}
          className={`action-wrapper ${isAnimationComplete ? "desc-collapsed" : "desc-expanded"}`}
          style={{
            gap: Styled.Spacing.S6,
            display: Styled.Display.Flex,
            paddingLeft: Styled.Spacing.S6,
            paddingRight: Styled.Spacing.S6,
            marginBottom: Styled.Spacing.S6,
            flexDirection: Styled.FlexDirection.Column,
          }}
        >
          <Box
            gridGap={Styled.Spacing.S4}
            display={Styled.Display.Flex}
            flexDirection={Styled.FlexDirection.Column}
          >
            <Box style={{ textAlign: "center" }}>
              <Text
                color={Colors.Indigo}
                size={Styled.FontSize.F24}
                font={Styled.FontFamily.Museo}
                weight={Styled.FontWeight.Bold}
              >
                Banking information submitted
              </Text>
            </Box>
            <Box style={{ textAlign: "center" }}>
              <Text color={Colors.Grey} lineHeight={Styled.LineHeight.L24}>
                Your banking information has been submitted and is in review. Verification can take
                up to 2 business days.
              </Text>
            </Box>
          </Box>
          <Box>
            <Button onClick={handleClose} title={"Sweet, thanks"} type={ButtonType.outlined} />
          </Box>
        </div>
      </div>
    </Dialog>
  );
};

export default StripeSubmitSuccessModal;
