import { Box } from "@mui/material";
import { Colors } from "../../../constants/colors";
import { formatToPrice } from "../../../helpers/number";

interface Props {
  label: string;
  text: string;
  value: number;
  details: any;
  currencySymbol: string;
  onClick: (details: any) => unknown;
}

const PayoutSummaryItem = ({ label, value, details, text, currencySymbol, onClick }: Props) => {
  const isClickable = !!details;
  const labelStyle = isClickable ? {...styles.payoutText, ...styles.clickable}: styles.payoutText;

  const handleClick = () => {
    if (!isClickable) return;
    onClick(details);
  };

  return <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
    <Box style={labelStyle} onClick={() => handleClick()}>
      {label}
    </Box>
    <Box style={styles.payoutText}>{text}</Box>
  </Box>;
};

const styles = {
  payoutText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  }
};

export default PayoutSummaryItem;