import { useQuery } from "@tanstack/react-query";
import { requestBookingCancel } from "../../services/bookings/bookings.service";
import { useAccessToken } from "../common";

const KEYS = {
  CANCELLATION_FEE_FOR_FUTURE_BOOKINGS: "CANCELLATION_FEE_FOR_FUTURE_BOOKINGS",
};

interface CancellationInfo {
  cancellationFee: number;
  clientPlatformFee: number;
  totalCancellationFee: number;
  platformFeeLabel: string;
  cancellationWarningMessage: string;
  totalBookings: number;
}

export const useCancellationFeeForFutureBookings = (bookingId: number) => {
  const token = useAccessToken();

  const { data, isLoading } = useQuery(
    [KEYS.CANCELLATION_FEE_FOR_FUTURE_BOOKINGS, bookingId],
    async (): Promise<CancellationInfo> => {
      return requestBookingCancel(bookingId, {
        accessToken: token,
        reason: "Future booking cancellation",
        onlyFutureBookings: true,
      }).then((response) => response.data);
    },
    {
      staleTime: 1000 * 60 * 1, // 1 minutes
      refetchOnWindowFocus: true,
    }
  );

  return {
    isLoading,
    ...(data || {}),
  };
};
