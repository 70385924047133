import { Box } from "@material-ui/core";
import axios from "axios";
import React from "react";
import useSWR from "swr";
import Dropdown, { DropdownOption } from "../../../components/Dropdown";
import UploadedFileRow from "../../../components/ProDashboard/UploadedFileRow";
import { Colors } from "../../../constants/colors";
import { parseApiError } from "../../../helpers/error";
import { useAccessToken } from "../../../hooks/common";
import { UploadedFile } from "../../../models";
import { useAlertStore } from "../../../stores/alert";
import { useUserStore } from "../../../stores/user";
import Button, { ButtonType } from "../../../components/Button";
import { Checkbox } from "@material-ui/core";
import {
  fetchMyTherapistProfile,
  useTherapistStore,
} from "../../../stores/therapist";

const yearsOfExperienceOptions = [
  { value: "less-1", title: "Less than 1 year" },
  { value: "1-3", title: "1-3 years" },
  { value: "3-5", title: "3-5 years" },
  { value: "5+", title: "5+ years" },
  { value: "none", title: "None" },
] as DropdownOption[];

const memberOfIndustryAssociationOptions = [
  { value: true, title: "Yes" },
  { value: false, title: "No" },
] as DropdownOption[];

const qualificationFileType = "qualification";
const industryMembershipFileType = "associationmembership";

export default function () {
  const accessToken = useAccessToken();
  const { user } = useUserStore();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();
  const { therapistUser } = useTherapistStore();

  const [yearsOfExperience, setYearsOfExperience] = React.useState<
    null | string
  >(null);
  const [memberOfAssociation, setMemberOfAssociation] = React.useState(false);

  const { data: uploadedFilesData, mutate: uploadedFilesMutate } = useSWR(
    `/therapists/me/files?accessToken=${accessToken}`
  );
  const uploadedQualificationFiles = uploadedFilesData
    ? (uploadedFilesData as UploadedFile[]).filter(
        (file) => file.type === qualificationFileType
      )
    : [];

  const uploadedIndustryMembershipFiles = uploadedFilesData
    ? (uploadedFilesData as UploadedFile[]).filter(
        (file) => file.type === industryMembershipFileType
      )
    : [];

  const qualificationsFileUploadRef = React.useRef<HTMLInputElement>(null);
  const industryFileUploadRef = React.useRef<HTMLInputElement>(null);

  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    fetchMyTherapistProfile();
  }, []);

  React.useEffect(() => {
    if (therapistUser) {
      setMemberOfAssociation(
        therapistUser.therapistprofile?.amtsMember === true
      );
      setYearsOfExperience(therapistUser.therapistprofile?.experience || null);
    }
  }, [therapistUser]);

  return (
    <Box maxWidth="632px" pb={20}>
      <Box
        fontFamily="Museo"
        fontSize="24px"
        fontWeight={600}
        color={Colors.NightBlue}
        mt="56px"
      >
        Experience
      </Box>

      <Box maxWidth="467px">
        <Dropdown
          placeholder="(Required)"
          title="Years of professional experience"
          options={yearsOfExperienceOptions}
          onSelectedOption={(option) => setYearsOfExperience(option.value)}
          selectedOption={yearsOfExperienceOptions.find(
            (option) => option.value === yearsOfExperience
          )}
        />

        {/* <Dropdown
          title="Are you a member of an industry association?"
          options={memberOfIndustryAssociationOptions}
          onSelectedOption={(option) => setMemberOfAssociation(option.value)}
          selectedOption={memberOfIndustryAssociationOptions.find(
            (option) => option.value === memberOfAssociation
          )}
        /> */}
      </Box>

      <Box
        fontFamily="Museo"
        fontSize="24px"
        fontWeight={600}
        color={Colors.NightBlue}
        mt="56px"
      >
        Qualifications
      </Box>

      <Box
        fontFamily="Museo"
        fontSize="16px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="24px"
        lineHeight="24px"
      >
        Please upload all current documentation. Scanned or photos of documents
        are accepted.
      </Box>
      {/* 
      <Box display="flex" flexDirection="row" alignItems="center" mt="24px">
        <Checkbox
          style={{
            color: Colors.TurquoiseBlue,
          }}
          checked={
            therapistUser?.therapistprofile?.associationMembership === true
          }
          onChange={(event) => {
            axios
              .put(`therapists/me/switch?accessToken=${accessToken}`, {
                field: "associationMembership",
              })
              .then((response) => {
                fetchMyTherapistProfile();

                setSuccessMessage(
                  "Your industry association membership answer has been updated"
                );
              })
              .catch((error) => {
                setErrorMessage(parseApiError(error));
              });
          }}
        />
        <Box
          fontFamily="Museo"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          color={Colors.Dusk}
        >
          I have industry association membership
        </Box>
      </Box> */}

      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="18px"
        lineHeight="27px"
        color={Colors.Dusk}
        mt="32px"
        mb="32px"
      >
        Upload documentation
      </Box>
      {/* List of files */}

      {uploadedQualificationFiles.map((uploadedFile) => (
        <UploadedFileRow
          file={uploadedFile}
          onRemove={() => {
            user &&
              axios
                .delete(
                  `/therapists/${uploadedFile.id}/file?accessToken=${accessToken}&therapistId=${user.id}`
                )
                .then((response) => {
                  uploadedFilesMutate();

                  setSuccessMessage("This file has been deleted");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
          }}
        />
      ))}

      <Box mt="37px" mb="32px">
        <Button
          title="Upload file"
          type={ButtonType.outlined}
          width="132px"
          onClick={() => {
            if (qualificationsFileUploadRef.current) {
              qualificationsFileUploadRef.current.click();
            }
          }}
        />
      </Box>

      <Box
        fontFamily="Museo"
        fontSize="24px"
        fontWeight={600}
        color={Colors.NightBlue}
        mt="56px"
      >
        Industry membership (optional)
      </Box>

      <Box
        fontFamily="Museo"
        fontSize="16px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="24px"
        lineHeight="24px"
      >
        If you are a member of an industry organisation, please upload all valid
        documentation. Scanned or photos of documents are accepted.
      </Box>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="18px"
        lineHeight="27px"
        color={Colors.Dusk}
        mt="32px"
        mb="32px"
      >
        Upload documentation
      </Box>
      {/* List of files */}

      {uploadedIndustryMembershipFiles.map((uploadedFile) => (
        <UploadedFileRow
          file={uploadedFile}
          onRemove={() => {
            user &&
              axios
                .delete(
                  `/therapists/${uploadedFile.id}/file?accessToken=${accessToken}&therapistId=${user.id}`
                )
                .then((response) => {
                  uploadedFilesMutate();

                  setSuccessMessage("This file has been deleted");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
          }}
        />
      ))}

      <Box mt="37px" mb="32px">
        <Button
          title="Upload file"
          type={ButtonType.outlined}
          width="132px"
          onClick={() => {
            if (industryFileUploadRef.current) {
              industryFileUploadRef.current.click();
            }
          }}
        />
      </Box>

      <input
        type="file"
        id="qualification-upload-file"
        ref={qualificationsFileUploadRef}
        style={{ display: "none" }}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();

          const file = event?.target?.files && event.target.files[0];

          console.debug("file: ", file);

          if (file) {
            const form = new FormData();
            form.append("image", file);

            user &&
              axios
                .post(
                  `/therapists/${user.id}/file?accessToken=${accessToken}&fileOwnership=${qualificationFileType}`,
                  form,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((response) => {
                  uploadedFilesMutate();

                  setSuccessMessage("This file has been uploaded");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
          }
        }}
      />
      <input
        type="file"
        id="industry-membership-upload-file"
        ref={industryFileUploadRef}
        style={{ display: "none" }}
        onChange={(event) => {
          event.stopPropagation();
          event.preventDefault();

          const file = event?.target?.files && event.target.files[0];

          console.debug("file: ", file);

          if (file) {
            const form = new FormData();
            form.append("image", file);

            user &&
              axios
                .post(
                  `/therapists/${user.id}/file?accessToken=${accessToken}&fileOwnership=${industryMembershipFileType}`,
                  form,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((response) => {
                  uploadedFilesMutate();

                  setSuccessMessage("This file has been uploaded");
                })
                .catch((error) => {
                  setErrorMessage(parseApiError(error));
                });
          }
        }}
      />

      <Box mt="64px">
        <Button
          loading={saving}
          title="Save changes"
          width="152px"
          type={ButtonType.primary}
          onClick={() => {
            setSaving(true);

            const data = {
              amtsMember: memberOfAssociation,
              experience: yearsOfExperience,
            };

            axios
              .put(`/therapists/me?accessToken=${accessToken}`, data)
              .then((response) => {
                setSaving(false);

                setSuccessMessage("Your qualifications has been updated");
              })
              .catch((error) => {
                setSaving(false);

                setErrorMessage(parseApiError(error));
              });
          }}
        />
      </Box>
    </Box>
  );
}
