import { Box, styled } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { styles } from "./styles";
import TextLink from "../../TextLink";
import AddDocument from "./AddDocument";
import { RootState } from "../../../stores/V2";
import { useAlertStore } from "../../../stores/alert";
import Button, { ButtonSize, ButtonType } from "../../Button";
import { USER_UPLOADED_CONTENT_TYPE } from "../../../data/enums";
import { KEYS, useCreateDocument } from "../../../hooks/userTerms.hook";
import { actions } from "../../../stores/V2/documents/addDocuments";
import AddDocumentModal from "../../Modals/AddDocumentModal/AddDocumentModal";
import queryClient from "../../../config/queryClient";
import { parseApiError } from "../../../helpers/error";

const BoxColumn = styled(Box)({
  flexDirection: "column",
});

const TermsAndPoliciesAddbutton = ({
  bookingId,
  therapistId,
  isAdditionalDocument,
  isTermsDocument,
  onSuccess = () => {}
}: any) => {
  const state = useSelector((state: RootState) => state.documents);
  const dispatch = useDispatch();

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const getSuccessMessage = () => {
    if (isTermsDocument) {
      return "Your terms and policies have been updated.";
    }

    if (state.sendForSignature) {
      return "Document has been added and sent to the provider successfully.";
    }

    return "Document has been added successfully.";
  };

  const onComplete = {
    save: (success: boolean) => {
      if (success) {
        const successMsg = getSuccessMessage();
        setSuccessMessage(successMsg);
        resetInput();
        dispatch(actions.setShowAddDocumentModal(false));

        if (!!onSuccess) onSuccess();
      } else {
        setErrorMessage("Error occur while updating terms and policies.");
      }
    },
    remove: (success: boolean) => {
      if (success) {
        setSuccessMessage("Your terms and policies have been removed.");
      } else {
        setErrorMessage("Error occur while removing document.");
      }
    },
  };

  const onUploadError = (err: any) => {
    const errorMessage = parseApiError(err);
    setErrorMessage(errorMessage);
  };

  const { isLoading: isSaving, mutate: saveTermsDocument } = useCreateDocument({
    onSuccess: onComplete.save,
    onError: onUploadError,
  });

  const resetInput = () => {
    dispatch(actions.reset());
    dispatch(actions.setSelectedTermPolicies(""));
    queryClient.invalidateQueries({ queryKey: [KEYS.PENDING_AGREEMENT, therapistId] });
  };

  const handleModalClose = () => {
    dispatch(actions.reset());
    dispatch(actions.setShowAddDocumentModal(false));
  };

  const handleSaveChange = async () => {
    let payload: any;
    const { sendForSignature } = state;

    if (state.contentType === USER_UPLOADED_CONTENT_TYPE.FILE) {
      payload = new FormData();
      payload.append("image", state.userInput as Blob);
      payload.append("bookingId", bookingId || null);
      payload.append("therapistId", therapistId || null);
      payload.append("isAdditionalDocument", isAdditionalDocument);
      payload.append("isTermsDocument", isTermsDocument);
      if (isAdditionalDocument)
        payload.append("sendForSignature", sendForSignature.toString());
    } else {
      payload = {
        url: state.userInput,
        bookingId,
        therapistId,
        isAdditionalDocument,
        isTermsDocument,
      };
      if (isAdditionalDocument)
        payload.sendForSignature = sendForSignature;
    }

    saveTermsDocument({
      docType: state.selectedTermPolicies,
      otherDocType: state.otherDocumentName,
      contentType: state.contentType,
      data: payload,
    });
  };

  return (
    <Box>
      <BoxColumn style={styles.block}>
        {isAdditionalDocument ? (
          <>
            <TextLink
              title={"Add document"}
              onClick={() => {
                dispatch(actions.setShowAddDocumentModal(true));
                dispatch(actions.setTherapistId(therapistId));
              }}
              fontSize={"14px"}
              fontWeight={700}
            />
          </>
        ) : (
          <Box>
            <AddDocument />
            <Button
              width={"152px"}
              title="Save changes"
              type={ButtonType.indigo}
              style={{ marginTop: "22px" }}
              onClick={() => handleSaveChange()}
              size={ButtonSize.small}
              loading={isSaving}
              disabled={!state.userInput}
            />
          </Box>
        )}
      </BoxColumn>
      <AddDocumentModal
        open={state.showAddDocumentModal}
        onClose={handleModalClose}
        title={"Add document"}
        onConfirm={handleSaveChange}
        isSaving={isSaving}
        therapistId={therapistId}
      />
    </Box>
  );
};

export default TermsAndPoliciesAddbutton;
