import { isBooleanValue } from "../../utils/boolean";

export const usePeakHourFixed = ({
  lateNightSurcharge,
  lateNightSurchargeApplied,
}: {
  lateNightSurcharge: number | undefined;
  lateNightSurchargeApplied: boolean | undefined;
}) => {
  if (!isBooleanValue(lateNightSurchargeApplied) && lateNightSurcharge) {
    return { upToText: "Up to ", isPeakHourFixed: false };
  }
  return { upToText: "", isPeakHourFixed: true };
};
