import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

import "../../../styles/DayPicker.css";
import "./slot_reserved.css";
import { RootState } from "../../../stores/V2";
import { Paths } from "../../../constants/paths";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import LogoLink from "../../../components/LogoLink";
import CorporateInformation from "./CorporateInformation";
import LandingWrapper from "../../../components/LandingWrapper";
import { SlotParams } from "../../../stores/V2/corporate/interface";
import { getQueryString } from "../../../helpers/string";
import { ReservedSlotSuccessData } from "../../../models";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import GoogleCalendarImg from "../../../images/google_calendar.png";
import ICalendarImg from "../../../images/iCalendar.png";
import OutlookCalendarImg from "../../../images/outlook_calendar.png";
import { removeHtmlTags, udpateOutLookCalendarUrl } from "../../../helpers/calendar";

const ConfirmSlot = () => {
  const isMobile = useMobile();
  const state = useSelector((state: RootState) => state.coroprateBooking);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showDialog, setShowDialog] = useState(false);

  const params = useParams<SlotParams>();
  const { calendarLink, outLookCalendarLink, appleCalendarLink } =
    location.state as ReservedSlotSuccessData;

  useEffect(() => {
    if (!state.booking.id) history.push(`${Paths.CorporateSlots}/${params.uuid}`);
  }, [history, params.uuid, state.booking.id]);

  const generateReservedSlotLink = (bookingLink: string | null) => {
    if (!bookingLink || !location.state) return bookingLink;
    const { slotId } = location.state as ReservedSlotSuccessData;

    const payload: any = { slotId };
    const queryString = getQueryString(payload);
    return `${bookingLink}?${queryString}`;
  };

  const updatedAppleCalendarLink = removeHtmlTags(appleCalendarLink);

  const udpatedOutLookUrl = udpateOutLookCalendarUrl(outLookCalendarLink);

  const handleAddToCalendar = () => {
    setShowDialog(true);
  };

  const handleUpdateSlot = () => {
    const reservedSlotLink = generateReservedSlotLink(state.booking.bookingLink);
    if (reservedSlotLink) {
      window.location.href = reservedSlotLink;
    }
  };

  return (
    <LandingWrapper showPromoImages={false}>
      <Dialog
        open={showDialog}
        maxWidth={false}
        fullScreen={isMobile}
        onClose={() => setShowDialog(false)}
      >
        <Box bgcolor="white" width={isMobile ? "100%" : "448px"} borderRadius="8px">
          <Box paddingX="48px" mb="32px" paddingY="40px">
            <Typography
              style={{
                fontSize: "22px",
                fontWeight: 600,
                color: Colors.NightBlue,
                textAlign: "center",
              }}
            >
              Add to calendar
            </Typography>

            <Box
              mt={3}
              display="flex" 
              justifyContent="flex-start"
              alignItems="center"
              className="calendar-box"
              onClick={() => window.open(calendarLink, "_blank")}
            >
              <img src={GoogleCalendarImg} alt="google calendar" className="calendar-img" />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: Colors.Dusk,
                  textAlign: "center",
                }}
              >
                Google Calendar
              </Typography>
            </Box>

            <Box
              mt={1}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              className="calendar-box"
              onClick={() => window.open(udpatedOutLookUrl, "_blank")}
            >
              <img src={OutlookCalendarImg} alt="outlook calendar" className="calendar-img" />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: Colors.Dusk,
                  textAlign: "center",
                }}
              >
                Outlook
              </Typography>
            </Box>

            <Box
              mt={1}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              className="calendar-box"
              onClick={() => window.open(updatedAppleCalendarLink, "_blank")}
            >
              <img src={ICalendarImg} alt="apple calendar" className="calendar-img" />
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: Colors.Dusk,
                  textAlign: "center",
                }}
              >
                iCal
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Box width={isMobile ? undefined : "1024px"} marginBottom="80px">
        <LogoLink />
        <CorporateInformation
          title={state.selectedTherapist && `Booking confirmed with ${state.selectedTherapist}`}
          subTitle="Please make sure to arrive 2 minutes before your turn."
          slot={state.selectedSlot}
          showBookingInfo
          hideStep
          booking={state.booking}
        />

        <Box>
          <Typography
            style={{
              color: Colors.NightBlue,
              fontSize: "24px",
              lineHeight: "26px",
              fontWeight: 600,
              fontFamily: "museo",
              paddingBottom: "8px",
            }}
          >
            Manage your booking
          </Typography>
          <Typography
            style={{
              color: Colors.Dusk,
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 500,
              paddingBottom: "16px",
            }}
          >
            If you need to make changes to your booking, including rescheduling or cancelling, you
            can do so by clicking on the link below, or add booking to calendar.
          </Typography>
          <Box style={{ display: "flex", gap: "8px" }}>
            <Button
              size={ButtonSize.large}
              type={ButtonType.indigo}
              width="170px"
              title="Add to calendar"
              onClick={handleAddToCalendar}
            />
            <Button
              size={ButtonSize.large}
              type={ButtonType.outlined}
              title="Update booking"
              width="170px"
              onClick={handleUpdateSlot}
            />
          </Box>
        </Box>
      </Box>
    </LandingWrapper>
  );
};

export default ConfirmSlot;
