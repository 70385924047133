import RecipientIcon from "../../../images/pro-icon.png";
import { Colors } from "../../../constants/colors";
import { Box } from "../../../components/v2/Styled";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../../components/v2/Styled/enum";
import { getValue } from "../../../utils/object";

interface Props {
  recipientDetails: any;
}

const UpdatedRecipient = ({ recipientDetails }: Props) => {
  const getClientGender = (gender: string) => {
    switch (gender) {
    case "male":
      return "I am male";

    case "female":
      return "I am female";

    case "non binary / non confirming":
      return "I am non-binary / non confirming";

    case "prefer not to respond":
      return "";

    default:
      return "";
    }
  };

  const fullName = `${getValue(recipientDetails, "firstName")} ${getValue(
    recipientDetails,
    "lastName",
  )}`;
  const gender = getValue(recipientDetails, "gender");
  const instructions = getValue(recipientDetails, "specialInstructions");
  const contraindications = getValue(recipientDetails, "contraindications");

  return (
    <Box display={Display.Flex} direction={FlexDirection.Column}>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        alignItems={AlignItems.start}
        gap={Spacing.S3}
      >
        <img src={RecipientIcon} style={styles.proIcon} alt="Time of arrival" />
        <Box display={Display.Flex} direction={FlexDirection.Column}>
          <Box
            fontFamily={FontFamily.Museo}
            fontSize={FontSize.F16}
            fontWeight={FontWeight.SemiBold}
            color={Colors.Indigo}
          >
            Booking For
          </Box>
          <Box
            display={Display.Flex}
            direction={FlexDirection.Column}
            gap={Spacing.S1}
            marginY={Spacing.S2}
            fontFamily={FontFamily.OpenSans}
            fontSize={FontSize.F14}
            color={Colors.Grey}
          >
            <Box>Client: {fullName}</Box>
            <Box>Gender: {getClientGender(gender)}</Box>
            {!!instructions && (
              <Box>Instructions: {getValue(recipientDetails, "specialInstructions")}</Box>
            )}
            {!!contraindications && (
              <Box>Contraindications: {getValue(recipientDetails, "contraindications")}</Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  proIcon: {
    width: 24,
    height: 24,
  },
};

export default UpdatedRecipient;
