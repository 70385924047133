import { Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import LandingWrapper from "../../components/LandingWrapper";
import LogoLink from "../../components/LogoLink";
import { useMobile } from "../../hooks/mobile";
import MfaLoginComponent from "./MfaLoginComponent";
import { Paths } from "../../constants/paths";
import { getValue } from "../../utils/object";
import { useMfaLogin, useSendTwoFAVerificationCode } from "../../hooks/settings/twoFA.hooks";
import { useAlertStore } from "../../stores/alert";
import { User } from "../../models";
import { trackEvent } from "../../services/segment/track.service";
import { useState } from "react";
import { getFutureTimeStamp } from "../../utils/date.util";
import { VERIFICATION_CODE_RESEND_TIMEOUT } from "../../constants/time";
import { parseApiError } from "../../helpers/error";
import firebaseApp from "../../services/firebaseApp";

interface MfaInterface {
  email: string | null;
  type: string | null;
}

const MfaLogin = () => {
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const [waitTime, setWaitTime] = useState("");
  const [isActionDisabled, setIsActionDisabled] = useState(true);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { mutateAsync: handleMfaLogin, isLoading: mfaLoginLoading } = useMfaLogin();
  const { mutateAsync: resendVerficationCode } = useSendTwoFAVerificationCode();

  const mfaFromState = location.state as MfaInterface;

  const mfaType = getValue(mfaFromState, "mfaType");
  const email = getValue(mfaFromState, "email");
  const mobile = getValue(mfaFromState, "mobile");
  const serviceId = getValue(mfaFromState, "serviceId");

  if (!mfaType || !email) {
    history.push(Paths.Login);
  }

  const onCodeSubmit = async (code: string) => {
    try {
      const response = await handleMfaLogin({ code: code, email, serviceId });

      const user = getValue(response, "user") as User;

      const { accessToken } = user;

      if (accessToken) {
        trackEvent("Logged In", {
          version: 2,
        });

        localStorage.setItem("token", accessToken);

        if (user.therapistprofile) {
          if (getValue(history, "location.state.jobId")) history.goBack();
          history.push(Paths.ProDashboardProfile);
        } else {
          history.push(Paths.Bookings);
        }
      }
    } catch (error) {
      setErrorMessage("Please make sure you have entered the correct verification code");
    }
  };

  const onResend = async () => {
    try {
      const fbToken = await firebaseApp.getToken();
      const response = await resendVerficationCode({ mobileNumber: mobile, fbToken });
      if (response) {
        setWaitTime(getFutureTimeStamp({ seconds: VERIFICATION_CODE_RESEND_TIMEOUT }).format());
        setIsActionDisabled(true);
        setSuccessMessage("Verification code sent successfully.");
      }
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };

  return (
    <LandingWrapper>
      <Box width={isMobile ? undefined : "400px"}>
        <LogoLink />

        <MfaLoginComponent
          onCodeSubmit={onCodeSubmit}
          mfaLoginLoading={mfaLoginLoading}
          mfaType={mfaType}
          setIsActionDisabled={setIsActionDisabled}
          waitTime={waitTime}
          isActionDisabled={isActionDisabled}
          onResend={onResend}
        />
      </Box>
    </LandingWrapper>
  );
};

export default MfaLogin;
