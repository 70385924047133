import { AxiosError } from "axios";
import { Box, BoxProps } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import { getValue } from "../../../../utils/object";
import { useMobile } from "../../../../hooks/mobile";
import { Colors } from "../../../../constants/colors";
import { useUserStore } from "../../../../stores/user";
import TextField from "../../../../components/TextField";
import { useAlertStore } from "../../../../stores/alert";
import { parseApiError } from "../../../../helpers/error";
import * as Styled from "../../../../components/v2/Styled/enum";
import { useUpdateTherapistProfile } from "../../../../hooks/therapistProfile.hooks";
import BankingInformation from "../../../../components/ProDashboard/Business/Banking/BankingInformation";

const ACCOUNT_FIELDS = {
  ACCOUNT_NUMBER: "accountNumber",
  BSB: "bsb",
} as const;

interface Props {
  bankingInfoConfig: Record<string, any>;
}

const OldBankInfoForm = ({ bankingInfoConfig }: Props): JSX.Element => {
  const { user } = useUserStore();
  const isMobile = useMobile();

  const [accountDetail, setAccountDetail] = useState({
    [ACCOUNT_FIELDS.ACCOUNT_NUMBER]: "",
    [ACCOUNT_FIELDS.BSB]: "",
  });
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { mutate: updateProfile, isLoading: isUpdating } = useUpdateTherapistProfile({
    onSuccess: () => setSuccessMessage("Bank account updated."),
    onError: (e: AxiosError) => setSuccessMessage(parseApiError(e)),
  });

  useEffect(() => {
    if (!user) return;

    const accountNumber = getValue(user, "therapistprofile.accountNumber") || "";
    const bsb = getValue(user, "therapistprofile.bsb") || "";

    setAccountDetail({
      [ACCOUNT_FIELDS.ACCOUNT_NUMBER]: accountNumber,
      [ACCOUNT_FIELDS.BSB]: bsb,
    });
  }, [user]);

  const handleChange = (field: (typeof ACCOUNT_FIELDS)[keyof typeof ACCOUNT_FIELDS], value: any) =>
    setAccountDetail({
      ...accountDetail,
      [field]: value,
    });

  const validate = () => {
    if (!accountDetail[ACCOUNT_FIELDS.ACCOUNT_NUMBER]) {
      setErrorMessage("Account number is required.");
      return false;
    }
    if (!accountDetail[ACCOUNT_FIELDS.BSB]) {
      setErrorMessage("Account number is required.");
      return false;
    }
    return true;
  };

  const handleSave = () => {
    const isValid = validate();
    if (!isValid) return;

    updateProfile(accountDetail);
  };

  const Title = (props: BoxProps) => (
    <Box
      color={Colors.Dusk}
      fontSize={Styled.FontSize.F14}
      fontFamily={Styled.FontFamily.Museo}
      fontWeight={Styled.FontWeight.SemiBold}
      {...props}
    />
  );

  const SubTitle = (props: BoxProps) => (
    <Box
      color={Colors.Dusk}
      fontSize={Styled.FontSize.F14}
      lineHeight={Styled.LineHeight.L21}
      fontWeight={Styled.FontWeight.Regular}
      fontFamily={Styled.FontFamily.OpenSans}
      {...props}
    />
  );

  return (
    <Box
      gridGap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <BankingInformation bankingInformation={bankingInfoConfig} hideBadge />
      <Box
        gridGap={Styled.Spacing.S6}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
      >
        <Box display={Styled.Display.Flex} gridGap={Styled.Spacing.S8}>
          <Box
            flex={1}
            gridGap={Styled.Spacing.S2}
            display={Styled.Display.Flex}
            flexDirection={Styled.FlexDirection.Column}
          >
            <Title>BSB number</Title>
            <SubTitle>No special characters or spaces.</SubTitle>
            <TextField
              placeholder="000000"
              paddingBottom={"0px"}
              paddingTop={Styled.Spacing.S2}
              value={accountDetail[ACCOUNT_FIELDS.BSB]}
              onChange={(text) => handleChange(ACCOUNT_FIELDS.BSB, text)}
            />
          </Box>
          <Box
            flex={1}
            gridGap={Styled.Spacing.S2}
            display={Styled.Display.Flex}
            flexDirection={Styled.FlexDirection.Column}
          >
            <Title>Account number</Title>
            <SubTitle>No special characters or spaces.</SubTitle>
            <TextField
              paddingBottom={"0px"}
              placeholder="00000000"
              paddingTop={Styled.Spacing.S2}
              value={accountDetail[ACCOUNT_FIELDS.ACCOUNT_NUMBER]}
              onChange={(text) => handleChange(ACCOUNT_FIELDS.ACCOUNT_NUMBER, text)}
            />
          </Box>
        </Box>
        <Box>
          <Button
            title={"Save change"}
            loading={isUpdating}
            onClick={handleSave}
            width={isMobile ? "100%" : "auto"}
            style={{ padding: `${Styled.Spacing.S3} ${Styled.Spacing.S6}`, minWidth: "144px" }} // minWidth to preserve button width when loading
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OldBankInfoForm;
