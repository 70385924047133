import { Typography } from "@material-ui/core";
import axios from "axios";
import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
  reportErrorLog?: boolean;
  domain?: string;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { reportErrorLog = true, domain = "DEFAULT" } = this.props;
    if (reportErrorLog) {
      const accessToken = localStorage.getItem("token");
      // log the error report
      const errorLog = {
        domain,
        log: {
          details: error.toString ? error.toString() : error,
          info: errorInfo,
        },
      };

      axios.post("api/v2/notifications/failed-transaction", errorLog, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Typography
          variant="h1"
          style={{ textAlign: "center", fontSize: "16px", marginTop: "32px" }}
        >
          Something went wrong. Someone from our customer service team will be
          in touch shortly.
        </Typography>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
