import { upperFirst } from "lodash";
import { DropdownOption } from "../components/Dropdown";
import {
  getCorporateMassageTypes,
  getGenderPreferences,
  getMassageTypes,
  getSessionTypes,
} from "../stores/booking";

export namespace MassageType {
  export function toString(type: string): string {
    const massageTypes = getMassageTypes();
    const corporateMassageTypes = getCorporateMassageTypes();
    if (massageTypes[type]) {
      return massageTypes[type].label;
    } else if (corporateMassageTypes[type]) {
      return corporateMassageTypes[type].label;
    }
    return formatDisplayNewService(type);
  }
}

export const formatDisplayNewService = (str: string) => {
  const split = str.split("|");
  return split
    .map((s, i) => {
      if (i === 0) {
        return upperFirst(s);
      }
      return s;
    })
    .join(" | ");
};

export const SessionType = {
  toString(type: string): string {
    const sessionTypes = getSessionTypes();
    if (sessionTypes[type]) {
      return sessionTypes[type].title;
    }
    return formatDisplayNewService(type);
  },
};

export const GenderPreference = {
  dontCare: "dont_care",
  preferFemale: "prefer_female",
  preferMale: "prefer_male",
  femaleOnly: "female_only",
  maleOnly: "male_only",

  toString(preference: string): string {
    const genderPreferences = getGenderPreferences();
    return genderPreferences[preference]?.title || "";
  },

  toDescriptiveString(preference: string): string {
    switch (preference) {
    case GenderPreference.dontCare:
      return "No preference";
    case GenderPreference.preferFemale:
      return "Female preferred";
    case GenderPreference.preferMale:
      return "Male preferred";
    case GenderPreference.femaleOnly:
      return "Female only";
    case GenderPreference.maleOnly:
      return "Male only";
    default:
      return "";
    }
  },

  getHelperText(preference: string): string {
    const genderPreferences = getGenderPreferences();
    return genderPreferences[preference]?.helpText || "";
  },
};

export enum MassageFor {
  myself = "myself",
  someoneElse = "someone_else",
}

export enum TreatmentFor {
  myself = "my_self",
  someoneElse = "someone_else",
  bride = "bride",
  birdesmaid = "bridesmaid",
  flowergirl = "flower_girl",
  guest = "guest",
}

export namespace MassageFor {
  export function toString(massageFor: MassageFor): string {
    switch (massageFor) {
    case MassageFor.myself:
      return "Myself";
    case MassageFor.someoneElse:
      return "Someone else";
    }
  }
}

export const AllMassageFors = [MassageFor.myself, MassageFor.someoneElse];

export enum Relationship {
  spouse = "spouse",
  parent = "parent",
  friend = "friend",
  colleague = "colleague",
  guest = "guest",
}

export namespace Relationship {
  export function toString(relationship: Relationship): string {
    switch (relationship) {
    case Relationship.spouse:
      return "Spouse / Better half";
    case Relationship.parent:
      return "Parent";
    case Relationship.friend:
      return "Friend";
    case Relationship.colleague:
      return "Boss / Colleague";
    case Relationship.guest:
      return "Client";
    }
  }
}

export const AllRelationships = [
  Relationship.spouse,
  Relationship.parent,
  Relationship.friend,
  Relationship.colleague,
  Relationship.guest,
];

export enum UpdateRequestedBy {
  pro = "pro",
  client = "client",
}

export enum BOOKING_STATUS {
  NEW = "new",
  ARRANGED = "arranged",
  COMPLETED = "completed",
  CANCELLED = "cancelled",
  DECLINED = "declined",
}

export enum UpdateRequestStatus {
  pending = "pending",
  declined = "declined",
  accepted = "accepted",
}

export enum JobStatus {
  pendingPayment = "Pending payment",
  pending = "pending",
  rebooking = "rebooking",
  accepted = "accepted",
  onway = "onway",
  finished = "finished",
  reviewLeft = "reviewLeft",
  cancelled = "cancelled",
}

export enum BookingUpdateStatus {
  requestByClient = "Pending provider confirmation",
}

export enum Stairs {
  no = "no",
  few = "few",
  lots = "lots",
}

export namespace Stairs {
  export function toString(stairs: Stairs): string {
    switch (stairs) {
    case Stairs.no:
      return "No";
    case Stairs.few:
      return "Yes - a few";
    case Stairs.lots:
      return "Yes - lots";
    }
  }
}

export const AllStairs = [Stairs.no, Stairs.few, Stairs.lots];

export enum Parking {
  freeParking = "free-parking",
  streetParking = "street-parking",
  noParking = "no-parking",
  notSure = "not-sure",
}

export namespace Parking {
  export function toString(parking: Parking): string {
    switch (parking) {
    case Parking.freeParking:
      return "Free parking on-site";
    case Parking.streetParking:
      return "Street parking";
    case Parking.noParking:
      return "No parking";
    case Parking.notSure:
      return "Not sure";
    }
  }
}

export const AllParkings = [
  Parking.freeParking,
  Parking.streetParking,
  Parking.noParking,
  Parking.notSure,
];

export enum RebookingStatus {
  declined = "declined",
  notResponded = "not-responded",
  abandoned = "abandoned",
}

export enum PRICE_TYPE {
  VALUE = "value",
  PERCENT = "percent",
}

export enum MASSAGE_TYPES {
  swedish = "swedish",
  deeptissue = "deeptissue",
  sports = "sports",
  pregnancy = "pregnancy",
  corporatechair = "corporatechair",
  corporatetable = "corporatetable",
  corporateroving = "corporateroving",
  chair = "chair",
  table = "table",
  roving = "roving",
}

export const TIME_PICKER_DIFFERENCE_IN_MINUTES = 15;

const BUFFER_TIME_MINS = 60;
const BUFFER_TIME_HOURS = 1;

const CORPORATE_CLIENTS_NUM = {
  max: 200,
  min: 1,
};

// in minutes
const CORPORATE_DURATION_LIMIT = {
  min: 2 * 60,
};

const MAX_CLIENTS_HAIR_MAKEUP = 6;

enum BOOKING_TIME {
  BACKUP = "backup",
  PREFERRED = "preferred",
}

const DEFAULT_CURRENCY = {
  currency: "AUD",
  currencySymbol: "A$",
};

interface MyObject {
  [key: number]: string;
}

export const bookingFrequencyTitle: MyObject = {
  0: "One off",
  1: "Every week",
  2: "Every 2 weeks",
  3: "Every 3 weeks",
  4: "Every 4 weeks",
  5: "Custom",
};

export enum BookingFrequencyType {
  CUSTOM = "CUSTOM",
}
export enum RecurringType {
  DAY = "daily",
  MONTH = "monthly",
  WEEK = "weekly",
}
export enum BookingRecurringMonthOptionType {
  DAY_OF_MONTH = "dayOfMonth",
  WEEK_DAY_OF_MONTH = "weekDayOfMonth",
}

export const recurringTypeOptions: DropdownOption[] = [
  {
    value: RecurringType.DAY,
    title: "Day",
  },
  {
    value: RecurringType.WEEK,
    title: "Week",
  },
  {
    value: RecurringType.MONTH,
    title: "Month",
  },
];

export const weekDayPositions = ["first", "second", "third", "fourth", "last"];

export const DEFAULT_MONTHLY_CUSTOM_RECURRING_FREQUENCY = 1;
export const DEFAULT_WEEKLY_CUSTOM_RECURRING_FREQUENCY = 1;

export const bookingFrequencyType = {
  oneOff: 0,
  everyWeek: 1,
  everyTwoWeek: 2,
  everyThreeWeek: 3,
  everyFourWeek: 4,
  custom: BookingFrequencyType.CUSTOM,
};

const DEFAULT_PROFESSION = "Provider";

export {
  BOOKING_TIME,
  BUFFER_TIME_HOURS,
  BUFFER_TIME_MINS,
  CORPORATE_CLIENTS_NUM,
  CORPORATE_DURATION_LIMIT,
  DEFAULT_CURRENCY,
  DEFAULT_PROFESSION,
  MAX_CLIENTS_HAIR_MAKEUP,
};

export const getProvidersTitle = (numberOfPros: number | null, profession: string) =>
  numberOfPros && numberOfPros > 1 ? `${profession}s` : `${profession}`;

export const frequencyOption = [
  { value: bookingFrequencyType.oneOff, title: bookingFrequencyTitle[0] },
  { value: bookingFrequencyType.everyWeek, title: bookingFrequencyTitle[1] },
  { value: bookingFrequencyType.everyTwoWeek, title: bookingFrequencyTitle[2] },
  { value: bookingFrequencyType.everyThreeWeek, title: bookingFrequencyTitle[3] },
  { value: bookingFrequencyType.everyFourWeek, title: bookingFrequencyTitle[4] },
  { value: bookingFrequencyType.custom, title: bookingFrequencyTitle[5] },
];

export enum BookingFrequencyUpdateType {
  only = "Only this booking",
  all = "All future repeating bookings",
}

export enum RecurringBookingCancelType {
  only = "only",
  all = "all",
}

export const DELIVERY_METHOD = {
  inperson: "inperson",
};

export const UPDATE_RECURRING_MODAL_SKIP_KEY = {
  recurring: "recurring",
  recuringEndOn: "recuringEndOn",
  preferredTherapists: "preferredTherapists",
};

export const TOTAL_DUE_INFO = {
  title: "Total due",
  description:
    "This price is indicative. Final price will be determined based on confirmed date & time and changes (if any) to treatment type, number of recipients, location or add-ons.",
};

export const TOTAL_PAYOUT_INFO = {
  title: "Total payout",
  description:
    "This amount is indicative. Final amount will be determined based on confirmed date & time and changes (if any) to treatment type, number of recipients, location or add-ons.",
};

export const CLIENT_BOOKING_CANCEL_REASONS = {
  OTHER: "OTHER",
  FELT_UNSAFE: "FELT_UNSAFE",
  CHANGED_MY_MIND: "CHANGED_MY_MIND",
  PROVIDER_NO_SHOW: "PROVIDER_NO_SHOW",
  BOOKED_BY_ACCIDENT: "BOOKED_BY_ACCIDENT",
  BOOKING_NOT_CONFIRMED: "BOOKING_NOT_CONFIRMED",
  NEED_TO_CHANGE_BOOKING_DETAILS: "NEED_TO_CHANGE_BOOKING_DETAILS",
};

export const BREAK_SLOT_LOCKED_INFO = {
  title: "Booking slot reserved",
  description:
    "You are unable to update the break time since at least one of the booking slots is already reserved.",
};

export const SLOT_TYPES = {
  BREAK_SLOT: "break",
  CHANGE_OVER: "changeover",
};

export const STEP_PROGRESS = {
  // have to update these values if in future number of steps in booking flow changes
  1: 0.142857,
  2: 0.285714,
  3: 0.428571,
  4: 0.571428,
  5: 0.714285,
  6: 0.857142,
  7: 1.0,
  LAST: 1.0, // Index with this 'LAST' key. In case additional steps are added, last step will always have progress 1.
};

export const BOOKING_FALLBACK_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  DECLINED: "declined",
};

export const BOOKING_FALLBACK = {
  GENDER: "gender-fallback",
  COUPLES_TO_BACK_TO_BACK: "couples-to-back-to-back",
  COUPLES_TO_BACK_TO_BACK_UNRESPONSIVE_PRO: "couples-to-back-back-unresponsive-pro",
};

export enum BillingInvoicePaymentStatus {
  pending = "pending",
  partial = "partial",
  overdue = "overdue",
  full = "full",
}

export enum BillingInvoicePaymentStatusText {
  pending = "Pending payment",
  partial = "Partially paid",
  overdue = "Overdue",
  full = "Fully paid",
}
export const BOOKING_DETAIL_FIELDS = {
  ADDRESS: "address",
  DATETIME: "datetime",
  RECIPIENT: "recipient",
  BOOKING_FREQUENCY: "bookingFrequency",
};

export const PRICE_BREAKDOWN = {
  CREDIT: "Credit",
  BASERATE: "Base rate",
  PEAK_PERIOD: "Peak period",
  PARKINGTRAVEL: "Parking & travel",
  PUBLIC_HOLIDAY: "Public holiday rate",
};

export const RECIPIENT_PAST_BOOKINGS_PER_PAGE = 10;
export const RECIPIENT_UPCOMING_BOOKINGS_PER_PAGE = 10;
