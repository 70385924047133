import { loadScript } from "../../helpers/script";
import { AFTERPAY_SCRIPT_SRC } from "../../constants/common";

class AfterPayHandler {

  // initalize after pay script
  static initialize() {
    loadScript(AFTERPAY_SCRIPT_SRC)
  }

  // redirects to the AfterPay page
  static redirectToAfterPay(countryCode: string = "AU", token: string) {
    window.AfterPay.initialize({ countryCode: countryCode });
    window.AfterPay.redirect({ token });
  }

}


export default AfterPayHandler;