import { Box } from "@material-ui/core";

import { Colors } from "../../../constants/colors";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import AvatarImageWithCovidStatus, { ImageSize } from "../../AvatarImageWithCovidStatus";

const ProImage = ({ image, firstName, lastName, onClick }: any) => {
  return (
    <Box
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <AvatarImageWithCovidStatus
        size={ImageSize.mediumLarge}
        imageSrc={`${BASE_UPLOADS_URL}/${image}`}
        isCovidVaccinated={false}
        whiteBorderImage={true}
      />
      <Box
        style={{
          fontFamily: "Museo",
          fontWeight: "600",
          fontSize: "16px",
          marginTop: "8px",
          color: Colors.Dusk,
        }}
      >
        {firstName} {lastName}
      </Box>
    </Box>
  );
};

export default ProImage;
