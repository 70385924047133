import { Box, Typography } from "@material-ui/core";


interface Props {
  title: string;
  subtitle?: string;
}

export default function ModalHeadeApp({
  title,
  subtitle,
}: Props): JSX.Element {
  return (
    <Box paddingLeft="20px" paddingRight="20px" paddingBottom="12px" paddingTop="30px">
      <Typography variant="h2" style={{ marginLeft: "20px" }}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="h3"
          style={{ marginTop: "16px", marginLeft: "20px", fontSize: "14px" }}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}
