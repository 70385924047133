import { Box } from "@material-ui/core";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";

interface Props {
  visible: boolean;
  address: string;
  message: string;
  onConfirm: () => unknown;
  onClose: () => unknown;
}

const BookingCompleteModal = ({ visible, address, message, onConfirm, onClose }: Props) => {
  return (
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      actions={[
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "8px",
            width: "100%",
            padding: "0px 16px 16px",
          }}
        >
          <Button title="Cancel" type={ButtonType.outlined} onClick={onClose} />
          <Button title="Ok" type={ButtonType.secondary} onClick={onConfirm} />
        </Box>,
      ]}
    >
      <Box
        style={{
          padding: "12px 8px 0px 8px",
        }}
      >
        <Box
          fontFamily="Museo"
          fontWeight={700}
          fontSize="22px"
          color={Colors.NightBlue}
          textAlign={"center"}
        >
          Booking in {address}
        </Box>
        <Box
          fontFamily="Open Sans"
          fontSize="16px"
          color={Colors.BlueyGrey}
          mt="16px"
          mb="5px"
          textAlign={"center"}
        >
          {message}
        </Box>
      </Box>
    </ContentModal>
  );
};

export default BookingCompleteModal;
