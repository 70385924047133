import React, { useCallback, useEffect, useState } from "react";
import Dropdown from "../../../Dropdown";
import TextField from "../../../TextField";
import { findItem, getValue } from "../../../../utils/object";
import { ServiceRatePrice } from "../../../../models";
import { debounce } from "../../../../utils/debounce";
import ServiceWrapper from "../../../Layout/ServiceWrapper";
import { formatMinutesToDisplayTime } from "../../../../helpers/time";
import TreatmentDropdownMenu from "../../../Treatment/DropdownMenu";
import {
  getBookingPrice,
  getMassageService,
  useBookingStore,
  useLocationDetailsStore,
} from "../../../../stores/booking";
import { usePaymentStore } from "../../../../stores/booking";
import {
  useGetQuoteAsEmail,
  useTotalEventDuration,
} from "../../../../hooks/booking/corporate.hooks";
import { EVENT_TYPE, NUMBER_OF_PROS } from "../../../../data/corporate.options";
import {
  getChangeOverTimeForTreatment,
  getDurationPerPersonOption,
} from "../../../../services/bookings/corporate/corporateBookingConfig.service";
import { CORPORATE_CLIENTS_NUM, GenderPreference } from "../../../../constants/booking";
import { BOOKING_SESSION_TYPE } from "../../../../helpers/booking";
import Button, { ButtonType } from "../../../Button";
import { useAlertStore } from "../../../../stores/alert";
import SendQuotationToModal from "../../../ReviewAndBook/SendQuotationToModal";
import { Box } from "@material-ui/core";
import isValidEmail from "../../../../helpers/validation";
import { checkIfEmpty } from "../../../../utils/object";
import GenderFallback from "../../../../pages/NewBooking/GenderFallback";
import { MassageTypeInfoModalType } from "../../../MassageTypeInfoModal";
import GenderFallbackInfoModal from "../../../GenderFallbackInfoModal/GenderFallbackInfoModal";
import { useCorporateConfig } from "../../../../hooks/corporateBooking/corporateBooking.hooks";

interface Props {
  treatments: ServiceRatePrice[];
  onTreatmentChanged: (treatmentId: number) => unknown;
}

export interface SendToSomeoneInterface {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  note: string | null;
  isSendToMe: boolean;
  isGuest: boolean;
  guestFirstName: string | null;
  guestLastName: string | null;
  guestEmail: string | null;
}

const CorporateFlow = ({ treatments, onTreatmentChanged }: Props): JSX.Element => {
  const {
    serviceId,
    treatmentId,
    setTreatmentId,
    numberOfPerson,
    massageType1: massageType,
    setMassageType1: setMassageType,
    setNumberOfPerson,
    durationPerPerson,
    setDurationPerPerson,
    numberOfPros,
    setNumberOfPros,
    eventType,
    setEventType,
    eventName,
    setEventName,
    noteForRecipients,
    setNoteForRecipients,
    preferredTherapists,
    address,
    totalEventDuration,
    setTotalEventDuration, // need this for price
    updateCorporateFields,
    setSessionType,
    currencySymbol,
    genderPreference1,
    setGenderPreference1,
    genderFallback,
    setGenderFallback,
    frequency,
    isSameProvider,
  } = useBookingStore();

  const { countryCode, fullAddress } = useLocationDetailsStore();

  const { genderPreferences: serviceGenderPreferences = [] } = getMassageService();
  const { bookingPrice } = usePaymentStore();

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const defaultMAX = NUMBER_OF_PROS[NUMBER_OF_PROS.length - 1].value;
  const [minProCount, setMinProCount] = useState(1);
  const [maxProCount, setMaxProCount] = useState(defaultMAX);
  const [prosCountOptions, setProCountOptions] = useState(NUMBER_OF_PROS);

  const { data: corporateConfig } = useCorporateConfig();

  const [numOfPerson, setNumOfPerson] = useState(numberOfPerson);
  const [durationOptions, setDurationOptions] = useState<any>([]);

  const [changeoverTime, setChangeoverTime] = useState(5);
  const [showQuotationToModal, setShowQuotationToModal] = useState(false);

  const [showGenderFallBack, setShowGenderFallBack] = useState(false);
  const [genderFallbackMessage, setGenderFallbackMessage] = useState(""); 

  const [infoModal, setInfoModal] = React.useState<any>({
    [MassageTypeInfoModalType.massageType1]: false,
    [MassageTypeInfoModalType.massageType2]: false,
    sessionType: false,
    genderFallback: false,
  });

  const MAX_CLIENTS = CORPORATE_CLIENTS_NUM.max;
  const MIN_CLIENTS = CORPORATE_CLIENTS_NUM.min;

  const { isLoading: isDurationLoading, data: totalDuration } = useTotalEventDuration({
    numberOfPerson,
    durationPerPerson,
    numberOfPros,
    massageType,
  });

  const { isLoading: isSendingQuote, mutate: getQuote } = useGetQuoteAsEmail({
    onSuccess: () => {
      setSuccessMessage("Quote sent. Please check your inbox and spam folder.");
      setShowQuotationToModal(false);
    },
    onError: () => setErrorMessage("Something went wrong"),
  });

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!isDurationLoading && totalDuration) {
      handleTotalDurationChanged();
    }
  }, [totalDuration]);

  useEffect(() => {
    const defaultValue = "*";
    const options = getDurationPerPersonOption(corporateConfig, massageType || defaultValue);
    setDurationOptions(options);
    
    if (!checkIfEmpty(options) && !durationPerPerson && options[0].value) {
      setDurationPerPerson(options[0].value);
    }
  }, [corporateConfig, massageType]);

  const handleTotalDurationChanged = () => {
    const minProsRequired = getValue(totalDuration, "minProsRequired", 1);
    const maxProsAllowed = getValue(totalDuration, "maxProsAllowed", defaultMAX);

    handleProCountOptions(minProsRequired, maxProsAllowed);
    setTotalEventDuration(totalDuration);
    getBookingPrice();
  };

  useEffect(() => {
    setNumOfPerson(numberOfPerson);
  }, [numberOfPerson]);

  const handleProCountOptions = (min: number, max: number) => {
    if (!numberOfPros || numberOfPros < min) {
      setNumberOfPros(min);
    } else if (numberOfPros > max) {
      setNumberOfPros(max);
    }

    if (minProCount !== min || maxProCount !== max) {
      setMinProCount(min);
      setMaxProCount(max);
      const options = NUMBER_OF_PROS.filter(({ value: opt }) => opt >= min && opt <= max);
      setProCountOptions(options);
    }
  };

  const initialize = () => {
    const updateForCorporate = {} as any;

    if (!numberOfPerson) {
      updateForCorporate.numberOfPerson = 1;
    }
    if (!numberOfPros) {
      updateForCorporate.numberOfPros = NUMBER_OF_PROS[0].value;
    }
    if (!eventType) {
      updateForCorporate.eventType = EVENT_TYPE[0].value;
    }
    if (Object.keys(updateForCorporate).length > 0) {
      updateCorporateFields(updateForCorporate);
    }
  };

  const genderPreferenceOptions = serviceGenderPreferences.map((preference) => {
    return {
      value: preference.value,
      title: preference.title,
      helperText: preference.helpText || undefined,
    };
  });

  const getTotalEventDuration = () => {
    if (isDurationLoading) return "-";
    return formatMinutesToDisplayTime(totalDuration?.massageDuration);
  };

  const handleTreatmentSelect = (treatment: ServiceRatePrice) => {
    setMassageType(treatment.name);
    setSessionType(BOOKING_SESSION_TYPE.CORPORATE);
    setTreatmentId(treatment.id);
    const changeoverTime = getChangeOverTimeForTreatment(corporateConfig, treatment.name);
    const options = getDurationPerPersonOption(corporateConfig, treatment.name);
    setChangeoverTime(changeoverTime);
    handleDurationPerPerson(options);
  };

  const debounceNumberOfPeople = useCallback(
    debounce((value: any) => setNumberOfPerson(parseInt(value)), 300),
    []
  );

  const handlePeopleChange = (value: any) => {
    let personNum = value;
    if (value !== "") {
      if (value < MIN_CLIENTS) personNum = MIN_CLIENTS;
      else if (value > MAX_CLIENTS) personNum = MAX_CLIENTS;
      debounceNumberOfPeople(personNum);
    }
    setNumOfPerson(personNum);
  };

  const handlePeopleNumBlur = () => {
    if (numOfPerson) return;
    setNumOfPerson(MIN_CLIENTS);
    debounceNumberOfPeople(MIN_CLIENTS);
  };

  const handleDurationPerPerson = (options: any) => {
    if (checkIfEmpty(options)) return;
  
    const noSelectedOption =
      !findItem(options, { key: "value", value: durationPerPerson });
    if (noSelectedOption) {
      setDurationPerPerson(options[0].value);
    }
  };

  const onConfirm = (data: SendToSomeoneInterface) => {
    const preferredPro = !checkIfEmpty(preferredTherapists)
      ? preferredTherapists
        .map(
          (therapist) =>
            therapist.therapistData.id || therapist.therapistData?.userservices[0]?.userId
        )
        .join(",")
      : null;

    const payload: any = {
      addressId: address?.id || null,
      timezone: address?.timezone,
      countryCode,
      fullAddress,
      preferredTherapists: preferredPro,
      frequency,
      isSameProvider,
      durationPerPerson,
      totalEventDuration,
      eventType,
      noteForRecipients,
      numberOfPerson,
      numberOfPros,
      serviceId,
      massageType,
      treatmentId,
      sendToMe: data.isSendToMe,
      recipientFirstName: data.firstName,
      recipientLastName: data.lastName,
      recipientEmail: data.email,
      recipientNote: data.note,
      isGuest: data.isGuest,
      guestFirstName: data.guestFirstName,
      guestLastName: data.guestLastName,
      guestEmail: data.guestEmail,
    };

    if (eventName) {
      payload.eventName = eventName;
    }

    const validPayload = validateCorporateFields(payload);

    if (!validPayload) {
      return;
    }

    getQuote({ data: validPayload });
  };

  const validateCorporateFields = (payload: any) => {
    const conditions = [
      { check: !payload.treatmentId, message: "Please select a treatment" },
      { check: !payload.numberOfPerson, message: "Please enter number of people" },
      {
        check:
          payload.numberOfPerson < CORPORATE_CLIENTS_NUM.min ||
          payload.numberOfPerson > CORPORATE_CLIENTS_NUM.max,
        message: `Number of clients must be in range ${CORPORATE_CLIENTS_NUM.min} to ${CORPORATE_CLIENTS_NUM.max}`,
      },
      { check: !payload.durationPerPerson, message: "Please select duration per person" },
      { check: !payload.numberOfPros, message: "Please select number of provider" },
      { check: !payload.eventType, message: "Please select a treatment" },
      { check: payload.isGuest && !payload.guestFirstName, message: "Please enter first name" },
      { check: payload.isGuest && !payload.guestLastName, message: "Please enter last name" },
      {
        check: payload.isGuest && !payload.guestEmail,
        message: `Please enter email${payload.sendToMe ? " to send quotation" : ""}`,
      },
      {
        check: payload.isGuest && !isValidEmail(payload.guestEmail),
        message: "Please enter a valid email",
      },
      {
        check: !payload.sendToMe && !payload.recipientFirstName,
        message: "Please enter recipient first name",
      },
      {
        check: !payload.sendToMe && !payload.recipientLastName,
        message: "Please enter recipient last name",
      },
      {
        check: !payload.sendToMe && !payload.recipientEmail,
        message: "Please enter recipient email to send quotation",
      },
      {
        check: !payload.sendToMe && !isValidEmail(payload.recipientEmail),
        message: "Please enter a valid email",
      },
    ];

    const error = conditions.find((condition) => condition.check);
    if (error) {
      setErrorMessage(error.message);
      return false;
    }

    return payload;
  };

  const getChangeOverTimeString = () => {
    if (changeoverTime > 1) {
      return `${changeoverTime - 1}-${changeoverTime}min`;
    }
    return `${changeoverTime}min`;
  };

  const getEventDurationSubtitle = () => {
    const minSpend = bookingPrice?.minSpend;
    let proCount = numberOfPros || 1;
    return minSpend
      ? `Minimum spend value is ${currencySymbol}${minSpend} for ${proCount} ${
        proCount > 1 ? "providers" : "provider"
      }.`
      : undefined;
  };

  const genderFallBackMessage = () => {
    const validGenderSelection = [GenderPreference.femaleOnly, GenderPreference.maleOnly].includes(
      genderPreference1 || ""
    );

    if (validGenderSelection) {
      setShowGenderFallBack(true);
      let bookGender = "";
      let unavailableGender = "";
      if (genderPreference1 === GenderPreference.femaleOnly) {
        bookGender = "male";
        unavailableGender = "female";
      } else if (genderPreference1 === GenderPreference.maleOnly) {
        bookGender = "female";
        unavailableGender = "male";
      }

      const genderFallbackMessage = `Book ${bookGender} if ${unavailableGender} is unavailable`;
      setGenderFallbackMessage(genderFallbackMessage);
    } else {
      setShowGenderFallBack(false);
    }
  };

  const onInfoModalChange = (type: any, value: boolean) => {
    setInfoModal({ [type]: value });
  };

  const handleGenderPrefSelect = (option: any) => {
    setGenderPreference1(option.value);
    setShowGenderFallBack(false);
    setGenderFallback(false);
  };
  useEffect(() => {
    genderFallBackMessage();
  }, [genderPreference1]);

  const getBreaksInfo = () => {
    return `Sessions are separated by ${changeoverTime}-minute changeovers. Bookings exceeding 4 hours offer a 30-minute break, and therapists take 10-minute breaks every 2 hours.`;
  };

  const breaksInfoText = getBreaksInfo();
  return (
    <>
      <ServiceWrapper>
        <TreatmentDropdownMenu
          label="Treatment type"
          treatments={treatments}
          onSelect={handleTreatmentSelect}
          value={treatmentId}
        />

        <TextField
          type="number"
          title="How many people do you expect to have?"
          value={String(numOfPerson)}
          onChange={(value) => handlePeopleChange(value)}
          onBlur={() => handlePeopleNumBlur()}
          minValue={MIN_CLIENTS}
          maxValue={MAX_CLIENTS}
        />

        <Dropdown
          title="Duration per person"
          description={breaksInfoText}
          options={durationOptions}
          selectedOption={findItem(durationOptions, { key: "value", value: durationPerPerson })}
          onSelectedOption={({ value }) => setDurationPerPerson(value)}
        />

        <Dropdown
          title="How many providers would you prefer to have?"
          options={prosCountOptions}
          selectedOption={findItem(NUMBER_OF_PROS, { key: "value", value: numberOfPros })}
          onSelectedOption={({ value }) => setNumberOfPros(value)}
        />

        <Box display="flex" flexDirection="column">
          <Dropdown
            title="Provider gender preference"
            options={genderPreferenceOptions}
            selectedOption={genderPreferenceOptions.find(
              (option) => option.value === genderPreference1
            )}
            onSelectedOption={handleGenderPrefSelect}
          />
          {showGenderFallBack && (
            <GenderFallback
              onInfoClick={(value) => onInfoModalChange("genderFallback", value)}
              onChange={setGenderFallback}
              genderFallback={genderFallback}
              genderFallbackMessage={genderFallbackMessage}
            />
          )}
        </Box>

        <GenderFallbackInfoModal
          isOpen={infoModal["genderFallback"]}
          title={"Therapist Gender"}
          description={
            "To increase the speed of your booking confirmation, we suggest selecting ‘no preference’ or indicating you’re open the other genders if your preferred therapist gender is unavailable."
          }
          onClose={() => onInfoModalChange("genderFallback", false)}
        />

        <TextField
          title="Total event duration"
          subtitle={getEventDurationSubtitle()}
          value={getTotalEventDuration()}
          disabled
        />

        <Dropdown
          title="Event type"
          options={EVENT_TYPE}
          selectedOption={findItem(EVENT_TYPE, { key: "value", value: eventType })}
          onSelectedOption={({ value }) => setEventType(value)}
        />

        <TextField
          title="Event name"
          placeholder="(optional)"
          value={eventName}
          onChange={(value) => setEventName(value)}
        />

        <TextField
          multiline
          title="Note for recipients"
          placeholder="(optional)"
          value={noteForRecipients}
          onChange={(value) => setNoteForRecipients(value)}
        />
        <Box mt="32px" width="174px">
          <Button
            loading={isSendingQuote}
            type={ButtonType.indigo}
            title={"Email as a quote"}
            onClick={() => setShowQuotationToModal(true)}
          />
        </Box>
      </ServiceWrapper>
      <SendQuotationToModal
        open={showQuotationToModal}
        onClose={() => setShowQuotationToModal(false)}
        onConfirm={(data: SendToSomeoneInterface) => onConfirm(data)}
      />
    </>
  );
};

export default CorporateFlow;
