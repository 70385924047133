export enum LOCKED_ATTRIBUTES {
  NAME = "NAME",
  TREATMENT = "TREATMENT",
  PRO_PROFILE_IMAGE = "PRO_PROFILE_IMAGE",
}

const PRO_ACCOUNT_STATUS = {
  PENDING: "pending",
  ACTIVE: "active",
  REVIEW: "review",
  DEACTIVATED: "deactivated",
  DECLINED: "declined",
  SUSPENDED: "suspended",
};

const PRO_DOCUMENT_TYPES = {
  INSURANCE: "insurance",
  QUALIFICATION: "qualification",
  PORTFOLIO: "portfolio",
  POLICE_CHECK: "policecheck",
} as const;

const PRO_DOCUMENTS = {
  [PRO_DOCUMENT_TYPES.INSURANCE]: "Provider's Insurance",
  [PRO_DOCUMENT_TYPES.POLICE_CHECK]: "Police Check Report",
  [PRO_DOCUMENT_TYPES.QUALIFICATION]: "Provider's Qualification",
  [PRO_DOCUMENT_TYPES.PORTFOLIO]: "Provider's Portfolio",
};

const ACC_STATUS_RESTRICT_DATA = [
  PRO_ACCOUNT_STATUS.PENDING,
  PRO_ACCOUNT_STATUS.DECLINED,
  PRO_ACCOUNT_STATUS.DEACTIVATED,
  PRO_ACCOUNT_STATUS.SUSPENDED,
];

export { PRO_ACCOUNT_STATUS, ACC_STATUS_RESTRICT_DATA, PRO_DOCUMENT_TYPES, PRO_DOCUMENTS };
