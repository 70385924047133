import { Box, ButtonBase } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../constants/colors";
import InfoIcon from "../../../images/info.svg";
import Switch from "../../Switch";
import LockButton from "../../LockButton";
import useAuth from "../../../hooks/auth.hooks";
import "../../switch.css";
import { LOCKED_ATTRIBUTES } from "../../../constants/profile";
import { LockAttributePayload } from "../../../hooks/userProfileLock.hooks";
import { useAlertStore } from "../../../stores/alert";

interface Props {
  treatmentId?: number | undefined;
  title?: string;
  checked: boolean;
  onChange: (checked: boolean) => unknown;
  onInfoClick?: () => unknown;
  isCategory?: boolean;
  isLocked?: boolean;
  handleToggleLock?: (body: LockAttributePayload) => any;
  loading?: boolean;
}

const TreatmentSwitch = (props: Props) => {
  const {
    treatmentId,
    title,
    checked,
    onChange,
    onInfoClick,
    isCategory,
    isLocked,
    handleToggleLock,
    loading,
  } = props;
  const { isAdminLoggedInAsClient } = useAuth();
  const { setErrorMessage } = useAlertStore();

  const toggleLock = (treatmentId: number | undefined) => {
    if (!handleToggleLock) return;

    const payload = {
      fieldName: LOCKED_ATTRIBUTES.TREATMENT,
      value: `${treatmentId}`,
    };

    handleToggleLock(payload);
  };

  const handleSwitchClick = () => {
    if (isLocked) {
      setErrorMessage(`This ${isCategory ? "category" : "treatment"} is locked`);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      my="8px"
      position={"relative"}
      paddingRight={"14px"}
    >
      <Box onClick={() => handleSwitchClick()}>
        <Switch
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
          disabled={isLocked}
          className={`${isLocked && "switch-disable"}`}
        />
      </Box>
      {title && (
        <Box fontFamily="Museo" fontSize="16px" color={Colors.Dusk} ml={"15px"} flexGrow={1}>
          {title}
        </Box>
      )}

      {onInfoClick && (
        <Box>
          <ButtonBase onClick={onInfoClick}>
            <img src={InfoIcon} alt="Info" height="14px" />
          </ButtonBase>
        </Box>
      )}

      {isAdminLoggedInAsClient && !isCategory ? (
        <Box ml={"8px"}>
          <LockButton
            isLocked={!!isLocked}
            handlePress={() => toggleLock(treatmentId)}
            padding={0}
          />
        </Box>
      ) : (
        <React.Fragment />
      )}
    </Box>
  );
};

export default React.memo(TreatmentSwitch);
