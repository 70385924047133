import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Field from "./Field";
import FormFooter from "./FormFooter";
import Each from "../../../../../components/Each";
import { getValue } from "../../../../../utils/object";
import { useUserStore } from "../../../../../stores/user";
import { useAlertStore } from "../../../../../stores/alert";
import { COUNTRY } from "../../../../../constants/countries";
import * as Styled from "../../../../../components/v2/Styled/enum";
import {
  IFormData,
  FormDataModal,
  FormStepsName,
  TPersonalDetails,
  StripeOnboardingFormFiled,
} from "./model";

interface Props {
  data: IFormData;
  isUpdating: boolean;
  isForUpdate: boolean;
  fields: StripeOnboardingFormFiled[];
  onChange: (n: keyof IFormData, v: any) => unknown;
  onContinue: (e: MouseEvent, to: FormStepsName) => unknown;
}

const PersonalDetail = ({
  data,
  fields,
  onChange,
  onContinue,
  isForUpdate,
}: Props): JSX.Element => {
  const { user } = useUserStore();
  const [formData, setFormData] = useState<TPersonalDetails>(
    FormDataModal[FormStepsName.PersonalDetails]
  );
  const userCountry = getValue(user, "country");

  useEffect(() => {
    if (!data) return;
    setFormData(data[FormStepsName.PersonalDetails]);
  }, [data]);

  const { setErrorMessage } = useAlertStore();

  const validate = () => {
    // Only validate the fields that are editable by user
    if (!formData.dob) {
      setErrorMessage("Please select your date of birth.");
      return false;
    }
    if (formData.dob?.toString() === "Invalid Date") {
      setErrorMessage("Invalid date.");
      return false;
    }
    if (userCountry === COUNTRY.US && !formData.idNumber && !formData.idNumberProvided) {
      setErrorMessage("Social Security Number is required.");
      return false;
    }
    return true;
  };

  const handleSave = (e: MouseEvent, cb = (e: MouseEvent) => {}) => {
    const isValid = validate();
    if (!isValid) return;

    onChange(FormStepsName.PersonalDetails, formData);
    cb(e);
  };

  const handleChange = (field: keyof TPersonalDetails, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleNext = (e: MouseEvent) => {
    handleSave(e, () => onContinue(e, FormStepsName.Address));
  };

  const buildRow = (field: StripeOnboardingFormFiled) => {
    const name = getValue(field, "name") as keyof TPersonalDetails;
    const value = formData[name];
    return (
      <Box>
        <Field
          value={value || ""}
          field={field}
          formData={formData}
          onChange={(value) => handleChange(name, value)}
        />
      </Box>
    );
  };

  return (
    <Box display={Styled.Display.Flex} flexDirection={Styled.FlexDirection.Column}>
      <Each of={fields} render={buildRow} />
      <FormFooter
        onContinue={handleNext}
        isForUpdate={isForUpdate}
        style={{ marginTop: Styled.Spacing.S4 }}
      />
    </Box>
  );
};

export default PersonalDetail;
