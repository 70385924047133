import { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

import "../../../styles/DayPicker.css";
import { RootState } from "../../../stores/V2";
import { useMobile } from "../../../hooks/mobile";
import { Paths } from "../../../constants/paths";
import LogoLink from "../../../components/LogoLink";
import CorporateInformation from "./CorporateInformation";
import { actions } from "../../../stores/V2/corporate/booking";
import LandingWrapper from "../../../components/LandingWrapper";
import SlotsList from "../../../components/BlysTeam/Slots/SlotsList";
import { BookingSlot, SlotParams } from "../../../stores/V2/corporate/interface";
import {
  useCorproateBooking,
  useReleaseSlot,
  useReserveSlot,
} from "../../../hooks/corporateBooking/corporateBooking.hooks";
import { ReservedSlotSuccessData } from "../../../models";
import { parseQueryString } from "../../../helpers/string";
import InfoModal from "../../../components/Modals/InfoModal/InfoModal";
import ActionModal from "../../../components/ActionModal";
import { formatDateWithTimezone } from "../../../utils/date.util";
import { useAlertStore } from "../../../stores/alert";
import PageMeta from "../../../components/PageMeta/PageMeta";
import { CORPORATE_LINK_META } from "../../../data/corporate.options";

const ConfirmSlot = () => {
  const isMobile = useMobile();
  const state = useSelector((state: RootState) => state.coroprateBooking);
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<SlotParams>();
  const location = useLocation();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const { booking, refetch: refetchCorporateBooking } = useCorproateBooking(
    params.uuid,
    location.search
  );

  const [showConfirmInfo, setShowConfirmInfo] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [reservedSlot, setReservedSlot] = useState<null | ReservedSlotSuccessData>(null);
  const userHasBookedSlot = !!reservedSlot;

  const handleReleaseSlotSuccess = () => {
    refetchCorporateBooking();
    setShowRemoveConfirm(false);
    setSuccessMessage("Booking cancelled");
    dispatch(actions.resetSelectedSlot());
  };

  const { mutate: releaseSlot } = useReleaseSlot({
    onSuccess: handleReleaseSlotSuccess,
    onError: () => setErrorMessage("Something went wrong. Please try again"),
  });

  const handleConfirmSlotSuccess = (response: any) => {
    if (response && response.alreadyBooked) {
      setShowConfirmInfo(true);
      return;
    }
    const selectedSlotId = state.selectedSlot.id;
    setSuccessMessage("Booking slot reserved");
    const reservedPath = `${Paths.CorporateSlots}/${params.uuid}/${selectedSlotId}/reserved`;
    history.push(reservedPath, { slotId: response.slotId });
  };

  const { mutate: confirmSlot } = useReserveSlot({
    onSuccess: handleConfirmSlotSuccess,
    onError: () => setErrorMessage("Something went wrong. Please try again"),
  });

  const getSlotUUIDFromURL = () => {
    const searchData = parseQueryString(location.search) as { slotId?: string };
    if (searchData && searchData.slotId) return searchData.slotId;
    return null;
  };

  useEffect(() => {
    if (booking) dispatch(actions.initialize(booking));
  }, [booking, dispatch]);

  // on url query string change
  useEffect(() => {
    if (!location.search && reservedSlot) {
      setReservedSlot(null);
      return;
    }

    const uuid = getSlotUUIDFromURL();
    if (booking.bookedSlot && uuid) {
      const bookedData = {
        uuid,
        ...booking.bookedSlot,
      };
      setReservedSlot({ ...bookedData });
    }
  }, [location.search, booking]);

  const handSlotPressed = (slot: BookingSlot) => {
    dispatch(actions.setSelectedSlot(slot));
  };

  const handleSlotConfirm = (slot: BookingSlot) => {
    if (!userHasBookedSlot) {
      history.push(`${Paths.CorporateSlots}/${params.uuid}/${slot.id}/confirm`);
      return;
    }
    if (!reservedSlot) return;

    const { userId, recipientId } = reservedSlot;
    const data = {
      uuid: params.uuid,
      slotId: slot.id,
      data: recipientId ? { recipientId } : { userId },
    };

    confirmSlot(data as any);
  };

  const handleConfirmInfoClose = () => {
    setShowConfirmInfo(false);
  };

  const handleSlotRemoveCancel = () => {
    setShowRemoveConfirm(false);
  };

  const handleSlotReleaseConfirm = () => {
    if (!reservedSlot || !reservedSlot.uuid) return;

    const { uuid } = reservedSlot;
    releaseSlot({ uuid: params.uuid, slotId: uuid });
  };

  const handleSlotRemove = (slot: BookingSlot) => {
    if (!reservedSlot) return;
    setShowRemoveConfirm(true);
  };

  const getAllowRemoveIds = () => {
    let ids = [];
    if (reservedSlot && reservedSlot.id) ids.push(reservedSlot.id);
    return ids;
  };

  const getSlotTime = (slot: BookingSlot) => {
    if (!slot || !slot.start) return "";
    const timezone = state.booking.timezone;
    return formatDateWithTimezone(slot.start, timezone, "h:mma");
  };

  const { title: metaTitle, description: metaDescription } = CORPORATE_LINK_META;

  return (
    <LandingWrapper showPromoImages={false}>
      <PageMeta title={metaTitle} description={metaDescription} />
      <Box position="relative" width={isMobile ? undefined : "1024px"} marginBottom="80px">
        <LogoLink />
        <CorporateInformation step={1} showCalendar={false} booking={state.booking} />
        <SlotsList
          jobs={state.booking.jobs}
          onPress={handSlotPressed}
          onPressRemove={handleSlotRemove}
          selectedSlot={state.selectedSlot}
          onPressConfirm={handleSlotConfirm}
          allowRemoveSlots={getAllowRemoveIds()}
          allowSlotRemoveOption={userHasBookedSlot}
          timeZone={state.booking.timezone}
          uuid={params.uuid}
          earliestTime={booking.earliestTime}
          isMobile={isMobile}
        />
      </Box>
      <InfoModal
        divider={false}
        maxWidth={"sm"}
        handleClose={handleConfirmInfoClose}
        title="You already have a booking"
        description="You can only book one time slot. You have to cancel your booked time to select a new time."
        descriptionStyle={{ fontFamily: "Open Sans", fontSize: "16px", fontWeight: 400 }}
        visible={showConfirmInfo}
      />
      <ActionModal
        open={showRemoveConfirm}
        title="Remove timeslot"
        description={`You already have a booking at ${getSlotTime(
          state.selectedSlot
        )}. Would you like to remove it? `}
        onCancel={handleSlotRemoveCancel}
        onConfirm={handleSlotReleaseConfirm}
      />
    </LandingWrapper>
  );
};

export default ConfirmSlot;
