import React from "react";
import { Box, Link } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props {
  title: string;
  selected: boolean;
  onClick: () => unknown;
}

export default function SideMenuLink({
  title,
  selected,
  onClick,
}: Props): JSX.Element {
  return (
    <Box
      fontFamily="Museo"
      fontSize="15px"
      lineHeight="40px"
      fontWeight={500}
      style={{ cursor: "pointer" }}
      paddingLeft="20px"
      paddingRight="20px"
      letterSpacing="0.2px"
    >
      <Link
        style={{ color: selected ? Colors.TurquoiseBlue : Colors.Dusk }}
        onClick={onClick}
      >
        {title}
      </Link>
    </Box>
  );
}
