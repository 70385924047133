import { Box } from "@material-ui/core";
import { useBookingStore, useServicesStore } from "../../../stores/booking";
import { transformCorporateForDescription } from "../../../stores/V2/booking/booking.transformer";
import { CORPORATE_BOOKING_KEYS, DURATION_PER_PERSON } from "../../../data/corporate.options";
import { Colors } from "../../../constants/colors";
import { useEffect, useState } from "react";
import { checkIfEmpty } from "../../../utils/object";
import { useCorporateConfig } from "../../../hooks/corporateBooking/corporateBooking.hooks";

interface Props {
  serviceId: any,
  treatmentId: any,
  corporateDetails: any,
};

const CorporateTreatmentDetailItem = ({ serviceId, treatmentId, corporateDetails }: Props) => {
  const { rates } = useServicesStore();

  const { data: corporateConfig } = useCorporateConfig();
  const [label, setLabel] = useState<string>("");

  useEffect(() => {
    const selectedService = rates.find(({ id }) => id === serviceId);

    if (!selectedService) {
      setLabel("Select service");
      return;
    }

    const selectedTreatment = selectedService.prices?.find(({ id }) => id === treatmentId);
    let formattedLabel = `${selectedService?.alias}`;

    if (selectedTreatment && !checkIfEmpty(selectedTreatment)) {
      formattedLabel+= ` | ${selectedTreatment.label}`;
    }

    setLabel(formattedLabel);
  }, [rates, serviceId, treatmentId]);

  const getDurationOptions = () => {
    if (checkIfEmpty(corporateConfig) || !treatmentId || !serviceId) return DURATION_PER_PERSON;

    const selectedService = rates.find(({ id }) => id === serviceId);
    if (!selectedService) return DURATION_PER_PERSON;

    const selectedTreatment = selectedService.prices?.find(({ id }) => id === treatmentId);

    const selectedConfig = selectedTreatment ? corporateConfig[selectedTreatment.name] : {};
    if (!selectedConfig || !selectedConfig.durationPerPersonOptions) return DURATION_PER_PERSON;
    return selectedConfig.durationPerPersonOptions;
  };

  const getDescription = () => {
    const durationOptions = getDurationOptions();

    const corporateDescription = transformCorporateForDescription(corporateDetails, durationOptions);
    return corporateDescription.map((item: any) => {
      if (!item.value && item.key === CORPORATE_BOOKING_KEYS.eventName) return <></>;
      if (!item.value && item.key === CORPORATE_BOOKING_KEYS.noteForRecipients) return <></>;
      return (
        <Box key={item.key} lineHeight={"21px"}>{`${item.label} ${item.value ? item.value : "-"}`}</Box>
      );
    });
  };

  return <Box>
    <Box
      fontFamily="Museo"
      fontSize="16px"
      color={Colors.Dusk}
      style={{ overflowWrap: "break-word" }}
    >
      {label}
    </Box>
    <Box style={styles.infoText}>
      {getDescription()}
    </Box>
  </Box>;
};

const styles = {
  infoText: {
    fontFamily: "Open sans",
    fontWeight: 400,
    fontSize: "14px",
    color: Colors.BlueyGrey,
  },
};

export default CorporateTreatmentDetailItem;
