import React from "react";
import { Box, Typography } from "@material-ui/core";
import LandingWrapper from "../../components/LandingWrapper";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { isEmpty } from "lodash";
import { useUserStore } from "../../stores/user";
import { useAlertStore } from "../../stores/alert";
import { useLocation } from "react-router-dom";
import LogoLink from "../../components/LogoLink";
import LogoutAndLoginButton from "../../components/Afterpay/LogoutAndLoginButton";
import UserDetails from "../../components/Afterpay/UserDetails";
import { parseAfterpayAccessError } from "../../helpers/error";
import { getAccessToken } from "../../helpers/accessToken";

interface Params {
  id: string;
}

export default function AfterpayAccessDenied() {
  const isMobile = useMobile();
  const location = useLocation();

  const { id } = useParams<Params>();

  const accessToken = getAccessToken();

  const {
    data: bookingData,
    mutate: bookingMutate,
    error,
  } = useSWR(
    !isEmpty(accessToken) ? `/bookings/${id}?accessToken=${accessToken}` : null
  );

  const { user, fetchMe } = useUserStore();

  const [authUser, setAuthUser] = React.useState({});

  const { setErrorMessage } = useAlertStore();

  React.useEffect(() => {
    if (error) {
      const { message, userDetails } = parseAfterpayAccessError(error);

      // setErrorMessage(message);
      setAuthUser(userDetails);
    }
  }, [error]);

  React.useEffect(() => {
    fetchMe();
  }, []);

  // NOTE: /afterpay-error route uses this same page as well
  React.useEffect(() => {
    if (location.pathname.includes("afterpay-error")) {
      setErrorMessage(
        "Request to Afterpay could not be processed, please try again"
      );
    }
  }, [location]);

  return (
    <>
      <LandingWrapper>
        <Box width={isMobile ? "100%" : "400px"}>
            <LogoLink />
            <Typography
                variant="h1"
                style={{
                color: Colors.NightBlue,
                marginTop: "40px",
                marginBottom: "16px",
                fontSize: "30px"
                }}
            >
                Wellness, delivered.
            </Typography>
            <Typography variant="body1"
            style={{
              color: "red",
              fontSize: isMobile ? "16px" : "20px",
              fontWeight: 600
              }}>
              { user ? `You are currently logged in as ${user.email}`: "Please log in to continue"}
            </Typography>
           {
           authUser ? 
           (<Box>
              <UserDetails details={authUser}/>
            </Box>
           ):
           <></>
           }
            <LogoutAndLoginButton details={authUser}/>
        </Box>
      </LandingWrapper>
    </>
  );
}
