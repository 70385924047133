import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { put } from "../api/client";

const THERAPIST_PROFILE_KEYS = {
  PROFILE: "PROFILE",
};

const useUpdateTherapistProfile = ({
  onSuccess = () => {},
  onError = () => {},
}: {
  onSuccess?: () => unknown;
  onError?: (e: AxiosError) => unknown;
}) =>
  useMutation((data: any) => put("therapists/me", data), {
    onSuccess: () => onSuccess(),
    onError: (e: AxiosError) => onError(e),
  });

export { useUpdateTherapistProfile };
