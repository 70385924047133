import TherapistBlock from "./TherapistBlock";
import { Box } from "@material-ui/core";
import { useMobile, useTab } from "../../../hooks/mobile";
import {BASE_UPLOADS_URL} from "../../../constants/common";
import PaginationControl from "../../../components/PaginationControl";
import Button, { ButtonSize, ButtonType } from "../../../components/Button";
import { FilterButton } from "../../../components/FilterButton/FilterButton";
import { Display, JustifyContent, Spacing } from "../../../components/v2/Styled/enum";
import { useEffect, useState } from "react";
import { BrowseTherapistFilter, providerBrowseFilters } from "./BrowseTherapistsFilter";
import { isEmpty } from "lodash";
import { NoResultPlaceholder } from "../../../components/NoResultPlaceholder";
import { removeDataFromUrl } from "../../../helpers/filter";

interface Props {
  providers? : any,
  pageDetails : { pageCount:number, currentPage:number },
  resetSearch : Function,
  onPageChange : Function,
  onFilterApplied: (data: any) => unknown,
  onFilterCountChange: (count: number) => unknown,
  filterCount: number,
}

export interface ProviderFilters {
  language: string | null,
  sortBy: string | null,
  healthFund: string | null,
  gender: string | null,
  name: string | null,
  startTime: Date | string | null,
  endTime: Date | string | null,
}

const BrowseResults = ({ 
  providers,
  resetSearch,
  pageDetails,
  onPageChange,
  onFilterApplied,
  onFilterCountChange,
  filterCount } : Props): JSX.Element => {

  const isMobile = useMobile();
  const isTab = useTab();
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const initialFiter = {
    language: null,
    sortBy: null,
    healthFund: null,
    gender: null,
    name: null,
    startTime: null,
    endTime: null
  };

  const [appliedFilter, setAppliedFilter] = useState<ProviderFilters>(initialFiter);

  const handleFilterApplied = ()=>{
    onFilterApplied(appliedFilter);
    initializeFilters();
  };

  const initializeFilters = (persistDataFromUrl: boolean = true) => {
    const params = new URLSearchParams(window.location.search);

    const gender = params.get("gender") || null;
    const language = params.get("language") || null;
    const healthFund = params.get("healthFund") || null;
    const sortBy = params.get("sortBy") || null;
    const name = params.get("name") || null;
    const startTime = params.get("startTime") || null;
    const endTime = params.get("endTime") || null;

    const dataPersistedFromUrl = {
      gender,
      language,
      healthFund,
      sortBy,
      name,
      startTime,
      endTime
    };
    
    const filterData = persistDataFromUrl? dataPersistedFromUrl: initialFiter;
    
    const timeRange = filterData.startTime || filterData.endTime ? { startTime: filterData.startTime, endTime: filterData.endTime } : null;

    const filterCount = Object.values({
      ...filterData, 
      timeRange
    }).filter((data) => !!data && data !== filterData.startTime && data !== filterData.endTime).length;

    persistDataFromUrl && onFilterCountChange(filterCount);
    setAppliedFilter(filterData as ProviderFilters);
  };

  useEffect(() => {
    initializeFilters();
  }, []);

  const resetFilter = ({shouldSearch = true}: {shouldSearch: boolean})=>{
    removeDataFromUrl({
      filtersToRemove: providerBrowseFilters as []
    });
    onFilterCountChange(0);
    setAppliedFilter(initialFiter);
    if(shouldSearch){
      onFilterApplied(initialFiter);
    }
  };

  
  const handleFilterChanged = (filters: ProviderFilters) => {
    setAppliedFilter(filters);
  };

  const renderHeader = () => {
    return (
      <Box
        style={{
          maxWidth: "auto",
          minWidth:"auto",
          display: Display.Flex,
          justifyContent: JustifyContent.spaceBetween
        }}
      >
        <Box mb={"24px"} width={"115px"}>
          <Button
            title="New search"
            colorTitle = "#8B96AD"
            type={ButtonType.white}
            size={ButtonSize.small}
            onClick={()=>resetSearch()}
            style={{border:"1px solid #DCDFE6",borderRadius:"24px",padding:"8px 16px"}}
            fontStyle={{fontFamily:"Open Sans",fontWeight:600}}
          />
        </Box>
        <Box>
          <FilterButton
            title="Filter and sort"
            onClick={() => {
              setOpenFilterModal(true);
            }}
            // filterCount={filterCount()}
            filterCount={filterCount}
          />
        </Box>
      </Box>
    )
    ;

  };

  const renderProviders = (providers:any) => {
    if(!providers || isEmpty(providers) && filterCount){
      return (
        <Box style ={{ maxWidth:isMobile?"100%":"82%", marginTop:Spacing.S8}}>
          <NoResultPlaceholder text=" No providers found" sortOrEditFilter={()=>{resetFilter({shouldSearch: true});}}/>
        </Box>
      );   
    }

    const summaries =  providers.map((provider:any) => {
      const therapistProfile = provider.therapistprofile;
      const imageUrl = (therapistProfile && therapistProfile.profileImage) ? `${BASE_UPLOADS_URL}/${therapistProfile.profileImage}`: null;
      
      return <TherapistBlock 
        key={provider.id} 
        image={imageUrl}
        userId={provider.id} 
        isVaccinated={provider.isCovidVaccinated} 
        firstName={provider.firstName} 
        totalReviews={provider.totalReviews} 
        rating={therapistProfile.rating ?? 0} 
        isMobile={isMobile}
      />;
    });
    
    return(
      <Box display="flex" flexWrap="wrap" flexDirection="row" width="100%"  justifyContent={JustifyContent.spaceBetween}> 
        {summaries}
      </Box> 

    ) ;
  };

  const renderPaginationControl = () => {
    return <Box
      display="flex"
      justifyContent="flex-start"
      width={isMobile || isTab ? "100%" : "885px"}
      mb="55px"
    >
      <PaginationControl
        currentPage={pageDetails.currentPage - 1 } 
        pages={pageDetails.pageCount}
        onPrevious={() => onPageChange(pageDetails.currentPage-1)}
        onNext={() => onPageChange(pageDetails.currentPage+1)}
      />
    </Box>;
  };

  return <Box style = {{
    maxWidth: isMobile?"100%":"80%",
    marginRight: !isMobile && !isTab?Spacing.S20: undefined
  }}>
    {renderHeader()}
    {renderProviders(providers)}
    {renderPaginationControl()}

    <BrowseTherapistFilter 
      open={openFilterModal} 
      onClose={()=>{
        setOpenFilterModal(false);
      }}
      onFilterApplied = {handleFilterApplied}
      appliedFilter = {appliedFilter}
      handleFilterChanged = {handleFilterChanged}
      clearFilters = {()=>resetFilter({shouldSearch: false})}
    />
  </Box>;

};

export default BrowseResults;