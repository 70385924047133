import React, { useState } from "react";
import { Box } from "@material-ui/core";

import Button, { ButtonType } from "../Button";
import { useMobile, useTab } from "../../hooks/mobile";
import ContentModal from "../Modals/ContentModal/contentModal";
import { Colors } from "../../constants/colors";
import DateAndTime from "../../pages/NewBooking/DateAndTime";
import { useDateTimeStore } from "../../stores/booking";
import { useAlertStore } from "../../stores/alert";



interface ReviewAndBookDateAndTimeModalProps {
  showChangeDateModal: boolean,
  onClose: () => unknown,
}

const ReviewAndBookDateAndTimeModal = ({
  showChangeDateModal,
  onClose,
}: ReviewAndBookDateAndTimeModalProps) => {
  const isMobile = useMobile();
  const isTab = useTab();

  const { setSuccessMessage, setErrorMessage } = useAlertStore();


  const {
    selectedDay,
    earliestStartInMinutes,
    latestStartInMinutes,
    backupSelectedDay,
    backupEarliestStartInMinutes,
    backupLatestStartInMinutes,
  } = useDateTimeStore();

  const [showInfoIcon, setShowInfoIcon] = useState(false);

  const handleSelectedTimeSave = () => {
    if (selectedDay && earliestStartInMinutes && latestStartInMinutes) { 
      if (backupSelectedDay) {
        if (backupEarliestStartInMinutes && backupLatestStartInMinutes) {
          onClose();
        }
        setErrorMessage("Please select backup earliest and latest time");
      } else {
        onClose();
      }
    } else {
      setShowInfoIcon(true);
    }
  };

  return (
    <ContentModal
      visible={showChangeDateModal}
      divider={false}
      onClose={() => {
        setShowInfoIcon(false);
        onClose();
      }}
      maxWidth={isMobile || isTab ? "xs" : "md"
      }
      fullWidth={isMobile ? false : true}
      descriptionStyle={{
        paddingTop: isMobile ? 20 : 40,
        paddingLeft: isMobile ? 4 : 30,
        paddingRight: isMobile ? 4 : 30
      }}
      actionStyle={{
        display: "flex",
        padding: isMobile ? "32px 10px 32px 10px" : "32px 54px 44px 54px",
        gap: 16
      }}
      actions={
        [
          <Button title="Close" type={ButtonType.outlined} onClick={() => {
            setShowInfoIcon(false);
            onClose();
          }} />,
          <Button title="Save & apply" type={ButtonType.secondary} onClick={handleSelectedTimeSave} />
        ]}
    >
      <Box >
        <Box
          fontFamily="Museo"
          fontWeight={700}
          fontSize="30px"
          color={Colors.NightBlue}
        >
          Preferred Date & time
        </Box>
        <DateAndTime showInfoIcon={showInfoIcon} titleStyle={{ fontSize: "30px" }} />
      </Box>
    </ContentModal >
  );
};


export default ReviewAndBookDateAndTimeModal;