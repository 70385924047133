import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import { useMobile } from "../hooks/mobile";
import InfoModalHeader from "../components/InfoModalHeader";
import ModalFooter from "../components/ModalFooter";
import { Colors } from "../constants/colors";
import Pattern1 from "../images/info-modal-pattern-1.svg";
import Pattern2 from "../images/info-modal-pattern-2.svg";
import Pattern3 from "../images/info-modal-pattern-3.svg";
import Pattern4 from "../images/info-modal-pattern-4.svg";
import Button, { ButtonSize, ButtonType } from "../components/Button";
import Dialog from "./Dialog";

export interface InfoModalItem {
  icon: string;
  title: string;
  description: string;
  selected: boolean;
  value: any;
  backgroundColor: string;
}

interface Props {
  open: boolean;
  title: string;
  items: InfoModalItem[];
  onSelectedItem: (item: InfoModalItem) => unknown;
  onClose: () => unknown;
}

// const backgroundColors = [
//   Colors.VeryLightPink,
//   Colors.LightCyan,
//   Colors.PalePink,
// ];
const patterns = [Pattern1, Pattern2, Pattern3, Pattern4];

export default function ({
  open,
  title,
  items,
  onSelectedItem,
  onClose,
}: Props) {
  const isMobile = useMobile();

  const contentHeight = isMobile ? "200px" : "104px";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullScreen={isMobile}
    >
      <Box
        width={isMobile ? "100%" : "640px"}
        bgcolor="#ffffff"
        borderRadius="11px"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <InfoModalHeader title={title} onClose={onClose} />

        <Box
          display="flex"
          flexDirection="column"
          paddingTop="31px"
          paddingBottom="39px"
          paddingLeft={isMobile ? "12px" : "35px"}
          paddingRight={isMobile ? "12px" : "35px"}
          alignItems="center"
        >
          {items.map((item, index) => {
            // const bgColor = backgroundColors[index % backgroundColors.length];

            const bgColor = item.backgroundColor;
            const pattern = patterns[index % patterns.length];

            return (
              <ButtonBase
                style={{
                  height: contentHeight,
                  backgroundColor: bgColor,
                  borderRadius: "10px",
                  width: "100%",
                  overflow: "hidden",
                  position: "relative",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                onClick={() => {
                  onSelectedItem(item);

                  if (isMobile) {
                    onClose();
                  }
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  height="100%"
                  zIndex={999}
                  position="absolute"
                  alignItems="center"
                  width="100%"
                >
                  <img
                    src={item.icon}
                    style={{
                      marginLeft: "20px",
                      width: "24px",
                      height: "24px",
                    }}
                  />

                  <Box
                    display="flex"
                    flexDirection="column"
                    ml="22px"
                    mr="28px"
                    flex={1}
                    width="100%"
                  >
                    <Box
                      fontFamily="Museo"
                      fontWeight={700}
                      fontSize="18px"
                      color={Colors.NightBlue}
                      textAlign="left"
                    >
                      {item.title}
                    </Box>
                    <Box
                      fontFamily="Open Sans"
                      fontSize="14px"
                      color={Colors.NightBlue}
                      textAlign="left"
                    >
                      {item.description}
                    </Box>
                  </Box>
                  {!isMobile && (
                    <Box alignSelf="center" ml="22px" mr="22px">
                      <Button
                        title={item.selected ? "Selected" : "Select"}
                        size={ButtonSize.small}
                        type={
                          item.selected ? ButtonType.primary : ButtonType.white
                        }
                        width="114px"
                        onClick={() => onSelectedItem(item)}
                      />
                    </Box>
                  )}
                </Box>

                <img
                  src={pattern}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: contentHeight,
                    maxWidth: "40%",
                  }}
                />
              </ButtonBase>
            );
          })}
        </Box>

        <ModalFooter>
          <Button
            title="Cancel"
            type={ButtonType.outlined}
            size={ButtonSize.small}
            onClick={onClose}
            width="130px"
          />
        </ModalFooter>
      </Box>
    </Dialog>
  );
}
