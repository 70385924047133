import React from "react";
import { Box, BoxProps, Link } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props extends BoxProps {
  title: string;
  onClick?: () => unknown;
  fontSize?: string;
  fontWeight?: number;
  destructive?: boolean;
  color?: string;
}

export default function TextLink({
  title,
  onClick,
  fontSize,
  fontWeight = 500,
  destructive = false,
  color = Colors.TurquoiseBlue,
  ...props
}: Props): JSX.Element {
  return (
    <Box
      fontFamily="Museo"
      fontWeight={fontWeight}
      fontSize={fontSize}
      style={{ cursor: "pointer" }}
      {...props}
    >
      <Link style={{ color: destructive ? Colors.Tomato : color }} onClick={onClick}>
        {title}
      </Link>
    </Box>
  );
}
