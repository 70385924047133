import { useMutation } from "@tanstack/react-query";
import { getValue } from "../utils/object";
import * as httpClient from "../api/client";
import { useUserStore } from "../stores/user";
import { useAlertStore } from "../stores/alert";

export interface LockAttributePayload {
  fieldName: string;
  value?: string;
}

const useProfileLock = () => {
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { fetchMe } = useUserStore();

  const { isLoading, mutate } = useMutation(
    (body: LockAttributePayload) => httpClient.post("api/v2/users/lock-attribute", body),
    {
      onSuccess: (res) => {
        setSuccessMessage(getValue(res, "message", "Success"));
        fetchMe();
      },
      onError: () => {
        setErrorMessage("Something went wrong");
      },
    }
  );

  return { isLoading, toggleLock: mutate };
};

export { useProfileLock };
