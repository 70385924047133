import React from "react";
import { Checkbox } from "@material-ui/core";
import UnCheckedIcon from "../../images/unchecked-icon.svg";
import CheckedIcon from "../../images/checked-icon.svg";
import DisabledCheckBoxIcon from "../../images/disabled-checkbox-icon.svg";

interface Props {
  isChecked: boolean;
  isDisabled?: boolean;
  onCheckBoxChange: (val: boolean) => void;
  
}

const CustomBillingCheckBox: React.FC<Props> = ({
  isChecked,
  onCheckBoxChange,
  isDisabled = false,
}) => {
  return (
    <Checkbox
      icon={<img src={isDisabled ? DisabledCheckBoxIcon : UnCheckedIcon} alt="checkbox" />}
      checkedIcon={<img src={CheckedIcon} alt="checkbox" />}
      disabled={isDisabled}
      style={{
        margin: 0,
        padding: 0,
      }}
      checked={isChecked}
      onChange={(event: any) => {
        onCheckBoxChange(event.target.checked);
      }}
    />
  );
};

export default CustomBillingCheckBox;
