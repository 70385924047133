import { Box, Typography } from "@material-ui/core";
import React from "react";
import { AlignItems, Display, FlexDirection, FontSize, FontWeight, JustifyContent, LineHeight, Spacing } from "../Styled/enum";
import { Colors } from "../../../constants/colors";
import { Grid } from "@mui/material";

interface optionType {
title: string;
value: any
}

interface Props {
  selectionOptions: optionType[];
  selectedOption: any;
  maxSelectionOptionPerRow?:number;
  title?: string;
  onSortOptionSelected : (selectedValue: any)=> unknown;
}
export const Selection = ({selectionOptions, selectedOption, maxSelectionOptionPerRow = 3,title,onSortOptionSelected}: Props)=>{
  
  const xsValue = Math.floor(12 / maxSelectionOptionPerRow || 3);

  return (
    <Box>
      {title?
        <Typography 
          style={{
            lineHeight: LineHeight.L24,
            width: "100%",
            color: Colors.Dusk,
            fontWeight: FontWeight.Bolder,
            fontSize: FontSize.F16,
            marginBottom: Spacing.S3
          }}>{title}
        </Typography>
        :
        <Box/>
      }
      <Grid container 
        style ={{ 
          display: Display.Flex,
          flexDirection: FlexDirection.Row,
          justifyContent: JustifyContent.spaceAround,
          borderRadius: Spacing.S2,
          overflow: "hidden",
          border: `1px solid ${Colors.LightPeriwinkle}`,
          cursor: "pointer"
        }}
      >
        {selectionOptions.map(({ value, title }: optionType, index: number) => {
          const isOptionSelected =  selectedOption === value;
          return (
            <>
              <Grid
                item
                xs={xsValue}
                style={{
                  height: "56px",
                  width: "130px",
                  backgroundColor: isOptionSelected ? Colors.TurquoiseBlue : undefined,
                  borderLeft: index > 0 ? `1px solid ${Colors.LightPeriwinkle}` : undefined,
                  display:Display.Flex,
                  alignItems: AlignItems.center,
                  justifyContent: JustifyContent.center,
                  color: isOptionSelected? Colors.White: Colors.Grey,
                  fontSize: FontSize.F16,
                  lineHeight: LineHeight.L24,
                  fontWeight: FontWeight.Bold
                }}
                key={value}
                onClick = {() => {onSortOptionSelected(value);}}
              >
                <Typography>
                  {title}
                </Typography>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Box>


  );
};