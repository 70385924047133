import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";

import ReferralItem from "./ReferralItem";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import PaginationControl from "../../PaginationControl";
import TableSeparator from "../../Reports/TableSeparator";
import { useReferrals } from "../../../hooks/referral/referral.hooks";
import { getValue } from "../../../utils/object";

const ReferralList = () => {

  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const isMobile = useMobile();

  const { isFetching: isLoading, data } = useReferrals({ page: currentPage });
  const referrals = getValue(data, "referrals") || [];

  useEffect(() => {
    if (!isLoading && data) {
      const { totalPage } = data;
      setTotalPage(totalPage);
    }
  }, [isLoading]);

  return <Box>
    <Box borderRadius="8px" border="1px solid #C5CBD6" bgcolor="white" marginY={"24px"}>
      <>
        {!isMobile ? <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            fontFamily="SF UI Text"
            fontSize="14px"
            lineHeight="24px"
            fontWeight={500}
            color={Colors.Grey}
            height="55px"
            pl="24px"
            pr="24px"
          >
            <Box width="40%">Provider</Box>
            <Box width="25%">Joined date</Box>
            <Box width="20%">Bookings</Box>
            <Box width="15%">Referral bonus</Box>
            <Box></Box>
          </Box>
          <TableSeparator />
        </Box>: <></>}

        {referrals && referrals.map((referral: any, index: number) => {
          const { referred, joinedAt, amount, bookingCompleted, bookingCount } = referral;
          return <Box>
            <ReferralItem
              isMobile={isMobile}
              amount={amount}
              referred={referred}
              joinedAt={joinedAt}
              bookingCount ={bookingCount}
              bookingCompleted={bookingCompleted}
            />
            {index < referrals.length - 1 ? <TableSeparator /> : <Box />}
          </Box>;
        })}
      </>
    </Box>
    {(
      <Box display="flex" justifyContent="flex-start" width={"100%"} mb="55px">
        <PaginationControl
          currentPage={currentPage - 1}
          pages={totalPage}
          onPrevious={() => {
            if (currentPage > 1) {
              setCurrentPage(currentPage - 1);
            }
          }}
          onNext={() => {
            if (currentPage < totalPage) {
              setCurrentPage(currentPage + 1);
            }
          }}
        />
      </Box>
    )}
  </Box>;
};

export default ReferralList;
