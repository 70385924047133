import { Box } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from "react-router-dom";
import EmailVerificationModal from "../components/Account/EmailVerificationModal";
import Button, { ButtonSize, ButtonType } from "../components/Button";
import Contraindications from "../components/Contraindications";
import CovidVaccinationInfo, { MessageType } from "../components/CovidVaccinationInfo";
import Dropdown from "../components/Dropdown";
import Gender from "../components/Gender";
import MobileInput from "../components/MobileModalOtp/MobileInput";
import Pronoun from "../components/Pronoun";
import SectionPageHeader from "../components/SectionPageHeader";
import InfoText from "../components/Text/InfoText/infoText";
import TextButton from "../components/TextButton/TextButton";
import TextField from "../components/TextField";
import DeleteConfirmModal from "../components/Users/DeleteAccount/DeleteConfirmModal/DeleteConfirmModal";
import DeleteReasonModal from "../components/Users/DeleteAccount/DeleteReasonModal/DeleteReasonModal";
import DeleteSuccessModal from "../components/Users/DeleteAccount/DeleteSuccessModal/DeleteSuccessModal";
import TermsAndPolicies from "../components/Users/TermsAndPolicies/TermsAndPolicies";
import Wrapper from "../components/Wrapper";
import config from "../config/user/profileConfig.json";
import { BASE_UPLOADS_URL, SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST } from "../constants/common";
import { COUNTRY } from "../constants/countries";
import { PASSWORD_DESCRIPTION } from "../constants/message";
import { Paths } from "../constants/paths";
import { NOTE_FOR_THERAPIST_PLACHOLDER } from "../constants/placeholder";
import {
  AllUserPreferences,
  labelForUserPreference,
  optionsForUserPreference,
} from "../constants/preferences";
import { LOCKED_ATTRIBUTES } from "../constants/profile";
import { TrackingEvents } from "../constants/tracking";
import { parseApiError } from "../helpers/error";
import { getCountryCode } from "../helpers/mobile";
import { capitalizeFirstLetter } from "../helpers/string";
import { withVersion } from "../helpers/tracking";
import { useAccessToken } from "../hooks/common";
import { useMobile } from "../hooks/mobile";
import { useUserServices } from "../hooks/services/userServices.hooks";
import ProfilePicturePlaceholder from "../images/profile-pic-placeholder.png";
import ProfilePictureEdit from "../images/profile-picture-edit.svg";
import { trackEvent } from "../services/segment/track.service";
import { updateUserPreferences } from "../services/users/users.service";
import { useAlertStore } from "../stores/alert";
import { checkServiceIsMassage, getServicesRates } from "../stores/booking";
import { useUserStore } from "../stores/user";
import { getValue } from "../utils/object";
import { Business } from "../components/Business/Business";
import PersonalInfoDisclaimerModal from "../components/DisclaimerModal/PersonalInfoDisclaimerModal";
import { hasPersonalInformation } from "../services/utility/personalIdentifiableInfo.service";

const profileImageStyle = {
  width: "80px",
  height: "80px",
  borderRadius: "40px",
  "object-fit": "cover",
};

export default function Profile(): JSX.Element {
  const section = new Set(["Personal", "Business", "Preferences"]);

  const accessToken = useAccessToken();
  const isMobile = useMobile();
  const location = useLocation();
  const history = useHistory();

  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const [userCountry, setUserCountry] = useState<string>("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [instructions, setInstructions] = useState("");
  const [hasContraindications, setHasContraindications] = useState<null | boolean>(null);
  const [contraindications, setContraindications] = useState<null | string>(null);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCovidVaccinated, setIsCovidVaccinated] = useState(false);
  const [isHomeCareProvider, setIsHomeCareProvider] = useState(false);
  const [personalInfoDisclaimerOpen, setPersonalDisclaimerOpen] = useState<boolean>(false);

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);

  const { user, fetchMe, userPreferences, fetchUserPreferences } = useUserStore();

  const [saving, setSaving] = useState(false);

  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

  const [gender, setGender] = useState("");
  const [pronoun, setPronoun] = useState("");

  const [deleteReason, setDeleteReason] = useState("");
  const [showDeleteSuccess, setDeleteSuccessVisible] = useState(false);

  const DELETE_STEPS = {
    REASON: "REASON",
    CONFIRM: "CONFIRM",
  };

  const [deleteStep, setDeleteStep] = useState<null | string>(null);

  const getSelectedSectionIndex = () => {
    let selectedIndex = 0;
    switch (location.pathname) {
    case Paths.Business:
      selectedIndex = 1;
      break;
    case Paths.Preferences:
      selectedIndex = 2;
      break;
    case Paths.TermsAndPolicies:
      selectedIndex = 3;
      break;
    default:
      selectedIndex = 0;
    }
    return selectedIndex;
  };

  const initialIndex = getSelectedSectionIndex();
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState<number>(initialIndex);

  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    // Do something with the files

    if (!isEmpty(acceptedFiles)) {
      setSelectedImageFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
  });

  useEffect(() => {
    if (window) window.scrollTo(0, 0);

    fetchMe();
    getServicesRates();
    setSelectedSectionIndex(getSelectedSectionIndex());

    if (!userPreferences) {
      fetchUserPreferences();
    } else {
      fetchPreferencesFromUserPreferences();
    }
  }, []);

  useEffect(() => {
    setSelectedSectionIndex(getSelectedSectionIndex());
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      const mobileCountryCodeData = getCountryCode(user.mobile);

      const firstName = getValue(user, "legalFirstName") || getValue(user, "firstName");
      setFirstName(firstName);
      setLastName(user.lastName);
      setCountryCode(mobileCountryCodeData?.countryCode || "");
      setMobile(mobileCountryCodeData?.withoutCountryCode || "");
      setEmail(user.email);
      setIsCovidVaccinated(user.isCovidVaccinated || false);
      setGender(user.gender);
      setPronoun(user.pronoun);
      setIsHomeCareProvider(user.isHomeCareProvider);
      setUserCountry(user.country);
    }
  }, [user]);

  const formatPreferences = (servicePreference: any) => {
    const _preferences: { [preference: string]: string } = {};

    if (servicePreference) {
      const parsedPreferences = JSON.parse(servicePreference.preference);

      Object.keys(parsedPreferences).forEach((preference) => {
        _preferences[preference] = parsedPreferences[preference];
      });
    }

    setPreferences(_preferences);
  };

  const { services: rates } = useUserServices(userCountry || COUNTRY.AU);

  const _serviceTypeOptions = () => {
    const services = rates
      .filter(({ country }) => country === (userCountry || COUNTRY.AU))
      .map((rate) => {
        return {
          value: rate.id,
          title: capitalizeFirstLetter(rate.alias || rate.name),
          profession: rate.profession || "therapist",
        };
      });
    return [...services];
  };

  const serviceTypeOptions = _serviceTypeOptions();

  const [selectedServiceType, setSelectedServiceType] = useState<number>(0);

  // default select first service Type Option
  useEffect(() => {
    if (!selectedServiceType && serviceTypeOptions.length > 0) {
      const initialServiceId = getValue(location, "state.serviceId");
      setSelectedServiceType(initialServiceId || serviceTypeOptions[0].value);
    }
  }, [serviceTypeOptions]);

  const [preferences, setPreferences] = React.useState<{
    [preference: string]: string;
  }>({});

  useEffect(() => {
    fetchPreferencesFromUserPreferences();
  }, [userPreferences, selectedServiceType]);

  const fetchPreferencesFromUserPreferences = () => {
    if (!userPreferences) return;
    const targetServicePref = userPreferences.find(
      (pref: any) => pref.serviceId === selectedServiceType
    );
    if (targetServicePref) {
      const preference = JSON.parse(targetServicePref.preference);
      formatPreferences(targetServicePref);
      setInstructions(preference["specialInstructions"]);
      setContraindications(preference["contraindications"]);
      setHasContraindications(preference["hasContraindications"]);
    } else {
      setPreferences({});
      setInstructions("");
      setContraindications(null);
      setHasContraindications(null);
    }
  };

  const verifyFields = () => {
    if (isEmpty(firstName)) {
      setErrorMessage("Please enter your first name");
      return false;
    }

    if (isEmpty(lastName)) {
      setErrorMessage("Please enter your last name");
      return false;
    }

    if (isEmpty(countryCode)) {
      setErrorMessage("Please enter your country code");
      return false;
    }
    if (isEmpty(mobile)) {
      setErrorMessage("Please enter your mobile");
      return false;
    }
    if (isEmpty(email)) {
      setErrorMessage("Please enter your email");
      return false;
    }

    if (isEmpty(gender)) {
      setErrorMessage("Please select your gender");
      return false;
    }

    if (!isEmpty(currentPassword) || !isEmpty(newPassword)) {
      if (newPassword !== confirmPassword) {
        setErrorMessage("Please make sure your new passwords match");
        return false;
      }
    }

    return true;
  };

  const updatePassword =
    !isEmpty(currentPassword) && !isEmpty(newPassword) && !isEmpty(confirmPassword);

  const onProfileUpdated = () => {
    setSaving(false);
    fetchMe();

    setSuccessMessage("Profile updated");

    trackEvent(TrackingEvents.ProfileSaved, withVersion());

    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const getProfession = (value: number) => {
    const targetService = serviceTypeOptions.find((option) => option.value === value);
    let profession = "therapist";
    if (targetService) {
      profession = targetService.profession;
    }
    return profession;
  };

  const isMassageService = checkServiceIsMassage(selectedServiceType, rates);

  const handlePreferenceUpdate = async (payload: any) => {
    updateUserPreferences(payload, accessToken)
      .then(async (response) => {
        setSaving(false);
        fetchUserPreferences();
        setSuccessMessage("Your preferences have been updated from profile");
      })
      .catch((err) => {
        setSaving(false);
        setErrorMessage(parseApiError(err));
      });
  };

  const handlePreferenceSave = () => {
    if (!selectedServiceType) return;
    setSaving(true);
    const userPrefs = {
      ...preferences,
      specialInstructions: instructions,
      hasContraindications: hasContraindications || false,
      contraindications,
    };
    const payload = { preferences: userPrefs, serviceId: selectedServiceType };
    handlePreferenceUpdate(payload);
  };

  const handleAccountDelete = () => {
    setDeleteStep(DELETE_STEPS.REASON);
  };

  const handleReasonProvided = ({ reason }: { reason: string }) => {
    setDeleteReason(reason);
    setDeleteStep(DELETE_STEPS.CONFIRM);
  };

  const cancelDeleteFlow = () => {
    setDeleteReason("");
    setDeleteStep(null);
  };

  const handleDeleteSuccess = () => {
    fetchMe();
    cancelDeleteFlow();
    setTimeout(() => setDeleteSuccessVisible(true), 200);
  };

  const dismissDeleteSuccess = () => {
    setDeleteSuccessVisible(false);
  };

  const isDeletionRequested = () => {
    return user?.status;
  };

  const getProfileConfig = (config: any, country = "AU") => {
    // defaults to AU if country code is not found
    const getTerm = config[country] || config["AU"];
    const { termsPolicies } = getTerm;
    if (termsPolicies?.showTermsPolicy && isHomeCareProvider) {
      section.add("Terms and Policies");
    }
    return termsPolicies;
  };

  const termsPolicies = getProfileConfig(config, user?.country);

  const isProfileLocked = user?.lockedAttributes.some(
    (data) => data.fieldName === LOCKED_ATTRIBUTES.NAME
  );

  const closePersonalInfoDisclaimerModal = () => {
    setTimeout(() => {
      if (hasPersonalInformation(firstName)) {
        firstNameRef.current?.focus();
      } else if (hasPersonalInformation(lastName)) {
        lastNameRef.current?.focus();
      }
    }, 100);

    setPersonalDisclaimerOpen(false);
  };

  const getSectionTitle = (index: number) => {
    switch (index) {
    case 0:
      return "Your details";
    case 1:
      return "Your business";
    case 2:
      return "Your preferences";
    case 3:
    default:
      return "Your terms and policies";
    }
  };

  const handleMobileVerified = (
    formattedMobileNumber: string,
    numberOnly?: string,
    countryCode?: string
  ) => {
    setMobile(numberOnly || "");
    setCountryCode(countryCode || "");
    fetchMe();
  };

  const handleEmailVerified = (email: string) => {
    setEmail(email);
    fetchMe();
  };

  const getMobileNumber = () => {
    return `${countryCode} ${mobile}`;
  };

  const formattedMobile = getMobileNumber();

  const handleSave = async () => {
    if (!verifyFields()) {
      return;
    }
    if (hasPersonalInformation(firstName) || hasPersonalInformation(lastName)) {
      setPersonalDisclaimerOpen(true);
      return;
    }

    setSaving(true);

    let updatePasswordSuccess = true;

    if (updatePassword) {
      try {
        await axios.put("/users/me/newPassword", {
          accessToken,
          email: user?.email,
          password: currentPassword,
          newPassword,
        });
      } catch (error: any) {
        setSaving(false);

        updatePasswordSuccess = false;

        if (error) {
          setErrorMessage(parseApiError(error));
        }
      }
    }

    updatePasswordSuccess &&
      axios
        .put("/users/me", {
          accessToken,
          firstName,
          lastName,
          mobile: `${countryCode}${mobile}`,
          email,
          specialInstructions: instructions,
          gender: gender,
          pronoun: pronoun,
          isCovidVaccinated,
        })
        .then((response) => {
          if (!isNil(selectedImageFile)) {
            console.debug("selectedImageFile: ", selectedImageFile);

            const form = new FormData();
            form.append("image", selectedImageFile);

            axios
              .put(`/users/me/profileImage?accessToken=${accessToken}`, form, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                onProfileUpdated();
              })
              .catch((error) => {
                setSaving(false);
                setErrorMessage(parseApiError(error));
              });
          } else {
            onProfileUpdated();
          }
        })
        .catch((error) => {
          setSaving(false);

          setErrorMessage(parseApiError(error));
        });
  };

  return (
    <>
      <Wrapper
        header={
          <SectionPageHeader
            title={getSectionTitle(selectedSectionIndex)}
            sections={[...section]}
            selectedSectionIndex={selectedSectionIndex}
            onSelectedSectionIndex={(index) => {
              if (index === 1) {
                history.push(Paths.Business);
              } else if (index === 2) {
                history.push(Paths.Preferences);
              } else if (index === 3) {
                history.push(Paths.TermsAndPolicies);
              } else {
                history.push(Paths.Profile);
              }
            }}
          />
        }
      >
        {selectedSectionIndex === 0 && (
          <Box
            marginTop="43px"
            paddingLeft={isMobile ? "16px" : "80px"}
            paddingRight={isMobile ? "16px" : "80px"}
            paddingBottom="88px"
            maxWidth="630px"
          >
            <Box display="flex" flexDirection={isMobile ? "column" : "row"} alignItems="center">
              <div {...getRootProps()} style={{ cursor: "pointer", position: "relative" }}>
                <input {...getInputProps()} />

                {selectedImageFile && (
                  <img
                    alt="Selected profile pic"
                    src={URL.createObjectURL(selectedImageFile)}
                    style={profileImageStyle}
                  />
                )}

                {!selectedImageFile && user?.profileImage && (
                  <img
                    alt="Profile pic"
                    src={`${BASE_UPLOADS_URL}/${user.profileImage}`}
                    style={profileImageStyle}
                  />
                )}

                {!selectedImageFile && isNil(user?.profileImage) && (
                  <img
                    alt="Profile pic placeholder"
                    src={ProfilePicturePlaceholder}
                    style={profileImageStyle}
                  />
                )}

                <img
                  src={ProfilePictureEdit}
                  alt="Pic"
                  style={{ position: "absolute", top: -20, left: -20 }}
                />
              </div>
              <Box width="28px" />
            </Box>

            <Box
              justifyContent="space-between"
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              marginTop="24px"
            >
              <TextField
                title="First"
                placeholder="Name"
                value={firstName}
                onChange={(text) => setFirstName(text)}
                isLocked={isProfileLocked}
                inputRef={firstNameRef}
              />
              <Box width="60px" />
              <TextField
                title="Last"
                placeholder="Name"
                value={lastName}
                onChange={(text) => setLastName(text)}
                isLocked={isProfileLocked}
                inputRef={lastNameRef}
              />
            </Box>

            <Box
              justifyContent="space-between"
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
            >
              {/* <MobileTextField
                countryCode={countryCode}
                mobile={mobile}
                onCountryCodeChange={(code) => setCountryCode(code)}
                onMobileChange={(mobile) => {console.log(mobile);setMobile(mobile)}}
              /> */}

              <MobileInput
                phoneNumber={formattedMobile}
                onVerified={handleMobileVerified}
                isVerified={!!user?.mobileVerifiedAt}
              />

              <Box width="60px" />

              <EmailVerificationModal
                email={email}
                onVerified={handleEmailVerified}
                isVerified={!!user?.emailVerifiedAt}
              />
            </Box>
            <Box>
              <Gender onChange={(text: any) => setGender(text)} value={gender} />
            </Box>
            <Box mb={3}>
              <Pronoun onChange={(text: any) => setPronoun(text)} value={pronoun} />
            </Box>

            <CovidVaccinationInfo
              type={MessageType.client}
              isCovidVaccinated={isCovidVaccinated}
              onCheckBoxChange={(status) => {
                setIsCovidVaccinated(status);
              }}
            />

            <Box mb={6}>
              <TextField
                title="Current password"
                subtitle={PASSWORD_DESCRIPTION}
                placeholder="Current password"
                value={currentPassword}
                isPassword={true}
                onChange={(text) => setCurrentPassword(text)}
                type="password"
              />
              <TextField
                title="New password "
                subtitle={PASSWORD_DESCRIPTION}
                placeholder="New password"
                value={newPassword}
                onChange={(text) => setNewPassword(text)}
                type="password"
                isPassword={true}
              />
              <TextField
                title="Confirm password"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(text) => setConfirmPassword(text)}
                type="password"
                isPassword={true}
              />
              <TextButton
                text="Delete account"
                onClick={handleAccountDelete}
                type={isDeletionRequested() ? "disabled" : "danger"}
              />
              {isDeletionRequested() ? (
                <InfoText
                  text="Your account deletion is in process and will be deleted in 48 hours."
                  textStyle={{ fontFamily: "Open Sans" }}
                />
              ) : (
                <Box />
              )}
            </Box>

            <Button
              title="Save"
              type={ButtonType.primary}
              width="128px"
              onClick={handleSave}
              size={ButtonSize.small}
              loading={saving}
            />
          </Box>
        )}
        {selectedSectionIndex === 1 && (
          <Box
            marginTop="43px"
            paddingLeft={isMobile ? "16px" : "88px"}
            paddingRight={isMobile ? "16px" : undefined}
            paddingBottom="88px"
            maxWidth="630px"
          >
            <Business />
          </Box>
        )}

        {selectedSectionIndex === 2 && (
          <Box
            marginTop="43px"
            paddingLeft={isMobile ? "16px" : "88px"}
            paddingRight={isMobile ? "16px" : undefined}
            paddingBottom="88px"
            maxWidth="630px"
          >
            <Dropdown
              title="Service type"
              options={serviceTypeOptions}
              selectedOption={serviceTypeOptions.find(
                (option) => option.value === selectedServiceType
              )}
              onSelectedOption={(option) => setSelectedServiceType(option.value)}
            />

            {isMassageService &&
              AllUserPreferences.map((userPreference) => {
                const options = optionsForUserPreference(userPreference).map((option) => {
                  return {
                    value: option,
                    title: option,
                  };
                });

                return (
                  <Dropdown
                    title={labelForUserPreference(userPreference)}
                    options={options}
                    onSelectedOption={(option) => {
                      const updatedPreferences = { ...preferences };
                      updatedPreferences[userPreference] = option.value;
                      setPreferences(updatedPreferences);
                    }}
                    selectedOption={options.find(
                      (option) => option.value === preferences[userPreference]
                    )}
                  />
                );
              })}

            <Contraindications
              hasContraindications={hasContraindications}
              setHasContraindications={setHasContraindications}
              contraindications={contraindications}
              setContraindications={setContraindications}
              gender={gender}
            />

            <TextField
              title={`Note for your ${getProfession(selectedServiceType)}`}
              subtitle="Your note is visible to providers."
              placeholder={NOTE_FOR_THERAPIST_PLACHOLDER}
              value={instructions}
              onChange={(text) => setInstructions(text)}
              multiline
              maxLength={SPECIAL_INSTRUCTIONS_LIMIT_FOR_THERPAIST}
            />

            <Box height="25px" />

            <Button
              title="Save"
              type={ButtonType.primary}
              width="128px"
              onClick={() => handlePreferenceSave()}
              size={ButtonSize.small}
              loading={saving}
            />
          </Box>
        )}

        {selectedSectionIndex === 3 && termsPolicies?.showTermsPolicy && isHomeCareProvider && (
          <Box
            marginTop="43px"
            paddingLeft={isMobile ? "16px" : "88px"}
            paddingRight={isMobile ? "16px" : undefined}
            paddingBottom="88px"
            maxWidth="630px"
          >
            <TermsAndPolicies accessToken={accessToken} termsPolicies={termsPolicies} />
          </Box>
        )}
        <PersonalInfoDisclaimerModal
          open={personalInfoDisclaimerOpen}
          onClose={closePersonalInfoDisclaimerModal}
        />
        <DeleteReasonModal
          reason={deleteReason}
          visible={deleteStep === DELETE_STEPS.REASON}
          onClose={cancelDeleteFlow}
          onContinue={handleReasonProvided}
        />
        <DeleteConfirmModal
          reason={deleteReason}
          onSuccess={handleDeleteSuccess}
          onClose={cancelDeleteFlow}
          visible={deleteStep === DELETE_STEPS.CONFIRM}
        />
        <DeleteSuccessModal
          visible={showDeleteSuccess}
          onClose={dismissDeleteSuccess}
          onContinue={dismissDeleteSuccess}
        />
      </Wrapper>
    </>
  );
}
