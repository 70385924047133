import { Box } from "@material-ui/core";
import React from "react";
import ComingSoonMessage from "../../components/ComingSoonMessage";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import Wrapper from "../../components/Wrapper";
import { useMobile } from "../../hooks/mobile";
import ContentSectionControl from "../../components/ContentSectionControl";
import Calendar from "./Availability/Calendar/index";
import Schedule from "./Availability/Schedule/index";

const sections = ["Calendar", "Schedule"];

function Availability() {
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);

  const isMobile = useMobile();

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Availability" />

        <Box
          mt="40px"
          ml={isMobile ? "16px" : "80px"}
          mr={isMobile ? "16px" : "80px"}
        >
          <ContentSectionControl
            sections={sections}
            selectedIndex={selectedSectionIndex}
            onSelectedIndex={(index) => setSelectedSectionIndex(index)}
          />

          {selectedSectionIndex === 0 && <Calendar />}

          {selectedSectionIndex === 1 && <Schedule />}
        </Box>
      </Wrapper>
    </>
  );
}

export default Availability;
