import { Box, CircularProgress } from "@material-ui/core";

import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
  TextAlign
} from "../../../../../../components/v2/Styled/enum";
import { Colors } from "../../../../../../constants/colors";
import TreatmentNoteListCard from "../../../../ClientDetails/TreatmentNotes/TreatmentNoteListCard";
import PaginationControl from "../../../../../../components/PaginationControl";
import { useUserTreatmentNotes } from "../../../../../../hooks/user/userTreatmentNote.hooks";
import { useState } from "react";
import { checkIfEmpty, getValue } from "../../../../../../utils/object";
import Button, { ButtonSize, ButtonType } from "../../../../../../components/Button";
import { useUserStore } from "../../../../../../stores/user";

import PlusIcon from "../../../../../../images/plus-white.svg";
import HeroImage from "../../../../../../images/no-bookings-placeholder.svg";
import TextButton from "../../../../../../components/TextButton/TextButton";

interface Props {
  clientId: string | number;
  jobId: string | number;
  timezone: string;
  refreshJob: () => unknown;
  onAddNote: () => unknown;
  onEditNote: (note: any) => unknown;
  onDeleteNote: (note: any) => unknown;
  showOtherNotes: () => unknown;
};

const ProviderNotes = ({
  timezone,
  clientId,
  jobId,
  refreshJob,
  onAddNote,
  onEditNote,
  onDeleteNote,
  showOtherNotes,
}: Props) => {
  const { user } = useUserStore();
  const [userNotePage, setUserNotePage] = useState(1);
  
  const {
    data: userNoteResponse,
    isLoading: loadingNotes,
  } = useUserTreatmentNotes(clientId, jobId, userNotePage);

  const getNotesFromMe = () => {
    return getValue(userNoteResponse, "notes");
  };

  const changeUserNotePage = (increment: 1 | -1) => {
    setUserNotePage(userNotePage + increment);
  };

  const notesFromMe = getNotesFromMe();

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4}>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S2}>
        <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S3} >
          <Box fontFamily={FontFamily.Museo} fontSize={FontSize.F16} color={Colors.NightBlue} fontWeight={FontWeight.Bold}>Notes you have added</Box>
          <Box fontFamily={FontFamily.OpenSans} fontSize={FontSize.F14} color={Colors.DarkSteelGrey} >These notes are visible to the client and any other provider that the client may book in the future.</Box>
        </Box>
        <Box display={Display.Flex} flexDirection={FlexDirection.Row} justifyContent={JustifyContent.end}>
          <Button
            width={"auto"}
            icon={PlusIcon}
            size={ButtonSize.small}
            title="Add note"
            onClick={onAddNote}
            type={ButtonType.secondary}
            iconStyle={{ marginRight: "8px", width: `${Spacing.S4}`, height: `${Spacing.S4}` }}
            style={{
              paddingTop: Spacing.S2,
              paddingLeft: Spacing.S4,
              paddingRight: Spacing.S4,
              paddingBottom: Spacing.S2,
              borderRadius: "100px",
            }}
            fontStyle={{ fontSize: FontSize.F14, fontFamily: FontFamily.Museo }}
          />
        </Box>
      </Box>
      <Box>
        {!checkIfEmpty(notesFromMe) && (
          <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4}>
            {(notesFromMe || []).map((note: any) => {
              const { id: noteId, updatedAt } = note;
              return <TreatmentNoteListCard
                pro={user}
                note={note}
                timezone={timezone}
                onEditNote={onEditNote}
                key={`treatmentNote-${noteId}-${updatedAt}`}
                onDeleteNote={() => onDeleteNote(note)}
              />;
            })}
          </Box>
        )}
        {(loadingNotes || checkIfEmpty(notesFromMe)) && (
          <Box
            minHeight={"300px"}
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
          >
            {loadingNotes && <CircularProgress />}
            {!loadingNotes && checkIfEmpty(notesFromMe) && (
              <Box
                maxWidth={"400px"}
                gridGap={Spacing.S1}
                display={Display.Flex}
                textAlign={TextAlign.center}
                alignItems={AlignItems.center}
                flexDirection={FlexDirection.Column}
              >
                <img src={HeroImage} alt="No notes found" width={"78px"} height={"55px"} />
                <Box display={Display.Flex} flexDirection={FlexDirection.Column} alignItems={AlignItems.center} gridGap={Spacing.S1}>
                  <Box
                    fontSize={FontSize.F16}
                    fontWeight={FontWeight.Bold}
                    color={Colors.DarkSteelGrey}
                    fontFamily={FontFamily.Museo}
                  >
                    No notes added
                  </Box>
                  <Box
                    fontSize={FontSize.F14}
                    fontWeight={FontWeight.Regular}
                    fontFamily={FontFamily.OpenSans}
                  >
                    <TextButton
                      type="primary"
                      text="Add a note"
                      onClick={onAddNote}
                      textStyle={{
                        fontFamily: FontFamily.OpenSans,
                        fontWeight: FontWeight.Regular,
                      }}
                    />, or {" "}
                    <TextButton
                      type="primary"
                      text="view notes from other providers"
                      onClick={showOtherNotes}
                      textStyle={{
                        fontFamily: FontFamily.OpenSans,
                        fontWeight: FontWeight.Regular,
                      }}
                    />.
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        justifyContent={JustifyContent.end}
      >
        <PaginationControl
          currentPage={userNotePage}
          pages={getValue(userNoteResponse, "totalPage") || 1}
          onPrevious={() => changeUserNotePage(-1)}
          onNext={() => changeUserNotePage(1)}
          startFromOne={true}
        />
      </Box>
    </Box>
  );
};

export default ProviderNotes;
