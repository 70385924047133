import { Box, ButtonBase } from "@material-ui/core";
import React, { useCallback } from "react";
import { Colors } from "../constants/colors";
import "../styles/scrollbar-hidden.css";

interface TabProps {
  onClick: () => unknown;
  isActive: boolean;
  title: string;
}

const Tab = React.memo(({ onClick, isActive, title }: TabProps) => {
  const titleStyle = useCallback(() => {
    return {
      fontFamily: "Museo",
      fontWeight: isActive ? 600 : 400,
      fontSize: "14px",
      color: isActive ? Colors.NightBlue : Colors.SlateGrey,
    };
  }, [isActive]);

  return (
    <ButtonBase onClick={onClick} style={{ flex: 1 }}>
      <Box minWidth="100px" width="100%">
        <Box
          style={titleStyle()}
          height="41px"
          marginBottom="4px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          pl={1}
          pr={1}
          // whiteSpace="pre-wrap"
        >
          {title}
        </Box>
        {isActive && <Box width="100%" height="2px" bgcolor={Colors.TurquoiseBlue} />}
      </Box>
    </ButtonBase>
  );
});

interface Props {
  sections: Array<string>;
  selectedIndex: number;
  onSelectedIndex: (index: number) => unknown;
}

function ContentSectionControl({ sections, selectedIndex, onSelectedIndex }: Props): JSX.Element {
  return (
    <>
      <Box
        className="scrollbar-hidden"
        display="flex"
        flexDirection="row"
        maxWidth="90vw"
        style={{ overflowX: "scroll" }}
      >
        {sections.map((section, index) => (
          <Tab
            key={`${section}-${index}`}
            title={section}
            isActive={selectedIndex === index}
            onClick={() => onSelectedIndex(index)}
          />
        ))}
      </Box>
      <Box width="100%" bgcolor={Colors.LightPeriwinkle} height="1px" />
    </>
  );
}

export default React.memo(ContentSectionControl);
