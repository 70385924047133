import React from "react";
import { Box } from "@material-ui/core";
import BlysStripe from "./BlysStripe";
import { Text } from "../../../../v2/Styled";
import * as Styled from "../../../../v2/Styled/enum";
import { Colors } from "../../../../../constants/colors";

interface Props {}

const StripeCoBranding = ({}: Props): JSX.Element => {
  return (
    <Box
      gridGap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      alignItems={Styled.AlignItems.center}
    >
      <BlysStripe />
      <Box>
        <Text color={Colors.Grey} size={Styled.FontSize.F12} font={Styled.FontFamily.SFPro}>
          Get Blys, Inc. partners with Stripe to help you receive payments and keep your personal
          bank and details secure.
        </Text>
      </Box>
    </Box>
  );
};

export default StripeCoBranding;
