import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { useState } from "react";
import BookingDetailRow from "../../../../components/BookingSummary/BookingDetailRow/BookingDetailRow";
import BookingNote from "../../../../components/BookingSummary/BookingDetailRow/BookingNote/BookingNote";
import TreatmentDetailItems from "../../../../components/ReviewAndBook/ReviewAndBookTreatmentDetail/TreatmentDetailItems";
import TextLink from "../../../../components/TextLink";
import TreatmentDescription from "../../../../components/Treatment/TreatmentDescription/TreatmentDescriptionV2";
import { Display, FlexDirection, Spacing } from "../../../../components/v2/Styled/enum";
import {
  BOOKING_DETAIL_FIELDS,
  BOOKING_STATUS,
  UpdateRequestStatus,
  UpdateRequestedBy,
} from "../../../../constants/booking";
import { PENDING_UPDATE_REQUEST_MESSAGE } from "../../../../constants/message";
import { Paths } from "../../../../constants/paths";
import { CORPORATE_BOOKING_KEYS } from "../../../../data/corporate.options";
import { bookingFrequencyCaption, frequencyTitleForBooking } from "../../../../helpers/booking";
import { isCorporateSession } from "../../../../helpers/massage";
import BookingFrequencyIcon from "../../../../images/booking-recurrency.png";
import DateIcon from "../../../../images/review-calendar-time.svg";
import ForIcon from "../../../../images/review-for-icon.png";
import AddressIcon from "../../../../images/review-map-icon.png";
import { ServiceRatePrice } from "../../../../models";
import { transformCorporateForDescription } from "../../../../stores/V2/booking/booking.transformer";
import { useAlertStore } from "../../../../stores/alert";
import {
  getAddOnDetailById,
  getFormattedQuestionAnswer,
  getServiceDurationFromDetails,
  getTreatmentDetailByTreatmentId,
  getTreatmentDetailsIcon,
  isCoupleMassageType,
  isMassageType,
  useBookingStore,
  useServicesStore,
} from "../../../../stores/booking";
import { formatDateWithTimezone, resetTimeFromDate } from "../../../../utils/date.util";
import { checkIfEmpty, getValue } from "../../../../utils/object";

interface Props {
  booking: any;
  id?: string;
  allowChange?: boolean;
  addPadding?: boolean;
  allowLocationUpdate?: boolean;
  allowedActionFields?: string[];
}

const TreatmentDetailsBlock = ({
  booking,
  id,
  allowChange = true,
  addPadding = true,
  allowLocationUpdate = false,
  allowedActionFields = [],
}: Props) => {
  const history = useHistory();
  const { setErrorMessage } = useAlertStore();

  const { questions } = useServicesStore();
  const { setUpdatingForBooking } = useBookingStore();

  const [showTreatmentInfoModel, setShowTreatmentInfoModel] = useState(false);
  const [treatmentForDetails, setTreatmentForDetails] = useState<ServiceRatePrice>();

  const [showPreferenceModal, setShowPreferenceModal] = useState(false);

  const sessionType = getValue(booking, "sessionType", null);
  const serviceId =
    getValue(booking, "selectedService.id") || getValue(booking, "service.id") || null;
  const bookingUpdates = getValue(booking, "bookingUpdates");
  const updateRequest = bookingUpdates?.find(
    (update: any) => update.status === UpdateRequestStatus.pending
  );
  const isPendingUpdate = updateRequest?.requestedBy === UpdateRequestedBy.pro;
  const isCorporateType = isCorporateSession(sessionType);
  const corporateInfo = getValue(booking, "corporateInfo", null);
  const isBookingComplete = getValue(booking, "status") === BOOKING_STATUS.COMPLETED;
  const isBookingCancelled = getValue(booking, "status") === BOOKING_STATUS.CANCELLED;
  const isHairAndMakeup = getValue(booking, "isHairAndMakeup") || false;

  const isCouplesMassage = isCoupleMassageType(sessionType);

  const bookingdetails = getValue(booking, "bookingDetails") || getValue(booking, "bookingdetails");

  const genderPreference1 = bookingdetails[0]?.genderPreference;
  const genderPreference2 = bookingdetails[1]?.genderPreference;

  const isMassage = isMassageType(serviceId);

  const [length1, length2] = getServiceDurationFromDetails(sessionType, bookingdetails);

  const getTreatmentDetails = () => {
    let treatments = bookingdetails.map((details: any) => {
      let { treatmentDetails } = details;
      if (!checkIfEmpty(treatmentDetails)) {
        const updatedDetails = treatmentDetails.map((detail: any) => ({
          ...detail,
          treatmentId: detail.id || detail.treatmentId,
        }));
        treatmentDetails = updatedDetails;
      }
      return treatmentDetails;
    });
    return treatments.flat();
  };

  const getRecipientName = () => {
    if (!booking) return "-";
    const { recipient, selectedRecipient, userId } = booking;
    const actualRecipient = selectedRecipient || recipient;
    const recipientName = actualRecipient
      ? `${actualRecipient.firstName} ${actualRecipient.lastName}`
      : "myself";
    return recipientName;
  };

  const getBookingDate = () => {
    const timeOfArrival = booking.timeOfArrival ? booking.timeOfArrival : booking.earliestTime;
    const timezone = getValue(booking, "address.timezone") || booking.timezone;
    return formatDateWithTimezone(timeOfArrival, timezone, "ddd, D MMM YYYY [at] h:mma");
  };

  const getBookingFrequency = () => {
    const { recurring } = booking;
    if (!recurring) return "";
    const { frequency, isSameProvider, ..._recurring } = recurring;
    const title = frequencyTitleForBooking({
      frequency,
      recurring: _recurring,
    });
    return title;
  };

  const getBookingFrequencyCaption = () => {
    const { recurring } = booking;
    if (!recurring) return "";
    const { frequency, ..._recurring } = recurring;

    const selectedDate = booking.timeOfArrival ? booking.timeOfArrival : booking.earliestTime;

    const caption = bookingFrequencyCaption({
      bookingDate: resetTimeFromDate(selectedDate),
      frequency,
      monthData: getValue(recurring, "monthly", null),
      timezone: getValue(booking, "address.timezone") || booking.timezone,
      type: recurring?.type,
    });
    return caption;
  };

  const handleRecipientChange = () => {
    return isPendingUpdate
      ? setErrorMessage(PENDING_UPDATE_REQUEST_MESSAGE)
      : history.replace(`/bookings/recipient/${id}`);
  };

  const handleDateChange = () => {
    return isPendingUpdate
      ? setErrorMessage(PENDING_UPDATE_REQUEST_MESSAGE)
      : history.replace(`/bookings/datetime/${id}`);
  };

  const handleLocationChange = () => {
    return isPendingUpdate
      ? setErrorMessage(PENDING_UPDATE_REQUEST_MESSAGE)
      : history.replace(`${Paths.ReviewConfirmedBookingLocation}/${id}`);
  };

  const handleFrequencyChange = () => {
    const inactiveStatus = [BOOKING_STATUS.CANCELLED, BOOKING_STATUS.COMPLETED];

    const isInactiveBooking = inactiveStatus.includes(booking.status);
    if (isInactiveBooking) {
      setUpdatingForBooking(booking);
      history.replace("/new-booking/booking-frequency", { editing: true, bookingId: booking.id });
      return;
    }

    return isPendingUpdate
      ? setErrorMessage(PENDING_UPDATE_REQUEST_MESSAGE)
      : history.replace(`/bookings/booking-frequency/${id}`);
  };

  const handleTreatmentChange = () => {
    return isPendingUpdate
      ? setErrorMessage(PENDING_UPDATE_REQUEST_MESSAGE)
      : history.replace(`/bookings/treatments/${id}`);
  };

  const handleTreatmentClick = (td: any) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    const treatment = getValue(td, "treatment") || getTreatmentDetailByTreatmentId(treatmentId);
    setTreatmentForDetails(treatment);
    setShowTreatmentInfoModel(true);
  };

  const handleAddOnClick = ({ td, addOnId }: { td: any; addOnId: any }) => {
    const treatmentId = getValue(td, "treatmentTypeId") || getValue(td, "treatmentId");
    const addOn = getAddOnDetailById({ treatmentId, id: addOnId });
    setTreatmentForDetails(addOn);
    setShowTreatmentInfoModel(true);
  };

  const getDescriptionForCorporate = (corporateInfo: any) => {
    if (checkIfEmpty(corporateInfo)) return "";

    const corporateDescription = transformCorporateForDescription({
      numberOfPerson: corporateInfo.numberOfPerson,
      durationPerPerson: corporateInfo.durationPerPerson,
      totalEventDuration: {
        massageDuration: corporateInfo.totalEventDuration,
        hasPaidBreak: corporateInfo.hasPaidBreak,
      },
      numberOfPros: corporateInfo.numberOfPros,
      eventType: corporateInfo.eventType,
      eventName: corporateInfo.eventName,
      noteForRecipients: corporateInfo.noteForRecipients,
      paymentSplit: corporateInfo.paymentSplit,
    });

    return corporateDescription.map((item: any) => {
      if (!item.value && item.key === CORPORATE_BOOKING_KEYS.eventName) return <></>;
      return (
        <Box key={item.key} lineHeight={"21px"}>{`${item.label} ${
          item.value ? item.value : "-"
        }`}</Box>
      );
    });
  };

  const getFormattedAnswersForCompleted = () => {
    if (isCorporateType) return [];

    if (isMassage || isHairAndMakeup) {
      const parsedDetails: any[] = [];
      bookingdetails.forEach(({ treatmentDetails }: any) =>
        parsedDetails.push(...treatmentDetails)
      );
      const formattedAnswer = parsedDetails.map(({ bookingAnswers }) =>
        getFormattedQuestionAnswer(bookingAnswers, questions, serviceId)
      );
      return formattedAnswer;
    }

    // "OTHER" service category
    const { bookinganswers } = booking;
    const formattedAnswers = getFormattedQuestionAnswer(bookinganswers, questions, serviceId);
    return [formattedAnswers];
  };

  const getFormattedAnswers = () => {
    const answers: any = [];

    if (isBookingComplete || isBookingCancelled) {
      return getFormattedAnswersForCompleted();
    }

    bookingdetails.forEach((detail: any) => {
      const { treatmentDetails } = detail;
      if (treatmentDetails && !checkIfEmpty(treatmentDetails)) {
        treatmentDetails.forEach(({ questions }: any) => {
          const formattedData = (questions || []).map((question: any) => {
            const answerValue = getValue(question, "answer.answer") || null;
            const answerOptions = question?.answerDetails;

            const selectedAns = answerOptions?.find(
              ({ value }: { value: string }) => value === answerValue
            );
            const payload: any = {
              question: question?.question,
              answer: selectedAns?.title,
              addedPrice: selectedAns?.addedPrice,
              addedDuration: selectedAns?.addedDuration,
              treatmentId: question?.treatmentId,
              serviceId: question?.serviceId,
              type: question?.type,
              addOn: question?.isAddOns,
              attrs: question?.attrs,
            };
            return payload;
          });
          answers.push(formattedData);
        });
      }
    });
    return answers;
  };

  const getTreatmentRowIcon = () => {
    let therapistCount = 1;
    if (isCorporateType) {
      therapistCount = corporateInfo?.numberOfPros || 1;
    } else if (isMassage) {
      therapistCount = isCouplesMassage ? 2 : 1;
    }
    return getTreatmentDetailsIcon(therapistCount);
  };

  const checkAllowedAction = (field: string) => {
    if (allowChange) return true;
    if (field && allowedActionFields.includes(field)) return true;

    return false;
  };

  const treatmentDetails = getTreatmentDetails();
  const address = getValue(booking, "address.fullAddress") || getValue(booking, "address.address");
  const bookingTitle = getValue(booking, "title", "");
  const recipientName = getRecipientName();
  const recipientId =
    getValue(booking, "selectedRecipient.id") || getValue(booking, "recipient.id");
  const timeOfArrival = getBookingDate();
  const bookingFrquency = getBookingFrequency();

  const styles = { padding: addPadding ? "32px 32px 0px 32px" : "" };

  const answers = getFormattedAnswers();

  const rowIcon = getTreatmentRowIcon();
  return (
    <Box
      style={styles}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      gridRowGap={Spacing.S4}
    >
      <BookingDetailRow
        icon={AddressIcon}
        text={address || ""}
        allowChange={allowLocationUpdate}
        handleChange={handleLocationChange}
        verticalAlignment="start"
        iconWrapperStyle={{ marginTop: "5px" }}
      />
      {isCorporateType ? (
        <BookingDetailRow
          icon={rowIcon}
          iconWrapperStyle={{ height: "none", width: "none" }}
          iconStyle={{ paddingTop: "5px", maxWidth: "none", maxHeight: "none" }}
          text={bookingTitle}
          allowChange={allowChange}
          description={() => getDescriptionForCorporate(corporateInfo)}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          lineHeight={"150%"}
          alignItems={"start"}
          gridColumnGap={"16px"}
        >
          <Box
            width="24px"
            mt={"5px"}
            height="24px"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <img src={rowIcon} alt="icon" />
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            <TreatmentDetailItems
              answers={answers}
              serviceId={serviceId}
              massageLength={length1}
              massageLength2={length2}
              sessionType={sessionType}
              treatmentDetails={treatmentDetails}
              genderPreference1={genderPreference1}
              genderPreference2={genderPreference2}
              onTreatmentClicked={handleTreatmentClick}
              onAddOnClicked={handleAddOnClick}
            />
          </Box>
          {allowChange && (
            <Box>
              {" "}
              <TextLink title="Change" onClick={handleTreatmentChange} />{" "}
            </Box>
          )}
        </Box>
      )}

      <BookingDetailRow
        prefix="For"
        icon={ForIcon}
        text={recipientName}
        allowChange={allowChange}
        textStyle={recipientId ? { textDecoration: "underline", cursor: "pointer" } : undefined}
        handleChange={handleRecipientChange}
        onTextClick={
          recipientId ? () => history.push(`/recipients/${recipientId}/details`) : undefined
        }
      />

      <BookingDetailRow
        icon={DateIcon}
        text={timeOfArrival}
        allowChange={allowChange}
        handleChange={handleDateChange}
      />

      <BookingDetailRow
        icon={BookingFrequencyIcon}
        text={bookingFrquency}
        allowChange={checkAllowedAction(BOOKING_DETAIL_FIELDS.BOOKING_FREQUENCY)}
        handleChange={handleFrequencyChange}
        iconStyle={{ width: "22px", height: "22px", maxHeight: "", maxWidth: "" }}
        description={getBookingFrequencyCaption()}
      />

      <BookingNote
        booking={booking}
        serviceId={booking.selectedService?.id}
        numberOfPros={corporateInfo?.numberOfPros}
        allowChange={false}
        onChange={() => {
          setShowPreferenceModal(true);
        }}
      />

      <TreatmentDescription
        isOpen={showTreatmentInfoModel}
        onClose={() => setShowTreatmentInfoModel(false)}
        title={treatmentForDetails?.label || ""}
        description={treatmentForDetails?.fullDescription || ""}
      />

      {/* <PreferencesModal
        open={showPreferenceModal}
        onClose={() => {
          setShowPreferenceModal(false);
        }}
        onSaved={() => {
          setShowPreferenceModal(false);
        }}
      /> */}
    </Box>
  );
};

export default TreatmentDetailsBlock;
