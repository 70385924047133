import { Colors } from "../../../constants/colors";

const styles = {
  flex: {
    display: "flex",
    gap: "16px",
  },
  person: {
    lineHeight: "24px",
    color: Colors.Indigo,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 600,
  },
  treatmentLabel: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
  detail: {
    lineHeight: "21px",
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
  detailText: {
    lineHeight: "21px",
    color: Colors.Dusk,
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 400,
  },
  imageWrapper: {
    height: "75px",
    width: "75px",
    borderRadius: 4,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  clickable: {
    cursor: "pointer",
  },
  label: {
    fontFamily: "Museo",
    fontWeight: 400,
    fontSize: "16px",
    color: Colors.Grey,
  },
  infoText: {
    color: Colors.Grey,
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 400,
  },
};

export default styles;
