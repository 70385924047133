import React from "react";
import { Box } from "@material-ui/core";
import * as Styled from "../../../../v2/Styled/enum";
import { Colors } from "../../../../../constants/colors";
import StripeLogo from "../../../../../images/stripe.png";
import BlysLogo from "../../../../../images/blys-logo.svg";

interface Props {
  iconStyle?: React.CSSProperties;
}

const BlysStripe = ({ iconStyle = {} }: Props): JSX.Element => {
  const defaultIconStyle = { height: "24px" };

  return (
    <Box
      gridGap={Styled.Spacing.S2}
      display={Styled.Display.Flex}
      alignItems={Styled.AlignItems.center}
      justifyContent={Styled.JustifyContent.center}
    >
      <img src={BlysLogo} alt={"Blys Logo"} style={{ ...defaultIconStyle, ...iconStyle }} />
      <Box style={{ width: "1px", height: "24px", backgroundColor: Colors.LightBlueGrey }} />
      <img src={StripeLogo} alt={"Stripe Logo"} style={{ ...defaultIconStyle, ...iconStyle }} />
    </Box>
  );
};

export default BlysStripe;
