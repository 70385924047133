import React from "react";
import { Box, Typography, Checkbox } from "@material-ui/core";
import { Colors } from "../constants/colors";


export interface CheckBoxOption {
  value: any;
  title: string;
}

interface Props {
  title?: string;
  options: Array<CheckBoxOption>;
  onSelectedOption: (option: Array<any>) => unknown;
  selectedOption?: Array<any>;
}

export default function CheckboxGroup({
  title,
  options,
  onSelectedOption,
  selectedOption = [],
}: Props): JSX.Element {

  const onCheck = (value: string) => {
    const selected = new Set(selectedOption);
    if (selected.has(value)) {
      selected.delete(value);
    } else {
      selected.add(value);
    }
    onSelectedOption(Array.from(selected));
  }

  return (
    <Box pt={'21px'} pb={'12px'}>
      {title && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            fontFamily="Museo"
            fontSize="14px"
            color={Colors.Dusk}
            fontWeight={700}
            flex={1}
            textAlign="left"
            mb={"10px"}
          >
            {title}
          </Box>
        </Box>
      )}

      {options.map((option) => (
        <Box
          fontFamily="Museo"
          fontSize="16px"
          color={Colors.Dusk}
          fontWeight={400}
          lineHeight="24px"
          mb="10px"
        >
          <Checkbox
            style={{
              color: Colors.TurquoiseBlue,
              margin: 0,
              padding: 0,
            }}
            id={option.value}
            checked={selectedOption.includes(option.value)}
            onChange={(event: any) => {
              onCheck(option.value)
            }}
          />  <label htmlFor={option.value}> {option.title}</label>
        </Box>
      ))}
    </Box>
  );
}