import React from "react";
import { Box } from "@material-ui/core";
import RadioButton from "../RadioButton";
import { BookingFrequencyUpdateType, RecurringBookingCancelType } from "../../constants/booking";

interface Props {
  type: RecurringBookingCancelType | BookingFrequencyUpdateType;
  selectedType: RecurringBookingCancelType | BookingFrequencyUpdateType;
  onSelected: () => unknown;
  label: string;
}
const ActionOptions = ({ type, selectedType, onSelected, label }: Props) => {
  return (
    <Box display="flex" alignItems="center" style={{ gap: "10px", padding: "3px 0px" }}>
      {
        <RadioButton
          selected={type === selectedType}
          onSelected={onSelected}
          style={{
            maxWidth: "16px",
            minWidth: "16px",
            display: "flex",
            height: 20,
          }}
        />
      }
      <Box style={{ textAlign: "left", minWidth: "32px" }}>{label}</Box>
    </Box>
  );
};

export default ActionOptions;
