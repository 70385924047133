import React, { useState } from "react";
import Wrapper from "../../components/Wrapper";
import SectionPageHeader from "../../components/SectionPageHeader";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { Colors } from "../../constants/colors";
import Button, { ButtonSize, ButtonType } from "../../components/Button";
import TextLink from "../../components/TextLink";
import AddUserModal from "../../components/BlysTeam/AddUserModal";
import ActionModal from "../../components/ActionModal";
import { Roles } from "../../constants/blysTeam";
import { useAlertStore } from "../../stores/alert";
import { useUserStore } from "../../stores/user";
import { useMobile } from "../../hooks/mobile";
import { useSendInvitaion } from "../../hooks/settings/settings.hooks";
import isValidEmail from "../../helpers/validation";
import { checkIfEmpty, getValue } from "../../utils/object";
import {
  useGetAllContactPerson,
  useGetAllInvitationRoles,
  useRemoveRecipientContactPerson,
  useUpdateRecipientContactPerson,
} from "../../hooks/settings/userSettings.hooks";
import { USER_ROLE } from "../../constants/role";

export interface UserDataInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: {} | null;
  userId: number;
}

export interface RecipientDataInterface {
  firstName: string;
  lastName: string;
  email: string;
  role?: string | number;
  userId?: number | null;
  mobile?: string;
  userOriginalRole?: string;
}

export interface InviteUserRolesOption {
  id: number;
  title: string;
  label: string;
}

const Users = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDataInterface | null>(null);
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);

  const [recipientId, setRecipientId] = useState<number | null>(null);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { user } = useUserStore();
  const isMobile = useMobile();

  const { data: contactPersonList, isLoading: isContactListPending } = useGetAllContactPerson();

  const { data: inviteUserRoles } = useGetAllInvitationRoles();

  const { isLoading: isUpdatingRecipient, mutate: updateContactPerson } =
    useUpdateRecipientContactPerson({
      onSuccess: () => setSuccessMessage("The user has been updated"),
      onError: () => setErrorMessage("Something went wrong"),
    });

  const { isLoading: isRemoving, mutate: removeContactPerson } = useRemoveRecipientContactPerson({
    onSuccess: () => setSuccessMessage("The user has been deleted"),
    onError: () => setErrorMessage("Something went wrong"),
  });

  const { isLoading: isSendingInvitation, mutate: sendInvitation } = useSendInvitaion({
    onSuccess: () => setSuccessMessage("Invitation has been sent"),
    onError: () => setErrorMessage("Something went wrong"),
  });

  const classes = useStyles();

  // Scroll to top on render
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const canInviteUser = getValue(user, "role.label") === Roles.HOME_CARE_PROVIDER;

  const handleUserEdit = (user: UserDataInterface) => {
    setSelectedUser(user);
    setShowAddUserModal(true);
  };

  const handleAddNewUser = () => {
    setSelectedUser(null);
    setShowAddUserModal(true);
  };

  const handleSaveUser = async (
    id: number | null,
    data: RecipientDataInterface,
    isManager: boolean,
    userOriginalRole?: string
  ) => {
    if (!data.userId && user && data.role === Roles.ACCOUNT_MANAGER) {
      data.userId = user.id;
    }
    const isValid = validateData(data);

    if (isValid !== true) {
      setErrorMessage(isValid.error);
      return;
    }

    const selectedRole = inviteUserRoles.find((item: any) => item.label === getValue(data, "role"));

    const reqData = {
      firstName: getValue(data, "firstName"),
      lastName: getValue(data, "lastName"),
      email: getValue(data, "email"),
      roleId: getValue(selectedRole, "id"),
      invitedBy: "recipient",
    };

    const isEmailUpdated = reqData.email !== selectedUser?.email;
    if (!id || isEmailUpdated) {
      sendInvitation(reqData);
    } else {
      updateContactPerson({ id, data: reqData });
    }

    setShowAddUserModal(false);
  };

  const validateData = (data: RecipientDataInterface) => {
    if (!data.firstName) {
      return { isValid: false, error: "Please enter first name" };
    }
    if (!data.lastName) {
      return { isValid: false, error: "Please enter last name" };
    }
    if (!data.email) {
      return { isValid: false, error: "Please enter email" };
    }
    if (!isValidEmail(data.email)) {
      return { isValid: false, error: "Please enter valid email" };
    }
    if (!data.role) {
      return { isValid: false, error: "Please enter role" };
    }

    return true;
  };

  const handleRemoveClicked = (id: number) => {
    setRecipientId(id);
    setShowRemoveUserModal(true);
  };

  const handleRemoveUserConfirm = async () => {
    if (recipientId) {
      removeContactPerson(recipientId);
      setRecipientId(null);
      setShowRemoveUserModal(false);
    }
  };

  const formatRoleLabel = (
    role = {
      title: "",
      label: "",
    }
  ) => {
    const { title, label } = role;

    switch (label) {
    case USER_ROLE.DISABILITY_SUPPORT_COORDINATOR:
    case USER_ROLE.AGED_CARE_SUPPORT_COORDINATOR:
      return "Support Coordinator";

    case USER_ROLE.GUARDIAN_OR_CARER:
      return "Carer / Next of Kin";

    default:
      return title;
    }
  };

  return (
    <>
      <Box marginLeft={isMobile ? "30px" : "80px"} marginTop="40px" fontFamily="museo">
        <Box fontSize="24px" fontWeight="600" color={Colors.NightBlue}>
          Manage users
        </Box>
        <Box
          paddingTop="24px"
          fontSize="16px"
          fontWeight="500"
          color={Colors.Dusk}
          paddingBottom="40px"
        >
          Invite your support team to your account so they can help you manage your bookings,
          payments and correspondence with Blys providers.
        </Box>

        <Box width={153}>
          <Button
            title={"Invite new user"}
            size={ButtonSize.large}
            type={ButtonType.outlined}
            onClick={handleAddNewUser}
          />
        </Box>
      </Box>
      {isContactListPending || isUpdatingRecipient ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer
            component={Paper}
            style={{
              marginLeft: isMobile ? "30px" : "80px",
              marginTop: "24px",
              maxWidth: isMobile ? "95%" : "92%",
            }}
          >
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header}>First name</TableCell>
                  <TableCell className={classes.header}>Last name</TableCell>
                  <TableCell className={classes.header}>Email</TableCell>
                  <TableCell className={classes.header}>Role</TableCell>
                  <TableCell className={classes.header}></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {!checkIfEmpty(getValue(contactPersonList, "data", [])) &&
                  getValue(contactPersonList, "data", []).map((contact: any) => {
                    return (
                      <TableRow key={contact.id}>
                        <TableCell className={classes.cell} component="th" scope="row">
                          {getValue(contact, "firstName")}
                        </TableCell>
                        <TableCell className={classes.cell} component="th" scope="row">
                          {getValue(contact, "lastName")}
                        </TableCell>
                        <TableCell className={classes.cell} component="th" scope="row">
                          {getValue(contact, "email")}
                        </TableCell>
                        <TableCell className={classes.cell} component="th" scope="row">
                          {formatRoleLabel(getValue(contact, "role", {})) ||
                            getValue(contact, "relation")}
                        </TableCell>
                        <TableCell className={classes.cell} component="th" scope="row">
                          <Box display="flex" flexDirection="row" justifyContent="right">
                            <TextLink
                              title="Edit"
                              fontSize="14px"
                              fontWeight={500}
                              onClick={() => handleUserEdit(contact)}
                            />

                            <TextLink
                              title="Remove"
                              fontSize="14px"
                              marginLeft="24px"
                              fontWeight={500}
                              color={Colors.Danger}
                              onClick={() => handleRemoveClicked(contact.id)}
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {showAddUserModal && (
        <AddUserModal
          open={showAddUserModal}
          onClose={() => setShowAddUserModal(false)}
          selectedUser={selectedUser}
          handleSaveClicked={(
            id: number | null,
            data: RecipientDataInterface,
            isManager: boolean,
            userOriginalRole?: string
          ) => handleSaveUser(id, data, isManager, userOriginalRole)}
          isLoading={isUpdatingRecipient || isSendingInvitation || isRemoving}
          managerOptions={[]}
          inviteUserRolesOption={inviteUserRoles}
        />
      )}
      {showRemoveUserModal && (
        <ActionModal
          open={showRemoveUserModal}
          title="Confirm user removal"
          description="Are you sure you want to delete a team member? "
          onCancel={() => setShowRemoveUserModal(false)}
          onConfirm={handleRemoveUserConfirm}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: "330px",
    maxWidth: "1040px",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  header: {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 500,
    color: Colors.Grey,
  },
  cell: {
    fontFamily: "Museo",
    fontSize: "16px",
    color: Colors.Dusk,
  },
});

export default Users;
