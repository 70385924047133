import { useMutation, useQuery } from "@tanstack/react-query";
import { get, post, put } from "../../api/client";
import queryClient from "../../config/queryClient";

const key = {
  BUSINESS_OPTIONS: "BUSINESS_OPTIONS",
  BUSINESS_DETAILS: "BUSINESS_DETAILS",
};

export const useBusinessOptions = () =>
  useQuery([key.BUSINESS_OPTIONS], () =>
    get("api/v2/users/organizations/options").then((res) => res),
  );

export const useGetBusinessDetails = () =>
  useQuery([key.BUSINESS_DETAILS], () => get("api/v2/users/organizations").then((res) => res.data));

export const useCreateBusinessDetails = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => unknown;
  onError: (error: any) => unknown;
}) =>
  useMutation((data: any) => post("api/v2/users/organizations", data), {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries({
        queryKey: [key.BUSINESS_DETAILS],
      });
    },
    onError,
  });

export const useUpdateBusinessDetails = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => unknown;
  onError: (error: any) => unknown;
}) =>
  useMutation(
    ({ data, businessDetailId: id }: any) => put(`api/v2/users/organizations/${id}`, data),
    {
      onSuccess: () => {
        onSuccess();

        queryClient.invalidateQueries({
          queryKey: [key.BUSINESS_DETAILS],
        });
      },
      onError,
    },
  );
