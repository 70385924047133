import React, { MouseEventHandler } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";

interface SummaryRowProps {
  title: string;
  value: string;
  handleOnClick?: MouseEventHandler<HTMLImageElement> | undefined;
}
const SummaryRow = ({ title, value, handleOnClick }: SummaryRowProps) => {
  const shouldHandleClick = !!handleOnClick;

  return (
    <Box display="flex" flexDirection="row">
      <Box
        style={{ ...styles.title, ...(shouldHandleClick ? styles.clickable : {}) }}
        onClick={handleOnClick}
      >
        {title}
      </Box>
      <Box style={styles.price}>{value}</Box>
    </Box>
  );
};

const styles: Record<string, React.CSSProperties> = {
  title: {
    fontFamily: "Museo",
    fontSize: "16px",
    fontWeight: 500,
    color: Colors.Dusk,
  },
  price: {
    flex: 1,
    display: "flex",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};

export default SummaryRow;
