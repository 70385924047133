import { Box, ButtonBase, Checkbox } from "@material-ui/core";
import InfoIcon from "../../images/info_grey.svg";
import { Colors } from "../../constants/colors";

interface Props {
  onChange: (event: any) => void;
  onInfoClick: (event: any) => void;
  genderFallback: boolean | null;
  genderFallbackMessage: string;
}

const GenderFallback = (props: Props) => {
  return (
    <Box
      fontFamily="Museo"
      fontSize="16px"
      color={Colors.Dusk}
      fontWeight={400}
      lineHeight="24px"
      mb="16px"
    >
      <Box mb="8px" display="flex" flexDirection="row" alignItems="center" gridGap="px">
        <Checkbox
          style={{
            color: Colors.TurquoiseBlue,
            margin: 0,
            padding: 0,
          }}
          checked={props.genderFallback || false}
          onChange={(event: any) => {
            props.onChange(event.target.checked);
          }}
        />{" "}
        {props.genderFallbackMessage}
      </Box>
      <Box
        fontFamily="Open Sans"
        fontWeight={400}
        fontSize="14px"
        lineHeight="21px"
        color={Colors.BlueyGrey}
      >
        Get confirmed sooner
        <ButtonBase
          onClick={(event) => {
            props.onInfoClick(true);
          }}
        >
          <img style={{ marginLeft: "5px" }} src={InfoIcon} width="20px" height="20px" />
        </ButtonBase>
      </Box>
    </Box>
  );
};

export default GenderFallback;
