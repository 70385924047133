import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as Styled from "../../v2/Styled/enum";
import { Paths } from "../../../constants/paths";
import { useMobile } from "../../../hooks/mobile";
import Button, { ButtonType } from "../../Button";
import { useAlertStore } from "../../../stores/alert";
import { parseApiError } from "../../../helpers/error";
import { StripeConnectAccountStatus } from "../../../constants/stripe";
import { LOG_CHANNEL, sendErrorLog } from "../../../services/log/log.service";
import {
  createExpressAccountLink,
  getExpressAccountStatus,
} from "../../../services/proDashboard/stripe.service";

interface Props {
  isLoading?: boolean;
}

const StripeConnectExpress = ({ isLoading }: Props) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(StripeConnectAccountStatus.NOT_CONNECTED);
  const { setErrorMessage } = useAlertStore();
  const isMobile = useMobile();
  const history = useHistory();

  useEffect(() => {
    getExpressAccountStatus()
      .then(({ status, expressDashboard = { url: "" } }) => {
        setStatus(status);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSetupPayouts = async () => {
    try {
      setLoading(true);
      const { data } = await createExpressAccountLink();
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage(parseApiError(error));
      sendErrorLog({
        domain: `WEB ${isMobile ? "MOBILE" : ""}`,
        channel: LOG_CHANNEL.SLACK,
        log: {
          title: "WEB: Stripe connect issue",
          data: JSON.stringify(error),
        },
      });
    }
  };

  const handleSeeAllPayoutsClicked = () => {
    history.push(`${Paths.ProDashboardReports}?tab=payouts`);
  };

  let buttonText;
  switch (status) {
  case StripeConnectAccountStatus.CONNECTED:
    buttonText = "Banking details";
    break;
  case StripeConnectAccountStatus.INCOMPLETE:
    buttonText = "Complete setup";
    break;
  default:
    buttonText = "Setup payouts";
    break;
  }

  return (
    <Box
      gridGap={Styled.Spacing.S4}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <Box
        gridGap={Styled.Spacing.S3}
        display={Styled.Display.Flex}
        flexDirection={isMobile ? Styled.FlexDirection.Column : Styled.FlexDirection.Row}
      >
        <Button
          width={"auto"}
          title={"See all payouts"}
          type={ButtonType.outlined}
          onClick={handleSeeAllPayoutsClicked}
          style={{
            minWidth: "208px",
          }}
        />
        <Button
          width={"auto"}
          loading={loading || isLoading}
          title={buttonText}
          onClick={handleSetupPayouts}
          style={{
            minWidth: "208px",
          }}
        />
      </Box>
    </Box>
  );
};

export default StripeConnectExpress;
