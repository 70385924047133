import React from "react";
import { Box } from "@material-ui/core";
import { Display, FlexDirection } from "../v2/Styled/enum";
import { ToggleSection } from "../ToggleSection/ToggleSection";
import { capitalizeFirstLetter } from "../../helpers/string";
import { BookingFilterStatus } from "../../constants/sortOptions";
import { JobStatus } from "../../constants/booking";

interface Props {
  onToggle: (statusToggle: boolean, status: string) => unknown;
  active: boolean;
  status: string;
  subtitle: string;
}

const BookingStatusFilter = ({ status, active, onToggle, subtitle }: Props) => {

  const handleBookingStatusFilterToggled = () => {
    onToggle(active, status);
  };

  const title = status === BookingFilterStatus[JobStatus.onway] ? "On the way" : status;

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
      <Box>
        <ToggleSection
          title={capitalizeFirstLetter(title)}
          subTitle={subtitle ? subtitle : `Show bookings that are ${status}.`}
          onToggle={() => {
            handleBookingStatusFilterToggled();
          }}
          isToggled={active}
          maxWidth={"311px"}
        />
      </Box>
    </Box>
  );
};

export default BookingStatusFilter;