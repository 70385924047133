import { Box } from "@material-ui/core";

import Divider from "../../../../components/Divider";
import ContentHeader from "../../../../components/Headers/ContentHeader/ContentHeader";
import CancellationPolicyText from "../../../../components/Util/CancellationPolicyText";
import { Colors } from "../../../../constants/colors";
import {
  bookingIsCancelled,
  bookingIsComplete,
  bookingIsConfirmed,
} from "../../../../helpers/booking";
import { Booking } from "../../../../models";
import InvoiceLinks from "../InvoiceLinks/InvoiceLinks";
import BookingSummaryItem, { BookingSummaryItemType } from "./BookingSummaryItem";

interface Props {
  booking: Booking;
}

const RecieptSummary = ({ booking }: Props) => {
  const bookingComplete = booking && bookingIsComplete(booking);
  const bookingConfirmed = booking && bookingIsConfirmed(booking);
  const bookingCancelled = booking && bookingIsCancelled(booking);

  return (
    <Box>
      <ContentHeader text="Reciept" style={{ fontSize: "20px", letterSpacing: "0.25px" }} />
      <BookingSummaryItem type={BookingSummaryItemType.massage} booking={booking} />
      <BookingSummaryItem type={BookingSummaryItemType.peakHourSurcharge} booking={booking} />
      <BookingSummaryItem type={BookingSummaryItemType.publicHolidaySurcharge} booking={booking} />
      <BookingSummaryItem type={BookingSummaryItemType.coupon} booking={booking} />
      <BookingSummaryItem type={BookingSummaryItemType.credit} booking={booking} />
      <BookingSummaryItem type={BookingSummaryItemType.discount} booking={booking} />
      <BookingSummaryItem type={BookingSummaryItemType.clientPlatformFee} booking={booking} />
      <BookingSummaryItem type={BookingSummaryItemType.cancellationFee} booking={booking} />
      <Box
        fontFamily="Museo"
        fontSize="16px"
        fontWeight={700}
        color={Colors.Dusk}
        display="flex"
        flexDirection="row"
        bgcolor={Colors.PaleGrey}
        height="54px"
        alignItems="center"
        borderRadius="6px"
        mb="25px"
      >
        <Box flex={1} ml="19px">
          Total paid
        </Box>
        <Box mr="19px">
          $
          {bookingCancelled
            ? booking?.cancellationFee?.toPriceString()
            : booking?.price?.toPriceString()}
        </Box>
      </Box>

      <CancellationPolicyText />
      <Divider />
      <Box mt={"14px"} justifyContent={"center"}>
        <InvoiceLinks booking={booking} />
        <Divider />
      </Box>
    </Box>
  );
};

export default RecieptSummary;
