import {
  getRadius,
  getServiceArea,
  updateServiceArea,
} from "../../../../services/proDashboard/serviceArea.proDashboard";

// Set Loading
const setLoading = (payload: boolean) => ({ type: "SET_LOADING", payload });

// Set Error
const setError = (message: string) => ({
  type: "SET_ERROR",
  payload: message,
});

const fetchServiceArea = (dispatch: any, accessToken: string) => {
  return Promise.all([
    getRadius(accessToken).then((radius: any = []) => {
      dispatch({
        type: "SET_RADIUS",
        payload: radius,
      });
    }),
    getServiceArea(accessToken).then((serviceArea: any = {}) => {
      dispatch({
        type: "SET_SERVICE_AREA",
        payload: serviceArea,
      });
    }),
  ])
    .then(() => {
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setError(error.message));
      dispatch(setLoading(false));
    });
};

const fetchServiceAreaRadius = (dispatch: any) => {
  return Promise.all([
    getRadius().then((radius: any = []) => {
      dispatch({
        type: "SET_RADIUS",
        payload: radius,
      });
    })
  ])
    .then(() => {
      dispatch(setLoading(false));
    })
    .catch((error) => {
      dispatch(setError(error.message));
      dispatch(setLoading(false));
    });
};


const update = (dispatch: any, payload: any, accessToken: string) => {
  // dispatch(setLoading(true));
  return updateServiceArea(payload, accessToken)
    .then((data) => {
      dispatch({
        type: "SET_SERVICE_AREA",
        payload: data,
      });
    });
};

const serviceAreaAction = {
  fetchServiceArea,
  fetchServiceAreaRadius,
  update,
};

export default serviceAreaAction;
