import { Colors } from "../../../constants/colors";

export const breakSlotStyles = {
  subTitle: {
    lineHeight: "18px",
    fontSize: "12px",
    fontWeight: 500,
    color: Colors.DarkGrey,
  },
  removeBtn: {
    border: `1px solid ${Colors.LightPeriwinkle}`,
    backgroundColor: Colors.Danger,
    borderRadius: "4px",
  },
  breakSlotContainer: {},
};

export const breakSlotModalStyles = {
  startTime: {
    display: "flex",
    width: "192px",
    height: "70px",
    padding: "15px 0px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px 4px 0px 4px",
    border: "0.5px solid #100249",
    background: " #FFF",
  },
  endTime: {
    display: "flex",
    width: "192px",
    height: "70px",
    padding: "15px 0px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px 4px 4px 0px",
    border: "0.5px solid #100249",
    background: " #FFF",
    fontFamily: "Museo",
  },
  timerClockWrapper: {
    width: "65px",
    height: "65px",
    strokeWidth: "1px",
    stroke: "#100249",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    border: `1px solid ${Colors.NightBlue}`,
    top: "-38px",
    zIndex: 1000,
    left: "calc((100% - 65px) / 2)",
    backgroundColor: "white",
  },
};
