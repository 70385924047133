import { useMutation, useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";
import { DropdownOption } from "../../components/Dropdown";
import { Recipient } from "../../models";

export const key = {
  RECIPIENT_OPTIONS: "RECIPIENT_OPTIONS",
  PROVIDER_OPTIONS: "PROVIDER_OPTIONS",
  SINGLE_RECIPIENT: "SINGLE_RECIPIENT",
  RECIPIENT_REVIEWS_RECEIVED: "RECIPIENT_REVIEWS_RECEIVED",
  RECIPIENT_REVIEWS_PROVIDED: "RECIPIENT_REVIEWS_PROVIDED",
  RECIPIENT_SAVED_ADDRESS: "RECIPIENT_SAVED_ADDRESS",
  RECIPIENT_FAV_THERAPIST: "RECIPIENT_FAV_THERAPIST",
  RECIPIENT_BLOCKED_THERAPIST: "RECIPIENT_BLOCKED_THERAPIST",
  RECIPIENT_RECENT_THERAPIST: "RECIPIENT_RECENT_THERAPIST",
};
export const useRecipientUpdate = () => {
  const { isLoading, mutate } = useMutation((recipientData: any) => {
    const { id: recipientId, updatedRecipientData: body } = recipientData;
    return httpClient.put(`users/me/recipients/${recipientId}`, body);
  });
  return { isLoading, updateRecipient: mutate };
};

export const useGetRecipientOptions = ({ name }: { name: string }) =>
  useQuery([key.RECIPIENT_OPTIONS, name], () => {
    return httpClient.get("users/me/recipients", { search: { name } }).then((res: any) => {
      const { data }: any = res;
      const recipientOptions: Array<DropdownOption> = (data || []).map((recipient: Recipient) => ({
        value: recipient.id,
        title: `${recipient.firstName} ${recipient.lastName}`,
      }));

      return recipientOptions;
    });
  });

export const useGetSingleRecipient = ({ id }: { id: number }) =>
  useQuery([key.SINGLE_RECIPIENT, id], () => {
    return httpClient.get(`api/v2/recipient/${id}`).then((res: any) => {
      const { data }: any = res;

      return data;
    });
  });

export const useGetRecipientProvidedReviews = (
  id: number,
  { currentPage = 1, perPage = 4, search = {} }
) =>
  useQuery([key.RECIPIENT_REVIEWS_PROVIDED, id, { currentPage, perPage, search }], () =>
    httpClient.get(`api/v2/recipient/${id}/reviews-provided`, {
      currentPage,
      perPage,
      search: JSON.stringify(search),
    })
  );

export const useGetRecipientReceivedReviews = (
  id: number,
  { currentPage = 1, perPage = 4, search = {} }
) =>
  useQuery([key.RECIPIENT_REVIEWS_RECEIVED, id, { currentPage, perPage, search }], () =>
    httpClient.get(`api/v2/recipient/${id}/reviews-received`, {
      currentPage,
      perPage,
      search: JSON.stringify(search),
    })
  );

export const useGetRecipientAddress = (
  id: number,
  { currentPage = 1, perPage = 15, search = {} }
) =>
  useQuery([key.RECIPIENT_SAVED_ADDRESS, id, { currentPage, perPage, search }], () =>
    httpClient.get(`api/v2/recipient/${id}/address`, {
      currentPage,
      perPage,
      search: JSON.stringify(search),
    })
  );

export const useGetFavouritePros = (
  id: number,
  { currentPage = 1, perPage = 15, search = {} }
) =>
  useQuery([key.RECIPIENT_FAV_THERAPIST, id, { currentPage, perPage, search }], () =>
    httpClient.get("favorite/me", {
      currentPage,
      perPage,
      search: JSON.stringify(search),
    })
  );

export const useGetBlockedPros = (
  id: number,
  { currentPage = 1, perPage = 15, search = {} }
) =>
  useQuery([key.RECIPIENT_BLOCKED_THERAPIST, id, { currentPage, perPage, search }], () =>
    httpClient.get("blockTherapist/me", {
      currentPage,
      perPage,
      search: JSON.stringify(search),
    })
  );

export const useGetRecipientRecentPros = (id: number,
  { currentPage = 1, perPage = 15, search = {} }) =>
  useQuery([key.RECIPIENT_RECENT_THERAPIST, id, { currentPage, perPage, search }], () =>
    httpClient.get(`api/v2/recipient/${id}/recentpros`, {
      currentPage,
      perPage,
      search: JSON.stringify(search),
    })
  );

