import axios from "axios";
import { isNil } from "lodash";
import moment from "moment";
import create from "zustand";
import { persist } from "zustand/middleware";
import { PaymentType } from "../constants/payment";
import { parseApiError } from "../helpers/error";
import { PaymentMethod, Purchase } from "../models";
import { getCurrentTimezone } from "../utils/date.util";
import { usePaymentStore } from "./booking";
import { DEFAULT_COUNTRY } from "../utils/country";

export interface Voucher {
  text: string;
  massageDuration: number;
  value: string | null;
  sessionType: string;
  maxNumberOfUsePerUser: number;
  maxNumberOfUseForAllUsers: number;
  voucherValue: string | null;
  type: string | null;
  giftText: string | null;
  sendTo: string;
  sendASAP: string;
  sendOn: Date | null;
  paymentMethod: PaymentMethod | null;
  couponCode: string;
  currency: string;
}

export interface VoucherPrice {
  price: number;
  priceWithoutDiscount: number;
  discount: number;
  credit: number;
  clientPlatformFee: {
    amount: number;
    remarks: string;
    title: string;
  };
  subTotal: number;
  convertedSubTotal: number;
}

type State = {
  voucher: Voucher | null;
  setVoucher: (voucher: Voucher | null) => unknown;
  recipientName: string;
  setRecipientName: (name: string) => unknown;
  recipientEmail: string;
  setRecipientEmail: (email: string) => unknown;
  yourName: string;
  setYourName: (name: string) => unknown;
  notes: string;
  setNotes: (notes: string) => unknown;
  sendTo: string;
  setSendTo: (sendTo: string) => unknown;
  sendASAP: string;
  setSendASAP: (sendASAP: string) => unknown;
  sendOn: Date | null;
  setSendOn: (sendOn: Date | null) => unknown;
  voucherPrice: VoucherPrice | null;
  setVoucherPrice: (price: VoucherPrice | null) => unknown;
  voucherOptions: Voucher[];
  setVoucherOptions: (options: Voucher[]) => unknown;
  countryOptions: string[];
  selectedCountry: string;
  setSelectedCountry: (country: string) => unknown;
  cardType: number;
  setCardType: (index: number) => unknown;
};

export const useVoucherStore = create<State>(
  persist(
    (set) => ({
      voucher: null,
      setVoucher: (voucher: Voucher | null) => {
        set({
          voucher,
        });
      },
      recipientName: "",
      setRecipientName: (name: string) => {
        set({
          recipientName: name,
        });
      },
      recipientEmail: "",
      setRecipientEmail: (email: string) => {
        set({
          recipientEmail: email,
        });
      },
      yourName: "",
      setYourName: (name: string) => {
        set({
          yourName: name,
        });
      },
      notes: "",
      setNotes: (notes: string) => {
        set({
          notes,
        });
      },
      sendTo: "client",
      setSendTo: (sendTo: string) => {
        set({
          sendTo,
        });
      },
      sendASAP: "asap",
      setSendASAP: (sendASAP: string) => {
        set({
          sendASAP,
        });
      },
      sendOn: null,
      setSendOn: (sendOn: Date | null) => {
        set({
          sendOn,
        });
      },
      voucherPrice: null,
      setVoucherPrice: (price: VoucherPrice | null) => {
        set({
          voucherPrice: price,
        });
      },
      voucherOptions: [],
      setVoucherOptions: (options: Voucher[]) => {
        set({
          voucherOptions: options,
        });
      },
      countryOptions: [],
      selectedCountry: "AU",
      setSelectedCountry: (selectedCountry: string) => {
        set({
          selectedCountry,
        });
      },
      cardType: 0,
      setCardType: (index: number) => {
        set({ cardType: index });
      },
    }),
    {
      name: "voucher-storage",
      getStorage: () => localStorage,
    }
  )
);

export function fetchVoucherPrice() {
  const { couponCode } = usePaymentStore.getState();
  const { voucher, selectedCountry } = useVoucherStore.getState();
  const accessToken = localStorage.getItem("token");

  if (!voucher || !accessToken) {
    return;
  }

  const { massageDuration, sessionType, value } = voucher;

  axios
    .get("/giftVouchers/price", {
      params: {
        accessToken,
        massageDuration,
        sessionType,
        couponPrice: value,
        couponCode,
        country: selectedCountry,
      },
    })
    .then((response) => {
      const price = response.data as VoucherPrice;

      if (!isNil(price.price)) {
        useVoucherStore.setState({
          voucherPrice: price,
        });
      }
    })
    .catch((error) => {
      console.debug("error: ", error);
    });
}

export function submitVoucherOrder(overridePaymentMethodId?: number): Promise<Purchase> {
  const {
    voucher,
    recipientEmail,
    recipientName,
    yourName,
    sendASAP,
    sendTo,
    sendOn,
    notes,
    selectedCountry,
    cardType,
  } = useVoucherStore.getState();
  const { paymentMethod, couponCode } = usePaymentStore.getState();
  const accessToken = localStorage.getItem("token");

  if (!voucher || !accessToken) {
    throw new Error("Fatal error");
  }

  const { sessionType, massageDuration, value } = voucher;

  const dateFormat = "yyyy-MM-DD";

  const _sendOnDate = () => {
    if (sendASAP === "asap") {
      return moment(new Date()).format(dateFormat);
    }

    if (sendOn) {
      return moment(sendOn).format(dateFormat);
    }

    return null;
  };

  const data = {
    accessToken,
    sessionType,
    voucherType: value,
    massageDuration,
    recipientEmail,
    toName: recipientName,
    fromName: yourName,
    sendASAP,
    sendTo,
    sendOnDate: _sendOnDate(),
    paymentMethodId: overridePaymentMethodId || paymentMethod?.id,
    currency: "AUD",
    couponCode,
    selectedVoucher: voucher,
    note: notes,
    timezone: getCurrentTimezone(),
    country: selectedCountry,
    cardType,
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/giftVouchers", data)
      .then((response) => {
        resolve(response.data as Purchase);
      })
      .catch((error) => {
        reject(parseApiError(error));
      });
  });
}

export const preselectLastPaymentMethod = () => {
  const accessToken = localStorage.getItem("token");

  const { paymentMethod } = usePaymentStore.getState();
  if (paymentMethod) return;

  axios
    .get(`/paymentmethods/last?accessToken=${accessToken}`)
    .then((response) => {
      const paymentMethod = response.data as PaymentMethod;

      if (paymentMethod.type === PaymentType.card || paymentMethod.type === PaymentType.paypal) {
        usePaymentStore.setState({
          paymentType: paymentMethod.type,
          paymentMethod,
        });
      }
    })
    .catch((error) => {
      console.debug("preselectLastPaymentMethod error: ", error);
    });
};

export const fetchVoucherOptions = () => {
  axios.get("/api/v2/voucher/option").then((response) => {
    const vouchers = response.data as Voucher[];

    const countryOptions = vouchers.reduce((countries: string[], object) => {
      const country = Object.keys(object)[0];
      countries.push(country);
      return countries;
    }, []);

    const usersCountry = localStorage.getItem("countryCode") || DEFAULT_COUNTRY;
    const isCountryAvailableForVoucher = countryOptions.includes(usersCountry);

    useVoucherStore.setState({
      voucherOptions: vouchers,
      countryOptions,
      selectedCountry: isCountryAvailableForVoucher ? usersCountry : DEFAULT_COUNTRY,
    });
  });
};
