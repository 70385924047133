import React from "react";
import { Box } from "@mui/material";
import RadioButton from "../RadioButton";
import { Display, FlexDirection, FontFamily, FontSize, FontWeight, Spacing } from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";

interface sortOption {
  value:any;
  title: string
}
interface Props {
  sortOptions: sortOption[];
  onOptionSelected: (selectedValue: any)=> unknown;
  selectedOption: any;
}

export const SortContainer = ({sortOptions =[], onOptionSelected, selectedOption}: Props)=>{
  return (     
    <Box gap={Spacing.S4} marginBottom={Spacing.S8} style = {{
      display: Display.Flex,
      flexDirection: FlexDirection.Column,
    }}>
      <Box fontWeight={FontWeight.Bold} fontSize={FontSize.F22} color={Colors.NightBlue} fontFamily={FontFamily.Museo}>
      Sort by
      </Box>
      {sortOptions.map((sortOpt) => (
        <Box alignItems="center" gap={Spacing.S5} style = {{
          display: Display.Flex,
          flexDirection: FlexDirection.Row,
        }}>
          <RadioButton
            style={{ height: 16, width: 16 }}
            selected={sortOpt.value === selectedOption}
            onSelected={()=>onOptionSelected(sortOpt.value)}
          />
          <Box
            color={Colors.Dusk}
            fontSize="16px"
            fontFamily="Museo"
            className="cursor-pointer"
            onClick={()=>onOptionSelected(sortOpt.value)}
          >
            {sortOpt.title}
          </Box>
        </Box>
      ))}
    </Box>);
};