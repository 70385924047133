import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, CircularProgress } from "@material-ui/core";
import BookingsMapArea from "./BookingsMapArea";
import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import { useAccessToken } from "../../../hooks/common";
import { Job, TherapistBooking } from "../../../models";
import { ADDRESS_DISCLAIMER } from "../../../constants/address";
import BookingItem from "../../../components/ProDashboard/BookingItem";
import { useServiceArea } from "../ServiceArea/context/ServiceAreaState";
import serviceAreaAction from "../ServiceArea/context/ServiceAreaAction";
import { BOOKINGS_SECTION_VALUES } from "../../../constants/prodashboard";

interface BookingsMapProps {
  bookings?: TherapistBooking[];
  jobs?: Job[];
  tab: string;
}

const BookingsMap = ({ bookings, jobs, tab }: BookingsMapProps) => {
  const markerRef = useRef<any>(null);
  const accessToken = useAccessToken();
  const { state, dispatch } = useServiceArea();
  const { user } = useUserStore();
  const history = useHistory();

  const [bookingsList, setBookingsList] = useState<any[]>([]);
  const [selectedBooking, setSelectedBookings] = useState<any>(null);

  const { loading, serviceArea = {} } = state;

  const therapistLongitude = getValue(user, "therapistprofile.longitude");
  const therapistLatitde = getValue(user, "therapistprofile.latitude");

  const serviceAreaInfo = {
    fixed: serviceArea.fixed,
    realTime: {
      ...serviceArea.realTime,
      latitude: therapistLatitde,
      longitude: therapistLongitude,
    },
  };

  useEffect(() => {
    if (accessToken) serviceAreaAction.fetchServiceArea(dispatch, accessToken);
  }, [accessToken]);

  useEffect(() => {
    if (bookings) {
      setBookingsList(bookings);
    }
    if (jobs) {
      setBookingsList(jobs);
    }
  }, [bookings, jobs]);

  const onBookingSelect = (booking: any) => {
    setSelectedBookings(booking);
  };

  const closeModal = (event: MouseEvent<HTMLElement>, cleanup = false) => {
    if (!cleanup) {
      event.stopPropagation();
    }
    setSelectedBookings(null);
    if (markerRef && markerRef.current) {
      markerRef.current.marker.setIcon(markerRef.current.icon);
    }
  };

  const handleClick = (jobId: any) => {
    if (tab === BOOKINGS_SECTION_VALUES.UPCOMING) return;
    history.push(`/pro/bookings/${jobId}`);
  };

  return (
    <Box mt="24px" mb="24px" position="relative">
      {tab === BOOKINGS_SECTION_VALUES.NEW && (
        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          lineHeight="21px"
          fontWeight={400}
          color={Colors.Dusk}
          mb={1}
        >
          {ADDRESS_DISCLAIMER}
        </Box>
      )}
      {loading ? (
        <CircularProgress
          variant="indeterminate"
          thickness={4}
          size={25}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "45px",
            width: "100%",
          }}
        />
      ) : (
        <BookingsMapArea
          tab={tab}
          showServiceAreas
          id={"bookings-map"}
          markerRef={markerRef}
          bookings={bookingsList}
          closeBookingModal={closeModal}
          serviceAreaInfo={serviceAreaInfo}
          onBookingSelect={onBookingSelect}
        />
      )}

      {selectedBooking &&
        (tab === BOOKINGS_SECTION_VALUES.NEW ? (
          <BookingItem
            job={selectedBooking}
            onClick={({ jobId }) => {
              handleClick(jobId);
            }}
            isModal={true}
            onClose={closeModal}
          />
        ) : (
          <BookingItem job={selectedBooking} isModal={true} onClose={closeModal} />
        ))}
    </Box>
  );
};
export default BookingsMap;
