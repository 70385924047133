import { Box, Typography } from "@material-ui/core";
import { isNil, sumBy } from "lodash";
import { useState } from "react";

import { PRICE_BREAKDOWN } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { bookingIsCancelled } from "../../helpers/booking";
import { formatToPrice } from "../../helpers/number";
import { usePeakHourFixed } from "../../hooks/booking/peakHour.hooks";
import { Booking } from "../../models";
import { useUserStore } from "../../stores/user";
import { checkIfEmpty, getValue } from "../../utils/object";
import CurrencyConversionInfo from "../CurrencyConversionInfo";
import InfoModal from "../Modals/InfoModal/InfoModal";

export enum BookingDetailsSummaryItemType {
  massage,
  peakHourSurcharge,
  discount,
  coupon,
  credit,
  cancellationFee,
  publicHolidaySurcharge,
  clientPlatformFee,
  totalPaid,
  parkingAndTravel,
}

interface Props {
  type: BookingDetailsSummaryItemType;
  booking: Booking;
}

export default function BookingDetailsSummaryItem({ type, booking }: Props): JSX.Element {
  const { originalPrice, platformFeeData, peakHourInfo } = booking;
  const [infoModal, setInfoModal] = useState({
    visible: false,
    content: {
      title: "",
      description: "",
      data: [],
    },
  });

  const { currency: userCurrency, currencyCode: userCurrencyCode } = useUserStore();

  const cancelled = bookingIsCancelled(booking);

  const bookingCurrency = getValue(booking, "service.selectedCountry.currencySymbol", "A$");
  const bookingCurrencyCode = getValue(booking, "service.selectedCountry.currency", "AUD");

  const originalCouponAmount = getValue(booking, "coupon.originalAmount");
  const originalCouponCurrency = getValue(booking, "coupon.originalCurrency");
  const originalCouponSymbol = getValue(booking, "coupon.originalCouponCurrencySymbol");
  const couponType = getValue(booking, "coupon.type");

  const originalAmount = sumBy(booking.credituses, (creditUse) => creditUse.originalAmount);

  const { upToText } = usePeakHourFixed({
    lateNightSurcharge: booking.lateNightSurcharge,
    lateNightSurchargeApplied: booking.lateNightSurchargeApplied,
  });

  const clickableSummaryItems = [
    BookingDetailsSummaryItemType.clientPlatformFee,
    BookingDetailsSummaryItemType.peakHourSurcharge,
    BookingDetailsSummaryItemType.parkingAndTravel,
  ];

  const enabled = () => {
    const parkingAndTravelPrice = (booking?.addressSurcharge ?? 0) + (booking?.hotelSurcharge ?? 0);
    switch (type) {
    case BookingDetailsSummaryItemType.massage:
      return true;
    case BookingDetailsSummaryItemType.parkingAndTravel:
      return parkingAndTravelPrice > 0;
    case BookingDetailsSummaryItemType.peakHourSurcharge:
      return ((booking?.lateNightSurcharge ?? 0) || (booking?.publicHolidaySurcharge ?? 0)) > 0;
    case BookingDetailsSummaryItemType.discount:
      return false;
    case BookingDetailsSummaryItemType.coupon:
      return !isNil(booking.coupon);
    case BookingDetailsSummaryItemType.credit:
      return booking.credituses.length > 0;
    case BookingDetailsSummaryItemType.cancellationFee:
      return cancelled;
    case BookingDetailsSummaryItemType.publicHolidaySurcharge:
      return false;
    case BookingDetailsSummaryItemType.clientPlatformFee:
      return booking.clientPlatformFee ?? 0 > 0;
    case BookingDetailsSummaryItemType.totalPaid:
      return (booking.totalAmountDue || 0) > 0;
    }
  };

  const title = () => {
    switch (type) {
    case BookingDetailsSummaryItemType.massage:
      return PRICE_BREAKDOWN.BASERATE;
    case BookingDetailsSummaryItemType.parkingAndTravel:
      return PRICE_BREAKDOWN.PARKINGTRAVEL;
    case BookingDetailsSummaryItemType.peakHourSurcharge:
      return PRICE_BREAKDOWN.PEAK_PERIOD;
    case BookingDetailsSummaryItemType.discount:
      return "Discount";
    case BookingDetailsSummaryItemType.coupon:
      return `Coupon (${booking.coupon.code})`;
    case BookingDetailsSummaryItemType.credit:
      return PRICE_BREAKDOWN.CREDIT;
    case BookingDetailsSummaryItemType.cancellationFee:
      return "Cancellation fee";
    case BookingDetailsSummaryItemType.publicHolidaySurcharge:
      return PRICE_BREAKDOWN.PUBLIC_HOLIDAY;
    case BookingDetailsSummaryItemType.clientPlatformFee:
      return getValue(booking, "platformFeeData.title", "Platform Fee");
    case BookingDetailsSummaryItemType.totalPaid:
      return "Total paid";
    }
  };

  const value = () => {
    let price = 0;

    switch (type) {
    case BookingDetailsSummaryItemType.massage:
      price = cancelled ? price : originalPrice - booking.addressSurcharge;
      return formatToPrice(price, bookingCurrency);
    case BookingDetailsSummaryItemType.parkingAndTravel:
      const parkingAndTravelPrice =
          (booking?.addressSurcharge ?? 0) + (booking?.hotelSurcharge ?? 0);
      price = cancelled ? price : parkingAndTravelPrice;
      return formatToPrice(price, bookingCurrency);
    case BookingDetailsSummaryItemType.peakHourSurcharge:
      price = cancelled ? price : booking.lateNightSurcharge + booking.publicHolidaySurcharge;
      return price > 0
        ? `${upToText}${formatToPrice(price, bookingCurrency)}`
        : `${formatToPrice(price, bookingCurrency)}`;
    case BookingDetailsSummaryItemType.discount:
      return formatToPrice(price, bookingCurrency);

    case BookingDetailsSummaryItemType.coupon:
      if (booking.coupon.type === "percent") {
        const couponPrice =
            (booking.priceWithoutDiscount - booking.clientPlatformFee) *
            (booking.coupon.value / 100);
        return `-${formatToPrice(couponPrice, bookingCurrency)}`;
      }

      if (booking.coupon.type === "referral" || booking.coupon.type === "value") {
        const couponPrice = booking.coupon.value;
        return `-${formatToPrice(couponPrice, bookingCurrency)}`;
      }

      return null;

    case BookingDetailsSummaryItemType.credit:
      const creditUsesAmount = sumBy(booking.credituses, (creditUse) => creditUse.amount);
      return formatToPrice(creditUsesAmount, bookingCurrency);
    case BookingDetailsSummaryItemType.cancellationFee:
      return formatToPrice(booking.cancellationFee, bookingCurrency);

    case BookingDetailsSummaryItemType.publicHolidaySurcharge:
      return formatToPrice(booking.publicHolidaySurcharge, bookingCurrency);
    case BookingDetailsSummaryItemType.clientPlatformFee:
      return formatToPrice(booking.clientPlatformFee, bookingCurrency);
    case BookingDetailsSummaryItemType.totalPaid:
      return formatToPrice(booking.totalPaid, bookingCurrency);
    }
  };

  const isClickable = () => clickableSummaryItems.includes(type);

  const getInfoModalContent = (type: BookingDetailsSummaryItemType) => {
    let modalContent = {
      title: "",
      description: "",
      data: [],
    };

    switch (type) {
    case BookingDetailsSummaryItemType.clientPlatformFee:
      modalContent.title = getValue(platformFeeData, "title", "");
      modalContent.description = getValue(platformFeeData, "remarks", "");
      break;
    case BookingDetailsSummaryItemType.peakHourSurcharge:
      modalContent.title = getValue(peakHourInfo, "title", "");
      modalContent.description = getValue(peakHourInfo, "subtitle", "");
      modalContent.data = getValue(peakHourInfo, "data", []);
      break;
    case BookingDetailsSummaryItemType.parkingAndTravel:
      if (!booking?.parkingAndTravelInfo || checkIfEmpty(booking.parkingAndTravelInfo)) break;
      const parkingInfo = booking.parkingAndTravelInfo;
      modalContent = {
        title: parkingInfo.title,
        description: parkingInfo.subtitle,
        data: parkingInfo.data,
      };
      break;
    }

    return modalContent;
  };

  const handleInfoIconClicked = (type: BookingDetailsSummaryItemType) => {
    const modalContent = getInfoModalContent(type);
    setInfoModal({
      visible: true,
      content: modalContent,
    });
  };

  const handleInfoDismiss = () => {
    setInfoModal({ ...infoModal, visible: false });
  };

  return enabled() ? (
    <Box>
      <Box display="flex" flexDirection="row">
        <Box
          fontFamily="Museo"
          fontSize="14px"
          color={Colors.Dusk}
          onClick={() => handleInfoIconClicked(type)}
        >
          <Typography style={isClickable() ? styles.clickable : {}}>{title()}</Typography>
        </Box>
        <Box flex={1} fontFamily="Museo" fontSize="14px" color={Colors.Dusk} textAlign="right">
          {value()}
        </Box>
      </Box>

      {type === BookingDetailsSummaryItemType.coupon && couponType === "value" ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={originalCouponCurrency}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(originalCouponAmount, originalCouponSymbol)}
        />
      ) : (
        <></>
      )}
      {type === BookingDetailsSummaryItemType.credit ? (
        <CurrencyConversionInfo
          convertedToCurrencyCode={userCurrencyCode}
          convertedFromCurrencyCode={bookingCurrencyCode}
          convertedCurrency={formatToPrice(originalAmount, userCurrency)}
        />
      ) : (
        <></>
      )}
      {isClickable() && (
        <InfoModal
          visible={infoModal.visible}
          title={infoModal.content.title}
          description={infoModal.content.description}
          data={infoModal.content.data}
          divider={false}
          handleClose={handleInfoDismiss}
        />
      )}
    </Box>
  ) : (
    <></>
  );
}

const styles = {
  clickable: {
    textDecoration: "underline",
    cursor: "pointer",
  },
};
