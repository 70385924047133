import { Box } from "@material-ui/core";
import { useMobile } from "../hooks/mobile";
import { Colors } from "../constants/colors";
import Button, { ButtonSize, ButtonType } from "./Button";
import Dialog from "./Dialog";

interface ActionModalInterface {
  open: boolean;
  title: string;
  description: string;
  onCancel: () => unknown;
  onConfirm: () => unknown;
  confirmText?: string;
  cancelText?: string;
}

const ActionModal = ({
  open,
  title,
  description,
  onCancel,
  onConfirm,
  confirmText = "Confirm",
  cancelText = "Cancel",
}: ActionModalInterface) => {
  const isMobile = useMobile();

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={isMobile}>
      <Box padding={"48px"} bgcolor="#ffffff" borderRadius="11px">
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="22px"
            color={Colors.NightBlue}
          >
            {title}
          </Box>
          <Box
            fontFamily="Open Sans"
            fontSize="16px"
            color={Colors.BlueyGrey}
            mt="16px"
            textAlign={"center"}
          >
            <Box>{description}</Box>
          </Box>
          <Box style={styles.actionWrapper} flexDirection="row">
            <Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title={cancelText}
              onClick={onCancel}
              style={{ marginRight: "12px" }}
            />
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title={confirmText}
              onClick={onConfirm}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ActionModal;

const styles = {
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "32px",
  },
};
