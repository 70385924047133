import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import NoProviders from "./NoProviders";
import ProviderItem from "./ProviderItem";
import { PROVIDER_OPTIONS_VALUE } from "./enum";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { useBookingStore } from "../../../stores/booking";
import { TherapistPublicProfileData } from "../../../models";
import { checkIfEmpty, getValue } from "../../../utils/object";
import TherapistProfileDrawer from "../../TherapistProfileDrawer";
import DropDownArrow from "../../../images/dropdown-arrow-nightblue.svg";
import { useProForBooking } from "../../../hooks/booking/preferredProviders.hooks";

interface Props {
  selectedProviderOption: (typeof PROVIDER_OPTIONS_VALUE)[keyof typeof PROVIDER_OPTIONS_VALUE];
  proSearchOptions?: Record<string, any>;
  selectedProIds: Array<number | string> | null;
  onSelectPreferredPro: (proId: number | string) => unknown;
  onFallbackClicked?: () => unknown;
}

const RecommendedProviders = ({
  selectedProviderOption,
  proSearchOptions,
  selectedProIds,
  onSelectPreferredPro,
  onFallbackClicked,
}: Props): JSX.Element => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(6);
  const [proList, setProList] = useState<Array<TherapistPublicProfileData>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [openProProfileDialog, setOpenProProfileDialog] = useState<boolean>(false);
  const [proIdForProfileView, setProIdForProfileView] = useState<number>(0);

  const isRecent = selectedProviderOption === PROVIDER_OPTIONS_VALUE.RECENT_PROVIDERS;
  const hasEndReached = (proList || []).length >= totalCount;

  const isMobile = useMobile();
  const useProList = useProForBooking({ isRecent });

  const { updatingForBooking } = useBookingStore();

  const {
    data: availablePros,
    isInitialLoading,
    isFetching,
  } = useProList({
    currentPage,
    perPage,
    options: proSearchOptions,
  });

  useEffect(() => {
    if (isFetching) return;

    let therapistList = getValue(availablePros, "therapistList", []);
    let page = getValue(availablePros, "currentPage", 1);

    if (!!updatingForBooking && !checkIfEmpty(selectedProIds)) {
      // make the pro first in the list if pre-selected
      const selectedProIdx = (therapistList || []).findIndex(({ id }: { id: number }) =>
        selectedProIds?.includes(id),
      );
      if (selectedProIdx !== -1) {
        const selectedPro = therapistList[selectedProIdx];
        therapistList.splice(selectedProIdx, 1);
        therapistList.unshift(selectedPro);
      }
    }

    setProList(page === 1 ? therapistList : [...proList, ...therapistList]);

    if (!totalCount) {
      const totalCount = getValue(availablePros, "totalCount", 0);
      setTotalCount(totalCount);
    }
  }, [isFetching, currentPage]);

  const handleProfileClick = (id: number | string) => {
    const proId = parseInt(String(id), 10);
    setProIdForProfileView(proId);
    setOpenProProfileDialog(true);
  };

  const handleSeeMore = () => {
    if ((proList || []).length >= totalCount) return;
    setCurrentPage(currentPage + 1);
  };

  const handleNoProviderFallback = () => {
    if (!onFallbackClicked) return;
    onFallbackClicked();
  };

  if (isInitialLoading && (proList || []).length === 0) {
    return (
      <Box display={"flex"} justifyContent={"center"} flex={1} my={"8px"} mt={"32px"}>
        <CircularProgress variant={"indeterminate"} style={{ color: Colors.Indigo }} />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gridGap={"24px"}
        flexWrap={"wrap"}
        mt={"32px"}
        ml={isMobile ? "0px" : "47px"}
      >
        {(proList || []).length > 0 ? (
          proList.map((provider) => (
            <ProviderItem
              key={provider.id}
              provider={provider}
              selected={(selectedProIds || []).includes(provider.id)}
              onSelect={() => onSelectPreferredPro(provider.id)}
              onProfileClick={() => handleProfileClick(provider.id)}
            />
          ))
        ) : (
          <React.Fragment />
        )}
        {(proList || []).length === 0 && !isFetching ? (
          <Box display={"flex"} flex={1} justifyContent={"center"}>
            <NoProviders onFallbackClick={handleNoProviderFallback} selectedProviderOption={selectedProviderOption}/>
          </Box>
        ) : (
          <React.Fragment />
        )}
      </Box>

      {isFetching ? (
        <Box display={"flex"} justifyContent={"center"} flex={1} my={"8px"} mt={"32px"}>
          <CircularProgress variant={"indeterminate"} style={{ color: Colors.Indigo }} />
        </Box>
      ) : (
        <React.Fragment />
      )}

      {(proList || []).length > 0 && !hasEndReached ? (
        <Box
          display={"flex"}
          gridGap={"4px"}
          justifyContent={"center"}
          mt={"32px"}
          onClick={() => handleSeeMore()}
          style={{
            cursor: "pointer",
          }}
        >
          <Box
            fontFamily={"Museo"}
            fontSize={"18px"}
            fontWeight={700}
            lineHeight={"27px"}
            color={Colors.NightBlue}
          >
            See more
          </Box>
          <Box p={"4px"} display={"flex"} alignItems={"center"}>
            <img src={DropDownArrow} alt={"Arrow"} width={"14px"} />
          </Box>
        </Box>
      ) : (
        <React.Fragment />
      )}
      <TherapistProfileDrawer
        type={"popup"}
        open={openProProfileDialog}
        therapistId={proIdForProfileView}
        onClose={() => setOpenProProfileDialog(false)}
      />
    </React.Fragment>
  );
};

export default RecommendedProviders;
