import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import { timeRanges } from "../config";
// @ts-ignore
import * as scheduleService from "./schedule.service";
// @ts-ignore
import DaySchedule from "./DaySchedule";
import BufferTime from "./BufferTime";
import {
  getTherapistSchedule,
  updateTherapistSchedule,
} from "./apiCall";
import { getWeekDays } from "../../../../utils/date.util";
import { DayInterface, WeekDayScheduleInterface, DefaultWeekScheduleInterface } from "./interface";
import CalendarConfig from "../Calendar/CalanderConfig";

const styles = {
  dayWrapper: {
    display: "flex",
  },
  scheduleBody: {
    marginTop: "15px",
    fontFamily: "Museo",
    fontSize: "14px",
  },
  day: {
    fontFamily: "Museo",
    fontSize: "14px",
    marginTop: "15px",
    width: "280px",
  },
  timeSelect: {
    fontFamily: "Museo",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    width: "182px",
    padding: "0px 15px",
    color: "#41506F",
  },
};



const weekDays = getWeekDays();
const defaultWeekSchedules: DefaultWeekScheduleInterface[] = weekDays.map(
  (weekDay: DayInterface) => {
    return {
      title: weekDay.title,
      weekDay: weekDay.value,
      startTime: null,
      endTime: null,
      isActive: false,
    };
  }
);

const Schedule = () => {
  const [proSchedules, setProSchedules] = useState(defaultWeekSchedules);
 

  const getAndSetProSchedules = async () => {
    // call to server, get method
    const response = await getTherapistSchedule();

    if (response) {
      const newProSchedules = scheduleService.transformProSchedules(
        response.data,
        defaultWeekSchedules
      );
      setProSchedules([...newProSchedules]);
    }
  };

  const setWeekDaySchedule = async ({
    weekDay,
    startTime,
    endTime,
    isActive,
  }: WeekDayScheduleInterface) => {
    // call the server over here
    const data = {
      weekDay: `${weekDay}`,
      startTime: startTime?.toString() || null,
      endTime: endTime?.toString() || null,
      isActive: isActive,
    };
    const response = await updateTherapistSchedule(data);

    if (!response) {
      getAndSetProSchedules();
    }
    // if server response fails, call the method getAndSetProSchedules;
  };

  useEffect(() => {
    getAndSetProSchedules();
  }, []);

  const handleTimeChange = ({ weekDay, startTime, endTime }: WeekDayScheduleInterface) => {
    const weekIndex = proSchedules.findIndex(
      (schedule: { weekDay: number }) => schedule.weekDay === weekDay
    );
    const schedule = proSchedules.find(
      (schedule: { weekDay: number }) => schedule.weekDay === weekDay
    );
    if (!schedule) {
      return;
    }

    if (startTime) {
      schedule.startTime = startTime;
    }

    if (endTime) {
      schedule.endTime = endTime;
    }

    if (schedule.startTime && schedule.endTime) {
      setWeekDaySchedule({
        weekDay,
        startTime: schedule.startTime as string,
        endTime: schedule.endTime as string,
        isActive: schedule.isActive,
      });
    }

    proSchedules[weekIndex] = schedule;
    setProSchedules([...proSchedules]);
  };

  const handleCheckBoxClick = (weekDay: number, dayScheduledStatus: boolean) => {
    const weekIndex = proSchedules.findIndex(
      (schedule: { weekDay: number }) => schedule.weekDay === weekDay
    );
    const schedule = proSchedules.find(
      (schedule: { weekDay: number }) => schedule.weekDay === weekDay
    );

    if (!schedule) {
      return;
    }
    const isActive = !dayScheduledStatus;
    const startTimeAndEndTimeConfigured = !!schedule.startTime && !!schedule.endTime;

    schedule.isActive = isActive;

    if (isActive && !schedule.startTime && !schedule.endTime) {
      schedule.startTime = "6";
      schedule.endTime = "23";
    }

    proSchedules[weekIndex] = schedule;

    setProSchedules([...proSchedules]);
    setWeekDaySchedule({
      weekDay: schedule.weekDay,
      startTime: schedule.startTime,
      endTime: schedule.endTime,
      isActive: schedule.isActive,
    });
  };


  return (
    <>
      <Box fontFamily="Museo" fontWeight={600} fontSize="24px" color={Colors.Dusk} mt="40px">
        Set your availability
      </Box>
      <Box
        fontFamily="Museo"
        fontSize="16px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="24px"
        mb="24px"
      >
        Choose when you're available to accept bookings.
      </Box>

      <CalendarConfig isIgnoreAvailabilities={true} />

      <Box style={styles.scheduleBody} flexDirection="column">
        <Box style={styles.dayWrapper} flexDirection="row">
          <Box style={styles.day} />
          <Box style={styles.timeSelect}>Start</Box>
          <Box style={styles.timeSelect}>Finish</Box>
        </Box>
        {proSchedules.map((schedule, index) => (
          <DaySchedule
            key={schedule.weekDay}
            schedule={schedule}
            timeRanges={timeRanges}
            handleTimeChange={handleTimeChange}
            handleCheckBoxClick={handleCheckBoxClick}
            index={index}
          />
        ))}

        <BufferTime />
      </Box>
    </>
  );
};

export default Schedule;
