import axios from "axios";
import { deleteApi, get, post, put } from "../../api/client";

export const checkEmailExists = async (email: string) => {
  try {
    const { data } = await axios.post("api/v2/users/email/check", { email });
    return data;
  } catch (err: any) {
    const error = err?.response?.data?.error;
    return { error };
  }
};

export const updateUserPreferences = async (payload: any, accessToken: string | null) => {
  try {
    const path = `/users/me/preferences?accessToken=${accessToken}`;
    const { data } = await axios.post(path, payload);
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchUserPreferences = async (accessToken: string) => {
  try {
    const path = "/api/v2/userPreferences";
    const { data } = await axios.get(path, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err) {
    throw err;
  }
};

interface AccountDeleteRequestPayload {
  email: string;
  reason?: string;
  password: string;
}

interface AccountDeleteRequestMfaPayload {
  email: string;
  reason?: string;
  code: string;
  serviceId: string;
}

const requestAccountDeletion = ({ reason, email, password }: AccountDeleteRequestPayload) => {
  const payload = {
    email,
    reason,
    password,
  };
  const DELETE_URL = "api/v2/users/delete-request";
  return post(DELETE_URL, payload);
};

const requestAccountDeletionMfa = ({
  reason,
  email,
  code,
  serviceId,
}: AccountDeleteRequestMfaPayload) => {
  const payload = {
    email,
    reason,
    code,
    serviceId,
  };
  const DELETE_URL = "api/v2/users/delete-request-mfa";
  return post(DELETE_URL, payload);
};

const removeUserAddress = async ({ addressId }: { addressId: number }) => {
  const path = `users/me/addresses/${addressId}`;
  return deleteApi(path);
};

interface UpdateAddressParams {
  addressId: number;
  data: any;
}

const updateUserAddress = async ({ addressId, data }: UpdateAddressParams) =>
  put(`users/me/addresses/${addressId}`, data);

const createUserAddress = async ({ data }: { data: any }) => post("users/me/addresses", data);

const lockUnlockAttribute = async (data: any) => post("api/v2/users/lock-attribute", data);

const verifyInvitationCode = async (code: string) => get(`api/v2/invite/${code}`, {}, false);

const acceptInvitation = async (code: string) =>
  post(
    `api/v2/invite/${code}/accept
`,
    {},
    false
  );

const createUserAddressOnBehalfOfClientByPro = async ({ data }: { data: any }) =>
  post("api/v2/users/address", data);

const checkAddressHasRecurringBooking = async (id: number) => {
  return get(`api/v2/users/address/checkRecurring/${id}`);
};

export {
  acceptInvitation,
  checkAddressHasRecurringBooking,
  createUserAddress,
  createUserAddressOnBehalfOfClientByPro,
  lockUnlockAttribute,
  removeUserAddress,
  requestAccountDeletion,
  requestAccountDeletionMfa,
  updateUserAddress,
  verifyInvitationCode,
};
