export const NETWORK_ERROR = "Network Error";
export const POOR_CONNECTIVITY_MESSAGE =
  "Page can't load due to poor connectivity. Check your connection and try again.";
export const PENDING_UPDATE_REQUEST_MESSAGE =
  "Please respond to the pending update request first before sending a new update request.";
export const GENDER_DESCRIPTION =
  "We collect this information to better match you with a provider. Your gender information will be visible to your provider.";
export const FEMALE_CONTRAINDICATIONS_TITLE =
  "Are you aware of any pre-existing medical conditions, injuries, allergies or pregnancy that may prevent you or the recipient from receiving this treatment?";
export const MALE_CONTRAINDICATIONS_TITLE =
  "Are you aware of any pre-existing medical conditions, injuries or allergies that may prevent you or the recipient from receiving this treatment?";
export const CONTRAINDICATIONS_DESCRIPTION =
  "Failing to disclose any pre-existing conditions may result in booking termination and up to 100% cancellation fee if your provider discovers the condition to be contraindicating.";
export const PASSWORD_DESCRIPTION = "Leave blank to leave unchanged.";
export const PENDING_UPDATE_REQUEST_MESSAGE_PRO =
  "Please wait unitl the pending update request  get accepted by client first before sending a new update request.";
export const REVIEW_CLIENT_MESSAGE = "Thank you for your review!";


export const INFO_MESSAGES = {
  corporateServiceUpdate:
    "To change the service type to corporate, please cancel this booking and create a new one with your preferred service type.",
  couplesServiceUpdate:
    "To change the service type for a couples booking, please cancel this booking and create a new one with your preferred service type.",
  couplesSessionUpdate: "To change the session type, please cancel this booking and create a new one with your preferred session type.",
};
