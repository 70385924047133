export const USER_ROLE = {
  USER: "user",
  ADMIN: "admin",
  SUPER_ADMIN: "superAdmin",
  PLAN_MANAGER: "planManager",
  ACCOUNT_OWNER: "accountOwner",
  ACCOUNT_MANAGER: "accountManager",
  GUARDIAN_OR_CARER: "guardianOrCarer",
  HOME_CARE_PROVIDER: "homeCareProvider",
  AGED_CARE_SUPPORT_COORDINATOR: "agedCareSupportCoordinator",
  DISABILITY_SUPPORT_COORDINATOR: "disabilitySupportCoordinator",
  AGED_CARE_SUPPORT_RECIPIENT: "agedCareSupportRecipient",
  DISABILITY_SUPPORT_RECIPIENT: "disabilitySupportRecipient",
  CUSTOMER_SUPPORT: "customerSupport",
  FUND_MANAGER: "fundManager",
  CARER_OR_NEXT_OF_KIN: "carerOrNextOfKin",
};
