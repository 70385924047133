import React from "react";
import { Text } from "./v2/Styled";
import * as Styled from "./v2/Styled/enum";
import { Colors } from "../constants/colors";

interface Props {
  totalSteps: number;
  currentStep: number;
}

const StepProgressIndicator = ({ totalSteps = 0, currentStep = 0 }: Props): JSX.Element => {
  return (
    <Text
      color={Colors.BlueyGrey}
      size={Styled.FontSize.F12}
      lineHeight={Styled.LineHeight.L18}
      weight={Styled.FontWeight.Medium}
    >
      Step {currentStep} of {totalSteps}
    </Text>
  );
};

export default StepProgressIndicator;
