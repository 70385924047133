import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import Button, { ButtonType } from "../Button";
import Dialog from "../Dialog";
import { Display, FlexDirection, Spacing } from "../v2/Styled/enum";

type Props = {
  open: boolean;
  onClose: () => void;
  bodyContent: React.ReactNode;
  titleMessage?: string;
  closeBtnTitle?: string;
};

const FlaggedWordDisclaimerModal = ({
  open,
  onClose,
  titleMessage,
  bodyContent,
  closeBtnTitle,
}: Props) => {
  const isMobile = useMobile();

  return (
    <Dialog open={open}>
      <Box
        width={isMobile ? "auto" : "330px"}
        padding={`${Spacing.S8} ${Spacing.S4} ${Spacing.S4}`}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
        style={{ rowGap: Spacing.S6 }}
      >
        <Typography
          variant="h3"
          style={{
            fontWeight: 700,
            color: Colors.Indigo,
            fontSize: "22px",
            textAlign: "center",
            lineHeight: "24.2px",
            padding: `0 ${Spacing.S2}`,
          }}
        >
          Action not allowed {titleMessage ? `: ${titleMessage}` : ""}
        </Typography>

        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          style={{ rowGap: Spacing.S4 }}
          color={Colors.BlueyGrey}
          fontWeight={500}
          textAlign="center"
          lineHeight="24px"
        >
          {bodyContent}

          <Typography>
            Failure to adhere to this policy can result in account suspension.
          </Typography>
        </Box>

        <Button
          title={closeBtnTitle || "Edit message"}
          type={ButtonType.outlined}
          onClick={onClose}
        />
      </Box>
    </Dialog>
  );
};

export default FlaggedWordDisclaimerModal;
