import { User } from "../../../models";
import { getValue } from "../../../utils/object";
import { capitalizeFirstLetter } from "../../../helpers/string";
import { PRO_TIER_LINK } from "../../../constants/prodashboard";
import { getFormatRespectToCountry, formatTime} from "../../../utils/date.util";
import { DEFAULT_DATE_FORMAT } from "../../../constants/date";

export interface ProPerformanceMatrix {
  title: string;
  value: string;
  link?: string;
  modalInfo?: {
    title: string;
    description: string;
    data?: Array<string>;
  };
}

const buildProPerformanceMatrix = (user: User): Array<ProPerformanceMatrix> => {
  const therapistProfile = getValue(user, "therapistprofile", {});
  const proScore = getValue(user, "proScore", null);
  // const userCountry = getValue(user, "country");

  if (!therapistProfile || !proScore) return [];

  return [
    {
      title: "Date joined:",
      value: formatTime(
        getValue(therapistProfile, "createdAt") || "",
        // getFormatRespectToCountry(userCountry),
        DEFAULT_DATE_FORMAT
      ),
    },
    {
      title: "Total bookings (All time):",
      value: getValue(proScore, "totalCompletedJobs") || 0,
      modalInfo: {
        title: "Total jobs",
        description: "<b>Total Jobs</b> is the number of jobs you completed since joining Blys. You get $100 store credit from Blys when you complete 100 bookings!",
      },
    },
    {
      title: "Total bookings (Last 30 days):",
      value: getValue(proScore, "recentCompletedJobsCount") || 0,
    },
    {
      title: "Completion rate:",
      value: `${parseFloat(getValue(proScore, "reliabilityRate") || 0).toFixed(1)}%`,
      modalInfo: {
        title: "Completion rate",
        description: "",
        data: [
          "<b>Completion rate</b> is a ratio of bookings that you completed after accepting. For example, abandoning a job would decrease your Completion Rate. Jobs that you decline don’t impact your Completion Rate.",
          "This number is based on the average of your last 50 bookings, and are updated every time you complete a new job.",
        ],
      },
    },
    {
      title: "Rebooking rate:",
      value: `${parseFloat(getValue(proScore, "reBookingRate") || 0).toFixed(1)}%`,
      modalInfo: {
        title: "Rebooking rate",
        description: "",
        data: [
          "<b>Rebooking rate</b> is the ratio of bookings you completed that were rebookings. The more rebookings you receive the higher your Rebooking Rate will be.",
          "This number is based on the average of your last 50 bookings, and are updated every time you complete a new job.",
        ],
      },
    },
    {
      title: "Account tier:",
      value: capitalizeFirstLetter(getValue(proScore, "proTier") || "Silvers"),
      link: PRO_TIER_LINK,
    },
  ];
};

export { buildProPerformanceMatrix };
