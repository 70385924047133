import React, { useRef, useState } from "react";
import { Box, InputBase } from "@material-ui/core";
import { checkIfEmpty, getValue } from "../../utils/object";
import "./ssn.css";
import Each from "../Each";
import { Dots } from "../Util";
import Text from "../v2/Styled/Text";
import * as Styled from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";
import { isMobile } from "react-device-detect";

interface Props {
  value: string;
  readonly?: boolean;
  onChange: (val: any) => unknown;
}

const SSNField = ({ value = "", onChange, readonly }: Props): JSX.Element => {
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = getValue(e, "target.value");
    value = (value || "").replace(/\D/g, ""); // remove non-numeric values
    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    onChange(value);
  };

  const formatValue = (value: string = "") => {
    if (typeof value !== "string") {
      return "";
    }
    const sections = [value.slice(0, 3), value.slice(3, 5), value.slice(5)].filter(Boolean);
    return sections.join("-");
  };

  const formattedValue = formatValue(value);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current?.focus();

      if (isMobile) {
        // scroll to bottom
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
      }
    }
  };

  const buildChar = (char: any) => {
    if (char === "-") return <div className={"ssn-dash"} />;
    return (
      <Dots n={1} style={{ width: "8px", height: "8px", backgroundColor: Colors.TurquoiseBlue }} />
    );
  };

  return (
    <Box className="ssn-input-container">
      <Box className="ssn-display" onClick={handleClick}>
        <Each of={formattedValue.split("")} render={buildChar} />
        {isFocus ? (
          <Box className={"cursor"} style={{ backgroundColor: Colors.TurquoiseBlue }} />
        ) : (
          <></>
        )}
        {checkIfEmpty(value) ? ( // Placeholder
          <Box className={"ssn-placeholder"}>
            <Text
              color={Colors.Grey}
              size={Styled.FontSize.F18}
              font={Styled.FontFamily.Museo}
              lineHeight={Styled.LineHeight.L27}
              className="unselectable"
            >
              123-45-6789
            </Text>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <InputBase
        type={"password"}
        inputRef={inputRef}
        disabled={readonly}
        value={formattedValue}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        placeholder={"000-00-0000"}
        style={{ opacity: 0, position: "absolute" }}
      />
    </Box>
  );
};

export default SSNField;
