import { get } from "../../../api/client";
import { useQuery } from "@tanstack/react-query";

export const IDENTITY_KEYS = {
  GOVERNMENT_ID: "GOVERNMENT_ID",
};

const useGovernmentDocuments = () =>
  useQuery([IDENTITY_KEYS.GOVERNMENT_ID], () =>
    get("api/v2/therapists/identity/government-documents")
  );

export { useGovernmentDocuments };
