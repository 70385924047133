import { useEffect, useRef, useState } from "react";
import { Box, Collapse, makeStyles } from "@material-ui/core";


import EditIcon from "../../../../images/pen.svg";
import DeleteIcon from "../../../../images/trashbin.svg";
import CaratUp from "../../../../images/carat-up.svg";
import CaratDown from "../../../../images/carat-down.svg";
import ProfilePlaceholder from "../../../../images/profile-pic-placeholder.png";

import { getValue } from "../../../../utils/object";
import {
  AlignItems,
  Cursor,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
} from "../../../../components/v2/Styled/enum";
import { Colors } from "../../../../constants/colors";
import { formatDateWithTimezone } from "../../../../utils/date.util";
import { DEFAULT_DATE_FORMAT } from "../../../../constants/date";
import { getFullUploadsUrl } from "../../../../utils/url";
import RoundedImage from "../../../../components/Image/RoundedImage";


interface Props {
  note: any;
  timezone: string;
  pro: any;
  allowActions?: boolean;
  collapsable?: boolean;
  onEditNote?: (note: any) => unknown;
  onDeleteNote?: () => unknown;
};


const styles = makeStyles({
  lineClamp2: {
    "textOverflow": "ellipsis",
    "display": "-webkit-box !important",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    "overflow": "hidden",
  },
});

const TreatmentNoteListCard = ({
  pro,
  note,
  timezone,
  allowActions = true,
  collapsable = true,
  onEditNote = () => {},
  onDeleteNote = () => {},
}: Props ) => {

  const [isExpanded, setExpanded] = useState(false);
  const [enableCollapse, setEnableCollapse] = useState(false);

  const noteText = getValue(note, "data.note");
  const noteRef = useRef<HTMLDivElement>(null);

  const checkIsClamped = () => {
    if (!noteRef.current) return false;
    return noteRef.current.scrollHeight > noteRef.current.clientHeight;
  };

  useEffect(() => {
    const isLongText = checkIsClamped(); 
    setEnableCollapse(isLongText);
  }, [noteRef]);
  
  const getNoteMetaData = () => {
    const noteDate = getValue(note, "updatedAt");
    const formattedDate = formatDateWithTimezone(noteDate, timezone, DEFAULT_DATE_FORMAT );
    let metaData = `${formattedDate}`;
    return metaData;
  };

  const getProfileImage = () => {
    const { profileImage } = pro;
    if (!profileImage) return ProfilePlaceholder;
    return getFullUploadsUrl(profileImage);
  };

  const handleNoteEdit = () => {
    if (!!onEditNote) onEditNote(note);
  };

  const onCollapseToggle = () => {
    setExpanded(!isExpanded);
  };

  const getNoteFiles = () => {
    return getValue(note, "data.files") || [];
  };
  
  const openImageURL = (url: string) => window.open(url, "_blank");

  const classes = styles();
  const noteMeta = getNoteMetaData();
  const profileImg = getProfileImage();

  const { firstName } = pro;
  const noteFiles: string[] = getNoteFiles();

  return <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4} padding={Spacing.S4} style={{ backgroundColor: "#F8F9FA", borderRadius: "8px" }}>
    <Box display={Display.Flex} flexDirection={FlexDirection.Row} justifyContent={JustifyContent.spaceBetween} alignItems={AlignItems.center}>
      <Box
        gridGap={Spacing.S2}
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
      >
        <img src={profileImg} height={"48px"} width={"48px"} style={{ borderRadius: "50%" }} alt="Therapist profile"/>
        <Box>
          <Box
            fontSize={FontSize.F16}
            fontWeight={FontWeight.Bold}
            color={Colors.DarkSteelGrey}
            fontFamily={FontFamily.Museo}
          >
            {firstName}
          </Box>
          <Box
            fontFamily={FontFamily.OpenSans}
            fontWeight={FontWeight.Medium}
            fontSize={FontSize.F14}
            color={Colors.Grey}
          >
            {noteMeta}
          </Box>
        </Box>
      </Box>
      {allowActions && (
        <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S2}>
          <img onClick={handleNoteEdit} src={EditIcon} alt="edit note" style={{ cursor: Cursor.pointer }}/>
          <img src={DeleteIcon} onClick={onDeleteNote} alt="delete note" style={{ cursor: Cursor.pointer }}/>
        </Box>
      )}
    </Box>

    {enableCollapse && collapsable ? <Collapse collapsedSize={"48px"} in={isExpanded}>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4}>
        <div
          ref={noteRef}
          style={{
            fontSize: FontSize.F16,
            color: Colors.DarkSteelGrey,
            fontWeight: FontWeight.Regular,
            fontFamily: FontFamily.OpenSans,
            lineHeight: "24px"
          }}
          className={isExpanded ? "" : classes.lineClamp2}
        >
          {noteText}
        </div>
        <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3}>
          {noteFiles.map((file: string) => <RoundedImage
            fileUrl={file}
            allowRemove={false}
            clickable
            onClick={() => openImageURL(file)}
          />)}
        </Box>
      </Box>
    </Collapse> : <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4}>
      <div
        ref={noteRef}
        style={{
          fontSize: FontSize.F16,
          color: Colors.DarkSteelGrey,
          fontWeight: FontWeight.Regular,
          fontFamily: FontFamily.OpenSans,
          lineHeight: "24px"
        }}
        className={isExpanded || !collapsable ? "" : classes.lineClamp2}
      >
        {noteText}
      </div>
      <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3}>
        {noteFiles.map((file: string) => <RoundedImage
          fileUrl={file}
          allowRemove={false}
          clickable
          onClick={() => openImageURL(file)}
        />)}
      </Box>
    </Box>}
    {enableCollapse && collapsable && (
      <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3} style={{ cursor: "pointer" }} onClick={onCollapseToggle}>
        <Box fontSize={FontSize.F16} fontWeight={FontWeight.Bold} fontFamily={FontFamily.Museo} color={Colors.TurquoiseBlue}>
          {isExpanded ? "Collapse" : "Expand"}
        </Box>
        {isExpanded ? <img src={CaratUp} alt="collapse" /> : <img src={CaratDown} alt="collapse" />}
      </Box>
    )}
  </Box>;
};

export default TreatmentNoteListCard;
