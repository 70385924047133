import React from "react";
import moment from "moment";
import { Box, BoxProps } from "@material-ui/core";
import { BillingSummary } from "../../models";
import { Colors } from "../../constants/colors";
import DownloadButton from "../../components/Reports/DownloadButton";
import { useMobile } from "../../hooks/mobile";
import { getFormatRespectToCountry } from "../../utils/date.util";
import { DEFAULT_DATE_FORMAT } from "../../constants/date";

interface Props {
  summary: BillingSummary;
  currency: string;
  country?: string;
}

interface MobileTitleValueProps {
  title: string;
  value: string;
}

function MobileTitleValue({ title, value }: MobileTitleValueProps) {
  return (
    <Box display="flex" flex={1} flexDirection="column" mb="16px">
      <Box
        fontFamily="Museo"
        fontSize="14px"
        fontWeight={400}
        color={Colors.BlueyGrey}
        flex={1}
      >
        {title}
      </Box>
      <Box
        fontFamily="Museo"
        fontSize="16px"
        lineHeight="24px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="8px"
      >
        {value}
      </Box>
    </Box>
  );
}

export default function ({ summary, currency, country = "AU" }: Props) {
  const isMobile = useMobile();

  const from = moment(summary.fromDate).format(DEFAULT_DATE_FORMAT);
  const to = moment(summary.toDate).format(DEFAULT_DATE_FORMAT);

  const onDownloadClicked = () => window.open(summary.file, "_blank");

  if (isMobile) {
    return (
      <Box pt="24px" pl="16px" pr="16px" pb="24px" display="flex" flexDirection="column">
        <MobileTitleValue title="Invoice" value={summary.invoiceNumber} />
        <MobileTitleValue title="Period" value={`${from} - ${to}`} />
        <MobileTitleValue title="Amount" value={`${summary.amount.toPriceString(currency)}`} />
        <Box display="flex" justifyContent="flex-start">
          <DownloadButton onClick={onDownloadClicked} />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      fontFamily="Museo"
      fontSize="16px"
      lineHeight="24px"
      fontWeight={400}
      color={Colors.Dusk}
      height="55px"
      pl="24px"
      pr="24px"
    >
      <Box width="10%">{summary.invoiceNumber}</Box>
      <Box width="30%">
        {from} - {to}
      </Box>
      <Box width="10%">{summary.amount.toPriceString(currency)}</Box>

      <Box
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <DownloadButton onClick={onDownloadClicked} />
      </Box>
    </Box>
  );
}
