import { useState } from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../stores/V2";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import TreatmentDropdownMenu from "../../components/Treatment/DropdownMenu";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import { checkIfEmpty, getValue } from "../../utils/object";
import BookingQuestions from "../../components/Bookings/V2/Questions/BookingQuestions";
import { DEFAULT_CURRENCY } from "../../constants/booking";
import AddOnDropdownMenu from "../../components/Bookings/V2/AddOn/DropdownMenu/AddOnDropdownMenu";
import { ServiceRatePrice, SessionTypes } from "../../models";
import {
  BOOKING_SESSION_TYPE,
  getTreatmentAddOnForConfirmedBooking,
  isMultipleAddOnAllowdForConfirmedBooking,
} from "../../helpers/booking";
import AlertModal from "../../components/AlertModal";
import { INFO_MESSAGES } from "../../constants/message";
import SessionTypeInfoModal from "../../components/SessionTypeInfoModal";

interface MassageProps {
  showOtherAddOnOption?: boolean;
}

export default function MassageDetails({ showOtherAddOnOption = false }: MassageProps) {
  const isMobile = useMobile();
  const dispatch = useDispatch();

  const state = useSelector(({ booking }: RootState) => booking);
  const { selectedService, bookingDetails, proAddOns, selectedServiceDuration } = state;

  const [infoModalText, setInfoModalText] = useState("");
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [showSessionInfo, setShowSessionInfo] = useState(false);

  const { duration: durations, prices: treatmentTypes, sessionTypes } = selectedService;

  const selectedSessionType = getValue(state, "selectedSessionType.value");
  const sessionTypeOptions = sessionTypes.map(({ title, value }: SessionTypes) => ({
    title,
    value,
  }));

  const genderPrefs = getValue(selectedService, "genderPreferences", []);
  const currency =
    getValue(selectedService, "selectedCountry.currencySymbol") || DEFAULT_CURRENCY.currencySymbol;
  const selectedCountry = getValue(selectedService, "selectedCountry", DEFAULT_CURRENCY);
  const getGenderPreference = (value: any) => {
    const genderPref = genderPrefs?.find((genderPref: any) => genderPref.value === value) || {};
    return genderPref.title || "";
  };

  const handleTreatmentTypeChange = (selectedIndex: any, val: any) => {
    dispatch(
      bookingActions.resetTreatmentAddOn({
        index: selectedIndex,
        treatmentId: val.id,
      })
    );
    dispatch(
      bookingActions.updateTreatmentDetails({ index: selectedIndex, selectedTreatment: val })
    );
  };

  const handleTreatmentAddOnChange = ({
    selectedIndex,
    addon,
    isMultipleAddonsAllowed,
  }: {
    selectedIndex: any;
    addon: any;
    isMultipleAddonsAllowed: boolean;
  }) => {
    dispatch(
      bookingActions.updateTreatmentAddOn({ index: selectedIndex, addon, isMultipleAddonsAllowed })
    );
  };

  const handleDurationChange = (selectedIndex: any, val: any) => {
    dispatch(
      bookingActions.updateTreatmentDetails({ index: selectedIndex, selectedDuration: val.value })
    );
  };

  const handleAnswerChange = ({ answer, questionId, treatmentIndex, answers }: any) => {
    dispatch(bookingActions.updateSelectedAnswer({ answer, treatmentIndex, questionId, answers }));
  };

  const getTreatmentDetails = (details: any) => {
    if (!details) return [];
    let treatments = details.map((detail: any) => {
      const { treatmentDetails } = detail;
      return treatmentDetails;
    });
    treatments = treatments.flat();
    return treatments.map((t: any) => ({
      ...t,
      genderTitle: getGenderPreference(t.genderPreference),
    }));
  };

  const bookingTreatmentDetails = getTreatmentDetails(bookingDetails);
  const serviceQuestions = getValue(bookingTreatmentDetails[0], "questions", []).filter(
    (question: any) => !question.treatmentId
  );

  const handleAddOnSelect = ({
    treatment,
    treatmentIndex,
    isMultipleAddonsAllowed,
  }: {
    treatment: ServiceRatePrice;
    treatmentIndex: number;
    isMultipleAddonsAllowed: boolean;
  }) => {
    handleTreatmentAddOnChange({
      selectedIndex: treatmentIndex,
      addon: treatment,
      isMultipleAddonsAllowed,
    });
  };

  const handleNoneSelect = (treatmentIndex: number) => {
    dispatch(
      bookingActions.resetTreatmentAddOn({
        index: treatmentIndex,
      })
    );
  };

  const validateSessionChange = (sessionType: string) => {
    let isValid = true;
    let message;
    if (
      selectedSessionType === BOOKING_SESSION_TYPE.COUPLES ||
      sessionType === BOOKING_SESSION_TYPE.COUPLES
    ) {
      isValid = false;
      message = INFO_MESSAGES.couplesSessionUpdate;
    }

    return { isValid, message };
  };

  const onSessionTypeChange = (option: DropdownOption) => {
    const { value: sessionType } = option;
    if (sessionType === selectedSessionType) return;

    const { isValid, message } = validateSessionChange(sessionType);
    if (!isValid) {
      if (message) {
        setInfoModalText(INFO_MESSAGES.couplesSessionUpdate);
        setInfoModalVisible(true);
      }
      return;
    }

    dispatch(bookingActions.updateSessionType({ sessionType }));
  };

  const treatmentDetailsLength = bookingTreatmentDetails?.length || 0;

  return (
    <Box display="flex" flexDirection="column" alignItems="start" width={"100%"}>
      <Box display="flex" flexDirection="column" width={isMobile ? "100%" : "50%"}>
        <Dropdown
          title="Session type"
          options={sessionTypeOptions}
          selectedOption={sessionTypeOptions.find(({ value }) => value === selectedSessionType)}
          onSelectedOption={onSessionTypeChange}
          onInfoClicked={() => {
            setShowSessionInfo(true);
          }}
        />
        {bookingTreatmentDetails.map((details: any, index: number) => {
          details.questions = details.questions.filter((q: any) => q.treatmentId);
          return (
            <Box key={index}>
              <Box
                fontFamily="Museo"
                fontSize="20px"
                color={Colors.Dusk}
                fontWeight={700}
                flex={1}
                textAlign="left"
              >
                {treatmentDetailsLength > 1 && `Person ${index + 1}`}
              </Box>
              <Box>
                <TreatmentDropdownMenu
                  value={details.value}
                  label="Treatment type"
                  treatments={treatmentTypes}
                  onSelect={(val) => handleTreatmentTypeChange(index, val)}
                  selectedDuration={selectedServiceDuration[index]}
                />

                <Box width="60px" />

                <Dropdown
                  title={"Duration"}
                  options={durations}
                  selectedOption={durations.find(
                    (option: any) => option.value === details.serviceDuration
                  )}
                  onSelectedOption={(option) => handleDurationChange(index, option)}
                />

                {(!checkIfEmpty(
                  getTreatmentAddOnForConfirmedBooking({
                    selectedTreatmentId: details.id,
                    treatmentsList: treatmentTypes,
                    proAddOns: proAddOns,
                    selectedCountry,
                  })
                ) ||
                  showOtherAddOnOption) && (
                  <AddOnDropdownMenu
                    value={details.value}
                    label="Treatment add-ons"
                    treatments={getTreatmentAddOnForConfirmedBooking({
                      selectedTreatmentId: details.id,
                      treatmentsList: treatmentTypes,
                      proAddOns: proAddOns,
                      selectedCountry,
                    })}
                    onSelect={handleAddOnSelect}
                    treatmentIndex={index}
                    isMultipleAddonsAllowed={isMultipleAddOnAllowdForConfirmedBooking({
                      selectedTreatmentId: details.id,
                      treatmentsList: treatmentTypes,
                    })}
                    selectedAddOn={details?.addons || []}
                    showOtherAddOnOption={showOtherAddOnOption}
                    onNoneSelect={handleNoneSelect}
                  />
                )}

                <BookingQuestions
                  index={index}
                  questions={details.questions}
                  setAnswer={handleAnswerChange}
                  treatmentId={details.id}
                  currency={currency}
                />
              </Box>
              {index + 1 !== bookingTreatmentDetails.length ? (
                <Box marginBottom={"32px"} />
              ) : (
                <Box />
              )}
            </Box>
          );
        })}

        <BookingQuestions
          index={0}
          questions={[...serviceQuestions]}
          setAnswer={handleAnswerChange}
          currency={currency}
        />
        <Box marginBottom={"32px"} />
      </Box>
      <AlertModal
        title="Session Type"
        message={infoModalText}
        open={infoModalVisible}
        onClose={() => setInfoModalVisible(false)}
      />
      <SessionTypeInfoModal open={showSessionInfo} onClose={() => setShowSessionInfo(false)} />
    </Box>
  );
}
