import React from "react";
import { useHistory } from "react-router-dom";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import { Box } from "@material-ui/core";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";
import { Paths } from "../../../../constants/paths";
import { useMobile } from "../../../../hooks/mobile";


interface Props {
  visible: boolean;
  onClose: () => unknown;
}

const UpdateServiceModal = ({ visible, onClose }: Props) => {
  const history = useHistory();
  const isMobile = useMobile();

  return (
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      PaperProps={{ style: { overflowY: "visible" } }}
      title="Update your services"
      description="Update your services from your Account page so you don't get notified about jobs for this specific service type anymore."
      titleStyle={{ paddingTop: "24px" }}
      descriptionStyle={{ paddingLeft: "8px", paddingRight: "8px", textAlign: "center", paddingBottom: "16px" }}
      textStyle={{ fontFamily: "Open Sans", fontWeight: "400" }}
      actions={
        [
          <Box
            style={{
              flex: 1,
              gap: 8,
              paddingBottom: 32,
              paddingRight: 16,
              paddingLeft: 16
            }}
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
          >
            <Button title="Cancel" type={ButtonType.outlined} onClick={onClose}
              style={{ padding: "12px 16px" }}
            />
            <Button
              title="Update"
              type={ButtonType.secondary}
              onClick={() => { history.replace(Paths.ProDashboardServices); }}
              style={{ padding: "12px 16px" }}
            />
          </Box >
        ]}
    />
  );
};

export default UpdateServiceModal;