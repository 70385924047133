interface PROVIDER_OPTION_TYPE {
  title: string;
  label?: string;
  subtitle: string;
  value: (typeof PROVIDER_OPTIONS_VALUE)[keyof typeof PROVIDER_OPTIONS_VALUE];
}

const PROVIDER_OPTIONS_VALUE = {
  NO_PROVIDERS: "noProviders",
  RECENT_PROVIDERS: "recentProviders",
  RECOMMENDED_PROVIDERS: "recommendedProviders",
} as const;

const PROVIDER_OPTIONS: Array<PROVIDER_OPTION_TYPE> = [
  {
    title: "First available",
    label: "Fastest way to get your booking confirmed",
    subtitle: "We'll send your booking request to all available providers in your area.",
    value: PROVIDER_OPTIONS_VALUE.NO_PROVIDERS,
  },
  {
    title: "Rebook a previous provider",
    subtitle: "Pick a provider from your favorites list.",
    value: PROVIDER_OPTIONS_VALUE.RECENT_PROVIDERS,
  },
  {
    title: "Top-rated providers in your area",
    subtitle: "Pick a provider who has been highly rated by other clients in your area.",
    value: PROVIDER_OPTIONS_VALUE.RECOMMENDED_PROVIDERS,
  },
];

const DEFAULT_PROVIDER_OPTION =
  PROVIDER_OPTIONS.find(({ value }) => value === PROVIDER_OPTIONS_VALUE.NO_PROVIDERS) ||
  PROVIDER_OPTIONS[0];

export { PROVIDER_OPTIONS, PROVIDER_OPTIONS_VALUE, DEFAULT_PROVIDER_OPTION };
export type { PROVIDER_OPTION_TYPE };
