import { isEmpty, isEqual } from "lodash";

import { GenderPreference } from "../../../constants/booking";
import { BOOKING_PRICE_MASTER_TOKEN } from "../../../constants/common";
import { DURATION_PER_PERSON, EVENT_TYPE } from "../../../data/corporate.options";
import { getAccessToken } from "../../../helpers/accessToken";
import {
  BOOKING_SESSION_TYPE,
  durationAndTimeRangeForBooking,
  formatTreatmentAddOnData,
  timeDifferenceForBooking,
} from "../../../helpers/booking";
import { isCorporateSession } from "../../../helpers/massage";
import { capitalizeFirstLetter } from "../../../helpers/string";
import { formatMinutesToDisplayTime } from "../../../helpers/time";
import { Booking, BookingDetails, ServiceRate } from "../../../models";
import { fetchBookingPrice } from "../../../services/bookings/bookings.service";
import { isArray } from "../../../utils/array";
import { convertToMoment, getDateStringInMinutes, isSameMoment } from "../../../utils/date.util";
import { checkIfEmpty, checkIfKeyExist, getValue } from "../../../utils/object";
import { CorporateFields, getAllQuestions } from "../../booking";
import { TreatmentDetail } from "./interface";

const transformQuestionAnswers = (selectedQuestions = [], answers = []) => {
  const questions = isArray(selectedQuestions as []) ? [...selectedQuestions] : [selectedQuestions];

  const formattedQuestions = questions.map((question: any) => {
    const selectedQuestion = { ...question } as any;
    let selectedAnswer = answers.find(
      (answer: any) => answer.questionId === selectedQuestion.id
    ) as any;
    if (!selectedAnswer) {
      const defaultAnswer = getValue(selectedQuestion, "answerDetails[0]");
      if (defaultAnswer && selectedQuestion.required) {
        selectedAnswer = {
          type: question.type,
          questionId: question.id,
          answer: defaultAnswer.value,
          answers: defaultAnswer.answers,
        };
      } else {
        selectedAnswer = {
          type: question.type,
          questionId: question.id,
          answer: null,
          answers: null,
        };
      }
    } else {
      selectedAnswer = {
        type: selectedAnswer.type,
        questionId: selectedAnswer.questionId,
        answer: selectedAnswer.answerValue || selectedAnswer.answer,
        answers: selectedAnswer.answers,
      };
    }
    selectedQuestion.answer = selectedAnswer;
    return selectedQuestion;
  });

  return formattedQuestions;
};

// returns treatment + service questions
const getQuestionsForTreatment = ({
  treatmentIndex,
  selectedService,
  treatmentId,
  questions,
  answers = [],
}: any) => {
  let serviceMatchingQuestions = questions.find(
    (question: any) => question.service === capitalizeFirstLetter(selectedService.name)
  );
  if (!serviceMatchingQuestions) serviceMatchingQuestions = { questions: [] };

  serviceMatchingQuestions.questions = serviceMatchingQuestions.questions.filter(
    (question: any) => question.serviceId === selectedService.id
  );

  let serviceQuestions = [];
  if (treatmentIndex === 0) {
    serviceQuestions = serviceMatchingQuestions.questions.filter(
      (question: any) => !question.treatmentId
    );
  }

  const treatmentQuestions = serviceMatchingQuestions.questions.filter(
    (question: any) => question.treatmentId === treatmentId
  );

  let questionsWithAnswer = transformQuestionAnswers(treatmentQuestions, answers);

  // if service questions, insert it in index 0
  const serviceQuestionsWithAnswer = transformQuestionAnswers(serviceQuestions, answers);
  questionsWithAnswer = [...questionsWithAnswer, ...serviceQuestionsWithAnswer];
  return questionsWithAnswer;
};

const getSelectedServiceDurations = (bookingDetails: any) => {
  if (!bookingDetails) return [];

  let serviceDurations: any = [];
  bookingDetails.forEach((detail: any) => {
    const { treatmentDetails } = detail;
    let treatmentDurations = [];
    if (Array.isArray(treatmentDetails)) {
      treatmentDurations = treatmentDetails.map(
        ({
          serviceDuration: duration,
          treatmentDuration,
        }: {
          serviceDuration: number;
          treatmentDuration: number;
        }) => treatmentDuration || duration
      );
    } else {
      treatmentDurations = [treatmentDetails.serviceDuration];
    }
    serviceDurations = [...serviceDurations, ...treatmentDurations];
  });
  return serviceDurations;
};

const transformBookingData = (
  booking: Booking,
  allServices: ServiceRate[],
  questions: any,
  addons: any = {}
) => {
  const {
    id,
    title,
    price,
    coupon,
    status,
    address,
    channel,
    isMassage,
    recipient,
    serviceId,
    credituses,
    sessionType,
    peakHourInfo,
    parkingAndTravelInfo,
    originalPrice,
    addressSurcharge,
    hotelSurcharge,
    bookingUpdates,
    bookinganswers,
    cancellationFee,
    platformFeeData,
    clientPlatformFee,
    lateNightSurcharge,
    specialInstructions,
    instructions,
    isPlatformFeeApplied,
    priceWithoutDiscount,
    publicHolidaySurcharge,
    paymentmethod,
    paymentmethodId,
    afterpayOrderToken,
    braintreeTransactionId,
    isManual,
    isActuallyPaid,
    afterpayInitiatedAt,
    isPaid,
    isHomeCare,
    corporateInfo,
    bookingLink,
    recurring,
    recuringEndOn,
    paymentType,
    serviceFee,
    userId,
    isHairAndMakeup,
    totalAmountDue,
    totalPaid,
    isB2B,
    uuid,
    numberOfClients,
    preferredTherapists,
  } = booking;

  const { addons: proAddOns } = addons;

  const isCoporateType = isCorporateSession(sessionType);

  const selectedService = allServices.find((service) => service.id === serviceId);

  const subtitle = `${durationAndTimeRangeForBooking(booking)} - ${timeDifferenceForBooking(
    booking
  )}`;

  const data: any = {
    id,
    title,
    coupon,
    status,
    address,
    subtitle,
    questions,
    channel,
    credituses,
    peakHourInfo,
    parkingAndTravelInfo,
    bookingUpdates,
    cancellationFee,
    selectedService,
    platformFeeData,
    specialInstructions,
    instructions,
    isPlatformFeeApplied,
    selectedServiceDuration: [],
    selectedRecipient: recipient,
    paymentmethod,
    paymentmethodId,
    afterpayOrderToken,
    braintreeTransactionId,
    isManual,
    isHomeCare,
    isActuallyPaid,
    afterpayInitiatedAt,
    isPaid,
    recurring,
    recuringEndOn,
    isB2B,
    paymentType,
    serviceFee,
    userId,
    isHairAndMakeup,
    totalAmountDue,
    totalPaid,
    numberOfClients,
    preferredTherapists,
    canOpenChat: booking.canOpenChat,
    conversationId: booking.conversationId,
  };

  if (isMassage && selectedService) {
    const { sessionTypes } = selectedService;
    if (sessionTypes) {
      const selectedSessionType = sessionTypes.find((session) => session.value === sessionType);
      data.selectedSessionType = selectedSessionType;
    }

    const { bookingdetails } = booking;

    data.bookingDetails = bookingdetails
      .map((details, index) => {
        if (!details) return null;

        const jobId = getValue(details, "job.id");
        const {
          id: bookingDetailId,
          massageType,
          serviceDuration,
          deliveryMethod,
          genderPreference,
          booking: bookingDetails,
          treatmentDetails,
        } = details;

        let title = bookingDetails.title;

        let treatments: any = [];
        if (treatmentDetails && treatmentDetails.length > 0) {
          treatments = treatmentDetails.map((treatmentDetail) => {
            const { prices } = selectedService;
            const {
              bookingAnswers,
              massageType: treatmentMassageType,
              serviceDuration,
              treatmentDuration,
              addons,
              treatment,
              serviceFee,
            } = treatmentDetail;

            let answers: any = [];
            if (bookingAnswers && bookingAnswers.length > 0)
              answers = [...answers, ...bookingAnswers];

            const tQuestions = getQuestionsForTreatment({
              treatmentIndex: index,
              answers,
              questions,
              selectedService,
              treatmentId: treatmentDetail.treatmentId,
            });

            const selectedTreatment = prices.find(({ name }) => name === treatmentMassageType);
            return {
              price: getValue(treatmentDetail, "price", 0),
              id: treatmentDetail.treatmentTypeId,
              jobId,
              title: getValue(selectedTreatment, "label", "-"),
              value: treatmentMassageType,
              serviceDuration,
              treatmentDuration,
              deliveryMethod,
              genderPreference,
              questions: tQuestions,
              addons,
              treatment,
              serviceFee,
            };
          });
        } else {
          const { prices } = selectedService;
          const selectedTreatment = prices.find(({ name }) => name === massageType);

          title = selectedTreatment ? `Massage | ${getValue(selectedTreatment, "label", "")}` : "-";
          // other massage bookings
          treatments = [
            {
              id: selectedTreatment?.id,
              jobId,
              title,
              deliveryMethod,
              serviceDuration,
              genderPreference,
              value: massageType,
              addons: [],
            },
          ];
        }

        const agreements = getValue(details, "job.agreement") || [];
        const providersDocuments = getValue(details, "job.providerDocument") || [];
        return {
          jobId,
          id,
          title,
          agreements,
          bookingDetailId,
          genderPreference,
          providersDocuments,
          treatmentDetails: treatments,
          status: getValue(details, "job.status"),
          therapist: getValue(details, "job.user"),
        };
      })
      .filter((item) => item != null);
    data.selectedServiceDuration = getSelectedServiceDurations(data.bookingDetails);
  } else {
    const { bookingdetails } = booking;
    data.bookingDetails = bookingdetails
      .map((details, index) => {
        if (!details) return null;
        const jobId = getValue(details, "job.id");
        const serviceDuration = getValue(details, "serviceDuration", null);

        const { treatmentDetails, massageType, id: bookingDetailId, genderPreference } = details;
        if (treatmentDetails && treatmentDetails.length > 0) {
          const treatments: any = treatmentDetails.map((treatmentDetail: any) => {
            const {
              treatment,
              bookingAnswers,
              treatmentDuration,
              addons,
              serviceFee,
              noteForProvider,
            } = treatmentDetail;
            const { id, name, label, deliveryMethod, duration: serviceDuration } = treatment;
            const treatmentData: any = {
              id,
              jobId,
              value: name,
              title: label,
              deliveryMethod,
              addons,
              treatmentDuration,
              serviceDuration,
              serviceFee,
              noteForProvider,
            };

            // for hairAndMakeUp treatments - booking answers in treatmentDetails
            let answers: any = [];
            if (bookingAnswers && bookingAnswers.length > 0)
              answers = [...answers, ...bookingAnswers];

            // for others,  booking answers from booking object
            if (bookinganswers && bookinganswers.length > 0)
              answers = [...answers, ...bookinganswers];

            // treatmentData.bookinganswers = answers;
            if (answers?.length > 0) {
              treatmentData.questions = getQuestionsForTreatment({
                treatmentIndex: index,
                answers,
                questions,
                selectedService,
                treatmentId: id,
              });
            } else {
              treatmentData.questions = [];
            }

            return treatmentData;
          });

          const agreements = getValue(details, "job.agreement") || [];
          const providersDocuments = getValue(details, "job.providerDocument") || [];
          return {
            jobId,
            agreements,
            bookingDetailId,
            title: massageType,
            providersDocuments,
            genderPreference,
            treatmentDetails: treatments,
            serviceDuration,
            status: getValue(details, "job.status"),
            therapist: getValue(details, "job.user"),
          };
        }
        return null;
      })
      .filter((detail) => detail != null);
  }

  if (isCoporateType && corporateInfo) {
    data.corporateInfo = corporateInfo;
    data.bookingLink = bookingLink;
    data.uuid = uuid;
  }

  data.sessionType = sessionType;
  const creditUsed =
    credituses && credituses.length > 0
      ? credituses.reduce((acc, { amount }) => acc + amount, 0)
      : 0;
  const creditBeforeConversion =
    credituses && credituses.length > 0
      ? credituses.reduce((acc, { originalAmount }) => acc + originalAmount, 0)
      : 0;
  data.bookingPrice = {
    price,
    originalPrice,
    addressSurcharge,
    hotelSurcharge,
    clientPlatformFee,
    credit: creditUsed,
    creditBeforeConversion,
    lateNightSurcharge,
    priceWithoutDiscount,
    publicHolidaySurcharge,
  };

  data.existingBooking = {};
  data.existingBooking.timeOfArrival = booking.timeOfArrival;
  data.existingBooking.recurring = booking.recurring;
  data.existingBooking.recuringEndOn = booking?.recuringEndOn;
  data.existingBooking.bookingDetails = [...data.bookingDetails];

  const addressTimezone = getValue(address, "timezone", booking.timezone);

  // earliest and latest booking time
  data.selectedDateMoment = convertToMoment(
    booking.timeOfArrival || booking.earliestTime,
    addressTimezone
  );

  data.selectedEarliestTimeInMinutes = getDateStringInMinutes(
    booking.earliestTime,
    addressTimezone
  );
  data.selectedLatestTimeInMinutes = getDateStringInMinutes(booking.latestTime, addressTimezone);

  data.timeOfArrival = convertToMoment(booking.timeOfArrival, addressTimezone);
  data.timezone = addressTimezone || booking.timezone;
  data.proAddOns = proAddOns || [];

  return data;
};

const getNonMassageSessionType = (bookingDetails: any = []) => {
  let treatments: any = [];
  bookingDetails.forEach((details: BookingDetails) => {
    const treatmentDetails = getValue(details, "treatmentDetails", []);
    treatments = [...treatments, ...treatmentDetails];
  });
  const sessionTypes = treatments.map((treatment: TreatmentDetail) => treatment.value);
  return sessionTypes.join(",");
};

const getAnswerParams = (bookingDetails: any = []) => {
  const answerParams: any = [];
  bookingDetails.forEach((detail: any) => {
    const { treatmentDetails } = detail;
    if (treatmentDetails && treatmentDetails.length > 0) {
      treatmentDetails.forEach((treatment: any, index: number) => {
        if (!answerParams[index]) answerParams[index] = [];
        const { questions } = treatment;
        if (questions)
          questions.map(({ answer, id }: any) => {
            answerParams[index].push({ [id]: answer.answer });
          });
      });
    }
  });
  return answerParams;
};

const getBookingPrice = async (booking: any) => {
  try {
    if (!booking) return null;

    const {
      coupon,
      address,
      timezone,
      paymentType,
      id: bookingId,
      timeOfArrival,
      bookingDetails,
      selectedService,
      selectedSessionType,
      isPlatformFeeApplied,
      sessionType: bookingSessionType,
    } = booking;

    const accessToken = getAccessToken();
    const { isHairAndMakeup, isMassage } = selectedService;

    let params: any = {
      timezone,
      bookingId,
      paymentType,
      isPlatformFeeApplied,
      sessionType: "singles",
      addressId: address?.id,
      couponCode: coupon?.code,
      typeOfLocation: address?.type,
      earliestTime: timeOfArrival.format(),
      latestTime: timeOfArrival.format(),
      accessToken: accessToken || BOOKING_PRICE_MASTER_TOKEN,
    };

    let treatments = bookingDetails.map((detail: any) => {
      const { treatmentDetails } = detail;
      return treatmentDetails;
    });
    treatments = treatments.flat();

    if (isMassage) {
      params.treatmentDetails = treatments.map(({ id, serviceDuration, addons }: any) => ({
        duration: serviceDuration,
        treatmentTypeId: id,
        addons: formatTreatmentAddOnData(addons),
      }));
    }

    const isCorporateMassage = isCorporateSession(bookingSessionType);

    let sessionType: any = "";
    if (isHairAndMakeup || !isMassage) {
      sessionType = getNonMassageSessionType(bookingDetails);
      params.treatmentDetails = treatments.map(({ id, addons }: any) => ({
        treatmentTypeId: id,
        addons: formatTreatmentAddOnData(addons),
      }));
    } else if (isCorporateMassage) {
      sessionType = BOOKING_SESSION_TYPE.CORPORATE;
    } else {
      sessionType = getValue(selectedSessionType, "value") || "singles";
    }

    params.bookingQuestions = getAnswerParams(bookingDetails);
    params.sessionType = sessionType;

    const corporateInfo = getValue(booking, "corporateInfo") || null;
    if (isCorporateMassage && !isEmpty(corporateInfo)) {
      const corporateMassageType = getValue(treatments[0], "treatment.name");
      const { numberOfPerson, numberOfPros, durationPerPerson, totalEventDuration } = corporateInfo;

      params = {
        ...params,
        numberOfPerson,
        massageType: corporateMassageType,
        numberOfTherapists: numberOfPros,
        durationPerPerson,
        massageDuration: totalEventDuration,
      };
    }

    const price = await fetchBookingPrice(params);
    price.clientPlatformFee = getValue(price, "clientPlatformFee.amount", 0);
    price.originalPrice = getValue(price, "subtotal", 0);

    return price;
  } catch (err) {
    return null;
  }
};

// transform booking Questions
const getAllBookingQuestions = async () => {
  try {
    const response = (await getAllQuestions()) as any;

    if (!response || !response.data) return [];

    const serviceQuestions: any = {};

    response.data.map((service: any) => {
      const serviceName = capitalizeFirstLetter(service.name);
      serviceQuestions[serviceName] = {
        service: serviceName,
        questions: [
          ...(serviceQuestions[serviceName]?.questions || []),
          ...service.bookingquestions,
        ],
      };
    });

    const bookingQuestions = Object.values(serviceQuestions);
    return bookingQuestions;
  } catch (err) {
    return [];
  }
};

const checkHasTreatmentDetailUpdated = (oldDetails: any = [], newDetails: any = []) => {
  const oldLength = (oldDetails || []).length;
  const newLength = (newDetails || []).length;
  if (oldLength !== newLength) return true;

  for (let i = 0; i < oldLength; i += 1) {
    const { addons, ...treatmentDetail } = newDetails[i];
    let { addons: oldAddons, ...oldTreatmentDetail } = oldDetails[i];

    if (!isEqual(treatmentDetail, oldTreatmentDetail)) return true;

    const newAddons = (addons || []).map(({ id }: any) => id).sort((a: number, b: number) => a - b);
    oldAddons = (oldAddons || [])
      .map(({ id, addonId }: any) => addonId || id)
      .sort((a: number, b: number) => a - b);

    if (!isEqual(oldAddons, newAddons)) return true;
  }

  return false;
};

const transformUpdatedData = (updatedData: any, existingBooking: any) => {
  const updatedState: any = {};

  if (getValue(updatedData, "serviceId") !== existingBooking.serviceId) {
    updatedState.serviceId = updatedData.serviceId;
  }

  if (getValue(updatedData, "sessionType") !== existingBooking.sessionType) {
    updatedState.sessionType = updatedData.sessionType;
  }

  const updatedArrivalTime = getValue(updatedData, "timeOfArrival");
  if (updatedArrivalTime && updatedArrivalTime !== existingBooking.timeOfArrival) {
    updatedState.timeOfArrival = updatedArrivalTime;
  }

  const bookingRecurring = getValue(updatedData, "recurring");
  const recurringEndOn = getValue(updatedData, "recuringEndOn");

  if (bookingRecurring && bookingRecurring.frequency !== existingBooking.recurring?.frequency) {
    updatedState.frequency = bookingRecurring.frequency;
    updatedState.recuringEndOn = existingBooking?.recuringEndOn;
    updatedState.isSameProvider = bookingRecurring.isSameProvider;
  }

  if (
    !checkIfEmpty(bookingRecurring) &&
    (!isEqual(bookingRecurring?.monthly, existingBooking?.recurring?.monthly) ||
      bookingRecurring?.type !== existingBooking?.recurring?.type ||
      bookingRecurring?.shouldCreateFutureBookings !==
        existingBooking?.recurring?.shouldCreateFutureBookings)
  ) {
    updatedState.frequency = bookingRecurring.frequency;
    updatedState.recuringEndOn = existingBooking?.recuringEndOn;
    updatedState.recurring = bookingRecurring;
    updatedState.isSameProvider = bookingRecurring.isSameProvider;
  }

  if (getValue(updatedData, "preferredTherapists") !== existingBooking.preferredTherapists) {
    updatedState.preferredTherapists = updatedData.preferredTherapists;
  }

  const updatedOccurrence =
    !!recurringEndOn &&
    (recurringEndOn.endsOn !== existingBooking?.recuringEndOn?.endsOn ||
      recurringEndOn.occurrence !== existingBooking?.recuringEndOn?.occurrence ||
      recurringEndOn.type !== existingBooking?.recuringEndOn?.type);

  if (updatedOccurrence) {
    updatedState.frequency = bookingRecurring.frequency;
    updatedState.recuringEndOn = recurringEndOn;
    updatedState.recurring = bookingRecurring;
    updatedState.isSameProvider = bookingRecurring.isSameProvider;
  }

  if (
    bookingRecurring &&
    bookingRecurring.isSameProvider !== existingBooking.recurring?.isSameProvider
  ) {
    updatedState.isSameProvider = bookingRecurring.isSameProvider;
  }

  const updatedLocation = getValue(updatedData, "address");
  const updatedAddressId = getValue(updatedLocation, "id");
  const existingBookingAddressId = getValue(existingBooking, "address.id");
  const updatedRecipient = getValue(updatedData, "selectedRecipient");
  const existingBookingRecipient = getValue(existingBooking, "selectedRecipient");

  if (updatedAddressId && updatedAddressId !== existingBookingAddressId) {
    updatedState.addressId = updatedAddressId;
  }

  const updatedRecipientId = getValue(updatedRecipient, "id");
  const existingBookingRecipientId = getValue(existingBookingRecipient, "id");

  const recipientKeyExist = checkIfKeyExist(updatedData, "selectedRecipient");

  if (recipientKeyExist && updatedRecipientId !== existingBookingRecipientId) {
    const mySelf = !getValue(updatedRecipient, "id");

    updatedState.recipient = {
      mySelf: mySelf,
      recipientId: getValue(updatedRecipient, "id"),
    };
  }

  const treatmentDetailsChanged = !isEqual(
    existingBooking.bookingDetails,
    updatedData.bookingDetails
  );

  if (
    treatmentDetailsChanged &&
    updatedData.bookingDetails &&
    updatedData.bookingDetails.length > 0
  ) {
    const treatments = updatedData.bookingDetails
      .map(({ treatmentDetails, bookingDetailId, jobId }: any, index: number) => {
        const existingJob = existingBooking.bookingDetails[index];

        const treatmentDetailUpdated = checkHasTreatmentDetailUpdated(
          existingJob.treatmentDetails,
          treatmentDetails
        );

        if (!treatmentDetailUpdated) return null;

        const details = treatmentDetails.map((treatment: any) => {
          const { questions, value, id, serviceDuration, addons } = treatment;
          let formattedAnswers = [];
          if (questions && questions.length > 0) {
            formattedAnswers = questions.map(({ answer: questionAnswer, id }: any) => {
              const { answer, answers, type } = questionAnswer;
              return {
                type,
                answer,
                answers,
                questionId: id,
              };
            });
          }
          return {
            jobId,
            serviceDuration,
            massageType: value,
            treatmentTypeId: id,
            detailId: bookingDetailId,
            bookingAnswers: formattedAnswers,
            addons: formatTreatmentAddOnData(addons),
          };
        });
        return details;
      })
      .filter((item: any) => item != null);
    const formattedDetails = treatments.flat();

    if (!checkIfEmpty(formattedDetails)) {
      updatedState.treatmentDetails = formattedDetails;
    }
  }
  return updatedState;
};

const transformPriceForAlternativeOffer = (prices: any) => {
  const surcharge =
    prices.addressSurcharge +
    prices.hotelSurcharge +
    prices.lateNightSurcharge +
    prices.publicHolidaySurcharge;
  const couponAmount =
    getValue(prices, "coupon.type") === "percent"
      ? (prices.priceWithoutDiscount - prices.clientPlatformFee.amount) *
        (prices.coupon.value / 100)
      : getValue(prices, "coupon.value", null);
  return {
    credit: prices.credit,
    price: prices.price,
    surcharge: surcharge,
    subtotal: prices.subtotal,
    processingFee: prices.clientPlatformFee.amount,
    coupon: {
      code: getValue(prices, "coupon.code", null),
      amount: couponAmount,
    },
    currency: {
      symbol: prices.currencySymbol,
    },
    peakHourInfo: {
      title: prices.peakHourInfo.title,
      description: prices.peakHourInfo.subtitle,
      data: prices.peakHourInfo.data,
    },
    processingFeeInfo: {
      title: prices.clientPlatformFee.title,
      description: prices.clientPlatformFee.remarks,
    },
  };
};

// get clid from different platform
const getClid = (source: any) => {
  const gclid = getValue(source, "gclid", "");
  const fbclid = getValue(source, "fbclid", "");
  return gclid || fbclid;
};


const transformSourceDetails = (source: any) => ({
  source: getValue(source, "utm_source", ""),
  medium: getValue(source, "utm_medium", ""),
  campaign: getValue(source, "utm_campaign", ""),
  content: getValue(source, "utm_content", ""),
  clid: getClid(source),
  // query
  query: JSON.stringify(source),
  countryCode: getValue(source, "country", ""),
});

const transformTreatmentDetailsForUpdate = (treatmentDetails: any) =>
  treatmentDetails.map((t: any) => ({
    bookingAnswers: t.bookingAnswers,
    clientImages: t.clientImages,
    currentColour: t.currentColour,
    desiredColour: t.desiredColour,
    referenceImages: t.referenceImages,
    treatmentFor: t.treatmentFor,
    treatmentTypeId: t.treatmentId,
    noteForProvider: t.noteForProvider,
    addons: formatTreatmentAddOnData(t?.addons || []),
  }));

const transformCorporateForDescription = (
  corporateDetails: CorporateFields | undefined | null,
  durationOptions = DURATION_PER_PERSON,
  eventOptions = EVENT_TYPE
) => {
  if (!corporateDetails) return [];
  return [
    {
      key: "totalDurationInMins",
      label: "",
      value: corporateDetails?.totalEventDuration
        ? `${corporateDetails?.totalEventDuration?.massageDuration} mins`
        : "",
    },
    {
      key: "numberOfPerson",
      label: "Number of people:",
      value: corporateDetails.numberOfPerson,
    },
    {
      key: "durationPerPerson",
      label: "Duration per person:",
      value: durationOptions.find((item: any) => item.value === corporateDetails.durationPerPerson)
        ?.title,
    },
    {
      key: "numberOfPros",
      label: "Number of providers:",
      value: corporateDetails.numberOfPros,
    },
    {
      key: "providerGenderPreference",
      label: "Provider gender preference:",
      value: GenderPreference.toDescriptiveString(
        corporateDetails.genderPreference || GenderPreference.dontCare
      ),
    },
    {
      key: "totalEventDuration",
      label: "Total event duration:",
      value: formatMinutesToDisplayTime(
        corporateDetails.totalEventDuration?.massageDuration || null
      ),
    },
    {
      key: "eventType",
      label: "Event type:",
      value: eventOptions.find((item: any) => item.value === corporateDetails.eventType)?.title,
    },
    {
      key: "eventName",
      label: "Event name:",
      value: corporateDetails.eventName,
    },
    {
      key: "noteForRecipients",
      label: "Note for recipients:",
      value: corporateDetails.noteForRecipients,
    },
  ];
};

const hasTreatmentDetailsChangedForBooking = (updatedData: any, existingBooking: any) => {
  const newBookingDetails = getValue(updatedData, "bookingDetails");
  if (checkIfEmpty(newBookingDetails)) return false;

  for (let i = 0; i < newBookingDetails.length; i += 1) {
    const newDetail = newBookingDetails[i];
    const existingDetail = existingBooking.bookingDetails[i];

    const treatmentDetailUpdated = checkHasTreatmentDetailUpdated(
      existingDetail.treatmentDetails,
      newDetail.treatmentDetails
    );

    if (treatmentDetailUpdated) return true;
  }
  return false;
};

const hasBookingAddressChanged = (updatedData: any, existingBooking: any) => {
  return (
    !checkIfEmpty(getValue(updatedData, "address")) &&
    !isEqual(getValue(existingBooking, "address.id"), getValue(updatedData, "address.id"))
  );
};

const hasServiceChanged = (updatedData: any, existingBooking: any) =>
  getValue(updatedData, "serviceId") &&
  getValue(updatedData, "serviceId") === getValue(existingBooking, "serviceId");

const hasBookingRecipientChanged = (
  updatedData: any,
  existingBooking: any,
  updatedKeys: string[] = []
) => {
  const hasRecipientKey = updatedKeys.includes("selectedRecipient");
  return (
    hasRecipientKey &&
    !isEqual(
      getValue(existingBooking, "selectedRecipient.id"),
      getValue(updatedData, "selectedRecipient.id")
    )
  );
};

const hasBookingRecurringChanged = (updatedData: any, existingBooking: any) => {
  return (
    !checkIfEmpty(getValue(updatedData, "recurring", {})) &&
    !isEqual(getValue(existingBooking, "recurring"), getValue(updatedData, "recurring"))
  );
};

const hasTimeOfArrivalChanged = (updatedData: any, existingBooking: any) => {
  return (
    !checkIfEmpty(getValue(updatedData, "timeOfArrival")) &&
    !isSameMoment(
      getValue(updatedData, "timeOfArrival"),
      getValue(existingBooking, "timeOfArrival")
    )
  );
};

const getBookingTitleByDetails = ({ service, treatmentDetails }: any) => {
  if (checkIfEmpty(service)) return "";

  let title = service.alias;
  if (checkIfEmpty(treatmentDetails)) return title;

  const labels = treatmentDetails
    .map(({ label, title }: { label?: string; title?: string }) => label || title)
    .filter((label?: string) => !!label);
  if (labels.length) title = `${title} | ${labels.join(", ")}`;
  return title;
};

export {
  getAllBookingQuestions,
  getBookingPrice,
  getBookingTitleByDetails,
  getQuestionsForTreatment,
  hasBookingAddressChanged,
  hasBookingRecipientChanged,
  hasBookingRecurringChanged,
  hasServiceChanged,
  hasTimeOfArrivalChanged,
  hasTreatmentDetailsChangedForBooking,
  transformBookingData,
  transformCorporateForDescription,
  transformPriceForAlternativeOffer,
  transformSourceDetails,
  transformTreatmentDetailsForUpdate,
  transformUpdatedData,
};
