import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";

import CloseIcon from "../images/modal-close.svg";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";

interface Props {
  title: string;
  subtitle?: string;
  onClose: () => unknown;
}

export default function ({ title, subtitle, onClose }: Props): JSX.Element {
  const isMobile = useMobile();
  return (
    <Box>
      <Box>
        <Box
          height="70px"
          alignItems="center"
          justifyContent="center"
          display="flex"
          position="relative"
          paddingLeft="20px"
          paddingRight="20px"
        >
          <Box position="absolute" right="4px" top="4px">
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="closeIcon" />
            </IconButton>
          </Box>

          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="16px"
            color={Colors.NightBlue}
            alignSelf="center"
            width="100%"
            paddingLeft={!isMobile ? "24px" : ""}
          >
            {title}
          </Box>
        </Box>
      </Box>
      <Box width="100%" height="1px" bgcolor={Colors.PaleLilac} />
    </Box>
  );
}
