const isImage = (file: any) => file["type"].includes("image");

const base64ToFile = (base64: string, fileName: string) => {
  // Remove the base64 header if it exists
  const base64Data = base64.split(",")[1];

  // Decode the base64 string to binary data
  const binaryString = atob(base64Data);
  const len = binaryString.length;
  const byteArray = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    byteArray[i] = binaryString.charCodeAt(i);
  }

  // Create a File object from the binary data
  return new File([byteArray], fileName, { type: "image/png" });
};

export { isImage, base64ToFile };
