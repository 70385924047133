import React, { useState } from "react";

import { getValue } from "../../../../utils/object";
import Ratings from "../../../../components/Ratings";
import { Colors } from "../../../../constants/colors";
import useShareSheet from "../../../../hooks/shareSheet";
import { useAlertStore } from "../../../../stores/alert";
import { useAccessToken } from "../../../../hooks/common";
import { Box, ButtonBase, Popover } from "@material-ui/core";
import MoreIcon from "../../../../images/therapists-more.svg";
import UserAvatar from "../../../../components/UserAvatar/UserAvatar";
import FindingTherapistIcon from "../../../../images/finding-therapist.svg";
import PopoverItemSeparator from "../../../../components/PopoverItemSeparator";
import PopoverItem, { PopoverItemType } from "../../../../components/PopoverItem";
import {
  blockTherapist,
  addToFavourites,
  removeFromFavorites,
  unBlockTherapist,
} from "../../../../services/therapist/therapist.service";
import { useUserStore } from "../../../../stores/user";
import ComplaintModal from "../../../../components/Complaint/ComplaintModal";

const CardHeader = ({
  therapist,
  publicProfile,
  refreshTherapist,
  bookingTitle,
  handleProfileOpen,
  profession = "therapist",
}: any) => {
  const { user } = useUserStore();
  const shareSheet = useShareSheet();
  const accessToken = useAccessToken();
  const { setSuccessMessage, setErrorMessage } = useAlertStore();

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const [showComplaintModal, setShowComplaintModal] = useState(false);

  const getPublicData = () => {
    const publicData = {
      isBlocked: getValue(publicProfile, "isBlocked", false),
      isFavorite: getValue(publicProfile, "isFavorite", false),
      totalReviews: getValue(publicProfile, "totalReviews", 0),
      rating: getValue(publicProfile, "therapistData.therapistprofile.rating", 0),
    };
    return publicData;
  };

  const handleFavorite = async (therapistId: Number, addToFavorite = true) => {
    try {
      setPopoverAnchorEl(null);
      if (addToFavorite) {
        await addToFavourites(therapistId, accessToken);
      } else {
        await removeFromFavorites(therapistId);
      }
      refreshTherapist();

      const successMessage = addToFavorite
        ? "Added this therapist to favourites"
        : "Removed this therapist from favourites";
      setSuccessMessage(successMessage);
    } catch (err: any) {
      console.log(err);
      const message = err.message || "Something went wrong, please try again.";
      setErrorMessage(message);
    }
  };

  const handleBlockThearpist = async (therapistId: Number, block = true) => {
    try {
      setPopoverAnchorEl(null);

      if (block) {
        await blockTherapist(therapistId, "Blocked", accessToken);
      } else {
        await unBlockTherapist(therapistId);
      }

      refreshTherapist();
      const successMessage = block ? "Therapist blocked" : "Therapist unblocked";
      setSuccessMessage(successMessage);
    } catch (err: any) {
      const message = err.message || "Something went wrong, please try again.";
      setErrorMessage(message);
    }
  };

  const handleShare = async (therapistId: Number) => {
    try {
      setPopoverAnchorEl(null);

      const firstNameLowercased = therapist?.firstName?.toLowerCase();

      if (!firstNameLowercased) throw Error("Could not find therapist. Please try again.");
      shareSheet.share({
        title: "Here's $20 off your first Blys Massage!",
        text: `My fav Blys therapist! Book with my code ${user?.referralCode} for $20 off`,
        url: `https://book.getblys.com.au/practitioner/${firstNameLowercased}-${therapistId}`,
      });
    } catch (err: any) {
      const message = err.message || "Something went wrong, please try again.";
      setErrorMessage(message);
    }
  };

  const getHeaderText = () => {
    if (!bookingTitle) return "";

    if (!therapist) return `Finding your ${profession}`;

    const headerText = `${bookingTitle} with `;
    return headerText;
  };

  const reportTherapist = () => {
    setShowComplaintModal(true);
  };

  const profileImage = getValue(therapist, "therapistprofile.profileImage", "");

  const { rating, totalReviews, isBlocked, isFavorite } = getPublicData();
  const headerText = getHeaderText();

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box display="flex" flexDirection="row" alignItems="center" mr="12px">
          {therapist ? (
            <Box style={{ cursor: "pointer" }} onClick={handleProfileOpen}>
              <UserAvatar isCovidVaccinated={therapist.isCovidVaccinated} image={profileImage} />
            </Box>
          ) : (
            <img src={FindingTherapistIcon} alt="Finding your therapist" />
          )}
          <Box ml="12px">
            <Box
              fontFamily="Museo"
              fontWeight={700}
              color={Colors.NightBlue}
              fontSize="16px"
              textAlign="left"
            >
              {headerText}
              <span onClick={handleProfileOpen} style={{ cursor: "pointer" }}>
                {getValue(therapist, "firstName", "therapist")}
              </span>
            </Box>
            {therapist && (
              <Box display="flex" flexDirection="row" alignItems="center" mt="1px">
                <Ratings rating={rating || 0} />

                <Box fontFamily="Open Sans" fontSize="12px" color={Colors.Dusk} ml="6px">
                  {`${totalReviews || 0} Reviews`}
                </Box>
              </Box>
            )}
            {!therapist && (
              <Box fontFamily="Open Sans" fontSize="14px" color={Colors.Dusk} textAlign="left">
                Checking availability...
              </Box>
            )}
          </Box>
        </Box>
        {therapist && (
          <ButtonBase
            onClick={(event) => {
              event.stopPropagation();
              setPopoverAnchorEl(event.currentTarget as HTMLElement);
            }}
            disableTouchRipple={true}
          >
            <Box minHeight="55px" display="flex" alignItems="center" justifyContent="center">
              <img src={MoreIcon} alt={"more options"} />
            </Box>
          </ButtonBase>
        )}
      </Box>

      <Popover
        id={"booking-details-therapist-popover"}
        open={popoverAnchorEl !== null}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" width="280px">
          {!isFavorite ? (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title="Add to favourites"
                onClick={() => handleFavorite(therapist.id)}
              />
              <PopoverItemSeparator />
            </>
          ) : (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title="Remove from favourites"
                onClick={() => handleFavorite(therapist.id, false)}
              />
              <PopoverItemSeparator />
            </>
          )}

          {shareSheet.isSupported && (
            <>
              <PopoverItem
                type={PopoverItemType.default}
                title="Recommend to a friend"
                onClick={() => handleShare(therapist.id)}
              />
              <PopoverItemSeparator />
            </>
          )}

          {isBlocked ? (
            <PopoverItem
              type={PopoverItemType.default}
              title={`Unblock ${therapist?.firstName || "therapist"}`}
              onClick={() => handleBlockThearpist(therapist.id, false)}
            />
          ) : (
            <PopoverItem
              type={PopoverItemType.default}
              title={`Block ${therapist?.firstName || "therapist"}`}
              onClick={() => handleBlockThearpist(therapist.id)}
            />
          )}

          <PopoverItemSeparator />
          <PopoverItem
            type={PopoverItemType.default}
            title={`Report ${therapist?.firstName || "therapist"}`}
            onClick={reportTherapist}
          />

          <ComplaintModal
            complaintFor={parseInt(therapist?.id)}
            open={showComplaintModal}
            onClose={() => {
              setShowComplaintModal(false);
              setPopoverAnchorEl(null);
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default CardHeader;
