import { User } from "../../models";
import { getValue } from "../../utils/object";
import AdditionalBillingIdentifierConfig from "../../config/AdditionalBillingIdentifier/AdditionalBillingIdentifierConfig.json";
import { INVOICE_FORMAT_ENUM } from "../../constants/payment";

const getBillingIdentifierConfig = (user: User | null) => {
  const isSupportCoordinator = getValue(user, "role.isNdisSupportCoordinator", false);
  const isSupportRecipient = getValue(user, "role.isNdisSupportRecipient", false);
  // const isB2BUser = getValue(user, "role.isB2BUser",false);
  const isAdmin = getValue(user, "role.isAdmin", false);
  const isSuperAdmin = getValue(user, "role.isSuperAdmin", false);
  const isGuardianOrCarer = getValue(user, "role.isGuardianOrCarer", false);

  return {
    paymentDetailConfig: () => {
      if (isSuperAdmin || isAdmin || isGuardianOrCarer || isSupportRecipient) {
        return getValue(AdditionalBillingIdentifierConfig, "enableAll");
      } else {
        return getValue(AdditionalBillingIdentifierConfig, "enableOrderCodeOnly");
      }
    },

    recipientDetailConfig: () => {
      if (isSupportCoordinator || isAdmin || isSuperAdmin || isGuardianOrCarer) {
        return getValue(AdditionalBillingIdentifierConfig, "enableAll");
      } else {
        return getValue(AdditionalBillingIdentifierConfig, "enableOrderCodeOnly");
      }
    },
  };
};

const isBulkInvoiceFormat = (value: string) => {
  return [
    INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_ALL_RECIPIENTS,
    INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_EACH_RECIPIENT,
    INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_EACH_PROVIDER,
    INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_ALL_RECIPIENTS_EXCEL,
    INVOICE_FORMAT_ENUM.BULK_INVOICE_FOR_EACH_RECIPIENT_NDIS,
  ].includes(value);
};
export { getBillingIdentifierConfig, isBulkInvoiceFormat };
