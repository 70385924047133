import { useEffect, useState } from "react";
import { CONTRAINDICATIONS_DESCRIPTION, FEMALE_CONTRAINDICATIONS_TITLE, MALE_CONTRAINDICATIONS_TITLE } from "../../constants/message";
import { CONTRAINDICATIONS_OPTIONS } from "../../data/contraindications.options";
import Dropdown, { DropdownOption } from "../Dropdown";
import TextField from "../TextField";

interface ContraindicationsProps {
  hasContraindications: boolean | null;
  setHasContraindications: (hasContraindications: boolean) => unknown;
  contraindications: string | null;
  setContraindications: (contraindication: string | null) => unknown;
  gender?:string | null | undefined
}
export default function Contraindications({
  hasContraindications = false,
  setHasContraindications,
  contraindications,
  setContraindications,
  gender
}: ContraindicationsProps):JSX.Element{
  const [contraindicationTitle, setContraindicationsTitle] = useState("");
  useEffect(()=>{
    // If user selects the has contraidication to NO the contraindication will be removed.
    if(!hasContraindications){
      setContraindications(null);
    }
  },[hasContraindications]);
  
  useEffect(()=>{
    const title = gender==="male"? MALE_CONTRAINDICATIONS_TITLE: FEMALE_CONTRAINDICATIONS_TITLE;
    setContraindicationsTitle(title);
  }, [gender]);
  
  return (
    <>
      <Dropdown
        title = {contraindicationTitle}
        description = {CONTRAINDICATIONS_DESCRIPTION}
        options = {CONTRAINDICATIONS_OPTIONS}
        selectedOption={CONTRAINDICATIONS_OPTIONS.find((option) => option.value === hasContraindications)}
        onSelectedOption = {(option)=>{setHasContraindications(option.value);}}
      />
      { 
        hasContraindications? 
          <TextField 
            placeholder= "Please provide more details."
            value= {contraindications}
            onChange= {(text)=> setContraindications(text)}
            type= "text"
          />
          :<></>}
    </>
  );
}