import { useEffect, useState } from "react";
import { BookingQuestion } from "../models";
import { getBookingQuestionsForService, useServicesStore } from "../stores/booking";

export const useQuestions = (serviceId: number, treatmentDetails: any[]) => {
  const [questions, setQuestions] = useState<BookingQuestion[]>([]);

  const { questions: serviceQuestions } = useServicesStore();

  useEffect(() => {
    const treatmentIds = [...(treatmentDetails || [])]
      .filter(({ treatmentId, treatmentTypeId }) => treatmentId || treatmentTypeId)
      .map(({ treatmentId, treatmentTypeId }) => treatmentId || treatmentTypeId);
    setQuestions(getBookingQuestionsForService(serviceId as number, treatmentIds));
  }, [serviceId, treatmentDetails, serviceQuestions]);

  return questions;
};
