import * as httpClient from "../../../../api/client";

export const getTherapistSchedule = () => {
  return httpClient.get("api/v2/proSchedules");
};

export const updateTherapistSchedule = (data: {}) => {
  return httpClient.post("api/v2/proSchedules", data);
};

export const updateTherapistBufferTime = (data: {}) => {
  return httpClient.post("api/v2/therapists/proBufferTime", data);
};

export const getTherapistBufferTime = () => {
  return httpClient.get("api/v2/therapists/proBufferTime");
};
