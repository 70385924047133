import React from "react";
import { Job } from "../../../models";
import { getValue } from "../../../utils/object";
import { Colors } from "../../../constants/colors";
import ClientPaymentMethod from "./ClientPaymentMethod";
import { PaymentType } from "../../../constants/payment";
import { Box, Text } from "../../../components/v2/Styled";
import {
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
} from "../../../components/v2/Styled/enum";

const ClientPaymentDetails = ({
  job,
  style = {},
}: {
  job: Job;
  style: React.CSSProperties;
}): JSX.Element => {
  const clientPaymentMethod = getValue(job, "clientPaymentMethod", {});
  const paymentType = getValue(clientPaymentMethod, "type");
  const clientPaymentTerm = getValue(clientPaymentMethod, "paymentTerm", 1);
  const afterpayInitiatedAt = getValue(job, "bookingdetail.booking.afterpayInitiatedAt");

  const getPayWithText = (paymentType: PaymentType) => {
    let text = "Pay with ";

    if (!!afterpayInitiatedAt) {
      return text + "Afterpay";
    }

    switch (paymentType) {
    case PaymentType.applepay:
      text += "Apple Pay";
      break;
    case PaymentType.card:
      text += "card";
      break;
    case PaymentType.payWithInvoice:
      text += "invoice";
      break;
    case PaymentType.payWithNDISFunding:
      text += "NDIS funding";
      break;
    case PaymentType.paypal:
      text += "Paypal";
      break;
    case PaymentType.afterpay:
      text += "Afterpay";
      break;
    }
    return text;
  };

  const getClientPaymentTermText = (paymentTerm = 1) =>
    `This client has a payment term of ${paymentTerm} ${paymentTerm > 1 ? "days" : "day"}.`;

  if (paymentType === PaymentType.default || !paymentType) {
    return <React.Fragment />;
  }

  return (
    <>
      {<div style={{ marginTop: style.marginTop ?? 0 }} />}
      <Box gap={Spacing.S4} direction={FlexDirection.Column}>
        <Text
          font={FontFamily.Museo}
          size={FontSize.F16}
          lineHeight={LineHeight.L24}
          weight={FontWeight.Bold}
          color={Colors.NightBlue}
        >
          Client payment details
        </Text>
        <Box
          gap={Spacing.S4}
          backgroundColor={Colors.White}
          border={`1px solid ${Colors.LightPeriwinkle}`}
          borderRadius={"8px"}
          paddingX={Spacing.S6}
          paddingY={Spacing.S4}
        >
          <Box>
            <ClientPaymentMethod job={job} />
          </Box>
          <Box direction={FlexDirection.Column}>
            <Box>
              <Text
                font={FontFamily.Museo}
                weight={FontWeight.Medium}
                lineHeight={LineHeight.L24}
                color={Colors.Dusk}
              >
                {getPayWithText(paymentType)}
              </Text>
            </Box>
            {clientPaymentTerm > 1 ? (
              <Box>
                <Text lineHeight={LineHeight.L21} color={Colors.Grey} size={FontSize.F14}>
                  {getClientPaymentTermText(clientPaymentTerm)}
                </Text>
              </Box>
            ) : (
              <React.Fragment />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ClientPaymentDetails;
