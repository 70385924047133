export const VOUCHER_DELIVERY_STATUS = {
  DECLINED: "Declined",
  DELIVERED: "Delivered",
  SCHEDULE_FOR_DELIVERY: "Scheduled For Delivery",
  CANCELLED: "Cancelled",
  FAILED: "Failed"
};

export const VOUCHER_DELIVERY_DISPLAY_STATUS = {
  [VOUCHER_DELIVERY_STATUS.DECLINED]: VOUCHER_DELIVERY_STATUS.DECLINED,
  [VOUCHER_DELIVERY_STATUS.DELIVERED]: VOUCHER_DELIVERY_STATUS.DELIVERED,
  [VOUCHER_DELIVERY_STATUS.SCHEDULE_FOR_DELIVERY]: "Scheduled for delivery",
  [VOUCHER_DELIVERY_STATUS.CANCELLED]: VOUCHER_DELIVERY_STATUS.CANCELLED,
  [VOUCHER_DELIVERY_STATUS.FAILED]: VOUCHER_DELIVERY_STATUS.FAILED,
};
