import { useQuery } from "@tanstack/react-query";

import { get } from "../../api/client";
import { ServiceRate } from "../../models";
import { DEFAULT_COUNTRY } from "../../utils/country";

const useUserServices= (country: string = DEFAULT_COUNTRY) => {
  const QUERY_KEY = `userServices-${country}`;

  const getUrlPath = () => {
    let path = `api/v3/service?channel=web&country=${country}`;
    return path;
  };

  const { isLoading, data } = useQuery([QUERY_KEY], () => {
    const path = getUrlPath();
    return get(path);
  });

  const getUserServices = () => {
    if (!data || data.length <= 0) return [];

    return data as ServiceRate[];
  };
  
  return { services: getUserServices(), isLoading };
};

export { useUserServices };
