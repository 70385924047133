import { Box } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import LandingWrapper from "../../components/LandingWrapper";
import { useMobile } from "../../hooks/mobile";
import logo from "../../images/blys-logo.svg";
import ForgotPasswordComponent from "./ForgotPasswordComponent";
import { get } from "lodash";
import LogoLink from "../../components/LogoLink";

export default function (): JSX.Element {
  const history = useHistory();
  const isMobile = useMobile();
  const location = useLocation();

  const email = get(location.state, "email");

  return (
    <LandingWrapper>
      <Box width={isMobile ? undefined : "400px"}>
        <LogoLink />
        <ForgotPasswordComponent
          prefillEmail={email}
          onSentInstructions={(email) => {
            history.push({
              pathname: "/reset-instructions-sent",
              state: {
                email,
              },
            });
          }}
          onBackToLogin={() => history.push("/login")}
        />
      </Box>
    </LandingWrapper>
  );
}
