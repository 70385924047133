import { Box, ButtonBase, Link, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../../../constants/colors";

interface StyledButtonProps {
  selected?: boolean;
}

interface Props {
  onSelect: () => unknown;
  selected: boolean;
}

const StyledButton = withStyles({
  root: ({ selected }: StyledButtonProps) => ({
    backgroundColor: selected ? "#EBF8FA" : undefined,
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    textAlign: "left",
    "&:hover": {
      backgroundColor: selected ? "#EBF8FA" : "#ebf8fa4d",
    },
  }),
})(ButtonBase);

const style = {
  fontFamily: "Open Sans",
};

const SelectNone = ({ onSelect, selected }: Props) => {
  return (
    <React.Fragment>
      <StyledButton onClick={() => onSelect()} selected={selected}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            flexDirection="row"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gridGap={15} alignItems="center">
              <Box>
                <Typography style={{ ...style, fontSize: "16px", fontWeight: 700 }}>
                  None
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledButton>
      <Box
        style={{
          height: "1px",
          backgroundColor: Colors.LightPeriwinkle,
          margin: "0 16px",
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(SelectNone);
