import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { FilterModal } from "./index";
import { FilterBlockWrapper } from "../../FilterBlockWrapper";
import { ToggleSection } from "../../ToggleSection/ToggleSection";
import { Display, FlexDirection, Position, Spacing } from "../../v2/Styled/enum";
import { DateSelectorField } from "../../DateSelectorField/DateSelectorField";
import { getValue } from "../../../utils/object";
import { formatDate, getDateObject } from "../../../utils/date.util";
import { isEmpty } from "lodash";
import { useAlertStore } from "../../../stores/alert";

export interface Filter {
  completed: boolean;
  cancelled: boolean;
}

export enum FilterName {
  Completed = "completed",
  Cancelled = "cancelled",
  PayoutStatus = "payoutStatus",
  Overdue = "overdue",
  StartingDate = "startingDate",
  EndingDate = "endingDate",
}
export interface SearchFilter {
  completed: boolean;
  cancelled: boolean;
  payoutStatus: string[] | undefined;
  overdue: boolean;
  startingDate: string | undefined | null;
  endingDate: string | undefined | null;
}
interface Props {
  open: boolean;
  onClose: () => unknown;
  onFilterApplied: (filter: SearchFilter) => unknown;
  bookingStatusFilter: [];
  paymentStatusFilter: [];
  appliedFilter: any;
}
const initialSearchState: SearchFilter = {
  completed: true,
  cancelled: true,
  payoutStatus: undefined,
  overdue: false,
  startingDate: undefined,
  endingDate: undefined,
};

export const ProDashPastBookingFilter = ({
  open,
  onClose,
  onFilterApplied,
  bookingStatusFilter,
  paymentStatusFilter,
  appliedFilter,
}: Props) => {
  const { setErrorMessage } = useAlertStore();
  const [selectedSearch, setSelectedSearch] = useState<SearchFilter>(initialSearchState);
  const [forceSelectToCalendar, setForceSelectToCalendar] = useState(false);

  const initializeData = useMemo(() => {
    return () => {
      const startingDate = getValue(
        appliedFilter,
        FilterName.StartingDate,
        initialSearchState.startingDate
      );
      setSelectedSearch({
        completed: appliedFilter.completed ?? initialSearchState.completed,
        cancelled: appliedFilter.cancelled ?? initialSearchState.cancelled,
        payoutStatus: getValue(
          appliedFilter,
          FilterName.PayoutStatus,
          initialSearchState.payoutStatus
        ),
        overdue: appliedFilter.overdue ?? initialSearchState.overdue,
        startingDate,
        endingDate: getValue(appliedFilter, FilterName.EndingDate, initialSearchState.endingDate),
      });
      setForceSelectToCalendar(false); // Move this here to ensure it's consistently set
    };
  }, [appliedFilter]);
  useEffect(() => {
    if (!open) return;
    initializeData();
  }, [open, initializeData]);

  const handleToggleChange = ({
    name,
    value,
    isBoolean = false,
  }: {
    name: string;
    value: any;
    isBoolean?: boolean;
  }) => {
    const currentValue = getValue(selectedSearch, name, isBoolean ? false : []);
    if (!isBoolean) {
      const updatedValue = currentValue.includes(value)
        ? currentValue.filter((item: string) => item !== value)
        : [...currentValue, value];

      setSelectedSearch({
        ...selectedSearch,
        [name]: updatedValue,
      });
      return;
    }
    setSelectedSearch({
      ...selectedSearch,
      [name]: !currentValue,
    });
  };

  const handleDateChange = ({ selectedDate, name }: { selectedDate: Date; name: string }) => {
    const formattedDate = formatDate({ date: selectedDate });
    setSelectedSearch({ ...selectedSearch, [name]: formattedDate });
  };

  const isToggled = ({ value, name }: { value: any; name: string }) => {
    const searchedValue = getValue(selectedSearch, name);
    const isValueBoolean = typeof value === "boolean" || typeof searchedValue === "boolean";
    return isValueBoolean ? !!searchedValue : (searchedValue || []).includes(value);
  };

  // NOTE :- This is the search value which will exclude all undefined and empty value
  // which will be used to get the count number
  const actualSearch: { [key: string]: any } = {};
  Object.entries(selectedSearch).forEach(([key, value]) => {
    // For this filter completd and cancelled is consider true as default
    // we should not consider the count if the completed and cancelled is true
    if (key === FilterName.Completed || key === FilterName.Cancelled) {
      if (!value) {
        actualSearch[key] = value;
      }
    } else if (value) {
      if (Array.isArray(value) && isEmpty(value)) {
        return;
      }
      actualSearch[key] = value;
    }
  });

  const shouldEnableClearAll = Object.keys(actualSearch).length > 0;

  return (
    <FilterModal
      open={open}
      onClose={onClose}
      title="Filters"
      handleFilterApply={() => {
        onFilterApplied(actualSearch as SearchFilter);
        onClose();
      }}
      shouldEnableClearAll={shouldEnableClearAll}
      handleClearAll={() => {
        setSelectedSearch(initialSearchState);
      }}
      dataToIncludeInUrl={actualSearch}
      filtersName={[
        FilterName.Completed,
        FilterName.Cancelled,
        FilterName.PayoutStatus,
        FilterName.Overdue,
        FilterName.StartingDate,
        FilterName.EndingDate,
      ]}
    >
      <FilterBlockWrapper headerText="Date">
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          style={{
            gap: Spacing.S4,
            width: "100%",
          }}
          position={Position.Relative}
        >
          <DateSelectorField
            title="From"
            value={
              selectedSearch.startingDate
                ? formatDate({ date: selectedSearch.startingDate, format: "D MMM YYYY" })
                : ""
            }
            placeHolder="Select date"
            onDateSelected={(selectedDate: Date) => {
              setForceSelectToCalendar(true);
              handleDateChange({
                selectedDate,
                name: "startingDate",
              });
            }}
            wrapperStyle={{
              position: Position.Static,
            }}
          />
          <DateSelectorField
            title="To"
            fromDate={selectedSearch.startingDate}
            forceSelect={forceSelectToCalendar}
            value={
              selectedSearch.endingDate
                ? formatDate({ date: selectedSearch.endingDate, format: "D MMM YYYY" })
                : ""
            }
            placeHolder="Select date"
            onDateSelected={(selectedDate: Date) => {
              setForceSelectToCalendar(false);
              handleDateChange({
                selectedDate,
                name: "endingDate",
              });
            }}
            validateOnDateSelect={() => {
              if (!selectedSearch.startingDate) {
                setErrorMessage("Please select 'from' date first.");
                return false;
              }
              return true;
            }}
            wrapperStyle={{
              position: Position.Static,
            }}
            minValidDate={
              selectedSearch.startingDate ? getDateObject(selectedSearch.startingDate) : null
            }
          />
        </Box>
      </FilterBlockWrapper>

      <FilterBlockWrapper
        headerText="Booking status"
        wrapperStyle={{
          marginTop: Spacing.S6,
        }}
      >
        {(bookingStatusFilter || []).map(({ name, label, info, value }) => {
          return (
            <ToggleSection
              title={label}
              subTitle={info}
              onToggle={() => [handleToggleChange({ name, value: !value, isBoolean: true })]}
              isToggled={isToggled({ name, value })}
            />
          );
        })}
      </FilterBlockWrapper>

      <FilterBlockWrapper
        headerText="Payment status"
        wrapperStyle={{
          marginTop: Spacing.S6,
        }}
      >
        {(paymentStatusFilter || []).map(({ name, label, info, value }) => {
          return (
            <ToggleSection
              title={label}
              subTitle={info}
              onToggle={() => [
                handleToggleChange({
                  name,
                  value,
                  isBoolean:
                    typeof value === "boolean" ||
                    typeof getValue(selectedSearch, name, []) === "boolean",
                }),
              ]}
              isToggled={isToggled({ name, value })}
            />
          );
        })}
      </FilterBlockWrapper>
    </FilterModal>
  );
};
