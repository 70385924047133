import { Colors } from "../../../constants/colors";

const styles = {
  header: {
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Museo",
    color: Colors.NightBlue,
  }
};

export default styles;