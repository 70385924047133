import React from "react";
import { Box } from "@material-ui/core";
import * as Styled from "../../../../v2/Styled/enum";
import CardWrapper from "../../../../BookingDetails/BookingDetailCard";
import { Colors } from "../../../../../constants/colors";
import StripeCoBranding from "./StripeCoBranding";

interface Props {}

const InReviewCoBranding = ({}: Props): JSX.Element => {
  return (
    <CardWrapper
      style={{
        gap: 0,
        margin: 0,
        padding: 0,
        width: "100%",
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <Box
        gridGap={Styled.Spacing.S2}
        display={Styled.Display.Flex}
        flexDirection={Styled.FlexDirection.Column}
        padding={`${Styled.Spacing.S4} ${Styled.Spacing.S6}`}
      >
        <Box
          color={Colors.Dusk}
          fontSize={Styled.FontSize.F18}
          lineHeight={Styled.LineHeight.L27}
          fontWeight={Styled.FontWeight.Bold}
          fontFamily={Styled.FontFamily.Museo}
        >
          We are verifying your banking information!
        </Box>
        <Box
          color={Colors.Dusk}
          fontSize={Styled.FontSize.F14}
          lineHeight={Styled.LineHeight.L21}
          fontWeight={Styled.FontWeight.Regular}
          fontFamily={Styled.FontFamily.OpenSans}
        >
          Payouts are on pause while your information is being reviewed. This process typically may
          takes up to 2 business days.
        </Box>
      </Box>
      <Box
        bgcolor={Colors.PaleGrey}
        padding={`${Styled.Spacing.S4} ${Styled.Spacing.S6}`}
        borderTop={`1px solid ${Colors.LightPeriwinkle}`}
      >
        <StripeCoBranding />
      </Box>
    </CardWrapper>
  );
};

export default InReviewCoBranding;
