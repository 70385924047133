import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../stores/V2";
import { checkIfEmpty, getValue } from "../../utils/object";
import { useMobile } from "../../hooks/mobile";
import { Colors } from "../../constants/colors";
import TreatmentDropdownMenu from "../../components/Treatment/DropdownMenu";
import { actions as bookingActions } from "../../stores/V2/booking/booking";
import BookingQuestions from "../../components/Bookings/V2/Questions/BookingQuestions";
import { DEFAULT_CURRENCY, MAX_CLIENTS_HAIR_MAKEUP } from "../../constants/booking";
import AddOnDropdownMenu from "../../components/Bookings/V2/AddOn/DropdownMenu/AddOnDropdownMenu";
import { ServiceRatePrice } from "../../models";
import {
  getTreatmentAddOnForConfirmedBooking,
  isMultipleAddOnAllowdForConfirmedBooking,
} from "../../helpers/booking";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import { useState } from "react";

interface HairAndMakeupProps {
  showOtherAddOnOption?: boolean;
}

function HairAndMakeupDetails({ showOtherAddOnOption = false }: HairAndMakeupProps) {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const state = useSelector(({ booking }: RootState) => booking);
  const { selectedService, bookingDetails, proAddOns, numberOfClients } = state;
  const treatmentTypes = selectedService.prices;
  const serviceQuestions = getValue(bookingDetails[0], "questions", []).filter(
    (question: any) => !question.treatmentId,
  );

  const [recipientOptions] = useState<Array<DropdownOption>>(
    Array(MAX_CLIENTS_HAIR_MAKEUP).fill(null).map((item, index) => {
      const count = index + 1;
      return {
        value: count,
        title: String(count),
      };
    })
  );

  const genderPrefs = getValue(selectedService, "genderPreferences", []);
  const currency =
    getValue(selectedService, "selectedCountry.currencySymbol") || DEFAULT_CURRENCY.currencySymbol;
  const selectedCountry = getValue(selectedService, "selectedCountry", DEFAULT_CURRENCY);

  const getGenderPreference = (value: any) => {
    const genderPref = genderPrefs?.find((genderPref: any) => genderPref.value === value) || {};
    return genderPref.title || "";
  };

  const checkTreatmentChanged = (selectedIndex: number, selectedTreatment: any) => {
    let hasChanged = true;
    const targetDetails = bookingDetails[0].treatmentDetails[selectedIndex];
    if (targetDetails.value === selectedTreatment.name) {
      hasChanged = false;
    }
    return hasChanged;
  };

  const handleTreatmentTypeChange = (selectedIndex: number, selectedTreatment: any) => {
    // check treatment changed (to stop running everytime onMount)
    const treatmentChanged = checkTreatmentChanged(selectedIndex, selectedTreatment);
    if (!treatmentChanged) return;

    dispatch(
      bookingActions.resetTreatmentAddOn({
        index: selectedIndex,
        treatmentId: selectedTreatment.id,
      }),
    );
    dispatch(bookingActions.updateTreatmentDetails({ index: selectedIndex, selectedTreatment }));
  };

  const handleAnswerChange = ({ answer, questionId, treatmentIndex, answers }: any) => {
    dispatch(bookingActions.updateSelectedAnswer({ answer, treatmentIndex, questionId, answers }));
  };

  const handleTreatmentAddOnChange = ({
    selectedIndex,
    addon,
    isMultipleAddonsAllowed,
  }: {
    selectedIndex: any;
    addon: any;
    isMultipleAddonsAllowed: boolean;
  }) => {
    dispatch(
      bookingActions.updateTreatmentAddOn({
        index: selectedIndex,
        addon,
        isMultipleAddonsAllowed,
      }),
    );
  };

  const getTreatmentDetails = (details: any) => {
    if (!details) return [];
    let treatments = details.map((detail: any) => {
      const { treatmentDetails } = detail;
      return treatmentDetails;
    });
    treatments = treatments.flat();
    return treatments.map((t: any) => ({
      ...t,
      genderTitle: getGenderPreference(t.genderPreference),
    }));
  };

  const handleAddOnSelect = ({
    treatment,
    treatmentIndex,
    isMultipleAddonsAllowed,
  }: {
    treatment: ServiceRatePrice;
    treatmentIndex: number;
    isMultipleAddonsAllowed: boolean;
  }) => {
    handleTreatmentAddOnChange({
      selectedIndex: treatmentIndex,
      addon: treatment,
      isMultipleAddonsAllowed,
    });
  };

  const details = getTreatmentDetails(bookingDetails);

  const handleNoneSelect = (treatmentIndex: number) => {
    dispatch(
      bookingActions.resetTreatmentAddOn({
        index: treatmentIndex,
      }),
    );
  };

  const onNumOfClientsChanged = (option: DropdownOption) => {
    const { value: numberOfRecipient } = option;
    dispatch(bookingActions.updateRecipientCount({ numberOfRecipient }));
  };

  const getSelectedClientCount = () =>
    recipientOptions.find((option) => option.value === numberOfClients);

  const showPersonIndex = (details || []).length > 1;

  return (
    <Box width={"100%"}>
      <Box display="flex" flexDirection="column" width={isMobile ? "100%" : "50%"}>
        <Box>
          {selectedService.isHairAndMakeup && <Dropdown
            title="How many people?"
            options={recipientOptions}
            selectedOption={getSelectedClientCount()}
            onSelectedOption={onNumOfClientsChanged}
          />}
        </Box>
        {details.map((detail: any, index: number) => {
          return (
            <Box key={index}>
              {showPersonIndex && (<Box
                fontFamily="Museo"
                fontSize="20px"
                color={Colors.Dusk}
                fontWeight={700}
                flex={1}
                textAlign="left"
              >
                { `Person ${index + 1}`}
              </Box>
              )}
              <Box>
                <TreatmentDropdownMenu
                  value={detail.value}
                  label="Treatment type"
                  treatments={treatmentTypes}
                  onSelect={(val) => handleTreatmentTypeChange(index, val)}
                />

                <Box width="60px" />

                {(!checkIfEmpty(
                  getTreatmentAddOnForConfirmedBooking({
                    selectedTreatmentId: detail.id,
                    treatmentsList: treatmentTypes,
                    proAddOns,
                    selectedCountry,
                  }),
                ) ||
                  showOtherAddOnOption) && (
                  <AddOnDropdownMenu
                    value={detail.value}
                    label="Treatment add-ons"
                    treatments={getTreatmentAddOnForConfirmedBooking({
                      selectedTreatmentId: detail.id,
                      treatmentsList: treatmentTypes,
                      proAddOns,
                      selectedCountry,
                    })}
                    onSelect={handleAddOnSelect}
                    treatmentIndex={index}
                    selectedAddOn={detail?.addons || []}
                    isMultipleAddonsAllowed={isMultipleAddOnAllowdForConfirmedBooking({
                      selectedTreatmentId: detail.id,
                      treatmentsList: treatmentTypes,
                    })}
                    showOtherAddOnOption={showOtherAddOnOption}
                    onNoneSelect={handleNoneSelect}
                  />
                )}

                <BookingQuestions
                  index={index}
                  questions={detail.questions}
                  setAnswer={handleAnswerChange}
                  treatmentId={detail.id}
                  currency={currency}
                />
              </Box>
              <Box marginBottom={"32px"} />
            </Box>
          );
        })}

        <BookingQuestions
          index={0}
          setAnswer={handleAnswerChange}
          questions={[...serviceQuestions]}
          currency={currency}
        />
      </Box>
    </Box>
  );
}

export default HairAndMakeupDetails;
