import { TABLE_OPTIONS } from "../constants/locationDetails";
import { Address } from "../models";
import { stringifyParams } from "./string";

export function stringForAddress(address: Address) {
  return address.fullAddress || `${address.address}, ${address.suburb} ${address.postcode}`;
}

export const stairsText = (value: string) => {
  switch (value) {
  case "few":
    return "with a few stairs";

  case "lots":
    return "with lots of stairs";

  default:
    return "no stairs";
  }
};

export const parkingOptionsText = (parking: string) => {
  switch (parking) {
  case "not-sure":
    return "not sure";
  case "no-parking":
    return "no parking";
  case "street-parking":
    return "street parking";
  case "free-parking":
    return "free parking";

  default:
    return "not sure";
  }
};

export const openMapUrl = (address: any = {}, forSearch = false) => {
  const baseUrl = forSearch
    ? "https://www.google.com/maps/search/"
    : "https://www.google.com/maps/dir/";
  const addressText =
    address.fullAddress || `${address.address}, ${address.suburb}, ${address.postcode}`;

  const params = forSearch
    ? {
      api: 1,
      query: addressText,
    }
    : {
      api: 1,
      destination: addressText,
      travelmode: "driving",
    };

  const url = `${baseUrl}?${stringifyParams(params)}`;
  window.open(url, "_blank");
};

