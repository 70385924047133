import { Box } from "@material-ui/core";
import React, { useEffect, useMemo } from "react";
import ContentSectionControl from "../../components/ContentSectionControl";
import ProDashboardHeader from "../../components/ProDashboard/ProDashboardHeader";
import Wrapper from "../../components/Wrapper";
import { useMobile } from "../../hooks/mobile";
import BillingSummary from "./Reports/BillingSummary";
import Overview from "./Reports/Overview";
import PaymentSummary from "./Reports/PaymentSummary";
import { useHistory, useLocation } from "react-router-dom";

const sections = ["Overview", "Payouts", "Billing"];

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const isMobile = useMobile();
  const [selectedSectionIndex, setSelectedSectionIndex] = React.useState(0);

  const location = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  useEffect(() => {
    const tab = queryParams.get("tab");

    if (!tab) {
      queryParams.set("tab", "overview");
      history.replace(`${location.pathname}?${queryParams.toString()}`);
      return;
    }

    const foundIndex = sections.findIndex((x) => x.toLowerCase() === tab);

    if (foundIndex === -1) {
      queryParams.set("tab", "overview");
      history.replace(`${location.pathname}?${queryParams.toString()}`);
      return;
    }

    setSelectedSectionIndex(foundIndex);
  }, [history, location.pathname, location.search, queryParams]);

  return (
    <>
      <Wrapper>
        <ProDashboardHeader section="Reports" />

        <Box mt="40px" ml={isMobile ? "16px" : "80px"} mr={isMobile ? "16px" : "80px"}>
          <ContentSectionControl
            sections={sections}
            selectedIndex={selectedSectionIndex}
            onSelectedIndex={(index) => {
              queryParams.set("tab", sections[index].toLowerCase());
              history.push(`${location.pathname}?${queryParams.toString()}`);
            }}
          />

          {selectedSectionIndex === 0 && <Overview />}

          {selectedSectionIndex === 1 && <PaymentSummary />}

          {selectedSectionIndex === 2 && <BillingSummary />}
        </Box>
      </Wrapper>
    </>
  );
}
