import React from "react";
import { Box } from "@material-ui/core";
import { Text } from "../../../../v2/Styled";
import * as Styled from "../../../../v2/Styled/enum";
import { Colors } from "../../../../../constants/colors";
import LockIcon from "../../../../../images/lock-icon-outlined.svg";
import TextWithLink from "../../../../Text/TextWithLink/TextWithLink";

interface Props {
  preText?: string;
  hideIcon?: boolean;
}

const StripeTos = ({ preText, hideIcon = false }: Props): JSX.Element => {
  const text = preText || "By linking your account";

  return (
    <Box display={Styled.Display.Flex} gridGap={Styled.Spacing.S2}>
      <Box
        display={hideIcon ? Styled.Display.None : Styled.Display.Flex}
        alignItems={Styled.AlignItems.center}
        justifyContent={Styled.JustifyContent.center}
        style={{ width: Styled.Spacing.S6, height: Styled.Spacing.S6 }}
      >
        <img src={LockIcon} alt="Lock icon" />
      </Box>
      <Box>
        <Text
          color={Colors.Grey}
          size={Styled.FontSize.F14}
          weight={Styled.FontWeight.Medium}
          lineHeight={Styled.LineHeight.L21}
        >
          {text}, you agree to Stripe's{" "}
          <TextWithLink
            linkText={"Terms"}
            style={{ display: "inline" }}
            link={"https://stripe.com/legal/linked-financial-accounts-merchant"}
          />{" "}
          and{" "}
          <TextWithLink
            linkText={"Privacy Policy"}
            style={{ display: "inline" }}
            link={"https://stripe.com/privacy"}
          />
          .
        </Text>
      </Box>
    </Box>
  );
};

export default StripeTos;
