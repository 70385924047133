import React from "react";
import { Box } from "@material-ui/core";
import RadioButton from "../../RadioButton";
import { Colors } from "../../../constants/colors";
import { checkIfObject, getValue } from "../../../utils/object";

interface Props {
  recipient:
    | string
    | {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        role: string;
      };
  selected: boolean;
  onSelect: (id?: number, role?: string | null) => unknown;
  title?: string;
}

const RecipientItem = ({ recipient, selected, onSelect }: Props): JSX.Element => {
  const isRecipientObject = checkIfObject(recipient);

  const handleOnSelect = () => {
    if (isRecipientObject) {
      onSelect(getValue(recipient, "id"), getValue(recipient, "role"));
    } else {
      onSelect();
    }
  };

  const name = isRecipientObject
    ? `${getValue(recipient, "firstName")} ${getValue(recipient, "lastName")}`
    : recipient;

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      gridGap={"16px"}
      style={{ borderBottom: `1px solid ${Colors.LightPeriwinkle}` }}
      mb={"32px"}
      py={"12px"}
    >
      <RadioButton
        style={{ width: "16px", height: "16px" }}
        selected={selected}
        onSelected={handleOnSelect}
      />
      <Box flexGrow={1}>
        <Box
          fontFamily={"Museo"}
          fontSize={"18px"}
          fontWeight={500}
          lineHeight={"150%"}
          color={Colors.Dusk}
          marginBottom={"4px"}
        >
          {name}
        </Box>
        {isRecipientObject ? (
          <Box
            fontFamily={"SF UI Text"}
            fontSize={"14px"}
            fontWeight={500}
            lineHeight={"130%"}
            color={Colors.Grey}
          >
            {getValue(recipient, "email")}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default RecipientItem;
