import { Box } from "@material-ui/core";
import { Colors } from "../../constants/colors";

interface Props {
  isExpired: boolean;
  expiryYear?: string;
  expiryMonth?: string;
  style?: object;
}

const ExpiryInfo = ({ isExpired, expiryYear, expiryMonth, style = {} }: Props) => {
  if (!expiryYear || !expiryMonth) return null;

  return (
    <Box
      fontFamily="Open Sans"
      fontSize={12}
      color={isExpired ? Colors.Crimson : Colors.BlueyGrey}
      style={{ ...style }}
    >
      Expiry {expiryMonth}/{(String(expiryYear) || "").slice(-2)}
      {isExpired ? " (Expired)" : ""}
    </Box>
  );
};

export default ExpiryInfo;
