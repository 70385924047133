import React from "react";
import { Box, Dialog, IconButton } from "@material-ui/core";
import { useMobile } from "../../hooks/mobile";
import InfoMobile from "../../images/afterpay-info-mobile.png";
import InfoDesktop from "../../images/afterpay-info-desktop.png";
import CloseIcon from "../../images/close.svg";

interface Props {
  open: boolean;
  onClose: () => unknown;
}

export default function AfterpayInfoModal({ open, onClose }: Props) {
  const isMobile = useMobile();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Box
        width={isMobile ? "327px" : "1200px"}
        height="878px"
        position="relative"
      >
        <img src={isMobile ? InfoMobile : InfoDesktop} />

        <IconButton
          style={{ zIndex: 999, position: "absolute", right: 4, top: 4 }}
          onClick={onClose}
        >
          <img src={CloseIcon} />
        </IconButton>
      </Box>
    </Dialog>
  );
}
