import React from "react";
import { Text } from "./v2/Styled";
import { Box } from "@material-ui/core";
import * as Styled from "./v2/Styled/enum";
import { Colors } from "../constants/colors";

interface Props {
  text: string;
  bgColor?: Colors;
  fontFamily?: Styled.FontFamily;
  fontColor?: Colors;
}

const StatusPill = ({
  text,
  bgColor = Colors.Pale,
  fontFamily = Styled.FontFamily.SFPro,
  fontColor = Colors.NightBlue,
}: Props): JSX.Element => {
  return (
    <Box
      bgcolor={bgColor}
      paddingX={Styled.Spacing.S3}
      paddingY={Styled.Spacing.S1}
      display={Styled.Display.Flex}
      borderRadius={Styled.Spacing.S6}
      alignItems={Styled.AlignItems.center}
    >
      <Text
        font={fontFamily}
        color={fontColor}
        size={Styled.FontSize.F12}
        lineHeight={Styled.LineHeight.L21}
        weight={Styled.FontWeight.SemiBold}
        letterSpacing={Styled.LetterSpacing.S05}
      >
        {text}
      </Text>
    </Box>
  );
};

export default StatusPill;
