import { useQuery } from "@tanstack/react-query";
import * as httpClient from "../../api/client";

const KEYS = {
  GET_TIME_RANGE: "GET_TIME_RANGE",
};

export const useGetTimeRange = () =>
  useQuery([KEYS.GET_TIME_RANGE], async () => {
    const res = await httpClient.get("api/v2/config/time-range");
    return res;
  });
