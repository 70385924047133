import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import TextButton from "../../TextButton/TextButton";
import { copyStringToClipboard } from "../../../utils/string";
import { useAlertStore } from "../../../stores/alert";

const ReferStats = ({ earned, referCount, referralCode }: any) => {

  const { setSuccessMessage } = useAlertStore();

  const data = [
    { label: "Total referred", value: referCount },
    { label: "Total earned", value: earned },
  ];

  const copyReferralCode = () => {
    copyStringToClipboard(referralCode);
    setSuccessMessage("Referral code copied to clipboard");
  };

  return <Box display={"flex"} flexDirection={"column"}>
    <Box style={styles.wrapper}>
      {data.map((field, index) => <Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent="space-between"
          paddingY={"8px"}
          paddingX={"16px"}
        >
          <Box style={{ ...styles.text, fontSize: "15px"}}>{field.label}</Box>
          <Box style={{ ...styles.text, ...styles.highlight }}>{field.value}</Box>
        </Box>
        {index !== data.length -1 ? <Box height={"1px"} bgcolor={"#DCDFE6"} marginX={"8px"}/> : <Box />}
      </Box>)}
    </Box>
    <Box height={"16px"} />
    {referralCode && <Box  style={styles.wrapper}>
      <Box paddingX={"24px"} paddingY={"16px"}>
        <Box style={{ ...styles.secondary }}>Code</Box>
        <Box display={"flex"} flexDirection={"row"} justifyContent="space-between" marginTop="8px" alignItems={"flex-end"}>
          <Box style={styles.referralCode}>{referralCode}</Box>
          <TextButton text="Copy code" onClick={copyReferralCode} textStyle={styles.clickable}  />
        </Box>
      </Box>
    </Box>}
  </Box>;
};

const styles = {
  wrapper: {
    border: "1px solid #DCDFE6",
    borderRadius: "8px",
  },
  text: {
    fontWeight: "300",
    fontFamily: "Open Sans",
  },
  secondary: {
    fontSize: "12px",
    fontWeight: 400,
    color: Colors.Grey,
    fontFamily: "Open Sans",
  },
  highlight: {
    fontSize: "15px",
    color: "#0DAEC7",
  },
  clickable: {
    color: "#0DAEC8",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    padding: 0,
  },
  referralCode: {
    color: Colors.Indigo,
    fontWeight: 600,
    fontFamily: "museo",
    fontSize: "22px",
  }
};

export default ReferStats;
