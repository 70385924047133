import { Box, Link } from "@material-ui/core";
import React from "react";
import HeroImage from "../images/no-bookings-placeholder.svg";
import { Colors } from "../constants/colors";
import { FontFamily, FontSize, FontWeight, Spacing, TextAlign } from "./v2/Styled/enum";

interface Props {
  sortOrEditFilter: (val: boolean) => unknown;
  text:string
}

export const NoResultPlaceholder = ({ sortOrEditFilter, text }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img src={HeroImage} alt="not found" />
      <Box
        fontFamily= {FontFamily.Museo}
        fontSize= {FontSize.F20}
        textAlign= {TextAlign.center}
        fontWeight= {FontWeight.Bold}
        color={Colors.Dusk}
        mt= {Spacing.S5}
      >
        {text}
      </Box>

      <Box
        fontFamily= {FontFamily.OpenSans}
        fontSize={FontSize.F14}
        color={Colors.Dusk}
        letterSpacing="0.39px"
        textAlign= {TextAlign.center}
        mt= {Spacing.S3}
      >
        Try changing your filter and sort or   <Link
          style={{ color: Colors.TurquoiseBlue, cursor: "pointer" }}
          onClick={() => sortOrEditFilter(true)}
        >
          clear all filter and sort.
        </Link>
      </Box>
    </Box>
  );
};
