import React from "react";
import { Box } from "@material-ui/core";
import TextLink from "../../../../components/TextLink";
import { checkIfEmpty } from "../../../../utils/object";
import { getFullUploadsUrl } from "../../../../utils/url";
import { PRO_DOCUMENT_TYPES, PRO_DOCUMENTS } from "../../../../constants/profile";

export interface ProDocs {
  id: number;
  type: (typeof PRO_DOCUMENT_TYPES)[keyof typeof PRO_DOCUMENT_TYPES];
  filePath: string;
}
interface Props {
  documents?: ProDocs[];
}

const ProvidersDocument = ({ documents = [] }: Props): JSX.Element => {
  const getLabel = (type: (typeof PRO_DOCUMENT_TYPES)[keyof typeof PRO_DOCUMENT_TYPES]) =>
    PRO_DOCUMENTS[type];
  const handleClick = ({ link }: { link: string }) => window.open(link, "_blank", "noreferrer");

  if (checkIfEmpty(documents)) {
    return <React.Fragment />;
  }

  return (
    <Box>
      {documents.map(({ id, type, filePath }: ProDocs) => {
        const link = getFullUploadsUrl(filePath);
        const label = getLabel(type);

        return (
          <Box mb="2px">
            <TextLink
              key={id}
              title={label}
              onClick={() => handleClick({ link })}
              fontSize={"14px"}
              fontWeight={700}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default ProvidersDocument;
