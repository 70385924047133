const transformProSchedules = (configuredProSchedules: any, defaultProSchedules: any) => {
  return defaultProSchedules.map((schedule: any) => {

    const configuredSchedule = configuredProSchedules.find((sch: any) => sch.weekDay == schedule.weekDay);

    if (configuredSchedule) {
      return {
        ...schedule,
        startTime: configuredSchedule.startTime,
        endTime: configuredSchedule.endTime,
        isActive: configuredSchedule.isActive,
      }
    }

    return schedule;
  });
};

const getFilteredTimeRanges = (baseTime: any, timeRanges: any, smallerValuesRequired = true) => {
  if(!baseTime) {
    return timeRanges;
  }

  const valueIndex = timeRanges.findIndex((time: { value: any; }) => time.value == baseTime);

  return smallerValuesRequired ? timeRanges.splice(0, valueIndex) : timeRanges.splice(valueIndex + 1, timeRanges.length);
};


export {
  transformProSchedules,
  getFilteredTimeRanges,
}
