import { Box, BoxProps } from "@material-ui/core";
import Snackbar, { SnackbarType } from "../components/Snackbar";
import { useAlertStore } from "../stores/alert";
import HeaderDecoImage from "../images/header-deco.svg";
import FooterDecoImage from "../images/footer-deco.svg";
import HeroImage from "../images/landing-hero.jpg";
import BlysLogoWhite from "../images/blys-logo-white.svg";
import Button, { ButtonType } from "../components/Button";
import { useMobile } from "../hooks/mobile";
import { LEARN_MORE_ABOUT_OFFICE_MASSAGE_URL } from "../constants/common";

interface Props extends BoxProps {
  showPromoImages?: boolean;
  marginTop?: string;
}

const LandingWrapper = ({
  showPromoImages = true,
  marginTop = "64px",
  children,
}: Props): JSX.Element => {
  const isMobile = useMobile();

  const { successMessage, setSuccessMessage, errorMessage, setErrorMessage } = useAlertStore();

  return (
    <>
      <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
        <Box
          position="relative"
          minHeight="100vh"
          height="100%"
          width="100%"
          display="flex"
          justifyContent={!showPromoImages ? "center" : undefined}
        >
          <img
            alt="header decorator"
            src={HeaderDecoImage}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              height: "110px",
            }}
          />
          {!isMobile && (
            <img
              alt="footer decorator"
              src={FooterDecoImage}
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                height: "110px",
              }}
            />
          )}

          {isMobile && (
            <Box mt={marginTop} ml="25px" mr="25px" >
              {children}
            </Box> 
          )}
          {!isMobile && showPromoImages && (
            <Box mt={marginTop} ml="144px" mr="55px" width={"500px"}>
              {children}
            </Box>
          )}
          {!isMobile && !showPromoImages && <Box mt={marginTop}>{children}</Box>}
        </Box>
        {/* Right hand side promo */}
        {showPromoImages && !isMobile && (
          <Box minWidth={"599px"} position="relative">
            <img
              alt="Hero"
              src={HeroImage}
              width="100%"
              height="100%"
              style={{ objectFit: "cover" }}
            />
            <Box
              position="absolute"
              top="65%"
              display="flex"
              alignItems="center"
              flexDirection="column"
              width="100%"
            >
              <img alt="logo" src={BlysLogoWhite} />
              <Box
                fontFamily="Museo"
                fontSize="28px"
                fontWeight={700}
                color="white"
                textAlign="center"
                width="390px"
                mt={3.5}
                mb={3.5}
              >
                Organise a Blys massage in the comfort of your office
              </Box>
              <Button
                title="Learn more"
                type={ButtonType.outlinedWhite}
                width="149px"
                onClick={() => {
                  window.open(LEARN_MORE_ABOUT_OFFICE_MASSAGE_URL, "_blank");
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Snackbar
        type={SnackbarType.error}
        message={errorMessage}
        open={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />

      <Snackbar
        type={SnackbarType.success}
        open={successMessage !== null}
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </>
  );
};

export default LandingWrapper;
