const config = require("../../config/proDashboard/dashboardConfig.json");

export const getLegalConfig = (country: string = "*") => {
  const dashboardConfig = config[country];
  const globalConfig = config["*"];

  return (
    dashboardConfig?.proDashboard?.business?.legal || globalConfig?.proDashboard?.business?.legal
  );
};
