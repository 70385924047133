const limitDecimals = (number: number | string, limit = 2, returnNumeric = true) => {
  const floatNum = typeof number === "number" ? number : parseFloat(number);

  if (Number.isNaN(floatNum)) return 0;

  const limitedResult = floatNum.toFixed(limit);
  return returnNumeric ? +limitedResult : limitedResult;
};

export { limitDecimals };
