import React from "react";
import { Box } from "@mui/material";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import {
  StripeElementChangeEvent,
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js";
import * as Styled from "../../v2/Styled/enum";
import { Colors } from "../../../constants/colors";

export const CARD_DETAILS_FIELD = {
  NUMBER: "number",
  EXPIRY: "expiry",
  CVC: "cvc",
} as const;

export type StripeCardFieldType = (typeof CARD_DETAILS_FIELD)[keyof typeof CARD_DETAILS_FIELD];

interface Props {
  title: string;
  errorMessage: string;
  field: StripeCardFieldType;
  onChange: (field: StripeCardFieldType, event: StripeElementChangeEvent) => void;
}

const StripeCardField = ({ title, field, onChange, errorMessage }: Props) => {
  const getOptions = (field: StripeCardFieldType) => {
    const style = {
      base: {
        color: Colors.TurquoiseBlue,
        fontSize: Styled.FontSize.F18,
        fontFamily: "'Museo 500', sans-serif", // Using 'Museo' here does not work for some reason.
        // frontUrl:
        //   "url(https://getblys-storage.s3.ap-southeast-2.amazonaws.com/fonts/Museo500-Regular.woff2)", // URL if needed
      },
      invalid: {
        color: Colors.Tomato,
        iconColor: Colors.Tomato,
      },
    };

    switch (field) {
    case CARD_DETAILS_FIELD.NUMBER:
      return {
        showIcon: true,
        iconStyle: "default",
        style,
      } as StripeCardNumberElementOptions;
    case CARD_DETAILS_FIELD.EXPIRY:
      return {
        style,
      } as StripeCardExpiryElementOptions;
    case CARD_DETAILS_FIELD.CVC:
      return {
        style,
      } as StripeCardCvcElementOptions;
    }
  };

  const getComponent = (field: StripeCardFieldType) => {
    switch (field) {
    case CARD_DETAILS_FIELD.NUMBER:
      return CardNumberElement;
    case CARD_DETAILS_FIELD.EXPIRY:
      return CardExpiryElement;
    case CARD_DETAILS_FIELD.CVC:
      return CardCvcElement;
    }
  };

  const options = getOptions(field);
  const Component = getComponent(field);

  return (
    <Box
      flex={1}
      gap={Styled.Spacing.S2}
      display={Styled.Display.Flex}
      flexDirection={Styled.FlexDirection.Column}
    >
      <Box
        color={Colors.Dusk}
        fontSize={Styled.FontSize.F14}
        lineHeight={Styled.LineHeight.L21}
        fontWeight={Styled.FontWeight.Bold}
        fontFamily={Styled.FontFamily.Museo}
      >
        {title}
      </Box>
      <Box borderBottom={`1px solid ${Colors.LightPeriwinkle}`} paddingY={Styled.Spacing.S3}>
        <Component options={options} onChange={(e) => onChange(field, e)} />
      </Box>
      {!!errorMessage ? (
        <Box
          fontFamily={Styled.FontFamily.OpenSans}
          color={Colors.Tomato}
          fontSize={Styled.FontSize.F14}
        >
          {errorMessage}
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default StripeCardField;
