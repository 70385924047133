import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  titleText?: string;
  descText?: string;
  nameText?: string;
  imgUrl?: string;
}

export default function TitleMetaChange({
  titleText = "Book Wellness At Home | Blys",
  descText = "Enjoy massage, beauty, fitness and more in the comfort of your home, hotel or work with Blys, the #1 wellness app.",
  nameText = "Mobile Massage Sydney &amp; Home Massage Sydney | Blys",
  imgUrl = "https://static.getblys.com.au/wp-content/uploads/2019/01/Google-Play-Feature-Graphic.png",
}: Props): JSX.Element {
  console.log("META titleText", titleText);
  console.log("META descText", descText);
  console.log("META nameText", nameText);
  console.log("META imgUrl", imgUrl);

  return (
    <Helmet>
      <title>{titleText}</title>
      <meta itemProp="name" content={nameText} />
      <meta itemProp="image" content={imgUrl} />
      <meta name="description" content={descText} />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:image:secure_url" content={imgUrl} />
      <meta property="og:description" content={descText} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={descText} />
      <meta name="twitter:title" content={titleText} />
      <meta name="twitter:site" content="@getblys" />
      <meta name="twitter:image" content={imgUrl} />
      <meta name="twitter:creator" content="@getblys" />
    </Helmet>
  );
}
