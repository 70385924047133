import {
  Box,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import "./disclaimerModal.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
}

function DisclaimerModal(props: Props) {
  const isMobile = useMobile();

  const [hasAgreed, setHasAgreed] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleClose = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    props.onClose();
  };

  useEffect(() => {
    if (hasAgreed && showError) {
      setShowError(false);
    }
  }, [hasAgreed, showError]);

  const toggleHasAgreed = () => {
    setHasAgreed((value) => !value);
  };

  const handleNext = () => {
    if (!hasAgreed) {
      setShowError(true);
      return;
    }

    props.onNext();
  };

  return (
    <MUIDialog open={props.isOpen} onClose={handleClose}>
      <Box className="modal">
        <Box display="flex" alignItems="center" position="relative">
          <DialogTitle style={{ flex: 1, padding: 0 }}>
            <Typography>
              <Box className="modalTitle">Message from Blys</Box>
            </Typography>
          </DialogTitle>
        </Box>
        <DialogContent style={{ padding: 0 }}>
          <Box
            style={{ color: Colors.BlueyGrey }}
            className="modalDescription"
            mr={isMobile ? "16px" : "55px"}
            ml={isMobile ? "16px" : "55px"}
          >
            <Box mb="6px" mt="6px">
              We care deeply about the safety of our community and want all
              users of the Blys platform to experience a community of mutual
              respect.
            </Box>
            <Box mb="6px" mt="6px">
              There is no tolerance for inappropriate behaviour on behalf of
              Providers or clients, including verbal comments, non-verbal
              gestures and inappropriate nudity or disrobing. Underwear must be
              worn by clients at all times.
            </Box>
            <Box mb="6px" mt="6px">
              Blys reserves the right to take appropriate action in any such
              cases.
            </Box>
            <Box className="action">
              <input
                type="checkbox"
                checked={hasAgreed}
                onClick={toggleHasAgreed}
                className="checkbox"
              />
              <span onClick={toggleHasAgreed} className="agreeText">
                I have read and agree the terms
              </span>
            </Box>

            {showError && (
              <div className="errorMessage">
                {" "}
                Please agree to the terms before proceeding with your booking
              </div>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          className="disclaimerFooter"
          style={{
            paddingTop: 10,
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16,
          }}
        >
          <Button
            onClick={handleNext}
            style={{
              color: Colors.Dusk,
              background: Colors.White,
            }}
          >
            Next
          </Button>
        </DialogActions>
      </Box>
    </MUIDialog>
  );
}

export default DisclaimerModal;
