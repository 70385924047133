interface iPagination {
  perPage: number;
  totalCount: number;
  page: number;
  [data: string]: any;
}

export const transformPagination = (data: iPagination) => {
  const { perPage = 1, totalCount = 0 } = data;

  let totalPages;

  if (data.totalPage) {
    totalPages = data.totalPage;
  } else {
    totalPages = Math.ceil(totalCount / perPage) || 1;
  }
  return {
    ...data,
    totalPages,
  };
};
