import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Button, { ButtonSize, ButtonType } from "../Button";
import ModalFooter from "../ModalFooter";
import { Box } from "@material-ui/core";
import { useAlertStore } from "../../stores/alert";
import { PaymentMethod } from "../../models";
import { useStripeStore } from "../../stores/stripe";
import { post } from "../../api/client";

interface Props {
  isMobile: boolean;
  onClose: () => unknown;
  onAddedNewCard: (method: PaymentMethod) => unknown;
  accessToken: string | null;
}

export default function PaymentFormStripe({
  isMobile,
  onClose,
  onAddedNewCard,
  accessToken,
}: Props): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();

  const { setErrorMessage } = useAlertStore();
  const { resetClientSecret } = useStripeStore();
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setAdding(true);
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (setupIntent) {
      post("api/v2/stripe/payment-method", {
        paymentMethodId: setupIntent.payment_method,
      })
        .then((response) => {
          setAdding(false);
          onAddedNewCard(response.payment);
          resetClientSecret();
        })
        .catch(() => {
          setAdding(false);
          setErrorMessage("Please check your card details.");
        });
    }

    if (error && error.message) {
      setErrorMessage(error.message);
      setAdding(false);
    }
  };

  return (
    <>
      <form id="my-sample-form" className="scale-down">
        <Box padding="40px">
          <PaymentElement />
        </Box>
        <ModalFooter>
          {!isMobile && (
            <>
              <Button
                size={ButtonSize.small}
                width="130px"
                type={ButtonType.outlined}
                title="Back"
                onClick={onClose}
              />
              <Box width="14px" />
            </>
          )}
          <Button
            size={ButtonSize.small}
            width="130px"
            title="Add card"
            onClick={handleSubmit}
            loading={adding}
          />
        </ModalFooter>
      </form>
    </>
  );
}
