import axios from "axios";
import create from "zustand";
import { User, UserService } from "../models";

interface SectionDetail {
  label: string;
  value: string;
}

type State = {
  therapistUser: User | null;
  customServices: UserService[];
  proDashSections: SectionDetail[],
  setCustomServices: (services: UserService[]) => unknown;
  setProDashSections: (sections: SectionDetail[]) => unknown;
};

export const useTherapistStore = create<State>((set) => ({
  therapistUser: null,
  customServices: [],
  setCustomServices: (customServices: UserService[]) => {
    set({ customServices });
  },
  proDashSections: [],
  setProDashSections: (sections: SectionDetail[]) => {
    set({ proDashSections: sections });
  },
}));

export function fetchMyTherapistProfile() {
  let accessToken = localStorage.getItem("token");

  if (accessToken) {
    axios
      .get("/therapists/me/profile", {
        params: {
          accessToken,
        },
      })
      .then((response) => {
        if (response.data) {
          const user = response.data as User;

          useTherapistStore.setState({
            therapistUser: user,
          });
        }
      })
      .catch((error) => {
        console.debug("error: ", error);
      });
  }
}
