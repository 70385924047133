import axios from "axios";
import create from "zustand";

type State = {
  clientSecret: string | null;
  createSetupIntent: () => unknown;
  resetClientSecret: () => unknown;
};

export const useStripeStore = create<State>((set) => ({
  clientSecret: null,
  createSetupIntent: () => {
    const accessToken = localStorage.getItem("token");

    if (accessToken) {
      axios
        .post("/api/v2/stripe/setup-intent", {
          accessToken,
        })
        .then((response) => {
          set({
            clientSecret: response.data.clientSecret,
          });
        })
        .catch((error) => {
          console.debug("fetch client secret error: ", error);
        });
    }
  },
  resetClientSecret: () => {
    set({ clientSecret: null});
  }
}));
