const weekDays = [
  {
    title: "Sun",
    value: 0,
  },
  {
    title: "Mon",
    value: 1,
  },
  {
    title: "Tue",
    value: 2,
  },
  {
    title: "Wed",
    value: 3,
  },
  {
    title: "Thr",
    value: 4,
  },
  {
    title: "Fri",
    value: 5,
  },
  {
    title: "Sat",
    value: 6,
  },
];

const timeRanges = [
  {
    title: "6am",
    value: 6,
  },
  {
    title: "7am",
    value: 7,
  },
  {
    title: "8am",
    value: 8,
  },
  {
    title: "9am",
    value: 9,
  },
  {
    title: "10am",
    value: 10,
  },
  {
    title: "11am",
    value: 11,
  },
  {
    title: "12pm",
    value: 12,
  },
  {
    title: "1pm",
    value: 13,
  },
  {
    title: "2pm",
    value: 14,
  },
  {
    title: "3pm",
    value: 15,
  },
  {
    title: "4pm",
    value: 16,
  },
  {
    title: "5pm",
    value: 17,
  },
  {
    title: "6pm",
    value: 18,
  },
  {
    title: "7pm",
    value: 19,
  },
  {
    title: "8pm",
    value: 20,
  },
  {
    title: "9pm",
    value: 21,
  },
  {
    title: "10pm",
    value: 22,
  },
  {
    title: "11pm",
    value: 23,
  },
  {
    title: "12am",
    value: 24,
  },
];

const bufferTimeRanges = [
  {
    title: "0min",
    value: "0",
  },
  {
    title: "15mins",
    value: "15",
  },
  {
    title: "30mins",
    value: "30",
  },
  {
    title: "45mins",
    value: "45",
  },
  {
    title: "60mins",
    value: "60",
  },
  {
    title: "75mins",
    value: "75",
  },
  {
    title: "90mins",
    value: "90",
  },
  {
    title: "105mins",
    value: "105",
  },
  {
    title: "120mins",
    value: "120",
  },
];

export { weekDays, timeRanges, bufferTimeRanges };
