import { useMutation } from "@tanstack/react-query";
import * as httpClient from "../../api/client";

export const useUserPreference = () => {
  const { isLoading, mutate } = useMutation((body: any) => {
    const includeToken = true;
    return httpClient.post("users/me/preferences", body, includeToken);
  });
  return { isLoading, updatePreference: mutate };
};
