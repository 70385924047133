import React, { useState, useEffect } from "react";
import { Box, Checkbox } from "@material-ui/core";
import { Colors } from "../../../../constants/colors";
import Dropdown from "../../../../components/Dropdown";
import { getTherapistBufferTime, updateTherapistBufferTime } from "./apiCall";
import { bufferTimeRanges } from "../config";

interface BufferTimeParams {
  bufferTimeBeforeBooking: string;
  bufferTimeAfterBooking: string;
  isBufferBeforeActive: boolean;
  isBufferAfterActive: boolean;
}

const BufferTime = () => {
  const [bufferTimeBeforeBooking, setBufferTimeBeforeBooking] = useState({
    title: bufferTimeRanges[1].title,
    value: bufferTimeRanges[1].value,
  });

  const [bufferTimeAfterBooking, setBufferTimeAfterBooking] = useState({
    title: bufferTimeRanges[1].title,
    value: bufferTimeRanges[1].value,
  });

  const [isBufferBeforeActive, setIsBufferBeforeActive] = useState(true);

  const [isBufferAfterActive, setIsBufferAfterActive] = useState(true);

  const handleBufferTimeChange = async ({
    bufferTimeBeforeBooking,
    bufferTimeAfterBooking,
    isBufferBeforeActive,
    isBufferAfterActive,
  }: BufferTimeParams) => {
    const body = {
      bufferTimeBefore: bufferTimeBeforeBooking ? bufferTimeBeforeBooking : null,
      bufferTimeAfter: bufferTimeAfterBooking ? bufferTimeAfterBooking : null,
      isBufferBeforeActive,
      isBufferAfterActive,
    };

    try {
      await updateTherapistBufferTime(body);
    } catch (error) {
      getProBufferTime();
    }
  };

  const onBeforeCheckBoxClick = () => {
    handleBufferTimeChange({
      bufferTimeBeforeBooking: bufferTimeBeforeBooking.value,
      bufferTimeAfterBooking: bufferTimeAfterBooking.value,
      isBufferBeforeActive: !isBufferBeforeActive,
      isBufferAfterActive,
    });
    setIsBufferBeforeActive(!isBufferBeforeActive);
  };

  const onAfterCheckBoxClick = () => {
    handleBufferTimeChange({
      bufferTimeBeforeBooking: bufferTimeBeforeBooking.value,
      bufferTimeAfterBooking: bufferTimeAfterBooking.value,
      isBufferBeforeActive,
      isBufferAfterActive: !isBufferAfterActive,
    });
    setIsBufferAfterActive(!isBufferAfterActive);
  };

  const handleBeforeBookingChange = (option: { title: string; value: string }) => {
    setBufferTimeBeforeBooking(option);
    handleBufferTimeChange({
      bufferTimeBeforeBooking: option.value,
      bufferTimeAfterBooking: bufferTimeAfterBooking.value,
      isBufferBeforeActive,
      isBufferAfterActive,
    });
  };

  const handleAfterBookingChange = (option: { title: string; value: string }) => {
    setBufferTimeAfterBooking(option);
    handleBufferTimeChange({
      bufferTimeBeforeBooking: bufferTimeBeforeBooking.value,
      bufferTimeAfterBooking: option.value,
      isBufferBeforeActive,
      isBufferAfterActive,
    });
  };

  const getProBufferTime = async () => {
    const response = await getTherapistBufferTime();
    if (response?.data) {
      const data = response.data;
      setBufferTimeBeforeBooking({
        title: `${data.bufferTimeBefore}min`,
        value: String(data.bufferTimeBefore),
      });
      setBufferTimeAfterBooking({
        title: `${data.bufferTimeAfter}min`,
        value: String(data.bufferTimeAfter),
      });
      setIsBufferAfterActive(data?.isBufferAfterActive);
      setIsBufferBeforeActive(data?.isBufferBeforeActive);
    }
  };

  useEffect(() => {
    getProBufferTime();
  }, []);

  return (
    <Box mt="30px" mb={4}>
      <Box color={Colors.Dusk} fontSize="16px" fontWeight={"500"} lineHeight="24px">
        Add buffer time between your bookings to allow for travel and preparation.
      </Box>
      <Box style={styles.dayWrapper} alignItems="center" mt={1}>
        <Box style={styles.day}>
          <Checkbox
            style={{
              color: isBufferBeforeActive ? Colors.TurquoiseBlue : Colors.BlueyGrey,
              margin: "0px 8px 0px 0px",
              width: 16,
              height: 16,
              border: 2,
              borderRadius: 4,
              padding: 0,
            }}
            checked={isBufferBeforeActive}
            onClick={onBeforeCheckBoxClick}
          />
          Before booking
        </Box>
        <Box style={styles.timeSelect}>
          <Dropdown
            options={bufferTimeRanges}
            onSelectedOption={(option) => {
              handleBeforeBookingChange(option);
            }}
            selectedOption={bufferTimeRanges.find((val) => val.value === bufferTimeBeforeBooking.value)}
            isDropdownDirectionUp={true}
            isDisabled={!isBufferBeforeActive}
          />
        </Box>
      </Box>

      <Box style={styles.dayWrapper} alignItems="center">
        <Box style={styles.day}>
          <Checkbox
            style={{
              color: isBufferAfterActive ? Colors.TurquoiseBlue : Colors.BlueyGrey,
              margin: "0px 8px 0px 0px",
              width: 16,
              height: 16,
              border: 2,
              borderRadius: 4,
              padding: 0,
            }}
            checked={isBufferAfterActive}
            onClick={onAfterCheckBoxClick}
          />
          After booking
        </Box>
        <Box style={styles.timeSelect}>
          <Dropdown
            options={bufferTimeRanges}
            onSelectedOption={(option) => {
              handleAfterBookingChange(option);
            }}
            selectedOption={bufferTimeRanges.find((val) => val.value === bufferTimeAfterBooking.value)}
            isDropdownDirectionUp={true}
            isDisabled={!isBufferAfterActive}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  dayWrapper: {
    display: "flex",
  },
  day: {
    display: "flex",
    fontFamily: "Museo",
    fontSize: "16px",
    alignItems: "center",
    width: "280px",
    color: "#41506F",
  },
  timeSelect: {
    fontFamily: "Museo",
    fontSize: "14px",
    width: "250px",
    padding: "0px 15px",
  },
};

export default BufferTime;
