import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { getValue } from "../../utils/object";
import { BASE_UPLOADS_URL } from "../../constants/common";

export interface Image {
  id: string | number;
  isLocallyUploaded?: boolean;
  filePath: string;
  imageFileToUpload: File;
}
interface Props {
  image: Image;
  onRemove: (removedImages: Image) => unknown;
}
export const RemoveableImage = ({ image, onRemove }: Props) => {
  const isLocallyUploaded = getValue(image, "isLocallyUploaded", false);
  const imagePath = isLocallyUploaded ? image.filePath : `${BASE_UPLOADS_URL}/${image.filePath}`;

  return (
    <Box style={removableImageStyles.imageContainer} position={"relative"}>
      <Box style={removableImageStyles.imageStyle} boxSizing={"border-box"}>
        <img
          src={imagePath}
          width={"100%"}
          height={"100%"}
          alt="review"
          style={{
            objectFit: "cover",
          }}
        />
      </Box>
      <Box
        style={removableImageStyles.cancleButton}
        position="absolute"
        onClick={() => onRemove(image)}
      >
        <Box style={closeButtonStyle.closeLeft as React.CSSProperties}></Box>
        <Box style={closeButtonStyle.closeRight as React.CSSProperties}></Box>
      </Box>
    </Box>
  );
};

const removableImageStyles = {
  imageContainer: {
    height: "120px",
    width: "120px",
  },
  imageStyle: {
    height: "100%",
    width: "100%",
    borderRadius: "8px",
    overflow: "hidden",
    zIndex: 1,
  },

  cancleButton: {
    top: -5,
    right: -5,
    zIndex: 999,
    borderRadius: "50%",
    height: "17px",
    width: "17px",
    backgroundColor: "rgba(151, 154, 162, 0.7)",
    cursor: "pointer",
  },
  closeBase: {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderColor: "#FFF",
    borderWidth: 1,
    width: 9,
    height: 2,
    backgroundColor: "#FFF",
    zIndex: 2,
    borderRadius: 8,
  },
  crossIcon: {
    width: 12,
    height: 12,
    color: "#FFF",
  },
};

const closeButtonStyle = {
  closeLeft: {
    ...removableImageStyles.closeBase,
    transform: "translate(-50%, -50%) rotate(-45deg)",
  },
  closeRight: {
    ...removableImageStyles.closeBase,
    transform: "translate(-50%, -50%) rotate(45deg)",
  },
};
