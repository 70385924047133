import { Box } from "@material-ui/core";
import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import Button, { ButtonType } from "../../../../components/Button";
import { Colors } from "../../../../constants/colors";
import Dropdown, { DropdownOption } from "../../../../components/Dropdown";
import { DECLINED_REASONS_VALUE, POSSIBLE_REASONS_TO_DECLINE } from "../../../../constants/job";
import { useEffect, useState } from "react";
import TextField from "../../../../components/TextField";
import UpdateServiceModal from "./UpdateServiceModal";
import { useMobile } from "../../../../hooks/mobile";
import Dialog from "../../../../components/Dialog";

interface Props {
  visible: boolean;
  onConfirm: ({ reason, customReason }: { reason: string; customReason: string }) => unknown;
  onClose: () => unknown;
}

const DeclineJobModal = ({ visible, onConfirm, onClose }: Props) => {
  const [selectedReason, setSelectedReason] = useState<DropdownOption | null>(null);
  const [customReason, setCustomReason] = useState("");
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [isDropdown, setIsDropdown] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    if (visible) {
      setSelectedReason(null);
      setCustomReason("");
    }
  }, [visible]);

  useEffect(() => {
    const formattedOptions: DropdownOption[] = POSSIBLE_REASONS_TO_DECLINE.map(
      ({ label, value }) => ({
        value,
        title: label,
      })
    );
    setOptions(formattedOptions);
  }, []);

  const handleOptionSelected = (option: DropdownOption) => {
    setSelectedReason(option);
  };

  const allowCustomReason = selectedReason && selectedReason.value === DECLINED_REASONS_VALUE.OTHER;
  const disableConfirm =
    !selectedReason ||
    (selectedReason.value === DECLINED_REASONS_VALUE.OTHER && (customReason || "").length <= 0);

  const handleDecline = () => {
    if (!selectedReason) return;

    onConfirm({ reason: selectedReason.value, customReason });
  };

  return (
    <Dialog
      open={visible}
      onClose={(e: MouseEvent) => {
        onClose();
      }}
      fullWidth={isMobile}
    >
      <Box padding={"30px 48px"} height={isDropdown ? "600px" : ""}>
        <Box width={isMobile ? "100%" : "352px"} display="flex" flexDirection="column">
          <Box
            fontSize="16px"
            mt="16px"
            mb="5px"
            fontFamily="Museo"
            fontWeight={700}
            color={Colors.NightBlue}
          >
            Are you sure you want to decline? This job will be removed from your app.
          </Box>

          <Dropdown
            title="Reason for declining"
            options={options}
            selectedOption={selectedReason}
            onSelectedOption={handleOptionSelected}
            setIsDropdown={setIsDropdown}
          />

          {allowCustomReason && (
            <Box>
              <TextField
                value={customReason}
                placeholder="Add your reason here"
                onChange={(value) => setCustomReason(value)}
              />
            </Box>
          )}
        </Box>
        <Box
          style={{
            flex: 1,
            gap: "8px",
            display: "flex",
            flexDirection: "row",
            padding: "0px 16px 16px",
          }}
        >
          <Button title="Cancel" type={ButtonType.outlined} onClick={onClose} />
          <Button
            title="Confirm"
            disabled={disableConfirm}
            type={ButtonType.secondary}
            onClick={handleDecline}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeclineJobModal;
