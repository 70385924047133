import { Box, ButtonBase } from "@material-ui/core";
import React from "react";

import { Colors } from "../../../constants/colors";
import { Display, FlexDirection, Spacing } from "../../v2/Styled/enum";

interface Props {
  tabStyle: any;
  sections: Array<string>;
  selectedIndex: number;
  wrapperStyle?: any;
  onSelectedIndex: (index: number) => unknown;
}

const titleStyle = (selected: boolean) => {
  return {
    fontFamily: "Museo",
    fontWeight: selected ? 700 : 500,
    fontSize: "16px",
    color: selected ? Colors.NightBlue : Colors.SlateGrey,
  };
};

export default function StaticSectionControl({
  sections,
  selectedIndex,
  onSelectedIndex,
  tabStyle = {},
  wrapperStyle = {}
}: Props): JSX.Element {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        ...wrapperStyle,
      }}
      ref={containerRef}
    >
      {sections.map((section, index) => {
        const isActive = index === selectedIndex;
        return (
          <ButtonBase
            style={{ flex: 1 }}
            onClick={() => {
              onSelectedIndex(index);
            }}
          >
            <Box
              style={{ width: "100%", ...tabStyle }}
              paddingBottom={Spacing.S3}
              borderBottom={`3px solid ${isActive ? Colors.TurquoiseBlue: Colors.LightPeriwinkle}`}
            >
              <Box
                style={titleStyle(selectedIndex === index)}
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
              >
                {section}
              </Box>
            </Box>
          </ButtonBase>
        );
      })}
    </div>
  );
}
