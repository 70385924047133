import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";
import LockedIcon from "../images/lock-icon.svg";
import ProfileEditIcon from "../images/profile-picture-edit.svg";
const ProfileEdit = ({ isLocked }: { isLocked?: boolean }) => {
  return isLocked ? (
    <Box style={styles.locked}>
      <img src={LockedIcon} style={styles.lockedImg} alt={"lock"}/>
    </Box>
  ) : (
    <img src={ProfileEditIcon} style={styles.editable} alt="profile" />
  );
};

const styles: Record<string, React.CSSProperties> = {
  locked: {
    top: "-24px",
    left: "-20px",
    margin: "10px",
    width: "40px",
    height: "40px",
    position: "absolute",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    background: Colors.White,
    boxShadow: "0px 4px 10px 0px rgba(15, 7, 47, 0.20)",
  },
  lockedImg: { opacity: 0.7 },
  editable: { position: "absolute", top: -20, left: -20 },
};

export default ProfileEdit;
