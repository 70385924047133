import { Colors } from "../../constants/colors";

const styles = {
  wrapper: {
    left: 0,
    bottom: 0,
    width: "100vw",
    display: "flex",
    flexDirection: "column" as "column",
    backgroundColor: Colors.White,
    position: "fixed" as "fixed",
  },
  footerWrapper: {
    height: "100%",
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
  },
  buttonsWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row" as "row",
  }
};

export default styles;