/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Box } from "@material-ui/core";
import TextField from "./TextField";
import Dropdown, { DropdownOption } from "./Dropdown";
import countryCodesData from "../data/countryCodes.json";
import { replace } from "lodash";

interface Props {
  countryCode: string | null;
  mobile: string | null;
  onCountryCodeChange: (code: string) => unknown;
  onMobileChange: (mobile: string) => unknown;
  mobileFieldTitle?: string;
  width?: string | number;
}

interface CountryCodeOption {
  name: string;
  dial_code: string;
  code: string;
}
const countryCodeOptions = countryCodesData as CountryCodeOption[];
const countryCodeDropdownOptions = countryCodeOptions.map((option) => {
  return {
    value: option.dial_code,
    title: `${option.code} (${option.dial_code})`,
  } as DropdownOption;
});

export default function ({
  countryCode,
  mobile,
  onCountryCodeChange,
  onMobileChange,
  mobileFieldTitle = "Mobile",
  width = "100%",
}: Props): JSX.Element {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" width={width}>
      <Box width="40%">
        <Dropdown
          title="Code"
          options={countryCodeDropdownOptions}
          onSelectedOption={(option) => onCountryCodeChange(option.value)}
          selectedOption={countryCodeDropdownOptions.find((option) => option.value === countryCode)}
        />
      </Box>

      <Box width="20px" />
      <TextField
        title={mobileFieldTitle}
        value={mobile}
        onChange={(_text = "") => {
          const text = replace(_text, / /g, ""); // Remove spaces first\
          const re = /^[0-9\b]+$/;
          if (text === "" || re.test(text)) {
            onMobileChange(_text);
          }
        }}
        placeholder="400 000 000"
        type="tel"
      />
    </Box>
  );
}
