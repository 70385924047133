import { Box, Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button, { ButtonType } from "../components/Button";
import LandingWrapper from "../components/LandingWrapper";
import LogoLink from "../components/LogoLink";
import { Colors } from "../constants/colors";
import { useMobile } from "../hooks/mobile";
import logo from "../images/blys-logo.svg";
import SuccessTick from "../images/success-tick.svg";

export default function (): JSX.Element {
  const history = useHistory();
  const { state } = useLocation();
  const isMobile = useMobile();

  const email = get(state, "email");

  return (
    <LandingWrapper>
      <Box
        width={isMobile ? undefined : "400px"}
        display="flex"
        flexDirection="column"
      >
        <LogoLink />
        <img
          alt="success"
          src={SuccessTick}
          width="59px"
          height="52px"
          style={{ marginTop: "48px" }}
        />

        <Typography
          variant="h1"
          style={{ color: Colors.NightBlue, marginTop: "32px" }}
        >
          Instructions sent!
        </Typography>
        <Typography
          variant="h3"
          style={{ color: Colors.Dusk, marginTop: "18px" }}
        >
          Password reset instructions have been sent to {email}
        </Typography>

        <Box mt="44px">
          <Button
            title="Back to login"
            type={ButtonType.primary}
            onClick={() => history.push("/login")}
          />
          <Box height="9px" />
          <Button
            title="Try again"
            type={ButtonType.outlined}
            onClick={() => history.push("/forgot-password")}
          />
        </Box>
        <Box
          mt="23px"
          fontFamily="Museo"
          fontWeight={700}
          fontSize="16px"
          color={Colors.Dusk}
        >
          Didn't receive an email?
        </Box>
        <Box
          fontFamily="Open Sans"
          fontSize="14px"
          color={Colors.BlueyGrey}
          mt={1}
        >
          Havent received the email? Try checking your spam or social folder
          before resending
        </Box>
      </Box>
    </LandingWrapper>
  );
}
