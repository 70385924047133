import { Box, Checkbox } from "@material-ui/core";
import axios from "axios";
import React, { useEffect } from "react";
import { Colors } from "../../../constants/colors";
import { parseApiError } from "../../../helpers/error";
import { useAccessToken } from "../../../hooks/common";
import { useAlertStore } from "../../../stores/alert";
import { useTherapistStore } from "../../../stores/therapist";

export default function Legal({ legalConfig }: any) {
  const { therapistUser } = useTherapistStore();
  const accessToken = useAccessToken();
  const { setErrorMessage } = useAlertStore();
  const [isTermsAccepted, setTermsAccepted] = React.useState(false);

  useEffect(() => {
    if (therapistUser?.therapistprofile) {
      const { isTermsAccepted = true } = therapistUser.therapistprofile;

      setTermsAccepted(isTermsAccepted);
    }
  }, [therapistUser]);

  return (
    <Box maxWidth="632px" pb={20}>
      <Box fontFamily="Museo" fontSize="24px" fontWeight={600} color={Colors.NightBlue} mt="56px">
        Terms and policies
      </Box>

      <Box
        fontFamily="Museo"
        fontSize="16px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="24px"
        lineHeight="24px"
      >
        Blys is committed to creating inclusive and positive experience for all users, if you have
        any questions, please contact{" "}
        <a
          href="mailto:legal@getblys.com"
          style={{ color: Colors.TurquoiseBlue, textDecoration: "none" }}
        >
          legal@getblys.com
        </a>
      </Box>
      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="18px"
        lineHeight="27px"
        color={Colors.Dusk}
        mt="32px"
        mb="24px"
      >
        Important documents
      </Box>
      <Box
        fontFamily="Museo"
        fontSize="16px"
        fontWeight={400}
        color={Colors.Dusk}
        mt="24px"
        mb="24px"
        lineHeight="24px"
      >
        Providers are required to read and agree to our terms and policies before using the Blys
        platform.
      </Box>
      <Box>
        <Box
          fontFamily="Museo"
          fontWeight={400}
          fontSize="16px"
          mb="8px"
          color={Colors.TurquoiseBlue}
        >
          <a
            style={{ color: Colors.TurquoiseBlue, textDecoration: "none" }}
            href="https://getblys.com.au/terms/software-licence-agreement/"
            target="_blank"
            rel="noreferrer"
          >
            Blys Software License Agreement
          </a>
        </Box>
        <Box
          fontFamily="Museo"
          fontWeight={400}
          fontSize="16px"
          mb="8px"
          color={Colors.TurquoiseBlue}
        >
          <a
            style={{ color: Colors.TurquoiseBlue, textDecoration: "none" }}
            href="https://help.getblys.com.au/support/solutions/articles/33000265175"
            target="_blank"
            rel="noreferrer"
          >
            Practitioner Code of Conduct
          </a>
        </Box>
        <Box
          fontFamily="Museo"
          fontWeight={400}
          fontSize="16px"
          mb="8px"
          color={Colors.TurquoiseBlue}
        >
          <a
            style={{ color: Colors.TurquoiseBlue, textDecoration: "none" }}
            href="https://getblys.com.au/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" style={{ paddingTop: "20px" }}>
        <Checkbox
          style={{
            color: Colors.TurquoiseBlue,
            padding: "0px",
          }}
          checked={isTermsAccepted}
          onChange={(event) => {
            axios
              .put(`therapists/me?accessToken=${accessToken}`, {
                isTermsAccepted: !isTermsAccepted,
              })
              .then(() => {
                setTermsAccepted(!isTermsAccepted);
              })
              .catch((error) => {
                setErrorMessage(parseApiError(error));
              });
          }}
        />
        <Box
          style={{ paddingLeft: "8px" }}
          fontFamily="Museo"
          fontWeight={400}
          fontSize="16px"
          lineHeight="24px"
          color={Colors.Dusk}
        >
          I have read and agree to Blys terms and policies
        </Box>
      </Box>

      <Box
        fontFamily="Museo"
        fontWeight={600}
        fontSize="18px"
        lineHeight="27px"
        color={Colors.Dusk}
        mt="32px"
        mb="24px"
      >
        Other documents
      </Box>
      <Box>
        {legalConfig.otherDocuments &&
          legalConfig.otherDocuments.map((document: any) => (
            <Box
              key={document.link}
              fontFamily="Museo"
              fontWeight={400}
              fontSize="16px"
              mb="8px"
              color={Colors.TurquoiseBlue}
            >
              <a
                style={{ color: Colors.TurquoiseBlue, textDecoration: "none" }}
                href={document.link}
                target="_blank"
                rel="noreferrer"
              >
                {document.text}
              </a>
            </Box>
          ))}
      </Box>
    </Box>
  );
}
