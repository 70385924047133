import { useRef, useState } from "react";
import InitialModal from "./InitialModal";
import AddSignatureModal from "./addSignatureModal";
import { useConfirmAgreement, useSignature } from "../../../../hooks/signature.hook";
import { useUserStore } from "../../../../stores/user";

import { useAlertStore } from "../../../../stores/alert";
interface Props {
  visible: boolean;
  onAcceptConfirmed: () => unknown;
  onClose: () => unknown;
  booking: {
    user: {
      id: number;
    };
    id: number;
  };
  job: {
    id: number;
  };
}

enum modalContentType {
  initial,
  addSignature,
}

const ClientTermsAndPoliciesModal = ({
  visible,
  onClose,
  onAcceptConfirmed,
  booking,
  job,
}: Props) => {
  const [modalContent, setModalContent] = useState<modalContentType>(modalContentType.initial);
  const sigCanvas = useRef<any>(null);
  const { user } = useUserStore();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { data: signature } = useSignature({ userId: user?.id as number });
  const { mutate: confirmAgreement, isLoading } = useConfirmAgreement({
    onSuccess: () => {
      setSuccessMessage("Document signed successfully.");
      onAcceptConfirmed();
    },
    onError: (err: any) => {
      setErrorMessage(err?.response?.data?.error?.message || "Failed to add signature.");
    },
  });

  const showAddSignatureModal = () => setModalContent(modalContentType.addSignature);
  const showInitialModal = () => setModalContent(modalContentType.initial);

  const handleConfirmTerms = (agreed?: boolean) => {
    if (!booking) return;
    if (!signature) {
      setErrorMessage("Please add your signature to proceed.");
      return;
    }

    if (!agreed) {
      setErrorMessage(
        "Please confirm that you agree with the client terms and conditions for all bookings from this client."
      );
      return;
    }

    const data = {
      userId: booking.user.id,
      signatureId: signature.id,
      bookingId: booking.id,
    };

    confirmAgreement(data);
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <InitialModal
        visible={modalContent === modalContentType.initial}
        signature={signature}
        openAddSignatureModal={showAddSignatureModal}
        onClose={onClose}
        onConfirm={handleConfirmTerms}
        isLoading={isLoading}
        job={job}
      />

      <AddSignatureModal
        goBack={showInitialModal}
        sigCanvas={sigCanvas}
        onClose={onClose}
        visible={modalContent === modalContentType.addSignature}
      />
    </>
  );
};

export default ClientTermsAndPoliciesModal;
