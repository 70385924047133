import { Colors } from "../../../constants/colors";

export const styles = {
  fontStyle: {
    fontSize: "14px",
    fontFamily: "Museo",
    fontWeight: 700,
    lineHeight: "150%",
    color: Colors.DarkGrey,
  },
  buttonBase: {
    border: `1px solid ${Colors.LightPeriwinkle}`,
    borderRadius: "4px",
    color: Colors.DarkGrey,
    height: "auto",
    minHeight: "48px",
    maxHeight: "68px",
    overflow: "hidden",
  },
  subTitle: {
    lineHeight: "18px",
    fontSize: "12px",
    fontWeight: 500,
    color: Colors.DarkGrey,
  },
  removeBtn: {
    border: `1px solid ${Colors.LightPeriwinkle}`,
    backgroundColor: Colors.Danger,
    borderRadius: "4px",
  }
};