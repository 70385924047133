import { Box } from "@material-ui/core";

const VerifiedBadge = () => {
  return (
    <Box display={"flex"} color="#198754" height={22} alignItems="center" gridGap={4} fontSize={12}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <circle cx="8" cy="8.5" r="7" fill="#198754" />
        <path
          d="M4.5 9L7 11.5L11.5 6.5"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style ={{
        fontWeight: "500",
        fontSize: "16px"
      }}>Verified</span>
    </Box>
  );
};

export default VerifiedBadge;
