import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { ClientReview } from "../../../../models";
import { checkIfEmpty } from "../../../../utils/object";
import TextButton from "../../../../components/TextButton/TextButton";
import SectionHeader from "../../../../components/Headers/SectionHeader/SectionHeader";
import ReviewRatingCard from "../../../../components/Cards/ReviewRatingCard/ReviewRatingCard";
import { Display, FlexDirection, JustifyContent, Spacing } from "../../../../components/v2/Styled/enum";
import UserRatingModal from "../../Modals/UserRatingModal/UserRatingModal";

import { RootState } from "../../../../stores/V2";
import { actions as bookingActions } from "../../../../stores/V2/booking/booking";
import { useDispatch } from "react-redux";

interface Props {
  timezone: string;
  review?: ClientReview;
  jobId: number | string;
  refreshJob: () => unknown;
}

const ClientRating = ({ review, jobId, timezone, refreshJob }: Props) => {
  const bookingStore = useSelector(({ booking }: RootState) => booking);
  const dispatch = useDispatch();

  const { showUserRatingModal } = bookingStore;

  const closeRatingModal = () => {
    dispatch(bookingActions.setShowUserRatingModal(false));
  };

  const onCloseRating = () => {
    closeRatingModal();
  };

  const onReviewAction = () => {
    dispatch(bookingActions.setShowUserRatingModal(true));
  };

  const hasReviewNote = !checkIfEmpty(review);
  const { review: reviewText, updatedAt: date } = review || {};

  const onRatingUpdated = () => {
    refreshJob();
    closeRatingModal();
  };

  return (
    <Box>
      <Box
        gridGap={Spacing.S2}
        display={Display.Flex}
        flexDirection={FlexDirection.Column}
      >
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Row}
          justifyContent={JustifyContent.spaceBetween}
        >
          <SectionHeader title="Client rating"/>
          <Box display={Display.Flex} flexDirection={FlexDirection.Row} gridGap={Spacing.S3}>
            <TextButton
              type="primary"
              text={checkIfEmpty(review) ? "Leave a review" : "Update review"}
              textStyle={{ padding: 0 }}
              onClick={onReviewAction}
            />
          </Box>
        </Box>

        {review && !checkIfEmpty(review) && (
          <Box>
            <ReviewRatingCard rating={review.rating} text={reviewText} date={date} timezone={timezone} />
          </Box>
        )}
      </Box>

      <UserRatingModal
        jobId={jobId}
        review={review}
        visible={showUserRatingModal}
        includeNote={!hasReviewNote}
        onClose={onCloseRating}
        onSuccess={onRatingUpdated}
      />
    </Box>
  );
};

export default ClientRating;
