import { Box } from "@material-ui/core";
import { isNil } from "lodash";
import Button, { ButtonSize, ButtonType } from "../components/Button";
import { CLIENT_BOOKING_CANCEL_REASONS } from "../constants/booking";
import { Colors } from "../constants/colors";
import { formatToPrice } from "../helpers/number";
import { useMobile } from "../hooks/mobile";
import { BookingCancelReasonOption } from "./CancelBooking";
import Dialog from "./Dialog";
import Dropdown, { DropdownOption } from "./Dropdown";
import TextField from "./TextField";
import { LoadingSpinner } from "./loadingSpinner";

interface Props {
  open: boolean;
  onClose: (e: MouseEvent) => unknown;
  onConfirm: (e: MouseEvent) => unknown;
  cancelling: boolean;
  bookingConfirmed?: boolean;
  cancellationFee: number | null;
  clientPlatformLabel: String;
  clientPlatformFee: number | null;
  bookingCurrency?: String;
  cancellationReason: string;
  handleCancellationReasonSelected: (option: DropdownOption) => unknown;
  handleAdditionalCancellationReasonChange: (reason: string) => unknown;
  additionalCancellationReason: string;
  cancellationReasonOptions: BookingCancelReasonOption[];
  shouldDisableConfirmButton: () => boolean;
  cancellationWarningMessage?: string;
}

export default function CancelBookingModal({
  open,
  onClose,
  onConfirm,
  cancelling,
  bookingConfirmed = false,
  cancellationFee,
  bookingCurrency,
  handleCancellationReasonSelected,
  cancellationReason,
  handleAdditionalCancellationReasonChange,
  additionalCancellationReason,
  cancellationReasonOptions,
  shouldDisableConfirmButton,
  cancellationWarningMessage,
}: Props): JSX.Element {
  const isMobile = useMobile();

  const getCancellationFeeTitle = (cancellationFee: number | null, bookingConfirmed: boolean) => {
    if (!cancellationFee || cancellationFee <= 0) return "No cancellation fee accrued.";

    const priceWithCurrency = formatToPrice(cancellationFee, bookingCurrency);
    if (bookingConfirmed) {
      return `The booking has already been confirmed and will incur a ${priceWithCurrency} cancellation fee.`;
    }

    return `This booking will incur a ${priceWithCurrency} cancellation fee.`;
  };

  const cancellationFeeTitle =
    cancellationWarningMessage || getCancellationFeeTitle(cancellationFee, bookingConfirmed);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile} style={{ overflowY: "visible" }}>
      <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
        <Box
          width={isMobile ? "100%" : "352px"}
          display="flex"
          flexDirection="column"
          alignItems="start"
        >
          <Box
            fontFamily="Museo"
            fontWeight={700}
            fontSize="21px"
            color={Colors.NightBlue}
            textAlign="center"
            width="100%"
          >
            Cancel your booking
          </Box>
          {isNil(cancellationFee) && (
            <Box fontFamily="Open Sans" fontSize="16px" color={Colors.BlueyGrey} mt="16px">
              Loading...
            </Box>
          )}

          {!isNil(cancellationFee) && (
            <>
              <Box
                fontFamily="Open Sans"
                fontSize="18px"
                color={Colors.BlueyGrey}
                mt="16px"
                width="100%"
              >
                <Box textAlign="center">{cancellationFeeTitle}</Box>

                <Box fontFamily="Museo" mt="10px">
                  <Dropdown
                    title="Reason for cancelling"
                    options={cancellationReasonOptions}
                    onSelectedOption={handleCancellationReasonSelected}
                    selectedOption={cancellationReasonOptions.find(
                      (data) => data.value === cancellationReason
                    )}
                  />

                  {cancellationReason === CLIENT_BOOKING_CANCEL_REASONS.OTHER && (
                    <TextField
                      title="Additional details"
                      placeholder="Additional details about your cancellation"
                      value={additionalCancellationReason}
                      onChange={handleAdditionalCancellationReasonChange}
                      multiline
                    />
                  )}
                </Box>
              </Box>
              {cancelling ? (
                <Box mt={4} width={"100%"}>
                  <LoadingSpinner paddingTop="0rem" />
                </Box>
              ) : (
                <Box style={styles.actionWrapper} flexDirection="row">
                  <Button
                    height={48}
                    type={ButtonType.outlined}
                    size={ButtonSize.small}
                    title="Cancel"
                    onClick={onClose}
                    style={{ marginRight: "12px" }}
                  />
                  <Button
                    height={48}
                    type={
                      shouldDisableConfirmButton() ? ButtonType.lightSteel : ButtonType.secondary
                    }
                    size={ButtonSize.small}
                    title="Confirm"
                    onClick={onConfirm}
                    disabled={shouldDisableConfirmButton()}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Dialog>
  );
}

const styles = {
  priceWrapper: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Museo",
    fontSize: "14px",
    color: Colors.Dusk,
    marginTop: "10px",
  },
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "24px",
  },
};
