import { Box, ButtonBase, Typography } from "@material-ui/core";
import styles from "./styles";
import InfoIcon from "../../../images/light-info-img.svg";

interface InfoTextProps {
  text: string,
  textStyle?: object,
  showInfoButton?: boolean,
  style?:object,
  onInfoClicked?: () => void,
};

const InfoText = ({ text, textStyle = {}, showInfoButton = false, style = {}, onInfoClicked } : InfoTextProps) => {
  return (
    <Box style={{...style}} onClick={onInfoClicked}>
      <Typography style={{ ...styles.text, ...textStyle }}>{text}</Typography>
      {showInfoButton && 
        <ButtonBase>
          <img src={InfoIcon} alt="Info" height="16px" style={{marginLeft: "4px"}} />
        </ButtonBase>
      }
    </Box>
  );
};

export default InfoText;