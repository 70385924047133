import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { TherapistProfile, TherapistProfileType } from "../components/TherapistProfileDrawer";

interface Params {
  id?: string;
}

export default function PublicTherapistProfile() {
  const { id } = useParams<Params>();

  const therapistId = id?.split("-")[1];

  return therapistId ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <TherapistProfile
        type={TherapistProfileType.publicLink}
        therapistId={parseInt(therapistId)}
        closable={false}
      />
    </Box>
  ) : (
    <></>
  );
}
