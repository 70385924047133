import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import RadioButton from "../../../../components/RadioButton";
import { Colors } from "../../../../constants/colors";

export default function NotificationRadius({ loading, data = [], selected, onRadiusChange }: any) {
  return (
    <>
      <Box fontSize="14px" mb="12px" fontWeight={600} color={Colors.NightBlue}>
        Notification radius
      </Box>

      {loading && (
        <Box mr={"24px"}>
          <Skeleton variant="text" width={"72%"} height={50} />
        </Box>
      )}

      <Box fontWeight={400} color={Colors.Dusk} display="flex" flexDirection="row" flexWrap={"wrap"} mt={2} mb={2} alignItems="center">
        {!loading &&
          data.length &&
          data.map((radius: any, index: number) => (
            <Box key={index} display="flex" flexDirection="row" alignItems="center" mr={"24px"}>
              <RadioButton selected={radius.value === selected} onSelected={() => onRadiusChange(radius.value)} />
              <Box fontSize="16px">{radius.label}</Box>
            </Box>
          ))}
      </Box>

      <Box fontSize="14px" lineHeight="21px" fontWeight={400} color={Colors.Dusk}>
        The maximum distance you will be notified of new jobs. If you’re not seeing enough jobs, consider increasing the
        notification radius.
      </Box>
    </>
  );
}
