import { Box } from "@material-ui/core";
import Ratings from "../../Ratings";
import { getValue } from "../../../utils/object";
import Button, { ButtonType } from "../../Button";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { limitDecimals } from "../../../utils/number";
import AvatarName from "../../TherapistProfile/AvatarName";
import { BASE_UPLOADS_URL } from "../../../constants/common";
import { TherapistPublicProfileData } from "../../../models";
import { capitalizeFirstLetter } from "../../../helpers/string";
import AvatarImageWithCovidStatus, { ImageSize } from "../../AvatarImageWithCovidStatus";

interface Props {
  provider: TherapistPublicProfileData;
  selected: boolean;
  onSelect: (id: number | string) => unknown;
  onProfileClick: () => unknown;
}

const ProviderItem = ({ provider, selected, onSelect, onProfileClick }: Props): JSX.Element => {
  const isMobile = useMobile();
  const { id: proId, firstName, lastName, therapistprofile, totalReviews } = provider;

  const profileImage = getValue(therapistprofile, "profileImage", "");
  const proImageUrl = !!profileImage ? `${BASE_UPLOADS_URL}/${profileImage}` : null;
  const rating = getValue(therapistprofile, "rating", 0);
  const displayRating = limitDecimals(rating || 0, 1, false);
  const dim = isMobile ? "96px" : "125px";

  return (
    <Box
      p={"24px"}
      display={"flex"}
      gridGap={"16px"}
      border={"2px solid"}
      borderRadius={"8px"}
      flexDirection={"column"}
      width={isMobile ? "100%" : "255px"}
      borderColor={selected ? Colors.TurquoiseBlue : Colors.LightPeriwinkle}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        onClick={() => onProfileClick()}
        style={{ cursor: "pointer" }}
      >
        {profileImage ? (
          <AvatarImageWithCovidStatus
            imageSrc={proImageUrl}
            size={ImageSize.large}
            isCovidVaccinated={false}
          />
        ) : (
          <AvatarName
            firstName={firstName}
            lastName={lastName}
            width={dim}
            height={dim}
            fontSize={"32px"}
          />
        )}
      </Box>

      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <Box
          mb={"8px"}
          fontWeight={700}
          fontSize={"24px"}
          textAlign={"center"}
          fontFamily={"Museo"}
          lineHeight={"26.4px"}
          color={Colors.NightBlue}
          onClick={() => onProfileClick()}
          style={{ cursor: "pointer" }}
        >
          {capitalizeFirstLetter(firstName)}
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gridGap={"8px"}>
          <Ratings rating={rating} width={"20px"} height={"24px"} />
          <Box
            fontFamily={"Open Sans"}
            fontSize={"16px"}
            fontWeight={600}
            lineHeight={"24px"}
            color={Colors.Dusk}
          >
            {displayRating}
          </Box>
        </Box>
        <Box
          textAlign={"center"}
          fontFamily={"Open Sans"}
          fontSize={"14px"}
          fontWeight={400}
          lineHeight={"21px"}
          color={Colors.BlueyGrey}
        >
          ({totalReviews || 0} {totalReviews > 1 ? "Reviews" : "Review"})
        </Box>
      </Box>

      <Box display={"flex"} justifyContent={"center"}>
        <Button
          title={selected ? "Selected" : "Select"}
          width={isMobile ? "100%" : "200px"}
          type={selected ? ButtonType.secondary : ButtonType.outlined}
          fontStyle={{
            fontFamily: "Museo",
            color: selected ? Colors.White : Colors.TurquoiseBlue,
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
          }}
          onClick={() => onSelect(proId)}
        />
      </Box>
    </Box>
  );
};

export default ProviderItem;
