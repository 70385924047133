import { get, post, put } from "../../api/client";

export const getAddOnsForPro = () => {
  const URL = "api/v2/addons";
  return get(URL);
};

export const addAddOns = (data: any) => {
  const URL = "api/v2/addons";

  return post(URL, data);
};
