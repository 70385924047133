import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneInputComponent = (props: any): JSX.Element => {

  return (<>
    <div style={{ position: "relative" }}>
      <div className="countryCodeNumber" style={{ width: "50px", position: "absolute", top: "16px", left: "30px", textAlign: "center", fontSize: "14px", fontFamily: "'Roboto',sans-serif", zIndex: 3, cursor: 'pointer' }}
      >{props.countryCode}</div>
      <PhoneInput {...props}  disableCountryGuess={true} disableCountryCode={true} enableLongNumbers={true}/>
    </div>
  </>);
}

export default PhoneInputComponent;
