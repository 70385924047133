import { useParams } from "react-router-dom";
import Landing from "./Landing";
import useVerifyInvitation from "../hooks/verifyInvitation.hooks";
import { CircularProgress, Box } from "@mui/material";
interface RouteParams {
  code: string;
}

const VerifyInvitation = () => {
  const params = useParams<RouteParams>();
  const { code } = params;

  // verify the invitation code by calling api and login user if the invitation is valid
  const { isLoading } = useVerifyInvitation(code);

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <CircularProgress size={100} color="info" />
        </Box>
      ) : (
        <Landing />
      )}
    </>
  );
};

export default VerifyInvitation;
