"use client";
import { pick } from "lodash";
import queryString from "query-string";

import { useLocation } from "react-router-dom";

interface UseBookingFilterProps {
sortBy:string
}
// safe query are those queries that are supported in backend
interface RecipientFilters {
  sortBy?: string;
  name?: string;
  gender?: string;
  relationship?: string;
  
};

const useRecipientFilterQuery = ({ sortBy }: UseBookingFilterProps) => {
  const location = useLocation();
  const query = queryString.parse(location.search) as RecipientFilters;

  query.sortBy = query.sortBy || sortBy;

  const safeQueries = [
    "sortBy",
    "name",
    "gender",
    "relationship"
  ] as const;

  
  const recipientQueries = pick(query,safeQueries) as RecipientFilters;


  return {recipientQueries};
};

export default useRecipientFilterQuery;
