import React from "react";
import { Box } from "@material-ui/core";
import { Colors } from "../constants/colors";

interface Props {
  progress: number;
}

export default function NewBookingProgressBar({
  progress,
}: Props): JSX.Element {
  const progressInPercentage = `${progress * 100}%`;

  return (
    <Box
      height="2px"
      bgcolor={Colors.LightPeriwinkle}
      display="flex"
      alignItems="center"
    >
      <Box
        width={progressInPercentage}
        height="100%"
        bgcolor={Colors.TurquoiseBlue}
      />
    </Box>
  );
}
