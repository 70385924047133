import React from "react";
import { Box, ButtonBase } from "@material-ui/core";
import DownloadIcon from "../../images/report-summary-download.svg";
import { Colors } from "../../constants/colors";

interface Props {
  onClick: () => unknown;
}

export default function ({ onClick }: Props) {
  return (
    <ButtonBase onClick={onClick}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <img src={DownloadIcon} />

        <Box
          fontFamily="Museo"
          fontSize="14px"
          lineHeight="21px"
          fontWeight={600}
          ml="4px"
          color={Colors.TurquoiseBlue}
        >
          Download
        </Box>
      </Box>
    </ButtonBase>
  );
}
