import React from "react";
import { Box } from "@material-ui/core";
import { getValue } from "../../../utils/object";
import { useHistory } from "react-router-dom";
import { Display, FlexDirection, Spacing } from "../../v2/Styled/enum";
import { JobStatus } from "../../../constants/booking";
import { BookingAccordionItem } from "./BookingAccordionItem";
import { LoadingSpinner } from "../../loadingSpinner";
import { isEmpty } from "lodash";
import { PastBookingPlaceholder } from "./PastBookingPlaceHolder";

export const Jobs = ({
  proPayouts,
  isPayoutsLoading,
}: {
  proPayouts: [];
  isPayoutsLoading: boolean;
}) => {
  const history = useHistory();
  const handleJobOpen = ({ jobId, proPayouts }: { jobId: number; proPayouts: [] | null }) => {
    history.push(`/pro/bookings/${jobId}`, {
      proPayouts,
    });
  };
  if (isPayoutsLoading) return <LoadingSpinner />;
  const jobs = getValue(proPayouts, "rows", []);

  if (!jobs || isEmpty(jobs)) {
    return <PastBookingPlaceholder />;
  }
  return (
    <>
      {(jobs || []).map((proPayout: any) => {
        return (
          <Box
            mt={Spacing.S6}
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
            style={{
              gap: Spacing.S6,
              cursor: "pointer",
            }}
            onClick={() => {
              if (proPayout?.status !== JobStatus.cancelled && !proPayout.isDebit) {
                handleJobOpen({
                  jobId: proPayout?.id,
                  proPayouts: getValue(proPayout, "proPayouts"),
                });
              }
            }}
          >
            <BookingAccordionItem job={proPayout} />
          </Box>
        );
      })}
    </>
  );
};
