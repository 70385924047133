import React from "react";
import { ButtonBase } from "@material-ui/core";
import logo from "../images/blys-logo.svg";
import { MARKETING_SITE_URL } from "../constants/common";

interface Props {
  style?: React.CSSProperties;
}
export default function LogoLink({ style = {} }: Props) {
  return (
    <ButtonBase onClick={() => window.location.assign(MARKETING_SITE_URL)}>
      <img alt="logo" src={logo} style={style}/>
    </ButtonBase>
  );
}
