const MASSAGE_ID = {
  staging: 264,
  production: 540,
};

const SERVICE_TYPE = {
  massage: "massage",
};

export { MASSAGE_ID, SERVICE_TYPE };
