import React from "react";
import { Box } from "@material-ui/core";
import Each from "../Each";
import * as Styled from "../v2/Styled/enum";
import { Colors } from "../../constants/colors";

interface Props {
  n?: number;
  style?: React.CSSProperties;
}

const Dots = ({ n = 1, style = {} }: Props): JSX.Element => (
  <Box display={Styled.Display.Flex} gridGap={"2px"}>
    <Each of={new Array(n).fill(0)} render={() => <div style={{ ...defaultStyle, ...style }} />} />
  </Box>
);

const defaultStyle = {
  width: "4px",
  height: "4px",
  borderRadius: "4px",
  backgroundColor: Colors.BlueyGrey,
};

export default Dots;
