import React, { useState } from "react";
import ContentModal from "../ContentModal/contentModal";
import Button, { ButtonType } from "../../Button";
import TextButton from "../../TextButton/TextButton";
import { FlexDirection, Spacing } from "../../v2/Styled/enum";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import RadioButton from "../../RadioButton";
import { Box } from "../../v2/Styled";
import GenderToggle from "../../GenderSegementedToggleButton/GenderSegementedToggleButton";
import RelationshipRadio from "../../RelationshipRadio/RelationshipRadio";
import { SORT_BY, AllSortBy } from "../../../constants/sortOptions";
import TextField from "../../TextField";
import { useCachedRecipientSort } from "../../../hooks/utility/filter.hooks";
import useRecipientFilterQuery from "../../../hooks/recipient/recipientFilter.hook";
import { useHistory } from "react-router-dom";
import { stringifyParams } from "../../../helpers/string";

interface Props {
  open: boolean;
  onClose: () => unknown;
  // onFilterRecipients: (filterValue: FilterBookingValue) => unknown;
}
export interface FilterBookingValue {
  recipientId: number | undefined | null;
  sortBy: string;
  gender: string;
  relationship: string;
}

const sortByoptions = AllSortBy.map((sortBy) => {
  return {
    value: sortBy,
    title: SORT_BY.toString(sortBy),
  };
});

export const RecipientFilterAndSortModal = ({ open, onClose }: Props) => {
  const isMobile = useMobile();
  const { cachedSortBy, cacheSortBy, removeSortBy } = useCachedRecipientSort();

  const history = useHistory();

  const recipientSortBy = cachedSortBy || AllSortBy[0];

  const {recipientQueries} = useRecipientFilterQuery({sortBy:recipientSortBy});


  const [recipientName, setRecipientName] = useState(recipientQueries.name||"");
  const [sortBy, setSortBy] = useState(recipientQueries.sortBy || recipientSortBy);
  const [gender, setGender] = useState(recipientQueries.gender || "any");
  const [relationship, setRelationship] = useState(recipientQueries.relationship || "any");

  const handleFilterApply = () => {
    const filterData: any = {};

    if (sortBy) {
      filterData.sortBy = sortBy;
      cacheSortBy(sortBy);
    }

    if (recipientName) {
      filterData.name = recipientName;
    }

    if (gender !== "any") {
      filterData.gender = gender;
    }

    if (relationship !== "any") {
      filterData.relationship = relationship;
    }

    // onFilterRecipients(filterData);
    history.push("/recipients?"+stringifyParams(filterData));
    onClose();
  };

  const handleClearAll = () => {
    //clear sort filter
    setSortBy(AllSortBy[0]);
    removeSortBy();

    //clear search filter
    setRecipientName("");
    setGender("any");
    setRelationship("any");
  };

  const handleSortBySelected = (value: string) => {
    setSortBy(value);
  };

  const shouldShowClearAll = () => {
    const isGenderSelected = gender && gender !== "any";
    const isRelationshipSelected = relationship && relationship !== "any";

    return recipientName || sortBy || isGenderSelected || isRelationshipSelected;
  };

  return (
    <ContentModal
      visible={open}
      onClose={onClose}
      actions={[
        <TextButton
          text="Clear all"
          type={shouldShowClearAll() ? "danger" : "disabled"}
          onClick={handleClearAll}
        />,
        <Button
          title="Apply"
          type={ButtonType.secondary}
          onClick={handleFilterApply}
          width={"161px"}
        />,
      ]}
      title="Filter and sort"
      divider={false}
      maxWidth="md"
      PaperProps={{
        style: {
          minWidth: !isMobile ? "438px" : "100%",
          minHeight:!isMobile ? "default" : "100%"
        },
      }}
      actionStyle={{
        paddingLeft: Spacing.S6,
        paddingRight: Spacing.S6,
        justifyContent: "space-between",
      }}
      titleStyle={{ fontSize: "16px" }}
      fullWidth={isMobile}
      childrenStyle={{ marginTop: 0 }}
      fixButtonsWithDivider={true}
      showCrossIcon={true}
    >
      <Box direction={FlexDirection.Column} marginY={Spacing.S6} gap={Spacing.S6}>
        <Box direction={FlexDirection.Column} gap={Spacing.S2}>
          <Box fontWeight={700} fontSize={"20px"} color={Colors.NightBlue} fontFamily="Museo">
            Sort by
          </Box>
          {sortByoptions.map((sortOpt) => (
            <Box alignItems="center" gap={Spacing.S1}>
              <RadioButton
                selected={sortOpt.value === sortBy}
                style={{
                  width: "20px",
                  height: "20px",
                }}
                onSelected={() => handleSortBySelected(sortOpt.value)}
              />
              <Box
                color={Colors.Dusk}
                fontSize="16px"
                fontFamily="Museo"
                className="cursor-pointer"
                onClick={() => handleSortBySelected(sortOpt.value)}
              >
                {sortOpt.title}
              </Box>
            </Box>
          ))}
        </Box>
        <Box direction={FlexDirection.Column}>
          <Box fontWeight={700} fontSize={"20px"} color={Colors.NightBlue} fontFamily="Museo">
            Filter by
          </Box>

          <TextField
            title="Recipient name"
            placeholder="Enter recipient name"
            onChange={(value) => setRecipientName(value)}
            value={recipientName}
          />

          <GenderToggle gender={gender} setGender={(value: string) => setGender(value)} />

          <RelationshipRadio
            relationship={relationship}
            setRelationship={(value: string) => setRelationship(value)}
          />
        </Box>
      </Box>
    </ContentModal>
  );
};
