import { useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";

import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  Spacing,
  TextAlign
} from "../../../../../../components/v2/Styled/enum";
import { Colors } from "../../../../../../constants/colors";
import TreatmentNoteListCard from "../../../../ClientDetails/TreatmentNotes/TreatmentNoteListCard";
import PaginationControl from "../../../../../../components/PaginationControl";
import { useAllTreatmentNotes } from "../../../../../../hooks/user/userTreatmentNote.hooks";
import { checkIfEmpty, getValue } from "../../../../../../utils/object";

import HeroImage from "../../../../../../images/no-bookings-placeholder.svg";

interface Props {
  timezone: string;
  jobId: string | number;
  clientId: string | number;
};

const OtherProviderNotes = ({ timezone, clientId, jobId }: Props) => {
  const [otherNotePage, setOtherNotePage] = useState(1);
  const {
    data: otherNoteResponse,
    isLoading: loadingNotes,
  } = useAllTreatmentNotes(clientId, jobId, otherNotePage);

  const getNotesFromOthers = () => {
    return getValue(otherNoteResponse, "notes");
  };

  const changeOtherNotePage = (increment: 1 | -1) => {
    setOtherNotePage(otherNotePage + increment);
  };

  const otherProviderNotes = getNotesFromOthers();

  return (
    <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4}>
      <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S2}>
        <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S3} >
          <Box
            fontFamily={FontFamily.Museo}
            fontSize={FontSize.F16}
            color={Colors.NightBlue}
            fontWeight={FontWeight.Bold}
          >
            Notes added by other providers
          </Box>
          <Box
            fontFamily={FontFamily.OpenSans}
            fontSize={FontSize.F14}
            color={Colors.DarkSteelGrey}
          >
            These notes are shared by other providers and include insights relevant to the client's treatments.
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display={Display.Flex} flexDirection={FlexDirection.Column} gridGap={Spacing.S4}>
          {(otherProviderNotes || []).map((note: any) => {
            const { pro: proUser, id } = note;
            return <TreatmentNoteListCard
              key={`${id}-treatmentNote`}
              note={note}
              pro={proUser}
              timezone={timezone}
              allowActions={false}
            />;
          })}
        </Box>
        {(loadingNotes || checkIfEmpty(otherProviderNotes)) && (
          <Box
            minHeight={"300px"}
            display={Display.Flex}
            flexDirection={FlexDirection.Row}
            justifyContent={JustifyContent.center}
            alignItems={AlignItems.center}
          >
            {loadingNotes && <CircularProgress />}
            {!loadingNotes && checkIfEmpty(otherProviderNotes) && (
              <Box
                maxWidth={"400px"}
                gridGap={Spacing.S1}
                display={Display.Flex}
                textAlign={TextAlign.center}
                alignItems={AlignItems.center}
                flexDirection={FlexDirection.Column}
              >
                <img src={HeroImage} alt="No notes found" width={"78px"} height={"55px"} />
                <Box display={Display.Flex} flexDirection={FlexDirection.Column} alignItems={AlignItems.center} gridGap={Spacing.S1}>
                  <Box
                    fontSize={FontSize.F16}
                    fontWeight={FontWeight.Bold}
                    color={Colors.DarkSteelGrey}
                    fontFamily={FontFamily.Museo}
                  >
                    No notes from other providers
                  </Box>
                  <Box
                    fontSize={FontSize.F14}
                    fontWeight={FontWeight.Regular}
                    fontFamily={FontFamily.OpenSans}
                  >
                    If any provider adds notes for this client, you'll see them here.
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Box
        display={Display.Flex}
        flexDirection={FlexDirection.Row}
        justifyContent={JustifyContent.end}
      >
        <PaginationControl
          currentPage={otherNotePage}
          pages={getValue(otherNoteResponse, "totalPage") || 1}
          onPrevious={() => changeOtherNotePage(-1)}
          onNext={() => changeOtherNotePage(1)}
          startFromOne={true}
        />
      </Box>
    </Box>
  );
};

export default OtherProviderNotes;
