import { get } from "../../api/client";

const getReferralStats = () => {
  const URL = "api/v2/therapists/referrals/stats";
  return get(URL);
};

const getPaginatedReferrals = (page = 0) => {
  const URL = "api/v2/therapists/referrals";
  return get(`${URL}?page=${page}`);
};

export {
  getReferralStats,
  getPaginatedReferrals,
};
