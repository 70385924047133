import {
  JobStatus,
  MassageFor,
  Parking,
  RecurringType,
  Relationship,
  Stairs,
  UpdateRequestedBy,
  UpdateRequestStatus,
} from "../constants/booking";
import { PaymentType } from "../constants/payment";
import AmexIcon from "../images/card-amex.svg";
import CardIcon from "../images/card-general.svg";
import MastercardIcon from "../images/card-mastercard.svg";
import VisaIcon from "../images/card-visa.svg";
import PayPalIcon from "../images/payment-paypal.svg";
import { ProDocs } from "../pages/Bookings/Components/BookingDetailCard/ProvidersDocument";
import { MonthData } from "../stores/booking";

export interface ReservedSlotSuccessData {
  id?: string;
  slotId?: string;
  userId?: string;
  recipientId?: string;
  uuid?: string;
  calendarLink?: string;
  outLookCalendarLink?: string;
  appleCalendarLink?: string;
}

export interface JobAgreement {
  name?: string;
  link: string;
  type: string;
}

export interface AddressOption {
  value: any;
  id?: number;
  type?: string;
  title: string;
  countryCode: string;
  helperText?: string;
  locationTypeId?: number;
}

export interface LockedAttributeOption {
  fieldName: string;
  value: string | null;
}

export interface AddressOptions {
  parkingOptions: AddressOption[];
  petOptions: AddressOption[];
  stairOptions: AddressOption[];
  tableOptions: AddressOption[];
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string | null;
  preferredFirstName: string | null;
  email: string;
  mobile: string;
  gender: string;
  pronoun: string;
  accessToken: string;
  braintreeCustomerId: string;
  resetPasswordToken: string;
  adminAccess: boolean;
  createdAt: string;
  updatedAt: string;
  firstMassageDate: string;
  lastMassageDate: string;
  totalMassagesBooked: number;
  totalRevenue: number;
  referralCode: string;
  profileImage: string;
  specialInstructions: string;
  therapistprofile?: UserTherapistProfile;
  proScore?: UserProScore;
  birthday: string;
  isCovidVaccinated: boolean;
  country: string;
  countryFullName: string;
  stripeCustomerId: string;
  status: null | string;
  welcomedAt: string;
  parentId: number | null;
  role: any;
  accountBalance: number;
  isHomeCareProvider: boolean;
  lockedAttributes: LockedAttributeOption[];
  emailVerifiedAt?: string | null;
  mobileVerifiedAt?: string | null;
  languageSpoken?: [];
  needMfa?: boolean;
  mfaType?: string;
  serviceId?: string;
  paymentProvider: string | null;
}

export interface UserTherapistProfile {
  id: number;
  postcode: string;
  experience: string;
  qualifications: string;
  confirmed: boolean;
  associationMembership: boolean;
  policeCheck: boolean;
  expiryDate: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  pushToken: string;
  profileImage: string;
  longitude: number;
  latitude: number;
  rating: number;
  gst: boolean;
  businessAddress: string;
  businessName: string;
  insuranceDate: string;
  abn: string;
  bsb: string;
  accountNumber: string;
  totalCompletedJobs: number;
  lastJobCompletedAt: string;
  splitPaymentsContactId: string;
  splitPaymentBankAccountId: string;
  reliabilityScore: number;
  rebookingScore: number;
  totalRebookingsCompleted: number;
  elite: boolean;
  bio: string;
  amtsMember: boolean;
  currentInsurance: string;
  massageTable: string;
  whereHear: string;
  city: string;
  kit: string;
  isTermsAccepted: boolean;
  stripeAccountId: string;
  licenseValidLocation: string;
  ignoreAvailabilities: boolean;
}

export interface UserProScore {
  id: number;
  daysSinceLastJobCompleted: number;
  totalReviewsCount: number;
  recentCompletedJobsCount: number;
  fiveStarRatingsCount: number;
  averageRating: number;
  reliabilityRate: number;
  reBookingRate: number;
  proTier: string;
  totalCompletedJobs: number;
}

export interface Address {
  id: number;
  userId: number;
  type: string;
  address: string;
  fullAddress?: string;
  suburb: string;
  state?: string;
  postcode: string;
  instructions: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  stairs: Stairs;
  longitude: number;
  latitude: number;
  parking: Parking;
  country: string;
  timezone?: string;
  countryCode?: string;
  parkingOptionId: number | null;
  petOptionId: number | null;
  stairOptionId: number | null;
  tableOptionId: number | null;
}

export interface PaymentMethod {
  id: number;
  braintreePaymentMethodToken: string;
  last4: string;
  expiryMonth: string;
  expiryYear: string;
  email: string;
  type: string;
  cardType: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  isExpired: boolean;
}

export namespace PaymentMethod {
  export function toString(paymentMethod: PaymentMethod): string {
    if (paymentMethod.type.toLowerCase() === "paypal") {
      return paymentMethod.email;
    }

    if (!paymentMethod.cardType) {
      return "";
    }

    return `${paymentMethod.cardType} ending ${paymentMethod.last4}`;
  }

  export function iconFor(paymentMethod: PaymentMethod): string | undefined {
    if (paymentMethod.type.toLowerCase() === "paypal") {
      return PayPalIcon;
    }

    if (!paymentMethod.cardType) {
      return CardIcon;
    }

    switch (paymentMethod.cardType.toLowerCase()) {
    case "visa":
      return VisaIcon;
    case "mastercard":
      return MastercardIcon;
    case "american express":
      return AmexIcon;
    default:
      return CardIcon;
    }
  }
}

export interface FavoriteTherapist {
  id: number;
  userId: number;
  therapistId: number;
  favoritelistId: number;
  position: number;
  createdAt: string;
  updatedAt: string;
  therapist?: Therapist;
}

export interface RecentlyBookedPro {
  id: number;
  isCovidVaccinated: number;
  firstName: string;
  lastName: string;
  gender: string;
  totalReviews: number;
  therapistprofile: TherapistProfile;
  useruploadedfiles?: ProDocs[];
}

export interface BlockedTherapist {
  id: number;
  userId: number;
  therapistId: number;
  blockReason: string;
  createdAt: string;
  updatedAt: string;
  therapist?: Therapist;
}

export interface Therapist {
  firstName: string;
  lastName: string;
  therapistprofile: TherapistProfile;
  useruploadedfiles?: ProDocs[];
}

export interface TherapistProfile {
  profileImage: string;
  rating: number;
  latitude: number;
  longitude: number;
  bio: string;
  totalCompletedJobs: number;
  experience: string;
  kit: string;
}

export interface TherapistPublicProfile {
  therapistData: TherapistPublicProfileData;
  isFavorite: boolean;
  isBlocked: boolean;
  isSuspended: boolean;
  totalReviews: number;
  recentReviews: Array<RecentReview>;
  userServices: Array<Service>;
}

export interface RecentReview {
  id: number;
  therapistId: number;
  userId: number;
  jobId: number;
  rating: number;
  review: string;
  createdAt: string;
  updatedAt: string;
  user: User;
  job: Job;
}

export interface UserReview {
  firstName: string;
  lastName: string;
  profileImage: string;
  rating: number;
  review?: string | null;
  reviewFiles?: Array<any>;
  massageType: string;
  suburb?: string | null;
  state?: string | null;
}

export interface UploadedFile {
  id: number;
  userId: number;
  filePath: string;
  type: string;
}

export interface TherapistPublicProfileData {
  addresses: any[];
  firstName: string;
  lastName: string;
  gender: string;
  therapistprofile: TherapistProfile;
  userservices: Array<UserService>;
  isCovidVaccinated: boolean;
  useruploadedfiles: Array<UploadedFile>;
  id: string;
  totalReviews: number;
}

export interface UserService {
  id: number;
  userId: number;
  serviceId: number;
  updatedAt: string;
  createdAt: string;
  service: Service;
}

export interface UserTreatment {
  id: number;
  userId: number;
  treatmentId: number;
  updatedAt: string;
  createdAt: string;
  isDisable?: boolean;
}

export interface Service {
  id: number;
  name: string;
  alias: string;
  custom: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface BookingPrice {
  subtotal: number;
  additionalTreatmentPrice: number;
  hotelSurcharge: number;
  lateNightSurcharge: number;
  publicHolidaySurcharge: number;
  lateNightSurchargeApplied: boolean;
  discount: number;
  discountBeforeConversion: number;
  originalCouponCurrency: string;
  originalCouponCurrencySymbol: string;
  credit: number;
  creditBeforeConversion: number;
  price: number;
  coupon: string;
  peakHourMessage: string;
  peakHourInfo: {
    title: string;
    subtitle: string;
    data: [];
  };
  clientPlatformFee: {
    amount: number;
    remarks: string;
    title: string;
  };
  minSpend: number;
  basePrice: number;
  addressSurcharge: number;
  parkingAndTravelInfo: {
    title: string;
    subtitle: string;
    data: [];
  };
}

export interface Recipient {
  id: number;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  gender: string;
  relationship: Relationship;
  specialInstructions: string;
  hasContraindications: boolean;
  contraindications: string;
  createdAt: string;
  updatedAt: string;
  companyName?: string;
  userId: number;
  isCovidVaccinated: boolean;
  customerId?: number | string;
  orderNumber?: string;
  lineItemCode?: string;
  contactPerson?: {
    countryCode: string;
    email: string;
    firstName: string;
    lastName: string;
    mobile: string;
    relation: string;
    isPrimaryPointOfContact?: boolean;
  };
  aliasUserId: number | null;
  primaryContactUserId: number | null;
  autoUpdateBooking?: boolean;
  profileImage?: string | null;
}

export type RecipientWithSummary = Recipient & {
  last30DaysBookings: number;
  totalBookings: number;
  averageRating: null | string;
  totalRatings: number;
  bookings: { id: number; createdAt: string }[];
};

export interface ErrorResponse {
  errorMessage: string;
  error: {
    message: string;
  };
}

export interface BookingDetails {
  id: number;
  title?: string;
  massageType: string;
  serviceDuration: number;
  genderPreference: string;
  genderFallback: boolean;
  genderFallbackTimeReached: boolean;
  deliveryMethod: string;
  treatmentTypeId: number;
  createdAt: string;
  updatedAt: string;
  bookingId: number;
  treatmenttypeId: number;
  booking: Booking;
  job: Job;
  treatmentDetails: any[];
}

export interface Job {
  id: number;
  status: JobStatus;
  createdAt: string;
  updatedAt: string;
  acceptedAt: string;
  startedAt: string;
  finishedAt: string;
  therapistsToRebook: number;
  lastNotificationIteration: number;
  checkedInIfNotFinishedAt: string;
  startTimeChanged: boolean;
  bookingdetailId: number;
  therapistId: number;
  user: User;
  userreview?: ClientReview;
  therapistreview: TherapistReview;
  bookingdetail: BookingDetails;
  serviceFee?: number;
  bookingId: number;
  canOpenChat: boolean;
  isAgreementRequired: boolean;
}

export interface DeclinedJob {
  reasonForDeclining: string;
  therapistId: number;
}
interface ReviewFiles {
  id: number;
  filePath: string;
}

export interface ClientReview {
  id: number;
  rating: number;
  review?: string;
  updatedAt: string;
}

export interface TherapistReview {
  id: number;
  therapistId: number;
  userId: number;
  jobId: number;
  rating: number;
  review: string;
  reviewFiles: Array<ReviewFiles>;
  createdAt: string;
  updatedAt: string;
}

export interface TherapistUserReview extends TherapistReview {
  user: User;
  therapist: User;
  job: Job;
}

export interface BookingFlag {
  id: number;
  bookingId: number;
  title: string;
  flag: boolean;
}

export interface BookingInstructions {
  note: string | null;
  contraindications: string | null;
  preference: string | null;
}

export interface Booking {
  id: number;
  braintreeTransactionId: string;
  braintreeSettlementSubmittedAt: string;
  sessionType: string;
  massageDuration: number;
  specialInstructions: string;
  instructions: BookingInstructions;
  earliestTime: string;
  latestTime: string;
  status: string;
  massageFor: MassageFor;
  timezone: string;
  price: number;
  originalPrice: number;
  serviceFee: number;
  serviceTax: number;
  serviceFeeOverride: number;
  priceWithoutDiscount: number;
  preferredTherapists: string;
  createdAt: string;
  updatedAt: string;
  timeOfArrival: string;
  numberOfNotifiedTherapists: number;
  reviewToken: string;
  promo: string;
  cancellationreason: string;
  cancellationFee: number;
  previousPreferredTherapists: string;
  channel: string;
  rebookingWaitInHour: string;
  rebookingStatus: string;
  latestTimeNotifiedForPending?: boolean;
  parking: string;
  lateNightSurcharge: number;
  lateNightSurchargeApplied: boolean;
  lateNightSurchargeWasCharged: boolean;
  needsAttention: boolean;
  eliteOnly: boolean;
  hotelSurcharge: number;
  afterpayInitiatedAt: string;
  afterpayOrderToken: string;
  currency: string;
  originalTimezone: string;
  serviceType: string;
  userId: number;
  paymentmethodId: number;
  addressId: number;
  addressSurcharge: number;
  recipientId: number;
  couponId: number;
  referrerId: number;
  totalAlternativeDateOffered: number;
  user: User;
  paymentmethod: PaymentMethod;
  address: Address;
  recipient: Recipient;
  bookingdetails: Array<BookingDetails>;
  declinedjobs: Array<DeclinedJob>;
  coupon: Coupon;
  credituses: Array<CreditUse>;
  referrer: string;
  bookingnotes: Array<any>;
  bookingservices: Array<any>;
  notifiedtherapists: Array<Therapist>;
  bookinganswers: Array<any>;
  bookinglogs: Array<any>;
  isHairAndMakeup: boolean;
  isB2B?: boolean;
  isPaid: boolean;
  fallbackPrompt: any;
  toBeReadyBy: string;
  numberOfClients: number | null;
  publicHolidaySurcharge: number;
  peakHourInfo?: object;
  parkingAndTravelInfo?: { title: string; subtitle: string; data: [] };
  peakHourMessage: string;
  serviceId: number | null;
  clientPlatformFee: number;
  platformFeeData?: object;
  title: string | null;
  isMassage: boolean;
  isPlatformFeeApplied: boolean;
  bookingUpdates?: BookingUpdateRequest[];
  backup?: { earliestTime: string; latestTime: string };
  isActuallyPaid: boolean;
  isManual: boolean;
  isHomeCare: boolean;
  statusFlag?: {
    isPending: boolean;
    isConfirmed: boolean;
    isCancelled: boolean;
    isDeclined: boolean;
    isCompleted: boolean;
  };
  bookingFlags?: BookingFlag[];
  isBookedByPro?: boolean;
  corporateInfo?: any;
  bookingLink?: string | null;

  recurring?: {
    frequency: number;
    isSameProvider: boolean;
    type: RecurringType;
    isCustom: boolean;
    monthly: MonthData;
    shouldCreateFutureBookings?: boolean;
    isRepeating?: boolean;
  };

  recuringEndOn?: {
    endsOn: Date;
    type: string;
    occurrence: number;
  };
  preferredTherapistProfile?: { id: number; profileImage: string }[];
  paymentType?: PaymentType;
  cancellationRequest?: {
    reason: string;
    jobId: number;
    text: string;
  };
  selectedService?: {
    id: number;
  };
  totalPaid?: number;
  totalAmountDue?: number;
  bookingFallbacks?: Array<object>;
  uuid: string | null;
  hasInvoices?: boolean;
  conversationId?: string;
  canOpenChat?: boolean;
}

export interface CorporateBooking {
  addressId: number;
  serviceId: number;
  treatmentId: number;
  numberOfPerson: number;
  durationPerPerson: number;
  numberOfPros: number;
  eventType: string;
  eventName: string;
  earliestTime: string;
  latestTime: string;
  recipientId: number | null | undefined;
  paymentmethodId: number;
  couponCode: string | null;
  paymentSplit: string;
  specialInstruction: string | null;
}

export interface CorporateBookingQuotation {
  address: Address | null;
  addressId: number;
  serviceId: number;
  treatmentId: number;
  numberOfPerson: number;
  durationPerPerson: number;
  numberOfPros: number;
  eventType: string;
  eventName: string;
  earliestTime: string;
  latestTime: string;
  recipientId: number | null | undefined;
  paymentmethodId: number;
  couponCode: string | null;
  paymentSplit: string;
  specialInstruction: string | null;
  recipient: Recipient | null;
  massageFor: MassageFor | null;
  frequency: number | null;
  totalEventDuration: any;
}

export interface CorporateRecipient {
  id: number;
  userId?: number;
  mobile?: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage?: string;
  role?: any;
  gender?: string;
  relationship?: string;
  specialInstructions?: string;
  companyName?: string | null;
  isCovidVaccinated?: boolean;
}

export interface BookingUpdateRequest {
  bookingId: number;
  clientId: number;
  pro: {
    firstName: string;
    lastName: string;
    id: number;
  };
  requestDetails: {
    [key: string]: any;
  };
  status: UpdateRequestStatus;
  requestedBy: UpdateRequestedBy;
  jobId: number;
}

export interface TherapistBooking {
  id: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  acceptedAt: string;
  startedAt: string;
  finishedAt: string;
  bookingdetailId: number;
  therapistId: number;
  bookingId: number;
  serviceFee?: number | null;
  bookingdetail: BookingDetails;
}

export interface Coupon {
  id: number;
  type: string;
  code: string;
  value: number;
  couponText: string;
}

export interface CreditUse {
  amount: number;
  originalAmount: number;
  originalCurrency: string;
}

export interface PastBookingsResponse {
  bookings: Array<Booking>;
  perPage: number;
  currentPage: number;
  objectCount: number;
  pageCount: number;
}

export interface TreatmentFor {
  service?: string;
  label?: string;
  value?: string;
}

export interface GenderPreferences {
  value: string;
  title: string;
  helpText: string | null;
}

export interface Duration {
  title: string;
  value: number;
}

export interface SessionTypes {
  title: string;
  value: string;
  description: string;
  icon: string;
  backgroundColor: string;
  image: string;
  numberOfClients: number;
  numberOfPros: number;
  isSingle: boolean;
  isBackToBack: boolean;
  isCouples: boolean;
}

export interface ServiceRate {
  id: number;
  name: string;
  alias: string;
  custom: boolean;
  country: string;
  createdAt: string;
  updatedAt: string;
  profession?: string;
  isHairAndMakeup: boolean;
  isHairColouring: boolean;
  isMassage: boolean;
  isCorporate: boolean;
  hasFixedDuration: boolean;
  category?: string | null;
  prices: Array<ServiceRatePrice>;
  genderPreferences?: Array<GenderPreferences>;
  duration?: Array<Duration>;
  sessionTypes?: Array<SessionTypes>;
  selectedCountry?: {
    currency: string;
    currencySymbol: string;
  };
}

interface MassageDurationRate {
  duration: number;
  rate: number;
}
export interface ServiceRatePrice {
  price?: number;
  id: number;
  name: string;
  label: string;
  rate: number;
  serviceId: string;
  duration?: string;
  shortDescription?: string;
  fullDescription?: string;
  deliveryMethod: string;
  createdAt: string;
  updatedAt: string;
  icon?: string;
  backgroundColor?: string;
  selectedCountry?: {
    currency: string;
    currencySymbol: string;
  };
  isCorporate?: boolean;
  isMultipleAddonsAllowed?: boolean;
  rates?: MassageDurationRate[];
  addons?: Array<ServiceRatePrice>;
}

export interface TreatmentAddOn extends ServiceRatePrice {
  treatmentIndex: number;
}

export interface BookingQuestionMeta {
  id: number;
  name: string;
  alias: string;
  custom: boolean;
  createdAt: string;
  updatedAt: string;
  bookingquestions: Array<BookingQuestion>;
}

export interface AnswerDetails {
  title: string;
  value: string;
  addedPrice: number;
  addedDuration?: number;
  priceType: string;
}

export interface BookingQuestion {
  id: number;
  serviceId: number;
  question: string;
  description?: string;
  values: string;
  createdAt: string;
  updatedAt: string;
  treatmentId: number;
  type: string;
  attrs?: {
    max?: number;
    appendDurationToTreatment?: boolean;
  };
  required: boolean;
  answerDetails?: AnswerDetails[];
  answer?: any;
  isAddOns?: boolean;
}

export interface LocationType {
  id: number;
  sequence: number;
  slug: string;
  label: string;
  createdAt: string;
  updatedAt: string;
  countryCode: string;
  helperText?: string;
}

export interface MyPreferences {
  id: number;
  userId: number;
  serviceId?: number;
  preference: string;
  createdAt: string;
  updatedAt: string;
}

export interface InvitedFriend {
  bookingDate: string;
  userId: number;
  fullName: string;
  amount: number;
}

export interface Purchase {
  id: number;
  userId: number;
  massageDuration: number;
  sessionType: string;
  fromName: string;
  toName: string;
  sendASAP: string;
  sendOnDate: string;
  sendTo: string;
  recipientEmail: string;
  note: string;
  paymentMethodId: number;
  braintreeTransactionId: string;
  afterpayOrderToken: string;
  braintreeSettlementSubmittedAt: string;
  price: number;
  priceWithoutDiscount: number;
  couponId: number;
  couponIdUsedAtCheckout: any;
  completedAt: string;
  createdAt: string;
  updatedAt: string;
  coupon: Coupon | null;
  couponUsedAtCheckout: CouponUsedAtCheckout;
  creditUsed: number | null;
  user: PurchaseUser;
  deliveryStatus: string;
  clientPlatformFee: number;
}

export interface PurchaseUser {
  firstName: string;
  lastName: string;
  email: string;
}

export interface CouponUsedAtCheckout {
  id: number;
  code: string;
  type: string;
  value: number;
}

export interface Coupon {
  id: number;
  type: string;
  code: string;
  value: number;
  maxNumberOfUsePerUser: number;
  maxNumberOfUseForAllUsers: number;
  usageCounter: number;
  createdAt: string;
  updatedAt: string;
  promotion: boolean;
  testing: boolean;
  expireDate: string;
  couponText: string;
  selectedCountry?: {
    currency?: string;
    currencySymbol?: string;
  };
}

export interface UploadedFile {
  id: number;
  userId: number;
  filePath: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface UserFund {
  id: number;
  userId: number;
  providerId: string;
  healthfundId: number;
  createdAt: string;
  updatedAt: string;
  healthfund: HealthFund;
}

export interface HealthFund {
  id: number;
  name: string;
  custom: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface BlockedClient {
  firstName: string;
  reason: string;
  createdAt: string;
  userId: number;
  id: number;
}

export interface PaginatedResponse {
  pageCount: number;
  objectCount: number;
  currentPage: number;
  perPage: number;
}

export interface PaginatedResponse2 {
  count: number;
  page: number;
  pageCount: number;
  perPage: number;
}

export interface PaginatedResponse3 {
  page: number;
  perPage: number;
  total: number;
}

export interface AbandonedJob {
  id: number;
  therapistId: number;
  jobId: number;
  reason: string;
  cancellationFee: number;
  createdAt: string;
  updatedAt: string;
  excludeFromReliabilityRate: boolean;
  job: Job;
}

export interface EarningsChartData {
  name: string;
  key: string;
  data: EarningsChartDataData;
}

export interface EarningsChartDataData {
  label: string[];
  values: number[];
}

export interface EarningsSummaryData {
  name: string;
  key: string;
  data: EarningsSummaryDataData;
}

export interface EarningsSummaryDataData {
  amount: number;
}

export interface ReportingData {
  id: string;
  values: ReportingDataValue[];
}

export interface ReportingDataValue {
  name: string;
  key: string;
  data: ReportingDataValueData;
}

export interface ReportingDataValueData {
  amount: number;
}

export interface PaymentSummary {
  id: number;
  userId: number;
  fromDate: string;
  toDate: string;
  amount: number;
  totalJobs: number;
  file: string;
  createdAt: string;
  updatedAt: string;
}

export interface BillingSummary {
  id: number;
  userId: number;
  invoiceNumber: string;
  amount: number;
  file: string;
  fromDate: string;
  toDate: string;
  createdAt: string;
  updatedAt: string;
}

export interface PublicReview {
  id: number;
  therapistId: number;
  userId: number;
  jobId: number;
  rating: number;
  review: string;
  createdAt: string;
  updatedAt: string;
  user: User;
  therapist: User;
  job: Job;
}

export interface PublicReviewsResponse {
  reviewsList: PublicReview[];
  currentPage: string;
  pageCount: number;
  objectCount: number;
  perPage: string;
  avgRating: string;
}

export interface ProDirectoryTherapist {
  user: User;
  distance: number;
  totalReviews: number;
  userservices: Array<UserService>;
}

export interface ProDirectoryTherapistResponse {
  currentPage: number;
  pageCount: number;
  objectCount: number;
  perPage: number;
  therapistList: Array<ProDirectoryTherapist>;
}

export interface ConfirmAbandonJobParameters {
  id: number | undefined;
  fee: number | undefined;
  reason: string | undefined;
}

export interface JobAbandonFeeResponse {
  description: string;
  fee: number;
  isAbandonAllowed: boolean;
  subrub: string;
  title: string;
}

interface JobAddressSelectedOption {
  id: number;
  title?: string;
  label?: string;
}

export interface JobAddress {
  address: string;
  fullAddress: string;
  suburb: string;
  state: string;
  country: string;
  instructions: string;
  locationtype: JobAddressSelectedOption;
  parkingOption: JobAddressSelectedOption;
  stairOption: JobAddressSelectedOption;
  petOption: JobAddressSelectedOption;
  type: string;
}

export interface OfferedAlternativeDate {
  id: number;
  time: string;
  status: string;
}

export interface ProPayoutBreakdownDetail {
  description: string;
  title: string;
}

export interface ProPayoutBreakdown {
  label: string;
  value: number;
  text: string;
  currencySymbol: string;
  details?: ProPayoutBreakdownDetail;
}

export interface InvoiceLink {
  title: string;
  link: string;
}

export interface Coordinators {
  label: string;
  value: number;
}
