import { useQuery } from "@tanstack/react-query";

import { get } from "../../api/client";
import { DropdownOption } from "../../components/Dropdown";

export const COUNTRY_STATE_KEYS = {
  STATE_OPTIONS: "country-states-options",
};

export const useStateOptions = ({ countryCode, transformToDropdownOptions = true }: any) =>
  useQuery(
    [COUNTRY_STATE_KEYS, { countryCode }],
    () => get("api/v2/utility/country-states", { countryCode }),
    {
      select: (data) => {
        if (!transformToDropdownOptions) return data;

        return (data || []).map(
          ({ name, code }: any) =>
            ({
              title: name,
              value: code,
            } as DropdownOption)
        ) as DropdownOption[];
      },
    }
  );
