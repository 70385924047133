import { Box, styled } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../constants/colors";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
} from "../../v2/Styled/enum";
import { useHistory } from "react-router-dom";
import { scrollToTop } from "../../../helpers/scrollToTop";

export enum PredefineFilterEnum {
  All = "All",
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
}
interface Props {
  filters: {
    value: PredefineFilterEnum;
    label: PredefineFilterEnum;
  }[];
  onPredefineFilterApplied: (selectedFilter: PredefineFilterEnum) => unknown;
}
const CustomBox = styled(Box)({
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "-ms-overflow-style": "none",
  "scrollbar-width": "none",
});
export const PredefinedFilter = ({ filters, onPredefineFilterApplied }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<PredefineFilterEnum>(
    PredefineFilterEnum.All
  );
  const history = useHistory();

  const persistDataInUrl = (value: PredefineFilterEnum) => {
    const params = new URLSearchParams(history.location.search);

    if (params.get("preDefinedFilter") !== value) {
      params.set("preDefinedFilter", value);
      const searchParams = params.toString();
      history.push({ search: searchParams, state: { shouldScrollToTop: false } });
    }
  };
  const onFilterSelected = (value: PredefineFilterEnum) => {
    if (value === selectedFilter) return;
    setSelectedFilter(value);
    persistDataInUrl(value);
    onPredefineFilterApplied(value);
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const preDefinedFilter = params.get("preDefinedFilter");
    setSelectedFilter((preDefinedFilter || PredefineFilterEnum.All) as PredefineFilterEnum);
  }, []);
  return (
    <CustomBox
      display={Display.Flex}
      flexDirection={FlexDirection.Row}
      style={{ gap: Spacing.S4, width: "auto", overflowX: "scroll" }}
    >
      {(filters || []).map(({ value, label }) => {
        return (
          <Box
            bgcolor={selectedFilter === value ? Colors.LightCyan : Colors.White}
            borderRadius={"8px"}
            style={{
              border: `1px solid ${Colors.LightPeriwinkle}`,
              padding: "10px 16px 10px 16px",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
            color={Colors.Dusk}
            fontWeight={FontWeight.Medium}
            fontSize={FontSize.F16}
            fontFamily={FontFamily.Museo}
            onClick={() => {
              onFilterSelected(value);
            }}
          >
            {label}
          </Box>
        );
      })}
    </CustomBox>
  );
};
