import { get, put } from "../../api/client";
import { ConfirmAbandonJobParameters } from "../../models";

const getJobAbandonFee = (id: number | undefined, abandonReason: string) =>
  get(`api/v2/therapists/jobs/${id}/abandonmentFee?abandonReason=${abandonReason}`);

const confirmJobAbandon = ({ id, fee, reason }: ConfirmAbandonJobParameters) =>
  put(`api/v2/therapists/jobs/${id}/abandon`, {
    fee,
    reason,
  });

export { getJobAbandonFee, confirmJobAbandon };
