import React from "react";
import styles from "./styles";
import { Box } from "@material-ui/core";

interface Props {
  title: string;
  wrapperStyle?: any,
  textStyle?: any,
}

const SectionHeader = ({ title, wrapperStyle = {}, textStyle = {}}: Props) => {
  return (
    <Box style={{ ...wrapperStyle }}>
      {title && <Box style={{ ...styles.header, ...textStyle }}>{title}</Box>}
    </Box>
  );
};

export default SectionHeader;
