import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";
import { SERVICE_COUNTRIES } from "../../constants/countries";
import { ServiceRate } from "../../models";
import { DEFAULT_COUNTRY } from "../../utils/country";
import { useState, useEffect } from "react";

const useServiceRates = (country: string = DEFAULT_COUNTRY, global: boolean = true) => {
  const [services, setServices] = useState<ServiceRate[]>([]);
  const QUERY_KEY = global ? "global-Services" : `services-${country}` ;

  const getUrlPath = () => {
    let path = "api/v3/service?channel=web";
    if (global) path += "&global=yes";
    else path += `&country=${country}`;
    return path;
  };

  const { isLoading, data } = useQuery([QUERY_KEY], () => {
    const path = getUrlPath();
    return get(path);
  });

  const filterByCountryCode = (code: string, services: ServiceRate[]) => {
    return services.filter(({ country }) => country === code);
  };

  useEffect(() => {
    if (!data || data.length <= 0) return;

    let servicesList = data;
    if (global) {
      const countryCode = SERVICE_COUNTRIES.includes(country) ? country : DEFAULT_COUNTRY;
      servicesList = filterByCountryCode(countryCode, data);
    }
    setServices(servicesList);
  }, [data, country, global]);
  
  return { services, isLoading };
};

export { useServiceRates };
