import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import TextField from "../TextField";
import Dropdown from "../Dropdown";
import Button, { ButtonSize, ButtonType } from "../Button";
import AddressAutocomplete from "../AddressAutocomplete";
import { SetLocationModal } from "../Modals/MapModal/SetLocationModal";
import {
  useBusinessOptions,
  useCreateBusinessDetails,
  useGetBusinessDetails,
  useUpdateBusinessDetails,
} from "../../hooks/business/business.hooks";
import { getValue } from "../../utils/object";
import { useAlertStore } from "../../stores/alert";
import { parseApiError } from "../../helpers/error";
import { isNumber } from "../../helpers/validation";
interface SelecetedBusinessOption {
  value: string;
  title: string;
  takesUserInput?: boolean;
}
export const Business = () => {
  const [organizationName, setOrganizationName] = useState<string>("");
  const [businessNumber, setBusinessNumber] = useState<string>("");
  const [businessAddress, setBusinessAddress] = useState<string>("");
  const [selectedBusinessType, setSelectedBusinessType] = useState<SelecetedBusinessOption | null>(
    null,
  );
  const [otherBusinessType, setOtherBusinessType] = useState<string>("");
  const [isMapSelected, setIsMapSelected] = useState<boolean>(false);

  const showOtherBusinessType = getValue(selectedBusinessType, "takesUserInput", false);

  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { data: businessOptions } = useBusinessOptions();
  const { isLoading: isBusinessDetailsLoading, data: businessDetails } = useGetBusinessDetails();

  const { isLoading: isSaving, mutate: createBusinessDetail } = useCreateBusinessDetails({
    onSuccess: () => {
      setSuccessMessage("Your business details have been updated.");
    },
    onError: (error) => {
      setErrorMessage(parseApiError(error));
    },
  });
  const { isLoading: isUpdating, mutate: updateBusinessDetail } = useUpdateBusinessDetails({
    onSuccess: () => {
      setSuccessMessage("Your business details have been updated.");
    },
    onError: (error) => {
      setErrorMessage(parseApiError(error));
    },
  });

  const onBusinessTypeSelected = (selectedOption: SelecetedBusinessOption) => {
    setSelectedBusinessType(selectedOption);
  };

  const initiateBusinessDetails = () => {
    setOrganizationName(businessDetails?.businessName || "");
    setBusinessAddress(businessDetails?.businessAddress || "");
    setBusinessNumber(businessDetails?.businessNumber || "");
    setSelectedBusinessType(businessDetails?.selectedBusinessOption || null);

    const isOtherBusinessType = getValue(
      businessDetails,
      "selectedBusinessOption.takesUserInput",
      false,
    );
    if (isOtherBusinessType) {
      setOtherBusinessType(businessDetails?.businessType || "");
    }
  };

  useEffect(() => {
    if (!isBusinessDetailsLoading) {
      initiateBusinessDetails();
    }
  }, [isBusinessDetailsLoading]);

  const validateForm = () => {
    if (!organizationName && !businessNumber && !businessAddress && !selectedBusinessType) {
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (!validateForm()) return;
    const payload = {
      businessName: organizationName,
      businessNumber,
      businessAddress,
      businessType: selectedBusinessType?.takesUserInput
        ? otherBusinessType
        : selectedBusinessType?.value,
    };
    if (!isBusinessDetailsLoading && businessDetails) {
      updateBusinessDetail({
        data: payload,
        businessDetailId: businessDetails?.id,
      });
      return;
    }
    createBusinessDetail(payload);
  };
  return (
    <>
      <TextField
        title="Organisation name"
        value={organizationName}
        onChange={(value) => {
          setOrganizationName(value);
        }}
        placeholder="Enter organisation name"
      />
      <TextField
        title="Business number"
        value={businessNumber}
        onChange={(value) => {
          if (!isNumber(value)) return;
          setBusinessNumber(value);
        }}
        placeholder="Enter business number"
      />
      <AddressAutocomplete
        title="Business address"
        value={businessAddress}
        onChange={(text) => {
          setBusinessAddress(text);
        }}
        onSelectedAddress={(address) => {
          setBusinessAddress(address?.fullAddress || "");
        }}
        onMapSelected={() => {
          setIsMapSelected(true);
        }}
        placeholder="Enter business address"
      />

      <SetLocationModal
        open={isMapSelected}
        onClose={() => {
          setIsMapSelected(false);
        }}
        setIsMapSelected={setIsMapSelected}
        handleAddressSelected={({ address }) => {
          setBusinessAddress(address.fullAddress || "");
        }}
      />

      <Dropdown
        title="Business type"
        options={businessOptions || []}
        onSelectedOption={onBusinessTypeSelected}
        selectedOption={selectedBusinessType}
      />
      {showOtherBusinessType ? (
        <TextField
          value={otherBusinessType}
          onChange={(value) => {
            setOtherBusinessType(value);
          }}
          multiline
        />
      ) : (
        <></>
      )}

      <Box height="25px" />

      <Button
        title="Save"
        type={ButtonType.primary}
        width="128px"
        onClick={() => {
          handleSave();
        }}
        size={ButtonSize.small}
        loading={isSaving || isUpdating}
      />
    </>
  );
};
