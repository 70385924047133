import React from "react";
import { Box, Text } from "../v2/Styled";
import { Colors } from "../../constants/colors";
import { PayoutStatus } from "../../constants/payment";
import { FontFamily, FontSize, FontWeight, LetterSpacing, LineHeight, Spacing } from "../v2/Styled/enum";

const PayoutStatusPillBgColor = {
  pending: Colors.LightPeriwinkle,
  inTransit: Colors.PowderBlue,
  paid: Colors.VeryLightGreen,
};

interface Props {
  payoutStatus: keyof typeof PayoutStatus;
}

const PayoutStatusPill = ({ payoutStatus }: Props) => {
  const backgroundColor = PayoutStatusPillBgColor[payoutStatus] || PayoutStatusPillBgColor.pending;
  const status = PayoutStatus[payoutStatus] || PayoutStatus.paid;

  return (
    <Box
      paddingX={Spacing.S4}
      paddingY={Spacing.S1}
      borderRadius={"24px"}
      backgroundColor={backgroundColor}
    >
      <Text
        font={FontFamily.SFPro}
        size={FontSize.F12}
        weight={FontWeight.SemiBold}
        color={Colors.NightBlue}
        lineHeight={LineHeight.L21}
        letterSpacing={LetterSpacing.S05}
      >
        {status}
      </Text>
    </Box>
  );
};

export default PayoutStatusPill;
