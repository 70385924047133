import { Box } from "@material-ui/core";
import TextLink from "../../../../components/TextLink";
import { Booking } from "../../../../models";

import { Colors } from "../../../../constants/colors";
import { checkIfJobAccepted } from "../../../../helpers/booking";
import { isCorporateSession } from "../../../../helpers/massage";
import { formatTime } from "../../../../helpers/time";
import TimeIcon from "../../../../images/review-calendar-time.svg";
import { getRangedBookingFormattedDateTime } from "../../../../services/bookings/bookingTime.service";
import { isCoupleMassageType } from "../../../../stores/booking";
import { formatDateWithTimezone, isSameDay } from "../../../../utils/date.util";

interface Props {
  booking: Booking;
  onChange?: () => unknown;
}

const BookingTimeDetails = ({ booking, onChange }: Props) => {
  const { sessionType, earliestTime, latestTime, timezone } = booking;

  const isCouplesMassage = isCoupleMassageType(sessionType);
  const isCorporateType = isCorporateSession(sessionType);
  const isAnyJobAccepted = checkIfJobAccepted(booking);

  const showLabel = !isCorporateType;

  const getCouplesTimeFormat = (earliestTime: string) => {
    return `${formatDateWithTimezone(earliestTime, timezone, "D MMM")} at ${formatTime({
      timeString: earliestTime,
      timezone,
    })}`;
  };

  const getSinglesTimeFormat = (earliestTime: string, latestTime: string) => {
    return `${formatDateWithTimezone(earliestTime, timezone, "D MMM")} between ${formatTime({
      timeString: earliestTime,
      timezone,
    })} -
    ${formatTime({ timeString: latestTime, timezone })}`;
  };

  const getDateAndTime = () => {
    const isSame = isSameDay(earliestTime, latestTime, timezone);
    const formattedDate = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });

    let date = "";
    let time = "";
    if (isSame) {
      date = formattedDate.datetime;
    } else {
      date = `${formattedDate.date},`;
      time = formattedDate.time;
    }

    return (
      <Box ml={2} mr={2} mb={"12px"} fontFamily="Museo">
        {showLabel ? (
          <Box
            style={{
              fontWeight: 400,
              fontSize: "16px",
              color: "#8B96AD",
              padding: "2px 0",
            }}
          >
            Preferred date and time
          </Box>
        ) : (
          <></>
        )}
        <Box fontSize="16px" color={Colors.Dusk} style={{ overflowWrap: "break-word" }}>
          {`${date} ${time ? time : ""}`}
        </Box>
      </Box>
    );
  };

  const getBackupDateAndTime = () => {
    if (!booking || !booking.backup) return null;

    const backupData = booking.backup;
    const { earliestTime, latestTime } = backupData;

    const isSame = isSameDay(earliestTime, latestTime, timezone);
    const formattedDate = getRangedBookingFormattedDateTime({ earliestTime, latestTime, timezone });

    let date = "";
    let time = "";
    if (isSame) {
      date = formattedDate.datetime;
    } else {
      date = `${formattedDate.date},`;
      time = formattedDate.time;
    }

    return (
      <Box ml={2} mr={2} fontFamily="Museo">
        {showLabel ? (
          <Box
            style={{
              fontWeight: 400,
              fontSize: "16px",
              color: "#8B96AD",
              padding: "2px 0",
            }}
          >
            Backup date and time
          </Box>
        ) : (
          <></>
        )}
        <Box fontSize="16px" color={Colors.Dusk} style={{ overflowWrap: "break-word" }}>
          {`${date} ${time ? time : ""}`}
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="start">
      <Box width="24px" height="24px" justifyContent="center" alignItems="center" display="flex">
        <img src={TimeIcon} alt="Time" />
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        {getDateAndTime()}
        {booking.backup ? getBackupDateAndTime() : <Box />}
      </Box>

      {onChange && (!isCorporateType || (isCorporateType && !isAnyJobAccepted)) && (
        <TextLink title="Change" onClick={onChange} />
      )}
    </Box>
  );
};

export default BookingTimeDetails;
