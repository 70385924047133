import React, { Fragment } from "react";

import ImageQuestion from "./ImageQuestion";
import { BookingQuestion } from "../../../../models";
import SelectQuestion from "../../Questions/SelectQuestion";
import { QUESTION_TYPE } from "../../../../constants/questionType";
import { DEFAULT_CURRENCY } from "../../../../constants/booking";

interface Props {
  index: number;
  treatmentId?: number;
  questions: BookingQuestion[];
  onAnswerSelected?: () => unknown;
  setAnswer: (answer: any) => void;
  currency?: string;
}

const BookingQuestions = ({
  questions,
  treatmentId,
  index,
  setAnswer,
  currency = DEFAULT_CURRENCY.currencySymbol,
}: Props) => {

  const getAnswerForQuestion = (questionId: number) => {
    const answer: any = questions.find((question) => question.id === questionId)?.answer || {};
    return answer.answer;
  };

  const handleAnswerSelected = (questionId: any, value: any) => {
    const targetQuestion = questions.find(({ id }) => id === questionId);
    if (targetQuestion && targetQuestion.answerDetails) {
      const answerDetail = targetQuestion.answerDetails.find(
        ({ value: answerValue }) => answerValue === value,
      );
      setAnswer({
        answer: answerDetail,
        treatmentIndex: index,
        questionId,
      });
    }
  };

  const onImageUploadSuccess = (data: any, questionId: number, type = QUESTION_TYPE.IMAGE) => {
    const {
      id,
      filePath,
      type: imageType,
    } = data.filter(({ type }: any) => type === `question-${questionId}`).at(-1);

    const imageData = { id, filePath, type: imageType };
    const targetAnswer: any = questions.find((question) => question.id === questionId)?.answer
      ?.answers;

    let images: any[] = [];
    if (targetAnswer) {
      images = targetAnswer.length > 0 ? [...targetAnswer] : [];
    }

    images.push(imageData);

    setAnswer({
      questionId,
      answer: null,
      answers: images,
      treatmentIndex: index,
      treatmentId,
      type,
    });
  };

  const onImageDeleteSuccess = (imageId: number, questionId: number) => {
    const targetAnswer: any =
      questions.find((question) => question.id === questionId)?.answer?.answers || [];
    let tempAnswers = [...targetAnswer];

    tempAnswers = tempAnswers.filter(({ id }: { id: number }) => id !== imageId);

    setAnswer({
      questionId,
      answer: null,
      answers: tempAnswers,
      treatmentIndex: index,
      treatmentId,
      type: QUESTION_TYPE.IMAGE,
    });
  };

  return (
    <Fragment>
      {questions.map((question) => {
        if (question.treatmentId && question.treatmentId !== treatmentId) {
          return null;
        }
        switch (question.type) {
        case QUESTION_TYPE.IMAGE:
          return (
            <ImageQuestion
              key={`question-${question.id}`}
              question={question}
              uploadSuccessHandler={onImageUploadSuccess}
              removeSuccessHandler={onImageDeleteSuccess}
            />
          );
        case QUESTION_TYPE.SELECT:
        case QUESTION_TYPE.DURATION:
        default:
          return (
            <SelectQuestion
              key={`question-${question.id}`}
              question={question}
              answer={getAnswerForQuestion(question.id)}
              showPrices={question.isAddOns}
              onAnswerSelected={handleAnswerSelected}
              currency={currency}
            />
          );
        }
      })}
    </Fragment>
  );
};

export default BookingQuestions;
