import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import BlysLogo from "../../images/BLYS_LOGO_ORANGE.png";
import { useVoucherStore } from "../../stores/voucher";
import CaretLeft from "../../images/carat-left.svg";
import CaretRight from "../../images/carat-right.svg";

import { getFormatRespectToCountry } from "../../utils/date.util";
import { AlignItems, Cursor, Display, FlexDirection, FontWeight, JustifyContent, Spacing } from "../../components/v2/Styled/enum";
import { borderRadius, height, width } from "@mui/system";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./customStyle.css";

import { useEffect, useState } from "react";
import { checkIfEmpty, getValue } from "../../utils/object";
import { useVoucherCards } from "../../hooks/voucher/voucher.hooks";
import { getFullUploadsUrl } from "../../utils/url";

interface VoucherInterface {
  country?: string;
}

function SampleNextArrow (props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={CaretRight} alt="Next voucher" />
    </div>
  );
}

function SamplePrevArrow (props: any) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}><img src={CaretLeft} alt="Previous Voucher"/></div>
  );
}

export default function GiftVoucherPreview({  }: VoucherInterface) {
  const isMobile = useMobile();
  const { data: response, isLoading } = useVoucherCards();

  const { setCardType, cardType } = useVoucherStore();

  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const getVoucherImages = () => {
    const cards: any = getValue(response, "voucherCards") || [];
    const parsedCards = cards.map((card: string) => getFullUploadsUrl(card));
    return parsedCards;
  };

  let settings: any = {
    infinite: true,
    slidesToShow: isMobile ? 2.5 : 2,
    slidesToScroll: 1,
    centerMode: true,
  };

  if (!isMobile) {
    settings = { ...settings, verticalSwiping: true, vertical: true, focusOnSelect: false };
  }

  if (isLoading) return <Box />;

  const voucherImages = getVoucherImages();

  return (
    <Box
      display={Display.Flex}
      flexDirection={isMobile ? FlexDirection.Column : FlexDirection.Row}
      gridGap={Spacing.S4}
      sx={isMobile ? styles.containerMobile : styles.container}
    >
      {/* Main carousel */}
      <Box sx={isMobile ? styles.mainViewMobile : styles.mainView}>
        <Box>
          <Slider ref={(slider) => setNav1(slider)} dots={false} fade
            infinite
            asNavFor={nav2}
            initialSlide={cardType}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            afterChange={(index: number) => setSelectedIndex(index)}
            beforeChange={(_, nextIndex) => setCardType(nextIndex)}
          >
            {voucherImages.map((image: any) => (
              <div>
                <img
                  src={image}
                  alt="Gift voucher full"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "8px", outline: "none", cursor: Cursor.pointer }}
                />
              </div>
            ))}
          </Slider>
        </Box>
      </Box>
      {/* side Carousel */}
      <Box>
        <div style={{ height: "100%", width: isMobile ? "100%" : 120 }}>
          <Slider className="thumbnail-slider" ref={(slider) => setNav2(slider)} {...settings}
            asNavFor={nav1}
            focusOnSelect
            arrows={false}
          >
            {voucherImages.map((image: any, index: number) => (
              <div style={{ margin: 10 }}>
                {
                  !isMobile ?
                    <img
                      src={image}
                      alt="Gift voucher"
                      style={{
                        outline: "none",
                        border: index === selectedIndex? "2px solid black" : "none",
                        borderRadius: "8px",
                        cursor: Cursor.pointer,
                      }}
                      width={index === selectedIndex? "114px" : "120px"}
                      height="70px"
                    /> :
                    <img
                      src={image}
                      alt="Gift voucher"
                      style={{
                        outline: "none",
                        border: index === selectedIndex? "2px solid black" : "none",
                        borderRadius: "8px"
                      }}
                      width={index === selectedIndex? "94%" : "100%"}
                      height={index === selectedIndex? "94%" : "100%"}
                    />}
              </div>
            ))}
          </Slider>
        </div>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    height: "260px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    fontFamily: "Museo, 'Open Sans', serif",
    position: "relative",
    display: "flex",
    justifyContent: "space-evenly",
    overflow: "hidden",
  },
  containerMobile: {
    width: "327px",
    display:"flex",
    minHeight: "274px",
    flexDirection: "column",
    justifyContent:"center",
  },
  mainView: {
    width: "426px",
    height: "260px",
    borderRadius: "8px",
  },
  mainViewMobile:{
    width:"327px",
    height:"200px",
  },
  carouselViewWrapper: {
    width: "125px",
    height: "495px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "14px",
  },
  carouselViewWrapperMobile: {
    gap: "14px",
    width: "125px",
    height: "495px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  carouselView: {
    backgroundColor: "yellow",
    width: "120px",
    height: "70px",
    top: "2px",
    left: "2px",
    border: "2.5px",
    borderRadius: "8px",
  }
};
