import {
  Box,
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import React from "react";
import CrossIcon from "../../../images/cross.svg";
import "./treatmentDescription.css";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  btnText?: string;
}

const CloseButton = styled(Button)({
  fontSize: "16px",
  textTransform: "none",
  color: "#41506F",
  marginRight: 20,
});

function TreatmentDescription(props: Props) {
  return (
    <MUIDialog open={props.isOpen} maxWidth="sm" fullWidth={true} onClose={props.onClose}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        style={{
          padding: 8,
          height: "52px",
        }}
      >
        <DialogTitle style={{ flex: 1, padding: 0 }}>
          <Typography className="treatmentTitle">{props.title}</Typography>
        </DialogTitle>
        <IconButton
          onClick={props.onClose}
          style={{ margin: 0, padding: 12, position: "absolute", right: "0px", top: "0px" }}
        >
          <img src={CrossIcon} alt="Close" />
        </IconButton>
      </Box>
      <DialogContent dividers style={{ padding: "32px 48px" }}>
        <div
          className="treatmentDescription"
          dangerouslySetInnerHTML={{ __html: props.description as string }}
          style={{ padding: 0 }}
        />
      </DialogContent>
      <DialogActions className="treatmentFooter" style={{ margin: 0, padding: 24, height: "auto" }}>
        <CloseButton
          variant="outlined"
          onClick={props.onClose}
          style={{ margin: 0, padding: "8px 48px" }}
        >
          {props.btnText || "Ok"}
        </CloseButton>
      </DialogActions>
    </MUIDialog>
  );
}

export default React.memo(TreatmentDescription);
