import { Box, ButtonBase, Link, Typography, withStyles } from "@material-ui/core";
import React, { useCallback } from "react";
import { Colors } from "../../../constants/colors";
import { ServiceRatePrice } from "../../../models";
import { getValue } from "../../../utils/object";

interface StyledButtonProps {
  selected?: boolean;
}

const StyledButton = withStyles({
  root: ({ selected }: StyledButtonProps) => ({
    backgroundColor: selected ? "#EBF8FA" : undefined,
    width: "100%",
    padding: "24px",
    justifyContent: "flex-start",
    textAlign: "left",
    "&:hover": {
      backgroundColor: selected ? "#EBF8FA" : "#ebf8fa4d",
    },
  }),
})(ButtonBase);

interface Props {
  treatment: ServiceRatePrice;
  selected: boolean;
  onSelect: (treatment: ServiceRatePrice) => unknown;
  onLearnMoreClick: (treatment: ServiceRatePrice) => unknown;
  hasFixedDuration?: boolean;
  selectedDuration?: number;
}

const style = {
  fontFamily: "Open Sans",
};

const DropdownItem = ({
  treatment,
  selected,
  onLearnMoreClick,
  onSelect,
  hasFixedDuration = false,
  selectedDuration,
}: Props) => {
  const {
    label: title,
    rate: price,
    rates,
    duration,
    selectedCountry,
    shortDescription: description,
  } = treatment;

  const getPrice = useCallback(() => {
    if (!!price) {
      return price;
    }

    if (rates && rates.length > 0) {
      // find selected duration or lowest duration price

      if (selectedDuration) {
        return rates.find((rate) => rate.duration === selectedDuration)?.rate;
      }

      const lowestDuration = rates.reduce((prev, curr) => {
        if (prev.duration < curr.duration) {
          return prev;
        }
        return curr;
      }, rates[0]);
      return lowestDuration.rate;
    }

    return undefined;
  }, [price, rates, selectedDuration]);

  const handleLearnMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onLearnMoreClick(treatment);
  };

  return (
    <React.Fragment>
      <StyledButton onClick={() => onSelect(treatment)} selected={selected}>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" flexDirection="row" flex={1} justifyContent="space-between">
            <Typography style={{ ...style, fontSize: "16px", fontWeight: 700 }}>{title}</Typography>
            {!!getPrice() && (
              <Typography style={{ ...style, fontSize: "16px", fontWeight: 700 }}>
                {`${getValue(selectedCountry, "currencySymbol", "$")}${getPrice()}`}
              </Typography>
            )}
          </Box>

          {!!duration && (
            <Typography style={{ ...style, fontSize: "14px", color: Colors.BlueyGrey }}>
              {`${!hasFixedDuration ? "Up to " : ""} ${duration}min`}
            </Typography>
          )}

          <Typography
            style={{
              ...style,
              fontSize: "14px",
              color: Colors.Dusk,
              paddingRight: "50px",
              margin: "4px 0 8px 0",
            }}
          >
            {description}
          </Typography>

          <Link
            style={{
              fontSize: "14px",
              color: Colors.TurquoiseBlue,
              width: "fit-content",
              fontWeight: 600,
            }}
            onClick={handleLearnMoreClick}
          >
            Learn more
          </Link>
        </Box>
      </StyledButton>
      <Box
        style={{
          height: "1px",
          backgroundColor: Colors.LightPeriwinkle,
          margin: "0 16px",
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(DropdownItem);
