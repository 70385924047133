import axios from "axios";
import { deleteApi, post } from "../../api/client";

interface ToggleServiceData {
  serviceId: number;
  value: boolean;
}

export const addCustomService = (serviceName: string, accessToken: string) => {
  return axios.put(`therapists/me/services?accessToken=${accessToken}`, {
    custom: true,
    service: serviceName,
  });
};

export const toggleService = (accessToken: string, { serviceId, value }: ToggleServiceData) => {
  return axios.put(`therapists/me/services?accessToken=${accessToken}`, {
    serviceId,
    value,
  });
};

export const getProTreatments = (accessToken: string) => {
  return axios.get("api/v2/therapists/me/treatment", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const toggleTreatment = (accessToken: string, treatmentId: number, adding: boolean) => {
  const URL = "api/v2/therapists/me/treatment";
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  return adding
    ? axios.post(URL, { treatmentId }, config)
    : axios.delete(`${URL}/${treatmentId}`, config);
};

export const toggleTreatmentService = (serviceId: number, adding: boolean) => {
  const URL = "api/v2/therapists/me/toggle-services";
  return adding ? post(URL, { serviceId }) : deleteApi(`${URL}/${serviceId}`);
};
