import { useQuery } from "@tanstack/react-query";
import { get } from "../../api/client";
import queryClient from "../../config/queryClient";
import { QUERY_KEYS } from "../../constants/queryKey";

const useUpcomingBookings = ({ query = {} }) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.UPCOMING_BOOKINGS, query],
    ({ signal }) =>
      get("api/v2/bookings/upcoming", { search: JSON.stringify(query) }, true, { signal }),
    {
      select: (res) => res.data,
    }
  );

  const invalidateUpcomingBookings = () =>
    queryClient.invalidateQueries([QUERY_KEYS.UPCOMING_BOOKINGS]);

  return {
    isLoading,
    upcomingBookings: data,
    invalidateUpcomingBookings,
  };
};

const usePastBookings = ({ currentPage = 1, perPage = 10, search = {} }) => {
  const { data, isLoading } = useQuery(
    [QUERY_KEYS.PAST_BOOKINGS, { currentPage, perPage, search }],
    () => get("api/v2/bookings/past", { currentPage, perPage, search: JSON.stringify(search) }),
    {
      select: (res) => res.data,
    }
  );

  const invalidatePastBookings = () => queryClient.invalidateQueries([QUERY_KEYS.PAST_BOOKINGS]);

  return {
    ...data,
    isLoading,
    invalidatePastBookings,
  };
};

const useGetBookingDetail = (bookingId: string) =>
  useQuery([QUERY_KEYS.BOOKING_DETAIL, bookingId], () => get(`bookings/${bookingId}`), {
    refetchOnWindowFocus: "always",
  });

export { usePastBookings, useUpcomingBookings, useGetBookingDetail };
