import { ButtonBase } from "@mui/material";
import BackIcon from "../images/booking-details-back.svg";

export default function BackButton({ onClick }: any) {
  return (
    <ButtonBase
      style={{
        width: "40px",
        height: "40px",
      }}
      onClick={onClick}
    >
      <img alt="back" src={BackIcon} />
    </ButtonBase>
  );
}
