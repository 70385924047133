import ContentModal from "../../../../components/Modals/ContentModal/contentModal";
import TextButton from "../../../../components/TextButton/TextButton";
import Button, { ButtonType } from "../../../../components/Button";
import { FontSize, JustifyContent } from "../../../../components/v2/Styled/enum";
import TreatmentNoteListCard from "../../ClientDetails/TreatmentNotes/TreatmentNoteListCard";
import { useUserStore } from "../../../../stores/user";
import CloseIcon from "../../../../images/cross-large.svg";

interface Props {
  note: any;
  visible: boolean;
  timezone: string;
  onClose: () => unknown;
  onDelete: (note: any) => unknown;
  onEdit: (note: any) => unknown;
}

const TreatmentNoteDetailModal = ({
  visible,
  note,
  timezone,
  onClose,
  onDelete,
  onEdit,
}: Props) => {
  const { user } = useUserStore();

  if (!note) return <></>;

  const actions: any = [
    <TextButton text="Delete" textStyle={{ fontSize: FontSize.F16 }} type={"danger"} onClick={() => onDelete(note)} />,
    <Button title="Edit" type={ButtonType.primary} onClick={() => onEdit(note)} width={"auto"} />
  ];

  return (
    <ContentModal
      visible={visible}
      divider={false}
      onClose={onClose}
      maxWidth={"sm"}
      fullWidth
      fixButtonsWithDivider
      contentStyle={{ padding: "8px 20px" }}
      actions={actions}
      actionStyle={{ justifyContent: JustifyContent.spaceBetween }}
      title="Note"
      showCrossIcon
      crossIcon={CloseIcon}
      closeIconStyle={{ height: "14px", width: "14px" }}
    >
      <TreatmentNoteListCard pro={user} note={note} allowActions={false} timezone={timezone} collapsable={false} />
    </ContentModal>
  );
};

export default TreatmentNoteDetailModal;
