import React from "react";
import { CircularProgress } from "@material-ui/core";
import { Box } from "../v2/Styled";
import Button, { ButtonSize, ButtonType } from "../Button";
import { Colors } from "../../constants/colors";
import { AlignItems, Display, FlexDirection, Spacing } from "../v2/Styled/enum";
import ContentModal from "../Modals/ContentModal/contentModal";

interface Props {
  isMobile: boolean;
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isPaying: boolean;
}

const PayWithInvoiceConfirmationModal: React.FC<Props> = ({
  isMobile,
  visible,
  onClose,
  isPaying,
  onConfirm,
}) => {
  return (
    <div>
      <ContentModal
        visible={visible}
        onClose={onClose}
        actions={[
          <Button type={ButtonType.outlined} title="Cancel" onClick={onClose} />,
          <Button
            title="Confirm"
            type={ButtonType.secondary}
            loading={isPaying}
            onClick={onConfirm}
          />,
        ]}
        title="Pay with invoice"
        divider={false}
        maxWidth="xs"
        PaperProps={{
          style: {
            overflowY: "scroll",
            minWidth: !isMobile ? "438px" : undefined,
          },
        }}
        // titleStyle={{ height: "10px" }}
        fullWidth={isMobile}
        childrenStyle={{ marginTop: 0 }}
        actionStyle={{display:"flex",gap:"8px"}}
      >
        <Box padding={"42px 48px"} bgcolor="#ffffff" borderRadius="11px">
          <Box
            width={isMobile ? "100%" : "352px"}
            display={Display.Flex}
            flexDirection={FlexDirection.Column}
            alignItems={AlignItems.start}
          >
            <Box
              fontFamily="Open Sans"
              fontWeight={400}
              fontSize="16px"
              color={Colors.Grey}
              textAlign="center"
              width="100%"
              marginY={Spacing.S5}
            >
              Please confirm you want to send a single invoice for the selected bookings to your
              email.{" "}
            </Box>
          </Box>
        </Box>
      </ContentModal>
    </div>
  );
};

export default PayWithInvoiceConfirmationModal;
