import { Colors } from "../../constants/colors";

type Props = {
  color?: string;
};

const CircleExclamation = (props: Props) => {
  const color = props.color || Colors.Danger;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 2.5C4.68629 2.5 2 5.18629 2 8.5C2 11.8137 4.68629 14.5 8 14.5C11.3137 14.5 14 11.8137 14 8.5C14 5.18629 11.3137 2.5 8 2.5ZM8 1.5C4.13401 1.5 1 4.63401 1 8.5C1 12.366 4.13401 15.5 8 15.5C11.866 15.5 15 12.366 15 8.5C15 4.63401 11.866 1.5 8 1.5Z"
        fill={color}
      />
      <path
        d="M7 11.5C7 10.9477 7.44772 10.5 8 10.5C8.55228 10.5 9 10.9477 9 11.5C9 12.0523 8.55228 12.5 8 12.5C7.44772 12.5 7 12.0523 7 11.5Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25 9.5L7.25 4.5L8.75 4.5L8.75 9.5H7.25Z"
        fill={color}
      />
    </svg>
  );
};

export default CircleExclamation;
