import React from "react";
import { Box } from "@material-ui/core";
import RadioButton from "../../RadioButton";
import { useMobile } from "../../../hooks/mobile";
import { Colors } from "../../../constants/colors";
import { JobStatus } from "../../../constants/booking";
import BookingStatusPill from "../V2/BookingStatusPill";
import RecommendedProviders from "./RecommendedProviders";
import { DEFAULT_PROVIDER_OPTION, PROVIDER_OPTION_TYPE, PROVIDER_OPTIONS_VALUE } from "./enum";

interface Props {
  option: PROVIDER_OPTION_TYPE;
  defaultSelect: boolean;
  selected: boolean;
  onSelect: (option: PROVIDER_OPTION_TYPE) => unknown;
  selectedProIds: Array<number | string> | null;
  onSelectPreferredPro: (proId: number | string) => unknown;
  proSearchOptions?: Record<string, any>;
  isNewUser?: boolean;
}

const ProviderOptionItem = ({
  option,
  selected,
  isNewUser,
  onSelect,
  defaultSelect,
  selectedProIds,
  proSearchOptions,
  onSelectPreferredPro,
}: Props): JSX.Element => {
  const isMobile = useMobile();

  const handleSelect = () => {
    onSelect(option);
  };

  const shouldShowAvailablePros =
    option.value !== PROVIDER_OPTIONS_VALUE.NO_PROVIDERS &&
    [
      PROVIDER_OPTIONS_VALUE.RECENT_PROVIDERS,
      PROVIDER_OPTIONS_VALUE.RECOMMENDED_PROVIDERS,
    ].includes(option.value);

  if (isNewUser && option.value === PROVIDER_OPTIONS_VALUE.RECENT_PROVIDERS) {
    return <React.Fragment />;
  }

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} gridGap={"8px"} alignItems={"flex-start"}>
        <Box>
          <RadioButton selected={selected || defaultSelect} onSelected={handleSelect} />
        </Box>
        <Box display={"flex"} flexDirection={"column"} gridGap={"16px"} onClick={handleSelect}>
          <Box
            display={"flex"}
            gridGap={"12px"}
            flexDirection={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
          >
            <Box
              fontFamily={"Museo"}
              color={Colors.Dusk}
              fontSize={"18px"}
              fontWeight={700}
              lineHeight={"26.4px"}
            >
              {option.title}
            </Box>
            {!!option.label ? (
              <BookingStatusPill
                status={JobStatus.accepted}
                textOverride={option.label}
                textOverrideStyle={{
                  fontFamily: "SF UI Text",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "15.6px",
                  color: Colors.Dusk,
                }}
                overrideStyle={{
                  borderRadius: "50px",
                }}
              />
            ) : (
              <React.Fragment />
            )}
          </Box>
          <Box
            fontFamily={"Museo"}
            fontWeight={500}
            fontSize={"14px"}
            lineHeight={"21px"}
            color={Colors.Dusk}
          >
            {option.subtitle}
          </Box>
        </Box>
      </Box>

      {shouldShowAvailablePros && selected ? (
        <Box>
          <Box display={"flex"} gridGap={"12px"} flexDirection={"row"} alignItems={"center"}>
            <Box width={"100%"}>
              <RecommendedProviders
                selectedProviderOption={option.value}
                proSearchOptions={proSearchOptions}
                selectedProIds={selectedProIds}
                onSelectPreferredPro={onSelectPreferredPro}
                onFallbackClicked={() => onSelect(DEFAULT_PROVIDER_OPTION)}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <React.Fragment />
      )}

      <Box
        height="1px"
        bgcolor={Colors.LightPeriwinkle}
        borderRadius="1px"
        width="100%"
        mt={"24px"}
      />
    </Box>
  );
};
export default ProviderOptionItem;
