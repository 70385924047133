import React, { useState } from "react";
import Wrapper from "../../../components/Wrapper";
import ProDashboardHeader from "../../../components/ProDashboard/ProDashboardHeader";
import CommunicationPreferences from "../../../components/CommunicationPreferences/CommunicationPreferences";
import { Box } from "@mui/material";
import ContentSectionControl from "../../../components/ContentSectionControl";
import { useMobile } from "../../../hooks/mobile";
import { BookingManagement } from "./BookingManagement";


const sections = ["Communication", "Bookings"];
const Settings = () => {
  const [selectedSection, setSelectionSection] = useState(0);
  const isMobile = useMobile();

  return (
    <Wrapper>
      <ProDashboardHeader section="Settings" />
      <Box
        mt="40px"
        ml={isMobile ? "16px" : "80px"}
        mr={isMobile ? "16px" : "80px"}
      >

        <ContentSectionControl
          sections={sections}
          selectedIndex={selectedSection}
          onSelectedIndex={(index) => setSelectionSection(index)}
        />

        {
          selectedSection ===0?
            <CommunicationPreferences proDash />:
            <BookingManagement/>
        }
      </Box>
    </Wrapper>
  );
};
export default Settings;
