import React from "react";
import {Colors} from "../../../constants/colors";

export const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  titleWrapper: {
    padding: "8px",
    display: "flex",
    alignItems: "center",
  },
  closeWrapper: {
    padding: "12px",
    cursor: "pointer",
  },
  closeBtn: {
    width: "24px",
    height: "24px",
  },
  title: {
    flex: 1,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "center",
    color: Colors.NightBlue,
    marginRight: "48px",
  },
  contentWrapper: {
    padding: "32px 48px",
    borderTop: `1px solid ${Colors.LightPeriwinkle}`,
    borderBottom: `1px solid ${Colors.LightPeriwinkle}`,
  },
  footerWrapper: {
    padding: "24px",
  },
};
