import React, { useState, useEffect } from "react";
import Dialog from "../../Dialog";
import "../../../styles/scrollbar-hidden.css";
import { useMobile } from "../../../hooks/mobile";
import CloseIcon from "../../../images/cross.svg";
import { Colors } from "../../../constants/colors";
import { useUserStore } from "../../../stores/user";
import { useAlertStore } from "../../../stores/alert";
import RecipientModalForm from "./RecipientModalForm";
import RecipientModalList from "./RecipientModalList";
import Button, { ButtonSize, ButtonType } from "../../Button";
import { RecipientDataInterface } from "../../../pages/BlysTeam/Settings";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useCreateRecipient, useRecipients } from "../../../hooks/settings/settings.hooks";
import isValidEmail from "../../../helpers/validation";
import { getValue } from "../../../utils/object";
import { Roles } from "../../../constants/blysTeam";

enum ModalMode {
  list,
  form,
}

interface Props {
  open: boolean;
  onClose: () => unknown;
  onConfirm: (id: number, role: string | null) => unknown;
}

const SelectRecipientModal = ({ open, onClose, onConfirm }: Props): JSX.Element => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [userId, setUserId] = useState<number | null>(null);

  const [selectedRecipientRole, setSelectedRecipientRole] = useState<string | null>(null);
  const [selectedRecipient, setSelectedRecipient] = useState<number | null>();

  const { user, fetchMe } = useUserStore();

  const isMobile = useMobile();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { isLoading: isRecipientLoading, data: recipientData } = useRecipients({
    userId: user?.id
  });

  const {
    isLoading: isCreating,
    mutate: createRecipient,
    data: newRecipient,
  } = useCreateRecipient({
    userId: user?.id,
    onSuccess: () => setSuccessMessage("The user has been added"),
    onError: () => setErrorMessage("Something went wrong"),
  });

  const [confirmText, setConfirmText] = useState<string>("Add");
  const [mode, setMode] = useState<ModalMode>(ModalMode.list);

  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    if (newRecipient) {
      const userRole = getValue(newRecipient, "role.label") || Roles.RECIPIENT;
      setSelectedRecipientRole(userRole);
      setSelectedRecipient(newRecipient.data.id);
    }
  }, [newRecipient]);

  useEffect(() => {
    setConfirmText(mode === ModalMode.list ? "Add" : "Save");
  }, [mode]);

  const handleModeChange = (mode: number) => {
    setMode(mode === ModalMode.list ? ModalMode.list : ModalMode.form);
  };

  const handleCancelClicked = () => {
    switch (mode) {
    case ModalMode.list:
      onClose();
      return;
    case ModalMode.form:
    default:
      setMode(ModalMode.list);
      return;
    }
  };

  const handleConfirmClicked = () => {
    switch (mode) {
    case ModalMode.list:
      if (!selectedRecipient) return;
      onConfirm(selectedRecipient, selectedRecipientRole);
      return;

    case ModalMode.form:
    default:
      const data = {
        firstName,
        lastName,
        email,
        role: "recipient",
        userId: userId ? userId : user ? user.id : null,
      };
      const validData = validateForm(data);
      if (!validData) return;

      createRecipient(validData);
      if (!isCreating) {
        setMode(ModalMode.list);
      }
      return;
    }
  };

  const validateForm = (data: RecipientDataInterface) => {
    if (!data.firstName) {
      setErrorMessage("Please enter first name");
      return false;
    }

    if (!data.lastName) {
      setErrorMessage("Please enter last name");
      return false;
    }

    if (!data.email) {
      setErrorMessage("Please enter email");
      return false;
    }

    if (!isValidEmail(data.email)) {
      setErrorMessage("Please enter a valid email");
      return false;
    }

    if(!data.userId) {
      setErrorMessage("Please select manager");;
      return false;
    }
    return data;
  };

  const handleRecipientSelect = (recipientId: number | null, role: string) => {
    if (!recipientId) return;
    setSelectedRecipient(recipientId);
    setSelectedRecipientRole(role);
  };

  return (
    <Dialog open={open} fullScreen={isMobile}>
      <Box width={isMobile ? "100%" : "448px"}>
        <Box paddingX={"48px"} mb={"24px"} pt={"40px"} position={"relative"}>
          <Box
            position={"absolute"}
            padding={"12px"}
            style={{ top: 0, right: 0, cursor: "pointer" }}
            onClick={onClose}
          >
            <img src={CloseIcon} />
          </Box>
          <Typography style={{ fontSize: "30px", fontWeight: 600, color: Colors.NightBlue }}>
            {mode === 0 ? "Add a recipient" : "Add new recipient"}
          </Typography>
        </Box>
        <Box paddingX={"48px"} mb={"32px"}>
          {mode === ModalMode.list ? (
            <Box maxHeight={"432px"} overflow={"auto"} className="scrollbar-hidden">
              {isRecipientLoading ? (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <RecipientModalList
                    recipients={recipientData?.data || []}
                    selectedRecipient={selectedRecipient}
                    handleSelect={handleRecipientSelect}
                  />
                  <Box
                    fontFamily={"Museo"}
                    fontWeight={400}
                    fontSize={"16px"}
                    lineHeight={"150%"}
                    color={Colors.TurquoiseBlue}
                    onClick={() => handleModeChange(1)}
                    style={{ cursor: "pointer" }}
                  >
                    Add another recipient
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <RecipientModalForm
              firstName={firstName}
              lastName={lastName}
              email={email}
              userId={userId}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmail}
              setUserId={setUserId}
            />
          )}
        </Box>

        <Box
          display={"flex"}
          padding={"24px"}
          gridGap={"16px"}
          border={`1px solid ${Colors.LightPeriwinkle}`}
        >
          <Button
            type={ButtonType.outlined}
            size={ButtonSize.large}
            title="Cancel"
            onClick={handleCancelClicked}
          />
          <Button
            type={ButtonType.indigo}
            size={ButtonSize.large}
            title={confirmText}
            onClick={handleConfirmClicked}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default SelectRecipientModal;
