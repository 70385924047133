import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import TextField from "../../TextField";
import Dropdown, { DropdownOption } from "../../Dropdown";
import { useManager } from "../../../hooks/settings/settings.hooks";

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  userId: number | null;
  setFirstName: (value: string) => unknown;
  setLastName: (value: string) => unknown;
  setEmail: (value: string) => unknown;
  setUserId: (value: number | null) => unknown;
}

const RecipientModalForm = ({
  firstName,
  lastName,
  email,
  userId,
  setFirstName,
  setLastName,
  setEmail,
  setUserId,
}: Props): JSX.Element => {
  
  const { isLoading: isManagerLoading, data: managerData } = useManager();

  const [managerOptions, setManagerOptions] = useState<DropdownOption[] | null>(null);

  useEffect(() => {
    if (!isManagerLoading && managerData && managerData.length !== 0) {
      const managerOptions = managerData.map((manager: any) => ({
        title: `${manager.firstName} <${manager.email}>`,
        value: manager.id,
      }));
      setManagerOptions(managerOptions);
    }
  }, [managerData]);

  return (
    <Box>
      <Box display={"flex"} gridGap={"24px"}>
        <TextField
          title="First"
          placeholder="Name"
          value={firstName}
          onChange={(value) => setFirstName(value)}
        />
        <TextField
          title="Last"
          placeholder="Name"
          value={lastName}
          onChange={(value) => setLastName(value)}
        />
      </Box> 
      <TextField
        title="Email"
        placeholder="someone@example.com"
        value={email}
        onChange={(value) => setEmail(value)}
      />
      {managerOptions && (
        <Dropdown
          title="Assign account manager"
          options={managerOptions}
          selectedOption={managerOptions.find((manager) => manager.value === userId)}
          onSelectedOption={(option) => setUserId(option.value)}
        />
      )}
    </Box>
  );
};

export default RecipientModalForm;
