export const LINKING_ROUTES = [
  // new booking routes
  "/new-booking",
  "/new-booking/new",
  "/new-booking/saved-recipients",
  "/new-booking/saved-locations",
  "/new-booking/datetime",
  "/new-booking/review",
  "/new-booking/location",

  // booking routes
  "/bookings",

  // therapists routes
  "/my-therapists",
  "/my-therapists/blocked",
  "/my-therapists/browse",

  // other routes
  "/addresses",
  "/recipients",
  "/payment",
  "/profile",
  "/preferences",
  "/invite-friends",
];

export const SHORT_LINK_PATH = {
  // PRO
  PRO : "/p",
  PRO_NEW_JOB: "/p/J",
  //CLIENT
  CLIENT : "/c",
};