import { Box, ButtonBase } from "@material-ui/core";
import React from "react";
import { BASE_UPLOADS_URL } from "../constants/common";
import ProfilePicturePlaceholder from "../images/profile-pic-placeholder.png";
import { useUserStore } from "../stores/user";

interface Props {
  onClick: () => unknown;
  buttonStyle?: React.CSSProperties;
}

export default function ({
  onClick,
  buttonStyle = {
    position: "absolute",
    top: "25px",
    left: "25px",
  },
}: Props) {
  const { user } = useUserStore();

  return user ? (
    <Box
      style={{
        zIndex: 999,
      }}
    >
      <ButtonBase onClick={onClick} style={buttonStyle}>
        {user.profileImage && (
          <img
            src={`${BASE_UPLOADS_URL}/${user.profileImage}`}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              objectFit: "cover",
            }}
          />
        )}
        {!user.profileImage && (
          <img src={ProfilePicturePlaceholder} style={{ width: "40px", height: "40px" }} />
        )}
      </ButtonBase>
    </Box>
  ) : (
    <Box width="40px" marginTop="60px" />
  );
}
