import { Box } from "@material-ui/core";
import { useState } from "react";

import Button, { ButtonSize, ButtonType } from "../../components/Button";
import Dialog from "../../components/Dialog";
import { RecurringBookingCancelType } from "../../constants/booking";
import { Colors } from "../../constants/colors";
import { useMobile } from "../../hooks/mobile";
import ActionOptions from "./ActionOption";

interface ConfirmCheckoutInterface {
  open: boolean;
  onClose: () => unknown;
  onConfirm: (cancelFutureRecurring: boolean) => unknown;
}

const CancelRecurringBookingModal = ({ open, onClose, onConfirm }: ConfirmCheckoutInterface) => {
  const isMobile = useMobile();
  const [selectedType, setSelectedType] = useState(RecurringBookingCancelType.only);

  const handleConfirm = () => {
    onConfirm(selectedType === RecurringBookingCancelType.all ? true : false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile}>
      <Box padding={"48px 48px 24px 48px"} bgcolor="#ffffff" borderRadius="8px" position="relative">
        <Box
          width={isMobile ? "100%" : "450px"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Box fontFamily="Museo" fontWeight={600} fontSize="22px" color={Colors.NightBlue}>
              Cancel recurring bookings
            </Box>
          </Box>
          <Box
            fontFamily="Museo"
            fontSize="16px"
            fontWeight={500}
            color={Colors.Dusk}
            mt="24px"
            textAlign={"center"}
          >
            <ActionOptions
              type={RecurringBookingCancelType.only}
              onSelected={() => setSelectedType(RecurringBookingCancelType.only)}
              selectedType={selectedType}
              label="This booking only"
            />
            <ActionOptions
              type={RecurringBookingCancelType.all}
              onSelected={() => setSelectedType(RecurringBookingCancelType.all)}
              selectedType={selectedType}
              label="This booking and all future recurring bookings"
            />
          </Box>

          <Box style={styles.actionWrapper} flexDirection="row">
            <Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title="Confirm"
              onClick={() => handleConfirm()}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CancelRecurringBookingModal;

const styles = {
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "32px",
  },
};
