import { Box } from '@material-ui/core';
import { getBaseStyle } from '../config';

export default function CalendarCell({ children, style = {}, ...props }: any) {
  const baseStyle = getBaseStyle();

  return (
    <Box style={{...baseStyle, ...style}} {...props }>
      <Box style={{
        color: "#41506F",
        fontFamily: 'Open Sans',
        fontWeight: 400,
        fontSize: "14px",
      }}>
        {children}
      </Box>
    </Box >
  )
}