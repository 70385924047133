import React, { ReactElement, useEffect } from "react";
import { Box } from "@material-ui/core";

import styles from "./styles";
import { useMobile } from "../../../hooks/mobile";
import ErrorBoundary from "../../../components/ErrorBoundary";
import HeaderDecoImage from "../../../images/header-deco.svg";
import FooterDecoImage from "../../../images/footer-deco.svg";
import LogoHeader from "../../../components/Headers/LogoHeader/LogoHeader";
import HeaderBlock from "../../../components/Headers/HeaderBlock/HeaderBlock";
import ReviewFooter from "../../../components/ReviewFooter/ReviewFooter";
import { MARKETING_SITE_URL } from "../../../constants/common";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bookingInitializer } from "../../../stores/V2/booking/booking.initializers";
import { RootState } from "../../../stores/V2";
import Snackbar, { SnackbarType } from "../../../components/Snackbar";
import { useAlertStore } from "../../../stores/alert";

interface Params {
  id?: string;
}

interface Props {
  children: any;
  title: string;
  label?: string;
  onClose?: any;
  progress: number;
  fullWidth?: boolean;
  buttons?: ReactElement[];
}

const ReviewWrapper = ({
  label,
  title,
  buttons,
  progress,
  children,
  fullWidth,
  onClose = () => {},
}: Props) => {
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const { booking: state } = useSelector((state: RootState) => state);
  const { errorMessage, successMessage, setErrorMessage, setSuccessMessage } = useAlertStore();

  useEffect(() => {
    if (!id || parseInt(id) === state.id) return;
    bookingInitializer(id, dispatch);
  }, []);

  const isMobile = useMobile();
  const footerHeight = isMobile ? 140.0 : 78.0;

  const onLogoClicked = () => window.location.assign(MARKETING_SITE_URL);

  return (
    <Box height="100vh" position="relative">
      <Box display="flex" flexDirection="column">
        <Box display="flex" margin={`52px auto ${footerHeight + 20}px auto`}>
          <img src={HeaderDecoImage} style={{ ...styles.topDeco }} alt="header deco" />
          {!isMobile && (
            <img
              src={FooterDecoImage}
              style={{
                ...styles.bottomDeco,
                marginBottom: footerHeight,
              }}
              alt="footer deco"
            />
          )}

          <Box
            display="flex"
            flexDirection="column"
            width={fullWidth ? "100%" : isMobile ? "85vw" : "800px"}
            ml={isMobile ? "25px" : undefined}
            mr={isMobile ? "25px" : undefined}
          >
            <LogoHeader onClose={onClose} onLogoClick={onLogoClicked} />
            <HeaderBlock title={title} label={label} />

            <ErrorBoundary domain="CLIENT_BOOKING">{children}</ErrorBoundary>
          </Box>
        </Box>
        <ReviewFooter height={footerHeight} progress={progress} buttons={buttons} />
      </Box>
      <Snackbar
        type={SnackbarType.error}
        message={errorMessage}
        open={errorMessage !== null}
        onClose={() => setErrorMessage(null)}
      />
      <Snackbar
        type={SnackbarType.success}
        open={successMessage !== null}
        message={successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </Box>
  );
};

export default ReviewWrapper;
