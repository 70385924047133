import { Booking } from "../../models";
import React, { useState } from "react";
import { Box } from "@material-ui/core";
import Button, { ButtonType } from "../Button";
import { Colors } from "../../constants/colors";
import { useUserStore } from "../../stores/user";
import {
  confirmUpdateBooking,
  declineUpdateBooking,
  getBookingUpdateDetails,
} from "../../services/bookings/bookings.service";
import { useAccessToken } from "../../hooks/common";
import { useAlertStore } from "../../stores/alert";
import { getValue } from "../../utils/object";
import ProUpdateBookingRequest from "../ProUpdateBookingRequest";
import styles from "./UpdateRequestAction.styles";
import Divider from "../Divider";
import { useServicesStore } from "../../stores/booking";

interface Props {
  booking: Booking;
  showTitle?: boolean;
  bookingMutate: any;
  jobId?: number | null;
}

const UpdateRequestActions = ({ booking, showTitle = true, bookingMutate, jobId }: Props) => {
  const { user } = useUserStore();
  const accessToken = useAccessToken();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const { rates } = useServicesStore();

  const [showUpdateRequestModel, setShowUpdateRequestModel] = useState(false);
  const [declining, setDeclining] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const width = showTitle ? "auto" : "100%";

  const checkIfRequestedByPro = () => {
    let requestByPro = false;
    let requestDetails: any;

    const { bookingUpdates } = booking;
    if (bookingUpdates && bookingUpdates.length > 0 && user) {
      // const { id } = user;
      const id = getValue(booking, "userId");
      requestDetails = getBookingUpdateDetails(id, bookingUpdates, jobId);
      if (requestDetails?.isRequestedByPro && requestDetails?.isPending) {
        requestByPro = true;
      }
    }
    return { requestByPro, requestDetails };
  };

  const { requestByPro, requestDetails } = checkIfRequestedByPro();

  const handleViewDetails = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowUpdateRequestModel(true);
  };

  const closeUpdateRequestModal = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setShowUpdateRequestModel(false);
  };

  const handleActionEvent = (isConfirmed: boolean) => {
    const proFirstName = getValue(requestDetails?.pro, "firstName");
    setShowUpdateRequestModel(false);
    if (isConfirmed) {
      setSuccessMessage(
        `Update request from ${proFirstName} has been confirmed. Booking has been updated.`,
      );
    } else {
      setSuccessMessage(`Booking update request from ${proFirstName} has been declined.`);
    }
  };

  const handleConfirm = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setConfirming(true);
    const jobIdFromRequestDetails = getValue(requestDetails, "jobId");
    const confirmedJobId = jobId ? jobId : jobIdFromRequestDetails;
    confirmUpdateBooking(booking.id, accessToken, confirmedJobId)
      .then(() => {
        handleActionEvent(true);
      })
      .catch(({ response }) => {
        const errorMessage =
          getValue(response, "data.errorMessage") || getValue(response, "data.error.message");
        if (errorMessage) setErrorMessage(errorMessage);
      })
      .finally(() => {
        bookingMutate();
        setConfirming(false);
        setShowUpdateRequestModel(false);
      });
  };

  const handleDecline = (e: MouseEvent) => {
    const jobIdFromRequestDetails = getValue(requestDetails, "jobId");
    const declinedJobId = jobId ? jobId : jobIdFromRequestDetails;
    e.stopPropagation();
    e.preventDefault();
    setDeclining(true);
    declineUpdateBooking(booking.id, accessToken, declinedJobId)
      .then(() => {
        handleActionEvent(false);
      })
      .catch(({ response }) => {
        const errorMessage =
          getValue(response, "data.errorMessage") || getValue(response, "data.error.message");
        if (errorMessage) setErrorMessage(errorMessage);
      })
      .finally(() => {
        bookingMutate();
        setDeclining(false);
        setShowUpdateRequestModel(false);
      });
  };

  if (!requestByPro) return <></>;

  return (
    <>
      <Divider mt="16px" />
      <Box display="flex" flexDirection="column" paddingTop={`${showTitle ? "16px" : "0px"}`}>
        {showTitle && (
          <Box
            fontWeight={400}
            fontFamily="Museo"
            fontSize={"16px"}
            lineHeight="24px"
            paddingRight={"20px"}
            color={Colors.Dusk}
          >
            {requestDetails?.pro?.firstName} wants to update your booking details.
          </Box>
        )}
        <Box sx={{ alignSelf: "center", width }}>
          <Button
            title="View updated details"
            onClick={handleViewDetails}
            type={ButtonType.outlined}
            style={{ ...styles.viewUpdateDetailsBtn, width }}
          />
        </Box>
        <ProUpdateBookingRequest
          timezone={booking.timezone}
          serviceId={getValue(booking, "serviceId") || getValue(booking, "selectedService.id")}
          updateRequest={requestDetails}
          open={showUpdateRequestModel}
          onConfirm={handleConfirm}
          onDecline={handleDecline}
          confirmingLoader={confirming}
          decliningLoader={declining}
          onClose={closeUpdateRequestModal}
          rates={rates}
          session={getValue(booking, "sessionType")}
          bookingId={getValue(booking, "id")}
          isHairAndMakeup={getValue(booking, "isHairAndMakeup")}
        />
      </Box>
    </>
  );
};

export default UpdateRequestActions;
