import React from "react";
import { Box } from "@material-ui/core";
import { BookingUpdateStatus, JobStatus, RebookingStatus } from "../../../constants/booking";
import { Colors } from "../../../constants/colors";

const backgroundColor = (
  status: JobStatus | BookingUpdateStatus,
  rebookingStatus: string,
  bookingStatus?: string,
) => {
  switch (status) {
  case JobStatus.cancelled:
    return Colors.Cancelled;
  case JobStatus.accepted:
    if (bookingStatus) return Colors.SecondaryPink;
    return Colors.LightCyan;
  case JobStatus.onway:
  case JobStatus.finished:
  case JobStatus.reviewLeft:
    return Colors.VeryLightGreen;
  case JobStatus.pendingPayment:
    return Colors.Pale;
  case JobStatus.pending:
  case JobStatus.rebooking:
    if (rebookingStatus) return Colors.SecondaryPink;
    return Colors.Pale;
  case BookingUpdateStatus.requestByClient:
    return Colors.LightPale;
  default:
    return Colors.Pale;
  }
};

const text = (status: string, rebookingStatus: string, bookingStatus?: string) => {
  switch (status) {
  case JobStatus.cancelled:
    return "Cancelled";
  case JobStatus.accepted:
    if(bookingStatus)
      return "Payment declined";
    return "Confirmed";
  case JobStatus.onway:
    return "On the way";
  case JobStatus.finished:
  case JobStatus.reviewLeft:
    return "Completed";
  case JobStatus.pendingPayment:
    return "Pending payment";
  case JobStatus.pending: // Pending
  case JobStatus.rebooking: {
    if (rebookingStatus === RebookingStatus.declined)
      return "Declined";
    if (rebookingStatus === RebookingStatus.notResponded)
      return "Not responding";
    return "Pending provider confirmation";
  }
  case BookingUpdateStatus.requestByClient:
    return BookingUpdateStatus.requestByClient;
  default:
    return "Pending provider confirmation";
  }
};

interface Props {
  status: JobStatus | BookingUpdateStatus;
  textOverride?: string;
  rebookingStatus?: string;
  bookingStatus?: string;
  textOverrideStyle?: React.CSSProperties;
  overrideStyle?: React.CSSProperties;
}

export default function BookingStatusPill({
  status,
  textOverride,
  rebookingStatus = "",
  bookingStatus = "",
  textOverrideStyle = {},
  overrideStyle = {},
}: Props): JSX.Element {
  return (
    <Box
      paddingY={textOverride ? "8px" : "4px"}
      paddingX="12px"
      borderRadius="12.5px"
      bgcolor={backgroundColor(status, rebookingStatus, bookingStatus)}
      style={overrideStyle}
    >
      <Box
        fontFamily="Open Sans"
        fontSize="12px"
        fontWeight={700}
        color={Colors.NightBlue}
        letterSpacing="0.5px"
        style={textOverrideStyle}
      >
        {textOverride || text(status, rebookingStatus, bookingStatus)}
      </Box>
    </Box>
  );
}
