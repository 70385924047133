import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TextButton from "../../../components/TextButton/TextButton";
import ActionModal from "../../../components/ActionModal";
import axios from "axios";
import { useAlertStore } from "../../../stores/alert";
import { parseApiError } from "../../../helpers/error";
import { useDeleteRecipient } from "../../../hooks/recipient.hook";

interface IProps {
  recipientId: number;
}

const RemoveRecipient: React.FC<IProps> = ({ recipientId }) => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const { setErrorMessage, setSuccessMessage } = useAlertStore();

  const { deleteRecipient } = useDeleteRecipient();

  const handleOpen = () => setOpenModal(true);

  const onClose = () => {
    setOpenModal(false);
  };

  const handleConfirm = async () => {
    try {
      await deleteRecipient(recipientId);
      setSuccessMessage("Recipient deleted");
      history.push("/recipients");
    } catch (error: any) {
      setErrorMessage(parseApiError(error));
    }
  };
  return (
    <>
      <TextButton
        text="Delete recipient"
        type={"danger"}
        onClick={() => {
          handleOpen();
        }}
      />
      <ActionModal
        open={openModal}
        title={"Delete recipient"}
        description={"Please confirm that you want to delete this recipient."}
        onCancel={() => {
          onClose();
        }}
        onConfirm={() => {
          handleConfirm();
        }}
        confirmText="Confirm"
        cancelText="Back"
      />
    </>
  );
};

export default RemoveRecipient;
