import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";

import { getProvidersTitle } from "../../../../constants/booking";
import { Colors } from "../../../../constants/colors";
import { getNotesFromTreatmentDetails } from "../../../../helpers/specialInstruction";
import NoteIcon from "../../../../images/review-note-icon.svg";
import { getProfessionByServiceId } from "../../../../stores/booking";
import { getValue } from "../../../../utils/object";
import { toJSON } from "../../../../utils/string";
import TextLink from "../../../TextLink";

interface Props {
  booking: any;
  serviceId?: number;
  numberOfPros: number | null;
  allowChange?: boolean;
  onChange: () => unknown;
}

function BookingNote({
  booking,
  serviceId,
  onChange,
  numberOfPros = null,
  allowChange = false,
}: Props) {
  const [profession, setProfession] = useState("therapist");

  useEffect(() => {
    if (!serviceId) return;

    const serviceProfession = getProfessionByServiceId(serviceId);
    if (serviceProfession) {
      setProfession(serviceProfession);
    }
  }, [serviceId]);

  const Note = (): JSX.Element => {
    const isHairAndMakeup = booking.isHairAndMakeup;
    const bookingInstructions = toJSON(getValue(booking, "instructions"));
    const preference = getValue(bookingInstructions, "preference", "");
    const contraindications = getValue(bookingInstructions, "contraindications", "");
    const treatmentDetails =
      (booking.bookingdetails || booking.bookingDetails)[0]?.treatmentDetails || [];
    let specialInstructions = getValue(bookingInstructions, "note", "");
    if (isHairAndMakeup) {
      const numOfClient = treatmentDetails.length;
      if (numOfClient > 1) {
        specialInstructions = getNotesFromTreatmentDetails(treatmentDetails);
      }
    }

    return (
      <>
        <Box marginLeft={"42px"}>
          <Box whiteSpace="pre-wrap">{specialInstructions}</Box>
          {preference ? (
            <Box>
              <Box style={styles.notesTitle}>Preferences</Box>
              {preference}
            </Box>
          ) : (
            <></>
          )}
          {contraindications ? (
            <Box>
              <Box style={styles.notesTitle}>Contraindications</Box>
              {contraindications}
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" flex={1}>
          <Box display="flex" flexDirection="row" flex={1} gridColumnGap={"16px"}>
            <Box
              width="24px"
              height="24px"
              justifyContent="center"
              alignItems="center"
              display="flex"
            >
              <img src={NoteIcon} alt="icon" style={{ maxHeight: "100%", maxWidth: "100%" }} />
            </Box>
            <Box
              fontFamily="Museo"
              fontSize="16px"
              color={Colors.Dusk}
              lineHeight={"18px"}
              flex={1}
              textAlign="left"
              marginBottom={"8px"}
            >
              Note for {getProvidersTitle(numberOfPros, profession)}
            </Box>

            {allowChange && (
              <Box justifyContent="center" alignSelf={"start"}>
                <TextLink title="Change" onClick={onChange} />
              </Box>
            )}
          </Box>
          <Box
            flex={1}
            display={"flex"}
            fontFamily="Open Sans"
            fontSize="14px"
            color={Colors.Grey}
            mt={0.2}
            whiteSpace="pre-wrap"
            lineHeight={"22px"}
            width="100%"
          >
            <Note />
          </Box>
        </Box>
      </Box>
    </>
  );
}
const styles = {
  notesTitle: {
    color: Colors.Dusk,
    marginTop: "10px",
    marginBottom: "1px",
    fontWeight: 600,
    lineHeight: "25px",
  },
};

export default BookingNote;
