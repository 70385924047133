import { Box } from "@material-ui/core";
import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useMobile } from "../../../../../hooks/mobile";

interface SignaturePanelProps {
  sigCanvasRef: React.RefObject<any>;
}

const SignaturePanel: React.FC<SignaturePanelProps> = ({ sigCanvasRef }) => {
  const [signMode, setSignMode] = useState(false);
  const isMobile = useMobile();

  return (
    <Box
      style={{
        width: isMobile ? "100%" : "463px",
        height: "216px",
      }}
    >
      {signMode ? (
        <SignatureCanvas
          ref={sigCanvasRef}
          canvasProps={{ width: isMobile ? "300px" : "463px", height: "216px" }}
        />
      ) : (
        <div
          style={{
            cursor: "pointer",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#666666",
          }}
          onClick={() => setSignMode(true)}
        >
          Tap here to sign
        </div>
      )}
    </Box>
  );
};

export default SignaturePanel;
