import { Box } from "@material-ui/core";
import { Colors } from "../../../constants/colors";
import { useMobile } from "../../../hooks/mobile";
import Button, { ButtonType, ButtonSize } from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import { checkIfEmpty } from "../../../utils/object";
import { Display, JustifyContent, TextAlign } from "../../../components/v2/Styled/enum";

interface ConfirmCancellationInterface {
  open: boolean;
  title?: string;
  description?: string;
  onClose: () => unknown;
  handleUpdate: (futureRecurring?: boolean) => unknown;
}

// future recurring bookings cancelled on booking frequency update
const ConfirmRecurringCancellationModal = ({
  open,
  onClose,
  handleUpdate,
  title = "Update recurring booking",
  description = "",
}: ConfirmCancellationInterface) => {
  const isMobile = useMobile();

  return (
    <Dialog open={open} onClose={onClose} fullWidth={isMobile}>
      <Box padding={"48px 48px 24px 48px"} bgcolor="#ffffff" borderRadius="8px" position="relative">
        <Box
          width={isMobile ? "100%" : "450px"}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Box fontFamily="Museo" fontWeight={600} fontSize="22px" color={Colors.NightBlue}>
              {title}
            </Box>
          </Box>
          {!checkIfEmpty(description) && <Box
            fontFamily="Open Sans"
            fontSize="18px"
            color={Colors.BlueyGrey}
            mt="16px"
            width="100%"
            display={Display.Flex}
            justifyContent={JustifyContent.center}
            textAlign={TextAlign.center}
          >
            {description}
          </Box>}

          <Box style={styles.actionWrapper} flexDirection="row">
            {<Button
              height={48}
              type={ButtonType.outlined}
              size={ButtonSize.small}
              title="Cancel"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />}
            <Button
              height={48}
              type={ButtonType.secondary}
              size={ButtonSize.small}
              title="Confirm"
              onClick={() => handleUpdate()}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmRecurringCancellationModal;

const styles = {
  actionWrapper: {
    width: "100%",
    display: "flex",
    marginTop: "48px",
  },
};
