import { BUFFER_TIME_MINS } from "../../constants/booking";
import { MIN_HOUR_ALLOWED_TODAY } from "../../constants/time";
import { isCorporateSession } from "../../helpers/massage";
import { convertMomentToMinutes, getCurrentMoment } from "../../helpers/time";
import { isCoupleMassageType } from "../../stores/booking";
import {
  getCurrentTimeInMinutes,
  convertDayMomentToDate,
  isFutureDateObject,
  isSameDate,
  isSelectedDateBeforeEndDate,
} from "../../utils/date.util";
import { checkIfEmpty } from "../../utils/object";
import { checkIsString } from "../../utils/string";

interface props {
  timezone?: string;
  latestTime: any;
  sessionType: any;
  earliestTime: any;
  selectedDay: any;
  timeOptions: any;
  timeRangeFilterFromInMinutes: any;
  dateRange: any;
}

const getNearestNextTimeOption = ({ timeOptions, timeInMinutes }: any) => {
  const newTime =
    timeOptions.find(({ value }: any) => value > timeInMinutes)?.value || timeInMinutes;
  return newTime;
};

const getDateTime = (timeOptions: any, sessionType: any, timezone?: string) => {
  let currentMoment = getCurrentMoment(timezone);

  let earliestStartInMinutes, latestStartInMinutes;
  const isCoupleMassage = isCoupleMassageType(sessionType);
  const isCorporate = isCorporateSession(sessionType);

  // 23:00 onwards
  if (currentMoment.hours() >= MIN_HOUR_ALLOWED_TODAY) {
    currentMoment = getCurrentMoment(timezone).add({ days: 1 });
    const earliestTimeMoment = getCurrentMoment(timezone)
      .add({ days: 1 })
      .set({ hours: 6, minutes: 0, seconds: 0 });
    earliestStartInMinutes = convertMomentToMinutes(earliestTimeMoment);
  } else {
    const minTimeInMins = getCurrentTimeInMinutes(timezone);
    const newNearestTime = getNearestNextTimeOption({ timeOptions, timeInMinutes: minTimeInMins });

    // 1 hour buffer only for couples and corporate massage time picker;
    earliestStartInMinutes =
      isCoupleMassage || isCorporate ? newNearestTime + BUFFER_TIME_MINS : newNearestTime;
  }

  if (isCoupleMassage || isCorporate) {
    latestStartInMinutes = earliestStartInMinutes;
  }
  return {
    selectedDay: convertDayMomentToDate(currentMoment),
    earliestStartInMinutes,
    latestStartInMinutes,
    timeRangeFilterFromInMinutes: earliestStartInMinutes,
  };
};

const isFutureDateRange = (dateRange: any, timezone?: string) => {
  if (checkIfEmpty(dateRange)) return false;

  const { from, to } = dateRange;
  return isFutureDateObject(from, timezone) && isFutureDateObject(to, timezone);
};

// REMINDER: selectedDay is in local timezone
const getUpdatedDateTime = ({
  timezone,
  timeOptions,
  selectedDay,
  earliestTime,
  latestTime,
  sessionType,
  timeRangeFilterFromInMinutes,
  dateRange,
}: props) => {
  let updatedFields: any = {
    updated: false,
  };

  // date object stored in local storage as string
  if (checkIsString(selectedDay) || !selectedDay) return updatedFields;

  const isFutureRange = isFutureDateRange(dateRange, timezone);
  const hasDateRange = !checkIfEmpty(dateRange);

  if (!isFutureRange) {
    const newDateTime = getDateTime(timeOptions, sessionType, timezone);

    const isSelectedRangeNotFeasible =
      hasDateRange && !isSameDate(dateRange.from, newDateTime.selectedDay, timezone);
    if (isSelectedRangeNotFeasible) {
      updatedFields = {
        selectedDay: newDateTime.selectedDay,
        latestStartInMinutes: newDateTime.latestStartInMinutes || null,
        earliestStartInMinutes: newDateTime.earliestStartInMinutes,
        timeRangeFilterFromInMinutes: newDateTime.timeRangeFilterFromInMinutes,
      };
      if (hasDateRange) {
        const { from: startDate, to: endDate } = dateRange;
        const updatedRange = dateRange;
        if (!isSameDate(startDate, newDateTime.selectedDay, timezone)) {
          updatedRange.from = newDateTime.selectedDay;
        }
        if (
          !isSameDate(endDate, newDateTime.selectedDay, timezone) &&
          isSelectedDateBeforeEndDate(endDate, newDateTime.selectedDay)
        ) {
          updatedRange.to = newDateTime.selectedDay;
        }
        updatedFields.selectedDayRange = updatedRange;
      }
    } else {
      const bufferTime =
        isCoupleMassageType(sessionType) || isCorporateSession(sessionType) ? BUFFER_TIME_MINS : 0;
      const minTimeInMins = getCurrentTimeInMinutes(timezone, bufferTime);
      const newNearestTime = getNearestNextTimeOption({
        timeOptions,
        timeInMinutes: minTimeInMins,
      });

      const isRangedBooking = !isSameDate(dateRange.from, dateRange.to, timezone);
      if (!isRangedBooking) {
        if (earliestTime < minTimeInMins) {
          updatedFields.earliestStartInMinutes = newDateTime.earliestStartInMinutes;
        }
        if (timeRangeFilterFromInMinutes < newNearestTime) {
          updatedFields.timeRangeFilterFromInMinutes = newNearestTime;
        }
      }
      if (latestTime < minTimeInMins) {
        updatedFields.latestStartInMinutes = newDateTime.latestStartInMinutes || null;
      }
    }
  }

  if (Object.keys(updatedFields).length > 1) {
    updatedFields.updated = true;
  }
  return updatedFields;
};

const getUpdatedDateTimePayload = ({
  timezone,
  timeOptions,
  sessionType,
  selectedDay,
  latestStartInMinutes,
  earliestStartInMinutes,
  backupSelectedDay,
  backupEarliestStartInMinutes,
  backupLatestStartInMinutes,
  timeRangeFilterFromInMinutes,
  selectedDayRange,
  backupSelectedDayRange,
}: any) => {
  let updatePayload: any = {
    selectedDay,
    latestStartInMinutes,
    earliestStartInMinutes,
    backupSelectedDay,
    backupEarliestStartInMinutes,
    backupLatestStartInMinutes,
    timeRangeFilterFromInMinutes,
  };

  let updated = false;
  const preferredUpdatedFields = getUpdatedDateTime({
    timezone,
    timeOptions,
    selectedDay,
    sessionType,
    earliestTime: earliestStartInMinutes,
    latestTime: latestStartInMinutes || null,
    timeRangeFilterFromInMinutes,
    dateRange: selectedDayRange,
  });
  if (preferredUpdatedFields.updated) {
    updatePayload = {
      ...updatePayload,
      ...preferredUpdatedFields,
    };
    updated = true;
  }

  if (backupSelectedDay) {
    const backupUpdatedFields = getUpdatedDateTime({
      timezone,
      timeOptions,
      selectedDay: backupSelectedDay,
      earliestTime: backupEarliestStartInMinutes,
      latestTime: backupLatestStartInMinutes,
      sessionType,
      timeRangeFilterFromInMinutes,
      dateRange: backupSelectedDayRange,
    });
    if (backupUpdatedFields.updated) {
      updated = true;
      const {
        selectedDay: updatedBackupDate,
        earliestStartInMinutes: updatedBackupEarliest,
        latestStartInMinutes: updatedBackupLatest,
        selectedDayRange: updatedDayRange,
      } = backupUpdatedFields;
      updatePayload = {
        ...updatePayload,
        backupSelectedDay: updatedBackupDate || updatePayload.backupSelectedDay,
        backupEarliestStartInMinutes:
          updatedBackupEarliest || updatePayload.backupEarliestStartInMinutes,
        backupLatestStartInMinutes: updatedBackupLatest || updatePayload.backupLatestStartInMinutes,
        backupSelectedDayRange: updatedDayRange || updatePayload.backupSelectedDayRange,
      };
    }
  }

  return {
    ...updatePayload,
    updated,
  };
};

export { getDateTime, getUpdatedDateTime, getNearestNextTimeOption, getUpdatedDateTimePayload };
