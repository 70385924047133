import { useEffect, useState } from "react";
import BrowseResults, { ProviderFilters } from "./BrowseResults";
import BrowseTherapistForm, { extractProvidersFilterFromQuery } from "./BrowseForm";
import { useMobile } from "../../../hooks/mobile";
import { Box, Grid, GridProps } from "@material-ui/core";
import { getPractitioners } from "../../../services/therapist/therapist.service";
import { useHistory } from "react-router-dom";

function BrowseTherapists(): JSX.Element {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);

  const [providers, setProviders] = useState<any>(null);
  const [pageDetails, setPageDetails] = useState({ currentPage: 1, pageCount: 0 });
  const [selectedOption, setSelectedOption] = useState<any>({
    serviceId: null,
    serviceName: null,
    latitude: null,
    longitude: null,
    treatmentId: null,
    languageSpoken: null,
    gender: null,
    healthFund: null,
    name: null,
    sortBy: null,
    startTime: null,
    endTime: null,
  });
  const [filterCount, setFilterCount] = useState(0);

  const [isLoading, setLoading] = useState(false);

  const isMobile = useMobile();
  const pageSize = 10;

  const handleBrowse = async (
    selectedCords: { lat: number; long: number },
    serviceId: string,
    serviceName: string,
    treatmentId: string,
    languageSpoken: string | null,
    gender: string | null,
    healthFund: string | null,
    name: string | null,
    sortBy: string | null,
    startTime: Date | string | null,
    endTime: Date | string | null,
    page: number = 1,
  ) => {
    setSelectedOption({
      serviceId,
      latitude: selectedCords.lat,
      longitude: selectedCords.long,
      serviceName,
      treatmentId,
      languageSpoken,
      gender,
      healthFund,
      name,
      startTime,
      endTime,
      sortBy,
    });

    fetchProviders(
      selectedCords.lat,
      selectedCords.long,
      serviceId,
      serviceName,
      treatmentId,
      languageSpoken,
      gender,
      healthFund,
      name,
      sortBy,
      startTime,
      endTime,
      page,
    );
  };

  useEffect(()=>{
    // The provider should initiallly be fetched only if its in browing screen not in a browsing 
    const isBrowsing = queryParams.has("isBrowsing");

    if(!isBrowsing) return;

    const {
      service,
      treatment,
      lat,
      long,
      serviceName,
      page,
      language,
      name,
      gender,
      healthFund,
      sortBy,
      startTime,
      endTime,
    } =  extractProvidersFilterFromQuery(window.location.search);

    handleBrowse(
      { lat: parseFloat(lat || ""), long:  parseFloat(long || "")},
      service as string,
      serviceName as string,
      treatment as string,
      language,
      gender,
      healthFund,
      name,
      sortBy,
      startTime,
      endTime,
      parseInt(page || "1", 10)
    );
  },[]);

  const gridProps = {
    spacing: 3,
    style: {
      marginTop: "40px",
      marginLeft: isMobile ? undefined : "80px",
      marginBottom: "16px",
      padding: isMobile ? "0px 24px" : "0px",
      width: "auto",
      marginRight: "0px",
    },
    direction: isMobile ? "column" : undefined,
    alignItems: isMobile ? "center" : undefined,
  } as GridProps;

  const handleFilterApplied = (filters: ProviderFilters)=> {
    const {
      service,
      treatment,
      lat,
      long,
      address: addressName,
      serviceName,
    } =  extractProvidersFilterFromQuery(window.location.search);

    const selectedCords = {
      lat:parseFloat(lat || ""), long: parseFloat(long|| "")
    };
    const serviceId = service || "";
    const treatmentId = treatment || "";

    const languageSpoken = filters.language;
    const gender = filters.gender;
    const healthFund = filters.healthFund;
    const name = filters.name;
    const sortBy = filters.sortBy;
    const startTime = filters.startTime;
    const endTime = filters.endTime;

    handleBrowse(
      selectedCords,
      serviceId,
      serviceName || "",
      treatmentId,
      languageSpoken,
      gender,
      healthFund,
      name,
      sortBy,
      startTime,
      endTime,
    );
  };

  const handlePageChange = async (page: number) => {
    // Preserving the page no.
    queryParams.set("page", String(page));
    history.replace({ search: queryParams.toString() });

    fetchProviders(
      selectedOption.latitude,
      selectedOption.longitude,
      selectedOption.serviceId,
      selectedOption.serviceName,
      selectedOption.treatmentId,
      selectedOption.languageSpoken,
      selectedOption.gender,
      selectedOption.healthFund,
      selectedOption.name,
      selectedOption.sortBy,
      selectedOption.startTime,
      selectedOption.endTime,
      page,
    );
  };

  const fetchProviders = async (
    latitude: number,
    longitude: number,
    serviceId: string,
    serviceName: string,
    treatmentId: string,
    languageSpoken: string | null,
    gender: string | null,
    healthFund: string | null,
    name: string | null,
    sortBy: string | null,
    startTime: Date | string | null,
    endTime: Date | string | null,
    currentPage?: number,
  ) => {
    setLoading(true);
    try {
      let response = await getPractitioners(
        latitude,
        longitude,
        serviceId,
        serviceName,
        currentPage,
        treatmentId,
        languageSpoken,
        gender,
        healthFund,
        name,
        sortBy,
        startTime,
        endTime,
        pageSize,
      );
      if (response.success && response.data) {
        let { totalCount, currentPage, therapistList } = response.data;
        const totalPages = Math.ceil(totalCount / pageSize);
        let pageDetails = { currentPage: currentPage, pageCount: totalPages };
        setPageDetails(pageDetails);

        const providers = therapistList;
        setProviders(providers);

        // This is to preserve the state that the user is still browsing.
        if(providers.length >0 && !queryParams.has("isBrowsing")){
          queryParams.set("isBrowsing", "true");
          history.replace({ search: queryParams.toString() });
        }
      }
    } catch (err) {
      console.debug(err);
    }
    setLoading(false);
  };

  const removeQueryParams = () => {
    // Replace the current URL without query parameters
    history.replace(window.location.pathname);
  };

  const handleResetSearch = () => {
    removeQueryParams();
    setProviders(null);
  };

  const renderBrowse = () => {
    let selected;
    const isBrowsing = queryParams.has("isBrowsing");

    if ((providers === null || (providers != null && providers.length === 0) && !filterCount) && !isBrowsing) {
      let notFound = false;
      if (providers !== null && providers.length === 0) {
        notFound = true;
      }
      selected = (
        <Grid container={isMobile ? false : true} {...gridProps}>
          <BrowseTherapistForm isLoading={isLoading} notFound={notFound} onBrowse={handleBrowse} />
        </Grid>
      );
    } else {
      selected = (
        <Grid {...gridProps}>
          <BrowseResults
            pageDetails={pageDetails}
            providers={providers}
            resetSearch={handleResetSearch}
            onPageChange={handlePageChange}
            onFilterApplied = {handleFilterApplied}
            onFilterCountChange = {(count:number) =>{
              setFilterCount(count);
            }}
            filterCount = {filterCount}
          />
        </Grid>
      );
    }
    return selected;
  };

  return <Box>{renderBrowse()}</Box>;
}

export default BrowseTherapists;
