import { Box, styled } from "@material-ui/core";
import React, { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMobile } from "../../../../hooks/mobile";
import { useDocumentTypeOptions } from "../../../../hooks/userTerms.hook";
import { RootState } from "../../../../stores/V2";
import { actions } from "../../../../stores/V2/documents/addDocuments";
import {
  AGE_CARE_TERMS_DOCUMENTS_TYPE_LABEL,
  REGEX,
  USER_UPLOADED_CONTENT_TYPE,
} from "../../../../data/enums";
import { getValue } from "../../../../utils/object";
import { TermsDocumentType } from "../../../Users/TermsAndPolicies/TermsAndPolicies";
import { styles } from "./styles";
import Dropdown, { DropdownOption } from "../../../Dropdown";
import Button, { ButtonSize, ButtonType } from "../../../Button";
import { Colors } from "../../../../constants/colors";
import InputModal from "../../../Modals/InputModal/InputModal";
import TermsAndPoliciesUploadedFiles from "../../../Users/TermsAndPolicies/TermsPoliciesUploadFiles";
import { getLastElement } from "../../../../utils/array";
import { BASE_UPLOADS_URL } from "../../../../constants/common";

const BoxColumn = styled(Box)({
  flexDirection: "column",
});

type Props = {
  documents: any[];
  listTitle?: string;
  uploadTitle?: string;
  allowUpload?: boolean;
  includeDocuments?: boolean;
};

const AddTherapistDocument = ({
  documents = [],
  allowUpload = true,
  includeDocuments = false,
  listTitle = "Your documents",
  uploadTitle = "Add document",
}: Props) => {
  const isMobile = useMobile();

  const state = useSelector((state: RootState) => state.documents);
  const dispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUserInput = (value: string) => {
    dispatch(actions.setUserInput(value));
    dispatch(actions.setShowInputModal(false));
  };

  const validateUserInput = (input: string) => {
    return REGEX.URL.test(input);
  };

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setContentType(USER_UPLOADED_CONTENT_TYPE.FILE));
    const file = getValue(e, "target.files[0]");
    dispatch(actions.setUserInput(file));
  }, []);

  const handleSelectedOption = (option: TermsDocumentType) => {
    dispatch(actions.setSelectedTermPolicies(option.value));
  };

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleAddLink = () => {
    dispatch(actions.setShowInputModal(true));
    dispatch(actions.setContentType(USER_UPLOADED_CONTENT_TYPE.LINK));
  };

  const getUserInputForDisplay = (input: typeof state.userInput) => {
    if (typeof input === "object") return input.name;
    if (typeof input === "string") return input;
    return "";
  };

  const resetInput = () => {
    dispatch(actions.setUserInput(undefined));

    // This will also clear the currently selected file in the file input field if there is one
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const getFormattedPendingDocs = () => {
    return documents.map((document) => {
      const { link, contentType, originalLink, documentTypeId, name,filePath } = document;
      const fullPath = BASE_UPLOADS_URL+"/" + filePath;
      return {
        ...document,
        filePath: link || fullPath,
        type: documentTypeId,
        name,
        contentType,
        originalLink,
      };
    });
  };

  const getDocumentTypeOptions = () => {
    if (!documents || !documents.length) return [];

    let formattedAgreements: any[] = [];

    if (documents && documents.length) {
      formattedAgreements = documents.map(
        ({
          type,
          name,
          id,
          link,
          contentType,
          filePath
        }: {
          type: string;
          name: string;
          id: number;
          link: string;
          contentType: string;
          filePath:string
        }) => {
          let title = type;
          if (type === AGE_CARE_TERMS_DOCUMENTS_TYPE_LABEL.OTHER) {
            title = name || "Other";
          }
          const linkName =
            contentType === USER_UPLOADED_CONTENT_TYPE.LINK
              ? link
              : getLastElement(filePath?.split("/"));
          return {
            value: id,
            title: title,
            helperText: linkName,
          };
        }
      );
    }
    return formattedAgreements;
  };

  const getAllPendingDocumentTypes = () => {
    return documents.map(({ type, documentTypeId }) => ({ value: documentTypeId, title: type }));
  };

  const formattedOptions = getDocumentTypeOptions();
  const formattedPendingDocs = getFormattedPendingDocs();
  const allDocumentTypes = getAllPendingDocumentTypes();

  return (
    <>
      {includeDocuments && (
        <Box>
          <Box style={styles.title} marginBottom={"24px"}>
            {listTitle}
          </Box>
          <Box style={{ ...styles.description, ...styles.dusk }}>
            PDF, JPG and PNG files accepted.
          </Box>
          <Box marginTop={"24px"}>
            <TermsAndPoliciesUploadedFiles
              attachBaseURL={false}
              docsType={allDocumentTypes}
              styles={{ marginTop: "16px" }}
              uploadedFilesData={formattedPendingDocs}
            />
          </Box>
          <Box
            style={{ ...styles.subTitle, ...styles.dusk }}
            marginBottom={"24px"}
            marginTop={"40px"}
          >
            {uploadTitle}
          </Box>
        </Box>
      )}
      <Box style={{ overflow: "visible" }}>
        <Dropdown
          title="Document type"
          options={formattedOptions}
          onSelectedOption={(option) => handleSelectedOption(option)}
          selectedOption={(formattedOptions as DropdownOption[]).find(
            ({ value }: TermsDocumentType) => value === state.selectedTermPolicies
          )}
          paddingTop={"0px"}
          paddingBottom={"0px"}
        />
      </Box>
      {!!state.userInput ? (
        <Box maxWidth={isMobile ? "100%" : "400px"} style={styles.selectedFileWrapper}>
          <Box style={{ ...styles.description, ...styles.dusk, ...styles.flex }} marginTop={"24px"}>
            {getUserInputForDisplay(state.userInput)}
          </Box>
          <Button
            title="Remove"
            colorTitle={Colors.Danger}
            type={ButtonType.background}
            width="auto"
            onClick={() => resetInput()}
            size={ButtonSize.small}
            style={{ height: "20px", marginTop: "24px" }}
          />
        </Box>
      ) : (
        <React.Fragment />
      )}
      {state.selectedTermPolicies && allowUpload ? (
        <Box style={styles.block} mt={"32px"}>
          <Box style={styles.displayFlex}>
            <Button
              width={"100px"}
              title="Select file"
              size={ButtonSize.small}
              type={ButtonType.outlined}
              onClick={handleFileSelect}
            />
            <input
              type="file"
              accept=".jpg, .png, .pdf"
              ref={fileInputRef}
              style={{ visibility: "hidden", width: 0 }}
              onChange={handleFileChange}
            />
          </Box>

          {/* <Button
            width={"100px"}
            title="Add link"
            size={ButtonSize.small}
            type={ButtonType.outlined}
            onClick={handleAddLink}
          /> */}
        </Box>
      ) : (
        <React.Fragment />
      )}
      <BoxColumn>
        <InputModal
          open={state.showInputModal}
          onClose={() => dispatch(actions.setShowInputModal(false))}
          title={"Add web url"}
          validateInput={validateUserInput}
          validationErrorMessage={"Please enter a valid URL"}
          onConfirm={handleUserInput}
        />
      </BoxColumn>
    </>
  );
};

export default AddTherapistDocument;
