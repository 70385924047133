import React from "react";
import { RecipientWithSummary } from "../../../models";
import { RecipientForm } from "../components/RecipientForm";

interface RecipientDetailsSectionProps {
  recipient: RecipientWithSummary;
}

const RecipientDetailsSection: React.FC<RecipientDetailsSectionProps> = ({
  recipient,
}) => {
  return <RecipientForm recipient={recipient} recipientId={recipient.id} />;
};

export default RecipientDetailsSection;
