import axios from "axios";
import { HostedFields, PayPal } from "braintree-web";
import create from "zustand";

type State = {
  clientToken: string | null;
  fetchClientToken: () => unknown;
  hostedFieldsInstance: HostedFields | null;
  setHostedFieldsInstance: (instance: HostedFields | null) => unknown;
  paypalInstance: PayPal | null;
  setPaypalInstance: (instance: PayPal) => unknown;
};

export const useBraintreeStore = create<State>((set) => ({
  clientToken: null,
  fetchClientToken: () => {
    const accessToken = localStorage.getItem("token");

    if (accessToken) {
      axios
        .get("/paymentmethods/clientToken", {
          params: {
            accessToken,
            currency: "AUD", // TODO: Currency?
          },
        })
        .then((response) => {
          set({
            clientToken: response.data.clientToken,
          });
        })
        .catch((error) => {
          console.debug("fetch client token error: ", error);
        });
    }
  },
  hostedFieldsInstance: null,
  setHostedFieldsInstance: (instance: HostedFields | null) => {
    set({
      hostedFieldsInstance: instance,
    });
  },
  paypalInstance: null,
  setPaypalInstance: (instance: PayPal) => {
    set({ paypalInstance: instance });
  },
}));
