import { Box } from "@material-ui/core";
import styles from "./styles";
import { Colors } from "../../constants/colors";

interface TextButtonProps {
  text: string,
  type?: "danger" | "disabled" | "primary",
  onClick: () => unknown,
  textStyle?: object,
}

const TextButton = ({ text, type, onClick, textStyle = {} }: TextButtonProps) => {
  const typeStyles = {
    "disabled": {
      color: "#979AA2",
    },
    "danger": {
      color: "#EA3323",
    },
    "primary" : {
      color: Colors.TurquoiseBlue,
    }
  };

  const handleClick = () => {
    if (type === "disabled") return;
    onClick();
  };

  const getStyle = () => {
    let style = { ...styles.textStyle };
    if (type && typeStyles[type]) style = { ...style, ...typeStyles[type] };
    return { ...style, ...textStyle };
  };

  return <Box sx={getStyle()} onClick={handleClick}>
    {text}
  </Box>;
};

export default TextButton;
